import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector } from "react-redux";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

/*
 ** Manager Reducer
 */
import activitySlice from "./features/activity-slice";
import agentSlice from "./features/agent-slice";
import asideSlice from "./features/aside-slice";
import authSlice from "./features/auth-slice";
import emailSlice from "./features/email-slice";
import goodsSlice from "./features/goods-slice";
import hallSlice from "./features/hall-slice";
import myBonusSlice from "./features/my-bonus-slice";
import rankSlice from "./features/rank-slice";
import recommendSlice from "./features/recommend-slice";
import renverSlice from "./features/renver-slice";
import spinSlice from "./features/spin-slice";
import teamSlice from "./features/team-slice";
import vipSlice from "./features/vip-slice";
import wallet from "./features/wallet-slice";
import wheelSlice from "./features/wheel.slice";
import loadSlice from "./features/load-slice";
import exchangeSlice from "./features/exchange-code-slice";
import agentRankSlice from "./features/agent-rank-slice";

const persistConfig = {
  key: "root",
  storage,
};

const rootReducer = combineReducers({
  activitySlice,
  agentRankSlice,
  agentSlice,
  asideSlice,
  authSlice,
  emailSlice,
  goodsSlice,
  hallSlice,
  loadSlice,
  myBonusSlice,
  rankSlice,
  recommendSlice,
  renverSlice,
  spinSlice,
  teamSlice,
  vipSlice,
  wallet,
  exchangeSlice,
  wheelSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware(getDefaultMiddleware) {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
