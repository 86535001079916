import { StoreKey, ThirdGame } from "@/const";
import { systemConfigUtils } from "@/utils";
import { EventGlobalManager } from "@/utils/events";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { NetMsg } from "@/utils/net/INet";
import { client_proto, game_manage_proto, gamealloc, tgame_proto } from "@/utils/net/shared/proto";
import { GameService } from "./game";
import { get } from "lodash-es";

export namespace ThirdGameService {
  /** 一级大厅列表 */
  export const gameGroupV1 = (uid: number, lang: string = "en") => {
    BrowserWsClient.instance.handleSendData({
      route: ThirdGame.Req.GAME_GROUP_V1,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GAME_MANAGE,
      cmd: game_manage_proto.GAME_MANAGE_SUB_MSG_ID.GAME_MANAGE_MULTI_LANG_GAME_GROUP_V1_REQ,
      data: { uid, lang, plat_type: 1 },
    });
  };

  /** 二级大厅列表 */
  export const gameGroupDetailV1 = (uid: number, game_group_id: number, lang = "en") => {
    const parm = {
      uid,
      game_group_id,
      lang,
    };
    BrowserWsClient.instance.handleSendData({
      route: ThirdGame.Req.GAME_GROUP_V1_DETAIL,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GAME_MANAGE,
      cmd: game_manage_proto.GAME_MANAGE_SUB_MSG_ID.GAME_MANAGE_GAME_GROUP_DETAIL_V1_REQ,
      data: parm,
    });
  };

  /** 二级大厅列表item */
  export const gameGroupDetailV2 = (uid: number, game_group_id: number, lang = "en") => {
    let param = {
      uid,
      game_group_id,
      lang,
    };
    BrowserWsClient.instance.handleSendData({
      route: ThirdGame.Req.GAME_GROUP_V2_DETAIL,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GAME_MANAGE,
      cmd: game_manage_proto.GAME_MANAGE_SUB_MSG_ID.GAME_MANAGE_GAME_GROUP_DETAIL_V2_REQ,
      data: param,
      force: true,
    });
  };

  /** 分组游戏列表 */
  export const gameGroupDetail = (uid: number, game_group_id: number, page: number, page_size: number) => {
    let param = {
      uid,
      game_group_id,
      page,
      page_size,
    };
    BrowserWsClient.instance.handleSendData({
      route: ThirdGame.Req.GAME_GROUP_DETAIL,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GAME_MANAGE,
      cmd: game_manage_proto.GAME_MANAGE_SUB_MSG_ID.GAME_MANAGE_GAME_GROUP_DETAIL_REQ,
      data: param,
    });
  };

  export const getGameUrl = (access: string, game: string, uid: number, userName: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      let dsttype: any = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GAMEALLOC;
      let ctype: any = gamealloc.GAMEALLOC_CMD.GAMEALLOC_CMD_ALLOC_REQ;

      BrowserWsClient.instance.handleSendData({
        route: ThirdGame.Req.GAME_PLATFORM,
        dsttype,
        cmd: ctype,
        data: { access, game, uid },
      });

      const sysConfig = GameService.getConfig();
      const client_id = get(sysConfig, StoreKey.CLIENT_IP);

      const gamePlatformCallback = (res: NetMsg<gamealloc.IAllocResp>) => {
        console.log("gamePlatformCallback-----------");
        const { data } = res;
        if (data) {
          const { srvid } = data;

          let param = {
            uid,
            plat: access,
            game_uid: game,
            extern_json: "",
            dstid: srvid,
          };

          switch (access) {
            case "bridge":
              let bridge = {
                member_ip: client_id,
                channel: "WebInDesktop",
              };
              param.extern_json = JSON.stringify(bridge);
              break;
            case "pgsoft":
              let pg = {
                loginIp: client_id,
                isMobileLogin: 1,
                userName,
              };
              param.extern_json = JSON.stringify(pg);
              break;
            case "jdb":
              let jdb = {
                playerType: 0,
                isAPP: false,
              };
              param.extern_json = JSON.stringify(jdb);
              break;
            case "bxzf":
            case "ydzf":
            case "flbzf":
              let bxzf = {
                loginIp: client_id,
                platform: "H5",
              };
              param.extern_json = JSON.stringify(bxzf);
              break;
          }

          dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_TGAME;
          ctype = tgame_proto.TGAME_SUB_MSG_ID.TGAME_LOGIN_REQ;
          console.log("gamePlatformCallback-----------", param);
          BrowserWsClient.instance.handleSendData({
            route: ThirdGame.Req.TGAME_SUB_GAME,
            dsttype,
            cmd: ctype,
            data: param,
          });
        }
      };

      const gameSubGameCallback = (res: NetMsg<tgame_proto.ILoginResp>) => {
        const { data } = res;
        if (data) {
          resolve(data.game_launch_url!);
          setTimeout(() => {
            EventGlobalManager.off(ThirdGame.Resp.GAME_PLATFORM, gamePlatformCallback);
            EventGlobalManager.off(ThirdGame.Resp.TGAME_SUB_GAME, gameSubGameCallback);
          }, 10);
        }
      };

      EventGlobalManager.on(ThirdGame.Resp.GAME_PLATFORM, gamePlatformCallback);
      EventGlobalManager.on(ThirdGame.Resp.TGAME_SUB_GAME, gameSubGameCallback);
    });
  };
}
