import { client_proto } from "@/utils/net/shared/proto";
import { createSlice } from "@reduxjs/toolkit";

export type VipTakeReward = {
  next_time: number;
  reward_id: any;
};

export type VipUnpack = {
  init_exp: number;
  next_exp: number;
  privilege: string[];
  reward: string[];
  status: number;
  take_reward: VipTakeReward[];
  vip_id: number;
};

export type VipData = {
  userScore: 0;
  vip_list: VipUnpack[];
};

type VipState = {
  vipConfigInfo: Partial<VipData>;
  vip_detail_log: client_proto.IGetVipDetailLogResp;
  vip_level_desc: client_proto.IVipLevelDescResp;
  set_vip_privilege: client_proto.ISetVipPrivilegeResp;
  user_vip_resp: client_proto.IGetUserVipResp;
  reward: {
    next_time: number;
    result: number;
  } | null;
};

const initialState: VipState = {
  vipConfigInfo: {},
  vip_detail_log: {},
  vip_level_desc: {},
  set_vip_privilege: {},
  user_vip_resp: {},
  reward: null,
};

export const vipSlice = createSlice({
  name: "vip",
  initialState,
  reducers: {
    setVipConfigInfo: (state, action) => {
      const { payload } = action;
      state.vipConfigInfo = payload;
    },
    setVipHistory: (state, action) => {
      const { payload } = action;
      state.vip_detail_log = payload;
    },
    setVipLevelDesc: (state, action) => {
      const { payload } = action;
      state.vip_level_desc = payload;
    },
    setVipPrivilege: (state, action) => {
      const { payload } = action;
      state.set_vip_privilege = payload;
    },
    setUserVipResp: (state, action) => {
      const { payload } = action;
      state.user_vip_resp = payload;
    },
    setReward(state, action) {
      const { payload } = action;
      state.reward = payload;
    },
  },
});

export namespace VipActions {
  export const { setVipConfigInfo, setVipHistory, setVipLevelDesc, setVipPrivilege, setUserVipResp, setReward } =
    vipSlice.actions;
}

export default vipSlice.reducer;
