import { clientProtoPrefix as prefix, clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace Rank {
  export const SERVER_TYPE_GETRANK = 35;

  export const Req = {
    SHOW_LIST: clientProtoPrefix("RequestRankShowListReq"),
    DATA_LIST: clientProtoPrefix("RequestRankListReq"),
    JACKPOT: clientProtoPrefix("RequestRankJackpotReq"),
    // 请求玩家的奖励列表
    REWARD_LIST: clientProtoPrefix("RequestRewardListReq"),
    GET_REWARD: clientProtoPrefix("RequestGetRewardReq"),
    GAME_LIST: clientProtoPrefix("RequestJoinGameListReq"),
    GET_REPORT: clientProtoPrefix("RankListReportReq"),
  };

  export const Resp = {
    SHOW_LIST: clientProtoPrefix("ResponseRankShowListResp"),
    DATA_LIST: clientProtoPrefix("ResponseRankListResp"),
    JACKPOT: clientProtoPrefix("ResponseRankJackpotResp"),
    REWARD_LIST: clientProtoPrefix("ResponseRewardListResp"),
    // 请求领取奖励
    GET_REWARD: clientProtoPrefix("ResponseGetRewardResp"),
    // // 加入排行榜的游戏
    GAME_LIST: clientProtoPrefix("ResponseJoinGameListResp"),
    //请求排行榜历史报告
    GET_REPORT: clientProtoPrefix("RankListReportResp"),
  };
}

export namespace AgentRank {
  export const Req = {
    SHOW_LIST: prefix("AgentRankRequestRankShowListReq"),
    JACKPOT: prefix("AgentRankRequestRankJackpotReq"),
    DATA_LIST: prefix("AgentRankRequestRankListReq"),
    GET_DETAILS: prefix("AgentRankGetUserDetailsReq"),
    REWARD_LIST: prefix("AgentRankRequestRewardListReq"),
    GET_REPORT: prefix("AgentRankListReportReq"),
    GET_REWARD: prefix("AgentRankRequestGetRewardReq"),
  };

  export const Resp = {
    SHOW_LIST: prefix("AgentRankResponseRankShowListResp"),
    JACKPOT: prefix("AgentRankResponseRankJackpotResp"),
    DATA_LIST: prefix("AgentRankResponseRankListResp"),
    GET_DETAILS: prefix("AgentRankGetUserDetailsResp"),
    REWARD_LIST: prefix("AgentRankResponseRewardListResp"),
    GET_REPORT: prefix("AgentRankListReportResp"),
    GET_REWARD: prefix("AgentRankResponseGetRewardResp"),
  };
}

/**
 * 排行榜的Resp
 * SHOW_LIST 排行榜列表
 */
CmdToPbName[client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_LIST_RESP] = Rank.Resp.DATA_LIST;
CmdToPbName[client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_JACKPOT_RESP] = Rank.Resp.JACKPOT;
CmdToPbName[client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_JOIN_GAME_LIST_RESP] = Rank.Resp.DATA_LIST;
CmdToPbName[client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_REWARD_LIST_RESP] = Rank.Resp.REWARD_LIST;
CmdToPbName[client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_SHOW_LIST_RESP] = Rank.Resp.SHOW_LIST;
CmdToPbName[client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_GET_REWARD_RESP] = Rank.Resp.GET_REWARD;
CmdToPbName[client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_GET_REPORT_RESP] = Rank.Resp.GET_REPORT;
CmdToPbName[client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_REWARD_LIST_RESP] = Rank.Resp.REWARD_LIST;

/**
 * 代理排行榜Resp
 */
CmdToPbName[client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_LIST_RESP] = AgentRank.Resp.DATA_LIST;
CmdToPbName[client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_SHOW_LIST_RESP] = AgentRank.Resp.SHOW_LIST;
CmdToPbName[client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_JACKPOT_RESP] = AgentRank.Resp.JACKPOT;
CmdToPbName[client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_GET_USER_DETAILS_RESP] =
  AgentRank.Resp.GET_DETAILS;

CmdToPbName[client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_REWARD_LIST_RESP] =
  AgentRank.Resp.REWARD_LIST;

CmdToPbName[client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_GET_REPORT_RESP] = AgentRank.Resp.GET_REPORT;
CmdToPbName[client_proto.AGENT_RANK_INFO_SUB_MSG_ID.AGENT_RANK_MSG_ID_RANK_GET_REWARD_RESP] = AgentRank.Resp.GET_REWARD;
