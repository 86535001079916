import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { client_proto } from "@/utils/net/shared/proto";
import { PURGE } from "redux-persist";
type GoodsState = {
  rules: string;
  propPush: client_proto.IGetPropPush;
};

const initialState: GoodsState = {
  rules: "",
  propPush: {},
};

export const goodsSlice = createSlice({
  name: "goods",
  initialState,
  reducers: {
    setRules: (state, action) => {
      const { payload } = action;
      const { rules } = payload;
      const json = JSON.parse(rules);
      const { content } = json;

      return {
        ...state,
        rules: content,
      };
    },
    setPropPush: (state, action) => {
      const { payload } = action;
      state.propPush = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<GoodsState>) => {
    builder.addCase(PURGE, (state, action) => {
      return initialState;
    });
  },
});

export namespace GoodsActions {
  export const { setRules, setPropPush } = goodsSlice.actions;
}

export default goodsSlice.reducer;
