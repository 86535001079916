import { assetProtoPrefix, clientProtoPrefix, agetProtoPrefix } from "@/utils";
import { CmdToPbName } from "@/utils/net/INet";
import { client_proto, agent } from "@/utils/net/shared/proto";

export namespace User {
  export const Req = {
    /** 登录 */
    Login: clientProtoPrefix("LoginReq"),
    Report: clientProtoPrefix("ReportRegDataReq"),

    /** 获取用户信息请求 */
    GetUserInfo: clientProtoPrefix("GetUserBasicInfoReq"),
    /** 设置用户信息请求 */
    SetUserInfo: clientProtoPrefix("SetUserInfoReq"),
    /** 设置语言 */
    SetLangCode: clientProtoPrefix("SetLangCodeReq"),
    /** 资产更新 */
    Asset: assetProtoPrefix("AssetReq"),
    /** 设置支付密码 **/
    BindPassword: clientProtoPrefix("BindPasswordReq"),
    /** 测试用 重置支付密码 **/
    ResetPassword: clientProtoPrefix("ResetPasswordReq"),
  };

  export const Resp = {
    /** 登录 */
    Login: clientProtoPrefix("LoginResp"),
    Report: clientProtoPrefix("ReportRegDataResp"),

    /** 获取用户信息返回 */
    GetUserInfo: clientProtoPrefix("GetUserBasicInfoResp"),
    /** 设置用户信息返回 */
    SetUserInfo: clientProtoPrefix("SetUserInfoResp"),
    /** 设置语言返回 */
    SetLangCode: clientProtoPrefix("SetLangCodeResp"),
    /** 资产更新 */
    Asset: assetProtoPrefix("AssetResp"),
    /** 设置支付密码 **/
    BindPassword: clientProtoPrefix("BindPasswordResp"),
    /** 测试用 重置支付密码 **/
    ResetPassword: clientProtoPrefix("ResetPasswordResp"),
    KickPush: agetProtoPrefix("KickPush"),
  };

  export const Event = {
    /**网络断开链接 */
    NetWork: clientProtoPrefix("NetworkReq"),
    WeakNetWork: clientProtoPrefix("WeakNetworkReq"),
    NetHearTimeOut: clientProtoPrefix("NetHearTimeOutReq"),
  };
}

// 请求用户数据
CmdToPbName[client_proto.USER_INFO_SUB_MSG_ID.UISMI_USERBASIC_GET_INFO_RESP] = User.Resp.GetUserInfo;
// 设置用户数据
CmdToPbName[client_proto.USER_INFO_SUB_MSG_ID.UISMI_USER_SET_INFO_RESP] = User.Resp.SetUserInfo;
// 设置用户语言
CmdToPbName[client_proto.USER_INFO_SUB_MSG_ID.UISMI_USER_SET_LANG_RESP] = User.Resp.SetLangCode;
// 设置支付密码
//@ts-ignore
CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_BIND_PASSWORD_RESP] = User.Resp.BindPassword;
// 上报数据
CmdToPbName[client_proto.LOGIN_SUB_MSG_ID.LSMI_REPORT_REG_DATA_RESP] = User.Resp.Report;
// 测试用 重置支付密码
//@ts-ignore
CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_RESET_PASSWORD_RESP] = User.Resp.ResetPassword;

// 踢账户
CmdToPbName[agent.AGENT_CMD.AGENT_CMD_KICK] = User.Resp.KickPush;
