import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { Asset } from "@/const";

export namespace AssetService {
  export const reqAsset = (uid: number) => {
    return new Promise((resolve, reject) => {
      BrowserWsClient.instance.handleSendData({
        route: Asset.Req.Asset,
        dsttype: 7,
        cmd: 1450,
        data: {
          uid,
        },
      });
    });
  };
}
