import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { client_proto } from "@/utils/net/shared/proto";
import { PURGE } from "redux-persist";

type EmailState = {
  unReadInfo: client_proto.IUnReadInfoResp;
  mailList: client_proto.IMailListResp;
  mailDelete: client_proto.IMailDeleteListResp;
  mailReceive: client_proto.IMailReceiveListResp;
  mailMarkRead: client_proto.IMailMarkReadResp;
  mailInfoPush: client_proto.IMailInfoPush;
  mailData: client_proto.IMailInfo[];
};

const initialState: EmailState = {
  unReadInfo: {},
  mailList: {
    mail_list: [],
  },
  mailDelete: {},
  mailReceive: {},
  mailMarkRead: {},
  mailInfoPush: {},
  mailData: [],
};

export const emailSlice = createSlice({
  name: "email",
  initialState,
  reducers: {
    setUnReadInfo: (state, action: PayloadAction<EmailState["unReadInfo"]>) => {
      const { payload } = action;
      state.unReadInfo = payload;
    },

    setMailList: (state, action: PayloadAction<EmailState["mailList"]>) => {
      const { payload } = action;
      state.mailList = payload;
    },

    setMailDelete: (state, action: PayloadAction<EmailState["mailDelete"]>) => {
      const { payload } = action;
      state.mailDelete = payload;
    },

    setMailReceive: (state, action: PayloadAction<EmailState["mailReceive"]>) => {
      const { payload } = action;
      state.mailReceive = payload;
    },

    setMailMarkRead: (state, action: PayloadAction<EmailState["mailMarkRead"]>) => {
      const { payload } = action;
      state.mailMarkRead = payload;
    },

    setMailInfoPush: (state, action: PayloadAction<EmailState["mailInfoPush"]>) => {
      const { payload } = action;
      state.mailInfoPush = payload;
    },
    setMailData: (state, action) => {
      const { payload } = action;
      state.mailData = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<EmailState>) => {
    builder.addCase(PURGE, (state, action) => {
      return initialState;
    });
  },
});

export namespace EmailActions {
  export const {
    setUnReadInfo,
    setMailList,
    setMailDelete,
    setMailReceive,
    setMailMarkRead,
    setMailInfoPush,
    setMailData,
  } = emailSlice.actions;
}

export default emailSlice.reducer;
