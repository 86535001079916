import { clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace Guide {
  export const Req = {};
  export const Resp = {
    UserGuidePush: clientProtoPrefix("UserGuidePush"),
  };
}

CmdToPbName[client_proto.COMMON_MSG_SUB_ID.COMM_MSG_USER_GUIDE_PUSH] = Guide.Resp.UserGuidePush;
