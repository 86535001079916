import { Hall, Recommend } from "@/const";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto } from "@/utils/net/shared/proto";

export namespace HallService {
  /** 大厅banner */
  export const bannerHallReq = (uid: number, banner_level: number, language: string = "en") => {
    BrowserWsClient.instance.handleSendData({
      route: Hall.Req.BANNER_HALL,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_BANNER,
      cmd: client_proto.BANNER_INFO_SUB_MSG_ID.BANNER_MSG_ID_PULL_HALL,
      data: {
        uid,
        banner_level,
        language,
        plat_type: 1,
      },
    });
  };

  export const FacePullReq = (uid: number, scene_id: number = 1, language: string = "en") => {
    BrowserWsClient.instance.handleSendData({
      route: Recommend.Req.FacePull,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_FACE,
      cmd: client_proto.FACE_INFO_SUB_MSG_ID.FACE_MSG_ID_PULL,
      data: {
        uid,
        scene_id,
        language,
      },
    });
  };
}
