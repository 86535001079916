import { Renver } from "@/const";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto, transactions } from "@/utils/net/shared/proto";

export namespace RenverService {
  export const getRevenuerData = (uid: number, pos: number, size: number) => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_TRANSACTIONS;
    let cmd = transactions.TRANSACTIONS_CMD.TRANSACTIONS_CMD_GET_TRANSACTIONS;
    BrowserWsClient.instance.handleSendData({
      route: Renver.Req.INFO,
      dsttype,
      cmd,
      data: {
        uid,
        pos,
        size,
      },
    });
  };
}
