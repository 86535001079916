import { clientProtoPrefix } from "@/utils";
import { CmdToPbName } from "@/utils/net/INet";
import { client_proto } from "@/utils/net/shared/proto";
/**
 * @Description: 幸运转盘常量
 */
export namespace Spin {
  export const Req = {
    INFO: clientProtoPrefix("SpinInfoReq"),
    SPIN: clientProtoPrefix("SpinReq"),
    WITHDRAW: clientProtoPrefix("SpinWithdrawReq"),
    RECORD: clientProtoPrefix("SpinOrderLogReq"),
    CURDATA: clientProtoPrefix("SpinGetCurDataReq"),
    POSTER: clientProtoPrefix("SpinPosterReq"),
  };

  export const Resp = {
    INFO: clientProtoPrefix("SpinInfoResp"),
    SPIN: clientProtoPrefix("SpinResp"),
    WITHDRAW: clientProtoPrefix("SpinWithdrawResp"),
    RECORD: clientProtoPrefix("SpinOrderLogResp"),
    ADD: clientProtoPrefix("SpinRecordAddPush"),
    CURDATA: clientProtoPrefix("SpinGetCurDataResp"),
    POSTER: clientProtoPrefix("SpinPosterResp"),
  };
}

// 转盘信息
CmdToPbName[client_proto.SPIN_SUB_MSG_ID.SSMI_SPIN_INFO_RESP] = Spin.Resp.INFO;
// 转转盘
CmdToPbName[client_proto.SPIN_SUB_MSG_ID.SSMI_SPIN_RESP] = Spin.Resp.SPIN;
// 转盘提现
CmdToPbName[client_proto.SPIN_SUB_MSG_ID.SSMI_WITHDRAW_RESP] = Spin.Resp.WITHDRAW;
// 转盘记录
CmdToPbName[client_proto.SPIN_SUB_MSG_ID.SSMI_WITHDRAW_LOG_RESP] = Spin.Resp.RECORD;
// 转盘记录推送
CmdToPbName[client_proto.SPIN_SUB_MSG_ID.SSMI_RECORD_ADD_PUSH] = Spin.Resp.ADD;
// 转盘当前数据
CmdToPbName[client_proto.SPIN_SUB_MSG_ID.SSMI_GET_CUR_DATA_RESP] = Spin.Resp.CURDATA;
// 转盘海报
CmdToPbName[client_proto.SPIN_SUB_MSG_ID.SSMI_GET_SPIN_POSTER_RESP] = Spin.Resp.POSTER;
