import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const asideSlice = createSlice({
  name: "aside",
  initialState: {
    isAsideOpen: false,
  },
  reducers: {
    toggleAside: (state: any, action: PayloadAction<boolean>) => {
      const { payload } = action;
      state.isAsideOpen = payload;
    },
  },
});

export const { toggleAside } = asideSlice.actions;

export default asideSlice.reducer;
