import { Goods } from "@/const";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto, CmdToPbName } from "@/utils/net/INet";

export namespace GoodsService {
  /** 规则请求 */
  export const reqRules = (key: string, lang = "en") => {
    if (!key) return;
    const dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    const cmd = client_proto.COMMON_MSG_SUB_ID.COMM_MSG_GET_RULES_REQ;
    BrowserWsClient.instance.handleSendData({
      route: Goods.Req.RULES,
      dsttype,
      cmd,
      data: {
        key,
        lang,
      },
    });
  };
}
