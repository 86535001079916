import src from "scroll-into-view-if-needed";

const prefixUtils = (prefix: string) => (name: string) => `${prefix}${name}`;
const clientProtoPrefix = prefixUtils("client_proto.");
const assetProtoPrefix = prefixUtils("asset.");
const agetProtoPrefix = prefixUtils("agent.");
const vipbetredateProtoPrefix = prefixUtils("vipbetredate.");
const clientEventPrefix = prefixUtils("client_event-");

function isValidUrl(str: string) {
  try {
    new URL(str);
    return true;
  } catch (err) {
    return false;
  }
}

const shouldAddPrefix = (str: string) => {
  if (isValidUrl(str)) {
    return str;
  }

  if (str.startsWith("/")) {
    return process.env.NEXT_PUBLIC_CDN_RUL + str.slice(1);
  }

  return process.env.NEXT_PUBLIC_CDN_RUL + str;
};

const buildBackgroundUrl = (url: string) => {
  const imageExtensions = [".jpg", ".jpeg", ".png"];
  if (imageExtensions.some((ext) => url.endsWith(ext))) {
    const fileName = url.substring(0, url.lastIndexOf("."));
    const urls = [
      `url(${shouldAddPrefix(`${fileName}.avif`)})`,
      `url(${shouldAddPrefix(`${fileName}.webp`)})`,
      `url(${shouldAddPrefix(url)})`,
    ];
    return urls.join(",");
  }
  return `url(${shouldAddPrefix(url)})`;
};

const getBackgroundUrl = (url: string) => {
  const imageExtensions = [".jpg", ".jpeg", ".png"];
  if (imageExtensions.some((ext) => url.endsWith(ext))) {
    const fileName = url.substring(0, url.lastIndexOf("."));
    return [shouldAddPrefix(`${fileName}.avif`), shouldAddPrefix(`${fileName}.webp`), shouldAddPrefix(url)];
  }
  return [shouldAddPrefix(url)];
};

const cdnPrefix = (str: string) => {
  return shouldAddPrefix(str);
};

export {
  clientProtoPrefix,
  assetProtoPrefix,
  vipbetredateProtoPrefix,
  clientEventPrefix,
  prefixUtils,
  cdnPrefix,
  buildBackgroundUrl,
  getBackgroundUrl,
  agetProtoPrefix,
};
