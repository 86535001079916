
import g_tx01 from '@/assets/profile/g_tx01.inline.jpg'
import g_tx02 from '@/assets/profile/g_tx02.inline.jpg'
import g_tx03 from '@/assets/profile/g_tx03.inline.jpg'
import g_tx04 from '@/assets/profile/g_tx04.inline.jpg'
import g_tx05 from '@/assets/profile/g_tx05.inline.jpg'
import g_tx06 from '@/assets/profile/g_tx06.inline.jpg'
import g_tx07 from '@/assets/profile/g_tx07.inline.jpg'
import g_tx08 from '@/assets/profile/g_tx08.inline.jpg'
import g_tx09 from '@/assets/profile/g_tx09.inline.jpg'
import g_tx00 from '@/assets/profile/phb_ques.inline.png'



export function getAvatarUrl(id: number | string | undefined | null) {

    // let AvatarList = [] as any
    // for (let i = 1; i < 10; i++) {
    //     AvatarList[i] = cdnPrefix(`/profile/g_tx0${i}.jpg`)
    // }

    let AvatarList = [g_tx00, g_tx01, g_tx02, g_tx03, g_tx04, g_tx05, g_tx06, g_tx07, g_tx08, g_tx09]

    if (!id) return AvatarList[1]
    return AvatarList[Number(id) || 0]
}