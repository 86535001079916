import mitt from "mitt";

const emitter = mitt();

class EventGlobalManager {
  static _instance: EventGlobalManager;

  static get instance() {
    if (!this._instance) {
      this._instance = new EventGlobalManager();
    }
    return this._instance;
  }

  emit(event: string, data: any) {
    emitter.emit(event, data);
  }

  on(event: string, callback: any) {
    emitter.on(event, callback);
  }

  off(event: string, callback: any) {
    emitter.off(event, callback);
  }

  once(event: string, callback: any) {
    emitter.on(event, callback);
  }

  clear() {
    emitter.all.clear();
  }
}

export default EventGlobalManager.instance;
