import { Recommend } from "@/const";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto } from "@/utils/net/INet";

export namespace RecommendService {
  /** :拍脸图请求 */
  export const reqFacePull = (uid: number, scene_id: number, language?: string) => {
    console.log("拍脸图请求==>位置>>>", scene_id);
    let parm = {
      uid,
      scene_id,
      language: language || "en",
    };
    BrowserWsClient.instance.handleSendData({
      route: Recommend.Req.FacePull,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_FACE,
      cmd: client_proto.FACE_INFO_SUB_MSG_ID.FACE_MSG_ID_PULL,
      data: parm,
    });
  };
}
