import { client_proto } from "@/utils/net/shared/proto";
import { createSlice } from "@reduxjs/toolkit";

type SpinState = {
  info: client_proto.ISpinInfoResp;
  spin: client_proto.ISpinResp;
  withdraw: client_proto.ISpinWithdrawResp;
  record: client_proto.ISpinOrderLogResp;
  add: client_proto.ISpinRecordAddPush;
  curData: client_proto.ISpinGetCurDataResp;
  poster: client_proto.ISpinPosterResp;
};

const initialState: SpinState = {
  info: {},
  spin: {},
  withdraw: {},
  record: {},
  add: {},
  curData: {},
  poster: {},
};

const spinSlice = createSlice({
  name: "spin",
  initialState: initialState,
  reducers: {
    setInfo: (state, action) => {
      const { payload } = action;
      state.info = payload;
    },
    setSpin: (state, action) => {
      const { payload } = action;
      state.spin = payload;
    },
    setWithdraw: (state, action) => {
      const { payload } = action;
      state.withdraw = payload;
    },
    setRecord: (state, action) => {
      const { payload } = action;
      state.record = payload;
    },
    setAdd: (state, action) => {
      const { payload } = action;
      state.add = payload;
    },
    setCurData: (state, action) => {
      const { payload } = action;
      state.curData = payload;
    },
    setPoster: (state, action) => {
      const { payload } = action;
      state.poster = payload;
    },
  },
});

export namespace SpinActions {
  export const { setInfo, setSpin, setWithdraw, setRecord, setAdd, setCurData, setPoster } = spinSlice.actions;
}
export default spinSlice.reducer;
