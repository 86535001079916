import { createSlice } from "@reduxjs/toolkit";

type LoadState = {
  webSocketReconnect: boolean;
};

const initialState: LoadState = {
  webSocketReconnect: false,
};

const loadSlice = createSlice({
  name: "load",
  initialState,
  reducers: {
    setWebSocketReconnect(state, action) {
      state.webSocketReconnect = action.payload;
    },
  },
});

export namespace LoadActions {
  export const { setWebSocketReconnect } = loadSlice.actions;
}

export default loadSlice.reducer;
