import { clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace Activity {
  export const Req = {
    /** 活动列表 */
    ActivityList: clientProtoPrefix("ActivityListReq"),

    /** 创建订单 */
    RechargeCreatOrder: clientProtoPrefix("GoodsGiftCreatOrderReq"),

    ActivityDetail: clientProtoPrefix("LookEventDetailReq"),
    /** 查看详情事件 */
    LookEventDetail: clientProtoPrefix("LookEventDetailReq"),
    /** 累充摇奖 */
    TotalRechargeDrawSpin: clientProtoPrefix("AccumulatedChargeLottryReq"),

    /** 累充标签解锁动画完成 */
    TotalRechargeUnLock: clientProtoPrefix("AccumulatedAnimationReq"),
    /** 累充通知广播 */
    TotalRechargeGetBroadcastMessage: clientProtoPrefix("AccumulatedChargeGetBroadcastMessageReq"),

    /** 累充历史记录 */
    TotalRechargeGetHistory: clientProtoPrefix("AccumulatedChargeLottryDataReq"),
  };

  export const Resp = {
    /** 活动列表 */
    ActivityList: clientProtoPrefix("ActivityListPush"),
    /** 单个活动详情 */
    ActivityItem: clientProtoPrefix("ActivityItemPush"),
    /** 查看详情事件 */
    LookEventDetail: clientProtoPrefix("LookEventDetailPush"),
    /** 创建订单结果 */
    RechargeCreatOrder: clientProtoPrefix("GoodsGiftCreatOrderResp"),
    /** 完成结果推送 */
    CompleteChargeOrder: clientProtoPrefix("CompleteChargeOrderPush"),
    /** 活动异常返回 */
    ActivityResultResp: clientProtoPrefix("ActivityResultResp"),

    /** 单个活动详情 */
    ActivityDetail: clientProtoPrefix("LookEventDetailPush"),

    ActivityItemPush: clientProtoPrefix("ActivityItemPush"),
    /** 累充摇奖 */
    TotalRechargeDrawSpin: clientProtoPrefix("AccumulatedChargeLottryResp"),
    /** 累充标签解锁状态变更推送 */
    TotalRechargeUnLock: clientProtoPrefix("AccumulatedSpinStatusPush"),
    /** 累充通知广播 */
    TotalRechargeGetBroadcastMessage: clientProtoPrefix("AccumulatedChargeGetBroadcastMessageResp"),

    /** 累充历史记录 */
    TotalRechargeGetHistory: clientProtoPrefix("AccumulatedChargeLottryHistoryResp"),
  };
}

CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_LIST_PUSH] = Activity.Resp.ActivityList;

CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_GIFT_CREATE_ORDER_RESP] = Activity.Resp.RechargeCreatOrder;

CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_COMPLETE_ORDER_PUSH] = Activity.Resp.CompleteChargeOrder;

CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_RESULT_RESP] = Activity.Resp.ActivityResultResp;

CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_LOOKEVENT_DETAIL_PUSH] = Activity.Resp.ActivityDetail;

CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_ITEM_PUSH] = Activity.Resp.ActivityItemPush;
CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_ITEM_PUSH] = Activity.Resp.ActivityItem;
CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_LOOKEVENT_DETAIL_PUSH] = Activity.Resp.LookEventDetail;
CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_ACCUMULATED_CHARGE_REWARD_RESP] =
  Activity.Resp.TotalRechargeDrawSpin;
CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_ACCUMULATED_CHARGE_STATUS_PUSH] =
  Activity.Resp.TotalRechargeUnLock;
CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_ACCUMULATED_CHARGE_GET_MESSAGE_RESP] =
  Activity.Resp.TotalRechargeGetBroadcastMessage;
CmdToPbName[client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_ACCUMULATED_CHARGE_GET_HISTORY_RESP] =
  Activity.Resp.TotalRechargeGetHistory;

/** 活动常量 */
export const ActivityConstant = {
  /** 活动位置 */
  ShowPos: {
    /** 活动列表 */
    ACTIVITY: "1",
    /** 钱包 */
    WALLET: "2",
  },

  /** 礼包类型 */
  Type: {
    TYPE_NONE: 0,
    GOODS_GIFT: 1, //商品类礼包
    TASK_GIFT: 2, //任务类礼包
    JUMP: 3, //直接跳转
    TOTAL_RECHARGE: 4, //累充任务类
    REGRESS_GIFT: 5, //回归礼包类
    REGRESS_WELFARE: 6, //回归福利类
  },

  /** 活动状态 */
  Status: {
    NONE: 0, //未开始
    START: 1, //进行中
    FINISH: 2, //已参与
    END: 3, //已结束
  },

  //礼包类错误码
  GOODSGIFT_STATUS: {
    ACTIVITY_GOODSGIFT_NORMAL: 0, //正常
    ACTIVITY_GOODSGIFT_SOLDOUT: 1001, //礼包已售完
    ACTIVITY_GOODSGIFT_PURCHASELIMIT: 1002, //礼包超购
    ACTIVITY_GOODSGIFT_OVER: 1003, //活动已结束
    ACTIVITY_GOODSGIFT_NOTORDER: 1004, //非礼包类活动下单
    ACTIVITY_REGRESSGIFT_NOTORDER: 1005, //非回归礼包类活动下单
    ACTIVITY_REGRESSGIFT_NOTCONFORM: 1006, //回归礼包类活动下单不符合购买条件
  },

  //累充类错误码
  TOTAL_STATUS: {
    SUCCESS: 0, //
    ACTIVITY_NOT_EXISTENT: 1, //活动不存在
    SUB_NOT_EXISTENT: 2, //子活不存在
    NO_LOTTERYCOUNT: 3, //次数不够
    ACTIVITY_CLOSE: 4, //活动已关闭或已过期
  },

  /** 时间显示状态 */
  TimeShowFlag: {
    /** 显示 */
    Show: 1,
    /** 隐藏 */
    Hide: 2,
  },

  /** 时间显示类型 */
  TimeShowType: {
    /** 永久 */
    Long: 1,
    /** 指定时间starttime-endtime */
    PointTime: 2,
    /** -每周 [1,2,3,4] */
    PointWeekDay: 3,
  },

  /** 点击类型 */
  ClickType: {
    /** 直接购买 */
    Buy: 1,
    /** 打开详情 */
    GotoDetail: 2,
  },

  /** 累充活动解锁状态 */
  TurnTableStatus: {
    /** 未解锁 */
    UnLock: 0,
    /** 已解锁有动画 */
    LockedHasAni: 1,
    /** 已解锁无动画 */
    LockedNotAni: 2,
  },
  /** 累充活动奖励物品类型 */
  TurnTableProp: {
    /** 充值金 */
    PROP_AMOUNT: 1,
    Cash: 2,
    HuangTong: 3,
    BaiYin: 4,
    HuangJin: 5,
    Special: 6,
  },

  /** 任务状态 */
  ITaskStatus: {
    TASK_NONE: 0, //初始状态
    TASK_START: 1, //进行中
    TASK_FINISH: 2, //已完成
    TASK_RECEIVED: 3, //已领取
  },
};
export namespace ActivityInterface {
  /** 基础内容 */
  export interface IActivityBaseJson {
    /** 活动排序 */
    sort: number;
    /** 展示位置 */
    show_pos: Array<number>;
    /** 角标 0:无 1：hot 2:new  */
    sub_corner: number;
    /** 活动列表图 */
    banner_url: string;
    /** 商城入口图 */
    shop_url: string;
    /** 点击跳转 */
    click_type: number;
    /** 二级详情页面底部文案 */
    second_text: string;
    /** 跳转参数 */
    jump_params: string;
    /** 详情页标题图 */
    detail_url: string;
    /** 详情页按钮文案 */
    btn_text: string;
  }
  /** 基础内容 */
  export interface IActivityTypeJson {
    /** 礼包类活动消失处理  1:展示至结束，2:玩家可买数量为0，3:总数为0 */
    activity_vanish: number;
    /** 商品列表 */
    goodslist: Array<any>;
  }
  /** 累充转盘基础内容 */
  export interface IActivityTurnTableData {
    /** 转盘ID */
    id: number;
    /** 转盘类型：1：黄铜 2：白银,3：黄金 4:特殊 */
    spin_type: number;
    /** 排序 */
    sort: number;
    /** 转盘标签 */
    icon: string;
    /** 转盘底图 */
    img: string;
    /** 转盘底图(外轮) */
    out_img: string;
    /** 转盘底图(指针) */
    button_img: string;
    /** 解锁条件文案 */
    tip_notlock: string;
    /** 参与条件 */
    conditon_content: Array<{
      /** 累充金额 */
      recharge: number;
      /** 奖励次数 */
      count: number;
    }>;
    /** 奖励内容 */
    reward_content: Array<{
      /** 奖励类型 ActivityTurnTableProp */
      reward_type: number;
      /** 奖励数量 */
      reward_value: number;
      /** 图片地址 */
      img: string;
    }>;
  }
}
