import { clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace Email {
  export const EMAIL_MSG_ID_UNREAD_INFO = 2101;
  export const EMAIL_MSG_ID_UNREAD_INFO_RESP = 2102;

  export const Req = {
    /** 未读消息 */
    UnReadInfo: clientProtoPrefix("UnReadInfoReq"),
    /** 邮件列表 */
    MailList: clientProtoPrefix("MailListReq"),
    /** 邮件删除 */
    MailDelete: clientProtoPrefix("MailDeleteListReq"),
    /** 附件领取 */
    MailReceive: clientProtoPrefix("MailReceiveListReq"),
    /** 标记已读 */
    MailMarkRead: clientProtoPrefix("MailMarkReadReq"),
  };

  export const Resp = {
    UnReadInfo: clientProtoPrefix("UnReadInfoResp"),
    /** 邮件列表 */
    MailList: clientProtoPrefix("MailListResp"),
    /** 邮件删除 */
    MailDelete: clientProtoPrefix("MailDeleteListResp"),
    /** 附件领取 */
    MailReceive: clientProtoPrefix("MailReceiveListResp"),
    /** 标记已读 */
    MailMarkRead: clientProtoPrefix("MailMarkReadResp"),
  };

  export const Push = {
    /** 主动推送信息到客户端 */
    MailInfoPush: clientProtoPrefix("MailInfoPush"),
  };
}

export const ContentType = {
  /** 排行榜报告-天 1 */
  RankReportDay: 1,
  /** 站内信 2 */
  SystemEmail: 2,
  /** 排行榜报告-周 3 */
  RankReportWeek: 3,
  /** 排行榜报告-月 4 */
  RankReportMonth: 4,
  /** 代理排行榜 */
  AgentRewardRank: 5,
};

/** 资产类型（pb定义） */
export const PropertyType = {
  PROP_NULL: 0,
  /** 充值金 */
  PROP_AMOUNT: 1,
  /** 提现金 */
  PROP_COIN: 2,
  /** 总下注 */
  PROP_TOTALBET: 3,
  /** 总充值 */
  PROP_TOTALRECHARGE: 4,
  /** 充值金+提现金 */
  PROP_BALANCE: 5,
  /** 提现限额 */
  PROP_WITHDRAW_LIMIT: 6,
  /** 钻石 */
  PROP_DIAMOND: 7,
  /** 体验币 */
  PROP_EXP: 8,
  /** 练习币 */
  PROP_PRT: 9,
};

export const ReadState = {
  /** 未读 */
  None: 0,
  /** 已读 */
  Readed: 1,
};
/** 附件状态 */
export const AttachmentState = {
  /** 未领 */
  None: 0,
  /** 已领取 */
  Received: 1,
};

export const EmailType = {
  /** 个人消息 */
  Personal: 1,
  /** 平台邮件 */
  Platform: 2,
};

//附件最大保留天数
export const MAX_ATTACHMENT_DAY = 31;

//单次请求邮件数量
export const MAX_MAIL_COUNT = 8;

//邮件相关
CmdToPbName[client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_UNREAD_INFO_RESP] = Email.Resp.UnReadInfo;

CmdToPbName[client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_MAIL_LIST_RESP] = Email.Resp.MailList;

CmdToPbName[client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_DELETE_LIST_RESP] = Email.Resp.MailDelete;

CmdToPbName[client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_RECEIVE_LIST_RESP] = Email.Resp.MailReceive;

CmdToPbName[client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_MARK_READ_RESP] = Email.Resp.MailMarkRead;

CmdToPbName[client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_MAIL_INFO_PUSH] = Email.Push.MailInfoPush;
