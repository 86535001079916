import protoRoot from "./shared/proto.js";
import { cloneDeep, get } from "lodash-es";
import out from "./buffer/buffer.js";
import { CmdToPbName, NetMsg } from "./INet";
import WebSocketNodeClient from "ws";
import { Login, User } from "@/const";

import EventGlobalManager from "../events/EventGlobalManager";

/** 心跳协议 */
const HeartBeat = {
  req: "agent.HeartBeat",
  resp: "agent.HeartBeat",
};

type Router = string;
type DstType = number;
type Cmd = number;
type Data = any;
type Force = boolean;

export default class WsBase {
  private _connectUrl: string = "";
  private _uid!: number;
  private _sendQueue: [Router, DstType, Cmd, Data, Force][] = []; // 发送队列

  // 用于存放发送心跳的定时器
  private _pingTimeOut!: NodeJS.Timeout | null;
  private _relinkNumber: number = 3;

  private _isLoginReady: boolean = false;

  public get connectUrl() {
    return this._connectUrl;
  }

  public set connectUrl(url: string) {
    this._connectUrl = url;
  }

  public get uid() {
    return this._uid;
  }

  public set uid(uid: number) {
    this._uid = uid;
  }

  public get isLoginReady() {
    return this._isLoginReady;
  }

  public set isLoginReady(isLoginReady: boolean) {
    this._isLoginReady = isLoginReady;
  }

  public getSendQueue() {
    return this._sendQueue;
  }

  private sendAsyncMessage() {
    setTimeout(() => {
      // console.log(this._sendQueue);
      this.handleSendQueue();
    }, 100);
  }

  public setSendQueue(route: string, dsttype: number, cmd: number, data: any, force: boolean = false) {
    this._sendQueue.push([route, dsttype, cmd, data, force]);
    if (this._sendQueue.length > 0) {
      this.sendAsyncMessage();
    }
  }

  public handleSendQueue() {
    if (this.getSocket()?.readyState !== WebSocket.OPEN || !this._isLoginReady) {
      if (this._sendQueue.length > 0) {
        this.sendAsyncMessage();
      }
      return;
    }

    if (this._sendQueue.length > 0) {
      let msg;
      const loginRouter = this._sendQueue.findIndex((item) => item[0] === Login.Req.LoginReq);

      if (loginRouter > -1) {
        msg = this._sendQueue[loginRouter];
        this._sendQueue.splice(loginRouter, 1);
      } else {
        if (!this._uid) {
          this.sendAsyncMessage();
          return;
        }
        msg = this._sendQueue.shift();
      }

      if (msg) {
        const [route, dsttype, cmd, data, force] = msg;
        const buf = this.sendEncode(route, dsttype, cmd, data, Number(force));
        console.log(`send ${route} buf ${buf}`);
        this.getSocket().send(buf);
        if (this._sendQueue.length > 0) {
          this.sendAsyncMessage();
        }
      }
    }
  }

  // 获取socket
  public getSocket(): WebSocketNodeClient | WebSocket {
    throw new Error("Method not implemented.");
  }

  public setSocket(socket: WebSocket | WebSocketNodeClient | null) {
    throw new Error("Method not implemented.");
  }

  public connect(url: string) {
    throw new Error("Method not implemented.");
  }

  /**
   * 接收消息
   * @param data
   * @returns
   */
  public recvRsp(data: ArrayBuffer) {
    // step1: 读取消息头
    const dataView = new DataView(data);
    let offset = 0;

    // 读取并解析数据包头部信息
    const magic1 = dataView.getUint8(offset++);
    const magic2 = dataView.getUint8(offset++);
    const ver = dataView.getUint8(offset++);
    const crc = dataView.getUint8(offset++);

    // 以下的get方法默认都是大端字节序
    const cmd = dataView.getUint32(offset, false);
    offset += 4;
    const dsttype = dataView.getUint16(offset, false);
    offset += 2;
    const srctype = dataView.getUint16(offset, false);
    offset += 2;
    const dstid = dataView.getUint32(offset, false);
    offset += 4;
    const srcid = dataView.getUint32(offset, false);
    offset += 4;
    const seq = dataView.getUint32(offset, false);
    offset += 4;
    const bitflag = dataView.getUint32(offset, false);
    offset += 4;
    const datalen = dataView.getUint32(offset, false);
    offset += 4;

    // step2: 读取出来msgBuf;
    const msgBuf: Uint8Array = new Uint8Array(data, offset, datalen);

    const newMsgBuf = this.encryptData(msgBuf);
    console.log("current cmd:", cmd);
    if (!CmdToPbName[cmd]) {
      console.log("CmdToPbName Error:cmd = ", cmd);
      return;
    }

    let route = CmdToPbName[cmd];
    const msg = this.protoDecode(route, newMsgBuf.buffer);

    if (route === HeartBeat.resp) {
      this.receiveHeartBeat(msg);
      return;
    }

    // 确定路由类型（取路由中包含的 'Resp'、'Push'、'Ntf' 之一）
    let routeType = ["Resp", "Ntf", "Push"].find((type) => route.includes(type));
    let netmsg: NetMsg<any> | undefined = { route, data: msg };
    if (!routeType) {
      routeType = "Resp";
      route = route + "Resp";
    }
    if (netmsg) {
      console.log(route, `[${routeType.toUpperCase()}-${route}]: `, cloneDeep(netmsg));
    }

    return netmsg;
  }

  public protoEncode(route: string, body: any): out.Buffer {
    const Req = get(protoRoot, route) || get(protoRoot, route + "Req");
    return out.Buffer.from(Req.encode(body).finish()); // Uint8Array
  }

  public protoDecode(route: string, data: ArrayBuffer): any {
    let Resp = get(protoRoot, route) || get(protoRoot, route + "Resp");
    if (data instanceof ArrayBuffer) {
      let outBuf: out.Buffer | Uint8Array = out.Buffer.from(data);

      if (outBuf instanceof out.Buffer) {
        outBuf = new Uint8Array(outBuf.buffer, outBuf.byteOffset, outBuf.length);
      }

      let res = Resp.decode(outBuf);
      // console.log("老数据", res.toJSON());
      this.modifyDefaultBody(res);
      // console.log("新数据", res);
      return res;
    }
    return data;
  }

  public sendEncode(route: string, dsttype: number, cmd: number, data: Record<string, any>, seq = 0) {
    let originRoute = route;
    if (route !== HeartBeat.req) {
      if (!route.endsWith("Req")) originRoute = originRoute + "Req";
      console.log(originRoute, `[REQ-${originRoute}]: `, data);
    }

    // step1: 数据data --->二进制
    let msgBuf = this.protoEncode(route, data); // Uint8Array

    if (msgBuf === null) {
      console.log("msgBuf is Null");
      return "";
    }

    // 这里的DataView和Uint8Array可以理解为容器，buf可以理解为我们的内存,DataView可以用int16,int32操作buf,使用Uint8Array，set可以大规模的复制数据
    // step2: 打入消息头长度=32
    var packHeadLength = 32;
    var m_datalen = msgBuf.length; // 消息体原始长度 buf_len(4字节)
    var total_len = packHeadLength + m_datalen; // 消息总长度 total_len(2字节) (消息头+消息体）
    var buf = new ArrayBuffer(total_len);
    let offset = 0;

    var m_magic = new Uint8Array([0xfe, 0x01]); // 使用Uint8Array存储魔数
    var m_ver = 0; // 版本号
    var m_crc = 0; // 防止改包校验，默认0
    var m_cmd = cmd; // 命令字
    var m_dstid = 0; // 方便转发 *(动态数据)
    if (data.dstid) {
      m_dstid = data.dstid;
    }
    console.log("send m_srcid -------------", this._uid, "and dsttype:", dsttype, cmd);
    var m_srcid = this._uid || 0; //Cache.User.getUID() || 0 // 默认用户id
    var m_seq = seq; // 序列号,默认0
    var m_bitflag = 1; // 控制用,1表示加密
    var m_dsttype = dsttype; // 目标服务器类型
    var m_srctype = 1; // 源

    var dataview = new DataView(buf);
    dataview.setUint8(offset++, m_magic[0]);
    dataview.setUint8(offset++, m_magic[1]);
    dataview.setUint8(offset++, m_ver);
    dataview.setUint8(offset++, m_crc);
    dataview.setUint32(offset, m_cmd);
    offset += 4;
    dataview.setUint16(offset, m_dsttype);
    offset += 2;
    dataview.setUint16(offset, m_srctype);
    offset += 2;
    dataview.setUint32(offset, m_dstid);
    offset += 4;
    dataview.setUint32(offset, m_srcid);
    offset += 4;
    dataview.setUint32(offset, m_seq);
    offset += 4;
    dataview.setUint32(offset, m_bitflag);
    offset += 4;
    dataview.setUint32(offset, m_datalen);
    offset += 4;

    // step3: 打入msgBuf
    var uint8Buf = new Uint8Array(buf, offset);
    var newMsgBuf = this.encryptData(msgBuf);
    uint8Buf.set(newMsgBuf);

    return buf;
  }

  /** 增加默认数据 */
  public modifyDefaultBody(obj: any) {
    if (obj == null) {
      return null;
    }
    if (typeof obj != "object") {
      return obj;
    }
    let self = this;
    if (obj instanceof Array) {
      obj.forEach((value, key) => {
        if (typeof value != "function") {
          if (value != null && typeof value == "object") {
            if (value && value["offset"] == null && value["buffer"] == null) {
              self.modifyDefaultBody(value);
            }
          }
        }
      });
      return obj;
    }
    let defaultKeys = Object.keys(Object.getPrototypeOf(obj));
    defaultKeys.forEach((key) => {
      let value = obj[key];
      // console.log("输出Key:", key, "输出value:", value, "输出类型:", typeof (value))
      if (typeof value != "function") {
        if (value != null && typeof value == "object") {
          if (value && value["offset"] != null && value["buffer"] != null) {
            obj[key] = value;
          } else {
            value && self.modifyDefaultBody(value);
          }
        } else {
          // console.log(`key:${key} value：`, value)
          obj[key] = value;
        }
      }
    });
    return obj;
  }

  // 加解密算法
  public encryptData(data: Uint8Array) {
    // 加解密 Key
    const ENCRYPT_KEY: string = "EFMNJQKW";
    const dataLength = data.length;
    let uindex = 0;
    const encryptedData = new Uint8Array(dataLength);

    for (let i = 0; i < dataLength; ++i) {
      const keyChar = ENCRYPT_KEY.charCodeAt(uindex); // 获取当前字符的ASCII码值
      encryptedData[i] = data[i] ^ keyChar; // 执行异或操作
      uindex = (uindex + 1) % ENCRYPT_KEY.length; // 更新索引
    }
    return encryptedData;
  }

  public startHeartbeat() {
    // 清除之前的定时器
    this.stopHeartbeat();
    this.asyncSendPing();
  }

  asyncSendPing() {
    this.sendPing(); // 发送心跳包
    this._pingTimeOut = setTimeout(() => {
      if (this._relinkNumber === 0) {
        console.log("relinkNumber End in...");
        EventGlobalManager.emit(User.Event.WeakNetWork, false);
        this.reconnect();
      } else {
        this._relinkNumber--;
        EventGlobalManager.emit(User.Event.WeakNetWork, true);
        this.asyncSendPing();
      }
    }, 10000); // 如果10秒收不到内容
  }

  sendPing() {
    console.log("Sending ping...");
    // 发送心跳包
    let data = { trans: Date.now() };
    const sendBuf = this.sendEncode(HeartBeat.req, 2, 1600, data);
    if (this.getSocket()?.readyState === WebSocket.OPEN) {
      this.getSocket().send(sendBuf);
    }
  }

  stopHeartbeat() {
    if (this._pingTimeOut) {
      clearTimeout(this._pingTimeOut);
    }
  }

  reconnect() {
    console.log("Attempting to reconnect...");
    this.stopHeartbeat();
    // 关闭旧的 WebSocket 连接
    if (this.getSocket()) {
      this.setSocket(null);
    }

    EventGlobalManager.emit(User.Event.WeakNetWork, false);
    EventGlobalManager.emit(User.Event.NetWork, true);
  }

  receiveHeartBeat(data: Record<string, any>) {
    if (data && data.trans != null) {
      let oldTime = Number(data.trans);
      if (oldTime > 0) {
        let curTime = Date.now();
        let delayTime = curTime - oldTime;
        console.log("receiveHeartBeat....", delayTime);
        // 收到消息，重置所有项目
        if (this._pingTimeOut) {
          clearTimeout(this._pingTimeOut);
        }
        this._relinkNumber = 3;

        EventGlobalManager.emit(User.Event.WeakNetWork, false);
        setTimeout(() => {
          this.asyncSendPing();
        }, 5000);
      }
    }
  }
}
