export namespace StoreKey {
  export const LOGIN_LAST_INFO = "LOGIN_LAST_INFO";
  export const CLIENT_IP = "CLIENT_IP";
  export const USER_LOGOUT = "USER_LOGOUT";
  export const EMAIL_MIN_ID = "EMAIL_MIN_ID";
  export const EMAIL_MIX_ID = "EMAIL_MIX_ID";
  export const EMAIL_LIST = "EMAIL_LIST";
  export const USER_TOKEN = "USER_TOKEN";
  export const USER_RANK = "USER_RANK";
  export const INVITE_CODE = "INVITE_CODE";
  export const SET_INFO_TYPE = "SET_INFO_TYPE";
  export const POP_MONTH_COUNT_DATA = "POP_MONTH_COUNT_DATA";
  export const TIMEOUT = "TIMEOUT";
  export const FIRST_CHARGE = "FIRST_CHARGE";
  export const LOGIN_DATA = "LOGIN_DATA";
  export const RECV_MESSAGE_LIST = "RECV_MESSAGE_LIST";
  export const SHOW_ACTIVITY_BUTTON = "SHOW_ACTIVITY_BUTTON";
  export const EARN_RANKING = "EARN_RANKING";
  export const IMG_TYPE = "IMG_TYPE";
}
