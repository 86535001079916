import * as R from "ramda";

// 是否服务器平台
export const isServer = typeof window === "undefined";
// 是否客户端平台
export const isClient = !isServer;

export const isHttps = () => {
  return window.location.protocol === "https:";
};
export const isHttp = () => {
  return window.location.protocol === "http:";
};

export const isWss = (url: string) => {
  // wss:// or ws://
  return /^wss?:\/\//.test(url);
};

export const shouldUnSetColor = (judgment: boolean, color: string) => {
  return judgment ? color : "unset";
};

export const isAndroid = (ua: string) => /Android/i.test(ua);
export const isIOS = (ua: string) => {
  return /iPhone|iPad|iPod/i.test(ua);
  // return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
};

export function isMobile() {
  let userAgent = navigator.userAgent.toLowerCase();
  return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile|Mobile/i.test(userAgent);
}
