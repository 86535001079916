import { CmdToPbName } from "@/utils/net/INet";
import { transactions } from "@/utils/net/shared/proto";

export namespace Renver {
  export const Req = {
    INFO: "transactions.TransactionsReq",
  };

  export const Resp = {
    INFO: "transactions.TransactionsResp",
  };
}

//总流水
CmdToPbName[transactions.TRANSACTIONS_CMD.TRANSACTIONS_CMD_GET_TRANSACTIONS_RESP] = Renver.Resp.INFO;
