import { Agent } from "@/const/agent";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto } from "@/utils/net/INet";

export namespace AgentService {
  const dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USER_PROXY;

  /**
   * 请求团队详情信息
   */
  export const requestTeamInfo = () => {
    const cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_TEAM_INFO_REQ;
    let data = {};
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.TEAM_INFO,
      dsttype,
      cmd,
      data,
    });
  };
  //检测邀请状态
  export const requestInviteState = (uid: number) => {
    BrowserWsClient.instance.uid = uid;
    const cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_CHECK_INVITE_STATUS_REQ;
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.CHECK_INVITESTATUS,
      dsttype,
      cmd,
      data: {
        uid,
      },
    });
  };

  /**
   * 请求代理界面数据
   */
  export const reqInfo = () => {
    const cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_INFO_REQ;
    let data = {};
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.INFO,
      dsttype,
      cmd,
      data,
    });
  };

  /**
   * 请求提现详情列表
   */
  export const reqWithdrawList = () => {
    let cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_WITHDRAW_LIST_REQ;
    let data = {};
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.WITHDRAW_LIST,
      dsttype,
      cmd,
      data,
    });
  };

  /**
   * 请求提现
   */
  export const reqWithdraw = () => {
    let cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_WITHDRAW_REQ;
    let data = {};
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.WITHDRAW,
      dsttype,
      cmd,
      data,
    });
  };

  /**
   * 请求团队详情信息
   */
  export const requestConfig = () => {
    let data = {};
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.CONFIG,
      dsttype,
      cmd: client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_AGENT_CONFIG_REQ,
      data,
    });
  };

  /**
   * 搜索下属详情信息
   */
  export const reqSubInfo = (userid: number, period: number, team_tab: number) => {
    let cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_SUB_INFO_REQ;

    let data = { search_userid: userid, period: Math.floor(period / 1000), team_tab: team_tab };

    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.USER_SUB_INFO,
      dsttype,
      cmd,
      data,
    });
  };

  /**
   * 请求团队下属列表
   * @param team_tab 代理页签
   * @param period 时间筛选
   * @param page_index 每页页码
   * @param sort_type 排序类型
   * @param sort_field 排序字段
   * @param force
   * @param page_size 每页条数
   */
  export const reqeamList = (
    team_tab: number,
    period: number,
    page_index: number = 1,
    sort_type: number = 1,
    sort_field: number = 1,
    force: boolean = true,
    page_size: number = 10,
  ) => {
    const cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_TEAM_LIST_REQ;
    const data = {
      page_index,
      page_size,
      period: Math.floor(period / 1000),
      sort_type,
      sort_field,
      team_tab,
    };
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.TEAM_LIST,
      dsttype,
      cmd,
      data,
      force,
    });
  };

  /**
   *  请求获取邀请奖励
   */
  export const requestInviteReward = () => {
    const cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_GET_INVITE_REWARD_REQ;
    const data = {};
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.INVITE_REWARD,
      dsttype,
      cmd,
      data,
    });
  };

  /**
   * 请求提现
   */
  export const requestWithdraw = () => {
    let cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_WITHDRAW_REQ;
    let data = {};
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.WITHDRAW,
      dsttype,
      cmd,
      data,
    });
  };

  /**
   * 请求佣金流水明细
   * @param source_type
   * @param page_index
   * @param page_size
   * @param end_time
   */
  export const requestCommissionList = (source_type: number, page_index: number, end_time: number, page_size = 30) => {
    let cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_COMMISSION_LIST_REQ;
    let data = {
      source_type: source_type,
      page_index: page_index,
      page_size: page_size,
      end_time: Math.floor(end_time / 1000),
    };

    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.COMMISSION_LIST,
      dsttype,
      cmd,
      data,
    });
  };

  export const requestWithdrawList = () => {
    let cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_WITHDRAW_LIST_REQ;
    let data = {};
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.WITHDRAW_LIST,
      dsttype,
      cmd,
      data,
      force: true,
    });
  };

  //检测邀请状态
  export const reqInviteState = (uid: number) => {
    BrowserWsClient.instance.uid = uid;
    const cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_CHECK_INVITE_STATUS_REQ;
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.CHECK_INVITESTATUS,
      dsttype,
      cmd,
      data: {
        uid,
      },
    });
  };

  /**
   *  请求获取邀请奖励
   */
  export const reqInviteReward = () => {
    const cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_GET_INVITE_REWARD_REQ;
    const data = {};
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.INVITE_REWARD,
      dsttype,
      cmd,
      data,
    });
  };

  /**
   * 请求分享邀请海报
   */
  export const requestInvite = () => {
    const cmd = client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_INVITE_REQ;
    const data = {};
    BrowserWsClient.instance.handleSendData({
      route: Agent.Req.INVITE,
      dsttype,
      cmd,
      data,
    });
  };
}
