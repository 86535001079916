import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { vipbetredate } from "@/utils/net/shared/proto";
import { PURGE } from "redux-persist";

type MyBonusState = {
  vipBetReadte: vipbetredate.IQuerylaimRewardResp;
  vipBetReadteReward: vipbetredate.IClaimRewardResp;
  rewardListPanelVisible: boolean;
};

const initialState: MyBonusState = {
  vipBetReadte: {},
  vipBetReadteReward: {},
  rewardListPanelVisible: false,
};

export const myBonusSlice = createSlice({
  name: "myBonus",
  initialState,
  reducers: {
    setVipBetReadte: (state, action) => {
      const { payload } = action;
      state.vipBetReadte = payload;
    },

    setVipBetReadteReward: (state, action) => {
      const { payload } = action;
      state.vipBetReadteReward = payload;
    },
    setRewardListPanelVisible: (state, action) => {
      const { payload } = action;
      state.rewardListPanelVisible = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<MyBonusState>) => {
    builder.addCase(PURGE, (state, action) => {
      return initialState;
    });
  },
});

export namespace MyBonusActions {
  export const { setVipBetReadte, setVipBetReadteReward, setRewardListPanelVisible } = myBonusSlice.actions;
}
export default myBonusSlice.reducer;
