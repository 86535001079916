import { HttpClient, SDK } from "@/utils";

export namespace GameService {
  export const version = "1.0.8";
  const config: Record<string, any> = new Map();

  export function getConfig() {
    return config;
  }

  export function setConfig(key: string, value: any) {
    config[key] = value;
  }

  // 热更检测
  export function httpUpdate() {
    let parm = {
      channel: "web",
      version: version,
      deviceId: SDK.device(),
    };

    return HttpClient.ajax({
      url: "clientVersion/checkUpdate",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(parm),
    });
  }
}
