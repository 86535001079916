import { client_proto, CmdToPbName } from "@/utils/net/INet";
import { game_manage_proto, tgame_proto, bridge_proxy_proto, gamealloc } from "@/utils/net/shared/proto";

export namespace ThirdGame {
  export const Req = {
    BRIDGE_SUB_GAME: "bridge_proxy_proto.LoginReq",
    TGAME_SUB_GAME: "tgame_proto.LoginReq",
    TGAME_SUB_GAME_LOGOUT: "tgame_proto.LogoutReq",
    GAME_HALL_DETAIL: "game_manage_proto.GameHallDetailReq",
    GAME_GROUP_DETAIL: "game_manage_proto.GameGroupDetailReq",
    GAME_GROUP_V1: "game_manage_proto.MultiLanguageGameGroupV1Req",
    GAME_GROUP_V1_DETAIL: "game_manage_proto.GameGroupDetailV1Req",
    GAME_GROUP_V2_DETAIL: "game_manage_proto.GameGroupDetailV2Req",
    GAME_PLATFORM: "gamealloc.AllocReq",
  };

  export const Resp = {
    BRIDGE_SUB_GAME: "bridge_proxy_proto.LoginResp",
    TGAME_SUB_GAME: "tgame_proto.LoginResp",
    TGAME_SUB_GAME_LOGOUT: "tgame_proto.LogoutResp",
    GAME_HALL_DETAIL: "game_manage_proto.GameHallDetailResp",
    BRIDGE_PROXY_PUSH_KICKOUT: "bridge_proxy_proto.KickOutPush", // 已作废
    TGAME_SUB_GAME_PUSH_KICKOUT: "tgame_proto.KickOutPush",
    GAME_GROUP_DETAIL: "game_manage_proto.GameGroupDetailResp",
    GAME_GROUP_V1: "game_manage_proto.MultiLanguageGameGroupV1Resp",
    GAME_GROUP_V1_DETAIL: "game_manage_proto.GameGroupDetailV1Resp",
    GAME_GROUP_V2_DETAIL: "game_manage_proto.GameGroupDetailV2Resp",
    GAME_FORBIT_PUSH: "client_proto.ForbitUserPush",
    GAME_PLATFORM: "gamealloc.AllocResp",
    TOP_BANNER_PUSH: "client_proto.GameBannerUserPush",
  };
}

// 大厅游戏列表，三方游戏
CmdToPbName[bridge_proxy_proto.RBRIDGE_PROXY_SUB_MSG_ID.BRIDGE_PROXY_LOGIN_RESP] = ThirdGame.Resp.BRIDGE_SUB_GAME;

CmdToPbName[tgame_proto.TGAME_SUB_MSG_ID.TGAME_LOGOUT_RESP] = ThirdGame.Resp.TGAME_SUB_GAME_LOGOUT;
CmdToPbName[game_manage_proto.GAME_MANAGE_SUB_MSG_ID.GAME_MANAGE_GAME_HALL_DETAIL_RESP] =
  ThirdGame.Resp.GAME_HALL_DETAIL;
CmdToPbName[game_manage_proto.GAME_MANAGE_SUB_MSG_ID.GAME_MANAGE_GAME_GROUP_DETAIL_RESP] =
  ThirdGame.Resp.GAME_GROUP_DETAIL;
CmdToPbName[game_manage_proto.GAME_MANAGE_SUB_MSG_ID.GAME_MANAGE_MULTI_LANG_GAME_GROUP_V1_RESP] =
  ThirdGame.Resp.GAME_GROUP_V1;
CmdToPbName[game_manage_proto.GAME_MANAGE_SUB_MSG_ID.GAME_MANAGE_GAME_GROUP_DETAIL_V1_RESP] =
  ThirdGame.Resp.GAME_GROUP_V1_DETAIL;
CmdToPbName[game_manage_proto.GAME_MANAGE_SUB_MSG_ID.GAME_MANAGE_GAME_GROUP_DETAIL_V2_RESP] =
  ThirdGame.Resp.GAME_GROUP_V2_DETAIL;
CmdToPbName[bridge_proxy_proto.RBRIDGE_PROXY_SUB_MSG_ID.BRIDGE_PROXY_KICKOUT_PUSH] =
  ThirdGame.Resp.BRIDGE_PROXY_PUSH_KICKOUT;
CmdToPbName[tgame_proto.TGAME_SUB_MSG_ID.TGAME_KICKOUT_PUSH] = ThirdGame.Resp.TGAME_SUB_GAME_PUSH_KICKOUT;

CmdToPbName[gamealloc.GAMEALLOC_CMD.GAMEALLOC_CMD_ALLOC_RESP] = ThirdGame.Resp.GAME_PLATFORM;
CmdToPbName[tgame_proto.TGAME_SUB_MSG_ID.TGAME_LOGIN_RESP] = ThirdGame.Resp.TGAME_SUB_GAME;

CmdToPbName[client_proto.GAME_BANNER_INFO_SUB_MSG_ID.GAME_BANNER_MSG_ID_USER_PUSH] = ThirdGame.Resp.TOP_BANNER_PUSH;
