import { transactions } from "@/utils/net/shared/proto";
import { createSlice } from "@reduxjs/toolkit";

type RenverState = {
  info: transactions.ITransactionsResp;
};

const initialState: RenverState = {
  info: {},
};

export const renverSlice = createSlice({
  name: "renver",
  initialState,
  reducers: {
    setInfo: (state, action) => {
      const { payload } = action;
      state.info = payload;
    },
  },
});

export namespace RenverActions {
  export const { setInfo } = renverSlice.actions;
}

export default renverSlice.reducer;
