const zh = require("./zh").default;
const pt = require("./pt").default;
const enIn = require("./en-IN").default;

const i18n = {
  zh,
  pt,
  "en-In": enIn,
};

export default i18n;
