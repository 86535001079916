import { ReactEventHandler } from "react";
import { cdnPrefix } from "@/utils/prefix";

export const imageLoadFadeIn: ReactEventHandler<HTMLImageElement> = (evt) => {
  "use client";
  const targetElement = Reflect.get(evt, "target");
  if (targetElement) {
    (targetElement as HTMLElement).classList.toggle("fadein-16ms-ease");
  }
};

export const loadImage = (url: string) =>
  fetch(url)
    .then((res) => res.blob())
    .then((blob) => URL.createObjectURL(blob));

export const styleImage = (url: string) => {
  return {
    backgroundImage: `url(${cdnPrefix(url)})`,
  };
};
