import { ExchangeCode } from "@/const";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto } from "@/utils/net/shared/proto";

export namespace ExchangeCodeService {
  /** 兑换码配置请求 */
  export const reqExchangeCodeConf = (language = "en") => {
    let param = {
      language,
    };
    BrowserWsClient.instance.handleSendData({
      route: ExchangeCode.Req.GiftGetShowPage,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GIFT,
      cmd: client_proto.GIFT_INFO_SUB_MSG_ID.GIFT_MSG_ID_GET_SHOW_PAGE_REQ,
      data: param,
    });
  };

  /** 兑换码兑换请求 */
  export const reqReceiveExchangeCode = (uid: number, codeKey: any, language = "en") => {
    codeKey = String(codeKey);
    let param = {
      uid,
      key: codeKey,
      language,
    };
    BrowserWsClient.instance.handleSendData({
      route: ExchangeCode.Req.OpenGift,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GIFT,
      cmd: client_proto.GIFT_INFO_SUB_MSG_ID.GIFT_MSG_ID_OPEN_GIFT_REQ,
      data: param,
    });
  };
}
