import { Wallet } from "@/const";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto } from "@/utils/net/INet";

export namespace WalletService {
  export const reqDepositInfo = () => {
    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Deposit.INFO,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO,
      cmd: client_proto.USER_WALLET_MSG_ID.UWSMI_GET_CHARGE_CONFIG_REQ,
      data: {},
    });
  };

  export const reqDepositOrder = (param: any) => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.USER_WALLET_MSG_ID.UWSMI_CREATE_ORDER_REQ;

    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Deposit.ORDER,
      dsttype,
      cmd,
      data: param,
    });
  };

  export const reqDepositRecord = () => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.USER_WALLET_MSG_ID.UWSMI_GET_CHARGE_LOG_REQ;

    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Deposit.RECORD,
      dsttype,
      cmd,
      data: {},
    });
  };

  export const reqDepositBanner = () => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.USER_WALLET_MSG_ID.UWSMI_GET_BANNER_LIST_REQ;

    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Deposit.BANNER,
      dsttype,
      cmd,
      data: {},
    });
  };

  /**
   * 获取提现列表信息
   */
  export const reqWithdrawInfo = () => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.USER_WALLET_MSG_ID.UWSMI_GET_WITHDRAW_CONFIG_REQ;

    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Withdraw.INFO,
      dsttype,
      cmd,
      data: {},
    });
  };

  /**
   * 设置银河卡
   * @param bank
   * @param acc
   * @param ifsc
   */
  export const reqWithdrawBank = (data: Record<string, any>) => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.USER_WALLET_MSG_ID.UWSMI_SET_BANKCARD_REQ;
    console.log("reqWithdrawBank", data);
    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Withdraw.SET_BANK,
      dsttype,
      cmd,
      data,
    });
  };

  export const reqWithdrawOrder = (id: any, code: any, pwd: any) => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.USER_WALLET_MSG_ID.UWSMI_CREATE_WITHDRAW_ORDER_REQ;

    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Withdraw.WITHDRAW,
      dsttype,
      cmd,
      data: {
        item_id: id,
        code: code,
        pwd,
      },
    });
  };

  export const reqWithdrawRecord = (period: number) => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.USER_WALLET_MSG_ID.UWSMI_GET_WITHDRAW_LOG_REQ;

    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Withdraw.INFO,
      dsttype,
      cmd,
      data: {
        period,
      },
    });
  };

  export const reqWithdrawLogReq = (period: number) => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.USER_WALLET_MSG_ID.UWSMI_GET_WITHDRAW_LOG_REQ;
    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Withdraw.RECORD,
      dsttype,
      cmd,
      data: {
        period,
      },
    });
  };

  export const requestDepositRecord = (period: number) => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.USER_WALLET_MSG_ID.UWSMI_GET_CHARGE_LOG_REQ;
    let parm: Record<string, any> = {
      period: period,
    };
    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Deposit.RECORD,
      dsttype,
      cmd,
      data: parm,
    });
  };

  /**
        from 格式:{position}_{id}
        position: 1-banner, 2-banner详细, 3-礼包
        id： 1，2，3，4 礼包序号,从1开始
    */
  export const requestDeposit = (id: any, isChecked: boolean, banner: any, param?: any) => {
    let stype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let ctype = client_proto.USER_WALLET_MSG_ID.UWSMI_CREATE_ORDER_REQ;
    let parm: Record<string, any> = {
      charge_id: id,
      bonus_checked: isChecked,
      banner_goods: banner,
      order_params: param,
    };
    BrowserWsClient.instance.handleSendData({
      route: Wallet.Req.Deposit.ORDER,
      dsttype: stype,
      cmd: ctype,
      data: parm,
    });
  };
}
