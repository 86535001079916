import config from "@/locales";
import { get } from "lodash-es";
import { getCookie } from "cookies-next";

class I18n {
  private static _instance: I18n;
  _lane: string;
  _config: Record<string, any> | undefined;

  public static get instance() {
    if (!this._instance) {
      const language = getCookie("LANGUAGE");
      this._instance = new I18n(language || process.env.NEXT_PUBLIC_TZ!);
    }
    return this._instance;
  }

  constructor(defaultLocale: string) {
    this._lane = defaultLocale;
    this._config = config;
    // 模拟异步加载config
    //    Promise.resolve().then(() => {
    //      this._config = config;
    //    });
  }

  setLocale(locale: string) {
    this._lane = locale;
  }

  getLocale() {
    return this._lane;
  }

  translation(t: string, ...args: any[]) {
    const tt = t.split(".");
    if (args.length > 0) {
      // 替换占位符
      return get(this._config, [this._lane, ...tt], t).replace(/{(\d+)}/g, (match: any, number: number) => {
        return typeof args[number] !== "undefined" ? args[number] : match;
      });
    } else {
      return get(this._config, [this._lane, ...tt]);
    }
  }

  common(t: string, ...args: any[]) {
    return this.translation(`${t}`, ...args);
  }

  earn(t: string, ...args: any[]) {
    return this.translation(`${t}`, ...args);
  }

  share(t: string, ...args: any[]) {
    return this.translation(`${t}`, ...args);
  }

  login(t: string, ...args: any[]) {
    return this.translation(`${t}`, ...args);
  }

  spin(t: string, ...args: any[]) {
    return this.translation(`spin.${t}`, ...args);
  }

  rank(t: string, ...args: any[]) {
    return this.translation(`rank.${t}`, ...args);
  }

  home(t: string, ...args: any[]) {
    return this.translation(`home.${t}`, ...args);
  }

  vip(t: string, ...args: any[]) {
    return this.translation(`vip.${t}`, ...args);
  }
  shopping(t: string, ...args: any[]) {
    return this.translation(`shopping.${t}`, ...args);
  }
  withdrawal(t: string, ...args: any[]) {
    return this.translation(`withdrawal.${t}`, ...args);
  }
  wallet(t: string, ...args: any[]) {
    return this.translation(`wallet.${t}`, ...args);
  }
  game(t: string, ...args: any[]) {
    return this.translation(`${t}`, ...args);
  }

  gifts(t: string, ...args: any[]) {
    return this.translation(`gifts.${t}`, ...args);
  }

  notfiy(t: string, ...args: any[]) {
    return this.translation(`notfiy.${t}`, ...args);
  }

  profile(t: string, ...args: any[]) {
    return this.translation(`${t}`, ...args);
  }
  activiy(t: string, ...args: any[]) {
    return this.translation(`activity.${t}`, ...args);
  }
}

export default I18n.instance;
