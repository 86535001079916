import React from "react";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import "animate.css";

export type ToastProps = {
  type?: "success" | "error" | "info" | "loading";
  duration?: number;
  onSuccess?: () => void;
};

let toastContainer: HTMLDivElement[] = [];

const createWrap = () => {
  const wrap = document.createElement("div");
  wrap.className =
    "fixed z-[9999] !w-[85%] top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-full text-center animate__animated  animate__fadeIn";
  document.body.appendChild(wrap);
  return wrap;
};

const toast = (message: string, toastProps?: ToastProps) => {
  const { duration = 2000, onSuccess } = toastProps || {};

  toastContainer.forEach((element) => {
    element.className += " animate__fadeOut";
    element.remove();
  });

  const wrap = createWrap();
  toastContainer.push(wrap);

  setTimeout(() => {
    wrap.className += " animate__fadeOut";
    setTimeout(() => {
      wrap.remove();
      onSuccess && onSuccess();
    }, 1000);
  }, duration);

  return createRoot(wrap!).render(
    createPortal(
      <main>
        <span className="text-[0.3rem] text-[#f3f1a1] inline-block bg-[rgba(000,000,000,0.8)] rounded-[0.06rem] p-[0.08rem_0.32rem] text-center">
          {message}
        </span>
      </main>,
      wrap,
    ),
  );
};

export default toast;
