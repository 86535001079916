import { Login as LoginConst, Net } from "@/const";
import { StoreKey } from "@/const/store";
import { HttpClient, SDK, systemConfigUtils } from "@/utils";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { StorageData } from "@/utils/net/storage/StorageData";
import { GameService } from "./game";
import { EventGlobalManager } from "@/utils/events";
import { User } from "@/const/user";
import { client_proto } from "@/utils/net/shared/proto";

import { persistor } from "@/redux/store";
import { NetMsg } from "@/utils/net/INet";
import { get } from "lodash-es";
import { workerRef } from "@/worker/uiDelayTime.worker";

export namespace UserService {
  export type LoginReq = {
    login_token: string;
    lang: string;
    login_ip: string;
    client_version: string;
    os_version: string;
  };

  export const reqGetToken = () => {
    let parm: Record<string, any> = {
      channel: "web",
      adid: "",
      fbid: "",
    };
    return requestLogin(parm);
  };

  const requestLogin = (data: any) => {
    let parm: Record<string, any> = {
      channel: data.channel,
      adid: data.adid,
      fbid: data.fbid,
      device_id: SDK.device(),
      pkg: "",
      json: "",
    };

    return HttpClient.ajax({
      url: "login/loginGuest",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(parm),
    }).then((res) => {
      return respGetToken(res);
    });
  };

  const respGetToken = (rsp: any) => {
    const curLoginType = LoginConst.LoginType.LTD_GUEST;
    const token = rsp.data?.token;
    const serverList = SDK.tableVerify(rsp.data?.wss_list || Net.WS_URL, true);
    const curServer = serverList[0];
    const env = 2;
    BrowserWsClient.instance.connect(curServer);
    systemConfigUtils.cs_url = rsp?.data?.cs_url;

    const stringify = JSON.stringify({
      loginType: curLoginType,
      token,
      serverList,
      curServer,
      env,
    });

    StorageData.local.set(StoreKey.LOGIN_LAST_INFO, stringify);
    return token;
  };

  export const getLastlocalLoginToken = () => {
    const infoStore = StorageData.local.get(StoreKey.LOGIN_LAST_INFO);
    if (infoStore) {
      const info = JSON.parse(infoStore as string);
      return info.token || null;
    }
    return null;
  };

  export const Login = (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        const httpUpdateRsp = await GameService.httpUpdate();

        const cacheToken = StorageData.local.get(StoreKey.USER_TOKEN);

        if (cacheToken) {
          loginWithCache(httpUpdateRsp);
        } else {
          StorageData.local.remove(StoreKey.USER_RANK);
        }

        const stringify = cacheToken || (await reqGetToken());
        if (!stringify) {
          reject("stringify is null");
          return;
        }

        const { data: checkUpdateData } = httpUpdateRsp;
        const { client_ip } = checkUpdateData;
        GameService.setConfig(StoreKey.CLIENT_IP, client_ip);
        GameService.setConfig(StoreKey.USER_TOKEN, stringify);
        // 拿到token后，应该设置一下状态，要不无法发送信息
        BrowserWsClient.instance.isLoginReady = true;
        getUserInfo(stringify, client_ip);

        const oneTimeListener = (userInfo: any) => {
          EventGlobalManager.off(LoginConst.Resp.LoginResp, oneTimeListener);
          resolve(userInfo);
        };

        EventGlobalManager.on(LoginConst.Resp.LoginResp, oneTimeListener);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  };

  export const getUserInfo = (login_token: string, client_ip: string = "") => {
    BrowserWsClient.instance.handleSendData({
      route: LoginConst.Req.LoginReq,
      dsttype: 11,
      cmd: 901,
      data: {
        login_token,
        lang: StorageData.local.get("LAN_CODE", "") || "en",
        login_ip: client_ip,
        client_version: GameService.version,
        os_version: "",
      },
    });
  };

  /** AF上报 */
  export const AFReport = (data: {
    invite_code?: string;
    af_id?: string;
    fb_id?: number;
    channel?: string;
    fbc?: string;
    fbp?: string;
    ua?: string;
  }) => {
    let param = {
      invite_code: data.invite_code,
      af_id: data.af_id,
      fb_id: data.fb_id,
      channel: data.channel,
      fbc: data.fbc,
      fbp: data.fbp,
      ua: data.ua,
    };
    BrowserWsClient.instance.handleSendData({
      route: User.Req.Report,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_ACCOUNT,
      cmd: client_proto.LOGIN_SUB_MSG_ID.LSMI_REPORT_REG_DATA_REQ,
      data: param,
    });
  };

  export const reqSetUserInfo = (params: client_proto.ISetUserInfoReq) => {
    BrowserWsClient.instance.handleSendData({
      route: User.Req.SetUserInfo,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO,
      cmd: client_proto.USER_INFO_SUB_MSG_ID.UISMI_USER_SET_INFO_REQ,
      data: params,
    });
  };

  export const reqBindPassword = (params: any) => {
    BrowserWsClient.instance.handleSendData({
      route: User.Req.BindPassword,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO,
      //@ts-ignore
      cmd: client_proto.USER_WALLET_MSG_ID.UWSMI_BIND_PASSWORD_REQ,
      data: params,
    }); /*  */
  };

  export const reqResetPassword = (uid: number) => {
    BrowserWsClient.instance.handleSendData({
      route: User.Req.BindPassword,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO,
      //@ts-ignore
      cmd: client_proto.USER_WALLET_MSG_ID.UWSMI_RESET_PASSWORD_REQ,
      data: {
        uid,
      },
    });
  };

  export const sendSmsCode = (mobile_number: string) => {
    return HttpClient.ajax({
      url: `login/sendSms`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        adid: 1,
        channel: "web",
        device_id: SDK.device(),
        fbid: "",
        mobile_number,
        pkg: "",
        type: 1,
      }),
    });
  };

  export const guestBinding = ({ code, phone, uid }: { code: string; phone: string; uid: number }) => {
    const lastLoginToken = StorageData.local.get(StoreKey.USER_TOKEN);
    return HttpClient.ajax({
      url: `login/guestBinding`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: "web",
        code,
        device_id: SDK.device(),
        mobile_number: phone,
        token: lastLoginToken,
        uid,
      }),
    });
  };

  export const resetMobilePassword = ({ code, phone, password }: { code: string; phone: string; password: string }) => {
    return HttpClient.ajax({
      url: `login/sendSms`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: "web",
        code,
        mobile_number: phone,
        password,
      }),
    });
  };
  export const loginWithPassword = ({ phone, password }: { phone: string; password: string }) => {
    return HttpClient.ajax({
      url: `login/loginMobilePassword`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: "web",
        password,
        mobile_number: phone,
        device_id: SDK.device(),
        pkg: "",
        fbid: "",
      }),
    });
  };
  export const loginWithCode = ({ code, phone }: { phone: string; code: string }) => {
    return HttpClient.ajax({
      url: `login/loginMobile`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: "web",
        mobile_number: phone,
        device_id: SDK.device(),
        adid: 1,
        code,
        pkg: "",
        fbid: "",
      }),
    });
  };
  export const setPassword = ({ password, phone, code }: { password: string; phone: string; code: string }) => {
    return HttpClient.ajax({
      url: `login/resetMobilePassword`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: "web",
        code,
        mobile_number: phone,
        password,
      }),
    });
  };

  //推出登录
  export const onLogout = async () => {
    StorageData.local.set(StoreKey.USER_LOGOUT, true);
    StorageData.local.set(StoreKey.USER_TOKEN, "");
    //清空邮件
    StorageData.local.set(StoreKey.EMAIL_LIST, "");
    StorageData.local.set(StoreKey.EMAIL_MIX_ID, "0");
    //清空排行榜记录
    StorageData.local.remove(StoreKey.SHOW_ACTIVITY_BUTTON);
    StorageData.local.remove(StoreKey.EARN_RANKING);
    //清除缓存
    persistor.purge();
  };

  export const loginWithCache = (httpUpdateRsp: any) => {
    const str = StorageData.local.get(StoreKey.LOGIN_DATA) as string;
    const cacheToken = StorageData.local.get(StoreKey.USER_TOKEN);
    const { data: checkUpdateData } = httpUpdateRsp;
    const { client_ip } = checkUpdateData;
    try {
      const loginCacheData = JSON.parse(str);
      const { wss_list, cs_url } = loginCacheData;
      systemConfigUtils.cs_url = cs_url;
      BrowserWsClient.instance.connect(wss_list[0] || Net.WS_URL);
      getUserInfo(cacheToken as string, client_ip);
      EventGlobalManager.on(LoginConst.Resp.LoginResp, (rsp: NetMsg<client_proto.IGetUserBasicInfoResp>) => {
        const data = get(rsp, "data");
        const timestamp = get(data, "timestamp");
        // systemConfigUtils.delayTime = timestamp!;
        workerRef?.postMessage(timestamp!);
      });
    } catch (e) {
      console.error("解析登录缓存数据失败", e);
    }
  };

  export const loginWithGoogle = (params: { access_token: string; open_id: string; user_email: string | null }) => {
    return HttpClient.ajax({
      url: `login/loginGoogle`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel: "web",
        device_id: SDK.device(),
        adid: 1,
        pkg: "",
        fbid: "",
        json: "",
        ...params,
      }),
    });
  };

  export const logReport = (uid: string) => (channel: string) => (type: string) => {
    return HttpClient.ajax({
      url: `logReport/guestClick`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        channel,
        type,
        uid,
      }),
    });
  };
}
