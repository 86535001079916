import { client_proto } from "@/utils/net/shared/proto";
import { createSlice } from "@reduxjs/toolkit";

type ActivityState = {
  activityList: client_proto.IActivityListPush;
  rechargeCreatOrder: client_proto.IGoodsGiftCreatOrderResp;
  completeChargeOrder: client_proto.ICompleteChargeOrderPush;
  activityResultResp: client_proto.IActivityResultResp;
  showRegressActivty: {
    //回归活动
    id: number | string;
    type?: number; //拍脸图还是活动点击 1-点击 2-标识拍脸图  默认点击
    onCloseCallback?: () => void; //关闭回调
  } | null;
  activityItem: client_proto.IActivityItemPush;
  activityItemPush: client_proto.IActivityItemPush;
  lookEventDetail: client_proto.ILookEventDetailPush;
  totalRechargeDrawSpin: client_proto.IAccumulatedChargeLottryResp;
  totalRechargeUnLock: client_proto.IAccumulatedSpinStatusPush;
  totalRechargeGetBroadcastMessage: client_proto.IAccumulatedChargeGetBroadcastMessageResp;
  totalRechargeGetHistory: client_proto.IAccumulatedChargeLottryHistoryResp;
  lastSpin: string | number;
  appDownload: {
    target?: "";
    isVisible?: boolean;
    buttonIsVisible: boolean;
  } | null;
};

const initialState: ActivityState = {
  activityList: {
    list: [],
  },
  lastSpin: "",
  rechargeCreatOrder: {},
  completeChargeOrder: {},
  activityResultResp: {},
  activityItem: {},
  lookEventDetail: {},
  totalRechargeDrawSpin: {},
  totalRechargeUnLock: {},
  totalRechargeGetBroadcastMessage: {},
  totalRechargeGetHistory: {},
  activityItemPush: {},
  appDownload: {
    buttonIsVisible: true,
  },
  showRegressActivty: null,
};

export const activitySlice = createSlice({
  name: "activity",
  initialState,
  reducers: {
    setActivityList: (state, action) => {
      const { payload } = action;
      state.activityList = payload;
    },

    setRechargeCreatOrder: (state, action) => {
      const { payload } = action;
      state.rechargeCreatOrder = payload;
    },

    setCompleteChargeOrder: (state, action) => {
      const { payload } = action;
      state.completeChargeOrder = payload;
    },

    setActivityResultResp: (state, action) => {
      const { payload } = action;
      state.activityResultResp = payload;
    },
    setActivityItem: (state, action) => {
      const { payload } = action;
      state.activityItem = payload;
    },
    settotalRechargeGetHistory: (state, action) => {
      const { payload } = action;
      state.totalRechargeGetHistory = payload;
    },
    setlookEventDetail: (state, action) => {
      const { payload } = action;
      state.lookEventDetail = payload;
    },
    settotalRechargeDrawSpin: (state, action) => {
      const { payload } = action;
      state.totalRechargeDrawSpin = payload;
    },
    settotalRechargeUnLock: (state, action) => {
      const { payload } = action;
      state.totalRechargeUnLock = payload;
    },
    settotalRechargeGetBroadcastMessage: (state, action) => {
      const { payload } = action;
      state.totalRechargeGetBroadcastMessage = payload;
    },
    setactivityItemPush: (state, action) => {
      const { payload } = action;
      state.activityItemPush = payload;
    },
    setLastSpin: (state, action) => {
      const { payload } = action;
      state.lastSpin = payload;
    },
    setAppDownload(state, action) {
      const { payload } = action;
      state.appDownload = {
        ...state.appDownload,
        ...payload,
      };
    },
    setShowRegressActivty(state, action: { payload: typeof initialState.showRegressActivty }) {
      const { payload } = action;
      state.showRegressActivty = payload;
    },
  },
});

export namespace ActivityActions {
  export const {
    setActivityList,
    setRechargeCreatOrder,
    setCompleteChargeOrder,
    setActivityResultResp,
    setLastSpin,
    setActivityItem,
    settotalRechargeGetHistory,
    setlookEventDetail,
    settotalRechargeDrawSpin,
    settotalRechargeUnLock,
    settotalRechargeGetBroadcastMessage,
    setactivityItemPush,
    setAppDownload,
    setShowRegressActivty,
  } = activitySlice.actions;
}

export default activitySlice.reducer;
