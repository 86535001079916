import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { Activity } from "@/const";
import { client_proto } from "@/utils/net/shared/proto";

export namespace ActivityService {
  /** 活动列表请求 */
  export const reqActivityList = (uid: number, activityId?: number) => {
    BrowserWsClient.instance.handleSendData({
      route: Activity.Req.ActivityList,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_ACTIVITY,
      cmd: client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_LIST_REQ,
      data: {
        uid,
        id: activityId,
      },
    });
  };

  /** 创建订单 */
  export const reqRechargeCreatOrder = (param: any) => {
    BrowserWsClient.instance.handleSendData({
      route: Activity.Req.RechargeCreatOrder,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_ACTIVITY,
      cmd: client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_GIFT_CREATE_ORDER_REQ,
      data: param,
    });
  };
  /**
   * 查看单个活动详情
   */
  export const reqActivityDetail = (uid: number, activityID: number) => {
    let param = {
      uid,
      id: activityID,
    };
    BrowserWsClient.instance.handleSendData({
      route: Activity.Req.ActivityDetail,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_ACTIVITY,
      cmd: client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_LOOKEVENT_DETAIL_REQ,
      data: param,
    });
  };
  /** 累充摇奖 */
  export const reqTotalRechargeDrawSpin = (param: any) => {
    BrowserWsClient.instance.handleSendData({
      route: Activity.Req.TotalRechargeDrawSpin,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_ACTIVITY,
      cmd: client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_ACCUMULATED_CHARGE_LOTTERY_REQ,
      data: param,
    });
  };
  /** 累充标签解锁动画完成 */
  export const reqTotalRechargeUnLock = (param: any) => {
    BrowserWsClient.instance.handleSendData({
      route: Activity.Req.TotalRechargeUnLock,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_ACTIVITY,
      cmd: client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_ACCUMULATED_CHARGE_ANIMATION_REQ,
      data: param,
    });
  };
  /** 累充通知广播 */
  export const reqTotalRechargeGetBroadcastMessage = (param: any) => {
    BrowserWsClient.instance.handleSendData({
      route: Activity.Req.TotalRechargeGetBroadcastMessage,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_ACTIVITY,
      cmd: client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_ACCUMULATED_CHARGE_GET_MESSAGE_REQ,
      data: param,
    });
  };
  /** 累充历史记录 */
  export const reqTotalRechargeGetHistory = (param: any) => {
    BrowserWsClient.instance.handleSendData({
      route: Activity.Req.TotalRechargeGetHistory,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_ACTIVITY,
      cmd: client_proto.ACTIVITY_SUB_MSG_ID.ACTIVITY_ACCUMULATED_CHARGE_GET_HISTORY_REQ,
      data: param,
    });
  };
}
