import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { client_proto } from "@/utils/net/shared/proto";

type ExchangeCodeState = {
  giftGetShowPage: client_proto.IGiftGetShowPageResp;
  openGift: client_proto.IOpenGiftResp;
};

const initialState: ExchangeCodeState = {
  giftGetShowPage: {},
  openGift: {},
};

export const exchangeCodeSlice = createSlice({
  name: "exchangeCode",
  initialState,
  reducers: {
    setGiftGetShowPage: (state, action: PayloadAction<ExchangeCodeState["giftGetShowPage"]>) => {
      const { payload } = action;
      state.giftGetShowPage = payload;
    },

    setOpenGift: (state, action: PayloadAction<ExchangeCodeState["openGift"]>) => {
      const { payload } = action;
      state.openGift = payload;
    },
  },
});

export namespace ExchangeCodeActions {
  export const { setGiftGetShowPage, setOpenGift } = exchangeCodeSlice.actions;
}

export default exchangeCodeSlice.reducer;
