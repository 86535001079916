import { clientProtoPrefix } from "@/utils";
import { CmdToPbName } from "@/utils/net/INet";
import { client_proto } from "@/utils/net/shared/proto";

export namespace Login {
  export const Req = {
    LoginReq: clientProtoPrefix("LoginReq"),
  };
  export const Resp = {
    LoginResp: clientProtoPrefix("LoginResp"),
  };

  /** 登录类型（pb定义） */
  export const LoginType = {
    /** 默认:无 */
    LTD_NULL: 0,
    /** token登录 */
    LTD_TOKEN: 1,
    /** 账号密码登录 */
    LTD_PASSWORD: 2,
    /** 手机验证码登录 */
    LTD_CODE: 3,
    /** 游客登录 */
    LTD_GUEST: 4,
    /** 登出 */
    LTD_LOGOUT: 5,
  };
}

CmdToPbName[client_proto.LOGIN_SUB_MSG_ID.LSMI_LOGIN_RESP] = Login.Resp.LoginResp;
