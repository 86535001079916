import { User } from "@/const/user";
import { AuthActions, BaseInfoResp } from "@/redux/features/auth-slice";
import { AppDispatch, useAppSelector } from "@/redux/store";
import { ThirdGameService, UserService } from "@/service";
import { EventGlobalManager } from "@/utils/events";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { NetMsg } from "@/utils/net/INet";
import { client_proto, agent } from "@/utils/net/shared/proto";
import { useDispatch } from "react-redux";
import { get } from "lodash-es";
import { StorageData } from "@/utils/net/storage/StorageData";
import { StoreKey } from "@/const";
import { i18n, systemConfigUtils } from "@/utils";
import { LoadActions } from "@/redux/features/load-slice";
import toast from "@/widget/Toast";
import { workerRef } from "@/worker/uiDelayTime.worker";

export const useUserHook = () => {
  const dispatch = useDispatch<AppDispatch>();
  const user_basic_info = useAppSelector((state) => state.authSlice.basic_info);

  function login() {
    return UserService.Login()
      .then((rsp: NetMsg<client_proto.ILoginResp>) => {
        const { data: userInfo } = rsp;
        const { user_id, basic_info, login_type } = userInfo!;
        BrowserWsClient.instance.uid = user_id!;
        StorageData.local.set(StoreKey.USER_LOGOUT, false);
        workerRef?.postMessage(rsp.data?.timestamp);
        dispatch(BaseInfoResp(basic_info!));
        dispatch(AuthActions.setNetError(false));
        dispatch(AuthActions.setLoginType(login_type!));
        ThirdGameService.gameGroupV1(basic_info?.uid!);

        //@ts-ignore
        window.AF("pba", "setCustomerUserId", "kb-" + basic_info?.uid!);

        // const result = new URLSearchParams(location.search);
        // if (result.get("invite_code")) {
        //   const invite_code = JSON.stringify({ ic: result.get("invite_code") });
        //   StorageData.local.set(StoreKey.INVITE_CODE, invite_code);
        // }

        // // 当用户登录了且没有邀请码的时候进行上报一次
        // if (basic_info?.user_mobile && basic_info?.uid && !basic_info?.invite_code) {
        //   const param: any = {
        //     channel: "default",
        //     ua: window.navigator.userAgent,
        //     af_id: "kb-" + basic_info?.uid!,
        //   };
        //   if (StorageData.local.get(StoreKey.INVITE_CODE)) {
        //     param.invite_code = StorageData.local.get(StoreKey.INVITE_CODE);
        //   }
        //   UserService.AFReport(param as any);
        // }

        setTimeout(() => {
          dispatch(LoadActions.setWebSocketReconnect(false));
        }, 10);
        StorageData.local.remove(StoreKey.FIRST_CHARGE);

        return Promise.resolve(true);
      })
      .catch((err) => {
        dispatch(AuthActions.setNetError(true));
        return Promise.reject(err);
      });
  }

  function onListenSocket() {
    EventGlobalManager.on(User.Resp.GetUserInfo, (rsp: NetMsg<client_proto.IGetUserBasicInfoResp>) => {
      const data = get(rsp, "data");
      if (data) {
        dispatch(AuthActions.setUserBasicInfoResp(data));
      }
    });

    EventGlobalManager.on(User.Resp.SetUserInfo, (rsp: NetMsg<client_proto.ISetUserInfoResp>) => {
      const data = get(rsp, "data");
      if (data) {
        dispatch(AuthActions.setUserInfoResp(data));

        const info = get(rsp, "data.datas") as client_proto.ISetUserInfoResp["datas"];
        if (info) {
          const update: client_proto.ILoginResp["basic_info"] = {
            user_avatar: info.user_avatar,
            user_name: info.user_name,
            user_mobile: info.telephone,
            user_email: info.email,
            user_gender: Number(info.user_gender),
          };
          dispatch(
            AuthActions.BaseInfoResp({
              ...user_basic_info,
              ...update,
            }),
          );

          // toast(i18n.translation("modify.successTxt"));
        }
      } else {
        toast(i18n.translation("modify.failTxt"));
      }
    });

    EventGlobalManager.on(User.Resp.SetLangCode, (rsp: NetMsg<client_proto.ISetLangCodeResp>) => {
      const data = get(rsp, "data");
      if (data) {
        dispatch(AuthActions.setLangCodeResp(data));
      }
    });

    EventGlobalManager.on(User.Resp.BindPassword, (rsp: NetMsg<any>) => {
      const data = get(rsp, "data");
      if (data) {
        dispatch(AuthActions.setBindPassword(data));
      }
    });

    EventGlobalManager.on(User.Resp.ResetPassword, (rsp: NetMsg<any>) => {
      const data = get(rsp, "data");
      if (data) {
        dispatch(AuthActions.setResetPassword(data));
      }
    });

    EventGlobalManager.on(User.Resp.Login, (res: NetMsg<client_proto.ILoginResp>) => {
      const basic_info = get(res, "data.basic_info");
      const loginType = get(res, "data.login_type");
      const result = get(res, "data.result");
      if (basic_info) {
        dispatch(AuthActions.BaseInfoResp(basic_info));
      }
      if (loginType) {
        dispatch(AuthActions.setLoginType(loginType));
      }
      dispatch(
        AuthActions.setLoginErrorInfo({
          result,
        }),
      );
    });

    EventGlobalManager.on(User.Event.NetWork, () => {
      dispatch(AuthActions.setNetError(true));
    });

    EventGlobalManager.on(User.Resp.Report, (rsp: NetMsg<any>) => {
      const data = get(rsp, "data");
      dispatch(AuthActions.setReport(data));
    });

    EventGlobalManager.on(User.Event.WeakNetWork, (state: boolean) => {
      dispatch(AuthActions.setWeakNetworkAlert(state));
    });

    EventGlobalManager.on(User.Resp.KickPush, (rsp: NetMsg<any>) => {
      dispatch(AuthActions.setKickPush(rsp.data as agent.IKickPush));
    });
  }

  return {
    login,
    onListenSocket,
  };
};

export const useImgType = () => {
  const type = StorageData.local.get(StoreKey.IMG_TYPE, "to");
  return {
    type: type === "to" ? true : false,
  };
};
