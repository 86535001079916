import { clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace Wallet {
  export const UWSMI_MSG_NULL = 0;
  export const UWSMI_MSG_ID_MIN = 1000;
  export const UWSMI_RELOAD_CHARGE_CONFIG = 1001;
  export const UWSMI_GET_CHARGE_CONFIG_REQ = 1002;
  export const UWSMI_GET_CHARGE_CONFIG_RESP = 1003;
  export const UWSMI_CREATE_ORDER_REQ = 1004;
  export const UWSMI_CREATE_ORDER_RESP = 1005;
  export const UWSMI_GET_CHARGE_LOG_REQ = 1007;
  export const UWSMI_GET_CHARGE_LOG_RESP = 1008;
  export const UWSMI_GET_WITHDRAW_LOG_REQ = 1009;
  export const UWSMI_GET_WITHDRAW_LOG_RESP = 1010;
  export const UWSMI_GET_WITHDRAW_BANK_REQ = 1015;
  export const UWSMI_GET_WITHDRAW_BANK_RESP = 1016;
  export const UWSMI_GET_WITHDRAW_CONFIG_REQ = 1017;
  export const UWSMI_GET_WITHDRAW_CONFIG_RESP = 1018;
  export const UWSMI_CREATE_WITHDRAW_ORDER_REQ = 1019;
  export const UWSMI_CREATE_WITHDRAW_ORDER_RESP = 1020;
  export const UWSMI_SET_BANKCARD_REQ = 1021;
  export const UWSMI_SET_BANKCARD_RESP = 1022;
  export const UWSMI_GET_BANNER_LIST_REQ = 1023;
  export const UWSMI_GET_BANNER_LIST_RESP = 1024;
  export const UWSMI_PHP_CREATE_ORDER_REQ = 1060;
  export const UWSMI_PHP_CREATE_ORDER_RESP = 1061;
  export const UWSMI_PHP_COMPLETE_ORDER_REQ = 1062;
  export const UWSMI_PHP_COMPLETE_ORDER_RESP = 1063;
  export const UWSMI_PHP_CREATE_WITHDRAW_REQ = 1064;
  export const UWSMI_PHP_CREATE_WITHDRAW_RESP = 1065;
  export const UWSMI_PHP_COMPLETE_WITHDRAW_REQ = 1066;
  export const UWSMI_PHP_COMPLETE_WITHDRAW_RESP = 1067;
  export const UWSMI_PHP_SET_BANKCARD_REQ = 1068;
  export const UWSMI_PHP_SET_BANKCARD_RESP = 1069;
  export const UWSMI_SERVER_CREATE_ORDER_REQ = 1070;
  export const UWSMI_SERVER_CREATE_ORDER_RESP = 1071;
  export const UWSMI_SERVER_COMPLETE_ORDER_REQ = 1072;
  export const UWSMI_PHP_RE_CREATE_CHARGE_REQ = 1073;
  export const UWSMI_PHP_RE_CREATE_CHARGE_RESP = 1074;
  export const UWSMI_MSG_ID_MAX = 1100;

  export const Req = {
    BANNER_DEPOSIT: clientProtoPrefix("GetBannerList"),
    Deposit: {
      INFO: clientProtoPrefix("ChargeConfigReq"),
      ORDER: clientProtoPrefix("CreateChargeOrderReq"),
      RECORD: clientProtoPrefix("GetChargeLogReq"),
      BANNER: clientProtoPrefix("GetBannerListReq"),
    },
    Withdraw: {
      INFO: clientProtoPrefix("GetWithdrawConfigReq"),
      SET_BANK: clientProtoPrefix("SetBankCardReq"),
      WITHDRAW: clientProtoPrefix("CreateWithdrawOrderReq"),
      RECORD: clientProtoPrefix("GetWithdrawLogReq"),
    },
  };
  export const Resp = {
    Deposit: {
      INFO: clientProtoPrefix("ChargeConfigResp"),
      ORDER: clientProtoPrefix("CreateChargeOrderResp"),
      RECORD: clientProtoPrefix("GetChargeLogResp"),
      BANNER: clientProtoPrefix("GetBannerListResp"),
    },
    Withdraw: {
      INFO: clientProtoPrefix("GetWithdrawConfigResp"),
      SET_BANK: clientProtoPrefix("SetBankCardResp"),
      WITHDRAW: clientProtoPrefix("CreateWithdrawOrderResp"),
      RECORD: clientProtoPrefix("GetWithdrawLogResp"),
    },
  };
}

// 钱包信息
CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_GET_CHARGE_CONFIG_RESP] = Wallet.Resp.Deposit.INFO;
// 充值
CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_CREATE_ORDER_RESP] = Wallet.Resp.Deposit.ORDER;
// 提现信息
CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_GET_WITHDRAW_CONFIG_RESP] = Wallet.Resp.Withdraw.INFO;
// 设置银行卡
CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_SET_BANKCARD_RESP] = Wallet.Resp.Withdraw.SET_BANK;
// 提取
CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_CREATE_WITHDRAW_ORDER_RESP] = Wallet.Resp.Withdraw.WITHDRAW;
// 充值记录
CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_GET_CHARGE_LOG_RESP] = Wallet.Resp.Deposit.RECORD;
// 提取记录
CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_GET_WITHDRAW_LOG_RESP] = Wallet.Resp.Withdraw.RECORD;
// 获取商城banner
CmdToPbName[client_proto.USER_WALLET_MSG_ID.UWSMI_GET_BANNER_LIST_RESP] = Wallet.Resp.Deposit.BANNER;
