import { Net } from "@/const/net";
import md5 from "md5";

interface HttpClientInit extends RequestInit {
  url: string;
}

class HttpClient {
  private static _instance: HttpClient;
  private _baseUrl!: string;

  get baseUrl() {
    return this._baseUrl;
  }

  set baseUrl(value: string) {
    this._baseUrl = value;
  }

  getCurrentUrl(path: string) {
    return `${this._baseUrl}${path}`;
  }

  public static get instance(): HttpClient {
    if (!HttpClient._instance) {
      HttpClient._instance = new HttpClient();
      HttpClient.instance.baseUrl = Net.HTTP_URL!;
    }
    return HttpClient._instance;
  }

  generateSignature(data: { [key: string]: any }, signKey?: string) {
    // 步骤1: 数据排序
    const sortedKeys = Object.keys(data).sort();

    // 步骤2: 构建待签名字符串
    let signString = sortedKeys
      .map((key) => {
        let value = data[key];
        // 如果值是对象或数组，需要转换为JSON字符串
        if (typeof value === "object" && value !== null) {
          value = JSON.stringify(value);
        } else if (typeof value === "string") {
          // 对字符串进行URL编码
          value = encodeURIComponent(value);
        }
        return `${key}=${value}`;
      })
      .join("&");

    // 步骤3: 如果有signKey，拼接在字符串后面
    if (signKey) {
      signString += signKey;
    }

    // 步骤4: 签名生成，对构建的待签名字符串进行MD5加密
    const signature = md5(signString);

    return signature;
  }

  preprocessBody(body: RequestInit["body"]) {
    if (typeof body === "string") {
      const newBody = JSON.parse(body);
      newBody.sign = this.generateSignature(newBody);
      return JSON.stringify(newBody);
    }
  }

  preprocessHeaders(headers: HeadersInit) {
    const fetchHeaders = new Headers(headers);
    return fetchHeaders;
  }

  ajax(config: HttpClientInit) {
    const { url, method, headers } = config;

    if (method === "POST" && headers) {
      const newHeaders = this.preprocessHeaders(headers);
      if (newHeaders.get("Content-Type") === "application/json") {
        config.body = this.preprocessBody(config.body);
      }
    }
    return fetch(this.getCurrentUrl(url), config).then((res) => {
      if (!res.ok) {
        console.log(res.statusText);
      }
      return res.json();
    });
  }
}

export default HttpClient.instance;
