import { vipbetredateProtoPrefix, clientProtoPrefix } from "@/utils";
import { CmdToPbName, vipbetredate_proto } from "@/utils/net/INet";

export namespace MyBound {
  export const Req = {
    vipBetReadte: vipbetredateProtoPrefix("QueryRewardReq"),
    vipBetReadteReward: vipbetredateProtoPrefix("ClaimRewardReq"),
  };

  export const Resp = {
    rewardList: clientProtoPrefix("ResponseRewardListResp"),
    vipBetReadte: vipbetredateProtoPrefix("QuerylaimRewardResp"),
    vipBetReadteReward: vipbetredateProtoPrefix("ClaimRewardResp"),
  };
}

CmdToPbName[vipbetredate_proto.ROOMALLOC_CMD.ROOMALLOC_CMD_VIP_BET_REDATE_INFO_RESP] = MyBound.Resp.vipBetReadte;

CmdToPbName[vipbetredate_proto.ROOMALLOC_CMD.ROOMALLOC_CMD_VIP_BET_REDATE_RESP] = MyBound.Resp.vipBetReadteReward;
