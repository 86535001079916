import { Rank } from "@/const/rank";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto } from "@/utils/net/INet";

export namespace rankService {
  /**
   * 请求标签列表 （排行榜ID）
   */
  export const reqShowList = () => {
    BrowserWsClient.instance.handleSendData({
      route: Rank.Req.SHOW_LIST,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GETRANK,
      cmd: client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_SHOW_LIST,
      data: {},
      force: true,
    });
  };

  /**
   * 请求排行榜列表
   * @param isHistory  是否请求历史记录
   * @param page_number 请求的第几页数据
   * @param number_per_page 每一页请求多少数量
   */
  export const reqDataList = (
    uid: number,
    id: number,
    isHistory: boolean = false,
    page_number: number = 1,
    force: boolean = true,
    number_per_page: number = 30,
  ) => {
    BrowserWsClient.instance.handleSendData({
      route: Rank.Req.DATA_LIST,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GETRANK,
      cmd: client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_LIST,
      data: {
        uid,
        id: id,
        is_history: isHistory,
        page_number: page_number,
        number_per_page: number_per_page,
      },
      force,
    });
  };

  /**
   * 请求jackpot 数据
   * @param id 榜单类型（）
   */
  export const reqJackpot = (id: number) => {
    BrowserWsClient.instance.handleSendData({
      route: Rank.Req.JACKPOT,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GETRANK,
      cmd: client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_JACKPOT,
      data: {
        id,
      },
      force: true,
    });
  };

  /**
   * 请求玩家奖励列表
   */
  export const reqRewardList = (uid: number) => {
    BrowserWsClient.instance.handleSendData({
      route: Rank.Req.REWARD_LIST,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GETRANK,
      cmd: client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_REWARD_LIST,
      data: {
        uid,
      },
    });
  };

  /**
   * 请求玩家奖励列表
   */
  export const reqGetReward = (uid: number, rewardId: number) => {
    let data = {
      uid,
      reward_id: rewardId,
    };
    BrowserWsClient.instance.handleSendData({
      route: Rank.Req.GET_REWARD,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_RANK,
      cmd: client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_GET_REWARD,
      data,
    });
  };

  /**
   * 请求领取奖励
   * @param rewardId  奖励ID
   */
  export const reqGameList = (uid: number, rewardId: number) => {
    let data = {
      uid,
      reward_id: rewardId,
    };
    BrowserWsClient.instance.handleSendData({
      route: Rank.Req.GET_REWARD,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GETRANK,
      cmd: client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_JOIN_GAME_LIST,
      data,
    });
  };

  /**
   * 请求排行榜历史报告
   * @param id 排行榜id
   */
  export const reqGetReport = (key: string, number: number) => {
    BrowserWsClient.instance.handleSendData({
      route: Rank.Req.GET_REPORT,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_GETRANK,
      cmd: client_proto.RANK_INFO_SUB_MSG_ID.RANK_MSG_ID_RANK_GET_REPORT,
      data: {
        number: number,
        key: key,
      },
    });
  };
}
