"use client";

import { imageLoadFadeIn } from "@/utils";
import React, { ReactEventHandler, useEffect, useState } from "react";
import { useImgType } from "@/hooks/user";

type Sources = {
  type: string;
  srcset: string;
};

function Img(props: any, ref: any) {
  const { src, onLoad: propOnLoad, noLoad, divProps, isTO = false, ...restProps } = props;
  let imgUrl = src;
  const { type } = useImgType();
  // const [source, setSource] = useState<Sources[]>([]);

  const onLoad: ReactEventHandler<HTMLImageElement> = (e) => {
    imageLoadFadeIn(e);
    propOnLoad?.(e);
  };

  if (isTO && type) {
    if (src?.indexOf("base64") !== -1) {
      imgUrl = restProps?.toUrl ? restProps.toUrl : src;
    } else {
      let url = src?.split(process.env.NEXT_PUBLIC_CDN_RUL)?.[1];
      imgUrl = process.env.NEXT_PUBLIC_CDN_RUL + "topOne/" + url;
    }
  }

  // useEffect(() => {
  //   const srcAs = src as string;
  //   console.log("srcAs", srcAs);
  //   if (srcAs.includes(process.env.NEXT_PUBLIC_CDN_RUL!)) {
  //     // 为这张可能为 jpge jpg png 的替换成 .webp 和 avif
  //     const filename = srcAs.slice(0, srcAs.lastIndexOf("."));
  //     const source = [
  //       {
  //         type: "image/avif",
  //         srcset: `${filename}.avif`,
  //       },
  //       {
  //         type: "image/webp",
  //         srcset: `${filename}.webp`,
  //       },
  //     ];
  //     setSource(source);
  //   }
  // }, [src]);

  return (
    <img ref={ref} onLoad={onLoad} alt={props.alt} loading="lazy" {...restProps} src={imgUrl} />
    // <picture>
    //   {source.map((item, index) => {
    //     return <source key={`${item.type}_${index}`} type={item.type} srcSet={item.srcset} />;
    //   })}
    //   <img ref={ref} alt={props.alt} loading="lazy" {...restProps} src={src} />
    // </picture>
  );
}

export default React.forwardRef(Img);
