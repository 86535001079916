/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal.js");

// Common aliases
var $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.client_proto = (function () {
  /**
   * Namespace client_proto.
   * @exports client_proto
   * @namespace
   */
  var client_proto = {};

  /**
   * USER_WALLET_MSG_ID enum.
   * @name client_proto.USER_WALLET_MSG_ID
   * @enum {number}
   * @property {number} UWSMI_MSG_NULL=0 UWSMI_MSG_NULL value
   * @property {number} UWSMI_MSG_ID_MIN=1000 UWSMI_MSG_ID_MIN value
   * @property {number} UWSMI_RELOAD_CHARGE_CONFIG=1001 UWSMI_RELOAD_CHARGE_CONFIG value
   * @property {number} UWSMI_GET_CHARGE_CONFIG_REQ=1002 UWSMI_GET_CHARGE_CONFIG_REQ value
   * @property {number} UWSMI_GET_CHARGE_CONFIG_RESP=1003 UWSMI_GET_CHARGE_CONFIG_RESP value
   * @property {number} UWSMI_CREATE_ORDER_REQ=1004 UWSMI_CREATE_ORDER_REQ value
   * @property {number} UWSMI_CREATE_ORDER_RESP=1005 UWSMI_CREATE_ORDER_RESP value
   * @property {number} UWSMI_GET_CHARGE_LOG_REQ=1007 UWSMI_GET_CHARGE_LOG_REQ value
   * @property {number} UWSMI_GET_CHARGE_LOG_RESP=1008 UWSMI_GET_CHARGE_LOG_RESP value
   * @property {number} UWSMI_GET_WITHDRAW_LOG_REQ=1009 UWSMI_GET_WITHDRAW_LOG_REQ value
   * @property {number} UWSMI_GET_WITHDRAW_LOG_RESP=1010 UWSMI_GET_WITHDRAW_LOG_RESP value
   * @property {number} UWSMI_GET_WITHDRAW_BANK_REQ=1015 UWSMI_GET_WITHDRAW_BANK_REQ value
   * @property {number} UWSMI_GET_WITHDRAW_BANK_RESP=1016 UWSMI_GET_WITHDRAW_BANK_RESP value
   * @property {number} UWSMI_GET_WITHDRAW_CONFIG_REQ=1017 UWSMI_GET_WITHDRAW_CONFIG_REQ value
   * @property {number} UWSMI_GET_WITHDRAW_CONFIG_RESP=1018 UWSMI_GET_WITHDRAW_CONFIG_RESP value
   * @property {number} UWSMI_CREATE_WITHDRAW_ORDER_REQ=1019 UWSMI_CREATE_WITHDRAW_ORDER_REQ value
   * @property {number} UWSMI_CREATE_WITHDRAW_ORDER_RESP=1020 UWSMI_CREATE_WITHDRAW_ORDER_RESP value
   * @property {number} UWSMI_SET_BANKCARD_REQ=1021 UWSMI_SET_BANKCARD_REQ value
   * @property {number} UWSMI_SET_BANKCARD_RESP=1022 UWSMI_SET_BANKCARD_RESP value
   * @property {number} UWSMI_GET_BANNER_LIST_REQ=1023 UWSMI_GET_BANNER_LIST_REQ value
   * @property {number} UWSMI_GET_BANNER_LIST_RESP=1024 UWSMI_GET_BANNER_LIST_RESP value
   * @property {number} UWSMI_PHP_CREATE_ORDER_REQ=1060 UWSMI_PHP_CREATE_ORDER_REQ value
   * @property {number} UWSMI_PHP_CREATE_ORDER_RESP=1061 UWSMI_PHP_CREATE_ORDER_RESP value
   * @property {number} UWSMI_PHP_COMPLETE_ORDER_REQ=1062 UWSMI_PHP_COMPLETE_ORDER_REQ value
   * @property {number} UWSMI_PHP_COMPLETE_ORDER_RESP=1063 UWSMI_PHP_COMPLETE_ORDER_RESP value
   * @property {number} UWSMI_PHP_CREATE_WITHDRAW_REQ=1064 UWSMI_PHP_CREATE_WITHDRAW_REQ value
   * @property {number} UWSMI_PHP_CREATE_WITHDRAW_RESP=1065 UWSMI_PHP_CREATE_WITHDRAW_RESP value
   * @property {number} UWSMI_PHP_COMPLETE_WITHDRAW_REQ=1066 UWSMI_PHP_COMPLETE_WITHDRAW_REQ value
   * @property {number} UWSMI_PHP_COMPLETE_WITHDRAW_RESP=1067 UWSMI_PHP_COMPLETE_WITHDRAW_RESP value
   * @property {number} UWSMI_PHP_SET_BANKCARD_REQ=1068 UWSMI_PHP_SET_BANKCARD_REQ value
   * @property {number} UWSMI_PHP_SET_BANKCARD_RESP=1069 UWSMI_PHP_SET_BANKCARD_RESP value
   * @property {number} UWSMI_SERVER_CREATE_ORDER_REQ=1070 UWSMI_SERVER_CREATE_ORDER_REQ value
   * @property {number} UWSMI_SERVER_CREATE_ORDER_RESP=1071 UWSMI_SERVER_CREATE_ORDER_RESP value
   * @property {number} UWSMI_SERVER_COMPLETE_ORDER_REQ=1072 UWSMI_SERVER_COMPLETE_ORDER_REQ value
   * @property {number} UWSMI_PHP_RE_CREATE_CHARGE_REQ=1073 UWSMI_PHP_RE_CREATE_CHARGE_REQ value
   * @property {number} UWSMI_PHP_RE_CREATE_CHARGE_RESP=1074 UWSMI_PHP_RE_CREATE_CHARGE_RESP value
   * @property {number} UWSMI_SERVER_UPDATE_ORDERINFO_REQ=1075 UWSMI_SERVER_UPDATE_ORDERINFO_REQ value
   * @property {number} UWSMI_SERVER_UPDATE_ORDERINFO_RESP=1076 UWSMI_SERVER_UPDATE_ORDERINFO_RESP value
   * @property {number} UWSMI_MSG_ID_MAX=1100 UWSMI_MSG_ID_MAX value
   */
  client_proto.USER_WALLET_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "UWSMI_MSG_NULL")] = 0;
    values[(valuesById[1000] = "UWSMI_MSG_ID_MIN")] = 1000;
    values[(valuesById[1001] = "UWSMI_RELOAD_CHARGE_CONFIG")] = 1001;
    values[(valuesById[1002] = "UWSMI_GET_CHARGE_CONFIG_REQ")] = 1002;
    values[(valuesById[1003] = "UWSMI_GET_CHARGE_CONFIG_RESP")] = 1003;
    values[(valuesById[1004] = "UWSMI_CREATE_ORDER_REQ")] = 1004;
    values[(valuesById[1005] = "UWSMI_CREATE_ORDER_RESP")] = 1005;
    values[(valuesById[1007] = "UWSMI_GET_CHARGE_LOG_REQ")] = 1007;
    values[(valuesById[1008] = "UWSMI_GET_CHARGE_LOG_RESP")] = 1008;
    values[(valuesById[1009] = "UWSMI_GET_WITHDRAW_LOG_REQ")] = 1009;
    values[(valuesById[1010] = "UWSMI_GET_WITHDRAW_LOG_RESP")] = 1010;
    values[(valuesById[1015] = "UWSMI_GET_WITHDRAW_BANK_REQ")] = 1015;
    values[(valuesById[1016] = "UWSMI_GET_WITHDRAW_BANK_RESP")] = 1016;
    values[(valuesById[1017] = "UWSMI_GET_WITHDRAW_CONFIG_REQ")] = 1017;
    values[(valuesById[1018] = "UWSMI_GET_WITHDRAW_CONFIG_RESP")] = 1018;
    values[(valuesById[1019] = "UWSMI_CREATE_WITHDRAW_ORDER_REQ")] = 1019;
    values[(valuesById[1020] = "UWSMI_CREATE_WITHDRAW_ORDER_RESP")] = 1020;
    values[(valuesById[1021] = "UWSMI_SET_BANKCARD_REQ")] = 1021;
    values[(valuesById[1022] = "UWSMI_SET_BANKCARD_RESP")] = 1022;
    values[(valuesById[1023] = "UWSMI_GET_BANNER_LIST_REQ")] = 1023;
    values[(valuesById[1024] = "UWSMI_GET_BANNER_LIST_RESP")] = 1024;
    values[(valuesById[1060] = "UWSMI_PHP_CREATE_ORDER_REQ")] = 1060;
    values[(valuesById[1061] = "UWSMI_PHP_CREATE_ORDER_RESP")] = 1061;
    values[(valuesById[1062] = "UWSMI_PHP_COMPLETE_ORDER_REQ")] = 1062;
    values[(valuesById[1063] = "UWSMI_PHP_COMPLETE_ORDER_RESP")] = 1063;
    values[(valuesById[1064] = "UWSMI_PHP_CREATE_WITHDRAW_REQ")] = 1064;
    values[(valuesById[1065] = "UWSMI_PHP_CREATE_WITHDRAW_RESP")] = 1065;
    values[(valuesById[1066] = "UWSMI_PHP_COMPLETE_WITHDRAW_REQ")] = 1066;
    values[(valuesById[1067] = "UWSMI_PHP_COMPLETE_WITHDRAW_RESP")] = 1067;
    values[(valuesById[1068] = "UWSMI_PHP_SET_BANKCARD_REQ")] = 1068;
    values[(valuesById[1069] = "UWSMI_PHP_SET_BANKCARD_RESP")] = 1069;
    values[(valuesById[1070] = "UWSMI_SERVER_CREATE_ORDER_REQ")] = 1070;
    values[(valuesById[1071] = "UWSMI_SERVER_CREATE_ORDER_RESP")] = 1071;
    values[(valuesById[1072] = "UWSMI_SERVER_COMPLETE_ORDER_REQ")] = 1072;
    values[(valuesById[1073] = "UWSMI_PHP_RE_CREATE_CHARGE_REQ")] = 1073;
    values[(valuesById[1074] = "UWSMI_PHP_RE_CREATE_CHARGE_RESP")] = 1074;
    values[(valuesById[1075] = "UWSMI_SERVER_UPDATE_ORDERINFO_REQ")] = 1075;
    values[(valuesById[1076] = "UWSMI_SERVER_UPDATE_ORDERINFO_RESP")] = 1076;
    values[(valuesById[1100] = "UWSMI_MSG_ID_MAX")] = 1100;
    return values;
  })();

  client_proto.ChargeConfigReq = (function () {
    /**
     * Properties of a ChargeConfigReq.
     * @memberof client_proto
     * @interface IChargeConfigReq
     */

    /**
     * Constructs a new ChargeConfigReq.
     * @memberof client_proto
     * @classdesc Represents a ChargeConfigReq.
     * @implements IChargeConfigReq
     * @constructor
     * @param {client_proto.IChargeConfigReq=} [properties] Properties to set
     */
    function ChargeConfigReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new ChargeConfigReq instance using the specified properties.
     * @function create
     * @memberof client_proto.ChargeConfigReq
     * @static
     * @param {client_proto.IChargeConfigReq=} [properties] Properties to set
     * @returns {client_proto.ChargeConfigReq} ChargeConfigReq instance
     */
    ChargeConfigReq.create = function create(properties) {
      return new ChargeConfigReq(properties);
    };

    /**
     * Encodes the specified ChargeConfigReq message. Does not implicitly {@link client_proto.ChargeConfigReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ChargeConfigReq
     * @static
     * @param {client_proto.IChargeConfigReq} message ChargeConfigReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChargeConfigReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified ChargeConfigReq message, length delimited. Does not implicitly {@link client_proto.ChargeConfigReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ChargeConfigReq
     * @static
     * @param {client_proto.IChargeConfigReq} message ChargeConfigReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChargeConfigReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ChargeConfigReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ChargeConfigReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ChargeConfigReq} ChargeConfigReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChargeConfigReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ChargeConfigReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ChargeConfigReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ChargeConfigReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ChargeConfigReq} ChargeConfigReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChargeConfigReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ChargeConfigReq message.
     * @function verify
     * @memberof client_proto.ChargeConfigReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ChargeConfigReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a ChargeConfigReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ChargeConfigReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ChargeConfigReq} ChargeConfigReq
     */
    ChargeConfigReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ChargeConfigReq) return object;
      return new $root.client_proto.ChargeConfigReq();
    };

    /**
     * Creates a plain object from a ChargeConfigReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ChargeConfigReq
     * @static
     * @param {client_proto.ChargeConfigReq} message ChargeConfigReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ChargeConfigReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this ChargeConfigReq to JSON.
     * @function toJSON
     * @memberof client_proto.ChargeConfigReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ChargeConfigReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ChargeConfigReq
     * @function getTypeUrl
     * @memberof client_proto.ChargeConfigReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ChargeConfigReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ChargeConfigReq";
    };

    return ChargeConfigReq;
  })();

  client_proto.ChargeItem = (function () {
    /**
     * Properties of a ChargeItem.
     * @memberof client_proto
     * @interface IChargeItem
     * @property {number|null} [id] ChargeItem id
     * @property {string|null} [title] ChargeItem title
     * @property {number|Long|null} [price] ChargeItem price
     * @property {number|Long|null} [balance] ChargeItem balance
     * @property {number|Long|null} [bonus] ChargeItem bonus
     * @property {number|null} [flow_multi] ChargeItem flow_multi
     * @property {string|null} [image] ChargeItem image
     */

    /**
     * Constructs a new ChargeItem.
     * @memberof client_proto
     * @classdesc Represents a ChargeItem.
     * @implements IChargeItem
     * @constructor
     * @param {client_proto.IChargeItem=} [properties] Properties to set
     */
    function ChargeItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChargeItem id.
     * @member {number} id
     * @memberof client_proto.ChargeItem
     * @instance
     */
    ChargeItem.prototype.id = 0;

    /**
     * ChargeItem title.
     * @member {string} title
     * @memberof client_proto.ChargeItem
     * @instance
     */
    ChargeItem.prototype.title = "";

    /**
     * ChargeItem price.
     * @member {number|Long} price
     * @memberof client_proto.ChargeItem
     * @instance
     */
    ChargeItem.prototype.price = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ChargeItem balance.
     * @member {number|Long} balance
     * @memberof client_proto.ChargeItem
     * @instance
     */
    ChargeItem.prototype.balance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ChargeItem bonus.
     * @member {number|Long} bonus
     * @memberof client_proto.ChargeItem
     * @instance
     */
    ChargeItem.prototype.bonus = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ChargeItem flow_multi.
     * @member {number} flow_multi
     * @memberof client_proto.ChargeItem
     * @instance
     */
    ChargeItem.prototype.flow_multi = 0;

    /**
     * ChargeItem image.
     * @member {string} image
     * @memberof client_proto.ChargeItem
     * @instance
     */
    ChargeItem.prototype.image = "";

    /**
     * Creates a new ChargeItem instance using the specified properties.
     * @function create
     * @memberof client_proto.ChargeItem
     * @static
     * @param {client_proto.IChargeItem=} [properties] Properties to set
     * @returns {client_proto.ChargeItem} ChargeItem instance
     */
    ChargeItem.create = function create(properties) {
      return new ChargeItem(properties);
    };

    /**
     * Encodes the specified ChargeItem message. Does not implicitly {@link client_proto.ChargeItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ChargeItem
     * @static
     * @param {client_proto.IChargeItem} message ChargeItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChargeItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.title != null && Object.hasOwnProperty.call(message, "title"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.title);
      if (message.price != null && Object.hasOwnProperty.call(message, "price"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.price);
      if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.balance);
      if (message.bonus != null && Object.hasOwnProperty.call(message, "bonus"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.bonus);
      if (message.flow_multi != null && Object.hasOwnProperty.call(message, "flow_multi"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.flow_multi);
      if (message.image != null && Object.hasOwnProperty.call(message, "image"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.image);
      return writer;
    };

    /**
     * Encodes the specified ChargeItem message, length delimited. Does not implicitly {@link client_proto.ChargeItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ChargeItem
     * @static
     * @param {client_proto.IChargeItem} message ChargeItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChargeItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ChargeItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ChargeItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ChargeItem} ChargeItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChargeItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ChargeItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.title = reader.string();
            break;
          }
          case 3: {
            message.price = reader.int64();
            break;
          }
          case 4: {
            message.balance = reader.int64();
            break;
          }
          case 5: {
            message.bonus = reader.int64();
            break;
          }
          case 6: {
            message.flow_multi = reader.int32();
            break;
          }
          case 7: {
            message.image = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ChargeItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ChargeItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ChargeItem} ChargeItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChargeItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ChargeItem message.
     * @function verify
     * @memberof client_proto.ChargeItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ChargeItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.title != null && message.hasOwnProperty("title"))
        if (!$util.isString(message.title)) return "title: string expected";
      if (message.price != null && message.hasOwnProperty("price"))
        if (
          !$util.isInteger(message.price) &&
          !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high))
        )
          return "price: integer|Long expected";
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (
          !$util.isInteger(message.balance) &&
          !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high))
        )
          return "balance: integer|Long expected";
      if (message.bonus != null && message.hasOwnProperty("bonus"))
        if (
          !$util.isInteger(message.bonus) &&
          !(message.bonus && $util.isInteger(message.bonus.low) && $util.isInteger(message.bonus.high))
        )
          return "bonus: integer|Long expected";
      if (message.flow_multi != null && message.hasOwnProperty("flow_multi"))
        if (!$util.isInteger(message.flow_multi)) return "flow_multi: integer expected";
      if (message.image != null && message.hasOwnProperty("image"))
        if (!$util.isString(message.image)) return "image: string expected";
      return null;
    };

    /**
     * Creates a ChargeItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ChargeItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ChargeItem} ChargeItem
     */
    ChargeItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ChargeItem) return object;
      var message = new $root.client_proto.ChargeItem();
      if (object.id != null) message.id = object.id | 0;
      if (object.title != null) message.title = String(object.title);
      if (object.price != null)
        if ($util.Long) (message.price = $util.Long.fromValue(object.price)).unsigned = false;
        else if (typeof object.price === "string") message.price = parseInt(object.price, 10);
        else if (typeof object.price === "number") message.price = object.price;
        else if (typeof object.price === "object")
          message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
      if (object.balance != null)
        if ($util.Long) (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
        else if (typeof object.balance === "string") message.balance = parseInt(object.balance, 10);
        else if (typeof object.balance === "number") message.balance = object.balance;
        else if (typeof object.balance === "object")
          message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
      if (object.bonus != null)
        if ($util.Long) (message.bonus = $util.Long.fromValue(object.bonus)).unsigned = false;
        else if (typeof object.bonus === "string") message.bonus = parseInt(object.bonus, 10);
        else if (typeof object.bonus === "number") message.bonus = object.bonus;
        else if (typeof object.bonus === "object")
          message.bonus = new $util.LongBits(object.bonus.low >>> 0, object.bonus.high >>> 0).toNumber();
      if (object.flow_multi != null) message.flow_multi = object.flow_multi | 0;
      if (object.image != null) message.image = String(object.image);
      return message;
    };

    /**
     * Creates a plain object from a ChargeItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ChargeItem
     * @static
     * @param {client_proto.ChargeItem} message ChargeItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ChargeItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.title = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.price = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.balance =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.balance = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.bonus = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.bonus = options.longs === String ? "0" : 0;
        object.flow_multi = 0;
        object.image = "";
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.title != null && message.hasOwnProperty("title")) object.title = message.title;
      if (message.price != null && message.hasOwnProperty("price"))
        if (typeof message.price === "number")
          object.price = options.longs === String ? String(message.price) : message.price;
        else
          object.price =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.price)
              : options.longs === Number
                ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber()
                : message.price;
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (typeof message.balance === "number")
          object.balance = options.longs === String ? String(message.balance) : message.balance;
        else
          object.balance =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.balance)
              : options.longs === Number
                ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber()
                : message.balance;
      if (message.bonus != null && message.hasOwnProperty("bonus"))
        if (typeof message.bonus === "number")
          object.bonus = options.longs === String ? String(message.bonus) : message.bonus;
        else
          object.bonus =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.bonus)
              : options.longs === Number
                ? new $util.LongBits(message.bonus.low >>> 0, message.bonus.high >>> 0).toNumber()
                : message.bonus;
      if (message.flow_multi != null && message.hasOwnProperty("flow_multi")) object.flow_multi = message.flow_multi;
      if (message.image != null && message.hasOwnProperty("image")) object.image = message.image;
      return object;
    };

    /**
     * Converts this ChargeItem to JSON.
     * @function toJSON
     * @memberof client_proto.ChargeItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ChargeItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ChargeItem
     * @function getTypeUrl
     * @memberof client_proto.ChargeItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ChargeItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ChargeItem";
    };

    return ChargeItem;
  })();

  client_proto.ChargeConfigResp = (function () {
    /**
     * Properties of a ChargeConfigResp.
     * @memberof client_proto
     * @interface IChargeConfigResp
     * @property {number|null} [charge_count] ChargeConfigResp charge_count
     * @property {Array.<client_proto.IChargeItem>|null} [items] ChargeConfigResp items
     * @property {number|Long|null} [charge_min] ChargeConfigResp charge_min
     * @property {number|Long|null} [charge_max] ChargeConfigResp charge_max
     * @property {number|null} [vip_bonus_ratio] ChargeConfigResp vip_bonus_ratio
     */

    /**
     * Constructs a new ChargeConfigResp.
     * @memberof client_proto
     * @classdesc Represents a ChargeConfigResp.
     * @implements IChargeConfigResp
     * @constructor
     * @param {client_proto.IChargeConfigResp=} [properties] Properties to set
     */
    function ChargeConfigResp(properties) {
      this.items = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChargeConfigResp charge_count.
     * @member {number} charge_count
     * @memberof client_proto.ChargeConfigResp
     * @instance
     */
    ChargeConfigResp.prototype.charge_count = 0;

    /**
     * ChargeConfigResp items.
     * @member {Array.<client_proto.IChargeItem>} items
     * @memberof client_proto.ChargeConfigResp
     * @instance
     */
    ChargeConfigResp.prototype.items = $util.emptyArray;

    /**
     * ChargeConfigResp charge_min.
     * @member {number|Long} charge_min
     * @memberof client_proto.ChargeConfigResp
     * @instance
     */
    ChargeConfigResp.prototype.charge_min = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ChargeConfigResp charge_max.
     * @member {number|Long} charge_max
     * @memberof client_proto.ChargeConfigResp
     * @instance
     */
    ChargeConfigResp.prototype.charge_max = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ChargeConfigResp vip_bonus_ratio.
     * @member {number} vip_bonus_ratio
     * @memberof client_proto.ChargeConfigResp
     * @instance
     */
    ChargeConfigResp.prototype.vip_bonus_ratio = 0;

    /**
     * Creates a new ChargeConfigResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ChargeConfigResp
     * @static
     * @param {client_proto.IChargeConfigResp=} [properties] Properties to set
     * @returns {client_proto.ChargeConfigResp} ChargeConfigResp instance
     */
    ChargeConfigResp.create = function create(properties) {
      return new ChargeConfigResp(properties);
    };

    /**
     * Encodes the specified ChargeConfigResp message. Does not implicitly {@link client_proto.ChargeConfigResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ChargeConfigResp
     * @static
     * @param {client_proto.IChargeConfigResp} message ChargeConfigResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChargeConfigResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.charge_count != null && Object.hasOwnProperty.call(message, "charge_count"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.charge_count);
      if (message.items != null && message.items.length)
        for (var i = 0; i < message.items.length; ++i)
          $root.client_proto.ChargeItem.encode(
            message.items[i],
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim();
      if (message.charge_min != null && Object.hasOwnProperty.call(message, "charge_min"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.charge_min);
      if (message.charge_max != null && Object.hasOwnProperty.call(message, "charge_max"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.charge_max);
      if (message.vip_bonus_ratio != null && Object.hasOwnProperty.call(message, "vip_bonus_ratio"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.vip_bonus_ratio);
      return writer;
    };

    /**
     * Encodes the specified ChargeConfigResp message, length delimited. Does not implicitly {@link client_proto.ChargeConfigResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ChargeConfigResp
     * @static
     * @param {client_proto.IChargeConfigResp} message ChargeConfigResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChargeConfigResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ChargeConfigResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ChargeConfigResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ChargeConfigResp} ChargeConfigResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChargeConfigResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ChargeConfigResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.charge_count = reader.int32();
            break;
          }
          case 3: {
            if (!(message.items && message.items.length)) message.items = [];
            message.items.push($root.client_proto.ChargeItem.decode(reader, reader.uint32()));
            break;
          }
          case 4: {
            message.charge_min = reader.int64();
            break;
          }
          case 5: {
            message.charge_max = reader.int64();
            break;
          }
          case 6: {
            message.vip_bonus_ratio = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ChargeConfigResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ChargeConfigResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ChargeConfigResp} ChargeConfigResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChargeConfigResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ChargeConfigResp message.
     * @function verify
     * @memberof client_proto.ChargeConfigResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ChargeConfigResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.charge_count != null && message.hasOwnProperty("charge_count"))
        if (!$util.isInteger(message.charge_count)) return "charge_count: integer expected";
      if (message.items != null && message.hasOwnProperty("items")) {
        if (!Array.isArray(message.items)) return "items: array expected";
        for (var i = 0; i < message.items.length; ++i) {
          var error = $root.client_proto.ChargeItem.verify(message.items[i]);
          if (error) return "items." + error;
        }
      }
      if (message.charge_min != null && message.hasOwnProperty("charge_min"))
        if (
          !$util.isInteger(message.charge_min) &&
          !(message.charge_min && $util.isInteger(message.charge_min.low) && $util.isInteger(message.charge_min.high))
        )
          return "charge_min: integer|Long expected";
      if (message.charge_max != null && message.hasOwnProperty("charge_max"))
        if (
          !$util.isInteger(message.charge_max) &&
          !(message.charge_max && $util.isInteger(message.charge_max.low) && $util.isInteger(message.charge_max.high))
        )
          return "charge_max: integer|Long expected";
      if (message.vip_bonus_ratio != null && message.hasOwnProperty("vip_bonus_ratio"))
        if (!$util.isInteger(message.vip_bonus_ratio)) return "vip_bonus_ratio: integer expected";
      return null;
    };

    /**
     * Creates a ChargeConfigResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ChargeConfigResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ChargeConfigResp} ChargeConfigResp
     */
    ChargeConfigResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ChargeConfigResp) return object;
      var message = new $root.client_proto.ChargeConfigResp();
      if (object.charge_count != null) message.charge_count = object.charge_count | 0;
      if (object.items) {
        if (!Array.isArray(object.items)) throw TypeError(".client_proto.ChargeConfigResp.items: array expected");
        message.items = [];
        for (var i = 0; i < object.items.length; ++i) {
          if (typeof object.items[i] !== "object")
            throw TypeError(".client_proto.ChargeConfigResp.items: object expected");
          message.items[i] = $root.client_proto.ChargeItem.fromObject(object.items[i]);
        }
      }
      if (object.charge_min != null)
        if ($util.Long) (message.charge_min = $util.Long.fromValue(object.charge_min)).unsigned = false;
        else if (typeof object.charge_min === "string") message.charge_min = parseInt(object.charge_min, 10);
        else if (typeof object.charge_min === "number") message.charge_min = object.charge_min;
        else if (typeof object.charge_min === "object")
          message.charge_min = new $util.LongBits(object.charge_min.low >>> 0, object.charge_min.high >>> 0).toNumber();
      if (object.charge_max != null)
        if ($util.Long) (message.charge_max = $util.Long.fromValue(object.charge_max)).unsigned = false;
        else if (typeof object.charge_max === "string") message.charge_max = parseInt(object.charge_max, 10);
        else if (typeof object.charge_max === "number") message.charge_max = object.charge_max;
        else if (typeof object.charge_max === "object")
          message.charge_max = new $util.LongBits(object.charge_max.low >>> 0, object.charge_max.high >>> 0).toNumber();
      if (object.vip_bonus_ratio != null) message.vip_bonus_ratio = object.vip_bonus_ratio | 0;
      return message;
    };

    /**
     * Creates a plain object from a ChargeConfigResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ChargeConfigResp
     * @static
     * @param {client_proto.ChargeConfigResp} message ChargeConfigResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ChargeConfigResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.items = [];
      if (options.defaults) {
        object.charge_count = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.charge_min =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.charge_min = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.charge_max =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.charge_max = options.longs === String ? "0" : 0;
        object.vip_bonus_ratio = 0;
      }
      if (message.charge_count != null && message.hasOwnProperty("charge_count"))
        object.charge_count = message.charge_count;
      if (message.items && message.items.length) {
        object.items = [];
        for (var j = 0; j < message.items.length; ++j)
          object.items[j] = $root.client_proto.ChargeItem.toObject(message.items[j], options);
      }
      if (message.charge_min != null && message.hasOwnProperty("charge_min"))
        if (typeof message.charge_min === "number")
          object.charge_min = options.longs === String ? String(message.charge_min) : message.charge_min;
        else
          object.charge_min =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.charge_min)
              : options.longs === Number
                ? new $util.LongBits(message.charge_min.low >>> 0, message.charge_min.high >>> 0).toNumber()
                : message.charge_min;
      if (message.charge_max != null && message.hasOwnProperty("charge_max"))
        if (typeof message.charge_max === "number")
          object.charge_max = options.longs === String ? String(message.charge_max) : message.charge_max;
        else
          object.charge_max =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.charge_max)
              : options.longs === Number
                ? new $util.LongBits(message.charge_max.low >>> 0, message.charge_max.high >>> 0).toNumber()
                : message.charge_max;
      if (message.vip_bonus_ratio != null && message.hasOwnProperty("vip_bonus_ratio"))
        object.vip_bonus_ratio = message.vip_bonus_ratio;
      return object;
    };

    /**
     * Converts this ChargeConfigResp to JSON.
     * @function toJSON
     * @memberof client_proto.ChargeConfigResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ChargeConfigResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ChargeConfigResp
     * @function getTypeUrl
     * @memberof client_proto.ChargeConfigResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ChargeConfigResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ChargeConfigResp";
    };

    return ChargeConfigResp;
  })();

  client_proto.CreateChargeOrderReq = (function () {
    /**
     * Properties of a CreateChargeOrderReq.
     * @memberof client_proto
     * @interface ICreateChargeOrderReq
     * @property {number|null} [charge_id] CreateChargeOrderReq charge_id
     * @property {boolean|null} [bonus_checked] CreateChargeOrderReq bonus_checked
     * @property {number|null} [banner_goods] CreateChargeOrderReq banner_goods
     * @property {string|null} [order_params] CreateChargeOrderReq order_params
     */

    /**
     * Constructs a new CreateChargeOrderReq.
     * @memberof client_proto
     * @classdesc Represents a CreateChargeOrderReq.
     * @implements ICreateChargeOrderReq
     * @constructor
     * @param {client_proto.ICreateChargeOrderReq=} [properties] Properties to set
     */
    function CreateChargeOrderReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateChargeOrderReq charge_id.
     * @member {number} charge_id
     * @memberof client_proto.CreateChargeOrderReq
     * @instance
     */
    CreateChargeOrderReq.prototype.charge_id = 0;

    /**
     * CreateChargeOrderReq bonus_checked.
     * @member {boolean} bonus_checked
     * @memberof client_proto.CreateChargeOrderReq
     * @instance
     */
    CreateChargeOrderReq.prototype.bonus_checked = false;

    /**
     * CreateChargeOrderReq banner_goods.
     * @member {number} banner_goods
     * @memberof client_proto.CreateChargeOrderReq
     * @instance
     */
    CreateChargeOrderReq.prototype.banner_goods = 0;

    /**
     * CreateChargeOrderReq order_params.
     * @member {string} order_params
     * @memberof client_proto.CreateChargeOrderReq
     * @instance
     */
    CreateChargeOrderReq.prototype.order_params = "";

    /**
     * Creates a new CreateChargeOrderReq instance using the specified properties.
     * @function create
     * @memberof client_proto.CreateChargeOrderReq
     * @static
     * @param {client_proto.ICreateChargeOrderReq=} [properties] Properties to set
     * @returns {client_proto.CreateChargeOrderReq} CreateChargeOrderReq instance
     */
    CreateChargeOrderReq.create = function create(properties) {
      return new CreateChargeOrderReq(properties);
    };

    /**
     * Encodes the specified CreateChargeOrderReq message. Does not implicitly {@link client_proto.CreateChargeOrderReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CreateChargeOrderReq
     * @static
     * @param {client_proto.ICreateChargeOrderReq} message CreateChargeOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateChargeOrderReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.charge_id != null && Object.hasOwnProperty.call(message, "charge_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.charge_id);
      if (message.bonus_checked != null && Object.hasOwnProperty.call(message, "bonus_checked"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.bonus_checked);
      if (message.banner_goods != null && Object.hasOwnProperty.call(message, "banner_goods"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.banner_goods);
      if (message.order_params != null && Object.hasOwnProperty.call(message, "order_params"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.order_params);
      return writer;
    };

    /**
     * Encodes the specified CreateChargeOrderReq message, length delimited. Does not implicitly {@link client_proto.CreateChargeOrderReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CreateChargeOrderReq
     * @static
     * @param {client_proto.ICreateChargeOrderReq} message CreateChargeOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateChargeOrderReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateChargeOrderReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CreateChargeOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CreateChargeOrderReq} CreateChargeOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateChargeOrderReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CreateChargeOrderReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.charge_id = reader.int32();
            break;
          }
          case 2: {
            message.bonus_checked = reader.bool();
            break;
          }
          case 3: {
            message.banner_goods = reader.int32();
            break;
          }
          case 4: {
            message.order_params = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateChargeOrderReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CreateChargeOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CreateChargeOrderReq} CreateChargeOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateChargeOrderReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateChargeOrderReq message.
     * @function verify
     * @memberof client_proto.CreateChargeOrderReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateChargeOrderReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.charge_id != null && message.hasOwnProperty("charge_id"))
        if (!$util.isInteger(message.charge_id)) return "charge_id: integer expected";
      if (message.bonus_checked != null && message.hasOwnProperty("bonus_checked"))
        if (typeof message.bonus_checked !== "boolean") return "bonus_checked: boolean expected";
      if (message.banner_goods != null && message.hasOwnProperty("banner_goods"))
        if (!$util.isInteger(message.banner_goods)) return "banner_goods: integer expected";
      if (message.order_params != null && message.hasOwnProperty("order_params"))
        if (!$util.isString(message.order_params)) return "order_params: string expected";
      return null;
    };

    /**
     * Creates a CreateChargeOrderReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CreateChargeOrderReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CreateChargeOrderReq} CreateChargeOrderReq
     */
    CreateChargeOrderReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CreateChargeOrderReq) return object;
      var message = new $root.client_proto.CreateChargeOrderReq();
      if (object.charge_id != null) message.charge_id = object.charge_id | 0;
      if (object.bonus_checked != null) message.bonus_checked = Boolean(object.bonus_checked);
      if (object.banner_goods != null) message.banner_goods = object.banner_goods | 0;
      if (object.order_params != null) message.order_params = String(object.order_params);
      return message;
    };

    /**
     * Creates a plain object from a CreateChargeOrderReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CreateChargeOrderReq
     * @static
     * @param {client_proto.CreateChargeOrderReq} message CreateChargeOrderReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateChargeOrderReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.charge_id = 0;
        object.bonus_checked = false;
        object.banner_goods = 0;
        object.order_params = "";
      }
      if (message.charge_id != null && message.hasOwnProperty("charge_id")) object.charge_id = message.charge_id;
      if (message.bonus_checked != null && message.hasOwnProperty("bonus_checked"))
        object.bonus_checked = message.bonus_checked;
      if (message.banner_goods != null && message.hasOwnProperty("banner_goods"))
        object.banner_goods = message.banner_goods;
      if (message.order_params != null && message.hasOwnProperty("order_params"))
        object.order_params = message.order_params;
      return object;
    };

    /**
     * Converts this CreateChargeOrderReq to JSON.
     * @function toJSON
     * @memberof client_proto.CreateChargeOrderReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateChargeOrderReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateChargeOrderReq
     * @function getTypeUrl
     * @memberof client_proto.CreateChargeOrderReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateChargeOrderReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CreateChargeOrderReq";
    };

    return CreateChargeOrderReq;
  })();

  client_proto.CreateChargeOrderResp = (function () {
    /**
     * Properties of a CreateChargeOrderResp.
     * @memberof client_proto
     * @interface ICreateChargeOrderResp
     * @property {number|null} [ret] CreateChargeOrderResp ret
     * @property {string|null} [order_info] CreateChargeOrderResp order_info
     */

    /**
     * Constructs a new CreateChargeOrderResp.
     * @memberof client_proto
     * @classdesc Represents a CreateChargeOrderResp.
     * @implements ICreateChargeOrderResp
     * @constructor
     * @param {client_proto.ICreateChargeOrderResp=} [properties] Properties to set
     */
    function CreateChargeOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateChargeOrderResp ret.
     * @member {number} ret
     * @memberof client_proto.CreateChargeOrderResp
     * @instance
     */
    CreateChargeOrderResp.prototype.ret = 0;

    /**
     * CreateChargeOrderResp order_info.
     * @member {string} order_info
     * @memberof client_proto.CreateChargeOrderResp
     * @instance
     */
    CreateChargeOrderResp.prototype.order_info = "";

    /**
     * Creates a new CreateChargeOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.CreateChargeOrderResp
     * @static
     * @param {client_proto.ICreateChargeOrderResp=} [properties] Properties to set
     * @returns {client_proto.CreateChargeOrderResp} CreateChargeOrderResp instance
     */
    CreateChargeOrderResp.create = function create(properties) {
      return new CreateChargeOrderResp(properties);
    };

    /**
     * Encodes the specified CreateChargeOrderResp message. Does not implicitly {@link client_proto.CreateChargeOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CreateChargeOrderResp
     * @static
     * @param {client_proto.ICreateChargeOrderResp} message CreateChargeOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateChargeOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.ret != null && Object.hasOwnProperty.call(message, "ret"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.ret);
      if (message.order_info != null && Object.hasOwnProperty.call(message, "order_info"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.order_info);
      return writer;
    };

    /**
     * Encodes the specified CreateChargeOrderResp message, length delimited. Does not implicitly {@link client_proto.CreateChargeOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CreateChargeOrderResp
     * @static
     * @param {client_proto.ICreateChargeOrderResp} message CreateChargeOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateChargeOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateChargeOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CreateChargeOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CreateChargeOrderResp} CreateChargeOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateChargeOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CreateChargeOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.ret = reader.int32();
            break;
          }
          case 2: {
            message.order_info = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateChargeOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CreateChargeOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CreateChargeOrderResp} CreateChargeOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateChargeOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateChargeOrderResp message.
     * @function verify
     * @memberof client_proto.CreateChargeOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateChargeOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.ret != null && message.hasOwnProperty("ret"))
        if (!$util.isInteger(message.ret)) return "ret: integer expected";
      if (message.order_info != null && message.hasOwnProperty("order_info"))
        if (!$util.isString(message.order_info)) return "order_info: string expected";
      return null;
    };

    /**
     * Creates a CreateChargeOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CreateChargeOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CreateChargeOrderResp} CreateChargeOrderResp
     */
    CreateChargeOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CreateChargeOrderResp) return object;
      var message = new $root.client_proto.CreateChargeOrderResp();
      if (object.ret != null) message.ret = object.ret | 0;
      if (object.order_info != null) message.order_info = String(object.order_info);
      return message;
    };

    /**
     * Creates a plain object from a CreateChargeOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CreateChargeOrderResp
     * @static
     * @param {client_proto.CreateChargeOrderResp} message CreateChargeOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateChargeOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.ret = 0;
        object.order_info = "";
      }
      if (message.ret != null && message.hasOwnProperty("ret")) object.ret = message.ret;
      if (message.order_info != null && message.hasOwnProperty("order_info")) object.order_info = message.order_info;
      return object;
    };

    /**
     * Converts this CreateChargeOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.CreateChargeOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateChargeOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateChargeOrderResp
     * @function getTypeUrl
     * @memberof client_proto.CreateChargeOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateChargeOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CreateChargeOrderResp";
    };

    return CreateChargeOrderResp;
  })();

  client_proto.CompleteOrderResp = (function () {
    /**
     * Properties of a CompleteOrderResp.
     * @memberof client_proto
     * @interface ICompleteOrderResp
     * @property {number|null} [ret] CompleteOrderResp ret
     */

    /**
     * Constructs a new CompleteOrderResp.
     * @memberof client_proto
     * @classdesc Represents a CompleteOrderResp.
     * @implements ICompleteOrderResp
     * @constructor
     * @param {client_proto.ICompleteOrderResp=} [properties] Properties to set
     */
    function CompleteOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CompleteOrderResp ret.
     * @member {number} ret
     * @memberof client_proto.CompleteOrderResp
     * @instance
     */
    CompleteOrderResp.prototype.ret = 0;

    /**
     * Creates a new CompleteOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.CompleteOrderResp
     * @static
     * @param {client_proto.ICompleteOrderResp=} [properties] Properties to set
     * @returns {client_proto.CompleteOrderResp} CompleteOrderResp instance
     */
    CompleteOrderResp.create = function create(properties) {
      return new CompleteOrderResp(properties);
    };

    /**
     * Encodes the specified CompleteOrderResp message. Does not implicitly {@link client_proto.CompleteOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CompleteOrderResp
     * @static
     * @param {client_proto.ICompleteOrderResp} message CompleteOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CompleteOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.ret != null && Object.hasOwnProperty.call(message, "ret"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.ret);
      return writer;
    };

    /**
     * Encodes the specified CompleteOrderResp message, length delimited. Does not implicitly {@link client_proto.CompleteOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CompleteOrderResp
     * @static
     * @param {client_proto.ICompleteOrderResp} message CompleteOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CompleteOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CompleteOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CompleteOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CompleteOrderResp} CompleteOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CompleteOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CompleteOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.ret = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CompleteOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CompleteOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CompleteOrderResp} CompleteOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CompleteOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CompleteOrderResp message.
     * @function verify
     * @memberof client_proto.CompleteOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CompleteOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.ret != null && message.hasOwnProperty("ret"))
        if (!$util.isInteger(message.ret)) return "ret: integer expected";
      return null;
    };

    /**
     * Creates a CompleteOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CompleteOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CompleteOrderResp} CompleteOrderResp
     */
    CompleteOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CompleteOrderResp) return object;
      var message = new $root.client_proto.CompleteOrderResp();
      if (object.ret != null) message.ret = object.ret | 0;
      return message;
    };

    /**
     * Creates a plain object from a CompleteOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CompleteOrderResp
     * @static
     * @param {client_proto.CompleteOrderResp} message CompleteOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CompleteOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.ret = 0;
      if (message.ret != null && message.hasOwnProperty("ret")) object.ret = message.ret;
      return object;
    };

    /**
     * Converts this CompleteOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.CompleteOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CompleteOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CompleteOrderResp
     * @function getTypeUrl
     * @memberof client_proto.CompleteOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CompleteOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CompleteOrderResp";
    };

    return CompleteOrderResp;
  })();

  client_proto.GetChargeLogReq = (function () {
    /**
     * Properties of a GetChargeLogReq.
     * @memberof client_proto
     * @interface IGetChargeLogReq
     * @property {number|null} [period] GetChargeLogReq period
     */

    /**
     * Constructs a new GetChargeLogReq.
     * @memberof client_proto
     * @classdesc Represents a GetChargeLogReq.
     * @implements IGetChargeLogReq
     * @constructor
     * @param {client_proto.IGetChargeLogReq=} [properties] Properties to set
     */
    function GetChargeLogReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetChargeLogReq period.
     * @member {number} period
     * @memberof client_proto.GetChargeLogReq
     * @instance
     */
    GetChargeLogReq.prototype.period = 0;

    /**
     * Creates a new GetChargeLogReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetChargeLogReq
     * @static
     * @param {client_proto.IGetChargeLogReq=} [properties] Properties to set
     * @returns {client_proto.GetChargeLogReq} GetChargeLogReq instance
     */
    GetChargeLogReq.create = function create(properties) {
      return new GetChargeLogReq(properties);
    };

    /**
     * Encodes the specified GetChargeLogReq message. Does not implicitly {@link client_proto.GetChargeLogReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetChargeLogReq
     * @static
     * @param {client_proto.IGetChargeLogReq} message GetChargeLogReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetChargeLogReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.period != null && Object.hasOwnProperty.call(message, "period"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.period);
      return writer;
    };

    /**
     * Encodes the specified GetChargeLogReq message, length delimited. Does not implicitly {@link client_proto.GetChargeLogReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetChargeLogReq
     * @static
     * @param {client_proto.IGetChargeLogReq} message GetChargeLogReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetChargeLogReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetChargeLogReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetChargeLogReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetChargeLogReq} GetChargeLogReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetChargeLogReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetChargeLogReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.period = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetChargeLogReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetChargeLogReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetChargeLogReq} GetChargeLogReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetChargeLogReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetChargeLogReq message.
     * @function verify
     * @memberof client_proto.GetChargeLogReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetChargeLogReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.period != null && message.hasOwnProperty("period"))
        if (!$util.isInteger(message.period)) return "period: integer expected";
      return null;
    };

    /**
     * Creates a GetChargeLogReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetChargeLogReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetChargeLogReq} GetChargeLogReq
     */
    GetChargeLogReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetChargeLogReq) return object;
      var message = new $root.client_proto.GetChargeLogReq();
      if (object.period != null) message.period = object.period | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetChargeLogReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetChargeLogReq
     * @static
     * @param {client_proto.GetChargeLogReq} message GetChargeLogReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetChargeLogReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.period = 0;
      if (message.period != null && message.hasOwnProperty("period")) object.period = message.period;
      return object;
    };

    /**
     * Converts this GetChargeLogReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetChargeLogReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetChargeLogReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetChargeLogReq
     * @function getTypeUrl
     * @memberof client_proto.GetChargeLogReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetChargeLogReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetChargeLogReq";
    };

    return GetChargeLogReq;
  })();

  client_proto.ChargeLogItem = (function () {
    /**
     * Properties of a ChargeLogItem.
     * @memberof client_proto
     * @interface IChargeLogItem
     * @property {number|null} [time] ChargeLogItem time
     * @property {string|null} [order_num] ChargeLogItem order_num
     * @property {number|Long|null} [cash] ChargeLogItem cash
     * @property {number|Long|null} [bonus] ChargeLogItem bonus
     * @property {number|null} [status] ChargeLogItem status
     * @property {number|null} [finish_time] ChargeLogItem finish_time
     */

    /**
     * Constructs a new ChargeLogItem.
     * @memberof client_proto
     * @classdesc Represents a ChargeLogItem.
     * @implements IChargeLogItem
     * @constructor
     * @param {client_proto.IChargeLogItem=} [properties] Properties to set
     */
    function ChargeLogItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChargeLogItem time.
     * @member {number} time
     * @memberof client_proto.ChargeLogItem
     * @instance
     */
    ChargeLogItem.prototype.time = 0;

    /**
     * ChargeLogItem order_num.
     * @member {string} order_num
     * @memberof client_proto.ChargeLogItem
     * @instance
     */
    ChargeLogItem.prototype.order_num = "";

    /**
     * ChargeLogItem cash.
     * @member {number|Long} cash
     * @memberof client_proto.ChargeLogItem
     * @instance
     */
    ChargeLogItem.prototype.cash = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ChargeLogItem bonus.
     * @member {number|Long} bonus
     * @memberof client_proto.ChargeLogItem
     * @instance
     */
    ChargeLogItem.prototype.bonus = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ChargeLogItem status.
     * @member {number} status
     * @memberof client_proto.ChargeLogItem
     * @instance
     */
    ChargeLogItem.prototype.status = 0;

    /**
     * ChargeLogItem finish_time.
     * @member {number} finish_time
     * @memberof client_proto.ChargeLogItem
     * @instance
     */
    ChargeLogItem.prototype.finish_time = 0;

    /**
     * Creates a new ChargeLogItem instance using the specified properties.
     * @function create
     * @memberof client_proto.ChargeLogItem
     * @static
     * @param {client_proto.IChargeLogItem=} [properties] Properties to set
     * @returns {client_proto.ChargeLogItem} ChargeLogItem instance
     */
    ChargeLogItem.create = function create(properties) {
      return new ChargeLogItem(properties);
    };

    /**
     * Encodes the specified ChargeLogItem message. Does not implicitly {@link client_proto.ChargeLogItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ChargeLogItem
     * @static
     * @param {client_proto.IChargeLogItem} message ChargeLogItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChargeLogItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.time != null && Object.hasOwnProperty.call(message, "time"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.time);
      if (message.order_num != null && Object.hasOwnProperty.call(message, "order_num"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.order_num);
      if (message.cash != null && Object.hasOwnProperty.call(message, "cash"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.cash);
      if (message.bonus != null && Object.hasOwnProperty.call(message, "bonus"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.bonus);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.status);
      if (message.finish_time != null && Object.hasOwnProperty.call(message, "finish_time"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.finish_time);
      return writer;
    };

    /**
     * Encodes the specified ChargeLogItem message, length delimited. Does not implicitly {@link client_proto.ChargeLogItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ChargeLogItem
     * @static
     * @param {client_proto.IChargeLogItem} message ChargeLogItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChargeLogItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ChargeLogItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ChargeLogItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ChargeLogItem} ChargeLogItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChargeLogItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ChargeLogItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.time = reader.int32();
            break;
          }
          case 2: {
            message.order_num = reader.string();
            break;
          }
          case 3: {
            message.cash = reader.int64();
            break;
          }
          case 4: {
            message.bonus = reader.int64();
            break;
          }
          case 5: {
            message.status = reader.int32();
            break;
          }
          case 6: {
            message.finish_time = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ChargeLogItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ChargeLogItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ChargeLogItem} ChargeLogItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChargeLogItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ChargeLogItem message.
     * @function verify
     * @memberof client_proto.ChargeLogItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ChargeLogItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.time != null && message.hasOwnProperty("time"))
        if (!$util.isInteger(message.time)) return "time: integer expected";
      if (message.order_num != null && message.hasOwnProperty("order_num"))
        if (!$util.isString(message.order_num)) return "order_num: string expected";
      if (message.cash != null && message.hasOwnProperty("cash"))
        if (
          !$util.isInteger(message.cash) &&
          !(message.cash && $util.isInteger(message.cash.low) && $util.isInteger(message.cash.high))
        )
          return "cash: integer|Long expected";
      if (message.bonus != null && message.hasOwnProperty("bonus"))
        if (
          !$util.isInteger(message.bonus) &&
          !(message.bonus && $util.isInteger(message.bonus.low) && $util.isInteger(message.bonus.high))
        )
          return "bonus: integer|Long expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      if (message.finish_time != null && message.hasOwnProperty("finish_time"))
        if (!$util.isInteger(message.finish_time)) return "finish_time: integer expected";
      return null;
    };

    /**
     * Creates a ChargeLogItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ChargeLogItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ChargeLogItem} ChargeLogItem
     */
    ChargeLogItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ChargeLogItem) return object;
      var message = new $root.client_proto.ChargeLogItem();
      if (object.time != null) message.time = object.time | 0;
      if (object.order_num != null) message.order_num = String(object.order_num);
      if (object.cash != null)
        if ($util.Long) (message.cash = $util.Long.fromValue(object.cash)).unsigned = false;
        else if (typeof object.cash === "string") message.cash = parseInt(object.cash, 10);
        else if (typeof object.cash === "number") message.cash = object.cash;
        else if (typeof object.cash === "object")
          message.cash = new $util.LongBits(object.cash.low >>> 0, object.cash.high >>> 0).toNumber();
      if (object.bonus != null)
        if ($util.Long) (message.bonus = $util.Long.fromValue(object.bonus)).unsigned = false;
        else if (typeof object.bonus === "string") message.bonus = parseInt(object.bonus, 10);
        else if (typeof object.bonus === "number") message.bonus = object.bonus;
        else if (typeof object.bonus === "object")
          message.bonus = new $util.LongBits(object.bonus.low >>> 0, object.bonus.high >>> 0).toNumber();
      if (object.status != null) message.status = object.status | 0;
      if (object.finish_time != null) message.finish_time = object.finish_time | 0;
      return message;
    };

    /**
     * Creates a plain object from a ChargeLogItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ChargeLogItem
     * @static
     * @param {client_proto.ChargeLogItem} message ChargeLogItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ChargeLogItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.time = 0;
        object.order_num = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.cash = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.cash = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.bonus = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.bonus = options.longs === String ? "0" : 0;
        object.status = 0;
        object.finish_time = 0;
      }
      if (message.time != null && message.hasOwnProperty("time")) object.time = message.time;
      if (message.order_num != null && message.hasOwnProperty("order_num")) object.order_num = message.order_num;
      if (message.cash != null && message.hasOwnProperty("cash"))
        if (typeof message.cash === "number")
          object.cash = options.longs === String ? String(message.cash) : message.cash;
        else
          object.cash =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.cash)
              : options.longs === Number
                ? new $util.LongBits(message.cash.low >>> 0, message.cash.high >>> 0).toNumber()
                : message.cash;
      if (message.bonus != null && message.hasOwnProperty("bonus"))
        if (typeof message.bonus === "number")
          object.bonus = options.longs === String ? String(message.bonus) : message.bonus;
        else
          object.bonus =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.bonus)
              : options.longs === Number
                ? new $util.LongBits(message.bonus.low >>> 0, message.bonus.high >>> 0).toNumber()
                : message.bonus;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      if (message.finish_time != null && message.hasOwnProperty("finish_time"))
        object.finish_time = message.finish_time;
      return object;
    };

    /**
     * Converts this ChargeLogItem to JSON.
     * @function toJSON
     * @memberof client_proto.ChargeLogItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ChargeLogItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ChargeLogItem
     * @function getTypeUrl
     * @memberof client_proto.ChargeLogItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ChargeLogItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ChargeLogItem";
    };

    return ChargeLogItem;
  })();

  client_proto.GetChargeLogResp = (function () {
    /**
     * Properties of a GetChargeLogResp.
     * @memberof client_proto
     * @interface IGetChargeLogResp
     * @property {number|Long|null} [total_amount] GetChargeLogResp total_amount
     * @property {Array.<client_proto.IChargeLogItem>|null} [items] GetChargeLogResp items
     * @property {number|null} [cur_page] GetChargeLogResp cur_page
     * @property {number|null} [total_page] GetChargeLogResp total_page
     */

    /**
     * Constructs a new GetChargeLogResp.
     * @memberof client_proto
     * @classdesc Represents a GetChargeLogResp.
     * @implements IGetChargeLogResp
     * @constructor
     * @param {client_proto.IGetChargeLogResp=} [properties] Properties to set
     */
    function GetChargeLogResp(properties) {
      this.items = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetChargeLogResp total_amount.
     * @member {number|Long} total_amount
     * @memberof client_proto.GetChargeLogResp
     * @instance
     */
    GetChargeLogResp.prototype.total_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GetChargeLogResp items.
     * @member {Array.<client_proto.IChargeLogItem>} items
     * @memberof client_proto.GetChargeLogResp
     * @instance
     */
    GetChargeLogResp.prototype.items = $util.emptyArray;

    /**
     * GetChargeLogResp cur_page.
     * @member {number} cur_page
     * @memberof client_proto.GetChargeLogResp
     * @instance
     */
    GetChargeLogResp.prototype.cur_page = 0;

    /**
     * GetChargeLogResp total_page.
     * @member {number} total_page
     * @memberof client_proto.GetChargeLogResp
     * @instance
     */
    GetChargeLogResp.prototype.total_page = 0;

    /**
     * Creates a new GetChargeLogResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetChargeLogResp
     * @static
     * @param {client_proto.IGetChargeLogResp=} [properties] Properties to set
     * @returns {client_proto.GetChargeLogResp} GetChargeLogResp instance
     */
    GetChargeLogResp.create = function create(properties) {
      return new GetChargeLogResp(properties);
    };

    /**
     * Encodes the specified GetChargeLogResp message. Does not implicitly {@link client_proto.GetChargeLogResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetChargeLogResp
     * @static
     * @param {client_proto.IGetChargeLogResp} message GetChargeLogResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetChargeLogResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.total_amount != null && Object.hasOwnProperty.call(message, "total_amount"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.total_amount);
      if (message.items != null && message.items.length)
        for (var i = 0; i < message.items.length; ++i)
          $root.client_proto.ChargeLogItem.encode(
            message.items[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim();
      if (message.cur_page != null && Object.hasOwnProperty.call(message, "cur_page"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.cur_page);
      if (message.total_page != null && Object.hasOwnProperty.call(message, "total_page"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.total_page);
      return writer;
    };

    /**
     * Encodes the specified GetChargeLogResp message, length delimited. Does not implicitly {@link client_proto.GetChargeLogResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetChargeLogResp
     * @static
     * @param {client_proto.IGetChargeLogResp} message GetChargeLogResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetChargeLogResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetChargeLogResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetChargeLogResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetChargeLogResp} GetChargeLogResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetChargeLogResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetChargeLogResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.total_amount = reader.int64();
            break;
          }
          case 2: {
            if (!(message.items && message.items.length)) message.items = [];
            message.items.push($root.client_proto.ChargeLogItem.decode(reader, reader.uint32()));
            break;
          }
          case 3: {
            message.cur_page = reader.int32();
            break;
          }
          case 4: {
            message.total_page = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetChargeLogResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetChargeLogResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetChargeLogResp} GetChargeLogResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetChargeLogResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetChargeLogResp message.
     * @function verify
     * @memberof client_proto.GetChargeLogResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetChargeLogResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.total_amount != null && message.hasOwnProperty("total_amount"))
        if (
          !$util.isInteger(message.total_amount) &&
          !(
            message.total_amount &&
            $util.isInteger(message.total_amount.low) &&
            $util.isInteger(message.total_amount.high)
          )
        )
          return "total_amount: integer|Long expected";
      if (message.items != null && message.hasOwnProperty("items")) {
        if (!Array.isArray(message.items)) return "items: array expected";
        for (var i = 0; i < message.items.length; ++i) {
          var error = $root.client_proto.ChargeLogItem.verify(message.items[i]);
          if (error) return "items." + error;
        }
      }
      if (message.cur_page != null && message.hasOwnProperty("cur_page"))
        if (!$util.isInteger(message.cur_page)) return "cur_page: integer expected";
      if (message.total_page != null && message.hasOwnProperty("total_page"))
        if (!$util.isInteger(message.total_page)) return "total_page: integer expected";
      return null;
    };

    /**
     * Creates a GetChargeLogResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetChargeLogResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetChargeLogResp} GetChargeLogResp
     */
    GetChargeLogResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetChargeLogResp) return object;
      var message = new $root.client_proto.GetChargeLogResp();
      if (object.total_amount != null)
        if ($util.Long) (message.total_amount = $util.Long.fromValue(object.total_amount)).unsigned = false;
        else if (typeof object.total_amount === "string") message.total_amount = parseInt(object.total_amount, 10);
        else if (typeof object.total_amount === "number") message.total_amount = object.total_amount;
        else if (typeof object.total_amount === "object")
          message.total_amount = new $util.LongBits(
            object.total_amount.low >>> 0,
            object.total_amount.high >>> 0,
          ).toNumber();
      if (object.items) {
        if (!Array.isArray(object.items)) throw TypeError(".client_proto.GetChargeLogResp.items: array expected");
        message.items = [];
        for (var i = 0; i < object.items.length; ++i) {
          if (typeof object.items[i] !== "object")
            throw TypeError(".client_proto.GetChargeLogResp.items: object expected");
          message.items[i] = $root.client_proto.ChargeLogItem.fromObject(object.items[i]);
        }
      }
      if (object.cur_page != null) message.cur_page = object.cur_page | 0;
      if (object.total_page != null) message.total_page = object.total_page | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetChargeLogResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetChargeLogResp
     * @static
     * @param {client_proto.GetChargeLogResp} message GetChargeLogResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetChargeLogResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.items = [];
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_amount = options.longs === String ? "0" : 0;
        object.cur_page = 0;
        object.total_page = 0;
      }
      if (message.total_amount != null && message.hasOwnProperty("total_amount"))
        if (typeof message.total_amount === "number")
          object.total_amount = options.longs === String ? String(message.total_amount) : message.total_amount;
        else
          object.total_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_amount)
              : options.longs === Number
                ? new $util.LongBits(message.total_amount.low >>> 0, message.total_amount.high >>> 0).toNumber()
                : message.total_amount;
      if (message.items && message.items.length) {
        object.items = [];
        for (var j = 0; j < message.items.length; ++j)
          object.items[j] = $root.client_proto.ChargeLogItem.toObject(message.items[j], options);
      }
      if (message.cur_page != null && message.hasOwnProperty("cur_page")) object.cur_page = message.cur_page;
      if (message.total_page != null && message.hasOwnProperty("total_page")) object.total_page = message.total_page;
      return object;
    };

    /**
     * Converts this GetChargeLogResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetChargeLogResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetChargeLogResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetChargeLogResp
     * @function getTypeUrl
     * @memberof client_proto.GetChargeLogResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetChargeLogResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetChargeLogResp";
    };

    return GetChargeLogResp;
  })();

  client_proto.GetWithdrawLogReq = (function () {
    /**
     * Properties of a GetWithdrawLogReq.
     * @memberof client_proto
     * @interface IGetWithdrawLogReq
     * @property {number|null} [period] GetWithdrawLogReq period
     */

    /**
     * Constructs a new GetWithdrawLogReq.
     * @memberof client_proto
     * @classdesc Represents a GetWithdrawLogReq.
     * @implements IGetWithdrawLogReq
     * @constructor
     * @param {client_proto.IGetWithdrawLogReq=} [properties] Properties to set
     */
    function GetWithdrawLogReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetWithdrawLogReq period.
     * @member {number} period
     * @memberof client_proto.GetWithdrawLogReq
     * @instance
     */
    GetWithdrawLogReq.prototype.period = 0;

    /**
     * Creates a new GetWithdrawLogReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetWithdrawLogReq
     * @static
     * @param {client_proto.IGetWithdrawLogReq=} [properties] Properties to set
     * @returns {client_proto.GetWithdrawLogReq} GetWithdrawLogReq instance
     */
    GetWithdrawLogReq.create = function create(properties) {
      return new GetWithdrawLogReq(properties);
    };

    /**
     * Encodes the specified GetWithdrawLogReq message. Does not implicitly {@link client_proto.GetWithdrawLogReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetWithdrawLogReq
     * @static
     * @param {client_proto.IGetWithdrawLogReq} message GetWithdrawLogReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawLogReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.period != null && Object.hasOwnProperty.call(message, "period"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.period);
      return writer;
    };

    /**
     * Encodes the specified GetWithdrawLogReq message, length delimited. Does not implicitly {@link client_proto.GetWithdrawLogReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetWithdrawLogReq
     * @static
     * @param {client_proto.IGetWithdrawLogReq} message GetWithdrawLogReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawLogReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWithdrawLogReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetWithdrawLogReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetWithdrawLogReq} GetWithdrawLogReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawLogReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetWithdrawLogReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.period = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetWithdrawLogReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetWithdrawLogReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetWithdrawLogReq} GetWithdrawLogReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawLogReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWithdrawLogReq message.
     * @function verify
     * @memberof client_proto.GetWithdrawLogReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWithdrawLogReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.period != null && message.hasOwnProperty("period"))
        if (!$util.isInteger(message.period)) return "period: integer expected";
      return null;
    };

    /**
     * Creates a GetWithdrawLogReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetWithdrawLogReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetWithdrawLogReq} GetWithdrawLogReq
     */
    GetWithdrawLogReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetWithdrawLogReq) return object;
      var message = new $root.client_proto.GetWithdrawLogReq();
      if (object.period != null) message.period = object.period | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetWithdrawLogReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetWithdrawLogReq
     * @static
     * @param {client_proto.GetWithdrawLogReq} message GetWithdrawLogReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWithdrawLogReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.period = 0;
      if (message.period != null && message.hasOwnProperty("period")) object.period = message.period;
      return object;
    };

    /**
     * Converts this GetWithdrawLogReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetWithdrawLogReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWithdrawLogReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWithdrawLogReq
     * @function getTypeUrl
     * @memberof client_proto.GetWithdrawLogReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWithdrawLogReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetWithdrawLogReq";
    };

    return GetWithdrawLogReq;
  })();

  client_proto.WithdrawLogItem = (function () {
    /**
     * Properties of a WithdrawLogItem.
     * @memberof client_proto
     * @interface IWithdrawLogItem
     * @property {number|null} [time] WithdrawLogItem time
     * @property {string|null} [order_num] WithdrawLogItem order_num
     * @property {number|null} [status] WithdrawLogItem status
     * @property {number|Long|null} [amount] WithdrawLogItem amount
     * @property {string|null} [reason] WithdrawLogItem reason
     * @property {number|null} [finish_time] WithdrawLogItem finish_time
     */

    /**
     * Constructs a new WithdrawLogItem.
     * @memberof client_proto
     * @classdesc Represents a WithdrawLogItem.
     * @implements IWithdrawLogItem
     * @constructor
     * @param {client_proto.IWithdrawLogItem=} [properties] Properties to set
     */
    function WithdrawLogItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * WithdrawLogItem time.
     * @member {number} time
     * @memberof client_proto.WithdrawLogItem
     * @instance
     */
    WithdrawLogItem.prototype.time = 0;

    /**
     * WithdrawLogItem order_num.
     * @member {string} order_num
     * @memberof client_proto.WithdrawLogItem
     * @instance
     */
    WithdrawLogItem.prototype.order_num = "";

    /**
     * WithdrawLogItem status.
     * @member {number} status
     * @memberof client_proto.WithdrawLogItem
     * @instance
     */
    WithdrawLogItem.prototype.status = 0;

    /**
     * WithdrawLogItem amount.
     * @member {number|Long} amount
     * @memberof client_proto.WithdrawLogItem
     * @instance
     */
    WithdrawLogItem.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * WithdrawLogItem reason.
     * @member {string} reason
     * @memberof client_proto.WithdrawLogItem
     * @instance
     */
    WithdrawLogItem.prototype.reason = "";

    /**
     * WithdrawLogItem finish_time.
     * @member {number} finish_time
     * @memberof client_proto.WithdrawLogItem
     * @instance
     */
    WithdrawLogItem.prototype.finish_time = 0;

    /**
     * Creates a new WithdrawLogItem instance using the specified properties.
     * @function create
     * @memberof client_proto.WithdrawLogItem
     * @static
     * @param {client_proto.IWithdrawLogItem=} [properties] Properties to set
     * @returns {client_proto.WithdrawLogItem} WithdrawLogItem instance
     */
    WithdrawLogItem.create = function create(properties) {
      return new WithdrawLogItem(properties);
    };

    /**
     * Encodes the specified WithdrawLogItem message. Does not implicitly {@link client_proto.WithdrawLogItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.WithdrawLogItem
     * @static
     * @param {client_proto.IWithdrawLogItem} message WithdrawLogItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawLogItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.time != null && Object.hasOwnProperty.call(message, "time"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.time);
      if (message.order_num != null && Object.hasOwnProperty.call(message, "order_num"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.order_num);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.status);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.amount);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.reason);
      if (message.finish_time != null && Object.hasOwnProperty.call(message, "finish_time"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.finish_time);
      return writer;
    };

    /**
     * Encodes the specified WithdrawLogItem message, length delimited. Does not implicitly {@link client_proto.WithdrawLogItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.WithdrawLogItem
     * @static
     * @param {client_proto.IWithdrawLogItem} message WithdrawLogItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawLogItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WithdrawLogItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.WithdrawLogItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.WithdrawLogItem} WithdrawLogItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawLogItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.WithdrawLogItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.time = reader.int32();
            break;
          }
          case 2: {
            message.order_num = reader.string();
            break;
          }
          case 3: {
            message.status = reader.int32();
            break;
          }
          case 4: {
            message.amount = reader.int64();
            break;
          }
          case 5: {
            message.reason = reader.string();
            break;
          }
          case 6: {
            message.finish_time = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a WithdrawLogItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.WithdrawLogItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.WithdrawLogItem} WithdrawLogItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawLogItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WithdrawLogItem message.
     * @function verify
     * @memberof client_proto.WithdrawLogItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WithdrawLogItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.time != null && message.hasOwnProperty("time"))
        if (!$util.isInteger(message.time)) return "time: integer expected";
      if (message.order_num != null && message.hasOwnProperty("order_num"))
        if (!$util.isString(message.order_num)) return "order_num: string expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.reason != null && message.hasOwnProperty("reason"))
        if (!$util.isString(message.reason)) return "reason: string expected";
      if (message.finish_time != null && message.hasOwnProperty("finish_time"))
        if (!$util.isInteger(message.finish_time)) return "finish_time: integer expected";
      return null;
    };

    /**
     * Creates a WithdrawLogItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.WithdrawLogItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.WithdrawLogItem} WithdrawLogItem
     */
    WithdrawLogItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.WithdrawLogItem) return object;
      var message = new $root.client_proto.WithdrawLogItem();
      if (object.time != null) message.time = object.time | 0;
      if (object.order_num != null) message.order_num = String(object.order_num);
      if (object.status != null) message.status = object.status | 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.reason != null) message.reason = String(object.reason);
      if (object.finish_time != null) message.finish_time = object.finish_time | 0;
      return message;
    };

    /**
     * Creates a plain object from a WithdrawLogItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.WithdrawLogItem
     * @static
     * @param {client_proto.WithdrawLogItem} message WithdrawLogItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WithdrawLogItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.time = 0;
        object.order_num = "";
        object.status = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        object.reason = "";
        object.finish_time = 0;
      }
      if (message.time != null && message.hasOwnProperty("time")) object.time = message.time;
      if (message.order_num != null && message.hasOwnProperty("order_num")) object.order_num = message.order_num;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.reason != null && message.hasOwnProperty("reason")) object.reason = message.reason;
      if (message.finish_time != null && message.hasOwnProperty("finish_time"))
        object.finish_time = message.finish_time;
      return object;
    };

    /**
     * Converts this WithdrawLogItem to JSON.
     * @function toJSON
     * @memberof client_proto.WithdrawLogItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WithdrawLogItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WithdrawLogItem
     * @function getTypeUrl
     * @memberof client_proto.WithdrawLogItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WithdrawLogItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.WithdrawLogItem";
    };

    return WithdrawLogItem;
  })();

  client_proto.GetWithdrawLogResp = (function () {
    /**
     * Properties of a GetWithdrawLogResp.
     * @memberof client_proto
     * @interface IGetWithdrawLogResp
     * @property {number|Long|null} [total_amount] GetWithdrawLogResp total_amount
     * @property {Array.<client_proto.IWithdrawLogItem>|null} [items] GetWithdrawLogResp items
     * @property {number|null} [cur_page] GetWithdrawLogResp cur_page
     * @property {number|null} [total_page] GetWithdrawLogResp total_page
     */

    /**
     * Constructs a new GetWithdrawLogResp.
     * @memberof client_proto
     * @classdesc Represents a GetWithdrawLogResp.
     * @implements IGetWithdrawLogResp
     * @constructor
     * @param {client_proto.IGetWithdrawLogResp=} [properties] Properties to set
     */
    function GetWithdrawLogResp(properties) {
      this.items = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetWithdrawLogResp total_amount.
     * @member {number|Long} total_amount
     * @memberof client_proto.GetWithdrawLogResp
     * @instance
     */
    GetWithdrawLogResp.prototype.total_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GetWithdrawLogResp items.
     * @member {Array.<client_proto.IWithdrawLogItem>} items
     * @memberof client_proto.GetWithdrawLogResp
     * @instance
     */
    GetWithdrawLogResp.prototype.items = $util.emptyArray;

    /**
     * GetWithdrawLogResp cur_page.
     * @member {number} cur_page
     * @memberof client_proto.GetWithdrawLogResp
     * @instance
     */
    GetWithdrawLogResp.prototype.cur_page = 0;

    /**
     * GetWithdrawLogResp total_page.
     * @member {number} total_page
     * @memberof client_proto.GetWithdrawLogResp
     * @instance
     */
    GetWithdrawLogResp.prototype.total_page = 0;

    /**
     * Creates a new GetWithdrawLogResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetWithdrawLogResp
     * @static
     * @param {client_proto.IGetWithdrawLogResp=} [properties] Properties to set
     * @returns {client_proto.GetWithdrawLogResp} GetWithdrawLogResp instance
     */
    GetWithdrawLogResp.create = function create(properties) {
      return new GetWithdrawLogResp(properties);
    };

    /**
     * Encodes the specified GetWithdrawLogResp message. Does not implicitly {@link client_proto.GetWithdrawLogResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetWithdrawLogResp
     * @static
     * @param {client_proto.IGetWithdrawLogResp} message GetWithdrawLogResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawLogResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.total_amount != null && Object.hasOwnProperty.call(message, "total_amount"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.total_amount);
      if (message.items != null && message.items.length)
        for (var i = 0; i < message.items.length; ++i)
          $root.client_proto.WithdrawLogItem.encode(
            message.items[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim();
      if (message.cur_page != null && Object.hasOwnProperty.call(message, "cur_page"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.cur_page);
      if (message.total_page != null && Object.hasOwnProperty.call(message, "total_page"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.total_page);
      return writer;
    };

    /**
     * Encodes the specified GetWithdrawLogResp message, length delimited. Does not implicitly {@link client_proto.GetWithdrawLogResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetWithdrawLogResp
     * @static
     * @param {client_proto.IGetWithdrawLogResp} message GetWithdrawLogResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawLogResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWithdrawLogResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetWithdrawLogResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetWithdrawLogResp} GetWithdrawLogResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawLogResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetWithdrawLogResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.total_amount = reader.int64();
            break;
          }
          case 2: {
            if (!(message.items && message.items.length)) message.items = [];
            message.items.push($root.client_proto.WithdrawLogItem.decode(reader, reader.uint32()));
            break;
          }
          case 3: {
            message.cur_page = reader.int32();
            break;
          }
          case 4: {
            message.total_page = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetWithdrawLogResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetWithdrawLogResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetWithdrawLogResp} GetWithdrawLogResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawLogResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWithdrawLogResp message.
     * @function verify
     * @memberof client_proto.GetWithdrawLogResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWithdrawLogResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.total_amount != null && message.hasOwnProperty("total_amount"))
        if (
          !$util.isInteger(message.total_amount) &&
          !(
            message.total_amount &&
            $util.isInteger(message.total_amount.low) &&
            $util.isInteger(message.total_amount.high)
          )
        )
          return "total_amount: integer|Long expected";
      if (message.items != null && message.hasOwnProperty("items")) {
        if (!Array.isArray(message.items)) return "items: array expected";
        for (var i = 0; i < message.items.length; ++i) {
          var error = $root.client_proto.WithdrawLogItem.verify(message.items[i]);
          if (error) return "items." + error;
        }
      }
      if (message.cur_page != null && message.hasOwnProperty("cur_page"))
        if (!$util.isInteger(message.cur_page)) return "cur_page: integer expected";
      if (message.total_page != null && message.hasOwnProperty("total_page"))
        if (!$util.isInteger(message.total_page)) return "total_page: integer expected";
      return null;
    };

    /**
     * Creates a GetWithdrawLogResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetWithdrawLogResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetWithdrawLogResp} GetWithdrawLogResp
     */
    GetWithdrawLogResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetWithdrawLogResp) return object;
      var message = new $root.client_proto.GetWithdrawLogResp();
      if (object.total_amount != null)
        if ($util.Long) (message.total_amount = $util.Long.fromValue(object.total_amount)).unsigned = false;
        else if (typeof object.total_amount === "string") message.total_amount = parseInt(object.total_amount, 10);
        else if (typeof object.total_amount === "number") message.total_amount = object.total_amount;
        else if (typeof object.total_amount === "object")
          message.total_amount = new $util.LongBits(
            object.total_amount.low >>> 0,
            object.total_amount.high >>> 0,
          ).toNumber();
      if (object.items) {
        if (!Array.isArray(object.items)) throw TypeError(".client_proto.GetWithdrawLogResp.items: array expected");
        message.items = [];
        for (var i = 0; i < object.items.length; ++i) {
          if (typeof object.items[i] !== "object")
            throw TypeError(".client_proto.GetWithdrawLogResp.items: object expected");
          message.items[i] = $root.client_proto.WithdrawLogItem.fromObject(object.items[i]);
        }
      }
      if (object.cur_page != null) message.cur_page = object.cur_page | 0;
      if (object.total_page != null) message.total_page = object.total_page | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetWithdrawLogResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetWithdrawLogResp
     * @static
     * @param {client_proto.GetWithdrawLogResp} message GetWithdrawLogResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWithdrawLogResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.items = [];
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_amount = options.longs === String ? "0" : 0;
        object.cur_page = 0;
        object.total_page = 0;
      }
      if (message.total_amount != null && message.hasOwnProperty("total_amount"))
        if (typeof message.total_amount === "number")
          object.total_amount = options.longs === String ? String(message.total_amount) : message.total_amount;
        else
          object.total_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_amount)
              : options.longs === Number
                ? new $util.LongBits(message.total_amount.low >>> 0, message.total_amount.high >>> 0).toNumber()
                : message.total_amount;
      if (message.items && message.items.length) {
        object.items = [];
        for (var j = 0; j < message.items.length; ++j)
          object.items[j] = $root.client_proto.WithdrawLogItem.toObject(message.items[j], options);
      }
      if (message.cur_page != null && message.hasOwnProperty("cur_page")) object.cur_page = message.cur_page;
      if (message.total_page != null && message.hasOwnProperty("total_page")) object.total_page = message.total_page;
      return object;
    };

    /**
     * Converts this GetWithdrawLogResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetWithdrawLogResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWithdrawLogResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWithdrawLogResp
     * @function getTypeUrl
     * @memberof client_proto.GetWithdrawLogResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWithdrawLogResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetWithdrawLogResp";
    };

    return GetWithdrawLogResp;
  })();

  client_proto.GetWithdrawBankReq = (function () {
    /**
     * Properties of a GetWithdrawBankReq.
     * @memberof client_proto
     * @interface IGetWithdrawBankReq
     */

    /**
     * Constructs a new GetWithdrawBankReq.
     * @memberof client_proto
     * @classdesc Represents a GetWithdrawBankReq.
     * @implements IGetWithdrawBankReq
     * @constructor
     * @param {client_proto.IGetWithdrawBankReq=} [properties] Properties to set
     */
    function GetWithdrawBankReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetWithdrawBankReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetWithdrawBankReq
     * @static
     * @param {client_proto.IGetWithdrawBankReq=} [properties] Properties to set
     * @returns {client_proto.GetWithdrawBankReq} GetWithdrawBankReq instance
     */
    GetWithdrawBankReq.create = function create(properties) {
      return new GetWithdrawBankReq(properties);
    };

    /**
     * Encodes the specified GetWithdrawBankReq message. Does not implicitly {@link client_proto.GetWithdrawBankReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetWithdrawBankReq
     * @static
     * @param {client_proto.IGetWithdrawBankReq} message GetWithdrawBankReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawBankReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified GetWithdrawBankReq message, length delimited. Does not implicitly {@link client_proto.GetWithdrawBankReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetWithdrawBankReq
     * @static
     * @param {client_proto.IGetWithdrawBankReq} message GetWithdrawBankReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawBankReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWithdrawBankReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetWithdrawBankReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetWithdrawBankReq} GetWithdrawBankReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawBankReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetWithdrawBankReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetWithdrawBankReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetWithdrawBankReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetWithdrawBankReq} GetWithdrawBankReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawBankReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWithdrawBankReq message.
     * @function verify
     * @memberof client_proto.GetWithdrawBankReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWithdrawBankReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a GetWithdrawBankReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetWithdrawBankReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetWithdrawBankReq} GetWithdrawBankReq
     */
    GetWithdrawBankReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetWithdrawBankReq) return object;
      return new $root.client_proto.GetWithdrawBankReq();
    };

    /**
     * Creates a plain object from a GetWithdrawBankReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetWithdrawBankReq
     * @static
     * @param {client_proto.GetWithdrawBankReq} message GetWithdrawBankReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWithdrawBankReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this GetWithdrawBankReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetWithdrawBankReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWithdrawBankReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWithdrawBankReq
     * @function getTypeUrl
     * @memberof client_proto.GetWithdrawBankReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWithdrawBankReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetWithdrawBankReq";
    };

    return GetWithdrawBankReq;
  })();

  client_proto.BankItem = (function () {
    /**
     * Properties of a BankItem.
     * @memberof client_proto
     * @interface IBankItem
     * @property {number|null} [id] BankItem id
     * @property {string|null} [name] BankItem name
     * @property {string|null} [code] BankItem code
     */

    /**
     * Constructs a new BankItem.
     * @memberof client_proto
     * @classdesc Represents a BankItem.
     * @implements IBankItem
     * @constructor
     * @param {client_proto.IBankItem=} [properties] Properties to set
     */
    function BankItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * BankItem id.
     * @member {number} id
     * @memberof client_proto.BankItem
     * @instance
     */
    BankItem.prototype.id = 0;

    /**
     * BankItem name.
     * @member {string} name
     * @memberof client_proto.BankItem
     * @instance
     */
    BankItem.prototype.name = "";

    /**
     * BankItem code.
     * @member {string} code
     * @memberof client_proto.BankItem
     * @instance
     */
    BankItem.prototype.code = "";

    /**
     * Creates a new BankItem instance using the specified properties.
     * @function create
     * @memberof client_proto.BankItem
     * @static
     * @param {client_proto.IBankItem=} [properties] Properties to set
     * @returns {client_proto.BankItem} BankItem instance
     */
    BankItem.create = function create(properties) {
      return new BankItem(properties);
    };

    /**
     * Encodes the specified BankItem message. Does not implicitly {@link client_proto.BankItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.BankItem
     * @static
     * @param {client_proto.IBankItem} message BankItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BankItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name);
      if (message.code != null && Object.hasOwnProperty.call(message, "code"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.code);
      return writer;
    };

    /**
     * Encodes the specified BankItem message, length delimited. Does not implicitly {@link client_proto.BankItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.BankItem
     * @static
     * @param {client_proto.IBankItem} message BankItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BankItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BankItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.BankItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.BankItem} BankItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BankItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.BankItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.name = reader.string();
            break;
          }
          case 3: {
            message.code = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a BankItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.BankItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.BankItem} BankItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BankItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BankItem message.
     * @function verify
     * @memberof client_proto.BankItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BankItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      if (message.code != null && message.hasOwnProperty("code"))
        if (!$util.isString(message.code)) return "code: string expected";
      return null;
    };

    /**
     * Creates a BankItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.BankItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.BankItem} BankItem
     */
    BankItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.BankItem) return object;
      var message = new $root.client_proto.BankItem();
      if (object.id != null) message.id = object.id | 0;
      if (object.name != null) message.name = String(object.name);
      if (object.code != null) message.code = String(object.code);
      return message;
    };

    /**
     * Creates a plain object from a BankItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.BankItem
     * @static
     * @param {client_proto.BankItem} message BankItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BankItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.name = "";
        object.code = "";
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      if (message.code != null && message.hasOwnProperty("code")) object.code = message.code;
      return object;
    };

    /**
     * Converts this BankItem to JSON.
     * @function toJSON
     * @memberof client_proto.BankItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BankItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BankItem
     * @function getTypeUrl
     * @memberof client_proto.BankItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BankItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.BankItem";
    };

    return BankItem;
  })();

  client_proto.GetWithdrawBankResp = (function () {
    /**
     * Properties of a GetWithdrawBankResp.
     * @memberof client_proto
     * @interface IGetWithdrawBankResp
     * @property {Array.<client_proto.IBankItem>|null} [banklist] GetWithdrawBankResp banklist
     */

    /**
     * Constructs a new GetWithdrawBankResp.
     * @memberof client_proto
     * @classdesc Represents a GetWithdrawBankResp.
     * @implements IGetWithdrawBankResp
     * @constructor
     * @param {client_proto.IGetWithdrawBankResp=} [properties] Properties to set
     */
    function GetWithdrawBankResp(properties) {
      this.banklist = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetWithdrawBankResp banklist.
     * @member {Array.<client_proto.IBankItem>} banklist
     * @memberof client_proto.GetWithdrawBankResp
     * @instance
     */
    GetWithdrawBankResp.prototype.banklist = $util.emptyArray;

    /**
     * Creates a new GetWithdrawBankResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetWithdrawBankResp
     * @static
     * @param {client_proto.IGetWithdrawBankResp=} [properties] Properties to set
     * @returns {client_proto.GetWithdrawBankResp} GetWithdrawBankResp instance
     */
    GetWithdrawBankResp.create = function create(properties) {
      return new GetWithdrawBankResp(properties);
    };

    /**
     * Encodes the specified GetWithdrawBankResp message. Does not implicitly {@link client_proto.GetWithdrawBankResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetWithdrawBankResp
     * @static
     * @param {client_proto.IGetWithdrawBankResp} message GetWithdrawBankResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawBankResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.banklist != null && message.banklist.length)
        for (var i = 0; i < message.banklist.length; ++i)
          $root.client_proto.BankItem.encode(
            message.banklist[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified GetWithdrawBankResp message, length delimited. Does not implicitly {@link client_proto.GetWithdrawBankResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetWithdrawBankResp
     * @static
     * @param {client_proto.IGetWithdrawBankResp} message GetWithdrawBankResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawBankResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWithdrawBankResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetWithdrawBankResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetWithdrawBankResp} GetWithdrawBankResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawBankResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetWithdrawBankResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.banklist && message.banklist.length)) message.banklist = [];
            message.banklist.push($root.client_proto.BankItem.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetWithdrawBankResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetWithdrawBankResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetWithdrawBankResp} GetWithdrawBankResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawBankResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWithdrawBankResp message.
     * @function verify
     * @memberof client_proto.GetWithdrawBankResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWithdrawBankResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.banklist != null && message.hasOwnProperty("banklist")) {
        if (!Array.isArray(message.banklist)) return "banklist: array expected";
        for (var i = 0; i < message.banklist.length; ++i) {
          var error = $root.client_proto.BankItem.verify(message.banklist[i]);
          if (error) return "banklist." + error;
        }
      }
      return null;
    };

    /**
     * Creates a GetWithdrawBankResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetWithdrawBankResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetWithdrawBankResp} GetWithdrawBankResp
     */
    GetWithdrawBankResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetWithdrawBankResp) return object;
      var message = new $root.client_proto.GetWithdrawBankResp();
      if (object.banklist) {
        if (!Array.isArray(object.banklist))
          throw TypeError(".client_proto.GetWithdrawBankResp.banklist: array expected");
        message.banklist = [];
        for (var i = 0; i < object.banklist.length; ++i) {
          if (typeof object.banklist[i] !== "object")
            throw TypeError(".client_proto.GetWithdrawBankResp.banklist: object expected");
          message.banklist[i] = $root.client_proto.BankItem.fromObject(object.banklist[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a GetWithdrawBankResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetWithdrawBankResp
     * @static
     * @param {client_proto.GetWithdrawBankResp} message GetWithdrawBankResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWithdrawBankResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.banklist = [];
      if (message.banklist && message.banklist.length) {
        object.banklist = [];
        for (var j = 0; j < message.banklist.length; ++j)
          object.banklist[j] = $root.client_proto.BankItem.toObject(message.banklist[j], options);
      }
      return object;
    };

    /**
     * Converts this GetWithdrawBankResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetWithdrawBankResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWithdrawBankResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWithdrawBankResp
     * @function getTypeUrl
     * @memberof client_proto.GetWithdrawBankResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWithdrawBankResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetWithdrawBankResp";
    };

    return GetWithdrawBankResp;
  })();

  client_proto.GetWithdrawConfigReq = (function () {
    /**
     * Properties of a GetWithdrawConfigReq.
     * @memberof client_proto
     * @interface IGetWithdrawConfigReq
     */

    /**
     * Constructs a new GetWithdrawConfigReq.
     * @memberof client_proto
     * @classdesc Represents a GetWithdrawConfigReq.
     * @implements IGetWithdrawConfigReq
     * @constructor
     * @param {client_proto.IGetWithdrawConfigReq=} [properties] Properties to set
     */
    function GetWithdrawConfigReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetWithdrawConfigReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetWithdrawConfigReq
     * @static
     * @param {client_proto.IGetWithdrawConfigReq=} [properties] Properties to set
     * @returns {client_proto.GetWithdrawConfigReq} GetWithdrawConfigReq instance
     */
    GetWithdrawConfigReq.create = function create(properties) {
      return new GetWithdrawConfigReq(properties);
    };

    /**
     * Encodes the specified GetWithdrawConfigReq message. Does not implicitly {@link client_proto.GetWithdrawConfigReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetWithdrawConfigReq
     * @static
     * @param {client_proto.IGetWithdrawConfigReq} message GetWithdrawConfigReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawConfigReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified GetWithdrawConfigReq message, length delimited. Does not implicitly {@link client_proto.GetWithdrawConfigReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetWithdrawConfigReq
     * @static
     * @param {client_proto.IGetWithdrawConfigReq} message GetWithdrawConfigReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawConfigReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWithdrawConfigReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetWithdrawConfigReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetWithdrawConfigReq} GetWithdrawConfigReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawConfigReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetWithdrawConfigReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetWithdrawConfigReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetWithdrawConfigReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetWithdrawConfigReq} GetWithdrawConfigReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawConfigReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWithdrawConfigReq message.
     * @function verify
     * @memberof client_proto.GetWithdrawConfigReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWithdrawConfigReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a GetWithdrawConfigReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetWithdrawConfigReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetWithdrawConfigReq} GetWithdrawConfigReq
     */
    GetWithdrawConfigReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetWithdrawConfigReq) return object;
      return new $root.client_proto.GetWithdrawConfigReq();
    };

    /**
     * Creates a plain object from a GetWithdrawConfigReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetWithdrawConfigReq
     * @static
     * @param {client_proto.GetWithdrawConfigReq} message GetWithdrawConfigReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWithdrawConfigReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this GetWithdrawConfigReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetWithdrawConfigReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWithdrawConfigReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWithdrawConfigReq
     * @function getTypeUrl
     * @memberof client_proto.GetWithdrawConfigReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWithdrawConfigReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetWithdrawConfigReq";
    };

    return GetWithdrawConfigReq;
  })();

  client_proto.WithdrawItem = (function () {
    /**
     * Properties of a WithdrawItem.
     * @memberof client_proto
     * @interface IWithdrawItem
     * @property {number|null} [id] WithdrawItem id
     * @property {number|Long|null} [amount] WithdrawItem amount
     */

    /**
     * Constructs a new WithdrawItem.
     * @memberof client_proto
     * @classdesc Represents a WithdrawItem.
     * @implements IWithdrawItem
     * @constructor
     * @param {client_proto.IWithdrawItem=} [properties] Properties to set
     */
    function WithdrawItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * WithdrawItem id.
     * @member {number} id
     * @memberof client_proto.WithdrawItem
     * @instance
     */
    WithdrawItem.prototype.id = 0;

    /**
     * WithdrawItem amount.
     * @member {number|Long} amount
     * @memberof client_proto.WithdrawItem
     * @instance
     */
    WithdrawItem.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new WithdrawItem instance using the specified properties.
     * @function create
     * @memberof client_proto.WithdrawItem
     * @static
     * @param {client_proto.IWithdrawItem=} [properties] Properties to set
     * @returns {client_proto.WithdrawItem} WithdrawItem instance
     */
    WithdrawItem.create = function create(properties) {
      return new WithdrawItem(properties);
    };

    /**
     * Encodes the specified WithdrawItem message. Does not implicitly {@link client_proto.WithdrawItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.WithdrawItem
     * @static
     * @param {client_proto.IWithdrawItem} message WithdrawItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.amount);
      return writer;
    };

    /**
     * Encodes the specified WithdrawItem message, length delimited. Does not implicitly {@link client_proto.WithdrawItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.WithdrawItem
     * @static
     * @param {client_proto.IWithdrawItem} message WithdrawItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WithdrawItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.WithdrawItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.WithdrawItem} WithdrawItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.WithdrawItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.amount = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a WithdrawItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.WithdrawItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.WithdrawItem} WithdrawItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WithdrawItem message.
     * @function verify
     * @memberof client_proto.WithdrawItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WithdrawItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      return null;
    };

    /**
     * Creates a WithdrawItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.WithdrawItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.WithdrawItem} WithdrawItem
     */
    WithdrawItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.WithdrawItem) return object;
      var message = new $root.client_proto.WithdrawItem();
      if (object.id != null) message.id = object.id | 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a WithdrawItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.WithdrawItem
     * @static
     * @param {client_proto.WithdrawItem} message WithdrawItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WithdrawItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      return object;
    };

    /**
     * Converts this WithdrawItem to JSON.
     * @function toJSON
     * @memberof client_proto.WithdrawItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WithdrawItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WithdrawItem
     * @function getTypeUrl
     * @memberof client_proto.WithdrawItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WithdrawItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.WithdrawItem";
    };

    return WithdrawItem;
  })();

  client_proto.GetWithdrawConfigResp = (function () {
    /**
     * Properties of a GetWithdrawConfigResp.
     * @memberof client_proto
     * @interface IGetWithdrawConfigResp
     * @property {number|Long|null} [withdraw_balance] GetWithdrawConfigResp withdraw_balance
     * @property {string|null} [bank_name] GetWithdrawConfigResp bank_name
     * @property {string|null} [bank_account] GetWithdrawConfigResp bank_account
     * @property {string|null} [bank_cpf] GetWithdrawConfigResp bank_cpf
     * @property {Array.<client_proto.IWithdrawItem>|null} [items] GetWithdrawConfigResp items
     * @property {number|Long|null} [withdraw_limit] GetWithdrawConfigResp withdraw_limit
     * @property {number|Long|null} [vip_limit] GetWithdrawConfigResp vip_limit
     * @property {number|Long|null} [wager_requirement] GetWithdrawConfigResp wager_requirement
     */

    /**
     * Constructs a new GetWithdrawConfigResp.
     * @memberof client_proto
     * @classdesc Represents a GetWithdrawConfigResp.
     * @implements IGetWithdrawConfigResp
     * @constructor
     * @param {client_proto.IGetWithdrawConfigResp=} [properties] Properties to set
     */
    function GetWithdrawConfigResp(properties) {
      this.items = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetWithdrawConfigResp withdraw_balance.
     * @member {number|Long} withdraw_balance
     * @memberof client_proto.GetWithdrawConfigResp
     * @instance
     */
    GetWithdrawConfigResp.prototype.withdraw_balance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GetWithdrawConfigResp bank_name.
     * @member {string} bank_name
     * @memberof client_proto.GetWithdrawConfigResp
     * @instance
     */
    GetWithdrawConfigResp.prototype.bank_name = "";

    /**
     * GetWithdrawConfigResp bank_account.
     * @member {string} bank_account
     * @memberof client_proto.GetWithdrawConfigResp
     * @instance
     */
    GetWithdrawConfigResp.prototype.bank_account = "";

    /**
     * GetWithdrawConfigResp bank_cpf.
     * @member {string} bank_cpf
     * @memberof client_proto.GetWithdrawConfigResp
     * @instance
     */
    GetWithdrawConfigResp.prototype.bank_cpf = "";

    /**
     * GetWithdrawConfigResp items.
     * @member {Array.<client_proto.IWithdrawItem>} items
     * @memberof client_proto.GetWithdrawConfigResp
     * @instance
     */
    GetWithdrawConfigResp.prototype.items = $util.emptyArray;

    /**
     * GetWithdrawConfigResp withdraw_limit.
     * @member {number|Long} withdraw_limit
     * @memberof client_proto.GetWithdrawConfigResp
     * @instance
     */
    GetWithdrawConfigResp.prototype.withdraw_limit = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GetWithdrawConfigResp vip_limit.
     * @member {number|Long} vip_limit
     * @memberof client_proto.GetWithdrawConfigResp
     * @instance
     */
    GetWithdrawConfigResp.prototype.vip_limit = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GetWithdrawConfigResp wager_requirement.
     * @member {number|Long} wager_requirement
     * @memberof client_proto.GetWithdrawConfigResp
     * @instance
     */
    GetWithdrawConfigResp.prototype.wager_requirement = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new GetWithdrawConfigResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetWithdrawConfigResp
     * @static
     * @param {client_proto.IGetWithdrawConfigResp=} [properties] Properties to set
     * @returns {client_proto.GetWithdrawConfigResp} GetWithdrawConfigResp instance
     */
    GetWithdrawConfigResp.create = function create(properties) {
      return new GetWithdrawConfigResp(properties);
    };

    /**
     * Encodes the specified GetWithdrawConfigResp message. Does not implicitly {@link client_proto.GetWithdrawConfigResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetWithdrawConfigResp
     * @static
     * @param {client_proto.IGetWithdrawConfigResp} message GetWithdrawConfigResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawConfigResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.withdraw_balance != null && Object.hasOwnProperty.call(message, "withdraw_balance"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.withdraw_balance);
      if (message.bank_name != null && Object.hasOwnProperty.call(message, "bank_name"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.bank_name);
      if (message.bank_account != null && Object.hasOwnProperty.call(message, "bank_account"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.bank_account);
      if (message.bank_cpf != null && Object.hasOwnProperty.call(message, "bank_cpf"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.bank_cpf);
      if (message.items != null && message.items.length)
        for (var i = 0; i < message.items.length; ++i)
          $root.client_proto.WithdrawItem.encode(
            message.items[i],
            writer.uint32(/* id 5, wireType 2 =*/ 42).fork(),
          ).ldelim();
      if (message.withdraw_limit != null && Object.hasOwnProperty.call(message, "withdraw_limit"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.withdraw_limit);
      if (message.vip_limit != null && Object.hasOwnProperty.call(message, "vip_limit"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.vip_limit);
      if (message.wager_requirement != null && Object.hasOwnProperty.call(message, "wager_requirement"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.wager_requirement);
      return writer;
    };

    /**
     * Encodes the specified GetWithdrawConfigResp message, length delimited. Does not implicitly {@link client_proto.GetWithdrawConfigResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetWithdrawConfigResp
     * @static
     * @param {client_proto.IGetWithdrawConfigResp} message GetWithdrawConfigResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetWithdrawConfigResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetWithdrawConfigResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetWithdrawConfigResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetWithdrawConfigResp} GetWithdrawConfigResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawConfigResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetWithdrawConfigResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.withdraw_balance = reader.int64();
            break;
          }
          case 2: {
            message.bank_name = reader.string();
            break;
          }
          case 3: {
            message.bank_account = reader.string();
            break;
          }
          case 4: {
            message.bank_cpf = reader.string();
            break;
          }
          case 5: {
            if (!(message.items && message.items.length)) message.items = [];
            message.items.push($root.client_proto.WithdrawItem.decode(reader, reader.uint32()));
            break;
          }
          case 6: {
            message.withdraw_limit = reader.int64();
            break;
          }
          case 7: {
            message.vip_limit = reader.int64();
            break;
          }
          case 8: {
            message.wager_requirement = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetWithdrawConfigResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetWithdrawConfigResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetWithdrawConfigResp} GetWithdrawConfigResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetWithdrawConfigResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetWithdrawConfigResp message.
     * @function verify
     * @memberof client_proto.GetWithdrawConfigResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetWithdrawConfigResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.withdraw_balance != null && message.hasOwnProperty("withdraw_balance"))
        if (
          !$util.isInteger(message.withdraw_balance) &&
          !(
            message.withdraw_balance &&
            $util.isInteger(message.withdraw_balance.low) &&
            $util.isInteger(message.withdraw_balance.high)
          )
        )
          return "withdraw_balance: integer|Long expected";
      if (message.bank_name != null && message.hasOwnProperty("bank_name"))
        if (!$util.isString(message.bank_name)) return "bank_name: string expected";
      if (message.bank_account != null && message.hasOwnProperty("bank_account"))
        if (!$util.isString(message.bank_account)) return "bank_account: string expected";
      if (message.bank_cpf != null && message.hasOwnProperty("bank_cpf"))
        if (!$util.isString(message.bank_cpf)) return "bank_cpf: string expected";
      if (message.items != null && message.hasOwnProperty("items")) {
        if (!Array.isArray(message.items)) return "items: array expected";
        for (var i = 0; i < message.items.length; ++i) {
          var error = $root.client_proto.WithdrawItem.verify(message.items[i]);
          if (error) return "items." + error;
        }
      }
      if (message.withdraw_limit != null && message.hasOwnProperty("withdraw_limit"))
        if (
          !$util.isInteger(message.withdraw_limit) &&
          !(
            message.withdraw_limit &&
            $util.isInteger(message.withdraw_limit.low) &&
            $util.isInteger(message.withdraw_limit.high)
          )
        )
          return "withdraw_limit: integer|Long expected";
      if (message.vip_limit != null && message.hasOwnProperty("vip_limit"))
        if (
          !$util.isInteger(message.vip_limit) &&
          !(message.vip_limit && $util.isInteger(message.vip_limit.low) && $util.isInteger(message.vip_limit.high))
        )
          return "vip_limit: integer|Long expected";
      if (message.wager_requirement != null && message.hasOwnProperty("wager_requirement"))
        if (
          !$util.isInteger(message.wager_requirement) &&
          !(
            message.wager_requirement &&
            $util.isInteger(message.wager_requirement.low) &&
            $util.isInteger(message.wager_requirement.high)
          )
        )
          return "wager_requirement: integer|Long expected";
      return null;
    };

    /**
     * Creates a GetWithdrawConfigResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetWithdrawConfigResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetWithdrawConfigResp} GetWithdrawConfigResp
     */
    GetWithdrawConfigResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetWithdrawConfigResp) return object;
      var message = new $root.client_proto.GetWithdrawConfigResp();
      if (object.withdraw_balance != null)
        if ($util.Long) (message.withdraw_balance = $util.Long.fromValue(object.withdraw_balance)).unsigned = false;
        else if (typeof object.withdraw_balance === "string")
          message.withdraw_balance = parseInt(object.withdraw_balance, 10);
        else if (typeof object.withdraw_balance === "number") message.withdraw_balance = object.withdraw_balance;
        else if (typeof object.withdraw_balance === "object")
          message.withdraw_balance = new $util.LongBits(
            object.withdraw_balance.low >>> 0,
            object.withdraw_balance.high >>> 0,
          ).toNumber();
      if (object.bank_name != null) message.bank_name = String(object.bank_name);
      if (object.bank_account != null) message.bank_account = String(object.bank_account);
      if (object.bank_cpf != null) message.bank_cpf = String(object.bank_cpf);
      if (object.items) {
        if (!Array.isArray(object.items)) throw TypeError(".client_proto.GetWithdrawConfigResp.items: array expected");
        message.items = [];
        for (var i = 0; i < object.items.length; ++i) {
          if (typeof object.items[i] !== "object")
            throw TypeError(".client_proto.GetWithdrawConfigResp.items: object expected");
          message.items[i] = $root.client_proto.WithdrawItem.fromObject(object.items[i]);
        }
      }
      if (object.withdraw_limit != null)
        if ($util.Long) (message.withdraw_limit = $util.Long.fromValue(object.withdraw_limit)).unsigned = false;
        else if (typeof object.withdraw_limit === "string")
          message.withdraw_limit = parseInt(object.withdraw_limit, 10);
        else if (typeof object.withdraw_limit === "number") message.withdraw_limit = object.withdraw_limit;
        else if (typeof object.withdraw_limit === "object")
          message.withdraw_limit = new $util.LongBits(
            object.withdraw_limit.low >>> 0,
            object.withdraw_limit.high >>> 0,
          ).toNumber();
      if (object.vip_limit != null)
        if ($util.Long) (message.vip_limit = $util.Long.fromValue(object.vip_limit)).unsigned = false;
        else if (typeof object.vip_limit === "string") message.vip_limit = parseInt(object.vip_limit, 10);
        else if (typeof object.vip_limit === "number") message.vip_limit = object.vip_limit;
        else if (typeof object.vip_limit === "object")
          message.vip_limit = new $util.LongBits(object.vip_limit.low >>> 0, object.vip_limit.high >>> 0).toNumber();
      if (object.wager_requirement != null)
        if ($util.Long) (message.wager_requirement = $util.Long.fromValue(object.wager_requirement)).unsigned = false;
        else if (typeof object.wager_requirement === "string")
          message.wager_requirement = parseInt(object.wager_requirement, 10);
        else if (typeof object.wager_requirement === "number") message.wager_requirement = object.wager_requirement;
        else if (typeof object.wager_requirement === "object")
          message.wager_requirement = new $util.LongBits(
            object.wager_requirement.low >>> 0,
            object.wager_requirement.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a GetWithdrawConfigResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetWithdrawConfigResp
     * @static
     * @param {client_proto.GetWithdrawConfigResp} message GetWithdrawConfigResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetWithdrawConfigResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.items = [];
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.withdraw_balance =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.withdraw_balance = options.longs === String ? "0" : 0;
        object.bank_name = "";
        object.bank_account = "";
        object.bank_cpf = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.withdraw_limit =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.withdraw_limit = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.vip_limit =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.vip_limit = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.wager_requirement =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.wager_requirement = options.longs === String ? "0" : 0;
      }
      if (message.withdraw_balance != null && message.hasOwnProperty("withdraw_balance"))
        if (typeof message.withdraw_balance === "number")
          object.withdraw_balance =
            options.longs === String ? String(message.withdraw_balance) : message.withdraw_balance;
        else
          object.withdraw_balance =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.withdraw_balance)
              : options.longs === Number
                ? new $util.LongBits(message.withdraw_balance.low >>> 0, message.withdraw_balance.high >>> 0).toNumber()
                : message.withdraw_balance;
      if (message.bank_name != null && message.hasOwnProperty("bank_name")) object.bank_name = message.bank_name;
      if (message.bank_account != null && message.hasOwnProperty("bank_account"))
        object.bank_account = message.bank_account;
      if (message.bank_cpf != null && message.hasOwnProperty("bank_cpf")) object.bank_cpf = message.bank_cpf;
      if (message.items && message.items.length) {
        object.items = [];
        for (var j = 0; j < message.items.length; ++j)
          object.items[j] = $root.client_proto.WithdrawItem.toObject(message.items[j], options);
      }
      if (message.withdraw_limit != null && message.hasOwnProperty("withdraw_limit"))
        if (typeof message.withdraw_limit === "number")
          object.withdraw_limit = options.longs === String ? String(message.withdraw_limit) : message.withdraw_limit;
        else
          object.withdraw_limit =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.withdraw_limit)
              : options.longs === Number
                ? new $util.LongBits(message.withdraw_limit.low >>> 0, message.withdraw_limit.high >>> 0).toNumber()
                : message.withdraw_limit;
      if (message.vip_limit != null && message.hasOwnProperty("vip_limit"))
        if (typeof message.vip_limit === "number")
          object.vip_limit = options.longs === String ? String(message.vip_limit) : message.vip_limit;
        else
          object.vip_limit =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.vip_limit)
              : options.longs === Number
                ? new $util.LongBits(message.vip_limit.low >>> 0, message.vip_limit.high >>> 0).toNumber()
                : message.vip_limit;
      if (message.wager_requirement != null && message.hasOwnProperty("wager_requirement"))
        if (typeof message.wager_requirement === "number")
          object.wager_requirement =
            options.longs === String ? String(message.wager_requirement) : message.wager_requirement;
        else
          object.wager_requirement =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.wager_requirement)
              : options.longs === Number
                ? new $util.LongBits(
                    message.wager_requirement.low >>> 0,
                    message.wager_requirement.high >>> 0,
                  ).toNumber()
                : message.wager_requirement;
      return object;
    };

    /**
     * Converts this GetWithdrawConfigResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetWithdrawConfigResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetWithdrawConfigResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetWithdrawConfigResp
     * @function getTypeUrl
     * @memberof client_proto.GetWithdrawConfigResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetWithdrawConfigResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetWithdrawConfigResp";
    };

    return GetWithdrawConfigResp;
  })();

  client_proto.SetBankCardReq = (function () {
    /**
     * Properties of a SetBankCardReq.
     * @memberof client_proto
     * @interface ISetBankCardReq
     * @property {string|null} [name] SetBankCardReq name
     * @property {string|null} [account] SetBankCardReq account
     * @property {string|null} [cpf] SetBankCardReq cpf
     * @property {number|null} [uid] SetBankCardReq uid
     */

    /**
     * Constructs a new SetBankCardReq.
     * @memberof client_proto
     * @classdesc Represents a SetBankCardReq.
     * @implements ISetBankCardReq
     * @constructor
     * @param {client_proto.ISetBankCardReq=} [properties] Properties to set
     */
    function SetBankCardReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetBankCardReq name.
     * @member {string} name
     * @memberof client_proto.SetBankCardReq
     * @instance
     */
    SetBankCardReq.prototype.name = "";

    /**
     * SetBankCardReq account.
     * @member {string} account
     * @memberof client_proto.SetBankCardReq
     * @instance
     */
    SetBankCardReq.prototype.account = "";

    /**
     * SetBankCardReq cpf.
     * @member {string} cpf
     * @memberof client_proto.SetBankCardReq
     * @instance
     */
    SetBankCardReq.prototype.cpf = "";

    /**
     * SetBankCardReq uid.
     * @member {number} uid
     * @memberof client_proto.SetBankCardReq
     * @instance
     */
    SetBankCardReq.prototype.uid = 0;

    /**
     * Creates a new SetBankCardReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SetBankCardReq
     * @static
     * @param {client_proto.ISetBankCardReq=} [properties] Properties to set
     * @returns {client_proto.SetBankCardReq} SetBankCardReq instance
     */
    SetBankCardReq.create = function create(properties) {
      return new SetBankCardReq(properties);
    };

    /**
     * Encodes the specified SetBankCardReq message. Does not implicitly {@link client_proto.SetBankCardReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SetBankCardReq
     * @static
     * @param {client_proto.ISetBankCardReq} message SetBankCardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetBankCardReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name);
      if (message.account != null && Object.hasOwnProperty.call(message, "account"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.account);
      if (message.cpf != null && Object.hasOwnProperty.call(message, "cpf"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.cpf);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified SetBankCardReq message, length delimited. Does not implicitly {@link client_proto.SetBankCardReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SetBankCardReq
     * @static
     * @param {client_proto.ISetBankCardReq} message SetBankCardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetBankCardReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetBankCardReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SetBankCardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SetBankCardReq} SetBankCardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetBankCardReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SetBankCardReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.name = reader.string();
            break;
          }
          case 2: {
            message.account = reader.string();
            break;
          }
          case 3: {
            message.cpf = reader.string();
            break;
          }
          case 4: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetBankCardReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SetBankCardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SetBankCardReq} SetBankCardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetBankCardReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetBankCardReq message.
     * @function verify
     * @memberof client_proto.SetBankCardReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetBankCardReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      if (message.account != null && message.hasOwnProperty("account"))
        if (!$util.isString(message.account)) return "account: string expected";
      if (message.cpf != null && message.hasOwnProperty("cpf"))
        if (!$util.isString(message.cpf)) return "cpf: string expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates a SetBankCardReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SetBankCardReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SetBankCardReq} SetBankCardReq
     */
    SetBankCardReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SetBankCardReq) return object;
      var message = new $root.client_proto.SetBankCardReq();
      if (object.name != null) message.name = String(object.name);
      if (object.account != null) message.account = String(object.account);
      if (object.cpf != null) message.cpf = String(object.cpf);
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SetBankCardReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SetBankCardReq
     * @static
     * @param {client_proto.SetBankCardReq} message SetBankCardReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetBankCardReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.name = "";
        object.account = "";
        object.cpf = "";
        object.uid = 0;
      }
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      if (message.account != null && message.hasOwnProperty("account")) object.account = message.account;
      if (message.cpf != null && message.hasOwnProperty("cpf")) object.cpf = message.cpf;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this SetBankCardReq to JSON.
     * @function toJSON
     * @memberof client_proto.SetBankCardReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetBankCardReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetBankCardReq
     * @function getTypeUrl
     * @memberof client_proto.SetBankCardReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetBankCardReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SetBankCardReq";
    };

    return SetBankCardReq;
  })();

  client_proto.SetBankCardResp = (function () {
    /**
     * Properties of a SetBankCardResp.
     * @memberof client_proto
     * @interface ISetBankCardResp
     * @property {client_proto.SetBankCardResp.BANKRET|null} [ret] SetBankCardResp ret
     * @property {number|null} [uid] SetBankCardResp uid
     */

    /**
     * Constructs a new SetBankCardResp.
     * @memberof client_proto
     * @classdesc Represents a SetBankCardResp.
     * @implements ISetBankCardResp
     * @constructor
     * @param {client_proto.ISetBankCardResp=} [properties] Properties to set
     */
    function SetBankCardResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetBankCardResp ret.
     * @member {client_proto.SetBankCardResp.BANKRET} ret
     * @memberof client_proto.SetBankCardResp
     * @instance
     */
    SetBankCardResp.prototype.ret = 0;

    /**
     * SetBankCardResp uid.
     * @member {number} uid
     * @memberof client_proto.SetBankCardResp
     * @instance
     */
    SetBankCardResp.prototype.uid = 0;

    /**
     * Creates a new SetBankCardResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SetBankCardResp
     * @static
     * @param {client_proto.ISetBankCardResp=} [properties] Properties to set
     * @returns {client_proto.SetBankCardResp} SetBankCardResp instance
     */
    SetBankCardResp.create = function create(properties) {
      return new SetBankCardResp(properties);
    };

    /**
     * Encodes the specified SetBankCardResp message. Does not implicitly {@link client_proto.SetBankCardResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SetBankCardResp
     * @static
     * @param {client_proto.ISetBankCardResp} message SetBankCardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetBankCardResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.ret != null && Object.hasOwnProperty.call(message, "ret"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.ret);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified SetBankCardResp message, length delimited. Does not implicitly {@link client_proto.SetBankCardResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SetBankCardResp
     * @static
     * @param {client_proto.ISetBankCardResp} message SetBankCardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetBankCardResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetBankCardResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SetBankCardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SetBankCardResp} SetBankCardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetBankCardResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SetBankCardResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.ret = reader.int32();
            break;
          }
          case 2: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetBankCardResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SetBankCardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SetBankCardResp} SetBankCardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetBankCardResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetBankCardResp message.
     * @function verify
     * @memberof client_proto.SetBankCardResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetBankCardResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.ret != null && message.hasOwnProperty("ret"))
        switch (message.ret) {
          default:
            return "ret: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
            break;
        }
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates a SetBankCardResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SetBankCardResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SetBankCardResp} SetBankCardResp
     */
    SetBankCardResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SetBankCardResp) return object;
      var message = new $root.client_proto.SetBankCardResp();
      switch (object.ret) {
        default:
          if (typeof object.ret === "number") {
            message.ret = object.ret;
            break;
          }
          break;
        case "RET_SUCCESS":
        case 0:
          message.ret = 0;
          break;
        case "RET_NOTNULL":
        case 1:
          message.ret = 1;
          break;
        case "RET_REPEAT":
        case 2:
          message.ret = 2;
          break;
        case "RET_BLANKLIST":
        case 3:
          message.ret = 3;
          break;
        case "RET_FAIL":
        case 4:
          message.ret = 4;
          break;
      }
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SetBankCardResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SetBankCardResp
     * @static
     * @param {client_proto.SetBankCardResp} message SetBankCardResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetBankCardResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.ret = options.enums === String ? "RET_SUCCESS" : 0;
        object.uid = 0;
      }
      if (message.ret != null && message.hasOwnProperty("ret"))
        object.ret =
          options.enums === String
            ? $root.client_proto.SetBankCardResp.BANKRET[message.ret] === undefined
              ? message.ret
              : $root.client_proto.SetBankCardResp.BANKRET[message.ret]
            : message.ret;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this SetBankCardResp to JSON.
     * @function toJSON
     * @memberof client_proto.SetBankCardResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetBankCardResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetBankCardResp
     * @function getTypeUrl
     * @memberof client_proto.SetBankCardResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetBankCardResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SetBankCardResp";
    };

    /**
     * BANKRET enum.
     * @name client_proto.SetBankCardResp.BANKRET
     * @enum {number}
     * @property {number} RET_SUCCESS=0 RET_SUCCESS value
     * @property {number} RET_NOTNULL=1 RET_NOTNULL value
     * @property {number} RET_REPEAT=2 RET_REPEAT value
     * @property {number} RET_BLANKLIST=3 RET_BLANKLIST value
     * @property {number} RET_FAIL=4 RET_FAIL value
     */
    SetBankCardResp.BANKRET = (function () {
      var valuesById = {},
        values = Object.create(valuesById);
      values[(valuesById[0] = "RET_SUCCESS")] = 0;
      values[(valuesById[1] = "RET_NOTNULL")] = 1;
      values[(valuesById[2] = "RET_REPEAT")] = 2;
      values[(valuesById[3] = "RET_BLANKLIST")] = 3;
      values[(valuesById[4] = "RET_FAIL")] = 4;
      return values;
    })();

    return SetBankCardResp;
  })();

  client_proto.CreateWithdrawOrderReq = (function () {
    /**
     * Properties of a CreateWithdrawOrderReq.
     * @memberof client_proto
     * @interface ICreateWithdrawOrderReq
     * @property {number|null} [item_id] CreateWithdrawOrderReq item_id
     * @property {string|null} [code] CreateWithdrawOrderReq code
     */

    /**
     * Constructs a new CreateWithdrawOrderReq.
     * @memberof client_proto
     * @classdesc Represents a CreateWithdrawOrderReq.
     * @implements ICreateWithdrawOrderReq
     * @constructor
     * @param {client_proto.ICreateWithdrawOrderReq=} [properties] Properties to set
     */
    function CreateWithdrawOrderReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateWithdrawOrderReq item_id.
     * @member {number} item_id
     * @memberof client_proto.CreateWithdrawOrderReq
     * @instance
     */
    CreateWithdrawOrderReq.prototype.item_id = 0;

    /**
     * CreateWithdrawOrderReq code.
     * @member {string} code
     * @memberof client_proto.CreateWithdrawOrderReq
     * @instance
     */
    CreateWithdrawOrderReq.prototype.code = "";

    /**
     * Creates a new CreateWithdrawOrderReq instance using the specified properties.
     * @function create
     * @memberof client_proto.CreateWithdrawOrderReq
     * @static
     * @param {client_proto.ICreateWithdrawOrderReq=} [properties] Properties to set
     * @returns {client_proto.CreateWithdrawOrderReq} CreateWithdrawOrderReq instance
     */
    CreateWithdrawOrderReq.create = function create(properties) {
      return new CreateWithdrawOrderReq(properties);
    };

    /**
     * Encodes the specified CreateWithdrawOrderReq message. Does not implicitly {@link client_proto.CreateWithdrawOrderReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CreateWithdrawOrderReq
     * @static
     * @param {client_proto.ICreateWithdrawOrderReq} message CreateWithdrawOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateWithdrawOrderReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.item_id != null && Object.hasOwnProperty.call(message, "item_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.item_id);
      if (message.code != null && Object.hasOwnProperty.call(message, "code"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.code);
      return writer;
    };

    /**
     * Encodes the specified CreateWithdrawOrderReq message, length delimited. Does not implicitly {@link client_proto.CreateWithdrawOrderReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CreateWithdrawOrderReq
     * @static
     * @param {client_proto.ICreateWithdrawOrderReq} message CreateWithdrawOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateWithdrawOrderReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateWithdrawOrderReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CreateWithdrawOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CreateWithdrawOrderReq} CreateWithdrawOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateWithdrawOrderReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CreateWithdrawOrderReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.item_id = reader.int32();
            break;
          }
          case 2: {
            message.code = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateWithdrawOrderReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CreateWithdrawOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CreateWithdrawOrderReq} CreateWithdrawOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateWithdrawOrderReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateWithdrawOrderReq message.
     * @function verify
     * @memberof client_proto.CreateWithdrawOrderReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateWithdrawOrderReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.item_id != null && message.hasOwnProperty("item_id"))
        if (!$util.isInteger(message.item_id)) return "item_id: integer expected";
      if (message.code != null && message.hasOwnProperty("code"))
        if (!$util.isString(message.code)) return "code: string expected";
      return null;
    };

    /**
     * Creates a CreateWithdrawOrderReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CreateWithdrawOrderReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CreateWithdrawOrderReq} CreateWithdrawOrderReq
     */
    CreateWithdrawOrderReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CreateWithdrawOrderReq) return object;
      var message = new $root.client_proto.CreateWithdrawOrderReq();
      if (object.item_id != null) message.item_id = object.item_id | 0;
      if (object.code != null) message.code = String(object.code);
      return message;
    };

    /**
     * Creates a plain object from a CreateWithdrawOrderReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CreateWithdrawOrderReq
     * @static
     * @param {client_proto.CreateWithdrawOrderReq} message CreateWithdrawOrderReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateWithdrawOrderReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.item_id = 0;
        object.code = "";
      }
      if (message.item_id != null && message.hasOwnProperty("item_id")) object.item_id = message.item_id;
      if (message.code != null && message.hasOwnProperty("code")) object.code = message.code;
      return object;
    };

    /**
     * Converts this CreateWithdrawOrderReq to JSON.
     * @function toJSON
     * @memberof client_proto.CreateWithdrawOrderReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateWithdrawOrderReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateWithdrawOrderReq
     * @function getTypeUrl
     * @memberof client_proto.CreateWithdrawOrderReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateWithdrawOrderReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CreateWithdrawOrderReq";
    };

    return CreateWithdrawOrderReq;
  })();

  client_proto.CreateWithdrawOrderResp = (function () {
    /**
     * Properties of a CreateWithdrawOrderResp.
     * @memberof client_proto
     * @interface ICreateWithdrawOrderResp
     * @property {number|null} [ret] CreateWithdrawOrderResp ret
     */

    /**
     * Constructs a new CreateWithdrawOrderResp.
     * @memberof client_proto
     * @classdesc Represents a CreateWithdrawOrderResp.
     * @implements ICreateWithdrawOrderResp
     * @constructor
     * @param {client_proto.ICreateWithdrawOrderResp=} [properties] Properties to set
     */
    function CreateWithdrawOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateWithdrawOrderResp ret.
     * @member {number} ret
     * @memberof client_proto.CreateWithdrawOrderResp
     * @instance
     */
    CreateWithdrawOrderResp.prototype.ret = 0;

    /**
     * Creates a new CreateWithdrawOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.CreateWithdrawOrderResp
     * @static
     * @param {client_proto.ICreateWithdrawOrderResp=} [properties] Properties to set
     * @returns {client_proto.CreateWithdrawOrderResp} CreateWithdrawOrderResp instance
     */
    CreateWithdrawOrderResp.create = function create(properties) {
      return new CreateWithdrawOrderResp(properties);
    };

    /**
     * Encodes the specified CreateWithdrawOrderResp message. Does not implicitly {@link client_proto.CreateWithdrawOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CreateWithdrawOrderResp
     * @static
     * @param {client_proto.ICreateWithdrawOrderResp} message CreateWithdrawOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateWithdrawOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.ret != null && Object.hasOwnProperty.call(message, "ret"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.ret);
      return writer;
    };

    /**
     * Encodes the specified CreateWithdrawOrderResp message, length delimited. Does not implicitly {@link client_proto.CreateWithdrawOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CreateWithdrawOrderResp
     * @static
     * @param {client_proto.ICreateWithdrawOrderResp} message CreateWithdrawOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateWithdrawOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateWithdrawOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CreateWithdrawOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CreateWithdrawOrderResp} CreateWithdrawOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateWithdrawOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CreateWithdrawOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.ret = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateWithdrawOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CreateWithdrawOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CreateWithdrawOrderResp} CreateWithdrawOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateWithdrawOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateWithdrawOrderResp message.
     * @function verify
     * @memberof client_proto.CreateWithdrawOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateWithdrawOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.ret != null && message.hasOwnProperty("ret"))
        if (!$util.isInteger(message.ret)) return "ret: integer expected";
      return null;
    };

    /**
     * Creates a CreateWithdrawOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CreateWithdrawOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CreateWithdrawOrderResp} CreateWithdrawOrderResp
     */
    CreateWithdrawOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CreateWithdrawOrderResp) return object;
      var message = new $root.client_proto.CreateWithdrawOrderResp();
      if (object.ret != null) message.ret = object.ret | 0;
      return message;
    };

    /**
     * Creates a plain object from a CreateWithdrawOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CreateWithdrawOrderResp
     * @static
     * @param {client_proto.CreateWithdrawOrderResp} message CreateWithdrawOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateWithdrawOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.ret = 0;
      if (message.ret != null && message.hasOwnProperty("ret")) object.ret = message.ret;
      return object;
    };

    /**
     * Converts this CreateWithdrawOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.CreateWithdrawOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateWithdrawOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateWithdrawOrderResp
     * @function getTypeUrl
     * @memberof client_proto.CreateWithdrawOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateWithdrawOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CreateWithdrawOrderResp";
    };

    return CreateWithdrawOrderResp;
  })();

  client_proto.CompleteWithdrawReq = (function () {
    /**
     * Properties of a CompleteWithdrawReq.
     * @memberof client_proto
     * @interface ICompleteWithdrawReq
     * @property {number|null} [uid] CompleteWithdrawReq uid
     * @property {number|null} [status] CompleteWithdrawReq status
     * @property {number|Long|null} [amount] CompleteWithdrawReq amount
     * @property {number|Long|null} [fee] CompleteWithdrawReq fee
     * @property {number|Long|null} [real_amount] CompleteWithdrawReq real_amount
     * @property {string|null} [order_id] CompleteWithdrawReq order_id
     */

    /**
     * Constructs a new CompleteWithdrawReq.
     * @memberof client_proto
     * @classdesc Represents a CompleteWithdrawReq.
     * @implements ICompleteWithdrawReq
     * @constructor
     * @param {client_proto.ICompleteWithdrawReq=} [properties] Properties to set
     */
    function CompleteWithdrawReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CompleteWithdrawReq uid.
     * @member {number} uid
     * @memberof client_proto.CompleteWithdrawReq
     * @instance
     */
    CompleteWithdrawReq.prototype.uid = 0;

    /**
     * CompleteWithdrawReq status.
     * @member {number} status
     * @memberof client_proto.CompleteWithdrawReq
     * @instance
     */
    CompleteWithdrawReq.prototype.status = 0;

    /**
     * CompleteWithdrawReq amount.
     * @member {number|Long} amount
     * @memberof client_proto.CompleteWithdrawReq
     * @instance
     */
    CompleteWithdrawReq.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CompleteWithdrawReq fee.
     * @member {number|Long} fee
     * @memberof client_proto.CompleteWithdrawReq
     * @instance
     */
    CompleteWithdrawReq.prototype.fee = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CompleteWithdrawReq real_amount.
     * @member {number|Long} real_amount
     * @memberof client_proto.CompleteWithdrawReq
     * @instance
     */
    CompleteWithdrawReq.prototype.real_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CompleteWithdrawReq order_id.
     * @member {string} order_id
     * @memberof client_proto.CompleteWithdrawReq
     * @instance
     */
    CompleteWithdrawReq.prototype.order_id = "";

    /**
     * Creates a new CompleteWithdrawReq instance using the specified properties.
     * @function create
     * @memberof client_proto.CompleteWithdrawReq
     * @static
     * @param {client_proto.ICompleteWithdrawReq=} [properties] Properties to set
     * @returns {client_proto.CompleteWithdrawReq} CompleteWithdrawReq instance
     */
    CompleteWithdrawReq.create = function create(properties) {
      return new CompleteWithdrawReq(properties);
    };

    /**
     * Encodes the specified CompleteWithdrawReq message. Does not implicitly {@link client_proto.CompleteWithdrawReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CompleteWithdrawReq
     * @static
     * @param {client_proto.ICompleteWithdrawReq} message CompleteWithdrawReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CompleteWithdrawReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.status);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.amount);
      if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.fee);
      if (message.real_amount != null && Object.hasOwnProperty.call(message, "real_amount"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.real_amount);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.order_id);
      return writer;
    };

    /**
     * Encodes the specified CompleteWithdrawReq message, length delimited. Does not implicitly {@link client_proto.CompleteWithdrawReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CompleteWithdrawReq
     * @static
     * @param {client_proto.ICompleteWithdrawReq} message CompleteWithdrawReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CompleteWithdrawReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CompleteWithdrawReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CompleteWithdrawReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CompleteWithdrawReq} CompleteWithdrawReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CompleteWithdrawReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CompleteWithdrawReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.status = reader.int32();
            break;
          }
          case 3: {
            message.amount = reader.int64();
            break;
          }
          case 4: {
            message.fee = reader.int64();
            break;
          }
          case 5: {
            message.real_amount = reader.int64();
            break;
          }
          case 6: {
            message.order_id = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CompleteWithdrawReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CompleteWithdrawReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CompleteWithdrawReq} CompleteWithdrawReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CompleteWithdrawReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CompleteWithdrawReq message.
     * @function verify
     * @memberof client_proto.CompleteWithdrawReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CompleteWithdrawReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.fee != null && message.hasOwnProperty("fee"))
        if (
          !$util.isInteger(message.fee) &&
          !(message.fee && $util.isInteger(message.fee.low) && $util.isInteger(message.fee.high))
        )
          return "fee: integer|Long expected";
      if (message.real_amount != null && message.hasOwnProperty("real_amount"))
        if (
          !$util.isInteger(message.real_amount) &&
          !(
            message.real_amount &&
            $util.isInteger(message.real_amount.low) &&
            $util.isInteger(message.real_amount.high)
          )
        )
          return "real_amount: integer|Long expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isString(message.order_id)) return "order_id: string expected";
      return null;
    };

    /**
     * Creates a CompleteWithdrawReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CompleteWithdrawReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CompleteWithdrawReq} CompleteWithdrawReq
     */
    CompleteWithdrawReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CompleteWithdrawReq) return object;
      var message = new $root.client_proto.CompleteWithdrawReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.status != null) message.status = object.status | 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.fee != null)
        if ($util.Long) (message.fee = $util.Long.fromValue(object.fee)).unsigned = false;
        else if (typeof object.fee === "string") message.fee = parseInt(object.fee, 10);
        else if (typeof object.fee === "number") message.fee = object.fee;
        else if (typeof object.fee === "object")
          message.fee = new $util.LongBits(object.fee.low >>> 0, object.fee.high >>> 0).toNumber();
      if (object.real_amount != null)
        if ($util.Long) (message.real_amount = $util.Long.fromValue(object.real_amount)).unsigned = false;
        else if (typeof object.real_amount === "string") message.real_amount = parseInt(object.real_amount, 10);
        else if (typeof object.real_amount === "number") message.real_amount = object.real_amount;
        else if (typeof object.real_amount === "object")
          message.real_amount = new $util.LongBits(
            object.real_amount.low >>> 0,
            object.real_amount.high >>> 0,
          ).toNumber();
      if (object.order_id != null) message.order_id = String(object.order_id);
      return message;
    };

    /**
     * Creates a plain object from a CompleteWithdrawReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CompleteWithdrawReq
     * @static
     * @param {client_proto.CompleteWithdrawReq} message CompleteWithdrawReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CompleteWithdrawReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.status = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.fee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.fee = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.real_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.real_amount = options.longs === String ? "0" : 0;
        object.order_id = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.fee != null && message.hasOwnProperty("fee"))
        if (typeof message.fee === "number") object.fee = options.longs === String ? String(message.fee) : message.fee;
        else
          object.fee =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.fee)
              : options.longs === Number
                ? new $util.LongBits(message.fee.low >>> 0, message.fee.high >>> 0).toNumber()
                : message.fee;
      if (message.real_amount != null && message.hasOwnProperty("real_amount"))
        if (typeof message.real_amount === "number")
          object.real_amount = options.longs === String ? String(message.real_amount) : message.real_amount;
        else
          object.real_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.real_amount)
              : options.longs === Number
                ? new $util.LongBits(message.real_amount.low >>> 0, message.real_amount.high >>> 0).toNumber()
                : message.real_amount;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      return object;
    };

    /**
     * Converts this CompleteWithdrawReq to JSON.
     * @function toJSON
     * @memberof client_proto.CompleteWithdrawReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CompleteWithdrawReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CompleteWithdrawReq
     * @function getTypeUrl
     * @memberof client_proto.CompleteWithdrawReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CompleteWithdrawReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CompleteWithdrawReq";
    };

    return CompleteWithdrawReq;
  })();

  client_proto.CompleteWithdrawResp = (function () {
    /**
     * Properties of a CompleteWithdrawResp.
     * @memberof client_proto
     * @interface ICompleteWithdrawResp
     */

    /**
     * Constructs a new CompleteWithdrawResp.
     * @memberof client_proto
     * @classdesc Represents a CompleteWithdrawResp.
     * @implements ICompleteWithdrawResp
     * @constructor
     * @param {client_proto.ICompleteWithdrawResp=} [properties] Properties to set
     */
    function CompleteWithdrawResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new CompleteWithdrawResp instance using the specified properties.
     * @function create
     * @memberof client_proto.CompleteWithdrawResp
     * @static
     * @param {client_proto.ICompleteWithdrawResp=} [properties] Properties to set
     * @returns {client_proto.CompleteWithdrawResp} CompleteWithdrawResp instance
     */
    CompleteWithdrawResp.create = function create(properties) {
      return new CompleteWithdrawResp(properties);
    };

    /**
     * Encodes the specified CompleteWithdrawResp message. Does not implicitly {@link client_proto.CompleteWithdrawResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CompleteWithdrawResp
     * @static
     * @param {client_proto.ICompleteWithdrawResp} message CompleteWithdrawResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CompleteWithdrawResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified CompleteWithdrawResp message, length delimited. Does not implicitly {@link client_proto.CompleteWithdrawResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CompleteWithdrawResp
     * @static
     * @param {client_proto.ICompleteWithdrawResp} message CompleteWithdrawResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CompleteWithdrawResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CompleteWithdrawResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CompleteWithdrawResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CompleteWithdrawResp} CompleteWithdrawResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CompleteWithdrawResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CompleteWithdrawResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CompleteWithdrawResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CompleteWithdrawResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CompleteWithdrawResp} CompleteWithdrawResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CompleteWithdrawResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CompleteWithdrawResp message.
     * @function verify
     * @memberof client_proto.CompleteWithdrawResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CompleteWithdrawResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a CompleteWithdrawResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CompleteWithdrawResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CompleteWithdrawResp} CompleteWithdrawResp
     */
    CompleteWithdrawResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CompleteWithdrawResp) return object;
      return new $root.client_proto.CompleteWithdrawResp();
    };

    /**
     * Creates a plain object from a CompleteWithdrawResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CompleteWithdrawResp
     * @static
     * @param {client_proto.CompleteWithdrawResp} message CompleteWithdrawResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CompleteWithdrawResp.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this CompleteWithdrawResp to JSON.
     * @function toJSON
     * @memberof client_proto.CompleteWithdrawResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CompleteWithdrawResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CompleteWithdrawResp
     * @function getTypeUrl
     * @memberof client_proto.CompleteWithdrawResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CompleteWithdrawResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CompleteWithdrawResp";
    };

    return CompleteWithdrawResp;
  })();

  client_proto.PhpChargeOrderReq = (function () {
    /**
     * Properties of a PhpChargeOrderReq.
     * @memberof client_proto
     * @interface IPhpChargeOrderReq
     * @property {number|null} [uid] PhpChargeOrderReq uid
     * @property {number|null} [charge_id] PhpChargeOrderReq charge_id
     * @property {number|Long|null} [amount] PhpChargeOrderReq amount
     * @property {number|Long|null} [balance] PhpChargeOrderReq balance
     * @property {number|Long|null} [extra] PhpChargeOrderReq extra
     * @property {number|null} [first_pay] PhpChargeOrderReq first_pay
     * @property {string|null} [ip] PhpChargeOrderReq ip
     * @property {string|null} [nick_name] PhpChargeOrderReq nick_name
     * @property {string|null} [from] PhpChargeOrderReq from
     * @property {number|null} [channel_id] PhpChargeOrderReq channel_id
     * @property {number|null} [order_id] PhpChargeOrderReq order_id
     * @property {string|null} [phone] PhpChargeOrderReq phone
     * @property {string|null} [channel] PhpChargeOrderReq channel
     */

    /**
     * Constructs a new PhpChargeOrderReq.
     * @memberof client_proto
     * @classdesc Represents a PhpChargeOrderReq.
     * @implements IPhpChargeOrderReq
     * @constructor
     * @param {client_proto.IPhpChargeOrderReq=} [properties] Properties to set
     */
    function PhpChargeOrderReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpChargeOrderReq uid.
     * @member {number} uid
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.uid = 0;

    /**
     * PhpChargeOrderReq charge_id.
     * @member {number} charge_id
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.charge_id = 0;

    /**
     * PhpChargeOrderReq amount.
     * @member {number|Long} amount
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PhpChargeOrderReq balance.
     * @member {number|Long} balance
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.balance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PhpChargeOrderReq extra.
     * @member {number|Long} extra
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.extra = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PhpChargeOrderReq first_pay.
     * @member {number} first_pay
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.first_pay = 0;

    /**
     * PhpChargeOrderReq ip.
     * @member {string} ip
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.ip = "";

    /**
     * PhpChargeOrderReq nick_name.
     * @member {string} nick_name
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.nick_name = "";

    /**
     * PhpChargeOrderReq from.
     * @member {string} from
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.from = "";

    /**
     * PhpChargeOrderReq channel_id.
     * @member {number} channel_id
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.channel_id = 0;

    /**
     * PhpChargeOrderReq order_id.
     * @member {number} order_id
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.order_id = 0;

    /**
     * PhpChargeOrderReq phone.
     * @member {string} phone
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.phone = "";

    /**
     * PhpChargeOrderReq channel.
     * @member {string} channel
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     */
    PhpChargeOrderReq.prototype.channel = "";

    /**
     * Creates a new PhpChargeOrderReq instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpChargeOrderReq
     * @static
     * @param {client_proto.IPhpChargeOrderReq=} [properties] Properties to set
     * @returns {client_proto.PhpChargeOrderReq} PhpChargeOrderReq instance
     */
    PhpChargeOrderReq.create = function create(properties) {
      return new PhpChargeOrderReq(properties);
    };

    /**
     * Encodes the specified PhpChargeOrderReq message. Does not implicitly {@link client_proto.PhpChargeOrderReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpChargeOrderReq
     * @static
     * @param {client_proto.IPhpChargeOrderReq} message PhpChargeOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpChargeOrderReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.charge_id != null && Object.hasOwnProperty.call(message, "charge_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.charge_id);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.amount);
      if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.balance);
      if (message.extra != null && Object.hasOwnProperty.call(message, "extra"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.extra);
      if (message.first_pay != null && Object.hasOwnProperty.call(message, "first_pay"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.first_pay);
      if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.ip);
      if (message.nick_name != null && Object.hasOwnProperty.call(message, "nick_name"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.nick_name);
      if (message.from != null && Object.hasOwnProperty.call(message, "from"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.from);
      if (message.channel_id != null && Object.hasOwnProperty.call(message, "channel_id"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int32(message.channel_id);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int32(message.order_id);
      if (message.phone != null && Object.hasOwnProperty.call(message, "phone"))
        writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.phone);
      if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
        writer.uint32(/* id 13, wireType 2 =*/ 106).string(message.channel);
      return writer;
    };

    /**
     * Encodes the specified PhpChargeOrderReq message, length delimited. Does not implicitly {@link client_proto.PhpChargeOrderReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpChargeOrderReq
     * @static
     * @param {client_proto.IPhpChargeOrderReq} message PhpChargeOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpChargeOrderReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpChargeOrderReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpChargeOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpChargeOrderReq} PhpChargeOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpChargeOrderReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpChargeOrderReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.charge_id = reader.int32();
            break;
          }
          case 3: {
            message.amount = reader.int64();
            break;
          }
          case 4: {
            message.balance = reader.int64();
            break;
          }
          case 5: {
            message.extra = reader.int64();
            break;
          }
          case 6: {
            message.first_pay = reader.int32();
            break;
          }
          case 7: {
            message.ip = reader.string();
            break;
          }
          case 8: {
            message.nick_name = reader.string();
            break;
          }
          case 9: {
            message.from = reader.string();
            break;
          }
          case 10: {
            message.channel_id = reader.int32();
            break;
          }
          case 11: {
            message.order_id = reader.int32();
            break;
          }
          case 12: {
            message.phone = reader.string();
            break;
          }
          case 13: {
            message.channel = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpChargeOrderReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpChargeOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpChargeOrderReq} PhpChargeOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpChargeOrderReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpChargeOrderReq message.
     * @function verify
     * @memberof client_proto.PhpChargeOrderReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpChargeOrderReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.charge_id != null && message.hasOwnProperty("charge_id"))
        if (!$util.isInteger(message.charge_id)) return "charge_id: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (
          !$util.isInteger(message.balance) &&
          !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high))
        )
          return "balance: integer|Long expected";
      if (message.extra != null && message.hasOwnProperty("extra"))
        if (
          !$util.isInteger(message.extra) &&
          !(message.extra && $util.isInteger(message.extra.low) && $util.isInteger(message.extra.high))
        )
          return "extra: integer|Long expected";
      if (message.first_pay != null && message.hasOwnProperty("first_pay"))
        if (!$util.isInteger(message.first_pay)) return "first_pay: integer expected";
      if (message.ip != null && message.hasOwnProperty("ip"))
        if (!$util.isString(message.ip)) return "ip: string expected";
      if (message.nick_name != null && message.hasOwnProperty("nick_name"))
        if (!$util.isString(message.nick_name)) return "nick_name: string expected";
      if (message.from != null && message.hasOwnProperty("from"))
        if (!$util.isString(message.from)) return "from: string expected";
      if (message.channel_id != null && message.hasOwnProperty("channel_id"))
        if (!$util.isInteger(message.channel_id)) return "channel_id: integer expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.phone != null && message.hasOwnProperty("phone"))
        if (!$util.isString(message.phone)) return "phone: string expected";
      if (message.channel != null && message.hasOwnProperty("channel"))
        if (!$util.isString(message.channel)) return "channel: string expected";
      return null;
    };

    /**
     * Creates a PhpChargeOrderReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpChargeOrderReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpChargeOrderReq} PhpChargeOrderReq
     */
    PhpChargeOrderReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpChargeOrderReq) return object;
      var message = new $root.client_proto.PhpChargeOrderReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.charge_id != null) message.charge_id = object.charge_id | 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.balance != null)
        if ($util.Long) (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
        else if (typeof object.balance === "string") message.balance = parseInt(object.balance, 10);
        else if (typeof object.balance === "number") message.balance = object.balance;
        else if (typeof object.balance === "object")
          message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
      if (object.extra != null)
        if ($util.Long) (message.extra = $util.Long.fromValue(object.extra)).unsigned = false;
        else if (typeof object.extra === "string") message.extra = parseInt(object.extra, 10);
        else if (typeof object.extra === "number") message.extra = object.extra;
        else if (typeof object.extra === "object")
          message.extra = new $util.LongBits(object.extra.low >>> 0, object.extra.high >>> 0).toNumber();
      if (object.first_pay != null) message.first_pay = object.first_pay | 0;
      if (object.ip != null) message.ip = String(object.ip);
      if (object.nick_name != null) message.nick_name = String(object.nick_name);
      if (object.from != null) message.from = String(object.from);
      if (object.channel_id != null) message.channel_id = object.channel_id | 0;
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.phone != null) message.phone = String(object.phone);
      if (object.channel != null) message.channel = String(object.channel);
      return message;
    };

    /**
     * Creates a plain object from a PhpChargeOrderReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpChargeOrderReq
     * @static
     * @param {client_proto.PhpChargeOrderReq} message PhpChargeOrderReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpChargeOrderReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.charge_id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.balance =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.balance = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.extra = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.extra = options.longs === String ? "0" : 0;
        object.first_pay = 0;
        object.ip = "";
        object.nick_name = "";
        object.from = "";
        object.channel_id = 0;
        object.order_id = 0;
        object.phone = "";
        object.channel = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.charge_id != null && message.hasOwnProperty("charge_id")) object.charge_id = message.charge_id;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (typeof message.balance === "number")
          object.balance = options.longs === String ? String(message.balance) : message.balance;
        else
          object.balance =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.balance)
              : options.longs === Number
                ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber()
                : message.balance;
      if (message.extra != null && message.hasOwnProperty("extra"))
        if (typeof message.extra === "number")
          object.extra = options.longs === String ? String(message.extra) : message.extra;
        else
          object.extra =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.extra)
              : options.longs === Number
                ? new $util.LongBits(message.extra.low >>> 0, message.extra.high >>> 0).toNumber()
                : message.extra;
      if (message.first_pay != null && message.hasOwnProperty("first_pay")) object.first_pay = message.first_pay;
      if (message.ip != null && message.hasOwnProperty("ip")) object.ip = message.ip;
      if (message.nick_name != null && message.hasOwnProperty("nick_name")) object.nick_name = message.nick_name;
      if (message.from != null && message.hasOwnProperty("from")) object.from = message.from;
      if (message.channel_id != null && message.hasOwnProperty("channel_id")) object.channel_id = message.channel_id;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.phone != null && message.hasOwnProperty("phone")) object.phone = message.phone;
      if (message.channel != null && message.hasOwnProperty("channel")) object.channel = message.channel;
      return object;
    };

    /**
     * Converts this PhpChargeOrderReq to JSON.
     * @function toJSON
     * @memberof client_proto.PhpChargeOrderReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpChargeOrderReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpChargeOrderReq
     * @function getTypeUrl
     * @memberof client_proto.PhpChargeOrderReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpChargeOrderReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpChargeOrderReq";
    };

    return PhpChargeOrderReq;
  })();

  client_proto.PhpChargeOrderResp = (function () {
    /**
     * Properties of a PhpChargeOrderResp.
     * @memberof client_proto
     * @interface IPhpChargeOrderResp
     * @property {number|null} [code] PhpChargeOrderResp code
     * @property {number|null} [uid] PhpChargeOrderResp uid
     * @property {string|null} [order_info] PhpChargeOrderResp order_info
     * @property {string|null} [order_num] PhpChargeOrderResp order_num
     * @property {number|null} [order_id] PhpChargeOrderResp order_id
     */

    /**
     * Constructs a new PhpChargeOrderResp.
     * @memberof client_proto
     * @classdesc Represents a PhpChargeOrderResp.
     * @implements IPhpChargeOrderResp
     * @constructor
     * @param {client_proto.IPhpChargeOrderResp=} [properties] Properties to set
     */
    function PhpChargeOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpChargeOrderResp code.
     * @member {number} code
     * @memberof client_proto.PhpChargeOrderResp
     * @instance
     */
    PhpChargeOrderResp.prototype.code = 0;

    /**
     * PhpChargeOrderResp uid.
     * @member {number} uid
     * @memberof client_proto.PhpChargeOrderResp
     * @instance
     */
    PhpChargeOrderResp.prototype.uid = 0;

    /**
     * PhpChargeOrderResp order_info.
     * @member {string} order_info
     * @memberof client_proto.PhpChargeOrderResp
     * @instance
     */
    PhpChargeOrderResp.prototype.order_info = "";

    /**
     * PhpChargeOrderResp order_num.
     * @member {string} order_num
     * @memberof client_proto.PhpChargeOrderResp
     * @instance
     */
    PhpChargeOrderResp.prototype.order_num = "";

    /**
     * PhpChargeOrderResp order_id.
     * @member {number} order_id
     * @memberof client_proto.PhpChargeOrderResp
     * @instance
     */
    PhpChargeOrderResp.prototype.order_id = 0;

    /**
     * Creates a new PhpChargeOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpChargeOrderResp
     * @static
     * @param {client_proto.IPhpChargeOrderResp=} [properties] Properties to set
     * @returns {client_proto.PhpChargeOrderResp} PhpChargeOrderResp instance
     */
    PhpChargeOrderResp.create = function create(properties) {
      return new PhpChargeOrderResp(properties);
    };

    /**
     * Encodes the specified PhpChargeOrderResp message. Does not implicitly {@link client_proto.PhpChargeOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpChargeOrderResp
     * @static
     * @param {client_proto.IPhpChargeOrderResp} message PhpChargeOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpChargeOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.code != null && Object.hasOwnProperty.call(message, "code"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.code);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.uid);
      if (message.order_info != null && Object.hasOwnProperty.call(message, "order_info"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.order_info);
      if (message.order_num != null && Object.hasOwnProperty.call(message, "order_num"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.order_num);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.order_id);
      return writer;
    };

    /**
     * Encodes the specified PhpChargeOrderResp message, length delimited. Does not implicitly {@link client_proto.PhpChargeOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpChargeOrderResp
     * @static
     * @param {client_proto.IPhpChargeOrderResp} message PhpChargeOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpChargeOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpChargeOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpChargeOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpChargeOrderResp} PhpChargeOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpChargeOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpChargeOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.code = reader.int32();
            break;
          }
          case 2: {
            message.uid = reader.uint32();
            break;
          }
          case 3: {
            message.order_info = reader.string();
            break;
          }
          case 4: {
            message.order_num = reader.string();
            break;
          }
          case 5: {
            message.order_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpChargeOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpChargeOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpChargeOrderResp} PhpChargeOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpChargeOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpChargeOrderResp message.
     * @function verify
     * @memberof client_proto.PhpChargeOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpChargeOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.code != null && message.hasOwnProperty("code"))
        if (!$util.isInteger(message.code)) return "code: integer expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.order_info != null && message.hasOwnProperty("order_info"))
        if (!$util.isString(message.order_info)) return "order_info: string expected";
      if (message.order_num != null && message.hasOwnProperty("order_num"))
        if (!$util.isString(message.order_num)) return "order_num: string expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      return null;
    };

    /**
     * Creates a PhpChargeOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpChargeOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpChargeOrderResp} PhpChargeOrderResp
     */
    PhpChargeOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpChargeOrderResp) return object;
      var message = new $root.client_proto.PhpChargeOrderResp();
      if (object.code != null) message.code = object.code | 0;
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.order_info != null) message.order_info = String(object.order_info);
      if (object.order_num != null) message.order_num = String(object.order_num);
      if (object.order_id != null) message.order_id = object.order_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a PhpChargeOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpChargeOrderResp
     * @static
     * @param {client_proto.PhpChargeOrderResp} message PhpChargeOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpChargeOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.code = 0;
        object.uid = 0;
        object.order_info = "";
        object.order_num = "";
        object.order_id = 0;
      }
      if (message.code != null && message.hasOwnProperty("code")) object.code = message.code;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.order_info != null && message.hasOwnProperty("order_info")) object.order_info = message.order_info;
      if (message.order_num != null && message.hasOwnProperty("order_num")) object.order_num = message.order_num;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      return object;
    };

    /**
     * Converts this PhpChargeOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.PhpChargeOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpChargeOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpChargeOrderResp
     * @function getTypeUrl
     * @memberof client_proto.PhpChargeOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpChargeOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpChargeOrderResp";
    };

    return PhpChargeOrderResp;
  })();

  client_proto.PhpChargeNotifyReq = (function () {
    /**
     * Properties of a PhpChargeNotifyReq.
     * @memberof client_proto
     * @interface IPhpChargeNotifyReq
     * @property {number|null} [uid] PhpChargeNotifyReq uid
     * @property {number|null} [charge_id] PhpChargeNotifyReq charge_id
     * @property {number|Long|null} [amount] PhpChargeNotifyReq amount
     * @property {number|null} [order_id] PhpChargeNotifyReq order_id
     * @property {number|null} [status] PhpChargeNotifyReq status
     * @property {number|null} [expire] PhpChargeNotifyReq expire
     */

    /**
     * Constructs a new PhpChargeNotifyReq.
     * @memberof client_proto
     * @classdesc Represents a PhpChargeNotifyReq.
     * @implements IPhpChargeNotifyReq
     * @constructor
     * @param {client_proto.IPhpChargeNotifyReq=} [properties] Properties to set
     */
    function PhpChargeNotifyReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpChargeNotifyReq uid.
     * @member {number} uid
     * @memberof client_proto.PhpChargeNotifyReq
     * @instance
     */
    PhpChargeNotifyReq.prototype.uid = 0;

    /**
     * PhpChargeNotifyReq charge_id.
     * @member {number} charge_id
     * @memberof client_proto.PhpChargeNotifyReq
     * @instance
     */
    PhpChargeNotifyReq.prototype.charge_id = 0;

    /**
     * PhpChargeNotifyReq amount.
     * @member {number|Long} amount
     * @memberof client_proto.PhpChargeNotifyReq
     * @instance
     */
    PhpChargeNotifyReq.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PhpChargeNotifyReq order_id.
     * @member {number} order_id
     * @memberof client_proto.PhpChargeNotifyReq
     * @instance
     */
    PhpChargeNotifyReq.prototype.order_id = 0;

    /**
     * PhpChargeNotifyReq status.
     * @member {number} status
     * @memberof client_proto.PhpChargeNotifyReq
     * @instance
     */
    PhpChargeNotifyReq.prototype.status = 0;

    /**
     * PhpChargeNotifyReq expire.
     * @member {number} expire
     * @memberof client_proto.PhpChargeNotifyReq
     * @instance
     */
    PhpChargeNotifyReq.prototype.expire = 0;

    /**
     * Creates a new PhpChargeNotifyReq instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpChargeNotifyReq
     * @static
     * @param {client_proto.IPhpChargeNotifyReq=} [properties] Properties to set
     * @returns {client_proto.PhpChargeNotifyReq} PhpChargeNotifyReq instance
     */
    PhpChargeNotifyReq.create = function create(properties) {
      return new PhpChargeNotifyReq(properties);
    };

    /**
     * Encodes the specified PhpChargeNotifyReq message. Does not implicitly {@link client_proto.PhpChargeNotifyReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpChargeNotifyReq
     * @static
     * @param {client_proto.IPhpChargeNotifyReq} message PhpChargeNotifyReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpChargeNotifyReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.charge_id != null && Object.hasOwnProperty.call(message, "charge_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.charge_id);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.amount);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.order_id);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.status);
      if (message.expire != null && Object.hasOwnProperty.call(message, "expire"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.expire);
      return writer;
    };

    /**
     * Encodes the specified PhpChargeNotifyReq message, length delimited. Does not implicitly {@link client_proto.PhpChargeNotifyReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpChargeNotifyReq
     * @static
     * @param {client_proto.IPhpChargeNotifyReq} message PhpChargeNotifyReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpChargeNotifyReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpChargeNotifyReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpChargeNotifyReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpChargeNotifyReq} PhpChargeNotifyReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpChargeNotifyReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpChargeNotifyReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.charge_id = reader.int32();
            break;
          }
          case 3: {
            message.amount = reader.int64();
            break;
          }
          case 4: {
            message.order_id = reader.int32();
            break;
          }
          case 5: {
            message.status = reader.int32();
            break;
          }
          case 6: {
            message.expire = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpChargeNotifyReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpChargeNotifyReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpChargeNotifyReq} PhpChargeNotifyReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpChargeNotifyReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpChargeNotifyReq message.
     * @function verify
     * @memberof client_proto.PhpChargeNotifyReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpChargeNotifyReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.charge_id != null && message.hasOwnProperty("charge_id"))
        if (!$util.isInteger(message.charge_id)) return "charge_id: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      if (message.expire != null && message.hasOwnProperty("expire"))
        if (!$util.isInteger(message.expire)) return "expire: integer expected";
      return null;
    };

    /**
     * Creates a PhpChargeNotifyReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpChargeNotifyReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpChargeNotifyReq} PhpChargeNotifyReq
     */
    PhpChargeNotifyReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpChargeNotifyReq) return object;
      var message = new $root.client_proto.PhpChargeNotifyReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.charge_id != null) message.charge_id = object.charge_id | 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.status != null) message.status = object.status | 0;
      if (object.expire != null) message.expire = object.expire | 0;
      return message;
    };

    /**
     * Creates a plain object from a PhpChargeNotifyReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpChargeNotifyReq
     * @static
     * @param {client_proto.PhpChargeNotifyReq} message PhpChargeNotifyReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpChargeNotifyReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.charge_id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        object.order_id = 0;
        object.status = 0;
        object.expire = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.charge_id != null && message.hasOwnProperty("charge_id")) object.charge_id = message.charge_id;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      if (message.expire != null && message.hasOwnProperty("expire")) object.expire = message.expire;
      return object;
    };

    /**
     * Converts this PhpChargeNotifyReq to JSON.
     * @function toJSON
     * @memberof client_proto.PhpChargeNotifyReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpChargeNotifyReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpChargeNotifyReq
     * @function getTypeUrl
     * @memberof client_proto.PhpChargeNotifyReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpChargeNotifyReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpChargeNotifyReq";
    };

    return PhpChargeNotifyReq;
  })();

  client_proto.PhpChargeNotifyResp = (function () {
    /**
     * Properties of a PhpChargeNotifyResp.
     * @memberof client_proto
     * @interface IPhpChargeNotifyResp
     * @property {number|null} [code] PhpChargeNotifyResp code
     * @property {string|null} [order_num] PhpChargeNotifyResp order_num
     */

    /**
     * Constructs a new PhpChargeNotifyResp.
     * @memberof client_proto
     * @classdesc Represents a PhpChargeNotifyResp.
     * @implements IPhpChargeNotifyResp
     * @constructor
     * @param {client_proto.IPhpChargeNotifyResp=} [properties] Properties to set
     */
    function PhpChargeNotifyResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpChargeNotifyResp code.
     * @member {number} code
     * @memberof client_proto.PhpChargeNotifyResp
     * @instance
     */
    PhpChargeNotifyResp.prototype.code = 0;

    /**
     * PhpChargeNotifyResp order_num.
     * @member {string} order_num
     * @memberof client_proto.PhpChargeNotifyResp
     * @instance
     */
    PhpChargeNotifyResp.prototype.order_num = "";

    /**
     * Creates a new PhpChargeNotifyResp instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpChargeNotifyResp
     * @static
     * @param {client_proto.IPhpChargeNotifyResp=} [properties] Properties to set
     * @returns {client_proto.PhpChargeNotifyResp} PhpChargeNotifyResp instance
     */
    PhpChargeNotifyResp.create = function create(properties) {
      return new PhpChargeNotifyResp(properties);
    };

    /**
     * Encodes the specified PhpChargeNotifyResp message. Does not implicitly {@link client_proto.PhpChargeNotifyResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpChargeNotifyResp
     * @static
     * @param {client_proto.IPhpChargeNotifyResp} message PhpChargeNotifyResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpChargeNotifyResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.code != null && Object.hasOwnProperty.call(message, "code"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.code);
      if (message.order_num != null && Object.hasOwnProperty.call(message, "order_num"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.order_num);
      return writer;
    };

    /**
     * Encodes the specified PhpChargeNotifyResp message, length delimited. Does not implicitly {@link client_proto.PhpChargeNotifyResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpChargeNotifyResp
     * @static
     * @param {client_proto.IPhpChargeNotifyResp} message PhpChargeNotifyResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpChargeNotifyResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpChargeNotifyResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpChargeNotifyResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpChargeNotifyResp} PhpChargeNotifyResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpChargeNotifyResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpChargeNotifyResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.code = reader.int32();
            break;
          }
          case 2: {
            message.order_num = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpChargeNotifyResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpChargeNotifyResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpChargeNotifyResp} PhpChargeNotifyResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpChargeNotifyResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpChargeNotifyResp message.
     * @function verify
     * @memberof client_proto.PhpChargeNotifyResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpChargeNotifyResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.code != null && message.hasOwnProperty("code"))
        if (!$util.isInteger(message.code)) return "code: integer expected";
      if (message.order_num != null && message.hasOwnProperty("order_num"))
        if (!$util.isString(message.order_num)) return "order_num: string expected";
      return null;
    };

    /**
     * Creates a PhpChargeNotifyResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpChargeNotifyResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpChargeNotifyResp} PhpChargeNotifyResp
     */
    PhpChargeNotifyResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpChargeNotifyResp) return object;
      var message = new $root.client_proto.PhpChargeNotifyResp();
      if (object.code != null) message.code = object.code | 0;
      if (object.order_num != null) message.order_num = String(object.order_num);
      return message;
    };

    /**
     * Creates a plain object from a PhpChargeNotifyResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpChargeNotifyResp
     * @static
     * @param {client_proto.PhpChargeNotifyResp} message PhpChargeNotifyResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpChargeNotifyResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.code = 0;
        object.order_num = "";
      }
      if (message.code != null && message.hasOwnProperty("code")) object.code = message.code;
      if (message.order_num != null && message.hasOwnProperty("order_num")) object.order_num = message.order_num;
      return object;
    };

    /**
     * Converts this PhpChargeNotifyResp to JSON.
     * @function toJSON
     * @memberof client_proto.PhpChargeNotifyResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpChargeNotifyResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpChargeNotifyResp
     * @function getTypeUrl
     * @memberof client_proto.PhpChargeNotifyResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpChargeNotifyResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpChargeNotifyResp";
    };

    return PhpChargeNotifyResp;
  })();

  client_proto.PhpWithdrawOrderReq = (function () {
    /**
     * Properties of a PhpWithdrawOrderReq.
     * @memberof client_proto
     * @interface IPhpWithdrawOrderReq
     * @property {number|null} [uid] PhpWithdrawOrderReq uid
     * @property {number|null} [country] PhpWithdrawOrderReq country
     * @property {number|Long|null} [amount] PhpWithdrawOrderReq amount
     * @property {number|Long|null} [fee] PhpWithdrawOrderReq fee
     * @property {number|Long|null} [real_amount] PhpWithdrawOrderReq real_amount
     * @property {string|null} [bank_name] PhpWithdrawOrderReq bank_name
     * @property {string|null} [account] PhpWithdrawOrderReq account
     * @property {string|null} [mobile] PhpWithdrawOrderReq mobile
     * @property {string|null} [bank_code] PhpWithdrawOrderReq bank_code
     * @property {number|null} [order_id] PhpWithdrawOrderReq order_id
     * @property {string|null} [nick_name] PhpWithdrawOrderReq nick_name
     * @property {string|null} [channel] PhpWithdrawOrderReq channel
     * @property {number|null} [first_withdraw] PhpWithdrawOrderReq first_withdraw
     * @property {string|null} [ip] PhpWithdrawOrderReq ip
     */

    /**
     * Constructs a new PhpWithdrawOrderReq.
     * @memberof client_proto
     * @classdesc Represents a PhpWithdrawOrderReq.
     * @implements IPhpWithdrawOrderReq
     * @constructor
     * @param {client_proto.IPhpWithdrawOrderReq=} [properties] Properties to set
     */
    function PhpWithdrawOrderReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpWithdrawOrderReq uid.
     * @member {number} uid
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.uid = 0;

    /**
     * PhpWithdrawOrderReq country.
     * @member {number} country
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.country = 0;

    /**
     * PhpWithdrawOrderReq amount.
     * @member {number|Long} amount
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PhpWithdrawOrderReq fee.
     * @member {number|Long} fee
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.fee = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PhpWithdrawOrderReq real_amount.
     * @member {number|Long} real_amount
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.real_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PhpWithdrawOrderReq bank_name.
     * @member {string} bank_name
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.bank_name = "";

    /**
     * PhpWithdrawOrderReq account.
     * @member {string} account
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.account = "";

    /**
     * PhpWithdrawOrderReq mobile.
     * @member {string} mobile
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.mobile = "";

    /**
     * PhpWithdrawOrderReq bank_code.
     * @member {string} bank_code
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.bank_code = "";

    /**
     * PhpWithdrawOrderReq order_id.
     * @member {number} order_id
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.order_id = 0;

    /**
     * PhpWithdrawOrderReq nick_name.
     * @member {string} nick_name
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.nick_name = "";

    /**
     * PhpWithdrawOrderReq channel.
     * @member {string} channel
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.channel = "";

    /**
     * PhpWithdrawOrderReq first_withdraw.
     * @member {number} first_withdraw
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.first_withdraw = 0;

    /**
     * PhpWithdrawOrderReq ip.
     * @member {string} ip
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     */
    PhpWithdrawOrderReq.prototype.ip = "";

    /**
     * Creates a new PhpWithdrawOrderReq instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpWithdrawOrderReq
     * @static
     * @param {client_proto.IPhpWithdrawOrderReq=} [properties] Properties to set
     * @returns {client_proto.PhpWithdrawOrderReq} PhpWithdrawOrderReq instance
     */
    PhpWithdrawOrderReq.create = function create(properties) {
      return new PhpWithdrawOrderReq(properties);
    };

    /**
     * Encodes the specified PhpWithdrawOrderReq message. Does not implicitly {@link client_proto.PhpWithdrawOrderReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpWithdrawOrderReq
     * @static
     * @param {client_proto.IPhpWithdrawOrderReq} message PhpWithdrawOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpWithdrawOrderReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.country != null && Object.hasOwnProperty.call(message, "country"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.country);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.amount);
      if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.fee);
      if (message.real_amount != null && Object.hasOwnProperty.call(message, "real_amount"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.real_amount);
      if (message.bank_name != null && Object.hasOwnProperty.call(message, "bank_name"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.bank_name);
      if (message.account != null && Object.hasOwnProperty.call(message, "account"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.account);
      if (message.mobile != null && Object.hasOwnProperty.call(message, "mobile"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.mobile);
      if (message.bank_code != null && Object.hasOwnProperty.call(message, "bank_code"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.bank_code);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int32(message.order_id);
      if (message.nick_name != null && Object.hasOwnProperty.call(message, "nick_name"))
        writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.nick_name);
      if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
        writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.channel);
      if (message.first_withdraw != null && Object.hasOwnProperty.call(message, "first_withdraw"))
        writer.uint32(/* id 13, wireType 0 =*/ 104).int32(message.first_withdraw);
      if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
        writer.uint32(/* id 14, wireType 2 =*/ 114).string(message.ip);
      return writer;
    };

    /**
     * Encodes the specified PhpWithdrawOrderReq message, length delimited. Does not implicitly {@link client_proto.PhpWithdrawOrderReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpWithdrawOrderReq
     * @static
     * @param {client_proto.IPhpWithdrawOrderReq} message PhpWithdrawOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpWithdrawOrderReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpWithdrawOrderReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpWithdrawOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpWithdrawOrderReq} PhpWithdrawOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpWithdrawOrderReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpWithdrawOrderReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.country = reader.int32();
            break;
          }
          case 3: {
            message.amount = reader.int64();
            break;
          }
          case 4: {
            message.fee = reader.int64();
            break;
          }
          case 5: {
            message.real_amount = reader.int64();
            break;
          }
          case 6: {
            message.bank_name = reader.string();
            break;
          }
          case 7: {
            message.account = reader.string();
            break;
          }
          case 8: {
            message.mobile = reader.string();
            break;
          }
          case 9: {
            message.bank_code = reader.string();
            break;
          }
          case 10: {
            message.order_id = reader.int32();
            break;
          }
          case 11: {
            message.nick_name = reader.string();
            break;
          }
          case 12: {
            message.channel = reader.string();
            break;
          }
          case 13: {
            message.first_withdraw = reader.int32();
            break;
          }
          case 14: {
            message.ip = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpWithdrawOrderReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpWithdrawOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpWithdrawOrderReq} PhpWithdrawOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpWithdrawOrderReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpWithdrawOrderReq message.
     * @function verify
     * @memberof client_proto.PhpWithdrawOrderReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpWithdrawOrderReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.country != null && message.hasOwnProperty("country"))
        if (!$util.isInteger(message.country)) return "country: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.fee != null && message.hasOwnProperty("fee"))
        if (
          !$util.isInteger(message.fee) &&
          !(message.fee && $util.isInteger(message.fee.low) && $util.isInteger(message.fee.high))
        )
          return "fee: integer|Long expected";
      if (message.real_amount != null && message.hasOwnProperty("real_amount"))
        if (
          !$util.isInteger(message.real_amount) &&
          !(
            message.real_amount &&
            $util.isInteger(message.real_amount.low) &&
            $util.isInteger(message.real_amount.high)
          )
        )
          return "real_amount: integer|Long expected";
      if (message.bank_name != null && message.hasOwnProperty("bank_name"))
        if (!$util.isString(message.bank_name)) return "bank_name: string expected";
      if (message.account != null && message.hasOwnProperty("account"))
        if (!$util.isString(message.account)) return "account: string expected";
      if (message.mobile != null && message.hasOwnProperty("mobile"))
        if (!$util.isString(message.mobile)) return "mobile: string expected";
      if (message.bank_code != null && message.hasOwnProperty("bank_code"))
        if (!$util.isString(message.bank_code)) return "bank_code: string expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.nick_name != null && message.hasOwnProperty("nick_name"))
        if (!$util.isString(message.nick_name)) return "nick_name: string expected";
      if (message.channel != null && message.hasOwnProperty("channel"))
        if (!$util.isString(message.channel)) return "channel: string expected";
      if (message.first_withdraw != null && message.hasOwnProperty("first_withdraw"))
        if (!$util.isInteger(message.first_withdraw)) return "first_withdraw: integer expected";
      if (message.ip != null && message.hasOwnProperty("ip"))
        if (!$util.isString(message.ip)) return "ip: string expected";
      return null;
    };

    /**
     * Creates a PhpWithdrawOrderReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpWithdrawOrderReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpWithdrawOrderReq} PhpWithdrawOrderReq
     */
    PhpWithdrawOrderReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpWithdrawOrderReq) return object;
      var message = new $root.client_proto.PhpWithdrawOrderReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.country != null) message.country = object.country | 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.fee != null)
        if ($util.Long) (message.fee = $util.Long.fromValue(object.fee)).unsigned = false;
        else if (typeof object.fee === "string") message.fee = parseInt(object.fee, 10);
        else if (typeof object.fee === "number") message.fee = object.fee;
        else if (typeof object.fee === "object")
          message.fee = new $util.LongBits(object.fee.low >>> 0, object.fee.high >>> 0).toNumber();
      if (object.real_amount != null)
        if ($util.Long) (message.real_amount = $util.Long.fromValue(object.real_amount)).unsigned = false;
        else if (typeof object.real_amount === "string") message.real_amount = parseInt(object.real_amount, 10);
        else if (typeof object.real_amount === "number") message.real_amount = object.real_amount;
        else if (typeof object.real_amount === "object")
          message.real_amount = new $util.LongBits(
            object.real_amount.low >>> 0,
            object.real_amount.high >>> 0,
          ).toNumber();
      if (object.bank_name != null) message.bank_name = String(object.bank_name);
      if (object.account != null) message.account = String(object.account);
      if (object.mobile != null) message.mobile = String(object.mobile);
      if (object.bank_code != null) message.bank_code = String(object.bank_code);
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.nick_name != null) message.nick_name = String(object.nick_name);
      if (object.channel != null) message.channel = String(object.channel);
      if (object.first_withdraw != null) message.first_withdraw = object.first_withdraw | 0;
      if (object.ip != null) message.ip = String(object.ip);
      return message;
    };

    /**
     * Creates a plain object from a PhpWithdrawOrderReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpWithdrawOrderReq
     * @static
     * @param {client_proto.PhpWithdrawOrderReq} message PhpWithdrawOrderReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpWithdrawOrderReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.country = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.fee = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.fee = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.real_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.real_amount = options.longs === String ? "0" : 0;
        object.bank_name = "";
        object.account = "";
        object.mobile = "";
        object.bank_code = "";
        object.order_id = 0;
        object.nick_name = "";
        object.channel = "";
        object.first_withdraw = 0;
        object.ip = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.country != null && message.hasOwnProperty("country")) object.country = message.country;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.fee != null && message.hasOwnProperty("fee"))
        if (typeof message.fee === "number") object.fee = options.longs === String ? String(message.fee) : message.fee;
        else
          object.fee =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.fee)
              : options.longs === Number
                ? new $util.LongBits(message.fee.low >>> 0, message.fee.high >>> 0).toNumber()
                : message.fee;
      if (message.real_amount != null && message.hasOwnProperty("real_amount"))
        if (typeof message.real_amount === "number")
          object.real_amount = options.longs === String ? String(message.real_amount) : message.real_amount;
        else
          object.real_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.real_amount)
              : options.longs === Number
                ? new $util.LongBits(message.real_amount.low >>> 0, message.real_amount.high >>> 0).toNumber()
                : message.real_amount;
      if (message.bank_name != null && message.hasOwnProperty("bank_name")) object.bank_name = message.bank_name;
      if (message.account != null && message.hasOwnProperty("account")) object.account = message.account;
      if (message.mobile != null && message.hasOwnProperty("mobile")) object.mobile = message.mobile;
      if (message.bank_code != null && message.hasOwnProperty("bank_code")) object.bank_code = message.bank_code;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.nick_name != null && message.hasOwnProperty("nick_name")) object.nick_name = message.nick_name;
      if (message.channel != null && message.hasOwnProperty("channel")) object.channel = message.channel;
      if (message.first_withdraw != null && message.hasOwnProperty("first_withdraw"))
        object.first_withdraw = message.first_withdraw;
      if (message.ip != null && message.hasOwnProperty("ip")) object.ip = message.ip;
      return object;
    };

    /**
     * Converts this PhpWithdrawOrderReq to JSON.
     * @function toJSON
     * @memberof client_proto.PhpWithdrawOrderReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpWithdrawOrderReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpWithdrawOrderReq
     * @function getTypeUrl
     * @memberof client_proto.PhpWithdrawOrderReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpWithdrawOrderReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpWithdrawOrderReq";
    };

    return PhpWithdrawOrderReq;
  })();

  client_proto.PhpWithdrawOrderResp = (function () {
    /**
     * Properties of a PhpWithdrawOrderResp.
     * @memberof client_proto
     * @interface IPhpWithdrawOrderResp
     * @property {number|null} [uid] PhpWithdrawOrderResp uid
     * @property {number|null} [code] PhpWithdrawOrderResp code
     * @property {number|null} [order_id] PhpWithdrawOrderResp order_id
     * @property {string|null} [order_num] PhpWithdrawOrderResp order_num
     */

    /**
     * Constructs a new PhpWithdrawOrderResp.
     * @memberof client_proto
     * @classdesc Represents a PhpWithdrawOrderResp.
     * @implements IPhpWithdrawOrderResp
     * @constructor
     * @param {client_proto.IPhpWithdrawOrderResp=} [properties] Properties to set
     */
    function PhpWithdrawOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpWithdrawOrderResp uid.
     * @member {number} uid
     * @memberof client_proto.PhpWithdrawOrderResp
     * @instance
     */
    PhpWithdrawOrderResp.prototype.uid = 0;

    /**
     * PhpWithdrawOrderResp code.
     * @member {number} code
     * @memberof client_proto.PhpWithdrawOrderResp
     * @instance
     */
    PhpWithdrawOrderResp.prototype.code = 0;

    /**
     * PhpWithdrawOrderResp order_id.
     * @member {number} order_id
     * @memberof client_proto.PhpWithdrawOrderResp
     * @instance
     */
    PhpWithdrawOrderResp.prototype.order_id = 0;

    /**
     * PhpWithdrawOrderResp order_num.
     * @member {string} order_num
     * @memberof client_proto.PhpWithdrawOrderResp
     * @instance
     */
    PhpWithdrawOrderResp.prototype.order_num = "";

    /**
     * Creates a new PhpWithdrawOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpWithdrawOrderResp
     * @static
     * @param {client_proto.IPhpWithdrawOrderResp=} [properties] Properties to set
     * @returns {client_proto.PhpWithdrawOrderResp} PhpWithdrawOrderResp instance
     */
    PhpWithdrawOrderResp.create = function create(properties) {
      return new PhpWithdrawOrderResp(properties);
    };

    /**
     * Encodes the specified PhpWithdrawOrderResp message. Does not implicitly {@link client_proto.PhpWithdrawOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpWithdrawOrderResp
     * @static
     * @param {client_proto.IPhpWithdrawOrderResp} message PhpWithdrawOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpWithdrawOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.uid);
      if (message.code != null && Object.hasOwnProperty.call(message, "code"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.code);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.order_id);
      if (message.order_num != null && Object.hasOwnProperty.call(message, "order_num"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.order_num);
      return writer;
    };

    /**
     * Encodes the specified PhpWithdrawOrderResp message, length delimited. Does not implicitly {@link client_proto.PhpWithdrawOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpWithdrawOrderResp
     * @static
     * @param {client_proto.IPhpWithdrawOrderResp} message PhpWithdrawOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpWithdrawOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpWithdrawOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpWithdrawOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpWithdrawOrderResp} PhpWithdrawOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpWithdrawOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpWithdrawOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.int32();
            break;
          }
          case 2: {
            message.code = reader.int32();
            break;
          }
          case 3: {
            message.order_id = reader.int32();
            break;
          }
          case 4: {
            message.order_num = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpWithdrawOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpWithdrawOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpWithdrawOrderResp} PhpWithdrawOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpWithdrawOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpWithdrawOrderResp message.
     * @function verify
     * @memberof client_proto.PhpWithdrawOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpWithdrawOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.code != null && message.hasOwnProperty("code"))
        if (!$util.isInteger(message.code)) return "code: integer expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.order_num != null && message.hasOwnProperty("order_num"))
        if (!$util.isString(message.order_num)) return "order_num: string expected";
      return null;
    };

    /**
     * Creates a PhpWithdrawOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpWithdrawOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpWithdrawOrderResp} PhpWithdrawOrderResp
     */
    PhpWithdrawOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpWithdrawOrderResp) return object;
      var message = new $root.client_proto.PhpWithdrawOrderResp();
      if (object.uid != null) message.uid = object.uid | 0;
      if (object.code != null) message.code = object.code | 0;
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.order_num != null) message.order_num = String(object.order_num);
      return message;
    };

    /**
     * Creates a plain object from a PhpWithdrawOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpWithdrawOrderResp
     * @static
     * @param {client_proto.PhpWithdrawOrderResp} message PhpWithdrawOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpWithdrawOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.code = 0;
        object.order_id = 0;
        object.order_num = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.code != null && message.hasOwnProperty("code")) object.code = message.code;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.order_num != null && message.hasOwnProperty("order_num")) object.order_num = message.order_num;
      return object;
    };

    /**
     * Converts this PhpWithdrawOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.PhpWithdrawOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpWithdrawOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpWithdrawOrderResp
     * @function getTypeUrl
     * @memberof client_proto.PhpWithdrawOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpWithdrawOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpWithdrawOrderResp";
    };

    return PhpWithdrawOrderResp;
  })();

  client_proto.PhpWithdrawNotifyReq = (function () {
    /**
     * Properties of a PhpWithdrawNotifyReq.
     * @memberof client_proto
     * @interface IPhpWithdrawNotifyReq
     * @property {number|null} [uid] PhpWithdrawNotifyReq uid
     * @property {number|null} [status] PhpWithdrawNotifyReq status
     * @property {number|null} [order_id] PhpWithdrawNotifyReq order_id
     * @property {string|null} [reason] PhpWithdrawNotifyReq reason
     */

    /**
     * Constructs a new PhpWithdrawNotifyReq.
     * @memberof client_proto
     * @classdesc Represents a PhpWithdrawNotifyReq.
     * @implements IPhpWithdrawNotifyReq
     * @constructor
     * @param {client_proto.IPhpWithdrawNotifyReq=} [properties] Properties to set
     */
    function PhpWithdrawNotifyReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpWithdrawNotifyReq uid.
     * @member {number} uid
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @instance
     */
    PhpWithdrawNotifyReq.prototype.uid = 0;

    /**
     * PhpWithdrawNotifyReq status.
     * @member {number} status
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @instance
     */
    PhpWithdrawNotifyReq.prototype.status = 0;

    /**
     * PhpWithdrawNotifyReq order_id.
     * @member {number} order_id
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @instance
     */
    PhpWithdrawNotifyReq.prototype.order_id = 0;

    /**
     * PhpWithdrawNotifyReq reason.
     * @member {string} reason
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @instance
     */
    PhpWithdrawNotifyReq.prototype.reason = "";

    /**
     * Creates a new PhpWithdrawNotifyReq instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @static
     * @param {client_proto.IPhpWithdrawNotifyReq=} [properties] Properties to set
     * @returns {client_proto.PhpWithdrawNotifyReq} PhpWithdrawNotifyReq instance
     */
    PhpWithdrawNotifyReq.create = function create(properties) {
      return new PhpWithdrawNotifyReq(properties);
    };

    /**
     * Encodes the specified PhpWithdrawNotifyReq message. Does not implicitly {@link client_proto.PhpWithdrawNotifyReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @static
     * @param {client_proto.IPhpWithdrawNotifyReq} message PhpWithdrawNotifyReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpWithdrawNotifyReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.status);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.order_id);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.reason);
      return writer;
    };

    /**
     * Encodes the specified PhpWithdrawNotifyReq message, length delimited. Does not implicitly {@link client_proto.PhpWithdrawNotifyReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @static
     * @param {client_proto.IPhpWithdrawNotifyReq} message PhpWithdrawNotifyReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpWithdrawNotifyReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpWithdrawNotifyReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpWithdrawNotifyReq} PhpWithdrawNotifyReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpWithdrawNotifyReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpWithdrawNotifyReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.status = reader.int32();
            break;
          }
          case 3: {
            message.order_id = reader.int32();
            break;
          }
          case 4: {
            message.reason = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpWithdrawNotifyReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpWithdrawNotifyReq} PhpWithdrawNotifyReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpWithdrawNotifyReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpWithdrawNotifyReq message.
     * @function verify
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpWithdrawNotifyReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.reason != null && message.hasOwnProperty("reason"))
        if (!$util.isString(message.reason)) return "reason: string expected";
      return null;
    };

    /**
     * Creates a PhpWithdrawNotifyReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpWithdrawNotifyReq} PhpWithdrawNotifyReq
     */
    PhpWithdrawNotifyReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpWithdrawNotifyReq) return object;
      var message = new $root.client_proto.PhpWithdrawNotifyReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.status != null) message.status = object.status | 0;
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.reason != null) message.reason = String(object.reason);
      return message;
    };

    /**
     * Creates a plain object from a PhpWithdrawNotifyReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @static
     * @param {client_proto.PhpWithdrawNotifyReq} message PhpWithdrawNotifyReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpWithdrawNotifyReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.status = 0;
        object.order_id = 0;
        object.reason = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.reason != null && message.hasOwnProperty("reason")) object.reason = message.reason;
      return object;
    };

    /**
     * Converts this PhpWithdrawNotifyReq to JSON.
     * @function toJSON
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpWithdrawNotifyReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpWithdrawNotifyReq
     * @function getTypeUrl
     * @memberof client_proto.PhpWithdrawNotifyReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpWithdrawNotifyReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpWithdrawNotifyReq";
    };

    return PhpWithdrawNotifyReq;
  })();

  client_proto.PhpWithdrawNotifyResp = (function () {
    /**
     * Properties of a PhpWithdrawNotifyResp.
     * @memberof client_proto
     * @interface IPhpWithdrawNotifyResp
     * @property {number|null} [code] PhpWithdrawNotifyResp code
     */

    /**
     * Constructs a new PhpWithdrawNotifyResp.
     * @memberof client_proto
     * @classdesc Represents a PhpWithdrawNotifyResp.
     * @implements IPhpWithdrawNotifyResp
     * @constructor
     * @param {client_proto.IPhpWithdrawNotifyResp=} [properties] Properties to set
     */
    function PhpWithdrawNotifyResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpWithdrawNotifyResp code.
     * @member {number} code
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @instance
     */
    PhpWithdrawNotifyResp.prototype.code = 0;

    /**
     * Creates a new PhpWithdrawNotifyResp instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @static
     * @param {client_proto.IPhpWithdrawNotifyResp=} [properties] Properties to set
     * @returns {client_proto.PhpWithdrawNotifyResp} PhpWithdrawNotifyResp instance
     */
    PhpWithdrawNotifyResp.create = function create(properties) {
      return new PhpWithdrawNotifyResp(properties);
    };

    /**
     * Encodes the specified PhpWithdrawNotifyResp message. Does not implicitly {@link client_proto.PhpWithdrawNotifyResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @static
     * @param {client_proto.IPhpWithdrawNotifyResp} message PhpWithdrawNotifyResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpWithdrawNotifyResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.code != null && Object.hasOwnProperty.call(message, "code"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.code);
      return writer;
    };

    /**
     * Encodes the specified PhpWithdrawNotifyResp message, length delimited. Does not implicitly {@link client_proto.PhpWithdrawNotifyResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @static
     * @param {client_proto.IPhpWithdrawNotifyResp} message PhpWithdrawNotifyResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpWithdrawNotifyResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpWithdrawNotifyResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpWithdrawNotifyResp} PhpWithdrawNotifyResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpWithdrawNotifyResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpWithdrawNotifyResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.code = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpWithdrawNotifyResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpWithdrawNotifyResp} PhpWithdrawNotifyResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpWithdrawNotifyResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpWithdrawNotifyResp message.
     * @function verify
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpWithdrawNotifyResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.code != null && message.hasOwnProperty("code"))
        if (!$util.isInteger(message.code)) return "code: integer expected";
      return null;
    };

    /**
     * Creates a PhpWithdrawNotifyResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpWithdrawNotifyResp} PhpWithdrawNotifyResp
     */
    PhpWithdrawNotifyResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpWithdrawNotifyResp) return object;
      var message = new $root.client_proto.PhpWithdrawNotifyResp();
      if (object.code != null) message.code = object.code | 0;
      return message;
    };

    /**
     * Creates a plain object from a PhpWithdrawNotifyResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @static
     * @param {client_proto.PhpWithdrawNotifyResp} message PhpWithdrawNotifyResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpWithdrawNotifyResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.code = 0;
      if (message.code != null && message.hasOwnProperty("code")) object.code = message.code;
      return object;
    };

    /**
     * Converts this PhpWithdrawNotifyResp to JSON.
     * @function toJSON
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpWithdrawNotifyResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpWithdrawNotifyResp
     * @function getTypeUrl
     * @memberof client_proto.PhpWithdrawNotifyResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpWithdrawNotifyResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpWithdrawNotifyResp";
    };

    return PhpWithdrawNotifyResp;
  })();

  client_proto.SrvCreateChargeOrderReq = (function () {
    /**
     * Properties of a SrvCreateChargeOrderReq.
     * @memberof client_proto
     * @interface ISrvCreateChargeOrderReq
     * @property {number|null} [uid] SrvCreateChargeOrderReq uid
     * @property {number|null} [charge_id] SrvCreateChargeOrderReq charge_id
     * @property {number|Long|null} [amount] SrvCreateChargeOrderReq amount
     * @property {number|Long|null} [extra] SrvCreateChargeOrderReq extra
     * @property {number|Long|null} [flow] SrvCreateChargeOrderReq flow
     * @property {Uint8Array|null} [trans] SrvCreateChargeOrderReq trans
     * @property {number|Long|null} [balance] SrvCreateChargeOrderReq balance
     */

    /**
     * Constructs a new SrvCreateChargeOrderReq.
     * @memberof client_proto
     * @classdesc Represents a SrvCreateChargeOrderReq.
     * @implements ISrvCreateChargeOrderReq
     * @constructor
     * @param {client_proto.ISrvCreateChargeOrderReq=} [properties] Properties to set
     */
    function SrvCreateChargeOrderReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SrvCreateChargeOrderReq uid.
     * @member {number} uid
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @instance
     */
    SrvCreateChargeOrderReq.prototype.uid = 0;

    /**
     * SrvCreateChargeOrderReq charge_id.
     * @member {number} charge_id
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @instance
     */
    SrvCreateChargeOrderReq.prototype.charge_id = 0;

    /**
     * SrvCreateChargeOrderReq amount.
     * @member {number|Long} amount
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @instance
     */
    SrvCreateChargeOrderReq.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SrvCreateChargeOrderReq extra.
     * @member {number|Long} extra
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @instance
     */
    SrvCreateChargeOrderReq.prototype.extra = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SrvCreateChargeOrderReq flow.
     * @member {number|Long} flow
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @instance
     */
    SrvCreateChargeOrderReq.prototype.flow = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SrvCreateChargeOrderReq trans.
     * @member {Uint8Array} trans
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @instance
     */
    SrvCreateChargeOrderReq.prototype.trans = $util.newBuffer([]);

    /**
     * SrvCreateChargeOrderReq balance.
     * @member {number|Long} balance
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @instance
     */
    SrvCreateChargeOrderReq.prototype.balance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new SrvCreateChargeOrderReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @static
     * @param {client_proto.ISrvCreateChargeOrderReq=} [properties] Properties to set
     * @returns {client_proto.SrvCreateChargeOrderReq} SrvCreateChargeOrderReq instance
     */
    SrvCreateChargeOrderReq.create = function create(properties) {
      return new SrvCreateChargeOrderReq(properties);
    };

    /**
     * Encodes the specified SrvCreateChargeOrderReq message. Does not implicitly {@link client_proto.SrvCreateChargeOrderReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @static
     * @param {client_proto.ISrvCreateChargeOrderReq} message SrvCreateChargeOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvCreateChargeOrderReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.charge_id != null && Object.hasOwnProperty.call(message, "charge_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.charge_id);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.amount);
      if (message.extra != null && Object.hasOwnProperty.call(message, "extra"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.extra);
      if (message.flow != null && Object.hasOwnProperty.call(message, "flow"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.flow);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).bytes(message.trans);
      if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.balance);
      return writer;
    };

    /**
     * Encodes the specified SrvCreateChargeOrderReq message, length delimited. Does not implicitly {@link client_proto.SrvCreateChargeOrderReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @static
     * @param {client_proto.ISrvCreateChargeOrderReq} message SrvCreateChargeOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvCreateChargeOrderReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SrvCreateChargeOrderReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SrvCreateChargeOrderReq} SrvCreateChargeOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvCreateChargeOrderReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SrvCreateChargeOrderReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.charge_id = reader.int32();
            break;
          }
          case 3: {
            message.amount = reader.int64();
            break;
          }
          case 4: {
            message.extra = reader.int64();
            break;
          }
          case 5: {
            message.flow = reader.int64();
            break;
          }
          case 6: {
            message.trans = reader.bytes();
            break;
          }
          case 7: {
            message.balance = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SrvCreateChargeOrderReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SrvCreateChargeOrderReq} SrvCreateChargeOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvCreateChargeOrderReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SrvCreateChargeOrderReq message.
     * @function verify
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SrvCreateChargeOrderReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.charge_id != null && message.hasOwnProperty("charge_id"))
        if (!$util.isInteger(message.charge_id)) return "charge_id: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.extra != null && message.hasOwnProperty("extra"))
        if (
          !$util.isInteger(message.extra) &&
          !(message.extra && $util.isInteger(message.extra.low) && $util.isInteger(message.extra.high))
        )
          return "extra: integer|Long expected";
      if (message.flow != null && message.hasOwnProperty("flow"))
        if (
          !$util.isInteger(message.flow) &&
          !(message.flow && $util.isInteger(message.flow.low) && $util.isInteger(message.flow.high))
        )
          return "flow: integer|Long expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (
          !$util.isInteger(message.balance) &&
          !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high))
        )
          return "balance: integer|Long expected";
      return null;
    };

    /**
     * Creates a SrvCreateChargeOrderReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SrvCreateChargeOrderReq} SrvCreateChargeOrderReq
     */
    SrvCreateChargeOrderReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SrvCreateChargeOrderReq) return object;
      var message = new $root.client_proto.SrvCreateChargeOrderReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.charge_id != null) message.charge_id = object.charge_id | 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.extra != null)
        if ($util.Long) (message.extra = $util.Long.fromValue(object.extra)).unsigned = false;
        else if (typeof object.extra === "string") message.extra = parseInt(object.extra, 10);
        else if (typeof object.extra === "number") message.extra = object.extra;
        else if (typeof object.extra === "object")
          message.extra = new $util.LongBits(object.extra.low >>> 0, object.extra.high >>> 0).toNumber();
      if (object.flow != null)
        if ($util.Long) (message.flow = $util.Long.fromValue(object.flow)).unsigned = false;
        else if (typeof object.flow === "string") message.flow = parseInt(object.flow, 10);
        else if (typeof object.flow === "number") message.flow = object.flow;
        else if (typeof object.flow === "object")
          message.flow = new $util.LongBits(object.flow.low >>> 0, object.flow.high >>> 0).toNumber();
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      if (object.balance != null)
        if ($util.Long) (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
        else if (typeof object.balance === "string") message.balance = parseInt(object.balance, 10);
        else if (typeof object.balance === "number") message.balance = object.balance;
        else if (typeof object.balance === "object")
          message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a SrvCreateChargeOrderReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @static
     * @param {client_proto.SrvCreateChargeOrderReq} message SrvCreateChargeOrderReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SrvCreateChargeOrderReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.charge_id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.extra = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.extra = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.flow = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.flow = options.longs === String ? "0" : 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.balance =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.balance = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.charge_id != null && message.hasOwnProperty("charge_id")) object.charge_id = message.charge_id;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.extra != null && message.hasOwnProperty("extra"))
        if (typeof message.extra === "number")
          object.extra = options.longs === String ? String(message.extra) : message.extra;
        else
          object.extra =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.extra)
              : options.longs === Number
                ? new $util.LongBits(message.extra.low >>> 0, message.extra.high >>> 0).toNumber()
                : message.extra;
      if (message.flow != null && message.hasOwnProperty("flow"))
        if (typeof message.flow === "number")
          object.flow = options.longs === String ? String(message.flow) : message.flow;
        else
          object.flow =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.flow)
              : options.longs === Number
                ? new $util.LongBits(message.flow.low >>> 0, message.flow.high >>> 0).toNumber()
                : message.flow;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (typeof message.balance === "number")
          object.balance = options.longs === String ? String(message.balance) : message.balance;
        else
          object.balance =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.balance)
              : options.longs === Number
                ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber()
                : message.balance;
      return object;
    };

    /**
     * Converts this SrvCreateChargeOrderReq to JSON.
     * @function toJSON
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SrvCreateChargeOrderReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SrvCreateChargeOrderReq
     * @function getTypeUrl
     * @memberof client_proto.SrvCreateChargeOrderReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SrvCreateChargeOrderReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SrvCreateChargeOrderReq";
    };

    return SrvCreateChargeOrderReq;
  })();

  client_proto.SrvCreateChargeOrderResp = (function () {
    /**
     * Properties of a SrvCreateChargeOrderResp.
     * @memberof client_proto
     * @interface ISrvCreateChargeOrderResp
     * @property {number|null} [ret] SrvCreateChargeOrderResp ret
     * @property {string|null} [order_info] SrvCreateChargeOrderResp order_info
     * @property {number|null} [uid] SrvCreateChargeOrderResp uid
     * @property {number|null} [charge_id] SrvCreateChargeOrderResp charge_id
     * @property {Uint8Array|null} [trans] SrvCreateChargeOrderResp trans
     */

    /**
     * Constructs a new SrvCreateChargeOrderResp.
     * @memberof client_proto
     * @classdesc Represents a SrvCreateChargeOrderResp.
     * @implements ISrvCreateChargeOrderResp
     * @constructor
     * @param {client_proto.ISrvCreateChargeOrderResp=} [properties] Properties to set
     */
    function SrvCreateChargeOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SrvCreateChargeOrderResp ret.
     * @member {number} ret
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @instance
     */
    SrvCreateChargeOrderResp.prototype.ret = 0;

    /**
     * SrvCreateChargeOrderResp order_info.
     * @member {string} order_info
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @instance
     */
    SrvCreateChargeOrderResp.prototype.order_info = "";

    /**
     * SrvCreateChargeOrderResp uid.
     * @member {number} uid
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @instance
     */
    SrvCreateChargeOrderResp.prototype.uid = 0;

    /**
     * SrvCreateChargeOrderResp charge_id.
     * @member {number} charge_id
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @instance
     */
    SrvCreateChargeOrderResp.prototype.charge_id = 0;

    /**
     * SrvCreateChargeOrderResp trans.
     * @member {Uint8Array} trans
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @instance
     */
    SrvCreateChargeOrderResp.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new SrvCreateChargeOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @static
     * @param {client_proto.ISrvCreateChargeOrderResp=} [properties] Properties to set
     * @returns {client_proto.SrvCreateChargeOrderResp} SrvCreateChargeOrderResp instance
     */
    SrvCreateChargeOrderResp.create = function create(properties) {
      return new SrvCreateChargeOrderResp(properties);
    };

    /**
     * Encodes the specified SrvCreateChargeOrderResp message. Does not implicitly {@link client_proto.SrvCreateChargeOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @static
     * @param {client_proto.ISrvCreateChargeOrderResp} message SrvCreateChargeOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvCreateChargeOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.ret != null && Object.hasOwnProperty.call(message, "ret"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.ret);
      if (message.order_info != null && Object.hasOwnProperty.call(message, "order_info"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.order_info);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.uid);
      if (message.charge_id != null && Object.hasOwnProperty.call(message, "charge_id"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.charge_id);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified SrvCreateChargeOrderResp message, length delimited. Does not implicitly {@link client_proto.SrvCreateChargeOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @static
     * @param {client_proto.ISrvCreateChargeOrderResp} message SrvCreateChargeOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvCreateChargeOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SrvCreateChargeOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SrvCreateChargeOrderResp} SrvCreateChargeOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvCreateChargeOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SrvCreateChargeOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.ret = reader.int32();
            break;
          }
          case 2: {
            message.order_info = reader.string();
            break;
          }
          case 3: {
            message.uid = reader.uint32();
            break;
          }
          case 4: {
            message.charge_id = reader.int32();
            break;
          }
          case 5: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SrvCreateChargeOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SrvCreateChargeOrderResp} SrvCreateChargeOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvCreateChargeOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SrvCreateChargeOrderResp message.
     * @function verify
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SrvCreateChargeOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.ret != null && message.hasOwnProperty("ret"))
        if (!$util.isInteger(message.ret)) return "ret: integer expected";
      if (message.order_info != null && message.hasOwnProperty("order_info"))
        if (!$util.isString(message.order_info)) return "order_info: string expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.charge_id != null && message.hasOwnProperty("charge_id"))
        if (!$util.isInteger(message.charge_id)) return "charge_id: integer expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates a SrvCreateChargeOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SrvCreateChargeOrderResp} SrvCreateChargeOrderResp
     */
    SrvCreateChargeOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SrvCreateChargeOrderResp) return object;
      var message = new $root.client_proto.SrvCreateChargeOrderResp();
      if (object.ret != null) message.ret = object.ret | 0;
      if (object.order_info != null) message.order_info = String(object.order_info);
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.charge_id != null) message.charge_id = object.charge_id | 0;
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from a SrvCreateChargeOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @static
     * @param {client_proto.SrvCreateChargeOrderResp} message SrvCreateChargeOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SrvCreateChargeOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.ret = 0;
        object.order_info = "";
        object.uid = 0;
        object.charge_id = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.ret != null && message.hasOwnProperty("ret")) object.ret = message.ret;
      if (message.order_info != null && message.hasOwnProperty("order_info")) object.order_info = message.order_info;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.charge_id != null && message.hasOwnProperty("charge_id")) object.charge_id = message.charge_id;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this SrvCreateChargeOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SrvCreateChargeOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SrvCreateChargeOrderResp
     * @function getTypeUrl
     * @memberof client_proto.SrvCreateChargeOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SrvCreateChargeOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SrvCreateChargeOrderResp";
    };

    return SrvCreateChargeOrderResp;
  })();

  client_proto.SrvCompleteChargeOrderResp = (function () {
    /**
     * Properties of a SrvCompleteChargeOrderResp.
     * @memberof client_proto
     * @interface ISrvCompleteChargeOrderResp
     * @property {number|null} [ret] SrvCompleteChargeOrderResp ret
     * @property {number|null} [uid] SrvCompleteChargeOrderResp uid
     * @property {number|null} [charge_id] SrvCompleteChargeOrderResp charge_id
     * @property {string|null} [trans] SrvCompleteChargeOrderResp trans
     * @property {number|null} [order_id] SrvCompleteChargeOrderResp order_id
     */

    /**
     * Constructs a new SrvCompleteChargeOrderResp.
     * @memberof client_proto
     * @classdesc Represents a SrvCompleteChargeOrderResp.
     * @implements ISrvCompleteChargeOrderResp
     * @constructor
     * @param {client_proto.ISrvCompleteChargeOrderResp=} [properties] Properties to set
     */
    function SrvCompleteChargeOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SrvCompleteChargeOrderResp ret.
     * @member {number} ret
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @instance
     */
    SrvCompleteChargeOrderResp.prototype.ret = 0;

    /**
     * SrvCompleteChargeOrderResp uid.
     * @member {number} uid
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @instance
     */
    SrvCompleteChargeOrderResp.prototype.uid = 0;

    /**
     * SrvCompleteChargeOrderResp charge_id.
     * @member {number} charge_id
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @instance
     */
    SrvCompleteChargeOrderResp.prototype.charge_id = 0;

    /**
     * SrvCompleteChargeOrderResp trans.
     * @member {string} trans
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @instance
     */
    SrvCompleteChargeOrderResp.prototype.trans = "";

    /**
     * SrvCompleteChargeOrderResp order_id.
     * @member {number} order_id
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @instance
     */
    SrvCompleteChargeOrderResp.prototype.order_id = 0;

    /**
     * Creates a new SrvCompleteChargeOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @static
     * @param {client_proto.ISrvCompleteChargeOrderResp=} [properties] Properties to set
     * @returns {client_proto.SrvCompleteChargeOrderResp} SrvCompleteChargeOrderResp instance
     */
    SrvCompleteChargeOrderResp.create = function create(properties) {
      return new SrvCompleteChargeOrderResp(properties);
    };

    /**
     * Encodes the specified SrvCompleteChargeOrderResp message. Does not implicitly {@link client_proto.SrvCompleteChargeOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @static
     * @param {client_proto.ISrvCompleteChargeOrderResp} message SrvCompleteChargeOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvCompleteChargeOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.ret != null && Object.hasOwnProperty.call(message, "ret"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.ret);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.uid);
      if (message.charge_id != null && Object.hasOwnProperty.call(message, "charge_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.charge_id);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.trans);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.order_id);
      return writer;
    };

    /**
     * Encodes the specified SrvCompleteChargeOrderResp message, length delimited. Does not implicitly {@link client_proto.SrvCompleteChargeOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @static
     * @param {client_proto.ISrvCompleteChargeOrderResp} message SrvCompleteChargeOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvCompleteChargeOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SrvCompleteChargeOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SrvCompleteChargeOrderResp} SrvCompleteChargeOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvCompleteChargeOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SrvCompleteChargeOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.ret = reader.int32();
            break;
          }
          case 2: {
            message.uid = reader.uint32();
            break;
          }
          case 3: {
            message.charge_id = reader.int32();
            break;
          }
          case 4: {
            message.trans = reader.string();
            break;
          }
          case 5: {
            message.order_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SrvCompleteChargeOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SrvCompleteChargeOrderResp} SrvCompleteChargeOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvCompleteChargeOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SrvCompleteChargeOrderResp message.
     * @function verify
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SrvCompleteChargeOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.ret != null && message.hasOwnProperty("ret"))
        if (!$util.isInteger(message.ret)) return "ret: integer expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.charge_id != null && message.hasOwnProperty("charge_id"))
        if (!$util.isInteger(message.charge_id)) return "charge_id: integer expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!$util.isString(message.trans)) return "trans: string expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      return null;
    };

    /**
     * Creates a SrvCompleteChargeOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SrvCompleteChargeOrderResp} SrvCompleteChargeOrderResp
     */
    SrvCompleteChargeOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SrvCompleteChargeOrderResp) return object;
      var message = new $root.client_proto.SrvCompleteChargeOrderResp();
      if (object.ret != null) message.ret = object.ret | 0;
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.charge_id != null) message.charge_id = object.charge_id | 0;
      if (object.trans != null) message.trans = String(object.trans);
      if (object.order_id != null) message.order_id = object.order_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a SrvCompleteChargeOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @static
     * @param {client_proto.SrvCompleteChargeOrderResp} message SrvCompleteChargeOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SrvCompleteChargeOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.ret = 0;
        object.uid = 0;
        object.charge_id = 0;
        object.trans = "";
        object.order_id = 0;
      }
      if (message.ret != null && message.hasOwnProperty("ret")) object.ret = message.ret;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.charge_id != null && message.hasOwnProperty("charge_id")) object.charge_id = message.charge_id;
      if (message.trans != null && message.hasOwnProperty("trans")) object.trans = message.trans;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      return object;
    };

    /**
     * Converts this SrvCompleteChargeOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SrvCompleteChargeOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SrvCompleteChargeOrderResp
     * @function getTypeUrl
     * @memberof client_proto.SrvCompleteChargeOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SrvCompleteChargeOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SrvCompleteChargeOrderResp";
    };

    return SrvCompleteChargeOrderResp;
  })();

  client_proto.PhpCreateChargeOrderReq = (function () {
    /**
     * Properties of a PhpCreateChargeOrderReq.
     * @memberof client_proto
     * @interface IPhpCreateChargeOrderReq
     * @property {number|null} [uid] PhpCreateChargeOrderReq uid
     * @property {string|null} [order_num] PhpCreateChargeOrderReq order_num
     * @property {number|null} [old_order_id] PhpCreateChargeOrderReq old_order_id
     */

    /**
     * Constructs a new PhpCreateChargeOrderReq.
     * @memberof client_proto
     * @classdesc Represents a PhpCreateChargeOrderReq.
     * @implements IPhpCreateChargeOrderReq
     * @constructor
     * @param {client_proto.IPhpCreateChargeOrderReq=} [properties] Properties to set
     */
    function PhpCreateChargeOrderReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpCreateChargeOrderReq uid.
     * @member {number} uid
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @instance
     */
    PhpCreateChargeOrderReq.prototype.uid = 0;

    /**
     * PhpCreateChargeOrderReq order_num.
     * @member {string} order_num
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @instance
     */
    PhpCreateChargeOrderReq.prototype.order_num = "";

    /**
     * PhpCreateChargeOrderReq old_order_id.
     * @member {number} old_order_id
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @instance
     */
    PhpCreateChargeOrderReq.prototype.old_order_id = 0;

    /**
     * Creates a new PhpCreateChargeOrderReq instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @static
     * @param {client_proto.IPhpCreateChargeOrderReq=} [properties] Properties to set
     * @returns {client_proto.PhpCreateChargeOrderReq} PhpCreateChargeOrderReq instance
     */
    PhpCreateChargeOrderReq.create = function create(properties) {
      return new PhpCreateChargeOrderReq(properties);
    };

    /**
     * Encodes the specified PhpCreateChargeOrderReq message. Does not implicitly {@link client_proto.PhpCreateChargeOrderReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @static
     * @param {client_proto.IPhpCreateChargeOrderReq} message PhpCreateChargeOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpCreateChargeOrderReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.order_num != null && Object.hasOwnProperty.call(message, "order_num"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.order_num);
      if (message.old_order_id != null && Object.hasOwnProperty.call(message, "old_order_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.old_order_id);
      return writer;
    };

    /**
     * Encodes the specified PhpCreateChargeOrderReq message, length delimited. Does not implicitly {@link client_proto.PhpCreateChargeOrderReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @static
     * @param {client_proto.IPhpCreateChargeOrderReq} message PhpCreateChargeOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpCreateChargeOrderReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpCreateChargeOrderReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpCreateChargeOrderReq} PhpCreateChargeOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpCreateChargeOrderReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpCreateChargeOrderReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.order_num = reader.string();
            break;
          }
          case 3: {
            message.old_order_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpCreateChargeOrderReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpCreateChargeOrderReq} PhpCreateChargeOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpCreateChargeOrderReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpCreateChargeOrderReq message.
     * @function verify
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpCreateChargeOrderReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.order_num != null && message.hasOwnProperty("order_num"))
        if (!$util.isString(message.order_num)) return "order_num: string expected";
      if (message.old_order_id != null && message.hasOwnProperty("old_order_id"))
        if (!$util.isInteger(message.old_order_id)) return "old_order_id: integer expected";
      return null;
    };

    /**
     * Creates a PhpCreateChargeOrderReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpCreateChargeOrderReq} PhpCreateChargeOrderReq
     */
    PhpCreateChargeOrderReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpCreateChargeOrderReq) return object;
      var message = new $root.client_proto.PhpCreateChargeOrderReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.order_num != null) message.order_num = String(object.order_num);
      if (object.old_order_id != null) message.old_order_id = object.old_order_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a PhpCreateChargeOrderReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @static
     * @param {client_proto.PhpCreateChargeOrderReq} message PhpCreateChargeOrderReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpCreateChargeOrderReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.order_num = "";
        object.old_order_id = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.order_num != null && message.hasOwnProperty("order_num")) object.order_num = message.order_num;
      if (message.old_order_id != null && message.hasOwnProperty("old_order_id"))
        object.old_order_id = message.old_order_id;
      return object;
    };

    /**
     * Converts this PhpCreateChargeOrderReq to JSON.
     * @function toJSON
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpCreateChargeOrderReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpCreateChargeOrderReq
     * @function getTypeUrl
     * @memberof client_proto.PhpCreateChargeOrderReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpCreateChargeOrderReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpCreateChargeOrderReq";
    };

    return PhpCreateChargeOrderReq;
  })();

  client_proto.PhpCreateChargeOrderResp = (function () {
    /**
     * Properties of a PhpCreateChargeOrderResp.
     * @memberof client_proto
     * @interface IPhpCreateChargeOrderResp
     * @property {number|null} [ret] PhpCreateChargeOrderResp ret
     * @property {string|null} [order_num] PhpCreateChargeOrderResp order_num
     * @property {number|null} [order_id] PhpCreateChargeOrderResp order_id
     */

    /**
     * Constructs a new PhpCreateChargeOrderResp.
     * @memberof client_proto
     * @classdesc Represents a PhpCreateChargeOrderResp.
     * @implements IPhpCreateChargeOrderResp
     * @constructor
     * @param {client_proto.IPhpCreateChargeOrderResp=} [properties] Properties to set
     */
    function PhpCreateChargeOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpCreateChargeOrderResp ret.
     * @member {number} ret
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @instance
     */
    PhpCreateChargeOrderResp.prototype.ret = 0;

    /**
     * PhpCreateChargeOrderResp order_num.
     * @member {string} order_num
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @instance
     */
    PhpCreateChargeOrderResp.prototype.order_num = "";

    /**
     * PhpCreateChargeOrderResp order_id.
     * @member {number} order_id
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @instance
     */
    PhpCreateChargeOrderResp.prototype.order_id = 0;

    /**
     * Creates a new PhpCreateChargeOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @static
     * @param {client_proto.IPhpCreateChargeOrderResp=} [properties] Properties to set
     * @returns {client_proto.PhpCreateChargeOrderResp} PhpCreateChargeOrderResp instance
     */
    PhpCreateChargeOrderResp.create = function create(properties) {
      return new PhpCreateChargeOrderResp(properties);
    };

    /**
     * Encodes the specified PhpCreateChargeOrderResp message. Does not implicitly {@link client_proto.PhpCreateChargeOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @static
     * @param {client_proto.IPhpCreateChargeOrderResp} message PhpCreateChargeOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpCreateChargeOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.ret != null && Object.hasOwnProperty.call(message, "ret"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.ret);
      if (message.order_num != null && Object.hasOwnProperty.call(message, "order_num"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.order_num);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.order_id);
      return writer;
    };

    /**
     * Encodes the specified PhpCreateChargeOrderResp message, length delimited. Does not implicitly {@link client_proto.PhpCreateChargeOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @static
     * @param {client_proto.IPhpCreateChargeOrderResp} message PhpCreateChargeOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpCreateChargeOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpCreateChargeOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpCreateChargeOrderResp} PhpCreateChargeOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpCreateChargeOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpCreateChargeOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.ret = reader.int32();
            break;
          }
          case 2: {
            message.order_num = reader.string();
            break;
          }
          case 3: {
            message.order_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpCreateChargeOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpCreateChargeOrderResp} PhpCreateChargeOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpCreateChargeOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpCreateChargeOrderResp message.
     * @function verify
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpCreateChargeOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.ret != null && message.hasOwnProperty("ret"))
        if (!$util.isInteger(message.ret)) return "ret: integer expected";
      if (message.order_num != null && message.hasOwnProperty("order_num"))
        if (!$util.isString(message.order_num)) return "order_num: string expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      return null;
    };

    /**
     * Creates a PhpCreateChargeOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpCreateChargeOrderResp} PhpCreateChargeOrderResp
     */
    PhpCreateChargeOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpCreateChargeOrderResp) return object;
      var message = new $root.client_proto.PhpCreateChargeOrderResp();
      if (object.ret != null) message.ret = object.ret | 0;
      if (object.order_num != null) message.order_num = String(object.order_num);
      if (object.order_id != null) message.order_id = object.order_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a PhpCreateChargeOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @static
     * @param {client_proto.PhpCreateChargeOrderResp} message PhpCreateChargeOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpCreateChargeOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.ret = 0;
        object.order_num = "";
        object.order_id = 0;
      }
      if (message.ret != null && message.hasOwnProperty("ret")) object.ret = message.ret;
      if (message.order_num != null && message.hasOwnProperty("order_num")) object.order_num = message.order_num;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      return object;
    };

    /**
     * Converts this PhpCreateChargeOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpCreateChargeOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpCreateChargeOrderResp
     * @function getTypeUrl
     * @memberof client_proto.PhpCreateChargeOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpCreateChargeOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpCreateChargeOrderResp";
    };

    return PhpCreateChargeOrderResp;
  })();

  client_proto.GetBannerListReq = (function () {
    /**
     * Properties of a GetBannerListReq.
     * @memberof client_proto
     * @interface IGetBannerListReq
     */

    /**
     * Constructs a new GetBannerListReq.
     * @memberof client_proto
     * @classdesc Represents a GetBannerListReq.
     * @implements IGetBannerListReq
     * @constructor
     * @param {client_proto.IGetBannerListReq=} [properties] Properties to set
     */
    function GetBannerListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetBannerListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetBannerListReq
     * @static
     * @param {client_proto.IGetBannerListReq=} [properties] Properties to set
     * @returns {client_proto.GetBannerListReq} GetBannerListReq instance
     */
    GetBannerListReq.create = function create(properties) {
      return new GetBannerListReq(properties);
    };

    /**
     * Encodes the specified GetBannerListReq message. Does not implicitly {@link client_proto.GetBannerListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetBannerListReq
     * @static
     * @param {client_proto.IGetBannerListReq} message GetBannerListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetBannerListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified GetBannerListReq message, length delimited. Does not implicitly {@link client_proto.GetBannerListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetBannerListReq
     * @static
     * @param {client_proto.IGetBannerListReq} message GetBannerListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetBannerListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetBannerListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetBannerListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetBannerListReq} GetBannerListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetBannerListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetBannerListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetBannerListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetBannerListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetBannerListReq} GetBannerListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetBannerListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetBannerListReq message.
     * @function verify
     * @memberof client_proto.GetBannerListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetBannerListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a GetBannerListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetBannerListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetBannerListReq} GetBannerListReq
     */
    GetBannerListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetBannerListReq) return object;
      return new $root.client_proto.GetBannerListReq();
    };

    /**
     * Creates a plain object from a GetBannerListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetBannerListReq
     * @static
     * @param {client_proto.GetBannerListReq} message GetBannerListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetBannerListReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this GetBannerListReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetBannerListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetBannerListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetBannerListReq
     * @function getTypeUrl
     * @memberof client_proto.GetBannerListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetBannerListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetBannerListReq";
    };

    return GetBannerListReq;
  })();

  client_proto.GetBannerListResp = (function () {
    /**
     * Properties of a GetBannerListResp.
     * @memberof client_proto
     * @interface IGetBannerListResp
     * @property {Array.<client_proto.GetBannerListResp.IBannerItem>|null} [banner_list] GetBannerListResp banner_list
     */

    /**
     * Constructs a new GetBannerListResp.
     * @memberof client_proto
     * @classdesc Represents a GetBannerListResp.
     * @implements IGetBannerListResp
     * @constructor
     * @param {client_proto.IGetBannerListResp=} [properties] Properties to set
     */
    function GetBannerListResp(properties) {
      this.banner_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetBannerListResp banner_list.
     * @member {Array.<client_proto.GetBannerListResp.IBannerItem>} banner_list
     * @memberof client_proto.GetBannerListResp
     * @instance
     */
    GetBannerListResp.prototype.banner_list = $util.emptyArray;

    /**
     * Creates a new GetBannerListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetBannerListResp
     * @static
     * @param {client_proto.IGetBannerListResp=} [properties] Properties to set
     * @returns {client_proto.GetBannerListResp} GetBannerListResp instance
     */
    GetBannerListResp.create = function create(properties) {
      return new GetBannerListResp(properties);
    };

    /**
     * Encodes the specified GetBannerListResp message. Does not implicitly {@link client_proto.GetBannerListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetBannerListResp
     * @static
     * @param {client_proto.IGetBannerListResp} message GetBannerListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetBannerListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.banner_list != null && message.banner_list.length)
        for (var i = 0; i < message.banner_list.length; ++i)
          $root.client_proto.GetBannerListResp.BannerItem.encode(
            message.banner_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified GetBannerListResp message, length delimited. Does not implicitly {@link client_proto.GetBannerListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetBannerListResp
     * @static
     * @param {client_proto.IGetBannerListResp} message GetBannerListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetBannerListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetBannerListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetBannerListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetBannerListResp} GetBannerListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetBannerListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetBannerListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.banner_list && message.banner_list.length)) message.banner_list = [];
            message.banner_list.push($root.client_proto.GetBannerListResp.BannerItem.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetBannerListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetBannerListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetBannerListResp} GetBannerListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetBannerListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetBannerListResp message.
     * @function verify
     * @memberof client_proto.GetBannerListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetBannerListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.banner_list != null && message.hasOwnProperty("banner_list")) {
        if (!Array.isArray(message.banner_list)) return "banner_list: array expected";
        for (var i = 0; i < message.banner_list.length; ++i) {
          var error = $root.client_proto.GetBannerListResp.BannerItem.verify(message.banner_list[i]);
          if (error) return "banner_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates a GetBannerListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetBannerListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetBannerListResp} GetBannerListResp
     */
    GetBannerListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetBannerListResp) return object;
      var message = new $root.client_proto.GetBannerListResp();
      if (object.banner_list) {
        if (!Array.isArray(object.banner_list))
          throw TypeError(".client_proto.GetBannerListResp.banner_list: array expected");
        message.banner_list = [];
        for (var i = 0; i < object.banner_list.length; ++i) {
          if (typeof object.banner_list[i] !== "object")
            throw TypeError(".client_proto.GetBannerListResp.banner_list: object expected");
          message.banner_list[i] = $root.client_proto.GetBannerListResp.BannerItem.fromObject(object.banner_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a GetBannerListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetBannerListResp
     * @static
     * @param {client_proto.GetBannerListResp} message GetBannerListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetBannerListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.banner_list = [];
      if (message.banner_list && message.banner_list.length) {
        object.banner_list = [];
        for (var j = 0; j < message.banner_list.length; ++j)
          object.banner_list[j] = $root.client_proto.GetBannerListResp.BannerItem.toObject(
            message.banner_list[j],
            options,
          );
      }
      return object;
    };

    /**
     * Converts this GetBannerListResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetBannerListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetBannerListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetBannerListResp
     * @function getTypeUrl
     * @memberof client_proto.GetBannerListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetBannerListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetBannerListResp";
    };

    GetBannerListResp.ChargeItem = (function () {
      /**
       * Properties of a ChargeItem.
       * @memberof client_proto.GetBannerListResp
       * @interface IChargeItem
       * @property {number|null} [charge_id] ChargeItem charge_id
       * @property {string|null} [title] ChargeItem title
       * @property {string|null} [image] ChargeItem image
       * @property {number|Long|null} [price] ChargeItem price
       * @property {number|Long|null} [amount] ChargeItem amount
       * @property {number|null} [type] ChargeItem type
       * @property {number|Long|null} [show_min_bonus] ChargeItem show_min_bonus
       * @property {number|Long|null} [show_max_bonus] ChargeItem show_max_bonus
       * @property {number|null} [discount] ChargeItem discount
       * @property {number|null} [banner_id] ChargeItem banner_id
       */

      /**
       * Constructs a new ChargeItem.
       * @memberof client_proto.GetBannerListResp
       * @classdesc Represents a ChargeItem.
       * @implements IChargeItem
       * @constructor
       * @param {client_proto.GetBannerListResp.IChargeItem=} [properties] Properties to set
       */
      function ChargeItem(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }

      /**
       * ChargeItem charge_id.
       * @member {number} charge_id
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       */
      ChargeItem.prototype.charge_id = 0;

      /**
       * ChargeItem title.
       * @member {string} title
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       */
      ChargeItem.prototype.title = "";

      /**
       * ChargeItem image.
       * @member {string} image
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       */
      ChargeItem.prototype.image = "";

      /**
       * ChargeItem price.
       * @member {number|Long} price
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       */
      ChargeItem.prototype.price = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * ChargeItem amount.
       * @member {number|Long} amount
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       */
      ChargeItem.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * ChargeItem type.
       * @member {number} type
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       */
      ChargeItem.prototype.type = 0;

      /**
       * ChargeItem show_min_bonus.
       * @member {number|Long} show_min_bonus
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       */
      ChargeItem.prototype.show_min_bonus = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * ChargeItem show_max_bonus.
       * @member {number|Long} show_max_bonus
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       */
      ChargeItem.prototype.show_max_bonus = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * ChargeItem discount.
       * @member {number} discount
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       */
      ChargeItem.prototype.discount = 0;

      /**
       * ChargeItem banner_id.
       * @member {number} banner_id
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       */
      ChargeItem.prototype.banner_id = 0;

      /**
       * Creates a new ChargeItem instance using the specified properties.
       * @function create
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @static
       * @param {client_proto.GetBannerListResp.IChargeItem=} [properties] Properties to set
       * @returns {client_proto.GetBannerListResp.ChargeItem} ChargeItem instance
       */
      ChargeItem.create = function create(properties) {
        return new ChargeItem(properties);
      };

      /**
       * Encodes the specified ChargeItem message. Does not implicitly {@link client_proto.GetBannerListResp.ChargeItem.verify|verify} messages.
       * @function encode
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @static
       * @param {client_proto.GetBannerListResp.IChargeItem} message ChargeItem message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ChargeItem.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.charge_id != null && Object.hasOwnProperty.call(message, "charge_id"))
          writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.charge_id);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
          writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.title);
        if (message.image != null && Object.hasOwnProperty.call(message, "image"))
          writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.image);
        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
          writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.price);
        if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
          writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.amount);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
          writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.type);
        if (message.show_min_bonus != null && Object.hasOwnProperty.call(message, "show_min_bonus"))
          writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.show_min_bonus);
        if (message.show_max_bonus != null && Object.hasOwnProperty.call(message, "show_max_bonus"))
          writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.show_max_bonus);
        if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
          writer.uint32(/* id 9, wireType 0 =*/ 72).int32(message.discount);
        if (message.banner_id != null && Object.hasOwnProperty.call(message, "banner_id"))
          writer.uint32(/* id 10, wireType 0 =*/ 80).int32(message.banner_id);
        return writer;
      };

      /**
       * Encodes the specified ChargeItem message, length delimited. Does not implicitly {@link client_proto.GetBannerListResp.ChargeItem.verify|verify} messages.
       * @function encodeDelimited
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @static
       * @param {client_proto.GetBannerListResp.IChargeItem} message ChargeItem message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ChargeItem.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ChargeItem message from the specified reader or buffer.
       * @function decode
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {client_proto.GetBannerListResp.ChargeItem} ChargeItem
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ChargeItem.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.client_proto.GetBannerListResp.ChargeItem();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.charge_id = reader.int32();
              break;
            }
            case 2: {
              message.title = reader.string();
              break;
            }
            case 3: {
              message.image = reader.string();
              break;
            }
            case 4: {
              message.price = reader.int64();
              break;
            }
            case 5: {
              message.amount = reader.int64();
              break;
            }
            case 6: {
              message.type = reader.int32();
              break;
            }
            case 7: {
              message.show_min_bonus = reader.int64();
              break;
            }
            case 8: {
              message.show_max_bonus = reader.int64();
              break;
            }
            case 9: {
              message.discount = reader.int32();
              break;
            }
            case 10: {
              message.banner_id = reader.int32();
              break;
            }
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a ChargeItem message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {client_proto.GetBannerListResp.ChargeItem} ChargeItem
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ChargeItem.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ChargeItem message.
       * @function verify
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ChargeItem.verify = function verify(message) {
        if (typeof message !== "object" || message === null) return "object expected";
        if (message.charge_id != null && message.hasOwnProperty("charge_id"))
          if (!$util.isInteger(message.charge_id)) return "charge_id: integer expected";
        if (message.title != null && message.hasOwnProperty("title"))
          if (!$util.isString(message.title)) return "title: string expected";
        if (message.image != null && message.hasOwnProperty("image"))
          if (!$util.isString(message.image)) return "image: string expected";
        if (message.price != null && message.hasOwnProperty("price"))
          if (
            !$util.isInteger(message.price) &&
            !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high))
          )
            return "price: integer|Long expected";
        if (message.amount != null && message.hasOwnProperty("amount"))
          if (
            !$util.isInteger(message.amount) &&
            !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
          )
            return "amount: integer|Long expected";
        if (message.type != null && message.hasOwnProperty("type"))
          if (!$util.isInteger(message.type)) return "type: integer expected";
        if (message.show_min_bonus != null && message.hasOwnProperty("show_min_bonus"))
          if (
            !$util.isInteger(message.show_min_bonus) &&
            !(
              message.show_min_bonus &&
              $util.isInteger(message.show_min_bonus.low) &&
              $util.isInteger(message.show_min_bonus.high)
            )
          )
            return "show_min_bonus: integer|Long expected";
        if (message.show_max_bonus != null && message.hasOwnProperty("show_max_bonus"))
          if (
            !$util.isInteger(message.show_max_bonus) &&
            !(
              message.show_max_bonus &&
              $util.isInteger(message.show_max_bonus.low) &&
              $util.isInteger(message.show_max_bonus.high)
            )
          )
            return "show_max_bonus: integer|Long expected";
        if (message.discount != null && message.hasOwnProperty("discount"))
          if (!$util.isInteger(message.discount)) return "discount: integer expected";
        if (message.banner_id != null && message.hasOwnProperty("banner_id"))
          if (!$util.isInteger(message.banner_id)) return "banner_id: integer expected";
        return null;
      };

      /**
       * Creates a ChargeItem message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {client_proto.GetBannerListResp.ChargeItem} ChargeItem
       */
      ChargeItem.fromObject = function fromObject(object) {
        if (object instanceof $root.client_proto.GetBannerListResp.ChargeItem) return object;
        var message = new $root.client_proto.GetBannerListResp.ChargeItem();
        if (object.charge_id != null) message.charge_id = object.charge_id | 0;
        if (object.title != null) message.title = String(object.title);
        if (object.image != null) message.image = String(object.image);
        if (object.price != null)
          if ($util.Long) (message.price = $util.Long.fromValue(object.price)).unsigned = false;
          else if (typeof object.price === "string") message.price = parseInt(object.price, 10);
          else if (typeof object.price === "number") message.price = object.price;
          else if (typeof object.price === "object")
            message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
        if (object.amount != null)
          if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
          else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
          else if (typeof object.amount === "number") message.amount = object.amount;
          else if (typeof object.amount === "object")
            message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
        if (object.type != null) message.type = object.type | 0;
        if (object.show_min_bonus != null)
          if ($util.Long) (message.show_min_bonus = $util.Long.fromValue(object.show_min_bonus)).unsigned = false;
          else if (typeof object.show_min_bonus === "string")
            message.show_min_bonus = parseInt(object.show_min_bonus, 10);
          else if (typeof object.show_min_bonus === "number") message.show_min_bonus = object.show_min_bonus;
          else if (typeof object.show_min_bonus === "object")
            message.show_min_bonus = new $util.LongBits(
              object.show_min_bonus.low >>> 0,
              object.show_min_bonus.high >>> 0,
            ).toNumber();
        if (object.show_max_bonus != null)
          if ($util.Long) (message.show_max_bonus = $util.Long.fromValue(object.show_max_bonus)).unsigned = false;
          else if (typeof object.show_max_bonus === "string")
            message.show_max_bonus = parseInt(object.show_max_bonus, 10);
          else if (typeof object.show_max_bonus === "number") message.show_max_bonus = object.show_max_bonus;
          else if (typeof object.show_max_bonus === "object")
            message.show_max_bonus = new $util.LongBits(
              object.show_max_bonus.low >>> 0,
              object.show_max_bonus.high >>> 0,
            ).toNumber();
        if (object.discount != null) message.discount = object.discount | 0;
        if (object.banner_id != null) message.banner_id = object.banner_id | 0;
        return message;
      };

      /**
       * Creates a plain object from a ChargeItem message. Also converts values to other types if specified.
       * @function toObject
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @static
       * @param {client_proto.GetBannerListResp.ChargeItem} message ChargeItem
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ChargeItem.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.charge_id = 0;
          object.title = "";
          object.image = "";
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.price =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.price = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.amount =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.amount = options.longs === String ? "0" : 0;
          object.type = 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.show_min_bonus =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.show_min_bonus = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.show_max_bonus =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.show_max_bonus = options.longs === String ? "0" : 0;
          object.discount = 0;
          object.banner_id = 0;
        }
        if (message.charge_id != null && message.hasOwnProperty("charge_id")) object.charge_id = message.charge_id;
        if (message.title != null && message.hasOwnProperty("title")) object.title = message.title;
        if (message.image != null && message.hasOwnProperty("image")) object.image = message.image;
        if (message.price != null && message.hasOwnProperty("price"))
          if (typeof message.price === "number")
            object.price = options.longs === String ? String(message.price) : message.price;
          else
            object.price =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.price)
                : options.longs === Number
                  ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber()
                  : message.price;
        if (message.amount != null && message.hasOwnProperty("amount"))
          if (typeof message.amount === "number")
            object.amount = options.longs === String ? String(message.amount) : message.amount;
          else
            object.amount =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.amount)
                : options.longs === Number
                  ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                  : message.amount;
        if (message.type != null && message.hasOwnProperty("type")) object.type = message.type;
        if (message.show_min_bonus != null && message.hasOwnProperty("show_min_bonus"))
          if (typeof message.show_min_bonus === "number")
            object.show_min_bonus = options.longs === String ? String(message.show_min_bonus) : message.show_min_bonus;
          else
            object.show_min_bonus =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.show_min_bonus)
                : options.longs === Number
                  ? new $util.LongBits(message.show_min_bonus.low >>> 0, message.show_min_bonus.high >>> 0).toNumber()
                  : message.show_min_bonus;
        if (message.show_max_bonus != null && message.hasOwnProperty("show_max_bonus"))
          if (typeof message.show_max_bonus === "number")
            object.show_max_bonus = options.longs === String ? String(message.show_max_bonus) : message.show_max_bonus;
          else
            object.show_max_bonus =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.show_max_bonus)
                : options.longs === Number
                  ? new $util.LongBits(message.show_max_bonus.low >>> 0, message.show_max_bonus.high >>> 0).toNumber()
                  : message.show_max_bonus;
        if (message.discount != null && message.hasOwnProperty("discount")) object.discount = message.discount;
        if (message.banner_id != null && message.hasOwnProperty("banner_id")) object.banner_id = message.banner_id;
        return object;
      };

      /**
       * Converts this ChargeItem to JSON.
       * @function toJSON
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ChargeItem.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for ChargeItem
       * @function getTypeUrl
       * @memberof client_proto.GetBannerListResp.ChargeItem
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      ChargeItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/client_proto.GetBannerListResp.ChargeItem";
      };

      return ChargeItem;
    })();

    GetBannerListResp.BannerItem = (function () {
      /**
       * Properties of a BannerItem.
       * @memberof client_proto.GetBannerListResp
       * @interface IBannerItem
       * @property {string|null} [image] BannerItem image
       * @property {number|null} [jump_type] BannerItem jump_type
       * @property {Array.<client_proto.GetBannerListResp.IChargeItem>|null} [item_list] BannerItem item_list
       * @property {number|null} [show_count_down] BannerItem show_count_down
       * @property {number|null} [count_down] BannerItem count_down
       * @property {number|null} [banner_id] BannerItem banner_id
       * @property {string|null} [image_title] BannerItem image_title
       * @property {string|null} [desc_txt] BannerItem desc_txt
       */

      /**
       * Constructs a new BannerItem.
       * @memberof client_proto.GetBannerListResp
       * @classdesc Represents a BannerItem.
       * @implements IBannerItem
       * @constructor
       * @param {client_proto.GetBannerListResp.IBannerItem=} [properties] Properties to set
       */
      function BannerItem(properties) {
        this.item_list = [];
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }

      /**
       * BannerItem image.
       * @member {string} image
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @instance
       */
      BannerItem.prototype.image = "";

      /**
       * BannerItem jump_type.
       * @member {number} jump_type
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @instance
       */
      BannerItem.prototype.jump_type = 0;

      /**
       * BannerItem item_list.
       * @member {Array.<client_proto.GetBannerListResp.IChargeItem>} item_list
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @instance
       */
      BannerItem.prototype.item_list = $util.emptyArray;

      /**
       * BannerItem show_count_down.
       * @member {number} show_count_down
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @instance
       */
      BannerItem.prototype.show_count_down = 0;

      /**
       * BannerItem count_down.
       * @member {number} count_down
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @instance
       */
      BannerItem.prototype.count_down = 0;

      /**
       * BannerItem banner_id.
       * @member {number} banner_id
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @instance
       */
      BannerItem.prototype.banner_id = 0;

      /**
       * BannerItem image_title.
       * @member {string} image_title
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @instance
       */
      BannerItem.prototype.image_title = "";

      /**
       * BannerItem desc_txt.
       * @member {string} desc_txt
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @instance
       */
      BannerItem.prototype.desc_txt = "";

      /**
       * Creates a new BannerItem instance using the specified properties.
       * @function create
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @static
       * @param {client_proto.GetBannerListResp.IBannerItem=} [properties] Properties to set
       * @returns {client_proto.GetBannerListResp.BannerItem} BannerItem instance
       */
      BannerItem.create = function create(properties) {
        return new BannerItem(properties);
      };

      /**
       * Encodes the specified BannerItem message. Does not implicitly {@link client_proto.GetBannerListResp.BannerItem.verify|verify} messages.
       * @function encode
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @static
       * @param {client_proto.GetBannerListResp.IBannerItem} message BannerItem message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      BannerItem.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.image != null && Object.hasOwnProperty.call(message, "image"))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.image);
        if (message.jump_type != null && Object.hasOwnProperty.call(message, "jump_type"))
          writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.jump_type);
        if (message.item_list != null && message.item_list.length)
          for (var i = 0; i < message.item_list.length; ++i)
            $root.client_proto.GetBannerListResp.ChargeItem.encode(
              message.item_list[i],
              writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
            ).ldelim();
        if (message.show_count_down != null && Object.hasOwnProperty.call(message, "show_count_down"))
          writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.show_count_down);
        if (message.count_down != null && Object.hasOwnProperty.call(message, "count_down"))
          writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.count_down);
        if (message.banner_id != null && Object.hasOwnProperty.call(message, "banner_id"))
          writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.banner_id);
        if (message.image_title != null && Object.hasOwnProperty.call(message, "image_title"))
          writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.image_title);
        if (message.desc_txt != null && Object.hasOwnProperty.call(message, "desc_txt"))
          writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.desc_txt);
        return writer;
      };

      /**
       * Encodes the specified BannerItem message, length delimited. Does not implicitly {@link client_proto.GetBannerListResp.BannerItem.verify|verify} messages.
       * @function encodeDelimited
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @static
       * @param {client_proto.GetBannerListResp.IBannerItem} message BannerItem message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      BannerItem.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a BannerItem message from the specified reader or buffer.
       * @function decode
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {client_proto.GetBannerListResp.BannerItem} BannerItem
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      BannerItem.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.client_proto.GetBannerListResp.BannerItem();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.image = reader.string();
              break;
            }
            case 2: {
              message.jump_type = reader.int32();
              break;
            }
            case 3: {
              if (!(message.item_list && message.item_list.length)) message.item_list = [];
              message.item_list.push($root.client_proto.GetBannerListResp.ChargeItem.decode(reader, reader.uint32()));
              break;
            }
            case 4: {
              message.show_count_down = reader.int32();
              break;
            }
            case 5: {
              message.count_down = reader.int32();
              break;
            }
            case 6: {
              message.banner_id = reader.int32();
              break;
            }
            case 7: {
              message.image_title = reader.string();
              break;
            }
            case 8: {
              message.desc_txt = reader.string();
              break;
            }
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a BannerItem message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {client_proto.GetBannerListResp.BannerItem} BannerItem
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      BannerItem.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a BannerItem message.
       * @function verify
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      BannerItem.verify = function verify(message) {
        if (typeof message !== "object" || message === null) return "object expected";
        if (message.image != null && message.hasOwnProperty("image"))
          if (!$util.isString(message.image)) return "image: string expected";
        if (message.jump_type != null && message.hasOwnProperty("jump_type"))
          if (!$util.isInteger(message.jump_type)) return "jump_type: integer expected";
        if (message.item_list != null && message.hasOwnProperty("item_list")) {
          if (!Array.isArray(message.item_list)) return "item_list: array expected";
          for (var i = 0; i < message.item_list.length; ++i) {
            var error = $root.client_proto.GetBannerListResp.ChargeItem.verify(message.item_list[i]);
            if (error) return "item_list." + error;
          }
        }
        if (message.show_count_down != null && message.hasOwnProperty("show_count_down"))
          if (!$util.isInteger(message.show_count_down)) return "show_count_down: integer expected";
        if (message.count_down != null && message.hasOwnProperty("count_down"))
          if (!$util.isInteger(message.count_down)) return "count_down: integer expected";
        if (message.banner_id != null && message.hasOwnProperty("banner_id"))
          if (!$util.isInteger(message.banner_id)) return "banner_id: integer expected";
        if (message.image_title != null && message.hasOwnProperty("image_title"))
          if (!$util.isString(message.image_title)) return "image_title: string expected";
        if (message.desc_txt != null && message.hasOwnProperty("desc_txt"))
          if (!$util.isString(message.desc_txt)) return "desc_txt: string expected";
        return null;
      };

      /**
       * Creates a BannerItem message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {client_proto.GetBannerListResp.BannerItem} BannerItem
       */
      BannerItem.fromObject = function fromObject(object) {
        if (object instanceof $root.client_proto.GetBannerListResp.BannerItem) return object;
        var message = new $root.client_proto.GetBannerListResp.BannerItem();
        if (object.image != null) message.image = String(object.image);
        if (object.jump_type != null) message.jump_type = object.jump_type | 0;
        if (object.item_list) {
          if (!Array.isArray(object.item_list))
            throw TypeError(".client_proto.GetBannerListResp.BannerItem.item_list: array expected");
          message.item_list = [];
          for (var i = 0; i < object.item_list.length; ++i) {
            if (typeof object.item_list[i] !== "object")
              throw TypeError(".client_proto.GetBannerListResp.BannerItem.item_list: object expected");
            message.item_list[i] = $root.client_proto.GetBannerListResp.ChargeItem.fromObject(object.item_list[i]);
          }
        }
        if (object.show_count_down != null) message.show_count_down = object.show_count_down | 0;
        if (object.count_down != null) message.count_down = object.count_down | 0;
        if (object.banner_id != null) message.banner_id = object.banner_id | 0;
        if (object.image_title != null) message.image_title = String(object.image_title);
        if (object.desc_txt != null) message.desc_txt = String(object.desc_txt);
        return message;
      };

      /**
       * Creates a plain object from a BannerItem message. Also converts values to other types if specified.
       * @function toObject
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @static
       * @param {client_proto.GetBannerListResp.BannerItem} message BannerItem
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      BannerItem.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.arrays || options.defaults) object.item_list = [];
        if (options.defaults) {
          object.image = "";
          object.jump_type = 0;
          object.show_count_down = 0;
          object.count_down = 0;
          object.banner_id = 0;
          object.image_title = "";
          object.desc_txt = "";
        }
        if (message.image != null && message.hasOwnProperty("image")) object.image = message.image;
        if (message.jump_type != null && message.hasOwnProperty("jump_type")) object.jump_type = message.jump_type;
        if (message.item_list && message.item_list.length) {
          object.item_list = [];
          for (var j = 0; j < message.item_list.length; ++j)
            object.item_list[j] = $root.client_proto.GetBannerListResp.ChargeItem.toObject(
              message.item_list[j],
              options,
            );
        }
        if (message.show_count_down != null && message.hasOwnProperty("show_count_down"))
          object.show_count_down = message.show_count_down;
        if (message.count_down != null && message.hasOwnProperty("count_down")) object.count_down = message.count_down;
        if (message.banner_id != null && message.hasOwnProperty("banner_id")) object.banner_id = message.banner_id;
        if (message.image_title != null && message.hasOwnProperty("image_title"))
          object.image_title = message.image_title;
        if (message.desc_txt != null && message.hasOwnProperty("desc_txt")) object.desc_txt = message.desc_txt;
        return object;
      };

      /**
       * Converts this BannerItem to JSON.
       * @function toJSON
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      BannerItem.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for BannerItem
       * @function getTypeUrl
       * @memberof client_proto.GetBannerListResp.BannerItem
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      BannerItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/client_proto.GetBannerListResp.BannerItem";
      };

      return BannerItem;
    })();

    return GetBannerListResp;
  })();

  client_proto.SrvUpdateOrderInfoReq = (function () {
    /**
     * Properties of a SrvUpdateOrderInfoReq.
     * @memberof client_proto
     * @interface ISrvUpdateOrderInfoReq
     * @property {number|null} [uid] SrvUpdateOrderInfoReq uid
     * @property {number|null} [order_id] SrvUpdateOrderInfoReq order_id
     * @property {number|Long|null} [bonus] SrvUpdateOrderInfoReq bonus
     * @property {number|Long|null} [bonus_flow] SrvUpdateOrderInfoReq bonus_flow
     */

    /**
     * Constructs a new SrvUpdateOrderInfoReq.
     * @memberof client_proto
     * @classdesc Represents a SrvUpdateOrderInfoReq.
     * @implements ISrvUpdateOrderInfoReq
     * @constructor
     * @param {client_proto.ISrvUpdateOrderInfoReq=} [properties] Properties to set
     */
    function SrvUpdateOrderInfoReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SrvUpdateOrderInfoReq uid.
     * @member {number} uid
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @instance
     */
    SrvUpdateOrderInfoReq.prototype.uid = 0;

    /**
     * SrvUpdateOrderInfoReq order_id.
     * @member {number} order_id
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @instance
     */
    SrvUpdateOrderInfoReq.prototype.order_id = 0;

    /**
     * SrvUpdateOrderInfoReq bonus.
     * @member {number|Long} bonus
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @instance
     */
    SrvUpdateOrderInfoReq.prototype.bonus = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SrvUpdateOrderInfoReq bonus_flow.
     * @member {number|Long} bonus_flow
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @instance
     */
    SrvUpdateOrderInfoReq.prototype.bonus_flow = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new SrvUpdateOrderInfoReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @static
     * @param {client_proto.ISrvUpdateOrderInfoReq=} [properties] Properties to set
     * @returns {client_proto.SrvUpdateOrderInfoReq} SrvUpdateOrderInfoReq instance
     */
    SrvUpdateOrderInfoReq.create = function create(properties) {
      return new SrvUpdateOrderInfoReq(properties);
    };

    /**
     * Encodes the specified SrvUpdateOrderInfoReq message. Does not implicitly {@link client_proto.SrvUpdateOrderInfoReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @static
     * @param {client_proto.ISrvUpdateOrderInfoReq} message SrvUpdateOrderInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvUpdateOrderInfoReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.order_id);
      if (message.bonus != null && Object.hasOwnProperty.call(message, "bonus"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.bonus);
      if (message.bonus_flow != null && Object.hasOwnProperty.call(message, "bonus_flow"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.bonus_flow);
      return writer;
    };

    /**
     * Encodes the specified SrvUpdateOrderInfoReq message, length delimited. Does not implicitly {@link client_proto.SrvUpdateOrderInfoReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @static
     * @param {client_proto.ISrvUpdateOrderInfoReq} message SrvUpdateOrderInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvUpdateOrderInfoReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SrvUpdateOrderInfoReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SrvUpdateOrderInfoReq} SrvUpdateOrderInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvUpdateOrderInfoReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SrvUpdateOrderInfoReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.order_id = reader.int32();
            break;
          }
          case 3: {
            message.bonus = reader.int64();
            break;
          }
          case 4: {
            message.bonus_flow = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SrvUpdateOrderInfoReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SrvUpdateOrderInfoReq} SrvUpdateOrderInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvUpdateOrderInfoReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SrvUpdateOrderInfoReq message.
     * @function verify
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SrvUpdateOrderInfoReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.bonus != null && message.hasOwnProperty("bonus"))
        if (
          !$util.isInteger(message.bonus) &&
          !(message.bonus && $util.isInteger(message.bonus.low) && $util.isInteger(message.bonus.high))
        )
          return "bonus: integer|Long expected";
      if (message.bonus_flow != null && message.hasOwnProperty("bonus_flow"))
        if (
          !$util.isInteger(message.bonus_flow) &&
          !(message.bonus_flow && $util.isInteger(message.bonus_flow.low) && $util.isInteger(message.bonus_flow.high))
        )
          return "bonus_flow: integer|Long expected";
      return null;
    };

    /**
     * Creates a SrvUpdateOrderInfoReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SrvUpdateOrderInfoReq} SrvUpdateOrderInfoReq
     */
    SrvUpdateOrderInfoReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SrvUpdateOrderInfoReq) return object;
      var message = new $root.client_proto.SrvUpdateOrderInfoReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.bonus != null)
        if ($util.Long) (message.bonus = $util.Long.fromValue(object.bonus)).unsigned = false;
        else if (typeof object.bonus === "string") message.bonus = parseInt(object.bonus, 10);
        else if (typeof object.bonus === "number") message.bonus = object.bonus;
        else if (typeof object.bonus === "object")
          message.bonus = new $util.LongBits(object.bonus.low >>> 0, object.bonus.high >>> 0).toNumber();
      if (object.bonus_flow != null)
        if ($util.Long) (message.bonus_flow = $util.Long.fromValue(object.bonus_flow)).unsigned = false;
        else if (typeof object.bonus_flow === "string") message.bonus_flow = parseInt(object.bonus_flow, 10);
        else if (typeof object.bonus_flow === "number") message.bonus_flow = object.bonus_flow;
        else if (typeof object.bonus_flow === "object")
          message.bonus_flow = new $util.LongBits(object.bonus_flow.low >>> 0, object.bonus_flow.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a SrvUpdateOrderInfoReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @static
     * @param {client_proto.SrvUpdateOrderInfoReq} message SrvUpdateOrderInfoReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SrvUpdateOrderInfoReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.order_id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.bonus = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.bonus = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.bonus_flow =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.bonus_flow = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.bonus != null && message.hasOwnProperty("bonus"))
        if (typeof message.bonus === "number")
          object.bonus = options.longs === String ? String(message.bonus) : message.bonus;
        else
          object.bonus =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.bonus)
              : options.longs === Number
                ? new $util.LongBits(message.bonus.low >>> 0, message.bonus.high >>> 0).toNumber()
                : message.bonus;
      if (message.bonus_flow != null && message.hasOwnProperty("bonus_flow"))
        if (typeof message.bonus_flow === "number")
          object.bonus_flow = options.longs === String ? String(message.bonus_flow) : message.bonus_flow;
        else
          object.bonus_flow =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.bonus_flow)
              : options.longs === Number
                ? new $util.LongBits(message.bonus_flow.low >>> 0, message.bonus_flow.high >>> 0).toNumber()
                : message.bonus_flow;
      return object;
    };

    /**
     * Converts this SrvUpdateOrderInfoReq to JSON.
     * @function toJSON
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SrvUpdateOrderInfoReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SrvUpdateOrderInfoReq
     * @function getTypeUrl
     * @memberof client_proto.SrvUpdateOrderInfoReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SrvUpdateOrderInfoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SrvUpdateOrderInfoReq";
    };

    return SrvUpdateOrderInfoReq;
  })();

  /**
   * SPIN_SUB_MSG_ID enum.
   * @name client_proto.SPIN_SUB_MSG_ID
   * @enum {number}
   * @property {number} SSMI_NULL=0 SSMI_NULL value
   * @property {number} SSMI_SPIN_MSG_MIN=1200 SSMI_SPIN_MSG_MIN value
   * @property {number} SSMI_SPIN_INFO_REQ=1201 SSMI_SPIN_INFO_REQ value
   * @property {number} SSMI_SPIN_INFO_RESP=1202 SSMI_SPIN_INFO_RESP value
   * @property {number} SSMI_SPIN_REQ=1203 SSMI_SPIN_REQ value
   * @property {number} SSMI_SPIN_RESP=1204 SSMI_SPIN_RESP value
   * @property {number} SSMI_LOAD_CONFIG=1205 SSMI_LOAD_CONFIG value
   * @property {number} SSMI_WITHDRAW_REQ=1206 SSMI_WITHDRAW_REQ value
   * @property {number} SSMI_WITHDRAW_RESP=1207 SSMI_WITHDRAW_RESP value
   * @property {number} SSMI_WITHDRAW_LOG_REQ=1208 SSMI_WITHDRAW_LOG_REQ value
   * @property {number} SSMI_WITHDRAW_LOG_RESP=1209 SSMI_WITHDRAW_LOG_RESP value
   * @property {number} SSMI_GET_CUR_DATA_REQ=1210 SSMI_GET_CUR_DATA_REQ value
   * @property {number} SSMI_GET_CUR_DATA_RESP=1211 SSMI_GET_CUR_DATA_RESP value
   * @property {number} SSMI_RECORD_ADD_PUSH=1212 SSMI_RECORD_ADD_PUSH value
   * @property {number} SSMI_GET_SPIN_POSTER_REQ=1213 SSMI_GET_SPIN_POSTER_REQ value
   * @property {number} SSMI_GET_SPIN_POSTER_RESP=1214 SSMI_GET_SPIN_POSTER_RESP value
   * @property {number} SSMI_PHP_AUDIT_REQ=1230 SSMI_PHP_AUDIT_REQ value
   * @property {number} SSMI_PHP_AUDIT_RESP=1231 SSMI_PHP_AUDIT_RESP value
   * @property {number} SSMI_SRV_INVITE_NOTIFY=1235 SSMI_SRV_INVITE_NOTIFY value
   * @property {number} SSMI_SPIN_MSG_MAX=1250 SSMI_SPIN_MSG_MAX value
   */
  client_proto.SPIN_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "SSMI_NULL")] = 0;
    values[(valuesById[1200] = "SSMI_SPIN_MSG_MIN")] = 1200;
    values[(valuesById[1201] = "SSMI_SPIN_INFO_REQ")] = 1201;
    values[(valuesById[1202] = "SSMI_SPIN_INFO_RESP")] = 1202;
    values[(valuesById[1203] = "SSMI_SPIN_REQ")] = 1203;
    values[(valuesById[1204] = "SSMI_SPIN_RESP")] = 1204;
    values[(valuesById[1205] = "SSMI_LOAD_CONFIG")] = 1205;
    values[(valuesById[1206] = "SSMI_WITHDRAW_REQ")] = 1206;
    values[(valuesById[1207] = "SSMI_WITHDRAW_RESP")] = 1207;
    values[(valuesById[1208] = "SSMI_WITHDRAW_LOG_REQ")] = 1208;
    values[(valuesById[1209] = "SSMI_WITHDRAW_LOG_RESP")] = 1209;
    values[(valuesById[1210] = "SSMI_GET_CUR_DATA_REQ")] = 1210;
    values[(valuesById[1211] = "SSMI_GET_CUR_DATA_RESP")] = 1211;
    values[(valuesById[1212] = "SSMI_RECORD_ADD_PUSH")] = 1212;
    values[(valuesById[1213] = "SSMI_GET_SPIN_POSTER_REQ")] = 1213;
    values[(valuesById[1214] = "SSMI_GET_SPIN_POSTER_RESP")] = 1214;
    values[(valuesById[1230] = "SSMI_PHP_AUDIT_REQ")] = 1230;
    values[(valuesById[1231] = "SSMI_PHP_AUDIT_RESP")] = 1231;
    values[(valuesById[1235] = "SSMI_SRV_INVITE_NOTIFY")] = 1235;
    values[(valuesById[1250] = "SSMI_SPIN_MSG_MAX")] = 1250;
    return values;
  })();

  /**
   * SPIN_ERROR_CODE enum.
   * @name client_proto.SPIN_ERROR_CODE
   * @enum {number}
   * @property {number} SPIN_ERR_NULL=0 SPIN_ERR_NULL value
   * @property {number} SPIN_ERR_SERVER_ERROR=1 SPIN_ERR_SERVER_ERROR value
   * @property {number} SPIN_ERR_DAY_LIMIT=2 SPIN_ERR_DAY_LIMIT value
   * @property {number} SPIN_ERR_NO_CHANCE=3 SPIN_ERR_NO_CHANCE value
   * @property {number} SPIN_ERR_USER_DATA_ERROR=4 SPIN_ERR_USER_DATA_ERROR value
   * @property {number} SPIN_ERR_BIND_PHONE_NUM=5 SPIN_ERR_BIND_PHONE_NUM value
   * @property {number} SPIN_ERR_NOT_ENOUGH=6 SPIN_ERR_NOT_ENOUGH value
   * @property {number} SPIN_ERR_AMOUNT_LIMIT=7 SPIN_ERR_AMOUNT_LIMIT value
   */
  client_proto.SPIN_ERROR_CODE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "SPIN_ERR_NULL")] = 0;
    values[(valuesById[1] = "SPIN_ERR_SERVER_ERROR")] = 1;
    values[(valuesById[2] = "SPIN_ERR_DAY_LIMIT")] = 2;
    values[(valuesById[3] = "SPIN_ERR_NO_CHANCE")] = 3;
    values[(valuesById[4] = "SPIN_ERR_USER_DATA_ERROR")] = 4;
    values[(valuesById[5] = "SPIN_ERR_BIND_PHONE_NUM")] = 5;
    values[(valuesById[6] = "SPIN_ERR_NOT_ENOUGH")] = 6;
    values[(valuesById[7] = "SPIN_ERR_AMOUNT_LIMIT")] = 7;
    return values;
  })();

  /**
   * SPIN_ORDER_STATUS enum.
   * @name client_proto.SPIN_ORDER_STATUS
   * @enum {number}
   * @property {number} SPIN_ORDER_STATUS_INIT=0 SPIN_ORDER_STATUS_INIT value
   * @property {number} SPIN_ORDER_STATUS_PASS=1 SPIN_ORDER_STATUS_PASS value
   * @property {number} SPIN_ORDER_STATUS_REJECT=2 SPIN_ORDER_STATUS_REJECT value
   * @property {number} SPIN_ORDER_STATUS_DELAY=3 SPIN_ORDER_STATUS_DELAY value
   */
  client_proto.SPIN_ORDER_STATUS = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "SPIN_ORDER_STATUS_INIT")] = 0;
    values[(valuesById[1] = "SPIN_ORDER_STATUS_PASS")] = 1;
    values[(valuesById[2] = "SPIN_ORDER_STATUS_REJECT")] = 2;
    values[(valuesById[3] = "SPIN_ORDER_STATUS_DELAY")] = 3;
    return values;
  })();

  client_proto.SpinItem = (function () {
    /**
     * Properties of a SpinItem.
     * @memberof client_proto
     * @interface ISpinItem
     * @property {number|null} [seq] SpinItem seq
     * @property {number|null} [prop_id] SpinItem prop_id
     * @property {number|null} [money_gt] SpinItem money_gt
     * @property {number|null} [money_le] SpinItem money_le
     */

    /**
     * Constructs a new SpinItem.
     * @memberof client_proto
     * @classdesc Represents a SpinItem.
     * @implements ISpinItem
     * @constructor
     * @param {client_proto.ISpinItem=} [properties] Properties to set
     */
    function SpinItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinItem seq.
     * @member {number} seq
     * @memberof client_proto.SpinItem
     * @instance
     */
    SpinItem.prototype.seq = 0;

    /**
     * SpinItem prop_id.
     * @member {number} prop_id
     * @memberof client_proto.SpinItem
     * @instance
     */
    SpinItem.prototype.prop_id = 0;

    /**
     * SpinItem money_gt.
     * @member {number} money_gt
     * @memberof client_proto.SpinItem
     * @instance
     */
    SpinItem.prototype.money_gt = 0;

    /**
     * SpinItem money_le.
     * @member {number} money_le
     * @memberof client_proto.SpinItem
     * @instance
     */
    SpinItem.prototype.money_le = 0;

    /**
     * Creates a new SpinItem instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinItem
     * @static
     * @param {client_proto.ISpinItem=} [properties] Properties to set
     * @returns {client_proto.SpinItem} SpinItem instance
     */
    SpinItem.create = function create(properties) {
      return new SpinItem(properties);
    };

    /**
     * Encodes the specified SpinItem message. Does not implicitly {@link client_proto.SpinItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinItem
     * @static
     * @param {client_proto.ISpinItem} message SpinItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.seq != null && Object.hasOwnProperty.call(message, "seq"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.seq);
      if (message.prop_id != null && Object.hasOwnProperty.call(message, "prop_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.prop_id);
      if (message.money_gt != null && Object.hasOwnProperty.call(message, "money_gt"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.money_gt);
      if (message.money_le != null && Object.hasOwnProperty.call(message, "money_le"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.money_le);
      return writer;
    };

    /**
     * Encodes the specified SpinItem message, length delimited. Does not implicitly {@link client_proto.SpinItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinItem
     * @static
     * @param {client_proto.ISpinItem} message SpinItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinItem} SpinItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.seq = reader.int32();
            break;
          }
          case 2: {
            message.prop_id = reader.int32();
            break;
          }
          case 3: {
            message.money_gt = reader.int32();
            break;
          }
          case 4: {
            message.money_le = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinItem} SpinItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinItem message.
     * @function verify
     * @memberof client_proto.SpinItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.seq != null && message.hasOwnProperty("seq"))
        if (!$util.isInteger(message.seq)) return "seq: integer expected";
      if (message.prop_id != null && message.hasOwnProperty("prop_id"))
        if (!$util.isInteger(message.prop_id)) return "prop_id: integer expected";
      if (message.money_gt != null && message.hasOwnProperty("money_gt"))
        if (!$util.isInteger(message.money_gt)) return "money_gt: integer expected";
      if (message.money_le != null && message.hasOwnProperty("money_le"))
        if (!$util.isInteger(message.money_le)) return "money_le: integer expected";
      return null;
    };

    /**
     * Creates a SpinItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinItem} SpinItem
     */
    SpinItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinItem) return object;
      var message = new $root.client_proto.SpinItem();
      if (object.seq != null) message.seq = object.seq | 0;
      if (object.prop_id != null) message.prop_id = object.prop_id | 0;
      if (object.money_gt != null) message.money_gt = object.money_gt | 0;
      if (object.money_le != null) message.money_le = object.money_le | 0;
      return message;
    };

    /**
     * Creates a plain object from a SpinItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinItem
     * @static
     * @param {client_proto.SpinItem} message SpinItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.seq = 0;
        object.prop_id = 0;
        object.money_gt = 0;
        object.money_le = 0;
      }
      if (message.seq != null && message.hasOwnProperty("seq")) object.seq = message.seq;
      if (message.prop_id != null && message.hasOwnProperty("prop_id")) object.prop_id = message.prop_id;
      if (message.money_gt != null && message.hasOwnProperty("money_gt")) object.money_gt = message.money_gt;
      if (message.money_le != null && message.hasOwnProperty("money_le")) object.money_le = message.money_le;
      return object;
    };

    /**
     * Converts this SpinItem to JSON.
     * @function toJSON
     * @memberof client_proto.SpinItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinItem
     * @function getTypeUrl
     * @memberof client_proto.SpinItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinItem";
    };

    return SpinItem;
  })();

  client_proto.SpinTopItem = (function () {
    /**
     * Properties of a SpinTopItem.
     * @memberof client_proto
     * @interface ISpinTopItem
     * @property {string|null} [nick_name] SpinTopItem nick_name
     * @property {number|Long|null} [amount] SpinTopItem amount
     */

    /**
     * Constructs a new SpinTopItem.
     * @memberof client_proto
     * @classdesc Represents a SpinTopItem.
     * @implements ISpinTopItem
     * @constructor
     * @param {client_proto.ISpinTopItem=} [properties] Properties to set
     */
    function SpinTopItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinTopItem nick_name.
     * @member {string} nick_name
     * @memberof client_proto.SpinTopItem
     * @instance
     */
    SpinTopItem.prototype.nick_name = "";

    /**
     * SpinTopItem amount.
     * @member {number|Long} amount
     * @memberof client_proto.SpinTopItem
     * @instance
     */
    SpinTopItem.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new SpinTopItem instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinTopItem
     * @static
     * @param {client_proto.ISpinTopItem=} [properties] Properties to set
     * @returns {client_proto.SpinTopItem} SpinTopItem instance
     */
    SpinTopItem.create = function create(properties) {
      return new SpinTopItem(properties);
    };

    /**
     * Encodes the specified SpinTopItem message. Does not implicitly {@link client_proto.SpinTopItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinTopItem
     * @static
     * @param {client_proto.ISpinTopItem} message SpinTopItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinTopItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.nick_name != null && Object.hasOwnProperty.call(message, "nick_name"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.nick_name);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.amount);
      return writer;
    };

    /**
     * Encodes the specified SpinTopItem message, length delimited. Does not implicitly {@link client_proto.SpinTopItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinTopItem
     * @static
     * @param {client_proto.ISpinTopItem} message SpinTopItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinTopItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinTopItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinTopItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinTopItem} SpinTopItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinTopItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinTopItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.nick_name = reader.string();
            break;
          }
          case 2: {
            message.amount = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinTopItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinTopItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinTopItem} SpinTopItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinTopItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinTopItem message.
     * @function verify
     * @memberof client_proto.SpinTopItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinTopItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.nick_name != null && message.hasOwnProperty("nick_name"))
        if (!$util.isString(message.nick_name)) return "nick_name: string expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      return null;
    };

    /**
     * Creates a SpinTopItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinTopItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinTopItem} SpinTopItem
     */
    SpinTopItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinTopItem) return object;
      var message = new $root.client_proto.SpinTopItem();
      if (object.nick_name != null) message.nick_name = String(object.nick_name);
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a SpinTopItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinTopItem
     * @static
     * @param {client_proto.SpinTopItem} message SpinTopItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinTopItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.nick_name = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
      }
      if (message.nick_name != null && message.hasOwnProperty("nick_name")) object.nick_name = message.nick_name;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      return object;
    };

    /**
     * Converts this SpinTopItem to JSON.
     * @function toJSON
     * @memberof client_proto.SpinTopItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinTopItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinTopItem
     * @function getTypeUrl
     * @memberof client_proto.SpinTopItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinTopItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinTopItem";
    };

    return SpinTopItem;
  })();

  client_proto.SpinInfoReq = (function () {
    /**
     * Properties of a SpinInfoReq.
     * @memberof client_proto
     * @interface ISpinInfoReq
     * @property {number|Long|null} [uid] SpinInfoReq uid
     */

    /**
     * Constructs a new SpinInfoReq.
     * @memberof client_proto
     * @classdesc Represents a SpinInfoReq.
     * @implements ISpinInfoReq
     * @constructor
     * @param {client_proto.ISpinInfoReq=} [properties] Properties to set
     */
    function SpinInfoReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinInfoReq uid.
     * @member {number|Long} uid
     * @memberof client_proto.SpinInfoReq
     * @instance
     */
    SpinInfoReq.prototype.uid = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new SpinInfoReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinInfoReq
     * @static
     * @param {client_proto.ISpinInfoReq=} [properties] Properties to set
     * @returns {client_proto.SpinInfoReq} SpinInfoReq instance
     */
    SpinInfoReq.create = function create(properties) {
      return new SpinInfoReq(properties);
    };

    /**
     * Encodes the specified SpinInfoReq message. Does not implicitly {@link client_proto.SpinInfoReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinInfoReq
     * @static
     * @param {client_proto.ISpinInfoReq} message SpinInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinInfoReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.uid);
      return writer;
    };

    /**
     * Encodes the specified SpinInfoReq message, length delimited. Does not implicitly {@link client_proto.SpinInfoReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinInfoReq
     * @static
     * @param {client_proto.ISpinInfoReq} message SpinInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinInfoReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinInfoReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinInfoReq} SpinInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinInfoReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinInfoReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinInfoReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinInfoReq} SpinInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinInfoReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinInfoReq message.
     * @function verify
     * @memberof client_proto.SpinInfoReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinInfoReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (
          !$util.isInteger(message.uid) &&
          !(message.uid && $util.isInteger(message.uid.low) && $util.isInteger(message.uid.high))
        )
          return "uid: integer|Long expected";
      return null;
    };

    /**
     * Creates a SpinInfoReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinInfoReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinInfoReq} SpinInfoReq
     */
    SpinInfoReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinInfoReq) return object;
      var message = new $root.client_proto.SpinInfoReq();
      if (object.uid != null)
        if ($util.Long) (message.uid = $util.Long.fromValue(object.uid)).unsigned = false;
        else if (typeof object.uid === "string") message.uid = parseInt(object.uid, 10);
        else if (typeof object.uid === "number") message.uid = object.uid;
        else if (typeof object.uid === "object")
          message.uid = new $util.LongBits(object.uid.low >>> 0, object.uid.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a SpinInfoReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinInfoReq
     * @static
     * @param {client_proto.SpinInfoReq} message SpinInfoReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinInfoReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults)
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.uid = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.uid = options.longs === String ? "0" : 0;
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (typeof message.uid === "number") object.uid = options.longs === String ? String(message.uid) : message.uid;
        else
          object.uid =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.uid)
              : options.longs === Number
                ? new $util.LongBits(message.uid.low >>> 0, message.uid.high >>> 0).toNumber()
                : message.uid;
      return object;
    };

    /**
     * Converts this SpinInfoReq to JSON.
     * @function toJSON
     * @memberof client_proto.SpinInfoReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinInfoReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinInfoReq
     * @function getTypeUrl
     * @memberof client_proto.SpinInfoReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinInfoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinInfoReq";
    };

    return SpinInfoReq;
  })();

  client_proto.SpinInfoResp = (function () {
    /**
     * Properties of a SpinInfoResp.
     * @memberof client_proto
     * @interface ISpinInfoResp
     * @property {number|null} [result] SpinInfoResp result
     * @property {number|null} [tickets] SpinInfoResp tickets
     * @property {Array.<number>|null} [boxes] SpinInfoResp boxes
     * @property {number|null} [amount] SpinInfoResp amount
     * @property {number|null} [amount_full] SpinInfoResp amount_full
     * @property {number|Long|null} [end_time] SpinInfoResp end_time
     * @property {string|null} [invite_code] SpinInfoResp invite_code
     * @property {string|null} [qrcode] SpinInfoResp qrcode
     * @property {Array.<client_proto.ISpinItem>|null} [items] SpinInfoResp items
     * @property {Array.<client_proto.ISpinTopItem>|null} [top_list] SpinInfoResp top_list
     * @property {string|null} [rec_list] SpinInfoResp rec_list
     */

    /**
     * Constructs a new SpinInfoResp.
     * @memberof client_proto
     * @classdesc Represents a SpinInfoResp.
     * @implements ISpinInfoResp
     * @constructor
     * @param {client_proto.ISpinInfoResp=} [properties] Properties to set
     */
    function SpinInfoResp(properties) {
      this.boxes = [];
      this.items = [];
      this.top_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinInfoResp result.
     * @member {number} result
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.result = 0;

    /**
     * SpinInfoResp tickets.
     * @member {number} tickets
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.tickets = 0;

    /**
     * SpinInfoResp boxes.
     * @member {Array.<number>} boxes
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.boxes = $util.emptyArray;

    /**
     * SpinInfoResp amount.
     * @member {number} amount
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.amount = 0;

    /**
     * SpinInfoResp amount_full.
     * @member {number} amount_full
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.amount_full = 0;

    /**
     * SpinInfoResp end_time.
     * @member {number|Long} end_time
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.end_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SpinInfoResp invite_code.
     * @member {string} invite_code
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.invite_code = "";

    /**
     * SpinInfoResp qrcode.
     * @member {string} qrcode
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.qrcode = "";

    /**
     * SpinInfoResp items.
     * @member {Array.<client_proto.ISpinItem>} items
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.items = $util.emptyArray;

    /**
     * SpinInfoResp top_list.
     * @member {Array.<client_proto.ISpinTopItem>} top_list
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.top_list = $util.emptyArray;

    /**
     * SpinInfoResp rec_list.
     * @member {string} rec_list
     * @memberof client_proto.SpinInfoResp
     * @instance
     */
    SpinInfoResp.prototype.rec_list = "";

    /**
     * Creates a new SpinInfoResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinInfoResp
     * @static
     * @param {client_proto.ISpinInfoResp=} [properties] Properties to set
     * @returns {client_proto.SpinInfoResp} SpinInfoResp instance
     */
    SpinInfoResp.create = function create(properties) {
      return new SpinInfoResp(properties);
    };

    /**
     * Encodes the specified SpinInfoResp message. Does not implicitly {@link client_proto.SpinInfoResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinInfoResp
     * @static
     * @param {client_proto.ISpinInfoResp} message SpinInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinInfoResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.tickets);
      if (message.boxes != null && message.boxes.length) {
        writer.uint32(/* id 3, wireType 2 =*/ 26).fork();
        for (var i = 0; i < message.boxes.length; ++i) writer.int32(message.boxes[i]);
        writer.ldelim();
      }
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.amount);
      if (message.amount_full != null && Object.hasOwnProperty.call(message, "amount_full"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.amount_full);
      if (message.end_time != null && Object.hasOwnProperty.call(message, "end_time"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.end_time);
      if (message.invite_code != null && Object.hasOwnProperty.call(message, "invite_code"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.invite_code);
      if (message.qrcode != null && Object.hasOwnProperty.call(message, "qrcode"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.qrcode);
      if (message.items != null && message.items.length)
        for (var i = 0; i < message.items.length; ++i)
          $root.client_proto.SpinItem.encode(
            message.items[i],
            writer.uint32(/* id 9, wireType 2 =*/ 74).fork(),
          ).ldelim();
      if (message.top_list != null && message.top_list.length)
        for (var i = 0; i < message.top_list.length; ++i)
          $root.client_proto.SpinTopItem.encode(
            message.top_list[i],
            writer.uint32(/* id 10, wireType 2 =*/ 82).fork(),
          ).ldelim();
      if (message.rec_list != null && Object.hasOwnProperty.call(message, "rec_list"))
        writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.rec_list);
      return writer;
    };

    /**
     * Encodes the specified SpinInfoResp message, length delimited. Does not implicitly {@link client_proto.SpinInfoResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinInfoResp
     * @static
     * @param {client_proto.ISpinInfoResp} message SpinInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinInfoResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinInfoResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinInfoResp} SpinInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinInfoResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinInfoResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.tickets = reader.int32();
            break;
          }
          case 3: {
            if (!(message.boxes && message.boxes.length)) message.boxes = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.boxes.push(reader.int32());
            } else message.boxes.push(reader.int32());
            break;
          }
          case 4: {
            message.amount = reader.int32();
            break;
          }
          case 5: {
            message.amount_full = reader.int32();
            break;
          }
          case 6: {
            message.end_time = reader.int64();
            break;
          }
          case 7: {
            message.invite_code = reader.string();
            break;
          }
          case 8: {
            message.qrcode = reader.string();
            break;
          }
          case 9: {
            if (!(message.items && message.items.length)) message.items = [];
            message.items.push($root.client_proto.SpinItem.decode(reader, reader.uint32()));
            break;
          }
          case 10: {
            if (!(message.top_list && message.top_list.length)) message.top_list = [];
            message.top_list.push($root.client_proto.SpinTopItem.decode(reader, reader.uint32()));
            break;
          }
          case 11: {
            message.rec_list = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinInfoResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinInfoResp} SpinInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinInfoResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinInfoResp message.
     * @function verify
     * @memberof client_proto.SpinInfoResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinInfoResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.tickets != null && message.hasOwnProperty("tickets"))
        if (!$util.isInteger(message.tickets)) return "tickets: integer expected";
      if (message.boxes != null && message.hasOwnProperty("boxes")) {
        if (!Array.isArray(message.boxes)) return "boxes: array expected";
        for (var i = 0; i < message.boxes.length; ++i)
          if (!$util.isInteger(message.boxes[i])) return "boxes: integer[] expected";
      }
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (!$util.isInteger(message.amount)) return "amount: integer expected";
      if (message.amount_full != null && message.hasOwnProperty("amount_full"))
        if (!$util.isInteger(message.amount_full)) return "amount_full: integer expected";
      if (message.end_time != null && message.hasOwnProperty("end_time"))
        if (
          !$util.isInteger(message.end_time) &&
          !(message.end_time && $util.isInteger(message.end_time.low) && $util.isInteger(message.end_time.high))
        )
          return "end_time: integer|Long expected";
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        if (!$util.isString(message.invite_code)) return "invite_code: string expected";
      if (message.qrcode != null && message.hasOwnProperty("qrcode"))
        if (!$util.isString(message.qrcode)) return "qrcode: string expected";
      if (message.items != null && message.hasOwnProperty("items")) {
        if (!Array.isArray(message.items)) return "items: array expected";
        for (var i = 0; i < message.items.length; ++i) {
          var error = $root.client_proto.SpinItem.verify(message.items[i]);
          if (error) return "items." + error;
        }
      }
      if (message.top_list != null && message.hasOwnProperty("top_list")) {
        if (!Array.isArray(message.top_list)) return "top_list: array expected";
        for (var i = 0; i < message.top_list.length; ++i) {
          var error = $root.client_proto.SpinTopItem.verify(message.top_list[i]);
          if (error) return "top_list." + error;
        }
      }
      if (message.rec_list != null && message.hasOwnProperty("rec_list"))
        if (!$util.isString(message.rec_list)) return "rec_list: string expected";
      return null;
    };

    /**
     * Creates a SpinInfoResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinInfoResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinInfoResp} SpinInfoResp
     */
    SpinInfoResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinInfoResp) return object;
      var message = new $root.client_proto.SpinInfoResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.tickets != null) message.tickets = object.tickets | 0;
      if (object.boxes) {
        if (!Array.isArray(object.boxes)) throw TypeError(".client_proto.SpinInfoResp.boxes: array expected");
        message.boxes = [];
        for (var i = 0; i < object.boxes.length; ++i) message.boxes[i] = object.boxes[i] | 0;
      }
      if (object.amount != null) message.amount = object.amount | 0;
      if (object.amount_full != null) message.amount_full = object.amount_full | 0;
      if (object.end_time != null)
        if ($util.Long) (message.end_time = $util.Long.fromValue(object.end_time)).unsigned = false;
        else if (typeof object.end_time === "string") message.end_time = parseInt(object.end_time, 10);
        else if (typeof object.end_time === "number") message.end_time = object.end_time;
        else if (typeof object.end_time === "object")
          message.end_time = new $util.LongBits(object.end_time.low >>> 0, object.end_time.high >>> 0).toNumber();
      if (object.invite_code != null) message.invite_code = String(object.invite_code);
      if (object.qrcode != null) message.qrcode = String(object.qrcode);
      if (object.items) {
        if (!Array.isArray(object.items)) throw TypeError(".client_proto.SpinInfoResp.items: array expected");
        message.items = [];
        for (var i = 0; i < object.items.length; ++i) {
          if (typeof object.items[i] !== "object") throw TypeError(".client_proto.SpinInfoResp.items: object expected");
          message.items[i] = $root.client_proto.SpinItem.fromObject(object.items[i]);
        }
      }
      if (object.top_list) {
        if (!Array.isArray(object.top_list)) throw TypeError(".client_proto.SpinInfoResp.top_list: array expected");
        message.top_list = [];
        for (var i = 0; i < object.top_list.length; ++i) {
          if (typeof object.top_list[i] !== "object")
            throw TypeError(".client_proto.SpinInfoResp.top_list: object expected");
          message.top_list[i] = $root.client_proto.SpinTopItem.fromObject(object.top_list[i]);
        }
      }
      if (object.rec_list != null) message.rec_list = String(object.rec_list);
      return message;
    };

    /**
     * Creates a plain object from a SpinInfoResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinInfoResp
     * @static
     * @param {client_proto.SpinInfoResp} message SpinInfoResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinInfoResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) {
        object.boxes = [];
        object.items = [];
        object.top_list = [];
      }
      if (options.defaults) {
        object.result = 0;
        object.tickets = 0;
        object.amount = 0;
        object.amount_full = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.end_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.end_time = options.longs === String ? "0" : 0;
        object.invite_code = "";
        object.qrcode = "";
        object.rec_list = "";
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.tickets != null && message.hasOwnProperty("tickets")) object.tickets = message.tickets;
      if (message.boxes && message.boxes.length) {
        object.boxes = [];
        for (var j = 0; j < message.boxes.length; ++j) object.boxes[j] = message.boxes[j];
      }
      if (message.amount != null && message.hasOwnProperty("amount")) object.amount = message.amount;
      if (message.amount_full != null && message.hasOwnProperty("amount_full"))
        object.amount_full = message.amount_full;
      if (message.end_time != null && message.hasOwnProperty("end_time"))
        if (typeof message.end_time === "number")
          object.end_time = options.longs === String ? String(message.end_time) : message.end_time;
        else
          object.end_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.end_time)
              : options.longs === Number
                ? new $util.LongBits(message.end_time.low >>> 0, message.end_time.high >>> 0).toNumber()
                : message.end_time;
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        object.invite_code = message.invite_code;
      if (message.qrcode != null && message.hasOwnProperty("qrcode")) object.qrcode = message.qrcode;
      if (message.items && message.items.length) {
        object.items = [];
        for (var j = 0; j < message.items.length; ++j)
          object.items[j] = $root.client_proto.SpinItem.toObject(message.items[j], options);
      }
      if (message.top_list && message.top_list.length) {
        object.top_list = [];
        for (var j = 0; j < message.top_list.length; ++j)
          object.top_list[j] = $root.client_proto.SpinTopItem.toObject(message.top_list[j], options);
      }
      if (message.rec_list != null && message.hasOwnProperty("rec_list")) object.rec_list = message.rec_list;
      return object;
    };

    /**
     * Converts this SpinInfoResp to JSON.
     * @function toJSON
     * @memberof client_proto.SpinInfoResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinInfoResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinInfoResp
     * @function getTypeUrl
     * @memberof client_proto.SpinInfoResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinInfoResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinInfoResp";
    };

    return SpinInfoResp;
  })();

  client_proto.SpinReq = (function () {
    /**
     * Properties of a SpinReq.
     * @memberof client_proto
     * @interface ISpinReq
     * @property {number|Long|null} [uid] SpinReq uid
     */

    /**
     * Constructs a new SpinReq.
     * @memberof client_proto
     * @classdesc Represents a SpinReq.
     * @implements ISpinReq
     * @constructor
     * @param {client_proto.ISpinReq=} [properties] Properties to set
     */
    function SpinReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinReq uid.
     * @member {number|Long} uid
     * @memberof client_proto.SpinReq
     * @instance
     */
    SpinReq.prototype.uid = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new SpinReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinReq
     * @static
     * @param {client_proto.ISpinReq=} [properties] Properties to set
     * @returns {client_proto.SpinReq} SpinReq instance
     */
    SpinReq.create = function create(properties) {
      return new SpinReq(properties);
    };

    /**
     * Encodes the specified SpinReq message. Does not implicitly {@link client_proto.SpinReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinReq
     * @static
     * @param {client_proto.ISpinReq} message SpinReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.uid);
      return writer;
    };

    /**
     * Encodes the specified SpinReq message, length delimited. Does not implicitly {@link client_proto.SpinReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinReq
     * @static
     * @param {client_proto.ISpinReq} message SpinReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinReq} SpinReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinReq} SpinReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinReq message.
     * @function verify
     * @memberof client_proto.SpinReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (
          !$util.isInteger(message.uid) &&
          !(message.uid && $util.isInteger(message.uid.low) && $util.isInteger(message.uid.high))
        )
          return "uid: integer|Long expected";
      return null;
    };

    /**
     * Creates a SpinReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinReq} SpinReq
     */
    SpinReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinReq) return object;
      var message = new $root.client_proto.SpinReq();
      if (object.uid != null)
        if ($util.Long) (message.uid = $util.Long.fromValue(object.uid)).unsigned = false;
        else if (typeof object.uid === "string") message.uid = parseInt(object.uid, 10);
        else if (typeof object.uid === "number") message.uid = object.uid;
        else if (typeof object.uid === "object")
          message.uid = new $util.LongBits(object.uid.low >>> 0, object.uid.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a SpinReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinReq
     * @static
     * @param {client_proto.SpinReq} message SpinReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults)
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.uid = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.uid = options.longs === String ? "0" : 0;
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (typeof message.uid === "number") object.uid = options.longs === String ? String(message.uid) : message.uid;
        else
          object.uid =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.uid)
              : options.longs === Number
                ? new $util.LongBits(message.uid.low >>> 0, message.uid.high >>> 0).toNumber()
                : message.uid;
      return object;
    };

    /**
     * Converts this SpinReq to JSON.
     * @function toJSON
     * @memberof client_proto.SpinReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinReq
     * @function getTypeUrl
     * @memberof client_proto.SpinReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinReq";
    };

    return SpinReq;
  })();

  client_proto.SpinResp = (function () {
    /**
     * Properties of a SpinResp.
     * @memberof client_proto
     * @interface ISpinResp
     * @property {number|null} [result] SpinResp result
     * @property {number|null} [pos] SpinResp pos
     * @property {number|null} [tickets] SpinResp tickets
     * @property {number|null} [amount] SpinResp amount
     */

    /**
     * Constructs a new SpinResp.
     * @memberof client_proto
     * @classdesc Represents a SpinResp.
     * @implements ISpinResp
     * @constructor
     * @param {client_proto.ISpinResp=} [properties] Properties to set
     */
    function SpinResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinResp result.
     * @member {number} result
     * @memberof client_proto.SpinResp
     * @instance
     */
    SpinResp.prototype.result = 0;

    /**
     * SpinResp pos.
     * @member {number} pos
     * @memberof client_proto.SpinResp
     * @instance
     */
    SpinResp.prototype.pos = 0;

    /**
     * SpinResp tickets.
     * @member {number} tickets
     * @memberof client_proto.SpinResp
     * @instance
     */
    SpinResp.prototype.tickets = 0;

    /**
     * SpinResp amount.
     * @member {number} amount
     * @memberof client_proto.SpinResp
     * @instance
     */
    SpinResp.prototype.amount = 0;

    /**
     * Creates a new SpinResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinResp
     * @static
     * @param {client_proto.ISpinResp=} [properties] Properties to set
     * @returns {client_proto.SpinResp} SpinResp instance
     */
    SpinResp.create = function create(properties) {
      return new SpinResp(properties);
    };

    /**
     * Encodes the specified SpinResp message. Does not implicitly {@link client_proto.SpinResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinResp
     * @static
     * @param {client_proto.ISpinResp} message SpinResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.pos != null && Object.hasOwnProperty.call(message, "pos"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.pos);
      if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.tickets);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.amount);
      return writer;
    };

    /**
     * Encodes the specified SpinResp message, length delimited. Does not implicitly {@link client_proto.SpinResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinResp
     * @static
     * @param {client_proto.ISpinResp} message SpinResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinResp} SpinResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.pos = reader.int32();
            break;
          }
          case 3: {
            message.tickets = reader.int32();
            break;
          }
          case 4: {
            message.amount = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinResp} SpinResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinResp message.
     * @function verify
     * @memberof client_proto.SpinResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.pos != null && message.hasOwnProperty("pos"))
        if (!$util.isInteger(message.pos)) return "pos: integer expected";
      if (message.tickets != null && message.hasOwnProperty("tickets"))
        if (!$util.isInteger(message.tickets)) return "tickets: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (!$util.isInteger(message.amount)) return "amount: integer expected";
      return null;
    };

    /**
     * Creates a SpinResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinResp} SpinResp
     */
    SpinResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinResp) return object;
      var message = new $root.client_proto.SpinResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.pos != null) message.pos = object.pos | 0;
      if (object.tickets != null) message.tickets = object.tickets | 0;
      if (object.amount != null) message.amount = object.amount | 0;
      return message;
    };

    /**
     * Creates a plain object from a SpinResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinResp
     * @static
     * @param {client_proto.SpinResp} message SpinResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.pos = 0;
        object.tickets = 0;
        object.amount = 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.pos != null && message.hasOwnProperty("pos")) object.pos = message.pos;
      if (message.tickets != null && message.hasOwnProperty("tickets")) object.tickets = message.tickets;
      if (message.amount != null && message.hasOwnProperty("amount")) object.amount = message.amount;
      return object;
    };

    /**
     * Converts this SpinResp to JSON.
     * @function toJSON
     * @memberof client_proto.SpinResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinResp
     * @function getTypeUrl
     * @memberof client_proto.SpinResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinResp";
    };

    return SpinResp;
  })();

  client_proto.SpinWithdrawReq = (function () {
    /**
     * Properties of a SpinWithdrawReq.
     * @memberof client_proto
     * @interface ISpinWithdrawReq
     */

    /**
     * Constructs a new SpinWithdrawReq.
     * @memberof client_proto
     * @classdesc Represents a SpinWithdrawReq.
     * @implements ISpinWithdrawReq
     * @constructor
     * @param {client_proto.ISpinWithdrawReq=} [properties] Properties to set
     */
    function SpinWithdrawReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new SpinWithdrawReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinWithdrawReq
     * @static
     * @param {client_proto.ISpinWithdrawReq=} [properties] Properties to set
     * @returns {client_proto.SpinWithdrawReq} SpinWithdrawReq instance
     */
    SpinWithdrawReq.create = function create(properties) {
      return new SpinWithdrawReq(properties);
    };

    /**
     * Encodes the specified SpinWithdrawReq message. Does not implicitly {@link client_proto.SpinWithdrawReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinWithdrawReq
     * @static
     * @param {client_proto.ISpinWithdrawReq} message SpinWithdrawReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinWithdrawReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified SpinWithdrawReq message, length delimited. Does not implicitly {@link client_proto.SpinWithdrawReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinWithdrawReq
     * @static
     * @param {client_proto.ISpinWithdrawReq} message SpinWithdrawReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinWithdrawReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinWithdrawReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinWithdrawReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinWithdrawReq} SpinWithdrawReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinWithdrawReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinWithdrawReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinWithdrawReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinWithdrawReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinWithdrawReq} SpinWithdrawReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinWithdrawReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinWithdrawReq message.
     * @function verify
     * @memberof client_proto.SpinWithdrawReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinWithdrawReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a SpinWithdrawReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinWithdrawReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinWithdrawReq} SpinWithdrawReq
     */
    SpinWithdrawReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinWithdrawReq) return object;
      return new $root.client_proto.SpinWithdrawReq();
    };

    /**
     * Creates a plain object from a SpinWithdrawReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinWithdrawReq
     * @static
     * @param {client_proto.SpinWithdrawReq} message SpinWithdrawReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinWithdrawReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this SpinWithdrawReq to JSON.
     * @function toJSON
     * @memberof client_proto.SpinWithdrawReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinWithdrawReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinWithdrawReq
     * @function getTypeUrl
     * @memberof client_proto.SpinWithdrawReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinWithdrawReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinWithdrawReq";
    };

    return SpinWithdrawReq;
  })();

  client_proto.SpinWithdrawResp = (function () {
    /**
     * Properties of a SpinWithdrawResp.
     * @memberof client_proto
     * @interface ISpinWithdrawResp
     * @property {number|null} [result] SpinWithdrawResp result
     */

    /**
     * Constructs a new SpinWithdrawResp.
     * @memberof client_proto
     * @classdesc Represents a SpinWithdrawResp.
     * @implements ISpinWithdrawResp
     * @constructor
     * @param {client_proto.ISpinWithdrawResp=} [properties] Properties to set
     */
    function SpinWithdrawResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinWithdrawResp result.
     * @member {number} result
     * @memberof client_proto.SpinWithdrawResp
     * @instance
     */
    SpinWithdrawResp.prototype.result = 0;

    /**
     * Creates a new SpinWithdrawResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinWithdrawResp
     * @static
     * @param {client_proto.ISpinWithdrawResp=} [properties] Properties to set
     * @returns {client_proto.SpinWithdrawResp} SpinWithdrawResp instance
     */
    SpinWithdrawResp.create = function create(properties) {
      return new SpinWithdrawResp(properties);
    };

    /**
     * Encodes the specified SpinWithdrawResp message. Does not implicitly {@link client_proto.SpinWithdrawResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinWithdrawResp
     * @static
     * @param {client_proto.ISpinWithdrawResp} message SpinWithdrawResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinWithdrawResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified SpinWithdrawResp message, length delimited. Does not implicitly {@link client_proto.SpinWithdrawResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinWithdrawResp
     * @static
     * @param {client_proto.ISpinWithdrawResp} message SpinWithdrawResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinWithdrawResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinWithdrawResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinWithdrawResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinWithdrawResp} SpinWithdrawResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinWithdrawResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinWithdrawResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinWithdrawResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinWithdrawResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinWithdrawResp} SpinWithdrawResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinWithdrawResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinWithdrawResp message.
     * @function verify
     * @memberof client_proto.SpinWithdrawResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinWithdrawResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a SpinWithdrawResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinWithdrawResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinWithdrawResp} SpinWithdrawResp
     */
    SpinWithdrawResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinWithdrawResp) return object;
      var message = new $root.client_proto.SpinWithdrawResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a SpinWithdrawResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinWithdrawResp
     * @static
     * @param {client_proto.SpinWithdrawResp} message SpinWithdrawResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinWithdrawResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this SpinWithdrawResp to JSON.
     * @function toJSON
     * @memberof client_proto.SpinWithdrawResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinWithdrawResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinWithdrawResp
     * @function getTypeUrl
     * @memberof client_proto.SpinWithdrawResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinWithdrawResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinWithdrawResp";
    };

    return SpinWithdrawResp;
  })();

  client_proto.SpinOrderLogReq = (function () {
    /**
     * Properties of a SpinOrderLogReq.
     * @memberof client_proto
     * @interface ISpinOrderLogReq
     * @property {number|null} [page_num] SpinOrderLogReq page_num
     * @property {number|null} [page_size] SpinOrderLogReq page_size
     */

    /**
     * Constructs a new SpinOrderLogReq.
     * @memberof client_proto
     * @classdesc Represents a SpinOrderLogReq.
     * @implements ISpinOrderLogReq
     * @constructor
     * @param {client_proto.ISpinOrderLogReq=} [properties] Properties to set
     */
    function SpinOrderLogReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinOrderLogReq page_num.
     * @member {number} page_num
     * @memberof client_proto.SpinOrderLogReq
     * @instance
     */
    SpinOrderLogReq.prototype.page_num = 0;

    /**
     * SpinOrderLogReq page_size.
     * @member {number} page_size
     * @memberof client_proto.SpinOrderLogReq
     * @instance
     */
    SpinOrderLogReq.prototype.page_size = 0;

    /**
     * Creates a new SpinOrderLogReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinOrderLogReq
     * @static
     * @param {client_proto.ISpinOrderLogReq=} [properties] Properties to set
     * @returns {client_proto.SpinOrderLogReq} SpinOrderLogReq instance
     */
    SpinOrderLogReq.create = function create(properties) {
      return new SpinOrderLogReq(properties);
    };

    /**
     * Encodes the specified SpinOrderLogReq message. Does not implicitly {@link client_proto.SpinOrderLogReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinOrderLogReq
     * @static
     * @param {client_proto.ISpinOrderLogReq} message SpinOrderLogReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinOrderLogReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.page_num != null && Object.hasOwnProperty.call(message, "page_num"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.page_num);
      if (message.page_size != null && Object.hasOwnProperty.call(message, "page_size"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.page_size);
      return writer;
    };

    /**
     * Encodes the specified SpinOrderLogReq message, length delimited. Does not implicitly {@link client_proto.SpinOrderLogReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinOrderLogReq
     * @static
     * @param {client_proto.ISpinOrderLogReq} message SpinOrderLogReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinOrderLogReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinOrderLogReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinOrderLogReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinOrderLogReq} SpinOrderLogReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinOrderLogReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinOrderLogReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.page_num = reader.int32();
            break;
          }
          case 2: {
            message.page_size = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinOrderLogReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinOrderLogReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinOrderLogReq} SpinOrderLogReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinOrderLogReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinOrderLogReq message.
     * @function verify
     * @memberof client_proto.SpinOrderLogReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinOrderLogReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.page_num != null && message.hasOwnProperty("page_num"))
        if (!$util.isInteger(message.page_num)) return "page_num: integer expected";
      if (message.page_size != null && message.hasOwnProperty("page_size"))
        if (!$util.isInteger(message.page_size)) return "page_size: integer expected";
      return null;
    };

    /**
     * Creates a SpinOrderLogReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinOrderLogReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinOrderLogReq} SpinOrderLogReq
     */
    SpinOrderLogReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinOrderLogReq) return object;
      var message = new $root.client_proto.SpinOrderLogReq();
      if (object.page_num != null) message.page_num = object.page_num | 0;
      if (object.page_size != null) message.page_size = object.page_size | 0;
      return message;
    };

    /**
     * Creates a plain object from a SpinOrderLogReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinOrderLogReq
     * @static
     * @param {client_proto.SpinOrderLogReq} message SpinOrderLogReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinOrderLogReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.page_num = 0;
        object.page_size = 0;
      }
      if (message.page_num != null && message.hasOwnProperty("page_num")) object.page_num = message.page_num;
      if (message.page_size != null && message.hasOwnProperty("page_size")) object.page_size = message.page_size;
      return object;
    };

    /**
     * Converts this SpinOrderLogReq to JSON.
     * @function toJSON
     * @memberof client_proto.SpinOrderLogReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinOrderLogReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinOrderLogReq
     * @function getTypeUrl
     * @memberof client_proto.SpinOrderLogReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinOrderLogReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinOrderLogReq";
    };

    return SpinOrderLogReq;
  })();

  client_proto.SpinOrderItem = (function () {
    /**
     * Properties of a SpinOrderItem.
     * @memberof client_proto
     * @interface ISpinOrderItem
     * @property {number|Long|null} [amount] SpinOrderItem amount
     * @property {number|null} [status] SpinOrderItem status
     * @property {string|null} [reason] SpinOrderItem reason
     * @property {number|null} [create_time] SpinOrderItem create_time
     */

    /**
     * Constructs a new SpinOrderItem.
     * @memberof client_proto
     * @classdesc Represents a SpinOrderItem.
     * @implements ISpinOrderItem
     * @constructor
     * @param {client_proto.ISpinOrderItem=} [properties] Properties to set
     */
    function SpinOrderItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinOrderItem amount.
     * @member {number|Long} amount
     * @memberof client_proto.SpinOrderItem
     * @instance
     */
    SpinOrderItem.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SpinOrderItem status.
     * @member {number} status
     * @memberof client_proto.SpinOrderItem
     * @instance
     */
    SpinOrderItem.prototype.status = 0;

    /**
     * SpinOrderItem reason.
     * @member {string} reason
     * @memberof client_proto.SpinOrderItem
     * @instance
     */
    SpinOrderItem.prototype.reason = "";

    /**
     * SpinOrderItem create_time.
     * @member {number} create_time
     * @memberof client_proto.SpinOrderItem
     * @instance
     */
    SpinOrderItem.prototype.create_time = 0;

    /**
     * Creates a new SpinOrderItem instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinOrderItem
     * @static
     * @param {client_proto.ISpinOrderItem=} [properties] Properties to set
     * @returns {client_proto.SpinOrderItem} SpinOrderItem instance
     */
    SpinOrderItem.create = function create(properties) {
      return new SpinOrderItem(properties);
    };

    /**
     * Encodes the specified SpinOrderItem message. Does not implicitly {@link client_proto.SpinOrderItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinOrderItem
     * @static
     * @param {client_proto.ISpinOrderItem} message SpinOrderItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinOrderItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.amount);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.status);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.reason);
      if (message.create_time != null && Object.hasOwnProperty.call(message, "create_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.create_time);
      return writer;
    };

    /**
     * Encodes the specified SpinOrderItem message, length delimited. Does not implicitly {@link client_proto.SpinOrderItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinOrderItem
     * @static
     * @param {client_proto.ISpinOrderItem} message SpinOrderItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinOrderItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinOrderItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinOrderItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinOrderItem} SpinOrderItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinOrderItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinOrderItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.amount = reader.int64();
            break;
          }
          case 2: {
            message.status = reader.int32();
            break;
          }
          case 3: {
            message.reason = reader.string();
            break;
          }
          case 4: {
            message.create_time = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinOrderItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinOrderItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinOrderItem} SpinOrderItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinOrderItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinOrderItem message.
     * @function verify
     * @memberof client_proto.SpinOrderItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinOrderItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      if (message.reason != null && message.hasOwnProperty("reason"))
        if (!$util.isString(message.reason)) return "reason: string expected";
      if (message.create_time != null && message.hasOwnProperty("create_time"))
        if (!$util.isInteger(message.create_time)) return "create_time: integer expected";
      return null;
    };

    /**
     * Creates a SpinOrderItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinOrderItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinOrderItem} SpinOrderItem
     */
    SpinOrderItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinOrderItem) return object;
      var message = new $root.client_proto.SpinOrderItem();
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.status != null) message.status = object.status | 0;
      if (object.reason != null) message.reason = String(object.reason);
      if (object.create_time != null) message.create_time = object.create_time | 0;
      return message;
    };

    /**
     * Creates a plain object from a SpinOrderItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinOrderItem
     * @static
     * @param {client_proto.SpinOrderItem} message SpinOrderItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinOrderItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        object.status = 0;
        object.reason = "";
        object.create_time = 0;
      }
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      if (message.reason != null && message.hasOwnProperty("reason")) object.reason = message.reason;
      if (message.create_time != null && message.hasOwnProperty("create_time"))
        object.create_time = message.create_time;
      return object;
    };

    /**
     * Converts this SpinOrderItem to JSON.
     * @function toJSON
     * @memberof client_proto.SpinOrderItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinOrderItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinOrderItem
     * @function getTypeUrl
     * @memberof client_proto.SpinOrderItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinOrderItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinOrderItem";
    };

    return SpinOrderItem;
  })();

  client_proto.SpinOrderLogResp = (function () {
    /**
     * Properties of a SpinOrderLogResp.
     * @memberof client_proto
     * @interface ISpinOrderLogResp
     * @property {number|null} [page_num] SpinOrderLogResp page_num
     * @property {Array.<client_proto.ISpinOrderItem>|null} [list] SpinOrderLogResp list
     */

    /**
     * Constructs a new SpinOrderLogResp.
     * @memberof client_proto
     * @classdesc Represents a SpinOrderLogResp.
     * @implements ISpinOrderLogResp
     * @constructor
     * @param {client_proto.ISpinOrderLogResp=} [properties] Properties to set
     */
    function SpinOrderLogResp(properties) {
      this.list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinOrderLogResp page_num.
     * @member {number} page_num
     * @memberof client_proto.SpinOrderLogResp
     * @instance
     */
    SpinOrderLogResp.prototype.page_num = 0;

    /**
     * SpinOrderLogResp list.
     * @member {Array.<client_proto.ISpinOrderItem>} list
     * @memberof client_proto.SpinOrderLogResp
     * @instance
     */
    SpinOrderLogResp.prototype.list = $util.emptyArray;

    /**
     * Creates a new SpinOrderLogResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinOrderLogResp
     * @static
     * @param {client_proto.ISpinOrderLogResp=} [properties] Properties to set
     * @returns {client_proto.SpinOrderLogResp} SpinOrderLogResp instance
     */
    SpinOrderLogResp.create = function create(properties) {
      return new SpinOrderLogResp(properties);
    };

    /**
     * Encodes the specified SpinOrderLogResp message. Does not implicitly {@link client_proto.SpinOrderLogResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinOrderLogResp
     * @static
     * @param {client_proto.ISpinOrderLogResp} message SpinOrderLogResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinOrderLogResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.page_num != null && Object.hasOwnProperty.call(message, "page_num"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.page_num);
      if (message.list != null && message.list.length)
        for (var i = 0; i < message.list.length; ++i)
          $root.client_proto.SpinOrderItem.encode(
            message.list[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified SpinOrderLogResp message, length delimited. Does not implicitly {@link client_proto.SpinOrderLogResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinOrderLogResp
     * @static
     * @param {client_proto.ISpinOrderLogResp} message SpinOrderLogResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinOrderLogResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinOrderLogResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinOrderLogResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinOrderLogResp} SpinOrderLogResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinOrderLogResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinOrderLogResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.page_num = reader.int32();
            break;
          }
          case 2: {
            if (!(message.list && message.list.length)) message.list = [];
            message.list.push($root.client_proto.SpinOrderItem.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinOrderLogResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinOrderLogResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinOrderLogResp} SpinOrderLogResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinOrderLogResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinOrderLogResp message.
     * @function verify
     * @memberof client_proto.SpinOrderLogResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinOrderLogResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.page_num != null && message.hasOwnProperty("page_num"))
        if (!$util.isInteger(message.page_num)) return "page_num: integer expected";
      if (message.list != null && message.hasOwnProperty("list")) {
        if (!Array.isArray(message.list)) return "list: array expected";
        for (var i = 0; i < message.list.length; ++i) {
          var error = $root.client_proto.SpinOrderItem.verify(message.list[i]);
          if (error) return "list." + error;
        }
      }
      return null;
    };

    /**
     * Creates a SpinOrderLogResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinOrderLogResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinOrderLogResp} SpinOrderLogResp
     */
    SpinOrderLogResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinOrderLogResp) return object;
      var message = new $root.client_proto.SpinOrderLogResp();
      if (object.page_num != null) message.page_num = object.page_num | 0;
      if (object.list) {
        if (!Array.isArray(object.list)) throw TypeError(".client_proto.SpinOrderLogResp.list: array expected");
        message.list = [];
        for (var i = 0; i < object.list.length; ++i) {
          if (typeof object.list[i] !== "object")
            throw TypeError(".client_proto.SpinOrderLogResp.list: object expected");
          message.list[i] = $root.client_proto.SpinOrderItem.fromObject(object.list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a SpinOrderLogResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinOrderLogResp
     * @static
     * @param {client_proto.SpinOrderLogResp} message SpinOrderLogResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinOrderLogResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.list = [];
      if (options.defaults) object.page_num = 0;
      if (message.page_num != null && message.hasOwnProperty("page_num")) object.page_num = message.page_num;
      if (message.list && message.list.length) {
        object.list = [];
        for (var j = 0; j < message.list.length; ++j)
          object.list[j] = $root.client_proto.SpinOrderItem.toObject(message.list[j], options);
      }
      return object;
    };

    /**
     * Converts this SpinOrderLogResp to JSON.
     * @function toJSON
     * @memberof client_proto.SpinOrderLogResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinOrderLogResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinOrderLogResp
     * @function getTypeUrl
     * @memberof client_proto.SpinOrderLogResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinOrderLogResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinOrderLogResp";
    };

    return SpinOrderLogResp;
  })();

  client_proto.SpinPhpAuditOrderReq = (function () {
    /**
     * Properties of a SpinPhpAuditOrderReq.
     * @memberof client_proto
     * @interface ISpinPhpAuditOrderReq
     * @property {number|null} [order_id] SpinPhpAuditOrderReq order_id
     * @property {number|null} [result] SpinPhpAuditOrderReq result
     * @property {number|null} [audit_uid] SpinPhpAuditOrderReq audit_uid
     * @property {string|null} [audit_name] SpinPhpAuditOrderReq audit_name
     * @property {string|null} [reason] SpinPhpAuditOrderReq reason
     */

    /**
     * Constructs a new SpinPhpAuditOrderReq.
     * @memberof client_proto
     * @classdesc Represents a SpinPhpAuditOrderReq.
     * @implements ISpinPhpAuditOrderReq
     * @constructor
     * @param {client_proto.ISpinPhpAuditOrderReq=} [properties] Properties to set
     */
    function SpinPhpAuditOrderReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinPhpAuditOrderReq order_id.
     * @member {number} order_id
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @instance
     */
    SpinPhpAuditOrderReq.prototype.order_id = 0;

    /**
     * SpinPhpAuditOrderReq result.
     * @member {number} result
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @instance
     */
    SpinPhpAuditOrderReq.prototype.result = 0;

    /**
     * SpinPhpAuditOrderReq audit_uid.
     * @member {number} audit_uid
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @instance
     */
    SpinPhpAuditOrderReq.prototype.audit_uid = 0;

    /**
     * SpinPhpAuditOrderReq audit_name.
     * @member {string} audit_name
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @instance
     */
    SpinPhpAuditOrderReq.prototype.audit_name = "";

    /**
     * SpinPhpAuditOrderReq reason.
     * @member {string} reason
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @instance
     */
    SpinPhpAuditOrderReq.prototype.reason = "";

    /**
     * Creates a new SpinPhpAuditOrderReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @static
     * @param {client_proto.ISpinPhpAuditOrderReq=} [properties] Properties to set
     * @returns {client_proto.SpinPhpAuditOrderReq} SpinPhpAuditOrderReq instance
     */
    SpinPhpAuditOrderReq.create = function create(properties) {
      return new SpinPhpAuditOrderReq(properties);
    };

    /**
     * Encodes the specified SpinPhpAuditOrderReq message. Does not implicitly {@link client_proto.SpinPhpAuditOrderReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @static
     * @param {client_proto.ISpinPhpAuditOrderReq} message SpinPhpAuditOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinPhpAuditOrderReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.order_id);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.audit_uid != null && Object.hasOwnProperty.call(message, "audit_uid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.audit_uid);
      if (message.audit_name != null && Object.hasOwnProperty.call(message, "audit_name"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.audit_name);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.reason);
      return writer;
    };

    /**
     * Encodes the specified SpinPhpAuditOrderReq message, length delimited. Does not implicitly {@link client_proto.SpinPhpAuditOrderReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @static
     * @param {client_proto.ISpinPhpAuditOrderReq} message SpinPhpAuditOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinPhpAuditOrderReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinPhpAuditOrderReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinPhpAuditOrderReq} SpinPhpAuditOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinPhpAuditOrderReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinPhpAuditOrderReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.order_id = reader.int32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            message.audit_uid = reader.uint32();
            break;
          }
          case 4: {
            message.audit_name = reader.string();
            break;
          }
          case 5: {
            message.reason = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinPhpAuditOrderReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinPhpAuditOrderReq} SpinPhpAuditOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinPhpAuditOrderReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinPhpAuditOrderReq message.
     * @function verify
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinPhpAuditOrderReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.audit_uid != null && message.hasOwnProperty("audit_uid"))
        if (!$util.isInteger(message.audit_uid)) return "audit_uid: integer expected";
      if (message.audit_name != null && message.hasOwnProperty("audit_name"))
        if (!$util.isString(message.audit_name)) return "audit_name: string expected";
      if (message.reason != null && message.hasOwnProperty("reason"))
        if (!$util.isString(message.reason)) return "reason: string expected";
      return null;
    };

    /**
     * Creates a SpinPhpAuditOrderReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinPhpAuditOrderReq} SpinPhpAuditOrderReq
     */
    SpinPhpAuditOrderReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinPhpAuditOrderReq) return object;
      var message = new $root.client_proto.SpinPhpAuditOrderReq();
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.audit_uid != null) message.audit_uid = object.audit_uid >>> 0;
      if (object.audit_name != null) message.audit_name = String(object.audit_name);
      if (object.reason != null) message.reason = String(object.reason);
      return message;
    };

    /**
     * Creates a plain object from a SpinPhpAuditOrderReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @static
     * @param {client_proto.SpinPhpAuditOrderReq} message SpinPhpAuditOrderReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinPhpAuditOrderReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.order_id = 0;
        object.result = 0;
        object.audit_uid = 0;
        object.audit_name = "";
        object.reason = "";
      }
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.audit_uid != null && message.hasOwnProperty("audit_uid")) object.audit_uid = message.audit_uid;
      if (message.audit_name != null && message.hasOwnProperty("audit_name")) object.audit_name = message.audit_name;
      if (message.reason != null && message.hasOwnProperty("reason")) object.reason = message.reason;
      return object;
    };

    /**
     * Converts this SpinPhpAuditOrderReq to JSON.
     * @function toJSON
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinPhpAuditOrderReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinPhpAuditOrderReq
     * @function getTypeUrl
     * @memberof client_proto.SpinPhpAuditOrderReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinPhpAuditOrderReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinPhpAuditOrderReq";
    };

    return SpinPhpAuditOrderReq;
  })();

  client_proto.SpinPhpAuditOrderResp = (function () {
    /**
     * Properties of a SpinPhpAuditOrderResp.
     * @memberof client_proto
     * @interface ISpinPhpAuditOrderResp
     * @property {number|null} [result] SpinPhpAuditOrderResp result
     */

    /**
     * Constructs a new SpinPhpAuditOrderResp.
     * @memberof client_proto
     * @classdesc Represents a SpinPhpAuditOrderResp.
     * @implements ISpinPhpAuditOrderResp
     * @constructor
     * @param {client_proto.ISpinPhpAuditOrderResp=} [properties] Properties to set
     */
    function SpinPhpAuditOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinPhpAuditOrderResp result.
     * @member {number} result
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @instance
     */
    SpinPhpAuditOrderResp.prototype.result = 0;

    /**
     * Creates a new SpinPhpAuditOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @static
     * @param {client_proto.ISpinPhpAuditOrderResp=} [properties] Properties to set
     * @returns {client_proto.SpinPhpAuditOrderResp} SpinPhpAuditOrderResp instance
     */
    SpinPhpAuditOrderResp.create = function create(properties) {
      return new SpinPhpAuditOrderResp(properties);
    };

    /**
     * Encodes the specified SpinPhpAuditOrderResp message. Does not implicitly {@link client_proto.SpinPhpAuditOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @static
     * @param {client_proto.ISpinPhpAuditOrderResp} message SpinPhpAuditOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinPhpAuditOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified SpinPhpAuditOrderResp message, length delimited. Does not implicitly {@link client_proto.SpinPhpAuditOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @static
     * @param {client_proto.ISpinPhpAuditOrderResp} message SpinPhpAuditOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinPhpAuditOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinPhpAuditOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinPhpAuditOrderResp} SpinPhpAuditOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinPhpAuditOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinPhpAuditOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinPhpAuditOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinPhpAuditOrderResp} SpinPhpAuditOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinPhpAuditOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinPhpAuditOrderResp message.
     * @function verify
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinPhpAuditOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a SpinPhpAuditOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinPhpAuditOrderResp} SpinPhpAuditOrderResp
     */
    SpinPhpAuditOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinPhpAuditOrderResp) return object;
      var message = new $root.client_proto.SpinPhpAuditOrderResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a SpinPhpAuditOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @static
     * @param {client_proto.SpinPhpAuditOrderResp} message SpinPhpAuditOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinPhpAuditOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this SpinPhpAuditOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinPhpAuditOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinPhpAuditOrderResp
     * @function getTypeUrl
     * @memberof client_proto.SpinPhpAuditOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinPhpAuditOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinPhpAuditOrderResp";
    };

    return SpinPhpAuditOrderResp;
  })();

  client_proto.SpinGetCurDataReq = (function () {
    /**
     * Properties of a SpinGetCurDataReq.
     * @memberof client_proto
     * @interface ISpinGetCurDataReq
     */

    /**
     * Constructs a new SpinGetCurDataReq.
     * @memberof client_proto
     * @classdesc Represents a SpinGetCurDataReq.
     * @implements ISpinGetCurDataReq
     * @constructor
     * @param {client_proto.ISpinGetCurDataReq=} [properties] Properties to set
     */
    function SpinGetCurDataReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new SpinGetCurDataReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinGetCurDataReq
     * @static
     * @param {client_proto.ISpinGetCurDataReq=} [properties] Properties to set
     * @returns {client_proto.SpinGetCurDataReq} SpinGetCurDataReq instance
     */
    SpinGetCurDataReq.create = function create(properties) {
      return new SpinGetCurDataReq(properties);
    };

    /**
     * Encodes the specified SpinGetCurDataReq message. Does not implicitly {@link client_proto.SpinGetCurDataReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinGetCurDataReq
     * @static
     * @param {client_proto.ISpinGetCurDataReq} message SpinGetCurDataReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinGetCurDataReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified SpinGetCurDataReq message, length delimited. Does not implicitly {@link client_proto.SpinGetCurDataReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinGetCurDataReq
     * @static
     * @param {client_proto.ISpinGetCurDataReq} message SpinGetCurDataReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinGetCurDataReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinGetCurDataReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinGetCurDataReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinGetCurDataReq} SpinGetCurDataReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinGetCurDataReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinGetCurDataReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinGetCurDataReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinGetCurDataReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinGetCurDataReq} SpinGetCurDataReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinGetCurDataReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinGetCurDataReq message.
     * @function verify
     * @memberof client_proto.SpinGetCurDataReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinGetCurDataReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a SpinGetCurDataReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinGetCurDataReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinGetCurDataReq} SpinGetCurDataReq
     */
    SpinGetCurDataReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinGetCurDataReq) return object;
      return new $root.client_proto.SpinGetCurDataReq();
    };

    /**
     * Creates a plain object from a SpinGetCurDataReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinGetCurDataReq
     * @static
     * @param {client_proto.SpinGetCurDataReq} message SpinGetCurDataReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinGetCurDataReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this SpinGetCurDataReq to JSON.
     * @function toJSON
     * @memberof client_proto.SpinGetCurDataReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinGetCurDataReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinGetCurDataReq
     * @function getTypeUrl
     * @memberof client_proto.SpinGetCurDataReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinGetCurDataReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinGetCurDataReq";
    };

    return SpinGetCurDataReq;
  })();

  client_proto.SpinGetCurDataResp = (function () {
    /**
     * Properties of a SpinGetCurDataResp.
     * @memberof client_proto
     * @interface ISpinGetCurDataResp
     * @property {number|Long|null} [amount] SpinGetCurDataResp amount
     * @property {number|Long|null} [target] SpinGetCurDataResp target
     * @property {number|null} [tickets] SpinGetCurDataResp tickets
     */

    /**
     * Constructs a new SpinGetCurDataResp.
     * @memberof client_proto
     * @classdesc Represents a SpinGetCurDataResp.
     * @implements ISpinGetCurDataResp
     * @constructor
     * @param {client_proto.ISpinGetCurDataResp=} [properties] Properties to set
     */
    function SpinGetCurDataResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinGetCurDataResp amount.
     * @member {number|Long} amount
     * @memberof client_proto.SpinGetCurDataResp
     * @instance
     */
    SpinGetCurDataResp.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SpinGetCurDataResp target.
     * @member {number|Long} target
     * @memberof client_proto.SpinGetCurDataResp
     * @instance
     */
    SpinGetCurDataResp.prototype.target = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SpinGetCurDataResp tickets.
     * @member {number} tickets
     * @memberof client_proto.SpinGetCurDataResp
     * @instance
     */
    SpinGetCurDataResp.prototype.tickets = 0;

    /**
     * Creates a new SpinGetCurDataResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinGetCurDataResp
     * @static
     * @param {client_proto.ISpinGetCurDataResp=} [properties] Properties to set
     * @returns {client_proto.SpinGetCurDataResp} SpinGetCurDataResp instance
     */
    SpinGetCurDataResp.create = function create(properties) {
      return new SpinGetCurDataResp(properties);
    };

    /**
     * Encodes the specified SpinGetCurDataResp message. Does not implicitly {@link client_proto.SpinGetCurDataResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinGetCurDataResp
     * @static
     * @param {client_proto.ISpinGetCurDataResp} message SpinGetCurDataResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinGetCurDataResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.amount);
      if (message.target != null && Object.hasOwnProperty.call(message, "target"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.target);
      if (message.tickets != null && Object.hasOwnProperty.call(message, "tickets"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.tickets);
      return writer;
    };

    /**
     * Encodes the specified SpinGetCurDataResp message, length delimited. Does not implicitly {@link client_proto.SpinGetCurDataResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinGetCurDataResp
     * @static
     * @param {client_proto.ISpinGetCurDataResp} message SpinGetCurDataResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinGetCurDataResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinGetCurDataResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinGetCurDataResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinGetCurDataResp} SpinGetCurDataResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinGetCurDataResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinGetCurDataResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.amount = reader.int64();
            break;
          }
          case 2: {
            message.target = reader.int64();
            break;
          }
          case 3: {
            message.tickets = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinGetCurDataResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinGetCurDataResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinGetCurDataResp} SpinGetCurDataResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinGetCurDataResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinGetCurDataResp message.
     * @function verify
     * @memberof client_proto.SpinGetCurDataResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinGetCurDataResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.target != null && message.hasOwnProperty("target"))
        if (
          !$util.isInteger(message.target) &&
          !(message.target && $util.isInteger(message.target.low) && $util.isInteger(message.target.high))
        )
          return "target: integer|Long expected";
      if (message.tickets != null && message.hasOwnProperty("tickets"))
        if (!$util.isInteger(message.tickets)) return "tickets: integer expected";
      return null;
    };

    /**
     * Creates a SpinGetCurDataResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinGetCurDataResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinGetCurDataResp} SpinGetCurDataResp
     */
    SpinGetCurDataResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinGetCurDataResp) return object;
      var message = new $root.client_proto.SpinGetCurDataResp();
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.target != null)
        if ($util.Long) (message.target = $util.Long.fromValue(object.target)).unsigned = false;
        else if (typeof object.target === "string") message.target = parseInt(object.target, 10);
        else if (typeof object.target === "number") message.target = object.target;
        else if (typeof object.target === "object")
          message.target = new $util.LongBits(object.target.low >>> 0, object.target.high >>> 0).toNumber();
      if (object.tickets != null) message.tickets = object.tickets | 0;
      return message;
    };

    /**
     * Creates a plain object from a SpinGetCurDataResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinGetCurDataResp
     * @static
     * @param {client_proto.SpinGetCurDataResp} message SpinGetCurDataResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinGetCurDataResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.target =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.target = options.longs === String ? "0" : 0;
        object.tickets = 0;
      }
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.target != null && message.hasOwnProperty("target"))
        if (typeof message.target === "number")
          object.target = options.longs === String ? String(message.target) : message.target;
        else
          object.target =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.target)
              : options.longs === Number
                ? new $util.LongBits(message.target.low >>> 0, message.target.high >>> 0).toNumber()
                : message.target;
      if (message.tickets != null && message.hasOwnProperty("tickets")) object.tickets = message.tickets;
      return object;
    };

    /**
     * Converts this SpinGetCurDataResp to JSON.
     * @function toJSON
     * @memberof client_proto.SpinGetCurDataResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinGetCurDataResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinGetCurDataResp
     * @function getTypeUrl
     * @memberof client_proto.SpinGetCurDataResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinGetCurDataResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinGetCurDataResp";
    };

    return SpinGetCurDataResp;
  })();

  client_proto.SpinInviteNotify = (function () {
    /**
     * Properties of a SpinInviteNotify.
     * @memberof client_proto
     * @interface ISpinInviteNotify
     * @property {number|null} [uid] SpinInviteNotify uid
     * @property {number|null} [invite_uid] SpinInviteNotify invite_uid
     */

    /**
     * Constructs a new SpinInviteNotify.
     * @memberof client_proto
     * @classdesc Represents a SpinInviteNotify.
     * @implements ISpinInviteNotify
     * @constructor
     * @param {client_proto.ISpinInviteNotify=} [properties] Properties to set
     */
    function SpinInviteNotify(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinInviteNotify uid.
     * @member {number} uid
     * @memberof client_proto.SpinInviteNotify
     * @instance
     */
    SpinInviteNotify.prototype.uid = 0;

    /**
     * SpinInviteNotify invite_uid.
     * @member {number} invite_uid
     * @memberof client_proto.SpinInviteNotify
     * @instance
     */
    SpinInviteNotify.prototype.invite_uid = 0;

    /**
     * Creates a new SpinInviteNotify instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinInviteNotify
     * @static
     * @param {client_proto.ISpinInviteNotify=} [properties] Properties to set
     * @returns {client_proto.SpinInviteNotify} SpinInviteNotify instance
     */
    SpinInviteNotify.create = function create(properties) {
      return new SpinInviteNotify(properties);
    };

    /**
     * Encodes the specified SpinInviteNotify message. Does not implicitly {@link client_proto.SpinInviteNotify.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinInviteNotify
     * @static
     * @param {client_proto.ISpinInviteNotify} message SpinInviteNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinInviteNotify.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.invite_uid != null && Object.hasOwnProperty.call(message, "invite_uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.invite_uid);
      return writer;
    };

    /**
     * Encodes the specified SpinInviteNotify message, length delimited. Does not implicitly {@link client_proto.SpinInviteNotify.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinInviteNotify
     * @static
     * @param {client_proto.ISpinInviteNotify} message SpinInviteNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinInviteNotify.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinInviteNotify message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinInviteNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinInviteNotify} SpinInviteNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinInviteNotify.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinInviteNotify();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.invite_uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinInviteNotify message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinInviteNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinInviteNotify} SpinInviteNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinInviteNotify.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinInviteNotify message.
     * @function verify
     * @memberof client_proto.SpinInviteNotify
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinInviteNotify.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.invite_uid != null && message.hasOwnProperty("invite_uid"))
        if (!$util.isInteger(message.invite_uid)) return "invite_uid: integer expected";
      return null;
    };

    /**
     * Creates a SpinInviteNotify message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinInviteNotify
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinInviteNotify} SpinInviteNotify
     */
    SpinInviteNotify.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinInviteNotify) return object;
      var message = new $root.client_proto.SpinInviteNotify();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.invite_uid != null) message.invite_uid = object.invite_uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a SpinInviteNotify message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinInviteNotify
     * @static
     * @param {client_proto.SpinInviteNotify} message SpinInviteNotify
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinInviteNotify.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.invite_uid = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.invite_uid != null && message.hasOwnProperty("invite_uid")) object.invite_uid = message.invite_uid;
      return object;
    };

    /**
     * Converts this SpinInviteNotify to JSON.
     * @function toJSON
     * @memberof client_proto.SpinInviteNotify
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinInviteNotify.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinInviteNotify
     * @function getTypeUrl
     * @memberof client_proto.SpinInviteNotify
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinInviteNotify.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinInviteNotify";
    };

    return SpinInviteNotify;
  })();

  client_proto.SpinRecordAddPush = (function () {
    /**
     * Properties of a SpinRecordAddPush.
     * @memberof client_proto
     * @interface ISpinRecordAddPush
     * @property {number|null} [uid] SpinRecordAddPush uid
     * @property {number|Long|null} [amount] SpinRecordAddPush amount
     * @property {string|null} [name] SpinRecordAddPush name
     * @property {string|null} [avatar] SpinRecordAddPush avatar
     * @property {number|null} [time] SpinRecordAddPush time
     * @property {number|Long|null} [cur_amount] SpinRecordAddPush cur_amount
     */

    /**
     * Constructs a new SpinRecordAddPush.
     * @memberof client_proto
     * @classdesc Represents a SpinRecordAddPush.
     * @implements ISpinRecordAddPush
     * @constructor
     * @param {client_proto.ISpinRecordAddPush=} [properties] Properties to set
     */
    function SpinRecordAddPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinRecordAddPush uid.
     * @member {number} uid
     * @memberof client_proto.SpinRecordAddPush
     * @instance
     */
    SpinRecordAddPush.prototype.uid = 0;

    /**
     * SpinRecordAddPush amount.
     * @member {number|Long} amount
     * @memberof client_proto.SpinRecordAddPush
     * @instance
     */
    SpinRecordAddPush.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SpinRecordAddPush name.
     * @member {string} name
     * @memberof client_proto.SpinRecordAddPush
     * @instance
     */
    SpinRecordAddPush.prototype.name = "";

    /**
     * SpinRecordAddPush avatar.
     * @member {string} avatar
     * @memberof client_proto.SpinRecordAddPush
     * @instance
     */
    SpinRecordAddPush.prototype.avatar = "";

    /**
     * SpinRecordAddPush time.
     * @member {number} time
     * @memberof client_proto.SpinRecordAddPush
     * @instance
     */
    SpinRecordAddPush.prototype.time = 0;

    /**
     * SpinRecordAddPush cur_amount.
     * @member {number|Long} cur_amount
     * @memberof client_proto.SpinRecordAddPush
     * @instance
     */
    SpinRecordAddPush.prototype.cur_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new SpinRecordAddPush instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinRecordAddPush
     * @static
     * @param {client_proto.ISpinRecordAddPush=} [properties] Properties to set
     * @returns {client_proto.SpinRecordAddPush} SpinRecordAddPush instance
     */
    SpinRecordAddPush.create = function create(properties) {
      return new SpinRecordAddPush(properties);
    };

    /**
     * Encodes the specified SpinRecordAddPush message. Does not implicitly {@link client_proto.SpinRecordAddPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinRecordAddPush
     * @static
     * @param {client_proto.ISpinRecordAddPush} message SpinRecordAddPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinRecordAddPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.amount);
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.name);
      if (message.avatar != null && Object.hasOwnProperty.call(message, "avatar"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.avatar);
      if (message.time != null && Object.hasOwnProperty.call(message, "time"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.time);
      if (message.cur_amount != null && Object.hasOwnProperty.call(message, "cur_amount"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.cur_amount);
      return writer;
    };

    /**
     * Encodes the specified SpinRecordAddPush message, length delimited. Does not implicitly {@link client_proto.SpinRecordAddPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinRecordAddPush
     * @static
     * @param {client_proto.ISpinRecordAddPush} message SpinRecordAddPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinRecordAddPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinRecordAddPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinRecordAddPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinRecordAddPush} SpinRecordAddPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinRecordAddPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinRecordAddPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.amount = reader.int64();
            break;
          }
          case 3: {
            message.name = reader.string();
            break;
          }
          case 4: {
            message.avatar = reader.string();
            break;
          }
          case 5: {
            message.time = reader.int32();
            break;
          }
          case 6: {
            message.cur_amount = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinRecordAddPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinRecordAddPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinRecordAddPush} SpinRecordAddPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinRecordAddPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinRecordAddPush message.
     * @function verify
     * @memberof client_proto.SpinRecordAddPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinRecordAddPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      if (message.avatar != null && message.hasOwnProperty("avatar"))
        if (!$util.isString(message.avatar)) return "avatar: string expected";
      if (message.time != null && message.hasOwnProperty("time"))
        if (!$util.isInteger(message.time)) return "time: integer expected";
      if (message.cur_amount != null && message.hasOwnProperty("cur_amount"))
        if (
          !$util.isInteger(message.cur_amount) &&
          !(message.cur_amount && $util.isInteger(message.cur_amount.low) && $util.isInteger(message.cur_amount.high))
        )
          return "cur_amount: integer|Long expected";
      return null;
    };

    /**
     * Creates a SpinRecordAddPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinRecordAddPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinRecordAddPush} SpinRecordAddPush
     */
    SpinRecordAddPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinRecordAddPush) return object;
      var message = new $root.client_proto.SpinRecordAddPush();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.name != null) message.name = String(object.name);
      if (object.avatar != null) message.avatar = String(object.avatar);
      if (object.time != null) message.time = object.time | 0;
      if (object.cur_amount != null)
        if ($util.Long) (message.cur_amount = $util.Long.fromValue(object.cur_amount)).unsigned = false;
        else if (typeof object.cur_amount === "string") message.cur_amount = parseInt(object.cur_amount, 10);
        else if (typeof object.cur_amount === "number") message.cur_amount = object.cur_amount;
        else if (typeof object.cur_amount === "object")
          message.cur_amount = new $util.LongBits(object.cur_amount.low >>> 0, object.cur_amount.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a SpinRecordAddPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinRecordAddPush
     * @static
     * @param {client_proto.SpinRecordAddPush} message SpinRecordAddPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinRecordAddPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        object.name = "";
        object.avatar = "";
        object.time = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.cur_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.cur_amount = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      if (message.avatar != null && message.hasOwnProperty("avatar")) object.avatar = message.avatar;
      if (message.time != null && message.hasOwnProperty("time")) object.time = message.time;
      if (message.cur_amount != null && message.hasOwnProperty("cur_amount"))
        if (typeof message.cur_amount === "number")
          object.cur_amount = options.longs === String ? String(message.cur_amount) : message.cur_amount;
        else
          object.cur_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.cur_amount)
              : options.longs === Number
                ? new $util.LongBits(message.cur_amount.low >>> 0, message.cur_amount.high >>> 0).toNumber()
                : message.cur_amount;
      return object;
    };

    /**
     * Converts this SpinRecordAddPush to JSON.
     * @function toJSON
     * @memberof client_proto.SpinRecordAddPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinRecordAddPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinRecordAddPush
     * @function getTypeUrl
     * @memberof client_proto.SpinRecordAddPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinRecordAddPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinRecordAddPush";
    };

    return SpinRecordAddPush;
  })();

  client_proto.SpinPosterReq = (function () {
    /**
     * Properties of a SpinPosterReq.
     * @memberof client_proto
     * @interface ISpinPosterReq
     */

    /**
     * Constructs a new SpinPosterReq.
     * @memberof client_proto
     * @classdesc Represents a SpinPosterReq.
     * @implements ISpinPosterReq
     * @constructor
     * @param {client_proto.ISpinPosterReq=} [properties] Properties to set
     */
    function SpinPosterReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new SpinPosterReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinPosterReq
     * @static
     * @param {client_proto.ISpinPosterReq=} [properties] Properties to set
     * @returns {client_proto.SpinPosterReq} SpinPosterReq instance
     */
    SpinPosterReq.create = function create(properties) {
      return new SpinPosterReq(properties);
    };

    /**
     * Encodes the specified SpinPosterReq message. Does not implicitly {@link client_proto.SpinPosterReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinPosterReq
     * @static
     * @param {client_proto.ISpinPosterReq} message SpinPosterReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinPosterReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified SpinPosterReq message, length delimited. Does not implicitly {@link client_proto.SpinPosterReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinPosterReq
     * @static
     * @param {client_proto.ISpinPosterReq} message SpinPosterReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinPosterReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinPosterReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinPosterReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinPosterReq} SpinPosterReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinPosterReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinPosterReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinPosterReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinPosterReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinPosterReq} SpinPosterReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinPosterReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinPosterReq message.
     * @function verify
     * @memberof client_proto.SpinPosterReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinPosterReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a SpinPosterReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinPosterReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinPosterReq} SpinPosterReq
     */
    SpinPosterReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinPosterReq) return object;
      return new $root.client_proto.SpinPosterReq();
    };

    /**
     * Creates a plain object from a SpinPosterReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinPosterReq
     * @static
     * @param {client_proto.SpinPosterReq} message SpinPosterReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinPosterReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this SpinPosterReq to JSON.
     * @function toJSON
     * @memberof client_proto.SpinPosterReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinPosterReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinPosterReq
     * @function getTypeUrl
     * @memberof client_proto.SpinPosterReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinPosterReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinPosterReq";
    };

    return SpinPosterReq;
  })();

  client_proto.SpinPosterResp = (function () {
    /**
     * Properties of a SpinPosterResp.
     * @memberof client_proto
     * @interface ISpinPosterResp
     * @property {Array.<client_proto.SpinPosterResp.IPoster>|null} [list] SpinPosterResp list
     * @property {string|null} [share_url] SpinPosterResp share_url
     * @property {string|null} [telegram] SpinPosterResp telegram
     * @property {string|null} [whatsapp] SpinPosterResp whatsapp
     * @property {string|null} [invite_code] SpinPosterResp invite_code
     */

    /**
     * Constructs a new SpinPosterResp.
     * @memberof client_proto
     * @classdesc Represents a SpinPosterResp.
     * @implements ISpinPosterResp
     * @constructor
     * @param {client_proto.ISpinPosterResp=} [properties] Properties to set
     */
    function SpinPosterResp(properties) {
      this.list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinPosterResp list.
     * @member {Array.<client_proto.SpinPosterResp.IPoster>} list
     * @memberof client_proto.SpinPosterResp
     * @instance
     */
    SpinPosterResp.prototype.list = $util.emptyArray;

    /**
     * SpinPosterResp share_url.
     * @member {string} share_url
     * @memberof client_proto.SpinPosterResp
     * @instance
     */
    SpinPosterResp.prototype.share_url = "";

    /**
     * SpinPosterResp telegram.
     * @member {string} telegram
     * @memberof client_proto.SpinPosterResp
     * @instance
     */
    SpinPosterResp.prototype.telegram = "";

    /**
     * SpinPosterResp whatsapp.
     * @member {string} whatsapp
     * @memberof client_proto.SpinPosterResp
     * @instance
     */
    SpinPosterResp.prototype.whatsapp = "";

    /**
     * SpinPosterResp invite_code.
     * @member {string} invite_code
     * @memberof client_proto.SpinPosterResp
     * @instance
     */
    SpinPosterResp.prototype.invite_code = "";

    /**
     * Creates a new SpinPosterResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinPosterResp
     * @static
     * @param {client_proto.ISpinPosterResp=} [properties] Properties to set
     * @returns {client_proto.SpinPosterResp} SpinPosterResp instance
     */
    SpinPosterResp.create = function create(properties) {
      return new SpinPosterResp(properties);
    };

    /**
     * Encodes the specified SpinPosterResp message. Does not implicitly {@link client_proto.SpinPosterResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinPosterResp
     * @static
     * @param {client_proto.ISpinPosterResp} message SpinPosterResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinPosterResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.list != null && message.list.length)
        for (var i = 0; i < message.list.length; ++i)
          $root.client_proto.SpinPosterResp.Poster.encode(
            message.list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.share_url != null && Object.hasOwnProperty.call(message, "share_url"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.share_url);
      if (message.telegram != null && Object.hasOwnProperty.call(message, "telegram"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.telegram);
      if (message.whatsapp != null && Object.hasOwnProperty.call(message, "whatsapp"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.whatsapp);
      if (message.invite_code != null && Object.hasOwnProperty.call(message, "invite_code"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.invite_code);
      return writer;
    };

    /**
     * Encodes the specified SpinPosterResp message, length delimited. Does not implicitly {@link client_proto.SpinPosterResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinPosterResp
     * @static
     * @param {client_proto.ISpinPosterResp} message SpinPosterResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinPosterResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinPosterResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinPosterResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinPosterResp} SpinPosterResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinPosterResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinPosterResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.list && message.list.length)) message.list = [];
            message.list.push($root.client_proto.SpinPosterResp.Poster.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.share_url = reader.string();
            break;
          }
          case 3: {
            message.telegram = reader.string();
            break;
          }
          case 4: {
            message.whatsapp = reader.string();
            break;
          }
          case 5: {
            message.invite_code = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinPosterResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinPosterResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinPosterResp} SpinPosterResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinPosterResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinPosterResp message.
     * @function verify
     * @memberof client_proto.SpinPosterResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinPosterResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.list != null && message.hasOwnProperty("list")) {
        if (!Array.isArray(message.list)) return "list: array expected";
        for (var i = 0; i < message.list.length; ++i) {
          var error = $root.client_proto.SpinPosterResp.Poster.verify(message.list[i]);
          if (error) return "list." + error;
        }
      }
      if (message.share_url != null && message.hasOwnProperty("share_url"))
        if (!$util.isString(message.share_url)) return "share_url: string expected";
      if (message.telegram != null && message.hasOwnProperty("telegram"))
        if (!$util.isString(message.telegram)) return "telegram: string expected";
      if (message.whatsapp != null && message.hasOwnProperty("whatsapp"))
        if (!$util.isString(message.whatsapp)) return "whatsapp: string expected";
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        if (!$util.isString(message.invite_code)) return "invite_code: string expected";
      return null;
    };

    /**
     * Creates a SpinPosterResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinPosterResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinPosterResp} SpinPosterResp
     */
    SpinPosterResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinPosterResp) return object;
      var message = new $root.client_proto.SpinPosterResp();
      if (object.list) {
        if (!Array.isArray(object.list)) throw TypeError(".client_proto.SpinPosterResp.list: array expected");
        message.list = [];
        for (var i = 0; i < object.list.length; ++i) {
          if (typeof object.list[i] !== "object") throw TypeError(".client_proto.SpinPosterResp.list: object expected");
          message.list[i] = $root.client_proto.SpinPosterResp.Poster.fromObject(object.list[i]);
        }
      }
      if (object.share_url != null) message.share_url = String(object.share_url);
      if (object.telegram != null) message.telegram = String(object.telegram);
      if (object.whatsapp != null) message.whatsapp = String(object.whatsapp);
      if (object.invite_code != null) message.invite_code = String(object.invite_code);
      return message;
    };

    /**
     * Creates a plain object from a SpinPosterResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinPosterResp
     * @static
     * @param {client_proto.SpinPosterResp} message SpinPosterResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinPosterResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.list = [];
      if (options.defaults) {
        object.share_url = "";
        object.telegram = "";
        object.whatsapp = "";
        object.invite_code = "";
      }
      if (message.list && message.list.length) {
        object.list = [];
        for (var j = 0; j < message.list.length; ++j)
          object.list[j] = $root.client_proto.SpinPosterResp.Poster.toObject(message.list[j], options);
      }
      if (message.share_url != null && message.hasOwnProperty("share_url")) object.share_url = message.share_url;
      if (message.telegram != null && message.hasOwnProperty("telegram")) object.telegram = message.telegram;
      if (message.whatsapp != null && message.hasOwnProperty("whatsapp")) object.whatsapp = message.whatsapp;
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        object.invite_code = message.invite_code;
      return object;
    };

    /**
     * Converts this SpinPosterResp to JSON.
     * @function toJSON
     * @memberof client_proto.SpinPosterResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinPosterResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinPosterResp
     * @function getTypeUrl
     * @memberof client_proto.SpinPosterResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinPosterResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinPosterResp";
    };

    SpinPosterResp.Poster = (function () {
      /**
       * Properties of a Poster.
       * @memberof client_proto.SpinPosterResp
       * @interface IPoster
       * @property {number|null} [id] Poster id
       * @property {number|null} [type] Poster type
       * @property {string|null} [title] Poster title
       * @property {string|null} [url] Poster url
       */

      /**
       * Constructs a new Poster.
       * @memberof client_proto.SpinPosterResp
       * @classdesc Represents a Poster.
       * @implements IPoster
       * @constructor
       * @param {client_proto.SpinPosterResp.IPoster=} [properties] Properties to set
       */
      function Poster(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }

      /**
       * Poster id.
       * @member {number} id
       * @memberof client_proto.SpinPosterResp.Poster
       * @instance
       */
      Poster.prototype.id = 0;

      /**
       * Poster type.
       * @member {number} type
       * @memberof client_proto.SpinPosterResp.Poster
       * @instance
       */
      Poster.prototype.type = 0;

      /**
       * Poster title.
       * @member {string} title
       * @memberof client_proto.SpinPosterResp.Poster
       * @instance
       */
      Poster.prototype.title = "";

      /**
       * Poster url.
       * @member {string} url
       * @memberof client_proto.SpinPosterResp.Poster
       * @instance
       */
      Poster.prototype.url = "";

      /**
       * Creates a new Poster instance using the specified properties.
       * @function create
       * @memberof client_proto.SpinPosterResp.Poster
       * @static
       * @param {client_proto.SpinPosterResp.IPoster=} [properties] Properties to set
       * @returns {client_proto.SpinPosterResp.Poster} Poster instance
       */
      Poster.create = function create(properties) {
        return new Poster(properties);
      };

      /**
       * Encodes the specified Poster message. Does not implicitly {@link client_proto.SpinPosterResp.Poster.verify|verify} messages.
       * @function encode
       * @memberof client_proto.SpinPosterResp.Poster
       * @static
       * @param {client_proto.SpinPosterResp.IPoster} message Poster message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Poster.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
          writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
        if (message.type != null && Object.hasOwnProperty.call(message, "type"))
          writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.type);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
          writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.title);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
          writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.url);
        return writer;
      };

      /**
       * Encodes the specified Poster message, length delimited. Does not implicitly {@link client_proto.SpinPosterResp.Poster.verify|verify} messages.
       * @function encodeDelimited
       * @memberof client_proto.SpinPosterResp.Poster
       * @static
       * @param {client_proto.SpinPosterResp.IPoster} message Poster message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Poster.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a Poster message from the specified reader or buffer.
       * @function decode
       * @memberof client_proto.SpinPosterResp.Poster
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {client_proto.SpinPosterResp.Poster} Poster
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Poster.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.client_proto.SpinPosterResp.Poster();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.id = reader.int32();
              break;
            }
            case 2: {
              message.type = reader.int32();
              break;
            }
            case 3: {
              message.title = reader.string();
              break;
            }
            case 4: {
              message.url = reader.string();
              break;
            }
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a Poster message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof client_proto.SpinPosterResp.Poster
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {client_proto.SpinPosterResp.Poster} Poster
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Poster.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a Poster message.
       * @function verify
       * @memberof client_proto.SpinPosterResp.Poster
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      Poster.verify = function verify(message) {
        if (typeof message !== "object" || message === null) return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
          if (!$util.isInteger(message.id)) return "id: integer expected";
        if (message.type != null && message.hasOwnProperty("type"))
          if (!$util.isInteger(message.type)) return "type: integer expected";
        if (message.title != null && message.hasOwnProperty("title"))
          if (!$util.isString(message.title)) return "title: string expected";
        if (message.url != null && message.hasOwnProperty("url"))
          if (!$util.isString(message.url)) return "url: string expected";
        return null;
      };

      /**
       * Creates a Poster message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof client_proto.SpinPosterResp.Poster
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {client_proto.SpinPosterResp.Poster} Poster
       */
      Poster.fromObject = function fromObject(object) {
        if (object instanceof $root.client_proto.SpinPosterResp.Poster) return object;
        var message = new $root.client_proto.SpinPosterResp.Poster();
        if (object.id != null) message.id = object.id | 0;
        if (object.type != null) message.type = object.type | 0;
        if (object.title != null) message.title = String(object.title);
        if (object.url != null) message.url = String(object.url);
        return message;
      };

      /**
       * Creates a plain object from a Poster message. Also converts values to other types if specified.
       * @function toObject
       * @memberof client_proto.SpinPosterResp.Poster
       * @static
       * @param {client_proto.SpinPosterResp.Poster} message Poster
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      Poster.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.id = 0;
          object.type = 0;
          object.title = "";
          object.url = "";
        }
        if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
        if (message.type != null && message.hasOwnProperty("type")) object.type = message.type;
        if (message.title != null && message.hasOwnProperty("title")) object.title = message.title;
        if (message.url != null && message.hasOwnProperty("url")) object.url = message.url;
        return object;
      };

      /**
       * Converts this Poster to JSON.
       * @function toJSON
       * @memberof client_proto.SpinPosterResp.Poster
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      Poster.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for Poster
       * @function getTypeUrl
       * @memberof client_proto.SpinPosterResp.Poster
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      Poster.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/client_proto.SpinPosterResp.Poster";
      };

      return Poster;
    })();

    return SpinPosterResp;
  })();

  /**
   * VIP_INFO_SUB_MSG_ID enum.
   * @name client_proto.VIP_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} VIPMI_NULL=0 VIPMI_NULL value
   * @property {number} VIPMI_VIP_MSG_ID_MIN=1250 VIPMI_VIP_MSG_ID_MIN value
   * @property {number} VIPMI_VIP_GET_INFO_REQ=1251 VIPMI_VIP_GET_INFO_REQ value
   * @property {number} VIPMI_VIP_GET_INFO_RESP=1252 VIPMI_VIP_GET_INFO_RESP value
   * @property {number} VIPMI_VIP_GET_PRIVILEGE_INFO_REQ=1254 VIPMI_VIP_GET_PRIVILEGE_INFO_REQ value
   * @property {number} VIPMI_VIP_SET_PRIVILEGE_INFO_RESP=1255 VIPMI_VIP_SET_PRIVILEGE_INFO_RESP value
   * @property {number} VIPMI_VIP_GET_DETAIL_LOG_REQ=1256 VIPMI_VIP_GET_DETAIL_LOG_REQ value
   * @property {number} VIPMI_VIP_GET_DETAIL_LOG_RESP=1257 VIPMI_VIP_GET_DETAIL_LOG_RESP value
   * @property {number} VIPMI_GET_LEVEL_DESC_REQ=1258 VIPMI_GET_LEVEL_DESC_REQ value
   * @property {number} VIPMI_GET_LEVEL_DESC_RESP=1259 VIPMI_GET_LEVEL_DESC_RESP value
   * @property {number} VIPMI_VIP_LEVEL_CHANGE_PUSH=1260 VIPMI_VIP_LEVEL_CHANGE_PUSH value
   * @property {number} VIPMI_GET_USER_VIP_REQ=1261 VIPMI_GET_USER_VIP_REQ value
   * @property {number} VIPMI_GET_USER_VIP_RESP=1262 VIPMI_GET_USER_VIP_RESP value
   * @property {number} VIPMI_PHP_UPDATE_VIP_SCORE_REQ=1263 VIPMI_PHP_UPDATE_VIP_SCORE_REQ value
   * @property {number} VIPMI_PHP_UPDATE_VIP_SCORE_RESP=1264 VIPMI_PHP_UPDATE_VIP_SCORE_RESP value
   * @property {number} VIPMI_VIP_MSG_ID_MAX=1300 VIPMI_VIP_MSG_ID_MAX value
   */
  client_proto.VIP_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "VIPMI_NULL")] = 0;
    values[(valuesById[1250] = "VIPMI_VIP_MSG_ID_MIN")] = 1250;
    values[(valuesById[1251] = "VIPMI_VIP_GET_INFO_REQ")] = 1251;
    values[(valuesById[1252] = "VIPMI_VIP_GET_INFO_RESP")] = 1252;
    values[(valuesById[1254] = "VIPMI_VIP_GET_PRIVILEGE_INFO_REQ")] = 1254;
    values[(valuesById[1255] = "VIPMI_VIP_SET_PRIVILEGE_INFO_RESP")] = 1255;
    values[(valuesById[1256] = "VIPMI_VIP_GET_DETAIL_LOG_REQ")] = 1256;
    values[(valuesById[1257] = "VIPMI_VIP_GET_DETAIL_LOG_RESP")] = 1257;
    values[(valuesById[1258] = "VIPMI_GET_LEVEL_DESC_REQ")] = 1258;
    values[(valuesById[1259] = "VIPMI_GET_LEVEL_DESC_RESP")] = 1259;
    values[(valuesById[1260] = "VIPMI_VIP_LEVEL_CHANGE_PUSH")] = 1260;
    values[(valuesById[1261] = "VIPMI_GET_USER_VIP_REQ")] = 1261;
    values[(valuesById[1262] = "VIPMI_GET_USER_VIP_RESP")] = 1262;
    values[(valuesById[1263] = "VIPMI_PHP_UPDATE_VIP_SCORE_REQ")] = 1263;
    values[(valuesById[1264] = "VIPMI_PHP_UPDATE_VIP_SCORE_RESP")] = 1264;
    values[(valuesById[1300] = "VIPMI_VIP_MSG_ID_MAX")] = 1300;
    return values;
  })();

  client_proto.GetVipConfigInfoReq = (function () {
    /**
     * Properties of a GetVipConfigInfoReq.
     * @memberof client_proto
     * @interface IGetVipConfigInfoReq
     * @property {number|null} [user_id] GetVipConfigInfoReq user_id
     */

    /**
     * Constructs a new GetVipConfigInfoReq.
     * @memberof client_proto
     * @classdesc Represents a GetVipConfigInfoReq.
     * @implements IGetVipConfigInfoReq
     * @constructor
     * @param {client_proto.IGetVipConfigInfoReq=} [properties] Properties to set
     */
    function GetVipConfigInfoReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetVipConfigInfoReq user_id.
     * @member {number} user_id
     * @memberof client_proto.GetVipConfigInfoReq
     * @instance
     */
    GetVipConfigInfoReq.prototype.user_id = 0;

    /**
     * Creates a new GetVipConfigInfoReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetVipConfigInfoReq
     * @static
     * @param {client_proto.IGetVipConfigInfoReq=} [properties] Properties to set
     * @returns {client_proto.GetVipConfigInfoReq} GetVipConfigInfoReq instance
     */
    GetVipConfigInfoReq.create = function create(properties) {
      return new GetVipConfigInfoReq(properties);
    };

    /**
     * Encodes the specified GetVipConfigInfoReq message. Does not implicitly {@link client_proto.GetVipConfigInfoReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetVipConfigInfoReq
     * @static
     * @param {client_proto.IGetVipConfigInfoReq} message GetVipConfigInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetVipConfigInfoReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.user_id);
      return writer;
    };

    /**
     * Encodes the specified GetVipConfigInfoReq message, length delimited. Does not implicitly {@link client_proto.GetVipConfigInfoReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetVipConfigInfoReq
     * @static
     * @param {client_proto.IGetVipConfigInfoReq} message GetVipConfigInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetVipConfigInfoReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetVipConfigInfoReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetVipConfigInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetVipConfigInfoReq} GetVipConfigInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetVipConfigInfoReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetVipConfigInfoReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetVipConfigInfoReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetVipConfigInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetVipConfigInfoReq} GetVipConfigInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetVipConfigInfoReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetVipConfigInfoReq message.
     * @function verify
     * @memberof client_proto.GetVipConfigInfoReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetVipConfigInfoReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      return null;
    };

    /**
     * Creates a GetVipConfigInfoReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetVipConfigInfoReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetVipConfigInfoReq} GetVipConfigInfoReq
     */
    GetVipConfigInfoReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetVipConfigInfoReq) return object;
      var message = new $root.client_proto.GetVipConfigInfoReq();
      if (object.user_id != null) message.user_id = object.user_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetVipConfigInfoReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetVipConfigInfoReq
     * @static
     * @param {client_proto.GetVipConfigInfoReq} message GetVipConfigInfoReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetVipConfigInfoReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.user_id = 0;
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      return object;
    };

    /**
     * Converts this GetVipConfigInfoReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetVipConfigInfoReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetVipConfigInfoReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetVipConfigInfoReq
     * @function getTypeUrl
     * @memberof client_proto.GetVipConfigInfoReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetVipConfigInfoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetVipConfigInfoReq";
    };

    return GetVipConfigInfoReq;
  })();

  /**
   * VIP_REWARD_ID enum.
   * @name client_proto.VIP_REWARD_ID
   * @enum {number}
   * @property {number} VIP_REWARD_NULL=0 VIP_REWARD_NULL value
   * @property {number} VIP_REWARD_UPGRADE=1 VIP_REWARD_UPGRADE value
   * @property {number} VIP_REWARD_WEEKLY=2 VIP_REWARD_WEEKLY value
   */
  client_proto.VIP_REWARD_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "VIP_REWARD_NULL")] = 0;
    values[(valuesById[1] = "VIP_REWARD_UPGRADE")] = 1;
    values[(valuesById[2] = "VIP_REWARD_WEEKLY")] = 2;
    return values;
  })();

  client_proto.vipUnpack = (function () {
    /**
     * Properties of a vipUnpack.
     * @memberof client_proto
     * @interface IvipUnpack
     * @property {number|null} [vip_id] vipUnpack vip_id
     * @property {number|Long|null} [init_exp] vipUnpack init_exp
     * @property {number|Long|null} [next_exp] vipUnpack next_exp
     * @property {number|null} [status] vipUnpack status
     * @property {Array.<string>|null} [reward] vipUnpack reward
     * @property {Array.<string>|null} [privilege] vipUnpack privilege
     * @property {Array.<client_proto.vipUnpack.IDetail>|null} [take_reward] vipUnpack take_reward
     */

    /**
     * Constructs a new vipUnpack.
     * @memberof client_proto
     * @classdesc Represents a vipUnpack.
     * @implements IvipUnpack
     * @constructor
     * @param {client_proto.IvipUnpack=} [properties] Properties to set
     */
    function vipUnpack(properties) {
      this.reward = [];
      this.privilege = [];
      this.take_reward = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * vipUnpack vip_id.
     * @member {number} vip_id
     * @memberof client_proto.vipUnpack
     * @instance
     */
    vipUnpack.prototype.vip_id = 0;

    /**
     * vipUnpack init_exp.
     * @member {number|Long} init_exp
     * @memberof client_proto.vipUnpack
     * @instance
     */
    vipUnpack.prototype.init_exp = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * vipUnpack next_exp.
     * @member {number|Long} next_exp
     * @memberof client_proto.vipUnpack
     * @instance
     */
    vipUnpack.prototype.next_exp = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * vipUnpack status.
     * @member {number} status
     * @memberof client_proto.vipUnpack
     * @instance
     */
    vipUnpack.prototype.status = 0;

    /**
     * vipUnpack reward.
     * @member {Array.<string>} reward
     * @memberof client_proto.vipUnpack
     * @instance
     */
    vipUnpack.prototype.reward = $util.emptyArray;

    /**
     * vipUnpack privilege.
     * @member {Array.<string>} privilege
     * @memberof client_proto.vipUnpack
     * @instance
     */
    vipUnpack.prototype.privilege = $util.emptyArray;

    /**
     * vipUnpack take_reward.
     * @member {Array.<client_proto.vipUnpack.IDetail>} take_reward
     * @memberof client_proto.vipUnpack
     * @instance
     */
    vipUnpack.prototype.take_reward = $util.emptyArray;

    /**
     * Creates a new vipUnpack instance using the specified properties.
     * @function create
     * @memberof client_proto.vipUnpack
     * @static
     * @param {client_proto.IvipUnpack=} [properties] Properties to set
     * @returns {client_proto.vipUnpack} vipUnpack instance
     */
    vipUnpack.create = function create(properties) {
      return new vipUnpack(properties);
    };

    /**
     * Encodes the specified vipUnpack message. Does not implicitly {@link client_proto.vipUnpack.verify|verify} messages.
     * @function encode
     * @memberof client_proto.vipUnpack
     * @static
     * @param {client_proto.IvipUnpack} message vipUnpack message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    vipUnpack.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.vip_id != null && Object.hasOwnProperty.call(message, "vip_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.vip_id);
      if (message.init_exp != null && Object.hasOwnProperty.call(message, "init_exp"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.init_exp);
      if (message.next_exp != null && Object.hasOwnProperty.call(message, "next_exp"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.next_exp);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.status);
      if (message.reward != null && message.reward.length)
        for (var i = 0; i < message.reward.length; ++i)
          writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.reward[i]);
      if (message.privilege != null && message.privilege.length)
        for (var i = 0; i < message.privilege.length; ++i)
          writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.privilege[i]);
      if (message.take_reward != null && message.take_reward.length)
        for (var i = 0; i < message.take_reward.length; ++i)
          $root.client_proto.vipUnpack.Detail.encode(
            message.take_reward[i],
            writer.uint32(/* id 7, wireType 2 =*/ 58).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified vipUnpack message, length delimited. Does not implicitly {@link client_proto.vipUnpack.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.vipUnpack
     * @static
     * @param {client_proto.IvipUnpack} message vipUnpack message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    vipUnpack.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a vipUnpack message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.vipUnpack
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.vipUnpack} vipUnpack
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    vipUnpack.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.vipUnpack();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.vip_id = reader.int32();
            break;
          }
          case 2: {
            message.init_exp = reader.int64();
            break;
          }
          case 3: {
            message.next_exp = reader.int64();
            break;
          }
          case 4: {
            message.status = reader.int32();
            break;
          }
          case 5: {
            if (!(message.reward && message.reward.length)) message.reward = [];
            message.reward.push(reader.string());
            break;
          }
          case 6: {
            if (!(message.privilege && message.privilege.length)) message.privilege = [];
            message.privilege.push(reader.string());
            break;
          }
          case 7: {
            if (!(message.take_reward && message.take_reward.length)) message.take_reward = [];
            message.take_reward.push($root.client_proto.vipUnpack.Detail.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a vipUnpack message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.vipUnpack
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.vipUnpack} vipUnpack
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    vipUnpack.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a vipUnpack message.
     * @function verify
     * @memberof client_proto.vipUnpack
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    vipUnpack.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.vip_id != null && message.hasOwnProperty("vip_id"))
        if (!$util.isInteger(message.vip_id)) return "vip_id: integer expected";
      if (message.init_exp != null && message.hasOwnProperty("init_exp"))
        if (
          !$util.isInteger(message.init_exp) &&
          !(message.init_exp && $util.isInteger(message.init_exp.low) && $util.isInteger(message.init_exp.high))
        )
          return "init_exp: integer|Long expected";
      if (message.next_exp != null && message.hasOwnProperty("next_exp"))
        if (
          !$util.isInteger(message.next_exp) &&
          !(message.next_exp && $util.isInteger(message.next_exp.low) && $util.isInteger(message.next_exp.high))
        )
          return "next_exp: integer|Long expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      if (message.reward != null && message.hasOwnProperty("reward")) {
        if (!Array.isArray(message.reward)) return "reward: array expected";
        for (var i = 0; i < message.reward.length; ++i)
          if (!$util.isString(message.reward[i])) return "reward: string[] expected";
      }
      if (message.privilege != null && message.hasOwnProperty("privilege")) {
        if (!Array.isArray(message.privilege)) return "privilege: array expected";
        for (var i = 0; i < message.privilege.length; ++i)
          if (!$util.isString(message.privilege[i])) return "privilege: string[] expected";
      }
      if (message.take_reward != null && message.hasOwnProperty("take_reward")) {
        if (!Array.isArray(message.take_reward)) return "take_reward: array expected";
        for (var i = 0; i < message.take_reward.length; ++i) {
          var error = $root.client_proto.vipUnpack.Detail.verify(message.take_reward[i]);
          if (error) return "take_reward." + error;
        }
      }
      return null;
    };

    /**
     * Creates a vipUnpack message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.vipUnpack
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.vipUnpack} vipUnpack
     */
    vipUnpack.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.vipUnpack) return object;
      var message = new $root.client_proto.vipUnpack();
      if (object.vip_id != null) message.vip_id = object.vip_id | 0;
      if (object.init_exp != null)
        if ($util.Long) (message.init_exp = $util.Long.fromValue(object.init_exp)).unsigned = false;
        else if (typeof object.init_exp === "string") message.init_exp = parseInt(object.init_exp, 10);
        else if (typeof object.init_exp === "number") message.init_exp = object.init_exp;
        else if (typeof object.init_exp === "object")
          message.init_exp = new $util.LongBits(object.init_exp.low >>> 0, object.init_exp.high >>> 0).toNumber();
      if (object.next_exp != null)
        if ($util.Long) (message.next_exp = $util.Long.fromValue(object.next_exp)).unsigned = false;
        else if (typeof object.next_exp === "string") message.next_exp = parseInt(object.next_exp, 10);
        else if (typeof object.next_exp === "number") message.next_exp = object.next_exp;
        else if (typeof object.next_exp === "object")
          message.next_exp = new $util.LongBits(object.next_exp.low >>> 0, object.next_exp.high >>> 0).toNumber();
      if (object.status != null) message.status = object.status | 0;
      if (object.reward) {
        if (!Array.isArray(object.reward)) throw TypeError(".client_proto.vipUnpack.reward: array expected");
        message.reward = [];
        for (var i = 0; i < object.reward.length; ++i) message.reward[i] = String(object.reward[i]);
      }
      if (object.privilege) {
        if (!Array.isArray(object.privilege)) throw TypeError(".client_proto.vipUnpack.privilege: array expected");
        message.privilege = [];
        for (var i = 0; i < object.privilege.length; ++i) message.privilege[i] = String(object.privilege[i]);
      }
      if (object.take_reward) {
        if (!Array.isArray(object.take_reward)) throw TypeError(".client_proto.vipUnpack.take_reward: array expected");
        message.take_reward = [];
        for (var i = 0; i < object.take_reward.length; ++i) {
          if (typeof object.take_reward[i] !== "object")
            throw TypeError(".client_proto.vipUnpack.take_reward: object expected");
          message.take_reward[i] = $root.client_proto.vipUnpack.Detail.fromObject(object.take_reward[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a vipUnpack message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.vipUnpack
     * @static
     * @param {client_proto.vipUnpack} message vipUnpack
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    vipUnpack.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) {
        object.reward = [];
        object.privilege = [];
        object.take_reward = [];
      }
      if (options.defaults) {
        object.vip_id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.init_exp =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.init_exp = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.next_exp =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.next_exp = options.longs === String ? "0" : 0;
        object.status = 0;
      }
      if (message.vip_id != null && message.hasOwnProperty("vip_id")) object.vip_id = message.vip_id;
      if (message.init_exp != null && message.hasOwnProperty("init_exp"))
        if (typeof message.init_exp === "number")
          object.init_exp = options.longs === String ? String(message.init_exp) : message.init_exp;
        else
          object.init_exp =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.init_exp)
              : options.longs === Number
                ? new $util.LongBits(message.init_exp.low >>> 0, message.init_exp.high >>> 0).toNumber()
                : message.init_exp;
      if (message.next_exp != null && message.hasOwnProperty("next_exp"))
        if (typeof message.next_exp === "number")
          object.next_exp = options.longs === String ? String(message.next_exp) : message.next_exp;
        else
          object.next_exp =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.next_exp)
              : options.longs === Number
                ? new $util.LongBits(message.next_exp.low >>> 0, message.next_exp.high >>> 0).toNumber()
                : message.next_exp;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      if (message.reward && message.reward.length) {
        object.reward = [];
        for (var j = 0; j < message.reward.length; ++j) object.reward[j] = message.reward[j];
      }
      if (message.privilege && message.privilege.length) {
        object.privilege = [];
        for (var j = 0; j < message.privilege.length; ++j) object.privilege[j] = message.privilege[j];
      }
      if (message.take_reward && message.take_reward.length) {
        object.take_reward = [];
        for (var j = 0; j < message.take_reward.length; ++j)
          object.take_reward[j] = $root.client_proto.vipUnpack.Detail.toObject(message.take_reward[j], options);
      }
      return object;
    };

    /**
     * Converts this vipUnpack to JSON.
     * @function toJSON
     * @memberof client_proto.vipUnpack
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    vipUnpack.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for vipUnpack
     * @function getTypeUrl
     * @memberof client_proto.vipUnpack
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    vipUnpack.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.vipUnpack";
    };

    vipUnpack.Detail = (function () {
      /**
       * Properties of a Detail.
       * @memberof client_proto.vipUnpack
       * @interface IDetail
       * @property {client_proto.VIP_REWARD_ID|null} [reward_id] Detail reward_id
       * @property {number|null} [next_time] Detail next_time
       */

      /**
       * Constructs a new Detail.
       * @memberof client_proto.vipUnpack
       * @classdesc Represents a Detail.
       * @implements IDetail
       * @constructor
       * @param {client_proto.vipUnpack.IDetail=} [properties] Properties to set
       */
      function Detail(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }

      /**
       * Detail reward_id.
       * @member {client_proto.VIP_REWARD_ID} reward_id
       * @memberof client_proto.vipUnpack.Detail
       * @instance
       */
      Detail.prototype.reward_id = 0;

      /**
       * Detail next_time.
       * @member {number} next_time
       * @memberof client_proto.vipUnpack.Detail
       * @instance
       */
      Detail.prototype.next_time = 0;

      /**
       * Creates a new Detail instance using the specified properties.
       * @function create
       * @memberof client_proto.vipUnpack.Detail
       * @static
       * @param {client_proto.vipUnpack.IDetail=} [properties] Properties to set
       * @returns {client_proto.vipUnpack.Detail} Detail instance
       */
      Detail.create = function create(properties) {
        return new Detail(properties);
      };

      /**
       * Encodes the specified Detail message. Does not implicitly {@link client_proto.vipUnpack.Detail.verify|verify} messages.
       * @function encode
       * @memberof client_proto.vipUnpack.Detail
       * @static
       * @param {client_proto.vipUnpack.IDetail} message Detail message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Detail.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.reward_id != null && Object.hasOwnProperty.call(message, "reward_id"))
          writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.reward_id);
        if (message.next_time != null && Object.hasOwnProperty.call(message, "next_time"))
          writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.next_time);
        return writer;
      };

      /**
       * Encodes the specified Detail message, length delimited. Does not implicitly {@link client_proto.vipUnpack.Detail.verify|verify} messages.
       * @function encodeDelimited
       * @memberof client_proto.vipUnpack.Detail
       * @static
       * @param {client_proto.vipUnpack.IDetail} message Detail message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Detail.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a Detail message from the specified reader or buffer.
       * @function decode
       * @memberof client_proto.vipUnpack.Detail
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {client_proto.vipUnpack.Detail} Detail
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Detail.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.client_proto.vipUnpack.Detail();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.reward_id = reader.int32();
              break;
            }
            case 2: {
              message.next_time = reader.int32();
              break;
            }
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a Detail message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof client_proto.vipUnpack.Detail
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {client_proto.vipUnpack.Detail} Detail
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Detail.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a Detail message.
       * @function verify
       * @memberof client_proto.vipUnpack.Detail
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      Detail.verify = function verify(message) {
        if (typeof message !== "object" || message === null) return "object expected";
        if (message.reward_id != null && message.hasOwnProperty("reward_id"))
          switch (message.reward_id) {
            default:
              return "reward_id: enum value expected";
            case 0:
            case 1:
            case 2:
              break;
          }
        if (message.next_time != null && message.hasOwnProperty("next_time"))
          if (!$util.isInteger(message.next_time)) return "next_time: integer expected";
        return null;
      };

      /**
       * Creates a Detail message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof client_proto.vipUnpack.Detail
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {client_proto.vipUnpack.Detail} Detail
       */
      Detail.fromObject = function fromObject(object) {
        if (object instanceof $root.client_proto.vipUnpack.Detail) return object;
        var message = new $root.client_proto.vipUnpack.Detail();
        switch (object.reward_id) {
          default:
            if (typeof object.reward_id === "number") {
              message.reward_id = object.reward_id;
              break;
            }
            break;
          case "VIP_REWARD_NULL":
          case 0:
            message.reward_id = 0;
            break;
          case "VIP_REWARD_UPGRADE":
          case 1:
            message.reward_id = 1;
            break;
          case "VIP_REWARD_WEEKLY":
          case 2:
            message.reward_id = 2;
            break;
        }
        if (object.next_time != null) message.next_time = object.next_time | 0;
        return message;
      };

      /**
       * Creates a plain object from a Detail message. Also converts values to other types if specified.
       * @function toObject
       * @memberof client_proto.vipUnpack.Detail
       * @static
       * @param {client_proto.vipUnpack.Detail} message Detail
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      Detail.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.reward_id = options.enums === String ? "VIP_REWARD_NULL" : 0;
          object.next_time = 0;
        }
        if (message.reward_id != null && message.hasOwnProperty("reward_id"))
          object.reward_id =
            options.enums === String
              ? $root.client_proto.VIP_REWARD_ID[message.reward_id] === undefined
                ? message.reward_id
                : $root.client_proto.VIP_REWARD_ID[message.reward_id]
              : message.reward_id;
        if (message.next_time != null && message.hasOwnProperty("next_time")) object.next_time = message.next_time;
        return object;
      };

      /**
       * Converts this Detail to JSON.
       * @function toJSON
       * @memberof client_proto.vipUnpack.Detail
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      Detail.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for Detail
       * @function getTypeUrl
       * @memberof client_proto.vipUnpack.Detail
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      Detail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/client_proto.vipUnpack.Detail";
      };

      return Detail;
    })();

    return vipUnpack;
  })();

  client_proto.GetVipConfigInfoResp = (function () {
    /**
     * Properties of a GetVipConfigInfoResp.
     * @memberof client_proto
     * @interface IGetVipConfigInfoResp
     * @property {number|Long|null} [userScore] GetVipConfigInfoResp userScore
     * @property {Array.<client_proto.IvipUnpack>|null} [vip_list] GetVipConfigInfoResp vip_list
     */

    /**
     * Constructs a new GetVipConfigInfoResp.
     * @memberof client_proto
     * @classdesc Represents a GetVipConfigInfoResp.
     * @implements IGetVipConfigInfoResp
     * @constructor
     * @param {client_proto.IGetVipConfigInfoResp=} [properties] Properties to set
     */
    function GetVipConfigInfoResp(properties) {
      this.vip_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetVipConfigInfoResp userScore.
     * @member {number|Long} userScore
     * @memberof client_proto.GetVipConfigInfoResp
     * @instance
     */
    GetVipConfigInfoResp.prototype.userScore = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GetVipConfigInfoResp vip_list.
     * @member {Array.<client_proto.IvipUnpack>} vip_list
     * @memberof client_proto.GetVipConfigInfoResp
     * @instance
     */
    GetVipConfigInfoResp.prototype.vip_list = $util.emptyArray;

    /**
     * Creates a new GetVipConfigInfoResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetVipConfigInfoResp
     * @static
     * @param {client_proto.IGetVipConfigInfoResp=} [properties] Properties to set
     * @returns {client_proto.GetVipConfigInfoResp} GetVipConfigInfoResp instance
     */
    GetVipConfigInfoResp.create = function create(properties) {
      return new GetVipConfigInfoResp(properties);
    };

    /**
     * Encodes the specified GetVipConfigInfoResp message. Does not implicitly {@link client_proto.GetVipConfigInfoResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetVipConfigInfoResp
     * @static
     * @param {client_proto.IGetVipConfigInfoResp} message GetVipConfigInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetVipConfigInfoResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.userScore != null && Object.hasOwnProperty.call(message, "userScore"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.userScore);
      if (message.vip_list != null && message.vip_list.length)
        for (var i = 0; i < message.vip_list.length; ++i)
          $root.client_proto.vipUnpack
            .encode(message.vip_list[i], writer.uint32(/* id 2, wireType 2 =*/ 18).fork())
            .ldelim();
      return writer;
    };

    /**
     * Encodes the specified GetVipConfigInfoResp message, length delimited. Does not implicitly {@link client_proto.GetVipConfigInfoResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetVipConfigInfoResp
     * @static
     * @param {client_proto.IGetVipConfigInfoResp} message GetVipConfigInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetVipConfigInfoResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetVipConfigInfoResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetVipConfigInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetVipConfigInfoResp} GetVipConfigInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetVipConfigInfoResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetVipConfigInfoResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.userScore = reader.int64();
            break;
          }
          case 2: {
            if (!(message.vip_list && message.vip_list.length)) message.vip_list = [];
            message.vip_list.push($root.client_proto.vipUnpack.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetVipConfigInfoResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetVipConfigInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetVipConfigInfoResp} GetVipConfigInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetVipConfigInfoResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetVipConfigInfoResp message.
     * @function verify
     * @memberof client_proto.GetVipConfigInfoResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetVipConfigInfoResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.userScore != null && message.hasOwnProperty("userScore"))
        if (
          !$util.isInteger(message.userScore) &&
          !(message.userScore && $util.isInteger(message.userScore.low) && $util.isInteger(message.userScore.high))
        )
          return "userScore: integer|Long expected";
      if (message.vip_list != null && message.hasOwnProperty("vip_list")) {
        if (!Array.isArray(message.vip_list)) return "vip_list: array expected";
        for (var i = 0; i < message.vip_list.length; ++i) {
          var error = $root.client_proto.vipUnpack.verify(message.vip_list[i]);
          if (error) return "vip_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates a GetVipConfigInfoResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetVipConfigInfoResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetVipConfigInfoResp} GetVipConfigInfoResp
     */
    GetVipConfigInfoResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetVipConfigInfoResp) return object;
      var message = new $root.client_proto.GetVipConfigInfoResp();
      if (object.userScore != null)
        if ($util.Long) (message.userScore = $util.Long.fromValue(object.userScore)).unsigned = false;
        else if (typeof object.userScore === "string") message.userScore = parseInt(object.userScore, 10);
        else if (typeof object.userScore === "number") message.userScore = object.userScore;
        else if (typeof object.userScore === "object")
          message.userScore = new $util.LongBits(object.userScore.low >>> 0, object.userScore.high >>> 0).toNumber();
      if (object.vip_list) {
        if (!Array.isArray(object.vip_list))
          throw TypeError(".client_proto.GetVipConfigInfoResp.vip_list: array expected");
        message.vip_list = [];
        for (var i = 0; i < object.vip_list.length; ++i) {
          if (typeof object.vip_list[i] !== "object")
            throw TypeError(".client_proto.GetVipConfigInfoResp.vip_list: object expected");
          message.vip_list[i] = $root.client_proto.vipUnpack.fromObject(object.vip_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a GetVipConfigInfoResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetVipConfigInfoResp
     * @static
     * @param {client_proto.GetVipConfigInfoResp} message GetVipConfigInfoResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetVipConfigInfoResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.vip_list = [];
      if (options.defaults)
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.userScore =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.userScore = options.longs === String ? "0" : 0;
      if (message.userScore != null && message.hasOwnProperty("userScore"))
        if (typeof message.userScore === "number")
          object.userScore = options.longs === String ? String(message.userScore) : message.userScore;
        else
          object.userScore =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.userScore)
              : options.longs === Number
                ? new $util.LongBits(message.userScore.low >>> 0, message.userScore.high >>> 0).toNumber()
                : message.userScore;
      if (message.vip_list && message.vip_list.length) {
        object.vip_list = [];
        for (var j = 0; j < message.vip_list.length; ++j)
          object.vip_list[j] = $root.client_proto.vipUnpack.toObject(message.vip_list[j], options);
      }
      return object;
    };

    /**
     * Converts this GetVipConfigInfoResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetVipConfigInfoResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetVipConfigInfoResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetVipConfigInfoResp
     * @function getTypeUrl
     * @memberof client_proto.GetVipConfigInfoResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetVipConfigInfoResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetVipConfigInfoResp";
    };

    return GetVipConfigInfoResp;
  })();

  client_proto.GetVipPrivilegeReq = (function () {
    /**
     * Properties of a GetVipPrivilegeReq.
     * @memberof client_proto
     * @interface IGetVipPrivilegeReq
     * @property {number|null} [user_id] GetVipPrivilegeReq user_id
     * @property {number|null} [reward_id] GetVipPrivilegeReq reward_id
     * @property {number|null} [vip_id] GetVipPrivilegeReq vip_id
     */

    /**
     * Constructs a new GetVipPrivilegeReq.
     * @memberof client_proto
     * @classdesc Represents a GetVipPrivilegeReq.
     * @implements IGetVipPrivilegeReq
     * @constructor
     * @param {client_proto.IGetVipPrivilegeReq=} [properties] Properties to set
     */
    function GetVipPrivilegeReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetVipPrivilegeReq user_id.
     * @member {number} user_id
     * @memberof client_proto.GetVipPrivilegeReq
     * @instance
     */
    GetVipPrivilegeReq.prototype.user_id = 0;

    /**
     * GetVipPrivilegeReq reward_id.
     * @member {number} reward_id
     * @memberof client_proto.GetVipPrivilegeReq
     * @instance
     */
    GetVipPrivilegeReq.prototype.reward_id = 0;

    /**
     * GetVipPrivilegeReq vip_id.
     * @member {number} vip_id
     * @memberof client_proto.GetVipPrivilegeReq
     * @instance
     */
    GetVipPrivilegeReq.prototype.vip_id = 0;

    /**
     * Creates a new GetVipPrivilegeReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetVipPrivilegeReq
     * @static
     * @param {client_proto.IGetVipPrivilegeReq=} [properties] Properties to set
     * @returns {client_proto.GetVipPrivilegeReq} GetVipPrivilegeReq instance
     */
    GetVipPrivilegeReq.create = function create(properties) {
      return new GetVipPrivilegeReq(properties);
    };

    /**
     * Encodes the specified GetVipPrivilegeReq message. Does not implicitly {@link client_proto.GetVipPrivilegeReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetVipPrivilegeReq
     * @static
     * @param {client_proto.IGetVipPrivilegeReq} message GetVipPrivilegeReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetVipPrivilegeReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.user_id);
      if (message.reward_id != null && Object.hasOwnProperty.call(message, "reward_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.reward_id);
      if (message.vip_id != null && Object.hasOwnProperty.call(message, "vip_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.vip_id);
      return writer;
    };

    /**
     * Encodes the specified GetVipPrivilegeReq message, length delimited. Does not implicitly {@link client_proto.GetVipPrivilegeReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetVipPrivilegeReq
     * @static
     * @param {client_proto.IGetVipPrivilegeReq} message GetVipPrivilegeReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetVipPrivilegeReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetVipPrivilegeReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetVipPrivilegeReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetVipPrivilegeReq} GetVipPrivilegeReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetVipPrivilegeReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetVipPrivilegeReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_id = reader.int32();
            break;
          }
          case 2: {
            message.reward_id = reader.int32();
            break;
          }
          case 3: {
            message.vip_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetVipPrivilegeReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetVipPrivilegeReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetVipPrivilegeReq} GetVipPrivilegeReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetVipPrivilegeReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetVipPrivilegeReq message.
     * @function verify
     * @memberof client_proto.GetVipPrivilegeReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetVipPrivilegeReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (!$util.isInteger(message.reward_id)) return "reward_id: integer expected";
      if (message.vip_id != null && message.hasOwnProperty("vip_id"))
        if (!$util.isInteger(message.vip_id)) return "vip_id: integer expected";
      return null;
    };

    /**
     * Creates a GetVipPrivilegeReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetVipPrivilegeReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetVipPrivilegeReq} GetVipPrivilegeReq
     */
    GetVipPrivilegeReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetVipPrivilegeReq) return object;
      var message = new $root.client_proto.GetVipPrivilegeReq();
      if (object.user_id != null) message.user_id = object.user_id | 0;
      if (object.reward_id != null) message.reward_id = object.reward_id | 0;
      if (object.vip_id != null) message.vip_id = object.vip_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetVipPrivilegeReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetVipPrivilegeReq
     * @static
     * @param {client_proto.GetVipPrivilegeReq} message GetVipPrivilegeReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetVipPrivilegeReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.user_id = 0;
        object.reward_id = 0;
        object.vip_id = 0;
      }
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      if (message.reward_id != null && message.hasOwnProperty("reward_id")) object.reward_id = message.reward_id;
      if (message.vip_id != null && message.hasOwnProperty("vip_id")) object.vip_id = message.vip_id;
      return object;
    };

    /**
     * Converts this GetVipPrivilegeReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetVipPrivilegeReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetVipPrivilegeReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetVipPrivilegeReq
     * @function getTypeUrl
     * @memberof client_proto.GetVipPrivilegeReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetVipPrivilegeReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetVipPrivilegeReq";
    };

    return GetVipPrivilegeReq;
  })();

  client_proto.SetVipPrivilegeResp = (function () {
    /**
     * Properties of a SetVipPrivilegeResp.
     * @memberof client_proto
     * @interface ISetVipPrivilegeResp
     * @property {number|null} [result] SetVipPrivilegeResp result
     * @property {number|null} [next_time] SetVipPrivilegeResp next_time
     */

    /**
     * Constructs a new SetVipPrivilegeResp.
     * @memberof client_proto
     * @classdesc Represents a SetVipPrivilegeResp.
     * @implements ISetVipPrivilegeResp
     * @constructor
     * @param {client_proto.ISetVipPrivilegeResp=} [properties] Properties to set
     */
    function SetVipPrivilegeResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetVipPrivilegeResp result.
     * @member {number} result
     * @memberof client_proto.SetVipPrivilegeResp
     * @instance
     */
    SetVipPrivilegeResp.prototype.result = 0;

    /**
     * SetVipPrivilegeResp next_time.
     * @member {number} next_time
     * @memberof client_proto.SetVipPrivilegeResp
     * @instance
     */
    SetVipPrivilegeResp.prototype.next_time = 0;

    /**
     * Creates a new SetVipPrivilegeResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SetVipPrivilegeResp
     * @static
     * @param {client_proto.ISetVipPrivilegeResp=} [properties] Properties to set
     * @returns {client_proto.SetVipPrivilegeResp} SetVipPrivilegeResp instance
     */
    SetVipPrivilegeResp.create = function create(properties) {
      return new SetVipPrivilegeResp(properties);
    };

    /**
     * Encodes the specified SetVipPrivilegeResp message. Does not implicitly {@link client_proto.SetVipPrivilegeResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SetVipPrivilegeResp
     * @static
     * @param {client_proto.ISetVipPrivilegeResp} message SetVipPrivilegeResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVipPrivilegeResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.next_time != null && Object.hasOwnProperty.call(message, "next_time"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.next_time);
      return writer;
    };

    /**
     * Encodes the specified SetVipPrivilegeResp message, length delimited. Does not implicitly {@link client_proto.SetVipPrivilegeResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SetVipPrivilegeResp
     * @static
     * @param {client_proto.ISetVipPrivilegeResp} message SetVipPrivilegeResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetVipPrivilegeResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetVipPrivilegeResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SetVipPrivilegeResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SetVipPrivilegeResp} SetVipPrivilegeResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVipPrivilegeResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SetVipPrivilegeResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.next_time = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetVipPrivilegeResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SetVipPrivilegeResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SetVipPrivilegeResp} SetVipPrivilegeResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetVipPrivilegeResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetVipPrivilegeResp message.
     * @function verify
     * @memberof client_proto.SetVipPrivilegeResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetVipPrivilegeResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.next_time != null && message.hasOwnProperty("next_time"))
        if (!$util.isInteger(message.next_time)) return "next_time: integer expected";
      return null;
    };

    /**
     * Creates a SetVipPrivilegeResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SetVipPrivilegeResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SetVipPrivilegeResp} SetVipPrivilegeResp
     */
    SetVipPrivilegeResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SetVipPrivilegeResp) return object;
      var message = new $root.client_proto.SetVipPrivilegeResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.next_time != null) message.next_time = object.next_time | 0;
      return message;
    };

    /**
     * Creates a plain object from a SetVipPrivilegeResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SetVipPrivilegeResp
     * @static
     * @param {client_proto.SetVipPrivilegeResp} message SetVipPrivilegeResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetVipPrivilegeResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.next_time = 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.next_time != null && message.hasOwnProperty("next_time")) object.next_time = message.next_time;
      return object;
    };

    /**
     * Converts this SetVipPrivilegeResp to JSON.
     * @function toJSON
     * @memberof client_proto.SetVipPrivilegeResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetVipPrivilegeResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetVipPrivilegeResp
     * @function getTypeUrl
     * @memberof client_proto.SetVipPrivilegeResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetVipPrivilegeResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SetVipPrivilegeResp";
    };

    return SetVipPrivilegeResp;
  })();

  client_proto.GetVipDetailLogReq = (function () {
    /**
     * Properties of a GetVipDetailLogReq.
     * @memberof client_proto
     * @interface IGetVipDetailLogReq
     * @property {number|null} [period] GetVipDetailLogReq period
     * @property {number|null} [page_size] GetVipDetailLogReq page_size
     * @property {number|null} [page_index] GetVipDetailLogReq page_index
     */

    /**
     * Constructs a new GetVipDetailLogReq.
     * @memberof client_proto
     * @classdesc Represents a GetVipDetailLogReq.
     * @implements IGetVipDetailLogReq
     * @constructor
     * @param {client_proto.IGetVipDetailLogReq=} [properties] Properties to set
     */
    function GetVipDetailLogReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetVipDetailLogReq period.
     * @member {number} period
     * @memberof client_proto.GetVipDetailLogReq
     * @instance
     */
    GetVipDetailLogReq.prototype.period = 0;

    /**
     * GetVipDetailLogReq page_size.
     * @member {number} page_size
     * @memberof client_proto.GetVipDetailLogReq
     * @instance
     */
    GetVipDetailLogReq.prototype.page_size = 0;

    /**
     * GetVipDetailLogReq page_index.
     * @member {number} page_index
     * @memberof client_proto.GetVipDetailLogReq
     * @instance
     */
    GetVipDetailLogReq.prototype.page_index = 0;

    /**
     * Creates a new GetVipDetailLogReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetVipDetailLogReq
     * @static
     * @param {client_proto.IGetVipDetailLogReq=} [properties] Properties to set
     * @returns {client_proto.GetVipDetailLogReq} GetVipDetailLogReq instance
     */
    GetVipDetailLogReq.create = function create(properties) {
      return new GetVipDetailLogReq(properties);
    };

    /**
     * Encodes the specified GetVipDetailLogReq message. Does not implicitly {@link client_proto.GetVipDetailLogReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetVipDetailLogReq
     * @static
     * @param {client_proto.IGetVipDetailLogReq} message GetVipDetailLogReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetVipDetailLogReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.period != null && Object.hasOwnProperty.call(message, "period"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.period);
      if (message.page_size != null && Object.hasOwnProperty.call(message, "page_size"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.page_size);
      if (message.page_index != null && Object.hasOwnProperty.call(message, "page_index"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.page_index);
      return writer;
    };

    /**
     * Encodes the specified GetVipDetailLogReq message, length delimited. Does not implicitly {@link client_proto.GetVipDetailLogReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetVipDetailLogReq
     * @static
     * @param {client_proto.IGetVipDetailLogReq} message GetVipDetailLogReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetVipDetailLogReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetVipDetailLogReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetVipDetailLogReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetVipDetailLogReq} GetVipDetailLogReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetVipDetailLogReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetVipDetailLogReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.period = reader.int32();
            break;
          }
          case 2: {
            message.page_size = reader.int32();
            break;
          }
          case 3: {
            message.page_index = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetVipDetailLogReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetVipDetailLogReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetVipDetailLogReq} GetVipDetailLogReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetVipDetailLogReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetVipDetailLogReq message.
     * @function verify
     * @memberof client_proto.GetVipDetailLogReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetVipDetailLogReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.period != null && message.hasOwnProperty("period"))
        if (!$util.isInteger(message.period)) return "period: integer expected";
      if (message.page_size != null && message.hasOwnProperty("page_size"))
        if (!$util.isInteger(message.page_size)) return "page_size: integer expected";
      if (message.page_index != null && message.hasOwnProperty("page_index"))
        if (!$util.isInteger(message.page_index)) return "page_index: integer expected";
      return null;
    };

    /**
     * Creates a GetVipDetailLogReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetVipDetailLogReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetVipDetailLogReq} GetVipDetailLogReq
     */
    GetVipDetailLogReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetVipDetailLogReq) return object;
      var message = new $root.client_proto.GetVipDetailLogReq();
      if (object.period != null) message.period = object.period | 0;
      if (object.page_size != null) message.page_size = object.page_size | 0;
      if (object.page_index != null) message.page_index = object.page_index | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetVipDetailLogReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetVipDetailLogReq
     * @static
     * @param {client_proto.GetVipDetailLogReq} message GetVipDetailLogReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetVipDetailLogReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.period = 0;
        object.page_size = 0;
        object.page_index = 0;
      }
      if (message.period != null && message.hasOwnProperty("period")) object.period = message.period;
      if (message.page_size != null && message.hasOwnProperty("page_size")) object.page_size = message.page_size;
      if (message.page_index != null && message.hasOwnProperty("page_index")) object.page_index = message.page_index;
      return object;
    };

    /**
     * Converts this GetVipDetailLogReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetVipDetailLogReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetVipDetailLogReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetVipDetailLogReq
     * @function getTypeUrl
     * @memberof client_proto.GetVipDetailLogReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetVipDetailLogReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetVipDetailLogReq";
    };

    return GetVipDetailLogReq;
  })();

  client_proto.Detail = (function () {
    /**
     * Properties of a Detail.
     * @memberof client_proto
     * @interface IDetail
     * @property {number|null} [source] Detail source
     * @property {number|null} [date] Detail date
     * @property {number|Long|null} [score] Detail score
     * @property {number|null} [times] Detail times
     */

    /**
     * Constructs a new Detail.
     * @memberof client_proto
     * @classdesc Represents a Detail.
     * @implements IDetail
     * @constructor
     * @param {client_proto.IDetail=} [properties] Properties to set
     */
    function Detail(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Detail source.
     * @member {number} source
     * @memberof client_proto.Detail
     * @instance
     */
    Detail.prototype.source = 0;

    /**
     * Detail date.
     * @member {number} date
     * @memberof client_proto.Detail
     * @instance
     */
    Detail.prototype.date = 0;

    /**
     * Detail score.
     * @member {number|Long} score
     * @memberof client_proto.Detail
     * @instance
     */
    Detail.prototype.score = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Detail times.
     * @member {number} times
     * @memberof client_proto.Detail
     * @instance
     */
    Detail.prototype.times = 0;

    /**
     * Creates a new Detail instance using the specified properties.
     * @function create
     * @memberof client_proto.Detail
     * @static
     * @param {client_proto.IDetail=} [properties] Properties to set
     * @returns {client_proto.Detail} Detail instance
     */
    Detail.create = function create(properties) {
      return new Detail(properties);
    };

    /**
     * Encodes the specified Detail message. Does not implicitly {@link client_proto.Detail.verify|verify} messages.
     * @function encode
     * @memberof client_proto.Detail
     * @static
     * @param {client_proto.IDetail} message Detail message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Detail.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.source != null && Object.hasOwnProperty.call(message, "source"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.source);
      if (message.date != null && Object.hasOwnProperty.call(message, "date"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.date);
      if (message.score != null && Object.hasOwnProperty.call(message, "score"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.score);
      if (message.times != null && Object.hasOwnProperty.call(message, "times"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.times);
      return writer;
    };

    /**
     * Encodes the specified Detail message, length delimited. Does not implicitly {@link client_proto.Detail.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.Detail
     * @static
     * @param {client_proto.IDetail} message Detail message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Detail.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Detail message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.Detail
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.Detail} Detail
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Detail.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.Detail();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 2: {
            message.source = reader.int32();
            break;
          }
          case 3: {
            message.date = reader.int32();
            break;
          }
          case 4: {
            message.score = reader.int64();
            break;
          }
          case 6: {
            message.times = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a Detail message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.Detail
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.Detail} Detail
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Detail.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Detail message.
     * @function verify
     * @memberof client_proto.Detail
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Detail.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.source != null && message.hasOwnProperty("source"))
        if (!$util.isInteger(message.source)) return "source: integer expected";
      if (message.date != null && message.hasOwnProperty("date"))
        if (!$util.isInteger(message.date)) return "date: integer expected";
      if (message.score != null && message.hasOwnProperty("score"))
        if (
          !$util.isInteger(message.score) &&
          !(message.score && $util.isInteger(message.score.low) && $util.isInteger(message.score.high))
        )
          return "score: integer|Long expected";
      if (message.times != null && message.hasOwnProperty("times"))
        if (!$util.isInteger(message.times)) return "times: integer expected";
      return null;
    };

    /**
     * Creates a Detail message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.Detail
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.Detail} Detail
     */
    Detail.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.Detail) return object;
      var message = new $root.client_proto.Detail();
      if (object.source != null) message.source = object.source | 0;
      if (object.date != null) message.date = object.date | 0;
      if (object.score != null)
        if ($util.Long) (message.score = $util.Long.fromValue(object.score)).unsigned = false;
        else if (typeof object.score === "string") message.score = parseInt(object.score, 10);
        else if (typeof object.score === "number") message.score = object.score;
        else if (typeof object.score === "object")
          message.score = new $util.LongBits(object.score.low >>> 0, object.score.high >>> 0).toNumber();
      if (object.times != null) message.times = object.times | 0;
      return message;
    };

    /**
     * Creates a plain object from a Detail message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.Detail
     * @static
     * @param {client_proto.Detail} message Detail
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Detail.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.source = 0;
        object.date = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.score = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.score = options.longs === String ? "0" : 0;
        object.times = 0;
      }
      if (message.source != null && message.hasOwnProperty("source")) object.source = message.source;
      if (message.date != null && message.hasOwnProperty("date")) object.date = message.date;
      if (message.score != null && message.hasOwnProperty("score"))
        if (typeof message.score === "number")
          object.score = options.longs === String ? String(message.score) : message.score;
        else
          object.score =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.score)
              : options.longs === Number
                ? new $util.LongBits(message.score.low >>> 0, message.score.high >>> 0).toNumber()
                : message.score;
      if (message.times != null && message.hasOwnProperty("times")) object.times = message.times;
      return object;
    };

    /**
     * Converts this Detail to JSON.
     * @function toJSON
     * @memberof client_proto.Detail
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Detail.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Detail
     * @function getTypeUrl
     * @memberof client_proto.Detail
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Detail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.Detail";
    };

    return Detail;
  })();

  client_proto.GetVipDetailLogResp = (function () {
    /**
     * Properties of a GetVipDetailLogResp.
     * @memberof client_proto
     * @interface IGetVipDetailLogResp
     * @property {Array.<client_proto.IDetail>|null} [detail_list] GetVipDetailLogResp detail_list
     * @property {number|Long|null} [total_score] GetVipDetailLogResp total_score
     */

    /**
     * Constructs a new GetVipDetailLogResp.
     * @memberof client_proto
     * @classdesc Represents a GetVipDetailLogResp.
     * @implements IGetVipDetailLogResp
     * @constructor
     * @param {client_proto.IGetVipDetailLogResp=} [properties] Properties to set
     */
    function GetVipDetailLogResp(properties) {
      this.detail_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetVipDetailLogResp detail_list.
     * @member {Array.<client_proto.IDetail>} detail_list
     * @memberof client_proto.GetVipDetailLogResp
     * @instance
     */
    GetVipDetailLogResp.prototype.detail_list = $util.emptyArray;

    /**
     * GetVipDetailLogResp total_score.
     * @member {number|Long} total_score
     * @memberof client_proto.GetVipDetailLogResp
     * @instance
     */
    GetVipDetailLogResp.prototype.total_score = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new GetVipDetailLogResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetVipDetailLogResp
     * @static
     * @param {client_proto.IGetVipDetailLogResp=} [properties] Properties to set
     * @returns {client_proto.GetVipDetailLogResp} GetVipDetailLogResp instance
     */
    GetVipDetailLogResp.create = function create(properties) {
      return new GetVipDetailLogResp(properties);
    };

    /**
     * Encodes the specified GetVipDetailLogResp message. Does not implicitly {@link client_proto.GetVipDetailLogResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetVipDetailLogResp
     * @static
     * @param {client_proto.IGetVipDetailLogResp} message GetVipDetailLogResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetVipDetailLogResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.detail_list != null && message.detail_list.length)
        for (var i = 0; i < message.detail_list.length; ++i)
          $root.client_proto.Detail.encode(
            message.detail_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.total_score != null && Object.hasOwnProperty.call(message, "total_score"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.total_score);
      return writer;
    };

    /**
     * Encodes the specified GetVipDetailLogResp message, length delimited. Does not implicitly {@link client_proto.GetVipDetailLogResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetVipDetailLogResp
     * @static
     * @param {client_proto.IGetVipDetailLogResp} message GetVipDetailLogResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetVipDetailLogResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetVipDetailLogResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetVipDetailLogResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetVipDetailLogResp} GetVipDetailLogResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetVipDetailLogResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetVipDetailLogResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.detail_list && message.detail_list.length)) message.detail_list = [];
            message.detail_list.push($root.client_proto.Detail.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.total_score = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetVipDetailLogResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetVipDetailLogResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetVipDetailLogResp} GetVipDetailLogResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetVipDetailLogResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetVipDetailLogResp message.
     * @function verify
     * @memberof client_proto.GetVipDetailLogResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetVipDetailLogResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.detail_list != null && message.hasOwnProperty("detail_list")) {
        if (!Array.isArray(message.detail_list)) return "detail_list: array expected";
        for (var i = 0; i < message.detail_list.length; ++i) {
          var error = $root.client_proto.Detail.verify(message.detail_list[i]);
          if (error) return "detail_list." + error;
        }
      }
      if (message.total_score != null && message.hasOwnProperty("total_score"))
        if (
          !$util.isInteger(message.total_score) &&
          !(
            message.total_score &&
            $util.isInteger(message.total_score.low) &&
            $util.isInteger(message.total_score.high)
          )
        )
          return "total_score: integer|Long expected";
      return null;
    };

    /**
     * Creates a GetVipDetailLogResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetVipDetailLogResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetVipDetailLogResp} GetVipDetailLogResp
     */
    GetVipDetailLogResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetVipDetailLogResp) return object;
      var message = new $root.client_proto.GetVipDetailLogResp();
      if (object.detail_list) {
        if (!Array.isArray(object.detail_list))
          throw TypeError(".client_proto.GetVipDetailLogResp.detail_list: array expected");
        message.detail_list = [];
        for (var i = 0; i < object.detail_list.length; ++i) {
          if (typeof object.detail_list[i] !== "object")
            throw TypeError(".client_proto.GetVipDetailLogResp.detail_list: object expected");
          message.detail_list[i] = $root.client_proto.Detail.fromObject(object.detail_list[i]);
        }
      }
      if (object.total_score != null)
        if ($util.Long) (message.total_score = $util.Long.fromValue(object.total_score)).unsigned = false;
        else if (typeof object.total_score === "string") message.total_score = parseInt(object.total_score, 10);
        else if (typeof object.total_score === "number") message.total_score = object.total_score;
        else if (typeof object.total_score === "object")
          message.total_score = new $util.LongBits(
            object.total_score.low >>> 0,
            object.total_score.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a GetVipDetailLogResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetVipDetailLogResp
     * @static
     * @param {client_proto.GetVipDetailLogResp} message GetVipDetailLogResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetVipDetailLogResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.detail_list = [];
      if (options.defaults)
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_score =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_score = options.longs === String ? "0" : 0;
      if (message.detail_list && message.detail_list.length) {
        object.detail_list = [];
        for (var j = 0; j < message.detail_list.length; ++j)
          object.detail_list[j] = $root.client_proto.Detail.toObject(message.detail_list[j], options);
      }
      if (message.total_score != null && message.hasOwnProperty("total_score"))
        if (typeof message.total_score === "number")
          object.total_score = options.longs === String ? String(message.total_score) : message.total_score;
        else
          object.total_score =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_score)
              : options.longs === Number
                ? new $util.LongBits(message.total_score.low >>> 0, message.total_score.high >>> 0).toNumber()
                : message.total_score;
      return object;
    };

    /**
     * Converts this GetVipDetailLogResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetVipDetailLogResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetVipDetailLogResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetVipDetailLogResp
     * @function getTypeUrl
     * @memberof client_proto.GetVipDetailLogResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetVipDetailLogResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetVipDetailLogResp";
    };

    return GetVipDetailLogResp;
  })();

  client_proto.LevelItem = (function () {
    /**
     * Properties of a LevelItem.
     * @memberof client_proto
     * @interface ILevelItem
     * @property {number|null} [level] LevelItem level
     * @property {number|Long|null} [upgrade_bonus] LevelItem upgrade_bonus
     * @property {number|Long|null} [weekly_bonus] LevelItem weekly_bonus
     * @property {number|Long|null} [withdraw_limit] LevelItem withdraw_limit
     * @property {number|null} [withdraw_time] LevelItem withdraw_time
     * @property {number|null} [withdraw_fee_base] LevelItem withdraw_fee_base
     * @property {number|null} [withdraw_fee_ratio] LevelItem withdraw_fee_ratio
     */

    /**
     * Constructs a new LevelItem.
     * @memberof client_proto
     * @classdesc Represents a LevelItem.
     * @implements ILevelItem
     * @constructor
     * @param {client_proto.ILevelItem=} [properties] Properties to set
     */
    function LevelItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LevelItem level.
     * @member {number} level
     * @memberof client_proto.LevelItem
     * @instance
     */
    LevelItem.prototype.level = 0;

    /**
     * LevelItem upgrade_bonus.
     * @member {number|Long} upgrade_bonus
     * @memberof client_proto.LevelItem
     * @instance
     */
    LevelItem.prototype.upgrade_bonus = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * LevelItem weekly_bonus.
     * @member {number|Long} weekly_bonus
     * @memberof client_proto.LevelItem
     * @instance
     */
    LevelItem.prototype.weekly_bonus = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * LevelItem withdraw_limit.
     * @member {number|Long} withdraw_limit
     * @memberof client_proto.LevelItem
     * @instance
     */
    LevelItem.prototype.withdraw_limit = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * LevelItem withdraw_time.
     * @member {number} withdraw_time
     * @memberof client_proto.LevelItem
     * @instance
     */
    LevelItem.prototype.withdraw_time = 0;

    /**
     * LevelItem withdraw_fee_base.
     * @member {number} withdraw_fee_base
     * @memberof client_proto.LevelItem
     * @instance
     */
    LevelItem.prototype.withdraw_fee_base = 0;

    /**
     * LevelItem withdraw_fee_ratio.
     * @member {number} withdraw_fee_ratio
     * @memberof client_proto.LevelItem
     * @instance
     */
    LevelItem.prototype.withdraw_fee_ratio = 0;

    /**
     * Creates a new LevelItem instance using the specified properties.
     * @function create
     * @memberof client_proto.LevelItem
     * @static
     * @param {client_proto.ILevelItem=} [properties] Properties to set
     * @returns {client_proto.LevelItem} LevelItem instance
     */
    LevelItem.create = function create(properties) {
      return new LevelItem(properties);
    };

    /**
     * Encodes the specified LevelItem message. Does not implicitly {@link client_proto.LevelItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.LevelItem
     * @static
     * @param {client_proto.ILevelItem} message LevelItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LevelItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.level != null && Object.hasOwnProperty.call(message, "level"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.level);
      if (message.upgrade_bonus != null && Object.hasOwnProperty.call(message, "upgrade_bonus"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.upgrade_bonus);
      if (message.weekly_bonus != null && Object.hasOwnProperty.call(message, "weekly_bonus"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.weekly_bonus);
      if (message.withdraw_limit != null && Object.hasOwnProperty.call(message, "withdraw_limit"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.withdraw_limit);
      if (message.withdraw_time != null && Object.hasOwnProperty.call(message, "withdraw_time"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.withdraw_time);
      if (message.withdraw_fee_base != null && Object.hasOwnProperty.call(message, "withdraw_fee_base"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.withdraw_fee_base);
      if (message.withdraw_fee_ratio != null && Object.hasOwnProperty.call(message, "withdraw_fee_ratio"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.withdraw_fee_ratio);
      return writer;
    };

    /**
     * Encodes the specified LevelItem message, length delimited. Does not implicitly {@link client_proto.LevelItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.LevelItem
     * @static
     * @param {client_proto.ILevelItem} message LevelItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LevelItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LevelItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.LevelItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.LevelItem} LevelItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LevelItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.LevelItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.level = reader.int32();
            break;
          }
          case 2: {
            message.upgrade_bonus = reader.int64();
            break;
          }
          case 3: {
            message.weekly_bonus = reader.int64();
            break;
          }
          case 4: {
            message.withdraw_limit = reader.int64();
            break;
          }
          case 5: {
            message.withdraw_time = reader.int32();
            break;
          }
          case 6: {
            message.withdraw_fee_base = reader.int32();
            break;
          }
          case 7: {
            message.withdraw_fee_ratio = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LevelItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.LevelItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.LevelItem} LevelItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LevelItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LevelItem message.
     * @function verify
     * @memberof client_proto.LevelItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LevelItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.level != null && message.hasOwnProperty("level"))
        if (!$util.isInteger(message.level)) return "level: integer expected";
      if (message.upgrade_bonus != null && message.hasOwnProperty("upgrade_bonus"))
        if (
          !$util.isInteger(message.upgrade_bonus) &&
          !(
            message.upgrade_bonus &&
            $util.isInteger(message.upgrade_bonus.low) &&
            $util.isInteger(message.upgrade_bonus.high)
          )
        )
          return "upgrade_bonus: integer|Long expected";
      if (message.weekly_bonus != null && message.hasOwnProperty("weekly_bonus"))
        if (
          !$util.isInteger(message.weekly_bonus) &&
          !(
            message.weekly_bonus &&
            $util.isInteger(message.weekly_bonus.low) &&
            $util.isInteger(message.weekly_bonus.high)
          )
        )
          return "weekly_bonus: integer|Long expected";
      if (message.withdraw_limit != null && message.hasOwnProperty("withdraw_limit"))
        if (
          !$util.isInteger(message.withdraw_limit) &&
          !(
            message.withdraw_limit &&
            $util.isInteger(message.withdraw_limit.low) &&
            $util.isInteger(message.withdraw_limit.high)
          )
        )
          return "withdraw_limit: integer|Long expected";
      if (message.withdraw_time != null && message.hasOwnProperty("withdraw_time"))
        if (!$util.isInteger(message.withdraw_time)) return "withdraw_time: integer expected";
      if (message.withdraw_fee_base != null && message.hasOwnProperty("withdraw_fee_base"))
        if (!$util.isInteger(message.withdraw_fee_base)) return "withdraw_fee_base: integer expected";
      if (message.withdraw_fee_ratio != null && message.hasOwnProperty("withdraw_fee_ratio"))
        if (!$util.isInteger(message.withdraw_fee_ratio)) return "withdraw_fee_ratio: integer expected";
      return null;
    };

    /**
     * Creates a LevelItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.LevelItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.LevelItem} LevelItem
     */
    LevelItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.LevelItem) return object;
      var message = new $root.client_proto.LevelItem();
      if (object.level != null) message.level = object.level | 0;
      if (object.upgrade_bonus != null)
        if ($util.Long) (message.upgrade_bonus = $util.Long.fromValue(object.upgrade_bonus)).unsigned = false;
        else if (typeof object.upgrade_bonus === "string") message.upgrade_bonus = parseInt(object.upgrade_bonus, 10);
        else if (typeof object.upgrade_bonus === "number") message.upgrade_bonus = object.upgrade_bonus;
        else if (typeof object.upgrade_bonus === "object")
          message.upgrade_bonus = new $util.LongBits(
            object.upgrade_bonus.low >>> 0,
            object.upgrade_bonus.high >>> 0,
          ).toNumber();
      if (object.weekly_bonus != null)
        if ($util.Long) (message.weekly_bonus = $util.Long.fromValue(object.weekly_bonus)).unsigned = false;
        else if (typeof object.weekly_bonus === "string") message.weekly_bonus = parseInt(object.weekly_bonus, 10);
        else if (typeof object.weekly_bonus === "number") message.weekly_bonus = object.weekly_bonus;
        else if (typeof object.weekly_bonus === "object")
          message.weekly_bonus = new $util.LongBits(
            object.weekly_bonus.low >>> 0,
            object.weekly_bonus.high >>> 0,
          ).toNumber();
      if (object.withdraw_limit != null)
        if ($util.Long) (message.withdraw_limit = $util.Long.fromValue(object.withdraw_limit)).unsigned = false;
        else if (typeof object.withdraw_limit === "string")
          message.withdraw_limit = parseInt(object.withdraw_limit, 10);
        else if (typeof object.withdraw_limit === "number") message.withdraw_limit = object.withdraw_limit;
        else if (typeof object.withdraw_limit === "object")
          message.withdraw_limit = new $util.LongBits(
            object.withdraw_limit.low >>> 0,
            object.withdraw_limit.high >>> 0,
          ).toNumber();
      if (object.withdraw_time != null) message.withdraw_time = object.withdraw_time | 0;
      if (object.withdraw_fee_base != null) message.withdraw_fee_base = object.withdraw_fee_base | 0;
      if (object.withdraw_fee_ratio != null) message.withdraw_fee_ratio = object.withdraw_fee_ratio | 0;
      return message;
    };

    /**
     * Creates a plain object from a LevelItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.LevelItem
     * @static
     * @param {client_proto.LevelItem} message LevelItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LevelItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.level = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.upgrade_bonus =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.upgrade_bonus = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.weekly_bonus =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.weekly_bonus = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.withdraw_limit =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.withdraw_limit = options.longs === String ? "0" : 0;
        object.withdraw_time = 0;
        object.withdraw_fee_base = 0;
        object.withdraw_fee_ratio = 0;
      }
      if (message.level != null && message.hasOwnProperty("level")) object.level = message.level;
      if (message.upgrade_bonus != null && message.hasOwnProperty("upgrade_bonus"))
        if (typeof message.upgrade_bonus === "number")
          object.upgrade_bonus = options.longs === String ? String(message.upgrade_bonus) : message.upgrade_bonus;
        else
          object.upgrade_bonus =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.upgrade_bonus)
              : options.longs === Number
                ? new $util.LongBits(message.upgrade_bonus.low >>> 0, message.upgrade_bonus.high >>> 0).toNumber()
                : message.upgrade_bonus;
      if (message.weekly_bonus != null && message.hasOwnProperty("weekly_bonus"))
        if (typeof message.weekly_bonus === "number")
          object.weekly_bonus = options.longs === String ? String(message.weekly_bonus) : message.weekly_bonus;
        else
          object.weekly_bonus =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.weekly_bonus)
              : options.longs === Number
                ? new $util.LongBits(message.weekly_bonus.low >>> 0, message.weekly_bonus.high >>> 0).toNumber()
                : message.weekly_bonus;
      if (message.withdraw_limit != null && message.hasOwnProperty("withdraw_limit"))
        if (typeof message.withdraw_limit === "number")
          object.withdraw_limit = options.longs === String ? String(message.withdraw_limit) : message.withdraw_limit;
        else
          object.withdraw_limit =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.withdraw_limit)
              : options.longs === Number
                ? new $util.LongBits(message.withdraw_limit.low >>> 0, message.withdraw_limit.high >>> 0).toNumber()
                : message.withdraw_limit;
      if (message.withdraw_time != null && message.hasOwnProperty("withdraw_time"))
        object.withdraw_time = message.withdraw_time;
      if (message.withdraw_fee_base != null && message.hasOwnProperty("withdraw_fee_base"))
        object.withdraw_fee_base = message.withdraw_fee_base;
      if (message.withdraw_fee_ratio != null && message.hasOwnProperty("withdraw_fee_ratio"))
        object.withdraw_fee_ratio = message.withdraw_fee_ratio;
      return object;
    };

    /**
     * Converts this LevelItem to JSON.
     * @function toJSON
     * @memberof client_proto.LevelItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LevelItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LevelItem
     * @function getTypeUrl
     * @memberof client_proto.LevelItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LevelItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.LevelItem";
    };

    return LevelItem;
  })();

  client_proto.VipLevelDescReq = (function () {
    /**
     * Properties of a VipLevelDescReq.
     * @memberof client_proto
     * @interface IVipLevelDescReq
     */

    /**
     * Constructs a new VipLevelDescReq.
     * @memberof client_proto
     * @classdesc Represents a VipLevelDescReq.
     * @implements IVipLevelDescReq
     * @constructor
     * @param {client_proto.IVipLevelDescReq=} [properties] Properties to set
     */
    function VipLevelDescReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new VipLevelDescReq instance using the specified properties.
     * @function create
     * @memberof client_proto.VipLevelDescReq
     * @static
     * @param {client_proto.IVipLevelDescReq=} [properties] Properties to set
     * @returns {client_proto.VipLevelDescReq} VipLevelDescReq instance
     */
    VipLevelDescReq.create = function create(properties) {
      return new VipLevelDescReq(properties);
    };

    /**
     * Encodes the specified VipLevelDescReq message. Does not implicitly {@link client_proto.VipLevelDescReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.VipLevelDescReq
     * @static
     * @param {client_proto.IVipLevelDescReq} message VipLevelDescReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VipLevelDescReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified VipLevelDescReq message, length delimited. Does not implicitly {@link client_proto.VipLevelDescReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.VipLevelDescReq
     * @static
     * @param {client_proto.IVipLevelDescReq} message VipLevelDescReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VipLevelDescReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a VipLevelDescReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.VipLevelDescReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.VipLevelDescReq} VipLevelDescReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VipLevelDescReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.VipLevelDescReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a VipLevelDescReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.VipLevelDescReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.VipLevelDescReq} VipLevelDescReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VipLevelDescReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a VipLevelDescReq message.
     * @function verify
     * @memberof client_proto.VipLevelDescReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    VipLevelDescReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a VipLevelDescReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.VipLevelDescReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.VipLevelDescReq} VipLevelDescReq
     */
    VipLevelDescReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.VipLevelDescReq) return object;
      return new $root.client_proto.VipLevelDescReq();
    };

    /**
     * Creates a plain object from a VipLevelDescReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.VipLevelDescReq
     * @static
     * @param {client_proto.VipLevelDescReq} message VipLevelDescReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    VipLevelDescReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this VipLevelDescReq to JSON.
     * @function toJSON
     * @memberof client_proto.VipLevelDescReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    VipLevelDescReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for VipLevelDescReq
     * @function getTypeUrl
     * @memberof client_proto.VipLevelDescReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    VipLevelDescReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.VipLevelDescReq";
    };

    return VipLevelDescReq;
  })();

  client_proto.VipLevelDescResp = (function () {
    /**
     * Properties of a VipLevelDescResp.
     * @memberof client_proto
     * @interface IVipLevelDescResp
     * @property {Array.<client_proto.ILevelItem>|null} [level_list] VipLevelDescResp level_list
     */

    /**
     * Constructs a new VipLevelDescResp.
     * @memberof client_proto
     * @classdesc Represents a VipLevelDescResp.
     * @implements IVipLevelDescResp
     * @constructor
     * @param {client_proto.IVipLevelDescResp=} [properties] Properties to set
     */
    function VipLevelDescResp(properties) {
      this.level_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * VipLevelDescResp level_list.
     * @member {Array.<client_proto.ILevelItem>} level_list
     * @memberof client_proto.VipLevelDescResp
     * @instance
     */
    VipLevelDescResp.prototype.level_list = $util.emptyArray;

    /**
     * Creates a new VipLevelDescResp instance using the specified properties.
     * @function create
     * @memberof client_proto.VipLevelDescResp
     * @static
     * @param {client_proto.IVipLevelDescResp=} [properties] Properties to set
     * @returns {client_proto.VipLevelDescResp} VipLevelDescResp instance
     */
    VipLevelDescResp.create = function create(properties) {
      return new VipLevelDescResp(properties);
    };

    /**
     * Encodes the specified VipLevelDescResp message. Does not implicitly {@link client_proto.VipLevelDescResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.VipLevelDescResp
     * @static
     * @param {client_proto.IVipLevelDescResp} message VipLevelDescResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VipLevelDescResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.level_list != null && message.level_list.length)
        for (var i = 0; i < message.level_list.length; ++i)
          $root.client_proto.LevelItem.encode(
            message.level_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified VipLevelDescResp message, length delimited. Does not implicitly {@link client_proto.VipLevelDescResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.VipLevelDescResp
     * @static
     * @param {client_proto.IVipLevelDescResp} message VipLevelDescResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VipLevelDescResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a VipLevelDescResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.VipLevelDescResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.VipLevelDescResp} VipLevelDescResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VipLevelDescResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.VipLevelDescResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.level_list && message.level_list.length)) message.level_list = [];
            message.level_list.push($root.client_proto.LevelItem.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a VipLevelDescResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.VipLevelDescResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.VipLevelDescResp} VipLevelDescResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VipLevelDescResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a VipLevelDescResp message.
     * @function verify
     * @memberof client_proto.VipLevelDescResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    VipLevelDescResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.level_list != null && message.hasOwnProperty("level_list")) {
        if (!Array.isArray(message.level_list)) return "level_list: array expected";
        for (var i = 0; i < message.level_list.length; ++i) {
          var error = $root.client_proto.LevelItem.verify(message.level_list[i]);
          if (error) return "level_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates a VipLevelDescResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.VipLevelDescResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.VipLevelDescResp} VipLevelDescResp
     */
    VipLevelDescResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.VipLevelDescResp) return object;
      var message = new $root.client_proto.VipLevelDescResp();
      if (object.level_list) {
        if (!Array.isArray(object.level_list))
          throw TypeError(".client_proto.VipLevelDescResp.level_list: array expected");
        message.level_list = [];
        for (var i = 0; i < object.level_list.length; ++i) {
          if (typeof object.level_list[i] !== "object")
            throw TypeError(".client_proto.VipLevelDescResp.level_list: object expected");
          message.level_list[i] = $root.client_proto.LevelItem.fromObject(object.level_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a VipLevelDescResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.VipLevelDescResp
     * @static
     * @param {client_proto.VipLevelDescResp} message VipLevelDescResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    VipLevelDescResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.level_list = [];
      if (message.level_list && message.level_list.length) {
        object.level_list = [];
        for (var j = 0; j < message.level_list.length; ++j)
          object.level_list[j] = $root.client_proto.LevelItem.toObject(message.level_list[j], options);
      }
      return object;
    };

    /**
     * Converts this VipLevelDescResp to JSON.
     * @function toJSON
     * @memberof client_proto.VipLevelDescResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    VipLevelDescResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for VipLevelDescResp
     * @function getTypeUrl
     * @memberof client_proto.VipLevelDescResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    VipLevelDescResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.VipLevelDescResp";
    };

    return VipLevelDescResp;
  })();

  client_proto.VipLevelChangePush = (function () {
    /**
     * Properties of a VipLevelChangePush.
     * @memberof client_proto
     * @interface IVipLevelChangePush
     * @property {number|null} [level] VipLevelChangePush level
     * @property {number|Long|null} [score] VipLevelChangePush score
     * @property {number|Long|null} [max_score] VipLevelChangePush max_score
     */

    /**
     * Constructs a new VipLevelChangePush.
     * @memberof client_proto
     * @classdesc Represents a VipLevelChangePush.
     * @implements IVipLevelChangePush
     * @constructor
     * @param {client_proto.IVipLevelChangePush=} [properties] Properties to set
     */
    function VipLevelChangePush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * VipLevelChangePush level.
     * @member {number} level
     * @memberof client_proto.VipLevelChangePush
     * @instance
     */
    VipLevelChangePush.prototype.level = 0;

    /**
     * VipLevelChangePush score.
     * @member {number|Long} score
     * @memberof client_proto.VipLevelChangePush
     * @instance
     */
    VipLevelChangePush.prototype.score = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * VipLevelChangePush max_score.
     * @member {number|Long} max_score
     * @memberof client_proto.VipLevelChangePush
     * @instance
     */
    VipLevelChangePush.prototype.max_score = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new VipLevelChangePush instance using the specified properties.
     * @function create
     * @memberof client_proto.VipLevelChangePush
     * @static
     * @param {client_proto.IVipLevelChangePush=} [properties] Properties to set
     * @returns {client_proto.VipLevelChangePush} VipLevelChangePush instance
     */
    VipLevelChangePush.create = function create(properties) {
      return new VipLevelChangePush(properties);
    };

    /**
     * Encodes the specified VipLevelChangePush message. Does not implicitly {@link client_proto.VipLevelChangePush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.VipLevelChangePush
     * @static
     * @param {client_proto.IVipLevelChangePush} message VipLevelChangePush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VipLevelChangePush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.level != null && Object.hasOwnProperty.call(message, "level"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.level);
      if (message.score != null && Object.hasOwnProperty.call(message, "score"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.score);
      if (message.max_score != null && Object.hasOwnProperty.call(message, "max_score"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.max_score);
      return writer;
    };

    /**
     * Encodes the specified VipLevelChangePush message, length delimited. Does not implicitly {@link client_proto.VipLevelChangePush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.VipLevelChangePush
     * @static
     * @param {client_proto.IVipLevelChangePush} message VipLevelChangePush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VipLevelChangePush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a VipLevelChangePush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.VipLevelChangePush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.VipLevelChangePush} VipLevelChangePush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VipLevelChangePush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.VipLevelChangePush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.level = reader.int32();
            break;
          }
          case 2: {
            message.score = reader.int64();
            break;
          }
          case 3: {
            message.max_score = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a VipLevelChangePush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.VipLevelChangePush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.VipLevelChangePush} VipLevelChangePush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VipLevelChangePush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a VipLevelChangePush message.
     * @function verify
     * @memberof client_proto.VipLevelChangePush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    VipLevelChangePush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.level != null && message.hasOwnProperty("level"))
        if (!$util.isInteger(message.level)) return "level: integer expected";
      if (message.score != null && message.hasOwnProperty("score"))
        if (
          !$util.isInteger(message.score) &&
          !(message.score && $util.isInteger(message.score.low) && $util.isInteger(message.score.high))
        )
          return "score: integer|Long expected";
      if (message.max_score != null && message.hasOwnProperty("max_score"))
        if (
          !$util.isInteger(message.max_score) &&
          !(message.max_score && $util.isInteger(message.max_score.low) && $util.isInteger(message.max_score.high))
        )
          return "max_score: integer|Long expected";
      return null;
    };

    /**
     * Creates a VipLevelChangePush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.VipLevelChangePush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.VipLevelChangePush} VipLevelChangePush
     */
    VipLevelChangePush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.VipLevelChangePush) return object;
      var message = new $root.client_proto.VipLevelChangePush();
      if (object.level != null) message.level = object.level | 0;
      if (object.score != null)
        if ($util.Long) (message.score = $util.Long.fromValue(object.score)).unsigned = false;
        else if (typeof object.score === "string") message.score = parseInt(object.score, 10);
        else if (typeof object.score === "number") message.score = object.score;
        else if (typeof object.score === "object")
          message.score = new $util.LongBits(object.score.low >>> 0, object.score.high >>> 0).toNumber();
      if (object.max_score != null)
        if ($util.Long) (message.max_score = $util.Long.fromValue(object.max_score)).unsigned = false;
        else if (typeof object.max_score === "string") message.max_score = parseInt(object.max_score, 10);
        else if (typeof object.max_score === "number") message.max_score = object.max_score;
        else if (typeof object.max_score === "object")
          message.max_score = new $util.LongBits(object.max_score.low >>> 0, object.max_score.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a VipLevelChangePush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.VipLevelChangePush
     * @static
     * @param {client_proto.VipLevelChangePush} message VipLevelChangePush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    VipLevelChangePush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.level = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.score = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.score = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.max_score =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.max_score = options.longs === String ? "0" : 0;
      }
      if (message.level != null && message.hasOwnProperty("level")) object.level = message.level;
      if (message.score != null && message.hasOwnProperty("score"))
        if (typeof message.score === "number")
          object.score = options.longs === String ? String(message.score) : message.score;
        else
          object.score =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.score)
              : options.longs === Number
                ? new $util.LongBits(message.score.low >>> 0, message.score.high >>> 0).toNumber()
                : message.score;
      if (message.max_score != null && message.hasOwnProperty("max_score"))
        if (typeof message.max_score === "number")
          object.max_score = options.longs === String ? String(message.max_score) : message.max_score;
        else
          object.max_score =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.max_score)
              : options.longs === Number
                ? new $util.LongBits(message.max_score.low >>> 0, message.max_score.high >>> 0).toNumber()
                : message.max_score;
      return object;
    };

    /**
     * Converts this VipLevelChangePush to JSON.
     * @function toJSON
     * @memberof client_proto.VipLevelChangePush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    VipLevelChangePush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for VipLevelChangePush
     * @function getTypeUrl
     * @memberof client_proto.VipLevelChangePush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    VipLevelChangePush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.VipLevelChangePush";
    };

    return VipLevelChangePush;
  })();

  client_proto.GetUserVipReq = (function () {
    /**
     * Properties of a GetUserVipReq.
     * @memberof client_proto
     * @interface IGetUserVipReq
     * @property {number|null} [user_id] GetUserVipReq user_id
     */

    /**
     * Constructs a new GetUserVipReq.
     * @memberof client_proto
     * @classdesc Represents a GetUserVipReq.
     * @implements IGetUserVipReq
     * @constructor
     * @param {client_proto.IGetUserVipReq=} [properties] Properties to set
     */
    function GetUserVipReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserVipReq user_id.
     * @member {number} user_id
     * @memberof client_proto.GetUserVipReq
     * @instance
     */
    GetUserVipReq.prototype.user_id = 0;

    /**
     * Creates a new GetUserVipReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserVipReq
     * @static
     * @param {client_proto.IGetUserVipReq=} [properties] Properties to set
     * @returns {client_proto.GetUserVipReq} GetUserVipReq instance
     */
    GetUserVipReq.create = function create(properties) {
      return new GetUserVipReq(properties);
    };

    /**
     * Encodes the specified GetUserVipReq message. Does not implicitly {@link client_proto.GetUserVipReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserVipReq
     * @static
     * @param {client_proto.IGetUserVipReq} message GetUserVipReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserVipReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.user_id);
      return writer;
    };

    /**
     * Encodes the specified GetUserVipReq message, length delimited. Does not implicitly {@link client_proto.GetUserVipReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserVipReq
     * @static
     * @param {client_proto.IGetUserVipReq} message GetUserVipReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserVipReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserVipReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserVipReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserVipReq} GetUserVipReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserVipReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserVipReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserVipReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserVipReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserVipReq} GetUserVipReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserVipReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserVipReq message.
     * @function verify
     * @memberof client_proto.GetUserVipReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserVipReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      return null;
    };

    /**
     * Creates a GetUserVipReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserVipReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserVipReq} GetUserVipReq
     */
    GetUserVipReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserVipReq) return object;
      var message = new $root.client_proto.GetUserVipReq();
      if (object.user_id != null) message.user_id = object.user_id >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a GetUserVipReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserVipReq
     * @static
     * @param {client_proto.GetUserVipReq} message GetUserVipReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserVipReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.user_id = 0;
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      return object;
    };

    /**
     * Converts this GetUserVipReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserVipReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserVipReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserVipReq
     * @function getTypeUrl
     * @memberof client_proto.GetUserVipReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserVipReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserVipReq";
    };

    return GetUserVipReq;
  })();

  client_proto.GetUserVipResp = (function () {
    /**
     * Properties of a GetUserVipResp.
     * @memberof client_proto
     * @interface IGetUserVipResp
     * @property {number|null} [user_id] GetUserVipResp user_id
     * @property {number|Long|null} [score] GetUserVipResp score
     * @property {number|null} [level] GetUserVipResp level
     * @property {number|Long|null} [max_score] GetUserVipResp max_score
     */

    /**
     * Constructs a new GetUserVipResp.
     * @memberof client_proto
     * @classdesc Represents a GetUserVipResp.
     * @implements IGetUserVipResp
     * @constructor
     * @param {client_proto.IGetUserVipResp=} [properties] Properties to set
     */
    function GetUserVipResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserVipResp user_id.
     * @member {number} user_id
     * @memberof client_proto.GetUserVipResp
     * @instance
     */
    GetUserVipResp.prototype.user_id = 0;

    /**
     * GetUserVipResp score.
     * @member {number|Long} score
     * @memberof client_proto.GetUserVipResp
     * @instance
     */
    GetUserVipResp.prototype.score = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GetUserVipResp level.
     * @member {number} level
     * @memberof client_proto.GetUserVipResp
     * @instance
     */
    GetUserVipResp.prototype.level = 0;

    /**
     * GetUserVipResp max_score.
     * @member {number|Long} max_score
     * @memberof client_proto.GetUserVipResp
     * @instance
     */
    GetUserVipResp.prototype.max_score = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new GetUserVipResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserVipResp
     * @static
     * @param {client_proto.IGetUserVipResp=} [properties] Properties to set
     * @returns {client_proto.GetUserVipResp} GetUserVipResp instance
     */
    GetUserVipResp.create = function create(properties) {
      return new GetUserVipResp(properties);
    };

    /**
     * Encodes the specified GetUserVipResp message. Does not implicitly {@link client_proto.GetUserVipResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserVipResp
     * @static
     * @param {client_proto.IGetUserVipResp} message GetUserVipResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserVipResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.user_id);
      if (message.score != null && Object.hasOwnProperty.call(message, "score"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.score);
      if (message.level != null && Object.hasOwnProperty.call(message, "level"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.level);
      if (message.max_score != null && Object.hasOwnProperty.call(message, "max_score"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.max_score);
      return writer;
    };

    /**
     * Encodes the specified GetUserVipResp message, length delimited. Does not implicitly {@link client_proto.GetUserVipResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserVipResp
     * @static
     * @param {client_proto.IGetUserVipResp} message GetUserVipResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserVipResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserVipResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserVipResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserVipResp} GetUserVipResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserVipResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserVipResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_id = reader.uint32();
            break;
          }
          case 2: {
            message.score = reader.int64();
            break;
          }
          case 3: {
            message.level = reader.int32();
            break;
          }
          case 4: {
            message.max_score = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserVipResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserVipResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserVipResp} GetUserVipResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserVipResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserVipResp message.
     * @function verify
     * @memberof client_proto.GetUserVipResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserVipResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      if (message.score != null && message.hasOwnProperty("score"))
        if (
          !$util.isInteger(message.score) &&
          !(message.score && $util.isInteger(message.score.low) && $util.isInteger(message.score.high))
        )
          return "score: integer|Long expected";
      if (message.level != null && message.hasOwnProperty("level"))
        if (!$util.isInteger(message.level)) return "level: integer expected";
      if (message.max_score != null && message.hasOwnProperty("max_score"))
        if (
          !$util.isInteger(message.max_score) &&
          !(message.max_score && $util.isInteger(message.max_score.low) && $util.isInteger(message.max_score.high))
        )
          return "max_score: integer|Long expected";
      return null;
    };

    /**
     * Creates a GetUserVipResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserVipResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserVipResp} GetUserVipResp
     */
    GetUserVipResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserVipResp) return object;
      var message = new $root.client_proto.GetUserVipResp();
      if (object.user_id != null) message.user_id = object.user_id >>> 0;
      if (object.score != null)
        if ($util.Long) (message.score = $util.Long.fromValue(object.score)).unsigned = false;
        else if (typeof object.score === "string") message.score = parseInt(object.score, 10);
        else if (typeof object.score === "number") message.score = object.score;
        else if (typeof object.score === "object")
          message.score = new $util.LongBits(object.score.low >>> 0, object.score.high >>> 0).toNumber();
      if (object.level != null) message.level = object.level | 0;
      if (object.max_score != null)
        if ($util.Long) (message.max_score = $util.Long.fromValue(object.max_score)).unsigned = false;
        else if (typeof object.max_score === "string") message.max_score = parseInt(object.max_score, 10);
        else if (typeof object.max_score === "number") message.max_score = object.max_score;
        else if (typeof object.max_score === "object")
          message.max_score = new $util.LongBits(object.max_score.low >>> 0, object.max_score.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a GetUserVipResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserVipResp
     * @static
     * @param {client_proto.GetUserVipResp} message GetUserVipResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserVipResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.user_id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.score = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.score = options.longs === String ? "0" : 0;
        object.level = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.max_score =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.max_score = options.longs === String ? "0" : 0;
      }
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      if (message.score != null && message.hasOwnProperty("score"))
        if (typeof message.score === "number")
          object.score = options.longs === String ? String(message.score) : message.score;
        else
          object.score =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.score)
              : options.longs === Number
                ? new $util.LongBits(message.score.low >>> 0, message.score.high >>> 0).toNumber()
                : message.score;
      if (message.level != null && message.hasOwnProperty("level")) object.level = message.level;
      if (message.max_score != null && message.hasOwnProperty("max_score"))
        if (typeof message.max_score === "number")
          object.max_score = options.longs === String ? String(message.max_score) : message.max_score;
        else
          object.max_score =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.max_score)
              : options.longs === Number
                ? new $util.LongBits(message.max_score.low >>> 0, message.max_score.high >>> 0).toNumber()
                : message.max_score;
      return object;
    };

    /**
     * Converts this GetUserVipResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserVipResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserVipResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserVipResp
     * @function getTypeUrl
     * @memberof client_proto.GetUserVipResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserVipResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserVipResp";
    };

    return GetUserVipResp;
  })();

  client_proto.UpdateVipScoreReq = (function () {
    /**
     * Properties of an UpdateVipScoreReq.
     * @memberof client_proto
     * @interface IUpdateVipScoreReq
     * @property {number|null} [user_id] UpdateVipScoreReq user_id
     * @property {number|Long|null} [score] UpdateVipScoreReq score
     */

    /**
     * Constructs a new UpdateVipScoreReq.
     * @memberof client_proto
     * @classdesc Represents an UpdateVipScoreReq.
     * @implements IUpdateVipScoreReq
     * @constructor
     * @param {client_proto.IUpdateVipScoreReq=} [properties] Properties to set
     */
    function UpdateVipScoreReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateVipScoreReq user_id.
     * @member {number} user_id
     * @memberof client_proto.UpdateVipScoreReq
     * @instance
     */
    UpdateVipScoreReq.prototype.user_id = 0;

    /**
     * UpdateVipScoreReq score.
     * @member {number|Long} score
     * @memberof client_proto.UpdateVipScoreReq
     * @instance
     */
    UpdateVipScoreReq.prototype.score = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new UpdateVipScoreReq instance using the specified properties.
     * @function create
     * @memberof client_proto.UpdateVipScoreReq
     * @static
     * @param {client_proto.IUpdateVipScoreReq=} [properties] Properties to set
     * @returns {client_proto.UpdateVipScoreReq} UpdateVipScoreReq instance
     */
    UpdateVipScoreReq.create = function create(properties) {
      return new UpdateVipScoreReq(properties);
    };

    /**
     * Encodes the specified UpdateVipScoreReq message. Does not implicitly {@link client_proto.UpdateVipScoreReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UpdateVipScoreReq
     * @static
     * @param {client_proto.IUpdateVipScoreReq} message UpdateVipScoreReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateVipScoreReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.user_id);
      if (message.score != null && Object.hasOwnProperty.call(message, "score"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.score);
      return writer;
    };

    /**
     * Encodes the specified UpdateVipScoreReq message, length delimited. Does not implicitly {@link client_proto.UpdateVipScoreReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UpdateVipScoreReq
     * @static
     * @param {client_proto.IUpdateVipScoreReq} message UpdateVipScoreReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateVipScoreReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateVipScoreReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UpdateVipScoreReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UpdateVipScoreReq} UpdateVipScoreReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateVipScoreReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UpdateVipScoreReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_id = reader.uint32();
            break;
          }
          case 2: {
            message.score = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an UpdateVipScoreReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UpdateVipScoreReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UpdateVipScoreReq} UpdateVipScoreReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateVipScoreReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateVipScoreReq message.
     * @function verify
     * @memberof client_proto.UpdateVipScoreReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateVipScoreReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      if (message.score != null && message.hasOwnProperty("score"))
        if (
          !$util.isInteger(message.score) &&
          !(message.score && $util.isInteger(message.score.low) && $util.isInteger(message.score.high))
        )
          return "score: integer|Long expected";
      return null;
    };

    /**
     * Creates an UpdateVipScoreReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UpdateVipScoreReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UpdateVipScoreReq} UpdateVipScoreReq
     */
    UpdateVipScoreReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UpdateVipScoreReq) return object;
      var message = new $root.client_proto.UpdateVipScoreReq();
      if (object.user_id != null) message.user_id = object.user_id >>> 0;
      if (object.score != null)
        if ($util.Long) (message.score = $util.Long.fromValue(object.score)).unsigned = false;
        else if (typeof object.score === "string") message.score = parseInt(object.score, 10);
        else if (typeof object.score === "number") message.score = object.score;
        else if (typeof object.score === "object")
          message.score = new $util.LongBits(object.score.low >>> 0, object.score.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from an UpdateVipScoreReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UpdateVipScoreReq
     * @static
     * @param {client_proto.UpdateVipScoreReq} message UpdateVipScoreReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateVipScoreReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.user_id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.score = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.score = options.longs === String ? "0" : 0;
      }
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      if (message.score != null && message.hasOwnProperty("score"))
        if (typeof message.score === "number")
          object.score = options.longs === String ? String(message.score) : message.score;
        else
          object.score =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.score)
              : options.longs === Number
                ? new $util.LongBits(message.score.low >>> 0, message.score.high >>> 0).toNumber()
                : message.score;
      return object;
    };

    /**
     * Converts this UpdateVipScoreReq to JSON.
     * @function toJSON
     * @memberof client_proto.UpdateVipScoreReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateVipScoreReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateVipScoreReq
     * @function getTypeUrl
     * @memberof client_proto.UpdateVipScoreReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateVipScoreReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UpdateVipScoreReq";
    };

    return UpdateVipScoreReq;
  })();

  client_proto.UpdateVipScoreResp = (function () {
    /**
     * Properties of an UpdateVipScoreResp.
     * @memberof client_proto
     * @interface IUpdateVipScoreResp
     * @property {number|null} [result] UpdateVipScoreResp result
     */

    /**
     * Constructs a new UpdateVipScoreResp.
     * @memberof client_proto
     * @classdesc Represents an UpdateVipScoreResp.
     * @implements IUpdateVipScoreResp
     * @constructor
     * @param {client_proto.IUpdateVipScoreResp=} [properties] Properties to set
     */
    function UpdateVipScoreResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateVipScoreResp result.
     * @member {number} result
     * @memberof client_proto.UpdateVipScoreResp
     * @instance
     */
    UpdateVipScoreResp.prototype.result = 0;

    /**
     * Creates a new UpdateVipScoreResp instance using the specified properties.
     * @function create
     * @memberof client_proto.UpdateVipScoreResp
     * @static
     * @param {client_proto.IUpdateVipScoreResp=} [properties] Properties to set
     * @returns {client_proto.UpdateVipScoreResp} UpdateVipScoreResp instance
     */
    UpdateVipScoreResp.create = function create(properties) {
      return new UpdateVipScoreResp(properties);
    };

    /**
     * Encodes the specified UpdateVipScoreResp message. Does not implicitly {@link client_proto.UpdateVipScoreResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UpdateVipScoreResp
     * @static
     * @param {client_proto.IUpdateVipScoreResp} message UpdateVipScoreResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateVipScoreResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified UpdateVipScoreResp message, length delimited. Does not implicitly {@link client_proto.UpdateVipScoreResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UpdateVipScoreResp
     * @static
     * @param {client_proto.IUpdateVipScoreResp} message UpdateVipScoreResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateVipScoreResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateVipScoreResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UpdateVipScoreResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UpdateVipScoreResp} UpdateVipScoreResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateVipScoreResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UpdateVipScoreResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an UpdateVipScoreResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UpdateVipScoreResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UpdateVipScoreResp} UpdateVipScoreResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateVipScoreResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateVipScoreResp message.
     * @function verify
     * @memberof client_proto.UpdateVipScoreResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateVipScoreResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates an UpdateVipScoreResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UpdateVipScoreResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UpdateVipScoreResp} UpdateVipScoreResp
     */
    UpdateVipScoreResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UpdateVipScoreResp) return object;
      var message = new $root.client_proto.UpdateVipScoreResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from an UpdateVipScoreResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UpdateVipScoreResp
     * @static
     * @param {client_proto.UpdateVipScoreResp} message UpdateVipScoreResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateVipScoreResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this UpdateVipScoreResp to JSON.
     * @function toJSON
     * @memberof client_proto.UpdateVipScoreResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateVipScoreResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateVipScoreResp
     * @function getTypeUrl
     * @memberof client_proto.UpdateVipScoreResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateVipScoreResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UpdateVipScoreResp";
    };

    return UpdateVipScoreResp;
  })();

  /**
   * USER_INFO_SUB_MSG_ID enum.
   * @name client_proto.USER_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} UISMI_NULL=0 UISMI_NULL value
   * @property {number} UISMI_USER_MSG_ID_MIN=1300 UISMI_USER_MSG_ID_MIN value
   * @property {number} UISMI_USER_ATTRI_CHANGE_PUSH=1301 UISMI_USER_ATTRI_CHANGE_PUSH value
   * @property {number} UISMI_USER_DATA_CHANGE_PUSH=1302 UISMI_USER_DATA_CHANGE_PUSH value
   * @property {number} UISMI_USERBASIC_GET_INFO_REQ=1303 UISMI_USERBASIC_GET_INFO_REQ value
   * @property {number} UISMI_USERBASIC_GET_INFO_RESP=1304 UISMI_USERBASIC_GET_INFO_RESP value
   * @property {number} UISMI_USER_SET_INFO_REQ=1305 UISMI_USER_SET_INFO_REQ value
   * @property {number} UISMI_USER_SET_INFO_RESP=1306 UISMI_USER_SET_INFO_RESP value
   * @property {number} UISMI_USER_SET_LANG_REQ=1307 UISMI_USER_SET_LANG_REQ value
   * @property {number} UISMI_USER_SET_LANG_RESP=1308 UISMI_USER_SET_LANG_RESP value
   * @property {number} UISMI_PHP_FORBIT_USER_REQ=1309 UISMI_PHP_FORBIT_USER_REQ value
   * @property {number} UISMI_PHP_FORBIT_USER_RESP=1310 UISMI_PHP_FORBIT_USER_RESP value
   * @property {number} UISMI_FORBIT_USER_PUSH=1311 UISMI_FORBIT_USER_PUSH value
   * @property {number} UISMI_USER_VIP_LEVEL_CHANGE=1312 UISMI_USER_VIP_LEVEL_CHANGE value
   * @property {number} UISMI_USER_MSG_ID_MAX=1350 UISMI_USER_MSG_ID_MAX value
   */
  client_proto.USER_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "UISMI_NULL")] = 0;
    values[(valuesById[1300] = "UISMI_USER_MSG_ID_MIN")] = 1300;
    values[(valuesById[1301] = "UISMI_USER_ATTRI_CHANGE_PUSH")] = 1301;
    values[(valuesById[1302] = "UISMI_USER_DATA_CHANGE_PUSH")] = 1302;
    values[(valuesById[1303] = "UISMI_USERBASIC_GET_INFO_REQ")] = 1303;
    values[(valuesById[1304] = "UISMI_USERBASIC_GET_INFO_RESP")] = 1304;
    values[(valuesById[1305] = "UISMI_USER_SET_INFO_REQ")] = 1305;
    values[(valuesById[1306] = "UISMI_USER_SET_INFO_RESP")] = 1306;
    values[(valuesById[1307] = "UISMI_USER_SET_LANG_REQ")] = 1307;
    values[(valuesById[1308] = "UISMI_USER_SET_LANG_RESP")] = 1308;
    values[(valuesById[1309] = "UISMI_PHP_FORBIT_USER_REQ")] = 1309;
    values[(valuesById[1310] = "UISMI_PHP_FORBIT_USER_RESP")] = 1310;
    values[(valuesById[1311] = "UISMI_FORBIT_USER_PUSH")] = 1311;
    values[(valuesById[1312] = "UISMI_USER_VIP_LEVEL_CHANGE")] = 1312;
    values[(valuesById[1350] = "UISMI_USER_MSG_ID_MAX")] = 1350;
    return values;
  })();

  client_proto.SetUserInfoReq = (function () {
    /**
     * Properties of a SetUserInfoReq.
     * @memberof client_proto
     * @interface ISetUserInfoReq
     * @property {string|null} [user_name] SetUserInfoReq user_name
     * @property {string|null} [user_avatar] SetUserInfoReq user_avatar
     * @property {string|null} [user_gender] SetUserInfoReq user_gender
     * @property {string|null} [telephone] SetUserInfoReq telephone
     * @property {string|null} [email] SetUserInfoReq email
     */

    /**
     * Constructs a new SetUserInfoReq.
     * @memberof client_proto
     * @classdesc Represents a SetUserInfoReq.
     * @implements ISetUserInfoReq
     * @constructor
     * @param {client_proto.ISetUserInfoReq=} [properties] Properties to set
     */
    function SetUserInfoReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetUserInfoReq user_name.
     * @member {string} user_name
     * @memberof client_proto.SetUserInfoReq
     * @instance
     */
    SetUserInfoReq.prototype.user_name = "";

    /**
     * SetUserInfoReq user_avatar.
     * @member {string} user_avatar
     * @memberof client_proto.SetUserInfoReq
     * @instance
     */
    SetUserInfoReq.prototype.user_avatar = "";

    /**
     * SetUserInfoReq user_gender.
     * @member {string} user_gender
     * @memberof client_proto.SetUserInfoReq
     * @instance
     */
    SetUserInfoReq.prototype.user_gender = "";

    /**
     * SetUserInfoReq telephone.
     * @member {string} telephone
     * @memberof client_proto.SetUserInfoReq
     * @instance
     */
    SetUserInfoReq.prototype.telephone = "";

    /**
     * SetUserInfoReq email.
     * @member {string} email
     * @memberof client_proto.SetUserInfoReq
     * @instance
     */
    SetUserInfoReq.prototype.email = "";

    /**
     * Creates a new SetUserInfoReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SetUserInfoReq
     * @static
     * @param {client_proto.ISetUserInfoReq=} [properties] Properties to set
     * @returns {client_proto.SetUserInfoReq} SetUserInfoReq instance
     */
    SetUserInfoReq.create = function create(properties) {
      return new SetUserInfoReq(properties);
    };

    /**
     * Encodes the specified SetUserInfoReq message. Does not implicitly {@link client_proto.SetUserInfoReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SetUserInfoReq
     * @static
     * @param {client_proto.ISetUserInfoReq} message SetUserInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetUserInfoReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_name != null && Object.hasOwnProperty.call(message, "user_name"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.user_name);
      if (message.user_avatar != null && Object.hasOwnProperty.call(message, "user_avatar"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.user_avatar);
      if (message.user_gender != null && Object.hasOwnProperty.call(message, "user_gender"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.user_gender);
      if (message.telephone != null && Object.hasOwnProperty.call(message, "telephone"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.telephone);
      if (message.email != null && Object.hasOwnProperty.call(message, "email"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.email);
      return writer;
    };

    /**
     * Encodes the specified SetUserInfoReq message, length delimited. Does not implicitly {@link client_proto.SetUserInfoReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SetUserInfoReq
     * @static
     * @param {client_proto.ISetUserInfoReq} message SetUserInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetUserInfoReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetUserInfoReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SetUserInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SetUserInfoReq} SetUserInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetUserInfoReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SetUserInfoReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_name = reader.string();
            break;
          }
          case 2: {
            message.user_avatar = reader.string();
            break;
          }
          case 3: {
            message.user_gender = reader.string();
            break;
          }
          case 4: {
            message.telephone = reader.string();
            break;
          }
          case 5: {
            message.email = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetUserInfoReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SetUserInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SetUserInfoReq} SetUserInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetUserInfoReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetUserInfoReq message.
     * @function verify
     * @memberof client_proto.SetUserInfoReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetUserInfoReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_name != null && message.hasOwnProperty("user_name"))
        if (!$util.isString(message.user_name)) return "user_name: string expected";
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        if (!$util.isString(message.user_avatar)) return "user_avatar: string expected";
      if (message.user_gender != null && message.hasOwnProperty("user_gender"))
        if (!$util.isString(message.user_gender)) return "user_gender: string expected";
      if (message.telephone != null && message.hasOwnProperty("telephone"))
        if (!$util.isString(message.telephone)) return "telephone: string expected";
      if (message.email != null && message.hasOwnProperty("email"))
        if (!$util.isString(message.email)) return "email: string expected";
      return null;
    };

    /**
     * Creates a SetUserInfoReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SetUserInfoReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SetUserInfoReq} SetUserInfoReq
     */
    SetUserInfoReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SetUserInfoReq) return object;
      var message = new $root.client_proto.SetUserInfoReq();
      if (object.user_name != null) message.user_name = String(object.user_name);
      if (object.user_avatar != null) message.user_avatar = String(object.user_avatar);
      if (object.user_gender != null) message.user_gender = String(object.user_gender);
      if (object.telephone != null) message.telephone = String(object.telephone);
      if (object.email != null) message.email = String(object.email);
      return message;
    };

    /**
     * Creates a plain object from a SetUserInfoReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SetUserInfoReq
     * @static
     * @param {client_proto.SetUserInfoReq} message SetUserInfoReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetUserInfoReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.user_name = "";
        object.user_avatar = "";
        object.user_gender = "";
        object.telephone = "";
        object.email = "";
      }
      if (message.user_name != null && message.hasOwnProperty("user_name")) object.user_name = message.user_name;
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        object.user_avatar = message.user_avatar;
      if (message.user_gender != null && message.hasOwnProperty("user_gender"))
        object.user_gender = message.user_gender;
      if (message.telephone != null && message.hasOwnProperty("telephone")) object.telephone = message.telephone;
      if (message.email != null && message.hasOwnProperty("email")) object.email = message.email;
      return object;
    };

    /**
     * Converts this SetUserInfoReq to JSON.
     * @function toJSON
     * @memberof client_proto.SetUserInfoReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetUserInfoReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetUserInfoReq
     * @function getTypeUrl
     * @memberof client_proto.SetUserInfoReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetUserInfoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SetUserInfoReq";
    };

    return SetUserInfoReq;
  })();

  client_proto.userinfo = (function () {
    /**
     * Properties of a userinfo.
     * @memberof client_proto
     * @interface Iuserinfo
     * @property {string|null} [user_name] userinfo user_name
     * @property {string|null} [user_avatar] userinfo user_avatar
     * @property {string|null} [user_gender] userinfo user_gender
     * @property {string|null} [telephone] userinfo telephone
     * @property {string|null} [email] userinfo email
     */

    /**
     * Constructs a new userinfo.
     * @memberof client_proto
     * @classdesc Represents a userinfo.
     * @implements Iuserinfo
     * @constructor
     * @param {client_proto.Iuserinfo=} [properties] Properties to set
     */
    function userinfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * userinfo user_name.
     * @member {string} user_name
     * @memberof client_proto.userinfo
     * @instance
     */
    userinfo.prototype.user_name = "";

    /**
     * userinfo user_avatar.
     * @member {string} user_avatar
     * @memberof client_proto.userinfo
     * @instance
     */
    userinfo.prototype.user_avatar = "";

    /**
     * userinfo user_gender.
     * @member {string} user_gender
     * @memberof client_proto.userinfo
     * @instance
     */
    userinfo.prototype.user_gender = "";

    /**
     * userinfo telephone.
     * @member {string} telephone
     * @memberof client_proto.userinfo
     * @instance
     */
    userinfo.prototype.telephone = "";

    /**
     * userinfo email.
     * @member {string} email
     * @memberof client_proto.userinfo
     * @instance
     */
    userinfo.prototype.email = "";

    /**
     * Creates a new userinfo instance using the specified properties.
     * @function create
     * @memberof client_proto.userinfo
     * @static
     * @param {client_proto.Iuserinfo=} [properties] Properties to set
     * @returns {client_proto.userinfo} userinfo instance
     */
    userinfo.create = function create(properties) {
      return new userinfo(properties);
    };

    /**
     * Encodes the specified userinfo message. Does not implicitly {@link client_proto.userinfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.userinfo
     * @static
     * @param {client_proto.Iuserinfo} message userinfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    userinfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_name != null && Object.hasOwnProperty.call(message, "user_name"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.user_name);
      if (message.user_avatar != null && Object.hasOwnProperty.call(message, "user_avatar"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.user_avatar);
      if (message.user_gender != null && Object.hasOwnProperty.call(message, "user_gender"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.user_gender);
      if (message.telephone != null && Object.hasOwnProperty.call(message, "telephone"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.telephone);
      if (message.email != null && Object.hasOwnProperty.call(message, "email"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.email);
      return writer;
    };

    /**
     * Encodes the specified userinfo message, length delimited. Does not implicitly {@link client_proto.userinfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.userinfo
     * @static
     * @param {client_proto.Iuserinfo} message userinfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    userinfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a userinfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.userinfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.userinfo} userinfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    userinfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.userinfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_name = reader.string();
            break;
          }
          case 2: {
            message.user_avatar = reader.string();
            break;
          }
          case 3: {
            message.user_gender = reader.string();
            break;
          }
          case 4: {
            message.telephone = reader.string();
            break;
          }
          case 5: {
            message.email = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a userinfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.userinfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.userinfo} userinfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    userinfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a userinfo message.
     * @function verify
     * @memberof client_proto.userinfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    userinfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_name != null && message.hasOwnProperty("user_name"))
        if (!$util.isString(message.user_name)) return "user_name: string expected";
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        if (!$util.isString(message.user_avatar)) return "user_avatar: string expected";
      if (message.user_gender != null && message.hasOwnProperty("user_gender"))
        if (!$util.isString(message.user_gender)) return "user_gender: string expected";
      if (message.telephone != null && message.hasOwnProperty("telephone"))
        if (!$util.isString(message.telephone)) return "telephone: string expected";
      if (message.email != null && message.hasOwnProperty("email"))
        if (!$util.isString(message.email)) return "email: string expected";
      return null;
    };

    /**
     * Creates a userinfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.userinfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.userinfo} userinfo
     */
    userinfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.userinfo) return object;
      var message = new $root.client_proto.userinfo();
      if (object.user_name != null) message.user_name = String(object.user_name);
      if (object.user_avatar != null) message.user_avatar = String(object.user_avatar);
      if (object.user_gender != null) message.user_gender = String(object.user_gender);
      if (object.telephone != null) message.telephone = String(object.telephone);
      if (object.email != null) message.email = String(object.email);
      return message;
    };

    /**
     * Creates a plain object from a userinfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.userinfo
     * @static
     * @param {client_proto.userinfo} message userinfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    userinfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.user_name = "";
        object.user_avatar = "";
        object.user_gender = "";
        object.telephone = "";
        object.email = "";
      }
      if (message.user_name != null && message.hasOwnProperty("user_name")) object.user_name = message.user_name;
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        object.user_avatar = message.user_avatar;
      if (message.user_gender != null && message.hasOwnProperty("user_gender"))
        object.user_gender = message.user_gender;
      if (message.telephone != null && message.hasOwnProperty("telephone")) object.telephone = message.telephone;
      if (message.email != null && message.hasOwnProperty("email")) object.email = message.email;
      return object;
    };

    /**
     * Converts this userinfo to JSON.
     * @function toJSON
     * @memberof client_proto.userinfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    userinfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for userinfo
     * @function getTypeUrl
     * @memberof client_proto.userinfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    userinfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.userinfo";
    };

    return userinfo;
  })();

  client_proto.SetUserInfoResp = (function () {
    /**
     * Properties of a SetUserInfoResp.
     * @memberof client_proto
     * @interface ISetUserInfoResp
     * @property {number|null} [result] SetUserInfoResp result
     * @property {client_proto.Iuserinfo|null} [datas] SetUserInfoResp datas
     */

    /**
     * Constructs a new SetUserInfoResp.
     * @memberof client_proto
     * @classdesc Represents a SetUserInfoResp.
     * @implements ISetUserInfoResp
     * @constructor
     * @param {client_proto.ISetUserInfoResp=} [properties] Properties to set
     */
    function SetUserInfoResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetUserInfoResp result.
     * @member {number} result
     * @memberof client_proto.SetUserInfoResp
     * @instance
     */
    SetUserInfoResp.prototype.result = 0;

    /**
     * SetUserInfoResp datas.
     * @member {client_proto.Iuserinfo|null|undefined} datas
     * @memberof client_proto.SetUserInfoResp
     * @instance
     */
    SetUserInfoResp.prototype.datas = null;

    /**
     * Creates a new SetUserInfoResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SetUserInfoResp
     * @static
     * @param {client_proto.ISetUserInfoResp=} [properties] Properties to set
     * @returns {client_proto.SetUserInfoResp} SetUserInfoResp instance
     */
    SetUserInfoResp.create = function create(properties) {
      return new SetUserInfoResp(properties);
    };

    /**
     * Encodes the specified SetUserInfoResp message. Does not implicitly {@link client_proto.SetUserInfoResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SetUserInfoResp
     * @static
     * @param {client_proto.ISetUserInfoResp} message SetUserInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetUserInfoResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.datas != null && Object.hasOwnProperty.call(message, "datas"))
        $root.client_proto.userinfo.encode(message.datas, writer.uint32(/* id 2, wireType 2 =*/ 18).fork()).ldelim();
      return writer;
    };

    /**
     * Encodes the specified SetUserInfoResp message, length delimited. Does not implicitly {@link client_proto.SetUserInfoResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SetUserInfoResp
     * @static
     * @param {client_proto.ISetUserInfoResp} message SetUserInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetUserInfoResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetUserInfoResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SetUserInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SetUserInfoResp} SetUserInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetUserInfoResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SetUserInfoResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.datas = $root.client_proto.userinfo.decode(reader, reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetUserInfoResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SetUserInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SetUserInfoResp} SetUserInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetUserInfoResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetUserInfoResp message.
     * @function verify
     * @memberof client_proto.SetUserInfoResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetUserInfoResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.datas != null && message.hasOwnProperty("datas")) {
        var error = $root.client_proto.userinfo.verify(message.datas);
        if (error) return "datas." + error;
      }
      return null;
    };

    /**
     * Creates a SetUserInfoResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SetUserInfoResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SetUserInfoResp} SetUserInfoResp
     */
    SetUserInfoResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SetUserInfoResp) return object;
      var message = new $root.client_proto.SetUserInfoResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.datas != null) {
        if (typeof object.datas !== "object") throw TypeError(".client_proto.SetUserInfoResp.datas: object expected");
        message.datas = $root.client_proto.userinfo.fromObject(object.datas);
      }
      return message;
    };

    /**
     * Creates a plain object from a SetUserInfoResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SetUserInfoResp
     * @static
     * @param {client_proto.SetUserInfoResp} message SetUserInfoResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetUserInfoResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.datas = null;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.datas != null && message.hasOwnProperty("datas"))
        object.datas = $root.client_proto.userinfo.toObject(message.datas, options);
      return object;
    };

    /**
     * Converts this SetUserInfoResp to JSON.
     * @function toJSON
     * @memberof client_proto.SetUserInfoResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetUserInfoResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetUserInfoResp
     * @function getTypeUrl
     * @memberof client_proto.SetUserInfoResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetUserInfoResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SetUserInfoResp";
    };

    return SetUserInfoResp;
  })();

  client_proto.GetUserBasicInfoReq = (function () {
    /**
     * Properties of a GetUserBasicInfoReq.
     * @memberof client_proto
     * @interface IGetUserBasicInfoReq
     * @property {number|null} [user_id] GetUserBasicInfoReq user_id
     * @property {Uint8Array|null} [trans] GetUserBasicInfoReq trans
     */

    /**
     * Constructs a new GetUserBasicInfoReq.
     * @memberof client_proto
     * @classdesc Represents a GetUserBasicInfoReq.
     * @implements IGetUserBasicInfoReq
     * @constructor
     * @param {client_proto.IGetUserBasicInfoReq=} [properties] Properties to set
     */
    function GetUserBasicInfoReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserBasicInfoReq user_id.
     * @member {number} user_id
     * @memberof client_proto.GetUserBasicInfoReq
     * @instance
     */
    GetUserBasicInfoReq.prototype.user_id = 0;

    /**
     * GetUserBasicInfoReq trans.
     * @member {Uint8Array} trans
     * @memberof client_proto.GetUserBasicInfoReq
     * @instance
     */
    GetUserBasicInfoReq.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new GetUserBasicInfoReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserBasicInfoReq
     * @static
     * @param {client_proto.IGetUserBasicInfoReq=} [properties] Properties to set
     * @returns {client_proto.GetUserBasicInfoReq} GetUserBasicInfoReq instance
     */
    GetUserBasicInfoReq.create = function create(properties) {
      return new GetUserBasicInfoReq(properties);
    };

    /**
     * Encodes the specified GetUserBasicInfoReq message. Does not implicitly {@link client_proto.GetUserBasicInfoReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserBasicInfoReq
     * @static
     * @param {client_proto.IGetUserBasicInfoReq} message GetUserBasicInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserBasicInfoReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.user_id);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified GetUserBasicInfoReq message, length delimited. Does not implicitly {@link client_proto.GetUserBasicInfoReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserBasicInfoReq
     * @static
     * @param {client_proto.IGetUserBasicInfoReq} message GetUserBasicInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserBasicInfoReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserBasicInfoReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserBasicInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserBasicInfoReq} GetUserBasicInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserBasicInfoReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserBasicInfoReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_id = reader.uint32();
            break;
          }
          case 2: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserBasicInfoReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserBasicInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserBasicInfoReq} GetUserBasicInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserBasicInfoReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserBasicInfoReq message.
     * @function verify
     * @memberof client_proto.GetUserBasicInfoReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserBasicInfoReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates a GetUserBasicInfoReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserBasicInfoReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserBasicInfoReq} GetUserBasicInfoReq
     */
    GetUserBasicInfoReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserBasicInfoReq) return object;
      var message = new $root.client_proto.GetUserBasicInfoReq();
      if (object.user_id != null) message.user_id = object.user_id >>> 0;
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from a GetUserBasicInfoReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserBasicInfoReq
     * @static
     * @param {client_proto.GetUserBasicInfoReq} message GetUserBasicInfoReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserBasicInfoReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.user_id = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this GetUserBasicInfoReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserBasicInfoReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserBasicInfoReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserBasicInfoReq
     * @function getTypeUrl
     * @memberof client_proto.GetUserBasicInfoReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserBasicInfoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserBasicInfoReq";
    };

    return GetUserBasicInfoReq;
  })();

  client_proto.GetUserBasicInfoResp = (function () {
    /**
     * Properties of a GetUserBasicInfoResp.
     * @memberof client_proto
     * @interface IGetUserBasicInfoResp
     * @property {string|null} [user_name] GetUserBasicInfoResp user_name
     * @property {string|null} [user_avatar] GetUserBasicInfoResp user_avatar
     * @property {string|null} [user_gender] GetUserBasicInfoResp user_gender
     * @property {string|null} [telephone] GetUserBasicInfoResp telephone
     * @property {string|null} [email] GetUserBasicInfoResp email
     * @property {string|null} [lang] GetUserBasicInfoResp lang
     * @property {number|null} [user_id] GetUserBasicInfoResp user_id
     * @property {string|null} [ip] GetUserBasicInfoResp ip
     * @property {number|null} [reg_time] GetUserBasicInfoResp reg_time
     * @property {string|null} [device_id] GetUserBasicInfoResp device_id
     * @property {string|null} [invite_code] GetUserBasicInfoResp invite_code
     * @property {string|null} [channel] GetUserBasicInfoResp channel
     * @property {number|null} [vip_level] GetUserBasicInfoResp vip_level
     * @property {Uint8Array|null} [trans] GetUserBasicInfoResp trans
     * @property {string|null} [bank_account] GetUserBasicInfoResp bank_account
     */

    /**
     * Constructs a new GetUserBasicInfoResp.
     * @memberof client_proto
     * @classdesc Represents a GetUserBasicInfoResp.
     * @implements IGetUserBasicInfoResp
     * @constructor
     * @param {client_proto.IGetUserBasicInfoResp=} [properties] Properties to set
     */
    function GetUserBasicInfoResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserBasicInfoResp user_name.
     * @member {string} user_name
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.user_name = "";

    /**
     * GetUserBasicInfoResp user_avatar.
     * @member {string} user_avatar
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.user_avatar = "";

    /**
     * GetUserBasicInfoResp user_gender.
     * @member {string} user_gender
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.user_gender = "";

    /**
     * GetUserBasicInfoResp telephone.
     * @member {string} telephone
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.telephone = "";

    /**
     * GetUserBasicInfoResp email.
     * @member {string} email
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.email = "";

    /**
     * GetUserBasicInfoResp lang.
     * @member {string} lang
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.lang = "";

    /**
     * GetUserBasicInfoResp user_id.
     * @member {number} user_id
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.user_id = 0;

    /**
     * GetUserBasicInfoResp ip.
     * @member {string} ip
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.ip = "";

    /**
     * GetUserBasicInfoResp reg_time.
     * @member {number} reg_time
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.reg_time = 0;

    /**
     * GetUserBasicInfoResp device_id.
     * @member {string} device_id
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.device_id = "";

    /**
     * GetUserBasicInfoResp invite_code.
     * @member {string} invite_code
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.invite_code = "";

    /**
     * GetUserBasicInfoResp channel.
     * @member {string} channel
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.channel = "";

    /**
     * GetUserBasicInfoResp vip_level.
     * @member {number} vip_level
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.vip_level = 0;

    /**
     * GetUserBasicInfoResp trans.
     * @member {Uint8Array} trans
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.trans = $util.newBuffer([]);

    /**
     * GetUserBasicInfoResp bank_account.
     * @member {string} bank_account
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     */
    GetUserBasicInfoResp.prototype.bank_account = "";

    /**
     * Creates a new GetUserBasicInfoResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserBasicInfoResp
     * @static
     * @param {client_proto.IGetUserBasicInfoResp=} [properties] Properties to set
     * @returns {client_proto.GetUserBasicInfoResp} GetUserBasicInfoResp instance
     */
    GetUserBasicInfoResp.create = function create(properties) {
      return new GetUserBasicInfoResp(properties);
    };

    /**
     * Encodes the specified GetUserBasicInfoResp message. Does not implicitly {@link client_proto.GetUserBasicInfoResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserBasicInfoResp
     * @static
     * @param {client_proto.IGetUserBasicInfoResp} message GetUserBasicInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserBasicInfoResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_name != null && Object.hasOwnProperty.call(message, "user_name"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.user_name);
      if (message.user_avatar != null && Object.hasOwnProperty.call(message, "user_avatar"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.user_avatar);
      if (message.user_gender != null && Object.hasOwnProperty.call(message, "user_gender"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.user_gender);
      if (message.telephone != null && Object.hasOwnProperty.call(message, "telephone"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.telephone);
      if (message.email != null && Object.hasOwnProperty.call(message, "email"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.email);
      if (message.lang != null && Object.hasOwnProperty.call(message, "lang"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.lang);
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).uint32(message.user_id);
      if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.ip);
      if (message.reg_time != null && Object.hasOwnProperty.call(message, "reg_time"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int32(message.reg_time);
      if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.device_id);
      if (message.invite_code != null && Object.hasOwnProperty.call(message, "invite_code"))
        writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.invite_code);
      if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
        writer.uint32(/* id 13, wireType 2 =*/ 106).string(message.channel);
      if (message.vip_level != null && Object.hasOwnProperty.call(message, "vip_level"))
        writer.uint32(/* id 14, wireType 0 =*/ 112).int32(message.vip_level);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 15, wireType 2 =*/ 122).bytes(message.trans);
      if (message.bank_account != null && Object.hasOwnProperty.call(message, "bank_account"))
        writer.uint32(/* id 16, wireType 2 =*/ 130).string(message.bank_account);
      return writer;
    };

    /**
     * Encodes the specified GetUserBasicInfoResp message, length delimited. Does not implicitly {@link client_proto.GetUserBasicInfoResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserBasicInfoResp
     * @static
     * @param {client_proto.IGetUserBasicInfoResp} message GetUserBasicInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserBasicInfoResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserBasicInfoResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserBasicInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserBasicInfoResp} GetUserBasicInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserBasicInfoResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserBasicInfoResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_name = reader.string();
            break;
          }
          case 2: {
            message.user_avatar = reader.string();
            break;
          }
          case 3: {
            message.user_gender = reader.string();
            break;
          }
          case 4: {
            message.telephone = reader.string();
            break;
          }
          case 5: {
            message.email = reader.string();
            break;
          }
          case 6: {
            message.lang = reader.string();
            break;
          }
          case 7: {
            message.user_id = reader.uint32();
            break;
          }
          case 8: {
            message.ip = reader.string();
            break;
          }
          case 9: {
            message.reg_time = reader.int32();
            break;
          }
          case 10: {
            message.device_id = reader.string();
            break;
          }
          case 12: {
            message.invite_code = reader.string();
            break;
          }
          case 13: {
            message.channel = reader.string();
            break;
          }
          case 14: {
            message.vip_level = reader.int32();
            break;
          }
          case 15: {
            message.trans = reader.bytes();
            break;
          }
          case 16: {
            message.bank_account = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserBasicInfoResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserBasicInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserBasicInfoResp} GetUserBasicInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserBasicInfoResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserBasicInfoResp message.
     * @function verify
     * @memberof client_proto.GetUserBasicInfoResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserBasicInfoResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_name != null && message.hasOwnProperty("user_name"))
        if (!$util.isString(message.user_name)) return "user_name: string expected";
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        if (!$util.isString(message.user_avatar)) return "user_avatar: string expected";
      if (message.user_gender != null && message.hasOwnProperty("user_gender"))
        if (!$util.isString(message.user_gender)) return "user_gender: string expected";
      if (message.telephone != null && message.hasOwnProperty("telephone"))
        if (!$util.isString(message.telephone)) return "telephone: string expected";
      if (message.email != null && message.hasOwnProperty("email"))
        if (!$util.isString(message.email)) return "email: string expected";
      if (message.lang != null && message.hasOwnProperty("lang"))
        if (!$util.isString(message.lang)) return "lang: string expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      if (message.ip != null && message.hasOwnProperty("ip"))
        if (!$util.isString(message.ip)) return "ip: string expected";
      if (message.reg_time != null && message.hasOwnProperty("reg_time"))
        if (!$util.isInteger(message.reg_time)) return "reg_time: integer expected";
      if (message.device_id != null && message.hasOwnProperty("device_id"))
        if (!$util.isString(message.device_id)) return "device_id: string expected";
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        if (!$util.isString(message.invite_code)) return "invite_code: string expected";
      if (message.channel != null && message.hasOwnProperty("channel"))
        if (!$util.isString(message.channel)) return "channel: string expected";
      if (message.vip_level != null && message.hasOwnProperty("vip_level"))
        if (!$util.isInteger(message.vip_level)) return "vip_level: integer expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      if (message.bank_account != null && message.hasOwnProperty("bank_account"))
        if (!$util.isString(message.bank_account)) return "bank_account: string expected";
      return null;
    };

    /**
     * Creates a GetUserBasicInfoResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserBasicInfoResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserBasicInfoResp} GetUserBasicInfoResp
     */
    GetUserBasicInfoResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserBasicInfoResp) return object;
      var message = new $root.client_proto.GetUserBasicInfoResp();
      if (object.user_name != null) message.user_name = String(object.user_name);
      if (object.user_avatar != null) message.user_avatar = String(object.user_avatar);
      if (object.user_gender != null) message.user_gender = String(object.user_gender);
      if (object.telephone != null) message.telephone = String(object.telephone);
      if (object.email != null) message.email = String(object.email);
      if (object.lang != null) message.lang = String(object.lang);
      if (object.user_id != null) message.user_id = object.user_id >>> 0;
      if (object.ip != null) message.ip = String(object.ip);
      if (object.reg_time != null) message.reg_time = object.reg_time | 0;
      if (object.device_id != null) message.device_id = String(object.device_id);
      if (object.invite_code != null) message.invite_code = String(object.invite_code);
      if (object.channel != null) message.channel = String(object.channel);
      if (object.vip_level != null) message.vip_level = object.vip_level | 0;
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      if (object.bank_account != null) message.bank_account = String(object.bank_account);
      return message;
    };

    /**
     * Creates a plain object from a GetUserBasicInfoResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserBasicInfoResp
     * @static
     * @param {client_proto.GetUserBasicInfoResp} message GetUserBasicInfoResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserBasicInfoResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.user_name = "";
        object.user_avatar = "";
        object.user_gender = "";
        object.telephone = "";
        object.email = "";
        object.lang = "";
        object.user_id = 0;
        object.ip = "";
        object.reg_time = 0;
        object.device_id = "";
        object.invite_code = "";
        object.channel = "";
        object.vip_level = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
        object.bank_account = "";
      }
      if (message.user_name != null && message.hasOwnProperty("user_name")) object.user_name = message.user_name;
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        object.user_avatar = message.user_avatar;
      if (message.user_gender != null && message.hasOwnProperty("user_gender"))
        object.user_gender = message.user_gender;
      if (message.telephone != null && message.hasOwnProperty("telephone")) object.telephone = message.telephone;
      if (message.email != null && message.hasOwnProperty("email")) object.email = message.email;
      if (message.lang != null && message.hasOwnProperty("lang")) object.lang = message.lang;
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      if (message.ip != null && message.hasOwnProperty("ip")) object.ip = message.ip;
      if (message.reg_time != null && message.hasOwnProperty("reg_time")) object.reg_time = message.reg_time;
      if (message.device_id != null && message.hasOwnProperty("device_id")) object.device_id = message.device_id;
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        object.invite_code = message.invite_code;
      if (message.channel != null && message.hasOwnProperty("channel")) object.channel = message.channel;
      if (message.vip_level != null && message.hasOwnProperty("vip_level")) object.vip_level = message.vip_level;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      if (message.bank_account != null && message.hasOwnProperty("bank_account"))
        object.bank_account = message.bank_account;
      return object;
    };

    /**
     * Converts this GetUserBasicInfoResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserBasicInfoResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserBasicInfoResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserBasicInfoResp
     * @function getTypeUrl
     * @memberof client_proto.GetUserBasicInfoResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserBasicInfoResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserBasicInfoResp";
    };

    return GetUserBasicInfoResp;
  })();

  client_proto.SetLangCodeReq = (function () {
    /**
     * Properties of a SetLangCodeReq.
     * @memberof client_proto
     * @interface ISetLangCodeReq
     * @property {string|null} [lang] SetLangCodeReq lang
     */

    /**
     * Constructs a new SetLangCodeReq.
     * @memberof client_proto
     * @classdesc Represents a SetLangCodeReq.
     * @implements ISetLangCodeReq
     * @constructor
     * @param {client_proto.ISetLangCodeReq=} [properties] Properties to set
     */
    function SetLangCodeReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetLangCodeReq lang.
     * @member {string} lang
     * @memberof client_proto.SetLangCodeReq
     * @instance
     */
    SetLangCodeReq.prototype.lang = "";

    /**
     * Creates a new SetLangCodeReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SetLangCodeReq
     * @static
     * @param {client_proto.ISetLangCodeReq=} [properties] Properties to set
     * @returns {client_proto.SetLangCodeReq} SetLangCodeReq instance
     */
    SetLangCodeReq.create = function create(properties) {
      return new SetLangCodeReq(properties);
    };

    /**
     * Encodes the specified SetLangCodeReq message. Does not implicitly {@link client_proto.SetLangCodeReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SetLangCodeReq
     * @static
     * @param {client_proto.ISetLangCodeReq} message SetLangCodeReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLangCodeReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.lang != null && Object.hasOwnProperty.call(message, "lang"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.lang);
      return writer;
    };

    /**
     * Encodes the specified SetLangCodeReq message, length delimited. Does not implicitly {@link client_proto.SetLangCodeReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SetLangCodeReq
     * @static
     * @param {client_proto.ISetLangCodeReq} message SetLangCodeReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLangCodeReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetLangCodeReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SetLangCodeReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SetLangCodeReq} SetLangCodeReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLangCodeReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SetLangCodeReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.lang = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetLangCodeReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SetLangCodeReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SetLangCodeReq} SetLangCodeReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLangCodeReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetLangCodeReq message.
     * @function verify
     * @memberof client_proto.SetLangCodeReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetLangCodeReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.lang != null && message.hasOwnProperty("lang"))
        if (!$util.isString(message.lang)) return "lang: string expected";
      return null;
    };

    /**
     * Creates a SetLangCodeReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SetLangCodeReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SetLangCodeReq} SetLangCodeReq
     */
    SetLangCodeReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SetLangCodeReq) return object;
      var message = new $root.client_proto.SetLangCodeReq();
      if (object.lang != null) message.lang = String(object.lang);
      return message;
    };

    /**
     * Creates a plain object from a SetLangCodeReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SetLangCodeReq
     * @static
     * @param {client_proto.SetLangCodeReq} message SetLangCodeReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetLangCodeReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.lang = "";
      if (message.lang != null && message.hasOwnProperty("lang")) object.lang = message.lang;
      return object;
    };

    /**
     * Converts this SetLangCodeReq to JSON.
     * @function toJSON
     * @memberof client_proto.SetLangCodeReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetLangCodeReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetLangCodeReq
     * @function getTypeUrl
     * @memberof client_proto.SetLangCodeReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetLangCodeReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SetLangCodeReq";
    };

    return SetLangCodeReq;
  })();

  client_proto.SetLangCodeResp = (function () {
    /**
     * Properties of a SetLangCodeResp.
     * @memberof client_proto
     * @interface ISetLangCodeResp
     * @property {number|null} [result] SetLangCodeResp result
     * @property {string|null} [lang] SetLangCodeResp lang
     */

    /**
     * Constructs a new SetLangCodeResp.
     * @memberof client_proto
     * @classdesc Represents a SetLangCodeResp.
     * @implements ISetLangCodeResp
     * @constructor
     * @param {client_proto.ISetLangCodeResp=} [properties] Properties to set
     */
    function SetLangCodeResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SetLangCodeResp result.
     * @member {number} result
     * @memberof client_proto.SetLangCodeResp
     * @instance
     */
    SetLangCodeResp.prototype.result = 0;

    /**
     * SetLangCodeResp lang.
     * @member {string} lang
     * @memberof client_proto.SetLangCodeResp
     * @instance
     */
    SetLangCodeResp.prototype.lang = "";

    /**
     * Creates a new SetLangCodeResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SetLangCodeResp
     * @static
     * @param {client_proto.ISetLangCodeResp=} [properties] Properties to set
     * @returns {client_proto.SetLangCodeResp} SetLangCodeResp instance
     */
    SetLangCodeResp.create = function create(properties) {
      return new SetLangCodeResp(properties);
    };

    /**
     * Encodes the specified SetLangCodeResp message. Does not implicitly {@link client_proto.SetLangCodeResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SetLangCodeResp
     * @static
     * @param {client_proto.ISetLangCodeResp} message SetLangCodeResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLangCodeResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.lang != null && Object.hasOwnProperty.call(message, "lang"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.lang);
      return writer;
    };

    /**
     * Encodes the specified SetLangCodeResp message, length delimited. Does not implicitly {@link client_proto.SetLangCodeResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SetLangCodeResp
     * @static
     * @param {client_proto.ISetLangCodeResp} message SetLangCodeResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SetLangCodeResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SetLangCodeResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SetLangCodeResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SetLangCodeResp} SetLangCodeResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLangCodeResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SetLangCodeResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.lang = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SetLangCodeResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SetLangCodeResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SetLangCodeResp} SetLangCodeResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SetLangCodeResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SetLangCodeResp message.
     * @function verify
     * @memberof client_proto.SetLangCodeResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SetLangCodeResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.lang != null && message.hasOwnProperty("lang"))
        if (!$util.isString(message.lang)) return "lang: string expected";
      return null;
    };

    /**
     * Creates a SetLangCodeResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SetLangCodeResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SetLangCodeResp} SetLangCodeResp
     */
    SetLangCodeResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SetLangCodeResp) return object;
      var message = new $root.client_proto.SetLangCodeResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.lang != null) message.lang = String(object.lang);
      return message;
    };

    /**
     * Creates a plain object from a SetLangCodeResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SetLangCodeResp
     * @static
     * @param {client_proto.SetLangCodeResp} message SetLangCodeResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SetLangCodeResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.lang = "";
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.lang != null && message.hasOwnProperty("lang")) object.lang = message.lang;
      return object;
    };

    /**
     * Converts this SetLangCodeResp to JSON.
     * @function toJSON
     * @memberof client_proto.SetLangCodeResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SetLangCodeResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SetLangCodeResp
     * @function getTypeUrl
     * @memberof client_proto.SetLangCodeResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SetLangCodeResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SetLangCodeResp";
    };

    return SetLangCodeResp;
  })();

  client_proto.ForbitUserReq = (function () {
    /**
     * Properties of a ForbitUserReq.
     * @memberof client_proto
     * @interface IForbitUserReq
     * @property {number|null} [user_id] ForbitUserReq user_id
     * @property {number|null} [status] ForbitUserReq status
     * @property {client_proto.ForbitUserReq.FORBIT_TYPE|null} [reason] ForbitUserReq reason
     */

    /**
     * Constructs a new ForbitUserReq.
     * @memberof client_proto
     * @classdesc Represents a ForbitUserReq.
     * @implements IForbitUserReq
     * @constructor
     * @param {client_proto.IForbitUserReq=} [properties] Properties to set
     */
    function ForbitUserReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ForbitUserReq user_id.
     * @member {number} user_id
     * @memberof client_proto.ForbitUserReq
     * @instance
     */
    ForbitUserReq.prototype.user_id = 0;

    /**
     * ForbitUserReq status.
     * @member {number} status
     * @memberof client_proto.ForbitUserReq
     * @instance
     */
    ForbitUserReq.prototype.status = 0;

    /**
     * ForbitUserReq reason.
     * @member {client_proto.ForbitUserReq.FORBIT_TYPE} reason
     * @memberof client_proto.ForbitUserReq
     * @instance
     */
    ForbitUserReq.prototype.reason = 0;

    /**
     * Creates a new ForbitUserReq instance using the specified properties.
     * @function create
     * @memberof client_proto.ForbitUserReq
     * @static
     * @param {client_proto.IForbitUserReq=} [properties] Properties to set
     * @returns {client_proto.ForbitUserReq} ForbitUserReq instance
     */
    ForbitUserReq.create = function create(properties) {
      return new ForbitUserReq(properties);
    };

    /**
     * Encodes the specified ForbitUserReq message. Does not implicitly {@link client_proto.ForbitUserReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ForbitUserReq
     * @static
     * @param {client_proto.IForbitUserReq} message ForbitUserReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForbitUserReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.user_id);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.status);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.reason);
      return writer;
    };

    /**
     * Encodes the specified ForbitUserReq message, length delimited. Does not implicitly {@link client_proto.ForbitUserReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ForbitUserReq
     * @static
     * @param {client_proto.IForbitUserReq} message ForbitUserReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForbitUserReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ForbitUserReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ForbitUserReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ForbitUserReq} ForbitUserReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForbitUserReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ForbitUserReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_id = reader.uint32();
            break;
          }
          case 2: {
            message.status = reader.int32();
            break;
          }
          case 3: {
            message.reason = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ForbitUserReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ForbitUserReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ForbitUserReq} ForbitUserReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForbitUserReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ForbitUserReq message.
     * @function verify
     * @memberof client_proto.ForbitUserReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ForbitUserReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      if (message.reason != null && message.hasOwnProperty("reason"))
        switch (message.reason) {
          default:
            return "reason: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
            break;
        }
      return null;
    };

    /**
     * Creates a ForbitUserReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ForbitUserReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ForbitUserReq} ForbitUserReq
     */
    ForbitUserReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ForbitUserReq) return object;
      var message = new $root.client_proto.ForbitUserReq();
      if (object.user_id != null) message.user_id = object.user_id >>> 0;
      if (object.status != null) message.status = object.status | 0;
      switch (object.reason) {
        default:
          if (typeof object.reason === "number") {
            message.reason = object.reason;
            break;
          }
          break;
        case "FORBIT_NULL":
        case 0:
          message.reason = 0;
          break;
        case "FORBIT_IP":
        case 1:
          message.reason = 1;
          break;
        case "FORBIT_BANK":
        case 2:
          message.reason = 2;
          break;
        case "FORBIT_DEVICE":
        case 3:
          message.reason = 3;
          break;
        case "FORBIT_MOBILE":
        case 4:
          message.reason = 4;
          break;
      }
      return message;
    };

    /**
     * Creates a plain object from a ForbitUserReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ForbitUserReq
     * @static
     * @param {client_proto.ForbitUserReq} message ForbitUserReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ForbitUserReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.user_id = 0;
        object.status = 0;
        object.reason = options.enums === String ? "FORBIT_NULL" : 0;
      }
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      if (message.reason != null && message.hasOwnProperty("reason"))
        object.reason =
          options.enums === String
            ? $root.client_proto.ForbitUserReq.FORBIT_TYPE[message.reason] === undefined
              ? message.reason
              : $root.client_proto.ForbitUserReq.FORBIT_TYPE[message.reason]
            : message.reason;
      return object;
    };

    /**
     * Converts this ForbitUserReq to JSON.
     * @function toJSON
     * @memberof client_proto.ForbitUserReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ForbitUserReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ForbitUserReq
     * @function getTypeUrl
     * @memberof client_proto.ForbitUserReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ForbitUserReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ForbitUserReq";
    };

    /**
     * FORBIT_TYPE enum.
     * @name client_proto.ForbitUserReq.FORBIT_TYPE
     * @enum {number}
     * @property {number} FORBIT_NULL=0 FORBIT_NULL value
     * @property {number} FORBIT_IP=1 FORBIT_IP value
     * @property {number} FORBIT_BANK=2 FORBIT_BANK value
     * @property {number} FORBIT_DEVICE=3 FORBIT_DEVICE value
     * @property {number} FORBIT_MOBILE=4 FORBIT_MOBILE value
     */
    ForbitUserReq.FORBIT_TYPE = (function () {
      var valuesById = {},
        values = Object.create(valuesById);
      values[(valuesById[0] = "FORBIT_NULL")] = 0;
      values[(valuesById[1] = "FORBIT_IP")] = 1;
      values[(valuesById[2] = "FORBIT_BANK")] = 2;
      values[(valuesById[3] = "FORBIT_DEVICE")] = 3;
      values[(valuesById[4] = "FORBIT_MOBILE")] = 4;
      return values;
    })();

    return ForbitUserReq;
  })();

  client_proto.ForbitUserResp = (function () {
    /**
     * Properties of a ForbitUserResp.
     * @memberof client_proto
     * @interface IForbitUserResp
     * @property {number|null} [result] ForbitUserResp result
     */

    /**
     * Constructs a new ForbitUserResp.
     * @memberof client_proto
     * @classdesc Represents a ForbitUserResp.
     * @implements IForbitUserResp
     * @constructor
     * @param {client_proto.IForbitUserResp=} [properties] Properties to set
     */
    function ForbitUserResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ForbitUserResp result.
     * @member {number} result
     * @memberof client_proto.ForbitUserResp
     * @instance
     */
    ForbitUserResp.prototype.result = 0;

    /**
     * Creates a new ForbitUserResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ForbitUserResp
     * @static
     * @param {client_proto.IForbitUserResp=} [properties] Properties to set
     * @returns {client_proto.ForbitUserResp} ForbitUserResp instance
     */
    ForbitUserResp.create = function create(properties) {
      return new ForbitUserResp(properties);
    };

    /**
     * Encodes the specified ForbitUserResp message. Does not implicitly {@link client_proto.ForbitUserResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ForbitUserResp
     * @static
     * @param {client_proto.IForbitUserResp} message ForbitUserResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForbitUserResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified ForbitUserResp message, length delimited. Does not implicitly {@link client_proto.ForbitUserResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ForbitUserResp
     * @static
     * @param {client_proto.IForbitUserResp} message ForbitUserResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForbitUserResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ForbitUserResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ForbitUserResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ForbitUserResp} ForbitUserResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForbitUserResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ForbitUserResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ForbitUserResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ForbitUserResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ForbitUserResp} ForbitUserResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForbitUserResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ForbitUserResp message.
     * @function verify
     * @memberof client_proto.ForbitUserResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ForbitUserResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a ForbitUserResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ForbitUserResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ForbitUserResp} ForbitUserResp
     */
    ForbitUserResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ForbitUserResp) return object;
      var message = new $root.client_proto.ForbitUserResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a ForbitUserResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ForbitUserResp
     * @static
     * @param {client_proto.ForbitUserResp} message ForbitUserResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ForbitUserResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this ForbitUserResp to JSON.
     * @function toJSON
     * @memberof client_proto.ForbitUserResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ForbitUserResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ForbitUserResp
     * @function getTypeUrl
     * @memberof client_proto.ForbitUserResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ForbitUserResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ForbitUserResp";
    };

    return ForbitUserResp;
  })();

  client_proto.ForbitUserPush = (function () {
    /**
     * Properties of a ForbitUserPush.
     * @memberof client_proto
     * @interface IForbitUserPush
     * @property {number|null} [user_id] ForbitUserPush user_id
     */

    /**
     * Constructs a new ForbitUserPush.
     * @memberof client_proto
     * @classdesc Represents a ForbitUserPush.
     * @implements IForbitUserPush
     * @constructor
     * @param {client_proto.IForbitUserPush=} [properties] Properties to set
     */
    function ForbitUserPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ForbitUserPush user_id.
     * @member {number} user_id
     * @memberof client_proto.ForbitUserPush
     * @instance
     */
    ForbitUserPush.prototype.user_id = 0;

    /**
     * Creates a new ForbitUserPush instance using the specified properties.
     * @function create
     * @memberof client_proto.ForbitUserPush
     * @static
     * @param {client_proto.IForbitUserPush=} [properties] Properties to set
     * @returns {client_proto.ForbitUserPush} ForbitUserPush instance
     */
    ForbitUserPush.create = function create(properties) {
      return new ForbitUserPush(properties);
    };

    /**
     * Encodes the specified ForbitUserPush message. Does not implicitly {@link client_proto.ForbitUserPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ForbitUserPush
     * @static
     * @param {client_proto.IForbitUserPush} message ForbitUserPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForbitUserPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.user_id);
      return writer;
    };

    /**
     * Encodes the specified ForbitUserPush message, length delimited. Does not implicitly {@link client_proto.ForbitUserPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ForbitUserPush
     * @static
     * @param {client_proto.IForbitUserPush} message ForbitUserPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForbitUserPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ForbitUserPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ForbitUserPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ForbitUserPush} ForbitUserPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForbitUserPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ForbitUserPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ForbitUserPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ForbitUserPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ForbitUserPush} ForbitUserPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForbitUserPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ForbitUserPush message.
     * @function verify
     * @memberof client_proto.ForbitUserPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ForbitUserPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      return null;
    };

    /**
     * Creates a ForbitUserPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ForbitUserPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ForbitUserPush} ForbitUserPush
     */
    ForbitUserPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ForbitUserPush) return object;
      var message = new $root.client_proto.ForbitUserPush();
      if (object.user_id != null) message.user_id = object.user_id >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a ForbitUserPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ForbitUserPush
     * @static
     * @param {client_proto.ForbitUserPush} message ForbitUserPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ForbitUserPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.user_id = 0;
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      return object;
    };

    /**
     * Converts this ForbitUserPush to JSON.
     * @function toJSON
     * @memberof client_proto.ForbitUserPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ForbitUserPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ForbitUserPush
     * @function getTypeUrl
     * @memberof client_proto.ForbitUserPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ForbitUserPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ForbitUserPush";
    };

    return ForbitUserPush;
  })();

  client_proto.UserVipLevelChangeResp = (function () {
    /**
     * Properties of a UserVipLevelChangeResp.
     * @memberof client_proto
     * @interface IUserVipLevelChangeResp
     * @property {number|null} [uid] UserVipLevelChangeResp uid
     * @property {number|null} [vip_level] UserVipLevelChangeResp vip_level
     */

    /**
     * Constructs a new UserVipLevelChangeResp.
     * @memberof client_proto
     * @classdesc Represents a UserVipLevelChangeResp.
     * @implements IUserVipLevelChangeResp
     * @constructor
     * @param {client_proto.IUserVipLevelChangeResp=} [properties] Properties to set
     */
    function UserVipLevelChangeResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserVipLevelChangeResp uid.
     * @member {number} uid
     * @memberof client_proto.UserVipLevelChangeResp
     * @instance
     */
    UserVipLevelChangeResp.prototype.uid = 0;

    /**
     * UserVipLevelChangeResp vip_level.
     * @member {number} vip_level
     * @memberof client_proto.UserVipLevelChangeResp
     * @instance
     */
    UserVipLevelChangeResp.prototype.vip_level = 0;

    /**
     * Creates a new UserVipLevelChangeResp instance using the specified properties.
     * @function create
     * @memberof client_proto.UserVipLevelChangeResp
     * @static
     * @param {client_proto.IUserVipLevelChangeResp=} [properties] Properties to set
     * @returns {client_proto.UserVipLevelChangeResp} UserVipLevelChangeResp instance
     */
    UserVipLevelChangeResp.create = function create(properties) {
      return new UserVipLevelChangeResp(properties);
    };

    /**
     * Encodes the specified UserVipLevelChangeResp message. Does not implicitly {@link client_proto.UserVipLevelChangeResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserVipLevelChangeResp
     * @static
     * @param {client_proto.IUserVipLevelChangeResp} message UserVipLevelChangeResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserVipLevelChangeResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.vip_level != null && Object.hasOwnProperty.call(message, "vip_level"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.vip_level);
      return writer;
    };

    /**
     * Encodes the specified UserVipLevelChangeResp message, length delimited. Does not implicitly {@link client_proto.UserVipLevelChangeResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserVipLevelChangeResp
     * @static
     * @param {client_proto.IUserVipLevelChangeResp} message UserVipLevelChangeResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserVipLevelChangeResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserVipLevelChangeResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserVipLevelChangeResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserVipLevelChangeResp} UserVipLevelChangeResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserVipLevelChangeResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserVipLevelChangeResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.vip_level = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserVipLevelChangeResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserVipLevelChangeResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserVipLevelChangeResp} UserVipLevelChangeResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserVipLevelChangeResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserVipLevelChangeResp message.
     * @function verify
     * @memberof client_proto.UserVipLevelChangeResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserVipLevelChangeResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.vip_level != null && message.hasOwnProperty("vip_level"))
        if (!$util.isInteger(message.vip_level)) return "vip_level: integer expected";
      return null;
    };

    /**
     * Creates a UserVipLevelChangeResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserVipLevelChangeResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserVipLevelChangeResp} UserVipLevelChangeResp
     */
    UserVipLevelChangeResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserVipLevelChangeResp) return object;
      var message = new $root.client_proto.UserVipLevelChangeResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.vip_level != null) message.vip_level = object.vip_level | 0;
      return message;
    };

    /**
     * Creates a plain object from a UserVipLevelChangeResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserVipLevelChangeResp
     * @static
     * @param {client_proto.UserVipLevelChangeResp} message UserVipLevelChangeResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserVipLevelChangeResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.vip_level = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.vip_level != null && message.hasOwnProperty("vip_level")) object.vip_level = message.vip_level;
      return object;
    };

    /**
     * Converts this UserVipLevelChangeResp to JSON.
     * @function toJSON
     * @memberof client_proto.UserVipLevelChangeResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserVipLevelChangeResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserVipLevelChangeResp
     * @function getTypeUrl
     * @memberof client_proto.UserVipLevelChangeResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserVipLevelChangeResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserVipLevelChangeResp";
    };

    return UserVipLevelChangeResp;
  })();

  /**
   * RANK_LIST_TYPE enum.
   * @name client_proto.RANK_LIST_TYPE
   * @enum {number}
   * @property {number} RANK_LIST_NULL=0 RANK_LIST_NULL value
   * @property {number} RANK_LIST_DAY=1 RANK_LIST_DAY value
   * @property {number} RANK_LIST_WEEK=2 RANK_LIST_WEEK value
   * @property {number} RANK_LIST_MONTH=3 RANK_LIST_MONTH value
   */
  client_proto.RANK_LIST_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "RANK_LIST_NULL")] = 0;
    values[(valuesById[1] = "RANK_LIST_DAY")] = 1;
    values[(valuesById[2] = "RANK_LIST_WEEK")] = 2;
    values[(valuesById[3] = "RANK_LIST_MONTH")] = 3;
    return values;
  })();

  /**
   * RANK_INFO_SUB_MSG_ID enum.
   * @name client_proto.RANK_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} RANK_MSG_ID_NULL=0 RANK_MSG_ID_NULL value
   * @property {number} RANK_MSG_ID_MIN=1400 RANK_MSG_ID_MIN value
   * @property {number} RANK_MSG_ID_RANK_LIST=1401 RANK_MSG_ID_RANK_LIST value
   * @property {number} RANK_MSG_ID_RANK_LIST_RESP=1402 RANK_MSG_ID_RANK_LIST_RESP value
   * @property {number} RANK_MSG_ID_RANK_JACKPOT=1403 RANK_MSG_ID_RANK_JACKPOT value
   * @property {number} RANK_MSG_ID_RANK_JACKPOT_RESP=1404 RANK_MSG_ID_RANK_JACKPOT_RESP value
   * @property {number} RANK_MSG_ID_RANK_JOIN_GAME_LIST=1405 RANK_MSG_ID_RANK_JOIN_GAME_LIST value
   * @property {number} RANK_MSG_ID_RANK_JOIN_GAME_LIST_RESP=1406 RANK_MSG_ID_RANK_JOIN_GAME_LIST_RESP value
   * @property {number} RANK_MSG_ID_RANK_REWARD_LIST=1407 RANK_MSG_ID_RANK_REWARD_LIST value
   * @property {number} RANK_MSG_ID_RANK_REWARD_LIST_RESP=1408 RANK_MSG_ID_RANK_REWARD_LIST_RESP value
   * @property {number} RANK_MSG_ID_RANK_SHOW_LIST=1409 RANK_MSG_ID_RANK_SHOW_LIST value
   * @property {number} RANK_MSG_ID_RANK_SHOW_LIST_RESP=1410 RANK_MSG_ID_RANK_SHOW_LIST_RESP value
   * @property {number} RANK_MSG_ID_RANK_GET_REWARD=1411 RANK_MSG_ID_RANK_GET_REWARD value
   * @property {number} RANK_MSG_ID_RANK_GET_REWARD_RESP=1412 RANK_MSG_ID_RANK_GET_REWARD_RESP value
   * @property {number} RANK_MSG_ID_RANK_GET_REPORT=1413 RANK_MSG_ID_RANK_GET_REPORT value
   * @property {number} RANK_MSG_ID_RANK_GET_REPORT_RESP=1414 RANK_MSG_ID_RANK_GET_REPORT_RESP value
   * @property {number} RANK_MSG_ID_MID=1450 RANK_MSG_ID_MID value
   * @property {number} RANK_MSG_ID_MAX=1500 RANK_MSG_ID_MAX value
   */
  client_proto.RANK_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "RANK_MSG_ID_NULL")] = 0;
    values[(valuesById[1400] = "RANK_MSG_ID_MIN")] = 1400;
    values[(valuesById[1401] = "RANK_MSG_ID_RANK_LIST")] = 1401;
    values[(valuesById[1402] = "RANK_MSG_ID_RANK_LIST_RESP")] = 1402;
    values[(valuesById[1403] = "RANK_MSG_ID_RANK_JACKPOT")] = 1403;
    values[(valuesById[1404] = "RANK_MSG_ID_RANK_JACKPOT_RESP")] = 1404;
    values[(valuesById[1405] = "RANK_MSG_ID_RANK_JOIN_GAME_LIST")] = 1405;
    values[(valuesById[1406] = "RANK_MSG_ID_RANK_JOIN_GAME_LIST_RESP")] = 1406;
    values[(valuesById[1407] = "RANK_MSG_ID_RANK_REWARD_LIST")] = 1407;
    values[(valuesById[1408] = "RANK_MSG_ID_RANK_REWARD_LIST_RESP")] = 1408;
    values[(valuesById[1409] = "RANK_MSG_ID_RANK_SHOW_LIST")] = 1409;
    values[(valuesById[1410] = "RANK_MSG_ID_RANK_SHOW_LIST_RESP")] = 1410;
    values[(valuesById[1411] = "RANK_MSG_ID_RANK_GET_REWARD")] = 1411;
    values[(valuesById[1412] = "RANK_MSG_ID_RANK_GET_REWARD_RESP")] = 1412;
    values[(valuesById[1413] = "RANK_MSG_ID_RANK_GET_REPORT")] = 1413;
    values[(valuesById[1414] = "RANK_MSG_ID_RANK_GET_REPORT_RESP")] = 1414;
    values[(valuesById[1450] = "RANK_MSG_ID_MID")] = 1450;
    values[(valuesById[1500] = "RANK_MSG_ID_MAX")] = 1500;
    return values;
  })();

  client_proto.RequestRankShowListReq = (function () {
    /**
     * Properties of a RequestRankShowListReq.
     * @memberof client_proto
     * @interface IRequestRankShowListReq
     */

    /**
     * Constructs a new RequestRankShowListReq.
     * @memberof client_proto
     * @classdesc Represents a RequestRankShowListReq.
     * @implements IRequestRankShowListReq
     * @constructor
     * @param {client_proto.IRequestRankShowListReq=} [properties] Properties to set
     */
    function RequestRankShowListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new RequestRankShowListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.RequestRankShowListReq
     * @static
     * @param {client_proto.IRequestRankShowListReq=} [properties] Properties to set
     * @returns {client_proto.RequestRankShowListReq} RequestRankShowListReq instance
     */
    RequestRankShowListReq.create = function create(properties) {
      return new RequestRankShowListReq(properties);
    };

    /**
     * Encodes the specified RequestRankShowListReq message. Does not implicitly {@link client_proto.RequestRankShowListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RequestRankShowListReq
     * @static
     * @param {client_proto.IRequestRankShowListReq} message RequestRankShowListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRankShowListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified RequestRankShowListReq message, length delimited. Does not implicitly {@link client_proto.RequestRankShowListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RequestRankShowListReq
     * @static
     * @param {client_proto.IRequestRankShowListReq} message RequestRankShowListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRankShowListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RequestRankShowListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RequestRankShowListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RequestRankShowListReq} RequestRankShowListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRankShowListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RequestRankShowListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RequestRankShowListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RequestRankShowListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RequestRankShowListReq} RequestRankShowListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRankShowListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RequestRankShowListReq message.
     * @function verify
     * @memberof client_proto.RequestRankShowListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RequestRankShowListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a RequestRankShowListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RequestRankShowListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RequestRankShowListReq} RequestRankShowListReq
     */
    RequestRankShowListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RequestRankShowListReq) return object;
      return new $root.client_proto.RequestRankShowListReq();
    };

    /**
     * Creates a plain object from a RequestRankShowListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RequestRankShowListReq
     * @static
     * @param {client_proto.RequestRankShowListReq} message RequestRankShowListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RequestRankShowListReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this RequestRankShowListReq to JSON.
     * @function toJSON
     * @memberof client_proto.RequestRankShowListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RequestRankShowListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RequestRankShowListReq
     * @function getTypeUrl
     * @memberof client_proto.RequestRankShowListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RequestRankShowListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RequestRankShowListReq";
    };

    return RequestRankShowListReq;
  })();

  client_proto.RankShowData = (function () {
    /**
     * Properties of a RankShowData.
     * @memberof client_proto
     * @interface IRankShowData
     * @property {number|null} [id] RankShowData id
     * @property {string|null} [name] RankShowData name
     * @property {number|null} [rank_start_time] RankShowData rank_start_time
     * @property {number|null} [rank_end_time] RankShowData rank_end_time
     */

    /**
     * Constructs a new RankShowData.
     * @memberof client_proto
     * @classdesc Represents a RankShowData.
     * @implements IRankShowData
     * @constructor
     * @param {client_proto.IRankShowData=} [properties] Properties to set
     */
    function RankShowData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RankShowData id.
     * @member {number} id
     * @memberof client_proto.RankShowData
     * @instance
     */
    RankShowData.prototype.id = 0;

    /**
     * RankShowData name.
     * @member {string} name
     * @memberof client_proto.RankShowData
     * @instance
     */
    RankShowData.prototype.name = "";

    /**
     * RankShowData rank_start_time.
     * @member {number} rank_start_time
     * @memberof client_proto.RankShowData
     * @instance
     */
    RankShowData.prototype.rank_start_time = 0;

    /**
     * RankShowData rank_end_time.
     * @member {number} rank_end_time
     * @memberof client_proto.RankShowData
     * @instance
     */
    RankShowData.prototype.rank_end_time = 0;

    /**
     * Creates a new RankShowData instance using the specified properties.
     * @function create
     * @memberof client_proto.RankShowData
     * @static
     * @param {client_proto.IRankShowData=} [properties] Properties to set
     * @returns {client_proto.RankShowData} RankShowData instance
     */
    RankShowData.create = function create(properties) {
      return new RankShowData(properties);
    };

    /**
     * Encodes the specified RankShowData message. Does not implicitly {@link client_proto.RankShowData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RankShowData
     * @static
     * @param {client_proto.IRankShowData} message RankShowData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RankShowData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name);
      if (message.rank_start_time != null && Object.hasOwnProperty.call(message, "rank_start_time"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.rank_start_time);
      if (message.rank_end_time != null && Object.hasOwnProperty.call(message, "rank_end_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.rank_end_time);
      return writer;
    };

    /**
     * Encodes the specified RankShowData message, length delimited. Does not implicitly {@link client_proto.RankShowData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RankShowData
     * @static
     * @param {client_proto.IRankShowData} message RankShowData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RankShowData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RankShowData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RankShowData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RankShowData} RankShowData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RankShowData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RankShowData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.name = reader.string();
            break;
          }
          case 3: {
            message.rank_start_time = reader.uint32();
            break;
          }
          case 4: {
            message.rank_end_time = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RankShowData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RankShowData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RankShowData} RankShowData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RankShowData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RankShowData message.
     * @function verify
     * @memberof client_proto.RankShowData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RankShowData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        if (!$util.isInteger(message.rank_start_time)) return "rank_start_time: integer expected";
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        if (!$util.isInteger(message.rank_end_time)) return "rank_end_time: integer expected";
      return null;
    };

    /**
     * Creates a RankShowData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RankShowData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RankShowData} RankShowData
     */
    RankShowData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RankShowData) return object;
      var message = new $root.client_proto.RankShowData();
      if (object.id != null) message.id = object.id | 0;
      if (object.name != null) message.name = String(object.name);
      if (object.rank_start_time != null) message.rank_start_time = object.rank_start_time >>> 0;
      if (object.rank_end_time != null) message.rank_end_time = object.rank_end_time >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a RankShowData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RankShowData
     * @static
     * @param {client_proto.RankShowData} message RankShowData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RankShowData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.name = "";
        object.rank_start_time = 0;
        object.rank_end_time = 0;
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        object.rank_start_time = message.rank_start_time;
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        object.rank_end_time = message.rank_end_time;
      return object;
    };

    /**
     * Converts this RankShowData to JSON.
     * @function toJSON
     * @memberof client_proto.RankShowData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RankShowData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RankShowData
     * @function getTypeUrl
     * @memberof client_proto.RankShowData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RankShowData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RankShowData";
    };

    return RankShowData;
  })();

  client_proto.ResponseRankShowListResp = (function () {
    /**
     * Properties of a ResponseRankShowListResp.
     * @memberof client_proto
     * @interface IResponseRankShowListResp
     * @property {Array.<client_proto.IRankShowData>|null} [show_list] ResponseRankShowListResp show_list
     */

    /**
     * Constructs a new ResponseRankShowListResp.
     * @memberof client_proto
     * @classdesc Represents a ResponseRankShowListResp.
     * @implements IResponseRankShowListResp
     * @constructor
     * @param {client_proto.IResponseRankShowListResp=} [properties] Properties to set
     */
    function ResponseRankShowListResp(properties) {
      this.show_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseRankShowListResp show_list.
     * @member {Array.<client_proto.IRankShowData>} show_list
     * @memberof client_proto.ResponseRankShowListResp
     * @instance
     */
    ResponseRankShowListResp.prototype.show_list = $util.emptyArray;

    /**
     * Creates a new ResponseRankShowListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ResponseRankShowListResp
     * @static
     * @param {client_proto.IResponseRankShowListResp=} [properties] Properties to set
     * @returns {client_proto.ResponseRankShowListResp} ResponseRankShowListResp instance
     */
    ResponseRankShowListResp.create = function create(properties) {
      return new ResponseRankShowListResp(properties);
    };

    /**
     * Encodes the specified ResponseRankShowListResp message. Does not implicitly {@link client_proto.ResponseRankShowListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ResponseRankShowListResp
     * @static
     * @param {client_proto.IResponseRankShowListResp} message ResponseRankShowListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseRankShowListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.show_list != null && message.show_list.length)
        for (var i = 0; i < message.show_list.length; ++i)
          $root.client_proto.RankShowData.encode(
            message.show_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified ResponseRankShowListResp message, length delimited. Does not implicitly {@link client_proto.ResponseRankShowListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ResponseRankShowListResp
     * @static
     * @param {client_proto.IResponseRankShowListResp} message ResponseRankShowListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseRankShowListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ResponseRankShowListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ResponseRankShowListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ResponseRankShowListResp} ResponseRankShowListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseRankShowListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ResponseRankShowListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.show_list && message.show_list.length)) message.show_list = [];
            message.show_list.push($root.client_proto.RankShowData.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ResponseRankShowListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ResponseRankShowListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ResponseRankShowListResp} ResponseRankShowListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseRankShowListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ResponseRankShowListResp message.
     * @function verify
     * @memberof client_proto.ResponseRankShowListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ResponseRankShowListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.show_list != null && message.hasOwnProperty("show_list")) {
        if (!Array.isArray(message.show_list)) return "show_list: array expected";
        for (var i = 0; i < message.show_list.length; ++i) {
          var error = $root.client_proto.RankShowData.verify(message.show_list[i]);
          if (error) return "show_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates a ResponseRankShowListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ResponseRankShowListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ResponseRankShowListResp} ResponseRankShowListResp
     */
    ResponseRankShowListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ResponseRankShowListResp) return object;
      var message = new $root.client_proto.ResponseRankShowListResp();
      if (object.show_list) {
        if (!Array.isArray(object.show_list))
          throw TypeError(".client_proto.ResponseRankShowListResp.show_list: array expected");
        message.show_list = [];
        for (var i = 0; i < object.show_list.length; ++i) {
          if (typeof object.show_list[i] !== "object")
            throw TypeError(".client_proto.ResponseRankShowListResp.show_list: object expected");
          message.show_list[i] = $root.client_proto.RankShowData.fromObject(object.show_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a ResponseRankShowListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ResponseRankShowListResp
     * @static
     * @param {client_proto.ResponseRankShowListResp} message ResponseRankShowListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ResponseRankShowListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.show_list = [];
      if (message.show_list && message.show_list.length) {
        object.show_list = [];
        for (var j = 0; j < message.show_list.length; ++j)
          object.show_list[j] = $root.client_proto.RankShowData.toObject(message.show_list[j], options);
      }
      return object;
    };

    /**
     * Converts this ResponseRankShowListResp to JSON.
     * @function toJSON
     * @memberof client_proto.ResponseRankShowListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ResponseRankShowListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ResponseRankShowListResp
     * @function getTypeUrl
     * @memberof client_proto.ResponseRankShowListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ResponseRankShowListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ResponseRankShowListResp";
    };

    return ResponseRankShowListResp;
  })();

  client_proto.UserRankData = (function () {
    /**
     * Properties of a UserRankData.
     * @memberof client_proto
     * @interface IUserRankData
     * @property {number|null} [rank] UserRankData rank
     * @property {number|Long|null} [money] UserRankData money
     * @property {number|null} [reward] UserRankData reward
     * @property {number|null} [uid] UserRankData uid
     * @property {string|null} [name] UserRankData name
     * @property {string|null} [head] UserRankData head
     * @property {number|Long|null} [reward_money] UserRankData reward_money
     * @property {number|null} [open_reward_state] UserRankData open_reward_state
     */

    /**
     * Constructs a new UserRankData.
     * @memberof client_proto
     * @classdesc Represents a UserRankData.
     * @implements IUserRankData
     * @constructor
     * @param {client_proto.IUserRankData=} [properties] Properties to set
     */
    function UserRankData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserRankData rank.
     * @member {number} rank
     * @memberof client_proto.UserRankData
     * @instance
     */
    UserRankData.prototype.rank = 0;

    /**
     * UserRankData money.
     * @member {number|Long} money
     * @memberof client_proto.UserRankData
     * @instance
     */
    UserRankData.prototype.money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * UserRankData reward.
     * @member {number} reward
     * @memberof client_proto.UserRankData
     * @instance
     */
    UserRankData.prototype.reward = 0;

    /**
     * UserRankData uid.
     * @member {number} uid
     * @memberof client_proto.UserRankData
     * @instance
     */
    UserRankData.prototype.uid = 0;

    /**
     * UserRankData name.
     * @member {string} name
     * @memberof client_proto.UserRankData
     * @instance
     */
    UserRankData.prototype.name = "";

    /**
     * UserRankData head.
     * @member {string} head
     * @memberof client_proto.UserRankData
     * @instance
     */
    UserRankData.prototype.head = "";

    /**
     * UserRankData reward_money.
     * @member {number|Long} reward_money
     * @memberof client_proto.UserRankData
     * @instance
     */
    UserRankData.prototype.reward_money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * UserRankData open_reward_state.
     * @member {number} open_reward_state
     * @memberof client_proto.UserRankData
     * @instance
     */
    UserRankData.prototype.open_reward_state = 0;

    /**
     * Creates a new UserRankData instance using the specified properties.
     * @function create
     * @memberof client_proto.UserRankData
     * @static
     * @param {client_proto.IUserRankData=} [properties] Properties to set
     * @returns {client_proto.UserRankData} UserRankData instance
     */
    UserRankData.create = function create(properties) {
      return new UserRankData(properties);
    };

    /**
     * Encodes the specified UserRankData message. Does not implicitly {@link client_proto.UserRankData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserRankData
     * @static
     * @param {client_proto.IUserRankData} message UserRankData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserRankData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.rank != null && Object.hasOwnProperty.call(message, "rank"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.rank);
      if (message.money != null && Object.hasOwnProperty.call(message, "money"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.money);
      if (message.reward != null && Object.hasOwnProperty.call(message, "reward"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.reward);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.uid);
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.name);
      if (message.head != null && Object.hasOwnProperty.call(message, "head"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.head);
      if (message.reward_money != null && Object.hasOwnProperty.call(message, "reward_money"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.reward_money);
      if (message.open_reward_state != null && Object.hasOwnProperty.call(message, "open_reward_state"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.open_reward_state);
      return writer;
    };

    /**
     * Encodes the specified UserRankData message, length delimited. Does not implicitly {@link client_proto.UserRankData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserRankData
     * @static
     * @param {client_proto.IUserRankData} message UserRankData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserRankData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserRankData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserRankData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserRankData} UserRankData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserRankData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserRankData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.rank = reader.int32();
            break;
          }
          case 2: {
            message.money = reader.int64();
            break;
          }
          case 3: {
            message.reward = reader.int32();
            break;
          }
          case 4: {
            message.uid = reader.uint32();
            break;
          }
          case 5: {
            message.name = reader.string();
            break;
          }
          case 6: {
            message.head = reader.string();
            break;
          }
          case 7: {
            message.reward_money = reader.int64();
            break;
          }
          case 8: {
            message.open_reward_state = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserRankData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserRankData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserRankData} UserRankData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserRankData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserRankData message.
     * @function verify
     * @memberof client_proto.UserRankData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserRankData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.rank != null && message.hasOwnProperty("rank"))
        if (!$util.isInteger(message.rank)) return "rank: integer expected";
      if (message.money != null && message.hasOwnProperty("money"))
        if (
          !$util.isInteger(message.money) &&
          !(message.money && $util.isInteger(message.money.low) && $util.isInteger(message.money.high))
        )
          return "money: integer|Long expected";
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (!$util.isInteger(message.reward)) return "reward: integer expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      if (message.head != null && message.hasOwnProperty("head"))
        if (!$util.isString(message.head)) return "head: string expected";
      if (message.reward_money != null && message.hasOwnProperty("reward_money"))
        if (
          !$util.isInteger(message.reward_money) &&
          !(
            message.reward_money &&
            $util.isInteger(message.reward_money.low) &&
            $util.isInteger(message.reward_money.high)
          )
        )
          return "reward_money: integer|Long expected";
      if (message.open_reward_state != null && message.hasOwnProperty("open_reward_state"))
        if (!$util.isInteger(message.open_reward_state)) return "open_reward_state: integer expected";
      return null;
    };

    /**
     * Creates a UserRankData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserRankData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserRankData} UserRankData
     */
    UserRankData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserRankData) return object;
      var message = new $root.client_proto.UserRankData();
      if (object.rank != null) message.rank = object.rank | 0;
      if (object.money != null)
        if ($util.Long) (message.money = $util.Long.fromValue(object.money)).unsigned = false;
        else if (typeof object.money === "string") message.money = parseInt(object.money, 10);
        else if (typeof object.money === "number") message.money = object.money;
        else if (typeof object.money === "object")
          message.money = new $util.LongBits(object.money.low >>> 0, object.money.high >>> 0).toNumber();
      if (object.reward != null) message.reward = object.reward | 0;
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.name != null) message.name = String(object.name);
      if (object.head != null) message.head = String(object.head);
      if (object.reward_money != null)
        if ($util.Long) (message.reward_money = $util.Long.fromValue(object.reward_money)).unsigned = false;
        else if (typeof object.reward_money === "string") message.reward_money = parseInt(object.reward_money, 10);
        else if (typeof object.reward_money === "number") message.reward_money = object.reward_money;
        else if (typeof object.reward_money === "object")
          message.reward_money = new $util.LongBits(
            object.reward_money.low >>> 0,
            object.reward_money.high >>> 0,
          ).toNumber();
      if (object.open_reward_state != null) message.open_reward_state = object.open_reward_state | 0;
      return message;
    };

    /**
     * Creates a plain object from a UserRankData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserRankData
     * @static
     * @param {client_proto.UserRankData} message UserRankData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserRankData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.rank = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.money = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.money = options.longs === String ? "0" : 0;
        object.reward = 0;
        object.uid = 0;
        object.name = "";
        object.head = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_money =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_money = options.longs === String ? "0" : 0;
        object.open_reward_state = 0;
      }
      if (message.rank != null && message.hasOwnProperty("rank")) object.rank = message.rank;
      if (message.money != null && message.hasOwnProperty("money"))
        if (typeof message.money === "number")
          object.money = options.longs === String ? String(message.money) : message.money;
        else
          object.money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.money)
              : options.longs === Number
                ? new $util.LongBits(message.money.low >>> 0, message.money.high >>> 0).toNumber()
                : message.money;
      if (message.reward != null && message.hasOwnProperty("reward")) object.reward = message.reward;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      if (message.head != null && message.hasOwnProperty("head")) object.head = message.head;
      if (message.reward_money != null && message.hasOwnProperty("reward_money"))
        if (typeof message.reward_money === "number")
          object.reward_money = options.longs === String ? String(message.reward_money) : message.reward_money;
        else
          object.reward_money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_money)
              : options.longs === Number
                ? new $util.LongBits(message.reward_money.low >>> 0, message.reward_money.high >>> 0).toNumber()
                : message.reward_money;
      if (message.open_reward_state != null && message.hasOwnProperty("open_reward_state"))
        object.open_reward_state = message.open_reward_state;
      return object;
    };

    /**
     * Converts this UserRankData to JSON.
     * @function toJSON
     * @memberof client_proto.UserRankData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserRankData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserRankData
     * @function getTypeUrl
     * @memberof client_proto.UserRankData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserRankData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserRankData";
    };

    return UserRankData;
  })();

  client_proto.RequestRankListReq = (function () {
    /**
     * Properties of a RequestRankListReq.
     * @memberof client_proto
     * @interface IRequestRankListReq
     * @property {number|null} [uid] RequestRankListReq uid
     * @property {number|null} [id] RequestRankListReq id
     * @property {boolean|null} [is_history] RequestRankListReq is_history
     * @property {number|null} [page_number] RequestRankListReq page_number
     * @property {number|null} [number_per_page] RequestRankListReq number_per_page
     */

    /**
     * Constructs a new RequestRankListReq.
     * @memberof client_proto
     * @classdesc Represents a RequestRankListReq.
     * @implements IRequestRankListReq
     * @constructor
     * @param {client_proto.IRequestRankListReq=} [properties] Properties to set
     */
    function RequestRankListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestRankListReq uid.
     * @member {number} uid
     * @memberof client_proto.RequestRankListReq
     * @instance
     */
    RequestRankListReq.prototype.uid = 0;

    /**
     * RequestRankListReq id.
     * @member {number} id
     * @memberof client_proto.RequestRankListReq
     * @instance
     */
    RequestRankListReq.prototype.id = 0;

    /**
     * RequestRankListReq is_history.
     * @member {boolean} is_history
     * @memberof client_proto.RequestRankListReq
     * @instance
     */
    RequestRankListReq.prototype.is_history = false;

    /**
     * RequestRankListReq page_number.
     * @member {number} page_number
     * @memberof client_proto.RequestRankListReq
     * @instance
     */
    RequestRankListReq.prototype.page_number = 0;

    /**
     * RequestRankListReq number_per_page.
     * @member {number} number_per_page
     * @memberof client_proto.RequestRankListReq
     * @instance
     */
    RequestRankListReq.prototype.number_per_page = 0;

    /**
     * Creates a new RequestRankListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.RequestRankListReq
     * @static
     * @param {client_proto.IRequestRankListReq=} [properties] Properties to set
     * @returns {client_proto.RequestRankListReq} RequestRankListReq instance
     */
    RequestRankListReq.create = function create(properties) {
      return new RequestRankListReq(properties);
    };

    /**
     * Encodes the specified RequestRankListReq message. Does not implicitly {@link client_proto.RequestRankListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RequestRankListReq
     * @static
     * @param {client_proto.IRequestRankListReq} message RequestRankListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRankListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.is_history != null && Object.hasOwnProperty.call(message, "is_history"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).bool(message.is_history);
      if (message.page_number != null && Object.hasOwnProperty.call(message, "page_number"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.page_number);
      if (message.number_per_page != null && Object.hasOwnProperty.call(message, "number_per_page"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.number_per_page);
      return writer;
    };

    /**
     * Encodes the specified RequestRankListReq message, length delimited. Does not implicitly {@link client_proto.RequestRankListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RequestRankListReq
     * @static
     * @param {client_proto.IRequestRankListReq} message RequestRankListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRankListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RequestRankListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RequestRankListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RequestRankListReq} RequestRankListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRankListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RequestRankListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            message.is_history = reader.bool();
            break;
          }
          case 4: {
            message.page_number = reader.int32();
            break;
          }
          case 5: {
            message.number_per_page = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RequestRankListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RequestRankListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RequestRankListReq} RequestRankListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRankListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RequestRankListReq message.
     * @function verify
     * @memberof client_proto.RequestRankListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RequestRankListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.is_history != null && message.hasOwnProperty("is_history"))
        if (typeof message.is_history !== "boolean") return "is_history: boolean expected";
      if (message.page_number != null && message.hasOwnProperty("page_number"))
        if (!$util.isInteger(message.page_number)) return "page_number: integer expected";
      if (message.number_per_page != null && message.hasOwnProperty("number_per_page"))
        if (!$util.isInteger(message.number_per_page)) return "number_per_page: integer expected";
      return null;
    };

    /**
     * Creates a RequestRankListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RequestRankListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RequestRankListReq} RequestRankListReq
     */
    RequestRankListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RequestRankListReq) return object;
      var message = new $root.client_proto.RequestRankListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      if (object.is_history != null) message.is_history = Boolean(object.is_history);
      if (object.page_number != null) message.page_number = object.page_number | 0;
      if (object.number_per_page != null) message.number_per_page = object.number_per_page | 0;
      return message;
    };

    /**
     * Creates a plain object from a RequestRankListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RequestRankListReq
     * @static
     * @param {client_proto.RequestRankListReq} message RequestRankListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RequestRankListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
        object.is_history = false;
        object.page_number = 0;
        object.number_per_page = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.is_history != null && message.hasOwnProperty("is_history")) object.is_history = message.is_history;
      if (message.page_number != null && message.hasOwnProperty("page_number"))
        object.page_number = message.page_number;
      if (message.number_per_page != null && message.hasOwnProperty("number_per_page"))
        object.number_per_page = message.number_per_page;
      return object;
    };

    /**
     * Converts this RequestRankListReq to JSON.
     * @function toJSON
     * @memberof client_proto.RequestRankListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RequestRankListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RequestRankListReq
     * @function getTypeUrl
     * @memberof client_proto.RequestRankListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RequestRankListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RequestRankListReq";
    };

    return RequestRankListReq;
  })();

  client_proto.ResponseRankListResp = (function () {
    /**
     * Properties of a ResponseRankListResp.
     * @memberof client_proto
     * @interface IResponseRankListResp
     * @property {boolean|null} [is_have_data] ResponseRankListResp is_have_data
     * @property {Array.<client_proto.IUserRankData>|null} [rank_list] ResponseRankListResp rank_list
     * @property {client_proto.IUserRankData|null} [user_rank] ResponseRankListResp user_rank
     * @property {number|Long|null} [jackpot] ResponseRankListResp jackpot
     * @property {number|Long|null} [last_round_jackpot] ResponseRankListResp last_round_jackpot
     * @property {number|null} [game_reward] ResponseRankListResp game_reward
     * @property {number|Long|null} [current_join_next_round] ResponseRankListResp current_join_next_round
     * @property {number|null} [id] ResponseRankListResp id
     * @property {boolean|null} [is_history] ResponseRankListResp is_history
     * @property {number|null} [rank_start_time] ResponseRankListResp rank_start_time
     * @property {number|null} [rank_end_time] ResponseRankListResp rank_end_time
     * @property {number|null} [page_number] ResponseRankListResp page_number
     * @property {number|null} [number_per_page] ResponseRankListResp number_per_page
     * @property {number|null} [total_page_number] ResponseRankListResp total_page_number
     * @property {number|Long|null} [need_money_to_join] ResponseRankListResp need_money_to_join
     * @property {number|Long|null} [need_money_to_min_rank] ResponseRankListResp need_money_to_min_rank
     */

    /**
     * Constructs a new ResponseRankListResp.
     * @memberof client_proto
     * @classdesc Represents a ResponseRankListResp.
     * @implements IResponseRankListResp
     * @constructor
     * @param {client_proto.IResponseRankListResp=} [properties] Properties to set
     */
    function ResponseRankListResp(properties) {
      this.rank_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseRankListResp is_have_data.
     * @member {boolean} is_have_data
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.is_have_data = false;

    /**
     * ResponseRankListResp rank_list.
     * @member {Array.<client_proto.IUserRankData>} rank_list
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.rank_list = $util.emptyArray;

    /**
     * ResponseRankListResp user_rank.
     * @member {client_proto.IUserRankData|null|undefined} user_rank
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.user_rank = null;

    /**
     * ResponseRankListResp jackpot.
     * @member {number|Long} jackpot
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ResponseRankListResp last_round_jackpot.
     * @member {number|Long} last_round_jackpot
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.last_round_jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ResponseRankListResp game_reward.
     * @member {number} game_reward
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.game_reward = 0;

    /**
     * ResponseRankListResp current_join_next_round.
     * @member {number|Long} current_join_next_round
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.current_join_next_round = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ResponseRankListResp id.
     * @member {number} id
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.id = 0;

    /**
     * ResponseRankListResp is_history.
     * @member {boolean} is_history
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.is_history = false;

    /**
     * ResponseRankListResp rank_start_time.
     * @member {number} rank_start_time
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.rank_start_time = 0;

    /**
     * ResponseRankListResp rank_end_time.
     * @member {number} rank_end_time
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.rank_end_time = 0;

    /**
     * ResponseRankListResp page_number.
     * @member {number} page_number
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.page_number = 0;

    /**
     * ResponseRankListResp number_per_page.
     * @member {number} number_per_page
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.number_per_page = 0;

    /**
     * ResponseRankListResp total_page_number.
     * @member {number} total_page_number
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.total_page_number = 0;

    /**
     * ResponseRankListResp need_money_to_join.
     * @member {number|Long} need_money_to_join
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.need_money_to_join = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ResponseRankListResp need_money_to_min_rank.
     * @member {number|Long} need_money_to_min_rank
     * @memberof client_proto.ResponseRankListResp
     * @instance
     */
    ResponseRankListResp.prototype.need_money_to_min_rank = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new ResponseRankListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ResponseRankListResp
     * @static
     * @param {client_proto.IResponseRankListResp=} [properties] Properties to set
     * @returns {client_proto.ResponseRankListResp} ResponseRankListResp instance
     */
    ResponseRankListResp.create = function create(properties) {
      return new ResponseRankListResp(properties);
    };

    /**
     * Encodes the specified ResponseRankListResp message. Does not implicitly {@link client_proto.ResponseRankListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ResponseRankListResp
     * @static
     * @param {client_proto.IResponseRankListResp} message ResponseRankListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseRankListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.is_have_data != null && Object.hasOwnProperty.call(message, "is_have_data"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.is_have_data);
      if (message.rank_list != null && message.rank_list.length)
        for (var i = 0; i < message.rank_list.length; ++i)
          $root.client_proto.UserRankData.encode(
            message.rank_list[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim();
      if (message.user_rank != null && Object.hasOwnProperty.call(message, "user_rank"))
        $root.client_proto.UserRankData.encode(
          message.user_rank,
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
        ).ldelim();
      if (message.jackpot != null && Object.hasOwnProperty.call(message, "jackpot"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.jackpot);
      if (message.last_round_jackpot != null && Object.hasOwnProperty.call(message, "last_round_jackpot"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.last_round_jackpot);
      if (message.game_reward != null && Object.hasOwnProperty.call(message, "game_reward"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.game_reward);
      if (message.current_join_next_round != null && Object.hasOwnProperty.call(message, "current_join_next_round"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.current_join_next_round);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.id);
      if (message.is_history != null && Object.hasOwnProperty.call(message, "is_history"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).bool(message.is_history);
      if (message.rank_start_time != null && Object.hasOwnProperty.call(message, "rank_start_time"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).uint32(message.rank_start_time);
      if (message.rank_end_time != null && Object.hasOwnProperty.call(message, "rank_end_time"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).uint32(message.rank_end_time);
      if (message.page_number != null && Object.hasOwnProperty.call(message, "page_number"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).int32(message.page_number);
      if (message.number_per_page != null && Object.hasOwnProperty.call(message, "number_per_page"))
        writer.uint32(/* id 13, wireType 0 =*/ 104).int32(message.number_per_page);
      if (message.total_page_number != null && Object.hasOwnProperty.call(message, "total_page_number"))
        writer.uint32(/* id 14, wireType 0 =*/ 112).int32(message.total_page_number);
      if (message.need_money_to_join != null && Object.hasOwnProperty.call(message, "need_money_to_join"))
        writer.uint32(/* id 15, wireType 0 =*/ 120).int64(message.need_money_to_join);
      if (message.need_money_to_min_rank != null && Object.hasOwnProperty.call(message, "need_money_to_min_rank"))
        writer.uint32(/* id 16, wireType 0 =*/ 128).int64(message.need_money_to_min_rank);
      return writer;
    };

    /**
     * Encodes the specified ResponseRankListResp message, length delimited. Does not implicitly {@link client_proto.ResponseRankListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ResponseRankListResp
     * @static
     * @param {client_proto.IResponseRankListResp} message ResponseRankListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseRankListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ResponseRankListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ResponseRankListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ResponseRankListResp} ResponseRankListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseRankListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ResponseRankListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.is_have_data = reader.bool();
            break;
          }
          case 2: {
            if (!(message.rank_list && message.rank_list.length)) message.rank_list = [];
            message.rank_list.push($root.client_proto.UserRankData.decode(reader, reader.uint32()));
            break;
          }
          case 3: {
            message.user_rank = $root.client_proto.UserRankData.decode(reader, reader.uint32());
            break;
          }
          case 4: {
            message.jackpot = reader.int64();
            break;
          }
          case 5: {
            message.last_round_jackpot = reader.int64();
            break;
          }
          case 6: {
            message.game_reward = reader.int32();
            break;
          }
          case 7: {
            message.current_join_next_round = reader.int64();
            break;
          }
          case 8: {
            message.id = reader.int32();
            break;
          }
          case 9: {
            message.is_history = reader.bool();
            break;
          }
          case 10: {
            message.rank_start_time = reader.uint32();
            break;
          }
          case 11: {
            message.rank_end_time = reader.uint32();
            break;
          }
          case 12: {
            message.page_number = reader.int32();
            break;
          }
          case 13: {
            message.number_per_page = reader.int32();
            break;
          }
          case 14: {
            message.total_page_number = reader.int32();
            break;
          }
          case 15: {
            message.need_money_to_join = reader.int64();
            break;
          }
          case 16: {
            message.need_money_to_min_rank = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ResponseRankListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ResponseRankListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ResponseRankListResp} ResponseRankListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseRankListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ResponseRankListResp message.
     * @function verify
     * @memberof client_proto.ResponseRankListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ResponseRankListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        if (typeof message.is_have_data !== "boolean") return "is_have_data: boolean expected";
      if (message.rank_list != null && message.hasOwnProperty("rank_list")) {
        if (!Array.isArray(message.rank_list)) return "rank_list: array expected";
        for (var i = 0; i < message.rank_list.length; ++i) {
          var error = $root.client_proto.UserRankData.verify(message.rank_list[i]);
          if (error) return "rank_list." + error;
        }
      }
      if (message.user_rank != null && message.hasOwnProperty("user_rank")) {
        var error = $root.client_proto.UserRankData.verify(message.user_rank);
        if (error) return "user_rank." + error;
      }
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (
          !$util.isInteger(message.jackpot) &&
          !(message.jackpot && $util.isInteger(message.jackpot.low) && $util.isInteger(message.jackpot.high))
        )
          return "jackpot: integer|Long expected";
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (
          !$util.isInteger(message.last_round_jackpot) &&
          !(
            message.last_round_jackpot &&
            $util.isInteger(message.last_round_jackpot.low) &&
            $util.isInteger(message.last_round_jackpot.high)
          )
        )
          return "last_round_jackpot: integer|Long expected";
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        if (!$util.isInteger(message.game_reward)) return "game_reward: integer expected";
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (
          !$util.isInteger(message.current_join_next_round) &&
          !(
            message.current_join_next_round &&
            $util.isInteger(message.current_join_next_round.low) &&
            $util.isInteger(message.current_join_next_round.high)
          )
        )
          return "current_join_next_round: integer|Long expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.is_history != null && message.hasOwnProperty("is_history"))
        if (typeof message.is_history !== "boolean") return "is_history: boolean expected";
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        if (!$util.isInteger(message.rank_start_time)) return "rank_start_time: integer expected";
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        if (!$util.isInteger(message.rank_end_time)) return "rank_end_time: integer expected";
      if (message.page_number != null && message.hasOwnProperty("page_number"))
        if (!$util.isInteger(message.page_number)) return "page_number: integer expected";
      if (message.number_per_page != null && message.hasOwnProperty("number_per_page"))
        if (!$util.isInteger(message.number_per_page)) return "number_per_page: integer expected";
      if (message.total_page_number != null && message.hasOwnProperty("total_page_number"))
        if (!$util.isInteger(message.total_page_number)) return "total_page_number: integer expected";
      if (message.need_money_to_join != null && message.hasOwnProperty("need_money_to_join"))
        if (
          !$util.isInteger(message.need_money_to_join) &&
          !(
            message.need_money_to_join &&
            $util.isInteger(message.need_money_to_join.low) &&
            $util.isInteger(message.need_money_to_join.high)
          )
        )
          return "need_money_to_join: integer|Long expected";
      if (message.need_money_to_min_rank != null && message.hasOwnProperty("need_money_to_min_rank"))
        if (
          !$util.isInteger(message.need_money_to_min_rank) &&
          !(
            message.need_money_to_min_rank &&
            $util.isInteger(message.need_money_to_min_rank.low) &&
            $util.isInteger(message.need_money_to_min_rank.high)
          )
        )
          return "need_money_to_min_rank: integer|Long expected";
      return null;
    };

    /**
     * Creates a ResponseRankListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ResponseRankListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ResponseRankListResp} ResponseRankListResp
     */
    ResponseRankListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ResponseRankListResp) return object;
      var message = new $root.client_proto.ResponseRankListResp();
      if (object.is_have_data != null) message.is_have_data = Boolean(object.is_have_data);
      if (object.rank_list) {
        if (!Array.isArray(object.rank_list))
          throw TypeError(".client_proto.ResponseRankListResp.rank_list: array expected");
        message.rank_list = [];
        for (var i = 0; i < object.rank_list.length; ++i) {
          if (typeof object.rank_list[i] !== "object")
            throw TypeError(".client_proto.ResponseRankListResp.rank_list: object expected");
          message.rank_list[i] = $root.client_proto.UserRankData.fromObject(object.rank_list[i]);
        }
      }
      if (object.user_rank != null) {
        if (typeof object.user_rank !== "object")
          throw TypeError(".client_proto.ResponseRankListResp.user_rank: object expected");
        message.user_rank = $root.client_proto.UserRankData.fromObject(object.user_rank);
      }
      if (object.jackpot != null)
        if ($util.Long) (message.jackpot = $util.Long.fromValue(object.jackpot)).unsigned = false;
        else if (typeof object.jackpot === "string") message.jackpot = parseInt(object.jackpot, 10);
        else if (typeof object.jackpot === "number") message.jackpot = object.jackpot;
        else if (typeof object.jackpot === "object")
          message.jackpot = new $util.LongBits(object.jackpot.low >>> 0, object.jackpot.high >>> 0).toNumber();
      if (object.last_round_jackpot != null)
        if ($util.Long) (message.last_round_jackpot = $util.Long.fromValue(object.last_round_jackpot)).unsigned = false;
        else if (typeof object.last_round_jackpot === "string")
          message.last_round_jackpot = parseInt(object.last_round_jackpot, 10);
        else if (typeof object.last_round_jackpot === "number") message.last_round_jackpot = object.last_round_jackpot;
        else if (typeof object.last_round_jackpot === "object")
          message.last_round_jackpot = new $util.LongBits(
            object.last_round_jackpot.low >>> 0,
            object.last_round_jackpot.high >>> 0,
          ).toNumber();
      if (object.game_reward != null) message.game_reward = object.game_reward | 0;
      if (object.current_join_next_round != null)
        if ($util.Long)
          (message.current_join_next_round = $util.Long.fromValue(object.current_join_next_round)).unsigned = false;
        else if (typeof object.current_join_next_round === "string")
          message.current_join_next_round = parseInt(object.current_join_next_round, 10);
        else if (typeof object.current_join_next_round === "number")
          message.current_join_next_round = object.current_join_next_round;
        else if (typeof object.current_join_next_round === "object")
          message.current_join_next_round = new $util.LongBits(
            object.current_join_next_round.low >>> 0,
            object.current_join_next_round.high >>> 0,
          ).toNumber();
      if (object.id != null) message.id = object.id | 0;
      if (object.is_history != null) message.is_history = Boolean(object.is_history);
      if (object.rank_start_time != null) message.rank_start_time = object.rank_start_time >>> 0;
      if (object.rank_end_time != null) message.rank_end_time = object.rank_end_time >>> 0;
      if (object.page_number != null) message.page_number = object.page_number | 0;
      if (object.number_per_page != null) message.number_per_page = object.number_per_page | 0;
      if (object.total_page_number != null) message.total_page_number = object.total_page_number | 0;
      if (object.need_money_to_join != null)
        if ($util.Long) (message.need_money_to_join = $util.Long.fromValue(object.need_money_to_join)).unsigned = false;
        else if (typeof object.need_money_to_join === "string")
          message.need_money_to_join = parseInt(object.need_money_to_join, 10);
        else if (typeof object.need_money_to_join === "number") message.need_money_to_join = object.need_money_to_join;
        else if (typeof object.need_money_to_join === "object")
          message.need_money_to_join = new $util.LongBits(
            object.need_money_to_join.low >>> 0,
            object.need_money_to_join.high >>> 0,
          ).toNumber();
      if (object.need_money_to_min_rank != null)
        if ($util.Long)
          (message.need_money_to_min_rank = $util.Long.fromValue(object.need_money_to_min_rank)).unsigned = false;
        else if (typeof object.need_money_to_min_rank === "string")
          message.need_money_to_min_rank = parseInt(object.need_money_to_min_rank, 10);
        else if (typeof object.need_money_to_min_rank === "number")
          message.need_money_to_min_rank = object.need_money_to_min_rank;
        else if (typeof object.need_money_to_min_rank === "object")
          message.need_money_to_min_rank = new $util.LongBits(
            object.need_money_to_min_rank.low >>> 0,
            object.need_money_to_min_rank.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a ResponseRankListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ResponseRankListResp
     * @static
     * @param {client_proto.ResponseRankListResp} message ResponseRankListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ResponseRankListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.rank_list = [];
      if (options.defaults) {
        object.is_have_data = false;
        object.user_rank = null;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.jackpot = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.last_round_jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.last_round_jackpot = options.longs === String ? "0" : 0;
        object.game_reward = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.current_join_next_round =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.current_join_next_round = options.longs === String ? "0" : 0;
        object.id = 0;
        object.is_history = false;
        object.rank_start_time = 0;
        object.rank_end_time = 0;
        object.page_number = 0;
        object.number_per_page = 0;
        object.total_page_number = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.need_money_to_join =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.need_money_to_join = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.need_money_to_min_rank =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.need_money_to_min_rank = options.longs === String ? "0" : 0;
      }
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        object.is_have_data = message.is_have_data;
      if (message.rank_list && message.rank_list.length) {
        object.rank_list = [];
        for (var j = 0; j < message.rank_list.length; ++j)
          object.rank_list[j] = $root.client_proto.UserRankData.toObject(message.rank_list[j], options);
      }
      if (message.user_rank != null && message.hasOwnProperty("user_rank"))
        object.user_rank = $root.client_proto.UserRankData.toObject(message.user_rank, options);
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (typeof message.jackpot === "number")
          object.jackpot = options.longs === String ? String(message.jackpot) : message.jackpot;
        else
          object.jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.jackpot)
              : options.longs === Number
                ? new $util.LongBits(message.jackpot.low >>> 0, message.jackpot.high >>> 0).toNumber()
                : message.jackpot;
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (typeof message.last_round_jackpot === "number")
          object.last_round_jackpot =
            options.longs === String ? String(message.last_round_jackpot) : message.last_round_jackpot;
        else
          object.last_round_jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.last_round_jackpot)
              : options.longs === Number
                ? new $util.LongBits(
                    message.last_round_jackpot.low >>> 0,
                    message.last_round_jackpot.high >>> 0,
                  ).toNumber()
                : message.last_round_jackpot;
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        object.game_reward = message.game_reward;
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (typeof message.current_join_next_round === "number")
          object.current_join_next_round =
            options.longs === String ? String(message.current_join_next_round) : message.current_join_next_round;
        else
          object.current_join_next_round =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.current_join_next_round)
              : options.longs === Number
                ? new $util.LongBits(
                    message.current_join_next_round.low >>> 0,
                    message.current_join_next_round.high >>> 0,
                  ).toNumber()
                : message.current_join_next_round;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.is_history != null && message.hasOwnProperty("is_history")) object.is_history = message.is_history;
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        object.rank_start_time = message.rank_start_time;
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        object.rank_end_time = message.rank_end_time;
      if (message.page_number != null && message.hasOwnProperty("page_number"))
        object.page_number = message.page_number;
      if (message.number_per_page != null && message.hasOwnProperty("number_per_page"))
        object.number_per_page = message.number_per_page;
      if (message.total_page_number != null && message.hasOwnProperty("total_page_number"))
        object.total_page_number = message.total_page_number;
      if (message.need_money_to_join != null && message.hasOwnProperty("need_money_to_join"))
        if (typeof message.need_money_to_join === "number")
          object.need_money_to_join =
            options.longs === String ? String(message.need_money_to_join) : message.need_money_to_join;
        else
          object.need_money_to_join =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.need_money_to_join)
              : options.longs === Number
                ? new $util.LongBits(
                    message.need_money_to_join.low >>> 0,
                    message.need_money_to_join.high >>> 0,
                  ).toNumber()
                : message.need_money_to_join;
      if (message.need_money_to_min_rank != null && message.hasOwnProperty("need_money_to_min_rank"))
        if (typeof message.need_money_to_min_rank === "number")
          object.need_money_to_min_rank =
            options.longs === String ? String(message.need_money_to_min_rank) : message.need_money_to_min_rank;
        else
          object.need_money_to_min_rank =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.need_money_to_min_rank)
              : options.longs === Number
                ? new $util.LongBits(
                    message.need_money_to_min_rank.low >>> 0,
                    message.need_money_to_min_rank.high >>> 0,
                  ).toNumber()
                : message.need_money_to_min_rank;
      return object;
    };

    /**
     * Converts this ResponseRankListResp to JSON.
     * @function toJSON
     * @memberof client_proto.ResponseRankListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ResponseRankListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ResponseRankListResp
     * @function getTypeUrl
     * @memberof client_proto.ResponseRankListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ResponseRankListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ResponseRankListResp";
    };

    return ResponseRankListResp;
  })();

  client_proto.RankListReportReq = (function () {
    /**
     * Properties of a RankListReportReq.
     * @memberof client_proto
     * @interface IRankListReportReq
     * @property {number|null} [number] RankListReportReq number
     * @property {string|null} [key] RankListReportReq key
     */

    /**
     * Constructs a new RankListReportReq.
     * @memberof client_proto
     * @classdesc Represents a RankListReportReq.
     * @implements IRankListReportReq
     * @constructor
     * @param {client_proto.IRankListReportReq=} [properties] Properties to set
     */
    function RankListReportReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RankListReportReq number.
     * @member {number} number
     * @memberof client_proto.RankListReportReq
     * @instance
     */
    RankListReportReq.prototype.number = 0;

    /**
     * RankListReportReq key.
     * @member {string} key
     * @memberof client_proto.RankListReportReq
     * @instance
     */
    RankListReportReq.prototype.key = "";

    /**
     * Creates a new RankListReportReq instance using the specified properties.
     * @function create
     * @memberof client_proto.RankListReportReq
     * @static
     * @param {client_proto.IRankListReportReq=} [properties] Properties to set
     * @returns {client_proto.RankListReportReq} RankListReportReq instance
     */
    RankListReportReq.create = function create(properties) {
      return new RankListReportReq(properties);
    };

    /**
     * Encodes the specified RankListReportReq message. Does not implicitly {@link client_proto.RankListReportReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RankListReportReq
     * @static
     * @param {client_proto.IRankListReportReq} message RankListReportReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RankListReportReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.number != null && Object.hasOwnProperty.call(message, "number"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.number);
      if (message.key != null && Object.hasOwnProperty.call(message, "key"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.key);
      return writer;
    };

    /**
     * Encodes the specified RankListReportReq message, length delimited. Does not implicitly {@link client_proto.RankListReportReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RankListReportReq
     * @static
     * @param {client_proto.IRankListReportReq} message RankListReportReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RankListReportReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RankListReportReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RankListReportReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RankListReportReq} RankListReportReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RankListReportReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RankListReportReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.number = reader.uint32();
            break;
          }
          case 2: {
            message.key = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RankListReportReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RankListReportReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RankListReportReq} RankListReportReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RankListReportReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RankListReportReq message.
     * @function verify
     * @memberof client_proto.RankListReportReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RankListReportReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.number != null && message.hasOwnProperty("number"))
        if (!$util.isInteger(message.number)) return "number: integer expected";
      if (message.key != null && message.hasOwnProperty("key"))
        if (!$util.isString(message.key)) return "key: string expected";
      return null;
    };

    /**
     * Creates a RankListReportReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RankListReportReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RankListReportReq} RankListReportReq
     */
    RankListReportReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RankListReportReq) return object;
      var message = new $root.client_proto.RankListReportReq();
      if (object.number != null) message.number = object.number >>> 0;
      if (object.key != null) message.key = String(object.key);
      return message;
    };

    /**
     * Creates a plain object from a RankListReportReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RankListReportReq
     * @static
     * @param {client_proto.RankListReportReq} message RankListReportReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RankListReportReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.number = 0;
        object.key = "";
      }
      if (message.number != null && message.hasOwnProperty("number")) object.number = message.number;
      if (message.key != null && message.hasOwnProperty("key")) object.key = message.key;
      return object;
    };

    /**
     * Converts this RankListReportReq to JSON.
     * @function toJSON
     * @memberof client_proto.RankListReportReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RankListReportReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RankListReportReq
     * @function getTypeUrl
     * @memberof client_proto.RankListReportReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RankListReportReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RankListReportReq";
    };

    return RankListReportReq;
  })();

  client_proto.RankListReportResp = (function () {
    /**
     * Properties of a RankListReportResp.
     * @memberof client_proto
     * @interface IRankListReportResp
     * @property {boolean|null} [is_have_data] RankListReportResp is_have_data
     * @property {Array.<client_proto.IUserRankData>|null} [rank_list] RankListReportResp rank_list
     * @property {number|Long|null} [jackpot] RankListReportResp jackpot
     * @property {number|Long|null} [last_round_jackpot] RankListReportResp last_round_jackpot
     * @property {number|null} [game_reward] RankListReportResp game_reward
     * @property {number|Long|null} [current_join_next_round] RankListReportResp current_join_next_round
     * @property {number|null} [id] RankListReportResp id
     * @property {number|null} [rank_start_time] RankListReportResp rank_start_time
     * @property {number|null} [rank_end_time] RankListReportResp rank_end_time
     * @property {number|null} [number] RankListReportResp number
     * @property {string|null} [key] RankListReportResp key
     */

    /**
     * Constructs a new RankListReportResp.
     * @memberof client_proto
     * @classdesc Represents a RankListReportResp.
     * @implements IRankListReportResp
     * @constructor
     * @param {client_proto.IRankListReportResp=} [properties] Properties to set
     */
    function RankListReportResp(properties) {
      this.rank_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RankListReportResp is_have_data.
     * @member {boolean} is_have_data
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.is_have_data = false;

    /**
     * RankListReportResp rank_list.
     * @member {Array.<client_proto.IUserRankData>} rank_list
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.rank_list = $util.emptyArray;

    /**
     * RankListReportResp jackpot.
     * @member {number|Long} jackpot
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RankListReportResp last_round_jackpot.
     * @member {number|Long} last_round_jackpot
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.last_round_jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RankListReportResp game_reward.
     * @member {number} game_reward
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.game_reward = 0;

    /**
     * RankListReportResp current_join_next_round.
     * @member {number|Long} current_join_next_round
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.current_join_next_round = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RankListReportResp id.
     * @member {number} id
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.id = 0;

    /**
     * RankListReportResp rank_start_time.
     * @member {number} rank_start_time
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.rank_start_time = 0;

    /**
     * RankListReportResp rank_end_time.
     * @member {number} rank_end_time
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.rank_end_time = 0;

    /**
     * RankListReportResp number.
     * @member {number} number
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.number = 0;

    /**
     * RankListReportResp key.
     * @member {string} key
     * @memberof client_proto.RankListReportResp
     * @instance
     */
    RankListReportResp.prototype.key = "";

    /**
     * Creates a new RankListReportResp instance using the specified properties.
     * @function create
     * @memberof client_proto.RankListReportResp
     * @static
     * @param {client_proto.IRankListReportResp=} [properties] Properties to set
     * @returns {client_proto.RankListReportResp} RankListReportResp instance
     */
    RankListReportResp.create = function create(properties) {
      return new RankListReportResp(properties);
    };

    /**
     * Encodes the specified RankListReportResp message. Does not implicitly {@link client_proto.RankListReportResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RankListReportResp
     * @static
     * @param {client_proto.IRankListReportResp} message RankListReportResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RankListReportResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.is_have_data != null && Object.hasOwnProperty.call(message, "is_have_data"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.is_have_data);
      if (message.rank_list != null && message.rank_list.length)
        for (var i = 0; i < message.rank_list.length; ++i)
          $root.client_proto.UserRankData.encode(
            message.rank_list[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim();
      if (message.jackpot != null && Object.hasOwnProperty.call(message, "jackpot"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.jackpot);
      if (message.last_round_jackpot != null && Object.hasOwnProperty.call(message, "last_round_jackpot"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.last_round_jackpot);
      if (message.game_reward != null && Object.hasOwnProperty.call(message, "game_reward"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.game_reward);
      if (message.current_join_next_round != null && Object.hasOwnProperty.call(message, "current_join_next_round"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.current_join_next_round);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.id);
      if (message.rank_start_time != null && Object.hasOwnProperty.call(message, "rank_start_time"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).uint32(message.rank_start_time);
      if (message.rank_end_time != null && Object.hasOwnProperty.call(message, "rank_end_time"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).uint32(message.rank_end_time);
      if (message.number != null && Object.hasOwnProperty.call(message, "number"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).uint32(message.number);
      if (message.key != null && Object.hasOwnProperty.call(message, "key"))
        writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.key);
      return writer;
    };

    /**
     * Encodes the specified RankListReportResp message, length delimited. Does not implicitly {@link client_proto.RankListReportResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RankListReportResp
     * @static
     * @param {client_proto.IRankListReportResp} message RankListReportResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RankListReportResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RankListReportResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RankListReportResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RankListReportResp} RankListReportResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RankListReportResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RankListReportResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.is_have_data = reader.bool();
            break;
          }
          case 2: {
            if (!(message.rank_list && message.rank_list.length)) message.rank_list = [];
            message.rank_list.push($root.client_proto.UserRankData.decode(reader, reader.uint32()));
            break;
          }
          case 3: {
            message.jackpot = reader.int64();
            break;
          }
          case 4: {
            message.last_round_jackpot = reader.int64();
            break;
          }
          case 5: {
            message.game_reward = reader.int32();
            break;
          }
          case 6: {
            message.current_join_next_round = reader.int64();
            break;
          }
          case 7: {
            message.id = reader.int32();
            break;
          }
          case 8: {
            message.rank_start_time = reader.uint32();
            break;
          }
          case 9: {
            message.rank_end_time = reader.uint32();
            break;
          }
          case 10: {
            message.number = reader.uint32();
            break;
          }
          case 11: {
            message.key = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RankListReportResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RankListReportResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RankListReportResp} RankListReportResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RankListReportResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RankListReportResp message.
     * @function verify
     * @memberof client_proto.RankListReportResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RankListReportResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        if (typeof message.is_have_data !== "boolean") return "is_have_data: boolean expected";
      if (message.rank_list != null && message.hasOwnProperty("rank_list")) {
        if (!Array.isArray(message.rank_list)) return "rank_list: array expected";
        for (var i = 0; i < message.rank_list.length; ++i) {
          var error = $root.client_proto.UserRankData.verify(message.rank_list[i]);
          if (error) return "rank_list." + error;
        }
      }
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (
          !$util.isInteger(message.jackpot) &&
          !(message.jackpot && $util.isInteger(message.jackpot.low) && $util.isInteger(message.jackpot.high))
        )
          return "jackpot: integer|Long expected";
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (
          !$util.isInteger(message.last_round_jackpot) &&
          !(
            message.last_round_jackpot &&
            $util.isInteger(message.last_round_jackpot.low) &&
            $util.isInteger(message.last_round_jackpot.high)
          )
        )
          return "last_round_jackpot: integer|Long expected";
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        if (!$util.isInteger(message.game_reward)) return "game_reward: integer expected";
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (
          !$util.isInteger(message.current_join_next_round) &&
          !(
            message.current_join_next_round &&
            $util.isInteger(message.current_join_next_round.low) &&
            $util.isInteger(message.current_join_next_round.high)
          )
        )
          return "current_join_next_round: integer|Long expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        if (!$util.isInteger(message.rank_start_time)) return "rank_start_time: integer expected";
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        if (!$util.isInteger(message.rank_end_time)) return "rank_end_time: integer expected";
      if (message.number != null && message.hasOwnProperty("number"))
        if (!$util.isInteger(message.number)) return "number: integer expected";
      if (message.key != null && message.hasOwnProperty("key"))
        if (!$util.isString(message.key)) return "key: string expected";
      return null;
    };

    /**
     * Creates a RankListReportResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RankListReportResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RankListReportResp} RankListReportResp
     */
    RankListReportResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RankListReportResp) return object;
      var message = new $root.client_proto.RankListReportResp();
      if (object.is_have_data != null) message.is_have_data = Boolean(object.is_have_data);
      if (object.rank_list) {
        if (!Array.isArray(object.rank_list))
          throw TypeError(".client_proto.RankListReportResp.rank_list: array expected");
        message.rank_list = [];
        for (var i = 0; i < object.rank_list.length; ++i) {
          if (typeof object.rank_list[i] !== "object")
            throw TypeError(".client_proto.RankListReportResp.rank_list: object expected");
          message.rank_list[i] = $root.client_proto.UserRankData.fromObject(object.rank_list[i]);
        }
      }
      if (object.jackpot != null)
        if ($util.Long) (message.jackpot = $util.Long.fromValue(object.jackpot)).unsigned = false;
        else if (typeof object.jackpot === "string") message.jackpot = parseInt(object.jackpot, 10);
        else if (typeof object.jackpot === "number") message.jackpot = object.jackpot;
        else if (typeof object.jackpot === "object")
          message.jackpot = new $util.LongBits(object.jackpot.low >>> 0, object.jackpot.high >>> 0).toNumber();
      if (object.last_round_jackpot != null)
        if ($util.Long) (message.last_round_jackpot = $util.Long.fromValue(object.last_round_jackpot)).unsigned = false;
        else if (typeof object.last_round_jackpot === "string")
          message.last_round_jackpot = parseInt(object.last_round_jackpot, 10);
        else if (typeof object.last_round_jackpot === "number") message.last_round_jackpot = object.last_round_jackpot;
        else if (typeof object.last_round_jackpot === "object")
          message.last_round_jackpot = new $util.LongBits(
            object.last_round_jackpot.low >>> 0,
            object.last_round_jackpot.high >>> 0,
          ).toNumber();
      if (object.game_reward != null) message.game_reward = object.game_reward | 0;
      if (object.current_join_next_round != null)
        if ($util.Long)
          (message.current_join_next_round = $util.Long.fromValue(object.current_join_next_round)).unsigned = false;
        else if (typeof object.current_join_next_round === "string")
          message.current_join_next_round = parseInt(object.current_join_next_round, 10);
        else if (typeof object.current_join_next_round === "number")
          message.current_join_next_round = object.current_join_next_round;
        else if (typeof object.current_join_next_round === "object")
          message.current_join_next_round = new $util.LongBits(
            object.current_join_next_round.low >>> 0,
            object.current_join_next_round.high >>> 0,
          ).toNumber();
      if (object.id != null) message.id = object.id | 0;
      if (object.rank_start_time != null) message.rank_start_time = object.rank_start_time >>> 0;
      if (object.rank_end_time != null) message.rank_end_time = object.rank_end_time >>> 0;
      if (object.number != null) message.number = object.number >>> 0;
      if (object.key != null) message.key = String(object.key);
      return message;
    };

    /**
     * Creates a plain object from a RankListReportResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RankListReportResp
     * @static
     * @param {client_proto.RankListReportResp} message RankListReportResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RankListReportResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.rank_list = [];
      if (options.defaults) {
        object.is_have_data = false;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.jackpot = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.last_round_jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.last_round_jackpot = options.longs === String ? "0" : 0;
        object.game_reward = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.current_join_next_round =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.current_join_next_round = options.longs === String ? "0" : 0;
        object.id = 0;
        object.rank_start_time = 0;
        object.rank_end_time = 0;
        object.number = 0;
        object.key = "";
      }
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        object.is_have_data = message.is_have_data;
      if (message.rank_list && message.rank_list.length) {
        object.rank_list = [];
        for (var j = 0; j < message.rank_list.length; ++j)
          object.rank_list[j] = $root.client_proto.UserRankData.toObject(message.rank_list[j], options);
      }
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (typeof message.jackpot === "number")
          object.jackpot = options.longs === String ? String(message.jackpot) : message.jackpot;
        else
          object.jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.jackpot)
              : options.longs === Number
                ? new $util.LongBits(message.jackpot.low >>> 0, message.jackpot.high >>> 0).toNumber()
                : message.jackpot;
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (typeof message.last_round_jackpot === "number")
          object.last_round_jackpot =
            options.longs === String ? String(message.last_round_jackpot) : message.last_round_jackpot;
        else
          object.last_round_jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.last_round_jackpot)
              : options.longs === Number
                ? new $util.LongBits(
                    message.last_round_jackpot.low >>> 0,
                    message.last_round_jackpot.high >>> 0,
                  ).toNumber()
                : message.last_round_jackpot;
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        object.game_reward = message.game_reward;
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (typeof message.current_join_next_round === "number")
          object.current_join_next_round =
            options.longs === String ? String(message.current_join_next_round) : message.current_join_next_round;
        else
          object.current_join_next_round =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.current_join_next_round)
              : options.longs === Number
                ? new $util.LongBits(
                    message.current_join_next_round.low >>> 0,
                    message.current_join_next_round.high >>> 0,
                  ).toNumber()
                : message.current_join_next_round;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        object.rank_start_time = message.rank_start_time;
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        object.rank_end_time = message.rank_end_time;
      if (message.number != null && message.hasOwnProperty("number")) object.number = message.number;
      if (message.key != null && message.hasOwnProperty("key")) object.key = message.key;
      return object;
    };

    /**
     * Converts this RankListReportResp to JSON.
     * @function toJSON
     * @memberof client_proto.RankListReportResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RankListReportResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RankListReportResp
     * @function getTypeUrl
     * @memberof client_proto.RankListReportResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RankListReportResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RankListReportResp";
    };

    return RankListReportResp;
  })();

  client_proto.RequestRankJackpotReq = (function () {
    /**
     * Properties of a RequestRankJackpotReq.
     * @memberof client_proto
     * @interface IRequestRankJackpotReq
     * @property {number|null} [id] RequestRankJackpotReq id
     */

    /**
     * Constructs a new RequestRankJackpotReq.
     * @memberof client_proto
     * @classdesc Represents a RequestRankJackpotReq.
     * @implements IRequestRankJackpotReq
     * @constructor
     * @param {client_proto.IRequestRankJackpotReq=} [properties] Properties to set
     */
    function RequestRankJackpotReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestRankJackpotReq id.
     * @member {number} id
     * @memberof client_proto.RequestRankJackpotReq
     * @instance
     */
    RequestRankJackpotReq.prototype.id = 0;

    /**
     * Creates a new RequestRankJackpotReq instance using the specified properties.
     * @function create
     * @memberof client_proto.RequestRankJackpotReq
     * @static
     * @param {client_proto.IRequestRankJackpotReq=} [properties] Properties to set
     * @returns {client_proto.RequestRankJackpotReq} RequestRankJackpotReq instance
     */
    RequestRankJackpotReq.create = function create(properties) {
      return new RequestRankJackpotReq(properties);
    };

    /**
     * Encodes the specified RequestRankJackpotReq message. Does not implicitly {@link client_proto.RequestRankJackpotReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RequestRankJackpotReq
     * @static
     * @param {client_proto.IRequestRankJackpotReq} message RequestRankJackpotReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRankJackpotReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified RequestRankJackpotReq message, length delimited. Does not implicitly {@link client_proto.RequestRankJackpotReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RequestRankJackpotReq
     * @static
     * @param {client_proto.IRequestRankJackpotReq} message RequestRankJackpotReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRankJackpotReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RequestRankJackpotReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RequestRankJackpotReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RequestRankJackpotReq} RequestRankJackpotReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRankJackpotReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RequestRankJackpotReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RequestRankJackpotReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RequestRankJackpotReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RequestRankJackpotReq} RequestRankJackpotReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRankJackpotReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RequestRankJackpotReq message.
     * @function verify
     * @memberof client_proto.RequestRankJackpotReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RequestRankJackpotReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates a RequestRankJackpotReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RequestRankJackpotReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RequestRankJackpotReq} RequestRankJackpotReq
     */
    RequestRankJackpotReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RequestRankJackpotReq) return object;
      var message = new $root.client_proto.RequestRankJackpotReq();
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from a RequestRankJackpotReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RequestRankJackpotReq
     * @static
     * @param {client_proto.RequestRankJackpotReq} message RequestRankJackpotReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RequestRankJackpotReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.id = 0;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this RequestRankJackpotReq to JSON.
     * @function toJSON
     * @memberof client_proto.RequestRankJackpotReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RequestRankJackpotReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RequestRankJackpotReq
     * @function getTypeUrl
     * @memberof client_proto.RequestRankJackpotReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RequestRankJackpotReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RequestRankJackpotReq";
    };

    return RequestRankJackpotReq;
  })();

  client_proto.ResponseRankJackpotResp = (function () {
    /**
     * Properties of a ResponseRankJackpotResp.
     * @memberof client_proto
     * @interface IResponseRankJackpotResp
     * @property {boolean|null} [is_have_data] ResponseRankJackpotResp is_have_data
     * @property {number|Long|null} [jackpot] ResponseRankJackpotResp jackpot
     * @property {number|Long|null} [last_round_jackpot] ResponseRankJackpotResp last_round_jackpot
     * @property {number|null} [game_reward] ResponseRankJackpotResp game_reward
     * @property {number|Long|null} [current_join_next_round] ResponseRankJackpotResp current_join_next_round
     * @property {number|null} [id] ResponseRankJackpotResp id
     */

    /**
     * Constructs a new ResponseRankJackpotResp.
     * @memberof client_proto
     * @classdesc Represents a ResponseRankJackpotResp.
     * @implements IResponseRankJackpotResp
     * @constructor
     * @param {client_proto.IResponseRankJackpotResp=} [properties] Properties to set
     */
    function ResponseRankJackpotResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseRankJackpotResp is_have_data.
     * @member {boolean} is_have_data
     * @memberof client_proto.ResponseRankJackpotResp
     * @instance
     */
    ResponseRankJackpotResp.prototype.is_have_data = false;

    /**
     * ResponseRankJackpotResp jackpot.
     * @member {number|Long} jackpot
     * @memberof client_proto.ResponseRankJackpotResp
     * @instance
     */
    ResponseRankJackpotResp.prototype.jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ResponseRankJackpotResp last_round_jackpot.
     * @member {number|Long} last_round_jackpot
     * @memberof client_proto.ResponseRankJackpotResp
     * @instance
     */
    ResponseRankJackpotResp.prototype.last_round_jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ResponseRankJackpotResp game_reward.
     * @member {number} game_reward
     * @memberof client_proto.ResponseRankJackpotResp
     * @instance
     */
    ResponseRankJackpotResp.prototype.game_reward = 0;

    /**
     * ResponseRankJackpotResp current_join_next_round.
     * @member {number|Long} current_join_next_round
     * @memberof client_proto.ResponseRankJackpotResp
     * @instance
     */
    ResponseRankJackpotResp.prototype.current_join_next_round = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ResponseRankJackpotResp id.
     * @member {number} id
     * @memberof client_proto.ResponseRankJackpotResp
     * @instance
     */
    ResponseRankJackpotResp.prototype.id = 0;

    /**
     * Creates a new ResponseRankJackpotResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ResponseRankJackpotResp
     * @static
     * @param {client_proto.IResponseRankJackpotResp=} [properties] Properties to set
     * @returns {client_proto.ResponseRankJackpotResp} ResponseRankJackpotResp instance
     */
    ResponseRankJackpotResp.create = function create(properties) {
      return new ResponseRankJackpotResp(properties);
    };

    /**
     * Encodes the specified ResponseRankJackpotResp message. Does not implicitly {@link client_proto.ResponseRankJackpotResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ResponseRankJackpotResp
     * @static
     * @param {client_proto.IResponseRankJackpotResp} message ResponseRankJackpotResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseRankJackpotResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.is_have_data != null && Object.hasOwnProperty.call(message, "is_have_data"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.is_have_data);
      if (message.jackpot != null && Object.hasOwnProperty.call(message, "jackpot"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.jackpot);
      if (message.last_round_jackpot != null && Object.hasOwnProperty.call(message, "last_round_jackpot"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.last_round_jackpot);
      if (message.game_reward != null && Object.hasOwnProperty.call(message, "game_reward"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.game_reward);
      if (message.current_join_next_round != null && Object.hasOwnProperty.call(message, "current_join_next_round"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.current_join_next_round);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified ResponseRankJackpotResp message, length delimited. Does not implicitly {@link client_proto.ResponseRankJackpotResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ResponseRankJackpotResp
     * @static
     * @param {client_proto.IResponseRankJackpotResp} message ResponseRankJackpotResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseRankJackpotResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ResponseRankJackpotResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ResponseRankJackpotResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ResponseRankJackpotResp} ResponseRankJackpotResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseRankJackpotResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ResponseRankJackpotResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.is_have_data = reader.bool();
            break;
          }
          case 2: {
            message.jackpot = reader.int64();
            break;
          }
          case 3: {
            message.last_round_jackpot = reader.int64();
            break;
          }
          case 4: {
            message.game_reward = reader.int32();
            break;
          }
          case 5: {
            message.current_join_next_round = reader.int64();
            break;
          }
          case 6: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ResponseRankJackpotResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ResponseRankJackpotResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ResponseRankJackpotResp} ResponseRankJackpotResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseRankJackpotResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ResponseRankJackpotResp message.
     * @function verify
     * @memberof client_proto.ResponseRankJackpotResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ResponseRankJackpotResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        if (typeof message.is_have_data !== "boolean") return "is_have_data: boolean expected";
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (
          !$util.isInteger(message.jackpot) &&
          !(message.jackpot && $util.isInteger(message.jackpot.low) && $util.isInteger(message.jackpot.high))
        )
          return "jackpot: integer|Long expected";
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (
          !$util.isInteger(message.last_round_jackpot) &&
          !(
            message.last_round_jackpot &&
            $util.isInteger(message.last_round_jackpot.low) &&
            $util.isInteger(message.last_round_jackpot.high)
          )
        )
          return "last_round_jackpot: integer|Long expected";
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        if (!$util.isInteger(message.game_reward)) return "game_reward: integer expected";
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (
          !$util.isInteger(message.current_join_next_round) &&
          !(
            message.current_join_next_round &&
            $util.isInteger(message.current_join_next_round.low) &&
            $util.isInteger(message.current_join_next_round.high)
          )
        )
          return "current_join_next_round: integer|Long expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates a ResponseRankJackpotResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ResponseRankJackpotResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ResponseRankJackpotResp} ResponseRankJackpotResp
     */
    ResponseRankJackpotResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ResponseRankJackpotResp) return object;
      var message = new $root.client_proto.ResponseRankJackpotResp();
      if (object.is_have_data != null) message.is_have_data = Boolean(object.is_have_data);
      if (object.jackpot != null)
        if ($util.Long) (message.jackpot = $util.Long.fromValue(object.jackpot)).unsigned = false;
        else if (typeof object.jackpot === "string") message.jackpot = parseInt(object.jackpot, 10);
        else if (typeof object.jackpot === "number") message.jackpot = object.jackpot;
        else if (typeof object.jackpot === "object")
          message.jackpot = new $util.LongBits(object.jackpot.low >>> 0, object.jackpot.high >>> 0).toNumber();
      if (object.last_round_jackpot != null)
        if ($util.Long) (message.last_round_jackpot = $util.Long.fromValue(object.last_round_jackpot)).unsigned = false;
        else if (typeof object.last_round_jackpot === "string")
          message.last_round_jackpot = parseInt(object.last_round_jackpot, 10);
        else if (typeof object.last_round_jackpot === "number") message.last_round_jackpot = object.last_round_jackpot;
        else if (typeof object.last_round_jackpot === "object")
          message.last_round_jackpot = new $util.LongBits(
            object.last_round_jackpot.low >>> 0,
            object.last_round_jackpot.high >>> 0,
          ).toNumber();
      if (object.game_reward != null) message.game_reward = object.game_reward | 0;
      if (object.current_join_next_round != null)
        if ($util.Long)
          (message.current_join_next_round = $util.Long.fromValue(object.current_join_next_round)).unsigned = false;
        else if (typeof object.current_join_next_round === "string")
          message.current_join_next_round = parseInt(object.current_join_next_round, 10);
        else if (typeof object.current_join_next_round === "number")
          message.current_join_next_round = object.current_join_next_round;
        else if (typeof object.current_join_next_round === "object")
          message.current_join_next_round = new $util.LongBits(
            object.current_join_next_round.low >>> 0,
            object.current_join_next_round.high >>> 0,
          ).toNumber();
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from a ResponseRankJackpotResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ResponseRankJackpotResp
     * @static
     * @param {client_proto.ResponseRankJackpotResp} message ResponseRankJackpotResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ResponseRankJackpotResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.is_have_data = false;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.jackpot = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.last_round_jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.last_round_jackpot = options.longs === String ? "0" : 0;
        object.game_reward = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.current_join_next_round =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.current_join_next_round = options.longs === String ? "0" : 0;
        object.id = 0;
      }
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        object.is_have_data = message.is_have_data;
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (typeof message.jackpot === "number")
          object.jackpot = options.longs === String ? String(message.jackpot) : message.jackpot;
        else
          object.jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.jackpot)
              : options.longs === Number
                ? new $util.LongBits(message.jackpot.low >>> 0, message.jackpot.high >>> 0).toNumber()
                : message.jackpot;
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (typeof message.last_round_jackpot === "number")
          object.last_round_jackpot =
            options.longs === String ? String(message.last_round_jackpot) : message.last_round_jackpot;
        else
          object.last_round_jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.last_round_jackpot)
              : options.longs === Number
                ? new $util.LongBits(
                    message.last_round_jackpot.low >>> 0,
                    message.last_round_jackpot.high >>> 0,
                  ).toNumber()
                : message.last_round_jackpot;
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        object.game_reward = message.game_reward;
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (typeof message.current_join_next_round === "number")
          object.current_join_next_round =
            options.longs === String ? String(message.current_join_next_round) : message.current_join_next_round;
        else
          object.current_join_next_round =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.current_join_next_round)
              : options.longs === Number
                ? new $util.LongBits(
                    message.current_join_next_round.low >>> 0,
                    message.current_join_next_round.high >>> 0,
                  ).toNumber()
                : message.current_join_next_round;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this ResponseRankJackpotResp to JSON.
     * @function toJSON
     * @memberof client_proto.ResponseRankJackpotResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ResponseRankJackpotResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ResponseRankJackpotResp
     * @function getTypeUrl
     * @memberof client_proto.ResponseRankJackpotResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ResponseRankJackpotResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ResponseRankJackpotResp";
    };

    return ResponseRankJackpotResp;
  })();

  client_proto.RequestJoinGameListReq = (function () {
    /**
     * Properties of a RequestJoinGameListReq.
     * @memberof client_proto
     * @interface IRequestJoinGameListReq
     * @property {number|null} [id] RequestJoinGameListReq id
     */

    /**
     * Constructs a new RequestJoinGameListReq.
     * @memberof client_proto
     * @classdesc Represents a RequestJoinGameListReq.
     * @implements IRequestJoinGameListReq
     * @constructor
     * @param {client_proto.IRequestJoinGameListReq=} [properties] Properties to set
     */
    function RequestJoinGameListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestJoinGameListReq id.
     * @member {number} id
     * @memberof client_proto.RequestJoinGameListReq
     * @instance
     */
    RequestJoinGameListReq.prototype.id = 0;

    /**
     * Creates a new RequestJoinGameListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.RequestJoinGameListReq
     * @static
     * @param {client_proto.IRequestJoinGameListReq=} [properties] Properties to set
     * @returns {client_proto.RequestJoinGameListReq} RequestJoinGameListReq instance
     */
    RequestJoinGameListReq.create = function create(properties) {
      return new RequestJoinGameListReq(properties);
    };

    /**
     * Encodes the specified RequestJoinGameListReq message. Does not implicitly {@link client_proto.RequestJoinGameListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RequestJoinGameListReq
     * @static
     * @param {client_proto.IRequestJoinGameListReq} message RequestJoinGameListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestJoinGameListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified RequestJoinGameListReq message, length delimited. Does not implicitly {@link client_proto.RequestJoinGameListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RequestJoinGameListReq
     * @static
     * @param {client_proto.IRequestJoinGameListReq} message RequestJoinGameListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestJoinGameListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RequestJoinGameListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RequestJoinGameListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RequestJoinGameListReq} RequestJoinGameListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestJoinGameListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RequestJoinGameListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RequestJoinGameListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RequestJoinGameListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RequestJoinGameListReq} RequestJoinGameListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestJoinGameListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RequestJoinGameListReq message.
     * @function verify
     * @memberof client_proto.RequestJoinGameListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RequestJoinGameListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates a RequestJoinGameListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RequestJoinGameListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RequestJoinGameListReq} RequestJoinGameListReq
     */
    RequestJoinGameListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RequestJoinGameListReq) return object;
      var message = new $root.client_proto.RequestJoinGameListReq();
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from a RequestJoinGameListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RequestJoinGameListReq
     * @static
     * @param {client_proto.RequestJoinGameListReq} message RequestJoinGameListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RequestJoinGameListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.id = 0;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this RequestJoinGameListReq to JSON.
     * @function toJSON
     * @memberof client_proto.RequestJoinGameListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RequestJoinGameListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RequestJoinGameListReq
     * @function getTypeUrl
     * @memberof client_proto.RequestJoinGameListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RequestJoinGameListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RequestJoinGameListReq";
    };

    return RequestJoinGameListReq;
  })();

  client_proto.ResponseJoinGameListResp = (function () {
    /**
     * Properties of a ResponseJoinGameListResp.
     * @memberof client_proto
     * @interface IResponseJoinGameListResp
     * @property {Array.<number>|null} [game_id_list] ResponseJoinGameListResp game_id_list
     * @property {number|null} [id] ResponseJoinGameListResp id
     * @property {Array.<number>|null} [company_id_list] ResponseJoinGameListResp company_id_list
     * @property {Array.<number>|null} [company_black_list] ResponseJoinGameListResp company_black_list
     * @property {Array.<number>|null} [game_black_list] ResponseJoinGameListResp game_black_list
     */

    /**
     * Constructs a new ResponseJoinGameListResp.
     * @memberof client_proto
     * @classdesc Represents a ResponseJoinGameListResp.
     * @implements IResponseJoinGameListResp
     * @constructor
     * @param {client_proto.IResponseJoinGameListResp=} [properties] Properties to set
     */
    function ResponseJoinGameListResp(properties) {
      this.game_id_list = [];
      this.company_id_list = [];
      this.company_black_list = [];
      this.game_black_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseJoinGameListResp game_id_list.
     * @member {Array.<number>} game_id_list
     * @memberof client_proto.ResponseJoinGameListResp
     * @instance
     */
    ResponseJoinGameListResp.prototype.game_id_list = $util.emptyArray;

    /**
     * ResponseJoinGameListResp id.
     * @member {number} id
     * @memberof client_proto.ResponseJoinGameListResp
     * @instance
     */
    ResponseJoinGameListResp.prototype.id = 0;

    /**
     * ResponseJoinGameListResp company_id_list.
     * @member {Array.<number>} company_id_list
     * @memberof client_proto.ResponseJoinGameListResp
     * @instance
     */
    ResponseJoinGameListResp.prototype.company_id_list = $util.emptyArray;

    /**
     * ResponseJoinGameListResp company_black_list.
     * @member {Array.<number>} company_black_list
     * @memberof client_proto.ResponseJoinGameListResp
     * @instance
     */
    ResponseJoinGameListResp.prototype.company_black_list = $util.emptyArray;

    /**
     * ResponseJoinGameListResp game_black_list.
     * @member {Array.<number>} game_black_list
     * @memberof client_proto.ResponseJoinGameListResp
     * @instance
     */
    ResponseJoinGameListResp.prototype.game_black_list = $util.emptyArray;

    /**
     * Creates a new ResponseJoinGameListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ResponseJoinGameListResp
     * @static
     * @param {client_proto.IResponseJoinGameListResp=} [properties] Properties to set
     * @returns {client_proto.ResponseJoinGameListResp} ResponseJoinGameListResp instance
     */
    ResponseJoinGameListResp.create = function create(properties) {
      return new ResponseJoinGameListResp(properties);
    };

    /**
     * Encodes the specified ResponseJoinGameListResp message. Does not implicitly {@link client_proto.ResponseJoinGameListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ResponseJoinGameListResp
     * @static
     * @param {client_proto.IResponseJoinGameListResp} message ResponseJoinGameListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseJoinGameListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_id_list != null && message.game_id_list.length) {
        writer.uint32(/* id 1, wireType 2 =*/ 10).fork();
        for (var i = 0; i < message.game_id_list.length; ++i) writer.uint32(message.game_id_list[i]);
        writer.ldelim();
      }
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.company_id_list != null && message.company_id_list.length) {
        writer.uint32(/* id 3, wireType 2 =*/ 26).fork();
        for (var i = 0; i < message.company_id_list.length; ++i) writer.uint32(message.company_id_list[i]);
        writer.ldelim();
      }
      if (message.company_black_list != null && message.company_black_list.length) {
        writer.uint32(/* id 4, wireType 2 =*/ 34).fork();
        for (var i = 0; i < message.company_black_list.length; ++i) writer.uint32(message.company_black_list[i]);
        writer.ldelim();
      }
      if (message.game_black_list != null && message.game_black_list.length) {
        writer.uint32(/* id 5, wireType 2 =*/ 42).fork();
        for (var i = 0; i < message.game_black_list.length; ++i) writer.uint32(message.game_black_list[i]);
        writer.ldelim();
      }
      return writer;
    };

    /**
     * Encodes the specified ResponseJoinGameListResp message, length delimited. Does not implicitly {@link client_proto.ResponseJoinGameListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ResponseJoinGameListResp
     * @static
     * @param {client_proto.IResponseJoinGameListResp} message ResponseJoinGameListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseJoinGameListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ResponseJoinGameListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ResponseJoinGameListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ResponseJoinGameListResp} ResponseJoinGameListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseJoinGameListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ResponseJoinGameListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.game_id_list && message.game_id_list.length)) message.game_id_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.game_id_list.push(reader.uint32());
            } else message.game_id_list.push(reader.uint32());
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            if (!(message.company_id_list && message.company_id_list.length)) message.company_id_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.company_id_list.push(reader.uint32());
            } else message.company_id_list.push(reader.uint32());
            break;
          }
          case 4: {
            if (!(message.company_black_list && message.company_black_list.length)) message.company_black_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.company_black_list.push(reader.uint32());
            } else message.company_black_list.push(reader.uint32());
            break;
          }
          case 5: {
            if (!(message.game_black_list && message.game_black_list.length)) message.game_black_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.game_black_list.push(reader.uint32());
            } else message.game_black_list.push(reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ResponseJoinGameListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ResponseJoinGameListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ResponseJoinGameListResp} ResponseJoinGameListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseJoinGameListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ResponseJoinGameListResp message.
     * @function verify
     * @memberof client_proto.ResponseJoinGameListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ResponseJoinGameListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_id_list != null && message.hasOwnProperty("game_id_list")) {
        if (!Array.isArray(message.game_id_list)) return "game_id_list: array expected";
        for (var i = 0; i < message.game_id_list.length; ++i)
          if (!$util.isInteger(message.game_id_list[i])) return "game_id_list: integer[] expected";
      }
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.company_id_list != null && message.hasOwnProperty("company_id_list")) {
        if (!Array.isArray(message.company_id_list)) return "company_id_list: array expected";
        for (var i = 0; i < message.company_id_list.length; ++i)
          if (!$util.isInteger(message.company_id_list[i])) return "company_id_list: integer[] expected";
      }
      if (message.company_black_list != null && message.hasOwnProperty("company_black_list")) {
        if (!Array.isArray(message.company_black_list)) return "company_black_list: array expected";
        for (var i = 0; i < message.company_black_list.length; ++i)
          if (!$util.isInteger(message.company_black_list[i])) return "company_black_list: integer[] expected";
      }
      if (message.game_black_list != null && message.hasOwnProperty("game_black_list")) {
        if (!Array.isArray(message.game_black_list)) return "game_black_list: array expected";
        for (var i = 0; i < message.game_black_list.length; ++i)
          if (!$util.isInteger(message.game_black_list[i])) return "game_black_list: integer[] expected";
      }
      return null;
    };

    /**
     * Creates a ResponseJoinGameListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ResponseJoinGameListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ResponseJoinGameListResp} ResponseJoinGameListResp
     */
    ResponseJoinGameListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ResponseJoinGameListResp) return object;
      var message = new $root.client_proto.ResponseJoinGameListResp();
      if (object.game_id_list) {
        if (!Array.isArray(object.game_id_list))
          throw TypeError(".client_proto.ResponseJoinGameListResp.game_id_list: array expected");
        message.game_id_list = [];
        for (var i = 0; i < object.game_id_list.length; ++i) message.game_id_list[i] = object.game_id_list[i] >>> 0;
      }
      if (object.id != null) message.id = object.id | 0;
      if (object.company_id_list) {
        if (!Array.isArray(object.company_id_list))
          throw TypeError(".client_proto.ResponseJoinGameListResp.company_id_list: array expected");
        message.company_id_list = [];
        for (var i = 0; i < object.company_id_list.length; ++i)
          message.company_id_list[i] = object.company_id_list[i] >>> 0;
      }
      if (object.company_black_list) {
        if (!Array.isArray(object.company_black_list))
          throw TypeError(".client_proto.ResponseJoinGameListResp.company_black_list: array expected");
        message.company_black_list = [];
        for (var i = 0; i < object.company_black_list.length; ++i)
          message.company_black_list[i] = object.company_black_list[i] >>> 0;
      }
      if (object.game_black_list) {
        if (!Array.isArray(object.game_black_list))
          throw TypeError(".client_proto.ResponseJoinGameListResp.game_black_list: array expected");
        message.game_black_list = [];
        for (var i = 0; i < object.game_black_list.length; ++i)
          message.game_black_list[i] = object.game_black_list[i] >>> 0;
      }
      return message;
    };

    /**
     * Creates a plain object from a ResponseJoinGameListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ResponseJoinGameListResp
     * @static
     * @param {client_proto.ResponseJoinGameListResp} message ResponseJoinGameListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ResponseJoinGameListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) {
        object.game_id_list = [];
        object.company_id_list = [];
        object.company_black_list = [];
        object.game_black_list = [];
      }
      if (options.defaults) object.id = 0;
      if (message.game_id_list && message.game_id_list.length) {
        object.game_id_list = [];
        for (var j = 0; j < message.game_id_list.length; ++j) object.game_id_list[j] = message.game_id_list[j];
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.company_id_list && message.company_id_list.length) {
        object.company_id_list = [];
        for (var j = 0; j < message.company_id_list.length; ++j) object.company_id_list[j] = message.company_id_list[j];
      }
      if (message.company_black_list && message.company_black_list.length) {
        object.company_black_list = [];
        for (var j = 0; j < message.company_black_list.length; ++j)
          object.company_black_list[j] = message.company_black_list[j];
      }
      if (message.game_black_list && message.game_black_list.length) {
        object.game_black_list = [];
        for (var j = 0; j < message.game_black_list.length; ++j) object.game_black_list[j] = message.game_black_list[j];
      }
      return object;
    };

    /**
     * Converts this ResponseJoinGameListResp to JSON.
     * @function toJSON
     * @memberof client_proto.ResponseJoinGameListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ResponseJoinGameListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ResponseJoinGameListResp
     * @function getTypeUrl
     * @memberof client_proto.ResponseJoinGameListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ResponseJoinGameListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ResponseJoinGameListResp";
    };

    return ResponseJoinGameListResp;
  })();

  client_proto.RequestRewardListReq = (function () {
    /**
     * Properties of a RequestRewardListReq.
     * @memberof client_proto
     * @interface IRequestRewardListReq
     * @property {number|null} [uid] RequestRewardListReq uid
     */

    /**
     * Constructs a new RequestRewardListReq.
     * @memberof client_proto
     * @classdesc Represents a RequestRewardListReq.
     * @implements IRequestRewardListReq
     * @constructor
     * @param {client_proto.IRequestRewardListReq=} [properties] Properties to set
     */
    function RequestRewardListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestRewardListReq uid.
     * @member {number} uid
     * @memberof client_proto.RequestRewardListReq
     * @instance
     */
    RequestRewardListReq.prototype.uid = 0;

    /**
     * Creates a new RequestRewardListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.RequestRewardListReq
     * @static
     * @param {client_proto.IRequestRewardListReq=} [properties] Properties to set
     * @returns {client_proto.RequestRewardListReq} RequestRewardListReq instance
     */
    RequestRewardListReq.create = function create(properties) {
      return new RequestRewardListReq(properties);
    };

    /**
     * Encodes the specified RequestRewardListReq message. Does not implicitly {@link client_proto.RequestRewardListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RequestRewardListReq
     * @static
     * @param {client_proto.IRequestRewardListReq} message RequestRewardListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRewardListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified RequestRewardListReq message, length delimited. Does not implicitly {@link client_proto.RequestRewardListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RequestRewardListReq
     * @static
     * @param {client_proto.IRequestRewardListReq} message RequestRewardListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRewardListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RequestRewardListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RequestRewardListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RequestRewardListReq} RequestRewardListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRewardListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RequestRewardListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RequestRewardListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RequestRewardListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RequestRewardListReq} RequestRewardListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRewardListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RequestRewardListReq message.
     * @function verify
     * @memberof client_proto.RequestRewardListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RequestRewardListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates a RequestRewardListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RequestRewardListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RequestRewardListReq} RequestRewardListReq
     */
    RequestRewardListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RequestRewardListReq) return object;
      var message = new $root.client_proto.RequestRewardListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a RequestRewardListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RequestRewardListReq
     * @static
     * @param {client_proto.RequestRewardListReq} message RequestRewardListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RequestRewardListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this RequestRewardListReq to JSON.
     * @function toJSON
     * @memberof client_proto.RequestRewardListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RequestRewardListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RequestRewardListReq
     * @function getTypeUrl
     * @memberof client_proto.RequestRewardListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RequestRewardListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RequestRewardListReq";
    };

    return RequestRewardListReq;
  })();

  client_proto.RewardData = (function () {
    /**
     * Properties of a RewardData.
     * @memberof client_proto
     * @interface IRewardData
     * @property {number|null} [id] RewardData id
     * @property {number|null} [rank_list_type] RewardData rank_list_type
     * @property {number|null} [reward_time] RewardData reward_time
     * @property {number|Long|null} [jackpot] RewardData jackpot
     * @property {number|null} [reward] RewardData reward
     * @property {number|null} [rank] RewardData rank
     * @property {number|Long|null} [money] RewardData money
     * @property {number|null} [state] RewardData state
     * @property {number|Long|null} [reward_money] RewardData reward_money
     * @property {number|Long|null} [reward_id] RewardData reward_id
     * @property {number|null} [reward_money_type] RewardData reward_money_type
     * @property {number|null} [rank_start_time] RewardData rank_start_time
     * @property {number|null} [rank_end_time] RewardData rank_end_time
     * @property {string|null} [name] RewardData name
     */

    /**
     * Constructs a new RewardData.
     * @memberof client_proto
     * @classdesc Represents a RewardData.
     * @implements IRewardData
     * @constructor
     * @param {client_proto.IRewardData=} [properties] Properties to set
     */
    function RewardData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RewardData id.
     * @member {number} id
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.id = 0;

    /**
     * RewardData rank_list_type.
     * @member {number} rank_list_type
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.rank_list_type = 0;

    /**
     * RewardData reward_time.
     * @member {number} reward_time
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.reward_time = 0;

    /**
     * RewardData jackpot.
     * @member {number|Long} jackpot
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RewardData reward.
     * @member {number} reward
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.reward = 0;

    /**
     * RewardData rank.
     * @member {number} rank
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.rank = 0;

    /**
     * RewardData money.
     * @member {number|Long} money
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RewardData state.
     * @member {number} state
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.state = 0;

    /**
     * RewardData reward_money.
     * @member {number|Long} reward_money
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.reward_money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RewardData reward_id.
     * @member {number|Long} reward_id
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.reward_id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RewardData reward_money_type.
     * @member {number} reward_money_type
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.reward_money_type = 0;

    /**
     * RewardData rank_start_time.
     * @member {number} rank_start_time
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.rank_start_time = 0;

    /**
     * RewardData rank_end_time.
     * @member {number} rank_end_time
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.rank_end_time = 0;

    /**
     * RewardData name.
     * @member {string} name
     * @memberof client_proto.RewardData
     * @instance
     */
    RewardData.prototype.name = "";

    /**
     * Creates a new RewardData instance using the specified properties.
     * @function create
     * @memberof client_proto.RewardData
     * @static
     * @param {client_proto.IRewardData=} [properties] Properties to set
     * @returns {client_proto.RewardData} RewardData instance
     */
    RewardData.create = function create(properties) {
      return new RewardData(properties);
    };

    /**
     * Encodes the specified RewardData message. Does not implicitly {@link client_proto.RewardData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RewardData
     * @static
     * @param {client_proto.IRewardData} message RewardData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RewardData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.rank_list_type != null && Object.hasOwnProperty.call(message, "rank_list_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.rank_list_type);
      if (message.reward_time != null && Object.hasOwnProperty.call(message, "reward_time"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.reward_time);
      if (message.jackpot != null && Object.hasOwnProperty.call(message, "jackpot"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.jackpot);
      if (message.reward != null && Object.hasOwnProperty.call(message, "reward"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.reward);
      if (message.rank != null && Object.hasOwnProperty.call(message, "rank"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.rank);
      if (message.money != null && Object.hasOwnProperty.call(message, "money"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.money);
      if (message.state != null && Object.hasOwnProperty.call(message, "state"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.state);
      if (message.reward_money != null && Object.hasOwnProperty.call(message, "reward_money"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.reward_money);
      if (message.reward_id != null && Object.hasOwnProperty.call(message, "reward_id"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.reward_id);
      if (message.reward_money_type != null && Object.hasOwnProperty.call(message, "reward_money_type"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int32(message.reward_money_type);
      if (message.rank_start_time != null && Object.hasOwnProperty.call(message, "rank_start_time"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).uint32(message.rank_start_time);
      if (message.rank_end_time != null && Object.hasOwnProperty.call(message, "rank_end_time"))
        writer.uint32(/* id 13, wireType 0 =*/ 104).uint32(message.rank_end_time);
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 14, wireType 2 =*/ 114).string(message.name);
      return writer;
    };

    /**
     * Encodes the specified RewardData message, length delimited. Does not implicitly {@link client_proto.RewardData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RewardData
     * @static
     * @param {client_proto.IRewardData} message RewardData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RewardData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RewardData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RewardData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RewardData} RewardData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RewardData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RewardData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.rank_list_type = reader.int32();
            break;
          }
          case 3: {
            message.reward_time = reader.uint32();
            break;
          }
          case 4: {
            message.jackpot = reader.int64();
            break;
          }
          case 5: {
            message.reward = reader.int32();
            break;
          }
          case 6: {
            message.rank = reader.int32();
            break;
          }
          case 7: {
            message.money = reader.int64();
            break;
          }
          case 8: {
            message.state = reader.int32();
            break;
          }
          case 9: {
            message.reward_money = reader.int64();
            break;
          }
          case 10: {
            message.reward_id = reader.int64();
            break;
          }
          case 11: {
            message.reward_money_type = reader.int32();
            break;
          }
          case 12: {
            message.rank_start_time = reader.uint32();
            break;
          }
          case 13: {
            message.rank_end_time = reader.uint32();
            break;
          }
          case 14: {
            message.name = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RewardData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RewardData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RewardData} RewardData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RewardData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RewardData message.
     * @function verify
     * @memberof client_proto.RewardData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RewardData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.rank_list_type != null && message.hasOwnProperty("rank_list_type"))
        if (!$util.isInteger(message.rank_list_type)) return "rank_list_type: integer expected";
      if (message.reward_time != null && message.hasOwnProperty("reward_time"))
        if (!$util.isInteger(message.reward_time)) return "reward_time: integer expected";
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (
          !$util.isInteger(message.jackpot) &&
          !(message.jackpot && $util.isInteger(message.jackpot.low) && $util.isInteger(message.jackpot.high))
        )
          return "jackpot: integer|Long expected";
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (!$util.isInteger(message.reward)) return "reward: integer expected";
      if (message.rank != null && message.hasOwnProperty("rank"))
        if (!$util.isInteger(message.rank)) return "rank: integer expected";
      if (message.money != null && message.hasOwnProperty("money"))
        if (
          !$util.isInteger(message.money) &&
          !(message.money && $util.isInteger(message.money.low) && $util.isInteger(message.money.high))
        )
          return "money: integer|Long expected";
      if (message.state != null && message.hasOwnProperty("state"))
        if (!$util.isInteger(message.state)) return "state: integer expected";
      if (message.reward_money != null && message.hasOwnProperty("reward_money"))
        if (
          !$util.isInteger(message.reward_money) &&
          !(
            message.reward_money &&
            $util.isInteger(message.reward_money.low) &&
            $util.isInteger(message.reward_money.high)
          )
        )
          return "reward_money: integer|Long expected";
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (
          !$util.isInteger(message.reward_id) &&
          !(message.reward_id && $util.isInteger(message.reward_id.low) && $util.isInteger(message.reward_id.high))
        )
          return "reward_id: integer|Long expected";
      if (message.reward_money_type != null && message.hasOwnProperty("reward_money_type"))
        if (!$util.isInteger(message.reward_money_type)) return "reward_money_type: integer expected";
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        if (!$util.isInteger(message.rank_start_time)) return "rank_start_time: integer expected";
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        if (!$util.isInteger(message.rank_end_time)) return "rank_end_time: integer expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      return null;
    };

    /**
     * Creates a RewardData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RewardData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RewardData} RewardData
     */
    RewardData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RewardData) return object;
      var message = new $root.client_proto.RewardData();
      if (object.id != null) message.id = object.id | 0;
      if (object.rank_list_type != null) message.rank_list_type = object.rank_list_type | 0;
      if (object.reward_time != null) message.reward_time = object.reward_time >>> 0;
      if (object.jackpot != null)
        if ($util.Long) (message.jackpot = $util.Long.fromValue(object.jackpot)).unsigned = false;
        else if (typeof object.jackpot === "string") message.jackpot = parseInt(object.jackpot, 10);
        else if (typeof object.jackpot === "number") message.jackpot = object.jackpot;
        else if (typeof object.jackpot === "object")
          message.jackpot = new $util.LongBits(object.jackpot.low >>> 0, object.jackpot.high >>> 0).toNumber();
      if (object.reward != null) message.reward = object.reward | 0;
      if (object.rank != null) message.rank = object.rank | 0;
      if (object.money != null)
        if ($util.Long) (message.money = $util.Long.fromValue(object.money)).unsigned = false;
        else if (typeof object.money === "string") message.money = parseInt(object.money, 10);
        else if (typeof object.money === "number") message.money = object.money;
        else if (typeof object.money === "object")
          message.money = new $util.LongBits(object.money.low >>> 0, object.money.high >>> 0).toNumber();
      if (object.state != null) message.state = object.state | 0;
      if (object.reward_money != null)
        if ($util.Long) (message.reward_money = $util.Long.fromValue(object.reward_money)).unsigned = false;
        else if (typeof object.reward_money === "string") message.reward_money = parseInt(object.reward_money, 10);
        else if (typeof object.reward_money === "number") message.reward_money = object.reward_money;
        else if (typeof object.reward_money === "object")
          message.reward_money = new $util.LongBits(
            object.reward_money.low >>> 0,
            object.reward_money.high >>> 0,
          ).toNumber();
      if (object.reward_id != null)
        if ($util.Long) (message.reward_id = $util.Long.fromValue(object.reward_id)).unsigned = false;
        else if (typeof object.reward_id === "string") message.reward_id = parseInt(object.reward_id, 10);
        else if (typeof object.reward_id === "number") message.reward_id = object.reward_id;
        else if (typeof object.reward_id === "object")
          message.reward_id = new $util.LongBits(object.reward_id.low >>> 0, object.reward_id.high >>> 0).toNumber();
      if (object.reward_money_type != null) message.reward_money_type = object.reward_money_type | 0;
      if (object.rank_start_time != null) message.rank_start_time = object.rank_start_time >>> 0;
      if (object.rank_end_time != null) message.rank_end_time = object.rank_end_time >>> 0;
      if (object.name != null) message.name = String(object.name);
      return message;
    };

    /**
     * Creates a plain object from a RewardData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RewardData
     * @static
     * @param {client_proto.RewardData} message RewardData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RewardData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.rank_list_type = 0;
        object.reward_time = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.jackpot = options.longs === String ? "0" : 0;
        object.reward = 0;
        object.rank = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.money = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.money = options.longs === String ? "0" : 0;
        object.state = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_money =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_money = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_id =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_id = options.longs === String ? "0" : 0;
        object.reward_money_type = 0;
        object.rank_start_time = 0;
        object.rank_end_time = 0;
        object.name = "";
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.rank_list_type != null && message.hasOwnProperty("rank_list_type"))
        object.rank_list_type = message.rank_list_type;
      if (message.reward_time != null && message.hasOwnProperty("reward_time"))
        object.reward_time = message.reward_time;
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (typeof message.jackpot === "number")
          object.jackpot = options.longs === String ? String(message.jackpot) : message.jackpot;
        else
          object.jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.jackpot)
              : options.longs === Number
                ? new $util.LongBits(message.jackpot.low >>> 0, message.jackpot.high >>> 0).toNumber()
                : message.jackpot;
      if (message.reward != null && message.hasOwnProperty("reward")) object.reward = message.reward;
      if (message.rank != null && message.hasOwnProperty("rank")) object.rank = message.rank;
      if (message.money != null && message.hasOwnProperty("money"))
        if (typeof message.money === "number")
          object.money = options.longs === String ? String(message.money) : message.money;
        else
          object.money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.money)
              : options.longs === Number
                ? new $util.LongBits(message.money.low >>> 0, message.money.high >>> 0).toNumber()
                : message.money;
      if (message.state != null && message.hasOwnProperty("state")) object.state = message.state;
      if (message.reward_money != null && message.hasOwnProperty("reward_money"))
        if (typeof message.reward_money === "number")
          object.reward_money = options.longs === String ? String(message.reward_money) : message.reward_money;
        else
          object.reward_money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_money)
              : options.longs === Number
                ? new $util.LongBits(message.reward_money.low >>> 0, message.reward_money.high >>> 0).toNumber()
                : message.reward_money;
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (typeof message.reward_id === "number")
          object.reward_id = options.longs === String ? String(message.reward_id) : message.reward_id;
        else
          object.reward_id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_id)
              : options.longs === Number
                ? new $util.LongBits(message.reward_id.low >>> 0, message.reward_id.high >>> 0).toNumber()
                : message.reward_id;
      if (message.reward_money_type != null && message.hasOwnProperty("reward_money_type"))
        object.reward_money_type = message.reward_money_type;
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        object.rank_start_time = message.rank_start_time;
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        object.rank_end_time = message.rank_end_time;
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      return object;
    };

    /**
     * Converts this RewardData to JSON.
     * @function toJSON
     * @memberof client_proto.RewardData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RewardData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RewardData
     * @function getTypeUrl
     * @memberof client_proto.RewardData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RewardData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RewardData";
    };

    return RewardData;
  })();

  client_proto.ResponseRewardListResp = (function () {
    /**
     * Properties of a ResponseRewardListResp.
     * @memberof client_proto
     * @interface IResponseRewardListResp
     * @property {Array.<client_proto.IRewardData>|null} [reward_list] ResponseRewardListResp reward_list
     * @property {number|null} [uid] ResponseRewardListResp uid
     */

    /**
     * Constructs a new ResponseRewardListResp.
     * @memberof client_proto
     * @classdesc Represents a ResponseRewardListResp.
     * @implements IResponseRewardListResp
     * @constructor
     * @param {client_proto.IResponseRewardListResp=} [properties] Properties to set
     */
    function ResponseRewardListResp(properties) {
      this.reward_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseRewardListResp reward_list.
     * @member {Array.<client_proto.IRewardData>} reward_list
     * @memberof client_proto.ResponseRewardListResp
     * @instance
     */
    ResponseRewardListResp.prototype.reward_list = $util.emptyArray;

    /**
     * ResponseRewardListResp uid.
     * @member {number} uid
     * @memberof client_proto.ResponseRewardListResp
     * @instance
     */
    ResponseRewardListResp.prototype.uid = 0;

    /**
     * Creates a new ResponseRewardListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ResponseRewardListResp
     * @static
     * @param {client_proto.IResponseRewardListResp=} [properties] Properties to set
     * @returns {client_proto.ResponseRewardListResp} ResponseRewardListResp instance
     */
    ResponseRewardListResp.create = function create(properties) {
      return new ResponseRewardListResp(properties);
    };

    /**
     * Encodes the specified ResponseRewardListResp message. Does not implicitly {@link client_proto.ResponseRewardListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ResponseRewardListResp
     * @static
     * @param {client_proto.IResponseRewardListResp} message ResponseRewardListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseRewardListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.reward_list != null && message.reward_list.length)
        for (var i = 0; i < message.reward_list.length; ++i)
          $root.client_proto.RewardData.encode(
            message.reward_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified ResponseRewardListResp message, length delimited. Does not implicitly {@link client_proto.ResponseRewardListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ResponseRewardListResp
     * @static
     * @param {client_proto.IResponseRewardListResp} message ResponseRewardListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseRewardListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ResponseRewardListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ResponseRewardListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ResponseRewardListResp} ResponseRewardListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseRewardListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ResponseRewardListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.reward_list && message.reward_list.length)) message.reward_list = [];
            message.reward_list.push($root.client_proto.RewardData.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ResponseRewardListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ResponseRewardListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ResponseRewardListResp} ResponseRewardListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseRewardListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ResponseRewardListResp message.
     * @function verify
     * @memberof client_proto.ResponseRewardListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ResponseRewardListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.reward_list != null && message.hasOwnProperty("reward_list")) {
        if (!Array.isArray(message.reward_list)) return "reward_list: array expected";
        for (var i = 0; i < message.reward_list.length; ++i) {
          var error = $root.client_proto.RewardData.verify(message.reward_list[i]);
          if (error) return "reward_list." + error;
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates a ResponseRewardListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ResponseRewardListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ResponseRewardListResp} ResponseRewardListResp
     */
    ResponseRewardListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ResponseRewardListResp) return object;
      var message = new $root.client_proto.ResponseRewardListResp();
      if (object.reward_list) {
        if (!Array.isArray(object.reward_list))
          throw TypeError(".client_proto.ResponseRewardListResp.reward_list: array expected");
        message.reward_list = [];
        for (var i = 0; i < object.reward_list.length; ++i) {
          if (typeof object.reward_list[i] !== "object")
            throw TypeError(".client_proto.ResponseRewardListResp.reward_list: object expected");
          message.reward_list[i] = $root.client_proto.RewardData.fromObject(object.reward_list[i]);
        }
      }
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a ResponseRewardListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ResponseRewardListResp
     * @static
     * @param {client_proto.ResponseRewardListResp} message ResponseRewardListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ResponseRewardListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.reward_list = [];
      if (options.defaults) object.uid = 0;
      if (message.reward_list && message.reward_list.length) {
        object.reward_list = [];
        for (var j = 0; j < message.reward_list.length; ++j)
          object.reward_list[j] = $root.client_proto.RewardData.toObject(message.reward_list[j], options);
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this ResponseRewardListResp to JSON.
     * @function toJSON
     * @memberof client_proto.ResponseRewardListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ResponseRewardListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ResponseRewardListResp
     * @function getTypeUrl
     * @memberof client_proto.ResponseRewardListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ResponseRewardListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ResponseRewardListResp";
    };

    return ResponseRewardListResp;
  })();

  client_proto.RequestGetRewardReq = (function () {
    /**
     * Properties of a RequestGetRewardReq.
     * @memberof client_proto
     * @interface IRequestGetRewardReq
     * @property {number|null} [uid] RequestGetRewardReq uid
     * @property {number|Long|null} [reward_id] RequestGetRewardReq reward_id
     */

    /**
     * Constructs a new RequestGetRewardReq.
     * @memberof client_proto
     * @classdesc Represents a RequestGetRewardReq.
     * @implements IRequestGetRewardReq
     * @constructor
     * @param {client_proto.IRequestGetRewardReq=} [properties] Properties to set
     */
    function RequestGetRewardReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetRewardReq uid.
     * @member {number} uid
     * @memberof client_proto.RequestGetRewardReq
     * @instance
     */
    RequestGetRewardReq.prototype.uid = 0;

    /**
     * RequestGetRewardReq reward_id.
     * @member {number|Long} reward_id
     * @memberof client_proto.RequestGetRewardReq
     * @instance
     */
    RequestGetRewardReq.prototype.reward_id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new RequestGetRewardReq instance using the specified properties.
     * @function create
     * @memberof client_proto.RequestGetRewardReq
     * @static
     * @param {client_proto.IRequestGetRewardReq=} [properties] Properties to set
     * @returns {client_proto.RequestGetRewardReq} RequestGetRewardReq instance
     */
    RequestGetRewardReq.create = function create(properties) {
      return new RequestGetRewardReq(properties);
    };

    /**
     * Encodes the specified RequestGetRewardReq message. Does not implicitly {@link client_proto.RequestGetRewardReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RequestGetRewardReq
     * @static
     * @param {client_proto.IRequestGetRewardReq} message RequestGetRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetRewardReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.reward_id != null && Object.hasOwnProperty.call(message, "reward_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.reward_id);
      return writer;
    };

    /**
     * Encodes the specified RequestGetRewardReq message, length delimited. Does not implicitly {@link client_proto.RequestGetRewardReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RequestGetRewardReq
     * @static
     * @param {client_proto.IRequestGetRewardReq} message RequestGetRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetRewardReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RequestGetRewardReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RequestGetRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RequestGetRewardReq} RequestGetRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetRewardReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RequestGetRewardReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.reward_id = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RequestGetRewardReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RequestGetRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RequestGetRewardReq} RequestGetRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetRewardReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RequestGetRewardReq message.
     * @function verify
     * @memberof client_proto.RequestGetRewardReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RequestGetRewardReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (
          !$util.isInteger(message.reward_id) &&
          !(message.reward_id && $util.isInteger(message.reward_id.low) && $util.isInteger(message.reward_id.high))
        )
          return "reward_id: integer|Long expected";
      return null;
    };

    /**
     * Creates a RequestGetRewardReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RequestGetRewardReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RequestGetRewardReq} RequestGetRewardReq
     */
    RequestGetRewardReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RequestGetRewardReq) return object;
      var message = new $root.client_proto.RequestGetRewardReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.reward_id != null)
        if ($util.Long) (message.reward_id = $util.Long.fromValue(object.reward_id)).unsigned = false;
        else if (typeof object.reward_id === "string") message.reward_id = parseInt(object.reward_id, 10);
        else if (typeof object.reward_id === "number") message.reward_id = object.reward_id;
        else if (typeof object.reward_id === "object")
          message.reward_id = new $util.LongBits(object.reward_id.low >>> 0, object.reward_id.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a RequestGetRewardReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RequestGetRewardReq
     * @static
     * @param {client_proto.RequestGetRewardReq} message RequestGetRewardReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RequestGetRewardReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_id =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_id = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (typeof message.reward_id === "number")
          object.reward_id = options.longs === String ? String(message.reward_id) : message.reward_id;
        else
          object.reward_id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_id)
              : options.longs === Number
                ? new $util.LongBits(message.reward_id.low >>> 0, message.reward_id.high >>> 0).toNumber()
                : message.reward_id;
      return object;
    };

    /**
     * Converts this RequestGetRewardReq to JSON.
     * @function toJSON
     * @memberof client_proto.RequestGetRewardReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RequestGetRewardReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RequestGetRewardReq
     * @function getTypeUrl
     * @memberof client_proto.RequestGetRewardReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RequestGetRewardReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RequestGetRewardReq";
    };

    return RequestGetRewardReq;
  })();

  client_proto.ResponseGetRewardResp = (function () {
    /**
     * Properties of a ResponseGetRewardResp.
     * @memberof client_proto
     * @interface IResponseGetRewardResp
     * @property {number|null} [uid] ResponseGetRewardResp uid
     * @property {number|Long|null} [reward_id] ResponseGetRewardResp reward_id
     * @property {number|null} [state] ResponseGetRewardResp state
     * @property {number|null} [error_code] ResponseGetRewardResp error_code
     */

    /**
     * Constructs a new ResponseGetRewardResp.
     * @memberof client_proto
     * @classdesc Represents a ResponseGetRewardResp.
     * @implements IResponseGetRewardResp
     * @constructor
     * @param {client_proto.IResponseGetRewardResp=} [properties] Properties to set
     */
    function ResponseGetRewardResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseGetRewardResp uid.
     * @member {number} uid
     * @memberof client_proto.ResponseGetRewardResp
     * @instance
     */
    ResponseGetRewardResp.prototype.uid = 0;

    /**
     * ResponseGetRewardResp reward_id.
     * @member {number|Long} reward_id
     * @memberof client_proto.ResponseGetRewardResp
     * @instance
     */
    ResponseGetRewardResp.prototype.reward_id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ResponseGetRewardResp state.
     * @member {number} state
     * @memberof client_proto.ResponseGetRewardResp
     * @instance
     */
    ResponseGetRewardResp.prototype.state = 0;

    /**
     * ResponseGetRewardResp error_code.
     * @member {number} error_code
     * @memberof client_proto.ResponseGetRewardResp
     * @instance
     */
    ResponseGetRewardResp.prototype.error_code = 0;

    /**
     * Creates a new ResponseGetRewardResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ResponseGetRewardResp
     * @static
     * @param {client_proto.IResponseGetRewardResp=} [properties] Properties to set
     * @returns {client_proto.ResponseGetRewardResp} ResponseGetRewardResp instance
     */
    ResponseGetRewardResp.create = function create(properties) {
      return new ResponseGetRewardResp(properties);
    };

    /**
     * Encodes the specified ResponseGetRewardResp message. Does not implicitly {@link client_proto.ResponseGetRewardResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ResponseGetRewardResp
     * @static
     * @param {client_proto.IResponseGetRewardResp} message ResponseGetRewardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseGetRewardResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.reward_id != null && Object.hasOwnProperty.call(message, "reward_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.reward_id);
      if (message.state != null && Object.hasOwnProperty.call(message, "state"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.state);
      if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.error_code);
      return writer;
    };

    /**
     * Encodes the specified ResponseGetRewardResp message, length delimited. Does not implicitly {@link client_proto.ResponseGetRewardResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ResponseGetRewardResp
     * @static
     * @param {client_proto.IResponseGetRewardResp} message ResponseGetRewardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseGetRewardResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ResponseGetRewardResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ResponseGetRewardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ResponseGetRewardResp} ResponseGetRewardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseGetRewardResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ResponseGetRewardResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.reward_id = reader.int64();
            break;
          }
          case 3: {
            message.state = reader.int32();
            break;
          }
          case 4: {
            message.error_code = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ResponseGetRewardResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ResponseGetRewardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ResponseGetRewardResp} ResponseGetRewardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseGetRewardResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ResponseGetRewardResp message.
     * @function verify
     * @memberof client_proto.ResponseGetRewardResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ResponseGetRewardResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (
          !$util.isInteger(message.reward_id) &&
          !(message.reward_id && $util.isInteger(message.reward_id.low) && $util.isInteger(message.reward_id.high))
        )
          return "reward_id: integer|Long expected";
      if (message.state != null && message.hasOwnProperty("state"))
        if (!$util.isInteger(message.state)) return "state: integer expected";
      if (message.error_code != null && message.hasOwnProperty("error_code"))
        if (!$util.isInteger(message.error_code)) return "error_code: integer expected";
      return null;
    };

    /**
     * Creates a ResponseGetRewardResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ResponseGetRewardResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ResponseGetRewardResp} ResponseGetRewardResp
     */
    ResponseGetRewardResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ResponseGetRewardResp) return object;
      var message = new $root.client_proto.ResponseGetRewardResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.reward_id != null)
        if ($util.Long) (message.reward_id = $util.Long.fromValue(object.reward_id)).unsigned = false;
        else if (typeof object.reward_id === "string") message.reward_id = parseInt(object.reward_id, 10);
        else if (typeof object.reward_id === "number") message.reward_id = object.reward_id;
        else if (typeof object.reward_id === "object")
          message.reward_id = new $util.LongBits(object.reward_id.low >>> 0, object.reward_id.high >>> 0).toNumber();
      if (object.state != null) message.state = object.state | 0;
      if (object.error_code != null) message.error_code = object.error_code | 0;
      return message;
    };

    /**
     * Creates a plain object from a ResponseGetRewardResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ResponseGetRewardResp
     * @static
     * @param {client_proto.ResponseGetRewardResp} message ResponseGetRewardResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ResponseGetRewardResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_id =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_id = options.longs === String ? "0" : 0;
        object.state = 0;
        object.error_code = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (typeof message.reward_id === "number")
          object.reward_id = options.longs === String ? String(message.reward_id) : message.reward_id;
        else
          object.reward_id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_id)
              : options.longs === Number
                ? new $util.LongBits(message.reward_id.low >>> 0, message.reward_id.high >>> 0).toNumber()
                : message.reward_id;
      if (message.state != null && message.hasOwnProperty("state")) object.state = message.state;
      if (message.error_code != null && message.hasOwnProperty("error_code")) object.error_code = message.error_code;
      return object;
    };

    /**
     * Converts this ResponseGetRewardResp to JSON.
     * @function toJSON
     * @memberof client_proto.ResponseGetRewardResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ResponseGetRewardResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ResponseGetRewardResp
     * @function getTypeUrl
     * @memberof client_proto.ResponseGetRewardResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ResponseGetRewardResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ResponseGetRewardResp";
    };

    return ResponseGetRewardResp;
  })();

  /**
   * ACTIVITY_TYPE enum.
   * @name client_proto.ACTIVITY_TYPE
   * @enum {number}
   * @property {number} TYPE_NONE=0 TYPE_NONE value
   * @property {number} GOODSGIFT=1 GOODSGIFT value
   * @property {number} TASKGIFT=2 TASKGIFT value
   * @property {number} JUMP=3 JUMP value
   * @property {number} TYPE_ACCUMULATED_CHARGE=4 TYPE_ACCUMULATED_CHARGE value
   * @property {number} TYPE_REGRESS_GIFT=5 TYPE_REGRESS_GIFT value
   * @property {number} TYPE_REGRESS_WELFARE=6 TYPE_REGRESS_WELFARE value
   * @property {number} TYPE_SURPRISE_GIFT=7 TYPE_SURPRISE_GIFT value
   */
  client_proto.ACTIVITY_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "TYPE_NONE")] = 0;
    values[(valuesById[1] = "GOODSGIFT")] = 1;
    values[(valuesById[2] = "TASKGIFT")] = 2;
    values[(valuesById[3] = "JUMP")] = 3;
    values[(valuesById[4] = "TYPE_ACCUMULATED_CHARGE")] = 4;
    values[(valuesById[5] = "TYPE_REGRESS_GIFT")] = 5;
    values[(valuesById[6] = "TYPE_REGRESS_WELFARE")] = 6;
    values[(valuesById[7] = "TYPE_SURPRISE_GIFT")] = 7;
    return values;
  })();

  /**
   * TASK_TYPE enum.
   * @name client_proto.TASK_TYPE
   * @enum {number}
   * @property {number} TASK_TYPE_NONE=0 TASK_TYPE_NONE value
   * @property {number} RECHARGE_COUNT=1 RECHARGE_COUNT value
   * @property {number} RECHARGE_TOTAL=2 RECHARGE_TOTAL value
   * @property {number} GAMES_COUNT=3 GAMES_COUNT value
   * @property {number} EFFECTIVE_FLOW=4 EFFECTIVE_FLOW value
   * @property {number} LOGIN_TOTALDAY=5 LOGIN_TOTALDAY value
   * @property {number} DAY_ONLINETIME=6 DAY_ONLINETIME value
   * @property {number} TYPE_GAMES_COUNT=7 TYPE_GAMES_COUNT value
   * @property {number} TYPE_EFFECTIVE_FLOW=8 TYPE_EFFECTIVE_FLOW value
   * @property {number} TYPE_GAME_TOTALWIN=9 TYPE_GAME_TOTALWIN value
   * @property {number} GAMEID_GAMES_COUNT=10 GAMEID_GAMES_COUNT value
   * @property {number} GAMEID_EFFECTIVE_FLOW=11 GAMEID_EFFECTIVE_FLOW value
   * @property {number} GAMEID_GAME_TOTALWIN=12 GAMEID_GAME_TOTALWIN value
   */
  client_proto.TASK_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "TASK_TYPE_NONE")] = 0;
    values[(valuesById[1] = "RECHARGE_COUNT")] = 1;
    values[(valuesById[2] = "RECHARGE_TOTAL")] = 2;
    values[(valuesById[3] = "GAMES_COUNT")] = 3;
    values[(valuesById[4] = "EFFECTIVE_FLOW")] = 4;
    values[(valuesById[5] = "LOGIN_TOTALDAY")] = 5;
    values[(valuesById[6] = "DAY_ONLINETIME")] = 6;
    values[(valuesById[7] = "TYPE_GAMES_COUNT")] = 7;
    values[(valuesById[8] = "TYPE_EFFECTIVE_FLOW")] = 8;
    values[(valuesById[9] = "TYPE_GAME_TOTALWIN")] = 9;
    values[(valuesById[10] = "GAMEID_GAMES_COUNT")] = 10;
    values[(valuesById[11] = "GAMEID_EFFECTIVE_FLOW")] = 11;
    values[(valuesById[12] = "GAMEID_GAME_TOTALWIN")] = 12;
    return values;
  })();

  /**
   * ACTIVITY_USER_STATUS enum.
   * @name client_proto.ACTIVITY_USER_STATUS
   * @enum {number}
   * @property {number} NONE=0 NONE value
   * @property {number} START=1 START value
   * @property {number} FINISH=2 FINISH value
   * @property {number} END=3 END value
   */
  client_proto.ACTIVITY_USER_STATUS = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "NONE")] = 0;
    values[(valuesById[1] = "START")] = 1;
    values[(valuesById[2] = "FINISH")] = 2;
    values[(valuesById[3] = "END")] = 3;
    return values;
  })();

  /**
   * ACTIVITY_SUB_MSG_ID enum.
   * @name client_proto.ACTIVITY_SUB_MSG_ID
   * @enum {number}
   * @property {number} ACTIVITY_MSG_ID_NULL=0 ACTIVITY_MSG_ID_NULL value
   * @property {number} ACTIVITY_MSG_ID_MIN=1800 ACTIVITY_MSG_ID_MIN value
   * @property {number} ACTIVITY_LIST_REQ=1801 ACTIVITY_LIST_REQ value
   * @property {number} ACTIVITY_LIST_PUSH=1802 ACTIVITY_LIST_PUSH value
   * @property {number} ACTIVITY_ITEM_PUSH=1803 ACTIVITY_ITEM_PUSH value
   * @property {number} ACTIVITY_GIFT_CREATE_ORDER_REQ=1804 ACTIVITY_GIFT_CREATE_ORDER_REQ value
   * @property {number} ACTIVITY_GIFT_CREATE_ORDER_RESP=1805 ACTIVITY_GIFT_CREATE_ORDER_RESP value
   * @property {number} ACTIVITY_COMPLETE_ORDER_PUSH=1806 ACTIVITY_COMPLETE_ORDER_PUSH value
   * @property {number} ACTIVITY_LOOKEVENT_DETAIL_REQ=1807 ACTIVITY_LOOKEVENT_DETAIL_REQ value
   * @property {number} ACTIVITY_LOOKEVENT_DETAIL_PUSH=1808 ACTIVITY_LOOKEVENT_DETAIL_PUSH value
   * @property {number} ACTIVITY_ACCUMULATED_CHARGE_MSG_ID_MIN=1820 ACTIVITY_ACCUMULATED_CHARGE_MSG_ID_MIN value
   * @property {number} ACTIVITY_ACCUMULATED_CHARGE_LOTTERY_REQ=1821 ACTIVITY_ACCUMULATED_CHARGE_LOTTERY_REQ value
   * @property {number} ACTIVITY_ACCUMULATED_CHARGE_REWARD_RESP=1822 ACTIVITY_ACCUMULATED_CHARGE_REWARD_RESP value
   * @property {number} ACTIVITY_ACCUMULATED_CHARGE_STATUS_PUSH=1823 ACTIVITY_ACCUMULATED_CHARGE_STATUS_PUSH value
   * @property {number} ACTIVITY_ACCUMULATED_CHARGE_ANIMATION_REQ=1824 ACTIVITY_ACCUMULATED_CHARGE_ANIMATION_REQ value
   * @property {number} ACTIVITY_ACCUMULATED_CHARGE_GET_MESSAGE_REQ=1825 ACTIVITY_ACCUMULATED_CHARGE_GET_MESSAGE_REQ value
   * @property {number} ACTIVITY_ACCUMULATED_CHARGE_GET_MESSAGE_RESP=1826 ACTIVITY_ACCUMULATED_CHARGE_GET_MESSAGE_RESP value
   * @property {number} ACTIVITY_ACCUMULATED_CHARGE_GET_HISTORY_REQ=1827 ACTIVITY_ACCUMULATED_CHARGE_GET_HISTORY_REQ value
   * @property {number} ACTIVITY_ACCUMULATED_CHARGE_GET_HISTORY_RESP=1828 ACTIVITY_ACCUMULATED_CHARGE_GET_HISTORY_RESP value
   * @property {number} ACTIVITY_ACCUMULATED_CHARGE_MSG_ID_MAX=1830 ACTIVITY_ACCUMULATED_CHARGE_MSG_ID_MAX value
   * @property {number} ACTIVITY_SRV_ACCUMULATED_CHARGE_STATISTIC=1850 ACTIVITY_SRV_ACCUMULATED_CHARGE_STATISTIC value
   * @property {number} ACTIVITY_SRV_ACCUMULATEDCHARGE_CHANGE_PUSH=1851 ACTIVITY_SRV_ACCUMULATEDCHARGE_CHANGE_PUSH value
   * @property {number} ACTIVITY_SRV_ACCUMULATEDCHARGE_SPIN_CHANGE_PUSH=1852 ACTIVITY_SRV_ACCUMULATEDCHARGE_SPIN_CHANGE_PUSH value
   * @property {number} ACTIVITY_SRV_ACCUMULATEDCHARGE_UPDATE_SQL_PUSH=1853 ACTIVITY_SRV_ACCUMULATEDCHARGE_UPDATE_SQL_PUSH value
   * @property {number} ACTIVITY_SRV_USER_REGRESSGIFT_INFO=1854 ACTIVITY_SRV_USER_REGRESSGIFT_INFO value
   * @property {number} ACTIVITY_SRV_REGRESSGIFT_STATISTIC=1855 ACTIVITY_SRV_REGRESSGIFT_STATISTIC value
   * @property {number} ACTIVITY_SRV_USER_REGRESSWELFARE_INFO=1856 ACTIVITY_SRV_USER_REGRESSWELFARE_INFO value
   * @property {number} ACTIVITY_SRV_REGRESSWELFARE_STATISTIC=1857 ACTIVITY_SRV_REGRESSWELFARE_STATISTIC value
   * @property {number} ACTIVITY_SRV_SURPRISE_USER_INFO=1858 ACTIVITY_SRV_SURPRISE_USER_INFO value
   * @property {number} ACTIVITY_SRV_SURPRISEGIFT_STATISTIC=1859 ACTIVITY_SRV_SURPRISEGIFT_STATISTIC value
   * @property {number} ACTIVITY_READ_USER_BANKRUPTCY_SITUATION=1865 ACTIVITY_READ_USER_BANKRUPTCY_SITUATION value
   * @property {number} ACTIVITY_WRITE_USER_BANKRUPTCY_SITUATION=1866 ACTIVITY_WRITE_USER_BANKRUPTCY_SITUATION value
   * @property {number} ACTIVITY_CLEAR_USER_BANKRUPTCY_SITUATION=1867 ACTIVITY_CLEAR_USER_BANKRUPTCY_SITUATION value
   * @property {number} ACTIVITY_ASSER_UPDATE_NOTICE=1868 ACTIVITY_ASSER_UPDATE_NOTICE value
   * @property {number} ACTIVITY_SRV_LABEL_CHANGED=1880 ACTIVITY_SRV_LABEL_CHANGED value
   * @property {number} ACTIVITY_SRV_WALLET_SERVER_RECHARGE_PUSH=1881 ACTIVITY_SRV_WALLET_SERVER_RECHARGE_PUSH value
   * @property {number} ACTIVITY_SRV_LIST_REQ=1882 ACTIVITY_SRV_LIST_REQ value
   * @property {number} ACTIVITY_SRV_LIST_RESP=1883 ACTIVITY_SRV_LIST_RESP value
   * @property {number} ACTIVITY_SRV_LOGIN_EVENT=1884 ACTIVITY_SRV_LOGIN_EVENT value
   * @property {number} ACTIVITY_RESULT_RESP=1890 ACTIVITY_RESULT_RESP value
   * @property {number} ACTIVITY_MSG_ID_MAX=1899 ACTIVITY_MSG_ID_MAX value
   */
  client_proto.ACTIVITY_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "ACTIVITY_MSG_ID_NULL")] = 0;
    values[(valuesById[1800] = "ACTIVITY_MSG_ID_MIN")] = 1800;
    values[(valuesById[1801] = "ACTIVITY_LIST_REQ")] = 1801;
    values[(valuesById[1802] = "ACTIVITY_LIST_PUSH")] = 1802;
    values[(valuesById[1803] = "ACTIVITY_ITEM_PUSH")] = 1803;
    values[(valuesById[1804] = "ACTIVITY_GIFT_CREATE_ORDER_REQ")] = 1804;
    values[(valuesById[1805] = "ACTIVITY_GIFT_CREATE_ORDER_RESP")] = 1805;
    values[(valuesById[1806] = "ACTIVITY_COMPLETE_ORDER_PUSH")] = 1806;
    values[(valuesById[1807] = "ACTIVITY_LOOKEVENT_DETAIL_REQ")] = 1807;
    values[(valuesById[1808] = "ACTIVITY_LOOKEVENT_DETAIL_PUSH")] = 1808;
    values[(valuesById[1820] = "ACTIVITY_ACCUMULATED_CHARGE_MSG_ID_MIN")] = 1820;
    values[(valuesById[1821] = "ACTIVITY_ACCUMULATED_CHARGE_LOTTERY_REQ")] = 1821;
    values[(valuesById[1822] = "ACTIVITY_ACCUMULATED_CHARGE_REWARD_RESP")] = 1822;
    values[(valuesById[1823] = "ACTIVITY_ACCUMULATED_CHARGE_STATUS_PUSH")] = 1823;
    values[(valuesById[1824] = "ACTIVITY_ACCUMULATED_CHARGE_ANIMATION_REQ")] = 1824;
    values[(valuesById[1825] = "ACTIVITY_ACCUMULATED_CHARGE_GET_MESSAGE_REQ")] = 1825;
    values[(valuesById[1826] = "ACTIVITY_ACCUMULATED_CHARGE_GET_MESSAGE_RESP")] = 1826;
    values[(valuesById[1827] = "ACTIVITY_ACCUMULATED_CHARGE_GET_HISTORY_REQ")] = 1827;
    values[(valuesById[1828] = "ACTIVITY_ACCUMULATED_CHARGE_GET_HISTORY_RESP")] = 1828;
    values[(valuesById[1830] = "ACTIVITY_ACCUMULATED_CHARGE_MSG_ID_MAX")] = 1830;
    values[(valuesById[1850] = "ACTIVITY_SRV_ACCUMULATED_CHARGE_STATISTIC")] = 1850;
    values[(valuesById[1851] = "ACTIVITY_SRV_ACCUMULATEDCHARGE_CHANGE_PUSH")] = 1851;
    values[(valuesById[1852] = "ACTIVITY_SRV_ACCUMULATEDCHARGE_SPIN_CHANGE_PUSH")] = 1852;
    values[(valuesById[1853] = "ACTIVITY_SRV_ACCUMULATEDCHARGE_UPDATE_SQL_PUSH")] = 1853;
    values[(valuesById[1854] = "ACTIVITY_SRV_USER_REGRESSGIFT_INFO")] = 1854;
    values[(valuesById[1855] = "ACTIVITY_SRV_REGRESSGIFT_STATISTIC")] = 1855;
    values[(valuesById[1856] = "ACTIVITY_SRV_USER_REGRESSWELFARE_INFO")] = 1856;
    values[(valuesById[1857] = "ACTIVITY_SRV_REGRESSWELFARE_STATISTIC")] = 1857;
    values[(valuesById[1858] = "ACTIVITY_SRV_SURPRISE_USER_INFO")] = 1858;
    values[(valuesById[1859] = "ACTIVITY_SRV_SURPRISEGIFT_STATISTIC")] = 1859;
    values[(valuesById[1865] = "ACTIVITY_READ_USER_BANKRUPTCY_SITUATION")] = 1865;
    values[(valuesById[1866] = "ACTIVITY_WRITE_USER_BANKRUPTCY_SITUATION")] = 1866;
    values[(valuesById[1867] = "ACTIVITY_CLEAR_USER_BANKRUPTCY_SITUATION")] = 1867;
    values[(valuesById[1868] = "ACTIVITY_ASSER_UPDATE_NOTICE")] = 1868;
    values[(valuesById[1880] = "ACTIVITY_SRV_LABEL_CHANGED")] = 1880;
    values[(valuesById[1881] = "ACTIVITY_SRV_WALLET_SERVER_RECHARGE_PUSH")] = 1881;
    values[(valuesById[1882] = "ACTIVITY_SRV_LIST_REQ")] = 1882;
    values[(valuesById[1883] = "ACTIVITY_SRV_LIST_RESP")] = 1883;
    values[(valuesById[1884] = "ACTIVITY_SRV_LOGIN_EVENT")] = 1884;
    values[(valuesById[1890] = "ACTIVITY_RESULT_RESP")] = 1890;
    values[(valuesById[1899] = "ACTIVITY_MSG_ID_MAX")] = 1899;
    return values;
  })();

  client_proto.ActivityListReq = (function () {
    /**
     * Properties of an ActivityListReq.
     * @memberof client_proto
     * @interface IActivityListReq
     * @property {number|null} [uid] ActivityListReq uid
     * @property {number|null} [id] ActivityListReq id
     */

    /**
     * Constructs a new ActivityListReq.
     * @memberof client_proto
     * @classdesc Represents an ActivityListReq.
     * @implements IActivityListReq
     * @constructor
     * @param {client_proto.IActivityListReq=} [properties] Properties to set
     */
    function ActivityListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ActivityListReq uid.
     * @member {number} uid
     * @memberof client_proto.ActivityListReq
     * @instance
     */
    ActivityListReq.prototype.uid = 0;

    /**
     * ActivityListReq id.
     * @member {number} id
     * @memberof client_proto.ActivityListReq
     * @instance
     */
    ActivityListReq.prototype.id = 0;

    /**
     * Creates a new ActivityListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.ActivityListReq
     * @static
     * @param {client_proto.IActivityListReq=} [properties] Properties to set
     * @returns {client_proto.ActivityListReq} ActivityListReq instance
     */
    ActivityListReq.create = function create(properties) {
      return new ActivityListReq(properties);
    };

    /**
     * Encodes the specified ActivityListReq message. Does not implicitly {@link client_proto.ActivityListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ActivityListReq
     * @static
     * @param {client_proto.IActivityListReq} message ActivityListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified ActivityListReq message, length delimited. Does not implicitly {@link client_proto.ActivityListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ActivityListReq
     * @static
     * @param {client_proto.IActivityListReq} message ActivityListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ActivityListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ActivityListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ActivityListReq} ActivityListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ActivityListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an ActivityListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ActivityListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ActivityListReq} ActivityListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ActivityListReq message.
     * @function verify
     * @memberof client_proto.ActivityListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ActivityListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates an ActivityListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ActivityListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ActivityListReq} ActivityListReq
     */
    ActivityListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ActivityListReq) return object;
      var message = new $root.client_proto.ActivityListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from an ActivityListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ActivityListReq
     * @static
     * @param {client_proto.ActivityListReq} message ActivityListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ActivityListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this ActivityListReq to JSON.
     * @function toJSON
     * @memberof client_proto.ActivityListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ActivityListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ActivityListReq
     * @function getTypeUrl
     * @memberof client_proto.ActivityListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ActivityListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ActivityListReq";
    };

    return ActivityListReq;
  })();

  client_proto.ActivityItem = (function () {
    /**
     * Properties of an ActivityItem.
     * @memberof client_proto
     * @interface IActivityItem
     * @property {number|null} [id] ActivityItem id
     * @property {number|null} [activity_type] ActivityItem activity_type
     * @property {number|null} [showtype] ActivityItem showtype
     * @property {number|null} [start_time] ActivityItem start_time
     * @property {number|null} [end_time] ActivityItem end_time
     * @property {number|null} [showflag] ActivityItem showflag
     * @property {string|null} [basejson] ActivityItem basejson
     * @property {string|null} [typejson] ActivityItem typejson
     * @property {Array.<client_proto.IGoodsGiftItem>|null} [giftuserdata] ActivityItem giftuserdata
     * @property {Array.<client_proto.ITurntableItem>|null} [turntabledata] ActivityItem turntabledata
     * @property {Array.<client_proto.IRegressGiftItem>|null} [regressgiftuserdata] ActivityItem regressgiftuserdata
     * @property {Array.<client_proto.ISurpriseGiftItem>|null} [surprisegiftdata] ActivityItem surprisegiftdata
     */

    /**
     * Constructs a new ActivityItem.
     * @memberof client_proto
     * @classdesc Represents an ActivityItem.
     * @implements IActivityItem
     * @constructor
     * @param {client_proto.IActivityItem=} [properties] Properties to set
     */
    function ActivityItem(properties) {
      this.giftuserdata = [];
      this.turntabledata = [];
      this.regressgiftuserdata = [];
      this.surprisegiftdata = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ActivityItem id.
     * @member {number} id
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.id = 0;

    /**
     * ActivityItem activity_type.
     * @member {number} activity_type
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.activity_type = 0;

    /**
     * ActivityItem showtype.
     * @member {number} showtype
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.showtype = 0;

    /**
     * ActivityItem start_time.
     * @member {number} start_time
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.start_time = 0;

    /**
     * ActivityItem end_time.
     * @member {number} end_time
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.end_time = 0;

    /**
     * ActivityItem showflag.
     * @member {number} showflag
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.showflag = 0;

    /**
     * ActivityItem basejson.
     * @member {string} basejson
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.basejson = "";

    /**
     * ActivityItem typejson.
     * @member {string} typejson
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.typejson = "";

    /**
     * ActivityItem giftuserdata.
     * @member {Array.<client_proto.IGoodsGiftItem>} giftuserdata
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.giftuserdata = $util.emptyArray;

    /**
     * ActivityItem turntabledata.
     * @member {Array.<client_proto.ITurntableItem>} turntabledata
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.turntabledata = $util.emptyArray;

    /**
     * ActivityItem regressgiftuserdata.
     * @member {Array.<client_proto.IRegressGiftItem>} regressgiftuserdata
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.regressgiftuserdata = $util.emptyArray;

    /**
     * ActivityItem surprisegiftdata.
     * @member {Array.<client_proto.ISurpriseGiftItem>} surprisegiftdata
     * @memberof client_proto.ActivityItem
     * @instance
     */
    ActivityItem.prototype.surprisegiftdata = $util.emptyArray;

    /**
     * Creates a new ActivityItem instance using the specified properties.
     * @function create
     * @memberof client_proto.ActivityItem
     * @static
     * @param {client_proto.IActivityItem=} [properties] Properties to set
     * @returns {client_proto.ActivityItem} ActivityItem instance
     */
    ActivityItem.create = function create(properties) {
      return new ActivityItem(properties);
    };

    /**
     * Encodes the specified ActivityItem message. Does not implicitly {@link client_proto.ActivityItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ActivityItem
     * @static
     * @param {client_proto.IActivityItem} message ActivityItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.activity_type != null && Object.hasOwnProperty.call(message, "activity_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.activity_type);
      if (message.showtype != null && Object.hasOwnProperty.call(message, "showtype"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.showtype);
      if (message.start_time != null && Object.hasOwnProperty.call(message, "start_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.start_time);
      if (message.end_time != null && Object.hasOwnProperty.call(message, "end_time"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.end_time);
      if (message.showflag != null && Object.hasOwnProperty.call(message, "showflag"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.showflag);
      if (message.basejson != null && Object.hasOwnProperty.call(message, "basejson"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.basejson);
      if (message.typejson != null && Object.hasOwnProperty.call(message, "typejson"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.typejson);
      if (message.giftuserdata != null && message.giftuserdata.length)
        for (var i = 0; i < message.giftuserdata.length; ++i)
          $root.client_proto.GoodsGiftItem.encode(
            message.giftuserdata[i],
            writer.uint32(/* id 9, wireType 2 =*/ 74).fork(),
          ).ldelim();
      if (message.turntabledata != null && message.turntabledata.length)
        for (var i = 0; i < message.turntabledata.length; ++i)
          $root.client_proto.TurntableItem.encode(
            message.turntabledata[i],
            writer.uint32(/* id 10, wireType 2 =*/ 82).fork(),
          ).ldelim();
      if (message.regressgiftuserdata != null && message.regressgiftuserdata.length)
        for (var i = 0; i < message.regressgiftuserdata.length; ++i)
          $root.client_proto.RegressGiftItem.encode(
            message.regressgiftuserdata[i],
            writer.uint32(/* id 11, wireType 2 =*/ 90).fork(),
          ).ldelim();
      if (message.surprisegiftdata != null && message.surprisegiftdata.length)
        for (var i = 0; i < message.surprisegiftdata.length; ++i)
          $root.client_proto.SurpriseGiftItem.encode(
            message.surprisegiftdata[i],
            writer.uint32(/* id 12, wireType 2 =*/ 98).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified ActivityItem message, length delimited. Does not implicitly {@link client_proto.ActivityItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ActivityItem
     * @static
     * @param {client_proto.IActivityItem} message ActivityItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ActivityItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ActivityItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ActivityItem} ActivityItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ActivityItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.activity_type = reader.int32();
            break;
          }
          case 3: {
            message.showtype = reader.int32();
            break;
          }
          case 4: {
            message.start_time = reader.int32();
            break;
          }
          case 5: {
            message.end_time = reader.int32();
            break;
          }
          case 6: {
            message.showflag = reader.int32();
            break;
          }
          case 7: {
            message.basejson = reader.string();
            break;
          }
          case 8: {
            message.typejson = reader.string();
            break;
          }
          case 9: {
            if (!(message.giftuserdata && message.giftuserdata.length)) message.giftuserdata = [];
            message.giftuserdata.push($root.client_proto.GoodsGiftItem.decode(reader, reader.uint32()));
            break;
          }
          case 10: {
            if (!(message.turntabledata && message.turntabledata.length)) message.turntabledata = [];
            message.turntabledata.push($root.client_proto.TurntableItem.decode(reader, reader.uint32()));
            break;
          }
          case 11: {
            if (!(message.regressgiftuserdata && message.regressgiftuserdata.length)) message.regressgiftuserdata = [];
            message.regressgiftuserdata.push($root.client_proto.RegressGiftItem.decode(reader, reader.uint32()));
            break;
          }
          case 12: {
            if (!(message.surprisegiftdata && message.surprisegiftdata.length)) message.surprisegiftdata = [];
            message.surprisegiftdata.push($root.client_proto.SurpriseGiftItem.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an ActivityItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ActivityItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ActivityItem} ActivityItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ActivityItem message.
     * @function verify
     * @memberof client_proto.ActivityItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ActivityItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.activity_type != null && message.hasOwnProperty("activity_type"))
        if (!$util.isInteger(message.activity_type)) return "activity_type: integer expected";
      if (message.showtype != null && message.hasOwnProperty("showtype"))
        if (!$util.isInteger(message.showtype)) return "showtype: integer expected";
      if (message.start_time != null && message.hasOwnProperty("start_time"))
        if (!$util.isInteger(message.start_time)) return "start_time: integer expected";
      if (message.end_time != null && message.hasOwnProperty("end_time"))
        if (!$util.isInteger(message.end_time)) return "end_time: integer expected";
      if (message.showflag != null && message.hasOwnProperty("showflag"))
        if (!$util.isInteger(message.showflag)) return "showflag: integer expected";
      if (message.basejson != null && message.hasOwnProperty("basejson"))
        if (!$util.isString(message.basejson)) return "basejson: string expected";
      if (message.typejson != null && message.hasOwnProperty("typejson"))
        if (!$util.isString(message.typejson)) return "typejson: string expected";
      if (message.giftuserdata != null && message.hasOwnProperty("giftuserdata")) {
        if (!Array.isArray(message.giftuserdata)) return "giftuserdata: array expected";
        for (var i = 0; i < message.giftuserdata.length; ++i) {
          var error = $root.client_proto.GoodsGiftItem.verify(message.giftuserdata[i]);
          if (error) return "giftuserdata." + error;
        }
      }
      if (message.turntabledata != null && message.hasOwnProperty("turntabledata")) {
        if (!Array.isArray(message.turntabledata)) return "turntabledata: array expected";
        for (var i = 0; i < message.turntabledata.length; ++i) {
          var error = $root.client_proto.TurntableItem.verify(message.turntabledata[i]);
          if (error) return "turntabledata." + error;
        }
      }
      if (message.regressgiftuserdata != null && message.hasOwnProperty("regressgiftuserdata")) {
        if (!Array.isArray(message.regressgiftuserdata)) return "regressgiftuserdata: array expected";
        for (var i = 0; i < message.regressgiftuserdata.length; ++i) {
          var error = $root.client_proto.RegressGiftItem.verify(message.regressgiftuserdata[i]);
          if (error) return "regressgiftuserdata." + error;
        }
      }
      if (message.surprisegiftdata != null && message.hasOwnProperty("surprisegiftdata")) {
        if (!Array.isArray(message.surprisegiftdata)) return "surprisegiftdata: array expected";
        for (var i = 0; i < message.surprisegiftdata.length; ++i) {
          var error = $root.client_proto.SurpriseGiftItem.verify(message.surprisegiftdata[i]);
          if (error) return "surprisegiftdata." + error;
        }
      }
      return null;
    };

    /**
     * Creates an ActivityItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ActivityItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ActivityItem} ActivityItem
     */
    ActivityItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ActivityItem) return object;
      var message = new $root.client_proto.ActivityItem();
      if (object.id != null) message.id = object.id | 0;
      if (object.activity_type != null) message.activity_type = object.activity_type | 0;
      if (object.showtype != null) message.showtype = object.showtype | 0;
      if (object.start_time != null) message.start_time = object.start_time | 0;
      if (object.end_time != null) message.end_time = object.end_time | 0;
      if (object.showflag != null) message.showflag = object.showflag | 0;
      if (object.basejson != null) message.basejson = String(object.basejson);
      if (object.typejson != null) message.typejson = String(object.typejson);
      if (object.giftuserdata) {
        if (!Array.isArray(object.giftuserdata))
          throw TypeError(".client_proto.ActivityItem.giftuserdata: array expected");
        message.giftuserdata = [];
        for (var i = 0; i < object.giftuserdata.length; ++i) {
          if (typeof object.giftuserdata[i] !== "object")
            throw TypeError(".client_proto.ActivityItem.giftuserdata: object expected");
          message.giftuserdata[i] = $root.client_proto.GoodsGiftItem.fromObject(object.giftuserdata[i]);
        }
      }
      if (object.turntabledata) {
        if (!Array.isArray(object.turntabledata))
          throw TypeError(".client_proto.ActivityItem.turntabledata: array expected");
        message.turntabledata = [];
        for (var i = 0; i < object.turntabledata.length; ++i) {
          if (typeof object.turntabledata[i] !== "object")
            throw TypeError(".client_proto.ActivityItem.turntabledata: object expected");
          message.turntabledata[i] = $root.client_proto.TurntableItem.fromObject(object.turntabledata[i]);
        }
      }
      if (object.regressgiftuserdata) {
        if (!Array.isArray(object.regressgiftuserdata))
          throw TypeError(".client_proto.ActivityItem.regressgiftuserdata: array expected");
        message.regressgiftuserdata = [];
        for (var i = 0; i < object.regressgiftuserdata.length; ++i) {
          if (typeof object.regressgiftuserdata[i] !== "object")
            throw TypeError(".client_proto.ActivityItem.regressgiftuserdata: object expected");
          message.regressgiftuserdata[i] = $root.client_proto.RegressGiftItem.fromObject(object.regressgiftuserdata[i]);
        }
      }
      if (object.surprisegiftdata) {
        if (!Array.isArray(object.surprisegiftdata))
          throw TypeError(".client_proto.ActivityItem.surprisegiftdata: array expected");
        message.surprisegiftdata = [];
        for (var i = 0; i < object.surprisegiftdata.length; ++i) {
          if (typeof object.surprisegiftdata[i] !== "object")
            throw TypeError(".client_proto.ActivityItem.surprisegiftdata: object expected");
          message.surprisegiftdata[i] = $root.client_proto.SurpriseGiftItem.fromObject(object.surprisegiftdata[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from an ActivityItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ActivityItem
     * @static
     * @param {client_proto.ActivityItem} message ActivityItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ActivityItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) {
        object.giftuserdata = [];
        object.turntabledata = [];
        object.regressgiftuserdata = [];
        object.surprisegiftdata = [];
      }
      if (options.defaults) {
        object.id = 0;
        object.activity_type = 0;
        object.showtype = 0;
        object.start_time = 0;
        object.end_time = 0;
        object.showflag = 0;
        object.basejson = "";
        object.typejson = "";
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.activity_type != null && message.hasOwnProperty("activity_type"))
        object.activity_type = message.activity_type;
      if (message.showtype != null && message.hasOwnProperty("showtype")) object.showtype = message.showtype;
      if (message.start_time != null && message.hasOwnProperty("start_time")) object.start_time = message.start_time;
      if (message.end_time != null && message.hasOwnProperty("end_time")) object.end_time = message.end_time;
      if (message.showflag != null && message.hasOwnProperty("showflag")) object.showflag = message.showflag;
      if (message.basejson != null && message.hasOwnProperty("basejson")) object.basejson = message.basejson;
      if (message.typejson != null && message.hasOwnProperty("typejson")) object.typejson = message.typejson;
      if (message.giftuserdata && message.giftuserdata.length) {
        object.giftuserdata = [];
        for (var j = 0; j < message.giftuserdata.length; ++j)
          object.giftuserdata[j] = $root.client_proto.GoodsGiftItem.toObject(message.giftuserdata[j], options);
      }
      if (message.turntabledata && message.turntabledata.length) {
        object.turntabledata = [];
        for (var j = 0; j < message.turntabledata.length; ++j)
          object.turntabledata[j] = $root.client_proto.TurntableItem.toObject(message.turntabledata[j], options);
      }
      if (message.regressgiftuserdata && message.regressgiftuserdata.length) {
        object.regressgiftuserdata = [];
        for (var j = 0; j < message.regressgiftuserdata.length; ++j)
          object.regressgiftuserdata[j] = $root.client_proto.RegressGiftItem.toObject(
            message.regressgiftuserdata[j],
            options,
          );
      }
      if (message.surprisegiftdata && message.surprisegiftdata.length) {
        object.surprisegiftdata = [];
        for (var j = 0; j < message.surprisegiftdata.length; ++j)
          object.surprisegiftdata[j] = $root.client_proto.SurpriseGiftItem.toObject(
            message.surprisegiftdata[j],
            options,
          );
      }
      return object;
    };

    /**
     * Converts this ActivityItem to JSON.
     * @function toJSON
     * @memberof client_proto.ActivityItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ActivityItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ActivityItem
     * @function getTypeUrl
     * @memberof client_proto.ActivityItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ActivityItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ActivityItem";
    };

    return ActivityItem;
  })();

  client_proto.ActivityItemPush = (function () {
    /**
     * Properties of an ActivityItemPush.
     * @memberof client_proto
     * @interface IActivityItemPush
     * @property {number|null} [result] ActivityItemPush result
     * @property {client_proto.IActivityItem|null} [item] ActivityItemPush item
     * @property {number|Long|null} [server_timestamp] ActivityItemPush server_timestamp
     */

    /**
     * Constructs a new ActivityItemPush.
     * @memberof client_proto
     * @classdesc Represents an ActivityItemPush.
     * @implements IActivityItemPush
     * @constructor
     * @param {client_proto.IActivityItemPush=} [properties] Properties to set
     */
    function ActivityItemPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ActivityItemPush result.
     * @member {number} result
     * @memberof client_proto.ActivityItemPush
     * @instance
     */
    ActivityItemPush.prototype.result = 0;

    /**
     * ActivityItemPush item.
     * @member {client_proto.IActivityItem|null|undefined} item
     * @memberof client_proto.ActivityItemPush
     * @instance
     */
    ActivityItemPush.prototype.item = null;

    /**
     * ActivityItemPush server_timestamp.
     * @member {number|Long} server_timestamp
     * @memberof client_proto.ActivityItemPush
     * @instance
     */
    ActivityItemPush.prototype.server_timestamp = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new ActivityItemPush instance using the specified properties.
     * @function create
     * @memberof client_proto.ActivityItemPush
     * @static
     * @param {client_proto.IActivityItemPush=} [properties] Properties to set
     * @returns {client_proto.ActivityItemPush} ActivityItemPush instance
     */
    ActivityItemPush.create = function create(properties) {
      return new ActivityItemPush(properties);
    };

    /**
     * Encodes the specified ActivityItemPush message. Does not implicitly {@link client_proto.ActivityItemPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ActivityItemPush
     * @static
     * @param {client_proto.IActivityItemPush} message ActivityItemPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityItemPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.item != null && Object.hasOwnProperty.call(message, "item"))
        $root.client_proto.ActivityItem.encode(message.item, writer.uint32(/* id 2, wireType 2 =*/ 18).fork()).ldelim();
      if (message.server_timestamp != null && Object.hasOwnProperty.call(message, "server_timestamp"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.server_timestamp);
      return writer;
    };

    /**
     * Encodes the specified ActivityItemPush message, length delimited. Does not implicitly {@link client_proto.ActivityItemPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ActivityItemPush
     * @static
     * @param {client_proto.IActivityItemPush} message ActivityItemPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityItemPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ActivityItemPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ActivityItemPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ActivityItemPush} ActivityItemPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityItemPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ActivityItemPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.item = $root.client_proto.ActivityItem.decode(reader, reader.uint32());
            break;
          }
          case 3: {
            message.server_timestamp = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an ActivityItemPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ActivityItemPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ActivityItemPush} ActivityItemPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityItemPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ActivityItemPush message.
     * @function verify
     * @memberof client_proto.ActivityItemPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ActivityItemPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.item != null && message.hasOwnProperty("item")) {
        var error = $root.client_proto.ActivityItem.verify(message.item);
        if (error) return "item." + error;
      }
      if (message.server_timestamp != null && message.hasOwnProperty("server_timestamp"))
        if (
          !$util.isInteger(message.server_timestamp) &&
          !(
            message.server_timestamp &&
            $util.isInteger(message.server_timestamp.low) &&
            $util.isInteger(message.server_timestamp.high)
          )
        )
          return "server_timestamp: integer|Long expected";
      return null;
    };

    /**
     * Creates an ActivityItemPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ActivityItemPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ActivityItemPush} ActivityItemPush
     */
    ActivityItemPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ActivityItemPush) return object;
      var message = new $root.client_proto.ActivityItemPush();
      if (object.result != null) message.result = object.result | 0;
      if (object.item != null) {
        if (typeof object.item !== "object") throw TypeError(".client_proto.ActivityItemPush.item: object expected");
        message.item = $root.client_proto.ActivityItem.fromObject(object.item);
      }
      if (object.server_timestamp != null)
        if ($util.Long) (message.server_timestamp = $util.Long.fromValue(object.server_timestamp)).unsigned = false;
        else if (typeof object.server_timestamp === "string")
          message.server_timestamp = parseInt(object.server_timestamp, 10);
        else if (typeof object.server_timestamp === "number") message.server_timestamp = object.server_timestamp;
        else if (typeof object.server_timestamp === "object")
          message.server_timestamp = new $util.LongBits(
            object.server_timestamp.low >>> 0,
            object.server_timestamp.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from an ActivityItemPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ActivityItemPush
     * @static
     * @param {client_proto.ActivityItemPush} message ActivityItemPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ActivityItemPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.item = null;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.server_timestamp =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.server_timestamp = options.longs === String ? "0" : 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.item != null && message.hasOwnProperty("item"))
        object.item = $root.client_proto.ActivityItem.toObject(message.item, options);
      if (message.server_timestamp != null && message.hasOwnProperty("server_timestamp"))
        if (typeof message.server_timestamp === "number")
          object.server_timestamp =
            options.longs === String ? String(message.server_timestamp) : message.server_timestamp;
        else
          object.server_timestamp =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.server_timestamp)
              : options.longs === Number
                ? new $util.LongBits(message.server_timestamp.low >>> 0, message.server_timestamp.high >>> 0).toNumber()
                : message.server_timestamp;
      return object;
    };

    /**
     * Converts this ActivityItemPush to JSON.
     * @function toJSON
     * @memberof client_proto.ActivityItemPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ActivityItemPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ActivityItemPush
     * @function getTypeUrl
     * @memberof client_proto.ActivityItemPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ActivityItemPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ActivityItemPush";
    };

    return ActivityItemPush;
  })();

  client_proto.GoodsGiftItem = (function () {
    /**
     * Properties of a GoodsGiftItem.
     * @memberof client_proto
     * @interface IGoodsGiftItem
     * @property {number|null} [subid] GoodsGiftItem subid
     * @property {number|null} [gifttotalnum] GoodsGiftItem gifttotalnum
     * @property {number|null} [buygiftnum] GoodsGiftItem buygiftnum
     * @property {number|null} [expendgiftnum] GoodsGiftItem expendgiftnum
     * @property {number|null} [purchasedgiftnum] GoodsGiftItem purchasedgiftnum
     * @property {number|Long|null} [price] GoodsGiftItem price
     * @property {string|null} [goodsimg] GoodsGiftItem goodsimg
     */

    /**
     * Constructs a new GoodsGiftItem.
     * @memberof client_proto
     * @classdesc Represents a GoodsGiftItem.
     * @implements IGoodsGiftItem
     * @constructor
     * @param {client_proto.IGoodsGiftItem=} [properties] Properties to set
     */
    function GoodsGiftItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GoodsGiftItem subid.
     * @member {number} subid
     * @memberof client_proto.GoodsGiftItem
     * @instance
     */
    GoodsGiftItem.prototype.subid = 0;

    /**
     * GoodsGiftItem gifttotalnum.
     * @member {number} gifttotalnum
     * @memberof client_proto.GoodsGiftItem
     * @instance
     */
    GoodsGiftItem.prototype.gifttotalnum = 0;

    /**
     * GoodsGiftItem buygiftnum.
     * @member {number} buygiftnum
     * @memberof client_proto.GoodsGiftItem
     * @instance
     */
    GoodsGiftItem.prototype.buygiftnum = 0;

    /**
     * GoodsGiftItem expendgiftnum.
     * @member {number} expendgiftnum
     * @memberof client_proto.GoodsGiftItem
     * @instance
     */
    GoodsGiftItem.prototype.expendgiftnum = 0;

    /**
     * GoodsGiftItem purchasedgiftnum.
     * @member {number} purchasedgiftnum
     * @memberof client_proto.GoodsGiftItem
     * @instance
     */
    GoodsGiftItem.prototype.purchasedgiftnum = 0;

    /**
     * GoodsGiftItem price.
     * @member {number|Long} price
     * @memberof client_proto.GoodsGiftItem
     * @instance
     */
    GoodsGiftItem.prototype.price = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GoodsGiftItem goodsimg.
     * @member {string} goodsimg
     * @memberof client_proto.GoodsGiftItem
     * @instance
     */
    GoodsGiftItem.prototype.goodsimg = "";

    /**
     * Creates a new GoodsGiftItem instance using the specified properties.
     * @function create
     * @memberof client_proto.GoodsGiftItem
     * @static
     * @param {client_proto.IGoodsGiftItem=} [properties] Properties to set
     * @returns {client_proto.GoodsGiftItem} GoodsGiftItem instance
     */
    GoodsGiftItem.create = function create(properties) {
      return new GoodsGiftItem(properties);
    };

    /**
     * Encodes the specified GoodsGiftItem message. Does not implicitly {@link client_proto.GoodsGiftItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GoodsGiftItem
     * @static
     * @param {client_proto.IGoodsGiftItem} message GoodsGiftItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GoodsGiftItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.subid);
      if (message.gifttotalnum != null && Object.hasOwnProperty.call(message, "gifttotalnum"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.gifttotalnum);
      if (message.buygiftnum != null && Object.hasOwnProperty.call(message, "buygiftnum"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.buygiftnum);
      if (message.expendgiftnum != null && Object.hasOwnProperty.call(message, "expendgiftnum"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.expendgiftnum);
      if (message.purchasedgiftnum != null && Object.hasOwnProperty.call(message, "purchasedgiftnum"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.purchasedgiftnum);
      if (message.price != null && Object.hasOwnProperty.call(message, "price"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.price);
      if (message.goodsimg != null && Object.hasOwnProperty.call(message, "goodsimg"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.goodsimg);
      return writer;
    };

    /**
     * Encodes the specified GoodsGiftItem message, length delimited. Does not implicitly {@link client_proto.GoodsGiftItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GoodsGiftItem
     * @static
     * @param {client_proto.IGoodsGiftItem} message GoodsGiftItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GoodsGiftItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GoodsGiftItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GoodsGiftItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GoodsGiftItem} GoodsGiftItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GoodsGiftItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GoodsGiftItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.subid = reader.int32();
            break;
          }
          case 2: {
            message.gifttotalnum = reader.int32();
            break;
          }
          case 3: {
            message.buygiftnum = reader.int32();
            break;
          }
          case 4: {
            message.expendgiftnum = reader.int32();
            break;
          }
          case 5: {
            message.purchasedgiftnum = reader.int32();
            break;
          }
          case 6: {
            message.price = reader.int64();
            break;
          }
          case 7: {
            message.goodsimg = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GoodsGiftItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GoodsGiftItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GoodsGiftItem} GoodsGiftItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GoodsGiftItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GoodsGiftItem message.
     * @function verify
     * @memberof client_proto.GoodsGiftItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GoodsGiftItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.gifttotalnum != null && message.hasOwnProperty("gifttotalnum"))
        if (!$util.isInteger(message.gifttotalnum)) return "gifttotalnum: integer expected";
      if (message.buygiftnum != null && message.hasOwnProperty("buygiftnum"))
        if (!$util.isInteger(message.buygiftnum)) return "buygiftnum: integer expected";
      if (message.expendgiftnum != null && message.hasOwnProperty("expendgiftnum"))
        if (!$util.isInteger(message.expendgiftnum)) return "expendgiftnum: integer expected";
      if (message.purchasedgiftnum != null && message.hasOwnProperty("purchasedgiftnum"))
        if (!$util.isInteger(message.purchasedgiftnum)) return "purchasedgiftnum: integer expected";
      if (message.price != null && message.hasOwnProperty("price"))
        if (
          !$util.isInteger(message.price) &&
          !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high))
        )
          return "price: integer|Long expected";
      if (message.goodsimg != null && message.hasOwnProperty("goodsimg"))
        if (!$util.isString(message.goodsimg)) return "goodsimg: string expected";
      return null;
    };

    /**
     * Creates a GoodsGiftItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GoodsGiftItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GoodsGiftItem} GoodsGiftItem
     */
    GoodsGiftItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GoodsGiftItem) return object;
      var message = new $root.client_proto.GoodsGiftItem();
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.gifttotalnum != null) message.gifttotalnum = object.gifttotalnum | 0;
      if (object.buygiftnum != null) message.buygiftnum = object.buygiftnum | 0;
      if (object.expendgiftnum != null) message.expendgiftnum = object.expendgiftnum | 0;
      if (object.purchasedgiftnum != null) message.purchasedgiftnum = object.purchasedgiftnum | 0;
      if (object.price != null)
        if ($util.Long) (message.price = $util.Long.fromValue(object.price)).unsigned = false;
        else if (typeof object.price === "string") message.price = parseInt(object.price, 10);
        else if (typeof object.price === "number") message.price = object.price;
        else if (typeof object.price === "object")
          message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
      if (object.goodsimg != null) message.goodsimg = String(object.goodsimg);
      return message;
    };

    /**
     * Creates a plain object from a GoodsGiftItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GoodsGiftItem
     * @static
     * @param {client_proto.GoodsGiftItem} message GoodsGiftItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GoodsGiftItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.subid = 0;
        object.gifttotalnum = 0;
        object.buygiftnum = 0;
        object.expendgiftnum = 0;
        object.purchasedgiftnum = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.price = options.longs === String ? "0" : 0;
        object.goodsimg = "";
      }
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.gifttotalnum != null && message.hasOwnProperty("gifttotalnum"))
        object.gifttotalnum = message.gifttotalnum;
      if (message.buygiftnum != null && message.hasOwnProperty("buygiftnum")) object.buygiftnum = message.buygiftnum;
      if (message.expendgiftnum != null && message.hasOwnProperty("expendgiftnum"))
        object.expendgiftnum = message.expendgiftnum;
      if (message.purchasedgiftnum != null && message.hasOwnProperty("purchasedgiftnum"))
        object.purchasedgiftnum = message.purchasedgiftnum;
      if (message.price != null && message.hasOwnProperty("price"))
        if (typeof message.price === "number")
          object.price = options.longs === String ? String(message.price) : message.price;
        else
          object.price =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.price)
              : options.longs === Number
                ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber()
                : message.price;
      if (message.goodsimg != null && message.hasOwnProperty("goodsimg")) object.goodsimg = message.goodsimg;
      return object;
    };

    /**
     * Converts this GoodsGiftItem to JSON.
     * @function toJSON
     * @memberof client_proto.GoodsGiftItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GoodsGiftItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GoodsGiftItem
     * @function getTypeUrl
     * @memberof client_proto.GoodsGiftItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GoodsGiftItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GoodsGiftItem";
    };

    return GoodsGiftItem;
  })();

  client_proto.RegressGiftItem = (function () {
    /**
     * Properties of a RegressGiftItem.
     * @memberof client_proto
     * @interface IRegressGiftItem
     * @property {number|null} [subid] RegressGiftItem subid
     * @property {number|null} [gifttotalnum] RegressGiftItem gifttotalnum
     * @property {number|null} [buygiftnum] RegressGiftItem buygiftnum
     * @property {number|null} [expendgiftnum] RegressGiftItem expendgiftnum
     * @property {number|null} [purchasedgiftnum] RegressGiftItem purchasedgiftnum
     * @property {number|Long|null} [price] RegressGiftItem price
     * @property {string|null} [goodsimg] RegressGiftItem goodsimg
     */

    /**
     * Constructs a new RegressGiftItem.
     * @memberof client_proto
     * @classdesc Represents a RegressGiftItem.
     * @implements IRegressGiftItem
     * @constructor
     * @param {client_proto.IRegressGiftItem=} [properties] Properties to set
     */
    function RegressGiftItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RegressGiftItem subid.
     * @member {number} subid
     * @memberof client_proto.RegressGiftItem
     * @instance
     */
    RegressGiftItem.prototype.subid = 0;

    /**
     * RegressGiftItem gifttotalnum.
     * @member {number} gifttotalnum
     * @memberof client_proto.RegressGiftItem
     * @instance
     */
    RegressGiftItem.prototype.gifttotalnum = 0;

    /**
     * RegressGiftItem buygiftnum.
     * @member {number} buygiftnum
     * @memberof client_proto.RegressGiftItem
     * @instance
     */
    RegressGiftItem.prototype.buygiftnum = 0;

    /**
     * RegressGiftItem expendgiftnum.
     * @member {number} expendgiftnum
     * @memberof client_proto.RegressGiftItem
     * @instance
     */
    RegressGiftItem.prototype.expendgiftnum = 0;

    /**
     * RegressGiftItem purchasedgiftnum.
     * @member {number} purchasedgiftnum
     * @memberof client_proto.RegressGiftItem
     * @instance
     */
    RegressGiftItem.prototype.purchasedgiftnum = 0;

    /**
     * RegressGiftItem price.
     * @member {number|Long} price
     * @memberof client_proto.RegressGiftItem
     * @instance
     */
    RegressGiftItem.prototype.price = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressGiftItem goodsimg.
     * @member {string} goodsimg
     * @memberof client_proto.RegressGiftItem
     * @instance
     */
    RegressGiftItem.prototype.goodsimg = "";

    /**
     * Creates a new RegressGiftItem instance using the specified properties.
     * @function create
     * @memberof client_proto.RegressGiftItem
     * @static
     * @param {client_proto.IRegressGiftItem=} [properties] Properties to set
     * @returns {client_proto.RegressGiftItem} RegressGiftItem instance
     */
    RegressGiftItem.create = function create(properties) {
      return new RegressGiftItem(properties);
    };

    /**
     * Encodes the specified RegressGiftItem message. Does not implicitly {@link client_proto.RegressGiftItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RegressGiftItem
     * @static
     * @param {client_proto.IRegressGiftItem} message RegressGiftItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RegressGiftItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.subid);
      if (message.gifttotalnum != null && Object.hasOwnProperty.call(message, "gifttotalnum"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.gifttotalnum);
      if (message.buygiftnum != null && Object.hasOwnProperty.call(message, "buygiftnum"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.buygiftnum);
      if (message.expendgiftnum != null && Object.hasOwnProperty.call(message, "expendgiftnum"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.expendgiftnum);
      if (message.purchasedgiftnum != null && Object.hasOwnProperty.call(message, "purchasedgiftnum"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.purchasedgiftnum);
      if (message.price != null && Object.hasOwnProperty.call(message, "price"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.price);
      if (message.goodsimg != null && Object.hasOwnProperty.call(message, "goodsimg"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.goodsimg);
      return writer;
    };

    /**
     * Encodes the specified RegressGiftItem message, length delimited. Does not implicitly {@link client_proto.RegressGiftItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RegressGiftItem
     * @static
     * @param {client_proto.IRegressGiftItem} message RegressGiftItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RegressGiftItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RegressGiftItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RegressGiftItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RegressGiftItem} RegressGiftItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RegressGiftItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RegressGiftItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.subid = reader.int32();
            break;
          }
          case 2: {
            message.gifttotalnum = reader.int32();
            break;
          }
          case 3: {
            message.buygiftnum = reader.int32();
            break;
          }
          case 4: {
            message.expendgiftnum = reader.int32();
            break;
          }
          case 5: {
            message.purchasedgiftnum = reader.int32();
            break;
          }
          case 6: {
            message.price = reader.int64();
            break;
          }
          case 7: {
            message.goodsimg = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RegressGiftItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RegressGiftItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RegressGiftItem} RegressGiftItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RegressGiftItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RegressGiftItem message.
     * @function verify
     * @memberof client_proto.RegressGiftItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RegressGiftItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.gifttotalnum != null && message.hasOwnProperty("gifttotalnum"))
        if (!$util.isInteger(message.gifttotalnum)) return "gifttotalnum: integer expected";
      if (message.buygiftnum != null && message.hasOwnProperty("buygiftnum"))
        if (!$util.isInteger(message.buygiftnum)) return "buygiftnum: integer expected";
      if (message.expendgiftnum != null && message.hasOwnProperty("expendgiftnum"))
        if (!$util.isInteger(message.expendgiftnum)) return "expendgiftnum: integer expected";
      if (message.purchasedgiftnum != null && message.hasOwnProperty("purchasedgiftnum"))
        if (!$util.isInteger(message.purchasedgiftnum)) return "purchasedgiftnum: integer expected";
      if (message.price != null && message.hasOwnProperty("price"))
        if (
          !$util.isInteger(message.price) &&
          !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high))
        )
          return "price: integer|Long expected";
      if (message.goodsimg != null && message.hasOwnProperty("goodsimg"))
        if (!$util.isString(message.goodsimg)) return "goodsimg: string expected";
      return null;
    };

    /**
     * Creates a RegressGiftItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RegressGiftItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RegressGiftItem} RegressGiftItem
     */
    RegressGiftItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RegressGiftItem) return object;
      var message = new $root.client_proto.RegressGiftItem();
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.gifttotalnum != null) message.gifttotalnum = object.gifttotalnum | 0;
      if (object.buygiftnum != null) message.buygiftnum = object.buygiftnum | 0;
      if (object.expendgiftnum != null) message.expendgiftnum = object.expendgiftnum | 0;
      if (object.purchasedgiftnum != null) message.purchasedgiftnum = object.purchasedgiftnum | 0;
      if (object.price != null)
        if ($util.Long) (message.price = $util.Long.fromValue(object.price)).unsigned = false;
        else if (typeof object.price === "string") message.price = parseInt(object.price, 10);
        else if (typeof object.price === "number") message.price = object.price;
        else if (typeof object.price === "object")
          message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
      if (object.goodsimg != null) message.goodsimg = String(object.goodsimg);
      return message;
    };

    /**
     * Creates a plain object from a RegressGiftItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RegressGiftItem
     * @static
     * @param {client_proto.RegressGiftItem} message RegressGiftItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RegressGiftItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.subid = 0;
        object.gifttotalnum = 0;
        object.buygiftnum = 0;
        object.expendgiftnum = 0;
        object.purchasedgiftnum = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.price = options.longs === String ? "0" : 0;
        object.goodsimg = "";
      }
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.gifttotalnum != null && message.hasOwnProperty("gifttotalnum"))
        object.gifttotalnum = message.gifttotalnum;
      if (message.buygiftnum != null && message.hasOwnProperty("buygiftnum")) object.buygiftnum = message.buygiftnum;
      if (message.expendgiftnum != null && message.hasOwnProperty("expendgiftnum"))
        object.expendgiftnum = message.expendgiftnum;
      if (message.purchasedgiftnum != null && message.hasOwnProperty("purchasedgiftnum"))
        object.purchasedgiftnum = message.purchasedgiftnum;
      if (message.price != null && message.hasOwnProperty("price"))
        if (typeof message.price === "number")
          object.price = options.longs === String ? String(message.price) : message.price;
        else
          object.price =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.price)
              : options.longs === Number
                ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber()
                : message.price;
      if (message.goodsimg != null && message.hasOwnProperty("goodsimg")) object.goodsimg = message.goodsimg;
      return object;
    };

    /**
     * Converts this RegressGiftItem to JSON.
     * @function toJSON
     * @memberof client_proto.RegressGiftItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RegressGiftItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RegressGiftItem
     * @function getTypeUrl
     * @memberof client_proto.RegressGiftItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RegressGiftItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RegressGiftItem";
    };

    return RegressGiftItem;
  })();

  client_proto.SurpriseGiftItem = (function () {
    /**
     * Properties of a SurpriseGiftItem.
     * @memberof client_proto
     * @interface ISurpriseGiftItem
     * @property {number|null} [subid] SurpriseGiftItem subid
     * @property {number|Long|null} [price] SurpriseGiftItem price
     * @property {string|null} [goodsimg] SurpriseGiftItem goodsimg
     */

    /**
     * Constructs a new SurpriseGiftItem.
     * @memberof client_proto
     * @classdesc Represents a SurpriseGiftItem.
     * @implements ISurpriseGiftItem
     * @constructor
     * @param {client_proto.ISurpriseGiftItem=} [properties] Properties to set
     */
    function SurpriseGiftItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SurpriseGiftItem subid.
     * @member {number} subid
     * @memberof client_proto.SurpriseGiftItem
     * @instance
     */
    SurpriseGiftItem.prototype.subid = 0;

    /**
     * SurpriseGiftItem price.
     * @member {number|Long} price
     * @memberof client_proto.SurpriseGiftItem
     * @instance
     */
    SurpriseGiftItem.prototype.price = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SurpriseGiftItem goodsimg.
     * @member {string} goodsimg
     * @memberof client_proto.SurpriseGiftItem
     * @instance
     */
    SurpriseGiftItem.prototype.goodsimg = "";

    /**
     * Creates a new SurpriseGiftItem instance using the specified properties.
     * @function create
     * @memberof client_proto.SurpriseGiftItem
     * @static
     * @param {client_proto.ISurpriseGiftItem=} [properties] Properties to set
     * @returns {client_proto.SurpriseGiftItem} SurpriseGiftItem instance
     */
    SurpriseGiftItem.create = function create(properties) {
      return new SurpriseGiftItem(properties);
    };

    /**
     * Encodes the specified SurpriseGiftItem message. Does not implicitly {@link client_proto.SurpriseGiftItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SurpriseGiftItem
     * @static
     * @param {client_proto.ISurpriseGiftItem} message SurpriseGiftItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SurpriseGiftItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.subid);
      if (message.price != null && Object.hasOwnProperty.call(message, "price"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.price);
      if (message.goodsimg != null && Object.hasOwnProperty.call(message, "goodsimg"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.goodsimg);
      return writer;
    };

    /**
     * Encodes the specified SurpriseGiftItem message, length delimited. Does not implicitly {@link client_proto.SurpriseGiftItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SurpriseGiftItem
     * @static
     * @param {client_proto.ISurpriseGiftItem} message SurpriseGiftItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SurpriseGiftItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SurpriseGiftItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SurpriseGiftItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SurpriseGiftItem} SurpriseGiftItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SurpriseGiftItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SurpriseGiftItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.subid = reader.int32();
            break;
          }
          case 2: {
            message.price = reader.int64();
            break;
          }
          case 3: {
            message.goodsimg = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SurpriseGiftItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SurpriseGiftItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SurpriseGiftItem} SurpriseGiftItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SurpriseGiftItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SurpriseGiftItem message.
     * @function verify
     * @memberof client_proto.SurpriseGiftItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SurpriseGiftItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.price != null && message.hasOwnProperty("price"))
        if (
          !$util.isInteger(message.price) &&
          !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high))
        )
          return "price: integer|Long expected";
      if (message.goodsimg != null && message.hasOwnProperty("goodsimg"))
        if (!$util.isString(message.goodsimg)) return "goodsimg: string expected";
      return null;
    };

    /**
     * Creates a SurpriseGiftItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SurpriseGiftItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SurpriseGiftItem} SurpriseGiftItem
     */
    SurpriseGiftItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SurpriseGiftItem) return object;
      var message = new $root.client_proto.SurpriseGiftItem();
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.price != null)
        if ($util.Long) (message.price = $util.Long.fromValue(object.price)).unsigned = false;
        else if (typeof object.price === "string") message.price = parseInt(object.price, 10);
        else if (typeof object.price === "number") message.price = object.price;
        else if (typeof object.price === "object")
          message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
      if (object.goodsimg != null) message.goodsimg = String(object.goodsimg);
      return message;
    };

    /**
     * Creates a plain object from a SurpriseGiftItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SurpriseGiftItem
     * @static
     * @param {client_proto.SurpriseGiftItem} message SurpriseGiftItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SurpriseGiftItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.subid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.price = options.longs === String ? "0" : 0;
        object.goodsimg = "";
      }
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.price != null && message.hasOwnProperty("price"))
        if (typeof message.price === "number")
          object.price = options.longs === String ? String(message.price) : message.price;
        else
          object.price =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.price)
              : options.longs === Number
                ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber()
                : message.price;
      if (message.goodsimg != null && message.hasOwnProperty("goodsimg")) object.goodsimg = message.goodsimg;
      return object;
    };

    /**
     * Converts this SurpriseGiftItem to JSON.
     * @function toJSON
     * @memberof client_proto.SurpriseGiftItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SurpriseGiftItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SurpriseGiftItem
     * @function getTypeUrl
     * @memberof client_proto.SurpriseGiftItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SurpriseGiftItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SurpriseGiftItem";
    };

    return SurpriseGiftItem;
  })();

  client_proto.TaskItem = (function () {
    /**
     * Properties of a TaskItem.
     * @memberof client_proto
     * @interface ITaskItem
     * @property {number|null} [subid] TaskItem subid
     * @property {number|null} [rechargecount] TaskItem rechargecount
     * @property {number|Long|null} [total_recharge] TaskItem total_recharge
     * @property {number|null} [gamecount] TaskItem gamecount
     */

    /**
     * Constructs a new TaskItem.
     * @memberof client_proto
     * @classdesc Represents a TaskItem.
     * @implements ITaskItem
     * @constructor
     * @param {client_proto.ITaskItem=} [properties] Properties to set
     */
    function TaskItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * TaskItem subid.
     * @member {number} subid
     * @memberof client_proto.TaskItem
     * @instance
     */
    TaskItem.prototype.subid = 0;

    /**
     * TaskItem rechargecount.
     * @member {number} rechargecount
     * @memberof client_proto.TaskItem
     * @instance
     */
    TaskItem.prototype.rechargecount = 0;

    /**
     * TaskItem total_recharge.
     * @member {number|Long} total_recharge
     * @memberof client_proto.TaskItem
     * @instance
     */
    TaskItem.prototype.total_recharge = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * TaskItem gamecount.
     * @member {number} gamecount
     * @memberof client_proto.TaskItem
     * @instance
     */
    TaskItem.prototype.gamecount = 0;

    /**
     * Creates a new TaskItem instance using the specified properties.
     * @function create
     * @memberof client_proto.TaskItem
     * @static
     * @param {client_proto.ITaskItem=} [properties] Properties to set
     * @returns {client_proto.TaskItem} TaskItem instance
     */
    TaskItem.create = function create(properties) {
      return new TaskItem(properties);
    };

    /**
     * Encodes the specified TaskItem message. Does not implicitly {@link client_proto.TaskItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.TaskItem
     * @static
     * @param {client_proto.ITaskItem} message TaskItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TaskItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.subid);
      if (message.rechargecount != null && Object.hasOwnProperty.call(message, "rechargecount"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.rechargecount);
      if (message.total_recharge != null && Object.hasOwnProperty.call(message, "total_recharge"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.total_recharge);
      if (message.gamecount != null && Object.hasOwnProperty.call(message, "gamecount"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.gamecount);
      return writer;
    };

    /**
     * Encodes the specified TaskItem message, length delimited. Does not implicitly {@link client_proto.TaskItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.TaskItem
     * @static
     * @param {client_proto.ITaskItem} message TaskItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TaskItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TaskItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.TaskItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.TaskItem} TaskItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TaskItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.TaskItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.subid = reader.int32();
            break;
          }
          case 2: {
            message.rechargecount = reader.int32();
            break;
          }
          case 3: {
            message.total_recharge = reader.int64();
            break;
          }
          case 4: {
            message.gamecount = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a TaskItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.TaskItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.TaskItem} TaskItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TaskItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TaskItem message.
     * @function verify
     * @memberof client_proto.TaskItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TaskItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.rechargecount != null && message.hasOwnProperty("rechargecount"))
        if (!$util.isInteger(message.rechargecount)) return "rechargecount: integer expected";
      if (message.total_recharge != null && message.hasOwnProperty("total_recharge"))
        if (
          !$util.isInteger(message.total_recharge) &&
          !(
            message.total_recharge &&
            $util.isInteger(message.total_recharge.low) &&
            $util.isInteger(message.total_recharge.high)
          )
        )
          return "total_recharge: integer|Long expected";
      if (message.gamecount != null && message.hasOwnProperty("gamecount"))
        if (!$util.isInteger(message.gamecount)) return "gamecount: integer expected";
      return null;
    };

    /**
     * Creates a TaskItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.TaskItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.TaskItem} TaskItem
     */
    TaskItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.TaskItem) return object;
      var message = new $root.client_proto.TaskItem();
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.rechargecount != null) message.rechargecount = object.rechargecount | 0;
      if (object.total_recharge != null)
        if ($util.Long) (message.total_recharge = $util.Long.fromValue(object.total_recharge)).unsigned = false;
        else if (typeof object.total_recharge === "string")
          message.total_recharge = parseInt(object.total_recharge, 10);
        else if (typeof object.total_recharge === "number") message.total_recharge = object.total_recharge;
        else if (typeof object.total_recharge === "object")
          message.total_recharge = new $util.LongBits(
            object.total_recharge.low >>> 0,
            object.total_recharge.high >>> 0,
          ).toNumber();
      if (object.gamecount != null) message.gamecount = object.gamecount | 0;
      return message;
    };

    /**
     * Creates a plain object from a TaskItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.TaskItem
     * @static
     * @param {client_proto.TaskItem} message TaskItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TaskItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.subid = 0;
        object.rechargecount = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_recharge =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_recharge = options.longs === String ? "0" : 0;
        object.gamecount = 0;
      }
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.rechargecount != null && message.hasOwnProperty("rechargecount"))
        object.rechargecount = message.rechargecount;
      if (message.total_recharge != null && message.hasOwnProperty("total_recharge"))
        if (typeof message.total_recharge === "number")
          object.total_recharge = options.longs === String ? String(message.total_recharge) : message.total_recharge;
        else
          object.total_recharge =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_recharge)
              : options.longs === Number
                ? new $util.LongBits(message.total_recharge.low >>> 0, message.total_recharge.high >>> 0).toNumber()
                : message.total_recharge;
      if (message.gamecount != null && message.hasOwnProperty("gamecount")) object.gamecount = message.gamecount;
      return object;
    };

    /**
     * Converts this TaskItem to JSON.
     * @function toJSON
     * @memberof client_proto.TaskItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TaskItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TaskItem
     * @function getTypeUrl
     * @memberof client_proto.TaskItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TaskItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.TaskItem";
    };

    return TaskItem;
  })();

  client_proto.ActivityListPush = (function () {
    /**
     * Properties of an ActivityListPush.
     * @memberof client_proto
     * @interface IActivityListPush
     * @property {Array.<client_proto.IActivityItem>|null} [list] ActivityListPush list
     */

    /**
     * Constructs a new ActivityListPush.
     * @memberof client_proto
     * @classdesc Represents an ActivityListPush.
     * @implements IActivityListPush
     * @constructor
     * @param {client_proto.IActivityListPush=} [properties] Properties to set
     */
    function ActivityListPush(properties) {
      this.list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ActivityListPush list.
     * @member {Array.<client_proto.IActivityItem>} list
     * @memberof client_proto.ActivityListPush
     * @instance
     */
    ActivityListPush.prototype.list = $util.emptyArray;

    /**
     * Creates a new ActivityListPush instance using the specified properties.
     * @function create
     * @memberof client_proto.ActivityListPush
     * @static
     * @param {client_proto.IActivityListPush=} [properties] Properties to set
     * @returns {client_proto.ActivityListPush} ActivityListPush instance
     */
    ActivityListPush.create = function create(properties) {
      return new ActivityListPush(properties);
    };

    /**
     * Encodes the specified ActivityListPush message. Does not implicitly {@link client_proto.ActivityListPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ActivityListPush
     * @static
     * @param {client_proto.IActivityListPush} message ActivityListPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityListPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.list != null && message.list.length)
        for (var i = 0; i < message.list.length; ++i)
          $root.client_proto.ActivityItem.encode(
            message.list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified ActivityListPush message, length delimited. Does not implicitly {@link client_proto.ActivityListPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ActivityListPush
     * @static
     * @param {client_proto.IActivityListPush} message ActivityListPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityListPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ActivityListPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ActivityListPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ActivityListPush} ActivityListPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityListPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ActivityListPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.list && message.list.length)) message.list = [];
            message.list.push($root.client_proto.ActivityItem.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an ActivityListPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ActivityListPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ActivityListPush} ActivityListPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityListPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ActivityListPush message.
     * @function verify
     * @memberof client_proto.ActivityListPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ActivityListPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.list != null && message.hasOwnProperty("list")) {
        if (!Array.isArray(message.list)) return "list: array expected";
        for (var i = 0; i < message.list.length; ++i) {
          var error = $root.client_proto.ActivityItem.verify(message.list[i]);
          if (error) return "list." + error;
        }
      }
      return null;
    };

    /**
     * Creates an ActivityListPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ActivityListPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ActivityListPush} ActivityListPush
     */
    ActivityListPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ActivityListPush) return object;
      var message = new $root.client_proto.ActivityListPush();
      if (object.list) {
        if (!Array.isArray(object.list)) throw TypeError(".client_proto.ActivityListPush.list: array expected");
        message.list = [];
        for (var i = 0; i < object.list.length; ++i) {
          if (typeof object.list[i] !== "object")
            throw TypeError(".client_proto.ActivityListPush.list: object expected");
          message.list[i] = $root.client_proto.ActivityItem.fromObject(object.list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from an ActivityListPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ActivityListPush
     * @static
     * @param {client_proto.ActivityListPush} message ActivityListPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ActivityListPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.list = [];
      if (message.list && message.list.length) {
        object.list = [];
        for (var j = 0; j < message.list.length; ++j)
          object.list[j] = $root.client_proto.ActivityItem.toObject(message.list[j], options);
      }
      return object;
    };

    /**
     * Converts this ActivityListPush to JSON.
     * @function toJSON
     * @memberof client_proto.ActivityListPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ActivityListPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ActivityListPush
     * @function getTypeUrl
     * @memberof client_proto.ActivityListPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ActivityListPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ActivityListPush";
    };

    return ActivityListPush;
  })();

  client_proto.ActivityDetailReq = (function () {
    /**
     * Properties of an ActivityDetailReq.
     * @memberof client_proto
     * @interface IActivityDetailReq
     * @property {number|null} [uid] ActivityDetailReq uid
     * @property {number|null} [id] ActivityDetailReq id
     */

    /**
     * Constructs a new ActivityDetailReq.
     * @memberof client_proto
     * @classdesc Represents an ActivityDetailReq.
     * @implements IActivityDetailReq
     * @constructor
     * @param {client_proto.IActivityDetailReq=} [properties] Properties to set
     */
    function ActivityDetailReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ActivityDetailReq uid.
     * @member {number} uid
     * @memberof client_proto.ActivityDetailReq
     * @instance
     */
    ActivityDetailReq.prototype.uid = 0;

    /**
     * ActivityDetailReq id.
     * @member {number} id
     * @memberof client_proto.ActivityDetailReq
     * @instance
     */
    ActivityDetailReq.prototype.id = 0;

    /**
     * Creates a new ActivityDetailReq instance using the specified properties.
     * @function create
     * @memberof client_proto.ActivityDetailReq
     * @static
     * @param {client_proto.IActivityDetailReq=} [properties] Properties to set
     * @returns {client_proto.ActivityDetailReq} ActivityDetailReq instance
     */
    ActivityDetailReq.create = function create(properties) {
      return new ActivityDetailReq(properties);
    };

    /**
     * Encodes the specified ActivityDetailReq message. Does not implicitly {@link client_proto.ActivityDetailReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ActivityDetailReq
     * @static
     * @param {client_proto.IActivityDetailReq} message ActivityDetailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityDetailReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified ActivityDetailReq message, length delimited. Does not implicitly {@link client_proto.ActivityDetailReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ActivityDetailReq
     * @static
     * @param {client_proto.IActivityDetailReq} message ActivityDetailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityDetailReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ActivityDetailReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ActivityDetailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ActivityDetailReq} ActivityDetailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityDetailReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ActivityDetailReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an ActivityDetailReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ActivityDetailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ActivityDetailReq} ActivityDetailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityDetailReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ActivityDetailReq message.
     * @function verify
     * @memberof client_proto.ActivityDetailReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ActivityDetailReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates an ActivityDetailReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ActivityDetailReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ActivityDetailReq} ActivityDetailReq
     */
    ActivityDetailReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ActivityDetailReq) return object;
      var message = new $root.client_proto.ActivityDetailReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from an ActivityDetailReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ActivityDetailReq
     * @static
     * @param {client_proto.ActivityDetailReq} message ActivityDetailReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ActivityDetailReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this ActivityDetailReq to JSON.
     * @function toJSON
     * @memberof client_proto.ActivityDetailReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ActivityDetailReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ActivityDetailReq
     * @function getTypeUrl
     * @memberof client_proto.ActivityDetailReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ActivityDetailReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ActivityDetailReq";
    };

    return ActivityDetailReq;
  })();

  client_proto.GoodsGiftCreatOrderReq = (function () {
    /**
     * Properties of a GoodsGiftCreatOrderReq.
     * @memberof client_proto
     * @interface IGoodsGiftCreatOrderReq
     * @property {number|null} [uid] GoodsGiftCreatOrderReq uid
     * @property {number|null} [id] GoodsGiftCreatOrderReq id
     * @property {number|null} [subid] GoodsGiftCreatOrderReq subid
     */

    /**
     * Constructs a new GoodsGiftCreatOrderReq.
     * @memberof client_proto
     * @classdesc Represents a GoodsGiftCreatOrderReq.
     * @implements IGoodsGiftCreatOrderReq
     * @constructor
     * @param {client_proto.IGoodsGiftCreatOrderReq=} [properties] Properties to set
     */
    function GoodsGiftCreatOrderReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GoodsGiftCreatOrderReq uid.
     * @member {number} uid
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @instance
     */
    GoodsGiftCreatOrderReq.prototype.uid = 0;

    /**
     * GoodsGiftCreatOrderReq id.
     * @member {number} id
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @instance
     */
    GoodsGiftCreatOrderReq.prototype.id = 0;

    /**
     * GoodsGiftCreatOrderReq subid.
     * @member {number} subid
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @instance
     */
    GoodsGiftCreatOrderReq.prototype.subid = 0;

    /**
     * Creates a new GoodsGiftCreatOrderReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @static
     * @param {client_proto.IGoodsGiftCreatOrderReq=} [properties] Properties to set
     * @returns {client_proto.GoodsGiftCreatOrderReq} GoodsGiftCreatOrderReq instance
     */
    GoodsGiftCreatOrderReq.create = function create(properties) {
      return new GoodsGiftCreatOrderReq(properties);
    };

    /**
     * Encodes the specified GoodsGiftCreatOrderReq message. Does not implicitly {@link client_proto.GoodsGiftCreatOrderReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @static
     * @param {client_proto.IGoodsGiftCreatOrderReq} message GoodsGiftCreatOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GoodsGiftCreatOrderReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.subid);
      return writer;
    };

    /**
     * Encodes the specified GoodsGiftCreatOrderReq message, length delimited. Does not implicitly {@link client_proto.GoodsGiftCreatOrderReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @static
     * @param {client_proto.IGoodsGiftCreatOrderReq} message GoodsGiftCreatOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GoodsGiftCreatOrderReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GoodsGiftCreatOrderReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GoodsGiftCreatOrderReq} GoodsGiftCreatOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GoodsGiftCreatOrderReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GoodsGiftCreatOrderReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            message.subid = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GoodsGiftCreatOrderReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GoodsGiftCreatOrderReq} GoodsGiftCreatOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GoodsGiftCreatOrderReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GoodsGiftCreatOrderReq message.
     * @function verify
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GoodsGiftCreatOrderReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      return null;
    };

    /**
     * Creates a GoodsGiftCreatOrderReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GoodsGiftCreatOrderReq} GoodsGiftCreatOrderReq
     */
    GoodsGiftCreatOrderReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GoodsGiftCreatOrderReq) return object;
      var message = new $root.client_proto.GoodsGiftCreatOrderReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      return message;
    };

    /**
     * Creates a plain object from a GoodsGiftCreatOrderReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @static
     * @param {client_proto.GoodsGiftCreatOrderReq} message GoodsGiftCreatOrderReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GoodsGiftCreatOrderReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
        object.subid = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      return object;
    };

    /**
     * Converts this GoodsGiftCreatOrderReq to JSON.
     * @function toJSON
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GoodsGiftCreatOrderReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GoodsGiftCreatOrderReq
     * @function getTypeUrl
     * @memberof client_proto.GoodsGiftCreatOrderReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GoodsGiftCreatOrderReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GoodsGiftCreatOrderReq";
    };

    return GoodsGiftCreatOrderReq;
  })();

  client_proto.GoodsGiftCreatOrderResp = (function () {
    /**
     * Properties of a GoodsGiftCreatOrderResp.
     * @memberof client_proto
     * @interface IGoodsGiftCreatOrderResp
     * @property {number|null} [result] GoodsGiftCreatOrderResp result
     * @property {number|null} [id] GoodsGiftCreatOrderResp id
     * @property {number|null} [subid] GoodsGiftCreatOrderResp subid
     * @property {string|null} [url] GoodsGiftCreatOrderResp url
     */

    /**
     * Constructs a new GoodsGiftCreatOrderResp.
     * @memberof client_proto
     * @classdesc Represents a GoodsGiftCreatOrderResp.
     * @implements IGoodsGiftCreatOrderResp
     * @constructor
     * @param {client_proto.IGoodsGiftCreatOrderResp=} [properties] Properties to set
     */
    function GoodsGiftCreatOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GoodsGiftCreatOrderResp result.
     * @member {number} result
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @instance
     */
    GoodsGiftCreatOrderResp.prototype.result = 0;

    /**
     * GoodsGiftCreatOrderResp id.
     * @member {number} id
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @instance
     */
    GoodsGiftCreatOrderResp.prototype.id = 0;

    /**
     * GoodsGiftCreatOrderResp subid.
     * @member {number} subid
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @instance
     */
    GoodsGiftCreatOrderResp.prototype.subid = 0;

    /**
     * GoodsGiftCreatOrderResp url.
     * @member {string} url
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @instance
     */
    GoodsGiftCreatOrderResp.prototype.url = "";

    /**
     * Creates a new GoodsGiftCreatOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @static
     * @param {client_proto.IGoodsGiftCreatOrderResp=} [properties] Properties to set
     * @returns {client_proto.GoodsGiftCreatOrderResp} GoodsGiftCreatOrderResp instance
     */
    GoodsGiftCreatOrderResp.create = function create(properties) {
      return new GoodsGiftCreatOrderResp(properties);
    };

    /**
     * Encodes the specified GoodsGiftCreatOrderResp message. Does not implicitly {@link client_proto.GoodsGiftCreatOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @static
     * @param {client_proto.IGoodsGiftCreatOrderResp} message GoodsGiftCreatOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GoodsGiftCreatOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.subid);
      if (message.url != null && Object.hasOwnProperty.call(message, "url"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.url);
      return writer;
    };

    /**
     * Encodes the specified GoodsGiftCreatOrderResp message, length delimited. Does not implicitly {@link client_proto.GoodsGiftCreatOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @static
     * @param {client_proto.IGoodsGiftCreatOrderResp} message GoodsGiftCreatOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GoodsGiftCreatOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GoodsGiftCreatOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GoodsGiftCreatOrderResp} GoodsGiftCreatOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GoodsGiftCreatOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GoodsGiftCreatOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            message.subid = reader.int32();
            break;
          }
          case 4: {
            message.url = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GoodsGiftCreatOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GoodsGiftCreatOrderResp} GoodsGiftCreatOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GoodsGiftCreatOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GoodsGiftCreatOrderResp message.
     * @function verify
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GoodsGiftCreatOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.url != null && message.hasOwnProperty("url"))
        if (!$util.isString(message.url)) return "url: string expected";
      return null;
    };

    /**
     * Creates a GoodsGiftCreatOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GoodsGiftCreatOrderResp} GoodsGiftCreatOrderResp
     */
    GoodsGiftCreatOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GoodsGiftCreatOrderResp) return object;
      var message = new $root.client_proto.GoodsGiftCreatOrderResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.id != null) message.id = object.id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.url != null) message.url = String(object.url);
      return message;
    };

    /**
     * Creates a plain object from a GoodsGiftCreatOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @static
     * @param {client_proto.GoodsGiftCreatOrderResp} message GoodsGiftCreatOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GoodsGiftCreatOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.id = 0;
        object.subid = 0;
        object.url = "";
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.url != null && message.hasOwnProperty("url")) object.url = message.url;
      return object;
    };

    /**
     * Converts this GoodsGiftCreatOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GoodsGiftCreatOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GoodsGiftCreatOrderResp
     * @function getTypeUrl
     * @memberof client_proto.GoodsGiftCreatOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GoodsGiftCreatOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GoodsGiftCreatOrderResp";
    };

    return GoodsGiftCreatOrderResp;
  })();

  client_proto.CompleteChargeOrderPush = (function () {
    /**
     * Properties of a CompleteChargeOrderPush.
     * @memberof client_proto
     * @interface ICompleteChargeOrderPush
     * @property {number|null} [result] CompleteChargeOrderPush result
     * @property {number|null} [id] CompleteChargeOrderPush id
     * @property {number|null} [subid] CompleteChargeOrderPush subid
     */

    /**
     * Constructs a new CompleteChargeOrderPush.
     * @memberof client_proto
     * @classdesc Represents a CompleteChargeOrderPush.
     * @implements ICompleteChargeOrderPush
     * @constructor
     * @param {client_proto.ICompleteChargeOrderPush=} [properties] Properties to set
     */
    function CompleteChargeOrderPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CompleteChargeOrderPush result.
     * @member {number} result
     * @memberof client_proto.CompleteChargeOrderPush
     * @instance
     */
    CompleteChargeOrderPush.prototype.result = 0;

    /**
     * CompleteChargeOrderPush id.
     * @member {number} id
     * @memberof client_proto.CompleteChargeOrderPush
     * @instance
     */
    CompleteChargeOrderPush.prototype.id = 0;

    /**
     * CompleteChargeOrderPush subid.
     * @member {number} subid
     * @memberof client_proto.CompleteChargeOrderPush
     * @instance
     */
    CompleteChargeOrderPush.prototype.subid = 0;

    /**
     * Creates a new CompleteChargeOrderPush instance using the specified properties.
     * @function create
     * @memberof client_proto.CompleteChargeOrderPush
     * @static
     * @param {client_proto.ICompleteChargeOrderPush=} [properties] Properties to set
     * @returns {client_proto.CompleteChargeOrderPush} CompleteChargeOrderPush instance
     */
    CompleteChargeOrderPush.create = function create(properties) {
      return new CompleteChargeOrderPush(properties);
    };

    /**
     * Encodes the specified CompleteChargeOrderPush message. Does not implicitly {@link client_proto.CompleteChargeOrderPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CompleteChargeOrderPush
     * @static
     * @param {client_proto.ICompleteChargeOrderPush} message CompleteChargeOrderPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CompleteChargeOrderPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.subid);
      return writer;
    };

    /**
     * Encodes the specified CompleteChargeOrderPush message, length delimited. Does not implicitly {@link client_proto.CompleteChargeOrderPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CompleteChargeOrderPush
     * @static
     * @param {client_proto.ICompleteChargeOrderPush} message CompleteChargeOrderPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CompleteChargeOrderPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CompleteChargeOrderPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CompleteChargeOrderPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CompleteChargeOrderPush} CompleteChargeOrderPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CompleteChargeOrderPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CompleteChargeOrderPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            message.subid = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CompleteChargeOrderPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CompleteChargeOrderPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CompleteChargeOrderPush} CompleteChargeOrderPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CompleteChargeOrderPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CompleteChargeOrderPush message.
     * @function verify
     * @memberof client_proto.CompleteChargeOrderPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CompleteChargeOrderPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      return null;
    };

    /**
     * Creates a CompleteChargeOrderPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CompleteChargeOrderPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CompleteChargeOrderPush} CompleteChargeOrderPush
     */
    CompleteChargeOrderPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CompleteChargeOrderPush) return object;
      var message = new $root.client_proto.CompleteChargeOrderPush();
      if (object.result != null) message.result = object.result | 0;
      if (object.id != null) message.id = object.id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      return message;
    };

    /**
     * Creates a plain object from a CompleteChargeOrderPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CompleteChargeOrderPush
     * @static
     * @param {client_proto.CompleteChargeOrderPush} message CompleteChargeOrderPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CompleteChargeOrderPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.id = 0;
        object.subid = 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      return object;
    };

    /**
     * Converts this CompleteChargeOrderPush to JSON.
     * @function toJSON
     * @memberof client_proto.CompleteChargeOrderPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CompleteChargeOrderPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CompleteChargeOrderPush
     * @function getTypeUrl
     * @memberof client_proto.CompleteChargeOrderPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CompleteChargeOrderPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CompleteChargeOrderPush";
    };

    return CompleteChargeOrderPush;
  })();

  /**
   * ACTIVITY_ERROR_TYPE enum.
   * @name client_proto.ACTIVITY_ERROR_TYPE
   * @enum {number}
   * @property {number} ACTIVITY_NORMAL=0 ACTIVITY_NORMAL value
   * @property {number} ACTIVITY_GOODSGIFT_CHARGE_ORDER_PAY=1 ACTIVITY_GOODSGIFT_CHARGE_ORDER_PAY value
   * @property {number} ACTIVITY_GOODSGIFT_CHARGE_ORDER_REFUND=2 ACTIVITY_GOODSGIFT_CHARGE_ORDER_REFUND value
   * @property {number} ACTIVITY_GOODSGIFT_CHARGE_ORDER_TIMEOUT=3 ACTIVITY_GOODSGIFT_CHARGE_ORDER_TIMEOUT value
   * @property {number} ACTIVITY_ERROR_NOT_EXISTENT=4 ACTIVITY_ERROR_NOT_EXISTENT value
   * @property {number} ACTIVITY_ERROR_NOT_SUB_EXISTENT=5 ACTIVITY_ERROR_NOT_SUB_EXISTENT value
   * @property {number} ACTIVITY_ERROR_NOT_LOTTERYCOUNT=6 ACTIVITY_ERROR_NOT_LOTTERYCOUNT value
   * @property {number} ACTIVITY_ERROR_CLOSE=7 ACTIVITY_ERROR_CLOSE value
   * @property {number} ACTIVITY_GOODSGIFT_SOLDOUT=1001 ACTIVITY_GOODSGIFT_SOLDOUT value
   * @property {number} ACTIVITY_GOODSGIFT_PURCHASELIMIT=1002 ACTIVITY_GOODSGIFT_PURCHASELIMIT value
   * @property {number} ACTIVITY_GOODSGIFT_OVER=1003 ACTIVITY_GOODSGIFT_OVER value
   * @property {number} ACTIVITY_GOODSGIFT_NOTORDER=1004 ACTIVITY_GOODSGIFT_NOTORDER value
   * @property {number} ACTIVITY_REGRESSGIFT_NOTORDER=1005 ACTIVITY_REGRESSGIFT_NOTORDER value
   * @property {number} ACTIVITY_REGRESSGIFT_NOTCONFORM=1006 ACTIVITY_REGRESSGIFT_NOTCONFORM value
   * @property {number} ACTIVITY_SURPRISEGIFT_NOTORDER=1007 ACTIVITY_SURPRISEGIFT_NOTORDER value
   * @property {number} ACTIVITY_SURPRISEGIFT_NOTCONFORM=1008 ACTIVITY_SURPRISEGIFT_NOTCONFORM value
   */
  client_proto.ACTIVITY_ERROR_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "ACTIVITY_NORMAL")] = 0;
    values[(valuesById[1] = "ACTIVITY_GOODSGIFT_CHARGE_ORDER_PAY")] = 1;
    values[(valuesById[2] = "ACTIVITY_GOODSGIFT_CHARGE_ORDER_REFUND")] = 2;
    values[(valuesById[3] = "ACTIVITY_GOODSGIFT_CHARGE_ORDER_TIMEOUT")] = 3;
    values[(valuesById[4] = "ACTIVITY_ERROR_NOT_EXISTENT")] = 4;
    values[(valuesById[5] = "ACTIVITY_ERROR_NOT_SUB_EXISTENT")] = 5;
    values[(valuesById[6] = "ACTIVITY_ERROR_NOT_LOTTERYCOUNT")] = 6;
    values[(valuesById[7] = "ACTIVITY_ERROR_CLOSE")] = 7;
    values[(valuesById[1001] = "ACTIVITY_GOODSGIFT_SOLDOUT")] = 1001;
    values[(valuesById[1002] = "ACTIVITY_GOODSGIFT_PURCHASELIMIT")] = 1002;
    values[(valuesById[1003] = "ACTIVITY_GOODSGIFT_OVER")] = 1003;
    values[(valuesById[1004] = "ACTIVITY_GOODSGIFT_NOTORDER")] = 1004;
    values[(valuesById[1005] = "ACTIVITY_REGRESSGIFT_NOTORDER")] = 1005;
    values[(valuesById[1006] = "ACTIVITY_REGRESSGIFT_NOTCONFORM")] = 1006;
    values[(valuesById[1007] = "ACTIVITY_SURPRISEGIFT_NOTORDER")] = 1007;
    values[(valuesById[1008] = "ACTIVITY_SURPRISEGIFT_NOTCONFORM")] = 1008;
    return values;
  })();

  client_proto.ActivityResultResp = (function () {
    /**
     * Properties of an ActivityResultResp.
     * @memberof client_proto
     * @interface IActivityResultResp
     * @property {number|null} [result] ActivityResultResp result
     * @property {number|null} [id] ActivityResultResp id
     * @property {number|null} [subid] ActivityResultResp subid
     */

    /**
     * Constructs a new ActivityResultResp.
     * @memberof client_proto
     * @classdesc Represents an ActivityResultResp.
     * @implements IActivityResultResp
     * @constructor
     * @param {client_proto.IActivityResultResp=} [properties] Properties to set
     */
    function ActivityResultResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ActivityResultResp result.
     * @member {number} result
     * @memberof client_proto.ActivityResultResp
     * @instance
     */
    ActivityResultResp.prototype.result = 0;

    /**
     * ActivityResultResp id.
     * @member {number} id
     * @memberof client_proto.ActivityResultResp
     * @instance
     */
    ActivityResultResp.prototype.id = 0;

    /**
     * ActivityResultResp subid.
     * @member {number} subid
     * @memberof client_proto.ActivityResultResp
     * @instance
     */
    ActivityResultResp.prototype.subid = 0;

    /**
     * Creates a new ActivityResultResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ActivityResultResp
     * @static
     * @param {client_proto.IActivityResultResp=} [properties] Properties to set
     * @returns {client_proto.ActivityResultResp} ActivityResultResp instance
     */
    ActivityResultResp.create = function create(properties) {
      return new ActivityResultResp(properties);
    };

    /**
     * Encodes the specified ActivityResultResp message. Does not implicitly {@link client_proto.ActivityResultResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ActivityResultResp
     * @static
     * @param {client_proto.IActivityResultResp} message ActivityResultResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityResultResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.subid);
      return writer;
    };

    /**
     * Encodes the specified ActivityResultResp message, length delimited. Does not implicitly {@link client_proto.ActivityResultResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ActivityResultResp
     * @static
     * @param {client_proto.IActivityResultResp} message ActivityResultResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActivityResultResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ActivityResultResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ActivityResultResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ActivityResultResp} ActivityResultResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityResultResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ActivityResultResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            message.subid = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an ActivityResultResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ActivityResultResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ActivityResultResp} ActivityResultResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActivityResultResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ActivityResultResp message.
     * @function verify
     * @memberof client_proto.ActivityResultResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ActivityResultResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      return null;
    };

    /**
     * Creates an ActivityResultResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ActivityResultResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ActivityResultResp} ActivityResultResp
     */
    ActivityResultResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ActivityResultResp) return object;
      var message = new $root.client_proto.ActivityResultResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.id != null) message.id = object.id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      return message;
    };

    /**
     * Creates a plain object from an ActivityResultResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ActivityResultResp
     * @static
     * @param {client_proto.ActivityResultResp} message ActivityResultResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ActivityResultResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.id = 0;
        object.subid = 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      return object;
    };

    /**
     * Converts this ActivityResultResp to JSON.
     * @function toJSON
     * @memberof client_proto.ActivityResultResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ActivityResultResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ActivityResultResp
     * @function getTypeUrl
     * @memberof client_proto.ActivityResultResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ActivityResultResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ActivityResultResp";
    };

    return ActivityResultResp;
  })();

  /**
   * SPIN_TYPE enum.
   * @name client_proto.SPIN_TYPE
   * @enum {number}
   * @property {number} SPIN_NONE=0 SPIN_NONE value
   * @property {number} SILVER=1 SILVER value
   * @property {number} GOLD=2 GOLD value
   * @property {number} DIAMONDS=3 DIAMONDS value
   * @property {number} SPECIAL=4 SPECIAL value
   */
  client_proto.SPIN_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "SPIN_NONE")] = 0;
    values[(valuesById[1] = "SILVER")] = 1;
    values[(valuesById[2] = "GOLD")] = 2;
    values[(valuesById[3] = "DIAMONDS")] = 3;
    values[(valuesById[4] = "SPECIAL")] = 4;
    return values;
  })();

  /**
   * REWARD_TYPE enum.
   * @name client_proto.REWARD_TYPE
   * @enum {number}
   * @property {number} REWARD_NONE=0 REWARD_NONE value
   * @property {number} REWARD_JCOIN=1 REWARD_JCOIN value
   * @property {number} REWARD_CASH=2 REWARD_CASH value
   * @property {number} REWARD_SILVER=3 REWARD_SILVER value
   * @property {number} REWARD_GOLD=4 REWARD_GOLD value
   * @property {number} REWARD_DIAMONDS=5 REWARD_DIAMONDS value
   * @property {number} REWARD_SPECIAL=6 REWARD_SPECIAL value
   */
  client_proto.REWARD_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "REWARD_NONE")] = 0;
    values[(valuesById[1] = "REWARD_JCOIN")] = 1;
    values[(valuesById[2] = "REWARD_CASH")] = 2;
    values[(valuesById[3] = "REWARD_SILVER")] = 3;
    values[(valuesById[4] = "REWARD_GOLD")] = 4;
    values[(valuesById[5] = "REWARD_DIAMONDS")] = 5;
    values[(valuesById[6] = "REWARD_SPECIAL")] = 6;
    return values;
  })();

  /**
   * RESULT_TYPE enum.
   * @name client_proto.RESULT_TYPE
   * @enum {number}
   * @property {number} RESULT_SUCCESS=0 RESULT_SUCCESS value
   * @property {number} RESULT_ACTIVITY_NOT_EXISTENT=1 RESULT_ACTIVITY_NOT_EXISTENT value
   * @property {number} RESULT_SUB_NOT_EXISTENT=2 RESULT_SUB_NOT_EXISTENT value
   * @property {number} RESULT_NO_LOTTERYCOUNT=3 RESULT_NO_LOTTERYCOUNT value
   * @property {number} RESULT_ACTIVITY_CLOSE=4 RESULT_ACTIVITY_CLOSE value
   */
  client_proto.RESULT_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "RESULT_SUCCESS")] = 0;
    values[(valuesById[1] = "RESULT_ACTIVITY_NOT_EXISTENT")] = 1;
    values[(valuesById[2] = "RESULT_SUB_NOT_EXISTENT")] = 2;
    values[(valuesById[3] = "RESULT_NO_LOTTERYCOUNT")] = 3;
    values[(valuesById[4] = "RESULT_ACTIVITY_CLOSE")] = 4;
    return values;
  })();

  client_proto.SrvWalletRechargePush = (function () {
    /**
     * Properties of a SrvWalletRechargePush.
     * @memberof client_proto
     * @interface ISrvWalletRechargePush
     * @property {number|null} [uid] SrvWalletRechargePush uid
     * @property {number|Long|null} [recharge] SrvWalletRechargePush recharge
     */

    /**
     * Constructs a new SrvWalletRechargePush.
     * @memberof client_proto
     * @classdesc Represents a SrvWalletRechargePush.
     * @implements ISrvWalletRechargePush
     * @constructor
     * @param {client_proto.ISrvWalletRechargePush=} [properties] Properties to set
     */
    function SrvWalletRechargePush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SrvWalletRechargePush uid.
     * @member {number} uid
     * @memberof client_proto.SrvWalletRechargePush
     * @instance
     */
    SrvWalletRechargePush.prototype.uid = 0;

    /**
     * SrvWalletRechargePush recharge.
     * @member {number|Long} recharge
     * @memberof client_proto.SrvWalletRechargePush
     * @instance
     */
    SrvWalletRechargePush.prototype.recharge = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new SrvWalletRechargePush instance using the specified properties.
     * @function create
     * @memberof client_proto.SrvWalletRechargePush
     * @static
     * @param {client_proto.ISrvWalletRechargePush=} [properties] Properties to set
     * @returns {client_proto.SrvWalletRechargePush} SrvWalletRechargePush instance
     */
    SrvWalletRechargePush.create = function create(properties) {
      return new SrvWalletRechargePush(properties);
    };

    /**
     * Encodes the specified SrvWalletRechargePush message. Does not implicitly {@link client_proto.SrvWalletRechargePush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SrvWalletRechargePush
     * @static
     * @param {client_proto.ISrvWalletRechargePush} message SrvWalletRechargePush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvWalletRechargePush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.recharge != null && Object.hasOwnProperty.call(message, "recharge"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.recharge);
      return writer;
    };

    /**
     * Encodes the specified SrvWalletRechargePush message, length delimited. Does not implicitly {@link client_proto.SrvWalletRechargePush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SrvWalletRechargePush
     * @static
     * @param {client_proto.ISrvWalletRechargePush} message SrvWalletRechargePush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvWalletRechargePush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SrvWalletRechargePush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SrvWalletRechargePush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SrvWalletRechargePush} SrvWalletRechargePush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvWalletRechargePush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SrvWalletRechargePush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.recharge = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SrvWalletRechargePush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SrvWalletRechargePush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SrvWalletRechargePush} SrvWalletRechargePush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvWalletRechargePush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SrvWalletRechargePush message.
     * @function verify
     * @memberof client_proto.SrvWalletRechargePush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SrvWalletRechargePush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.recharge != null && message.hasOwnProperty("recharge"))
        if (
          !$util.isInteger(message.recharge) &&
          !(message.recharge && $util.isInteger(message.recharge.low) && $util.isInteger(message.recharge.high))
        )
          return "recharge: integer|Long expected";
      return null;
    };

    /**
     * Creates a SrvWalletRechargePush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SrvWalletRechargePush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SrvWalletRechargePush} SrvWalletRechargePush
     */
    SrvWalletRechargePush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SrvWalletRechargePush) return object;
      var message = new $root.client_proto.SrvWalletRechargePush();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.recharge != null)
        if ($util.Long) (message.recharge = $util.Long.fromValue(object.recharge)).unsigned = false;
        else if (typeof object.recharge === "string") message.recharge = parseInt(object.recharge, 10);
        else if (typeof object.recharge === "number") message.recharge = object.recharge;
        else if (typeof object.recharge === "object")
          message.recharge = new $util.LongBits(object.recharge.low >>> 0, object.recharge.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a SrvWalletRechargePush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SrvWalletRechargePush
     * @static
     * @param {client_proto.SrvWalletRechargePush} message SrvWalletRechargePush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SrvWalletRechargePush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.recharge =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.recharge = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.recharge != null && message.hasOwnProperty("recharge"))
        if (typeof message.recharge === "number")
          object.recharge = options.longs === String ? String(message.recharge) : message.recharge;
        else
          object.recharge =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.recharge)
              : options.longs === Number
                ? new $util.LongBits(message.recharge.low >>> 0, message.recharge.high >>> 0).toNumber()
                : message.recharge;
      return object;
    };

    /**
     * Converts this SrvWalletRechargePush to JSON.
     * @function toJSON
     * @memberof client_proto.SrvWalletRechargePush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SrvWalletRechargePush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SrvWalletRechargePush
     * @function getTypeUrl
     * @memberof client_proto.SrvWalletRechargePush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SrvWalletRechargePush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SrvWalletRechargePush";
    };

    return SrvWalletRechargePush;
  })();

  client_proto.TurntableItem = (function () {
    /**
     * Properties of a TurntableItem.
     * @memberof client_proto
     * @interface ITurntableItem
     * @property {number|null} [id] TurntableItem id
     * @property {number|null} [sort] TurntableItem sort
     * @property {string|null} [strjson] TurntableItem strjson
     * @property {number|Long|null} [totalrecharge] TurntableItem totalrecharge
     * @property {number|null} [lotterycount] TurntableItem lotterycount
     * @property {number|null} [status] TurntableItem status
     */

    /**
     * Constructs a new TurntableItem.
     * @memberof client_proto
     * @classdesc Represents a TurntableItem.
     * @implements ITurntableItem
     * @constructor
     * @param {client_proto.ITurntableItem=} [properties] Properties to set
     */
    function TurntableItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * TurntableItem id.
     * @member {number} id
     * @memberof client_proto.TurntableItem
     * @instance
     */
    TurntableItem.prototype.id = 0;

    /**
     * TurntableItem sort.
     * @member {number} sort
     * @memberof client_proto.TurntableItem
     * @instance
     */
    TurntableItem.prototype.sort = 0;

    /**
     * TurntableItem strjson.
     * @member {string} strjson
     * @memberof client_proto.TurntableItem
     * @instance
     */
    TurntableItem.prototype.strjson = "";

    /**
     * TurntableItem totalrecharge.
     * @member {number|Long} totalrecharge
     * @memberof client_proto.TurntableItem
     * @instance
     */
    TurntableItem.prototype.totalrecharge = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * TurntableItem lotterycount.
     * @member {number} lotterycount
     * @memberof client_proto.TurntableItem
     * @instance
     */
    TurntableItem.prototype.lotterycount = 0;

    /**
     * TurntableItem status.
     * @member {number} status
     * @memberof client_proto.TurntableItem
     * @instance
     */
    TurntableItem.prototype.status = 0;

    /**
     * Creates a new TurntableItem instance using the specified properties.
     * @function create
     * @memberof client_proto.TurntableItem
     * @static
     * @param {client_proto.ITurntableItem=} [properties] Properties to set
     * @returns {client_proto.TurntableItem} TurntableItem instance
     */
    TurntableItem.create = function create(properties) {
      return new TurntableItem(properties);
    };

    /**
     * Encodes the specified TurntableItem message. Does not implicitly {@link client_proto.TurntableItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.TurntableItem
     * @static
     * @param {client_proto.ITurntableItem} message TurntableItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TurntableItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.sort != null && Object.hasOwnProperty.call(message, "sort"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.sort);
      if (message.strjson != null && Object.hasOwnProperty.call(message, "strjson"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.strjson);
      if (message.totalrecharge != null && Object.hasOwnProperty.call(message, "totalrecharge"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.totalrecharge);
      if (message.lotterycount != null && Object.hasOwnProperty.call(message, "lotterycount"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.lotterycount);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.status);
      return writer;
    };

    /**
     * Encodes the specified TurntableItem message, length delimited. Does not implicitly {@link client_proto.TurntableItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.TurntableItem
     * @static
     * @param {client_proto.ITurntableItem} message TurntableItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TurntableItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TurntableItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.TurntableItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.TurntableItem} TurntableItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TurntableItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.TurntableItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.sort = reader.int32();
            break;
          }
          case 3: {
            message.strjson = reader.string();
            break;
          }
          case 4: {
            message.totalrecharge = reader.int64();
            break;
          }
          case 5: {
            message.lotterycount = reader.int32();
            break;
          }
          case 6: {
            message.status = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a TurntableItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.TurntableItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.TurntableItem} TurntableItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TurntableItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TurntableItem message.
     * @function verify
     * @memberof client_proto.TurntableItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TurntableItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.sort != null && message.hasOwnProperty("sort"))
        if (!$util.isInteger(message.sort)) return "sort: integer expected";
      if (message.strjson != null && message.hasOwnProperty("strjson"))
        if (!$util.isString(message.strjson)) return "strjson: string expected";
      if (message.totalrecharge != null && message.hasOwnProperty("totalrecharge"))
        if (
          !$util.isInteger(message.totalrecharge) &&
          !(
            message.totalrecharge &&
            $util.isInteger(message.totalrecharge.low) &&
            $util.isInteger(message.totalrecharge.high)
          )
        )
          return "totalrecharge: integer|Long expected";
      if (message.lotterycount != null && message.hasOwnProperty("lotterycount"))
        if (!$util.isInteger(message.lotterycount)) return "lotterycount: integer expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      return null;
    };

    /**
     * Creates a TurntableItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.TurntableItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.TurntableItem} TurntableItem
     */
    TurntableItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.TurntableItem) return object;
      var message = new $root.client_proto.TurntableItem();
      if (object.id != null) message.id = object.id | 0;
      if (object.sort != null) message.sort = object.sort | 0;
      if (object.strjson != null) message.strjson = String(object.strjson);
      if (object.totalrecharge != null)
        if ($util.Long) (message.totalrecharge = $util.Long.fromValue(object.totalrecharge)).unsigned = false;
        else if (typeof object.totalrecharge === "string") message.totalrecharge = parseInt(object.totalrecharge, 10);
        else if (typeof object.totalrecharge === "number") message.totalrecharge = object.totalrecharge;
        else if (typeof object.totalrecharge === "object")
          message.totalrecharge = new $util.LongBits(
            object.totalrecharge.low >>> 0,
            object.totalrecharge.high >>> 0,
          ).toNumber();
      if (object.lotterycount != null) message.lotterycount = object.lotterycount | 0;
      if (object.status != null) message.status = object.status | 0;
      return message;
    };

    /**
     * Creates a plain object from a TurntableItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.TurntableItem
     * @static
     * @param {client_proto.TurntableItem} message TurntableItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TurntableItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.sort = 0;
        object.strjson = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.totalrecharge =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.totalrecharge = options.longs === String ? "0" : 0;
        object.lotterycount = 0;
        object.status = 0;
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.sort != null && message.hasOwnProperty("sort")) object.sort = message.sort;
      if (message.strjson != null && message.hasOwnProperty("strjson")) object.strjson = message.strjson;
      if (message.totalrecharge != null && message.hasOwnProperty("totalrecharge"))
        if (typeof message.totalrecharge === "number")
          object.totalrecharge = options.longs === String ? String(message.totalrecharge) : message.totalrecharge;
        else
          object.totalrecharge =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.totalrecharge)
              : options.longs === Number
                ? new $util.LongBits(message.totalrecharge.low >>> 0, message.totalrecharge.high >>> 0).toNumber()
                : message.totalrecharge;
      if (message.lotterycount != null && message.hasOwnProperty("lotterycount"))
        object.lotterycount = message.lotterycount;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      return object;
    };

    /**
     * Converts this TurntableItem to JSON.
     * @function toJSON
     * @memberof client_proto.TurntableItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TurntableItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TurntableItem
     * @function getTypeUrl
     * @memberof client_proto.TurntableItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TurntableItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.TurntableItem";
    };

    return TurntableItem;
  })();

  client_proto.LookEventDetailReq = (function () {
    /**
     * Properties of a LookEventDetailReq.
     * @memberof client_proto
     * @interface ILookEventDetailReq
     * @property {number|null} [uid] LookEventDetailReq uid
     * @property {number|null} [id] LookEventDetailReq id
     */

    /**
     * Constructs a new LookEventDetailReq.
     * @memberof client_proto
     * @classdesc Represents a LookEventDetailReq.
     * @implements ILookEventDetailReq
     * @constructor
     * @param {client_proto.ILookEventDetailReq=} [properties] Properties to set
     */
    function LookEventDetailReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LookEventDetailReq uid.
     * @member {number} uid
     * @memberof client_proto.LookEventDetailReq
     * @instance
     */
    LookEventDetailReq.prototype.uid = 0;

    /**
     * LookEventDetailReq id.
     * @member {number} id
     * @memberof client_proto.LookEventDetailReq
     * @instance
     */
    LookEventDetailReq.prototype.id = 0;

    /**
     * Creates a new LookEventDetailReq instance using the specified properties.
     * @function create
     * @memberof client_proto.LookEventDetailReq
     * @static
     * @param {client_proto.ILookEventDetailReq=} [properties] Properties to set
     * @returns {client_proto.LookEventDetailReq} LookEventDetailReq instance
     */
    LookEventDetailReq.create = function create(properties) {
      return new LookEventDetailReq(properties);
    };

    /**
     * Encodes the specified LookEventDetailReq message. Does not implicitly {@link client_proto.LookEventDetailReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.LookEventDetailReq
     * @static
     * @param {client_proto.ILookEventDetailReq} message LookEventDetailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LookEventDetailReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified LookEventDetailReq message, length delimited. Does not implicitly {@link client_proto.LookEventDetailReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.LookEventDetailReq
     * @static
     * @param {client_proto.ILookEventDetailReq} message LookEventDetailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LookEventDetailReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LookEventDetailReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.LookEventDetailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.LookEventDetailReq} LookEventDetailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LookEventDetailReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.LookEventDetailReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LookEventDetailReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.LookEventDetailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.LookEventDetailReq} LookEventDetailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LookEventDetailReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LookEventDetailReq message.
     * @function verify
     * @memberof client_proto.LookEventDetailReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LookEventDetailReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates a LookEventDetailReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.LookEventDetailReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.LookEventDetailReq} LookEventDetailReq
     */
    LookEventDetailReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.LookEventDetailReq) return object;
      var message = new $root.client_proto.LookEventDetailReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from a LookEventDetailReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.LookEventDetailReq
     * @static
     * @param {client_proto.LookEventDetailReq} message LookEventDetailReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LookEventDetailReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this LookEventDetailReq to JSON.
     * @function toJSON
     * @memberof client_proto.LookEventDetailReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LookEventDetailReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LookEventDetailReq
     * @function getTypeUrl
     * @memberof client_proto.LookEventDetailReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LookEventDetailReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.LookEventDetailReq";
    };

    return LookEventDetailReq;
  })();

  client_proto.LookEventDetailPush = (function () {
    /**
     * Properties of a LookEventDetailPush.
     * @memberof client_proto
     * @interface ILookEventDetailPush
     * @property {number|null} [id] LookEventDetailPush id
     * @property {number|null} [result] LookEventDetailPush result
     */

    /**
     * Constructs a new LookEventDetailPush.
     * @memberof client_proto
     * @classdesc Represents a LookEventDetailPush.
     * @implements ILookEventDetailPush
     * @constructor
     * @param {client_proto.ILookEventDetailPush=} [properties] Properties to set
     */
    function LookEventDetailPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LookEventDetailPush id.
     * @member {number} id
     * @memberof client_proto.LookEventDetailPush
     * @instance
     */
    LookEventDetailPush.prototype.id = 0;

    /**
     * LookEventDetailPush result.
     * @member {number} result
     * @memberof client_proto.LookEventDetailPush
     * @instance
     */
    LookEventDetailPush.prototype.result = 0;

    /**
     * Creates a new LookEventDetailPush instance using the specified properties.
     * @function create
     * @memberof client_proto.LookEventDetailPush
     * @static
     * @param {client_proto.ILookEventDetailPush=} [properties] Properties to set
     * @returns {client_proto.LookEventDetailPush} LookEventDetailPush instance
     */
    LookEventDetailPush.create = function create(properties) {
      return new LookEventDetailPush(properties);
    };

    /**
     * Encodes the specified LookEventDetailPush message. Does not implicitly {@link client_proto.LookEventDetailPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.LookEventDetailPush
     * @static
     * @param {client_proto.ILookEventDetailPush} message LookEventDetailPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LookEventDetailPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified LookEventDetailPush message, length delimited. Does not implicitly {@link client_proto.LookEventDetailPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.LookEventDetailPush
     * @static
     * @param {client_proto.ILookEventDetailPush} message LookEventDetailPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LookEventDetailPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LookEventDetailPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.LookEventDetailPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.LookEventDetailPush} LookEventDetailPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LookEventDetailPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.LookEventDetailPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LookEventDetailPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.LookEventDetailPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.LookEventDetailPush} LookEventDetailPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LookEventDetailPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LookEventDetailPush message.
     * @function verify
     * @memberof client_proto.LookEventDetailPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LookEventDetailPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a LookEventDetailPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.LookEventDetailPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.LookEventDetailPush} LookEventDetailPush
     */
    LookEventDetailPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.LookEventDetailPush) return object;
      var message = new $root.client_proto.LookEventDetailPush();
      if (object.id != null) message.id = object.id | 0;
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a LookEventDetailPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.LookEventDetailPush
     * @static
     * @param {client_proto.LookEventDetailPush} message LookEventDetailPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LookEventDetailPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.result = 0;
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this LookEventDetailPush to JSON.
     * @function toJSON
     * @memberof client_proto.LookEventDetailPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LookEventDetailPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LookEventDetailPush
     * @function getTypeUrl
     * @memberof client_proto.LookEventDetailPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LookEventDetailPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.LookEventDetailPush";
    };

    return LookEventDetailPush;
  })();

  client_proto.AccumulatedChargeLottryReq = (function () {
    /**
     * Properties of an AccumulatedChargeLottryReq.
     * @memberof client_proto
     * @interface IAccumulatedChargeLottryReq
     * @property {number|null} [uid] AccumulatedChargeLottryReq uid
     * @property {number|null} [id] AccumulatedChargeLottryReq id
     * @property {number|null} [subid] AccumulatedChargeLottryReq subid
     * @property {string|null} [nickname] AccumulatedChargeLottryReq nickname
     */

    /**
     * Constructs a new AccumulatedChargeLottryReq.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeLottryReq.
     * @implements IAccumulatedChargeLottryReq
     * @constructor
     * @param {client_proto.IAccumulatedChargeLottryReq=} [properties] Properties to set
     */
    function AccumulatedChargeLottryReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeLottryReq uid.
     * @member {number} uid
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @instance
     */
    AccumulatedChargeLottryReq.prototype.uid = 0;

    /**
     * AccumulatedChargeLottryReq id.
     * @member {number} id
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @instance
     */
    AccumulatedChargeLottryReq.prototype.id = 0;

    /**
     * AccumulatedChargeLottryReq subid.
     * @member {number} subid
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @instance
     */
    AccumulatedChargeLottryReq.prototype.subid = 0;

    /**
     * AccumulatedChargeLottryReq nickname.
     * @member {string} nickname
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @instance
     */
    AccumulatedChargeLottryReq.prototype.nickname = "";

    /**
     * Creates a new AccumulatedChargeLottryReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @static
     * @param {client_proto.IAccumulatedChargeLottryReq=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeLottryReq} AccumulatedChargeLottryReq instance
     */
    AccumulatedChargeLottryReq.create = function create(properties) {
      return new AccumulatedChargeLottryReq(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeLottryReq message. Does not implicitly {@link client_proto.AccumulatedChargeLottryReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @static
     * @param {client_proto.IAccumulatedChargeLottryReq} message AccumulatedChargeLottryReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeLottryReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.subid);
      if (message.nickname != null && Object.hasOwnProperty.call(message, "nickname"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.nickname);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeLottryReq message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeLottryReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @static
     * @param {client_proto.IAccumulatedChargeLottryReq} message AccumulatedChargeLottryReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeLottryReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeLottryReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeLottryReq} AccumulatedChargeLottryReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeLottryReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeLottryReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            message.subid = reader.int32();
            break;
          }
          case 4: {
            message.nickname = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeLottryReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeLottryReq} AccumulatedChargeLottryReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeLottryReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeLottryReq message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeLottryReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.nickname != null && message.hasOwnProperty("nickname"))
        if (!$util.isString(message.nickname)) return "nickname: string expected";
      return null;
    };

    /**
     * Creates an AccumulatedChargeLottryReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeLottryReq} AccumulatedChargeLottryReq
     */
    AccumulatedChargeLottryReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeLottryReq) return object;
      var message = new $root.client_proto.AccumulatedChargeLottryReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.nickname != null) message.nickname = String(object.nickname);
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeLottryReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @static
     * @param {client_proto.AccumulatedChargeLottryReq} message AccumulatedChargeLottryReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeLottryReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
        object.subid = 0;
        object.nickname = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.nickname != null && message.hasOwnProperty("nickname")) object.nickname = message.nickname;
      return object;
    };

    /**
     * Converts this AccumulatedChargeLottryReq to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeLottryReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeLottryReq
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeLottryReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeLottryReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeLottryReq";
    };

    return AccumulatedChargeLottryReq;
  })();

  client_proto.AccumulatedChargeLottryResp = (function () {
    /**
     * Properties of an AccumulatedChargeLottryResp.
     * @memberof client_proto
     * @interface IAccumulatedChargeLottryResp
     * @property {number|null} [id] AccumulatedChargeLottryResp id
     * @property {number|null} [subid] AccumulatedChargeLottryResp subid
     * @property {number|null} [rewardindex] AccumulatedChargeLottryResp rewardindex
     * @property {number|null} [rewardid] AccumulatedChargeLottryResp rewardid
     * @property {number|null} [rewardtype] AccumulatedChargeLottryResp rewardtype
     * @property {number|null} [rewardvalue] AccumulatedChargeLottryResp rewardvalue
     * @property {number|null} [lotterycount] AccumulatedChargeLottryResp lotterycount
     * @property {number|null} [ret] AccumulatedChargeLottryResp ret
     */

    /**
     * Constructs a new AccumulatedChargeLottryResp.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeLottryResp.
     * @implements IAccumulatedChargeLottryResp
     * @constructor
     * @param {client_proto.IAccumulatedChargeLottryResp=} [properties] Properties to set
     */
    function AccumulatedChargeLottryResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeLottryResp id.
     * @member {number} id
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @instance
     */
    AccumulatedChargeLottryResp.prototype.id = 0;

    /**
     * AccumulatedChargeLottryResp subid.
     * @member {number} subid
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @instance
     */
    AccumulatedChargeLottryResp.prototype.subid = 0;

    /**
     * AccumulatedChargeLottryResp rewardindex.
     * @member {number} rewardindex
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @instance
     */
    AccumulatedChargeLottryResp.prototype.rewardindex = 0;

    /**
     * AccumulatedChargeLottryResp rewardid.
     * @member {number} rewardid
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @instance
     */
    AccumulatedChargeLottryResp.prototype.rewardid = 0;

    /**
     * AccumulatedChargeLottryResp rewardtype.
     * @member {number} rewardtype
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @instance
     */
    AccumulatedChargeLottryResp.prototype.rewardtype = 0;

    /**
     * AccumulatedChargeLottryResp rewardvalue.
     * @member {number} rewardvalue
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @instance
     */
    AccumulatedChargeLottryResp.prototype.rewardvalue = 0;

    /**
     * AccumulatedChargeLottryResp lotterycount.
     * @member {number} lotterycount
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @instance
     */
    AccumulatedChargeLottryResp.prototype.lotterycount = 0;

    /**
     * AccumulatedChargeLottryResp ret.
     * @member {number} ret
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @instance
     */
    AccumulatedChargeLottryResp.prototype.ret = 0;

    /**
     * Creates a new AccumulatedChargeLottryResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @static
     * @param {client_proto.IAccumulatedChargeLottryResp=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeLottryResp} AccumulatedChargeLottryResp instance
     */
    AccumulatedChargeLottryResp.create = function create(properties) {
      return new AccumulatedChargeLottryResp(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeLottryResp message. Does not implicitly {@link client_proto.AccumulatedChargeLottryResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @static
     * @param {client_proto.IAccumulatedChargeLottryResp} message AccumulatedChargeLottryResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeLottryResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.subid);
      if (message.rewardindex != null && Object.hasOwnProperty.call(message, "rewardindex"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.rewardindex);
      if (message.rewardid != null && Object.hasOwnProperty.call(message, "rewardid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.rewardid);
      if (message.rewardtype != null && Object.hasOwnProperty.call(message, "rewardtype"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.rewardtype);
      if (message.rewardvalue != null && Object.hasOwnProperty.call(message, "rewardvalue"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.rewardvalue);
      if (message.lotterycount != null && Object.hasOwnProperty.call(message, "lotterycount"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.lotterycount);
      if (message.ret != null && Object.hasOwnProperty.call(message, "ret"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.ret);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeLottryResp message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeLottryResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @static
     * @param {client_proto.IAccumulatedChargeLottryResp} message AccumulatedChargeLottryResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeLottryResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeLottryResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeLottryResp} AccumulatedChargeLottryResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeLottryResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeLottryResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.subid = reader.int32();
            break;
          }
          case 3: {
            message.rewardindex = reader.int32();
            break;
          }
          case 4: {
            message.rewardid = reader.int32();
            break;
          }
          case 5: {
            message.rewardtype = reader.int32();
            break;
          }
          case 6: {
            message.rewardvalue = reader.int32();
            break;
          }
          case 7: {
            message.lotterycount = reader.int32();
            break;
          }
          case 8: {
            message.ret = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeLottryResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeLottryResp} AccumulatedChargeLottryResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeLottryResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeLottryResp message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeLottryResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.rewardindex != null && message.hasOwnProperty("rewardindex"))
        if (!$util.isInteger(message.rewardindex)) return "rewardindex: integer expected";
      if (message.rewardid != null && message.hasOwnProperty("rewardid"))
        if (!$util.isInteger(message.rewardid)) return "rewardid: integer expected";
      if (message.rewardtype != null && message.hasOwnProperty("rewardtype"))
        if (!$util.isInteger(message.rewardtype)) return "rewardtype: integer expected";
      if (message.rewardvalue != null && message.hasOwnProperty("rewardvalue"))
        if (!$util.isInteger(message.rewardvalue)) return "rewardvalue: integer expected";
      if (message.lotterycount != null && message.hasOwnProperty("lotterycount"))
        if (!$util.isInteger(message.lotterycount)) return "lotterycount: integer expected";
      if (message.ret != null && message.hasOwnProperty("ret"))
        if (!$util.isInteger(message.ret)) return "ret: integer expected";
      return null;
    };

    /**
     * Creates an AccumulatedChargeLottryResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeLottryResp} AccumulatedChargeLottryResp
     */
    AccumulatedChargeLottryResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeLottryResp) return object;
      var message = new $root.client_proto.AccumulatedChargeLottryResp();
      if (object.id != null) message.id = object.id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.rewardindex != null) message.rewardindex = object.rewardindex | 0;
      if (object.rewardid != null) message.rewardid = object.rewardid | 0;
      if (object.rewardtype != null) message.rewardtype = object.rewardtype | 0;
      if (object.rewardvalue != null) message.rewardvalue = object.rewardvalue | 0;
      if (object.lotterycount != null) message.lotterycount = object.lotterycount | 0;
      if (object.ret != null) message.ret = object.ret | 0;
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeLottryResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @static
     * @param {client_proto.AccumulatedChargeLottryResp} message AccumulatedChargeLottryResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeLottryResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.subid = 0;
        object.rewardindex = 0;
        object.rewardid = 0;
        object.rewardtype = 0;
        object.rewardvalue = 0;
        object.lotterycount = 0;
        object.ret = 0;
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.rewardindex != null && message.hasOwnProperty("rewardindex"))
        object.rewardindex = message.rewardindex;
      if (message.rewardid != null && message.hasOwnProperty("rewardid")) object.rewardid = message.rewardid;
      if (message.rewardtype != null && message.hasOwnProperty("rewardtype")) object.rewardtype = message.rewardtype;
      if (message.rewardvalue != null && message.hasOwnProperty("rewardvalue"))
        object.rewardvalue = message.rewardvalue;
      if (message.lotterycount != null && message.hasOwnProperty("lotterycount"))
        object.lotterycount = message.lotterycount;
      if (message.ret != null && message.hasOwnProperty("ret")) object.ret = message.ret;
      return object;
    };

    /**
     * Converts this AccumulatedChargeLottryResp to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeLottryResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeLottryResp
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeLottryResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeLottryResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeLottryResp";
    };

    return AccumulatedChargeLottryResp;
  })();

  client_proto.AccumulatedSpinStatusPush = (function () {
    /**
     * Properties of an AccumulatedSpinStatusPush.
     * @memberof client_proto
     * @interface IAccumulatedSpinStatusPush
     * @property {number|null} [uid] AccumulatedSpinStatusPush uid
     * @property {number|null} [id] AccumulatedSpinStatusPush id
     * @property {number|null} [subid] AccumulatedSpinStatusPush subid
     * @property {number|null} [status] AccumulatedSpinStatusPush status
     */

    /**
     * Constructs a new AccumulatedSpinStatusPush.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedSpinStatusPush.
     * @implements IAccumulatedSpinStatusPush
     * @constructor
     * @param {client_proto.IAccumulatedSpinStatusPush=} [properties] Properties to set
     */
    function AccumulatedSpinStatusPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedSpinStatusPush uid.
     * @member {number} uid
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @instance
     */
    AccumulatedSpinStatusPush.prototype.uid = 0;

    /**
     * AccumulatedSpinStatusPush id.
     * @member {number} id
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @instance
     */
    AccumulatedSpinStatusPush.prototype.id = 0;

    /**
     * AccumulatedSpinStatusPush subid.
     * @member {number} subid
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @instance
     */
    AccumulatedSpinStatusPush.prototype.subid = 0;

    /**
     * AccumulatedSpinStatusPush status.
     * @member {number} status
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @instance
     */
    AccumulatedSpinStatusPush.prototype.status = 0;

    /**
     * Creates a new AccumulatedSpinStatusPush instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @static
     * @param {client_proto.IAccumulatedSpinStatusPush=} [properties] Properties to set
     * @returns {client_proto.AccumulatedSpinStatusPush} AccumulatedSpinStatusPush instance
     */
    AccumulatedSpinStatusPush.create = function create(properties) {
      return new AccumulatedSpinStatusPush(properties);
    };

    /**
     * Encodes the specified AccumulatedSpinStatusPush message. Does not implicitly {@link client_proto.AccumulatedSpinStatusPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @static
     * @param {client_proto.IAccumulatedSpinStatusPush} message AccumulatedSpinStatusPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedSpinStatusPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.subid);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.status);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedSpinStatusPush message, length delimited. Does not implicitly {@link client_proto.AccumulatedSpinStatusPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @static
     * @param {client_proto.IAccumulatedSpinStatusPush} message AccumulatedSpinStatusPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedSpinStatusPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedSpinStatusPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedSpinStatusPush} AccumulatedSpinStatusPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedSpinStatusPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedSpinStatusPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            message.subid = reader.int32();
            break;
          }
          case 4: {
            message.status = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedSpinStatusPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedSpinStatusPush} AccumulatedSpinStatusPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedSpinStatusPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedSpinStatusPush message.
     * @function verify
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedSpinStatusPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      return null;
    };

    /**
     * Creates an AccumulatedSpinStatusPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedSpinStatusPush} AccumulatedSpinStatusPush
     */
    AccumulatedSpinStatusPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedSpinStatusPush) return object;
      var message = new $root.client_proto.AccumulatedSpinStatusPush();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.status != null) message.status = object.status | 0;
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedSpinStatusPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @static
     * @param {client_proto.AccumulatedSpinStatusPush} message AccumulatedSpinStatusPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedSpinStatusPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
        object.subid = 0;
        object.status = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      return object;
    };

    /**
     * Converts this AccumulatedSpinStatusPush to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedSpinStatusPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedSpinStatusPush
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedSpinStatusPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedSpinStatusPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedSpinStatusPush";
    };

    return AccumulatedSpinStatusPush;
  })();

  client_proto.AccumulatedAnimationReq = (function () {
    /**
     * Properties of an AccumulatedAnimationReq.
     * @memberof client_proto
     * @interface IAccumulatedAnimationReq
     * @property {number|null} [uid] AccumulatedAnimationReq uid
     * @property {number|null} [id] AccumulatedAnimationReq id
     * @property {number|null} [subid] AccumulatedAnimationReq subid
     */

    /**
     * Constructs a new AccumulatedAnimationReq.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedAnimationReq.
     * @implements IAccumulatedAnimationReq
     * @constructor
     * @param {client_proto.IAccumulatedAnimationReq=} [properties] Properties to set
     */
    function AccumulatedAnimationReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedAnimationReq uid.
     * @member {number} uid
     * @memberof client_proto.AccumulatedAnimationReq
     * @instance
     */
    AccumulatedAnimationReq.prototype.uid = 0;

    /**
     * AccumulatedAnimationReq id.
     * @member {number} id
     * @memberof client_proto.AccumulatedAnimationReq
     * @instance
     */
    AccumulatedAnimationReq.prototype.id = 0;

    /**
     * AccumulatedAnimationReq subid.
     * @member {number} subid
     * @memberof client_proto.AccumulatedAnimationReq
     * @instance
     */
    AccumulatedAnimationReq.prototype.subid = 0;

    /**
     * Creates a new AccumulatedAnimationReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedAnimationReq
     * @static
     * @param {client_proto.IAccumulatedAnimationReq=} [properties] Properties to set
     * @returns {client_proto.AccumulatedAnimationReq} AccumulatedAnimationReq instance
     */
    AccumulatedAnimationReq.create = function create(properties) {
      return new AccumulatedAnimationReq(properties);
    };

    /**
     * Encodes the specified AccumulatedAnimationReq message. Does not implicitly {@link client_proto.AccumulatedAnimationReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedAnimationReq
     * @static
     * @param {client_proto.IAccumulatedAnimationReq} message AccumulatedAnimationReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedAnimationReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.subid);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedAnimationReq message, length delimited. Does not implicitly {@link client_proto.AccumulatedAnimationReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedAnimationReq
     * @static
     * @param {client_proto.IAccumulatedAnimationReq} message AccumulatedAnimationReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedAnimationReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedAnimationReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedAnimationReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedAnimationReq} AccumulatedAnimationReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedAnimationReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedAnimationReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            message.subid = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedAnimationReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedAnimationReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedAnimationReq} AccumulatedAnimationReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedAnimationReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedAnimationReq message.
     * @function verify
     * @memberof client_proto.AccumulatedAnimationReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedAnimationReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      return null;
    };

    /**
     * Creates an AccumulatedAnimationReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedAnimationReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedAnimationReq} AccumulatedAnimationReq
     */
    AccumulatedAnimationReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedAnimationReq) return object;
      var message = new $root.client_proto.AccumulatedAnimationReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedAnimationReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedAnimationReq
     * @static
     * @param {client_proto.AccumulatedAnimationReq} message AccumulatedAnimationReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedAnimationReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
        object.subid = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      return object;
    };

    /**
     * Converts this AccumulatedAnimationReq to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedAnimationReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedAnimationReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedAnimationReq
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedAnimationReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedAnimationReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedAnimationReq";
    };

    return AccumulatedAnimationReq;
  })();

  client_proto.AccumulatedChargeGetBroadcastMessageReq = (function () {
    /**
     * Properties of an AccumulatedChargeGetBroadcastMessageReq.
     * @memberof client_proto
     * @interface IAccumulatedChargeGetBroadcastMessageReq
     * @property {number|null} [activity_id] AccumulatedChargeGetBroadcastMessageReq activity_id
     * @property {Array.<number>|null} [id_array] AccumulatedChargeGetBroadcastMessageReq id_array
     */

    /**
     * Constructs a new AccumulatedChargeGetBroadcastMessageReq.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeGetBroadcastMessageReq.
     * @implements IAccumulatedChargeGetBroadcastMessageReq
     * @constructor
     * @param {client_proto.IAccumulatedChargeGetBroadcastMessageReq=} [properties] Properties to set
     */
    function AccumulatedChargeGetBroadcastMessageReq(properties) {
      this.id_array = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeGetBroadcastMessageReq activity_id.
     * @member {number} activity_id
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @instance
     */
    AccumulatedChargeGetBroadcastMessageReq.prototype.activity_id = 0;

    /**
     * AccumulatedChargeGetBroadcastMessageReq id_array.
     * @member {Array.<number>} id_array
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @instance
     */
    AccumulatedChargeGetBroadcastMessageReq.prototype.id_array = $util.emptyArray;

    /**
     * Creates a new AccumulatedChargeGetBroadcastMessageReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @static
     * @param {client_proto.IAccumulatedChargeGetBroadcastMessageReq=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeGetBroadcastMessageReq} AccumulatedChargeGetBroadcastMessageReq instance
     */
    AccumulatedChargeGetBroadcastMessageReq.create = function create(properties) {
      return new AccumulatedChargeGetBroadcastMessageReq(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeGetBroadcastMessageReq message. Does not implicitly {@link client_proto.AccumulatedChargeGetBroadcastMessageReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @static
     * @param {client_proto.IAccumulatedChargeGetBroadcastMessageReq} message AccumulatedChargeGetBroadcastMessageReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeGetBroadcastMessageReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.activity_id);
      if (message.id_array != null && message.id_array.length) {
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
        for (var i = 0; i < message.id_array.length; ++i) writer.int32(message.id_array[i]);
        writer.ldelim();
      }
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeGetBroadcastMessageReq message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeGetBroadcastMessageReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @static
     * @param {client_proto.IAccumulatedChargeGetBroadcastMessageReq} message AccumulatedChargeGetBroadcastMessageReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeGetBroadcastMessageReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeGetBroadcastMessageReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeGetBroadcastMessageReq} AccumulatedChargeGetBroadcastMessageReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeGetBroadcastMessageReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeGetBroadcastMessageReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.activity_id = reader.int32();
            break;
          }
          case 2: {
            if (!(message.id_array && message.id_array.length)) message.id_array = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.id_array.push(reader.int32());
            } else message.id_array.push(reader.int32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeGetBroadcastMessageReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeGetBroadcastMessageReq} AccumulatedChargeGetBroadcastMessageReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeGetBroadcastMessageReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeGetBroadcastMessageReq message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeGetBroadcastMessageReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.id_array != null && message.hasOwnProperty("id_array")) {
        if (!Array.isArray(message.id_array)) return "id_array: array expected";
        for (var i = 0; i < message.id_array.length; ++i)
          if (!$util.isInteger(message.id_array[i])) return "id_array: integer[] expected";
      }
      return null;
    };

    /**
     * Creates an AccumulatedChargeGetBroadcastMessageReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeGetBroadcastMessageReq} AccumulatedChargeGetBroadcastMessageReq
     */
    AccumulatedChargeGetBroadcastMessageReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeGetBroadcastMessageReq) return object;
      var message = new $root.client_proto.AccumulatedChargeGetBroadcastMessageReq();
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.id_array) {
        if (!Array.isArray(object.id_array))
          throw TypeError(".client_proto.AccumulatedChargeGetBroadcastMessageReq.id_array: array expected");
        message.id_array = [];
        for (var i = 0; i < object.id_array.length; ++i) message.id_array[i] = object.id_array[i] | 0;
      }
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeGetBroadcastMessageReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @static
     * @param {client_proto.AccumulatedChargeGetBroadcastMessageReq} message AccumulatedChargeGetBroadcastMessageReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeGetBroadcastMessageReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.id_array = [];
      if (options.defaults) object.activity_id = 0;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.id_array && message.id_array.length) {
        object.id_array = [];
        for (var j = 0; j < message.id_array.length; ++j) object.id_array[j] = message.id_array[j];
      }
      return object;
    };

    /**
     * Converts this AccumulatedChargeGetBroadcastMessageReq to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeGetBroadcastMessageReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeGetBroadcastMessageReq
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeGetBroadcastMessageReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeGetBroadcastMessageReq";
    };

    return AccumulatedChargeGetBroadcastMessageReq;
  })();

  client_proto.AccumulatedChargeMessage = (function () {
    /**
     * Properties of an AccumulatedChargeMessage.
     * @memberof client_proto
     * @interface IAccumulatedChargeMessage
     * @property {number|null} [id] AccumulatedChargeMessage id
     * @property {number|null} [spin_type] AccumulatedChargeMessage spin_type
     * @property {boolean|null} [is_max_reward] AccumulatedChargeMessage is_max_reward
     * @property {string|null} [msg] AccumulatedChargeMessage msg
     */

    /**
     * Constructs a new AccumulatedChargeMessage.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeMessage.
     * @implements IAccumulatedChargeMessage
     * @constructor
     * @param {client_proto.IAccumulatedChargeMessage=} [properties] Properties to set
     */
    function AccumulatedChargeMessage(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeMessage id.
     * @member {number} id
     * @memberof client_proto.AccumulatedChargeMessage
     * @instance
     */
    AccumulatedChargeMessage.prototype.id = 0;

    /**
     * AccumulatedChargeMessage spin_type.
     * @member {number} spin_type
     * @memberof client_proto.AccumulatedChargeMessage
     * @instance
     */
    AccumulatedChargeMessage.prototype.spin_type = 0;

    /**
     * AccumulatedChargeMessage is_max_reward.
     * @member {boolean} is_max_reward
     * @memberof client_proto.AccumulatedChargeMessage
     * @instance
     */
    AccumulatedChargeMessage.prototype.is_max_reward = false;

    /**
     * AccumulatedChargeMessage msg.
     * @member {string} msg
     * @memberof client_proto.AccumulatedChargeMessage
     * @instance
     */
    AccumulatedChargeMessage.prototype.msg = "";

    /**
     * Creates a new AccumulatedChargeMessage instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeMessage
     * @static
     * @param {client_proto.IAccumulatedChargeMessage=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeMessage} AccumulatedChargeMessage instance
     */
    AccumulatedChargeMessage.create = function create(properties) {
      return new AccumulatedChargeMessage(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeMessage message. Does not implicitly {@link client_proto.AccumulatedChargeMessage.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeMessage
     * @static
     * @param {client_proto.IAccumulatedChargeMessage} message AccumulatedChargeMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeMessage.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.spin_type != null && Object.hasOwnProperty.call(message, "spin_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.spin_type);
      if (message.is_max_reward != null && Object.hasOwnProperty.call(message, "is_max_reward"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).bool(message.is_max_reward);
      if (message.msg != null && Object.hasOwnProperty.call(message, "msg"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.msg);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeMessage message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeMessage.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeMessage
     * @static
     * @param {client_proto.IAccumulatedChargeMessage} message AccumulatedChargeMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeMessage.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeMessage message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeMessage} AccumulatedChargeMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeMessage.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeMessage();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.spin_type = reader.int32();
            break;
          }
          case 3: {
            message.is_max_reward = reader.bool();
            break;
          }
          case 4: {
            message.msg = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeMessage message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeMessage} AccumulatedChargeMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeMessage.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeMessage message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeMessage
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeMessage.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.spin_type != null && message.hasOwnProperty("spin_type"))
        if (!$util.isInteger(message.spin_type)) return "spin_type: integer expected";
      if (message.is_max_reward != null && message.hasOwnProperty("is_max_reward"))
        if (typeof message.is_max_reward !== "boolean") return "is_max_reward: boolean expected";
      if (message.msg != null && message.hasOwnProperty("msg"))
        if (!$util.isString(message.msg)) return "msg: string expected";
      return null;
    };

    /**
     * Creates an AccumulatedChargeMessage message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeMessage
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeMessage} AccumulatedChargeMessage
     */
    AccumulatedChargeMessage.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeMessage) return object;
      var message = new $root.client_proto.AccumulatedChargeMessage();
      if (object.id != null) message.id = object.id | 0;
      if (object.spin_type != null) message.spin_type = object.spin_type | 0;
      if (object.is_max_reward != null) message.is_max_reward = Boolean(object.is_max_reward);
      if (object.msg != null) message.msg = String(object.msg);
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeMessage message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeMessage
     * @static
     * @param {client_proto.AccumulatedChargeMessage} message AccumulatedChargeMessage
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeMessage.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.spin_type = 0;
        object.is_max_reward = false;
        object.msg = "";
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.spin_type != null && message.hasOwnProperty("spin_type")) object.spin_type = message.spin_type;
      if (message.is_max_reward != null && message.hasOwnProperty("is_max_reward"))
        object.is_max_reward = message.is_max_reward;
      if (message.msg != null && message.hasOwnProperty("msg")) object.msg = message.msg;
      return object;
    };

    /**
     * Converts this AccumulatedChargeMessage to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeMessage
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeMessage.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeMessage
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeMessage
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeMessage.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeMessage";
    };

    return AccumulatedChargeMessage;
  })();

  client_proto.AccumulatedChargeGetBroadcastMessageResp = (function () {
    /**
     * Properties of an AccumulatedChargeGetBroadcastMessageResp.
     * @memberof client_proto
     * @interface IAccumulatedChargeGetBroadcastMessageResp
     * @property {number|null} [activity_id] AccumulatedChargeGetBroadcastMessageResp activity_id
     * @property {Array.<number>|null} [id_array] AccumulatedChargeGetBroadcastMessageResp id_array
     * @property {Array.<client_proto.IAccumulatedChargeMessage>|null} [msg_array] AccumulatedChargeGetBroadcastMessageResp msg_array
     */

    /**
     * Constructs a new AccumulatedChargeGetBroadcastMessageResp.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeGetBroadcastMessageResp.
     * @implements IAccumulatedChargeGetBroadcastMessageResp
     * @constructor
     * @param {client_proto.IAccumulatedChargeGetBroadcastMessageResp=} [properties] Properties to set
     */
    function AccumulatedChargeGetBroadcastMessageResp(properties) {
      this.id_array = [];
      this.msg_array = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeGetBroadcastMessageResp activity_id.
     * @member {number} activity_id
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @instance
     */
    AccumulatedChargeGetBroadcastMessageResp.prototype.activity_id = 0;

    /**
     * AccumulatedChargeGetBroadcastMessageResp id_array.
     * @member {Array.<number>} id_array
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @instance
     */
    AccumulatedChargeGetBroadcastMessageResp.prototype.id_array = $util.emptyArray;

    /**
     * AccumulatedChargeGetBroadcastMessageResp msg_array.
     * @member {Array.<client_proto.IAccumulatedChargeMessage>} msg_array
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @instance
     */
    AccumulatedChargeGetBroadcastMessageResp.prototype.msg_array = $util.emptyArray;

    /**
     * Creates a new AccumulatedChargeGetBroadcastMessageResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @static
     * @param {client_proto.IAccumulatedChargeGetBroadcastMessageResp=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeGetBroadcastMessageResp} AccumulatedChargeGetBroadcastMessageResp instance
     */
    AccumulatedChargeGetBroadcastMessageResp.create = function create(properties) {
      return new AccumulatedChargeGetBroadcastMessageResp(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeGetBroadcastMessageResp message. Does not implicitly {@link client_proto.AccumulatedChargeGetBroadcastMessageResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @static
     * @param {client_proto.IAccumulatedChargeGetBroadcastMessageResp} message AccumulatedChargeGetBroadcastMessageResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeGetBroadcastMessageResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.activity_id);
      if (message.id_array != null && message.id_array.length) {
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
        for (var i = 0; i < message.id_array.length; ++i) writer.int32(message.id_array[i]);
        writer.ldelim();
      }
      if (message.msg_array != null && message.msg_array.length)
        for (var i = 0; i < message.msg_array.length; ++i)
          $root.client_proto.AccumulatedChargeMessage.encode(
            message.msg_array[i],
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeGetBroadcastMessageResp message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeGetBroadcastMessageResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @static
     * @param {client_proto.IAccumulatedChargeGetBroadcastMessageResp} message AccumulatedChargeGetBroadcastMessageResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeGetBroadcastMessageResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeGetBroadcastMessageResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeGetBroadcastMessageResp} AccumulatedChargeGetBroadcastMessageResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeGetBroadcastMessageResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeGetBroadcastMessageResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.activity_id = reader.int32();
            break;
          }
          case 2: {
            if (!(message.id_array && message.id_array.length)) message.id_array = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.id_array.push(reader.int32());
            } else message.id_array.push(reader.int32());
            break;
          }
          case 3: {
            if (!(message.msg_array && message.msg_array.length)) message.msg_array = [];
            message.msg_array.push($root.client_proto.AccumulatedChargeMessage.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeGetBroadcastMessageResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeGetBroadcastMessageResp} AccumulatedChargeGetBroadcastMessageResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeGetBroadcastMessageResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeGetBroadcastMessageResp message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeGetBroadcastMessageResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.id_array != null && message.hasOwnProperty("id_array")) {
        if (!Array.isArray(message.id_array)) return "id_array: array expected";
        for (var i = 0; i < message.id_array.length; ++i)
          if (!$util.isInteger(message.id_array[i])) return "id_array: integer[] expected";
      }
      if (message.msg_array != null && message.hasOwnProperty("msg_array")) {
        if (!Array.isArray(message.msg_array)) return "msg_array: array expected";
        for (var i = 0; i < message.msg_array.length; ++i) {
          var error = $root.client_proto.AccumulatedChargeMessage.verify(message.msg_array[i]);
          if (error) return "msg_array." + error;
        }
      }
      return null;
    };

    /**
     * Creates an AccumulatedChargeGetBroadcastMessageResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeGetBroadcastMessageResp} AccumulatedChargeGetBroadcastMessageResp
     */
    AccumulatedChargeGetBroadcastMessageResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeGetBroadcastMessageResp) return object;
      var message = new $root.client_proto.AccumulatedChargeGetBroadcastMessageResp();
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.id_array) {
        if (!Array.isArray(object.id_array))
          throw TypeError(".client_proto.AccumulatedChargeGetBroadcastMessageResp.id_array: array expected");
        message.id_array = [];
        for (var i = 0; i < object.id_array.length; ++i) message.id_array[i] = object.id_array[i] | 0;
      }
      if (object.msg_array) {
        if (!Array.isArray(object.msg_array))
          throw TypeError(".client_proto.AccumulatedChargeGetBroadcastMessageResp.msg_array: array expected");
        message.msg_array = [];
        for (var i = 0; i < object.msg_array.length; ++i) {
          if (typeof object.msg_array[i] !== "object")
            throw TypeError(".client_proto.AccumulatedChargeGetBroadcastMessageResp.msg_array: object expected");
          message.msg_array[i] = $root.client_proto.AccumulatedChargeMessage.fromObject(object.msg_array[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeGetBroadcastMessageResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @static
     * @param {client_proto.AccumulatedChargeGetBroadcastMessageResp} message AccumulatedChargeGetBroadcastMessageResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeGetBroadcastMessageResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) {
        object.id_array = [];
        object.msg_array = [];
      }
      if (options.defaults) object.activity_id = 0;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.id_array && message.id_array.length) {
        object.id_array = [];
        for (var j = 0; j < message.id_array.length; ++j) object.id_array[j] = message.id_array[j];
      }
      if (message.msg_array && message.msg_array.length) {
        object.msg_array = [];
        for (var j = 0; j < message.msg_array.length; ++j)
          object.msg_array[j] = $root.client_proto.AccumulatedChargeMessage.toObject(message.msg_array[j], options);
      }
      return object;
    };

    /**
     * Converts this AccumulatedChargeGetBroadcastMessageResp to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeGetBroadcastMessageResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeGetBroadcastMessageResp
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeGetBroadcastMessageResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeGetBroadcastMessageResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeGetBroadcastMessageResp";
    };

    return AccumulatedChargeGetBroadcastMessageResp;
  })();

  client_proto.AccumulatedChargeLottryDataReq = (function () {
    /**
     * Properties of an AccumulatedChargeLottryDataReq.
     * @memberof client_proto
     * @interface IAccumulatedChargeLottryDataReq
     * @property {number|null} [uid] AccumulatedChargeLottryDataReq uid
     * @property {number|null} [id] AccumulatedChargeLottryDataReq id
     */

    /**
     * Constructs a new AccumulatedChargeLottryDataReq.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeLottryDataReq.
     * @implements IAccumulatedChargeLottryDataReq
     * @constructor
     * @param {client_proto.IAccumulatedChargeLottryDataReq=} [properties] Properties to set
     */
    function AccumulatedChargeLottryDataReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeLottryDataReq uid.
     * @member {number} uid
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @instance
     */
    AccumulatedChargeLottryDataReq.prototype.uid = 0;

    /**
     * AccumulatedChargeLottryDataReq id.
     * @member {number} id
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @instance
     */
    AccumulatedChargeLottryDataReq.prototype.id = 0;

    /**
     * Creates a new AccumulatedChargeLottryDataReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @static
     * @param {client_proto.IAccumulatedChargeLottryDataReq=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeLottryDataReq} AccumulatedChargeLottryDataReq instance
     */
    AccumulatedChargeLottryDataReq.create = function create(properties) {
      return new AccumulatedChargeLottryDataReq(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeLottryDataReq message. Does not implicitly {@link client_proto.AccumulatedChargeLottryDataReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @static
     * @param {client_proto.IAccumulatedChargeLottryDataReq} message AccumulatedChargeLottryDataReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeLottryDataReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeLottryDataReq message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeLottryDataReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @static
     * @param {client_proto.IAccumulatedChargeLottryDataReq} message AccumulatedChargeLottryDataReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeLottryDataReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeLottryDataReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeLottryDataReq} AccumulatedChargeLottryDataReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeLottryDataReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeLottryDataReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeLottryDataReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeLottryDataReq} AccumulatedChargeLottryDataReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeLottryDataReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeLottryDataReq message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeLottryDataReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates an AccumulatedChargeLottryDataReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeLottryDataReq} AccumulatedChargeLottryDataReq
     */
    AccumulatedChargeLottryDataReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeLottryDataReq) return object;
      var message = new $root.client_proto.AccumulatedChargeLottryDataReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeLottryDataReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @static
     * @param {client_proto.AccumulatedChargeLottryDataReq} message AccumulatedChargeLottryDataReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeLottryDataReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this AccumulatedChargeLottryDataReq to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeLottryDataReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeLottryDataReq
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeLottryDataReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeLottryDataReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeLottryDataReq";
    };

    return AccumulatedChargeLottryDataReq;
  })();

  client_proto.AccumulatedChargeLottryData = (function () {
    /**
     * Properties of an AccumulatedChargeLottryData.
     * @memberof client_proto
     * @interface IAccumulatedChargeLottryData
     * @property {number|null} [subid] AccumulatedChargeLottryData subid
     * @property {number|null} [spin_type] AccumulatedChargeLottryData spin_type
     * @property {string|null} [stricon] AccumulatedChargeLottryData stricon
     * @property {number|null} [rewardtype] AccumulatedChargeLottryData rewardtype
     * @property {number|null} [rewardvalue] AccumulatedChargeLottryData rewardvalue
     * @property {number|Long|null} [time] AccumulatedChargeLottryData time
     */

    /**
     * Constructs a new AccumulatedChargeLottryData.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeLottryData.
     * @implements IAccumulatedChargeLottryData
     * @constructor
     * @param {client_proto.IAccumulatedChargeLottryData=} [properties] Properties to set
     */
    function AccumulatedChargeLottryData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeLottryData subid.
     * @member {number} subid
     * @memberof client_proto.AccumulatedChargeLottryData
     * @instance
     */
    AccumulatedChargeLottryData.prototype.subid = 0;

    /**
     * AccumulatedChargeLottryData spin_type.
     * @member {number} spin_type
     * @memberof client_proto.AccumulatedChargeLottryData
     * @instance
     */
    AccumulatedChargeLottryData.prototype.spin_type = 0;

    /**
     * AccumulatedChargeLottryData stricon.
     * @member {string} stricon
     * @memberof client_proto.AccumulatedChargeLottryData
     * @instance
     */
    AccumulatedChargeLottryData.prototype.stricon = "";

    /**
     * AccumulatedChargeLottryData rewardtype.
     * @member {number} rewardtype
     * @memberof client_proto.AccumulatedChargeLottryData
     * @instance
     */
    AccumulatedChargeLottryData.prototype.rewardtype = 0;

    /**
     * AccumulatedChargeLottryData rewardvalue.
     * @member {number} rewardvalue
     * @memberof client_proto.AccumulatedChargeLottryData
     * @instance
     */
    AccumulatedChargeLottryData.prototype.rewardvalue = 0;

    /**
     * AccumulatedChargeLottryData time.
     * @member {number|Long} time
     * @memberof client_proto.AccumulatedChargeLottryData
     * @instance
     */
    AccumulatedChargeLottryData.prototype.time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new AccumulatedChargeLottryData instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeLottryData
     * @static
     * @param {client_proto.IAccumulatedChargeLottryData=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeLottryData} AccumulatedChargeLottryData instance
     */
    AccumulatedChargeLottryData.create = function create(properties) {
      return new AccumulatedChargeLottryData(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeLottryData message. Does not implicitly {@link client_proto.AccumulatedChargeLottryData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeLottryData
     * @static
     * @param {client_proto.IAccumulatedChargeLottryData} message AccumulatedChargeLottryData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeLottryData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.subid);
      if (message.spin_type != null && Object.hasOwnProperty.call(message, "spin_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.spin_type);
      if (message.stricon != null && Object.hasOwnProperty.call(message, "stricon"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.stricon);
      if (message.rewardtype != null && Object.hasOwnProperty.call(message, "rewardtype"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.rewardtype);
      if (message.rewardvalue != null && Object.hasOwnProperty.call(message, "rewardvalue"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.rewardvalue);
      if (message.time != null && Object.hasOwnProperty.call(message, "time"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.time);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeLottryData message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeLottryData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeLottryData
     * @static
     * @param {client_proto.IAccumulatedChargeLottryData} message AccumulatedChargeLottryData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeLottryData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeLottryData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeLottryData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeLottryData} AccumulatedChargeLottryData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeLottryData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeLottryData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.subid = reader.int32();
            break;
          }
          case 2: {
            message.spin_type = reader.int32();
            break;
          }
          case 3: {
            message.stricon = reader.string();
            break;
          }
          case 4: {
            message.rewardtype = reader.int32();
            break;
          }
          case 5: {
            message.rewardvalue = reader.int32();
            break;
          }
          case 6: {
            message.time = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeLottryData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeLottryData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeLottryData} AccumulatedChargeLottryData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeLottryData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeLottryData message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeLottryData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeLottryData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.spin_type != null && message.hasOwnProperty("spin_type"))
        if (!$util.isInteger(message.spin_type)) return "spin_type: integer expected";
      if (message.stricon != null && message.hasOwnProperty("stricon"))
        if (!$util.isString(message.stricon)) return "stricon: string expected";
      if (message.rewardtype != null && message.hasOwnProperty("rewardtype"))
        if (!$util.isInteger(message.rewardtype)) return "rewardtype: integer expected";
      if (message.rewardvalue != null && message.hasOwnProperty("rewardvalue"))
        if (!$util.isInteger(message.rewardvalue)) return "rewardvalue: integer expected";
      if (message.time != null && message.hasOwnProperty("time"))
        if (
          !$util.isInteger(message.time) &&
          !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high))
        )
          return "time: integer|Long expected";
      return null;
    };

    /**
     * Creates an AccumulatedChargeLottryData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeLottryData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeLottryData} AccumulatedChargeLottryData
     */
    AccumulatedChargeLottryData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeLottryData) return object;
      var message = new $root.client_proto.AccumulatedChargeLottryData();
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.spin_type != null) message.spin_type = object.spin_type | 0;
      if (object.stricon != null) message.stricon = String(object.stricon);
      if (object.rewardtype != null) message.rewardtype = object.rewardtype | 0;
      if (object.rewardvalue != null) message.rewardvalue = object.rewardvalue | 0;
      if (object.time != null)
        if ($util.Long) (message.time = $util.Long.fromValue(object.time)).unsigned = false;
        else if (typeof object.time === "string") message.time = parseInt(object.time, 10);
        else if (typeof object.time === "number") message.time = object.time;
        else if (typeof object.time === "object")
          message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeLottryData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeLottryData
     * @static
     * @param {client_proto.AccumulatedChargeLottryData} message AccumulatedChargeLottryData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeLottryData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.subid = 0;
        object.spin_type = 0;
        object.stricon = "";
        object.rewardtype = 0;
        object.rewardvalue = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.time = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.time = options.longs === String ? "0" : 0;
      }
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.spin_type != null && message.hasOwnProperty("spin_type")) object.spin_type = message.spin_type;
      if (message.stricon != null && message.hasOwnProperty("stricon")) object.stricon = message.stricon;
      if (message.rewardtype != null && message.hasOwnProperty("rewardtype")) object.rewardtype = message.rewardtype;
      if (message.rewardvalue != null && message.hasOwnProperty("rewardvalue"))
        object.rewardvalue = message.rewardvalue;
      if (message.time != null && message.hasOwnProperty("time"))
        if (typeof message.time === "number")
          object.time = options.longs === String ? String(message.time) : message.time;
        else
          object.time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.time)
              : options.longs === Number
                ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber()
                : message.time;
      return object;
    };

    /**
     * Converts this AccumulatedChargeLottryData to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeLottryData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeLottryData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeLottryData
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeLottryData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeLottryData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeLottryData";
    };

    return AccumulatedChargeLottryData;
  })();

  client_proto.AccumulatedChargeLottryHistoryResp = (function () {
    /**
     * Properties of an AccumulatedChargeLottryHistoryResp.
     * @memberof client_proto
     * @interface IAccumulatedChargeLottryHistoryResp
     * @property {number|null} [id] AccumulatedChargeLottryHistoryResp id
     * @property {number|Long|null} [totalrecharge] AccumulatedChargeLottryHistoryResp totalrecharge
     * @property {Array.<client_proto.IAccumulatedChargeLottryData>|null} [lottorylist] AccumulatedChargeLottryHistoryResp lottorylist
     * @property {number|null} [ret] AccumulatedChargeLottryHistoryResp ret
     */

    /**
     * Constructs a new AccumulatedChargeLottryHistoryResp.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeLottryHistoryResp.
     * @implements IAccumulatedChargeLottryHistoryResp
     * @constructor
     * @param {client_proto.IAccumulatedChargeLottryHistoryResp=} [properties] Properties to set
     */
    function AccumulatedChargeLottryHistoryResp(properties) {
      this.lottorylist = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeLottryHistoryResp id.
     * @member {number} id
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @instance
     */
    AccumulatedChargeLottryHistoryResp.prototype.id = 0;

    /**
     * AccumulatedChargeLottryHistoryResp totalrecharge.
     * @member {number|Long} totalrecharge
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @instance
     */
    AccumulatedChargeLottryHistoryResp.prototype.totalrecharge = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AccumulatedChargeLottryHistoryResp lottorylist.
     * @member {Array.<client_proto.IAccumulatedChargeLottryData>} lottorylist
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @instance
     */
    AccumulatedChargeLottryHistoryResp.prototype.lottorylist = $util.emptyArray;

    /**
     * AccumulatedChargeLottryHistoryResp ret.
     * @member {number} ret
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @instance
     */
    AccumulatedChargeLottryHistoryResp.prototype.ret = 0;

    /**
     * Creates a new AccumulatedChargeLottryHistoryResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @static
     * @param {client_proto.IAccumulatedChargeLottryHistoryResp=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeLottryHistoryResp} AccumulatedChargeLottryHistoryResp instance
     */
    AccumulatedChargeLottryHistoryResp.create = function create(properties) {
      return new AccumulatedChargeLottryHistoryResp(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeLottryHistoryResp message. Does not implicitly {@link client_proto.AccumulatedChargeLottryHistoryResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @static
     * @param {client_proto.IAccumulatedChargeLottryHistoryResp} message AccumulatedChargeLottryHistoryResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeLottryHistoryResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.totalrecharge != null && Object.hasOwnProperty.call(message, "totalrecharge"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.totalrecharge);
      if (message.lottorylist != null && message.lottorylist.length)
        for (var i = 0; i < message.lottorylist.length; ++i)
          $root.client_proto.AccumulatedChargeLottryData.encode(
            message.lottorylist[i],
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim();
      if (message.ret != null && Object.hasOwnProperty.call(message, "ret"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.ret);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeLottryHistoryResp message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeLottryHistoryResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @static
     * @param {client_proto.IAccumulatedChargeLottryHistoryResp} message AccumulatedChargeLottryHistoryResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeLottryHistoryResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeLottryHistoryResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeLottryHistoryResp} AccumulatedChargeLottryHistoryResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeLottryHistoryResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeLottryHistoryResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.totalrecharge = reader.int64();
            break;
          }
          case 3: {
            if (!(message.lottorylist && message.lottorylist.length)) message.lottorylist = [];
            message.lottorylist.push($root.client_proto.AccumulatedChargeLottryData.decode(reader, reader.uint32()));
            break;
          }
          case 4: {
            message.ret = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeLottryHistoryResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeLottryHistoryResp} AccumulatedChargeLottryHistoryResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeLottryHistoryResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeLottryHistoryResp message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeLottryHistoryResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.totalrecharge != null && message.hasOwnProperty("totalrecharge"))
        if (
          !$util.isInteger(message.totalrecharge) &&
          !(
            message.totalrecharge &&
            $util.isInteger(message.totalrecharge.low) &&
            $util.isInteger(message.totalrecharge.high)
          )
        )
          return "totalrecharge: integer|Long expected";
      if (message.lottorylist != null && message.hasOwnProperty("lottorylist")) {
        if (!Array.isArray(message.lottorylist)) return "lottorylist: array expected";
        for (var i = 0; i < message.lottorylist.length; ++i) {
          var error = $root.client_proto.AccumulatedChargeLottryData.verify(message.lottorylist[i]);
          if (error) return "lottorylist." + error;
        }
      }
      if (message.ret != null && message.hasOwnProperty("ret"))
        if (!$util.isInteger(message.ret)) return "ret: integer expected";
      return null;
    };

    /**
     * Creates an AccumulatedChargeLottryHistoryResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeLottryHistoryResp} AccumulatedChargeLottryHistoryResp
     */
    AccumulatedChargeLottryHistoryResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeLottryHistoryResp) return object;
      var message = new $root.client_proto.AccumulatedChargeLottryHistoryResp();
      if (object.id != null) message.id = object.id | 0;
      if (object.totalrecharge != null)
        if ($util.Long) (message.totalrecharge = $util.Long.fromValue(object.totalrecharge)).unsigned = false;
        else if (typeof object.totalrecharge === "string") message.totalrecharge = parseInt(object.totalrecharge, 10);
        else if (typeof object.totalrecharge === "number") message.totalrecharge = object.totalrecharge;
        else if (typeof object.totalrecharge === "object")
          message.totalrecharge = new $util.LongBits(
            object.totalrecharge.low >>> 0,
            object.totalrecharge.high >>> 0,
          ).toNumber();
      if (object.lottorylist) {
        if (!Array.isArray(object.lottorylist))
          throw TypeError(".client_proto.AccumulatedChargeLottryHistoryResp.lottorylist: array expected");
        message.lottorylist = [];
        for (var i = 0; i < object.lottorylist.length; ++i) {
          if (typeof object.lottorylist[i] !== "object")
            throw TypeError(".client_proto.AccumulatedChargeLottryHistoryResp.lottorylist: object expected");
          message.lottorylist[i] = $root.client_proto.AccumulatedChargeLottryData.fromObject(object.lottorylist[i]);
        }
      }
      if (object.ret != null) message.ret = object.ret | 0;
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeLottryHistoryResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @static
     * @param {client_proto.AccumulatedChargeLottryHistoryResp} message AccumulatedChargeLottryHistoryResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeLottryHistoryResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.lottorylist = [];
      if (options.defaults) {
        object.id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.totalrecharge =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.totalrecharge = options.longs === String ? "0" : 0;
        object.ret = 0;
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.totalrecharge != null && message.hasOwnProperty("totalrecharge"))
        if (typeof message.totalrecharge === "number")
          object.totalrecharge = options.longs === String ? String(message.totalrecharge) : message.totalrecharge;
        else
          object.totalrecharge =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.totalrecharge)
              : options.longs === Number
                ? new $util.LongBits(message.totalrecharge.low >>> 0, message.totalrecharge.high >>> 0).toNumber()
                : message.totalrecharge;
      if (message.lottorylist && message.lottorylist.length) {
        object.lottorylist = [];
        for (var j = 0; j < message.lottorylist.length; ++j)
          object.lottorylist[j] = $root.client_proto.AccumulatedChargeLottryData.toObject(
            message.lottorylist[j],
            options,
          );
      }
      if (message.ret != null && message.hasOwnProperty("ret")) object.ret = message.ret;
      return object;
    };

    /**
     * Converts this AccumulatedChargeLottryHistoryResp to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeLottryHistoryResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeLottryHistoryResp
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeLottryHistoryResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeLottryHistoryResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeLottryHistoryResp";
    };

    return AccumulatedChargeLottryHistoryResp;
  })();

  client_proto.SpinInfo = (function () {
    /**
     * Properties of a SpinInfo.
     * @memberof client_proto
     * @interface ISpinInfo
     * @property {number|null} [subid] SpinInfo subid
     * @property {number|null} [spintype] SpinInfo spintype
     * @property {number|null} [lotterycount] SpinInfo lotterycount
     * @property {number|null} [spincount] SpinInfo spincount
     * @property {number|null} [status] SpinInfo status
     * @property {number|Long|null} [totalrecharge] SpinInfo totalrecharge
     */

    /**
     * Constructs a new SpinInfo.
     * @memberof client_proto
     * @classdesc Represents a SpinInfo.
     * @implements ISpinInfo
     * @constructor
     * @param {client_proto.ISpinInfo=} [properties] Properties to set
     */
    function SpinInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SpinInfo subid.
     * @member {number} subid
     * @memberof client_proto.SpinInfo
     * @instance
     */
    SpinInfo.prototype.subid = 0;

    /**
     * SpinInfo spintype.
     * @member {number} spintype
     * @memberof client_proto.SpinInfo
     * @instance
     */
    SpinInfo.prototype.spintype = 0;

    /**
     * SpinInfo lotterycount.
     * @member {number} lotterycount
     * @memberof client_proto.SpinInfo
     * @instance
     */
    SpinInfo.prototype.lotterycount = 0;

    /**
     * SpinInfo spincount.
     * @member {number} spincount
     * @memberof client_proto.SpinInfo
     * @instance
     */
    SpinInfo.prototype.spincount = 0;

    /**
     * SpinInfo status.
     * @member {number} status
     * @memberof client_proto.SpinInfo
     * @instance
     */
    SpinInfo.prototype.status = 0;

    /**
     * SpinInfo totalrecharge.
     * @member {number|Long} totalrecharge
     * @memberof client_proto.SpinInfo
     * @instance
     */
    SpinInfo.prototype.totalrecharge = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new SpinInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.SpinInfo
     * @static
     * @param {client_proto.ISpinInfo=} [properties] Properties to set
     * @returns {client_proto.SpinInfo} SpinInfo instance
     */
    SpinInfo.create = function create(properties) {
      return new SpinInfo(properties);
    };

    /**
     * Encodes the specified SpinInfo message. Does not implicitly {@link client_proto.SpinInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SpinInfo
     * @static
     * @param {client_proto.ISpinInfo} message SpinInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.subid);
      if (message.spintype != null && Object.hasOwnProperty.call(message, "spintype"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.spintype);
      if (message.lotterycount != null && Object.hasOwnProperty.call(message, "lotterycount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.lotterycount);
      if (message.spincount != null && Object.hasOwnProperty.call(message, "spincount"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.spincount);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.status);
      if (message.totalrecharge != null && Object.hasOwnProperty.call(message, "totalrecharge"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.totalrecharge);
      return writer;
    };

    /**
     * Encodes the specified SpinInfo message, length delimited. Does not implicitly {@link client_proto.SpinInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SpinInfo
     * @static
     * @param {client_proto.ISpinInfo} message SpinInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SpinInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SpinInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SpinInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SpinInfo} SpinInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SpinInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.subid = reader.int32();
            break;
          }
          case 2: {
            message.spintype = reader.int32();
            break;
          }
          case 3: {
            message.lotterycount = reader.int32();
            break;
          }
          case 4: {
            message.spincount = reader.int32();
            break;
          }
          case 5: {
            message.status = reader.int32();
            break;
          }
          case 6: {
            message.totalrecharge = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SpinInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SpinInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SpinInfo} SpinInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SpinInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SpinInfo message.
     * @function verify
     * @memberof client_proto.SpinInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SpinInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.spintype != null && message.hasOwnProperty("spintype"))
        if (!$util.isInteger(message.spintype)) return "spintype: integer expected";
      if (message.lotterycount != null && message.hasOwnProperty("lotterycount"))
        if (!$util.isInteger(message.lotterycount)) return "lotterycount: integer expected";
      if (message.spincount != null && message.hasOwnProperty("spincount"))
        if (!$util.isInteger(message.spincount)) return "spincount: integer expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      if (message.totalrecharge != null && message.hasOwnProperty("totalrecharge"))
        if (
          !$util.isInteger(message.totalrecharge) &&
          !(
            message.totalrecharge &&
            $util.isInteger(message.totalrecharge.low) &&
            $util.isInteger(message.totalrecharge.high)
          )
        )
          return "totalrecharge: integer|Long expected";
      return null;
    };

    /**
     * Creates a SpinInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SpinInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SpinInfo} SpinInfo
     */
    SpinInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SpinInfo) return object;
      var message = new $root.client_proto.SpinInfo();
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.spintype != null) message.spintype = object.spintype | 0;
      if (object.lotterycount != null) message.lotterycount = object.lotterycount | 0;
      if (object.spincount != null) message.spincount = object.spincount | 0;
      if (object.status != null) message.status = object.status | 0;
      if (object.totalrecharge != null)
        if ($util.Long) (message.totalrecharge = $util.Long.fromValue(object.totalrecharge)).unsigned = false;
        else if (typeof object.totalrecharge === "string") message.totalrecharge = parseInt(object.totalrecharge, 10);
        else if (typeof object.totalrecharge === "number") message.totalrecharge = object.totalrecharge;
        else if (typeof object.totalrecharge === "object")
          message.totalrecharge = new $util.LongBits(
            object.totalrecharge.low >>> 0,
            object.totalrecharge.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a SpinInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SpinInfo
     * @static
     * @param {client_proto.SpinInfo} message SpinInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SpinInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.subid = 0;
        object.spintype = 0;
        object.lotterycount = 0;
        object.spincount = 0;
        object.status = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.totalrecharge =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.totalrecharge = options.longs === String ? "0" : 0;
      }
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.spintype != null && message.hasOwnProperty("spintype")) object.spintype = message.spintype;
      if (message.lotterycount != null && message.hasOwnProperty("lotterycount"))
        object.lotterycount = message.lotterycount;
      if (message.spincount != null && message.hasOwnProperty("spincount")) object.spincount = message.spincount;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      if (message.totalrecharge != null && message.hasOwnProperty("totalrecharge"))
        if (typeof message.totalrecharge === "number")
          object.totalrecharge = options.longs === String ? String(message.totalrecharge) : message.totalrecharge;
        else
          object.totalrecharge =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.totalrecharge)
              : options.longs === Number
                ? new $util.LongBits(message.totalrecharge.low >>> 0, message.totalrecharge.high >>> 0).toNumber()
                : message.totalrecharge;
      return object;
    };

    /**
     * Converts this SpinInfo to JSON.
     * @function toJSON
     * @memberof client_proto.SpinInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SpinInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SpinInfo
     * @function getTypeUrl
     * @memberof client_proto.SpinInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SpinInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SpinInfo";
    };

    return SpinInfo;
  })();

  client_proto.AccumulatedChargeNotifyStatistic = (function () {
    /**
     * Properties of an AccumulatedChargeNotifyStatistic.
     * @memberof client_proto
     * @interface IAccumulatedChargeNotifyStatistic
     * @property {number|null} [uid] AccumulatedChargeNotifyStatistic uid
     * @property {string|null} [channel] AccumulatedChargeNotifyStatistic channel
     * @property {number|null} [activity_id] AccumulatedChargeNotifyStatistic activity_id
     * @property {number|null} [spin_id] AccumulatedChargeNotifyStatistic spin_id
     * @property {number|null} [spin_type] AccumulatedChargeNotifyStatistic spin_type
     * @property {number|null} [event] AccumulatedChargeNotifyStatistic event
     * @property {number|null} [reward_type] AccumulatedChargeNotifyStatistic reward_type
     * @property {number|Long|null} [amount] AccumulatedChargeNotifyStatistic amount
     * @property {number|Long|null} [need_flow] AccumulatedChargeNotifyStatistic need_flow
     * @property {number|null} [is_first] AccumulatedChargeNotifyStatistic is_first
     * @property {number|Long|null} [create_time] AccumulatedChargeNotifyStatistic create_time
     * @property {string|null} [stricon] AccumulatedChargeNotifyStatistic stricon
     */

    /**
     * Constructs a new AccumulatedChargeNotifyStatistic.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeNotifyStatistic.
     * @implements IAccumulatedChargeNotifyStatistic
     * @constructor
     * @param {client_proto.IAccumulatedChargeNotifyStatistic=} [properties] Properties to set
     */
    function AccumulatedChargeNotifyStatistic(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeNotifyStatistic uid.
     * @member {number} uid
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.uid = 0;

    /**
     * AccumulatedChargeNotifyStatistic channel.
     * @member {string} channel
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.channel = "";

    /**
     * AccumulatedChargeNotifyStatistic activity_id.
     * @member {number} activity_id
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.activity_id = 0;

    /**
     * AccumulatedChargeNotifyStatistic spin_id.
     * @member {number} spin_id
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.spin_id = 0;

    /**
     * AccumulatedChargeNotifyStatistic spin_type.
     * @member {number} spin_type
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.spin_type = 0;

    /**
     * AccumulatedChargeNotifyStatistic event.
     * @member {number} event
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.event = 0;

    /**
     * AccumulatedChargeNotifyStatistic reward_type.
     * @member {number} reward_type
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.reward_type = 0;

    /**
     * AccumulatedChargeNotifyStatistic amount.
     * @member {number|Long} amount
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AccumulatedChargeNotifyStatistic need_flow.
     * @member {number|Long} need_flow
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.need_flow = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AccumulatedChargeNotifyStatistic is_first.
     * @member {number} is_first
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.is_first = 0;

    /**
     * AccumulatedChargeNotifyStatistic create_time.
     * @member {number|Long} create_time
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.create_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AccumulatedChargeNotifyStatistic stricon.
     * @member {string} stricon
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     */
    AccumulatedChargeNotifyStatistic.prototype.stricon = "";

    /**
     * Creates a new AccumulatedChargeNotifyStatistic instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @static
     * @param {client_proto.IAccumulatedChargeNotifyStatistic=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeNotifyStatistic} AccumulatedChargeNotifyStatistic instance
     */
    AccumulatedChargeNotifyStatistic.create = function create(properties) {
      return new AccumulatedChargeNotifyStatistic(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeNotifyStatistic message. Does not implicitly {@link client_proto.AccumulatedChargeNotifyStatistic.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @static
     * @param {client_proto.IAccumulatedChargeNotifyStatistic} message AccumulatedChargeNotifyStatistic message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeNotifyStatistic.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.channel);
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.activity_id);
      if (message.spin_id != null && Object.hasOwnProperty.call(message, "spin_id"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.spin_id);
      if (message.spin_type != null && Object.hasOwnProperty.call(message, "spin_type"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.spin_type);
      if (message.event != null && Object.hasOwnProperty.call(message, "event"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.event);
      if (message.reward_type != null && Object.hasOwnProperty.call(message, "reward_type"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.reward_type);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.amount);
      if (message.need_flow != null && Object.hasOwnProperty.call(message, "need_flow"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.need_flow);
      if (message.is_first != null && Object.hasOwnProperty.call(message, "is_first"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int32(message.is_first);
      if (message.create_time != null && Object.hasOwnProperty.call(message, "create_time"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int64(message.create_time);
      if (message.stricon != null && Object.hasOwnProperty.call(message, "stricon"))
        writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.stricon);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeNotifyStatistic message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeNotifyStatistic.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @static
     * @param {client_proto.IAccumulatedChargeNotifyStatistic} message AccumulatedChargeNotifyStatistic message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeNotifyStatistic.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeNotifyStatistic message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeNotifyStatistic} AccumulatedChargeNotifyStatistic
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeNotifyStatistic.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeNotifyStatistic();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.channel = reader.string();
            break;
          }
          case 3: {
            message.activity_id = reader.int32();
            break;
          }
          case 4: {
            message.spin_id = reader.int32();
            break;
          }
          case 5: {
            message.spin_type = reader.int32();
            break;
          }
          case 6: {
            message.event = reader.int32();
            break;
          }
          case 7: {
            message.reward_type = reader.int32();
            break;
          }
          case 8: {
            message.amount = reader.int64();
            break;
          }
          case 9: {
            message.need_flow = reader.int64();
            break;
          }
          case 10: {
            message.is_first = reader.int32();
            break;
          }
          case 11: {
            message.create_time = reader.int64();
            break;
          }
          case 12: {
            message.stricon = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeNotifyStatistic message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeNotifyStatistic} AccumulatedChargeNotifyStatistic
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeNotifyStatistic.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeNotifyStatistic message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeNotifyStatistic.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.channel != null && message.hasOwnProperty("channel"))
        if (!$util.isString(message.channel)) return "channel: string expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.spin_id != null && message.hasOwnProperty("spin_id"))
        if (!$util.isInteger(message.spin_id)) return "spin_id: integer expected";
      if (message.spin_type != null && message.hasOwnProperty("spin_type"))
        if (!$util.isInteger(message.spin_type)) return "spin_type: integer expected";
      if (message.event != null && message.hasOwnProperty("event"))
        if (!$util.isInteger(message.event)) return "event: integer expected";
      if (message.reward_type != null && message.hasOwnProperty("reward_type"))
        if (!$util.isInteger(message.reward_type)) return "reward_type: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.need_flow != null && message.hasOwnProperty("need_flow"))
        if (
          !$util.isInteger(message.need_flow) &&
          !(message.need_flow && $util.isInteger(message.need_flow.low) && $util.isInteger(message.need_flow.high))
        )
          return "need_flow: integer|Long expected";
      if (message.is_first != null && message.hasOwnProperty("is_first"))
        if (!$util.isInteger(message.is_first)) return "is_first: integer expected";
      if (message.create_time != null && message.hasOwnProperty("create_time"))
        if (
          !$util.isInteger(message.create_time) &&
          !(
            message.create_time &&
            $util.isInteger(message.create_time.low) &&
            $util.isInteger(message.create_time.high)
          )
        )
          return "create_time: integer|Long expected";
      if (message.stricon != null && message.hasOwnProperty("stricon"))
        if (!$util.isString(message.stricon)) return "stricon: string expected";
      return null;
    };

    /**
     * Creates an AccumulatedChargeNotifyStatistic message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeNotifyStatistic} AccumulatedChargeNotifyStatistic
     */
    AccumulatedChargeNotifyStatistic.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeNotifyStatistic) return object;
      var message = new $root.client_proto.AccumulatedChargeNotifyStatistic();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.channel != null) message.channel = String(object.channel);
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.spin_id != null) message.spin_id = object.spin_id | 0;
      if (object.spin_type != null) message.spin_type = object.spin_type | 0;
      if (object.event != null) message.event = object.event | 0;
      if (object.reward_type != null) message.reward_type = object.reward_type | 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.need_flow != null)
        if ($util.Long) (message.need_flow = $util.Long.fromValue(object.need_flow)).unsigned = false;
        else if (typeof object.need_flow === "string") message.need_flow = parseInt(object.need_flow, 10);
        else if (typeof object.need_flow === "number") message.need_flow = object.need_flow;
        else if (typeof object.need_flow === "object")
          message.need_flow = new $util.LongBits(object.need_flow.low >>> 0, object.need_flow.high >>> 0).toNumber();
      if (object.is_first != null) message.is_first = object.is_first | 0;
      if (object.create_time != null)
        if ($util.Long) (message.create_time = $util.Long.fromValue(object.create_time)).unsigned = false;
        else if (typeof object.create_time === "string") message.create_time = parseInt(object.create_time, 10);
        else if (typeof object.create_time === "number") message.create_time = object.create_time;
        else if (typeof object.create_time === "object")
          message.create_time = new $util.LongBits(
            object.create_time.low >>> 0,
            object.create_time.high >>> 0,
          ).toNumber();
      if (object.stricon != null) message.stricon = String(object.stricon);
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeNotifyStatistic message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @static
     * @param {client_proto.AccumulatedChargeNotifyStatistic} message AccumulatedChargeNotifyStatistic
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeNotifyStatistic.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.channel = "";
        object.activity_id = 0;
        object.spin_id = 0;
        object.spin_type = 0;
        object.event = 0;
        object.reward_type = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.need_flow =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.need_flow = options.longs === String ? "0" : 0;
        object.is_first = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.create_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.create_time = options.longs === String ? "0" : 0;
        object.stricon = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.channel != null && message.hasOwnProperty("channel")) object.channel = message.channel;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.spin_id != null && message.hasOwnProperty("spin_id")) object.spin_id = message.spin_id;
      if (message.spin_type != null && message.hasOwnProperty("spin_type")) object.spin_type = message.spin_type;
      if (message.event != null && message.hasOwnProperty("event")) object.event = message.event;
      if (message.reward_type != null && message.hasOwnProperty("reward_type"))
        object.reward_type = message.reward_type;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.need_flow != null && message.hasOwnProperty("need_flow"))
        if (typeof message.need_flow === "number")
          object.need_flow = options.longs === String ? String(message.need_flow) : message.need_flow;
        else
          object.need_flow =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.need_flow)
              : options.longs === Number
                ? new $util.LongBits(message.need_flow.low >>> 0, message.need_flow.high >>> 0).toNumber()
                : message.need_flow;
      if (message.is_first != null && message.hasOwnProperty("is_first")) object.is_first = message.is_first;
      if (message.create_time != null && message.hasOwnProperty("create_time"))
        if (typeof message.create_time === "number")
          object.create_time = options.longs === String ? String(message.create_time) : message.create_time;
        else
          object.create_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.create_time)
              : options.longs === Number
                ? new $util.LongBits(message.create_time.low >>> 0, message.create_time.high >>> 0).toNumber()
                : message.create_time;
      if (message.stricon != null && message.hasOwnProperty("stricon")) object.stricon = message.stricon;
      return object;
    };

    /**
     * Converts this AccumulatedChargeNotifyStatistic to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeNotifyStatistic.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeNotifyStatistic
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeNotifyStatistic
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeNotifyStatistic.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeNotifyStatistic";
    };

    return AccumulatedChargeNotifyStatistic;
  })();

  client_proto.AccumulatedChargeChange = (function () {
    /**
     * Properties of an AccumulatedChargeChange.
     * @memberof client_proto
     * @interface IAccumulatedChargeChange
     * @property {number|null} [uid] AccumulatedChargeChange uid
     * @property {number|null} [activity_id] AccumulatedChargeChange activity_id
     * @property {number|null} [status] AccumulatedChargeChange status
     */

    /**
     * Constructs a new AccumulatedChargeChange.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeChange.
     * @implements IAccumulatedChargeChange
     * @constructor
     * @param {client_proto.IAccumulatedChargeChange=} [properties] Properties to set
     */
    function AccumulatedChargeChange(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeChange uid.
     * @member {number} uid
     * @memberof client_proto.AccumulatedChargeChange
     * @instance
     */
    AccumulatedChargeChange.prototype.uid = 0;

    /**
     * AccumulatedChargeChange activity_id.
     * @member {number} activity_id
     * @memberof client_proto.AccumulatedChargeChange
     * @instance
     */
    AccumulatedChargeChange.prototype.activity_id = 0;

    /**
     * AccumulatedChargeChange status.
     * @member {number} status
     * @memberof client_proto.AccumulatedChargeChange
     * @instance
     */
    AccumulatedChargeChange.prototype.status = 0;

    /**
     * Creates a new AccumulatedChargeChange instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeChange
     * @static
     * @param {client_proto.IAccumulatedChargeChange=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeChange} AccumulatedChargeChange instance
     */
    AccumulatedChargeChange.create = function create(properties) {
      return new AccumulatedChargeChange(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeChange message. Does not implicitly {@link client_proto.AccumulatedChargeChange.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeChange
     * @static
     * @param {client_proto.IAccumulatedChargeChange} message AccumulatedChargeChange message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeChange.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.activity_id);
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.status);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeChange message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeChange.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeChange
     * @static
     * @param {client_proto.IAccumulatedChargeChange} message AccumulatedChargeChange message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeChange.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeChange message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeChange
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeChange} AccumulatedChargeChange
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeChange.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeChange();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.activity_id = reader.int32();
            break;
          }
          case 3: {
            message.status = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeChange message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeChange
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeChange} AccumulatedChargeChange
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeChange.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeChange message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeChange
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeChange.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      return null;
    };

    /**
     * Creates an AccumulatedChargeChange message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeChange
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeChange} AccumulatedChargeChange
     */
    AccumulatedChargeChange.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeChange) return object;
      var message = new $root.client_proto.AccumulatedChargeChange();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.status != null) message.status = object.status >>> 0;
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeChange message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeChange
     * @static
     * @param {client_proto.AccumulatedChargeChange} message AccumulatedChargeChange
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeChange.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.activity_id = 0;
        object.status = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      return object;
    };

    /**
     * Converts this AccumulatedChargeChange to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeChange
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeChange.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeChange
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeChange
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeChange.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeChange";
    };

    return AccumulatedChargeChange;
  })();

  client_proto.AccumulatedChargeSpinInfo = (function () {
    /**
     * Properties of an AccumulatedChargeSpinInfo.
     * @memberof client_proto
     * @interface IAccumulatedChargeSpinInfo
     * @property {number|null} [uid] AccumulatedChargeSpinInfo uid
     * @property {number|null} [activity_id] AccumulatedChargeSpinInfo activity_id
     * @property {Array.<client_proto.ISpinInfo>|null} [spinlist] AccumulatedChargeSpinInfo spinlist
     */

    /**
     * Constructs a new AccumulatedChargeSpinInfo.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeSpinInfo.
     * @implements IAccumulatedChargeSpinInfo
     * @constructor
     * @param {client_proto.IAccumulatedChargeSpinInfo=} [properties] Properties to set
     */
    function AccumulatedChargeSpinInfo(properties) {
      this.spinlist = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeSpinInfo uid.
     * @member {number} uid
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @instance
     */
    AccumulatedChargeSpinInfo.prototype.uid = 0;

    /**
     * AccumulatedChargeSpinInfo activity_id.
     * @member {number} activity_id
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @instance
     */
    AccumulatedChargeSpinInfo.prototype.activity_id = 0;

    /**
     * AccumulatedChargeSpinInfo spinlist.
     * @member {Array.<client_proto.ISpinInfo>} spinlist
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @instance
     */
    AccumulatedChargeSpinInfo.prototype.spinlist = $util.emptyArray;

    /**
     * Creates a new AccumulatedChargeSpinInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @static
     * @param {client_proto.IAccumulatedChargeSpinInfo=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeSpinInfo} AccumulatedChargeSpinInfo instance
     */
    AccumulatedChargeSpinInfo.create = function create(properties) {
      return new AccumulatedChargeSpinInfo(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeSpinInfo message. Does not implicitly {@link client_proto.AccumulatedChargeSpinInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @static
     * @param {client_proto.IAccumulatedChargeSpinInfo} message AccumulatedChargeSpinInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeSpinInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.activity_id);
      if (message.spinlist != null && message.spinlist.length)
        for (var i = 0; i < message.spinlist.length; ++i)
          $root.client_proto.SpinInfo.encode(
            message.spinlist[i],
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeSpinInfo message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeSpinInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @static
     * @param {client_proto.IAccumulatedChargeSpinInfo} message AccumulatedChargeSpinInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeSpinInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeSpinInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeSpinInfo} AccumulatedChargeSpinInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeSpinInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeSpinInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.activity_id = reader.int32();
            break;
          }
          case 3: {
            if (!(message.spinlist && message.spinlist.length)) message.spinlist = [];
            message.spinlist.push($root.client_proto.SpinInfo.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeSpinInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeSpinInfo} AccumulatedChargeSpinInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeSpinInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeSpinInfo message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeSpinInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.spinlist != null && message.hasOwnProperty("spinlist")) {
        if (!Array.isArray(message.spinlist)) return "spinlist: array expected";
        for (var i = 0; i < message.spinlist.length; ++i) {
          var error = $root.client_proto.SpinInfo.verify(message.spinlist[i]);
          if (error) return "spinlist." + error;
        }
      }
      return null;
    };

    /**
     * Creates an AccumulatedChargeSpinInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeSpinInfo} AccumulatedChargeSpinInfo
     */
    AccumulatedChargeSpinInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeSpinInfo) return object;
      var message = new $root.client_proto.AccumulatedChargeSpinInfo();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.spinlist) {
        if (!Array.isArray(object.spinlist))
          throw TypeError(".client_proto.AccumulatedChargeSpinInfo.spinlist: array expected");
        message.spinlist = [];
        for (var i = 0; i < object.spinlist.length; ++i) {
          if (typeof object.spinlist[i] !== "object")
            throw TypeError(".client_proto.AccumulatedChargeSpinInfo.spinlist: object expected");
          message.spinlist[i] = $root.client_proto.SpinInfo.fromObject(object.spinlist[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeSpinInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @static
     * @param {client_proto.AccumulatedChargeSpinInfo} message AccumulatedChargeSpinInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeSpinInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.spinlist = [];
      if (options.defaults) {
        object.uid = 0;
        object.activity_id = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.spinlist && message.spinlist.length) {
        object.spinlist = [];
        for (var j = 0; j < message.spinlist.length; ++j)
          object.spinlist[j] = $root.client_proto.SpinInfo.toObject(message.spinlist[j], options);
      }
      return object;
    };

    /**
     * Converts this AccumulatedChargeSpinInfo to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeSpinInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeSpinInfo
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeSpinInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeSpinInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeSpinInfo";
    };

    return AccumulatedChargeSpinInfo;
  })();

  client_proto.AccumulatedChargeUpdateReq = (function () {
    /**
     * Properties of an AccumulatedChargeUpdateReq.
     * @memberof client_proto
     * @interface IAccumulatedChargeUpdateReq
     * @property {number|null} [table_index] AccumulatedChargeUpdateReq table_index
     * @property {string|null} [sql_string] AccumulatedChargeUpdateReq sql_string
     */

    /**
     * Constructs a new AccumulatedChargeUpdateReq.
     * @memberof client_proto
     * @classdesc Represents an AccumulatedChargeUpdateReq.
     * @implements IAccumulatedChargeUpdateReq
     * @constructor
     * @param {client_proto.IAccumulatedChargeUpdateReq=} [properties] Properties to set
     */
    function AccumulatedChargeUpdateReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AccumulatedChargeUpdateReq table_index.
     * @member {number} table_index
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @instance
     */
    AccumulatedChargeUpdateReq.prototype.table_index = 0;

    /**
     * AccumulatedChargeUpdateReq sql_string.
     * @member {string} sql_string
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @instance
     */
    AccumulatedChargeUpdateReq.prototype.sql_string = "";

    /**
     * Creates a new AccumulatedChargeUpdateReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @static
     * @param {client_proto.IAccumulatedChargeUpdateReq=} [properties] Properties to set
     * @returns {client_proto.AccumulatedChargeUpdateReq} AccumulatedChargeUpdateReq instance
     */
    AccumulatedChargeUpdateReq.create = function create(properties) {
      return new AccumulatedChargeUpdateReq(properties);
    };

    /**
     * Encodes the specified AccumulatedChargeUpdateReq message. Does not implicitly {@link client_proto.AccumulatedChargeUpdateReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @static
     * @param {client_proto.IAccumulatedChargeUpdateReq} message AccumulatedChargeUpdateReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeUpdateReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.table_index != null && Object.hasOwnProperty.call(message, "table_index"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.table_index);
      if (message.sql_string != null && Object.hasOwnProperty.call(message, "sql_string"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.sql_string);
      return writer;
    };

    /**
     * Encodes the specified AccumulatedChargeUpdateReq message, length delimited. Does not implicitly {@link client_proto.AccumulatedChargeUpdateReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @static
     * @param {client_proto.IAccumulatedChargeUpdateReq} message AccumulatedChargeUpdateReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AccumulatedChargeUpdateReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AccumulatedChargeUpdateReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AccumulatedChargeUpdateReq} AccumulatedChargeUpdateReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeUpdateReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AccumulatedChargeUpdateReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.table_index = reader.int32();
            break;
          }
          case 2: {
            message.sql_string = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AccumulatedChargeUpdateReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AccumulatedChargeUpdateReq} AccumulatedChargeUpdateReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AccumulatedChargeUpdateReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AccumulatedChargeUpdateReq message.
     * @function verify
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AccumulatedChargeUpdateReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.table_index != null && message.hasOwnProperty("table_index"))
        if (!$util.isInteger(message.table_index)) return "table_index: integer expected";
      if (message.sql_string != null && message.hasOwnProperty("sql_string"))
        if (!$util.isString(message.sql_string)) return "sql_string: string expected";
      return null;
    };

    /**
     * Creates an AccumulatedChargeUpdateReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AccumulatedChargeUpdateReq} AccumulatedChargeUpdateReq
     */
    AccumulatedChargeUpdateReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AccumulatedChargeUpdateReq) return object;
      var message = new $root.client_proto.AccumulatedChargeUpdateReq();
      if (object.table_index != null) message.table_index = object.table_index | 0;
      if (object.sql_string != null) message.sql_string = String(object.sql_string);
      return message;
    };

    /**
     * Creates a plain object from an AccumulatedChargeUpdateReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @static
     * @param {client_proto.AccumulatedChargeUpdateReq} message AccumulatedChargeUpdateReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AccumulatedChargeUpdateReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.table_index = 0;
        object.sql_string = "";
      }
      if (message.table_index != null && message.hasOwnProperty("table_index"))
        object.table_index = message.table_index;
      if (message.sql_string != null && message.hasOwnProperty("sql_string")) object.sql_string = message.sql_string;
      return object;
    };

    /**
     * Converts this AccumulatedChargeUpdateReq to JSON.
     * @function toJSON
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AccumulatedChargeUpdateReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AccumulatedChargeUpdateReq
     * @function getTypeUrl
     * @memberof client_proto.AccumulatedChargeUpdateReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AccumulatedChargeUpdateReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AccumulatedChargeUpdateReq";
    };

    return AccumulatedChargeUpdateReq;
  })();

  client_proto.RegressGiftUserInfo = (function () {
    /**
     * Properties of a RegressGiftUserInfo.
     * @memberof client_proto
     * @interface IRegressGiftUserInfo
     * @property {number|null} [uid] RegressGiftUserInfo uid
     * @property {number|null} [activity_id] RegressGiftUserInfo activity_id
     * @property {number|null} [regresscount] RegressGiftUserInfo regresscount
     * @property {number|Long|null} [last_logintime] RegressGiftUserInfo last_logintime
     * @property {number|Long|null} [logintime] RegressGiftUserInfo logintime
     * @property {number|null} [event] RegressGiftUserInfo event
     * @property {number|null} [buy_gifttotal] RegressGiftUserInfo buy_gifttotal
     * @property {number|null} [buy_curgifttotal] RegressGiftUserInfo buy_curgifttotal
     * @property {number|null} [countdown] RegressGiftUserInfo countdown
     */

    /**
     * Constructs a new RegressGiftUserInfo.
     * @memberof client_proto
     * @classdesc Represents a RegressGiftUserInfo.
     * @implements IRegressGiftUserInfo
     * @constructor
     * @param {client_proto.IRegressGiftUserInfo=} [properties] Properties to set
     */
    function RegressGiftUserInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RegressGiftUserInfo uid.
     * @member {number} uid
     * @memberof client_proto.RegressGiftUserInfo
     * @instance
     */
    RegressGiftUserInfo.prototype.uid = 0;

    /**
     * RegressGiftUserInfo activity_id.
     * @member {number} activity_id
     * @memberof client_proto.RegressGiftUserInfo
     * @instance
     */
    RegressGiftUserInfo.prototype.activity_id = 0;

    /**
     * RegressGiftUserInfo regresscount.
     * @member {number} regresscount
     * @memberof client_proto.RegressGiftUserInfo
     * @instance
     */
    RegressGiftUserInfo.prototype.regresscount = 0;

    /**
     * RegressGiftUserInfo last_logintime.
     * @member {number|Long} last_logintime
     * @memberof client_proto.RegressGiftUserInfo
     * @instance
     */
    RegressGiftUserInfo.prototype.last_logintime = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressGiftUserInfo logintime.
     * @member {number|Long} logintime
     * @memberof client_proto.RegressGiftUserInfo
     * @instance
     */
    RegressGiftUserInfo.prototype.logintime = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressGiftUserInfo event.
     * @member {number} event
     * @memberof client_proto.RegressGiftUserInfo
     * @instance
     */
    RegressGiftUserInfo.prototype.event = 0;

    /**
     * RegressGiftUserInfo buy_gifttotal.
     * @member {number} buy_gifttotal
     * @memberof client_proto.RegressGiftUserInfo
     * @instance
     */
    RegressGiftUserInfo.prototype.buy_gifttotal = 0;

    /**
     * RegressGiftUserInfo buy_curgifttotal.
     * @member {number} buy_curgifttotal
     * @memberof client_proto.RegressGiftUserInfo
     * @instance
     */
    RegressGiftUserInfo.prototype.buy_curgifttotal = 0;

    /**
     * RegressGiftUserInfo countdown.
     * @member {number} countdown
     * @memberof client_proto.RegressGiftUserInfo
     * @instance
     */
    RegressGiftUserInfo.prototype.countdown = 0;

    /**
     * Creates a new RegressGiftUserInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.RegressGiftUserInfo
     * @static
     * @param {client_proto.IRegressGiftUserInfo=} [properties] Properties to set
     * @returns {client_proto.RegressGiftUserInfo} RegressGiftUserInfo instance
     */
    RegressGiftUserInfo.create = function create(properties) {
      return new RegressGiftUserInfo(properties);
    };

    /**
     * Encodes the specified RegressGiftUserInfo message. Does not implicitly {@link client_proto.RegressGiftUserInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RegressGiftUserInfo
     * @static
     * @param {client_proto.IRegressGiftUserInfo} message RegressGiftUserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RegressGiftUserInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.activity_id);
      if (message.regresscount != null && Object.hasOwnProperty.call(message, "regresscount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.regresscount);
      if (message.last_logintime != null && Object.hasOwnProperty.call(message, "last_logintime"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.last_logintime);
      if (message.logintime != null && Object.hasOwnProperty.call(message, "logintime"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.logintime);
      if (message.event != null && Object.hasOwnProperty.call(message, "event"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.event);
      if (message.buy_gifttotal != null && Object.hasOwnProperty.call(message, "buy_gifttotal"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.buy_gifttotal);
      if (message.buy_curgifttotal != null && Object.hasOwnProperty.call(message, "buy_curgifttotal"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.buy_curgifttotal);
      if (message.countdown != null && Object.hasOwnProperty.call(message, "countdown"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int32(message.countdown);
      return writer;
    };

    /**
     * Encodes the specified RegressGiftUserInfo message, length delimited. Does not implicitly {@link client_proto.RegressGiftUserInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RegressGiftUserInfo
     * @static
     * @param {client_proto.IRegressGiftUserInfo} message RegressGiftUserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RegressGiftUserInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RegressGiftUserInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RegressGiftUserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RegressGiftUserInfo} RegressGiftUserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RegressGiftUserInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RegressGiftUserInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.activity_id = reader.int32();
            break;
          }
          case 3: {
            message.regresscount = reader.int32();
            break;
          }
          case 4: {
            message.last_logintime = reader.int64();
            break;
          }
          case 5: {
            message.logintime = reader.int64();
            break;
          }
          case 6: {
            message.event = reader.int32();
            break;
          }
          case 7: {
            message.buy_gifttotal = reader.int32();
            break;
          }
          case 8: {
            message.buy_curgifttotal = reader.int32();
            break;
          }
          case 9: {
            message.countdown = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RegressGiftUserInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RegressGiftUserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RegressGiftUserInfo} RegressGiftUserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RegressGiftUserInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RegressGiftUserInfo message.
     * @function verify
     * @memberof client_proto.RegressGiftUserInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RegressGiftUserInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.regresscount != null && message.hasOwnProperty("regresscount"))
        if (!$util.isInteger(message.regresscount)) return "regresscount: integer expected";
      if (message.last_logintime != null && message.hasOwnProperty("last_logintime"))
        if (
          !$util.isInteger(message.last_logintime) &&
          !(
            message.last_logintime &&
            $util.isInteger(message.last_logintime.low) &&
            $util.isInteger(message.last_logintime.high)
          )
        )
          return "last_logintime: integer|Long expected";
      if (message.logintime != null && message.hasOwnProperty("logintime"))
        if (
          !$util.isInteger(message.logintime) &&
          !(message.logintime && $util.isInteger(message.logintime.low) && $util.isInteger(message.logintime.high))
        )
          return "logintime: integer|Long expected";
      if (message.event != null && message.hasOwnProperty("event"))
        if (!$util.isInteger(message.event)) return "event: integer expected";
      if (message.buy_gifttotal != null && message.hasOwnProperty("buy_gifttotal"))
        if (!$util.isInteger(message.buy_gifttotal)) return "buy_gifttotal: integer expected";
      if (message.buy_curgifttotal != null && message.hasOwnProperty("buy_curgifttotal"))
        if (!$util.isInteger(message.buy_curgifttotal)) return "buy_curgifttotal: integer expected";
      if (message.countdown != null && message.hasOwnProperty("countdown"))
        if (!$util.isInteger(message.countdown)) return "countdown: integer expected";
      return null;
    };

    /**
     * Creates a RegressGiftUserInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RegressGiftUserInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RegressGiftUserInfo} RegressGiftUserInfo
     */
    RegressGiftUserInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RegressGiftUserInfo) return object;
      var message = new $root.client_proto.RegressGiftUserInfo();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.regresscount != null) message.regresscount = object.regresscount | 0;
      if (object.last_logintime != null)
        if ($util.Long) (message.last_logintime = $util.Long.fromValue(object.last_logintime)).unsigned = false;
        else if (typeof object.last_logintime === "string")
          message.last_logintime = parseInt(object.last_logintime, 10);
        else if (typeof object.last_logintime === "number") message.last_logintime = object.last_logintime;
        else if (typeof object.last_logintime === "object")
          message.last_logintime = new $util.LongBits(
            object.last_logintime.low >>> 0,
            object.last_logintime.high >>> 0,
          ).toNumber();
      if (object.logintime != null)
        if ($util.Long) (message.logintime = $util.Long.fromValue(object.logintime)).unsigned = false;
        else if (typeof object.logintime === "string") message.logintime = parseInt(object.logintime, 10);
        else if (typeof object.logintime === "number") message.logintime = object.logintime;
        else if (typeof object.logintime === "object")
          message.logintime = new $util.LongBits(object.logintime.low >>> 0, object.logintime.high >>> 0).toNumber();
      if (object.event != null) message.event = object.event | 0;
      if (object.buy_gifttotal != null) message.buy_gifttotal = object.buy_gifttotal | 0;
      if (object.buy_curgifttotal != null) message.buy_curgifttotal = object.buy_curgifttotal | 0;
      if (object.countdown != null) message.countdown = object.countdown | 0;
      return message;
    };

    /**
     * Creates a plain object from a RegressGiftUserInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RegressGiftUserInfo
     * @static
     * @param {client_proto.RegressGiftUserInfo} message RegressGiftUserInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RegressGiftUserInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.activity_id = 0;
        object.regresscount = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.last_logintime =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.last_logintime = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.logintime =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.logintime = options.longs === String ? "0" : 0;
        object.event = 0;
        object.buy_gifttotal = 0;
        object.buy_curgifttotal = 0;
        object.countdown = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.regresscount != null && message.hasOwnProperty("regresscount"))
        object.regresscount = message.regresscount;
      if (message.last_logintime != null && message.hasOwnProperty("last_logintime"))
        if (typeof message.last_logintime === "number")
          object.last_logintime = options.longs === String ? String(message.last_logintime) : message.last_logintime;
        else
          object.last_logintime =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.last_logintime)
              : options.longs === Number
                ? new $util.LongBits(message.last_logintime.low >>> 0, message.last_logintime.high >>> 0).toNumber()
                : message.last_logintime;
      if (message.logintime != null && message.hasOwnProperty("logintime"))
        if (typeof message.logintime === "number")
          object.logintime = options.longs === String ? String(message.logintime) : message.logintime;
        else
          object.logintime =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.logintime)
              : options.longs === Number
                ? new $util.LongBits(message.logintime.low >>> 0, message.logintime.high >>> 0).toNumber()
                : message.logintime;
      if (message.event != null && message.hasOwnProperty("event")) object.event = message.event;
      if (message.buy_gifttotal != null && message.hasOwnProperty("buy_gifttotal"))
        object.buy_gifttotal = message.buy_gifttotal;
      if (message.buy_curgifttotal != null && message.hasOwnProperty("buy_curgifttotal"))
        object.buy_curgifttotal = message.buy_curgifttotal;
      if (message.countdown != null && message.hasOwnProperty("countdown")) object.countdown = message.countdown;
      return object;
    };

    /**
     * Converts this RegressGiftUserInfo to JSON.
     * @function toJSON
     * @memberof client_proto.RegressGiftUserInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RegressGiftUserInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RegressGiftUserInfo
     * @function getTypeUrl
     * @memberof client_proto.RegressGiftUserInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RegressGiftUserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RegressGiftUserInfo";
    };

    return RegressGiftUserInfo;
  })();

  client_proto.RegressGiftStatistic = (function () {
    /**
     * Properties of a RegressGiftStatistic.
     * @memberof client_proto
     * @interface IRegressGiftStatistic
     * @property {number|null} [uid] RegressGiftStatistic uid
     * @property {number|null} [activity_type] RegressGiftStatistic activity_type
     * @property {number|null} [activity_id] RegressGiftStatistic activity_id
     * @property {number|null} [subid] RegressGiftStatistic subid
     * @property {number|Long|null} [giftlabel] RegressGiftStatistic giftlabel
     * @property {number|null} [event] RegressGiftStatistic event
     * @property {number|null} [charge_id] RegressGiftStatistic charge_id
     * @property {number|null} [regresscount] RegressGiftStatistic regresscount
     * @property {number|Long|null} [face_price] RegressGiftStatistic face_price
     * @property {number|Long|null} [price] RegressGiftStatistic price
     * @property {number|Long|null} [reward] RegressGiftStatistic reward
     * @property {number|Long|null} [need_flow] RegressGiftStatistic need_flow
     * @property {number|Long|null} [create_time] RegressGiftStatistic create_time
     */

    /**
     * Constructs a new RegressGiftStatistic.
     * @memberof client_proto
     * @classdesc Represents a RegressGiftStatistic.
     * @implements IRegressGiftStatistic
     * @constructor
     * @param {client_proto.IRegressGiftStatistic=} [properties] Properties to set
     */
    function RegressGiftStatistic(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RegressGiftStatistic uid.
     * @member {number} uid
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.uid = 0;

    /**
     * RegressGiftStatistic activity_type.
     * @member {number} activity_type
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.activity_type = 0;

    /**
     * RegressGiftStatistic activity_id.
     * @member {number} activity_id
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.activity_id = 0;

    /**
     * RegressGiftStatistic subid.
     * @member {number} subid
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.subid = 0;

    /**
     * RegressGiftStatistic giftlabel.
     * @member {number|Long} giftlabel
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.giftlabel = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressGiftStatistic event.
     * @member {number} event
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.event = 0;

    /**
     * RegressGiftStatistic charge_id.
     * @member {number} charge_id
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.charge_id = 0;

    /**
     * RegressGiftStatistic regresscount.
     * @member {number} regresscount
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.regresscount = 0;

    /**
     * RegressGiftStatistic face_price.
     * @member {number|Long} face_price
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.face_price = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressGiftStatistic price.
     * @member {number|Long} price
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.price = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressGiftStatistic reward.
     * @member {number|Long} reward
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.reward = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressGiftStatistic need_flow.
     * @member {number|Long} need_flow
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.need_flow = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressGiftStatistic create_time.
     * @member {number|Long} create_time
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     */
    RegressGiftStatistic.prototype.create_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new RegressGiftStatistic instance using the specified properties.
     * @function create
     * @memberof client_proto.RegressGiftStatistic
     * @static
     * @param {client_proto.IRegressGiftStatistic=} [properties] Properties to set
     * @returns {client_proto.RegressGiftStatistic} RegressGiftStatistic instance
     */
    RegressGiftStatistic.create = function create(properties) {
      return new RegressGiftStatistic(properties);
    };

    /**
     * Encodes the specified RegressGiftStatistic message. Does not implicitly {@link client_proto.RegressGiftStatistic.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RegressGiftStatistic
     * @static
     * @param {client_proto.IRegressGiftStatistic} message RegressGiftStatistic message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RegressGiftStatistic.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.activity_type != null && Object.hasOwnProperty.call(message, "activity_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.activity_type);
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.activity_id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.subid);
      if (message.giftlabel != null && Object.hasOwnProperty.call(message, "giftlabel"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.giftlabel);
      if (message.event != null && Object.hasOwnProperty.call(message, "event"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.event);
      if (message.charge_id != null && Object.hasOwnProperty.call(message, "charge_id"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.charge_id);
      if (message.regresscount != null && Object.hasOwnProperty.call(message, "regresscount"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.regresscount);
      if (message.face_price != null && Object.hasOwnProperty.call(message, "face_price"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.face_price);
      if (message.price != null && Object.hasOwnProperty.call(message, "price"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.price);
      if (message.reward != null && Object.hasOwnProperty.call(message, "reward"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int64(message.reward);
      if (message.need_flow != null && Object.hasOwnProperty.call(message, "need_flow"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).int64(message.need_flow);
      if (message.create_time != null && Object.hasOwnProperty.call(message, "create_time"))
        writer.uint32(/* id 13, wireType 0 =*/ 104).int64(message.create_time);
      return writer;
    };

    /**
     * Encodes the specified RegressGiftStatistic message, length delimited. Does not implicitly {@link client_proto.RegressGiftStatistic.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RegressGiftStatistic
     * @static
     * @param {client_proto.IRegressGiftStatistic} message RegressGiftStatistic message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RegressGiftStatistic.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RegressGiftStatistic message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RegressGiftStatistic
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RegressGiftStatistic} RegressGiftStatistic
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RegressGiftStatistic.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RegressGiftStatistic();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.activity_type = reader.int32();
            break;
          }
          case 3: {
            message.activity_id = reader.int32();
            break;
          }
          case 4: {
            message.subid = reader.int32();
            break;
          }
          case 5: {
            message.giftlabel = reader.int64();
            break;
          }
          case 6: {
            message.event = reader.int32();
            break;
          }
          case 7: {
            message.charge_id = reader.int32();
            break;
          }
          case 8: {
            message.regresscount = reader.int32();
            break;
          }
          case 9: {
            message.face_price = reader.int64();
            break;
          }
          case 10: {
            message.price = reader.int64();
            break;
          }
          case 11: {
            message.reward = reader.int64();
            break;
          }
          case 12: {
            message.need_flow = reader.int64();
            break;
          }
          case 13: {
            message.create_time = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RegressGiftStatistic message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RegressGiftStatistic
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RegressGiftStatistic} RegressGiftStatistic
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RegressGiftStatistic.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RegressGiftStatistic message.
     * @function verify
     * @memberof client_proto.RegressGiftStatistic
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RegressGiftStatistic.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.activity_type != null && message.hasOwnProperty("activity_type"))
        if (!$util.isInteger(message.activity_type)) return "activity_type: integer expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.giftlabel != null && message.hasOwnProperty("giftlabel"))
        if (
          !$util.isInteger(message.giftlabel) &&
          !(message.giftlabel && $util.isInteger(message.giftlabel.low) && $util.isInteger(message.giftlabel.high))
        )
          return "giftlabel: integer|Long expected";
      if (message.event != null && message.hasOwnProperty("event"))
        if (!$util.isInteger(message.event)) return "event: integer expected";
      if (message.charge_id != null && message.hasOwnProperty("charge_id"))
        if (!$util.isInteger(message.charge_id)) return "charge_id: integer expected";
      if (message.regresscount != null && message.hasOwnProperty("regresscount"))
        if (!$util.isInteger(message.regresscount)) return "regresscount: integer expected";
      if (message.face_price != null && message.hasOwnProperty("face_price"))
        if (
          !$util.isInteger(message.face_price) &&
          !(message.face_price && $util.isInteger(message.face_price.low) && $util.isInteger(message.face_price.high))
        )
          return "face_price: integer|Long expected";
      if (message.price != null && message.hasOwnProperty("price"))
        if (
          !$util.isInteger(message.price) &&
          !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high))
        )
          return "price: integer|Long expected";
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (
          !$util.isInteger(message.reward) &&
          !(message.reward && $util.isInteger(message.reward.low) && $util.isInteger(message.reward.high))
        )
          return "reward: integer|Long expected";
      if (message.need_flow != null && message.hasOwnProperty("need_flow"))
        if (
          !$util.isInteger(message.need_flow) &&
          !(message.need_flow && $util.isInteger(message.need_flow.low) && $util.isInteger(message.need_flow.high))
        )
          return "need_flow: integer|Long expected";
      if (message.create_time != null && message.hasOwnProperty("create_time"))
        if (
          !$util.isInteger(message.create_time) &&
          !(
            message.create_time &&
            $util.isInteger(message.create_time.low) &&
            $util.isInteger(message.create_time.high)
          )
        )
          return "create_time: integer|Long expected";
      return null;
    };

    /**
     * Creates a RegressGiftStatistic message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RegressGiftStatistic
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RegressGiftStatistic} RegressGiftStatistic
     */
    RegressGiftStatistic.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RegressGiftStatistic) return object;
      var message = new $root.client_proto.RegressGiftStatistic();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.activity_type != null) message.activity_type = object.activity_type | 0;
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.giftlabel != null)
        if ($util.Long) (message.giftlabel = $util.Long.fromValue(object.giftlabel)).unsigned = false;
        else if (typeof object.giftlabel === "string") message.giftlabel = parseInt(object.giftlabel, 10);
        else if (typeof object.giftlabel === "number") message.giftlabel = object.giftlabel;
        else if (typeof object.giftlabel === "object")
          message.giftlabel = new $util.LongBits(object.giftlabel.low >>> 0, object.giftlabel.high >>> 0).toNumber();
      if (object.event != null) message.event = object.event | 0;
      if (object.charge_id != null) message.charge_id = object.charge_id | 0;
      if (object.regresscount != null) message.regresscount = object.regresscount | 0;
      if (object.face_price != null)
        if ($util.Long) (message.face_price = $util.Long.fromValue(object.face_price)).unsigned = false;
        else if (typeof object.face_price === "string") message.face_price = parseInt(object.face_price, 10);
        else if (typeof object.face_price === "number") message.face_price = object.face_price;
        else if (typeof object.face_price === "object")
          message.face_price = new $util.LongBits(object.face_price.low >>> 0, object.face_price.high >>> 0).toNumber();
      if (object.price != null)
        if ($util.Long) (message.price = $util.Long.fromValue(object.price)).unsigned = false;
        else if (typeof object.price === "string") message.price = parseInt(object.price, 10);
        else if (typeof object.price === "number") message.price = object.price;
        else if (typeof object.price === "object")
          message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
      if (object.reward != null)
        if ($util.Long) (message.reward = $util.Long.fromValue(object.reward)).unsigned = false;
        else if (typeof object.reward === "string") message.reward = parseInt(object.reward, 10);
        else if (typeof object.reward === "number") message.reward = object.reward;
        else if (typeof object.reward === "object")
          message.reward = new $util.LongBits(object.reward.low >>> 0, object.reward.high >>> 0).toNumber();
      if (object.need_flow != null)
        if ($util.Long) (message.need_flow = $util.Long.fromValue(object.need_flow)).unsigned = false;
        else if (typeof object.need_flow === "string") message.need_flow = parseInt(object.need_flow, 10);
        else if (typeof object.need_flow === "number") message.need_flow = object.need_flow;
        else if (typeof object.need_flow === "object")
          message.need_flow = new $util.LongBits(object.need_flow.low >>> 0, object.need_flow.high >>> 0).toNumber();
      if (object.create_time != null)
        if ($util.Long) (message.create_time = $util.Long.fromValue(object.create_time)).unsigned = false;
        else if (typeof object.create_time === "string") message.create_time = parseInt(object.create_time, 10);
        else if (typeof object.create_time === "number") message.create_time = object.create_time;
        else if (typeof object.create_time === "object")
          message.create_time = new $util.LongBits(
            object.create_time.low >>> 0,
            object.create_time.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a RegressGiftStatistic message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RegressGiftStatistic
     * @static
     * @param {client_proto.RegressGiftStatistic} message RegressGiftStatistic
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RegressGiftStatistic.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.activity_type = 0;
        object.activity_id = 0;
        object.subid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.giftlabel =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.giftlabel = options.longs === String ? "0" : 0;
        object.event = 0;
        object.charge_id = 0;
        object.regresscount = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.face_price =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.face_price = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.price = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.need_flow =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.need_flow = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.create_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.create_time = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.activity_type != null && message.hasOwnProperty("activity_type"))
        object.activity_type = message.activity_type;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.giftlabel != null && message.hasOwnProperty("giftlabel"))
        if (typeof message.giftlabel === "number")
          object.giftlabel = options.longs === String ? String(message.giftlabel) : message.giftlabel;
        else
          object.giftlabel =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.giftlabel)
              : options.longs === Number
                ? new $util.LongBits(message.giftlabel.low >>> 0, message.giftlabel.high >>> 0).toNumber()
                : message.giftlabel;
      if (message.event != null && message.hasOwnProperty("event")) object.event = message.event;
      if (message.charge_id != null && message.hasOwnProperty("charge_id")) object.charge_id = message.charge_id;
      if (message.regresscount != null && message.hasOwnProperty("regresscount"))
        object.regresscount = message.regresscount;
      if (message.face_price != null && message.hasOwnProperty("face_price"))
        if (typeof message.face_price === "number")
          object.face_price = options.longs === String ? String(message.face_price) : message.face_price;
        else
          object.face_price =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.face_price)
              : options.longs === Number
                ? new $util.LongBits(message.face_price.low >>> 0, message.face_price.high >>> 0).toNumber()
                : message.face_price;
      if (message.price != null && message.hasOwnProperty("price"))
        if (typeof message.price === "number")
          object.price = options.longs === String ? String(message.price) : message.price;
        else
          object.price =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.price)
              : options.longs === Number
                ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber()
                : message.price;
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (typeof message.reward === "number")
          object.reward = options.longs === String ? String(message.reward) : message.reward;
        else
          object.reward =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward)
              : options.longs === Number
                ? new $util.LongBits(message.reward.low >>> 0, message.reward.high >>> 0).toNumber()
                : message.reward;
      if (message.need_flow != null && message.hasOwnProperty("need_flow"))
        if (typeof message.need_flow === "number")
          object.need_flow = options.longs === String ? String(message.need_flow) : message.need_flow;
        else
          object.need_flow =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.need_flow)
              : options.longs === Number
                ? new $util.LongBits(message.need_flow.low >>> 0, message.need_flow.high >>> 0).toNumber()
                : message.need_flow;
      if (message.create_time != null && message.hasOwnProperty("create_time"))
        if (typeof message.create_time === "number")
          object.create_time = options.longs === String ? String(message.create_time) : message.create_time;
        else
          object.create_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.create_time)
              : options.longs === Number
                ? new $util.LongBits(message.create_time.low >>> 0, message.create_time.high >>> 0).toNumber()
                : message.create_time;
      return object;
    };

    /**
     * Converts this RegressGiftStatistic to JSON.
     * @function toJSON
     * @memberof client_proto.RegressGiftStatistic
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RegressGiftStatistic.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RegressGiftStatistic
     * @function getTypeUrl
     * @memberof client_proto.RegressGiftStatistic
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RegressGiftStatistic.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RegressGiftStatistic";
    };

    return RegressGiftStatistic;
  })();

  client_proto.RegressWelfareStatistic = (function () {
    /**
     * Properties of a RegressWelfareStatistic.
     * @memberof client_proto
     * @interface IRegressWelfareStatistic
     * @property {number|null} [uid] RegressWelfareStatistic uid
     * @property {number|null} [activity_type] RegressWelfareStatistic activity_type
     * @property {number|null} [activity_id] RegressWelfareStatistic activity_id
     * @property {number|null} [subid] RegressWelfareStatistic subid
     * @property {number|Long|null} [level_id] RegressWelfareStatistic level_id
     * @property {number|null} [event] RegressWelfareStatistic event
     * @property {number|null} [charge_id] RegressWelfareStatistic charge_id
     * @property {number|null} [regresscount] RegressWelfareStatistic regresscount
     * @property {number|Long|null} [reward] RegressWelfareStatistic reward
     * @property {number|Long|null} [need_flow] RegressWelfareStatistic need_flow
     * @property {number|Long|null} [create_time] RegressWelfareStatistic create_time
     */

    /**
     * Constructs a new RegressWelfareStatistic.
     * @memberof client_proto
     * @classdesc Represents a RegressWelfareStatistic.
     * @implements IRegressWelfareStatistic
     * @constructor
     * @param {client_proto.IRegressWelfareStatistic=} [properties] Properties to set
     */
    function RegressWelfareStatistic(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RegressWelfareStatistic uid.
     * @member {number} uid
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.uid = 0;

    /**
     * RegressWelfareStatistic activity_type.
     * @member {number} activity_type
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.activity_type = 0;

    /**
     * RegressWelfareStatistic activity_id.
     * @member {number} activity_id
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.activity_id = 0;

    /**
     * RegressWelfareStatistic subid.
     * @member {number} subid
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.subid = 0;

    /**
     * RegressWelfareStatistic level_id.
     * @member {number|Long} level_id
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.level_id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressWelfareStatistic event.
     * @member {number} event
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.event = 0;

    /**
     * RegressWelfareStatistic charge_id.
     * @member {number} charge_id
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.charge_id = 0;

    /**
     * RegressWelfareStatistic regresscount.
     * @member {number} regresscount
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.regresscount = 0;

    /**
     * RegressWelfareStatistic reward.
     * @member {number|Long} reward
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.reward = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressWelfareStatistic need_flow.
     * @member {number|Long} need_flow
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.need_flow = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressWelfareStatistic create_time.
     * @member {number|Long} create_time
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     */
    RegressWelfareStatistic.prototype.create_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new RegressWelfareStatistic instance using the specified properties.
     * @function create
     * @memberof client_proto.RegressWelfareStatistic
     * @static
     * @param {client_proto.IRegressWelfareStatistic=} [properties] Properties to set
     * @returns {client_proto.RegressWelfareStatistic} RegressWelfareStatistic instance
     */
    RegressWelfareStatistic.create = function create(properties) {
      return new RegressWelfareStatistic(properties);
    };

    /**
     * Encodes the specified RegressWelfareStatistic message. Does not implicitly {@link client_proto.RegressWelfareStatistic.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RegressWelfareStatistic
     * @static
     * @param {client_proto.IRegressWelfareStatistic} message RegressWelfareStatistic message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RegressWelfareStatistic.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.activity_type != null && Object.hasOwnProperty.call(message, "activity_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.activity_type);
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.activity_id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.subid);
      if (message.level_id != null && Object.hasOwnProperty.call(message, "level_id"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.level_id);
      if (message.event != null && Object.hasOwnProperty.call(message, "event"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.event);
      if (message.charge_id != null && Object.hasOwnProperty.call(message, "charge_id"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.charge_id);
      if (message.regresscount != null && Object.hasOwnProperty.call(message, "regresscount"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.regresscount);
      if (message.reward != null && Object.hasOwnProperty.call(message, "reward"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.reward);
      if (message.need_flow != null && Object.hasOwnProperty.call(message, "need_flow"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.need_flow);
      if (message.create_time != null && Object.hasOwnProperty.call(message, "create_time"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int64(message.create_time);
      return writer;
    };

    /**
     * Encodes the specified RegressWelfareStatistic message, length delimited. Does not implicitly {@link client_proto.RegressWelfareStatistic.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RegressWelfareStatistic
     * @static
     * @param {client_proto.IRegressWelfareStatistic} message RegressWelfareStatistic message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RegressWelfareStatistic.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RegressWelfareStatistic message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RegressWelfareStatistic
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RegressWelfareStatistic} RegressWelfareStatistic
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RegressWelfareStatistic.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RegressWelfareStatistic();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.activity_type = reader.int32();
            break;
          }
          case 3: {
            message.activity_id = reader.int32();
            break;
          }
          case 4: {
            message.subid = reader.int32();
            break;
          }
          case 5: {
            message.level_id = reader.int64();
            break;
          }
          case 6: {
            message.event = reader.int32();
            break;
          }
          case 7: {
            message.charge_id = reader.int32();
            break;
          }
          case 8: {
            message.regresscount = reader.int32();
            break;
          }
          case 9: {
            message.reward = reader.int64();
            break;
          }
          case 10: {
            message.need_flow = reader.int64();
            break;
          }
          case 11: {
            message.create_time = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RegressWelfareStatistic message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RegressWelfareStatistic
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RegressWelfareStatistic} RegressWelfareStatistic
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RegressWelfareStatistic.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RegressWelfareStatistic message.
     * @function verify
     * @memberof client_proto.RegressWelfareStatistic
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RegressWelfareStatistic.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.activity_type != null && message.hasOwnProperty("activity_type"))
        if (!$util.isInteger(message.activity_type)) return "activity_type: integer expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.level_id != null && message.hasOwnProperty("level_id"))
        if (
          !$util.isInteger(message.level_id) &&
          !(message.level_id && $util.isInteger(message.level_id.low) && $util.isInteger(message.level_id.high))
        )
          return "level_id: integer|Long expected";
      if (message.event != null && message.hasOwnProperty("event"))
        if (!$util.isInteger(message.event)) return "event: integer expected";
      if (message.charge_id != null && message.hasOwnProperty("charge_id"))
        if (!$util.isInteger(message.charge_id)) return "charge_id: integer expected";
      if (message.regresscount != null && message.hasOwnProperty("regresscount"))
        if (!$util.isInteger(message.regresscount)) return "regresscount: integer expected";
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (
          !$util.isInteger(message.reward) &&
          !(message.reward && $util.isInteger(message.reward.low) && $util.isInteger(message.reward.high))
        )
          return "reward: integer|Long expected";
      if (message.need_flow != null && message.hasOwnProperty("need_flow"))
        if (
          !$util.isInteger(message.need_flow) &&
          !(message.need_flow && $util.isInteger(message.need_flow.low) && $util.isInteger(message.need_flow.high))
        )
          return "need_flow: integer|Long expected";
      if (message.create_time != null && message.hasOwnProperty("create_time"))
        if (
          !$util.isInteger(message.create_time) &&
          !(
            message.create_time &&
            $util.isInteger(message.create_time.low) &&
            $util.isInteger(message.create_time.high)
          )
        )
          return "create_time: integer|Long expected";
      return null;
    };

    /**
     * Creates a RegressWelfareStatistic message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RegressWelfareStatistic
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RegressWelfareStatistic} RegressWelfareStatistic
     */
    RegressWelfareStatistic.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RegressWelfareStatistic) return object;
      var message = new $root.client_proto.RegressWelfareStatistic();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.activity_type != null) message.activity_type = object.activity_type | 0;
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.level_id != null)
        if ($util.Long) (message.level_id = $util.Long.fromValue(object.level_id)).unsigned = false;
        else if (typeof object.level_id === "string") message.level_id = parseInt(object.level_id, 10);
        else if (typeof object.level_id === "number") message.level_id = object.level_id;
        else if (typeof object.level_id === "object")
          message.level_id = new $util.LongBits(object.level_id.low >>> 0, object.level_id.high >>> 0).toNumber();
      if (object.event != null) message.event = object.event | 0;
      if (object.charge_id != null) message.charge_id = object.charge_id | 0;
      if (object.regresscount != null) message.regresscount = object.regresscount | 0;
      if (object.reward != null)
        if ($util.Long) (message.reward = $util.Long.fromValue(object.reward)).unsigned = false;
        else if (typeof object.reward === "string") message.reward = parseInt(object.reward, 10);
        else if (typeof object.reward === "number") message.reward = object.reward;
        else if (typeof object.reward === "object")
          message.reward = new $util.LongBits(object.reward.low >>> 0, object.reward.high >>> 0).toNumber();
      if (object.need_flow != null)
        if ($util.Long) (message.need_flow = $util.Long.fromValue(object.need_flow)).unsigned = false;
        else if (typeof object.need_flow === "string") message.need_flow = parseInt(object.need_flow, 10);
        else if (typeof object.need_flow === "number") message.need_flow = object.need_flow;
        else if (typeof object.need_flow === "object")
          message.need_flow = new $util.LongBits(object.need_flow.low >>> 0, object.need_flow.high >>> 0).toNumber();
      if (object.create_time != null)
        if ($util.Long) (message.create_time = $util.Long.fromValue(object.create_time)).unsigned = false;
        else if (typeof object.create_time === "string") message.create_time = parseInt(object.create_time, 10);
        else if (typeof object.create_time === "number") message.create_time = object.create_time;
        else if (typeof object.create_time === "object")
          message.create_time = new $util.LongBits(
            object.create_time.low >>> 0,
            object.create_time.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a RegressWelfareStatistic message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RegressWelfareStatistic
     * @static
     * @param {client_proto.RegressWelfareStatistic} message RegressWelfareStatistic
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RegressWelfareStatistic.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.activity_type = 0;
        object.activity_id = 0;
        object.subid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.level_id =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.level_id = options.longs === String ? "0" : 0;
        object.event = 0;
        object.charge_id = 0;
        object.regresscount = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.need_flow =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.need_flow = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.create_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.create_time = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.activity_type != null && message.hasOwnProperty("activity_type"))
        object.activity_type = message.activity_type;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.level_id != null && message.hasOwnProperty("level_id"))
        if (typeof message.level_id === "number")
          object.level_id = options.longs === String ? String(message.level_id) : message.level_id;
        else
          object.level_id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.level_id)
              : options.longs === Number
                ? new $util.LongBits(message.level_id.low >>> 0, message.level_id.high >>> 0).toNumber()
                : message.level_id;
      if (message.event != null && message.hasOwnProperty("event")) object.event = message.event;
      if (message.charge_id != null && message.hasOwnProperty("charge_id")) object.charge_id = message.charge_id;
      if (message.regresscount != null && message.hasOwnProperty("regresscount"))
        object.regresscount = message.regresscount;
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (typeof message.reward === "number")
          object.reward = options.longs === String ? String(message.reward) : message.reward;
        else
          object.reward =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward)
              : options.longs === Number
                ? new $util.LongBits(message.reward.low >>> 0, message.reward.high >>> 0).toNumber()
                : message.reward;
      if (message.need_flow != null && message.hasOwnProperty("need_flow"))
        if (typeof message.need_flow === "number")
          object.need_flow = options.longs === String ? String(message.need_flow) : message.need_flow;
        else
          object.need_flow =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.need_flow)
              : options.longs === Number
                ? new $util.LongBits(message.need_flow.low >>> 0, message.need_flow.high >>> 0).toNumber()
                : message.need_flow;
      if (message.create_time != null && message.hasOwnProperty("create_time"))
        if (typeof message.create_time === "number")
          object.create_time = options.longs === String ? String(message.create_time) : message.create_time;
        else
          object.create_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.create_time)
              : options.longs === Number
                ? new $util.LongBits(message.create_time.low >>> 0, message.create_time.high >>> 0).toNumber()
                : message.create_time;
      return object;
    };

    /**
     * Converts this RegressWelfareStatistic to JSON.
     * @function toJSON
     * @memberof client_proto.RegressWelfareStatistic
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RegressWelfareStatistic.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RegressWelfareStatistic
     * @function getTypeUrl
     * @memberof client_proto.RegressWelfareStatistic
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RegressWelfareStatistic.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RegressWelfareStatistic";
    };

    return RegressWelfareStatistic;
  })();

  client_proto.RegressWelfareUserInfo = (function () {
    /**
     * Properties of a RegressWelfareUserInfo.
     * @memberof client_proto
     * @interface IRegressWelfareUserInfo
     * @property {number|null} [uid] RegressWelfareUserInfo uid
     * @property {number|null} [activity_id] RegressWelfareUserInfo activity_id
     * @property {number|null} [regresscount] RegressWelfareUserInfo regresscount
     * @property {number|Long|null} [last_logintime] RegressWelfareUserInfo last_logintime
     * @property {number|Long|null} [logintime] RegressWelfareUserInfo logintime
     * @property {number|null} [event] RegressWelfareUserInfo event
     * @property {number|null} [giveout_total] RegressWelfareUserInfo giveout_total
     * @property {number|null} [cur_giveout_count] RegressWelfareUserInfo cur_giveout_count
     * @property {number|null} [receive_status] RegressWelfareUserInfo receive_status
     * @property {number|Long|null} [expired_time] RegressWelfareUserInfo expired_time
     */

    /**
     * Constructs a new RegressWelfareUserInfo.
     * @memberof client_proto
     * @classdesc Represents a RegressWelfareUserInfo.
     * @implements IRegressWelfareUserInfo
     * @constructor
     * @param {client_proto.IRegressWelfareUserInfo=} [properties] Properties to set
     */
    function RegressWelfareUserInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RegressWelfareUserInfo uid.
     * @member {number} uid
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     */
    RegressWelfareUserInfo.prototype.uid = 0;

    /**
     * RegressWelfareUserInfo activity_id.
     * @member {number} activity_id
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     */
    RegressWelfareUserInfo.prototype.activity_id = 0;

    /**
     * RegressWelfareUserInfo regresscount.
     * @member {number} regresscount
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     */
    RegressWelfareUserInfo.prototype.regresscount = 0;

    /**
     * RegressWelfareUserInfo last_logintime.
     * @member {number|Long} last_logintime
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     */
    RegressWelfareUserInfo.prototype.last_logintime = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressWelfareUserInfo logintime.
     * @member {number|Long} logintime
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     */
    RegressWelfareUserInfo.prototype.logintime = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RegressWelfareUserInfo event.
     * @member {number} event
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     */
    RegressWelfareUserInfo.prototype.event = 0;

    /**
     * RegressWelfareUserInfo giveout_total.
     * @member {number} giveout_total
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     */
    RegressWelfareUserInfo.prototype.giveout_total = 0;

    /**
     * RegressWelfareUserInfo cur_giveout_count.
     * @member {number} cur_giveout_count
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     */
    RegressWelfareUserInfo.prototype.cur_giveout_count = 0;

    /**
     * RegressWelfareUserInfo receive_status.
     * @member {number} receive_status
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     */
    RegressWelfareUserInfo.prototype.receive_status = 0;

    /**
     * RegressWelfareUserInfo expired_time.
     * @member {number|Long} expired_time
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     */
    RegressWelfareUserInfo.prototype.expired_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new RegressWelfareUserInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.RegressWelfareUserInfo
     * @static
     * @param {client_proto.IRegressWelfareUserInfo=} [properties] Properties to set
     * @returns {client_proto.RegressWelfareUserInfo} RegressWelfareUserInfo instance
     */
    RegressWelfareUserInfo.create = function create(properties) {
      return new RegressWelfareUserInfo(properties);
    };

    /**
     * Encodes the specified RegressWelfareUserInfo message. Does not implicitly {@link client_proto.RegressWelfareUserInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RegressWelfareUserInfo
     * @static
     * @param {client_proto.IRegressWelfareUserInfo} message RegressWelfareUserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RegressWelfareUserInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.activity_id);
      if (message.regresscount != null && Object.hasOwnProperty.call(message, "regresscount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.regresscount);
      if (message.last_logintime != null && Object.hasOwnProperty.call(message, "last_logintime"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.last_logintime);
      if (message.logintime != null && Object.hasOwnProperty.call(message, "logintime"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.logintime);
      if (message.event != null && Object.hasOwnProperty.call(message, "event"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.event);
      if (message.giveout_total != null && Object.hasOwnProperty.call(message, "giveout_total"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.giveout_total);
      if (message.cur_giveout_count != null && Object.hasOwnProperty.call(message, "cur_giveout_count"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.cur_giveout_count);
      if (message.receive_status != null && Object.hasOwnProperty.call(message, "receive_status"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int32(message.receive_status);
      if (message.expired_time != null && Object.hasOwnProperty.call(message, "expired_time"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.expired_time);
      return writer;
    };

    /**
     * Encodes the specified RegressWelfareUserInfo message, length delimited. Does not implicitly {@link client_proto.RegressWelfareUserInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RegressWelfareUserInfo
     * @static
     * @param {client_proto.IRegressWelfareUserInfo} message RegressWelfareUserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RegressWelfareUserInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RegressWelfareUserInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RegressWelfareUserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RegressWelfareUserInfo} RegressWelfareUserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RegressWelfareUserInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RegressWelfareUserInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.activity_id = reader.int32();
            break;
          }
          case 3: {
            message.regresscount = reader.int32();
            break;
          }
          case 4: {
            message.last_logintime = reader.int64();
            break;
          }
          case 5: {
            message.logintime = reader.int64();
            break;
          }
          case 6: {
            message.event = reader.int32();
            break;
          }
          case 7: {
            message.giveout_total = reader.int32();
            break;
          }
          case 8: {
            message.cur_giveout_count = reader.int32();
            break;
          }
          case 9: {
            message.receive_status = reader.int32();
            break;
          }
          case 10: {
            message.expired_time = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RegressWelfareUserInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RegressWelfareUserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RegressWelfareUserInfo} RegressWelfareUserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RegressWelfareUserInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RegressWelfareUserInfo message.
     * @function verify
     * @memberof client_proto.RegressWelfareUserInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RegressWelfareUserInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.regresscount != null && message.hasOwnProperty("regresscount"))
        if (!$util.isInteger(message.regresscount)) return "regresscount: integer expected";
      if (message.last_logintime != null && message.hasOwnProperty("last_logintime"))
        if (
          !$util.isInteger(message.last_logintime) &&
          !(
            message.last_logintime &&
            $util.isInteger(message.last_logintime.low) &&
            $util.isInteger(message.last_logintime.high)
          )
        )
          return "last_logintime: integer|Long expected";
      if (message.logintime != null && message.hasOwnProperty("logintime"))
        if (
          !$util.isInteger(message.logintime) &&
          !(message.logintime && $util.isInteger(message.logintime.low) && $util.isInteger(message.logintime.high))
        )
          return "logintime: integer|Long expected";
      if (message.event != null && message.hasOwnProperty("event"))
        if (!$util.isInteger(message.event)) return "event: integer expected";
      if (message.giveout_total != null && message.hasOwnProperty("giveout_total"))
        if (!$util.isInteger(message.giveout_total)) return "giveout_total: integer expected";
      if (message.cur_giveout_count != null && message.hasOwnProperty("cur_giveout_count"))
        if (!$util.isInteger(message.cur_giveout_count)) return "cur_giveout_count: integer expected";
      if (message.receive_status != null && message.hasOwnProperty("receive_status"))
        if (!$util.isInteger(message.receive_status)) return "receive_status: integer expected";
      if (message.expired_time != null && message.hasOwnProperty("expired_time"))
        if (
          !$util.isInteger(message.expired_time) &&
          !(
            message.expired_time &&
            $util.isInteger(message.expired_time.low) &&
            $util.isInteger(message.expired_time.high)
          )
        )
          return "expired_time: integer|Long expected";
      return null;
    };

    /**
     * Creates a RegressWelfareUserInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RegressWelfareUserInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RegressWelfareUserInfo} RegressWelfareUserInfo
     */
    RegressWelfareUserInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RegressWelfareUserInfo) return object;
      var message = new $root.client_proto.RegressWelfareUserInfo();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.regresscount != null) message.regresscount = object.regresscount | 0;
      if (object.last_logintime != null)
        if ($util.Long) (message.last_logintime = $util.Long.fromValue(object.last_logintime)).unsigned = false;
        else if (typeof object.last_logintime === "string")
          message.last_logintime = parseInt(object.last_logintime, 10);
        else if (typeof object.last_logintime === "number") message.last_logintime = object.last_logintime;
        else if (typeof object.last_logintime === "object")
          message.last_logintime = new $util.LongBits(
            object.last_logintime.low >>> 0,
            object.last_logintime.high >>> 0,
          ).toNumber();
      if (object.logintime != null)
        if ($util.Long) (message.logintime = $util.Long.fromValue(object.logintime)).unsigned = false;
        else if (typeof object.logintime === "string") message.logintime = parseInt(object.logintime, 10);
        else if (typeof object.logintime === "number") message.logintime = object.logintime;
        else if (typeof object.logintime === "object")
          message.logintime = new $util.LongBits(object.logintime.low >>> 0, object.logintime.high >>> 0).toNumber();
      if (object.event != null) message.event = object.event | 0;
      if (object.giveout_total != null) message.giveout_total = object.giveout_total | 0;
      if (object.cur_giveout_count != null) message.cur_giveout_count = object.cur_giveout_count | 0;
      if (object.receive_status != null) message.receive_status = object.receive_status | 0;
      if (object.expired_time != null)
        if ($util.Long) (message.expired_time = $util.Long.fromValue(object.expired_time)).unsigned = false;
        else if (typeof object.expired_time === "string") message.expired_time = parseInt(object.expired_time, 10);
        else if (typeof object.expired_time === "number") message.expired_time = object.expired_time;
        else if (typeof object.expired_time === "object")
          message.expired_time = new $util.LongBits(
            object.expired_time.low >>> 0,
            object.expired_time.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a RegressWelfareUserInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RegressWelfareUserInfo
     * @static
     * @param {client_proto.RegressWelfareUserInfo} message RegressWelfareUserInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RegressWelfareUserInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.activity_id = 0;
        object.regresscount = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.last_logintime =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.last_logintime = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.logintime =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.logintime = options.longs === String ? "0" : 0;
        object.event = 0;
        object.giveout_total = 0;
        object.cur_giveout_count = 0;
        object.receive_status = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.expired_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.expired_time = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.regresscount != null && message.hasOwnProperty("regresscount"))
        object.regresscount = message.regresscount;
      if (message.last_logintime != null && message.hasOwnProperty("last_logintime"))
        if (typeof message.last_logintime === "number")
          object.last_logintime = options.longs === String ? String(message.last_logintime) : message.last_logintime;
        else
          object.last_logintime =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.last_logintime)
              : options.longs === Number
                ? new $util.LongBits(message.last_logintime.low >>> 0, message.last_logintime.high >>> 0).toNumber()
                : message.last_logintime;
      if (message.logintime != null && message.hasOwnProperty("logintime"))
        if (typeof message.logintime === "number")
          object.logintime = options.longs === String ? String(message.logintime) : message.logintime;
        else
          object.logintime =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.logintime)
              : options.longs === Number
                ? new $util.LongBits(message.logintime.low >>> 0, message.logintime.high >>> 0).toNumber()
                : message.logintime;
      if (message.event != null && message.hasOwnProperty("event")) object.event = message.event;
      if (message.giveout_total != null && message.hasOwnProperty("giveout_total"))
        object.giveout_total = message.giveout_total;
      if (message.cur_giveout_count != null && message.hasOwnProperty("cur_giveout_count"))
        object.cur_giveout_count = message.cur_giveout_count;
      if (message.receive_status != null && message.hasOwnProperty("receive_status"))
        object.receive_status = message.receive_status;
      if (message.expired_time != null && message.hasOwnProperty("expired_time"))
        if (typeof message.expired_time === "number")
          object.expired_time = options.longs === String ? String(message.expired_time) : message.expired_time;
        else
          object.expired_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.expired_time)
              : options.longs === Number
                ? new $util.LongBits(message.expired_time.low >>> 0, message.expired_time.high >>> 0).toNumber()
                : message.expired_time;
      return object;
    };

    /**
     * Converts this RegressWelfareUserInfo to JSON.
     * @function toJSON
     * @memberof client_proto.RegressWelfareUserInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RegressWelfareUserInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RegressWelfareUserInfo
     * @function getTypeUrl
     * @memberof client_proto.RegressWelfareUserInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RegressWelfareUserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RegressWelfareUserInfo";
    };

    return RegressWelfareUserInfo;
  })();

  client_proto.SurpriseUserInfo = (function () {
    /**
     * Properties of a SurpriseUserInfo.
     * @memberof client_proto
     * @interface ISurpriseUserInfo
     * @property {number|null} [uid] SurpriseUserInfo uid
     * @property {number|null} [activity_id] SurpriseUserInfo activity_id
     * @property {number|null} [sub_id] SurpriseUserInfo sub_id
     * @property {number|null} [vip_level] SurpriseUserInfo vip_level
     * @property {number|null} [show_count] SurpriseUserInfo show_count
     * @property {number|null} [showing_count] SurpriseUserInfo showing_count
     * @property {number|null} [buy_gifttotal] SurpriseUserInfo buy_gifttotal
     * @property {number|null} [rotationgiftcount] SurpriseUserInfo rotationgiftcount
     * @property {number|null} [buy_coolstatus] SurpriseUserInfo buy_coolstatus
     * @property {number|Long|null} [rotationtime] SurpriseUserInfo rotationtime
     * @property {number|Long|null} [countdown] SurpriseUserInfo countdown
     * @property {number|null} [event] SurpriseUserInfo event
     */

    /**
     * Constructs a new SurpriseUserInfo.
     * @memberof client_proto
     * @classdesc Represents a SurpriseUserInfo.
     * @implements ISurpriseUserInfo
     * @constructor
     * @param {client_proto.ISurpriseUserInfo=} [properties] Properties to set
     */
    function SurpriseUserInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SurpriseUserInfo uid.
     * @member {number} uid
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.uid = 0;

    /**
     * SurpriseUserInfo activity_id.
     * @member {number} activity_id
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.activity_id = 0;

    /**
     * SurpriseUserInfo sub_id.
     * @member {number} sub_id
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.sub_id = 0;

    /**
     * SurpriseUserInfo vip_level.
     * @member {number} vip_level
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.vip_level = 0;

    /**
     * SurpriseUserInfo show_count.
     * @member {number} show_count
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.show_count = 0;

    /**
     * SurpriseUserInfo showing_count.
     * @member {number} showing_count
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.showing_count = 0;

    /**
     * SurpriseUserInfo buy_gifttotal.
     * @member {number} buy_gifttotal
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.buy_gifttotal = 0;

    /**
     * SurpriseUserInfo rotationgiftcount.
     * @member {number} rotationgiftcount
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.rotationgiftcount = 0;

    /**
     * SurpriseUserInfo buy_coolstatus.
     * @member {number} buy_coolstatus
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.buy_coolstatus = 0;

    /**
     * SurpriseUserInfo rotationtime.
     * @member {number|Long} rotationtime
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.rotationtime = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SurpriseUserInfo countdown.
     * @member {number|Long} countdown
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.countdown = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SurpriseUserInfo event.
     * @member {number} event
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     */
    SurpriseUserInfo.prototype.event = 0;

    /**
     * Creates a new SurpriseUserInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.SurpriseUserInfo
     * @static
     * @param {client_proto.ISurpriseUserInfo=} [properties] Properties to set
     * @returns {client_proto.SurpriseUserInfo} SurpriseUserInfo instance
     */
    SurpriseUserInfo.create = function create(properties) {
      return new SurpriseUserInfo(properties);
    };

    /**
     * Encodes the specified SurpriseUserInfo message. Does not implicitly {@link client_proto.SurpriseUserInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SurpriseUserInfo
     * @static
     * @param {client_proto.ISurpriseUserInfo} message SurpriseUserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SurpriseUserInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.activity_id);
      if (message.sub_id != null && Object.hasOwnProperty.call(message, "sub_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.sub_id);
      if (message.vip_level != null && Object.hasOwnProperty.call(message, "vip_level"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.vip_level);
      if (message.show_count != null && Object.hasOwnProperty.call(message, "show_count"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.show_count);
      if (message.showing_count != null && Object.hasOwnProperty.call(message, "showing_count"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.showing_count);
      if (message.buy_gifttotal != null && Object.hasOwnProperty.call(message, "buy_gifttotal"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.buy_gifttotal);
      if (message.rotationgiftcount != null && Object.hasOwnProperty.call(message, "rotationgiftcount"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.rotationgiftcount);
      if (message.buy_coolstatus != null && Object.hasOwnProperty.call(message, "buy_coolstatus"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int32(message.buy_coolstatus);
      if (message.rotationtime != null && Object.hasOwnProperty.call(message, "rotationtime"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.rotationtime);
      if (message.countdown != null && Object.hasOwnProperty.call(message, "countdown"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int64(message.countdown);
      if (message.event != null && Object.hasOwnProperty.call(message, "event"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).int32(message.event);
      return writer;
    };

    /**
     * Encodes the specified SurpriseUserInfo message, length delimited. Does not implicitly {@link client_proto.SurpriseUserInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SurpriseUserInfo
     * @static
     * @param {client_proto.ISurpriseUserInfo} message SurpriseUserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SurpriseUserInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SurpriseUserInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SurpriseUserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SurpriseUserInfo} SurpriseUserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SurpriseUserInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SurpriseUserInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.activity_id = reader.int32();
            break;
          }
          case 3: {
            message.sub_id = reader.int32();
            break;
          }
          case 4: {
            message.vip_level = reader.int32();
            break;
          }
          case 5: {
            message.show_count = reader.int32();
            break;
          }
          case 6: {
            message.showing_count = reader.int32();
            break;
          }
          case 7: {
            message.buy_gifttotal = reader.int32();
            break;
          }
          case 8: {
            message.rotationgiftcount = reader.int32();
            break;
          }
          case 9: {
            message.buy_coolstatus = reader.int32();
            break;
          }
          case 10: {
            message.rotationtime = reader.int64();
            break;
          }
          case 11: {
            message.countdown = reader.int64();
            break;
          }
          case 12: {
            message.event = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SurpriseUserInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SurpriseUserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SurpriseUserInfo} SurpriseUserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SurpriseUserInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SurpriseUserInfo message.
     * @function verify
     * @memberof client_proto.SurpriseUserInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SurpriseUserInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.sub_id != null && message.hasOwnProperty("sub_id"))
        if (!$util.isInteger(message.sub_id)) return "sub_id: integer expected";
      if (message.vip_level != null && message.hasOwnProperty("vip_level"))
        if (!$util.isInteger(message.vip_level)) return "vip_level: integer expected";
      if (message.show_count != null && message.hasOwnProperty("show_count"))
        if (!$util.isInteger(message.show_count)) return "show_count: integer expected";
      if (message.showing_count != null && message.hasOwnProperty("showing_count"))
        if (!$util.isInteger(message.showing_count)) return "showing_count: integer expected";
      if (message.buy_gifttotal != null && message.hasOwnProperty("buy_gifttotal"))
        if (!$util.isInteger(message.buy_gifttotal)) return "buy_gifttotal: integer expected";
      if (message.rotationgiftcount != null && message.hasOwnProperty("rotationgiftcount"))
        if (!$util.isInteger(message.rotationgiftcount)) return "rotationgiftcount: integer expected";
      if (message.buy_coolstatus != null && message.hasOwnProperty("buy_coolstatus"))
        if (!$util.isInteger(message.buy_coolstatus)) return "buy_coolstatus: integer expected";
      if (message.rotationtime != null && message.hasOwnProperty("rotationtime"))
        if (
          !$util.isInteger(message.rotationtime) &&
          !(
            message.rotationtime &&
            $util.isInteger(message.rotationtime.low) &&
            $util.isInteger(message.rotationtime.high)
          )
        )
          return "rotationtime: integer|Long expected";
      if (message.countdown != null && message.hasOwnProperty("countdown"))
        if (
          !$util.isInteger(message.countdown) &&
          !(message.countdown && $util.isInteger(message.countdown.low) && $util.isInteger(message.countdown.high))
        )
          return "countdown: integer|Long expected";
      if (message.event != null && message.hasOwnProperty("event"))
        if (!$util.isInteger(message.event)) return "event: integer expected";
      return null;
    };

    /**
     * Creates a SurpriseUserInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SurpriseUserInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SurpriseUserInfo} SurpriseUserInfo
     */
    SurpriseUserInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SurpriseUserInfo) return object;
      var message = new $root.client_proto.SurpriseUserInfo();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.sub_id != null) message.sub_id = object.sub_id | 0;
      if (object.vip_level != null) message.vip_level = object.vip_level | 0;
      if (object.show_count != null) message.show_count = object.show_count | 0;
      if (object.showing_count != null) message.showing_count = object.showing_count | 0;
      if (object.buy_gifttotal != null) message.buy_gifttotal = object.buy_gifttotal | 0;
      if (object.rotationgiftcount != null) message.rotationgiftcount = object.rotationgiftcount | 0;
      if (object.buy_coolstatus != null) message.buy_coolstatus = object.buy_coolstatus | 0;
      if (object.rotationtime != null)
        if ($util.Long) (message.rotationtime = $util.Long.fromValue(object.rotationtime)).unsigned = false;
        else if (typeof object.rotationtime === "string") message.rotationtime = parseInt(object.rotationtime, 10);
        else if (typeof object.rotationtime === "number") message.rotationtime = object.rotationtime;
        else if (typeof object.rotationtime === "object")
          message.rotationtime = new $util.LongBits(
            object.rotationtime.low >>> 0,
            object.rotationtime.high >>> 0,
          ).toNumber();
      if (object.countdown != null)
        if ($util.Long) (message.countdown = $util.Long.fromValue(object.countdown)).unsigned = false;
        else if (typeof object.countdown === "string") message.countdown = parseInt(object.countdown, 10);
        else if (typeof object.countdown === "number") message.countdown = object.countdown;
        else if (typeof object.countdown === "object")
          message.countdown = new $util.LongBits(object.countdown.low >>> 0, object.countdown.high >>> 0).toNumber();
      if (object.event != null) message.event = object.event | 0;
      return message;
    };

    /**
     * Creates a plain object from a SurpriseUserInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SurpriseUserInfo
     * @static
     * @param {client_proto.SurpriseUserInfo} message SurpriseUserInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SurpriseUserInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.activity_id = 0;
        object.sub_id = 0;
        object.vip_level = 0;
        object.show_count = 0;
        object.showing_count = 0;
        object.buy_gifttotal = 0;
        object.rotationgiftcount = 0;
        object.buy_coolstatus = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.rotationtime =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.rotationtime = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.countdown =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.countdown = options.longs === String ? "0" : 0;
        object.event = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.sub_id != null && message.hasOwnProperty("sub_id")) object.sub_id = message.sub_id;
      if (message.vip_level != null && message.hasOwnProperty("vip_level")) object.vip_level = message.vip_level;
      if (message.show_count != null && message.hasOwnProperty("show_count")) object.show_count = message.show_count;
      if (message.showing_count != null && message.hasOwnProperty("showing_count"))
        object.showing_count = message.showing_count;
      if (message.buy_gifttotal != null && message.hasOwnProperty("buy_gifttotal"))
        object.buy_gifttotal = message.buy_gifttotal;
      if (message.rotationgiftcount != null && message.hasOwnProperty("rotationgiftcount"))
        object.rotationgiftcount = message.rotationgiftcount;
      if (message.buy_coolstatus != null && message.hasOwnProperty("buy_coolstatus"))
        object.buy_coolstatus = message.buy_coolstatus;
      if (message.rotationtime != null && message.hasOwnProperty("rotationtime"))
        if (typeof message.rotationtime === "number")
          object.rotationtime = options.longs === String ? String(message.rotationtime) : message.rotationtime;
        else
          object.rotationtime =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.rotationtime)
              : options.longs === Number
                ? new $util.LongBits(message.rotationtime.low >>> 0, message.rotationtime.high >>> 0).toNumber()
                : message.rotationtime;
      if (message.countdown != null && message.hasOwnProperty("countdown"))
        if (typeof message.countdown === "number")
          object.countdown = options.longs === String ? String(message.countdown) : message.countdown;
        else
          object.countdown =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.countdown)
              : options.longs === Number
                ? new $util.LongBits(message.countdown.low >>> 0, message.countdown.high >>> 0).toNumber()
                : message.countdown;
      if (message.event != null && message.hasOwnProperty("event")) object.event = message.event;
      return object;
    };

    /**
     * Converts this SurpriseUserInfo to JSON.
     * @function toJSON
     * @memberof client_proto.SurpriseUserInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SurpriseUserInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SurpriseUserInfo
     * @function getTypeUrl
     * @memberof client_proto.SurpriseUserInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SurpriseUserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SurpriseUserInfo";
    };

    return SurpriseUserInfo;
  })();

  client_proto.UserBankruptcySituation = (function () {
    /**
     * Properties of a UserBankruptcySituation.
     * @memberof client_proto
     * @interface IUserBankruptcySituation
     * @property {number|null} [uid] UserBankruptcySituation uid
     * @property {number|null} [op_type] UserBankruptcySituation op_type
     * @property {boolean|null} [bankruptcy] UserBankruptcySituation bankruptcy
     * @property {number|Long|null} [bankruptcy_time] UserBankruptcySituation bankruptcy_time
     * @property {string|null} [trans] UserBankruptcySituation trans
     */

    /**
     * Constructs a new UserBankruptcySituation.
     * @memberof client_proto
     * @classdesc Represents a UserBankruptcySituation.
     * @implements IUserBankruptcySituation
     * @constructor
     * @param {client_proto.IUserBankruptcySituation=} [properties] Properties to set
     */
    function UserBankruptcySituation(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserBankruptcySituation uid.
     * @member {number} uid
     * @memberof client_proto.UserBankruptcySituation
     * @instance
     */
    UserBankruptcySituation.prototype.uid = 0;

    /**
     * UserBankruptcySituation op_type.
     * @member {number} op_type
     * @memberof client_proto.UserBankruptcySituation
     * @instance
     */
    UserBankruptcySituation.prototype.op_type = 0;

    /**
     * UserBankruptcySituation bankruptcy.
     * @member {boolean} bankruptcy
     * @memberof client_proto.UserBankruptcySituation
     * @instance
     */
    UserBankruptcySituation.prototype.bankruptcy = false;

    /**
     * UserBankruptcySituation bankruptcy_time.
     * @member {number|Long} bankruptcy_time
     * @memberof client_proto.UserBankruptcySituation
     * @instance
     */
    UserBankruptcySituation.prototype.bankruptcy_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * UserBankruptcySituation trans.
     * @member {string} trans
     * @memberof client_proto.UserBankruptcySituation
     * @instance
     */
    UserBankruptcySituation.prototype.trans = "";

    /**
     * Creates a new UserBankruptcySituation instance using the specified properties.
     * @function create
     * @memberof client_proto.UserBankruptcySituation
     * @static
     * @param {client_proto.IUserBankruptcySituation=} [properties] Properties to set
     * @returns {client_proto.UserBankruptcySituation} UserBankruptcySituation instance
     */
    UserBankruptcySituation.create = function create(properties) {
      return new UserBankruptcySituation(properties);
    };

    /**
     * Encodes the specified UserBankruptcySituation message. Does not implicitly {@link client_proto.UserBankruptcySituation.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserBankruptcySituation
     * @static
     * @param {client_proto.IUserBankruptcySituation} message UserBankruptcySituation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserBankruptcySituation.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.op_type != null && Object.hasOwnProperty.call(message, "op_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.op_type);
      if (message.bankruptcy != null && Object.hasOwnProperty.call(message, "bankruptcy"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).bool(message.bankruptcy);
      if (message.bankruptcy_time != null && Object.hasOwnProperty.call(message, "bankruptcy_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.bankruptcy_time);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.trans);
      return writer;
    };

    /**
     * Encodes the specified UserBankruptcySituation message, length delimited. Does not implicitly {@link client_proto.UserBankruptcySituation.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserBankruptcySituation
     * @static
     * @param {client_proto.IUserBankruptcySituation} message UserBankruptcySituation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserBankruptcySituation.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserBankruptcySituation message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserBankruptcySituation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserBankruptcySituation} UserBankruptcySituation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserBankruptcySituation.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserBankruptcySituation();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.op_type = reader.int32();
            break;
          }
          case 3: {
            message.bankruptcy = reader.bool();
            break;
          }
          case 4: {
            message.bankruptcy_time = reader.int64();
            break;
          }
          case 5: {
            message.trans = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserBankruptcySituation message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserBankruptcySituation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserBankruptcySituation} UserBankruptcySituation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserBankruptcySituation.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserBankruptcySituation message.
     * @function verify
     * @memberof client_proto.UserBankruptcySituation
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserBankruptcySituation.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.op_type != null && message.hasOwnProperty("op_type"))
        if (!$util.isInteger(message.op_type)) return "op_type: integer expected";
      if (message.bankruptcy != null && message.hasOwnProperty("bankruptcy"))
        if (typeof message.bankruptcy !== "boolean") return "bankruptcy: boolean expected";
      if (message.bankruptcy_time != null && message.hasOwnProperty("bankruptcy_time"))
        if (
          !$util.isInteger(message.bankruptcy_time) &&
          !(
            message.bankruptcy_time &&
            $util.isInteger(message.bankruptcy_time.low) &&
            $util.isInteger(message.bankruptcy_time.high)
          )
        )
          return "bankruptcy_time: integer|Long expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!$util.isString(message.trans)) return "trans: string expected";
      return null;
    };

    /**
     * Creates a UserBankruptcySituation message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserBankruptcySituation
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserBankruptcySituation} UserBankruptcySituation
     */
    UserBankruptcySituation.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserBankruptcySituation) return object;
      var message = new $root.client_proto.UserBankruptcySituation();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.op_type != null) message.op_type = object.op_type | 0;
      if (object.bankruptcy != null) message.bankruptcy = Boolean(object.bankruptcy);
      if (object.bankruptcy_time != null)
        if ($util.Long) (message.bankruptcy_time = $util.Long.fromValue(object.bankruptcy_time)).unsigned = false;
        else if (typeof object.bankruptcy_time === "string")
          message.bankruptcy_time = parseInt(object.bankruptcy_time, 10);
        else if (typeof object.bankruptcy_time === "number") message.bankruptcy_time = object.bankruptcy_time;
        else if (typeof object.bankruptcy_time === "object")
          message.bankruptcy_time = new $util.LongBits(
            object.bankruptcy_time.low >>> 0,
            object.bankruptcy_time.high >>> 0,
          ).toNumber();
      if (object.trans != null) message.trans = String(object.trans);
      return message;
    };

    /**
     * Creates a plain object from a UserBankruptcySituation message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserBankruptcySituation
     * @static
     * @param {client_proto.UserBankruptcySituation} message UserBankruptcySituation
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserBankruptcySituation.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.op_type = 0;
        object.bankruptcy = false;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.bankruptcy_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.bankruptcy_time = options.longs === String ? "0" : 0;
        object.trans = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.op_type != null && message.hasOwnProperty("op_type")) object.op_type = message.op_type;
      if (message.bankruptcy != null && message.hasOwnProperty("bankruptcy")) object.bankruptcy = message.bankruptcy;
      if (message.bankruptcy_time != null && message.hasOwnProperty("bankruptcy_time"))
        if (typeof message.bankruptcy_time === "number")
          object.bankruptcy_time = options.longs === String ? String(message.bankruptcy_time) : message.bankruptcy_time;
        else
          object.bankruptcy_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.bankruptcy_time)
              : options.longs === Number
                ? new $util.LongBits(message.bankruptcy_time.low >>> 0, message.bankruptcy_time.high >>> 0).toNumber()
                : message.bankruptcy_time;
      if (message.trans != null && message.hasOwnProperty("trans")) object.trans = message.trans;
      return object;
    };

    /**
     * Converts this UserBankruptcySituation to JSON.
     * @function toJSON
     * @memberof client_proto.UserBankruptcySituation
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserBankruptcySituation.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserBankruptcySituation
     * @function getTypeUrl
     * @memberof client_proto.UserBankruptcySituation
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserBankruptcySituation.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserBankruptcySituation";
    };

    return UserBankruptcySituation;
  })();

  client_proto.UserAssetUpdateNotice = (function () {
    /**
     * Properties of a UserAssetUpdateNotice.
     * @memberof client_proto
     * @interface IUserAssetUpdateNotice
     * @property {number|null} [uid] UserAssetUpdateNotice uid
     * @property {number|Long|null} [bankruptcy_time] UserAssetUpdateNotice bankruptcy_time
     */

    /**
     * Constructs a new UserAssetUpdateNotice.
     * @memberof client_proto
     * @classdesc Represents a UserAssetUpdateNotice.
     * @implements IUserAssetUpdateNotice
     * @constructor
     * @param {client_proto.IUserAssetUpdateNotice=} [properties] Properties to set
     */
    function UserAssetUpdateNotice(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserAssetUpdateNotice uid.
     * @member {number} uid
     * @memberof client_proto.UserAssetUpdateNotice
     * @instance
     */
    UserAssetUpdateNotice.prototype.uid = 0;

    /**
     * UserAssetUpdateNotice bankruptcy_time.
     * @member {number|Long} bankruptcy_time
     * @memberof client_proto.UserAssetUpdateNotice
     * @instance
     */
    UserAssetUpdateNotice.prototype.bankruptcy_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new UserAssetUpdateNotice instance using the specified properties.
     * @function create
     * @memberof client_proto.UserAssetUpdateNotice
     * @static
     * @param {client_proto.IUserAssetUpdateNotice=} [properties] Properties to set
     * @returns {client_proto.UserAssetUpdateNotice} UserAssetUpdateNotice instance
     */
    UserAssetUpdateNotice.create = function create(properties) {
      return new UserAssetUpdateNotice(properties);
    };

    /**
     * Encodes the specified UserAssetUpdateNotice message. Does not implicitly {@link client_proto.UserAssetUpdateNotice.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserAssetUpdateNotice
     * @static
     * @param {client_proto.IUserAssetUpdateNotice} message UserAssetUpdateNotice message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserAssetUpdateNotice.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.bankruptcy_time != null && Object.hasOwnProperty.call(message, "bankruptcy_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.bankruptcy_time);
      return writer;
    };

    /**
     * Encodes the specified UserAssetUpdateNotice message, length delimited. Does not implicitly {@link client_proto.UserAssetUpdateNotice.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserAssetUpdateNotice
     * @static
     * @param {client_proto.IUserAssetUpdateNotice} message UserAssetUpdateNotice message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserAssetUpdateNotice.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserAssetUpdateNotice message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserAssetUpdateNotice
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserAssetUpdateNotice} UserAssetUpdateNotice
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserAssetUpdateNotice.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserAssetUpdateNotice();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 4: {
            message.bankruptcy_time = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserAssetUpdateNotice message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserAssetUpdateNotice
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserAssetUpdateNotice} UserAssetUpdateNotice
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserAssetUpdateNotice.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserAssetUpdateNotice message.
     * @function verify
     * @memberof client_proto.UserAssetUpdateNotice
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserAssetUpdateNotice.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.bankruptcy_time != null && message.hasOwnProperty("bankruptcy_time"))
        if (
          !$util.isInteger(message.bankruptcy_time) &&
          !(
            message.bankruptcy_time &&
            $util.isInteger(message.bankruptcy_time.low) &&
            $util.isInteger(message.bankruptcy_time.high)
          )
        )
          return "bankruptcy_time: integer|Long expected";
      return null;
    };

    /**
     * Creates a UserAssetUpdateNotice message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserAssetUpdateNotice
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserAssetUpdateNotice} UserAssetUpdateNotice
     */
    UserAssetUpdateNotice.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserAssetUpdateNotice) return object;
      var message = new $root.client_proto.UserAssetUpdateNotice();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.bankruptcy_time != null)
        if ($util.Long) (message.bankruptcy_time = $util.Long.fromValue(object.bankruptcy_time)).unsigned = false;
        else if (typeof object.bankruptcy_time === "string")
          message.bankruptcy_time = parseInt(object.bankruptcy_time, 10);
        else if (typeof object.bankruptcy_time === "number") message.bankruptcy_time = object.bankruptcy_time;
        else if (typeof object.bankruptcy_time === "object")
          message.bankruptcy_time = new $util.LongBits(
            object.bankruptcy_time.low >>> 0,
            object.bankruptcy_time.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a UserAssetUpdateNotice message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserAssetUpdateNotice
     * @static
     * @param {client_proto.UserAssetUpdateNotice} message UserAssetUpdateNotice
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserAssetUpdateNotice.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.bankruptcy_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.bankruptcy_time = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.bankruptcy_time != null && message.hasOwnProperty("bankruptcy_time"))
        if (typeof message.bankruptcy_time === "number")
          object.bankruptcy_time = options.longs === String ? String(message.bankruptcy_time) : message.bankruptcy_time;
        else
          object.bankruptcy_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.bankruptcy_time)
              : options.longs === Number
                ? new $util.LongBits(message.bankruptcy_time.low >>> 0, message.bankruptcy_time.high >>> 0).toNumber()
                : message.bankruptcy_time;
      return object;
    };

    /**
     * Converts this UserAssetUpdateNotice to JSON.
     * @function toJSON
     * @memberof client_proto.UserAssetUpdateNotice
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserAssetUpdateNotice.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserAssetUpdateNotice
     * @function getTypeUrl
     * @memberof client_proto.UserAssetUpdateNotice
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserAssetUpdateNotice.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserAssetUpdateNotice";
    };

    return UserAssetUpdateNotice;
  })();

  client_proto.PbSurpriseGiftStatisticEvent = (function () {
    /**
     * Properties of a PbSurpriseGiftStatisticEvent.
     * @memberof client_proto
     * @interface IPbSurpriseGiftStatisticEvent
     * @property {number|null} [uid] PbSurpriseGiftStatisticEvent uid
     * @property {number|null} [activity_type] PbSurpriseGiftStatisticEvent activity_type
     * @property {number|null} [activity_id] PbSurpriseGiftStatisticEvent activity_id
     * @property {number|null} [subid] PbSurpriseGiftStatisticEvent subid
     * @property {number|null} [vip_level] PbSurpriseGiftStatisticEvent vip_level
     * @property {number|null} [event] PbSurpriseGiftStatisticEvent event
     * @property {number|null} [charge_id] PbSurpriseGiftStatisticEvent charge_id
     * @property {number|Long|null} [price] PbSurpriseGiftStatisticEvent price
     * @property {number|Long|null} [face_price] PbSurpriseGiftStatisticEvent face_price
     * @property {number|Long|null} [reward] PbSurpriseGiftStatisticEvent reward
     * @property {number|Long|null} [need_flow] PbSurpriseGiftStatisticEvent need_flow
     * @property {number|Long|null} [create_time] PbSurpriseGiftStatisticEvent create_time
     */

    /**
     * Constructs a new PbSurpriseGiftStatisticEvent.
     * @memberof client_proto
     * @classdesc Represents a PbSurpriseGiftStatisticEvent.
     * @implements IPbSurpriseGiftStatisticEvent
     * @constructor
     * @param {client_proto.IPbSurpriseGiftStatisticEvent=} [properties] Properties to set
     */
    function PbSurpriseGiftStatisticEvent(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PbSurpriseGiftStatisticEvent uid.
     * @member {number} uid
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.uid = 0;

    /**
     * PbSurpriseGiftStatisticEvent activity_type.
     * @member {number} activity_type
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.activity_type = 0;

    /**
     * PbSurpriseGiftStatisticEvent activity_id.
     * @member {number} activity_id
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.activity_id = 0;

    /**
     * PbSurpriseGiftStatisticEvent subid.
     * @member {number} subid
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.subid = 0;

    /**
     * PbSurpriseGiftStatisticEvent vip_level.
     * @member {number} vip_level
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.vip_level = 0;

    /**
     * PbSurpriseGiftStatisticEvent event.
     * @member {number} event
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.event = 0;

    /**
     * PbSurpriseGiftStatisticEvent charge_id.
     * @member {number} charge_id
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.charge_id = 0;

    /**
     * PbSurpriseGiftStatisticEvent price.
     * @member {number|Long} price
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.price = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PbSurpriseGiftStatisticEvent face_price.
     * @member {number|Long} face_price
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.face_price = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PbSurpriseGiftStatisticEvent reward.
     * @member {number|Long} reward
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.reward = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PbSurpriseGiftStatisticEvent need_flow.
     * @member {number|Long} need_flow
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.need_flow = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PbSurpriseGiftStatisticEvent create_time.
     * @member {number|Long} create_time
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     */
    PbSurpriseGiftStatisticEvent.prototype.create_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new PbSurpriseGiftStatisticEvent instance using the specified properties.
     * @function create
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @static
     * @param {client_proto.IPbSurpriseGiftStatisticEvent=} [properties] Properties to set
     * @returns {client_proto.PbSurpriseGiftStatisticEvent} PbSurpriseGiftStatisticEvent instance
     */
    PbSurpriseGiftStatisticEvent.create = function create(properties) {
      return new PbSurpriseGiftStatisticEvent(properties);
    };

    /**
     * Encodes the specified PbSurpriseGiftStatisticEvent message. Does not implicitly {@link client_proto.PbSurpriseGiftStatisticEvent.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @static
     * @param {client_proto.IPbSurpriseGiftStatisticEvent} message PbSurpriseGiftStatisticEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PbSurpriseGiftStatisticEvent.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.activity_type != null && Object.hasOwnProperty.call(message, "activity_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.activity_type);
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.activity_id);
      if (message.subid != null && Object.hasOwnProperty.call(message, "subid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.subid);
      if (message.vip_level != null && Object.hasOwnProperty.call(message, "vip_level"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.vip_level);
      if (message.event != null && Object.hasOwnProperty.call(message, "event"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.event);
      if (message.charge_id != null && Object.hasOwnProperty.call(message, "charge_id"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.charge_id);
      if (message.price != null && Object.hasOwnProperty.call(message, "price"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.price);
      if (message.face_price != null && Object.hasOwnProperty.call(message, "face_price"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.face_price);
      if (message.reward != null && Object.hasOwnProperty.call(message, "reward"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.reward);
      if (message.need_flow != null && Object.hasOwnProperty.call(message, "need_flow"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int64(message.need_flow);
      if (message.create_time != null && Object.hasOwnProperty.call(message, "create_time"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).int64(message.create_time);
      return writer;
    };

    /**
     * Encodes the specified PbSurpriseGiftStatisticEvent message, length delimited. Does not implicitly {@link client_proto.PbSurpriseGiftStatisticEvent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @static
     * @param {client_proto.IPbSurpriseGiftStatisticEvent} message PbSurpriseGiftStatisticEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PbSurpriseGiftStatisticEvent.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PbSurpriseGiftStatisticEvent message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PbSurpriseGiftStatisticEvent} PbSurpriseGiftStatisticEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PbSurpriseGiftStatisticEvent.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PbSurpriseGiftStatisticEvent();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.activity_type = reader.int32();
            break;
          }
          case 3: {
            message.activity_id = reader.int32();
            break;
          }
          case 4: {
            message.subid = reader.int32();
            break;
          }
          case 5: {
            message.vip_level = reader.int32();
            break;
          }
          case 6: {
            message.event = reader.int32();
            break;
          }
          case 7: {
            message.charge_id = reader.int32();
            break;
          }
          case 8: {
            message.price = reader.int64();
            break;
          }
          case 9: {
            message.face_price = reader.int64();
            break;
          }
          case 10: {
            message.reward = reader.int64();
            break;
          }
          case 11: {
            message.need_flow = reader.int64();
            break;
          }
          case 12: {
            message.create_time = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PbSurpriseGiftStatisticEvent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PbSurpriseGiftStatisticEvent} PbSurpriseGiftStatisticEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PbSurpriseGiftStatisticEvent.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PbSurpriseGiftStatisticEvent message.
     * @function verify
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PbSurpriseGiftStatisticEvent.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.activity_type != null && message.hasOwnProperty("activity_type"))
        if (!$util.isInteger(message.activity_type)) return "activity_type: integer expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.subid != null && message.hasOwnProperty("subid"))
        if (!$util.isInteger(message.subid)) return "subid: integer expected";
      if (message.vip_level != null && message.hasOwnProperty("vip_level"))
        if (!$util.isInteger(message.vip_level)) return "vip_level: integer expected";
      if (message.event != null && message.hasOwnProperty("event"))
        if (!$util.isInteger(message.event)) return "event: integer expected";
      if (message.charge_id != null && message.hasOwnProperty("charge_id"))
        if (!$util.isInteger(message.charge_id)) return "charge_id: integer expected";
      if (message.price != null && message.hasOwnProperty("price"))
        if (
          !$util.isInteger(message.price) &&
          !(message.price && $util.isInteger(message.price.low) && $util.isInteger(message.price.high))
        )
          return "price: integer|Long expected";
      if (message.face_price != null && message.hasOwnProperty("face_price"))
        if (
          !$util.isInteger(message.face_price) &&
          !(message.face_price && $util.isInteger(message.face_price.low) && $util.isInteger(message.face_price.high))
        )
          return "face_price: integer|Long expected";
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (
          !$util.isInteger(message.reward) &&
          !(message.reward && $util.isInteger(message.reward.low) && $util.isInteger(message.reward.high))
        )
          return "reward: integer|Long expected";
      if (message.need_flow != null && message.hasOwnProperty("need_flow"))
        if (
          !$util.isInteger(message.need_flow) &&
          !(message.need_flow && $util.isInteger(message.need_flow.low) && $util.isInteger(message.need_flow.high))
        )
          return "need_flow: integer|Long expected";
      if (message.create_time != null && message.hasOwnProperty("create_time"))
        if (
          !$util.isInteger(message.create_time) &&
          !(
            message.create_time &&
            $util.isInteger(message.create_time.low) &&
            $util.isInteger(message.create_time.high)
          )
        )
          return "create_time: integer|Long expected";
      return null;
    };

    /**
     * Creates a PbSurpriseGiftStatisticEvent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PbSurpriseGiftStatisticEvent} PbSurpriseGiftStatisticEvent
     */
    PbSurpriseGiftStatisticEvent.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PbSurpriseGiftStatisticEvent) return object;
      var message = new $root.client_proto.PbSurpriseGiftStatisticEvent();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.activity_type != null) message.activity_type = object.activity_type | 0;
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.subid != null) message.subid = object.subid | 0;
      if (object.vip_level != null) message.vip_level = object.vip_level | 0;
      if (object.event != null) message.event = object.event | 0;
      if (object.charge_id != null) message.charge_id = object.charge_id | 0;
      if (object.price != null)
        if ($util.Long) (message.price = $util.Long.fromValue(object.price)).unsigned = false;
        else if (typeof object.price === "string") message.price = parseInt(object.price, 10);
        else if (typeof object.price === "number") message.price = object.price;
        else if (typeof object.price === "object")
          message.price = new $util.LongBits(object.price.low >>> 0, object.price.high >>> 0).toNumber();
      if (object.face_price != null)
        if ($util.Long) (message.face_price = $util.Long.fromValue(object.face_price)).unsigned = false;
        else if (typeof object.face_price === "string") message.face_price = parseInt(object.face_price, 10);
        else if (typeof object.face_price === "number") message.face_price = object.face_price;
        else if (typeof object.face_price === "object")
          message.face_price = new $util.LongBits(object.face_price.low >>> 0, object.face_price.high >>> 0).toNumber();
      if (object.reward != null)
        if ($util.Long) (message.reward = $util.Long.fromValue(object.reward)).unsigned = false;
        else if (typeof object.reward === "string") message.reward = parseInt(object.reward, 10);
        else if (typeof object.reward === "number") message.reward = object.reward;
        else if (typeof object.reward === "object")
          message.reward = new $util.LongBits(object.reward.low >>> 0, object.reward.high >>> 0).toNumber();
      if (object.need_flow != null)
        if ($util.Long) (message.need_flow = $util.Long.fromValue(object.need_flow)).unsigned = false;
        else if (typeof object.need_flow === "string") message.need_flow = parseInt(object.need_flow, 10);
        else if (typeof object.need_flow === "number") message.need_flow = object.need_flow;
        else if (typeof object.need_flow === "object")
          message.need_flow = new $util.LongBits(object.need_flow.low >>> 0, object.need_flow.high >>> 0).toNumber();
      if (object.create_time != null)
        if ($util.Long) (message.create_time = $util.Long.fromValue(object.create_time)).unsigned = false;
        else if (typeof object.create_time === "string") message.create_time = parseInt(object.create_time, 10);
        else if (typeof object.create_time === "number") message.create_time = object.create_time;
        else if (typeof object.create_time === "object")
          message.create_time = new $util.LongBits(
            object.create_time.low >>> 0,
            object.create_time.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a PbSurpriseGiftStatisticEvent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @static
     * @param {client_proto.PbSurpriseGiftStatisticEvent} message PbSurpriseGiftStatisticEvent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PbSurpriseGiftStatisticEvent.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.activity_type = 0;
        object.activity_id = 0;
        object.subid = 0;
        object.vip_level = 0;
        object.event = 0;
        object.charge_id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.price = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.price = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.face_price =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.face_price = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.need_flow =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.need_flow = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.create_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.create_time = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.activity_type != null && message.hasOwnProperty("activity_type"))
        object.activity_type = message.activity_type;
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.subid != null && message.hasOwnProperty("subid")) object.subid = message.subid;
      if (message.vip_level != null && message.hasOwnProperty("vip_level")) object.vip_level = message.vip_level;
      if (message.event != null && message.hasOwnProperty("event")) object.event = message.event;
      if (message.charge_id != null && message.hasOwnProperty("charge_id")) object.charge_id = message.charge_id;
      if (message.price != null && message.hasOwnProperty("price"))
        if (typeof message.price === "number")
          object.price = options.longs === String ? String(message.price) : message.price;
        else
          object.price =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.price)
              : options.longs === Number
                ? new $util.LongBits(message.price.low >>> 0, message.price.high >>> 0).toNumber()
                : message.price;
      if (message.face_price != null && message.hasOwnProperty("face_price"))
        if (typeof message.face_price === "number")
          object.face_price = options.longs === String ? String(message.face_price) : message.face_price;
        else
          object.face_price =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.face_price)
              : options.longs === Number
                ? new $util.LongBits(message.face_price.low >>> 0, message.face_price.high >>> 0).toNumber()
                : message.face_price;
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (typeof message.reward === "number")
          object.reward = options.longs === String ? String(message.reward) : message.reward;
        else
          object.reward =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward)
              : options.longs === Number
                ? new $util.LongBits(message.reward.low >>> 0, message.reward.high >>> 0).toNumber()
                : message.reward;
      if (message.need_flow != null && message.hasOwnProperty("need_flow"))
        if (typeof message.need_flow === "number")
          object.need_flow = options.longs === String ? String(message.need_flow) : message.need_flow;
        else
          object.need_flow =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.need_flow)
              : options.longs === Number
                ? new $util.LongBits(message.need_flow.low >>> 0, message.need_flow.high >>> 0).toNumber()
                : message.need_flow;
      if (message.create_time != null && message.hasOwnProperty("create_time"))
        if (typeof message.create_time === "number")
          object.create_time = options.longs === String ? String(message.create_time) : message.create_time;
        else
          object.create_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.create_time)
              : options.longs === Number
                ? new $util.LongBits(message.create_time.low >>> 0, message.create_time.high >>> 0).toNumber()
                : message.create_time;
      return object;
    };

    /**
     * Converts this PbSurpriseGiftStatisticEvent to JSON.
     * @function toJSON
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PbSurpriseGiftStatisticEvent.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PbSurpriseGiftStatisticEvent
     * @function getTypeUrl
     * @memberof client_proto.PbSurpriseGiftStatisticEvent
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PbSurpriseGiftStatisticEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PbSurpriseGiftStatisticEvent";
    };

    return PbSurpriseGiftStatisticEvent;
  })();

  client_proto.UserLoginEvent = (function () {
    /**
     * Properties of a UserLoginEvent.
     * @memberof client_proto
     * @interface IUserLoginEvent
     * @property {number|null} [uid] UserLoginEvent uid
     * @property {number|Long|null} [last_logintime] UserLoginEvent last_logintime
     * @property {number|Long|null} [logintime] UserLoginEvent logintime
     */

    /**
     * Constructs a new UserLoginEvent.
     * @memberof client_proto
     * @classdesc Represents a UserLoginEvent.
     * @implements IUserLoginEvent
     * @constructor
     * @param {client_proto.IUserLoginEvent=} [properties] Properties to set
     */
    function UserLoginEvent(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserLoginEvent uid.
     * @member {number} uid
     * @memberof client_proto.UserLoginEvent
     * @instance
     */
    UserLoginEvent.prototype.uid = 0;

    /**
     * UserLoginEvent last_logintime.
     * @member {number|Long} last_logintime
     * @memberof client_proto.UserLoginEvent
     * @instance
     */
    UserLoginEvent.prototype.last_logintime = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * UserLoginEvent logintime.
     * @member {number|Long} logintime
     * @memberof client_proto.UserLoginEvent
     * @instance
     */
    UserLoginEvent.prototype.logintime = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new UserLoginEvent instance using the specified properties.
     * @function create
     * @memberof client_proto.UserLoginEvent
     * @static
     * @param {client_proto.IUserLoginEvent=} [properties] Properties to set
     * @returns {client_proto.UserLoginEvent} UserLoginEvent instance
     */
    UserLoginEvent.create = function create(properties) {
      return new UserLoginEvent(properties);
    };

    /**
     * Encodes the specified UserLoginEvent message. Does not implicitly {@link client_proto.UserLoginEvent.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserLoginEvent
     * @static
     * @param {client_proto.IUserLoginEvent} message UserLoginEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserLoginEvent.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.last_logintime != null && Object.hasOwnProperty.call(message, "last_logintime"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.last_logintime);
      if (message.logintime != null && Object.hasOwnProperty.call(message, "logintime"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.logintime);
      return writer;
    };

    /**
     * Encodes the specified UserLoginEvent message, length delimited. Does not implicitly {@link client_proto.UserLoginEvent.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserLoginEvent
     * @static
     * @param {client_proto.IUserLoginEvent} message UserLoginEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserLoginEvent.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserLoginEvent message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserLoginEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserLoginEvent} UserLoginEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserLoginEvent.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserLoginEvent();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.last_logintime = reader.int64();
            break;
          }
          case 3: {
            message.logintime = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserLoginEvent message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserLoginEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserLoginEvent} UserLoginEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserLoginEvent.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserLoginEvent message.
     * @function verify
     * @memberof client_proto.UserLoginEvent
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserLoginEvent.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.last_logintime != null && message.hasOwnProperty("last_logintime"))
        if (
          !$util.isInteger(message.last_logintime) &&
          !(
            message.last_logintime &&
            $util.isInteger(message.last_logintime.low) &&
            $util.isInteger(message.last_logintime.high)
          )
        )
          return "last_logintime: integer|Long expected";
      if (message.logintime != null && message.hasOwnProperty("logintime"))
        if (
          !$util.isInteger(message.logintime) &&
          !(message.logintime && $util.isInteger(message.logintime.low) && $util.isInteger(message.logintime.high))
        )
          return "logintime: integer|Long expected";
      return null;
    };

    /**
     * Creates a UserLoginEvent message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserLoginEvent
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserLoginEvent} UserLoginEvent
     */
    UserLoginEvent.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserLoginEvent) return object;
      var message = new $root.client_proto.UserLoginEvent();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.last_logintime != null)
        if ($util.Long) (message.last_logintime = $util.Long.fromValue(object.last_logintime)).unsigned = false;
        else if (typeof object.last_logintime === "string")
          message.last_logintime = parseInt(object.last_logintime, 10);
        else if (typeof object.last_logintime === "number") message.last_logintime = object.last_logintime;
        else if (typeof object.last_logintime === "object")
          message.last_logintime = new $util.LongBits(
            object.last_logintime.low >>> 0,
            object.last_logintime.high >>> 0,
          ).toNumber();
      if (object.logintime != null)
        if ($util.Long) (message.logintime = $util.Long.fromValue(object.logintime)).unsigned = false;
        else if (typeof object.logintime === "string") message.logintime = parseInt(object.logintime, 10);
        else if (typeof object.logintime === "number") message.logintime = object.logintime;
        else if (typeof object.logintime === "object")
          message.logintime = new $util.LongBits(object.logintime.low >>> 0, object.logintime.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a UserLoginEvent message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserLoginEvent
     * @static
     * @param {client_proto.UserLoginEvent} message UserLoginEvent
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserLoginEvent.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.last_logintime =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.last_logintime = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.logintime =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.logintime = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.last_logintime != null && message.hasOwnProperty("last_logintime"))
        if (typeof message.last_logintime === "number")
          object.last_logintime = options.longs === String ? String(message.last_logintime) : message.last_logintime;
        else
          object.last_logintime =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.last_logintime)
              : options.longs === Number
                ? new $util.LongBits(message.last_logintime.low >>> 0, message.last_logintime.high >>> 0).toNumber()
                : message.last_logintime;
      if (message.logintime != null && message.hasOwnProperty("logintime"))
        if (typeof message.logintime === "number")
          object.logintime = options.longs === String ? String(message.logintime) : message.logintime;
        else
          object.logintime =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.logintime)
              : options.longs === Number
                ? new $util.LongBits(message.logintime.low >>> 0, message.logintime.high >>> 0).toNumber()
                : message.logintime;
      return object;
    };

    /**
     * Converts this UserLoginEvent to JSON.
     * @function toJSON
     * @memberof client_proto.UserLoginEvent
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserLoginEvent.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserLoginEvent
     * @function getTypeUrl
     * @memberof client_proto.UserLoginEvent
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserLoginEvent.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserLoginEvent";
    };

    return UserLoginEvent;
  })();

  client_proto.SrvActivityListReq = (function () {
    /**
     * Properties of a SrvActivityListReq.
     * @memberof client_proto
     * @interface ISrvActivityListReq
     * @property {number|null} [uid] SrvActivityListReq uid
     * @property {Uint8Array|null} [trans_data] SrvActivityListReq trans_data
     */

    /**
     * Constructs a new SrvActivityListReq.
     * @memberof client_proto
     * @classdesc Represents a SrvActivityListReq.
     * @implements ISrvActivityListReq
     * @constructor
     * @param {client_proto.ISrvActivityListReq=} [properties] Properties to set
     */
    function SrvActivityListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SrvActivityListReq uid.
     * @member {number} uid
     * @memberof client_proto.SrvActivityListReq
     * @instance
     */
    SrvActivityListReq.prototype.uid = 0;

    /**
     * SrvActivityListReq trans_data.
     * @member {Uint8Array} trans_data
     * @memberof client_proto.SrvActivityListReq
     * @instance
     */
    SrvActivityListReq.prototype.trans_data = $util.newBuffer([]);

    /**
     * Creates a new SrvActivityListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SrvActivityListReq
     * @static
     * @param {client_proto.ISrvActivityListReq=} [properties] Properties to set
     * @returns {client_proto.SrvActivityListReq} SrvActivityListReq instance
     */
    SrvActivityListReq.create = function create(properties) {
      return new SrvActivityListReq(properties);
    };

    /**
     * Encodes the specified SrvActivityListReq message. Does not implicitly {@link client_proto.SrvActivityListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SrvActivityListReq
     * @static
     * @param {client_proto.ISrvActivityListReq} message SrvActivityListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvActivityListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.trans_data != null && Object.hasOwnProperty.call(message, "trans_data"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).bytes(message.trans_data);
      return writer;
    };

    /**
     * Encodes the specified SrvActivityListReq message, length delimited. Does not implicitly {@link client_proto.SrvActivityListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SrvActivityListReq
     * @static
     * @param {client_proto.ISrvActivityListReq} message SrvActivityListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvActivityListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SrvActivityListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SrvActivityListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SrvActivityListReq} SrvActivityListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvActivityListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SrvActivityListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.trans_data = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SrvActivityListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SrvActivityListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SrvActivityListReq} SrvActivityListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvActivityListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SrvActivityListReq message.
     * @function verify
     * @memberof client_proto.SrvActivityListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SrvActivityListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        if (
          !((message.trans_data && typeof message.trans_data.length === "number") || $util.isString(message.trans_data))
        )
          return "trans_data: buffer expected";
      return null;
    };

    /**
     * Creates a SrvActivityListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SrvActivityListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SrvActivityListReq} SrvActivityListReq
     */
    SrvActivityListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SrvActivityListReq) return object;
      var message = new $root.client_proto.SrvActivityListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.trans_data != null)
        if (typeof object.trans_data === "string")
          $util.base64.decode(
            object.trans_data,
            (message.trans_data = $util.newBuffer($util.base64.length(object.trans_data))),
            0,
          );
        else if (object.trans_data.length >= 0) message.trans_data = object.trans_data;
      return message;
    };

    /**
     * Creates a plain object from a SrvActivityListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SrvActivityListReq
     * @static
     * @param {client_proto.SrvActivityListReq} message SrvActivityListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SrvActivityListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if (options.bytes === String) object.trans_data = "";
        else {
          object.trans_data = [];
          if (options.bytes !== Array) object.trans_data = $util.newBuffer(object.trans_data);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        object.trans_data =
          options.bytes === String
            ? $util.base64.encode(message.trans_data, 0, message.trans_data.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans_data)
              : message.trans_data;
      return object;
    };

    /**
     * Converts this SrvActivityListReq to JSON.
     * @function toJSON
     * @memberof client_proto.SrvActivityListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SrvActivityListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SrvActivityListReq
     * @function getTypeUrl
     * @memberof client_proto.SrvActivityListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SrvActivityListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SrvActivityListReq";
    };

    return SrvActivityListReq;
  })();

  client_proto.ActvityInfo = (function () {
    /**
     * Properties of an ActvityInfo.
     * @memberof client_proto
     * @interface IActvityInfo
     * @property {number|null} [activity_id] ActvityInfo activity_id
     * @property {number|null} [package_goods_id] ActvityInfo package_goods_id
     */

    /**
     * Constructs a new ActvityInfo.
     * @memberof client_proto
     * @classdesc Represents an ActvityInfo.
     * @implements IActvityInfo
     * @constructor
     * @param {client_proto.IActvityInfo=} [properties] Properties to set
     */
    function ActvityInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ActvityInfo activity_id.
     * @member {number} activity_id
     * @memberof client_proto.ActvityInfo
     * @instance
     */
    ActvityInfo.prototype.activity_id = 0;

    /**
     * ActvityInfo package_goods_id.
     * @member {number} package_goods_id
     * @memberof client_proto.ActvityInfo
     * @instance
     */
    ActvityInfo.prototype.package_goods_id = 0;

    /**
     * Creates a new ActvityInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.ActvityInfo
     * @static
     * @param {client_proto.IActvityInfo=} [properties] Properties to set
     * @returns {client_proto.ActvityInfo} ActvityInfo instance
     */
    ActvityInfo.create = function create(properties) {
      return new ActvityInfo(properties);
    };

    /**
     * Encodes the specified ActvityInfo message. Does not implicitly {@link client_proto.ActvityInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ActvityInfo
     * @static
     * @param {client_proto.IActvityInfo} message ActvityInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActvityInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.activity_id != null && Object.hasOwnProperty.call(message, "activity_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.activity_id);
      if (message.package_goods_id != null && Object.hasOwnProperty.call(message, "package_goods_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.package_goods_id);
      return writer;
    };

    /**
     * Encodes the specified ActvityInfo message, length delimited. Does not implicitly {@link client_proto.ActvityInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ActvityInfo
     * @static
     * @param {client_proto.IActvityInfo} message ActvityInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ActvityInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ActvityInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ActvityInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ActvityInfo} ActvityInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActvityInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ActvityInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.activity_id = reader.int32();
            break;
          }
          case 2: {
            message.package_goods_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an ActvityInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ActvityInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ActvityInfo} ActvityInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ActvityInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ActvityInfo message.
     * @function verify
     * @memberof client_proto.ActvityInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ActvityInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        if (!$util.isInteger(message.activity_id)) return "activity_id: integer expected";
      if (message.package_goods_id != null && message.hasOwnProperty("package_goods_id"))
        if (!$util.isInteger(message.package_goods_id)) return "package_goods_id: integer expected";
      return null;
    };

    /**
     * Creates an ActvityInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ActvityInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ActvityInfo} ActvityInfo
     */
    ActvityInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ActvityInfo) return object;
      var message = new $root.client_proto.ActvityInfo();
      if (object.activity_id != null) message.activity_id = object.activity_id | 0;
      if (object.package_goods_id != null) message.package_goods_id = object.package_goods_id | 0;
      return message;
    };

    /**
     * Creates a plain object from an ActvityInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ActvityInfo
     * @static
     * @param {client_proto.ActvityInfo} message ActvityInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ActvityInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.activity_id = 0;
        object.package_goods_id = 0;
      }
      if (message.activity_id != null && message.hasOwnProperty("activity_id"))
        object.activity_id = message.activity_id;
      if (message.package_goods_id != null && message.hasOwnProperty("package_goods_id"))
        object.package_goods_id = message.package_goods_id;
      return object;
    };

    /**
     * Converts this ActvityInfo to JSON.
     * @function toJSON
     * @memberof client_proto.ActvityInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ActvityInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ActvityInfo
     * @function getTypeUrl
     * @memberof client_proto.ActvityInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ActvityInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ActvityInfo";
    };

    return ActvityInfo;
  })();

  client_proto.SrvActivityListResp = (function () {
    /**
     * Properties of a SrvActivityListResp.
     * @memberof client_proto
     * @interface ISrvActivityListResp
     * @property {number|null} [uid] SrvActivityListResp uid
     * @property {Array.<number>|null} [activity_list] SrvActivityListResp activity_list
     * @property {Uint8Array|null} [trans_data] SrvActivityListResp trans_data
     * @property {Array.<number>|null} [red_activity_list] SrvActivityListResp red_activity_list
     * @property {Array.<client_proto.IActvityInfo>|null} [activity_list_new] SrvActivityListResp activity_list_new
     */

    /**
     * Constructs a new SrvActivityListResp.
     * @memberof client_proto
     * @classdesc Represents a SrvActivityListResp.
     * @implements ISrvActivityListResp
     * @constructor
     * @param {client_proto.ISrvActivityListResp=} [properties] Properties to set
     */
    function SrvActivityListResp(properties) {
      this.activity_list = [];
      this.red_activity_list = [];
      this.activity_list_new = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SrvActivityListResp uid.
     * @member {number} uid
     * @memberof client_proto.SrvActivityListResp
     * @instance
     */
    SrvActivityListResp.prototype.uid = 0;

    /**
     * SrvActivityListResp activity_list.
     * @member {Array.<number>} activity_list
     * @memberof client_proto.SrvActivityListResp
     * @instance
     */
    SrvActivityListResp.prototype.activity_list = $util.emptyArray;

    /**
     * SrvActivityListResp trans_data.
     * @member {Uint8Array} trans_data
     * @memberof client_proto.SrvActivityListResp
     * @instance
     */
    SrvActivityListResp.prototype.trans_data = $util.newBuffer([]);

    /**
     * SrvActivityListResp red_activity_list.
     * @member {Array.<number>} red_activity_list
     * @memberof client_proto.SrvActivityListResp
     * @instance
     */
    SrvActivityListResp.prototype.red_activity_list = $util.emptyArray;

    /**
     * SrvActivityListResp activity_list_new.
     * @member {Array.<client_proto.IActvityInfo>} activity_list_new
     * @memberof client_proto.SrvActivityListResp
     * @instance
     */
    SrvActivityListResp.prototype.activity_list_new = $util.emptyArray;

    /**
     * Creates a new SrvActivityListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SrvActivityListResp
     * @static
     * @param {client_proto.ISrvActivityListResp=} [properties] Properties to set
     * @returns {client_proto.SrvActivityListResp} SrvActivityListResp instance
     */
    SrvActivityListResp.create = function create(properties) {
      return new SrvActivityListResp(properties);
    };

    /**
     * Encodes the specified SrvActivityListResp message. Does not implicitly {@link client_proto.SrvActivityListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SrvActivityListResp
     * @static
     * @param {client_proto.ISrvActivityListResp} message SrvActivityListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvActivityListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.activity_list != null && message.activity_list.length) {
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
        for (var i = 0; i < message.activity_list.length; ++i) writer.int32(message.activity_list[i]);
        writer.ldelim();
      }
      if (message.trans_data != null && Object.hasOwnProperty.call(message, "trans_data"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).bytes(message.trans_data);
      if (message.red_activity_list != null && message.red_activity_list.length) {
        writer.uint32(/* id 4, wireType 2 =*/ 34).fork();
        for (var i = 0; i < message.red_activity_list.length; ++i) writer.int32(message.red_activity_list[i]);
        writer.ldelim();
      }
      if (message.activity_list_new != null && message.activity_list_new.length)
        for (var i = 0; i < message.activity_list_new.length; ++i)
          $root.client_proto.ActvityInfo.encode(
            message.activity_list_new[i],
            writer.uint32(/* id 5, wireType 2 =*/ 42).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified SrvActivityListResp message, length delimited. Does not implicitly {@link client_proto.SrvActivityListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SrvActivityListResp
     * @static
     * @param {client_proto.ISrvActivityListResp} message SrvActivityListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SrvActivityListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SrvActivityListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SrvActivityListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SrvActivityListResp} SrvActivityListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvActivityListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SrvActivityListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            if (!(message.activity_list && message.activity_list.length)) message.activity_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.activity_list.push(reader.int32());
            } else message.activity_list.push(reader.int32());
            break;
          }
          case 3: {
            message.trans_data = reader.bytes();
            break;
          }
          case 4: {
            if (!(message.red_activity_list && message.red_activity_list.length)) message.red_activity_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.red_activity_list.push(reader.int32());
            } else message.red_activity_list.push(reader.int32());
            break;
          }
          case 5: {
            if (!(message.activity_list_new && message.activity_list_new.length)) message.activity_list_new = [];
            message.activity_list_new.push($root.client_proto.ActvityInfo.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SrvActivityListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SrvActivityListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SrvActivityListResp} SrvActivityListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SrvActivityListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SrvActivityListResp message.
     * @function verify
     * @memberof client_proto.SrvActivityListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SrvActivityListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.activity_list != null && message.hasOwnProperty("activity_list")) {
        if (!Array.isArray(message.activity_list)) return "activity_list: array expected";
        for (var i = 0; i < message.activity_list.length; ++i)
          if (!$util.isInteger(message.activity_list[i])) return "activity_list: integer[] expected";
      }
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        if (
          !((message.trans_data && typeof message.trans_data.length === "number") || $util.isString(message.trans_data))
        )
          return "trans_data: buffer expected";
      if (message.red_activity_list != null && message.hasOwnProperty("red_activity_list")) {
        if (!Array.isArray(message.red_activity_list)) return "red_activity_list: array expected";
        for (var i = 0; i < message.red_activity_list.length; ++i)
          if (!$util.isInteger(message.red_activity_list[i])) return "red_activity_list: integer[] expected";
      }
      if (message.activity_list_new != null && message.hasOwnProperty("activity_list_new")) {
        if (!Array.isArray(message.activity_list_new)) return "activity_list_new: array expected";
        for (var i = 0; i < message.activity_list_new.length; ++i) {
          var error = $root.client_proto.ActvityInfo.verify(message.activity_list_new[i]);
          if (error) return "activity_list_new." + error;
        }
      }
      return null;
    };

    /**
     * Creates a SrvActivityListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SrvActivityListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SrvActivityListResp} SrvActivityListResp
     */
    SrvActivityListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SrvActivityListResp) return object;
      var message = new $root.client_proto.SrvActivityListResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.activity_list) {
        if (!Array.isArray(object.activity_list))
          throw TypeError(".client_proto.SrvActivityListResp.activity_list: array expected");
        message.activity_list = [];
        for (var i = 0; i < object.activity_list.length; ++i) message.activity_list[i] = object.activity_list[i] | 0;
      }
      if (object.trans_data != null)
        if (typeof object.trans_data === "string")
          $util.base64.decode(
            object.trans_data,
            (message.trans_data = $util.newBuffer($util.base64.length(object.trans_data))),
            0,
          );
        else if (object.trans_data.length >= 0) message.trans_data = object.trans_data;
      if (object.red_activity_list) {
        if (!Array.isArray(object.red_activity_list))
          throw TypeError(".client_proto.SrvActivityListResp.red_activity_list: array expected");
        message.red_activity_list = [];
        for (var i = 0; i < object.red_activity_list.length; ++i)
          message.red_activity_list[i] = object.red_activity_list[i] | 0;
      }
      if (object.activity_list_new) {
        if (!Array.isArray(object.activity_list_new))
          throw TypeError(".client_proto.SrvActivityListResp.activity_list_new: array expected");
        message.activity_list_new = [];
        for (var i = 0; i < object.activity_list_new.length; ++i) {
          if (typeof object.activity_list_new[i] !== "object")
            throw TypeError(".client_proto.SrvActivityListResp.activity_list_new: object expected");
          message.activity_list_new[i] = $root.client_proto.ActvityInfo.fromObject(object.activity_list_new[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a SrvActivityListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SrvActivityListResp
     * @static
     * @param {client_proto.SrvActivityListResp} message SrvActivityListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SrvActivityListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) {
        object.activity_list = [];
        object.red_activity_list = [];
        object.activity_list_new = [];
      }
      if (options.defaults) {
        object.uid = 0;
        if (options.bytes === String) object.trans_data = "";
        else {
          object.trans_data = [];
          if (options.bytes !== Array) object.trans_data = $util.newBuffer(object.trans_data);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.activity_list && message.activity_list.length) {
        object.activity_list = [];
        for (var j = 0; j < message.activity_list.length; ++j) object.activity_list[j] = message.activity_list[j];
      }
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        object.trans_data =
          options.bytes === String
            ? $util.base64.encode(message.trans_data, 0, message.trans_data.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans_data)
              : message.trans_data;
      if (message.red_activity_list && message.red_activity_list.length) {
        object.red_activity_list = [];
        for (var j = 0; j < message.red_activity_list.length; ++j)
          object.red_activity_list[j] = message.red_activity_list[j];
      }
      if (message.activity_list_new && message.activity_list_new.length) {
        object.activity_list_new = [];
        for (var j = 0; j < message.activity_list_new.length; ++j)
          object.activity_list_new[j] = $root.client_proto.ActvityInfo.toObject(message.activity_list_new[j], options);
      }
      return object;
    };

    /**
     * Converts this SrvActivityListResp to JSON.
     * @function toJSON
     * @memberof client_proto.SrvActivityListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SrvActivityListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SrvActivityListResp
     * @function getTypeUrl
     * @memberof client_proto.SrvActivityListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SrvActivityListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SrvActivityListResp";
    };

    return SrvActivityListResp;
  })();

  /**
   * EMAIL_INFO_SUB_MSG_ID enum.
   * @name client_proto.EMAIL_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} EMAIL_MSG_ID_NULL=0 EMAIL_MSG_ID_NULL value
   * @property {number} EMAIL_MSG_ID_MIN=2100 EMAIL_MSG_ID_MIN value
   * @property {number} EMAIL_MSG_ID_UNREAD_INFO=2101 EMAIL_MSG_ID_UNREAD_INFO value
   * @property {number} EMAIL_MSG_ID_UNREAD_INFO_RESP=2102 EMAIL_MSG_ID_UNREAD_INFO_RESP value
   * @property {number} EMAIL_MSG_ID_MAIL_LIST=2103 EMAIL_MSG_ID_MAIL_LIST value
   * @property {number} EMAIL_MSG_ID_MAIL_LIST_RESP=2104 EMAIL_MSG_ID_MAIL_LIST_RESP value
   * @property {number} EMAIL_MSG_ID_DELETE_LIST=2105 EMAIL_MSG_ID_DELETE_LIST value
   * @property {number} EMAIL_MSG_ID_DELETE_LIST_RESP=2106 EMAIL_MSG_ID_DELETE_LIST_RESP value
   * @property {number} EMAIL_MSG_ID_RECEIVE_LIST=2107 EMAIL_MSG_ID_RECEIVE_LIST value
   * @property {number} EMAIL_MSG_ID_RECEIVE_LIST_RESP=2108 EMAIL_MSG_ID_RECEIVE_LIST_RESP value
   * @property {number} EMAIL_MSG_ID_MARK_READ=2109 EMAIL_MSG_ID_MARK_READ value
   * @property {number} EMAIL_MSG_ID_MARK_READ_RESP=2110 EMAIL_MSG_ID_MARK_READ_RESP value
   * @property {number} EMAIL_MSG_ID_MAIL_INFO_PUSH=2111 EMAIL_MSG_ID_MAIL_INFO_PUSH value
   * @property {number} EMAIL_MSG_ID_MAX=2150 EMAIL_MSG_ID_MAX value
   */
  client_proto.EMAIL_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "EMAIL_MSG_ID_NULL")] = 0;
    values[(valuesById[2100] = "EMAIL_MSG_ID_MIN")] = 2100;
    values[(valuesById[2101] = "EMAIL_MSG_ID_UNREAD_INFO")] = 2101;
    values[(valuesById[2102] = "EMAIL_MSG_ID_UNREAD_INFO_RESP")] = 2102;
    values[(valuesById[2103] = "EMAIL_MSG_ID_MAIL_LIST")] = 2103;
    values[(valuesById[2104] = "EMAIL_MSG_ID_MAIL_LIST_RESP")] = 2104;
    values[(valuesById[2105] = "EMAIL_MSG_ID_DELETE_LIST")] = 2105;
    values[(valuesById[2106] = "EMAIL_MSG_ID_DELETE_LIST_RESP")] = 2106;
    values[(valuesById[2107] = "EMAIL_MSG_ID_RECEIVE_LIST")] = 2107;
    values[(valuesById[2108] = "EMAIL_MSG_ID_RECEIVE_LIST_RESP")] = 2108;
    values[(valuesById[2109] = "EMAIL_MSG_ID_MARK_READ")] = 2109;
    values[(valuesById[2110] = "EMAIL_MSG_ID_MARK_READ_RESP")] = 2110;
    values[(valuesById[2111] = "EMAIL_MSG_ID_MAIL_INFO_PUSH")] = 2111;
    values[(valuesById[2150] = "EMAIL_MSG_ID_MAX")] = 2150;
    return values;
  })();

  client_proto.UnReadInfoReq = (function () {
    /**
     * Properties of an UnReadInfoReq.
     * @memberof client_proto
     * @interface IUnReadInfoReq
     * @property {number|null} [uid] UnReadInfoReq uid
     * @property {string|null} [language] UnReadInfoReq language
     */

    /**
     * Constructs a new UnReadInfoReq.
     * @memberof client_proto
     * @classdesc Represents an UnReadInfoReq.
     * @implements IUnReadInfoReq
     * @constructor
     * @param {client_proto.IUnReadInfoReq=} [properties] Properties to set
     */
    function UnReadInfoReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UnReadInfoReq uid.
     * @member {number} uid
     * @memberof client_proto.UnReadInfoReq
     * @instance
     */
    UnReadInfoReq.prototype.uid = 0;

    /**
     * UnReadInfoReq language.
     * @member {string} language
     * @memberof client_proto.UnReadInfoReq
     * @instance
     */
    UnReadInfoReq.prototype.language = "";

    /**
     * Creates a new UnReadInfoReq instance using the specified properties.
     * @function create
     * @memberof client_proto.UnReadInfoReq
     * @static
     * @param {client_proto.IUnReadInfoReq=} [properties] Properties to set
     * @returns {client_proto.UnReadInfoReq} UnReadInfoReq instance
     */
    UnReadInfoReq.create = function create(properties) {
      return new UnReadInfoReq(properties);
    };

    /**
     * Encodes the specified UnReadInfoReq message. Does not implicitly {@link client_proto.UnReadInfoReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UnReadInfoReq
     * @static
     * @param {client_proto.IUnReadInfoReq} message UnReadInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UnReadInfoReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.language);
      return writer;
    };

    /**
     * Encodes the specified UnReadInfoReq message, length delimited. Does not implicitly {@link client_proto.UnReadInfoReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UnReadInfoReq
     * @static
     * @param {client_proto.IUnReadInfoReq} message UnReadInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UnReadInfoReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UnReadInfoReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UnReadInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UnReadInfoReq} UnReadInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UnReadInfoReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UnReadInfoReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.language = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an UnReadInfoReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UnReadInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UnReadInfoReq} UnReadInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UnReadInfoReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UnReadInfoReq message.
     * @function verify
     * @memberof client_proto.UnReadInfoReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UnReadInfoReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      return null;
    };

    /**
     * Creates an UnReadInfoReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UnReadInfoReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UnReadInfoReq} UnReadInfoReq
     */
    UnReadInfoReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UnReadInfoReq) return object;
      var message = new $root.client_proto.UnReadInfoReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.language != null) message.language = String(object.language);
      return message;
    };

    /**
     * Creates a plain object from an UnReadInfoReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UnReadInfoReq
     * @static
     * @param {client_proto.UnReadInfoReq} message UnReadInfoReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UnReadInfoReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.language = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      return object;
    };

    /**
     * Converts this UnReadInfoReq to JSON.
     * @function toJSON
     * @memberof client_proto.UnReadInfoReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UnReadInfoReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UnReadInfoReq
     * @function getTypeUrl
     * @memberof client_proto.UnReadInfoReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UnReadInfoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UnReadInfoReq";
    };

    return UnReadInfoReq;
  })();

  client_proto.UnReadInfoResp = (function () {
    /**
     * Properties of an UnReadInfoResp.
     * @memberof client_proto
     * @interface IUnReadInfoResp
     * @property {number|null} [max_mail_id] UnReadInfoResp max_mail_id
     * @property {number|null} [unread_number] UnReadInfoResp unread_number
     * @property {number|null} [platform_number] UnReadInfoResp platform_number
     * @property {number|null} [personal_number] UnReadInfoResp personal_number
     * @property {number|null} [min_mail_id] UnReadInfoResp min_mail_id
     * @property {boolean|null} [is_request_ok] UnReadInfoResp is_request_ok
     * @property {number|null} [last_del_time] UnReadInfoResp last_del_time
     */

    /**
     * Constructs a new UnReadInfoResp.
     * @memberof client_proto
     * @classdesc Represents an UnReadInfoResp.
     * @implements IUnReadInfoResp
     * @constructor
     * @param {client_proto.IUnReadInfoResp=} [properties] Properties to set
     */
    function UnReadInfoResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UnReadInfoResp max_mail_id.
     * @member {number} max_mail_id
     * @memberof client_proto.UnReadInfoResp
     * @instance
     */
    UnReadInfoResp.prototype.max_mail_id = 0;

    /**
     * UnReadInfoResp unread_number.
     * @member {number} unread_number
     * @memberof client_proto.UnReadInfoResp
     * @instance
     */
    UnReadInfoResp.prototype.unread_number = 0;

    /**
     * UnReadInfoResp platform_number.
     * @member {number} platform_number
     * @memberof client_proto.UnReadInfoResp
     * @instance
     */
    UnReadInfoResp.prototype.platform_number = 0;

    /**
     * UnReadInfoResp personal_number.
     * @member {number} personal_number
     * @memberof client_proto.UnReadInfoResp
     * @instance
     */
    UnReadInfoResp.prototype.personal_number = 0;

    /**
     * UnReadInfoResp min_mail_id.
     * @member {number} min_mail_id
     * @memberof client_proto.UnReadInfoResp
     * @instance
     */
    UnReadInfoResp.prototype.min_mail_id = 0;

    /**
     * UnReadInfoResp is_request_ok.
     * @member {boolean} is_request_ok
     * @memberof client_proto.UnReadInfoResp
     * @instance
     */
    UnReadInfoResp.prototype.is_request_ok = false;

    /**
     * UnReadInfoResp last_del_time.
     * @member {number} last_del_time
     * @memberof client_proto.UnReadInfoResp
     * @instance
     */
    UnReadInfoResp.prototype.last_del_time = 0;

    /**
     * Creates a new UnReadInfoResp instance using the specified properties.
     * @function create
     * @memberof client_proto.UnReadInfoResp
     * @static
     * @param {client_proto.IUnReadInfoResp=} [properties] Properties to set
     * @returns {client_proto.UnReadInfoResp} UnReadInfoResp instance
     */
    UnReadInfoResp.create = function create(properties) {
      return new UnReadInfoResp(properties);
    };

    /**
     * Encodes the specified UnReadInfoResp message. Does not implicitly {@link client_proto.UnReadInfoResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UnReadInfoResp
     * @static
     * @param {client_proto.IUnReadInfoResp} message UnReadInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UnReadInfoResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.max_mail_id != null && Object.hasOwnProperty.call(message, "max_mail_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.max_mail_id);
      if (message.unread_number != null && Object.hasOwnProperty.call(message, "unread_number"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.unread_number);
      if (message.platform_number != null && Object.hasOwnProperty.call(message, "platform_number"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.platform_number);
      if (message.personal_number != null && Object.hasOwnProperty.call(message, "personal_number"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.personal_number);
      if (message.min_mail_id != null && Object.hasOwnProperty.call(message, "min_mail_id"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).uint32(message.min_mail_id);
      if (message.is_request_ok != null && Object.hasOwnProperty.call(message, "is_request_ok"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).bool(message.is_request_ok);
      if (message.last_del_time != null && Object.hasOwnProperty.call(message, "last_del_time"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).uint32(message.last_del_time);
      return writer;
    };

    /**
     * Encodes the specified UnReadInfoResp message, length delimited. Does not implicitly {@link client_proto.UnReadInfoResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UnReadInfoResp
     * @static
     * @param {client_proto.IUnReadInfoResp} message UnReadInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UnReadInfoResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UnReadInfoResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UnReadInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UnReadInfoResp} UnReadInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UnReadInfoResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UnReadInfoResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.max_mail_id = reader.uint32();
            break;
          }
          case 2: {
            message.unread_number = reader.int32();
            break;
          }
          case 3: {
            message.platform_number = reader.int32();
            break;
          }
          case 4: {
            message.personal_number = reader.int32();
            break;
          }
          case 5: {
            message.min_mail_id = reader.uint32();
            break;
          }
          case 6: {
            message.is_request_ok = reader.bool();
            break;
          }
          case 7: {
            message.last_del_time = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an UnReadInfoResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UnReadInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UnReadInfoResp} UnReadInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UnReadInfoResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UnReadInfoResp message.
     * @function verify
     * @memberof client_proto.UnReadInfoResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UnReadInfoResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.max_mail_id != null && message.hasOwnProperty("max_mail_id"))
        if (!$util.isInteger(message.max_mail_id)) return "max_mail_id: integer expected";
      if (message.unread_number != null && message.hasOwnProperty("unread_number"))
        if (!$util.isInteger(message.unread_number)) return "unread_number: integer expected";
      if (message.platform_number != null && message.hasOwnProperty("platform_number"))
        if (!$util.isInteger(message.platform_number)) return "platform_number: integer expected";
      if (message.personal_number != null && message.hasOwnProperty("personal_number"))
        if (!$util.isInteger(message.personal_number)) return "personal_number: integer expected";
      if (message.min_mail_id != null && message.hasOwnProperty("min_mail_id"))
        if (!$util.isInteger(message.min_mail_id)) return "min_mail_id: integer expected";
      if (message.is_request_ok != null && message.hasOwnProperty("is_request_ok"))
        if (typeof message.is_request_ok !== "boolean") return "is_request_ok: boolean expected";
      if (message.last_del_time != null && message.hasOwnProperty("last_del_time"))
        if (!$util.isInteger(message.last_del_time)) return "last_del_time: integer expected";
      return null;
    };

    /**
     * Creates an UnReadInfoResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UnReadInfoResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UnReadInfoResp} UnReadInfoResp
     */
    UnReadInfoResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UnReadInfoResp) return object;
      var message = new $root.client_proto.UnReadInfoResp();
      if (object.max_mail_id != null) message.max_mail_id = object.max_mail_id >>> 0;
      if (object.unread_number != null) message.unread_number = object.unread_number | 0;
      if (object.platform_number != null) message.platform_number = object.platform_number | 0;
      if (object.personal_number != null) message.personal_number = object.personal_number | 0;
      if (object.min_mail_id != null) message.min_mail_id = object.min_mail_id >>> 0;
      if (object.is_request_ok != null) message.is_request_ok = Boolean(object.is_request_ok);
      if (object.last_del_time != null) message.last_del_time = object.last_del_time >>> 0;
      return message;
    };

    /**
     * Creates a plain object from an UnReadInfoResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UnReadInfoResp
     * @static
     * @param {client_proto.UnReadInfoResp} message UnReadInfoResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UnReadInfoResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.max_mail_id = 0;
        object.unread_number = 0;
        object.platform_number = 0;
        object.personal_number = 0;
        object.min_mail_id = 0;
        object.is_request_ok = false;
        object.last_del_time = 0;
      }
      if (message.max_mail_id != null && message.hasOwnProperty("max_mail_id"))
        object.max_mail_id = message.max_mail_id;
      if (message.unread_number != null && message.hasOwnProperty("unread_number"))
        object.unread_number = message.unread_number;
      if (message.platform_number != null && message.hasOwnProperty("platform_number"))
        object.platform_number = message.platform_number;
      if (message.personal_number != null && message.hasOwnProperty("personal_number"))
        object.personal_number = message.personal_number;
      if (message.min_mail_id != null && message.hasOwnProperty("min_mail_id"))
        object.min_mail_id = message.min_mail_id;
      if (message.is_request_ok != null && message.hasOwnProperty("is_request_ok"))
        object.is_request_ok = message.is_request_ok;
      if (message.last_del_time != null && message.hasOwnProperty("last_del_time"))
        object.last_del_time = message.last_del_time;
      return object;
    };

    /**
     * Converts this UnReadInfoResp to JSON.
     * @function toJSON
     * @memberof client_proto.UnReadInfoResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UnReadInfoResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UnReadInfoResp
     * @function getTypeUrl
     * @memberof client_proto.UnReadInfoResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UnReadInfoResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UnReadInfoResp";
    };

    return UnReadInfoResp;
  })();

  client_proto.MailListReq = (function () {
    /**
     * Properties of a MailListReq.
     * @memberof client_proto
     * @interface IMailListReq
     * @property {number|null} [uid] MailListReq uid
     * @property {number|null} [max_id] MailListReq max_id
     * @property {number|null} [min_id] MailListReq min_id
     */

    /**
     * Constructs a new MailListReq.
     * @memberof client_proto
     * @classdesc Represents a MailListReq.
     * @implements IMailListReq
     * @constructor
     * @param {client_proto.IMailListReq=} [properties] Properties to set
     */
    function MailListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailListReq uid.
     * @member {number} uid
     * @memberof client_proto.MailListReq
     * @instance
     */
    MailListReq.prototype.uid = 0;

    /**
     * MailListReq max_id.
     * @member {number} max_id
     * @memberof client_proto.MailListReq
     * @instance
     */
    MailListReq.prototype.max_id = 0;

    /**
     * MailListReq min_id.
     * @member {number} min_id
     * @memberof client_proto.MailListReq
     * @instance
     */
    MailListReq.prototype.min_id = 0;

    /**
     * Creates a new MailListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.MailListReq
     * @static
     * @param {client_proto.IMailListReq=} [properties] Properties to set
     * @returns {client_proto.MailListReq} MailListReq instance
     */
    MailListReq.create = function create(properties) {
      return new MailListReq(properties);
    };

    /**
     * Encodes the specified MailListReq message. Does not implicitly {@link client_proto.MailListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailListReq
     * @static
     * @param {client_proto.IMailListReq} message MailListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.max_id != null && Object.hasOwnProperty.call(message, "max_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.max_id);
      if (message.min_id != null && Object.hasOwnProperty.call(message, "min_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.min_id);
      return writer;
    };

    /**
     * Encodes the specified MailListReq message, length delimited. Does not implicitly {@link client_proto.MailListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailListReq
     * @static
     * @param {client_proto.IMailListReq} message MailListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailListReq} MailListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.max_id = reader.uint32();
            break;
          }
          case 3: {
            message.min_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailListReq} MailListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailListReq message.
     * @function verify
     * @memberof client_proto.MailListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.max_id != null && message.hasOwnProperty("max_id"))
        if (!$util.isInteger(message.max_id)) return "max_id: integer expected";
      if (message.min_id != null && message.hasOwnProperty("min_id"))
        if (!$util.isInteger(message.min_id)) return "min_id: integer expected";
      return null;
    };

    /**
     * Creates a MailListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailListReq} MailListReq
     */
    MailListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailListReq) return object;
      var message = new $root.client_proto.MailListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.max_id != null) message.max_id = object.max_id >>> 0;
      if (object.min_id != null) message.min_id = object.min_id >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a MailListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailListReq
     * @static
     * @param {client_proto.MailListReq} message MailListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.max_id = 0;
        object.min_id = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.max_id != null && message.hasOwnProperty("max_id")) object.max_id = message.max_id;
      if (message.min_id != null && message.hasOwnProperty("min_id")) object.min_id = message.min_id;
      return object;
    };

    /**
     * Converts this MailListReq to JSON.
     * @function toJSON
     * @memberof client_proto.MailListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailListReq
     * @function getTypeUrl
     * @memberof client_proto.MailListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailListReq";
    };

    return MailListReq;
  })();

  client_proto.MailInfo = (function () {
    /**
     * Properties of a MailInfo.
     * @memberof client_proto
     * @interface IMailInfo
     * @property {number|null} [uid] MailInfo uid
     * @property {number|null} [mail_id] MailInfo mail_id
     * @property {number|null} [receive_time] MailInfo receive_time
     * @property {number|null} [expired_time] MailInfo expired_time
     * @property {string|null} [title] MailInfo title
     * @property {string|null} [content] MailInfo content
     * @property {string|null} [attachment] MailInfo attachment
     * @property {number|null} [attachment_state] MailInfo attachment_state
     * @property {number|null} [read_state] MailInfo read_state
     * @property {number|null} [mail_type] MailInfo mail_type
     */

    /**
     * Constructs a new MailInfo.
     * @memberof client_proto
     * @classdesc Represents a MailInfo.
     * @implements IMailInfo
     * @constructor
     * @param {client_proto.IMailInfo=} [properties] Properties to set
     */
    function MailInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailInfo uid.
     * @member {number} uid
     * @memberof client_proto.MailInfo
     * @instance
     */
    MailInfo.prototype.uid = 0;

    /**
     * MailInfo mail_id.
     * @member {number} mail_id
     * @memberof client_proto.MailInfo
     * @instance
     */
    MailInfo.prototype.mail_id = 0;

    /**
     * MailInfo receive_time.
     * @member {number} receive_time
     * @memberof client_proto.MailInfo
     * @instance
     */
    MailInfo.prototype.receive_time = 0;

    /**
     * MailInfo expired_time.
     * @member {number} expired_time
     * @memberof client_proto.MailInfo
     * @instance
     */
    MailInfo.prototype.expired_time = 0;

    /**
     * MailInfo title.
     * @member {string} title
     * @memberof client_proto.MailInfo
     * @instance
     */
    MailInfo.prototype.title = "";

    /**
     * MailInfo content.
     * @member {string} content
     * @memberof client_proto.MailInfo
     * @instance
     */
    MailInfo.prototype.content = "";

    /**
     * MailInfo attachment.
     * @member {string} attachment
     * @memberof client_proto.MailInfo
     * @instance
     */
    MailInfo.prototype.attachment = "";

    /**
     * MailInfo attachment_state.
     * @member {number} attachment_state
     * @memberof client_proto.MailInfo
     * @instance
     */
    MailInfo.prototype.attachment_state = 0;

    /**
     * MailInfo read_state.
     * @member {number} read_state
     * @memberof client_proto.MailInfo
     * @instance
     */
    MailInfo.prototype.read_state = 0;

    /**
     * MailInfo mail_type.
     * @member {number} mail_type
     * @memberof client_proto.MailInfo
     * @instance
     */
    MailInfo.prototype.mail_type = 0;

    /**
     * Creates a new MailInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.MailInfo
     * @static
     * @param {client_proto.IMailInfo=} [properties] Properties to set
     * @returns {client_proto.MailInfo} MailInfo instance
     */
    MailInfo.create = function create(properties) {
      return new MailInfo(properties);
    };

    /**
     * Encodes the specified MailInfo message. Does not implicitly {@link client_proto.MailInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailInfo
     * @static
     * @param {client_proto.IMailInfo} message MailInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.mail_id != null && Object.hasOwnProperty.call(message, "mail_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.mail_id);
      if (message.receive_time != null && Object.hasOwnProperty.call(message, "receive_time"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.receive_time);
      if (message.expired_time != null && Object.hasOwnProperty.call(message, "expired_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.expired_time);
      if (message.title != null && Object.hasOwnProperty.call(message, "title"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.title);
      if (message.content != null && Object.hasOwnProperty.call(message, "content"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.content);
      if (message.attachment != null && Object.hasOwnProperty.call(message, "attachment"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.attachment);
      if (message.attachment_state != null && Object.hasOwnProperty.call(message, "attachment_state"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.attachment_state);
      if (message.read_state != null && Object.hasOwnProperty.call(message, "read_state"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int32(message.read_state);
      if (message.mail_type != null && Object.hasOwnProperty.call(message, "mail_type"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int32(message.mail_type);
      return writer;
    };

    /**
     * Encodes the specified MailInfo message, length delimited. Does not implicitly {@link client_proto.MailInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailInfo
     * @static
     * @param {client_proto.IMailInfo} message MailInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailInfo} MailInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.mail_id = reader.uint32();
            break;
          }
          case 3: {
            message.receive_time = reader.uint32();
            break;
          }
          case 4: {
            message.expired_time = reader.uint32();
            break;
          }
          case 5: {
            message.title = reader.string();
            break;
          }
          case 6: {
            message.content = reader.string();
            break;
          }
          case 7: {
            message.attachment = reader.string();
            break;
          }
          case 8: {
            message.attachment_state = reader.int32();
            break;
          }
          case 9: {
            message.read_state = reader.int32();
            break;
          }
          case 10: {
            message.mail_type = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailInfo} MailInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailInfo message.
     * @function verify
     * @memberof client_proto.MailInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.mail_id != null && message.hasOwnProperty("mail_id"))
        if (!$util.isInteger(message.mail_id)) return "mail_id: integer expected";
      if (message.receive_time != null && message.hasOwnProperty("receive_time"))
        if (!$util.isInteger(message.receive_time)) return "receive_time: integer expected";
      if (message.expired_time != null && message.hasOwnProperty("expired_time"))
        if (!$util.isInteger(message.expired_time)) return "expired_time: integer expected";
      if (message.title != null && message.hasOwnProperty("title"))
        if (!$util.isString(message.title)) return "title: string expected";
      if (message.content != null && message.hasOwnProperty("content"))
        if (!$util.isString(message.content)) return "content: string expected";
      if (message.attachment != null && message.hasOwnProperty("attachment"))
        if (!$util.isString(message.attachment)) return "attachment: string expected";
      if (message.attachment_state != null && message.hasOwnProperty("attachment_state"))
        if (!$util.isInteger(message.attachment_state)) return "attachment_state: integer expected";
      if (message.read_state != null && message.hasOwnProperty("read_state"))
        if (!$util.isInteger(message.read_state)) return "read_state: integer expected";
      if (message.mail_type != null && message.hasOwnProperty("mail_type"))
        if (!$util.isInteger(message.mail_type)) return "mail_type: integer expected";
      return null;
    };

    /**
     * Creates a MailInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailInfo} MailInfo
     */
    MailInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailInfo) return object;
      var message = new $root.client_proto.MailInfo();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.mail_id != null) message.mail_id = object.mail_id >>> 0;
      if (object.receive_time != null) message.receive_time = object.receive_time >>> 0;
      if (object.expired_time != null) message.expired_time = object.expired_time >>> 0;
      if (object.title != null) message.title = String(object.title);
      if (object.content != null) message.content = String(object.content);
      if (object.attachment != null) message.attachment = String(object.attachment);
      if (object.attachment_state != null) message.attachment_state = object.attachment_state | 0;
      if (object.read_state != null) message.read_state = object.read_state | 0;
      if (object.mail_type != null) message.mail_type = object.mail_type | 0;
      return message;
    };

    /**
     * Creates a plain object from a MailInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailInfo
     * @static
     * @param {client_proto.MailInfo} message MailInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.mail_id = 0;
        object.receive_time = 0;
        object.expired_time = 0;
        object.title = "";
        object.content = "";
        object.attachment = "";
        object.attachment_state = 0;
        object.read_state = 0;
        object.mail_type = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.mail_id != null && message.hasOwnProperty("mail_id")) object.mail_id = message.mail_id;
      if (message.receive_time != null && message.hasOwnProperty("receive_time"))
        object.receive_time = message.receive_time;
      if (message.expired_time != null && message.hasOwnProperty("expired_time"))
        object.expired_time = message.expired_time;
      if (message.title != null && message.hasOwnProperty("title")) object.title = message.title;
      if (message.content != null && message.hasOwnProperty("content")) object.content = message.content;
      if (message.attachment != null && message.hasOwnProperty("attachment")) object.attachment = message.attachment;
      if (message.attachment_state != null && message.hasOwnProperty("attachment_state"))
        object.attachment_state = message.attachment_state;
      if (message.read_state != null && message.hasOwnProperty("read_state")) object.read_state = message.read_state;
      if (message.mail_type != null && message.hasOwnProperty("mail_type")) object.mail_type = message.mail_type;
      return object;
    };

    /**
     * Converts this MailInfo to JSON.
     * @function toJSON
     * @memberof client_proto.MailInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailInfo
     * @function getTypeUrl
     * @memberof client_proto.MailInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailInfo";
    };

    return MailInfo;
  })();

  client_proto.MailListResp = (function () {
    /**
     * Properties of a MailListResp.
     * @memberof client_proto
     * @interface IMailListResp
     * @property {number|null} [max_id] MailListResp max_id
     * @property {number|null} [min_id] MailListResp min_id
     * @property {Array.<client_proto.IMailInfo>|null} [mail_list] MailListResp mail_list
     */

    /**
     * Constructs a new MailListResp.
     * @memberof client_proto
     * @classdesc Represents a MailListResp.
     * @implements IMailListResp
     * @constructor
     * @param {client_proto.IMailListResp=} [properties] Properties to set
     */
    function MailListResp(properties) {
      this.mail_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailListResp max_id.
     * @member {number} max_id
     * @memberof client_proto.MailListResp
     * @instance
     */
    MailListResp.prototype.max_id = 0;

    /**
     * MailListResp min_id.
     * @member {number} min_id
     * @memberof client_proto.MailListResp
     * @instance
     */
    MailListResp.prototype.min_id = 0;

    /**
     * MailListResp mail_list.
     * @member {Array.<client_proto.IMailInfo>} mail_list
     * @memberof client_proto.MailListResp
     * @instance
     */
    MailListResp.prototype.mail_list = $util.emptyArray;

    /**
     * Creates a new MailListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.MailListResp
     * @static
     * @param {client_proto.IMailListResp=} [properties] Properties to set
     * @returns {client_proto.MailListResp} MailListResp instance
     */
    MailListResp.create = function create(properties) {
      return new MailListResp(properties);
    };

    /**
     * Encodes the specified MailListResp message. Does not implicitly {@link client_proto.MailListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailListResp
     * @static
     * @param {client_proto.IMailListResp} message MailListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.max_id != null && Object.hasOwnProperty.call(message, "max_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.max_id);
      if (message.min_id != null && Object.hasOwnProperty.call(message, "min_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.min_id);
      if (message.mail_list != null && message.mail_list.length)
        for (var i = 0; i < message.mail_list.length; ++i)
          $root.client_proto.MailInfo.encode(
            message.mail_list[i],
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified MailListResp message, length delimited. Does not implicitly {@link client_proto.MailListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailListResp
     * @static
     * @param {client_proto.IMailListResp} message MailListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailListResp} MailListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.max_id = reader.uint32();
            break;
          }
          case 2: {
            message.min_id = reader.uint32();
            break;
          }
          case 3: {
            if (!(message.mail_list && message.mail_list.length)) message.mail_list = [];
            message.mail_list.push($root.client_proto.MailInfo.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailListResp} MailListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailListResp message.
     * @function verify
     * @memberof client_proto.MailListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.max_id != null && message.hasOwnProperty("max_id"))
        if (!$util.isInteger(message.max_id)) return "max_id: integer expected";
      if (message.min_id != null && message.hasOwnProperty("min_id"))
        if (!$util.isInteger(message.min_id)) return "min_id: integer expected";
      if (message.mail_list != null && message.hasOwnProperty("mail_list")) {
        if (!Array.isArray(message.mail_list)) return "mail_list: array expected";
        for (var i = 0; i < message.mail_list.length; ++i) {
          var error = $root.client_proto.MailInfo.verify(message.mail_list[i]);
          if (error) return "mail_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates a MailListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailListResp} MailListResp
     */
    MailListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailListResp) return object;
      var message = new $root.client_proto.MailListResp();
      if (object.max_id != null) message.max_id = object.max_id >>> 0;
      if (object.min_id != null) message.min_id = object.min_id >>> 0;
      if (object.mail_list) {
        if (!Array.isArray(object.mail_list)) throw TypeError(".client_proto.MailListResp.mail_list: array expected");
        message.mail_list = [];
        for (var i = 0; i < object.mail_list.length; ++i) {
          if (typeof object.mail_list[i] !== "object")
            throw TypeError(".client_proto.MailListResp.mail_list: object expected");
          message.mail_list[i] = $root.client_proto.MailInfo.fromObject(object.mail_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a MailListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailListResp
     * @static
     * @param {client_proto.MailListResp} message MailListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.mail_list = [];
      if (options.defaults) {
        object.max_id = 0;
        object.min_id = 0;
      }
      if (message.max_id != null && message.hasOwnProperty("max_id")) object.max_id = message.max_id;
      if (message.min_id != null && message.hasOwnProperty("min_id")) object.min_id = message.min_id;
      if (message.mail_list && message.mail_list.length) {
        object.mail_list = [];
        for (var j = 0; j < message.mail_list.length; ++j)
          object.mail_list[j] = $root.client_proto.MailInfo.toObject(message.mail_list[j], options);
      }
      return object;
    };

    /**
     * Converts this MailListResp to JSON.
     * @function toJSON
     * @memberof client_proto.MailListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailListResp
     * @function getTypeUrl
     * @memberof client_proto.MailListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailListResp";
    };

    return MailListResp;
  })();

  client_proto.MailDeleteListReq = (function () {
    /**
     * Properties of a MailDeleteListReq.
     * @memberof client_proto
     * @interface IMailDeleteListReq
     * @property {number|null} [uid] MailDeleteListReq uid
     * @property {Array.<number>|null} [id_list] MailDeleteListReq id_list
     */

    /**
     * Constructs a new MailDeleteListReq.
     * @memberof client_proto
     * @classdesc Represents a MailDeleteListReq.
     * @implements IMailDeleteListReq
     * @constructor
     * @param {client_proto.IMailDeleteListReq=} [properties] Properties to set
     */
    function MailDeleteListReq(properties) {
      this.id_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailDeleteListReq uid.
     * @member {number} uid
     * @memberof client_proto.MailDeleteListReq
     * @instance
     */
    MailDeleteListReq.prototype.uid = 0;

    /**
     * MailDeleteListReq id_list.
     * @member {Array.<number>} id_list
     * @memberof client_proto.MailDeleteListReq
     * @instance
     */
    MailDeleteListReq.prototype.id_list = $util.emptyArray;

    /**
     * Creates a new MailDeleteListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.MailDeleteListReq
     * @static
     * @param {client_proto.IMailDeleteListReq=} [properties] Properties to set
     * @returns {client_proto.MailDeleteListReq} MailDeleteListReq instance
     */
    MailDeleteListReq.create = function create(properties) {
      return new MailDeleteListReq(properties);
    };

    /**
     * Encodes the specified MailDeleteListReq message. Does not implicitly {@link client_proto.MailDeleteListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailDeleteListReq
     * @static
     * @param {client_proto.IMailDeleteListReq} message MailDeleteListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailDeleteListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id_list != null && message.id_list.length) {
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
        for (var i = 0; i < message.id_list.length; ++i) writer.uint32(message.id_list[i]);
        writer.ldelim();
      }
      return writer;
    };

    /**
     * Encodes the specified MailDeleteListReq message, length delimited. Does not implicitly {@link client_proto.MailDeleteListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailDeleteListReq
     * @static
     * @param {client_proto.IMailDeleteListReq} message MailDeleteListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailDeleteListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailDeleteListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailDeleteListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailDeleteListReq} MailDeleteListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailDeleteListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailDeleteListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            if (!(message.id_list && message.id_list.length)) message.id_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.id_list.push(reader.uint32());
            } else message.id_list.push(reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailDeleteListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailDeleteListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailDeleteListReq} MailDeleteListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailDeleteListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailDeleteListReq message.
     * @function verify
     * @memberof client_proto.MailDeleteListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailDeleteListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id_list != null && message.hasOwnProperty("id_list")) {
        if (!Array.isArray(message.id_list)) return "id_list: array expected";
        for (var i = 0; i < message.id_list.length; ++i)
          if (!$util.isInteger(message.id_list[i])) return "id_list: integer[] expected";
      }
      return null;
    };

    /**
     * Creates a MailDeleteListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailDeleteListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailDeleteListReq} MailDeleteListReq
     */
    MailDeleteListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailDeleteListReq) return object;
      var message = new $root.client_proto.MailDeleteListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id_list) {
        if (!Array.isArray(object.id_list)) throw TypeError(".client_proto.MailDeleteListReq.id_list: array expected");
        message.id_list = [];
        for (var i = 0; i < object.id_list.length; ++i) message.id_list[i] = object.id_list[i] >>> 0;
      }
      return message;
    };

    /**
     * Creates a plain object from a MailDeleteListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailDeleteListReq
     * @static
     * @param {client_proto.MailDeleteListReq} message MailDeleteListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailDeleteListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.id_list = [];
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id_list && message.id_list.length) {
        object.id_list = [];
        for (var j = 0; j < message.id_list.length; ++j) object.id_list[j] = message.id_list[j];
      }
      return object;
    };

    /**
     * Converts this MailDeleteListReq to JSON.
     * @function toJSON
     * @memberof client_proto.MailDeleteListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailDeleteListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailDeleteListReq
     * @function getTypeUrl
     * @memberof client_proto.MailDeleteListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailDeleteListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailDeleteListReq";
    };

    return MailDeleteListReq;
  })();

  client_proto.MailDeleteListResp = (function () {
    /**
     * Properties of a MailDeleteListResp.
     * @memberof client_proto
     * @interface IMailDeleteListResp
     * @property {number|null} [result] MailDeleteListResp result
     * @property {Array.<number>|null} [id_list] MailDeleteListResp id_list
     * @property {client_proto.IUnReadInfoResp|null} [read_info] MailDeleteListResp read_info
     */

    /**
     * Constructs a new MailDeleteListResp.
     * @memberof client_proto
     * @classdesc Represents a MailDeleteListResp.
     * @implements IMailDeleteListResp
     * @constructor
     * @param {client_proto.IMailDeleteListResp=} [properties] Properties to set
     */
    function MailDeleteListResp(properties) {
      this.id_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailDeleteListResp result.
     * @member {number} result
     * @memberof client_proto.MailDeleteListResp
     * @instance
     */
    MailDeleteListResp.prototype.result = 0;

    /**
     * MailDeleteListResp id_list.
     * @member {Array.<number>} id_list
     * @memberof client_proto.MailDeleteListResp
     * @instance
     */
    MailDeleteListResp.prototype.id_list = $util.emptyArray;

    /**
     * MailDeleteListResp read_info.
     * @member {client_proto.IUnReadInfoResp|null|undefined} read_info
     * @memberof client_proto.MailDeleteListResp
     * @instance
     */
    MailDeleteListResp.prototype.read_info = null;

    /**
     * Creates a new MailDeleteListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.MailDeleteListResp
     * @static
     * @param {client_proto.IMailDeleteListResp=} [properties] Properties to set
     * @returns {client_proto.MailDeleteListResp} MailDeleteListResp instance
     */
    MailDeleteListResp.create = function create(properties) {
      return new MailDeleteListResp(properties);
    };

    /**
     * Encodes the specified MailDeleteListResp message. Does not implicitly {@link client_proto.MailDeleteListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailDeleteListResp
     * @static
     * @param {client_proto.IMailDeleteListResp} message MailDeleteListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailDeleteListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.id_list != null && message.id_list.length) {
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
        for (var i = 0; i < message.id_list.length; ++i) writer.uint32(message.id_list[i]);
        writer.ldelim();
      }
      if (message.read_info != null && Object.hasOwnProperty.call(message, "read_info"))
        $root.client_proto.UnReadInfoResp.encode(
          message.read_info,
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
        ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified MailDeleteListResp message, length delimited. Does not implicitly {@link client_proto.MailDeleteListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailDeleteListResp
     * @static
     * @param {client_proto.IMailDeleteListResp} message MailDeleteListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailDeleteListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailDeleteListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailDeleteListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailDeleteListResp} MailDeleteListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailDeleteListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailDeleteListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            if (!(message.id_list && message.id_list.length)) message.id_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.id_list.push(reader.uint32());
            } else message.id_list.push(reader.uint32());
            break;
          }
          case 3: {
            message.read_info = $root.client_proto.UnReadInfoResp.decode(reader, reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailDeleteListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailDeleteListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailDeleteListResp} MailDeleteListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailDeleteListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailDeleteListResp message.
     * @function verify
     * @memberof client_proto.MailDeleteListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailDeleteListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.id_list != null && message.hasOwnProperty("id_list")) {
        if (!Array.isArray(message.id_list)) return "id_list: array expected";
        for (var i = 0; i < message.id_list.length; ++i)
          if (!$util.isInteger(message.id_list[i])) return "id_list: integer[] expected";
      }
      if (message.read_info != null && message.hasOwnProperty("read_info")) {
        var error = $root.client_proto.UnReadInfoResp.verify(message.read_info);
        if (error) return "read_info." + error;
      }
      return null;
    };

    /**
     * Creates a MailDeleteListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailDeleteListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailDeleteListResp} MailDeleteListResp
     */
    MailDeleteListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailDeleteListResp) return object;
      var message = new $root.client_proto.MailDeleteListResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.id_list) {
        if (!Array.isArray(object.id_list)) throw TypeError(".client_proto.MailDeleteListResp.id_list: array expected");
        message.id_list = [];
        for (var i = 0; i < object.id_list.length; ++i) message.id_list[i] = object.id_list[i] >>> 0;
      }
      if (object.read_info != null) {
        if (typeof object.read_info !== "object")
          throw TypeError(".client_proto.MailDeleteListResp.read_info: object expected");
        message.read_info = $root.client_proto.UnReadInfoResp.fromObject(object.read_info);
      }
      return message;
    };

    /**
     * Creates a plain object from a MailDeleteListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailDeleteListResp
     * @static
     * @param {client_proto.MailDeleteListResp} message MailDeleteListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailDeleteListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.id_list = [];
      if (options.defaults) {
        object.result = 0;
        object.read_info = null;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.id_list && message.id_list.length) {
        object.id_list = [];
        for (var j = 0; j < message.id_list.length; ++j) object.id_list[j] = message.id_list[j];
      }
      if (message.read_info != null && message.hasOwnProperty("read_info"))
        object.read_info = $root.client_proto.UnReadInfoResp.toObject(message.read_info, options);
      return object;
    };

    /**
     * Converts this MailDeleteListResp to JSON.
     * @function toJSON
     * @memberof client_proto.MailDeleteListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailDeleteListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailDeleteListResp
     * @function getTypeUrl
     * @memberof client_proto.MailDeleteListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailDeleteListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailDeleteListResp";
    };

    return MailDeleteListResp;
  })();

  client_proto.MailReceiveListReq = (function () {
    /**
     * Properties of a MailReceiveListReq.
     * @memberof client_proto
     * @interface IMailReceiveListReq
     * @property {number|null} [uid] MailReceiveListReq uid
     * @property {Array.<number>|null} [id_list] MailReceiveListReq id_list
     */

    /**
     * Constructs a new MailReceiveListReq.
     * @memberof client_proto
     * @classdesc Represents a MailReceiveListReq.
     * @implements IMailReceiveListReq
     * @constructor
     * @param {client_proto.IMailReceiveListReq=} [properties] Properties to set
     */
    function MailReceiveListReq(properties) {
      this.id_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailReceiveListReq uid.
     * @member {number} uid
     * @memberof client_proto.MailReceiveListReq
     * @instance
     */
    MailReceiveListReq.prototype.uid = 0;

    /**
     * MailReceiveListReq id_list.
     * @member {Array.<number>} id_list
     * @memberof client_proto.MailReceiveListReq
     * @instance
     */
    MailReceiveListReq.prototype.id_list = $util.emptyArray;

    /**
     * Creates a new MailReceiveListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.MailReceiveListReq
     * @static
     * @param {client_proto.IMailReceiveListReq=} [properties] Properties to set
     * @returns {client_proto.MailReceiveListReq} MailReceiveListReq instance
     */
    MailReceiveListReq.create = function create(properties) {
      return new MailReceiveListReq(properties);
    };

    /**
     * Encodes the specified MailReceiveListReq message. Does not implicitly {@link client_proto.MailReceiveListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailReceiveListReq
     * @static
     * @param {client_proto.IMailReceiveListReq} message MailReceiveListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailReceiveListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id_list != null && message.id_list.length) {
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
        for (var i = 0; i < message.id_list.length; ++i) writer.uint32(message.id_list[i]);
        writer.ldelim();
      }
      return writer;
    };

    /**
     * Encodes the specified MailReceiveListReq message, length delimited. Does not implicitly {@link client_proto.MailReceiveListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailReceiveListReq
     * @static
     * @param {client_proto.IMailReceiveListReq} message MailReceiveListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailReceiveListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailReceiveListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailReceiveListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailReceiveListReq} MailReceiveListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailReceiveListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailReceiveListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            if (!(message.id_list && message.id_list.length)) message.id_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.id_list.push(reader.uint32());
            } else message.id_list.push(reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailReceiveListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailReceiveListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailReceiveListReq} MailReceiveListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailReceiveListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailReceiveListReq message.
     * @function verify
     * @memberof client_proto.MailReceiveListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailReceiveListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id_list != null && message.hasOwnProperty("id_list")) {
        if (!Array.isArray(message.id_list)) return "id_list: array expected";
        for (var i = 0; i < message.id_list.length; ++i)
          if (!$util.isInteger(message.id_list[i])) return "id_list: integer[] expected";
      }
      return null;
    };

    /**
     * Creates a MailReceiveListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailReceiveListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailReceiveListReq} MailReceiveListReq
     */
    MailReceiveListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailReceiveListReq) return object;
      var message = new $root.client_proto.MailReceiveListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id_list) {
        if (!Array.isArray(object.id_list)) throw TypeError(".client_proto.MailReceiveListReq.id_list: array expected");
        message.id_list = [];
        for (var i = 0; i < object.id_list.length; ++i) message.id_list[i] = object.id_list[i] >>> 0;
      }
      return message;
    };

    /**
     * Creates a plain object from a MailReceiveListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailReceiveListReq
     * @static
     * @param {client_proto.MailReceiveListReq} message MailReceiveListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailReceiveListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.id_list = [];
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id_list && message.id_list.length) {
        object.id_list = [];
        for (var j = 0; j < message.id_list.length; ++j) object.id_list[j] = message.id_list[j];
      }
      return object;
    };

    /**
     * Converts this MailReceiveListReq to JSON.
     * @function toJSON
     * @memberof client_proto.MailReceiveListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailReceiveListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailReceiveListReq
     * @function getTypeUrl
     * @memberof client_proto.MailReceiveListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailReceiveListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailReceiveListReq";
    };

    return MailReceiveListReq;
  })();

  client_proto.MailReceiveInfo = (function () {
    /**
     * Properties of a MailReceiveInfo.
     * @memberof client_proto
     * @interface IMailReceiveInfo
     * @property {number|null} [mail_id] MailReceiveInfo mail_id
     * @property {number|null} [result] MailReceiveInfo result
     * @property {string|null} [attachment] MailReceiveInfo attachment
     */

    /**
     * Constructs a new MailReceiveInfo.
     * @memberof client_proto
     * @classdesc Represents a MailReceiveInfo.
     * @implements IMailReceiveInfo
     * @constructor
     * @param {client_proto.IMailReceiveInfo=} [properties] Properties to set
     */
    function MailReceiveInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailReceiveInfo mail_id.
     * @member {number} mail_id
     * @memberof client_proto.MailReceiveInfo
     * @instance
     */
    MailReceiveInfo.prototype.mail_id = 0;

    /**
     * MailReceiveInfo result.
     * @member {number} result
     * @memberof client_proto.MailReceiveInfo
     * @instance
     */
    MailReceiveInfo.prototype.result = 0;

    /**
     * MailReceiveInfo attachment.
     * @member {string} attachment
     * @memberof client_proto.MailReceiveInfo
     * @instance
     */
    MailReceiveInfo.prototype.attachment = "";

    /**
     * Creates a new MailReceiveInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.MailReceiveInfo
     * @static
     * @param {client_proto.IMailReceiveInfo=} [properties] Properties to set
     * @returns {client_proto.MailReceiveInfo} MailReceiveInfo instance
     */
    MailReceiveInfo.create = function create(properties) {
      return new MailReceiveInfo(properties);
    };

    /**
     * Encodes the specified MailReceiveInfo message. Does not implicitly {@link client_proto.MailReceiveInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailReceiveInfo
     * @static
     * @param {client_proto.IMailReceiveInfo} message MailReceiveInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailReceiveInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.mail_id != null && Object.hasOwnProperty.call(message, "mail_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.mail_id);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.attachment != null && Object.hasOwnProperty.call(message, "attachment"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.attachment);
      return writer;
    };

    /**
     * Encodes the specified MailReceiveInfo message, length delimited. Does not implicitly {@link client_proto.MailReceiveInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailReceiveInfo
     * @static
     * @param {client_proto.IMailReceiveInfo} message MailReceiveInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailReceiveInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailReceiveInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailReceiveInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailReceiveInfo} MailReceiveInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailReceiveInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailReceiveInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.mail_id = reader.uint32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            message.attachment = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailReceiveInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailReceiveInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailReceiveInfo} MailReceiveInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailReceiveInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailReceiveInfo message.
     * @function verify
     * @memberof client_proto.MailReceiveInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailReceiveInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.mail_id != null && message.hasOwnProperty("mail_id"))
        if (!$util.isInteger(message.mail_id)) return "mail_id: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.attachment != null && message.hasOwnProperty("attachment"))
        if (!$util.isString(message.attachment)) return "attachment: string expected";
      return null;
    };

    /**
     * Creates a MailReceiveInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailReceiveInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailReceiveInfo} MailReceiveInfo
     */
    MailReceiveInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailReceiveInfo) return object;
      var message = new $root.client_proto.MailReceiveInfo();
      if (object.mail_id != null) message.mail_id = object.mail_id >>> 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.attachment != null) message.attachment = String(object.attachment);
      return message;
    };

    /**
     * Creates a plain object from a MailReceiveInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailReceiveInfo
     * @static
     * @param {client_proto.MailReceiveInfo} message MailReceiveInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailReceiveInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.mail_id = 0;
        object.result = 0;
        object.attachment = "";
      }
      if (message.mail_id != null && message.hasOwnProperty("mail_id")) object.mail_id = message.mail_id;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.attachment != null && message.hasOwnProperty("attachment")) object.attachment = message.attachment;
      return object;
    };

    /**
     * Converts this MailReceiveInfo to JSON.
     * @function toJSON
     * @memberof client_proto.MailReceiveInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailReceiveInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailReceiveInfo
     * @function getTypeUrl
     * @memberof client_proto.MailReceiveInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailReceiveInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailReceiveInfo";
    };

    return MailReceiveInfo;
  })();

  client_proto.MailReceiveListResp = (function () {
    /**
     * Properties of a MailReceiveListResp.
     * @memberof client_proto
     * @interface IMailReceiveListResp
     * @property {number|null} [result] MailReceiveListResp result
     * @property {Array.<client_proto.IMailReceiveInfo>|null} [receive_list] MailReceiveListResp receive_list
     */

    /**
     * Constructs a new MailReceiveListResp.
     * @memberof client_proto
     * @classdesc Represents a MailReceiveListResp.
     * @implements IMailReceiveListResp
     * @constructor
     * @param {client_proto.IMailReceiveListResp=} [properties] Properties to set
     */
    function MailReceiveListResp(properties) {
      this.receive_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailReceiveListResp result.
     * @member {number} result
     * @memberof client_proto.MailReceiveListResp
     * @instance
     */
    MailReceiveListResp.prototype.result = 0;

    /**
     * MailReceiveListResp receive_list.
     * @member {Array.<client_proto.IMailReceiveInfo>} receive_list
     * @memberof client_proto.MailReceiveListResp
     * @instance
     */
    MailReceiveListResp.prototype.receive_list = $util.emptyArray;

    /**
     * Creates a new MailReceiveListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.MailReceiveListResp
     * @static
     * @param {client_proto.IMailReceiveListResp=} [properties] Properties to set
     * @returns {client_proto.MailReceiveListResp} MailReceiveListResp instance
     */
    MailReceiveListResp.create = function create(properties) {
      return new MailReceiveListResp(properties);
    };

    /**
     * Encodes the specified MailReceiveListResp message. Does not implicitly {@link client_proto.MailReceiveListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailReceiveListResp
     * @static
     * @param {client_proto.IMailReceiveListResp} message MailReceiveListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailReceiveListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.receive_list != null && message.receive_list.length)
        for (var i = 0; i < message.receive_list.length; ++i)
          $root.client_proto.MailReceiveInfo.encode(
            message.receive_list[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified MailReceiveListResp message, length delimited. Does not implicitly {@link client_proto.MailReceiveListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailReceiveListResp
     * @static
     * @param {client_proto.IMailReceiveListResp} message MailReceiveListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailReceiveListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailReceiveListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailReceiveListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailReceiveListResp} MailReceiveListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailReceiveListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailReceiveListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            if (!(message.receive_list && message.receive_list.length)) message.receive_list = [];
            message.receive_list.push($root.client_proto.MailReceiveInfo.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailReceiveListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailReceiveListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailReceiveListResp} MailReceiveListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailReceiveListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailReceiveListResp message.
     * @function verify
     * @memberof client_proto.MailReceiveListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailReceiveListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.receive_list != null && message.hasOwnProperty("receive_list")) {
        if (!Array.isArray(message.receive_list)) return "receive_list: array expected";
        for (var i = 0; i < message.receive_list.length; ++i) {
          var error = $root.client_proto.MailReceiveInfo.verify(message.receive_list[i]);
          if (error) return "receive_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates a MailReceiveListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailReceiveListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailReceiveListResp} MailReceiveListResp
     */
    MailReceiveListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailReceiveListResp) return object;
      var message = new $root.client_proto.MailReceiveListResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.receive_list) {
        if (!Array.isArray(object.receive_list))
          throw TypeError(".client_proto.MailReceiveListResp.receive_list: array expected");
        message.receive_list = [];
        for (var i = 0; i < object.receive_list.length; ++i) {
          if (typeof object.receive_list[i] !== "object")
            throw TypeError(".client_proto.MailReceiveListResp.receive_list: object expected");
          message.receive_list[i] = $root.client_proto.MailReceiveInfo.fromObject(object.receive_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a MailReceiveListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailReceiveListResp
     * @static
     * @param {client_proto.MailReceiveListResp} message MailReceiveListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailReceiveListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.receive_list = [];
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.receive_list && message.receive_list.length) {
        object.receive_list = [];
        for (var j = 0; j < message.receive_list.length; ++j)
          object.receive_list[j] = $root.client_proto.MailReceiveInfo.toObject(message.receive_list[j], options);
      }
      return object;
    };

    /**
     * Converts this MailReceiveListResp to JSON.
     * @function toJSON
     * @memberof client_proto.MailReceiveListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailReceiveListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailReceiveListResp
     * @function getTypeUrl
     * @memberof client_proto.MailReceiveListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailReceiveListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailReceiveListResp";
    };

    return MailReceiveListResp;
  })();

  client_proto.MailMarkReadReq = (function () {
    /**
     * Properties of a MailMarkReadReq.
     * @memberof client_proto
     * @interface IMailMarkReadReq
     * @property {number|null} [uid] MailMarkReadReq uid
     * @property {Array.<number>|null} [id_list] MailMarkReadReq id_list
     */

    /**
     * Constructs a new MailMarkReadReq.
     * @memberof client_proto
     * @classdesc Represents a MailMarkReadReq.
     * @implements IMailMarkReadReq
     * @constructor
     * @param {client_proto.IMailMarkReadReq=} [properties] Properties to set
     */
    function MailMarkReadReq(properties) {
      this.id_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailMarkReadReq uid.
     * @member {number} uid
     * @memberof client_proto.MailMarkReadReq
     * @instance
     */
    MailMarkReadReq.prototype.uid = 0;

    /**
     * MailMarkReadReq id_list.
     * @member {Array.<number>} id_list
     * @memberof client_proto.MailMarkReadReq
     * @instance
     */
    MailMarkReadReq.prototype.id_list = $util.emptyArray;

    /**
     * Creates a new MailMarkReadReq instance using the specified properties.
     * @function create
     * @memberof client_proto.MailMarkReadReq
     * @static
     * @param {client_proto.IMailMarkReadReq=} [properties] Properties to set
     * @returns {client_proto.MailMarkReadReq} MailMarkReadReq instance
     */
    MailMarkReadReq.create = function create(properties) {
      return new MailMarkReadReq(properties);
    };

    /**
     * Encodes the specified MailMarkReadReq message. Does not implicitly {@link client_proto.MailMarkReadReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailMarkReadReq
     * @static
     * @param {client_proto.IMailMarkReadReq} message MailMarkReadReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailMarkReadReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id_list != null && message.id_list.length) {
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
        for (var i = 0; i < message.id_list.length; ++i) writer.uint32(message.id_list[i]);
        writer.ldelim();
      }
      return writer;
    };

    /**
     * Encodes the specified MailMarkReadReq message, length delimited. Does not implicitly {@link client_proto.MailMarkReadReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailMarkReadReq
     * @static
     * @param {client_proto.IMailMarkReadReq} message MailMarkReadReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailMarkReadReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailMarkReadReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailMarkReadReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailMarkReadReq} MailMarkReadReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailMarkReadReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailMarkReadReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            if (!(message.id_list && message.id_list.length)) message.id_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.id_list.push(reader.uint32());
            } else message.id_list.push(reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailMarkReadReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailMarkReadReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailMarkReadReq} MailMarkReadReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailMarkReadReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailMarkReadReq message.
     * @function verify
     * @memberof client_proto.MailMarkReadReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailMarkReadReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id_list != null && message.hasOwnProperty("id_list")) {
        if (!Array.isArray(message.id_list)) return "id_list: array expected";
        for (var i = 0; i < message.id_list.length; ++i)
          if (!$util.isInteger(message.id_list[i])) return "id_list: integer[] expected";
      }
      return null;
    };

    /**
     * Creates a MailMarkReadReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailMarkReadReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailMarkReadReq} MailMarkReadReq
     */
    MailMarkReadReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailMarkReadReq) return object;
      var message = new $root.client_proto.MailMarkReadReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id_list) {
        if (!Array.isArray(object.id_list)) throw TypeError(".client_proto.MailMarkReadReq.id_list: array expected");
        message.id_list = [];
        for (var i = 0; i < object.id_list.length; ++i) message.id_list[i] = object.id_list[i] >>> 0;
      }
      return message;
    };

    /**
     * Creates a plain object from a MailMarkReadReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailMarkReadReq
     * @static
     * @param {client_proto.MailMarkReadReq} message MailMarkReadReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailMarkReadReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.id_list = [];
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id_list && message.id_list.length) {
        object.id_list = [];
        for (var j = 0; j < message.id_list.length; ++j) object.id_list[j] = message.id_list[j];
      }
      return object;
    };

    /**
     * Converts this MailMarkReadReq to JSON.
     * @function toJSON
     * @memberof client_proto.MailMarkReadReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailMarkReadReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailMarkReadReq
     * @function getTypeUrl
     * @memberof client_proto.MailMarkReadReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailMarkReadReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailMarkReadReq";
    };

    return MailMarkReadReq;
  })();

  client_proto.MailMarkReadResp = (function () {
    /**
     * Properties of a MailMarkReadResp.
     * @memberof client_proto
     * @interface IMailMarkReadResp
     * @property {number|null} [result] MailMarkReadResp result
     * @property {Array.<number>|null} [id_list] MailMarkReadResp id_list
     */

    /**
     * Constructs a new MailMarkReadResp.
     * @memberof client_proto
     * @classdesc Represents a MailMarkReadResp.
     * @implements IMailMarkReadResp
     * @constructor
     * @param {client_proto.IMailMarkReadResp=} [properties] Properties to set
     */
    function MailMarkReadResp(properties) {
      this.id_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailMarkReadResp result.
     * @member {number} result
     * @memberof client_proto.MailMarkReadResp
     * @instance
     */
    MailMarkReadResp.prototype.result = 0;

    /**
     * MailMarkReadResp id_list.
     * @member {Array.<number>} id_list
     * @memberof client_proto.MailMarkReadResp
     * @instance
     */
    MailMarkReadResp.prototype.id_list = $util.emptyArray;

    /**
     * Creates a new MailMarkReadResp instance using the specified properties.
     * @function create
     * @memberof client_proto.MailMarkReadResp
     * @static
     * @param {client_proto.IMailMarkReadResp=} [properties] Properties to set
     * @returns {client_proto.MailMarkReadResp} MailMarkReadResp instance
     */
    MailMarkReadResp.create = function create(properties) {
      return new MailMarkReadResp(properties);
    };

    /**
     * Encodes the specified MailMarkReadResp message. Does not implicitly {@link client_proto.MailMarkReadResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailMarkReadResp
     * @static
     * @param {client_proto.IMailMarkReadResp} message MailMarkReadResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailMarkReadResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.id_list != null && message.id_list.length) {
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
        for (var i = 0; i < message.id_list.length; ++i) writer.uint32(message.id_list[i]);
        writer.ldelim();
      }
      return writer;
    };

    /**
     * Encodes the specified MailMarkReadResp message, length delimited. Does not implicitly {@link client_proto.MailMarkReadResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailMarkReadResp
     * @static
     * @param {client_proto.IMailMarkReadResp} message MailMarkReadResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailMarkReadResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailMarkReadResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailMarkReadResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailMarkReadResp} MailMarkReadResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailMarkReadResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailMarkReadResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            if (!(message.id_list && message.id_list.length)) message.id_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.id_list.push(reader.uint32());
            } else message.id_list.push(reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailMarkReadResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailMarkReadResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailMarkReadResp} MailMarkReadResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailMarkReadResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailMarkReadResp message.
     * @function verify
     * @memberof client_proto.MailMarkReadResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailMarkReadResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.id_list != null && message.hasOwnProperty("id_list")) {
        if (!Array.isArray(message.id_list)) return "id_list: array expected";
        for (var i = 0; i < message.id_list.length; ++i)
          if (!$util.isInteger(message.id_list[i])) return "id_list: integer[] expected";
      }
      return null;
    };

    /**
     * Creates a MailMarkReadResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailMarkReadResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailMarkReadResp} MailMarkReadResp
     */
    MailMarkReadResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailMarkReadResp) return object;
      var message = new $root.client_proto.MailMarkReadResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.id_list) {
        if (!Array.isArray(object.id_list)) throw TypeError(".client_proto.MailMarkReadResp.id_list: array expected");
        message.id_list = [];
        for (var i = 0; i < object.id_list.length; ++i) message.id_list[i] = object.id_list[i] >>> 0;
      }
      return message;
    };

    /**
     * Creates a plain object from a MailMarkReadResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailMarkReadResp
     * @static
     * @param {client_proto.MailMarkReadResp} message MailMarkReadResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailMarkReadResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.id_list = [];
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.id_list && message.id_list.length) {
        object.id_list = [];
        for (var j = 0; j < message.id_list.length; ++j) object.id_list[j] = message.id_list[j];
      }
      return object;
    };

    /**
     * Converts this MailMarkReadResp to JSON.
     * @function toJSON
     * @memberof client_proto.MailMarkReadResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailMarkReadResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailMarkReadResp
     * @function getTypeUrl
     * @memberof client_proto.MailMarkReadResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailMarkReadResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailMarkReadResp";
    };

    return MailMarkReadResp;
  })();

  client_proto.MailInfoPush = (function () {
    /**
     * Properties of a MailInfoPush.
     * @memberof client_proto
     * @interface IMailInfoPush
     * @property {number|null} [max_mail_id] MailInfoPush max_mail_id
     * @property {client_proto.IMailInfo|null} [main_info] MailInfoPush main_info
     * @property {number|null} [min_mail_id] MailInfoPush min_mail_id
     */

    /**
     * Constructs a new MailInfoPush.
     * @memberof client_proto
     * @classdesc Represents a MailInfoPush.
     * @implements IMailInfoPush
     * @constructor
     * @param {client_proto.IMailInfoPush=} [properties] Properties to set
     */
    function MailInfoPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailInfoPush max_mail_id.
     * @member {number} max_mail_id
     * @memberof client_proto.MailInfoPush
     * @instance
     */
    MailInfoPush.prototype.max_mail_id = 0;

    /**
     * MailInfoPush main_info.
     * @member {client_proto.IMailInfo|null|undefined} main_info
     * @memberof client_proto.MailInfoPush
     * @instance
     */
    MailInfoPush.prototype.main_info = null;

    /**
     * MailInfoPush min_mail_id.
     * @member {number} min_mail_id
     * @memberof client_proto.MailInfoPush
     * @instance
     */
    MailInfoPush.prototype.min_mail_id = 0;

    /**
     * Creates a new MailInfoPush instance using the specified properties.
     * @function create
     * @memberof client_proto.MailInfoPush
     * @static
     * @param {client_proto.IMailInfoPush=} [properties] Properties to set
     * @returns {client_proto.MailInfoPush} MailInfoPush instance
     */
    MailInfoPush.create = function create(properties) {
      return new MailInfoPush(properties);
    };

    /**
     * Encodes the specified MailInfoPush message. Does not implicitly {@link client_proto.MailInfoPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MailInfoPush
     * @static
     * @param {client_proto.IMailInfoPush} message MailInfoPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailInfoPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.max_mail_id != null && Object.hasOwnProperty.call(message, "max_mail_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.max_mail_id);
      if (message.main_info != null && Object.hasOwnProperty.call(message, "main_info"))
        $root.client_proto.MailInfo.encode(
          message.main_info,
          writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
        ).ldelim();
      if (message.min_mail_id != null && Object.hasOwnProperty.call(message, "min_mail_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.min_mail_id);
      return writer;
    };

    /**
     * Encodes the specified MailInfoPush message, length delimited. Does not implicitly {@link client_proto.MailInfoPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MailInfoPush
     * @static
     * @param {client_proto.IMailInfoPush} message MailInfoPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailInfoPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailInfoPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MailInfoPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MailInfoPush} MailInfoPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailInfoPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MailInfoPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.max_mail_id = reader.uint32();
            break;
          }
          case 2: {
            message.main_info = $root.client_proto.MailInfo.decode(reader, reader.uint32());
            break;
          }
          case 3: {
            message.min_mail_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailInfoPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MailInfoPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MailInfoPush} MailInfoPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailInfoPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailInfoPush message.
     * @function verify
     * @memberof client_proto.MailInfoPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailInfoPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.max_mail_id != null && message.hasOwnProperty("max_mail_id"))
        if (!$util.isInteger(message.max_mail_id)) return "max_mail_id: integer expected";
      if (message.main_info != null && message.hasOwnProperty("main_info")) {
        var error = $root.client_proto.MailInfo.verify(message.main_info);
        if (error) return "main_info." + error;
      }
      if (message.min_mail_id != null && message.hasOwnProperty("min_mail_id"))
        if (!$util.isInteger(message.min_mail_id)) return "min_mail_id: integer expected";
      return null;
    };

    /**
     * Creates a MailInfoPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MailInfoPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MailInfoPush} MailInfoPush
     */
    MailInfoPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MailInfoPush) return object;
      var message = new $root.client_proto.MailInfoPush();
      if (object.max_mail_id != null) message.max_mail_id = object.max_mail_id >>> 0;
      if (object.main_info != null) {
        if (typeof object.main_info !== "object")
          throw TypeError(".client_proto.MailInfoPush.main_info: object expected");
        message.main_info = $root.client_proto.MailInfo.fromObject(object.main_info);
      }
      if (object.min_mail_id != null) message.min_mail_id = object.min_mail_id >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a MailInfoPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MailInfoPush
     * @static
     * @param {client_proto.MailInfoPush} message MailInfoPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailInfoPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.max_mail_id = 0;
        object.main_info = null;
        object.min_mail_id = 0;
      }
      if (message.max_mail_id != null && message.hasOwnProperty("max_mail_id"))
        object.max_mail_id = message.max_mail_id;
      if (message.main_info != null && message.hasOwnProperty("main_info"))
        object.main_info = $root.client_proto.MailInfo.toObject(message.main_info, options);
      if (message.min_mail_id != null && message.hasOwnProperty("min_mail_id"))
        object.min_mail_id = message.min_mail_id;
      return object;
    };

    /**
     * Converts this MailInfoPush to JSON.
     * @function toJSON
     * @memberof client_proto.MailInfoPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailInfoPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailInfoPush
     * @function getTypeUrl
     * @memberof client_proto.MailInfoPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailInfoPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MailInfoPush";
    };

    return MailInfoPush;
  })();

  /**
   * PROXY_USER_INFO_SUB_MSG_ID enum.
   * @name client_proto.PROXY_USER_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} PROXYMI_NULL=0 PROXYMI_NULL value
   * @property {number} PROXYMI_USER_MSG_ID_MIN=2300 PROXYMI_USER_MSG_ID_MIN value
   * @property {number} PROXYMI_USER_INFO_REQ=2301 PROXYMI_USER_INFO_REQ value
   * @property {number} PROXYMI_USER_INFO_RESP=2302 PROXYMI_USER_INFO_RESP value
   * @property {number} PROXYMI_USER_WITHDRAW_LIST_REQ=2303 PROXYMI_USER_WITHDRAW_LIST_REQ value
   * @property {number} PROXYMI_USER_WITHDRAW_LIST_RESP=2304 PROXYMI_USER_WITHDRAW_LIST_RESP value
   * @property {number} PROXYMI_USER_WITHDRAW_REQ=2305 PROXYMI_USER_WITHDRAW_REQ value
   * @property {number} PROXYMI_USER_WITHDRAW_RESP=2306 PROXYMI_USER_WITHDRAW_RESP value
   * @property {number} PROXYMI_USER_TEAM_INFO_REQ=2307 PROXYMI_USER_TEAM_INFO_REQ value
   * @property {number} PROXYMI_USER_TEAM_INFO_RESP=2308 PROXYMI_USER_TEAM_INFO_RESP value
   * @property {number} PROXYMI_USER_SUB_INFO_REQ=2309 PROXYMI_USER_SUB_INFO_REQ value
   * @property {number} PROXYMI_USER_SUB_INFO_RESP=2310 PROXYMI_USER_SUB_INFO_RESP value
   * @property {number} PROXYMI_USER_TEAM_LIST_REQ=2311 PROXYMI_USER_TEAM_LIST_REQ value
   * @property {number} PROXYMI_USER_TEAM_LIST_RESP=2312 PROXYMI_USER_TEAM_LIST_RESP value
   * @property {number} PROXYMI_USER_COMMISSION_LIST_REQ=2313 PROXYMI_USER_COMMISSION_LIST_REQ value
   * @property {number} PROXYMI_USER_COMMISSION_LIST_RESP=2314 PROXYMI_USER_COMMISSION_LIST_RESP value
   * @property {number} PROXYMI_USER_INVITE_REQ=2315 PROXYMI_USER_INVITE_REQ value
   * @property {number} PROXYMI_USER_INVITE_RESP=2316 PROXYMI_USER_INVITE_RESP value
   * @property {number} PROXYMI_PHP_AUDIT_WITHDRAW_REQ=2317 PROXYMI_PHP_AUDIT_WITHDRAW_REQ value
   * @property {number} PROXYMI_PHP_AUDIT_WITHDRAW_RESP=2318 PROXYMI_PHP_AUDIT_WITHDRAW_RESP value
   * @property {number} PROXYMI_PUSH_SERVER_INVITE_REQ=2319 PROXYMI_PUSH_SERVER_INVITE_REQ value
   * @property {number} PROXYMI_PUSH_SERVER_INVITE_RESP=2320 PROXYMI_PUSH_SERVER_INVITE_RESP value
   * @property {number} PROXYMI_PUSH_SERVER_COMMISSION_REQ=2321 PROXYMI_PUSH_SERVER_COMMISSION_REQ value
   * @property {number} PROXYMI_PUSH_SERVER_DEPOSIT_REQ=2323 PROXYMI_PUSH_SERVER_DEPOSIT_REQ value
   * @property {number} PROXYMI_PUSH_SERVER_BET_REQ=2325 PROXYMI_PUSH_SERVER_BET_REQ value
   * @property {number} PROXYMI_USER_AGENT_CONFIG_REQ=2327 PROXYMI_USER_AGENT_CONFIG_REQ value
   * @property {number} PROXYMI_USER_AGENT_CONFIG_RESP=2328 PROXYMI_USER_AGENT_CONFIG_RESP value
   * @property {number} PROXYMI_CHECK_INVITE_STATUS_REQ=2329 PROXYMI_CHECK_INVITE_STATUS_REQ value
   * @property {number} PROXYMI_CHECK_INVITE_STATUS_RESP=2330 PROXYMI_CHECK_INVITE_STATUS_RESP value
   * @property {number} PROXYMI_USER_GET_INVITE_REWARD_REQ=2331 PROXYMI_USER_GET_INVITE_REWARD_REQ value
   * @property {number} PROXYMI_USER_GET_INVITE_REWARD_RESP=2332 PROXYMI_USER_GET_INVITE_REWARD_RESP value
   * @property {number} PROXYMI_USER_MSG_ID_MAX=2350 PROXYMI_USER_MSG_ID_MAX value
   */
  client_proto.PROXY_USER_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "PROXYMI_NULL")] = 0;
    values[(valuesById[2300] = "PROXYMI_USER_MSG_ID_MIN")] = 2300;
    values[(valuesById[2301] = "PROXYMI_USER_INFO_REQ")] = 2301;
    values[(valuesById[2302] = "PROXYMI_USER_INFO_RESP")] = 2302;
    values[(valuesById[2303] = "PROXYMI_USER_WITHDRAW_LIST_REQ")] = 2303;
    values[(valuesById[2304] = "PROXYMI_USER_WITHDRAW_LIST_RESP")] = 2304;
    values[(valuesById[2305] = "PROXYMI_USER_WITHDRAW_REQ")] = 2305;
    values[(valuesById[2306] = "PROXYMI_USER_WITHDRAW_RESP")] = 2306;
    values[(valuesById[2307] = "PROXYMI_USER_TEAM_INFO_REQ")] = 2307;
    values[(valuesById[2308] = "PROXYMI_USER_TEAM_INFO_RESP")] = 2308;
    values[(valuesById[2309] = "PROXYMI_USER_SUB_INFO_REQ")] = 2309;
    values[(valuesById[2310] = "PROXYMI_USER_SUB_INFO_RESP")] = 2310;
    values[(valuesById[2311] = "PROXYMI_USER_TEAM_LIST_REQ")] = 2311;
    values[(valuesById[2312] = "PROXYMI_USER_TEAM_LIST_RESP")] = 2312;
    values[(valuesById[2313] = "PROXYMI_USER_COMMISSION_LIST_REQ")] = 2313;
    values[(valuesById[2314] = "PROXYMI_USER_COMMISSION_LIST_RESP")] = 2314;
    values[(valuesById[2315] = "PROXYMI_USER_INVITE_REQ")] = 2315;
    values[(valuesById[2316] = "PROXYMI_USER_INVITE_RESP")] = 2316;
    values[(valuesById[2317] = "PROXYMI_PHP_AUDIT_WITHDRAW_REQ")] = 2317;
    values[(valuesById[2318] = "PROXYMI_PHP_AUDIT_WITHDRAW_RESP")] = 2318;
    values[(valuesById[2319] = "PROXYMI_PUSH_SERVER_INVITE_REQ")] = 2319;
    values[(valuesById[2320] = "PROXYMI_PUSH_SERVER_INVITE_RESP")] = 2320;
    values[(valuesById[2321] = "PROXYMI_PUSH_SERVER_COMMISSION_REQ")] = 2321;
    values[(valuesById[2323] = "PROXYMI_PUSH_SERVER_DEPOSIT_REQ")] = 2323;
    values[(valuesById[2325] = "PROXYMI_PUSH_SERVER_BET_REQ")] = 2325;
    values[(valuesById[2327] = "PROXYMI_USER_AGENT_CONFIG_REQ")] = 2327;
    values[(valuesById[2328] = "PROXYMI_USER_AGENT_CONFIG_RESP")] = 2328;
    values[(valuesById[2329] = "PROXYMI_CHECK_INVITE_STATUS_REQ")] = 2329;
    values[(valuesById[2330] = "PROXYMI_CHECK_INVITE_STATUS_RESP")] = 2330;
    values[(valuesById[2331] = "PROXYMI_USER_GET_INVITE_REWARD_REQ")] = 2331;
    values[(valuesById[2332] = "PROXYMI_USER_GET_INVITE_REWARD_RESP")] = 2332;
    values[(valuesById[2350] = "PROXYMI_USER_MSG_ID_MAX")] = 2350;
    return values;
  })();

  /**
   * OP_STATUS enum.
   * @name client_proto.OP_STATUS
   * @enum {number}
   * @property {number} OP_STATUS_PROCESSING=0 OP_STATUS_PROCESSING value
   * @property {number} OP_STATUS_SUCCESS=1 OP_STATUS_SUCCESS value
   * @property {number} OP_STATUS_FAIL=2 OP_STATUS_FAIL value
   */
  client_proto.OP_STATUS = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "OP_STATUS_PROCESSING")] = 0;
    values[(valuesById[1] = "OP_STATUS_SUCCESS")] = 1;
    values[(valuesById[2] = "OP_STATUS_FAIL")] = 2;
    return values;
  })();

  /**
   * TEAM_TAB_TYPE enum.
   * @name client_proto.TEAM_TAB_TYPE
   * @enum {number}
   * @property {number} TEAM_TAB_TYPE_NULL=0 TEAM_TAB_TYPE_NULL value
   * @property {number} TEAM_TAB_TYPE_FIRST=1 TEAM_TAB_TYPE_FIRST value
   * @property {number} TEAM_TAB_TYPE_SECOND=2 TEAM_TAB_TYPE_SECOND value
   * @property {number} TEAM_TAB_TYPE_THIRD=3 TEAM_TAB_TYPE_THIRD value
   * @property {number} TEAM_TAB_TYPE_FOURTH=4 TEAM_TAB_TYPE_FOURTH value
   * @property {number} TEAM_TAB_TYPE_FIFTH=5 TEAM_TAB_TYPE_FIFTH value
   * @property {number} TEAM_TAB_TYPE_SIXTH=6 TEAM_TAB_TYPE_SIXTH value
   */
  client_proto.TEAM_TAB_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "TEAM_TAB_TYPE_NULL")] = 0;
    values[(valuesById[1] = "TEAM_TAB_TYPE_FIRST")] = 1;
    values[(valuesById[2] = "TEAM_TAB_TYPE_SECOND")] = 2;
    values[(valuesById[3] = "TEAM_TAB_TYPE_THIRD")] = 3;
    values[(valuesById[4] = "TEAM_TAB_TYPE_FOURTH")] = 4;
    values[(valuesById[5] = "TEAM_TAB_TYPE_FIFTH")] = 5;
    values[(valuesById[6] = "TEAM_TAB_TYPE_SIXTH")] = 6;
    return values;
  })();

  /**
   * SORT_TYPE enum.
   * @name client_proto.SORT_TYPE
   * @enum {number}
   * @property {number} SORT_TYPE_NULL=0 SORT_TYPE_NULL value
   * @property {number} SORT_TYPE_ASC=1 SORT_TYPE_ASC value
   * @property {number} SORT_TYPE_DESC=2 SORT_TYPE_DESC value
   */
  client_proto.SORT_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "SORT_TYPE_NULL")] = 0;
    values[(valuesById[1] = "SORT_TYPE_ASC")] = 1;
    values[(valuesById[2] = "SORT_TYPE_DESC")] = 2;
    return values;
  })();

  /**
   * SORT_FIELD enum.
   * @name client_proto.SORT_FIELD
   * @enum {number}
   * @property {number} SORT_FIELD_NULL=0 SORT_FIELD_NULL value
   * @property {number} SORT_FIELD_JOIN=1 SORT_FIELD_JOIN value
   * @property {number} SORT_FIELD_COMMISSION=2 SORT_FIELD_COMMISSION value
   */
  client_proto.SORT_FIELD = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "SORT_FIELD_NULL")] = 0;
    values[(valuesById[1] = "SORT_FIELD_JOIN")] = 1;
    values[(valuesById[2] = "SORT_FIELD_COMMISSION")] = 2;
    return values;
  })();

  client_proto.CommissionInfo = (function () {
    /**
     * Properties of a CommissionInfo.
     * @memberof client_proto
     * @interface ICommissionInfo
     * @property {number|Long|null} [today_commission] CommissionInfo today_commission
     * @property {number|Long|null} [total_commission] CommissionInfo total_commission
     */

    /**
     * Constructs a new CommissionInfo.
     * @memberof client_proto
     * @classdesc Represents a CommissionInfo.
     * @implements ICommissionInfo
     * @constructor
     * @param {client_proto.ICommissionInfo=} [properties] Properties to set
     */
    function CommissionInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CommissionInfo today_commission.
     * @member {number|Long} today_commission
     * @memberof client_proto.CommissionInfo
     * @instance
     */
    CommissionInfo.prototype.today_commission = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CommissionInfo total_commission.
     * @member {number|Long} total_commission
     * @memberof client_proto.CommissionInfo
     * @instance
     */
    CommissionInfo.prototype.total_commission = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new CommissionInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.CommissionInfo
     * @static
     * @param {client_proto.ICommissionInfo=} [properties] Properties to set
     * @returns {client_proto.CommissionInfo} CommissionInfo instance
     */
    CommissionInfo.create = function create(properties) {
      return new CommissionInfo(properties);
    };

    /**
     * Encodes the specified CommissionInfo message. Does not implicitly {@link client_proto.CommissionInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CommissionInfo
     * @static
     * @param {client_proto.ICommissionInfo} message CommissionInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CommissionInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.today_commission != null && Object.hasOwnProperty.call(message, "today_commission"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.today_commission);
      if (message.total_commission != null && Object.hasOwnProperty.call(message, "total_commission"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.total_commission);
      return writer;
    };

    /**
     * Encodes the specified CommissionInfo message, length delimited. Does not implicitly {@link client_proto.CommissionInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CommissionInfo
     * @static
     * @param {client_proto.ICommissionInfo} message CommissionInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CommissionInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CommissionInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CommissionInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CommissionInfo} CommissionInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CommissionInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CommissionInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.today_commission = reader.int64();
            break;
          }
          case 2: {
            message.total_commission = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CommissionInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CommissionInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CommissionInfo} CommissionInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CommissionInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CommissionInfo message.
     * @function verify
     * @memberof client_proto.CommissionInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CommissionInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.today_commission != null && message.hasOwnProperty("today_commission"))
        if (
          !$util.isInteger(message.today_commission) &&
          !(
            message.today_commission &&
            $util.isInteger(message.today_commission.low) &&
            $util.isInteger(message.today_commission.high)
          )
        )
          return "today_commission: integer|Long expected";
      if (message.total_commission != null && message.hasOwnProperty("total_commission"))
        if (
          !$util.isInteger(message.total_commission) &&
          !(
            message.total_commission &&
            $util.isInteger(message.total_commission.low) &&
            $util.isInteger(message.total_commission.high)
          )
        )
          return "total_commission: integer|Long expected";
      return null;
    };

    /**
     * Creates a CommissionInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CommissionInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CommissionInfo} CommissionInfo
     */
    CommissionInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CommissionInfo) return object;
      var message = new $root.client_proto.CommissionInfo();
      if (object.today_commission != null)
        if ($util.Long) (message.today_commission = $util.Long.fromValue(object.today_commission)).unsigned = false;
        else if (typeof object.today_commission === "string")
          message.today_commission = parseInt(object.today_commission, 10);
        else if (typeof object.today_commission === "number") message.today_commission = object.today_commission;
        else if (typeof object.today_commission === "object")
          message.today_commission = new $util.LongBits(
            object.today_commission.low >>> 0,
            object.today_commission.high >>> 0,
          ).toNumber();
      if (object.total_commission != null)
        if ($util.Long) (message.total_commission = $util.Long.fromValue(object.total_commission)).unsigned = false;
        else if (typeof object.total_commission === "string")
          message.total_commission = parseInt(object.total_commission, 10);
        else if (typeof object.total_commission === "number") message.total_commission = object.total_commission;
        else if (typeof object.total_commission === "object")
          message.total_commission = new $util.LongBits(
            object.total_commission.low >>> 0,
            object.total_commission.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a CommissionInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CommissionInfo
     * @static
     * @param {client_proto.CommissionInfo} message CommissionInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CommissionInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.today_commission =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.today_commission = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_commission =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_commission = options.longs === String ? "0" : 0;
      }
      if (message.today_commission != null && message.hasOwnProperty("today_commission"))
        if (typeof message.today_commission === "number")
          object.today_commission =
            options.longs === String ? String(message.today_commission) : message.today_commission;
        else
          object.today_commission =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.today_commission)
              : options.longs === Number
                ? new $util.LongBits(message.today_commission.low >>> 0, message.today_commission.high >>> 0).toNumber()
                : message.today_commission;
      if (message.total_commission != null && message.hasOwnProperty("total_commission"))
        if (typeof message.total_commission === "number")
          object.total_commission =
            options.longs === String ? String(message.total_commission) : message.total_commission;
        else
          object.total_commission =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_commission)
              : options.longs === Number
                ? new $util.LongBits(message.total_commission.low >>> 0, message.total_commission.high >>> 0).toNumber()
                : message.total_commission;
      return object;
    };

    /**
     * Converts this CommissionInfo to JSON.
     * @function toJSON
     * @memberof client_proto.CommissionInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CommissionInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CommissionInfo
     * @function getTypeUrl
     * @memberof client_proto.CommissionInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CommissionInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CommissionInfo";
    };

    return CommissionInfo;
  })();

  client_proto.TeamInfo = (function () {
    /**
     * Properties of a TeamInfo.
     * @memberof client_proto
     * @interface ITeamInfo
     * @property {number|null} [team_level] TeamInfo team_level
     * @property {number|null} [team_count] TeamInfo team_count
     * @property {number|null} [today_user] TeamInfo today_user
     * @property {number|null} [yesterday_user] TeamInfo yesterday_user
     * @property {number|null} [month_user] TeamInfo month_user
     * @property {number|Long|null} [team_bet] TeamInfo team_bet
     */

    /**
     * Constructs a new TeamInfo.
     * @memberof client_proto
     * @classdesc Represents a TeamInfo.
     * @implements ITeamInfo
     * @constructor
     * @param {client_proto.ITeamInfo=} [properties] Properties to set
     */
    function TeamInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * TeamInfo team_level.
     * @member {number} team_level
     * @memberof client_proto.TeamInfo
     * @instance
     */
    TeamInfo.prototype.team_level = 0;

    /**
     * TeamInfo team_count.
     * @member {number} team_count
     * @memberof client_proto.TeamInfo
     * @instance
     */
    TeamInfo.prototype.team_count = 0;

    /**
     * TeamInfo today_user.
     * @member {number} today_user
     * @memberof client_proto.TeamInfo
     * @instance
     */
    TeamInfo.prototype.today_user = 0;

    /**
     * TeamInfo yesterday_user.
     * @member {number} yesterday_user
     * @memberof client_proto.TeamInfo
     * @instance
     */
    TeamInfo.prototype.yesterday_user = 0;

    /**
     * TeamInfo month_user.
     * @member {number} month_user
     * @memberof client_proto.TeamInfo
     * @instance
     */
    TeamInfo.prototype.month_user = 0;

    /**
     * TeamInfo team_bet.
     * @member {number|Long} team_bet
     * @memberof client_proto.TeamInfo
     * @instance
     */
    TeamInfo.prototype.team_bet = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new TeamInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.TeamInfo
     * @static
     * @param {client_proto.ITeamInfo=} [properties] Properties to set
     * @returns {client_proto.TeamInfo} TeamInfo instance
     */
    TeamInfo.create = function create(properties) {
      return new TeamInfo(properties);
    };

    /**
     * Encodes the specified TeamInfo message. Does not implicitly {@link client_proto.TeamInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.TeamInfo
     * @static
     * @param {client_proto.ITeamInfo} message TeamInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TeamInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.team_level != null && Object.hasOwnProperty.call(message, "team_level"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.team_level);
      if (message.team_count != null && Object.hasOwnProperty.call(message, "team_count"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.team_count);
      if (message.today_user != null && Object.hasOwnProperty.call(message, "today_user"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.today_user);
      if (message.yesterday_user != null && Object.hasOwnProperty.call(message, "yesterday_user"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.yesterday_user);
      if (message.month_user != null && Object.hasOwnProperty.call(message, "month_user"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.month_user);
      if (message.team_bet != null && Object.hasOwnProperty.call(message, "team_bet"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.team_bet);
      return writer;
    };

    /**
     * Encodes the specified TeamInfo message, length delimited. Does not implicitly {@link client_proto.TeamInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.TeamInfo
     * @static
     * @param {client_proto.ITeamInfo} message TeamInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TeamInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TeamInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.TeamInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.TeamInfo} TeamInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TeamInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.TeamInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.team_level = reader.int32();
            break;
          }
          case 2: {
            message.team_count = reader.int32();
            break;
          }
          case 3: {
            message.today_user = reader.int32();
            break;
          }
          case 4: {
            message.yesterday_user = reader.int32();
            break;
          }
          case 5: {
            message.month_user = reader.int32();
            break;
          }
          case 6: {
            message.team_bet = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a TeamInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.TeamInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.TeamInfo} TeamInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TeamInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TeamInfo message.
     * @function verify
     * @memberof client_proto.TeamInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TeamInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.team_level != null && message.hasOwnProperty("team_level"))
        if (!$util.isInteger(message.team_level)) return "team_level: integer expected";
      if (message.team_count != null && message.hasOwnProperty("team_count"))
        if (!$util.isInteger(message.team_count)) return "team_count: integer expected";
      if (message.today_user != null && message.hasOwnProperty("today_user"))
        if (!$util.isInteger(message.today_user)) return "today_user: integer expected";
      if (message.yesterday_user != null && message.hasOwnProperty("yesterday_user"))
        if (!$util.isInteger(message.yesterday_user)) return "yesterday_user: integer expected";
      if (message.month_user != null && message.hasOwnProperty("month_user"))
        if (!$util.isInteger(message.month_user)) return "month_user: integer expected";
      if (message.team_bet != null && message.hasOwnProperty("team_bet"))
        if (
          !$util.isInteger(message.team_bet) &&
          !(message.team_bet && $util.isInteger(message.team_bet.low) && $util.isInteger(message.team_bet.high))
        )
          return "team_bet: integer|Long expected";
      return null;
    };

    /**
     * Creates a TeamInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.TeamInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.TeamInfo} TeamInfo
     */
    TeamInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.TeamInfo) return object;
      var message = new $root.client_proto.TeamInfo();
      if (object.team_level != null) message.team_level = object.team_level | 0;
      if (object.team_count != null) message.team_count = object.team_count | 0;
      if (object.today_user != null) message.today_user = object.today_user | 0;
      if (object.yesterday_user != null) message.yesterday_user = object.yesterday_user | 0;
      if (object.month_user != null) message.month_user = object.month_user | 0;
      if (object.team_bet != null)
        if ($util.Long) (message.team_bet = $util.Long.fromValue(object.team_bet)).unsigned = false;
        else if (typeof object.team_bet === "string") message.team_bet = parseInt(object.team_bet, 10);
        else if (typeof object.team_bet === "number") message.team_bet = object.team_bet;
        else if (typeof object.team_bet === "object")
          message.team_bet = new $util.LongBits(object.team_bet.low >>> 0, object.team_bet.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a TeamInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.TeamInfo
     * @static
     * @param {client_proto.TeamInfo} message TeamInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TeamInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.team_level = 0;
        object.team_count = 0;
        object.today_user = 0;
        object.yesterday_user = 0;
        object.month_user = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.team_bet =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.team_bet = options.longs === String ? "0" : 0;
      }
      if (message.team_level != null && message.hasOwnProperty("team_level")) object.team_level = message.team_level;
      if (message.team_count != null && message.hasOwnProperty("team_count")) object.team_count = message.team_count;
      if (message.today_user != null && message.hasOwnProperty("today_user")) object.today_user = message.today_user;
      if (message.yesterday_user != null && message.hasOwnProperty("yesterday_user"))
        object.yesterday_user = message.yesterday_user;
      if (message.month_user != null && message.hasOwnProperty("month_user")) object.month_user = message.month_user;
      if (message.team_bet != null && message.hasOwnProperty("team_bet"))
        if (typeof message.team_bet === "number")
          object.team_bet = options.longs === String ? String(message.team_bet) : message.team_bet;
        else
          object.team_bet =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.team_bet)
              : options.longs === Number
                ? new $util.LongBits(message.team_bet.low >>> 0, message.team_bet.high >>> 0).toNumber()
                : message.team_bet;
      return object;
    };

    /**
     * Converts this TeamInfo to JSON.
     * @function toJSON
     * @memberof client_proto.TeamInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TeamInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TeamInfo
     * @function getTypeUrl
     * @memberof client_proto.TeamInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TeamInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.TeamInfo";
    };

    return TeamInfo;
  })();

  client_proto.TeamTab = (function () {
    /**
     * Properties of a TeamTab.
     * @memberof client_proto
     * @interface ITeamTab
     * @property {string|null} [name] TeamTab name
     * @property {number|null} [count] TeamTab count
     * @property {number|null} [label_id] TeamTab label_id
     * @property {boolean|null} [is_read] TeamTab is_read
     */

    /**
     * Constructs a new TeamTab.
     * @memberof client_proto
     * @classdesc Represents a TeamTab.
     * @implements ITeamTab
     * @constructor
     * @param {client_proto.ITeamTab=} [properties] Properties to set
     */
    function TeamTab(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * TeamTab name.
     * @member {string} name
     * @memberof client_proto.TeamTab
     * @instance
     */
    TeamTab.prototype.name = "";

    /**
     * TeamTab count.
     * @member {number} count
     * @memberof client_proto.TeamTab
     * @instance
     */
    TeamTab.prototype.count = 0;

    /**
     * TeamTab label_id.
     * @member {number} label_id
     * @memberof client_proto.TeamTab
     * @instance
     */
    TeamTab.prototype.label_id = 0;

    /**
     * TeamTab is_read.
     * @member {boolean} is_read
     * @memberof client_proto.TeamTab
     * @instance
     */
    TeamTab.prototype.is_read = false;

    /**
     * Creates a new TeamTab instance using the specified properties.
     * @function create
     * @memberof client_proto.TeamTab
     * @static
     * @param {client_proto.ITeamTab=} [properties] Properties to set
     * @returns {client_proto.TeamTab} TeamTab instance
     */
    TeamTab.create = function create(properties) {
      return new TeamTab(properties);
    };

    /**
     * Encodes the specified TeamTab message. Does not implicitly {@link client_proto.TeamTab.verify|verify} messages.
     * @function encode
     * @memberof client_proto.TeamTab
     * @static
     * @param {client_proto.ITeamTab} message TeamTab message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TeamTab.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name);
      if (message.count != null && Object.hasOwnProperty.call(message, "count"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.count);
      if (message.label_id != null && Object.hasOwnProperty.call(message, "label_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.label_id);
      if (message.is_read != null && Object.hasOwnProperty.call(message, "is_read"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).bool(message.is_read);
      return writer;
    };

    /**
     * Encodes the specified TeamTab message, length delimited. Does not implicitly {@link client_proto.TeamTab.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.TeamTab
     * @static
     * @param {client_proto.ITeamTab} message TeamTab message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TeamTab.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TeamTab message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.TeamTab
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.TeamTab} TeamTab
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TeamTab.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.TeamTab();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.name = reader.string();
            break;
          }
          case 2: {
            message.count = reader.int32();
            break;
          }
          case 3: {
            message.label_id = reader.int32();
            break;
          }
          case 4: {
            message.is_read = reader.bool();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a TeamTab message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.TeamTab
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.TeamTab} TeamTab
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TeamTab.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TeamTab message.
     * @function verify
     * @memberof client_proto.TeamTab
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TeamTab.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      if (message.count != null && message.hasOwnProperty("count"))
        if (!$util.isInteger(message.count)) return "count: integer expected";
      if (message.label_id != null && message.hasOwnProperty("label_id"))
        if (!$util.isInteger(message.label_id)) return "label_id: integer expected";
      if (message.is_read != null && message.hasOwnProperty("is_read"))
        if (typeof message.is_read !== "boolean") return "is_read: boolean expected";
      return null;
    };

    /**
     * Creates a TeamTab message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.TeamTab
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.TeamTab} TeamTab
     */
    TeamTab.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.TeamTab) return object;
      var message = new $root.client_proto.TeamTab();
      if (object.name != null) message.name = String(object.name);
      if (object.count != null) message.count = object.count | 0;
      if (object.label_id != null) message.label_id = object.label_id | 0;
      if (object.is_read != null) message.is_read = Boolean(object.is_read);
      return message;
    };

    /**
     * Creates a plain object from a TeamTab message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.TeamTab
     * @static
     * @param {client_proto.TeamTab} message TeamTab
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TeamTab.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.name = "";
        object.count = 0;
        object.label_id = 0;
        object.is_read = false;
      }
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      if (message.count != null && message.hasOwnProperty("count")) object.count = message.count;
      if (message.label_id != null && message.hasOwnProperty("label_id")) object.label_id = message.label_id;
      if (message.is_read != null && message.hasOwnProperty("is_read")) object.is_read = message.is_read;
      return object;
    };

    /**
     * Converts this TeamTab to JSON.
     * @function toJSON
     * @memberof client_proto.TeamTab
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TeamTab.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TeamTab
     * @function getTypeUrl
     * @memberof client_proto.TeamTab
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TeamTab.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.TeamTab";
    };

    return TeamTab;
  })();

  /**
   * COMMISSION_SOURCE_TYPE enum.
   * @name client_proto.COMMISSION_SOURCE_TYPE
   * @enum {number}
   * @property {number} COMMISSION_SOURCE_TYPE_ALL=0 COMMISSION_SOURCE_TYPE_ALL value
   * @property {number} COMMISSION_SOURCE_TYPE_BET=1 COMMISSION_SOURCE_TYPE_BET value
   * @property {number} COMMISSION_SOURCE_TYPE_DEPOSIT=2 COMMISSION_SOURCE_TYPE_DEPOSIT value
   * @property {number} COMMISSION_SOURCE_TYPE_INVITE=3 COMMISSION_SOURCE_TYPE_INVITE value
   * @property {number} COMMISSION_SOURCE_TYPE_TASK=4 COMMISSION_SOURCE_TYPE_TASK value
   */
  client_proto.COMMISSION_SOURCE_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "COMMISSION_SOURCE_TYPE_ALL")] = 0;
    values[(valuesById[1] = "COMMISSION_SOURCE_TYPE_BET")] = 1;
    values[(valuesById[2] = "COMMISSION_SOURCE_TYPE_DEPOSIT")] = 2;
    values[(valuesById[3] = "COMMISSION_SOURCE_TYPE_INVITE")] = 3;
    values[(valuesById[4] = "COMMISSION_SOURCE_TYPE_TASK")] = 4;
    return values;
  })();

  client_proto.UserInfo = (function () {
    /**
     * Properties of a UserInfo.
     * @memberof client_proto
     * @interface IUserInfo
     * @property {string|null} [user_name] UserInfo user_name
     * @property {string|null} [user_avatar] UserInfo user_avatar
     * @property {number|null} [user_id] UserInfo user_id
     */

    /**
     * Constructs a new UserInfo.
     * @memberof client_proto
     * @classdesc Represents a UserInfo.
     * @implements IUserInfo
     * @constructor
     * @param {client_proto.IUserInfo=} [properties] Properties to set
     */
    function UserInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserInfo user_name.
     * @member {string} user_name
     * @memberof client_proto.UserInfo
     * @instance
     */
    UserInfo.prototype.user_name = "";

    /**
     * UserInfo user_avatar.
     * @member {string} user_avatar
     * @memberof client_proto.UserInfo
     * @instance
     */
    UserInfo.prototype.user_avatar = "";

    /**
     * UserInfo user_id.
     * @member {number} user_id
     * @memberof client_proto.UserInfo
     * @instance
     */
    UserInfo.prototype.user_id = 0;

    /**
     * Creates a new UserInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.UserInfo
     * @static
     * @param {client_proto.IUserInfo=} [properties] Properties to set
     * @returns {client_proto.UserInfo} UserInfo instance
     */
    UserInfo.create = function create(properties) {
      return new UserInfo(properties);
    };

    /**
     * Encodes the specified UserInfo message. Does not implicitly {@link client_proto.UserInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserInfo
     * @static
     * @param {client_proto.IUserInfo} message UserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.user_name != null && Object.hasOwnProperty.call(message, "user_name"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.user_name);
      if (message.user_avatar != null && Object.hasOwnProperty.call(message, "user_avatar"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.user_avatar);
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.user_id);
      return writer;
    };

    /**
     * Encodes the specified UserInfo message, length delimited. Does not implicitly {@link client_proto.UserInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserInfo
     * @static
     * @param {client_proto.IUserInfo} message UserInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserInfo} UserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.user_name = reader.string();
            break;
          }
          case 2: {
            message.user_avatar = reader.string();
            break;
          }
          case 3: {
            message.user_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserInfo} UserInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserInfo message.
     * @function verify
     * @memberof client_proto.UserInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.user_name != null && message.hasOwnProperty("user_name"))
        if (!$util.isString(message.user_name)) return "user_name: string expected";
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        if (!$util.isString(message.user_avatar)) return "user_avatar: string expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      return null;
    };

    /**
     * Creates a UserInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserInfo} UserInfo
     */
    UserInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserInfo) return object;
      var message = new $root.client_proto.UserInfo();
      if (object.user_name != null) message.user_name = String(object.user_name);
      if (object.user_avatar != null) message.user_avatar = String(object.user_avatar);
      if (object.user_id != null) message.user_id = object.user_id >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a UserInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserInfo
     * @static
     * @param {client_proto.UserInfo} message UserInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.user_name = "";
        object.user_avatar = "";
        object.user_id = 0;
      }
      if (message.user_name != null && message.hasOwnProperty("user_name")) object.user_name = message.user_name;
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        object.user_avatar = message.user_avatar;
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      return object;
    };

    /**
     * Converts this UserInfo to JSON.
     * @function toJSON
     * @memberof client_proto.UserInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserInfo
     * @function getTypeUrl
     * @memberof client_proto.UserInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserInfo";
    };

    return UserInfo;
  })();

  client_proto.ProxyTransReq = (function () {
    /**
     * Properties of a ProxyTransReq.
     * @memberof client_proto
     * @interface IProxyTransReq
     * @property {number|null} [cmd] ProxyTransReq cmd
     * @property {number|null} [f_uid] ProxyTransReq f_uid
     * @property {number|null} [agent_id] ProxyTransReq agent_id
     * @property {number|null} [s_uid] ProxyTransReq s_uid
     * @property {number|null} [page_index] ProxyTransReq page_index
     * @property {string|null} [source] ProxyTransReq source
     * @property {Array.<client_proto.ISubInfo>|null} [sub_infos] ProxyTransReq sub_infos
     */

    /**
     * Constructs a new ProxyTransReq.
     * @memberof client_proto
     * @classdesc Represents a ProxyTransReq.
     * @implements IProxyTransReq
     * @constructor
     * @param {client_proto.IProxyTransReq=} [properties] Properties to set
     */
    function ProxyTransReq(properties) {
      this.sub_infos = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProxyTransReq cmd.
     * @member {number} cmd
     * @memberof client_proto.ProxyTransReq
     * @instance
     */
    ProxyTransReq.prototype.cmd = 0;

    /**
     * ProxyTransReq f_uid.
     * @member {number} f_uid
     * @memberof client_proto.ProxyTransReq
     * @instance
     */
    ProxyTransReq.prototype.f_uid = 0;

    /**
     * ProxyTransReq agent_id.
     * @member {number} agent_id
     * @memberof client_proto.ProxyTransReq
     * @instance
     */
    ProxyTransReq.prototype.agent_id = 0;

    /**
     * ProxyTransReq s_uid.
     * @member {number} s_uid
     * @memberof client_proto.ProxyTransReq
     * @instance
     */
    ProxyTransReq.prototype.s_uid = 0;

    /**
     * ProxyTransReq page_index.
     * @member {number} page_index
     * @memberof client_proto.ProxyTransReq
     * @instance
     */
    ProxyTransReq.prototype.page_index = 0;

    /**
     * ProxyTransReq source.
     * @member {string} source
     * @memberof client_proto.ProxyTransReq
     * @instance
     */
    ProxyTransReq.prototype.source = "";

    /**
     * ProxyTransReq sub_infos.
     * @member {Array.<client_proto.ISubInfo>} sub_infos
     * @memberof client_proto.ProxyTransReq
     * @instance
     */
    ProxyTransReq.prototype.sub_infos = $util.emptyArray;

    /**
     * Creates a new ProxyTransReq instance using the specified properties.
     * @function create
     * @memberof client_proto.ProxyTransReq
     * @static
     * @param {client_proto.IProxyTransReq=} [properties] Properties to set
     * @returns {client_proto.ProxyTransReq} ProxyTransReq instance
     */
    ProxyTransReq.create = function create(properties) {
      return new ProxyTransReq(properties);
    };

    /**
     * Encodes the specified ProxyTransReq message. Does not implicitly {@link client_proto.ProxyTransReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ProxyTransReq
     * @static
     * @param {client_proto.IProxyTransReq} message ProxyTransReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProxyTransReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.cmd != null && Object.hasOwnProperty.call(message, "cmd"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.cmd);
      if (message.f_uid != null && Object.hasOwnProperty.call(message, "f_uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.f_uid);
      if (message.agent_id != null && Object.hasOwnProperty.call(message, "agent_id"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.agent_id);
      if (message.s_uid != null && Object.hasOwnProperty.call(message, "s_uid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.s_uid);
      if (message.page_index != null && Object.hasOwnProperty.call(message, "page_index"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.page_index);
      if (message.source != null && Object.hasOwnProperty.call(message, "source"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.source);
      if (message.sub_infos != null && message.sub_infos.length)
        for (var i = 0; i < message.sub_infos.length; ++i)
          $root.client_proto.SubInfo.encode(
            message.sub_infos[i],
            writer.uint32(/* id 7, wireType 2 =*/ 58).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified ProxyTransReq message, length delimited. Does not implicitly {@link client_proto.ProxyTransReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ProxyTransReq
     * @static
     * @param {client_proto.IProxyTransReq} message ProxyTransReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProxyTransReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProxyTransReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ProxyTransReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ProxyTransReq} ProxyTransReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProxyTransReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ProxyTransReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.cmd = reader.int32();
            break;
          }
          case 2: {
            message.f_uid = reader.uint32();
            break;
          }
          case 3: {
            message.agent_id = reader.uint32();
            break;
          }
          case 4: {
            message.s_uid = reader.uint32();
            break;
          }
          case 5: {
            message.page_index = reader.int32();
            break;
          }
          case 6: {
            message.source = reader.string();
            break;
          }
          case 7: {
            if (!(message.sub_infos && message.sub_infos.length)) message.sub_infos = [];
            message.sub_infos.push($root.client_proto.SubInfo.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ProxyTransReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ProxyTransReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ProxyTransReq} ProxyTransReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProxyTransReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProxyTransReq message.
     * @function verify
     * @memberof client_proto.ProxyTransReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProxyTransReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.cmd != null && message.hasOwnProperty("cmd"))
        if (!$util.isInteger(message.cmd)) return "cmd: integer expected";
      if (message.f_uid != null && message.hasOwnProperty("f_uid"))
        if (!$util.isInteger(message.f_uid)) return "f_uid: integer expected";
      if (message.agent_id != null && message.hasOwnProperty("agent_id"))
        if (!$util.isInteger(message.agent_id)) return "agent_id: integer expected";
      if (message.s_uid != null && message.hasOwnProperty("s_uid"))
        if (!$util.isInteger(message.s_uid)) return "s_uid: integer expected";
      if (message.page_index != null && message.hasOwnProperty("page_index"))
        if (!$util.isInteger(message.page_index)) return "page_index: integer expected";
      if (message.source != null && message.hasOwnProperty("source"))
        if (!$util.isString(message.source)) return "source: string expected";
      if (message.sub_infos != null && message.hasOwnProperty("sub_infos")) {
        if (!Array.isArray(message.sub_infos)) return "sub_infos: array expected";
        for (var i = 0; i < message.sub_infos.length; ++i) {
          var error = $root.client_proto.SubInfo.verify(message.sub_infos[i]);
          if (error) return "sub_infos." + error;
        }
      }
      return null;
    };

    /**
     * Creates a ProxyTransReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ProxyTransReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ProxyTransReq} ProxyTransReq
     */
    ProxyTransReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ProxyTransReq) return object;
      var message = new $root.client_proto.ProxyTransReq();
      if (object.cmd != null) message.cmd = object.cmd | 0;
      if (object.f_uid != null) message.f_uid = object.f_uid >>> 0;
      if (object.agent_id != null) message.agent_id = object.agent_id >>> 0;
      if (object.s_uid != null) message.s_uid = object.s_uid >>> 0;
      if (object.page_index != null) message.page_index = object.page_index | 0;
      if (object.source != null) message.source = String(object.source);
      if (object.sub_infos) {
        if (!Array.isArray(object.sub_infos)) throw TypeError(".client_proto.ProxyTransReq.sub_infos: array expected");
        message.sub_infos = [];
        for (var i = 0; i < object.sub_infos.length; ++i) {
          if (typeof object.sub_infos[i] !== "object")
            throw TypeError(".client_proto.ProxyTransReq.sub_infos: object expected");
          message.sub_infos[i] = $root.client_proto.SubInfo.fromObject(object.sub_infos[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a ProxyTransReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ProxyTransReq
     * @static
     * @param {client_proto.ProxyTransReq} message ProxyTransReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProxyTransReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.sub_infos = [];
      if (options.defaults) {
        object.cmd = 0;
        object.f_uid = 0;
        object.agent_id = 0;
        object.s_uid = 0;
        object.page_index = 0;
        object.source = "";
      }
      if (message.cmd != null && message.hasOwnProperty("cmd")) object.cmd = message.cmd;
      if (message.f_uid != null && message.hasOwnProperty("f_uid")) object.f_uid = message.f_uid;
      if (message.agent_id != null && message.hasOwnProperty("agent_id")) object.agent_id = message.agent_id;
      if (message.s_uid != null && message.hasOwnProperty("s_uid")) object.s_uid = message.s_uid;
      if (message.page_index != null && message.hasOwnProperty("page_index")) object.page_index = message.page_index;
      if (message.source != null && message.hasOwnProperty("source")) object.source = message.source;
      if (message.sub_infos && message.sub_infos.length) {
        object.sub_infos = [];
        for (var j = 0; j < message.sub_infos.length; ++j)
          object.sub_infos[j] = $root.client_proto.SubInfo.toObject(message.sub_infos[j], options);
      }
      return object;
    };

    /**
     * Converts this ProxyTransReq to JSON.
     * @function toJSON
     * @memberof client_proto.ProxyTransReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProxyTransReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProxyTransReq
     * @function getTypeUrl
     * @memberof client_proto.ProxyTransReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProxyTransReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ProxyTransReq";
    };

    return ProxyTransReq;
  })();

  client_proto.GetUserProxyInfoReq = (function () {
    /**
     * Properties of a GetUserProxyInfoReq.
     * @memberof client_proto
     * @interface IGetUserProxyInfoReq
     */

    /**
     * Constructs a new GetUserProxyInfoReq.
     * @memberof client_proto
     * @classdesc Represents a GetUserProxyInfoReq.
     * @implements IGetUserProxyInfoReq
     * @constructor
     * @param {client_proto.IGetUserProxyInfoReq=} [properties] Properties to set
     */
    function GetUserProxyInfoReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetUserProxyInfoReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserProxyInfoReq
     * @static
     * @param {client_proto.IGetUserProxyInfoReq=} [properties] Properties to set
     * @returns {client_proto.GetUserProxyInfoReq} GetUserProxyInfoReq instance
     */
    GetUserProxyInfoReq.create = function create(properties) {
      return new GetUserProxyInfoReq(properties);
    };

    /**
     * Encodes the specified GetUserProxyInfoReq message. Does not implicitly {@link client_proto.GetUserProxyInfoReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserProxyInfoReq
     * @static
     * @param {client_proto.IGetUserProxyInfoReq} message GetUserProxyInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProxyInfoReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified GetUserProxyInfoReq message, length delimited. Does not implicitly {@link client_proto.GetUserProxyInfoReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserProxyInfoReq
     * @static
     * @param {client_proto.IGetUserProxyInfoReq} message GetUserProxyInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProxyInfoReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserProxyInfoReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserProxyInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserProxyInfoReq} GetUserProxyInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProxyInfoReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserProxyInfoReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserProxyInfoReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserProxyInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserProxyInfoReq} GetUserProxyInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProxyInfoReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserProxyInfoReq message.
     * @function verify
     * @memberof client_proto.GetUserProxyInfoReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserProxyInfoReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a GetUserProxyInfoReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserProxyInfoReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserProxyInfoReq} GetUserProxyInfoReq
     */
    GetUserProxyInfoReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserProxyInfoReq) return object;
      return new $root.client_proto.GetUserProxyInfoReq();
    };

    /**
     * Creates a plain object from a GetUserProxyInfoReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserProxyInfoReq
     * @static
     * @param {client_proto.GetUserProxyInfoReq} message GetUserProxyInfoReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserProxyInfoReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this GetUserProxyInfoReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserProxyInfoReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserProxyInfoReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserProxyInfoReq
     * @function getTypeUrl
     * @memberof client_proto.GetUserProxyInfoReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserProxyInfoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserProxyInfoReq";
    };

    return GetUserProxyInfoReq;
  })();

  client_proto.GetUserProxyInfoResp = (function () {
    /**
     * Properties of a GetUserProxyInfoResp.
     * @memberof client_proto
     * @interface IGetUserProxyInfoResp
     * @property {client_proto.ICommissionInfo|null} [commission_info] GetUserProxyInfoResp commission_info
     * @property {client_proto.ITeamInfo|null} [team_info] GetUserProxyInfoResp team_info
     * @property {number|Long|null} [withdraw_amount] GetUserProxyInfoResp withdraw_amount
     */

    /**
     * Constructs a new GetUserProxyInfoResp.
     * @memberof client_proto
     * @classdesc Represents a GetUserProxyInfoResp.
     * @implements IGetUserProxyInfoResp
     * @constructor
     * @param {client_proto.IGetUserProxyInfoResp=} [properties] Properties to set
     */
    function GetUserProxyInfoResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserProxyInfoResp commission_info.
     * @member {client_proto.ICommissionInfo|null|undefined} commission_info
     * @memberof client_proto.GetUserProxyInfoResp
     * @instance
     */
    GetUserProxyInfoResp.prototype.commission_info = null;

    /**
     * GetUserProxyInfoResp team_info.
     * @member {client_proto.ITeamInfo|null|undefined} team_info
     * @memberof client_proto.GetUserProxyInfoResp
     * @instance
     */
    GetUserProxyInfoResp.prototype.team_info = null;

    /**
     * GetUserProxyInfoResp withdraw_amount.
     * @member {number|Long} withdraw_amount
     * @memberof client_proto.GetUserProxyInfoResp
     * @instance
     */
    GetUserProxyInfoResp.prototype.withdraw_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new GetUserProxyInfoResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserProxyInfoResp
     * @static
     * @param {client_proto.IGetUserProxyInfoResp=} [properties] Properties to set
     * @returns {client_proto.GetUserProxyInfoResp} GetUserProxyInfoResp instance
     */
    GetUserProxyInfoResp.create = function create(properties) {
      return new GetUserProxyInfoResp(properties);
    };

    /**
     * Encodes the specified GetUserProxyInfoResp message. Does not implicitly {@link client_proto.GetUserProxyInfoResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserProxyInfoResp
     * @static
     * @param {client_proto.IGetUserProxyInfoResp} message GetUserProxyInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProxyInfoResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.commission_info != null && Object.hasOwnProperty.call(message, "commission_info"))
        $root.client_proto.CommissionInfo.encode(
          message.commission_info,
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
        ).ldelim();
      if (message.team_info != null && Object.hasOwnProperty.call(message, "team_info"))
        $root.client_proto.TeamInfo.encode(
          message.team_info,
          writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
        ).ldelim();
      if (message.withdraw_amount != null && Object.hasOwnProperty.call(message, "withdraw_amount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.withdraw_amount);
      return writer;
    };

    /**
     * Encodes the specified GetUserProxyInfoResp message, length delimited. Does not implicitly {@link client_proto.GetUserProxyInfoResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserProxyInfoResp
     * @static
     * @param {client_proto.IGetUserProxyInfoResp} message GetUserProxyInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProxyInfoResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserProxyInfoResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserProxyInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserProxyInfoResp} GetUserProxyInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProxyInfoResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserProxyInfoResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.commission_info = $root.client_proto.CommissionInfo.decode(reader, reader.uint32());
            break;
          }
          case 2: {
            message.team_info = $root.client_proto.TeamInfo.decode(reader, reader.uint32());
            break;
          }
          case 3: {
            message.withdraw_amount = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserProxyInfoResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserProxyInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserProxyInfoResp} GetUserProxyInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProxyInfoResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserProxyInfoResp message.
     * @function verify
     * @memberof client_proto.GetUserProxyInfoResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserProxyInfoResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.commission_info != null && message.hasOwnProperty("commission_info")) {
        var error = $root.client_proto.CommissionInfo.verify(message.commission_info);
        if (error) return "commission_info." + error;
      }
      if (message.team_info != null && message.hasOwnProperty("team_info")) {
        var error = $root.client_proto.TeamInfo.verify(message.team_info);
        if (error) return "team_info." + error;
      }
      if (message.withdraw_amount != null && message.hasOwnProperty("withdraw_amount"))
        if (
          !$util.isInteger(message.withdraw_amount) &&
          !(
            message.withdraw_amount &&
            $util.isInteger(message.withdraw_amount.low) &&
            $util.isInteger(message.withdraw_amount.high)
          )
        )
          return "withdraw_amount: integer|Long expected";
      return null;
    };

    /**
     * Creates a GetUserProxyInfoResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserProxyInfoResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserProxyInfoResp} GetUserProxyInfoResp
     */
    GetUserProxyInfoResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserProxyInfoResp) return object;
      var message = new $root.client_proto.GetUserProxyInfoResp();
      if (object.commission_info != null) {
        if (typeof object.commission_info !== "object")
          throw TypeError(".client_proto.GetUserProxyInfoResp.commission_info: object expected");
        message.commission_info = $root.client_proto.CommissionInfo.fromObject(object.commission_info);
      }
      if (object.team_info != null) {
        if (typeof object.team_info !== "object")
          throw TypeError(".client_proto.GetUserProxyInfoResp.team_info: object expected");
        message.team_info = $root.client_proto.TeamInfo.fromObject(object.team_info);
      }
      if (object.withdraw_amount != null)
        if ($util.Long) (message.withdraw_amount = $util.Long.fromValue(object.withdraw_amount)).unsigned = false;
        else if (typeof object.withdraw_amount === "string")
          message.withdraw_amount = parseInt(object.withdraw_amount, 10);
        else if (typeof object.withdraw_amount === "number") message.withdraw_amount = object.withdraw_amount;
        else if (typeof object.withdraw_amount === "object")
          message.withdraw_amount = new $util.LongBits(
            object.withdraw_amount.low >>> 0,
            object.withdraw_amount.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a GetUserProxyInfoResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserProxyInfoResp
     * @static
     * @param {client_proto.GetUserProxyInfoResp} message GetUserProxyInfoResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserProxyInfoResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.commission_info = null;
        object.team_info = null;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.withdraw_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.withdraw_amount = options.longs === String ? "0" : 0;
      }
      if (message.commission_info != null && message.hasOwnProperty("commission_info"))
        object.commission_info = $root.client_proto.CommissionInfo.toObject(message.commission_info, options);
      if (message.team_info != null && message.hasOwnProperty("team_info"))
        object.team_info = $root.client_proto.TeamInfo.toObject(message.team_info, options);
      if (message.withdraw_amount != null && message.hasOwnProperty("withdraw_amount"))
        if (typeof message.withdraw_amount === "number")
          object.withdraw_amount = options.longs === String ? String(message.withdraw_amount) : message.withdraw_amount;
        else
          object.withdraw_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.withdraw_amount)
              : options.longs === Number
                ? new $util.LongBits(message.withdraw_amount.low >>> 0, message.withdraw_amount.high >>> 0).toNumber()
                : message.withdraw_amount;
      return object;
    };

    /**
     * Converts this GetUserProxyInfoResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserProxyInfoResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserProxyInfoResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserProxyInfoResp
     * @function getTypeUrl
     * @memberof client_proto.GetUserProxyInfoResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserProxyInfoResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserProxyInfoResp";
    };

    return GetUserProxyInfoResp;
  })();

  client_proto.GetUserWithdrawListReq = (function () {
    /**
     * Properties of a GetUserWithdrawListReq.
     * @memberof client_proto
     * @interface IGetUserWithdrawListReq
     * @property {number|null} [page_size] GetUserWithdrawListReq page_size
     * @property {number|null} [page_index] GetUserWithdrawListReq page_index
     */

    /**
     * Constructs a new GetUserWithdrawListReq.
     * @memberof client_proto
     * @classdesc Represents a GetUserWithdrawListReq.
     * @implements IGetUserWithdrawListReq
     * @constructor
     * @param {client_proto.IGetUserWithdrawListReq=} [properties] Properties to set
     */
    function GetUserWithdrawListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserWithdrawListReq page_size.
     * @member {number} page_size
     * @memberof client_proto.GetUserWithdrawListReq
     * @instance
     */
    GetUserWithdrawListReq.prototype.page_size = 0;

    /**
     * GetUserWithdrawListReq page_index.
     * @member {number} page_index
     * @memberof client_proto.GetUserWithdrawListReq
     * @instance
     */
    GetUserWithdrawListReq.prototype.page_index = 0;

    /**
     * Creates a new GetUserWithdrawListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserWithdrawListReq
     * @static
     * @param {client_proto.IGetUserWithdrawListReq=} [properties] Properties to set
     * @returns {client_proto.GetUserWithdrawListReq} GetUserWithdrawListReq instance
     */
    GetUserWithdrawListReq.create = function create(properties) {
      return new GetUserWithdrawListReq(properties);
    };

    /**
     * Encodes the specified GetUserWithdrawListReq message. Does not implicitly {@link client_proto.GetUserWithdrawListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserWithdrawListReq
     * @static
     * @param {client_proto.IGetUserWithdrawListReq} message GetUserWithdrawListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserWithdrawListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.page_size != null && Object.hasOwnProperty.call(message, "page_size"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.page_size);
      if (message.page_index != null && Object.hasOwnProperty.call(message, "page_index"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.page_index);
      return writer;
    };

    /**
     * Encodes the specified GetUserWithdrawListReq message, length delimited. Does not implicitly {@link client_proto.GetUserWithdrawListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserWithdrawListReq
     * @static
     * @param {client_proto.IGetUserWithdrawListReq} message GetUserWithdrawListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserWithdrawListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserWithdrawListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserWithdrawListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserWithdrawListReq} GetUserWithdrawListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserWithdrawListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserWithdrawListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.page_size = reader.int32();
            break;
          }
          case 2: {
            message.page_index = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserWithdrawListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserWithdrawListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserWithdrawListReq} GetUserWithdrawListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserWithdrawListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserWithdrawListReq message.
     * @function verify
     * @memberof client_proto.GetUserWithdrawListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserWithdrawListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.page_size != null && message.hasOwnProperty("page_size"))
        if (!$util.isInteger(message.page_size)) return "page_size: integer expected";
      if (message.page_index != null && message.hasOwnProperty("page_index"))
        if (!$util.isInteger(message.page_index)) return "page_index: integer expected";
      return null;
    };

    /**
     * Creates a GetUserWithdrawListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserWithdrawListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserWithdrawListReq} GetUserWithdrawListReq
     */
    GetUserWithdrawListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserWithdrawListReq) return object;
      var message = new $root.client_proto.GetUserWithdrawListReq();
      if (object.page_size != null) message.page_size = object.page_size | 0;
      if (object.page_index != null) message.page_index = object.page_index | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetUserWithdrawListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserWithdrawListReq
     * @static
     * @param {client_proto.GetUserWithdrawListReq} message GetUserWithdrawListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserWithdrawListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.page_size = 0;
        object.page_index = 0;
      }
      if (message.page_size != null && message.hasOwnProperty("page_size")) object.page_size = message.page_size;
      if (message.page_index != null && message.hasOwnProperty("page_index")) object.page_index = message.page_index;
      return object;
    };

    /**
     * Converts this GetUserWithdrawListReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserWithdrawListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserWithdrawListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserWithdrawListReq
     * @function getTypeUrl
     * @memberof client_proto.GetUserWithdrawListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserWithdrawListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserWithdrawListReq";
    };

    return GetUserWithdrawListReq;
  })();

  client_proto.GetUserWithdrawListResp = (function () {
    /**
     * Properties of a GetUserWithdrawListResp.
     * @memberof client_proto
     * @interface IGetUserWithdrawListResp
     * @property {Array.<client_proto.GetUserWithdrawListResp.IWithdrawInfo>|null} [withdraw_info] GetUserWithdrawListResp withdraw_info
     * @property {number|null} [page_index] GetUserWithdrawListResp page_index
     */

    /**
     * Constructs a new GetUserWithdrawListResp.
     * @memberof client_proto
     * @classdesc Represents a GetUserWithdrawListResp.
     * @implements IGetUserWithdrawListResp
     * @constructor
     * @param {client_proto.IGetUserWithdrawListResp=} [properties] Properties to set
     */
    function GetUserWithdrawListResp(properties) {
      this.withdraw_info = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserWithdrawListResp withdraw_info.
     * @member {Array.<client_proto.GetUserWithdrawListResp.IWithdrawInfo>} withdraw_info
     * @memberof client_proto.GetUserWithdrawListResp
     * @instance
     */
    GetUserWithdrawListResp.prototype.withdraw_info = $util.emptyArray;

    /**
     * GetUserWithdrawListResp page_index.
     * @member {number} page_index
     * @memberof client_proto.GetUserWithdrawListResp
     * @instance
     */
    GetUserWithdrawListResp.prototype.page_index = 0;

    /**
     * Creates a new GetUserWithdrawListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserWithdrawListResp
     * @static
     * @param {client_proto.IGetUserWithdrawListResp=} [properties] Properties to set
     * @returns {client_proto.GetUserWithdrawListResp} GetUserWithdrawListResp instance
     */
    GetUserWithdrawListResp.create = function create(properties) {
      return new GetUserWithdrawListResp(properties);
    };

    /**
     * Encodes the specified GetUserWithdrawListResp message. Does not implicitly {@link client_proto.GetUserWithdrawListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserWithdrawListResp
     * @static
     * @param {client_proto.IGetUserWithdrawListResp} message GetUserWithdrawListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserWithdrawListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.withdraw_info != null && message.withdraw_info.length)
        for (var i = 0; i < message.withdraw_info.length; ++i)
          $root.client_proto.GetUserWithdrawListResp.WithdrawInfo.encode(
            message.withdraw_info[i],
            writer.uint32(/* id 4, wireType 2 =*/ 34).fork(),
          ).ldelim();
      if (message.page_index != null && Object.hasOwnProperty.call(message, "page_index"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.page_index);
      return writer;
    };

    /**
     * Encodes the specified GetUserWithdrawListResp message, length delimited. Does not implicitly {@link client_proto.GetUserWithdrawListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserWithdrawListResp
     * @static
     * @param {client_proto.IGetUserWithdrawListResp} message GetUserWithdrawListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserWithdrawListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserWithdrawListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserWithdrawListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserWithdrawListResp} GetUserWithdrawListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserWithdrawListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserWithdrawListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 4: {
            if (!(message.withdraw_info && message.withdraw_info.length)) message.withdraw_info = [];
            message.withdraw_info.push(
              $root.client_proto.GetUserWithdrawListResp.WithdrawInfo.decode(reader, reader.uint32()),
            );
            break;
          }
          case 5: {
            message.page_index = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserWithdrawListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserWithdrawListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserWithdrawListResp} GetUserWithdrawListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserWithdrawListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserWithdrawListResp message.
     * @function verify
     * @memberof client_proto.GetUserWithdrawListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserWithdrawListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.withdraw_info != null && message.hasOwnProperty("withdraw_info")) {
        if (!Array.isArray(message.withdraw_info)) return "withdraw_info: array expected";
        for (var i = 0; i < message.withdraw_info.length; ++i) {
          var error = $root.client_proto.GetUserWithdrawListResp.WithdrawInfo.verify(message.withdraw_info[i]);
          if (error) return "withdraw_info." + error;
        }
      }
      if (message.page_index != null && message.hasOwnProperty("page_index"))
        if (!$util.isInteger(message.page_index)) return "page_index: integer expected";
      return null;
    };

    /**
     * Creates a GetUserWithdrawListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserWithdrawListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserWithdrawListResp} GetUserWithdrawListResp
     */
    GetUserWithdrawListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserWithdrawListResp) return object;
      var message = new $root.client_proto.GetUserWithdrawListResp();
      if (object.withdraw_info) {
        if (!Array.isArray(object.withdraw_info))
          throw TypeError(".client_proto.GetUserWithdrawListResp.withdraw_info: array expected");
        message.withdraw_info = [];
        for (var i = 0; i < object.withdraw_info.length; ++i) {
          if (typeof object.withdraw_info[i] !== "object")
            throw TypeError(".client_proto.GetUserWithdrawListResp.withdraw_info: object expected");
          message.withdraw_info[i] = $root.client_proto.GetUserWithdrawListResp.WithdrawInfo.fromObject(
            object.withdraw_info[i],
          );
        }
      }
      if (object.page_index != null) message.page_index = object.page_index | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetUserWithdrawListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserWithdrawListResp
     * @static
     * @param {client_proto.GetUserWithdrawListResp} message GetUserWithdrawListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserWithdrawListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.withdraw_info = [];
      if (options.defaults) object.page_index = 0;
      if (message.withdraw_info && message.withdraw_info.length) {
        object.withdraw_info = [];
        for (var j = 0; j < message.withdraw_info.length; ++j)
          object.withdraw_info[j] = $root.client_proto.GetUserWithdrawListResp.WithdrawInfo.toObject(
            message.withdraw_info[j],
            options,
          );
      }
      if (message.page_index != null && message.hasOwnProperty("page_index")) object.page_index = message.page_index;
      return object;
    };

    /**
     * Converts this GetUserWithdrawListResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserWithdrawListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserWithdrawListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserWithdrawListResp
     * @function getTypeUrl
     * @memberof client_proto.GetUserWithdrawListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserWithdrawListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserWithdrawListResp";
    };

    GetUserWithdrawListResp.WithdrawInfo = (function () {
      /**
       * Properties of a WithdrawInfo.
       * @memberof client_proto.GetUserWithdrawListResp
       * @interface IWithdrawInfo
       * @property {number|null} [time] WithdrawInfo time
       * @property {number|Long|null} [amount] WithdrawInfo amount
       * @property {client_proto.OP_STATUS|null} [state] WithdrawInfo state
       */

      /**
       * Constructs a new WithdrawInfo.
       * @memberof client_proto.GetUserWithdrawListResp
       * @classdesc Represents a WithdrawInfo.
       * @implements IWithdrawInfo
       * @constructor
       * @param {client_proto.GetUserWithdrawListResp.IWithdrawInfo=} [properties] Properties to set
       */
      function WithdrawInfo(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }

      /**
       * WithdrawInfo time.
       * @member {number} time
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @instance
       */
      WithdrawInfo.prototype.time = 0;

      /**
       * WithdrawInfo amount.
       * @member {number|Long} amount
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @instance
       */
      WithdrawInfo.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * WithdrawInfo state.
       * @member {client_proto.OP_STATUS} state
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @instance
       */
      WithdrawInfo.prototype.state = 0;

      /**
       * Creates a new WithdrawInfo instance using the specified properties.
       * @function create
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @static
       * @param {client_proto.GetUserWithdrawListResp.IWithdrawInfo=} [properties] Properties to set
       * @returns {client_proto.GetUserWithdrawListResp.WithdrawInfo} WithdrawInfo instance
       */
      WithdrawInfo.create = function create(properties) {
        return new WithdrawInfo(properties);
      };

      /**
       * Encodes the specified WithdrawInfo message. Does not implicitly {@link client_proto.GetUserWithdrawListResp.WithdrawInfo.verify|verify} messages.
       * @function encode
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @static
       * @param {client_proto.GetUserWithdrawListResp.IWithdrawInfo} message WithdrawInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      WithdrawInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.time != null && Object.hasOwnProperty.call(message, "time"))
          writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.time);
        if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
          writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.amount);
        if (message.state != null && Object.hasOwnProperty.call(message, "state"))
          writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.state);
        return writer;
      };

      /**
       * Encodes the specified WithdrawInfo message, length delimited. Does not implicitly {@link client_proto.GetUserWithdrawListResp.WithdrawInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @static
       * @param {client_proto.GetUserWithdrawListResp.IWithdrawInfo} message WithdrawInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      WithdrawInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a WithdrawInfo message from the specified reader or buffer.
       * @function decode
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {client_proto.GetUserWithdrawListResp.WithdrawInfo} WithdrawInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      WithdrawInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.client_proto.GetUserWithdrawListResp.WithdrawInfo();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.time = reader.int32();
              break;
            }
            case 2: {
              message.amount = reader.int64();
              break;
            }
            case 3: {
              message.state = reader.int32();
              break;
            }
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a WithdrawInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {client_proto.GetUserWithdrawListResp.WithdrawInfo} WithdrawInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      WithdrawInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a WithdrawInfo message.
       * @function verify
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      WithdrawInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null) return "object expected";
        if (message.time != null && message.hasOwnProperty("time"))
          if (!$util.isInteger(message.time)) return "time: integer expected";
        if (message.amount != null && message.hasOwnProperty("amount"))
          if (
            !$util.isInteger(message.amount) &&
            !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
          )
            return "amount: integer|Long expected";
        if (message.state != null && message.hasOwnProperty("state"))
          switch (message.state) {
            default:
              return "state: enum value expected";
            case 0:
            case 1:
            case 2:
              break;
          }
        return null;
      };

      /**
       * Creates a WithdrawInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {client_proto.GetUserWithdrawListResp.WithdrawInfo} WithdrawInfo
       */
      WithdrawInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.client_proto.GetUserWithdrawListResp.WithdrawInfo) return object;
        var message = new $root.client_proto.GetUserWithdrawListResp.WithdrawInfo();
        if (object.time != null) message.time = object.time | 0;
        if (object.amount != null)
          if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
          else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
          else if (typeof object.amount === "number") message.amount = object.amount;
          else if (typeof object.amount === "object")
            message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
        switch (object.state) {
          default:
            if (typeof object.state === "number") {
              message.state = object.state;
              break;
            }
            break;
          case "OP_STATUS_PROCESSING":
          case 0:
            message.state = 0;
            break;
          case "OP_STATUS_SUCCESS":
          case 1:
            message.state = 1;
            break;
          case "OP_STATUS_FAIL":
          case 2:
            message.state = 2;
            break;
        }
        return message;
      };

      /**
       * Creates a plain object from a WithdrawInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @static
       * @param {client_proto.GetUserWithdrawListResp.WithdrawInfo} message WithdrawInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      WithdrawInfo.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.time = 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.amount =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.amount = options.longs === String ? "0" : 0;
          object.state = options.enums === String ? "OP_STATUS_PROCESSING" : 0;
        }
        if (message.time != null && message.hasOwnProperty("time")) object.time = message.time;
        if (message.amount != null && message.hasOwnProperty("amount"))
          if (typeof message.amount === "number")
            object.amount = options.longs === String ? String(message.amount) : message.amount;
          else
            object.amount =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.amount)
                : options.longs === Number
                  ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                  : message.amount;
        if (message.state != null && message.hasOwnProperty("state"))
          object.state =
            options.enums === String
              ? $root.client_proto.OP_STATUS[message.state] === undefined
                ? message.state
                : $root.client_proto.OP_STATUS[message.state]
              : message.state;
        return object;
      };

      /**
       * Converts this WithdrawInfo to JSON.
       * @function toJSON
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      WithdrawInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for WithdrawInfo
       * @function getTypeUrl
       * @memberof client_proto.GetUserWithdrawListResp.WithdrawInfo
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      WithdrawInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/client_proto.GetUserWithdrawListResp.WithdrawInfo";
      };

      return WithdrawInfo;
    })();

    return GetUserWithdrawListResp;
  })();

  client_proto.WithdrawReq = (function () {
    /**
     * Properties of a WithdrawReq.
     * @memberof client_proto
     * @interface IWithdrawReq
     */

    /**
     * Constructs a new WithdrawReq.
     * @memberof client_proto
     * @classdesc Represents a WithdrawReq.
     * @implements IWithdrawReq
     * @constructor
     * @param {client_proto.IWithdrawReq=} [properties] Properties to set
     */
    function WithdrawReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new WithdrawReq instance using the specified properties.
     * @function create
     * @memberof client_proto.WithdrawReq
     * @static
     * @param {client_proto.IWithdrawReq=} [properties] Properties to set
     * @returns {client_proto.WithdrawReq} WithdrawReq instance
     */
    WithdrawReq.create = function create(properties) {
      return new WithdrawReq(properties);
    };

    /**
     * Encodes the specified WithdrawReq message. Does not implicitly {@link client_proto.WithdrawReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.WithdrawReq
     * @static
     * @param {client_proto.IWithdrawReq} message WithdrawReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified WithdrawReq message, length delimited. Does not implicitly {@link client_proto.WithdrawReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.WithdrawReq
     * @static
     * @param {client_proto.IWithdrawReq} message WithdrawReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WithdrawReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.WithdrawReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.WithdrawReq} WithdrawReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.WithdrawReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a WithdrawReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.WithdrawReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.WithdrawReq} WithdrawReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WithdrawReq message.
     * @function verify
     * @memberof client_proto.WithdrawReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WithdrawReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a WithdrawReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.WithdrawReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.WithdrawReq} WithdrawReq
     */
    WithdrawReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.WithdrawReq) return object;
      return new $root.client_proto.WithdrawReq();
    };

    /**
     * Creates a plain object from a WithdrawReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.WithdrawReq
     * @static
     * @param {client_proto.WithdrawReq} message WithdrawReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WithdrawReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this WithdrawReq to JSON.
     * @function toJSON
     * @memberof client_proto.WithdrawReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WithdrawReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WithdrawReq
     * @function getTypeUrl
     * @memberof client_proto.WithdrawReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WithdrawReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.WithdrawReq";
    };

    return WithdrawReq;
  })();

  client_proto.WithdrawResp = (function () {
    /**
     * Properties of a WithdrawResp.
     * @memberof client_proto
     * @interface IWithdrawResp
     * @property {number|null} [state] WithdrawResp state
     */

    /**
     * Constructs a new WithdrawResp.
     * @memberof client_proto
     * @classdesc Represents a WithdrawResp.
     * @implements IWithdrawResp
     * @constructor
     * @param {client_proto.IWithdrawResp=} [properties] Properties to set
     */
    function WithdrawResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * WithdrawResp state.
     * @member {number} state
     * @memberof client_proto.WithdrawResp
     * @instance
     */
    WithdrawResp.prototype.state = 0;

    /**
     * Creates a new WithdrawResp instance using the specified properties.
     * @function create
     * @memberof client_proto.WithdrawResp
     * @static
     * @param {client_proto.IWithdrawResp=} [properties] Properties to set
     * @returns {client_proto.WithdrawResp} WithdrawResp instance
     */
    WithdrawResp.create = function create(properties) {
      return new WithdrawResp(properties);
    };

    /**
     * Encodes the specified WithdrawResp message. Does not implicitly {@link client_proto.WithdrawResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.WithdrawResp
     * @static
     * @param {client_proto.IWithdrawResp} message WithdrawResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.state != null && Object.hasOwnProperty.call(message, "state"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.state);
      return writer;
    };

    /**
     * Encodes the specified WithdrawResp message, length delimited. Does not implicitly {@link client_proto.WithdrawResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.WithdrawResp
     * @static
     * @param {client_proto.IWithdrawResp} message WithdrawResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WithdrawResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a WithdrawResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.WithdrawResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.WithdrawResp} WithdrawResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.WithdrawResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.state = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a WithdrawResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.WithdrawResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.WithdrawResp} WithdrawResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WithdrawResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a WithdrawResp message.
     * @function verify
     * @memberof client_proto.WithdrawResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    WithdrawResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.state != null && message.hasOwnProperty("state"))
        if (!$util.isInteger(message.state)) return "state: integer expected";
      return null;
    };

    /**
     * Creates a WithdrawResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.WithdrawResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.WithdrawResp} WithdrawResp
     */
    WithdrawResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.WithdrawResp) return object;
      var message = new $root.client_proto.WithdrawResp();
      if (object.state != null) message.state = object.state | 0;
      return message;
    };

    /**
     * Creates a plain object from a WithdrawResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.WithdrawResp
     * @static
     * @param {client_proto.WithdrawResp} message WithdrawResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    WithdrawResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.state = 0;
      if (message.state != null && message.hasOwnProperty("state")) object.state = message.state;
      return object;
    };

    /**
     * Converts this WithdrawResp to JSON.
     * @function toJSON
     * @memberof client_proto.WithdrawResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    WithdrawResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for WithdrawResp
     * @function getTypeUrl
     * @memberof client_proto.WithdrawResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    WithdrawResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.WithdrawResp";
    };

    return WithdrawResp;
  })();

  client_proto.GetUserTeamInfoReq = (function () {
    /**
     * Properties of a GetUserTeamInfoReq.
     * @memberof client_proto
     * @interface IGetUserTeamInfoReq
     */

    /**
     * Constructs a new GetUserTeamInfoReq.
     * @memberof client_proto
     * @classdesc Represents a GetUserTeamInfoReq.
     * @implements IGetUserTeamInfoReq
     * @constructor
     * @param {client_proto.IGetUserTeamInfoReq=} [properties] Properties to set
     */
    function GetUserTeamInfoReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetUserTeamInfoReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserTeamInfoReq
     * @static
     * @param {client_proto.IGetUserTeamInfoReq=} [properties] Properties to set
     * @returns {client_proto.GetUserTeamInfoReq} GetUserTeamInfoReq instance
     */
    GetUserTeamInfoReq.create = function create(properties) {
      return new GetUserTeamInfoReq(properties);
    };

    /**
     * Encodes the specified GetUserTeamInfoReq message. Does not implicitly {@link client_proto.GetUserTeamInfoReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserTeamInfoReq
     * @static
     * @param {client_proto.IGetUserTeamInfoReq} message GetUserTeamInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserTeamInfoReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified GetUserTeamInfoReq message, length delimited. Does not implicitly {@link client_proto.GetUserTeamInfoReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserTeamInfoReq
     * @static
     * @param {client_proto.IGetUserTeamInfoReq} message GetUserTeamInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserTeamInfoReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserTeamInfoReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserTeamInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserTeamInfoReq} GetUserTeamInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserTeamInfoReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserTeamInfoReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserTeamInfoReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserTeamInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserTeamInfoReq} GetUserTeamInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserTeamInfoReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserTeamInfoReq message.
     * @function verify
     * @memberof client_proto.GetUserTeamInfoReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserTeamInfoReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a GetUserTeamInfoReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserTeamInfoReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserTeamInfoReq} GetUserTeamInfoReq
     */
    GetUserTeamInfoReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserTeamInfoReq) return object;
      return new $root.client_proto.GetUserTeamInfoReq();
    };

    /**
     * Creates a plain object from a GetUserTeamInfoReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserTeamInfoReq
     * @static
     * @param {client_proto.GetUserTeamInfoReq} message GetUserTeamInfoReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserTeamInfoReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this GetUserTeamInfoReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserTeamInfoReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserTeamInfoReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserTeamInfoReq
     * @function getTypeUrl
     * @memberof client_proto.GetUserTeamInfoReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserTeamInfoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserTeamInfoReq";
    };

    return GetUserTeamInfoReq;
  })();

  client_proto.GetUserTeamInfoResp = (function () {
    /**
     * Properties of a GetUserTeamInfoResp.
     * @memberof client_proto
     * @interface IGetUserTeamInfoResp
     * @property {client_proto.ITeamInfo|null} [team_info] GetUserTeamInfoResp team_info
     * @property {Array.<client_proto.ITeamTab>|null} [team_tabs] GetUserTeamInfoResp team_tabs
     */

    /**
     * Constructs a new GetUserTeamInfoResp.
     * @memberof client_proto
     * @classdesc Represents a GetUserTeamInfoResp.
     * @implements IGetUserTeamInfoResp
     * @constructor
     * @param {client_proto.IGetUserTeamInfoResp=} [properties] Properties to set
     */
    function GetUserTeamInfoResp(properties) {
      this.team_tabs = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserTeamInfoResp team_info.
     * @member {client_proto.ITeamInfo|null|undefined} team_info
     * @memberof client_proto.GetUserTeamInfoResp
     * @instance
     */
    GetUserTeamInfoResp.prototype.team_info = null;

    /**
     * GetUserTeamInfoResp team_tabs.
     * @member {Array.<client_proto.ITeamTab>} team_tabs
     * @memberof client_proto.GetUserTeamInfoResp
     * @instance
     */
    GetUserTeamInfoResp.prototype.team_tabs = $util.emptyArray;

    /**
     * Creates a new GetUserTeamInfoResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserTeamInfoResp
     * @static
     * @param {client_proto.IGetUserTeamInfoResp=} [properties] Properties to set
     * @returns {client_proto.GetUserTeamInfoResp} GetUserTeamInfoResp instance
     */
    GetUserTeamInfoResp.create = function create(properties) {
      return new GetUserTeamInfoResp(properties);
    };

    /**
     * Encodes the specified GetUserTeamInfoResp message. Does not implicitly {@link client_proto.GetUserTeamInfoResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserTeamInfoResp
     * @static
     * @param {client_proto.IGetUserTeamInfoResp} message GetUserTeamInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserTeamInfoResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.team_info != null && Object.hasOwnProperty.call(message, "team_info"))
        $root.client_proto.TeamInfo.encode(
          message.team_info,
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
        ).ldelim();
      if (message.team_tabs != null && message.team_tabs.length)
        for (var i = 0; i < message.team_tabs.length; ++i)
          $root.client_proto.TeamTab.encode(
            message.team_tabs[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified GetUserTeamInfoResp message, length delimited. Does not implicitly {@link client_proto.GetUserTeamInfoResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserTeamInfoResp
     * @static
     * @param {client_proto.IGetUserTeamInfoResp} message GetUserTeamInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserTeamInfoResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserTeamInfoResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserTeamInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserTeamInfoResp} GetUserTeamInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserTeamInfoResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserTeamInfoResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.team_info = $root.client_proto.TeamInfo.decode(reader, reader.uint32());
            break;
          }
          case 2: {
            if (!(message.team_tabs && message.team_tabs.length)) message.team_tabs = [];
            message.team_tabs.push($root.client_proto.TeamTab.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserTeamInfoResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserTeamInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserTeamInfoResp} GetUserTeamInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserTeamInfoResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserTeamInfoResp message.
     * @function verify
     * @memberof client_proto.GetUserTeamInfoResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserTeamInfoResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.team_info != null && message.hasOwnProperty("team_info")) {
        var error = $root.client_proto.TeamInfo.verify(message.team_info);
        if (error) return "team_info." + error;
      }
      if (message.team_tabs != null && message.hasOwnProperty("team_tabs")) {
        if (!Array.isArray(message.team_tabs)) return "team_tabs: array expected";
        for (var i = 0; i < message.team_tabs.length; ++i) {
          var error = $root.client_proto.TeamTab.verify(message.team_tabs[i]);
          if (error) return "team_tabs." + error;
        }
      }
      return null;
    };

    /**
     * Creates a GetUserTeamInfoResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserTeamInfoResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserTeamInfoResp} GetUserTeamInfoResp
     */
    GetUserTeamInfoResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserTeamInfoResp) return object;
      var message = new $root.client_proto.GetUserTeamInfoResp();
      if (object.team_info != null) {
        if (typeof object.team_info !== "object")
          throw TypeError(".client_proto.GetUserTeamInfoResp.team_info: object expected");
        message.team_info = $root.client_proto.TeamInfo.fromObject(object.team_info);
      }
      if (object.team_tabs) {
        if (!Array.isArray(object.team_tabs))
          throw TypeError(".client_proto.GetUserTeamInfoResp.team_tabs: array expected");
        message.team_tabs = [];
        for (var i = 0; i < object.team_tabs.length; ++i) {
          if (typeof object.team_tabs[i] !== "object")
            throw TypeError(".client_proto.GetUserTeamInfoResp.team_tabs: object expected");
          message.team_tabs[i] = $root.client_proto.TeamTab.fromObject(object.team_tabs[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a GetUserTeamInfoResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserTeamInfoResp
     * @static
     * @param {client_proto.GetUserTeamInfoResp} message GetUserTeamInfoResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserTeamInfoResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.team_tabs = [];
      if (options.defaults) object.team_info = null;
      if (message.team_info != null && message.hasOwnProperty("team_info"))
        object.team_info = $root.client_proto.TeamInfo.toObject(message.team_info, options);
      if (message.team_tabs && message.team_tabs.length) {
        object.team_tabs = [];
        for (var j = 0; j < message.team_tabs.length; ++j)
          object.team_tabs[j] = $root.client_proto.TeamTab.toObject(message.team_tabs[j], options);
      }
      return object;
    };

    /**
     * Converts this GetUserTeamInfoResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserTeamInfoResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserTeamInfoResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserTeamInfoResp
     * @function getTypeUrl
     * @memberof client_proto.GetUserTeamInfoResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserTeamInfoResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserTeamInfoResp";
    };

    return GetUserTeamInfoResp;
  })();

  client_proto.GetUserSubInfoReq = (function () {
    /**
     * Properties of a GetUserSubInfoReq.
     * @memberof client_proto
     * @interface IGetUserSubInfoReq
     * @property {number|null} [search_userid] GetUserSubInfoReq search_userid
     * @property {number|null} [period] GetUserSubInfoReq period
     * @property {client_proto.TEAM_TAB_TYPE|null} [team_tab] GetUserSubInfoReq team_tab
     */

    /**
     * Constructs a new GetUserSubInfoReq.
     * @memberof client_proto
     * @classdesc Represents a GetUserSubInfoReq.
     * @implements IGetUserSubInfoReq
     * @constructor
     * @param {client_proto.IGetUserSubInfoReq=} [properties] Properties to set
     */
    function GetUserSubInfoReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserSubInfoReq search_userid.
     * @member {number} search_userid
     * @memberof client_proto.GetUserSubInfoReq
     * @instance
     */
    GetUserSubInfoReq.prototype.search_userid = 0;

    /**
     * GetUserSubInfoReq period.
     * @member {number} period
     * @memberof client_proto.GetUserSubInfoReq
     * @instance
     */
    GetUserSubInfoReq.prototype.period = 0;

    /**
     * GetUserSubInfoReq team_tab.
     * @member {client_proto.TEAM_TAB_TYPE} team_tab
     * @memberof client_proto.GetUserSubInfoReq
     * @instance
     */
    GetUserSubInfoReq.prototype.team_tab = 0;

    /**
     * Creates a new GetUserSubInfoReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserSubInfoReq
     * @static
     * @param {client_proto.IGetUserSubInfoReq=} [properties] Properties to set
     * @returns {client_proto.GetUserSubInfoReq} GetUserSubInfoReq instance
     */
    GetUserSubInfoReq.create = function create(properties) {
      return new GetUserSubInfoReq(properties);
    };

    /**
     * Encodes the specified GetUserSubInfoReq message. Does not implicitly {@link client_proto.GetUserSubInfoReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserSubInfoReq
     * @static
     * @param {client_proto.IGetUserSubInfoReq} message GetUserSubInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserSubInfoReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.search_userid != null && Object.hasOwnProperty.call(message, "search_userid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.search_userid);
      if (message.period != null && Object.hasOwnProperty.call(message, "period"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.period);
      if (message.team_tab != null && Object.hasOwnProperty.call(message, "team_tab"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.team_tab);
      return writer;
    };

    /**
     * Encodes the specified GetUserSubInfoReq message, length delimited. Does not implicitly {@link client_proto.GetUserSubInfoReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserSubInfoReq
     * @static
     * @param {client_proto.IGetUserSubInfoReq} message GetUserSubInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserSubInfoReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserSubInfoReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserSubInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserSubInfoReq} GetUserSubInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserSubInfoReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserSubInfoReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.search_userid = reader.uint32();
            break;
          }
          case 2: {
            message.period = reader.int32();
            break;
          }
          case 3: {
            message.team_tab = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserSubInfoReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserSubInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserSubInfoReq} GetUserSubInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserSubInfoReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserSubInfoReq message.
     * @function verify
     * @memberof client_proto.GetUserSubInfoReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserSubInfoReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.search_userid != null && message.hasOwnProperty("search_userid"))
        if (!$util.isInteger(message.search_userid)) return "search_userid: integer expected";
      if (message.period != null && message.hasOwnProperty("period"))
        if (!$util.isInteger(message.period)) return "period: integer expected";
      if (message.team_tab != null && message.hasOwnProperty("team_tab"))
        switch (message.team_tab) {
          default:
            return "team_tab: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
            break;
        }
      return null;
    };

    /**
     * Creates a GetUserSubInfoReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserSubInfoReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserSubInfoReq} GetUserSubInfoReq
     */
    GetUserSubInfoReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserSubInfoReq) return object;
      var message = new $root.client_proto.GetUserSubInfoReq();
      if (object.search_userid != null) message.search_userid = object.search_userid >>> 0;
      if (object.period != null) message.period = object.period | 0;
      switch (object.team_tab) {
        default:
          if (typeof object.team_tab === "number") {
            message.team_tab = object.team_tab;
            break;
          }
          break;
        case "TEAM_TAB_TYPE_NULL":
        case 0:
          message.team_tab = 0;
          break;
        case "TEAM_TAB_TYPE_FIRST":
        case 1:
          message.team_tab = 1;
          break;
        case "TEAM_TAB_TYPE_SECOND":
        case 2:
          message.team_tab = 2;
          break;
        case "TEAM_TAB_TYPE_THIRD":
        case 3:
          message.team_tab = 3;
          break;
        case "TEAM_TAB_TYPE_FOURTH":
        case 4:
          message.team_tab = 4;
          break;
        case "TEAM_TAB_TYPE_FIFTH":
        case 5:
          message.team_tab = 5;
          break;
        case "TEAM_TAB_TYPE_SIXTH":
        case 6:
          message.team_tab = 6;
          break;
      }
      return message;
    };

    /**
     * Creates a plain object from a GetUserSubInfoReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserSubInfoReq
     * @static
     * @param {client_proto.GetUserSubInfoReq} message GetUserSubInfoReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserSubInfoReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.search_userid = 0;
        object.period = 0;
        object.team_tab = options.enums === String ? "TEAM_TAB_TYPE_NULL" : 0;
      }
      if (message.search_userid != null && message.hasOwnProperty("search_userid"))
        object.search_userid = message.search_userid;
      if (message.period != null && message.hasOwnProperty("period")) object.period = message.period;
      if (message.team_tab != null && message.hasOwnProperty("team_tab"))
        object.team_tab =
          options.enums === String
            ? $root.client_proto.TEAM_TAB_TYPE[message.team_tab] === undefined
              ? message.team_tab
              : $root.client_proto.TEAM_TAB_TYPE[message.team_tab]
            : message.team_tab;
      return object;
    };

    /**
     * Converts this GetUserSubInfoReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserSubInfoReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserSubInfoReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserSubInfoReq
     * @function getTypeUrl
     * @memberof client_proto.GetUserSubInfoReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserSubInfoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserSubInfoReq";
    };

    return GetUserSubInfoReq;
  })();

  client_proto.SubInfo = (function () {
    /**
     * Properties of a SubInfo.
     * @memberof client_proto
     * @interface ISubInfo
     * @property {number|null} [sub_uid] SubInfo sub_uid
     * @property {string|null} [user_avatar] SubInfo user_avatar
     * @property {number|Long|null} [user_deposit] SubInfo user_deposit
     * @property {number|Long|null} [user_commission] SubInfo user_commission
     * @property {number|Long|null} [user_join_time] SubInfo user_join_time
     * @property {number|null} [user_new] SubInfo user_new
     */

    /**
     * Constructs a new SubInfo.
     * @memberof client_proto
     * @classdesc Represents a SubInfo.
     * @implements ISubInfo
     * @constructor
     * @param {client_proto.ISubInfo=} [properties] Properties to set
     */
    function SubInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SubInfo sub_uid.
     * @member {number} sub_uid
     * @memberof client_proto.SubInfo
     * @instance
     */
    SubInfo.prototype.sub_uid = 0;

    /**
     * SubInfo user_avatar.
     * @member {string} user_avatar
     * @memberof client_proto.SubInfo
     * @instance
     */
    SubInfo.prototype.user_avatar = "";

    /**
     * SubInfo user_deposit.
     * @member {number|Long} user_deposit
     * @memberof client_proto.SubInfo
     * @instance
     */
    SubInfo.prototype.user_deposit = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SubInfo user_commission.
     * @member {number|Long} user_commission
     * @memberof client_proto.SubInfo
     * @instance
     */
    SubInfo.prototype.user_commission = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SubInfo user_join_time.
     * @member {number|Long} user_join_time
     * @memberof client_proto.SubInfo
     * @instance
     */
    SubInfo.prototype.user_join_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * SubInfo user_new.
     * @member {number} user_new
     * @memberof client_proto.SubInfo
     * @instance
     */
    SubInfo.prototype.user_new = 0;

    /**
     * Creates a new SubInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.SubInfo
     * @static
     * @param {client_proto.ISubInfo=} [properties] Properties to set
     * @returns {client_proto.SubInfo} SubInfo instance
     */
    SubInfo.create = function create(properties) {
      return new SubInfo(properties);
    };

    /**
     * Encodes the specified SubInfo message. Does not implicitly {@link client_proto.SubInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SubInfo
     * @static
     * @param {client_proto.ISubInfo} message SubInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.sub_uid != null && Object.hasOwnProperty.call(message, "sub_uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.sub_uid);
      if (message.user_avatar != null && Object.hasOwnProperty.call(message, "user_avatar"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.user_avatar);
      if (message.user_deposit != null && Object.hasOwnProperty.call(message, "user_deposit"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.user_deposit);
      if (message.user_commission != null && Object.hasOwnProperty.call(message, "user_commission"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.user_commission);
      if (message.user_join_time != null && Object.hasOwnProperty.call(message, "user_join_time"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.user_join_time);
      if (message.user_new != null && Object.hasOwnProperty.call(message, "user_new"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.user_new);
      return writer;
    };

    /**
     * Encodes the specified SubInfo message, length delimited. Does not implicitly {@link client_proto.SubInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SubInfo
     * @static
     * @param {client_proto.ISubInfo} message SubInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SubInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SubInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SubInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SubInfo} SubInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SubInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.sub_uid = reader.uint32();
            break;
          }
          case 2: {
            message.user_avatar = reader.string();
            break;
          }
          case 3: {
            message.user_deposit = reader.int64();
            break;
          }
          case 4: {
            message.user_commission = reader.int64();
            break;
          }
          case 5: {
            message.user_join_time = reader.int64();
            break;
          }
          case 6: {
            message.user_new = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SubInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SubInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SubInfo} SubInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SubInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SubInfo message.
     * @function verify
     * @memberof client_proto.SubInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SubInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.sub_uid != null && message.hasOwnProperty("sub_uid"))
        if (!$util.isInteger(message.sub_uid)) return "sub_uid: integer expected";
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        if (!$util.isString(message.user_avatar)) return "user_avatar: string expected";
      if (message.user_deposit != null && message.hasOwnProperty("user_deposit"))
        if (
          !$util.isInteger(message.user_deposit) &&
          !(
            message.user_deposit &&
            $util.isInteger(message.user_deposit.low) &&
            $util.isInteger(message.user_deposit.high)
          )
        )
          return "user_deposit: integer|Long expected";
      if (message.user_commission != null && message.hasOwnProperty("user_commission"))
        if (
          !$util.isInteger(message.user_commission) &&
          !(
            message.user_commission &&
            $util.isInteger(message.user_commission.low) &&
            $util.isInteger(message.user_commission.high)
          )
        )
          return "user_commission: integer|Long expected";
      if (message.user_join_time != null && message.hasOwnProperty("user_join_time"))
        if (
          !$util.isInteger(message.user_join_time) &&
          !(
            message.user_join_time &&
            $util.isInteger(message.user_join_time.low) &&
            $util.isInteger(message.user_join_time.high)
          )
        )
          return "user_join_time: integer|Long expected";
      if (message.user_new != null && message.hasOwnProperty("user_new"))
        if (!$util.isInteger(message.user_new)) return "user_new: integer expected";
      return null;
    };

    /**
     * Creates a SubInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SubInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SubInfo} SubInfo
     */
    SubInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SubInfo) return object;
      var message = new $root.client_proto.SubInfo();
      if (object.sub_uid != null) message.sub_uid = object.sub_uid >>> 0;
      if (object.user_avatar != null) message.user_avatar = String(object.user_avatar);
      if (object.user_deposit != null)
        if ($util.Long) (message.user_deposit = $util.Long.fromValue(object.user_deposit)).unsigned = false;
        else if (typeof object.user_deposit === "string") message.user_deposit = parseInt(object.user_deposit, 10);
        else if (typeof object.user_deposit === "number") message.user_deposit = object.user_deposit;
        else if (typeof object.user_deposit === "object")
          message.user_deposit = new $util.LongBits(
            object.user_deposit.low >>> 0,
            object.user_deposit.high >>> 0,
          ).toNumber();
      if (object.user_commission != null)
        if ($util.Long) (message.user_commission = $util.Long.fromValue(object.user_commission)).unsigned = false;
        else if (typeof object.user_commission === "string")
          message.user_commission = parseInt(object.user_commission, 10);
        else if (typeof object.user_commission === "number") message.user_commission = object.user_commission;
        else if (typeof object.user_commission === "object")
          message.user_commission = new $util.LongBits(
            object.user_commission.low >>> 0,
            object.user_commission.high >>> 0,
          ).toNumber();
      if (object.user_join_time != null)
        if ($util.Long) (message.user_join_time = $util.Long.fromValue(object.user_join_time)).unsigned = false;
        else if (typeof object.user_join_time === "string")
          message.user_join_time = parseInt(object.user_join_time, 10);
        else if (typeof object.user_join_time === "number") message.user_join_time = object.user_join_time;
        else if (typeof object.user_join_time === "object")
          message.user_join_time = new $util.LongBits(
            object.user_join_time.low >>> 0,
            object.user_join_time.high >>> 0,
          ).toNumber();
      if (object.user_new != null) message.user_new = object.user_new | 0;
      return message;
    };

    /**
     * Creates a plain object from a SubInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SubInfo
     * @static
     * @param {client_proto.SubInfo} message SubInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SubInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.sub_uid = 0;
        object.user_avatar = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.user_deposit =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.user_deposit = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.user_commission =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.user_commission = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.user_join_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.user_join_time = options.longs === String ? "0" : 0;
        object.user_new = 0;
      }
      if (message.sub_uid != null && message.hasOwnProperty("sub_uid")) object.sub_uid = message.sub_uid;
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        object.user_avatar = message.user_avatar;
      if (message.user_deposit != null && message.hasOwnProperty("user_deposit"))
        if (typeof message.user_deposit === "number")
          object.user_deposit = options.longs === String ? String(message.user_deposit) : message.user_deposit;
        else
          object.user_deposit =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.user_deposit)
              : options.longs === Number
                ? new $util.LongBits(message.user_deposit.low >>> 0, message.user_deposit.high >>> 0).toNumber()
                : message.user_deposit;
      if (message.user_commission != null && message.hasOwnProperty("user_commission"))
        if (typeof message.user_commission === "number")
          object.user_commission = options.longs === String ? String(message.user_commission) : message.user_commission;
        else
          object.user_commission =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.user_commission)
              : options.longs === Number
                ? new $util.LongBits(message.user_commission.low >>> 0, message.user_commission.high >>> 0).toNumber()
                : message.user_commission;
      if (message.user_join_time != null && message.hasOwnProperty("user_join_time"))
        if (typeof message.user_join_time === "number")
          object.user_join_time = options.longs === String ? String(message.user_join_time) : message.user_join_time;
        else
          object.user_join_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.user_join_time)
              : options.longs === Number
                ? new $util.LongBits(message.user_join_time.low >>> 0, message.user_join_time.high >>> 0).toNumber()
                : message.user_join_time;
      if (message.user_new != null && message.hasOwnProperty("user_new")) object.user_new = message.user_new;
      return object;
    };

    /**
     * Converts this SubInfo to JSON.
     * @function toJSON
     * @memberof client_proto.SubInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SubInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SubInfo
     * @function getTypeUrl
     * @memberof client_proto.SubInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SubInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SubInfo";
    };

    return SubInfo;
  })();

  client_proto.GetUserSubInfoResp = (function () {
    /**
     * Properties of a GetUserSubInfoResp.
     * @memberof client_proto
     * @interface IGetUserSubInfoResp
     * @property {Array.<client_proto.ISubInfo>|null} [sub_infos] GetUserSubInfoResp sub_infos
     * @property {number|null} [state] GetUserSubInfoResp state
     */

    /**
     * Constructs a new GetUserSubInfoResp.
     * @memberof client_proto
     * @classdesc Represents a GetUserSubInfoResp.
     * @implements IGetUserSubInfoResp
     * @constructor
     * @param {client_proto.IGetUserSubInfoResp=} [properties] Properties to set
     */
    function GetUserSubInfoResp(properties) {
      this.sub_infos = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserSubInfoResp sub_infos.
     * @member {Array.<client_proto.ISubInfo>} sub_infos
     * @memberof client_proto.GetUserSubInfoResp
     * @instance
     */
    GetUserSubInfoResp.prototype.sub_infos = $util.emptyArray;

    /**
     * GetUserSubInfoResp state.
     * @member {number} state
     * @memberof client_proto.GetUserSubInfoResp
     * @instance
     */
    GetUserSubInfoResp.prototype.state = 0;

    /**
     * Creates a new GetUserSubInfoResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserSubInfoResp
     * @static
     * @param {client_proto.IGetUserSubInfoResp=} [properties] Properties to set
     * @returns {client_proto.GetUserSubInfoResp} GetUserSubInfoResp instance
     */
    GetUserSubInfoResp.create = function create(properties) {
      return new GetUserSubInfoResp(properties);
    };

    /**
     * Encodes the specified GetUserSubInfoResp message. Does not implicitly {@link client_proto.GetUserSubInfoResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserSubInfoResp
     * @static
     * @param {client_proto.IGetUserSubInfoResp} message GetUserSubInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserSubInfoResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.sub_infos != null && message.sub_infos.length)
        for (var i = 0; i < message.sub_infos.length; ++i)
          $root.client_proto.SubInfo.encode(
            message.sub_infos[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.state != null && Object.hasOwnProperty.call(message, "state"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.state);
      return writer;
    };

    /**
     * Encodes the specified GetUserSubInfoResp message, length delimited. Does not implicitly {@link client_proto.GetUserSubInfoResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserSubInfoResp
     * @static
     * @param {client_proto.IGetUserSubInfoResp} message GetUserSubInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserSubInfoResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserSubInfoResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserSubInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserSubInfoResp} GetUserSubInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserSubInfoResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserSubInfoResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.sub_infos && message.sub_infos.length)) message.sub_infos = [];
            message.sub_infos.push($root.client_proto.SubInfo.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.state = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserSubInfoResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserSubInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserSubInfoResp} GetUserSubInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserSubInfoResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserSubInfoResp message.
     * @function verify
     * @memberof client_proto.GetUserSubInfoResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserSubInfoResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.sub_infos != null && message.hasOwnProperty("sub_infos")) {
        if (!Array.isArray(message.sub_infos)) return "sub_infos: array expected";
        for (var i = 0; i < message.sub_infos.length; ++i) {
          var error = $root.client_proto.SubInfo.verify(message.sub_infos[i]);
          if (error) return "sub_infos." + error;
        }
      }
      if (message.state != null && message.hasOwnProperty("state"))
        if (!$util.isInteger(message.state)) return "state: integer expected";
      return null;
    };

    /**
     * Creates a GetUserSubInfoResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserSubInfoResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserSubInfoResp} GetUserSubInfoResp
     */
    GetUserSubInfoResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserSubInfoResp) return object;
      var message = new $root.client_proto.GetUserSubInfoResp();
      if (object.sub_infos) {
        if (!Array.isArray(object.sub_infos))
          throw TypeError(".client_proto.GetUserSubInfoResp.sub_infos: array expected");
        message.sub_infos = [];
        for (var i = 0; i < object.sub_infos.length; ++i) {
          if (typeof object.sub_infos[i] !== "object")
            throw TypeError(".client_proto.GetUserSubInfoResp.sub_infos: object expected");
          message.sub_infos[i] = $root.client_proto.SubInfo.fromObject(object.sub_infos[i]);
        }
      }
      if (object.state != null) message.state = object.state | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetUserSubInfoResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserSubInfoResp
     * @static
     * @param {client_proto.GetUserSubInfoResp} message GetUserSubInfoResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserSubInfoResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.sub_infos = [];
      if (options.defaults) object.state = 0;
      if (message.sub_infos && message.sub_infos.length) {
        object.sub_infos = [];
        for (var j = 0; j < message.sub_infos.length; ++j)
          object.sub_infos[j] = $root.client_proto.SubInfo.toObject(message.sub_infos[j], options);
      }
      if (message.state != null && message.hasOwnProperty("state")) object.state = message.state;
      return object;
    };

    /**
     * Converts this GetUserSubInfoResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserSubInfoResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserSubInfoResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserSubInfoResp
     * @function getTypeUrl
     * @memberof client_proto.GetUserSubInfoResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserSubInfoResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserSubInfoResp";
    };

    return GetUserSubInfoResp;
  })();

  client_proto.GetUserTeamListReq = (function () {
    /**
     * Properties of a GetUserTeamListReq.
     * @memberof client_proto
     * @interface IGetUserTeamListReq
     * @property {number|null} [page_index] GetUserTeamListReq page_index
     * @property {number|null} [page_size] GetUserTeamListReq page_size
     * @property {number|null} [period] GetUserTeamListReq period
     * @property {client_proto.SORT_TYPE|null} [sort_type] GetUserTeamListReq sort_type
     * @property {client_proto.SORT_FIELD|null} [sort_field] GetUserTeamListReq sort_field
     * @property {client_proto.TEAM_TAB_TYPE|null} [team_tab] GetUserTeamListReq team_tab
     */

    /**
     * Constructs a new GetUserTeamListReq.
     * @memberof client_proto
     * @classdesc Represents a GetUserTeamListReq.
     * @implements IGetUserTeamListReq
     * @constructor
     * @param {client_proto.IGetUserTeamListReq=} [properties] Properties to set
     */
    function GetUserTeamListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserTeamListReq page_index.
     * @member {number} page_index
     * @memberof client_proto.GetUserTeamListReq
     * @instance
     */
    GetUserTeamListReq.prototype.page_index = 0;

    /**
     * GetUserTeamListReq page_size.
     * @member {number} page_size
     * @memberof client_proto.GetUserTeamListReq
     * @instance
     */
    GetUserTeamListReq.prototype.page_size = 0;

    /**
     * GetUserTeamListReq period.
     * @member {number} period
     * @memberof client_proto.GetUserTeamListReq
     * @instance
     */
    GetUserTeamListReq.prototype.period = 0;

    /**
     * GetUserTeamListReq sort_type.
     * @member {client_proto.SORT_TYPE} sort_type
     * @memberof client_proto.GetUserTeamListReq
     * @instance
     */
    GetUserTeamListReq.prototype.sort_type = 0;

    /**
     * GetUserTeamListReq sort_field.
     * @member {client_proto.SORT_FIELD} sort_field
     * @memberof client_proto.GetUserTeamListReq
     * @instance
     */
    GetUserTeamListReq.prototype.sort_field = 0;

    /**
     * GetUserTeamListReq team_tab.
     * @member {client_proto.TEAM_TAB_TYPE} team_tab
     * @memberof client_proto.GetUserTeamListReq
     * @instance
     */
    GetUserTeamListReq.prototype.team_tab = 0;

    /**
     * Creates a new GetUserTeamListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserTeamListReq
     * @static
     * @param {client_proto.IGetUserTeamListReq=} [properties] Properties to set
     * @returns {client_proto.GetUserTeamListReq} GetUserTeamListReq instance
     */
    GetUserTeamListReq.create = function create(properties) {
      return new GetUserTeamListReq(properties);
    };

    /**
     * Encodes the specified GetUserTeamListReq message. Does not implicitly {@link client_proto.GetUserTeamListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserTeamListReq
     * @static
     * @param {client_proto.IGetUserTeamListReq} message GetUserTeamListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserTeamListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.page_index != null && Object.hasOwnProperty.call(message, "page_index"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.page_index);
      if (message.page_size != null && Object.hasOwnProperty.call(message, "page_size"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.page_size);
      if (message.period != null && Object.hasOwnProperty.call(message, "period"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.period);
      if (message.sort_type != null && Object.hasOwnProperty.call(message, "sort_type"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.sort_type);
      if (message.sort_field != null && Object.hasOwnProperty.call(message, "sort_field"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.sort_field);
      if (message.team_tab != null && Object.hasOwnProperty.call(message, "team_tab"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.team_tab);
      return writer;
    };

    /**
     * Encodes the specified GetUserTeamListReq message, length delimited. Does not implicitly {@link client_proto.GetUserTeamListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserTeamListReq
     * @static
     * @param {client_proto.IGetUserTeamListReq} message GetUserTeamListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserTeamListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserTeamListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserTeamListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserTeamListReq} GetUserTeamListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserTeamListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserTeamListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.page_index = reader.int32();
            break;
          }
          case 2: {
            message.page_size = reader.int32();
            break;
          }
          case 3: {
            message.period = reader.int32();
            break;
          }
          case 4: {
            message.sort_type = reader.int32();
            break;
          }
          case 5: {
            message.sort_field = reader.int32();
            break;
          }
          case 6: {
            message.team_tab = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserTeamListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserTeamListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserTeamListReq} GetUserTeamListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserTeamListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserTeamListReq message.
     * @function verify
     * @memberof client_proto.GetUserTeamListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserTeamListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.page_index != null && message.hasOwnProperty("page_index"))
        if (!$util.isInteger(message.page_index)) return "page_index: integer expected";
      if (message.page_size != null && message.hasOwnProperty("page_size"))
        if (!$util.isInteger(message.page_size)) return "page_size: integer expected";
      if (message.period != null && message.hasOwnProperty("period"))
        if (!$util.isInteger(message.period)) return "period: integer expected";
      if (message.sort_type != null && message.hasOwnProperty("sort_type"))
        switch (message.sort_type) {
          default:
            return "sort_type: enum value expected";
          case 0:
          case 1:
          case 2:
            break;
        }
      if (message.sort_field != null && message.hasOwnProperty("sort_field"))
        switch (message.sort_field) {
          default:
            return "sort_field: enum value expected";
          case 0:
          case 1:
          case 2:
            break;
        }
      if (message.team_tab != null && message.hasOwnProperty("team_tab"))
        switch (message.team_tab) {
          default:
            return "team_tab: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
            break;
        }
      return null;
    };

    /**
     * Creates a GetUserTeamListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserTeamListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserTeamListReq} GetUserTeamListReq
     */
    GetUserTeamListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserTeamListReq) return object;
      var message = new $root.client_proto.GetUserTeamListReq();
      if (object.page_index != null) message.page_index = object.page_index | 0;
      if (object.page_size != null) message.page_size = object.page_size | 0;
      if (object.period != null) message.period = object.period | 0;
      switch (object.sort_type) {
        default:
          if (typeof object.sort_type === "number") {
            message.sort_type = object.sort_type;
            break;
          }
          break;
        case "SORT_TYPE_NULL":
        case 0:
          message.sort_type = 0;
          break;
        case "SORT_TYPE_ASC":
        case 1:
          message.sort_type = 1;
          break;
        case "SORT_TYPE_DESC":
        case 2:
          message.sort_type = 2;
          break;
      }
      switch (object.sort_field) {
        default:
          if (typeof object.sort_field === "number") {
            message.sort_field = object.sort_field;
            break;
          }
          break;
        case "SORT_FIELD_NULL":
        case 0:
          message.sort_field = 0;
          break;
        case "SORT_FIELD_JOIN":
        case 1:
          message.sort_field = 1;
          break;
        case "SORT_FIELD_COMMISSION":
        case 2:
          message.sort_field = 2;
          break;
      }
      switch (object.team_tab) {
        default:
          if (typeof object.team_tab === "number") {
            message.team_tab = object.team_tab;
            break;
          }
          break;
        case "TEAM_TAB_TYPE_NULL":
        case 0:
          message.team_tab = 0;
          break;
        case "TEAM_TAB_TYPE_FIRST":
        case 1:
          message.team_tab = 1;
          break;
        case "TEAM_TAB_TYPE_SECOND":
        case 2:
          message.team_tab = 2;
          break;
        case "TEAM_TAB_TYPE_THIRD":
        case 3:
          message.team_tab = 3;
          break;
        case "TEAM_TAB_TYPE_FOURTH":
        case 4:
          message.team_tab = 4;
          break;
        case "TEAM_TAB_TYPE_FIFTH":
        case 5:
          message.team_tab = 5;
          break;
        case "TEAM_TAB_TYPE_SIXTH":
        case 6:
          message.team_tab = 6;
          break;
      }
      return message;
    };

    /**
     * Creates a plain object from a GetUserTeamListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserTeamListReq
     * @static
     * @param {client_proto.GetUserTeamListReq} message GetUserTeamListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserTeamListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.page_index = 0;
        object.page_size = 0;
        object.period = 0;
        object.sort_type = options.enums === String ? "SORT_TYPE_NULL" : 0;
        object.sort_field = options.enums === String ? "SORT_FIELD_NULL" : 0;
        object.team_tab = options.enums === String ? "TEAM_TAB_TYPE_NULL" : 0;
      }
      if (message.page_index != null && message.hasOwnProperty("page_index")) object.page_index = message.page_index;
      if (message.page_size != null && message.hasOwnProperty("page_size")) object.page_size = message.page_size;
      if (message.period != null && message.hasOwnProperty("period")) object.period = message.period;
      if (message.sort_type != null && message.hasOwnProperty("sort_type"))
        object.sort_type =
          options.enums === String
            ? $root.client_proto.SORT_TYPE[message.sort_type] === undefined
              ? message.sort_type
              : $root.client_proto.SORT_TYPE[message.sort_type]
            : message.sort_type;
      if (message.sort_field != null && message.hasOwnProperty("sort_field"))
        object.sort_field =
          options.enums === String
            ? $root.client_proto.SORT_FIELD[message.sort_field] === undefined
              ? message.sort_field
              : $root.client_proto.SORT_FIELD[message.sort_field]
            : message.sort_field;
      if (message.team_tab != null && message.hasOwnProperty("team_tab"))
        object.team_tab =
          options.enums === String
            ? $root.client_proto.TEAM_TAB_TYPE[message.team_tab] === undefined
              ? message.team_tab
              : $root.client_proto.TEAM_TAB_TYPE[message.team_tab]
            : message.team_tab;
      return object;
    };

    /**
     * Converts this GetUserTeamListReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserTeamListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserTeamListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserTeamListReq
     * @function getTypeUrl
     * @memberof client_proto.GetUserTeamListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserTeamListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserTeamListReq";
    };

    return GetUserTeamListReq;
  })();

  client_proto.GetUserTeamListResp = (function () {
    /**
     * Properties of a GetUserTeamListResp.
     * @memberof client_proto
     * @interface IGetUserTeamListResp
     * @property {Array.<client_proto.ISubInfo>|null} [sub_infos] GetUserTeamListResp sub_infos
     * @property {number|null} [state] GetUserTeamListResp state
     * @property {number|null} [page_index] GetUserTeamListResp page_index
     */

    /**
     * Constructs a new GetUserTeamListResp.
     * @memberof client_proto
     * @classdesc Represents a GetUserTeamListResp.
     * @implements IGetUserTeamListResp
     * @constructor
     * @param {client_proto.IGetUserTeamListResp=} [properties] Properties to set
     */
    function GetUserTeamListResp(properties) {
      this.sub_infos = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserTeamListResp sub_infos.
     * @member {Array.<client_proto.ISubInfo>} sub_infos
     * @memberof client_proto.GetUserTeamListResp
     * @instance
     */
    GetUserTeamListResp.prototype.sub_infos = $util.emptyArray;

    /**
     * GetUserTeamListResp state.
     * @member {number} state
     * @memberof client_proto.GetUserTeamListResp
     * @instance
     */
    GetUserTeamListResp.prototype.state = 0;

    /**
     * GetUserTeamListResp page_index.
     * @member {number} page_index
     * @memberof client_proto.GetUserTeamListResp
     * @instance
     */
    GetUserTeamListResp.prototype.page_index = 0;

    /**
     * Creates a new GetUserTeamListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserTeamListResp
     * @static
     * @param {client_proto.IGetUserTeamListResp=} [properties] Properties to set
     * @returns {client_proto.GetUserTeamListResp} GetUserTeamListResp instance
     */
    GetUserTeamListResp.create = function create(properties) {
      return new GetUserTeamListResp(properties);
    };

    /**
     * Encodes the specified GetUserTeamListResp message. Does not implicitly {@link client_proto.GetUserTeamListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserTeamListResp
     * @static
     * @param {client_proto.IGetUserTeamListResp} message GetUserTeamListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserTeamListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.sub_infos != null && message.sub_infos.length)
        for (var i = 0; i < message.sub_infos.length; ++i)
          $root.client_proto.SubInfo.encode(
            message.sub_infos[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.state != null && Object.hasOwnProperty.call(message, "state"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.state);
      if (message.page_index != null && Object.hasOwnProperty.call(message, "page_index"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.page_index);
      return writer;
    };

    /**
     * Encodes the specified GetUserTeamListResp message, length delimited. Does not implicitly {@link client_proto.GetUserTeamListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserTeamListResp
     * @static
     * @param {client_proto.IGetUserTeamListResp} message GetUserTeamListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserTeamListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserTeamListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserTeamListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserTeamListResp} GetUserTeamListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserTeamListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserTeamListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.sub_infos && message.sub_infos.length)) message.sub_infos = [];
            message.sub_infos.push($root.client_proto.SubInfo.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.state = reader.int32();
            break;
          }
          case 3: {
            message.page_index = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserTeamListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserTeamListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserTeamListResp} GetUserTeamListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserTeamListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserTeamListResp message.
     * @function verify
     * @memberof client_proto.GetUserTeamListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserTeamListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.sub_infos != null && message.hasOwnProperty("sub_infos")) {
        if (!Array.isArray(message.sub_infos)) return "sub_infos: array expected";
        for (var i = 0; i < message.sub_infos.length; ++i) {
          var error = $root.client_proto.SubInfo.verify(message.sub_infos[i]);
          if (error) return "sub_infos." + error;
        }
      }
      if (message.state != null && message.hasOwnProperty("state"))
        if (!$util.isInteger(message.state)) return "state: integer expected";
      if (message.page_index != null && message.hasOwnProperty("page_index"))
        if (!$util.isInteger(message.page_index)) return "page_index: integer expected";
      return null;
    };

    /**
     * Creates a GetUserTeamListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserTeamListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserTeamListResp} GetUserTeamListResp
     */
    GetUserTeamListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserTeamListResp) return object;
      var message = new $root.client_proto.GetUserTeamListResp();
      if (object.sub_infos) {
        if (!Array.isArray(object.sub_infos))
          throw TypeError(".client_proto.GetUserTeamListResp.sub_infos: array expected");
        message.sub_infos = [];
        for (var i = 0; i < object.sub_infos.length; ++i) {
          if (typeof object.sub_infos[i] !== "object")
            throw TypeError(".client_proto.GetUserTeamListResp.sub_infos: object expected");
          message.sub_infos[i] = $root.client_proto.SubInfo.fromObject(object.sub_infos[i]);
        }
      }
      if (object.state != null) message.state = object.state | 0;
      if (object.page_index != null) message.page_index = object.page_index | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetUserTeamListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserTeamListResp
     * @static
     * @param {client_proto.GetUserTeamListResp} message GetUserTeamListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserTeamListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.sub_infos = [];
      if (options.defaults) {
        object.state = 0;
        object.page_index = 0;
      }
      if (message.sub_infos && message.sub_infos.length) {
        object.sub_infos = [];
        for (var j = 0; j < message.sub_infos.length; ++j)
          object.sub_infos[j] = $root.client_proto.SubInfo.toObject(message.sub_infos[j], options);
      }
      if (message.state != null && message.hasOwnProperty("state")) object.state = message.state;
      if (message.page_index != null && message.hasOwnProperty("page_index")) object.page_index = message.page_index;
      return object;
    };

    /**
     * Converts this GetUserTeamListResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserTeamListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserTeamListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserTeamListResp
     * @function getTypeUrl
     * @memberof client_proto.GetUserTeamListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserTeamListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserTeamListResp";
    };

    return GetUserTeamListResp;
  })();

  client_proto.GetCommissionFlowListReq = (function () {
    /**
     * Properties of a GetCommissionFlowListReq.
     * @memberof client_proto
     * @interface IGetCommissionFlowListReq
     * @property {client_proto.COMMISSION_SOURCE_TYPE|null} [source_type] GetCommissionFlowListReq source_type
     * @property {number|null} [page_index] GetCommissionFlowListReq page_index
     * @property {number|null} [page_size] GetCommissionFlowListReq page_size
     * @property {number|null} [end_time] GetCommissionFlowListReq end_time
     */

    /**
     * Constructs a new GetCommissionFlowListReq.
     * @memberof client_proto
     * @classdesc Represents a GetCommissionFlowListReq.
     * @implements IGetCommissionFlowListReq
     * @constructor
     * @param {client_proto.IGetCommissionFlowListReq=} [properties] Properties to set
     */
    function GetCommissionFlowListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetCommissionFlowListReq source_type.
     * @member {client_proto.COMMISSION_SOURCE_TYPE} source_type
     * @memberof client_proto.GetCommissionFlowListReq
     * @instance
     */
    GetCommissionFlowListReq.prototype.source_type = 0;

    /**
     * GetCommissionFlowListReq page_index.
     * @member {number} page_index
     * @memberof client_proto.GetCommissionFlowListReq
     * @instance
     */
    GetCommissionFlowListReq.prototype.page_index = 0;

    /**
     * GetCommissionFlowListReq page_size.
     * @member {number} page_size
     * @memberof client_proto.GetCommissionFlowListReq
     * @instance
     */
    GetCommissionFlowListReq.prototype.page_size = 0;

    /**
     * GetCommissionFlowListReq end_time.
     * @member {number} end_time
     * @memberof client_proto.GetCommissionFlowListReq
     * @instance
     */
    GetCommissionFlowListReq.prototype.end_time = 0;

    /**
     * Creates a new GetCommissionFlowListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetCommissionFlowListReq
     * @static
     * @param {client_proto.IGetCommissionFlowListReq=} [properties] Properties to set
     * @returns {client_proto.GetCommissionFlowListReq} GetCommissionFlowListReq instance
     */
    GetCommissionFlowListReq.create = function create(properties) {
      return new GetCommissionFlowListReq(properties);
    };

    /**
     * Encodes the specified GetCommissionFlowListReq message. Does not implicitly {@link client_proto.GetCommissionFlowListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetCommissionFlowListReq
     * @static
     * @param {client_proto.IGetCommissionFlowListReq} message GetCommissionFlowListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCommissionFlowListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.source_type != null && Object.hasOwnProperty.call(message, "source_type"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.source_type);
      if (message.page_index != null && Object.hasOwnProperty.call(message, "page_index"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.page_index);
      if (message.page_size != null && Object.hasOwnProperty.call(message, "page_size"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.page_size);
      if (message.end_time != null && Object.hasOwnProperty.call(message, "end_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.end_time);
      return writer;
    };

    /**
     * Encodes the specified GetCommissionFlowListReq message, length delimited. Does not implicitly {@link client_proto.GetCommissionFlowListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetCommissionFlowListReq
     * @static
     * @param {client_proto.IGetCommissionFlowListReq} message GetCommissionFlowListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCommissionFlowListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetCommissionFlowListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetCommissionFlowListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetCommissionFlowListReq} GetCommissionFlowListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCommissionFlowListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetCommissionFlowListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.source_type = reader.int32();
            break;
          }
          case 2: {
            message.page_index = reader.int32();
            break;
          }
          case 3: {
            message.page_size = reader.int32();
            break;
          }
          case 4: {
            message.end_time = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetCommissionFlowListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetCommissionFlowListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetCommissionFlowListReq} GetCommissionFlowListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCommissionFlowListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetCommissionFlowListReq message.
     * @function verify
     * @memberof client_proto.GetCommissionFlowListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetCommissionFlowListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.source_type != null && message.hasOwnProperty("source_type"))
        switch (message.source_type) {
          default:
            return "source_type: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
            break;
        }
      if (message.page_index != null && message.hasOwnProperty("page_index"))
        if (!$util.isInteger(message.page_index)) return "page_index: integer expected";
      if (message.page_size != null && message.hasOwnProperty("page_size"))
        if (!$util.isInteger(message.page_size)) return "page_size: integer expected";
      if (message.end_time != null && message.hasOwnProperty("end_time"))
        if (!$util.isInteger(message.end_time)) return "end_time: integer expected";
      return null;
    };

    /**
     * Creates a GetCommissionFlowListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetCommissionFlowListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetCommissionFlowListReq} GetCommissionFlowListReq
     */
    GetCommissionFlowListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetCommissionFlowListReq) return object;
      var message = new $root.client_proto.GetCommissionFlowListReq();
      switch (object.source_type) {
        default:
          if (typeof object.source_type === "number") {
            message.source_type = object.source_type;
            break;
          }
          break;
        case "COMMISSION_SOURCE_TYPE_ALL":
        case 0:
          message.source_type = 0;
          break;
        case "COMMISSION_SOURCE_TYPE_BET":
        case 1:
          message.source_type = 1;
          break;
        case "COMMISSION_SOURCE_TYPE_DEPOSIT":
        case 2:
          message.source_type = 2;
          break;
        case "COMMISSION_SOURCE_TYPE_INVITE":
        case 3:
          message.source_type = 3;
          break;
        case "COMMISSION_SOURCE_TYPE_TASK":
        case 4:
          message.source_type = 4;
          break;
      }
      if (object.page_index != null) message.page_index = object.page_index | 0;
      if (object.page_size != null) message.page_size = object.page_size | 0;
      if (object.end_time != null) message.end_time = object.end_time | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetCommissionFlowListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetCommissionFlowListReq
     * @static
     * @param {client_proto.GetCommissionFlowListReq} message GetCommissionFlowListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetCommissionFlowListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.source_type = options.enums === String ? "COMMISSION_SOURCE_TYPE_ALL" : 0;
        object.page_index = 0;
        object.page_size = 0;
        object.end_time = 0;
      }
      if (message.source_type != null && message.hasOwnProperty("source_type"))
        object.source_type =
          options.enums === String
            ? $root.client_proto.COMMISSION_SOURCE_TYPE[message.source_type] === undefined
              ? message.source_type
              : $root.client_proto.COMMISSION_SOURCE_TYPE[message.source_type]
            : message.source_type;
      if (message.page_index != null && message.hasOwnProperty("page_index")) object.page_index = message.page_index;
      if (message.page_size != null && message.hasOwnProperty("page_size")) object.page_size = message.page_size;
      if (message.end_time != null && message.hasOwnProperty("end_time")) object.end_time = message.end_time;
      return object;
    };

    /**
     * Converts this GetCommissionFlowListReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetCommissionFlowListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetCommissionFlowListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetCommissionFlowListReq
     * @function getTypeUrl
     * @memberof client_proto.GetCommissionFlowListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetCommissionFlowListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetCommissionFlowListReq";
    };

    return GetCommissionFlowListReq;
  })();

  client_proto.CommissionDetail = (function () {
    /**
     * Properties of a CommissionDetail.
     * @memberof client_proto
     * @interface ICommissionDetail
     * @property {number|Long|null} [date_time] CommissionDetail date_time
     * @property {number|Long|null} [commission] CommissionDetail commission
     * @property {string|null} [details] CommissionDetail details
     * @property {client_proto.COMMISSION_SOURCE_TYPE|null} [source_type] CommissionDetail source_type
     */

    /**
     * Constructs a new CommissionDetail.
     * @memberof client_proto
     * @classdesc Represents a CommissionDetail.
     * @implements ICommissionDetail
     * @constructor
     * @param {client_proto.ICommissionDetail=} [properties] Properties to set
     */
    function CommissionDetail(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CommissionDetail date_time.
     * @member {number|Long} date_time
     * @memberof client_proto.CommissionDetail
     * @instance
     */
    CommissionDetail.prototype.date_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CommissionDetail commission.
     * @member {number|Long} commission
     * @memberof client_proto.CommissionDetail
     * @instance
     */
    CommissionDetail.prototype.commission = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CommissionDetail details.
     * @member {string} details
     * @memberof client_proto.CommissionDetail
     * @instance
     */
    CommissionDetail.prototype.details = "";

    /**
     * CommissionDetail source_type.
     * @member {client_proto.COMMISSION_SOURCE_TYPE} source_type
     * @memberof client_proto.CommissionDetail
     * @instance
     */
    CommissionDetail.prototype.source_type = 0;

    /**
     * Creates a new CommissionDetail instance using the specified properties.
     * @function create
     * @memberof client_proto.CommissionDetail
     * @static
     * @param {client_proto.ICommissionDetail=} [properties] Properties to set
     * @returns {client_proto.CommissionDetail} CommissionDetail instance
     */
    CommissionDetail.create = function create(properties) {
      return new CommissionDetail(properties);
    };

    /**
     * Encodes the specified CommissionDetail message. Does not implicitly {@link client_proto.CommissionDetail.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CommissionDetail
     * @static
     * @param {client_proto.ICommissionDetail} message CommissionDetail message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CommissionDetail.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.date_time != null && Object.hasOwnProperty.call(message, "date_time"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.date_time);
      if (message.commission != null && Object.hasOwnProperty.call(message, "commission"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.commission);
      if (message.details != null && Object.hasOwnProperty.call(message, "details"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.details);
      if (message.source_type != null && Object.hasOwnProperty.call(message, "source_type"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.source_type);
      return writer;
    };

    /**
     * Encodes the specified CommissionDetail message, length delimited. Does not implicitly {@link client_proto.CommissionDetail.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CommissionDetail
     * @static
     * @param {client_proto.ICommissionDetail} message CommissionDetail message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CommissionDetail.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CommissionDetail message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CommissionDetail
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CommissionDetail} CommissionDetail
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CommissionDetail.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CommissionDetail();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.date_time = reader.int64();
            break;
          }
          case 2: {
            message.commission = reader.int64();
            break;
          }
          case 3: {
            message.details = reader.string();
            break;
          }
          case 4: {
            message.source_type = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CommissionDetail message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CommissionDetail
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CommissionDetail} CommissionDetail
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CommissionDetail.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CommissionDetail message.
     * @function verify
     * @memberof client_proto.CommissionDetail
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CommissionDetail.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.date_time != null && message.hasOwnProperty("date_time"))
        if (
          !$util.isInteger(message.date_time) &&
          !(message.date_time && $util.isInteger(message.date_time.low) && $util.isInteger(message.date_time.high))
        )
          return "date_time: integer|Long expected";
      if (message.commission != null && message.hasOwnProperty("commission"))
        if (
          !$util.isInteger(message.commission) &&
          !(message.commission && $util.isInteger(message.commission.low) && $util.isInteger(message.commission.high))
        )
          return "commission: integer|Long expected";
      if (message.details != null && message.hasOwnProperty("details"))
        if (!$util.isString(message.details)) return "details: string expected";
      if (message.source_type != null && message.hasOwnProperty("source_type"))
        switch (message.source_type) {
          default:
            return "source_type: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
            break;
        }
      return null;
    };

    /**
     * Creates a CommissionDetail message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CommissionDetail
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CommissionDetail} CommissionDetail
     */
    CommissionDetail.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CommissionDetail) return object;
      var message = new $root.client_proto.CommissionDetail();
      if (object.date_time != null)
        if ($util.Long) (message.date_time = $util.Long.fromValue(object.date_time)).unsigned = false;
        else if (typeof object.date_time === "string") message.date_time = parseInt(object.date_time, 10);
        else if (typeof object.date_time === "number") message.date_time = object.date_time;
        else if (typeof object.date_time === "object")
          message.date_time = new $util.LongBits(object.date_time.low >>> 0, object.date_time.high >>> 0).toNumber();
      if (object.commission != null)
        if ($util.Long) (message.commission = $util.Long.fromValue(object.commission)).unsigned = false;
        else if (typeof object.commission === "string") message.commission = parseInt(object.commission, 10);
        else if (typeof object.commission === "number") message.commission = object.commission;
        else if (typeof object.commission === "object")
          message.commission = new $util.LongBits(object.commission.low >>> 0, object.commission.high >>> 0).toNumber();
      if (object.details != null) message.details = String(object.details);
      switch (object.source_type) {
        default:
          if (typeof object.source_type === "number") {
            message.source_type = object.source_type;
            break;
          }
          break;
        case "COMMISSION_SOURCE_TYPE_ALL":
        case 0:
          message.source_type = 0;
          break;
        case "COMMISSION_SOURCE_TYPE_BET":
        case 1:
          message.source_type = 1;
          break;
        case "COMMISSION_SOURCE_TYPE_DEPOSIT":
        case 2:
          message.source_type = 2;
          break;
        case "COMMISSION_SOURCE_TYPE_INVITE":
        case 3:
          message.source_type = 3;
          break;
        case "COMMISSION_SOURCE_TYPE_TASK":
        case 4:
          message.source_type = 4;
          break;
      }
      return message;
    };

    /**
     * Creates a plain object from a CommissionDetail message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CommissionDetail
     * @static
     * @param {client_proto.CommissionDetail} message CommissionDetail
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CommissionDetail.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.date_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.date_time = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.commission =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.commission = options.longs === String ? "0" : 0;
        object.details = "";
        object.source_type = options.enums === String ? "COMMISSION_SOURCE_TYPE_ALL" : 0;
      }
      if (message.date_time != null && message.hasOwnProperty("date_time"))
        if (typeof message.date_time === "number")
          object.date_time = options.longs === String ? String(message.date_time) : message.date_time;
        else
          object.date_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.date_time)
              : options.longs === Number
                ? new $util.LongBits(message.date_time.low >>> 0, message.date_time.high >>> 0).toNumber()
                : message.date_time;
      if (message.commission != null && message.hasOwnProperty("commission"))
        if (typeof message.commission === "number")
          object.commission = options.longs === String ? String(message.commission) : message.commission;
        else
          object.commission =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.commission)
              : options.longs === Number
                ? new $util.LongBits(message.commission.low >>> 0, message.commission.high >>> 0).toNumber()
                : message.commission;
      if (message.details != null && message.hasOwnProperty("details")) object.details = message.details;
      if (message.source_type != null && message.hasOwnProperty("source_type"))
        object.source_type =
          options.enums === String
            ? $root.client_proto.COMMISSION_SOURCE_TYPE[message.source_type] === undefined
              ? message.source_type
              : $root.client_proto.COMMISSION_SOURCE_TYPE[message.source_type]
            : message.source_type;
      return object;
    };

    /**
     * Converts this CommissionDetail to JSON.
     * @function toJSON
     * @memberof client_proto.CommissionDetail
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CommissionDetail.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CommissionDetail
     * @function getTypeUrl
     * @memberof client_proto.CommissionDetail
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CommissionDetail.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CommissionDetail";
    };

    return CommissionDetail;
  })();

  client_proto.GetCommissionFlowListResp = (function () {
    /**
     * Properties of a GetCommissionFlowListResp.
     * @memberof client_proto
     * @interface IGetCommissionFlowListResp
     * @property {Array.<client_proto.ICommissionDetail>|null} [commission_details] GetCommissionFlowListResp commission_details
     * @property {number|Long|null} [total_commission] GetCommissionFlowListResp total_commission
     * @property {number|null} [page_index] GetCommissionFlowListResp page_index
     */

    /**
     * Constructs a new GetCommissionFlowListResp.
     * @memberof client_proto
     * @classdesc Represents a GetCommissionFlowListResp.
     * @implements IGetCommissionFlowListResp
     * @constructor
     * @param {client_proto.IGetCommissionFlowListResp=} [properties] Properties to set
     */
    function GetCommissionFlowListResp(properties) {
      this.commission_details = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetCommissionFlowListResp commission_details.
     * @member {Array.<client_proto.ICommissionDetail>} commission_details
     * @memberof client_proto.GetCommissionFlowListResp
     * @instance
     */
    GetCommissionFlowListResp.prototype.commission_details = $util.emptyArray;

    /**
     * GetCommissionFlowListResp total_commission.
     * @member {number|Long} total_commission
     * @memberof client_proto.GetCommissionFlowListResp
     * @instance
     */
    GetCommissionFlowListResp.prototype.total_commission = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GetCommissionFlowListResp page_index.
     * @member {number} page_index
     * @memberof client_proto.GetCommissionFlowListResp
     * @instance
     */
    GetCommissionFlowListResp.prototype.page_index = 0;

    /**
     * Creates a new GetCommissionFlowListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetCommissionFlowListResp
     * @static
     * @param {client_proto.IGetCommissionFlowListResp=} [properties] Properties to set
     * @returns {client_proto.GetCommissionFlowListResp} GetCommissionFlowListResp instance
     */
    GetCommissionFlowListResp.create = function create(properties) {
      return new GetCommissionFlowListResp(properties);
    };

    /**
     * Encodes the specified GetCommissionFlowListResp message. Does not implicitly {@link client_proto.GetCommissionFlowListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetCommissionFlowListResp
     * @static
     * @param {client_proto.IGetCommissionFlowListResp} message GetCommissionFlowListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCommissionFlowListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.commission_details != null && message.commission_details.length)
        for (var i = 0; i < message.commission_details.length; ++i)
          $root.client_proto.CommissionDetail.encode(
            message.commission_details[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.total_commission != null && Object.hasOwnProperty.call(message, "total_commission"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.total_commission);
      if (message.page_index != null && Object.hasOwnProperty.call(message, "page_index"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.page_index);
      return writer;
    };

    /**
     * Encodes the specified GetCommissionFlowListResp message, length delimited. Does not implicitly {@link client_proto.GetCommissionFlowListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetCommissionFlowListResp
     * @static
     * @param {client_proto.IGetCommissionFlowListResp} message GetCommissionFlowListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetCommissionFlowListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetCommissionFlowListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetCommissionFlowListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetCommissionFlowListResp} GetCommissionFlowListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCommissionFlowListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetCommissionFlowListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.commission_details && message.commission_details.length)) message.commission_details = [];
            message.commission_details.push($root.client_proto.CommissionDetail.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.total_commission = reader.int64();
            break;
          }
          case 3: {
            message.page_index = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetCommissionFlowListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetCommissionFlowListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetCommissionFlowListResp} GetCommissionFlowListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetCommissionFlowListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetCommissionFlowListResp message.
     * @function verify
     * @memberof client_proto.GetCommissionFlowListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetCommissionFlowListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.commission_details != null && message.hasOwnProperty("commission_details")) {
        if (!Array.isArray(message.commission_details)) return "commission_details: array expected";
        for (var i = 0; i < message.commission_details.length; ++i) {
          var error = $root.client_proto.CommissionDetail.verify(message.commission_details[i]);
          if (error) return "commission_details." + error;
        }
      }
      if (message.total_commission != null && message.hasOwnProperty("total_commission"))
        if (
          !$util.isInteger(message.total_commission) &&
          !(
            message.total_commission &&
            $util.isInteger(message.total_commission.low) &&
            $util.isInteger(message.total_commission.high)
          )
        )
          return "total_commission: integer|Long expected";
      if (message.page_index != null && message.hasOwnProperty("page_index"))
        if (!$util.isInteger(message.page_index)) return "page_index: integer expected";
      return null;
    };

    /**
     * Creates a GetCommissionFlowListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetCommissionFlowListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetCommissionFlowListResp} GetCommissionFlowListResp
     */
    GetCommissionFlowListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetCommissionFlowListResp) return object;
      var message = new $root.client_proto.GetCommissionFlowListResp();
      if (object.commission_details) {
        if (!Array.isArray(object.commission_details))
          throw TypeError(".client_proto.GetCommissionFlowListResp.commission_details: array expected");
        message.commission_details = [];
        for (var i = 0; i < object.commission_details.length; ++i) {
          if (typeof object.commission_details[i] !== "object")
            throw TypeError(".client_proto.GetCommissionFlowListResp.commission_details: object expected");
          message.commission_details[i] = $root.client_proto.CommissionDetail.fromObject(object.commission_details[i]);
        }
      }
      if (object.total_commission != null)
        if ($util.Long) (message.total_commission = $util.Long.fromValue(object.total_commission)).unsigned = false;
        else if (typeof object.total_commission === "string")
          message.total_commission = parseInt(object.total_commission, 10);
        else if (typeof object.total_commission === "number") message.total_commission = object.total_commission;
        else if (typeof object.total_commission === "object")
          message.total_commission = new $util.LongBits(
            object.total_commission.low >>> 0,
            object.total_commission.high >>> 0,
          ).toNumber();
      if (object.page_index != null) message.page_index = object.page_index | 0;
      return message;
    };

    /**
     * Creates a plain object from a GetCommissionFlowListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetCommissionFlowListResp
     * @static
     * @param {client_proto.GetCommissionFlowListResp} message GetCommissionFlowListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetCommissionFlowListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.commission_details = [];
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_commission =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_commission = options.longs === String ? "0" : 0;
        object.page_index = 0;
      }
      if (message.commission_details && message.commission_details.length) {
        object.commission_details = [];
        for (var j = 0; j < message.commission_details.length; ++j)
          object.commission_details[j] = $root.client_proto.CommissionDetail.toObject(
            message.commission_details[j],
            options,
          );
      }
      if (message.total_commission != null && message.hasOwnProperty("total_commission"))
        if (typeof message.total_commission === "number")
          object.total_commission =
            options.longs === String ? String(message.total_commission) : message.total_commission;
        else
          object.total_commission =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_commission)
              : options.longs === Number
                ? new $util.LongBits(message.total_commission.low >>> 0, message.total_commission.high >>> 0).toNumber()
                : message.total_commission;
      if (message.page_index != null && message.hasOwnProperty("page_index")) object.page_index = message.page_index;
      return object;
    };

    /**
     * Converts this GetCommissionFlowListResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetCommissionFlowListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetCommissionFlowListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetCommissionFlowListResp
     * @function getTypeUrl
     * @memberof client_proto.GetCommissionFlowListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetCommissionFlowListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetCommissionFlowListResp";
    };

    return GetCommissionFlowListResp;
  })();

  client_proto.GetUserInviteReq = (function () {
    /**
     * Properties of a GetUserInviteReq.
     * @memberof client_proto
     * @interface IGetUserInviteReq
     */

    /**
     * Constructs a new GetUserInviteReq.
     * @memberof client_proto
     * @classdesc Represents a GetUserInviteReq.
     * @implements IGetUserInviteReq
     * @constructor
     * @param {client_proto.IGetUserInviteReq=} [properties] Properties to set
     */
    function GetUserInviteReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetUserInviteReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserInviteReq
     * @static
     * @param {client_proto.IGetUserInviteReq=} [properties] Properties to set
     * @returns {client_proto.GetUserInviteReq} GetUserInviteReq instance
     */
    GetUserInviteReq.create = function create(properties) {
      return new GetUserInviteReq(properties);
    };

    /**
     * Encodes the specified GetUserInviteReq message. Does not implicitly {@link client_proto.GetUserInviteReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserInviteReq
     * @static
     * @param {client_proto.IGetUserInviteReq} message GetUserInviteReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserInviteReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified GetUserInviteReq message, length delimited. Does not implicitly {@link client_proto.GetUserInviteReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserInviteReq
     * @static
     * @param {client_proto.IGetUserInviteReq} message GetUserInviteReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserInviteReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserInviteReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserInviteReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserInviteReq} GetUserInviteReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserInviteReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserInviteReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserInviteReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserInviteReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserInviteReq} GetUserInviteReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserInviteReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserInviteReq message.
     * @function verify
     * @memberof client_proto.GetUserInviteReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserInviteReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a GetUserInviteReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserInviteReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserInviteReq} GetUserInviteReq
     */
    GetUserInviteReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserInviteReq) return object;
      return new $root.client_proto.GetUserInviteReq();
    };

    /**
     * Creates a plain object from a GetUserInviteReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserInviteReq
     * @static
     * @param {client_proto.GetUserInviteReq} message GetUserInviteReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserInviteReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this GetUserInviteReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserInviteReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserInviteReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserInviteReq
     * @function getTypeUrl
     * @memberof client_proto.GetUserInviteReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserInviteReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserInviteReq";
    };

    return GetUserInviteReq;
  })();

  client_proto.GetUserInviteResp = (function () {
    /**
     * Properties of a GetUserInviteResp.
     * @memberof client_proto
     * @interface IGetUserInviteResp
     * @property {string|null} [invite_qrcode] GetUserInviteResp invite_qrcode
     * @property {client_proto.IUserInfo|null} [user_info] GetUserInviteResp user_info
     * @property {string|null} [invite_code] GetUserInviteResp invite_code
     * @property {Array.<client_proto.GetUserInviteResp.IPoster>|null} [poster] GetUserInviteResp poster
     * @property {string|null} [telegram] GetUserInviteResp telegram
     * @property {string|null} [whatsapp] GetUserInviteResp whatsapp
     * @property {string|null} [share_url] GetUserInviteResp share_url
     */

    /**
     * Constructs a new GetUserInviteResp.
     * @memberof client_proto
     * @classdesc Represents a GetUserInviteResp.
     * @implements IGetUserInviteResp
     * @constructor
     * @param {client_proto.IGetUserInviteResp=} [properties] Properties to set
     */
    function GetUserInviteResp(properties) {
      this.poster = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserInviteResp invite_qrcode.
     * @member {string} invite_qrcode
     * @memberof client_proto.GetUserInviteResp
     * @instance
     */
    GetUserInviteResp.prototype.invite_qrcode = "";

    /**
     * GetUserInviteResp user_info.
     * @member {client_proto.IUserInfo|null|undefined} user_info
     * @memberof client_proto.GetUserInviteResp
     * @instance
     */
    GetUserInviteResp.prototype.user_info = null;

    /**
     * GetUserInviteResp invite_code.
     * @member {string} invite_code
     * @memberof client_proto.GetUserInviteResp
     * @instance
     */
    GetUserInviteResp.prototype.invite_code = "";

    /**
     * GetUserInviteResp poster.
     * @member {Array.<client_proto.GetUserInviteResp.IPoster>} poster
     * @memberof client_proto.GetUserInviteResp
     * @instance
     */
    GetUserInviteResp.prototype.poster = $util.emptyArray;

    /**
     * GetUserInviteResp telegram.
     * @member {string} telegram
     * @memberof client_proto.GetUserInviteResp
     * @instance
     */
    GetUserInviteResp.prototype.telegram = "";

    /**
     * GetUserInviteResp whatsapp.
     * @member {string} whatsapp
     * @memberof client_proto.GetUserInviteResp
     * @instance
     */
    GetUserInviteResp.prototype.whatsapp = "";

    /**
     * GetUserInviteResp share_url.
     * @member {string} share_url
     * @memberof client_proto.GetUserInviteResp
     * @instance
     */
    GetUserInviteResp.prototype.share_url = "";

    /**
     * Creates a new GetUserInviteResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserInviteResp
     * @static
     * @param {client_proto.IGetUserInviteResp=} [properties] Properties to set
     * @returns {client_proto.GetUserInviteResp} GetUserInviteResp instance
     */
    GetUserInviteResp.create = function create(properties) {
      return new GetUserInviteResp(properties);
    };

    /**
     * Encodes the specified GetUserInviteResp message. Does not implicitly {@link client_proto.GetUserInviteResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserInviteResp
     * @static
     * @param {client_proto.IGetUserInviteResp} message GetUserInviteResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserInviteResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.invite_qrcode != null && Object.hasOwnProperty.call(message, "invite_qrcode"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.invite_qrcode);
      if (message.user_info != null && Object.hasOwnProperty.call(message, "user_info"))
        $root.client_proto.UserInfo.encode(
          message.user_info,
          writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
        ).ldelim();
      if (message.invite_code != null && Object.hasOwnProperty.call(message, "invite_code"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.invite_code);
      if (message.poster != null && message.poster.length)
        for (var i = 0; i < message.poster.length; ++i)
          $root.client_proto.GetUserInviteResp.Poster.encode(
            message.poster[i],
            writer.uint32(/* id 4, wireType 2 =*/ 34).fork(),
          ).ldelim();
      if (message.telegram != null && Object.hasOwnProperty.call(message, "telegram"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.telegram);
      if (message.whatsapp != null && Object.hasOwnProperty.call(message, "whatsapp"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.whatsapp);
      if (message.share_url != null && Object.hasOwnProperty.call(message, "share_url"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.share_url);
      return writer;
    };

    /**
     * Encodes the specified GetUserInviteResp message, length delimited. Does not implicitly {@link client_proto.GetUserInviteResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserInviteResp
     * @static
     * @param {client_proto.IGetUserInviteResp} message GetUserInviteResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserInviteResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserInviteResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserInviteResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserInviteResp} GetUserInviteResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserInviteResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserInviteResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.invite_qrcode = reader.string();
            break;
          }
          case 2: {
            message.user_info = $root.client_proto.UserInfo.decode(reader, reader.uint32());
            break;
          }
          case 3: {
            message.invite_code = reader.string();
            break;
          }
          case 4: {
            if (!(message.poster && message.poster.length)) message.poster = [];
            message.poster.push($root.client_proto.GetUserInviteResp.Poster.decode(reader, reader.uint32()));
            break;
          }
          case 5: {
            message.telegram = reader.string();
            break;
          }
          case 6: {
            message.whatsapp = reader.string();
            break;
          }
          case 7: {
            message.share_url = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserInviteResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserInviteResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserInviteResp} GetUserInviteResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserInviteResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserInviteResp message.
     * @function verify
     * @memberof client_proto.GetUserInviteResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserInviteResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.invite_qrcode != null && message.hasOwnProperty("invite_qrcode"))
        if (!$util.isString(message.invite_qrcode)) return "invite_qrcode: string expected";
      if (message.user_info != null && message.hasOwnProperty("user_info")) {
        var error = $root.client_proto.UserInfo.verify(message.user_info);
        if (error) return "user_info." + error;
      }
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        if (!$util.isString(message.invite_code)) return "invite_code: string expected";
      if (message.poster != null && message.hasOwnProperty("poster")) {
        if (!Array.isArray(message.poster)) return "poster: array expected";
        for (var i = 0; i < message.poster.length; ++i) {
          var error = $root.client_proto.GetUserInviteResp.Poster.verify(message.poster[i]);
          if (error) return "poster." + error;
        }
      }
      if (message.telegram != null && message.hasOwnProperty("telegram"))
        if (!$util.isString(message.telegram)) return "telegram: string expected";
      if (message.whatsapp != null && message.hasOwnProperty("whatsapp"))
        if (!$util.isString(message.whatsapp)) return "whatsapp: string expected";
      if (message.share_url != null && message.hasOwnProperty("share_url"))
        if (!$util.isString(message.share_url)) return "share_url: string expected";
      return null;
    };

    /**
     * Creates a GetUserInviteResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserInviteResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserInviteResp} GetUserInviteResp
     */
    GetUserInviteResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserInviteResp) return object;
      var message = new $root.client_proto.GetUserInviteResp();
      if (object.invite_qrcode != null) message.invite_qrcode = String(object.invite_qrcode);
      if (object.user_info != null) {
        if (typeof object.user_info !== "object")
          throw TypeError(".client_proto.GetUserInviteResp.user_info: object expected");
        message.user_info = $root.client_proto.UserInfo.fromObject(object.user_info);
      }
      if (object.invite_code != null) message.invite_code = String(object.invite_code);
      if (object.poster) {
        if (!Array.isArray(object.poster)) throw TypeError(".client_proto.GetUserInviteResp.poster: array expected");
        message.poster = [];
        for (var i = 0; i < object.poster.length; ++i) {
          if (typeof object.poster[i] !== "object")
            throw TypeError(".client_proto.GetUserInviteResp.poster: object expected");
          message.poster[i] = $root.client_proto.GetUserInviteResp.Poster.fromObject(object.poster[i]);
        }
      }
      if (object.telegram != null) message.telegram = String(object.telegram);
      if (object.whatsapp != null) message.whatsapp = String(object.whatsapp);
      if (object.share_url != null) message.share_url = String(object.share_url);
      return message;
    };

    /**
     * Creates a plain object from a GetUserInviteResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserInviteResp
     * @static
     * @param {client_proto.GetUserInviteResp} message GetUserInviteResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserInviteResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.poster = [];
      if (options.defaults) {
        object.invite_qrcode = "";
        object.user_info = null;
        object.invite_code = "";
        object.telegram = "";
        object.whatsapp = "";
        object.share_url = "";
      }
      if (message.invite_qrcode != null && message.hasOwnProperty("invite_qrcode"))
        object.invite_qrcode = message.invite_qrcode;
      if (message.user_info != null && message.hasOwnProperty("user_info"))
        object.user_info = $root.client_proto.UserInfo.toObject(message.user_info, options);
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        object.invite_code = message.invite_code;
      if (message.poster && message.poster.length) {
        object.poster = [];
        for (var j = 0; j < message.poster.length; ++j)
          object.poster[j] = $root.client_proto.GetUserInviteResp.Poster.toObject(message.poster[j], options);
      }
      if (message.telegram != null && message.hasOwnProperty("telegram")) object.telegram = message.telegram;
      if (message.whatsapp != null && message.hasOwnProperty("whatsapp")) object.whatsapp = message.whatsapp;
      if (message.share_url != null && message.hasOwnProperty("share_url")) object.share_url = message.share_url;
      return object;
    };

    /**
     * Converts this GetUserInviteResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserInviteResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserInviteResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserInviteResp
     * @function getTypeUrl
     * @memberof client_proto.GetUserInviteResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserInviteResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserInviteResp";
    };

    GetUserInviteResp.Poster = (function () {
      /**
       * Properties of a Poster.
       * @memberof client_proto.GetUserInviteResp
       * @interface IPoster
       * @property {number|null} [id] Poster id
       * @property {string|null} [url] Poster url
       */

      /**
       * Constructs a new Poster.
       * @memberof client_proto.GetUserInviteResp
       * @classdesc Represents a Poster.
       * @implements IPoster
       * @constructor
       * @param {client_proto.GetUserInviteResp.IPoster=} [properties] Properties to set
       */
      function Poster(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }

      /**
       * Poster id.
       * @member {number} id
       * @memberof client_proto.GetUserInviteResp.Poster
       * @instance
       */
      Poster.prototype.id = 0;

      /**
       * Poster url.
       * @member {string} url
       * @memberof client_proto.GetUserInviteResp.Poster
       * @instance
       */
      Poster.prototype.url = "";

      /**
       * Creates a new Poster instance using the specified properties.
       * @function create
       * @memberof client_proto.GetUserInviteResp.Poster
       * @static
       * @param {client_proto.GetUserInviteResp.IPoster=} [properties] Properties to set
       * @returns {client_proto.GetUserInviteResp.Poster} Poster instance
       */
      Poster.create = function create(properties) {
        return new Poster(properties);
      };

      /**
       * Encodes the specified Poster message. Does not implicitly {@link client_proto.GetUserInviteResp.Poster.verify|verify} messages.
       * @function encode
       * @memberof client_proto.GetUserInviteResp.Poster
       * @static
       * @param {client_proto.GetUserInviteResp.IPoster} message Poster message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Poster.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
          writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
        if (message.url != null && Object.hasOwnProperty.call(message, "url"))
          writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.url);
        return writer;
      };

      /**
       * Encodes the specified Poster message, length delimited. Does not implicitly {@link client_proto.GetUserInviteResp.Poster.verify|verify} messages.
       * @function encodeDelimited
       * @memberof client_proto.GetUserInviteResp.Poster
       * @static
       * @param {client_proto.GetUserInviteResp.IPoster} message Poster message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Poster.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a Poster message from the specified reader or buffer.
       * @function decode
       * @memberof client_proto.GetUserInviteResp.Poster
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {client_proto.GetUserInviteResp.Poster} Poster
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Poster.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.client_proto.GetUserInviteResp.Poster();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.id = reader.int32();
              break;
            }
            case 2: {
              message.url = reader.string();
              break;
            }
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a Poster message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof client_proto.GetUserInviteResp.Poster
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {client_proto.GetUserInviteResp.Poster} Poster
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Poster.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a Poster message.
       * @function verify
       * @memberof client_proto.GetUserInviteResp.Poster
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      Poster.verify = function verify(message) {
        if (typeof message !== "object" || message === null) return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
          if (!$util.isInteger(message.id)) return "id: integer expected";
        if (message.url != null && message.hasOwnProperty("url"))
          if (!$util.isString(message.url)) return "url: string expected";
        return null;
      };

      /**
       * Creates a Poster message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof client_proto.GetUserInviteResp.Poster
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {client_proto.GetUserInviteResp.Poster} Poster
       */
      Poster.fromObject = function fromObject(object) {
        if (object instanceof $root.client_proto.GetUserInviteResp.Poster) return object;
        var message = new $root.client_proto.GetUserInviteResp.Poster();
        if (object.id != null) message.id = object.id | 0;
        if (object.url != null) message.url = String(object.url);
        return message;
      };

      /**
       * Creates a plain object from a Poster message. Also converts values to other types if specified.
       * @function toObject
       * @memberof client_proto.GetUserInviteResp.Poster
       * @static
       * @param {client_proto.GetUserInviteResp.Poster} message Poster
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      Poster.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.id = 0;
          object.url = "";
        }
        if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
        if (message.url != null && message.hasOwnProperty("url")) object.url = message.url;
        return object;
      };

      /**
       * Converts this Poster to JSON.
       * @function toJSON
       * @memberof client_proto.GetUserInviteResp.Poster
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      Poster.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for Poster
       * @function getTypeUrl
       * @memberof client_proto.GetUserInviteResp.Poster
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      Poster.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/client_proto.GetUserInviteResp.Poster";
      };

      return Poster;
    })();

    return GetUserInviteResp;
  })();

  client_proto.PushServerInviteReq = (function () {
    /**
     * Properties of a PushServerInviteReq.
     * @memberof client_proto
     * @interface IPushServerInviteReq
     * @property {number|null} [f_uid] PushServerInviteReq f_uid
     * @property {number|null} [s_uid] PushServerInviteReq s_uid
     * @property {string|null} [source] PushServerInviteReq source
     */

    /**
     * Constructs a new PushServerInviteReq.
     * @memberof client_proto
     * @classdesc Represents a PushServerInviteReq.
     * @implements IPushServerInviteReq
     * @constructor
     * @param {client_proto.IPushServerInviteReq=} [properties] Properties to set
     */
    function PushServerInviteReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PushServerInviteReq f_uid.
     * @member {number} f_uid
     * @memberof client_proto.PushServerInviteReq
     * @instance
     */
    PushServerInviteReq.prototype.f_uid = 0;

    /**
     * PushServerInviteReq s_uid.
     * @member {number} s_uid
     * @memberof client_proto.PushServerInviteReq
     * @instance
     */
    PushServerInviteReq.prototype.s_uid = 0;

    /**
     * PushServerInviteReq source.
     * @member {string} source
     * @memberof client_proto.PushServerInviteReq
     * @instance
     */
    PushServerInviteReq.prototype.source = "";

    /**
     * Creates a new PushServerInviteReq instance using the specified properties.
     * @function create
     * @memberof client_proto.PushServerInviteReq
     * @static
     * @param {client_proto.IPushServerInviteReq=} [properties] Properties to set
     * @returns {client_proto.PushServerInviteReq} PushServerInviteReq instance
     */
    PushServerInviteReq.create = function create(properties) {
      return new PushServerInviteReq(properties);
    };

    /**
     * Encodes the specified PushServerInviteReq message. Does not implicitly {@link client_proto.PushServerInviteReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PushServerInviteReq
     * @static
     * @param {client_proto.IPushServerInviteReq} message PushServerInviteReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushServerInviteReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.f_uid != null && Object.hasOwnProperty.call(message, "f_uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.f_uid);
      if (message.s_uid != null && Object.hasOwnProperty.call(message, "s_uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.s_uid);
      if (message.source != null && Object.hasOwnProperty.call(message, "source"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.source);
      return writer;
    };

    /**
     * Encodes the specified PushServerInviteReq message, length delimited. Does not implicitly {@link client_proto.PushServerInviteReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PushServerInviteReq
     * @static
     * @param {client_proto.IPushServerInviteReq} message PushServerInviteReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushServerInviteReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PushServerInviteReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PushServerInviteReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PushServerInviteReq} PushServerInviteReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushServerInviteReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PushServerInviteReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.f_uid = reader.uint32();
            break;
          }
          case 2: {
            message.s_uid = reader.uint32();
            break;
          }
          case 3: {
            message.source = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PushServerInviteReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PushServerInviteReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PushServerInviteReq} PushServerInviteReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushServerInviteReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PushServerInviteReq message.
     * @function verify
     * @memberof client_proto.PushServerInviteReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PushServerInviteReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.f_uid != null && message.hasOwnProperty("f_uid"))
        if (!$util.isInteger(message.f_uid)) return "f_uid: integer expected";
      if (message.s_uid != null && message.hasOwnProperty("s_uid"))
        if (!$util.isInteger(message.s_uid)) return "s_uid: integer expected";
      if (message.source != null && message.hasOwnProperty("source"))
        if (!$util.isString(message.source)) return "source: string expected";
      return null;
    };

    /**
     * Creates a PushServerInviteReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PushServerInviteReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PushServerInviteReq} PushServerInviteReq
     */
    PushServerInviteReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PushServerInviteReq) return object;
      var message = new $root.client_proto.PushServerInviteReq();
      if (object.f_uid != null) message.f_uid = object.f_uid >>> 0;
      if (object.s_uid != null) message.s_uid = object.s_uid >>> 0;
      if (object.source != null) message.source = String(object.source);
      return message;
    };

    /**
     * Creates a plain object from a PushServerInviteReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PushServerInviteReq
     * @static
     * @param {client_proto.PushServerInviteReq} message PushServerInviteReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PushServerInviteReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.f_uid = 0;
        object.s_uid = 0;
        object.source = "";
      }
      if (message.f_uid != null && message.hasOwnProperty("f_uid")) object.f_uid = message.f_uid;
      if (message.s_uid != null && message.hasOwnProperty("s_uid")) object.s_uid = message.s_uid;
      if (message.source != null && message.hasOwnProperty("source")) object.source = message.source;
      return object;
    };

    /**
     * Converts this PushServerInviteReq to JSON.
     * @function toJSON
     * @memberof client_proto.PushServerInviteReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PushServerInviteReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PushServerInviteReq
     * @function getTypeUrl
     * @memberof client_proto.PushServerInviteReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PushServerInviteReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PushServerInviteReq";
    };

    return PushServerInviteReq;
  })();

  client_proto.PushServerCommissionReq = (function () {
    /**
     * Properties of a PushServerCommissionReq.
     * @memberof client_proto
     * @interface IPushServerCommissionReq
     * @property {number|null} [f_uid] PushServerCommissionReq f_uid
     * @property {number|null} [s_uid] PushServerCommissionReq s_uid
     * @property {client_proto.COMMISSION_SOURCE_TYPE|null} [source_type] PushServerCommissionReq source_type
     * @property {number|Long|null} [commission] PushServerCommissionReq commission
     */

    /**
     * Constructs a new PushServerCommissionReq.
     * @memberof client_proto
     * @classdesc Represents a PushServerCommissionReq.
     * @implements IPushServerCommissionReq
     * @constructor
     * @param {client_proto.IPushServerCommissionReq=} [properties] Properties to set
     */
    function PushServerCommissionReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PushServerCommissionReq f_uid.
     * @member {number} f_uid
     * @memberof client_proto.PushServerCommissionReq
     * @instance
     */
    PushServerCommissionReq.prototype.f_uid = 0;

    /**
     * PushServerCommissionReq s_uid.
     * @member {number} s_uid
     * @memberof client_proto.PushServerCommissionReq
     * @instance
     */
    PushServerCommissionReq.prototype.s_uid = 0;

    /**
     * PushServerCommissionReq source_type.
     * @member {client_proto.COMMISSION_SOURCE_TYPE} source_type
     * @memberof client_proto.PushServerCommissionReq
     * @instance
     */
    PushServerCommissionReq.prototype.source_type = 0;

    /**
     * PushServerCommissionReq commission.
     * @member {number|Long} commission
     * @memberof client_proto.PushServerCommissionReq
     * @instance
     */
    PushServerCommissionReq.prototype.commission = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new PushServerCommissionReq instance using the specified properties.
     * @function create
     * @memberof client_proto.PushServerCommissionReq
     * @static
     * @param {client_proto.IPushServerCommissionReq=} [properties] Properties to set
     * @returns {client_proto.PushServerCommissionReq} PushServerCommissionReq instance
     */
    PushServerCommissionReq.create = function create(properties) {
      return new PushServerCommissionReq(properties);
    };

    /**
     * Encodes the specified PushServerCommissionReq message. Does not implicitly {@link client_proto.PushServerCommissionReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PushServerCommissionReq
     * @static
     * @param {client_proto.IPushServerCommissionReq} message PushServerCommissionReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushServerCommissionReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.f_uid != null && Object.hasOwnProperty.call(message, "f_uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.f_uid);
      if (message.s_uid != null && Object.hasOwnProperty.call(message, "s_uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.s_uid);
      if (message.source_type != null && Object.hasOwnProperty.call(message, "source_type"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.source_type);
      if (message.commission != null && Object.hasOwnProperty.call(message, "commission"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.commission);
      return writer;
    };

    /**
     * Encodes the specified PushServerCommissionReq message, length delimited. Does not implicitly {@link client_proto.PushServerCommissionReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PushServerCommissionReq
     * @static
     * @param {client_proto.IPushServerCommissionReq} message PushServerCommissionReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushServerCommissionReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PushServerCommissionReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PushServerCommissionReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PushServerCommissionReq} PushServerCommissionReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushServerCommissionReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PushServerCommissionReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.f_uid = reader.uint32();
            break;
          }
          case 2: {
            message.s_uid = reader.uint32();
            break;
          }
          case 3: {
            message.source_type = reader.int32();
            break;
          }
          case 4: {
            message.commission = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PushServerCommissionReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PushServerCommissionReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PushServerCommissionReq} PushServerCommissionReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushServerCommissionReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PushServerCommissionReq message.
     * @function verify
     * @memberof client_proto.PushServerCommissionReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PushServerCommissionReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.f_uid != null && message.hasOwnProperty("f_uid"))
        if (!$util.isInteger(message.f_uid)) return "f_uid: integer expected";
      if (message.s_uid != null && message.hasOwnProperty("s_uid"))
        if (!$util.isInteger(message.s_uid)) return "s_uid: integer expected";
      if (message.source_type != null && message.hasOwnProperty("source_type"))
        switch (message.source_type) {
          default:
            return "source_type: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
            break;
        }
      if (message.commission != null && message.hasOwnProperty("commission"))
        if (
          !$util.isInteger(message.commission) &&
          !(message.commission && $util.isInteger(message.commission.low) && $util.isInteger(message.commission.high))
        )
          return "commission: integer|Long expected";
      return null;
    };

    /**
     * Creates a PushServerCommissionReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PushServerCommissionReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PushServerCommissionReq} PushServerCommissionReq
     */
    PushServerCommissionReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PushServerCommissionReq) return object;
      var message = new $root.client_proto.PushServerCommissionReq();
      if (object.f_uid != null) message.f_uid = object.f_uid >>> 0;
      if (object.s_uid != null) message.s_uid = object.s_uid >>> 0;
      switch (object.source_type) {
        default:
          if (typeof object.source_type === "number") {
            message.source_type = object.source_type;
            break;
          }
          break;
        case "COMMISSION_SOURCE_TYPE_ALL":
        case 0:
          message.source_type = 0;
          break;
        case "COMMISSION_SOURCE_TYPE_BET":
        case 1:
          message.source_type = 1;
          break;
        case "COMMISSION_SOURCE_TYPE_DEPOSIT":
        case 2:
          message.source_type = 2;
          break;
        case "COMMISSION_SOURCE_TYPE_INVITE":
        case 3:
          message.source_type = 3;
          break;
        case "COMMISSION_SOURCE_TYPE_TASK":
        case 4:
          message.source_type = 4;
          break;
      }
      if (object.commission != null)
        if ($util.Long) (message.commission = $util.Long.fromValue(object.commission)).unsigned = false;
        else if (typeof object.commission === "string") message.commission = parseInt(object.commission, 10);
        else if (typeof object.commission === "number") message.commission = object.commission;
        else if (typeof object.commission === "object")
          message.commission = new $util.LongBits(object.commission.low >>> 0, object.commission.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a PushServerCommissionReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PushServerCommissionReq
     * @static
     * @param {client_proto.PushServerCommissionReq} message PushServerCommissionReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PushServerCommissionReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.f_uid = 0;
        object.s_uid = 0;
        object.source_type = options.enums === String ? "COMMISSION_SOURCE_TYPE_ALL" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.commission =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.commission = options.longs === String ? "0" : 0;
      }
      if (message.f_uid != null && message.hasOwnProperty("f_uid")) object.f_uid = message.f_uid;
      if (message.s_uid != null && message.hasOwnProperty("s_uid")) object.s_uid = message.s_uid;
      if (message.source_type != null && message.hasOwnProperty("source_type"))
        object.source_type =
          options.enums === String
            ? $root.client_proto.COMMISSION_SOURCE_TYPE[message.source_type] === undefined
              ? message.source_type
              : $root.client_proto.COMMISSION_SOURCE_TYPE[message.source_type]
            : message.source_type;
      if (message.commission != null && message.hasOwnProperty("commission"))
        if (typeof message.commission === "number")
          object.commission = options.longs === String ? String(message.commission) : message.commission;
        else
          object.commission =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.commission)
              : options.longs === Number
                ? new $util.LongBits(message.commission.low >>> 0, message.commission.high >>> 0).toNumber()
                : message.commission;
      return object;
    };

    /**
     * Converts this PushServerCommissionReq to JSON.
     * @function toJSON
     * @memberof client_proto.PushServerCommissionReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PushServerCommissionReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PushServerCommissionReq
     * @function getTypeUrl
     * @memberof client_proto.PushServerCommissionReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PushServerCommissionReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PushServerCommissionReq";
    };

    return PushServerCommissionReq;
  })();

  client_proto.PushServerDepositReq = (function () {
    /**
     * Properties of a PushServerDepositReq.
     * @memberof client_proto
     * @interface IPushServerDepositReq
     * @property {number|null} [s_uid] PushServerDepositReq s_uid
     * @property {number|Long|null} [deposit_amount] PushServerDepositReq deposit_amount
     * @property {number|Long|null} [total_amount] PushServerDepositReq total_amount
     */

    /**
     * Constructs a new PushServerDepositReq.
     * @memberof client_proto
     * @classdesc Represents a PushServerDepositReq.
     * @implements IPushServerDepositReq
     * @constructor
     * @param {client_proto.IPushServerDepositReq=} [properties] Properties to set
     */
    function PushServerDepositReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PushServerDepositReq s_uid.
     * @member {number} s_uid
     * @memberof client_proto.PushServerDepositReq
     * @instance
     */
    PushServerDepositReq.prototype.s_uid = 0;

    /**
     * PushServerDepositReq deposit_amount.
     * @member {number|Long} deposit_amount
     * @memberof client_proto.PushServerDepositReq
     * @instance
     */
    PushServerDepositReq.prototype.deposit_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PushServerDepositReq total_amount.
     * @member {number|Long} total_amount
     * @memberof client_proto.PushServerDepositReq
     * @instance
     */
    PushServerDepositReq.prototype.total_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new PushServerDepositReq instance using the specified properties.
     * @function create
     * @memberof client_proto.PushServerDepositReq
     * @static
     * @param {client_proto.IPushServerDepositReq=} [properties] Properties to set
     * @returns {client_proto.PushServerDepositReq} PushServerDepositReq instance
     */
    PushServerDepositReq.create = function create(properties) {
      return new PushServerDepositReq(properties);
    };

    /**
     * Encodes the specified PushServerDepositReq message. Does not implicitly {@link client_proto.PushServerDepositReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PushServerDepositReq
     * @static
     * @param {client_proto.IPushServerDepositReq} message PushServerDepositReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushServerDepositReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.s_uid != null && Object.hasOwnProperty.call(message, "s_uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.s_uid);
      if (message.deposit_amount != null && Object.hasOwnProperty.call(message, "deposit_amount"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.deposit_amount);
      if (message.total_amount != null && Object.hasOwnProperty.call(message, "total_amount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.total_amount);
      return writer;
    };

    /**
     * Encodes the specified PushServerDepositReq message, length delimited. Does not implicitly {@link client_proto.PushServerDepositReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PushServerDepositReq
     * @static
     * @param {client_proto.IPushServerDepositReq} message PushServerDepositReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushServerDepositReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PushServerDepositReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PushServerDepositReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PushServerDepositReq} PushServerDepositReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushServerDepositReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PushServerDepositReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.s_uid = reader.uint32();
            break;
          }
          case 2: {
            message.deposit_amount = reader.int64();
            break;
          }
          case 3: {
            message.total_amount = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PushServerDepositReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PushServerDepositReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PushServerDepositReq} PushServerDepositReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushServerDepositReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PushServerDepositReq message.
     * @function verify
     * @memberof client_proto.PushServerDepositReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PushServerDepositReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.s_uid != null && message.hasOwnProperty("s_uid"))
        if (!$util.isInteger(message.s_uid)) return "s_uid: integer expected";
      if (message.deposit_amount != null && message.hasOwnProperty("deposit_amount"))
        if (
          !$util.isInteger(message.deposit_amount) &&
          !(
            message.deposit_amount &&
            $util.isInteger(message.deposit_amount.low) &&
            $util.isInteger(message.deposit_amount.high)
          )
        )
          return "deposit_amount: integer|Long expected";
      if (message.total_amount != null && message.hasOwnProperty("total_amount"))
        if (
          !$util.isInteger(message.total_amount) &&
          !(
            message.total_amount &&
            $util.isInteger(message.total_amount.low) &&
            $util.isInteger(message.total_amount.high)
          )
        )
          return "total_amount: integer|Long expected";
      return null;
    };

    /**
     * Creates a PushServerDepositReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PushServerDepositReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PushServerDepositReq} PushServerDepositReq
     */
    PushServerDepositReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PushServerDepositReq) return object;
      var message = new $root.client_proto.PushServerDepositReq();
      if (object.s_uid != null) message.s_uid = object.s_uid >>> 0;
      if (object.deposit_amount != null)
        if ($util.Long) (message.deposit_amount = $util.Long.fromValue(object.deposit_amount)).unsigned = false;
        else if (typeof object.deposit_amount === "string")
          message.deposit_amount = parseInt(object.deposit_amount, 10);
        else if (typeof object.deposit_amount === "number") message.deposit_amount = object.deposit_amount;
        else if (typeof object.deposit_amount === "object")
          message.deposit_amount = new $util.LongBits(
            object.deposit_amount.low >>> 0,
            object.deposit_amount.high >>> 0,
          ).toNumber();
      if (object.total_amount != null)
        if ($util.Long) (message.total_amount = $util.Long.fromValue(object.total_amount)).unsigned = false;
        else if (typeof object.total_amount === "string") message.total_amount = parseInt(object.total_amount, 10);
        else if (typeof object.total_amount === "number") message.total_amount = object.total_amount;
        else if (typeof object.total_amount === "object")
          message.total_amount = new $util.LongBits(
            object.total_amount.low >>> 0,
            object.total_amount.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a PushServerDepositReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PushServerDepositReq
     * @static
     * @param {client_proto.PushServerDepositReq} message PushServerDepositReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PushServerDepositReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.s_uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.deposit_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.deposit_amount = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_amount = options.longs === String ? "0" : 0;
      }
      if (message.s_uid != null && message.hasOwnProperty("s_uid")) object.s_uid = message.s_uid;
      if (message.deposit_amount != null && message.hasOwnProperty("deposit_amount"))
        if (typeof message.deposit_amount === "number")
          object.deposit_amount = options.longs === String ? String(message.deposit_amount) : message.deposit_amount;
        else
          object.deposit_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.deposit_amount)
              : options.longs === Number
                ? new $util.LongBits(message.deposit_amount.low >>> 0, message.deposit_amount.high >>> 0).toNumber()
                : message.deposit_amount;
      if (message.total_amount != null && message.hasOwnProperty("total_amount"))
        if (typeof message.total_amount === "number")
          object.total_amount = options.longs === String ? String(message.total_amount) : message.total_amount;
        else
          object.total_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_amount)
              : options.longs === Number
                ? new $util.LongBits(message.total_amount.low >>> 0, message.total_amount.high >>> 0).toNumber()
                : message.total_amount;
      return object;
    };

    /**
     * Converts this PushServerDepositReq to JSON.
     * @function toJSON
     * @memberof client_proto.PushServerDepositReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PushServerDepositReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PushServerDepositReq
     * @function getTypeUrl
     * @memberof client_proto.PushServerDepositReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PushServerDepositReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PushServerDepositReq";
    };

    return PushServerDepositReq;
  })();

  client_proto.PushServerBetReq = (function () {
    /**
     * Properties of a PushServerBetReq.
     * @memberof client_proto
     * @interface IPushServerBetReq
     * @property {number|null} [s_uid] PushServerBetReq s_uid
     * @property {number|Long|null} [bet_amount] PushServerBetReq bet_amount
     * @property {number|Long|null} [valid_flow] PushServerBetReq valid_flow
     */

    /**
     * Constructs a new PushServerBetReq.
     * @memberof client_proto
     * @classdesc Represents a PushServerBetReq.
     * @implements IPushServerBetReq
     * @constructor
     * @param {client_proto.IPushServerBetReq=} [properties] Properties to set
     */
    function PushServerBetReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PushServerBetReq s_uid.
     * @member {number} s_uid
     * @memberof client_proto.PushServerBetReq
     * @instance
     */
    PushServerBetReq.prototype.s_uid = 0;

    /**
     * PushServerBetReq bet_amount.
     * @member {number|Long} bet_amount
     * @memberof client_proto.PushServerBetReq
     * @instance
     */
    PushServerBetReq.prototype.bet_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * PushServerBetReq valid_flow.
     * @member {number|Long} valid_flow
     * @memberof client_proto.PushServerBetReq
     * @instance
     */
    PushServerBetReq.prototype.valid_flow = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new PushServerBetReq instance using the specified properties.
     * @function create
     * @memberof client_proto.PushServerBetReq
     * @static
     * @param {client_proto.IPushServerBetReq=} [properties] Properties to set
     * @returns {client_proto.PushServerBetReq} PushServerBetReq instance
     */
    PushServerBetReq.create = function create(properties) {
      return new PushServerBetReq(properties);
    };

    /**
     * Encodes the specified PushServerBetReq message. Does not implicitly {@link client_proto.PushServerBetReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PushServerBetReq
     * @static
     * @param {client_proto.IPushServerBetReq} message PushServerBetReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushServerBetReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.s_uid != null && Object.hasOwnProperty.call(message, "s_uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.s_uid);
      if (message.bet_amount != null && Object.hasOwnProperty.call(message, "bet_amount"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.bet_amount);
      if (message.valid_flow != null && Object.hasOwnProperty.call(message, "valid_flow"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.valid_flow);
      return writer;
    };

    /**
     * Encodes the specified PushServerBetReq message, length delimited. Does not implicitly {@link client_proto.PushServerBetReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PushServerBetReq
     * @static
     * @param {client_proto.IPushServerBetReq} message PushServerBetReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushServerBetReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PushServerBetReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PushServerBetReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PushServerBetReq} PushServerBetReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushServerBetReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PushServerBetReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.s_uid = reader.uint32();
            break;
          }
          case 2: {
            message.bet_amount = reader.int64();
            break;
          }
          case 3: {
            message.valid_flow = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PushServerBetReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PushServerBetReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PushServerBetReq} PushServerBetReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushServerBetReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PushServerBetReq message.
     * @function verify
     * @memberof client_proto.PushServerBetReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PushServerBetReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.s_uid != null && message.hasOwnProperty("s_uid"))
        if (!$util.isInteger(message.s_uid)) return "s_uid: integer expected";
      if (message.bet_amount != null && message.hasOwnProperty("bet_amount"))
        if (
          !$util.isInteger(message.bet_amount) &&
          !(message.bet_amount && $util.isInteger(message.bet_amount.low) && $util.isInteger(message.bet_amount.high))
        )
          return "bet_amount: integer|Long expected";
      if (message.valid_flow != null && message.hasOwnProperty("valid_flow"))
        if (
          !$util.isInteger(message.valid_flow) &&
          !(message.valid_flow && $util.isInteger(message.valid_flow.low) && $util.isInteger(message.valid_flow.high))
        )
          return "valid_flow: integer|Long expected";
      return null;
    };

    /**
     * Creates a PushServerBetReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PushServerBetReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PushServerBetReq} PushServerBetReq
     */
    PushServerBetReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PushServerBetReq) return object;
      var message = new $root.client_proto.PushServerBetReq();
      if (object.s_uid != null) message.s_uid = object.s_uid >>> 0;
      if (object.bet_amount != null)
        if ($util.Long) (message.bet_amount = $util.Long.fromValue(object.bet_amount)).unsigned = false;
        else if (typeof object.bet_amount === "string") message.bet_amount = parseInt(object.bet_amount, 10);
        else if (typeof object.bet_amount === "number") message.bet_amount = object.bet_amount;
        else if (typeof object.bet_amount === "object")
          message.bet_amount = new $util.LongBits(object.bet_amount.low >>> 0, object.bet_amount.high >>> 0).toNumber();
      if (object.valid_flow != null)
        if ($util.Long) (message.valid_flow = $util.Long.fromValue(object.valid_flow)).unsigned = false;
        else if (typeof object.valid_flow === "string") message.valid_flow = parseInt(object.valid_flow, 10);
        else if (typeof object.valid_flow === "number") message.valid_flow = object.valid_flow;
        else if (typeof object.valid_flow === "object")
          message.valid_flow = new $util.LongBits(object.valid_flow.low >>> 0, object.valid_flow.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a PushServerBetReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PushServerBetReq
     * @static
     * @param {client_proto.PushServerBetReq} message PushServerBetReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PushServerBetReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.s_uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.bet_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.bet_amount = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.valid_flow =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.valid_flow = options.longs === String ? "0" : 0;
      }
      if (message.s_uid != null && message.hasOwnProperty("s_uid")) object.s_uid = message.s_uid;
      if (message.bet_amount != null && message.hasOwnProperty("bet_amount"))
        if (typeof message.bet_amount === "number")
          object.bet_amount = options.longs === String ? String(message.bet_amount) : message.bet_amount;
        else
          object.bet_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.bet_amount)
              : options.longs === Number
                ? new $util.LongBits(message.bet_amount.low >>> 0, message.bet_amount.high >>> 0).toNumber()
                : message.bet_amount;
      if (message.valid_flow != null && message.hasOwnProperty("valid_flow"))
        if (typeof message.valid_flow === "number")
          object.valid_flow = options.longs === String ? String(message.valid_flow) : message.valid_flow;
        else
          object.valid_flow =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.valid_flow)
              : options.longs === Number
                ? new $util.LongBits(message.valid_flow.low >>> 0, message.valid_flow.high >>> 0).toNumber()
                : message.valid_flow;
      return object;
    };

    /**
     * Converts this PushServerBetReq to JSON.
     * @function toJSON
     * @memberof client_proto.PushServerBetReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PushServerBetReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PushServerBetReq
     * @function getTypeUrl
     * @memberof client_proto.PushServerBetReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PushServerBetReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PushServerBetReq";
    };

    return PushServerBetReq;
  })();

  client_proto.AgentConfig = (function () {
    /**
     * Properties of an AgentConfig.
     * @memberof client_proto
     * @interface IAgentConfig
     * @property {number|Long|null} [bet_rebate] AgentConfig bet_rebate
     * @property {number|Long|null} [deposit_rebate] AgentConfig deposit_rebate
     * @property {number|Long|null} [inviate_rebate] AgentConfig inviate_rebate
     * @property {number|null} [team_level] AgentConfig team_level
     * @property {number|null} [team_count] AgentConfig team_count
     * @property {number|Long|null} [team_bet] AgentConfig team_bet
     */

    /**
     * Constructs a new AgentConfig.
     * @memberof client_proto
     * @classdesc Represents an AgentConfig.
     * @implements IAgentConfig
     * @constructor
     * @param {client_proto.IAgentConfig=} [properties] Properties to set
     */
    function AgentConfig(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentConfig bet_rebate.
     * @member {number|Long} bet_rebate
     * @memberof client_proto.AgentConfig
     * @instance
     */
    AgentConfig.prototype.bet_rebate = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentConfig deposit_rebate.
     * @member {number|Long} deposit_rebate
     * @memberof client_proto.AgentConfig
     * @instance
     */
    AgentConfig.prototype.deposit_rebate = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentConfig inviate_rebate.
     * @member {number|Long} inviate_rebate
     * @memberof client_proto.AgentConfig
     * @instance
     */
    AgentConfig.prototype.inviate_rebate = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentConfig team_level.
     * @member {number} team_level
     * @memberof client_proto.AgentConfig
     * @instance
     */
    AgentConfig.prototype.team_level = 0;

    /**
     * AgentConfig team_count.
     * @member {number} team_count
     * @memberof client_proto.AgentConfig
     * @instance
     */
    AgentConfig.prototype.team_count = 0;

    /**
     * AgentConfig team_bet.
     * @member {number|Long} team_bet
     * @memberof client_proto.AgentConfig
     * @instance
     */
    AgentConfig.prototype.team_bet = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new AgentConfig instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentConfig
     * @static
     * @param {client_proto.IAgentConfig=} [properties] Properties to set
     * @returns {client_proto.AgentConfig} AgentConfig instance
     */
    AgentConfig.create = function create(properties) {
      return new AgentConfig(properties);
    };

    /**
     * Encodes the specified AgentConfig message. Does not implicitly {@link client_proto.AgentConfig.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentConfig
     * @static
     * @param {client_proto.IAgentConfig} message AgentConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentConfig.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.bet_rebate != null && Object.hasOwnProperty.call(message, "bet_rebate"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.bet_rebate);
      if (message.deposit_rebate != null && Object.hasOwnProperty.call(message, "deposit_rebate"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.deposit_rebate);
      if (message.inviate_rebate != null && Object.hasOwnProperty.call(message, "inviate_rebate"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.inviate_rebate);
      if (message.team_level != null && Object.hasOwnProperty.call(message, "team_level"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.team_level);
      if (message.team_count != null && Object.hasOwnProperty.call(message, "team_count"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.team_count);
      if (message.team_bet != null && Object.hasOwnProperty.call(message, "team_bet"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.team_bet);
      return writer;
    };

    /**
     * Encodes the specified AgentConfig message, length delimited. Does not implicitly {@link client_proto.AgentConfig.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentConfig
     * @static
     * @param {client_proto.IAgentConfig} message AgentConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentConfig.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentConfig message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentConfig} AgentConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentConfig.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentConfig();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.bet_rebate = reader.int64();
            break;
          }
          case 2: {
            message.deposit_rebate = reader.int64();
            break;
          }
          case 3: {
            message.inviate_rebate = reader.int64();
            break;
          }
          case 4: {
            message.team_level = reader.int32();
            break;
          }
          case 5: {
            message.team_count = reader.int32();
            break;
          }
          case 6: {
            message.team_bet = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentConfig message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentConfig} AgentConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentConfig.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentConfig message.
     * @function verify
     * @memberof client_proto.AgentConfig
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentConfig.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.bet_rebate != null && message.hasOwnProperty("bet_rebate"))
        if (
          !$util.isInteger(message.bet_rebate) &&
          !(message.bet_rebate && $util.isInteger(message.bet_rebate.low) && $util.isInteger(message.bet_rebate.high))
        )
          return "bet_rebate: integer|Long expected";
      if (message.deposit_rebate != null && message.hasOwnProperty("deposit_rebate"))
        if (
          !$util.isInteger(message.deposit_rebate) &&
          !(
            message.deposit_rebate &&
            $util.isInteger(message.deposit_rebate.low) &&
            $util.isInteger(message.deposit_rebate.high)
          )
        )
          return "deposit_rebate: integer|Long expected";
      if (message.inviate_rebate != null && message.hasOwnProperty("inviate_rebate"))
        if (
          !$util.isInteger(message.inviate_rebate) &&
          !(
            message.inviate_rebate &&
            $util.isInteger(message.inviate_rebate.low) &&
            $util.isInteger(message.inviate_rebate.high)
          )
        )
          return "inviate_rebate: integer|Long expected";
      if (message.team_level != null && message.hasOwnProperty("team_level"))
        if (!$util.isInteger(message.team_level)) return "team_level: integer expected";
      if (message.team_count != null && message.hasOwnProperty("team_count"))
        if (!$util.isInteger(message.team_count)) return "team_count: integer expected";
      if (message.team_bet != null && message.hasOwnProperty("team_bet"))
        if (
          !$util.isInteger(message.team_bet) &&
          !(message.team_bet && $util.isInteger(message.team_bet.low) && $util.isInteger(message.team_bet.high))
        )
          return "team_bet: integer|Long expected";
      return null;
    };

    /**
     * Creates an AgentConfig message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentConfig
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentConfig} AgentConfig
     */
    AgentConfig.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentConfig) return object;
      var message = new $root.client_proto.AgentConfig();
      if (object.bet_rebate != null)
        if ($util.Long) (message.bet_rebate = $util.Long.fromValue(object.bet_rebate)).unsigned = false;
        else if (typeof object.bet_rebate === "string") message.bet_rebate = parseInt(object.bet_rebate, 10);
        else if (typeof object.bet_rebate === "number") message.bet_rebate = object.bet_rebate;
        else if (typeof object.bet_rebate === "object")
          message.bet_rebate = new $util.LongBits(object.bet_rebate.low >>> 0, object.bet_rebate.high >>> 0).toNumber();
      if (object.deposit_rebate != null)
        if ($util.Long) (message.deposit_rebate = $util.Long.fromValue(object.deposit_rebate)).unsigned = false;
        else if (typeof object.deposit_rebate === "string")
          message.deposit_rebate = parseInt(object.deposit_rebate, 10);
        else if (typeof object.deposit_rebate === "number") message.deposit_rebate = object.deposit_rebate;
        else if (typeof object.deposit_rebate === "object")
          message.deposit_rebate = new $util.LongBits(
            object.deposit_rebate.low >>> 0,
            object.deposit_rebate.high >>> 0,
          ).toNumber();
      if (object.inviate_rebate != null)
        if ($util.Long) (message.inviate_rebate = $util.Long.fromValue(object.inviate_rebate)).unsigned = false;
        else if (typeof object.inviate_rebate === "string")
          message.inviate_rebate = parseInt(object.inviate_rebate, 10);
        else if (typeof object.inviate_rebate === "number") message.inviate_rebate = object.inviate_rebate;
        else if (typeof object.inviate_rebate === "object")
          message.inviate_rebate = new $util.LongBits(
            object.inviate_rebate.low >>> 0,
            object.inviate_rebate.high >>> 0,
          ).toNumber();
      if (object.team_level != null) message.team_level = object.team_level | 0;
      if (object.team_count != null) message.team_count = object.team_count | 0;
      if (object.team_bet != null)
        if ($util.Long) (message.team_bet = $util.Long.fromValue(object.team_bet)).unsigned = false;
        else if (typeof object.team_bet === "string") message.team_bet = parseInt(object.team_bet, 10);
        else if (typeof object.team_bet === "number") message.team_bet = object.team_bet;
        else if (typeof object.team_bet === "object")
          message.team_bet = new $util.LongBits(object.team_bet.low >>> 0, object.team_bet.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from an AgentConfig message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentConfig
     * @static
     * @param {client_proto.AgentConfig} message AgentConfig
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentConfig.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.bet_rebate =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.bet_rebate = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.deposit_rebate =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.deposit_rebate = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.inviate_rebate =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.inviate_rebate = options.longs === String ? "0" : 0;
        object.team_level = 0;
        object.team_count = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.team_bet =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.team_bet = options.longs === String ? "0" : 0;
      }
      if (message.bet_rebate != null && message.hasOwnProperty("bet_rebate"))
        if (typeof message.bet_rebate === "number")
          object.bet_rebate = options.longs === String ? String(message.bet_rebate) : message.bet_rebate;
        else
          object.bet_rebate =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.bet_rebate)
              : options.longs === Number
                ? new $util.LongBits(message.bet_rebate.low >>> 0, message.bet_rebate.high >>> 0).toNumber()
                : message.bet_rebate;
      if (message.deposit_rebate != null && message.hasOwnProperty("deposit_rebate"))
        if (typeof message.deposit_rebate === "number")
          object.deposit_rebate = options.longs === String ? String(message.deposit_rebate) : message.deposit_rebate;
        else
          object.deposit_rebate =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.deposit_rebate)
              : options.longs === Number
                ? new $util.LongBits(message.deposit_rebate.low >>> 0, message.deposit_rebate.high >>> 0).toNumber()
                : message.deposit_rebate;
      if (message.inviate_rebate != null && message.hasOwnProperty("inviate_rebate"))
        if (typeof message.inviate_rebate === "number")
          object.inviate_rebate = options.longs === String ? String(message.inviate_rebate) : message.inviate_rebate;
        else
          object.inviate_rebate =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.inviate_rebate)
              : options.longs === Number
                ? new $util.LongBits(message.inviate_rebate.low >>> 0, message.inviate_rebate.high >>> 0).toNumber()
                : message.inviate_rebate;
      if (message.team_level != null && message.hasOwnProperty("team_level")) object.team_level = message.team_level;
      if (message.team_count != null && message.hasOwnProperty("team_count")) object.team_count = message.team_count;
      if (message.team_bet != null && message.hasOwnProperty("team_bet"))
        if (typeof message.team_bet === "number")
          object.team_bet = options.longs === String ? String(message.team_bet) : message.team_bet;
        else
          object.team_bet =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.team_bet)
              : options.longs === Number
                ? new $util.LongBits(message.team_bet.low >>> 0, message.team_bet.high >>> 0).toNumber()
                : message.team_bet;
      return object;
    };

    /**
     * Converts this AgentConfig to JSON.
     * @function toJSON
     * @memberof client_proto.AgentConfig
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentConfig.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentConfig
     * @function getTypeUrl
     * @memberof client_proto.AgentConfig
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentConfig";
    };

    return AgentConfig;
  })();

  client_proto.GetUserAgentConfigReq = (function () {
    /**
     * Properties of a GetUserAgentConfigReq.
     * @memberof client_proto
     * @interface IGetUserAgentConfigReq
     */

    /**
     * Constructs a new GetUserAgentConfigReq.
     * @memberof client_proto
     * @classdesc Represents a GetUserAgentConfigReq.
     * @implements IGetUserAgentConfigReq
     * @constructor
     * @param {client_proto.IGetUserAgentConfigReq=} [properties] Properties to set
     */
    function GetUserAgentConfigReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetUserAgentConfigReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserAgentConfigReq
     * @static
     * @param {client_proto.IGetUserAgentConfigReq=} [properties] Properties to set
     * @returns {client_proto.GetUserAgentConfigReq} GetUserAgentConfigReq instance
     */
    GetUserAgentConfigReq.create = function create(properties) {
      return new GetUserAgentConfigReq(properties);
    };

    /**
     * Encodes the specified GetUserAgentConfigReq message. Does not implicitly {@link client_proto.GetUserAgentConfigReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserAgentConfigReq
     * @static
     * @param {client_proto.IGetUserAgentConfigReq} message GetUserAgentConfigReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserAgentConfigReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified GetUserAgentConfigReq message, length delimited. Does not implicitly {@link client_proto.GetUserAgentConfigReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserAgentConfigReq
     * @static
     * @param {client_proto.IGetUserAgentConfigReq} message GetUserAgentConfigReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserAgentConfigReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserAgentConfigReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserAgentConfigReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserAgentConfigReq} GetUserAgentConfigReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserAgentConfigReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserAgentConfigReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserAgentConfigReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserAgentConfigReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserAgentConfigReq} GetUserAgentConfigReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserAgentConfigReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserAgentConfigReq message.
     * @function verify
     * @memberof client_proto.GetUserAgentConfigReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserAgentConfigReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a GetUserAgentConfigReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserAgentConfigReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserAgentConfigReq} GetUserAgentConfigReq
     */
    GetUserAgentConfigReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserAgentConfigReq) return object;
      return new $root.client_proto.GetUserAgentConfigReq();
    };

    /**
     * Creates a plain object from a GetUserAgentConfigReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserAgentConfigReq
     * @static
     * @param {client_proto.GetUserAgentConfigReq} message GetUserAgentConfigReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserAgentConfigReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this GetUserAgentConfigReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserAgentConfigReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserAgentConfigReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserAgentConfigReq
     * @function getTypeUrl
     * @memberof client_proto.GetUserAgentConfigReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserAgentConfigReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserAgentConfigReq";
    };

    return GetUserAgentConfigReq;
  })();

  client_proto.GetUserAgentConfigResp = (function () {
    /**
     * Properties of a GetUserAgentConfigResp.
     * @memberof client_proto
     * @interface IGetUserAgentConfigResp
     * @property {Array.<client_proto.IAgentConfig>|null} [agent_configs] GetUserAgentConfigResp agent_configs
     */

    /**
     * Constructs a new GetUserAgentConfigResp.
     * @memberof client_proto
     * @classdesc Represents a GetUserAgentConfigResp.
     * @implements IGetUserAgentConfigResp
     * @constructor
     * @param {client_proto.IGetUserAgentConfigResp=} [properties] Properties to set
     */
    function GetUserAgentConfigResp(properties) {
      this.agent_configs = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserAgentConfigResp agent_configs.
     * @member {Array.<client_proto.IAgentConfig>} agent_configs
     * @memberof client_proto.GetUserAgentConfigResp
     * @instance
     */
    GetUserAgentConfigResp.prototype.agent_configs = $util.emptyArray;

    /**
     * Creates a new GetUserAgentConfigResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetUserAgentConfigResp
     * @static
     * @param {client_proto.IGetUserAgentConfigResp=} [properties] Properties to set
     * @returns {client_proto.GetUserAgentConfigResp} GetUserAgentConfigResp instance
     */
    GetUserAgentConfigResp.create = function create(properties) {
      return new GetUserAgentConfigResp(properties);
    };

    /**
     * Encodes the specified GetUserAgentConfigResp message. Does not implicitly {@link client_proto.GetUserAgentConfigResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetUserAgentConfigResp
     * @static
     * @param {client_proto.IGetUserAgentConfigResp} message GetUserAgentConfigResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserAgentConfigResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.agent_configs != null && message.agent_configs.length)
        for (var i = 0; i < message.agent_configs.length; ++i)
          $root.client_proto.AgentConfig.encode(
            message.agent_configs[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified GetUserAgentConfigResp message, length delimited. Does not implicitly {@link client_proto.GetUserAgentConfigResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetUserAgentConfigResp
     * @static
     * @param {client_proto.IGetUserAgentConfigResp} message GetUserAgentConfigResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserAgentConfigResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserAgentConfigResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetUserAgentConfigResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetUserAgentConfigResp} GetUserAgentConfigResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserAgentConfigResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetUserAgentConfigResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.agent_configs && message.agent_configs.length)) message.agent_configs = [];
            message.agent_configs.push($root.client_proto.AgentConfig.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetUserAgentConfigResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetUserAgentConfigResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetUserAgentConfigResp} GetUserAgentConfigResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserAgentConfigResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserAgentConfigResp message.
     * @function verify
     * @memberof client_proto.GetUserAgentConfigResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserAgentConfigResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.agent_configs != null && message.hasOwnProperty("agent_configs")) {
        if (!Array.isArray(message.agent_configs)) return "agent_configs: array expected";
        for (var i = 0; i < message.agent_configs.length; ++i) {
          var error = $root.client_proto.AgentConfig.verify(message.agent_configs[i]);
          if (error) return "agent_configs." + error;
        }
      }
      return null;
    };

    /**
     * Creates a GetUserAgentConfigResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetUserAgentConfigResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetUserAgentConfigResp} GetUserAgentConfigResp
     */
    GetUserAgentConfigResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetUserAgentConfigResp) return object;
      var message = new $root.client_proto.GetUserAgentConfigResp();
      if (object.agent_configs) {
        if (!Array.isArray(object.agent_configs))
          throw TypeError(".client_proto.GetUserAgentConfigResp.agent_configs: array expected");
        message.agent_configs = [];
        for (var i = 0; i < object.agent_configs.length; ++i) {
          if (typeof object.agent_configs[i] !== "object")
            throw TypeError(".client_proto.GetUserAgentConfigResp.agent_configs: object expected");
          message.agent_configs[i] = $root.client_proto.AgentConfig.fromObject(object.agent_configs[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a GetUserAgentConfigResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetUserAgentConfigResp
     * @static
     * @param {client_proto.GetUserAgentConfigResp} message GetUserAgentConfigResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserAgentConfigResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.agent_configs = [];
      if (message.agent_configs && message.agent_configs.length) {
        object.agent_configs = [];
        for (var j = 0; j < message.agent_configs.length; ++j)
          object.agent_configs[j] = $root.client_proto.AgentConfig.toObject(message.agent_configs[j], options);
      }
      return object;
    };

    /**
     * Converts this GetUserAgentConfigResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetUserAgentConfigResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserAgentConfigResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserAgentConfigResp
     * @function getTypeUrl
     * @memberof client_proto.GetUserAgentConfigResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserAgentConfigResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetUserAgentConfigResp";
    };

    return GetUserAgentConfigResp;
  })();

  client_proto.PhpAuditAgentOrderReq = (function () {
    /**
     * Properties of a PhpAuditAgentOrderReq.
     * @memberof client_proto
     * @interface IPhpAuditAgentOrderReq
     * @property {number|null} [order_id] PhpAuditAgentOrderReq order_id
     * @property {number|null} [result] PhpAuditAgentOrderReq result
     * @property {number|null} [audit_uid] PhpAuditAgentOrderReq audit_uid
     * @property {string|null} [audit_name] PhpAuditAgentOrderReq audit_name
     * @property {string|null} [reason] PhpAuditAgentOrderReq reason
     */

    /**
     * Constructs a new PhpAuditAgentOrderReq.
     * @memberof client_proto
     * @classdesc Represents a PhpAuditAgentOrderReq.
     * @implements IPhpAuditAgentOrderReq
     * @constructor
     * @param {client_proto.IPhpAuditAgentOrderReq=} [properties] Properties to set
     */
    function PhpAuditAgentOrderReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpAuditAgentOrderReq order_id.
     * @member {number} order_id
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @instance
     */
    PhpAuditAgentOrderReq.prototype.order_id = 0;

    /**
     * PhpAuditAgentOrderReq result.
     * @member {number} result
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @instance
     */
    PhpAuditAgentOrderReq.prototype.result = 0;

    /**
     * PhpAuditAgentOrderReq audit_uid.
     * @member {number} audit_uid
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @instance
     */
    PhpAuditAgentOrderReq.prototype.audit_uid = 0;

    /**
     * PhpAuditAgentOrderReq audit_name.
     * @member {string} audit_name
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @instance
     */
    PhpAuditAgentOrderReq.prototype.audit_name = "";

    /**
     * PhpAuditAgentOrderReq reason.
     * @member {string} reason
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @instance
     */
    PhpAuditAgentOrderReq.prototype.reason = "";

    /**
     * Creates a new PhpAuditAgentOrderReq instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @static
     * @param {client_proto.IPhpAuditAgentOrderReq=} [properties] Properties to set
     * @returns {client_proto.PhpAuditAgentOrderReq} PhpAuditAgentOrderReq instance
     */
    PhpAuditAgentOrderReq.create = function create(properties) {
      return new PhpAuditAgentOrderReq(properties);
    };

    /**
     * Encodes the specified PhpAuditAgentOrderReq message. Does not implicitly {@link client_proto.PhpAuditAgentOrderReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @static
     * @param {client_proto.IPhpAuditAgentOrderReq} message PhpAuditAgentOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpAuditAgentOrderReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.order_id);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.audit_uid != null && Object.hasOwnProperty.call(message, "audit_uid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.audit_uid);
      if (message.audit_name != null && Object.hasOwnProperty.call(message, "audit_name"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.audit_name);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.reason);
      return writer;
    };

    /**
     * Encodes the specified PhpAuditAgentOrderReq message, length delimited. Does not implicitly {@link client_proto.PhpAuditAgentOrderReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @static
     * @param {client_proto.IPhpAuditAgentOrderReq} message PhpAuditAgentOrderReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpAuditAgentOrderReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpAuditAgentOrderReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpAuditAgentOrderReq} PhpAuditAgentOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpAuditAgentOrderReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpAuditAgentOrderReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.order_id = reader.int32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            message.audit_uid = reader.uint32();
            break;
          }
          case 4: {
            message.audit_name = reader.string();
            break;
          }
          case 5: {
            message.reason = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpAuditAgentOrderReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpAuditAgentOrderReq} PhpAuditAgentOrderReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpAuditAgentOrderReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpAuditAgentOrderReq message.
     * @function verify
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpAuditAgentOrderReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.audit_uid != null && message.hasOwnProperty("audit_uid"))
        if (!$util.isInteger(message.audit_uid)) return "audit_uid: integer expected";
      if (message.audit_name != null && message.hasOwnProperty("audit_name"))
        if (!$util.isString(message.audit_name)) return "audit_name: string expected";
      if (message.reason != null && message.hasOwnProperty("reason"))
        if (!$util.isString(message.reason)) return "reason: string expected";
      return null;
    };

    /**
     * Creates a PhpAuditAgentOrderReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpAuditAgentOrderReq} PhpAuditAgentOrderReq
     */
    PhpAuditAgentOrderReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpAuditAgentOrderReq) return object;
      var message = new $root.client_proto.PhpAuditAgentOrderReq();
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.audit_uid != null) message.audit_uid = object.audit_uid >>> 0;
      if (object.audit_name != null) message.audit_name = String(object.audit_name);
      if (object.reason != null) message.reason = String(object.reason);
      return message;
    };

    /**
     * Creates a plain object from a PhpAuditAgentOrderReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @static
     * @param {client_proto.PhpAuditAgentOrderReq} message PhpAuditAgentOrderReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpAuditAgentOrderReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.order_id = 0;
        object.result = 0;
        object.audit_uid = 0;
        object.audit_name = "";
        object.reason = "";
      }
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.audit_uid != null && message.hasOwnProperty("audit_uid")) object.audit_uid = message.audit_uid;
      if (message.audit_name != null && message.hasOwnProperty("audit_name")) object.audit_name = message.audit_name;
      if (message.reason != null && message.hasOwnProperty("reason")) object.reason = message.reason;
      return object;
    };

    /**
     * Converts this PhpAuditAgentOrderReq to JSON.
     * @function toJSON
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpAuditAgentOrderReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpAuditAgentOrderReq
     * @function getTypeUrl
     * @memberof client_proto.PhpAuditAgentOrderReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpAuditAgentOrderReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpAuditAgentOrderReq";
    };

    return PhpAuditAgentOrderReq;
  })();

  client_proto.PhpAuditAgentOrderResp = (function () {
    /**
     * Properties of a PhpAuditAgentOrderResp.
     * @memberof client_proto
     * @interface IPhpAuditAgentOrderResp
     * @property {number|null} [PhpAuditAgentOrder] PhpAuditAgentOrderResp PhpAuditAgentOrder
     */

    /**
     * Constructs a new PhpAuditAgentOrderResp.
     * @memberof client_proto
     * @classdesc Represents a PhpAuditAgentOrderResp.
     * @implements IPhpAuditAgentOrderResp
     * @constructor
     * @param {client_proto.IPhpAuditAgentOrderResp=} [properties] Properties to set
     */
    function PhpAuditAgentOrderResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpAuditAgentOrderResp PhpAuditAgentOrder.
     * @member {number} PhpAuditAgentOrder
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @instance
     */
    PhpAuditAgentOrderResp.prototype.PhpAuditAgentOrder = 0;

    /**
     * Creates a new PhpAuditAgentOrderResp instance using the specified properties.
     * @function create
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @static
     * @param {client_proto.IPhpAuditAgentOrderResp=} [properties] Properties to set
     * @returns {client_proto.PhpAuditAgentOrderResp} PhpAuditAgentOrderResp instance
     */
    PhpAuditAgentOrderResp.create = function create(properties) {
      return new PhpAuditAgentOrderResp(properties);
    };

    /**
     * Encodes the specified PhpAuditAgentOrderResp message. Does not implicitly {@link client_proto.PhpAuditAgentOrderResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @static
     * @param {client_proto.IPhpAuditAgentOrderResp} message PhpAuditAgentOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpAuditAgentOrderResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.PhpAuditAgentOrder != null && Object.hasOwnProperty.call(message, "PhpAuditAgentOrder"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.PhpAuditAgentOrder);
      return writer;
    };

    /**
     * Encodes the specified PhpAuditAgentOrderResp message, length delimited. Does not implicitly {@link client_proto.PhpAuditAgentOrderResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @static
     * @param {client_proto.IPhpAuditAgentOrderResp} message PhpAuditAgentOrderResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpAuditAgentOrderResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpAuditAgentOrderResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PhpAuditAgentOrderResp} PhpAuditAgentOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpAuditAgentOrderResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PhpAuditAgentOrderResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.PhpAuditAgentOrder = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpAuditAgentOrderResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PhpAuditAgentOrderResp} PhpAuditAgentOrderResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpAuditAgentOrderResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpAuditAgentOrderResp message.
     * @function verify
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpAuditAgentOrderResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.PhpAuditAgentOrder != null && message.hasOwnProperty("PhpAuditAgentOrder"))
        if (!$util.isInteger(message.PhpAuditAgentOrder)) return "PhpAuditAgentOrder: integer expected";
      return null;
    };

    /**
     * Creates a PhpAuditAgentOrderResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PhpAuditAgentOrderResp} PhpAuditAgentOrderResp
     */
    PhpAuditAgentOrderResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PhpAuditAgentOrderResp) return object;
      var message = new $root.client_proto.PhpAuditAgentOrderResp();
      if (object.PhpAuditAgentOrder != null) message.PhpAuditAgentOrder = object.PhpAuditAgentOrder | 0;
      return message;
    };

    /**
     * Creates a plain object from a PhpAuditAgentOrderResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @static
     * @param {client_proto.PhpAuditAgentOrderResp} message PhpAuditAgentOrderResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpAuditAgentOrderResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.PhpAuditAgentOrder = 0;
      if (message.PhpAuditAgentOrder != null && message.hasOwnProperty("PhpAuditAgentOrder"))
        object.PhpAuditAgentOrder = message.PhpAuditAgentOrder;
      return object;
    };

    /**
     * Converts this PhpAuditAgentOrderResp to JSON.
     * @function toJSON
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpAuditAgentOrderResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpAuditAgentOrderResp
     * @function getTypeUrl
     * @memberof client_proto.PhpAuditAgentOrderResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpAuditAgentOrderResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PhpAuditAgentOrderResp";
    };

    return PhpAuditAgentOrderResp;
  })();

  client_proto.CheckInviteStatusReq = (function () {
    /**
     * Properties of a CheckInviteStatusReq.
     * @memberof client_proto
     * @interface ICheckInviteStatusReq
     * @property {number|null} [uid] CheckInviteStatusReq uid
     * @property {Uint8Array|null} [trans] CheckInviteStatusReq trans
     */

    /**
     * Constructs a new CheckInviteStatusReq.
     * @memberof client_proto
     * @classdesc Represents a CheckInviteStatusReq.
     * @implements ICheckInviteStatusReq
     * @constructor
     * @param {client_proto.ICheckInviteStatusReq=} [properties] Properties to set
     */
    function CheckInviteStatusReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckInviteStatusReq uid.
     * @member {number} uid
     * @memberof client_proto.CheckInviteStatusReq
     * @instance
     */
    CheckInviteStatusReq.prototype.uid = 0;

    /**
     * CheckInviteStatusReq trans.
     * @member {Uint8Array} trans
     * @memberof client_proto.CheckInviteStatusReq
     * @instance
     */
    CheckInviteStatusReq.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new CheckInviteStatusReq instance using the specified properties.
     * @function create
     * @memberof client_proto.CheckInviteStatusReq
     * @static
     * @param {client_proto.ICheckInviteStatusReq=} [properties] Properties to set
     * @returns {client_proto.CheckInviteStatusReq} CheckInviteStatusReq instance
     */
    CheckInviteStatusReq.create = function create(properties) {
      return new CheckInviteStatusReq(properties);
    };

    /**
     * Encodes the specified CheckInviteStatusReq message. Does not implicitly {@link client_proto.CheckInviteStatusReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CheckInviteStatusReq
     * @static
     * @param {client_proto.ICheckInviteStatusReq} message CheckInviteStatusReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInviteStatusReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified CheckInviteStatusReq message, length delimited. Does not implicitly {@link client_proto.CheckInviteStatusReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CheckInviteStatusReq
     * @static
     * @param {client_proto.ICheckInviteStatusReq} message CheckInviteStatusReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInviteStatusReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckInviteStatusReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CheckInviteStatusReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CheckInviteStatusReq} CheckInviteStatusReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInviteStatusReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CheckInviteStatusReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CheckInviteStatusReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CheckInviteStatusReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CheckInviteStatusReq} CheckInviteStatusReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInviteStatusReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckInviteStatusReq message.
     * @function verify
     * @memberof client_proto.CheckInviteStatusReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckInviteStatusReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates a CheckInviteStatusReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CheckInviteStatusReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CheckInviteStatusReq} CheckInviteStatusReq
     */
    CheckInviteStatusReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CheckInviteStatusReq) return object;
      var message = new $root.client_proto.CheckInviteStatusReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from a CheckInviteStatusReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CheckInviteStatusReq
     * @static
     * @param {client_proto.CheckInviteStatusReq} message CheckInviteStatusReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckInviteStatusReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this CheckInviteStatusReq to JSON.
     * @function toJSON
     * @memberof client_proto.CheckInviteStatusReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckInviteStatusReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckInviteStatusReq
     * @function getTypeUrl
     * @memberof client_proto.CheckInviteStatusReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckInviteStatusReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CheckInviteStatusReq";
    };

    return CheckInviteStatusReq;
  })();

  client_proto.CheckInviteStatusResp = (function () {
    /**
     * Properties of a CheckInviteStatusResp.
     * @memberof client_proto
     * @interface ICheckInviteStatusResp
     * @property {number|null} [uid] CheckInviteStatusResp uid
     * @property {number|Long|null} [bonus] CheckInviteStatusResp bonus
     * @property {number|Long|null} [charge_limit] CheckInviteStatusResp charge_limit
     * @property {number|Long|null} [total_charge] CheckInviteStatusResp total_charge
     * @property {number|Long|null} [total_flow] CheckInviteStatusResp total_flow
     * @property {number|Long|null} [flow_limit] CheckInviteStatusResp flow_limit
     * @property {Uint8Array|null} [trans] CheckInviteStatusResp trans
     */

    /**
     * Constructs a new CheckInviteStatusResp.
     * @memberof client_proto
     * @classdesc Represents a CheckInviteStatusResp.
     * @implements ICheckInviteStatusResp
     * @constructor
     * @param {client_proto.ICheckInviteStatusResp=} [properties] Properties to set
     */
    function CheckInviteStatusResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckInviteStatusResp uid.
     * @member {number} uid
     * @memberof client_proto.CheckInviteStatusResp
     * @instance
     */
    CheckInviteStatusResp.prototype.uid = 0;

    /**
     * CheckInviteStatusResp bonus.
     * @member {number|Long} bonus
     * @memberof client_proto.CheckInviteStatusResp
     * @instance
     */
    CheckInviteStatusResp.prototype.bonus = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CheckInviteStatusResp charge_limit.
     * @member {number|Long} charge_limit
     * @memberof client_proto.CheckInviteStatusResp
     * @instance
     */
    CheckInviteStatusResp.prototype.charge_limit = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CheckInviteStatusResp total_charge.
     * @member {number|Long} total_charge
     * @memberof client_proto.CheckInviteStatusResp
     * @instance
     */
    CheckInviteStatusResp.prototype.total_charge = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CheckInviteStatusResp total_flow.
     * @member {number|Long} total_flow
     * @memberof client_proto.CheckInviteStatusResp
     * @instance
     */
    CheckInviteStatusResp.prototype.total_flow = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CheckInviteStatusResp flow_limit.
     * @member {number|Long} flow_limit
     * @memberof client_proto.CheckInviteStatusResp
     * @instance
     */
    CheckInviteStatusResp.prototype.flow_limit = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * CheckInviteStatusResp trans.
     * @member {Uint8Array} trans
     * @memberof client_proto.CheckInviteStatusResp
     * @instance
     */
    CheckInviteStatusResp.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new CheckInviteStatusResp instance using the specified properties.
     * @function create
     * @memberof client_proto.CheckInviteStatusResp
     * @static
     * @param {client_proto.ICheckInviteStatusResp=} [properties] Properties to set
     * @returns {client_proto.CheckInviteStatusResp} CheckInviteStatusResp instance
     */
    CheckInviteStatusResp.create = function create(properties) {
      return new CheckInviteStatusResp(properties);
    };

    /**
     * Encodes the specified CheckInviteStatusResp message. Does not implicitly {@link client_proto.CheckInviteStatusResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.CheckInviteStatusResp
     * @static
     * @param {client_proto.ICheckInviteStatusResp} message CheckInviteStatusResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInviteStatusResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.bonus != null && Object.hasOwnProperty.call(message, "bonus"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.bonus);
      if (message.charge_limit != null && Object.hasOwnProperty.call(message, "charge_limit"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.charge_limit);
      if (message.total_charge != null && Object.hasOwnProperty.call(message, "total_charge"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.total_charge);
      if (message.total_flow != null && Object.hasOwnProperty.call(message, "total_flow"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.total_flow);
      if (message.flow_limit != null && Object.hasOwnProperty.call(message, "flow_limit"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.flow_limit);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 10, wireType 2 =*/ 82).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified CheckInviteStatusResp message, length delimited. Does not implicitly {@link client_proto.CheckInviteStatusResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.CheckInviteStatusResp
     * @static
     * @param {client_proto.ICheckInviteStatusResp} message CheckInviteStatusResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInviteStatusResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckInviteStatusResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.CheckInviteStatusResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.CheckInviteStatusResp} CheckInviteStatusResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInviteStatusResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.CheckInviteStatusResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.bonus = reader.int64();
            break;
          }
          case 3: {
            message.charge_limit = reader.int64();
            break;
          }
          case 4: {
            message.total_charge = reader.int64();
            break;
          }
          case 5: {
            message.total_flow = reader.int64();
            break;
          }
          case 6: {
            message.flow_limit = reader.int64();
            break;
          }
          case 10: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CheckInviteStatusResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.CheckInviteStatusResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.CheckInviteStatusResp} CheckInviteStatusResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInviteStatusResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckInviteStatusResp message.
     * @function verify
     * @memberof client_proto.CheckInviteStatusResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckInviteStatusResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.bonus != null && message.hasOwnProperty("bonus"))
        if (
          !$util.isInteger(message.bonus) &&
          !(message.bonus && $util.isInteger(message.bonus.low) && $util.isInteger(message.bonus.high))
        )
          return "bonus: integer|Long expected";
      if (message.charge_limit != null && message.hasOwnProperty("charge_limit"))
        if (
          !$util.isInteger(message.charge_limit) &&
          !(
            message.charge_limit &&
            $util.isInteger(message.charge_limit.low) &&
            $util.isInteger(message.charge_limit.high)
          )
        )
          return "charge_limit: integer|Long expected";
      if (message.total_charge != null && message.hasOwnProperty("total_charge"))
        if (
          !$util.isInteger(message.total_charge) &&
          !(
            message.total_charge &&
            $util.isInteger(message.total_charge.low) &&
            $util.isInteger(message.total_charge.high)
          )
        )
          return "total_charge: integer|Long expected";
      if (message.total_flow != null && message.hasOwnProperty("total_flow"))
        if (
          !$util.isInteger(message.total_flow) &&
          !(message.total_flow && $util.isInteger(message.total_flow.low) && $util.isInteger(message.total_flow.high))
        )
          return "total_flow: integer|Long expected";
      if (message.flow_limit != null && message.hasOwnProperty("flow_limit"))
        if (
          !$util.isInteger(message.flow_limit) &&
          !(message.flow_limit && $util.isInteger(message.flow_limit.low) && $util.isInteger(message.flow_limit.high))
        )
          return "flow_limit: integer|Long expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates a CheckInviteStatusResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.CheckInviteStatusResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.CheckInviteStatusResp} CheckInviteStatusResp
     */
    CheckInviteStatusResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.CheckInviteStatusResp) return object;
      var message = new $root.client_proto.CheckInviteStatusResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.bonus != null)
        if ($util.Long) (message.bonus = $util.Long.fromValue(object.bonus)).unsigned = false;
        else if (typeof object.bonus === "string") message.bonus = parseInt(object.bonus, 10);
        else if (typeof object.bonus === "number") message.bonus = object.bonus;
        else if (typeof object.bonus === "object")
          message.bonus = new $util.LongBits(object.bonus.low >>> 0, object.bonus.high >>> 0).toNumber();
      if (object.charge_limit != null)
        if ($util.Long) (message.charge_limit = $util.Long.fromValue(object.charge_limit)).unsigned = false;
        else if (typeof object.charge_limit === "string") message.charge_limit = parseInt(object.charge_limit, 10);
        else if (typeof object.charge_limit === "number") message.charge_limit = object.charge_limit;
        else if (typeof object.charge_limit === "object")
          message.charge_limit = new $util.LongBits(
            object.charge_limit.low >>> 0,
            object.charge_limit.high >>> 0,
          ).toNumber();
      if (object.total_charge != null)
        if ($util.Long) (message.total_charge = $util.Long.fromValue(object.total_charge)).unsigned = false;
        else if (typeof object.total_charge === "string") message.total_charge = parseInt(object.total_charge, 10);
        else if (typeof object.total_charge === "number") message.total_charge = object.total_charge;
        else if (typeof object.total_charge === "object")
          message.total_charge = new $util.LongBits(
            object.total_charge.low >>> 0,
            object.total_charge.high >>> 0,
          ).toNumber();
      if (object.total_flow != null)
        if ($util.Long) (message.total_flow = $util.Long.fromValue(object.total_flow)).unsigned = false;
        else if (typeof object.total_flow === "string") message.total_flow = parseInt(object.total_flow, 10);
        else if (typeof object.total_flow === "number") message.total_flow = object.total_flow;
        else if (typeof object.total_flow === "object")
          message.total_flow = new $util.LongBits(object.total_flow.low >>> 0, object.total_flow.high >>> 0).toNumber();
      if (object.flow_limit != null)
        if ($util.Long) (message.flow_limit = $util.Long.fromValue(object.flow_limit)).unsigned = false;
        else if (typeof object.flow_limit === "string") message.flow_limit = parseInt(object.flow_limit, 10);
        else if (typeof object.flow_limit === "number") message.flow_limit = object.flow_limit;
        else if (typeof object.flow_limit === "object")
          message.flow_limit = new $util.LongBits(object.flow_limit.low >>> 0, object.flow_limit.high >>> 0).toNumber();
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from a CheckInviteStatusResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.CheckInviteStatusResp
     * @static
     * @param {client_proto.CheckInviteStatusResp} message CheckInviteStatusResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckInviteStatusResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.bonus = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.bonus = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.charge_limit =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.charge_limit = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_charge =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_charge = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_flow =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_flow = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.flow_limit =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.flow_limit = options.longs === String ? "0" : 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.bonus != null && message.hasOwnProperty("bonus"))
        if (typeof message.bonus === "number")
          object.bonus = options.longs === String ? String(message.bonus) : message.bonus;
        else
          object.bonus =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.bonus)
              : options.longs === Number
                ? new $util.LongBits(message.bonus.low >>> 0, message.bonus.high >>> 0).toNumber()
                : message.bonus;
      if (message.charge_limit != null && message.hasOwnProperty("charge_limit"))
        if (typeof message.charge_limit === "number")
          object.charge_limit = options.longs === String ? String(message.charge_limit) : message.charge_limit;
        else
          object.charge_limit =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.charge_limit)
              : options.longs === Number
                ? new $util.LongBits(message.charge_limit.low >>> 0, message.charge_limit.high >>> 0).toNumber()
                : message.charge_limit;
      if (message.total_charge != null && message.hasOwnProperty("total_charge"))
        if (typeof message.total_charge === "number")
          object.total_charge = options.longs === String ? String(message.total_charge) : message.total_charge;
        else
          object.total_charge =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_charge)
              : options.longs === Number
                ? new $util.LongBits(message.total_charge.low >>> 0, message.total_charge.high >>> 0).toNumber()
                : message.total_charge;
      if (message.total_flow != null && message.hasOwnProperty("total_flow"))
        if (typeof message.total_flow === "number")
          object.total_flow = options.longs === String ? String(message.total_flow) : message.total_flow;
        else
          object.total_flow =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_flow)
              : options.longs === Number
                ? new $util.LongBits(message.total_flow.low >>> 0, message.total_flow.high >>> 0).toNumber()
                : message.total_flow;
      if (message.flow_limit != null && message.hasOwnProperty("flow_limit"))
        if (typeof message.flow_limit === "number")
          object.flow_limit = options.longs === String ? String(message.flow_limit) : message.flow_limit;
        else
          object.flow_limit =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.flow_limit)
              : options.longs === Number
                ? new $util.LongBits(message.flow_limit.low >>> 0, message.flow_limit.high >>> 0).toNumber()
                : message.flow_limit;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this CheckInviteStatusResp to JSON.
     * @function toJSON
     * @memberof client_proto.CheckInviteStatusResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckInviteStatusResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckInviteStatusResp
     * @function getTypeUrl
     * @memberof client_proto.CheckInviteStatusResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckInviteStatusResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.CheckInviteStatusResp";
    };

    return CheckInviteStatusResp;
  })();

  client_proto.GetInviteRewardReq = (function () {
    /**
     * Properties of a GetInviteRewardReq.
     * @memberof client_proto
     * @interface IGetInviteRewardReq
     */

    /**
     * Constructs a new GetInviteRewardReq.
     * @memberof client_proto
     * @classdesc Represents a GetInviteRewardReq.
     * @implements IGetInviteRewardReq
     * @constructor
     * @param {client_proto.IGetInviteRewardReq=} [properties] Properties to set
     */
    function GetInviteRewardReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetInviteRewardReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetInviteRewardReq
     * @static
     * @param {client_proto.IGetInviteRewardReq=} [properties] Properties to set
     * @returns {client_proto.GetInviteRewardReq} GetInviteRewardReq instance
     */
    GetInviteRewardReq.create = function create(properties) {
      return new GetInviteRewardReq(properties);
    };

    /**
     * Encodes the specified GetInviteRewardReq message. Does not implicitly {@link client_proto.GetInviteRewardReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetInviteRewardReq
     * @static
     * @param {client_proto.IGetInviteRewardReq} message GetInviteRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetInviteRewardReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified GetInviteRewardReq message, length delimited. Does not implicitly {@link client_proto.GetInviteRewardReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetInviteRewardReq
     * @static
     * @param {client_proto.IGetInviteRewardReq} message GetInviteRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetInviteRewardReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetInviteRewardReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetInviteRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetInviteRewardReq} GetInviteRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetInviteRewardReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetInviteRewardReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetInviteRewardReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetInviteRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetInviteRewardReq} GetInviteRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetInviteRewardReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetInviteRewardReq message.
     * @function verify
     * @memberof client_proto.GetInviteRewardReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetInviteRewardReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a GetInviteRewardReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetInviteRewardReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetInviteRewardReq} GetInviteRewardReq
     */
    GetInviteRewardReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetInviteRewardReq) return object;
      return new $root.client_proto.GetInviteRewardReq();
    };

    /**
     * Creates a plain object from a GetInviteRewardReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetInviteRewardReq
     * @static
     * @param {client_proto.GetInviteRewardReq} message GetInviteRewardReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetInviteRewardReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this GetInviteRewardReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetInviteRewardReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetInviteRewardReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetInviteRewardReq
     * @function getTypeUrl
     * @memberof client_proto.GetInviteRewardReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetInviteRewardReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetInviteRewardReq";
    };

    return GetInviteRewardReq;
  })();

  client_proto.GetInviteRewardResp = (function () {
    /**
     * Properties of a GetInviteRewardResp.
     * @memberof client_proto
     * @interface IGetInviteRewardResp
     * @property {number|null} [today_invite_count] GetInviteRewardResp today_invite_count
     * @property {number|Long|null} [total_invite_reward] GetInviteRewardResp total_invite_reward
     * @property {number|null} [total_valid_invite] GetInviteRewardResp total_valid_invite
     * @property {number|null} [round_valid_invite] GetInviteRewardResp round_valid_invite
     * @property {number|Long|null} [finish_time] GetInviteRewardResp finish_time
     * @property {client_proto.IInviteConfig|null} [reward_config] GetInviteRewardResp reward_config
     */

    /**
     * Constructs a new GetInviteRewardResp.
     * @memberof client_proto
     * @classdesc Represents a GetInviteRewardResp.
     * @implements IGetInviteRewardResp
     * @constructor
     * @param {client_proto.IGetInviteRewardResp=} [properties] Properties to set
     */
    function GetInviteRewardResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetInviteRewardResp today_invite_count.
     * @member {number} today_invite_count
     * @memberof client_proto.GetInviteRewardResp
     * @instance
     */
    GetInviteRewardResp.prototype.today_invite_count = 0;

    /**
     * GetInviteRewardResp total_invite_reward.
     * @member {number|Long} total_invite_reward
     * @memberof client_proto.GetInviteRewardResp
     * @instance
     */
    GetInviteRewardResp.prototype.total_invite_reward = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GetInviteRewardResp total_valid_invite.
     * @member {number} total_valid_invite
     * @memberof client_proto.GetInviteRewardResp
     * @instance
     */
    GetInviteRewardResp.prototype.total_valid_invite = 0;

    /**
     * GetInviteRewardResp round_valid_invite.
     * @member {number} round_valid_invite
     * @memberof client_proto.GetInviteRewardResp
     * @instance
     */
    GetInviteRewardResp.prototype.round_valid_invite = 0;

    /**
     * GetInviteRewardResp finish_time.
     * @member {number|Long} finish_time
     * @memberof client_proto.GetInviteRewardResp
     * @instance
     */
    GetInviteRewardResp.prototype.finish_time = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GetInviteRewardResp reward_config.
     * @member {client_proto.IInviteConfig|null|undefined} reward_config
     * @memberof client_proto.GetInviteRewardResp
     * @instance
     */
    GetInviteRewardResp.prototype.reward_config = null;

    /**
     * Creates a new GetInviteRewardResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetInviteRewardResp
     * @static
     * @param {client_proto.IGetInviteRewardResp=} [properties] Properties to set
     * @returns {client_proto.GetInviteRewardResp} GetInviteRewardResp instance
     */
    GetInviteRewardResp.create = function create(properties) {
      return new GetInviteRewardResp(properties);
    };

    /**
     * Encodes the specified GetInviteRewardResp message. Does not implicitly {@link client_proto.GetInviteRewardResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetInviteRewardResp
     * @static
     * @param {client_proto.IGetInviteRewardResp} message GetInviteRewardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetInviteRewardResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.today_invite_count != null && Object.hasOwnProperty.call(message, "today_invite_count"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.today_invite_count);
      if (message.total_invite_reward != null && Object.hasOwnProperty.call(message, "total_invite_reward"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.total_invite_reward);
      if (message.total_valid_invite != null && Object.hasOwnProperty.call(message, "total_valid_invite"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.total_valid_invite);
      if (message.round_valid_invite != null && Object.hasOwnProperty.call(message, "round_valid_invite"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.round_valid_invite);
      if (message.finish_time != null && Object.hasOwnProperty.call(message, "finish_time"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.finish_time);
      if (message.reward_config != null && Object.hasOwnProperty.call(message, "reward_config"))
        $root.client_proto.InviteConfig.encode(
          message.reward_config,
          writer.uint32(/* id 6, wireType 2 =*/ 50).fork(),
        ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified GetInviteRewardResp message, length delimited. Does not implicitly {@link client_proto.GetInviteRewardResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetInviteRewardResp
     * @static
     * @param {client_proto.IGetInviteRewardResp} message GetInviteRewardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetInviteRewardResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetInviteRewardResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetInviteRewardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetInviteRewardResp} GetInviteRewardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetInviteRewardResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetInviteRewardResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.today_invite_count = reader.int32();
            break;
          }
          case 2: {
            message.total_invite_reward = reader.int64();
            break;
          }
          case 3: {
            message.total_valid_invite = reader.int32();
            break;
          }
          case 4: {
            message.round_valid_invite = reader.int32();
            break;
          }
          case 5: {
            message.finish_time = reader.int64();
            break;
          }
          case 6: {
            message.reward_config = $root.client_proto.InviteConfig.decode(reader, reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetInviteRewardResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetInviteRewardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetInviteRewardResp} GetInviteRewardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetInviteRewardResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetInviteRewardResp message.
     * @function verify
     * @memberof client_proto.GetInviteRewardResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetInviteRewardResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.today_invite_count != null && message.hasOwnProperty("today_invite_count"))
        if (!$util.isInteger(message.today_invite_count)) return "today_invite_count: integer expected";
      if (message.total_invite_reward != null && message.hasOwnProperty("total_invite_reward"))
        if (
          !$util.isInteger(message.total_invite_reward) &&
          !(
            message.total_invite_reward &&
            $util.isInteger(message.total_invite_reward.low) &&
            $util.isInteger(message.total_invite_reward.high)
          )
        )
          return "total_invite_reward: integer|Long expected";
      if (message.total_valid_invite != null && message.hasOwnProperty("total_valid_invite"))
        if (!$util.isInteger(message.total_valid_invite)) return "total_valid_invite: integer expected";
      if (message.round_valid_invite != null && message.hasOwnProperty("round_valid_invite"))
        if (!$util.isInteger(message.round_valid_invite)) return "round_valid_invite: integer expected";
      if (message.finish_time != null && message.hasOwnProperty("finish_time"))
        if (
          !$util.isInteger(message.finish_time) &&
          !(
            message.finish_time &&
            $util.isInteger(message.finish_time.low) &&
            $util.isInteger(message.finish_time.high)
          )
        )
          return "finish_time: integer|Long expected";
      if (message.reward_config != null && message.hasOwnProperty("reward_config")) {
        var error = $root.client_proto.InviteConfig.verify(message.reward_config);
        if (error) return "reward_config." + error;
      }
      return null;
    };

    /**
     * Creates a GetInviteRewardResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetInviteRewardResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetInviteRewardResp} GetInviteRewardResp
     */
    GetInviteRewardResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetInviteRewardResp) return object;
      var message = new $root.client_proto.GetInviteRewardResp();
      if (object.today_invite_count != null) message.today_invite_count = object.today_invite_count | 0;
      if (object.total_invite_reward != null)
        if ($util.Long)
          (message.total_invite_reward = $util.Long.fromValue(object.total_invite_reward)).unsigned = false;
        else if (typeof object.total_invite_reward === "string")
          message.total_invite_reward = parseInt(object.total_invite_reward, 10);
        else if (typeof object.total_invite_reward === "number")
          message.total_invite_reward = object.total_invite_reward;
        else if (typeof object.total_invite_reward === "object")
          message.total_invite_reward = new $util.LongBits(
            object.total_invite_reward.low >>> 0,
            object.total_invite_reward.high >>> 0,
          ).toNumber();
      if (object.total_valid_invite != null) message.total_valid_invite = object.total_valid_invite | 0;
      if (object.round_valid_invite != null) message.round_valid_invite = object.round_valid_invite | 0;
      if (object.finish_time != null)
        if ($util.Long) (message.finish_time = $util.Long.fromValue(object.finish_time)).unsigned = false;
        else if (typeof object.finish_time === "string") message.finish_time = parseInt(object.finish_time, 10);
        else if (typeof object.finish_time === "number") message.finish_time = object.finish_time;
        else if (typeof object.finish_time === "object")
          message.finish_time = new $util.LongBits(
            object.finish_time.low >>> 0,
            object.finish_time.high >>> 0,
          ).toNumber();
      if (object.reward_config != null) {
        if (typeof object.reward_config !== "object")
          throw TypeError(".client_proto.GetInviteRewardResp.reward_config: object expected");
        message.reward_config = $root.client_proto.InviteConfig.fromObject(object.reward_config);
      }
      return message;
    };

    /**
     * Creates a plain object from a GetInviteRewardResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetInviteRewardResp
     * @static
     * @param {client_proto.GetInviteRewardResp} message GetInviteRewardResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetInviteRewardResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.today_invite_count = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_invite_reward =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_invite_reward = options.longs === String ? "0" : 0;
        object.total_valid_invite = 0;
        object.round_valid_invite = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.finish_time =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.finish_time = options.longs === String ? "0" : 0;
        object.reward_config = null;
      }
      if (message.today_invite_count != null && message.hasOwnProperty("today_invite_count"))
        object.today_invite_count = message.today_invite_count;
      if (message.total_invite_reward != null && message.hasOwnProperty("total_invite_reward"))
        if (typeof message.total_invite_reward === "number")
          object.total_invite_reward =
            options.longs === String ? String(message.total_invite_reward) : message.total_invite_reward;
        else
          object.total_invite_reward =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_invite_reward)
              : options.longs === Number
                ? new $util.LongBits(
                    message.total_invite_reward.low >>> 0,
                    message.total_invite_reward.high >>> 0,
                  ).toNumber()
                : message.total_invite_reward;
      if (message.total_valid_invite != null && message.hasOwnProperty("total_valid_invite"))
        object.total_valid_invite = message.total_valid_invite;
      if (message.round_valid_invite != null && message.hasOwnProperty("round_valid_invite"))
        object.round_valid_invite = message.round_valid_invite;
      if (message.finish_time != null && message.hasOwnProperty("finish_time"))
        if (typeof message.finish_time === "number")
          object.finish_time = options.longs === String ? String(message.finish_time) : message.finish_time;
        else
          object.finish_time =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.finish_time)
              : options.longs === Number
                ? new $util.LongBits(message.finish_time.low >>> 0, message.finish_time.high >>> 0).toNumber()
                : message.finish_time;
      if (message.reward_config != null && message.hasOwnProperty("reward_config"))
        object.reward_config = $root.client_proto.InviteConfig.toObject(message.reward_config, options);
      return object;
    };

    /**
     * Converts this GetInviteRewardResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetInviteRewardResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetInviteRewardResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetInviteRewardResp
     * @function getTypeUrl
     * @memberof client_proto.GetInviteRewardResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetInviteRewardResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetInviteRewardResp";
    };

    return GetInviteRewardResp;
  })();

  client_proto.InviteItems = (function () {
    /**
     * Properties of an InviteItems.
     * @memberof client_proto
     * @interface IInviteItems
     * @property {number|null} [number] InviteItems number
     * @property {number|null} [flow_multi] InviteItems flow_multi
     * @property {number|null} [bonus] InviteItems bonus
     */

    /**
     * Constructs a new InviteItems.
     * @memberof client_proto
     * @classdesc Represents an InviteItems.
     * @implements IInviteItems
     * @constructor
     * @param {client_proto.IInviteItems=} [properties] Properties to set
     */
    function InviteItems(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * InviteItems number.
     * @member {number} number
     * @memberof client_proto.InviteItems
     * @instance
     */
    InviteItems.prototype.number = 0;

    /**
     * InviteItems flow_multi.
     * @member {number} flow_multi
     * @memberof client_proto.InviteItems
     * @instance
     */
    InviteItems.prototype.flow_multi = 0;

    /**
     * InviteItems bonus.
     * @member {number} bonus
     * @memberof client_proto.InviteItems
     * @instance
     */
    InviteItems.prototype.bonus = 0;

    /**
     * Creates a new InviteItems instance using the specified properties.
     * @function create
     * @memberof client_proto.InviteItems
     * @static
     * @param {client_proto.IInviteItems=} [properties] Properties to set
     * @returns {client_proto.InviteItems} InviteItems instance
     */
    InviteItems.create = function create(properties) {
      return new InviteItems(properties);
    };

    /**
     * Encodes the specified InviteItems message. Does not implicitly {@link client_proto.InviteItems.verify|verify} messages.
     * @function encode
     * @memberof client_proto.InviteItems
     * @static
     * @param {client_proto.IInviteItems} message InviteItems message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InviteItems.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.number != null && Object.hasOwnProperty.call(message, "number"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.number);
      if (message.flow_multi != null && Object.hasOwnProperty.call(message, "flow_multi"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.flow_multi);
      if (message.bonus != null && Object.hasOwnProperty.call(message, "bonus"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.bonus);
      return writer;
    };

    /**
     * Encodes the specified InviteItems message, length delimited. Does not implicitly {@link client_proto.InviteItems.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.InviteItems
     * @static
     * @param {client_proto.IInviteItems} message InviteItems message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InviteItems.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an InviteItems message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.InviteItems
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.InviteItems} InviteItems
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InviteItems.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.InviteItems();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.number = reader.int32();
            break;
          }
          case 2: {
            message.flow_multi = reader.int32();
            break;
          }
          case 3: {
            message.bonus = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an InviteItems message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.InviteItems
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.InviteItems} InviteItems
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InviteItems.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an InviteItems message.
     * @function verify
     * @memberof client_proto.InviteItems
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    InviteItems.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.number != null && message.hasOwnProperty("number"))
        if (!$util.isInteger(message.number)) return "number: integer expected";
      if (message.flow_multi != null && message.hasOwnProperty("flow_multi"))
        if (!$util.isInteger(message.flow_multi)) return "flow_multi: integer expected";
      if (message.bonus != null && message.hasOwnProperty("bonus"))
        if (!$util.isInteger(message.bonus)) return "bonus: integer expected";
      return null;
    };

    /**
     * Creates an InviteItems message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.InviteItems
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.InviteItems} InviteItems
     */
    InviteItems.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.InviteItems) return object;
      var message = new $root.client_proto.InviteItems();
      if (object.number != null) message.number = object.number | 0;
      if (object.flow_multi != null) message.flow_multi = object.flow_multi | 0;
      if (object.bonus != null) message.bonus = object.bonus | 0;
      return message;
    };

    /**
     * Creates a plain object from an InviteItems message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.InviteItems
     * @static
     * @param {client_proto.InviteItems} message InviteItems
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    InviteItems.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.number = 0;
        object.flow_multi = 0;
        object.bonus = 0;
      }
      if (message.number != null && message.hasOwnProperty("number")) object.number = message.number;
      if (message.flow_multi != null && message.hasOwnProperty("flow_multi")) object.flow_multi = message.flow_multi;
      if (message.bonus != null && message.hasOwnProperty("bonus")) object.bonus = message.bonus;
      return object;
    };

    /**
     * Converts this InviteItems to JSON.
     * @function toJSON
     * @memberof client_proto.InviteItems
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    InviteItems.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for InviteItems
     * @function getTypeUrl
     * @memberof client_proto.InviteItems
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    InviteItems.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.InviteItems";
    };

    return InviteItems;
  })();

  client_proto.InviteConfig = (function () {
    /**
     * Properties of an InviteConfig.
     * @memberof client_proto
     * @interface IInviteConfig
     * @property {number|Long|null} [invite_reward] InviteConfig invite_reward
     * @property {number|Long|null} [sub_user_reward] InviteConfig sub_user_reward
     * @property {number|null} [daily_invite_count] InviteConfig daily_invite_count
     * @property {number|null} [time_limit_hour] InviteConfig time_limit_hour
     * @property {Array.<client_proto.IInviteItems>|null} [invite_items] InviteConfig invite_items
     */

    /**
     * Constructs a new InviteConfig.
     * @memberof client_proto
     * @classdesc Represents an InviteConfig.
     * @implements IInviteConfig
     * @constructor
     * @param {client_proto.IInviteConfig=} [properties] Properties to set
     */
    function InviteConfig(properties) {
      this.invite_items = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * InviteConfig invite_reward.
     * @member {number|Long} invite_reward
     * @memberof client_proto.InviteConfig
     * @instance
     */
    InviteConfig.prototype.invite_reward = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * InviteConfig sub_user_reward.
     * @member {number|Long} sub_user_reward
     * @memberof client_proto.InviteConfig
     * @instance
     */
    InviteConfig.prototype.sub_user_reward = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * InviteConfig daily_invite_count.
     * @member {number} daily_invite_count
     * @memberof client_proto.InviteConfig
     * @instance
     */
    InviteConfig.prototype.daily_invite_count = 0;

    /**
     * InviteConfig time_limit_hour.
     * @member {number} time_limit_hour
     * @memberof client_proto.InviteConfig
     * @instance
     */
    InviteConfig.prototype.time_limit_hour = 0;

    /**
     * InviteConfig invite_items.
     * @member {Array.<client_proto.IInviteItems>} invite_items
     * @memberof client_proto.InviteConfig
     * @instance
     */
    InviteConfig.prototype.invite_items = $util.emptyArray;

    /**
     * Creates a new InviteConfig instance using the specified properties.
     * @function create
     * @memberof client_proto.InviteConfig
     * @static
     * @param {client_proto.IInviteConfig=} [properties] Properties to set
     * @returns {client_proto.InviteConfig} InviteConfig instance
     */
    InviteConfig.create = function create(properties) {
      return new InviteConfig(properties);
    };

    /**
     * Encodes the specified InviteConfig message. Does not implicitly {@link client_proto.InviteConfig.verify|verify} messages.
     * @function encode
     * @memberof client_proto.InviteConfig
     * @static
     * @param {client_proto.IInviteConfig} message InviteConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InviteConfig.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.invite_reward != null && Object.hasOwnProperty.call(message, "invite_reward"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.invite_reward);
      if (message.sub_user_reward != null && Object.hasOwnProperty.call(message, "sub_user_reward"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.sub_user_reward);
      if (message.daily_invite_count != null && Object.hasOwnProperty.call(message, "daily_invite_count"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.daily_invite_count);
      if (message.time_limit_hour != null && Object.hasOwnProperty.call(message, "time_limit_hour"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.time_limit_hour);
      if (message.invite_items != null && message.invite_items.length)
        for (var i = 0; i < message.invite_items.length; ++i)
          $root.client_proto.InviteItems.encode(
            message.invite_items[i],
            writer.uint32(/* id 5, wireType 2 =*/ 42).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified InviteConfig message, length delimited. Does not implicitly {@link client_proto.InviteConfig.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.InviteConfig
     * @static
     * @param {client_proto.IInviteConfig} message InviteConfig message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InviteConfig.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an InviteConfig message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.InviteConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.InviteConfig} InviteConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InviteConfig.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.InviteConfig();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.invite_reward = reader.int64();
            break;
          }
          case 2: {
            message.sub_user_reward = reader.int64();
            break;
          }
          case 3: {
            message.daily_invite_count = reader.int32();
            break;
          }
          case 4: {
            message.time_limit_hour = reader.int32();
            break;
          }
          case 5: {
            if (!(message.invite_items && message.invite_items.length)) message.invite_items = [];
            message.invite_items.push($root.client_proto.InviteItems.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an InviteConfig message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.InviteConfig
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.InviteConfig} InviteConfig
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InviteConfig.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an InviteConfig message.
     * @function verify
     * @memberof client_proto.InviteConfig
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    InviteConfig.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.invite_reward != null && message.hasOwnProperty("invite_reward"))
        if (
          !$util.isInteger(message.invite_reward) &&
          !(
            message.invite_reward &&
            $util.isInteger(message.invite_reward.low) &&
            $util.isInteger(message.invite_reward.high)
          )
        )
          return "invite_reward: integer|Long expected";
      if (message.sub_user_reward != null && message.hasOwnProperty("sub_user_reward"))
        if (
          !$util.isInteger(message.sub_user_reward) &&
          !(
            message.sub_user_reward &&
            $util.isInteger(message.sub_user_reward.low) &&
            $util.isInteger(message.sub_user_reward.high)
          )
        )
          return "sub_user_reward: integer|Long expected";
      if (message.daily_invite_count != null && message.hasOwnProperty("daily_invite_count"))
        if (!$util.isInteger(message.daily_invite_count)) return "daily_invite_count: integer expected";
      if (message.time_limit_hour != null && message.hasOwnProperty("time_limit_hour"))
        if (!$util.isInteger(message.time_limit_hour)) return "time_limit_hour: integer expected";
      if (message.invite_items != null && message.hasOwnProperty("invite_items")) {
        if (!Array.isArray(message.invite_items)) return "invite_items: array expected";
        for (var i = 0; i < message.invite_items.length; ++i) {
          var error = $root.client_proto.InviteItems.verify(message.invite_items[i]);
          if (error) return "invite_items." + error;
        }
      }
      return null;
    };

    /**
     * Creates an InviteConfig message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.InviteConfig
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.InviteConfig} InviteConfig
     */
    InviteConfig.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.InviteConfig) return object;
      var message = new $root.client_proto.InviteConfig();
      if (object.invite_reward != null)
        if ($util.Long) (message.invite_reward = $util.Long.fromValue(object.invite_reward)).unsigned = false;
        else if (typeof object.invite_reward === "string") message.invite_reward = parseInt(object.invite_reward, 10);
        else if (typeof object.invite_reward === "number") message.invite_reward = object.invite_reward;
        else if (typeof object.invite_reward === "object")
          message.invite_reward = new $util.LongBits(
            object.invite_reward.low >>> 0,
            object.invite_reward.high >>> 0,
          ).toNumber();
      if (object.sub_user_reward != null)
        if ($util.Long) (message.sub_user_reward = $util.Long.fromValue(object.sub_user_reward)).unsigned = false;
        else if (typeof object.sub_user_reward === "string")
          message.sub_user_reward = parseInt(object.sub_user_reward, 10);
        else if (typeof object.sub_user_reward === "number") message.sub_user_reward = object.sub_user_reward;
        else if (typeof object.sub_user_reward === "object")
          message.sub_user_reward = new $util.LongBits(
            object.sub_user_reward.low >>> 0,
            object.sub_user_reward.high >>> 0,
          ).toNumber();
      if (object.daily_invite_count != null) message.daily_invite_count = object.daily_invite_count | 0;
      if (object.time_limit_hour != null) message.time_limit_hour = object.time_limit_hour | 0;
      if (object.invite_items) {
        if (!Array.isArray(object.invite_items))
          throw TypeError(".client_proto.InviteConfig.invite_items: array expected");
        message.invite_items = [];
        for (var i = 0; i < object.invite_items.length; ++i) {
          if (typeof object.invite_items[i] !== "object")
            throw TypeError(".client_proto.InviteConfig.invite_items: object expected");
          message.invite_items[i] = $root.client_proto.InviteItems.fromObject(object.invite_items[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from an InviteConfig message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.InviteConfig
     * @static
     * @param {client_proto.InviteConfig} message InviteConfig
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    InviteConfig.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.invite_items = [];
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.invite_reward =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.invite_reward = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.sub_user_reward =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.sub_user_reward = options.longs === String ? "0" : 0;
        object.daily_invite_count = 0;
        object.time_limit_hour = 0;
      }
      if (message.invite_reward != null && message.hasOwnProperty("invite_reward"))
        if (typeof message.invite_reward === "number")
          object.invite_reward = options.longs === String ? String(message.invite_reward) : message.invite_reward;
        else
          object.invite_reward =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.invite_reward)
              : options.longs === Number
                ? new $util.LongBits(message.invite_reward.low >>> 0, message.invite_reward.high >>> 0).toNumber()
                : message.invite_reward;
      if (message.sub_user_reward != null && message.hasOwnProperty("sub_user_reward"))
        if (typeof message.sub_user_reward === "number")
          object.sub_user_reward = options.longs === String ? String(message.sub_user_reward) : message.sub_user_reward;
        else
          object.sub_user_reward =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.sub_user_reward)
              : options.longs === Number
                ? new $util.LongBits(message.sub_user_reward.low >>> 0, message.sub_user_reward.high >>> 0).toNumber()
                : message.sub_user_reward;
      if (message.daily_invite_count != null && message.hasOwnProperty("daily_invite_count"))
        object.daily_invite_count = message.daily_invite_count;
      if (message.time_limit_hour != null && message.hasOwnProperty("time_limit_hour"))
        object.time_limit_hour = message.time_limit_hour;
      if (message.invite_items && message.invite_items.length) {
        object.invite_items = [];
        for (var j = 0; j < message.invite_items.length; ++j)
          object.invite_items[j] = $root.client_proto.InviteItems.toObject(message.invite_items[j], options);
      }
      return object;
    };

    /**
     * Converts this InviteConfig to JSON.
     * @function toJSON
     * @memberof client_proto.InviteConfig
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    InviteConfig.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for InviteConfig
     * @function getTypeUrl
     * @memberof client_proto.InviteConfig
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    InviteConfig.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.InviteConfig";
    };

    return InviteConfig;
  })();

  /**
   * SCENE_ID enum.
   * @name client_proto.SCENE_ID
   * @enum {number}
   * @property {number} PLACEHOLDER=0 PLACEHOLDER value
   * @property {number} LOGIN_TO_HALL=1 LOGIN_TO_HALL value
   * @property {number} GAME_EXIT=2 GAME_EXIT value
   */
  client_proto.SCENE_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "PLACEHOLDER")] = 0;
    values[(valuesById[1] = "LOGIN_TO_HALL")] = 1;
    values[(valuesById[2] = "GAME_EXIT")] = 2;
    return values;
  })();

  /**
   * FACE_INFO_SUB_MSG_ID enum.
   * @name client_proto.FACE_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} FACE_MSG_ID_NULL=0 FACE_MSG_ID_NULL value
   * @property {number} FACE_MSG_ID_MIN=2500 FACE_MSG_ID_MIN value
   * @property {number} FACE_MSG_ID_PULL=2501 FACE_MSG_ID_PULL value
   * @property {number} FACE_MSG_ID_PULL_RESP=2502 FACE_MSG_ID_PULL_RESP value
   * @property {number} FACE_MSG_ID_MAX=2550 FACE_MSG_ID_MAX value
   */
  client_proto.FACE_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "FACE_MSG_ID_NULL")] = 0;
    values[(valuesById[2500] = "FACE_MSG_ID_MIN")] = 2500;
    values[(valuesById[2501] = "FACE_MSG_ID_PULL")] = 2501;
    values[(valuesById[2502] = "FACE_MSG_ID_PULL_RESP")] = 2502;
    values[(valuesById[2550] = "FACE_MSG_ID_MAX")] = 2550;
    return values;
  })();

  client_proto.FacePullReq = (function () {
    /**
     * Properties of a FacePullReq.
     * @memberof client_proto
     * @interface IFacePullReq
     * @property {number|null} [uid] FacePullReq uid
     * @property {number|null} [scene_id] FacePullReq scene_id
     * @property {string|null} [language] FacePullReq language
     */

    /**
     * Constructs a new FacePullReq.
     * @memberof client_proto
     * @classdesc Represents a FacePullReq.
     * @implements IFacePullReq
     * @constructor
     * @param {client_proto.IFacePullReq=} [properties] Properties to set
     */
    function FacePullReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FacePullReq uid.
     * @member {number} uid
     * @memberof client_proto.FacePullReq
     * @instance
     */
    FacePullReq.prototype.uid = 0;

    /**
     * FacePullReq scene_id.
     * @member {number} scene_id
     * @memberof client_proto.FacePullReq
     * @instance
     */
    FacePullReq.prototype.scene_id = 0;

    /**
     * FacePullReq language.
     * @member {string} language
     * @memberof client_proto.FacePullReq
     * @instance
     */
    FacePullReq.prototype.language = "";

    /**
     * Creates a new FacePullReq instance using the specified properties.
     * @function create
     * @memberof client_proto.FacePullReq
     * @static
     * @param {client_proto.IFacePullReq=} [properties] Properties to set
     * @returns {client_proto.FacePullReq} FacePullReq instance
     */
    FacePullReq.create = function create(properties) {
      return new FacePullReq(properties);
    };

    /**
     * Encodes the specified FacePullReq message. Does not implicitly {@link client_proto.FacePullReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.FacePullReq
     * @static
     * @param {client_proto.IFacePullReq} message FacePullReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FacePullReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.scene_id != null && Object.hasOwnProperty.call(message, "scene_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.scene_id);
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.language);
      return writer;
    };

    /**
     * Encodes the specified FacePullReq message, length delimited. Does not implicitly {@link client_proto.FacePullReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.FacePullReq
     * @static
     * @param {client_proto.IFacePullReq} message FacePullReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FacePullReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FacePullReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.FacePullReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.FacePullReq} FacePullReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FacePullReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.FacePullReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.scene_id = reader.int32();
            break;
          }
          case 3: {
            message.language = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FacePullReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.FacePullReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.FacePullReq} FacePullReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FacePullReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FacePullReq message.
     * @function verify
     * @memberof client_proto.FacePullReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FacePullReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.scene_id != null && message.hasOwnProperty("scene_id"))
        if (!$util.isInteger(message.scene_id)) return "scene_id: integer expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      return null;
    };

    /**
     * Creates a FacePullReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.FacePullReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.FacePullReq} FacePullReq
     */
    FacePullReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.FacePullReq) return object;
      var message = new $root.client_proto.FacePullReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.scene_id != null) message.scene_id = object.scene_id | 0;
      if (object.language != null) message.language = String(object.language);
      return message;
    };

    /**
     * Creates a plain object from a FacePullReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.FacePullReq
     * @static
     * @param {client_proto.FacePullReq} message FacePullReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FacePullReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.scene_id = 0;
        object.language = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.scene_id != null && message.hasOwnProperty("scene_id")) object.scene_id = message.scene_id;
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      return object;
    };

    /**
     * Converts this FacePullReq to JSON.
     * @function toJSON
     * @memberof client_proto.FacePullReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FacePullReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FacePullReq
     * @function getTypeUrl
     * @memberof client_proto.FacePullReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FacePullReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.FacePullReq";
    };

    return FacePullReq;
  })();

  client_proto.FaceData = (function () {
    /**
     * Properties of a FaceData.
     * @memberof client_proto
     * @interface IFaceData
     * @property {number|null} [face_id] FaceData face_id
     * @property {number|null} [order_id] FaceData order_id
     * @property {number|null} [show_type] FaceData show_type
     * @property {number|null} [show_start_time] FaceData show_start_time
     * @property {number|null} [show_end_time] FaceData show_end_time
     * @property {number|null} [pop_frequency] FaceData pop_frequency
     * @property {number|null} [pop_number] FaceData pop_number
     * @property {string|null} [pic] FaceData pic
     * @property {string|null} [jump] FaceData jump
     * @property {string|null} [jump_button] FaceData jump_button
     * @property {number|null} [jump_type] FaceData jump_type
     * @property {number|null} [is_show_time] FaceData is_show_time
     * @property {number|null} [count_down_show] FaceData count_down_show
     * @property {number|Long|null} [min_coin] FaceData min_coin
     * @property {number|Long|null} [max_coin] FaceData max_coin
     */

    /**
     * Constructs a new FaceData.
     * @memberof client_proto
     * @classdesc Represents a FaceData.
     * @implements IFaceData
     * @constructor
     * @param {client_proto.IFaceData=} [properties] Properties to set
     */
    function FaceData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FaceData face_id.
     * @member {number} face_id
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.face_id = 0;

    /**
     * FaceData order_id.
     * @member {number} order_id
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.order_id = 0;

    /**
     * FaceData show_type.
     * @member {number} show_type
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.show_type = 0;

    /**
     * FaceData show_start_time.
     * @member {number} show_start_time
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.show_start_time = 0;

    /**
     * FaceData show_end_time.
     * @member {number} show_end_time
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.show_end_time = 0;

    /**
     * FaceData pop_frequency.
     * @member {number} pop_frequency
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.pop_frequency = 0;

    /**
     * FaceData pop_number.
     * @member {number} pop_number
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.pop_number = 0;

    /**
     * FaceData pic.
     * @member {string} pic
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.pic = "";

    /**
     * FaceData jump.
     * @member {string} jump
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.jump = "";

    /**
     * FaceData jump_button.
     * @member {string} jump_button
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.jump_button = "";

    /**
     * FaceData jump_type.
     * @member {number} jump_type
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.jump_type = 0;

    /**
     * FaceData is_show_time.
     * @member {number} is_show_time
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.is_show_time = 0;

    /**
     * FaceData count_down_show.
     * @member {number} count_down_show
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.count_down_show = 0;

    /**
     * FaceData min_coin.
     * @member {number|Long} min_coin
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.min_coin = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * FaceData max_coin.
     * @member {number|Long} max_coin
     * @memberof client_proto.FaceData
     * @instance
     */
    FaceData.prototype.max_coin = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new FaceData instance using the specified properties.
     * @function create
     * @memberof client_proto.FaceData
     * @static
     * @param {client_proto.IFaceData=} [properties] Properties to set
     * @returns {client_proto.FaceData} FaceData instance
     */
    FaceData.create = function create(properties) {
      return new FaceData(properties);
    };

    /**
     * Encodes the specified FaceData message. Does not implicitly {@link client_proto.FaceData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.FaceData
     * @static
     * @param {client_proto.IFaceData} message FaceData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FaceData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.face_id != null && Object.hasOwnProperty.call(message, "face_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.face_id);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.order_id);
      if (message.show_type != null && Object.hasOwnProperty.call(message, "show_type"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.show_type);
      if (message.show_start_time != null && Object.hasOwnProperty.call(message, "show_start_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.show_start_time);
      if (message.show_end_time != null && Object.hasOwnProperty.call(message, "show_end_time"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).uint32(message.show_end_time);
      if (message.pop_frequency != null && Object.hasOwnProperty.call(message, "pop_frequency"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.pop_frequency);
      if (message.pop_number != null && Object.hasOwnProperty.call(message, "pop_number"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.pop_number);
      if (message.pic != null && Object.hasOwnProperty.call(message, "pic"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.pic);
      if (message.jump != null && Object.hasOwnProperty.call(message, "jump"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.jump);
      if (message.jump_button != null && Object.hasOwnProperty.call(message, "jump_button"))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.jump_button);
      if (message.jump_type != null && Object.hasOwnProperty.call(message, "jump_type"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int32(message.jump_type);
      if (message.is_show_time != null && Object.hasOwnProperty.call(message, "is_show_time"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).int32(message.is_show_time);
      if (message.count_down_show != null && Object.hasOwnProperty.call(message, "count_down_show"))
        writer.uint32(/* id 13, wireType 0 =*/ 104).int32(message.count_down_show);
      if (message.min_coin != null && Object.hasOwnProperty.call(message, "min_coin"))
        writer.uint32(/* id 14, wireType 0 =*/ 112).int64(message.min_coin);
      if (message.max_coin != null && Object.hasOwnProperty.call(message, "max_coin"))
        writer.uint32(/* id 15, wireType 0 =*/ 120).int64(message.max_coin);
      return writer;
    };

    /**
     * Encodes the specified FaceData message, length delimited. Does not implicitly {@link client_proto.FaceData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.FaceData
     * @static
     * @param {client_proto.IFaceData} message FaceData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FaceData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FaceData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.FaceData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.FaceData} FaceData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FaceData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.FaceData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.face_id = reader.int32();
            break;
          }
          case 2: {
            message.order_id = reader.int32();
            break;
          }
          case 3: {
            message.show_type = reader.int32();
            break;
          }
          case 4: {
            message.show_start_time = reader.uint32();
            break;
          }
          case 5: {
            message.show_end_time = reader.uint32();
            break;
          }
          case 6: {
            message.pop_frequency = reader.int32();
            break;
          }
          case 7: {
            message.pop_number = reader.int32();
            break;
          }
          case 8: {
            message.pic = reader.string();
            break;
          }
          case 9: {
            message.jump = reader.string();
            break;
          }
          case 10: {
            message.jump_button = reader.string();
            break;
          }
          case 11: {
            message.jump_type = reader.int32();
            break;
          }
          case 12: {
            message.is_show_time = reader.int32();
            break;
          }
          case 13: {
            message.count_down_show = reader.int32();
            break;
          }
          case 14: {
            message.min_coin = reader.int64();
            break;
          }
          case 15: {
            message.max_coin = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FaceData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.FaceData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.FaceData} FaceData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FaceData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FaceData message.
     * @function verify
     * @memberof client_proto.FaceData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FaceData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.face_id != null && message.hasOwnProperty("face_id"))
        if (!$util.isInteger(message.face_id)) return "face_id: integer expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.show_type != null && message.hasOwnProperty("show_type"))
        if (!$util.isInteger(message.show_type)) return "show_type: integer expected";
      if (message.show_start_time != null && message.hasOwnProperty("show_start_time"))
        if (!$util.isInteger(message.show_start_time)) return "show_start_time: integer expected";
      if (message.show_end_time != null && message.hasOwnProperty("show_end_time"))
        if (!$util.isInteger(message.show_end_time)) return "show_end_time: integer expected";
      if (message.pop_frequency != null && message.hasOwnProperty("pop_frequency"))
        if (!$util.isInteger(message.pop_frequency)) return "pop_frequency: integer expected";
      if (message.pop_number != null && message.hasOwnProperty("pop_number"))
        if (!$util.isInteger(message.pop_number)) return "pop_number: integer expected";
      if (message.pic != null && message.hasOwnProperty("pic"))
        if (!$util.isString(message.pic)) return "pic: string expected";
      if (message.jump != null && message.hasOwnProperty("jump"))
        if (!$util.isString(message.jump)) return "jump: string expected";
      if (message.jump_button != null && message.hasOwnProperty("jump_button"))
        if (!$util.isString(message.jump_button)) return "jump_button: string expected";
      if (message.jump_type != null && message.hasOwnProperty("jump_type"))
        if (!$util.isInteger(message.jump_type)) return "jump_type: integer expected";
      if (message.is_show_time != null && message.hasOwnProperty("is_show_time"))
        if (!$util.isInteger(message.is_show_time)) return "is_show_time: integer expected";
      if (message.count_down_show != null && message.hasOwnProperty("count_down_show"))
        if (!$util.isInteger(message.count_down_show)) return "count_down_show: integer expected";
      if (message.min_coin != null && message.hasOwnProperty("min_coin"))
        if (
          !$util.isInteger(message.min_coin) &&
          !(message.min_coin && $util.isInteger(message.min_coin.low) && $util.isInteger(message.min_coin.high))
        )
          return "min_coin: integer|Long expected";
      if (message.max_coin != null && message.hasOwnProperty("max_coin"))
        if (
          !$util.isInteger(message.max_coin) &&
          !(message.max_coin && $util.isInteger(message.max_coin.low) && $util.isInteger(message.max_coin.high))
        )
          return "max_coin: integer|Long expected";
      return null;
    };

    /**
     * Creates a FaceData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.FaceData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.FaceData} FaceData
     */
    FaceData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.FaceData) return object;
      var message = new $root.client_proto.FaceData();
      if (object.face_id != null) message.face_id = object.face_id | 0;
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.show_type != null) message.show_type = object.show_type | 0;
      if (object.show_start_time != null) message.show_start_time = object.show_start_time >>> 0;
      if (object.show_end_time != null) message.show_end_time = object.show_end_time >>> 0;
      if (object.pop_frequency != null) message.pop_frequency = object.pop_frequency | 0;
      if (object.pop_number != null) message.pop_number = object.pop_number | 0;
      if (object.pic != null) message.pic = String(object.pic);
      if (object.jump != null) message.jump = String(object.jump);
      if (object.jump_button != null) message.jump_button = String(object.jump_button);
      if (object.jump_type != null) message.jump_type = object.jump_type | 0;
      if (object.is_show_time != null) message.is_show_time = object.is_show_time | 0;
      if (object.count_down_show != null) message.count_down_show = object.count_down_show | 0;
      if (object.min_coin != null)
        if ($util.Long) (message.min_coin = $util.Long.fromValue(object.min_coin)).unsigned = false;
        else if (typeof object.min_coin === "string") message.min_coin = parseInt(object.min_coin, 10);
        else if (typeof object.min_coin === "number") message.min_coin = object.min_coin;
        else if (typeof object.min_coin === "object")
          message.min_coin = new $util.LongBits(object.min_coin.low >>> 0, object.min_coin.high >>> 0).toNumber();
      if (object.max_coin != null)
        if ($util.Long) (message.max_coin = $util.Long.fromValue(object.max_coin)).unsigned = false;
        else if (typeof object.max_coin === "string") message.max_coin = parseInt(object.max_coin, 10);
        else if (typeof object.max_coin === "number") message.max_coin = object.max_coin;
        else if (typeof object.max_coin === "object")
          message.max_coin = new $util.LongBits(object.max_coin.low >>> 0, object.max_coin.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a FaceData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.FaceData
     * @static
     * @param {client_proto.FaceData} message FaceData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FaceData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.face_id = 0;
        object.order_id = 0;
        object.show_type = 0;
        object.show_start_time = 0;
        object.show_end_time = 0;
        object.pop_frequency = 0;
        object.pop_number = 0;
        object.pic = "";
        object.jump = "";
        object.jump_button = "";
        object.jump_type = 0;
        object.is_show_time = 0;
        object.count_down_show = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.min_coin =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.min_coin = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.max_coin =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.max_coin = options.longs === String ? "0" : 0;
      }
      if (message.face_id != null && message.hasOwnProperty("face_id")) object.face_id = message.face_id;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.show_type != null && message.hasOwnProperty("show_type")) object.show_type = message.show_type;
      if (message.show_start_time != null && message.hasOwnProperty("show_start_time"))
        object.show_start_time = message.show_start_time;
      if (message.show_end_time != null && message.hasOwnProperty("show_end_time"))
        object.show_end_time = message.show_end_time;
      if (message.pop_frequency != null && message.hasOwnProperty("pop_frequency"))
        object.pop_frequency = message.pop_frequency;
      if (message.pop_number != null && message.hasOwnProperty("pop_number")) object.pop_number = message.pop_number;
      if (message.pic != null && message.hasOwnProperty("pic")) object.pic = message.pic;
      if (message.jump != null && message.hasOwnProperty("jump")) object.jump = message.jump;
      if (message.jump_button != null && message.hasOwnProperty("jump_button"))
        object.jump_button = message.jump_button;
      if (message.jump_type != null && message.hasOwnProperty("jump_type")) object.jump_type = message.jump_type;
      if (message.is_show_time != null && message.hasOwnProperty("is_show_time"))
        object.is_show_time = message.is_show_time;
      if (message.count_down_show != null && message.hasOwnProperty("count_down_show"))
        object.count_down_show = message.count_down_show;
      if (message.min_coin != null && message.hasOwnProperty("min_coin"))
        if (typeof message.min_coin === "number")
          object.min_coin = options.longs === String ? String(message.min_coin) : message.min_coin;
        else
          object.min_coin =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.min_coin)
              : options.longs === Number
                ? new $util.LongBits(message.min_coin.low >>> 0, message.min_coin.high >>> 0).toNumber()
                : message.min_coin;
      if (message.max_coin != null && message.hasOwnProperty("max_coin"))
        if (typeof message.max_coin === "number")
          object.max_coin = options.longs === String ? String(message.max_coin) : message.max_coin;
        else
          object.max_coin =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.max_coin)
              : options.longs === Number
                ? new $util.LongBits(message.max_coin.low >>> 0, message.max_coin.high >>> 0).toNumber()
                : message.max_coin;
      return object;
    };

    /**
     * Converts this FaceData to JSON.
     * @function toJSON
     * @memberof client_proto.FaceData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FaceData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FaceData
     * @function getTypeUrl
     * @memberof client_proto.FaceData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FaceData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.FaceData";
    };

    return FaceData;
  })();

  client_proto.FacePullResp = (function () {
    /**
     * Properties of a FacePullResp.
     * @memberof client_proto
     * @interface IFacePullResp
     * @property {Array.<client_proto.IFaceData>|null} [data_list] FacePullResp data_list
     * @property {number|null} [scene_id] FacePullResp scene_id
     * @property {string|null} [language] FacePullResp language
     */

    /**
     * Constructs a new FacePullResp.
     * @memberof client_proto
     * @classdesc Represents a FacePullResp.
     * @implements IFacePullResp
     * @constructor
     * @param {client_proto.IFacePullResp=} [properties] Properties to set
     */
    function FacePullResp(properties) {
      this.data_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FacePullResp data_list.
     * @member {Array.<client_proto.IFaceData>} data_list
     * @memberof client_proto.FacePullResp
     * @instance
     */
    FacePullResp.prototype.data_list = $util.emptyArray;

    /**
     * FacePullResp scene_id.
     * @member {number} scene_id
     * @memberof client_proto.FacePullResp
     * @instance
     */
    FacePullResp.prototype.scene_id = 0;

    /**
     * FacePullResp language.
     * @member {string} language
     * @memberof client_proto.FacePullResp
     * @instance
     */
    FacePullResp.prototype.language = "";

    /**
     * Creates a new FacePullResp instance using the specified properties.
     * @function create
     * @memberof client_proto.FacePullResp
     * @static
     * @param {client_proto.IFacePullResp=} [properties] Properties to set
     * @returns {client_proto.FacePullResp} FacePullResp instance
     */
    FacePullResp.create = function create(properties) {
      return new FacePullResp(properties);
    };

    /**
     * Encodes the specified FacePullResp message. Does not implicitly {@link client_proto.FacePullResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.FacePullResp
     * @static
     * @param {client_proto.IFacePullResp} message FacePullResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FacePullResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data_list != null && message.data_list.length)
        for (var i = 0; i < message.data_list.length; ++i)
          $root.client_proto.FaceData.encode(
            message.data_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.scene_id != null && Object.hasOwnProperty.call(message, "scene_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.scene_id);
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.language);
      return writer;
    };

    /**
     * Encodes the specified FacePullResp message, length delimited. Does not implicitly {@link client_proto.FacePullResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.FacePullResp
     * @static
     * @param {client_proto.IFacePullResp} message FacePullResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FacePullResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FacePullResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.FacePullResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.FacePullResp} FacePullResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FacePullResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.FacePullResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.data_list && message.data_list.length)) message.data_list = [];
            message.data_list.push($root.client_proto.FaceData.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.scene_id = reader.int32();
            break;
          }
          case 3: {
            message.language = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FacePullResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.FacePullResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.FacePullResp} FacePullResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FacePullResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FacePullResp message.
     * @function verify
     * @memberof client_proto.FacePullResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FacePullResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data_list != null && message.hasOwnProperty("data_list")) {
        if (!Array.isArray(message.data_list)) return "data_list: array expected";
        for (var i = 0; i < message.data_list.length; ++i) {
          var error = $root.client_proto.FaceData.verify(message.data_list[i]);
          if (error) return "data_list." + error;
        }
      }
      if (message.scene_id != null && message.hasOwnProperty("scene_id"))
        if (!$util.isInteger(message.scene_id)) return "scene_id: integer expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      return null;
    };

    /**
     * Creates a FacePullResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.FacePullResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.FacePullResp} FacePullResp
     */
    FacePullResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.FacePullResp) return object;
      var message = new $root.client_proto.FacePullResp();
      if (object.data_list) {
        if (!Array.isArray(object.data_list)) throw TypeError(".client_proto.FacePullResp.data_list: array expected");
        message.data_list = [];
        for (var i = 0; i < object.data_list.length; ++i) {
          if (typeof object.data_list[i] !== "object")
            throw TypeError(".client_proto.FacePullResp.data_list: object expected");
          message.data_list[i] = $root.client_proto.FaceData.fromObject(object.data_list[i]);
        }
      }
      if (object.scene_id != null) message.scene_id = object.scene_id | 0;
      if (object.language != null) message.language = String(object.language);
      return message;
    };

    /**
     * Creates a plain object from a FacePullResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.FacePullResp
     * @static
     * @param {client_proto.FacePullResp} message FacePullResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FacePullResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.data_list = [];
      if (options.defaults) {
        object.scene_id = 0;
        object.language = "";
      }
      if (message.data_list && message.data_list.length) {
        object.data_list = [];
        for (var j = 0; j < message.data_list.length; ++j)
          object.data_list[j] = $root.client_proto.FaceData.toObject(message.data_list[j], options);
      }
      if (message.scene_id != null && message.hasOwnProperty("scene_id")) object.scene_id = message.scene_id;
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      return object;
    };

    /**
     * Converts this FacePullResp to JSON.
     * @function toJSON
     * @memberof client_proto.FacePullResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FacePullResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FacePullResp
     * @function getTypeUrl
     * @memberof client_proto.FacePullResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FacePullResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.FacePullResp";
    };

    return FacePullResp;
  })();

  /**
   * BANNER_INFO_SUB_MSG_ID enum.
   * @name client_proto.BANNER_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} BANNER_MSG_ID_NULL=0 BANNER_MSG_ID_NULL value
   * @property {number} BANNER_MSG_ID_MIN=2600 BANNER_MSG_ID_MIN value
   * @property {number} BANNER_MSG_ID_PULL_HALL=2601 BANNER_MSG_ID_PULL_HALL value
   * @property {number} BANNER_MSG_ID_PULL_HALL_RESP=2602 BANNER_MSG_ID_PULL_HALL_RESP value
   * @property {number} BANNER_MSG_ID_MAX=2650 BANNER_MSG_ID_MAX value
   */
  client_proto.BANNER_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "BANNER_MSG_ID_NULL")] = 0;
    values[(valuesById[2600] = "BANNER_MSG_ID_MIN")] = 2600;
    values[(valuesById[2601] = "BANNER_MSG_ID_PULL_HALL")] = 2601;
    values[(valuesById[2602] = "BANNER_MSG_ID_PULL_HALL_RESP")] = 2602;
    values[(valuesById[2650] = "BANNER_MSG_ID_MAX")] = 2650;
    return values;
  })();

  client_proto.BannerData = (function () {
    /**
     * Properties of a BannerData.
     * @memberof client_proto
     * @interface IBannerData
     * @property {number|null} [banner_id] BannerData banner_id
     * @property {number|null} [order_id] BannerData order_id
     * @property {number|null} [banner_level] BannerData banner_level
     * @property {number|null} [template_id] BannerData template_id
     * @property {number|null} [jump_type] BannerData jump_type
     * @property {string|null} [title] BannerData title
     * @property {string|null} [pic] BannerData pic
     * @property {string|null} [jump] BannerData jump
     * @property {string|null} [jump_button] BannerData jump_button
     * @property {number|null} [is_show_time] BannerData is_show_time
     * @property {number|null} [count_down_show] BannerData count_down_show
     * @property {number|null} [show_type] BannerData show_type
     * @property {number|null} [show_start_time] BannerData show_start_time
     * @property {number|null} [show_end_time] BannerData show_end_time
     */

    /**
     * Constructs a new BannerData.
     * @memberof client_proto
     * @classdesc Represents a BannerData.
     * @implements IBannerData
     * @constructor
     * @param {client_proto.IBannerData=} [properties] Properties to set
     */
    function BannerData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * BannerData banner_id.
     * @member {number} banner_id
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.banner_id = 0;

    /**
     * BannerData order_id.
     * @member {number} order_id
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.order_id = 0;

    /**
     * BannerData banner_level.
     * @member {number} banner_level
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.banner_level = 0;

    /**
     * BannerData template_id.
     * @member {number} template_id
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.template_id = 0;

    /**
     * BannerData jump_type.
     * @member {number} jump_type
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.jump_type = 0;

    /**
     * BannerData title.
     * @member {string} title
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.title = "";

    /**
     * BannerData pic.
     * @member {string} pic
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.pic = "";

    /**
     * BannerData jump.
     * @member {string} jump
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.jump = "";

    /**
     * BannerData jump_button.
     * @member {string} jump_button
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.jump_button = "";

    /**
     * BannerData is_show_time.
     * @member {number} is_show_time
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.is_show_time = 0;

    /**
     * BannerData count_down_show.
     * @member {number} count_down_show
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.count_down_show = 0;

    /**
     * BannerData show_type.
     * @member {number} show_type
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.show_type = 0;

    /**
     * BannerData show_start_time.
     * @member {number} show_start_time
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.show_start_time = 0;

    /**
     * BannerData show_end_time.
     * @member {number} show_end_time
     * @memberof client_proto.BannerData
     * @instance
     */
    BannerData.prototype.show_end_time = 0;

    /**
     * Creates a new BannerData instance using the specified properties.
     * @function create
     * @memberof client_proto.BannerData
     * @static
     * @param {client_proto.IBannerData=} [properties] Properties to set
     * @returns {client_proto.BannerData} BannerData instance
     */
    BannerData.create = function create(properties) {
      return new BannerData(properties);
    };

    /**
     * Encodes the specified BannerData message. Does not implicitly {@link client_proto.BannerData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.BannerData
     * @static
     * @param {client_proto.IBannerData} message BannerData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BannerData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.banner_id != null && Object.hasOwnProperty.call(message, "banner_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.banner_id);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.order_id);
      if (message.banner_level != null && Object.hasOwnProperty.call(message, "banner_level"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.banner_level);
      if (message.template_id != null && Object.hasOwnProperty.call(message, "template_id"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.template_id);
      if (message.jump_type != null && Object.hasOwnProperty.call(message, "jump_type"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.jump_type);
      if (message.title != null && Object.hasOwnProperty.call(message, "title"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.title);
      if (message.pic != null && Object.hasOwnProperty.call(message, "pic"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.pic);
      if (message.jump != null && Object.hasOwnProperty.call(message, "jump"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.jump);
      if (message.jump_button != null && Object.hasOwnProperty.call(message, "jump_button"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.jump_button);
      if (message.is_show_time != null && Object.hasOwnProperty.call(message, "is_show_time"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int32(message.is_show_time);
      if (message.count_down_show != null && Object.hasOwnProperty.call(message, "count_down_show"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int32(message.count_down_show);
      if (message.show_type != null && Object.hasOwnProperty.call(message, "show_type"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).int32(message.show_type);
      if (message.show_start_time != null && Object.hasOwnProperty.call(message, "show_start_time"))
        writer.uint32(/* id 13, wireType 0 =*/ 104).uint32(message.show_start_time);
      if (message.show_end_time != null && Object.hasOwnProperty.call(message, "show_end_time"))
        writer.uint32(/* id 14, wireType 0 =*/ 112).uint32(message.show_end_time);
      return writer;
    };

    /**
     * Encodes the specified BannerData message, length delimited. Does not implicitly {@link client_proto.BannerData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.BannerData
     * @static
     * @param {client_proto.IBannerData} message BannerData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BannerData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BannerData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.BannerData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.BannerData} BannerData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BannerData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.BannerData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.banner_id = reader.int32();
            break;
          }
          case 2: {
            message.order_id = reader.int32();
            break;
          }
          case 3: {
            message.banner_level = reader.int32();
            break;
          }
          case 4: {
            message.template_id = reader.int32();
            break;
          }
          case 5: {
            message.jump_type = reader.int32();
            break;
          }
          case 6: {
            message.title = reader.string();
            break;
          }
          case 7: {
            message.pic = reader.string();
            break;
          }
          case 8: {
            message.jump = reader.string();
            break;
          }
          case 9: {
            message.jump_button = reader.string();
            break;
          }
          case 10: {
            message.is_show_time = reader.int32();
            break;
          }
          case 11: {
            message.count_down_show = reader.int32();
            break;
          }
          case 12: {
            message.show_type = reader.int32();
            break;
          }
          case 13: {
            message.show_start_time = reader.uint32();
            break;
          }
          case 14: {
            message.show_end_time = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a BannerData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.BannerData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.BannerData} BannerData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BannerData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BannerData message.
     * @function verify
     * @memberof client_proto.BannerData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BannerData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.banner_id != null && message.hasOwnProperty("banner_id"))
        if (!$util.isInteger(message.banner_id)) return "banner_id: integer expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.banner_level != null && message.hasOwnProperty("banner_level"))
        if (!$util.isInteger(message.banner_level)) return "banner_level: integer expected";
      if (message.template_id != null && message.hasOwnProperty("template_id"))
        if (!$util.isInteger(message.template_id)) return "template_id: integer expected";
      if (message.jump_type != null && message.hasOwnProperty("jump_type"))
        if (!$util.isInteger(message.jump_type)) return "jump_type: integer expected";
      if (message.title != null && message.hasOwnProperty("title"))
        if (!$util.isString(message.title)) return "title: string expected";
      if (message.pic != null && message.hasOwnProperty("pic"))
        if (!$util.isString(message.pic)) return "pic: string expected";
      if (message.jump != null && message.hasOwnProperty("jump"))
        if (!$util.isString(message.jump)) return "jump: string expected";
      if (message.jump_button != null && message.hasOwnProperty("jump_button"))
        if (!$util.isString(message.jump_button)) return "jump_button: string expected";
      if (message.is_show_time != null && message.hasOwnProperty("is_show_time"))
        if (!$util.isInteger(message.is_show_time)) return "is_show_time: integer expected";
      if (message.count_down_show != null && message.hasOwnProperty("count_down_show"))
        if (!$util.isInteger(message.count_down_show)) return "count_down_show: integer expected";
      if (message.show_type != null && message.hasOwnProperty("show_type"))
        if (!$util.isInteger(message.show_type)) return "show_type: integer expected";
      if (message.show_start_time != null && message.hasOwnProperty("show_start_time"))
        if (!$util.isInteger(message.show_start_time)) return "show_start_time: integer expected";
      if (message.show_end_time != null && message.hasOwnProperty("show_end_time"))
        if (!$util.isInteger(message.show_end_time)) return "show_end_time: integer expected";
      return null;
    };

    /**
     * Creates a BannerData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.BannerData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.BannerData} BannerData
     */
    BannerData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.BannerData) return object;
      var message = new $root.client_proto.BannerData();
      if (object.banner_id != null) message.banner_id = object.banner_id | 0;
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.banner_level != null) message.banner_level = object.banner_level | 0;
      if (object.template_id != null) message.template_id = object.template_id | 0;
      if (object.jump_type != null) message.jump_type = object.jump_type | 0;
      if (object.title != null) message.title = String(object.title);
      if (object.pic != null) message.pic = String(object.pic);
      if (object.jump != null) message.jump = String(object.jump);
      if (object.jump_button != null) message.jump_button = String(object.jump_button);
      if (object.is_show_time != null) message.is_show_time = object.is_show_time | 0;
      if (object.count_down_show != null) message.count_down_show = object.count_down_show | 0;
      if (object.show_type != null) message.show_type = object.show_type | 0;
      if (object.show_start_time != null) message.show_start_time = object.show_start_time >>> 0;
      if (object.show_end_time != null) message.show_end_time = object.show_end_time >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a BannerData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.BannerData
     * @static
     * @param {client_proto.BannerData} message BannerData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BannerData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.banner_id = 0;
        object.order_id = 0;
        object.banner_level = 0;
        object.template_id = 0;
        object.jump_type = 0;
        object.title = "";
        object.pic = "";
        object.jump = "";
        object.jump_button = "";
        object.is_show_time = 0;
        object.count_down_show = 0;
        object.show_type = 0;
        object.show_start_time = 0;
        object.show_end_time = 0;
      }
      if (message.banner_id != null && message.hasOwnProperty("banner_id")) object.banner_id = message.banner_id;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.banner_level != null && message.hasOwnProperty("banner_level"))
        object.banner_level = message.banner_level;
      if (message.template_id != null && message.hasOwnProperty("template_id"))
        object.template_id = message.template_id;
      if (message.jump_type != null && message.hasOwnProperty("jump_type")) object.jump_type = message.jump_type;
      if (message.title != null && message.hasOwnProperty("title")) object.title = message.title;
      if (message.pic != null && message.hasOwnProperty("pic")) object.pic = message.pic;
      if (message.jump != null && message.hasOwnProperty("jump")) object.jump = message.jump;
      if (message.jump_button != null && message.hasOwnProperty("jump_button"))
        object.jump_button = message.jump_button;
      if (message.is_show_time != null && message.hasOwnProperty("is_show_time"))
        object.is_show_time = message.is_show_time;
      if (message.count_down_show != null && message.hasOwnProperty("count_down_show"))
        object.count_down_show = message.count_down_show;
      if (message.show_type != null && message.hasOwnProperty("show_type")) object.show_type = message.show_type;
      if (message.show_start_time != null && message.hasOwnProperty("show_start_time"))
        object.show_start_time = message.show_start_time;
      if (message.show_end_time != null && message.hasOwnProperty("show_end_time"))
        object.show_end_time = message.show_end_time;
      return object;
    };

    /**
     * Converts this BannerData to JSON.
     * @function toJSON
     * @memberof client_proto.BannerData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BannerData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BannerData
     * @function getTypeUrl
     * @memberof client_proto.BannerData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BannerData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.BannerData";
    };

    return BannerData;
  })();

  client_proto.BannerHallReq = (function () {
    /**
     * Properties of a BannerHallReq.
     * @memberof client_proto
     * @interface IBannerHallReq
     * @property {number|null} [uid] BannerHallReq uid
     * @property {number|null} [banner_level] BannerHallReq banner_level
     * @property {string|null} [language] BannerHallReq language
     * @property {number|null} [plat_type] BannerHallReq plat_type
     */

    /**
     * Constructs a new BannerHallReq.
     * @memberof client_proto
     * @classdesc Represents a BannerHallReq.
     * @implements IBannerHallReq
     * @constructor
     * @param {client_proto.IBannerHallReq=} [properties] Properties to set
     */
    function BannerHallReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * BannerHallReq uid.
     * @member {number} uid
     * @memberof client_proto.BannerHallReq
     * @instance
     */
    BannerHallReq.prototype.uid = 0;

    /**
     * BannerHallReq banner_level.
     * @member {number} banner_level
     * @memberof client_proto.BannerHallReq
     * @instance
     */
    BannerHallReq.prototype.banner_level = 0;

    /**
     * BannerHallReq language.
     * @member {string} language
     * @memberof client_proto.BannerHallReq
     * @instance
     */
    BannerHallReq.prototype.language = "";

    /**
     * BannerHallReq plat_type.
     * @member {number} plat_type
     * @memberof client_proto.BannerHallReq
     * @instance
     */
    BannerHallReq.prototype.plat_type = 0;

    /**
     * Creates a new BannerHallReq instance using the specified properties.
     * @function create
     * @memberof client_proto.BannerHallReq
     * @static
     * @param {client_proto.IBannerHallReq=} [properties] Properties to set
     * @returns {client_proto.BannerHallReq} BannerHallReq instance
     */
    BannerHallReq.create = function create(properties) {
      return new BannerHallReq(properties);
    };

    /**
     * Encodes the specified BannerHallReq message. Does not implicitly {@link client_proto.BannerHallReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.BannerHallReq
     * @static
     * @param {client_proto.IBannerHallReq} message BannerHallReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BannerHallReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.banner_level != null && Object.hasOwnProperty.call(message, "banner_level"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.banner_level);
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.language);
      if (message.plat_type != null && Object.hasOwnProperty.call(message, "plat_type"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.plat_type);
      return writer;
    };

    /**
     * Encodes the specified BannerHallReq message, length delimited. Does not implicitly {@link client_proto.BannerHallReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.BannerHallReq
     * @static
     * @param {client_proto.IBannerHallReq} message BannerHallReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BannerHallReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BannerHallReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.BannerHallReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.BannerHallReq} BannerHallReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BannerHallReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.BannerHallReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.banner_level = reader.int32();
            break;
          }
          case 3: {
            message.language = reader.string();
            break;
          }
          case 4: {
            message.plat_type = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a BannerHallReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.BannerHallReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.BannerHallReq} BannerHallReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BannerHallReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BannerHallReq message.
     * @function verify
     * @memberof client_proto.BannerHallReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BannerHallReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.banner_level != null && message.hasOwnProperty("banner_level"))
        if (!$util.isInteger(message.banner_level)) return "banner_level: integer expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      if (message.plat_type != null && message.hasOwnProperty("plat_type"))
        if (!$util.isInteger(message.plat_type)) return "plat_type: integer expected";
      return null;
    };

    /**
     * Creates a BannerHallReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.BannerHallReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.BannerHallReq} BannerHallReq
     */
    BannerHallReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.BannerHallReq) return object;
      var message = new $root.client_proto.BannerHallReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.banner_level != null) message.banner_level = object.banner_level | 0;
      if (object.language != null) message.language = String(object.language);
      if (object.plat_type != null) message.plat_type = object.plat_type | 0;
      return message;
    };

    /**
     * Creates a plain object from a BannerHallReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.BannerHallReq
     * @static
     * @param {client_proto.BannerHallReq} message BannerHallReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BannerHallReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.banner_level = 0;
        object.language = "";
        object.plat_type = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.banner_level != null && message.hasOwnProperty("banner_level"))
        object.banner_level = message.banner_level;
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      if (message.plat_type != null && message.hasOwnProperty("plat_type")) object.plat_type = message.plat_type;
      return object;
    };

    /**
     * Converts this BannerHallReq to JSON.
     * @function toJSON
     * @memberof client_proto.BannerHallReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BannerHallReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BannerHallReq
     * @function getTypeUrl
     * @memberof client_proto.BannerHallReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BannerHallReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.BannerHallReq";
    };

    return BannerHallReq;
  })();

  client_proto.BannerHallResp = (function () {
    /**
     * Properties of a BannerHallResp.
     * @memberof client_proto
     * @interface IBannerHallResp
     * @property {Array.<client_proto.IBannerData>|null} [data_list] BannerHallResp data_list
     * @property {number|null} [banner_level] BannerHallResp banner_level
     * @property {string|null} [language] BannerHallResp language
     * @property {number|null} [server_timestamp] BannerHallResp server_timestamp
     * @property {number|null} [today_left_time] BannerHallResp today_left_time
     */

    /**
     * Constructs a new BannerHallResp.
     * @memberof client_proto
     * @classdesc Represents a BannerHallResp.
     * @implements IBannerHallResp
     * @constructor
     * @param {client_proto.IBannerHallResp=} [properties] Properties to set
     */
    function BannerHallResp(properties) {
      this.data_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * BannerHallResp data_list.
     * @member {Array.<client_proto.IBannerData>} data_list
     * @memberof client_proto.BannerHallResp
     * @instance
     */
    BannerHallResp.prototype.data_list = $util.emptyArray;

    /**
     * BannerHallResp banner_level.
     * @member {number} banner_level
     * @memberof client_proto.BannerHallResp
     * @instance
     */
    BannerHallResp.prototype.banner_level = 0;

    /**
     * BannerHallResp language.
     * @member {string} language
     * @memberof client_proto.BannerHallResp
     * @instance
     */
    BannerHallResp.prototype.language = "";

    /**
     * BannerHallResp server_timestamp.
     * @member {number} server_timestamp
     * @memberof client_proto.BannerHallResp
     * @instance
     */
    BannerHallResp.prototype.server_timestamp = 0;

    /**
     * BannerHallResp today_left_time.
     * @member {number} today_left_time
     * @memberof client_proto.BannerHallResp
     * @instance
     */
    BannerHallResp.prototype.today_left_time = 0;

    /**
     * Creates a new BannerHallResp instance using the specified properties.
     * @function create
     * @memberof client_proto.BannerHallResp
     * @static
     * @param {client_proto.IBannerHallResp=} [properties] Properties to set
     * @returns {client_proto.BannerHallResp} BannerHallResp instance
     */
    BannerHallResp.create = function create(properties) {
      return new BannerHallResp(properties);
    };

    /**
     * Encodes the specified BannerHallResp message. Does not implicitly {@link client_proto.BannerHallResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.BannerHallResp
     * @static
     * @param {client_proto.IBannerHallResp} message BannerHallResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BannerHallResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data_list != null && message.data_list.length)
        for (var i = 0; i < message.data_list.length; ++i)
          $root.client_proto.BannerData.encode(
            message.data_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.banner_level != null && Object.hasOwnProperty.call(message, "banner_level"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.banner_level);
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.language);
      if (message.server_timestamp != null && Object.hasOwnProperty.call(message, "server_timestamp"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.server_timestamp);
      if (message.today_left_time != null && Object.hasOwnProperty.call(message, "today_left_time"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).uint32(message.today_left_time);
      return writer;
    };

    /**
     * Encodes the specified BannerHallResp message, length delimited. Does not implicitly {@link client_proto.BannerHallResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.BannerHallResp
     * @static
     * @param {client_proto.IBannerHallResp} message BannerHallResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BannerHallResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BannerHallResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.BannerHallResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.BannerHallResp} BannerHallResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BannerHallResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.BannerHallResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.data_list && message.data_list.length)) message.data_list = [];
            message.data_list.push($root.client_proto.BannerData.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.banner_level = reader.int32();
            break;
          }
          case 3: {
            message.language = reader.string();
            break;
          }
          case 4: {
            message.server_timestamp = reader.uint32();
            break;
          }
          case 5: {
            message.today_left_time = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a BannerHallResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.BannerHallResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.BannerHallResp} BannerHallResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BannerHallResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BannerHallResp message.
     * @function verify
     * @memberof client_proto.BannerHallResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BannerHallResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data_list != null && message.hasOwnProperty("data_list")) {
        if (!Array.isArray(message.data_list)) return "data_list: array expected";
        for (var i = 0; i < message.data_list.length; ++i) {
          var error = $root.client_proto.BannerData.verify(message.data_list[i]);
          if (error) return "data_list." + error;
        }
      }
      if (message.banner_level != null && message.hasOwnProperty("banner_level"))
        if (!$util.isInteger(message.banner_level)) return "banner_level: integer expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      if (message.server_timestamp != null && message.hasOwnProperty("server_timestamp"))
        if (!$util.isInteger(message.server_timestamp)) return "server_timestamp: integer expected";
      if (message.today_left_time != null && message.hasOwnProperty("today_left_time"))
        if (!$util.isInteger(message.today_left_time)) return "today_left_time: integer expected";
      return null;
    };

    /**
     * Creates a BannerHallResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.BannerHallResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.BannerHallResp} BannerHallResp
     */
    BannerHallResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.BannerHallResp) return object;
      var message = new $root.client_proto.BannerHallResp();
      if (object.data_list) {
        if (!Array.isArray(object.data_list)) throw TypeError(".client_proto.BannerHallResp.data_list: array expected");
        message.data_list = [];
        for (var i = 0; i < object.data_list.length; ++i) {
          if (typeof object.data_list[i] !== "object")
            throw TypeError(".client_proto.BannerHallResp.data_list: object expected");
          message.data_list[i] = $root.client_proto.BannerData.fromObject(object.data_list[i]);
        }
      }
      if (object.banner_level != null) message.banner_level = object.banner_level | 0;
      if (object.language != null) message.language = String(object.language);
      if (object.server_timestamp != null) message.server_timestamp = object.server_timestamp >>> 0;
      if (object.today_left_time != null) message.today_left_time = object.today_left_time >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a BannerHallResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.BannerHallResp
     * @static
     * @param {client_proto.BannerHallResp} message BannerHallResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BannerHallResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.data_list = [];
      if (options.defaults) {
        object.banner_level = 0;
        object.language = "";
        object.server_timestamp = 0;
        object.today_left_time = 0;
      }
      if (message.data_list && message.data_list.length) {
        object.data_list = [];
        for (var j = 0; j < message.data_list.length; ++j)
          object.data_list[j] = $root.client_proto.BannerData.toObject(message.data_list[j], options);
      }
      if (message.banner_level != null && message.hasOwnProperty("banner_level"))
        object.banner_level = message.banner_level;
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      if (message.server_timestamp != null && message.hasOwnProperty("server_timestamp"))
        object.server_timestamp = message.server_timestamp;
      if (message.today_left_time != null && message.hasOwnProperty("today_left_time"))
        object.today_left_time = message.today_left_time;
      return object;
    };

    /**
     * Converts this BannerHallResp to JSON.
     * @function toJSON
     * @memberof client_proto.BannerHallResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BannerHallResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BannerHallResp
     * @function getTypeUrl
     * @memberof client_proto.BannerHallResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BannerHallResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.BannerHallResp";
    };

    return BannerHallResp;
  })();

  /**
   * COMMON_MSG_SUB_ID enum.
   * @name client_proto.COMMON_MSG_SUB_ID
   * @enum {number}
   * @property {number} COMM_MSG_SUB_ID_NULL=0 COMM_MSG_SUB_ID_NULL value
   * @property {number} COMM_MSG_ID_MIN=3000 COMM_MSG_ID_MIN value
   * @property {number} COMM_MSG_GET_PROP_PUSH=3001 COMM_MSG_GET_PROP_PUSH value
   * @property {number} COMM_MSG_GET_RULES_REQ=3002 COMM_MSG_GET_RULES_REQ value
   * @property {number} COMM_MSG_GET_RULES_RESP=3003 COMM_MSG_GET_RULES_RESP value
   * @property {number} COMM_MSG_USER_GUIDE_PUSH=3004 COMM_MSG_USER_GUIDE_PUSH value
   * @property {number} COMM_MSG_GUIDE_FINISH_REQ=3005 COMM_MSG_GUIDE_FINISH_REQ value
   * @property {number} COMM_MSG_GUIDE_FINISH_RESP=3006 COMM_MSG_GUIDE_FINISH_RESP value
   * @property {number} COMM_MSG_USER_NOTIFY_PUSH=3007 COMM_MSG_USER_NOTIFY_PUSH value
   * @property {number} COMM_MSG_ID_MAX=3050 COMM_MSG_ID_MAX value
   */
  client_proto.COMMON_MSG_SUB_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "COMM_MSG_SUB_ID_NULL")] = 0;
    values[(valuesById[3000] = "COMM_MSG_ID_MIN")] = 3000;
    values[(valuesById[3001] = "COMM_MSG_GET_PROP_PUSH")] = 3001;
    values[(valuesById[3002] = "COMM_MSG_GET_RULES_REQ")] = 3002;
    values[(valuesById[3003] = "COMM_MSG_GET_RULES_RESP")] = 3003;
    values[(valuesById[3004] = "COMM_MSG_USER_GUIDE_PUSH")] = 3004;
    values[(valuesById[3005] = "COMM_MSG_GUIDE_FINISH_REQ")] = 3005;
    values[(valuesById[3006] = "COMM_MSG_GUIDE_FINISH_RESP")] = 3006;
    values[(valuesById[3007] = "COMM_MSG_USER_NOTIFY_PUSH")] = 3007;
    values[(valuesById[3050] = "COMM_MSG_ID_MAX")] = 3050;
    return values;
  })();

  /**
   * SERVER_INNER_MSG_TYPE enum.
   * @name client_proto.SERVER_INNER_MSG_TYPE
   * @enum {number}
   * @property {number} SERVER_TYPE_NONE=0 SERVER_TYPE_NONE value
   * @property {number} SERVER_TYPE_GAME_PLAYER=1 SERVER_TYPE_GAME_PLAYER value
   * @property {number} SERVER_TYPE_AGENT=2 SERVER_TYPE_AGENT value
   * @property {number} SERVER_TYPE_GAMEALLOC=3 SERVER_TYPE_GAMEALLOC value
   * @property {number} SERVER_TYPE_ONLINE=4 SERVER_TYPE_ONLINE value
   * @property {number} SERVER_TYPE_ROOMSERVER=5 SERVER_TYPE_ROOMSERVER value
   * @property {number} SERVER_TYPE_MASTER=6 SERVER_TYPE_MASTER value
   * @property {number} SERVER_TYPE_ASSET=7 SERVER_TYPE_ASSET value
   * @property {number} SERVER_TYPE_DB_UPDATE=8 SERVER_TYPE_DB_UPDATE value
   * @property {number} SERVER_TYPE_USERINFO=9 SERVER_TYPE_USERINFO value
   * @property {number} SERVER_TYPE_PHP_AGENT=10 SERVER_TYPE_PHP_AGENT value
   * @property {number} SERVER_TYPE_ACCOUNT=11 SERVER_TYPE_ACCOUNT value
   * @property {number} SERVER_TYPE_DISPATCH=12 SERVER_TYPE_DISPATCH value
   * @property {number} SERVER_TYPE_RANK=13 SERVER_TYPE_RANK value
   * @property {number} SERVER_TYPE_SERVICE_MANAGER=14 SERVER_TYPE_SERVICE_MANAGER value
   * @property {number} SERVER_TYPE_BRIDGE_PROXY=16 SERVER_TYPE_BRIDGE_PROXY value
   * @property {number} SERVER_TYPE_ACTIVITY=18 SERVER_TYPE_ACTIVITY value
   * @property {number} SERVER_TYPE_PHP=19 SERVER_TYPE_PHP value
   * @property {number} SERVER_TYPE_GAME_MANAGE=20 SERVER_TYPE_GAME_MANAGE value
   * @property {number} SERVER_TYPE_MAIL=21 SERVER_TYPE_MAIL value
   * @property {number} SERVER_TYPE_GAME_RPOXY_MANAGE=22 SERVER_TYPE_GAME_RPOXY_MANAGE value
   * @property {number} SERVER_TYPE_USER_PROXY=23 SERVER_TYPE_USER_PROXY value
   * @property {number} SERVER_TYPE_LABEL=24 SERVER_TYPE_LABEL value
   * @property {number} SERVER_TYPE_FACE=25 SERVER_TYPE_FACE value
   * @property {number} SERVER_TYPE_BANNER=26 SERVER_TYPE_BANNER value
   * @property {number} SERVER_TYPE_TGAME=28 SERVER_TYPE_TGAME value
   * @property {number} SERVER_TYPE_EVENT=29 SERVER_TYPE_EVENT value
   * @property {number} SERVER_TYPE_COMPANY=30 SERVER_TYPE_COMPANY value
   * @property {number} SERVER_TYPE_STATISTIC=31 SERVER_TYPE_STATISTIC value
   * @property {number} SERVER_TYPE_TRANSACTIONS=32 SERVER_TYPE_TRANSACTIONS value
   * @property {number} SERVER_TYPE_ASSET_FOLLOW=33 SERVER_TYPE_ASSET_FOLLOW value
   * @property {number} SERVER_TYPE_ANNOUNCEMENT=34 SERVER_TYPE_ANNOUNCEMENT value
   * @property {number} SERVER_TYPE_GETRANK=35 SERVER_TYPE_GETRANK value
   * @property {number} SERVER_TYPE_GIFT=45 SERVER_TYPE_GIFT value
   * @property {number} SERVER_TYPE_ROOMALLOC=47 SERVER_TYPE_ROOMALLOC value
   * @property {number} SERVER_TYPE_VIP_BET_REDATE=48 SERVER_TYPE_VIP_BET_REDATE value
   * @property {number} SERVER_TYPE_AGENT_RANK=53 SERVER_TYPE_AGENT_RANK value
   * @property {number} SERVER_TYPE_AGENT_GET_RANK=55 SERVER_TYPE_AGENT_GET_RANK value
   * @property {number} SERVER_TYPE_FLOATING=56 SERVER_TYPE_FLOATING value
   * @property {number} SERVER_TYPE_REPORT_SWITCH=59 SERVER_TYPE_REPORT_SWITCH value
   */
  client_proto.SERVER_INNER_MSG_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "SERVER_TYPE_NONE")] = 0;
    values[(valuesById[1] = "SERVER_TYPE_GAME_PLAYER")] = 1;
    values[(valuesById[2] = "SERVER_TYPE_AGENT")] = 2;
    values[(valuesById[3] = "SERVER_TYPE_GAMEALLOC")] = 3;
    values[(valuesById[4] = "SERVER_TYPE_ONLINE")] = 4;
    values[(valuesById[5] = "SERVER_TYPE_ROOMSERVER")] = 5;
    values[(valuesById[6] = "SERVER_TYPE_MASTER")] = 6;
    values[(valuesById[7] = "SERVER_TYPE_ASSET")] = 7;
    values[(valuesById[8] = "SERVER_TYPE_DB_UPDATE")] = 8;
    values[(valuesById[9] = "SERVER_TYPE_USERINFO")] = 9;
    values[(valuesById[10] = "SERVER_TYPE_PHP_AGENT")] = 10;
    values[(valuesById[11] = "SERVER_TYPE_ACCOUNT")] = 11;
    values[(valuesById[12] = "SERVER_TYPE_DISPATCH")] = 12;
    values[(valuesById[13] = "SERVER_TYPE_RANK")] = 13;
    values[(valuesById[14] = "SERVER_TYPE_SERVICE_MANAGER")] = 14;
    values[(valuesById[16] = "SERVER_TYPE_BRIDGE_PROXY")] = 16;
    values[(valuesById[18] = "SERVER_TYPE_ACTIVITY")] = 18;
    values[(valuesById[19] = "SERVER_TYPE_PHP")] = 19;
    values[(valuesById[20] = "SERVER_TYPE_GAME_MANAGE")] = 20;
    values[(valuesById[21] = "SERVER_TYPE_MAIL")] = 21;
    values[(valuesById[22] = "SERVER_TYPE_GAME_RPOXY_MANAGE")] = 22;
    values[(valuesById[23] = "SERVER_TYPE_USER_PROXY")] = 23;
    values[(valuesById[24] = "SERVER_TYPE_LABEL")] = 24;
    values[(valuesById[25] = "SERVER_TYPE_FACE")] = 25;
    values[(valuesById[26] = "SERVER_TYPE_BANNER")] = 26;
    values[(valuesById[28] = "SERVER_TYPE_TGAME")] = 28;
    values[(valuesById[29] = "SERVER_TYPE_EVENT")] = 29;
    values[(valuesById[30] = "SERVER_TYPE_COMPANY")] = 30;
    values[(valuesById[31] = "SERVER_TYPE_STATISTIC")] = 31;
    values[(valuesById[32] = "SERVER_TYPE_TRANSACTIONS")] = 32;
    values[(valuesById[33] = "SERVER_TYPE_ASSET_FOLLOW")] = 33;
    values[(valuesById[34] = "SERVER_TYPE_ANNOUNCEMENT")] = 34;
    values[(valuesById[35] = "SERVER_TYPE_GETRANK")] = 35;
    values[(valuesById[45] = "SERVER_TYPE_GIFT")] = 45;
    values[(valuesById[47] = "SERVER_TYPE_ROOMALLOC")] = 47;
    values[(valuesById[48] = "SERVER_TYPE_VIP_BET_REDATE")] = 48;
    values[(valuesById[53] = "SERVER_TYPE_AGENT_RANK")] = 53;
    values[(valuesById[55] = "SERVER_TYPE_AGENT_GET_RANK")] = 55;
    values[(valuesById[56] = "SERVER_TYPE_FLOATING")] = 56;
    values[(valuesById[59] = "SERVER_TYPE_REPORT_SWITCH")] = 59;
    return values;
  })();

  /**
   * PROP_ID enum.
   * @name client_proto.PROP_ID
   * @enum {number}
   * @property {number} PROP_NULL=0 PROP_NULL value
   * @property {number} PROP_AMOUNT=1 PROP_AMOUNT value
   * @property {number} PROP_COIN=2 PROP_COIN value
   * @property {number} PROP_TOTALBET=3 PROP_TOTALBET value
   * @property {number} PROP_TOTALRECHARGE=4 PROP_TOTALRECHARGE value
   * @property {number} PROP_BALANCE=5 PROP_BALANCE value
   * @property {number} PROP_WITHDRAW_LIMIT=6 PROP_WITHDRAW_LIMIT value
   * @property {number} PROP_DIAMOND=7 PROP_DIAMOND value
   * @property {number} PROP_EXP=8 PROP_EXP value
   * @property {number} PROP_PRT=9 PROP_PRT value
   */
  client_proto.PROP_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "PROP_NULL")] = 0;
    values[(valuesById[1] = "PROP_AMOUNT")] = 1;
    values[(valuesById[2] = "PROP_COIN")] = 2;
    values[(valuesById[3] = "PROP_TOTALBET")] = 3;
    values[(valuesById[4] = "PROP_TOTALRECHARGE")] = 4;
    values[(valuesById[5] = "PROP_BALANCE")] = 5;
    values[(valuesById[6] = "PROP_WITHDRAW_LIMIT")] = 6;
    values[(valuesById[7] = "PROP_DIAMOND")] = 7;
    values[(valuesById[8] = "PROP_EXP")] = 8;
    values[(valuesById[9] = "PROP_PRT")] = 9;
    return values;
  })();

  client_proto.GetPropPush = (function () {
    /**
     * Properties of a GetPropPush.
     * @memberof client_proto
     * @interface IGetPropPush
     * @property {Array.<client_proto.IPropItem>|null} [reward] GetPropPush reward
     * @property {client_proto.GetPropPush.PROP_FROM|null} [propfrom] GetPropPush propfrom
     * @property {string|null} [params] GetPropPush params
     */

    /**
     * Constructs a new GetPropPush.
     * @memberof client_proto
     * @classdesc Represents a GetPropPush.
     * @implements IGetPropPush
     * @constructor
     * @param {client_proto.IGetPropPush=} [properties] Properties to set
     */
    function GetPropPush(properties) {
      this.reward = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetPropPush reward.
     * @member {Array.<client_proto.IPropItem>} reward
     * @memberof client_proto.GetPropPush
     * @instance
     */
    GetPropPush.prototype.reward = $util.emptyArray;

    /**
     * GetPropPush propfrom.
     * @member {client_proto.GetPropPush.PROP_FROM} propfrom
     * @memberof client_proto.GetPropPush
     * @instance
     */
    GetPropPush.prototype.propfrom = 0;

    /**
     * GetPropPush params.
     * @member {string} params
     * @memberof client_proto.GetPropPush
     * @instance
     */
    GetPropPush.prototype.params = "";

    /**
     * Creates a new GetPropPush instance using the specified properties.
     * @function create
     * @memberof client_proto.GetPropPush
     * @static
     * @param {client_proto.IGetPropPush=} [properties] Properties to set
     * @returns {client_proto.GetPropPush} GetPropPush instance
     */
    GetPropPush.create = function create(properties) {
      return new GetPropPush(properties);
    };

    /**
     * Encodes the specified GetPropPush message. Does not implicitly {@link client_proto.GetPropPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetPropPush
     * @static
     * @param {client_proto.IGetPropPush} message GetPropPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPropPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.reward != null && message.reward.length)
        for (var i = 0; i < message.reward.length; ++i)
          $root.client_proto.PropItem.encode(
            message.reward[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.propfrom != null && Object.hasOwnProperty.call(message, "propfrom"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.propfrom);
      if (message.params != null && Object.hasOwnProperty.call(message, "params"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.params);
      return writer;
    };

    /**
     * Encodes the specified GetPropPush message, length delimited. Does not implicitly {@link client_proto.GetPropPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetPropPush
     * @static
     * @param {client_proto.IGetPropPush} message GetPropPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetPropPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetPropPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetPropPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetPropPush} GetPropPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPropPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetPropPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.reward && message.reward.length)) message.reward = [];
            message.reward.push($root.client_proto.PropItem.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.propfrom = reader.int32();
            break;
          }
          case 3: {
            message.params = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetPropPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetPropPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetPropPush} GetPropPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetPropPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetPropPush message.
     * @function verify
     * @memberof client_proto.GetPropPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetPropPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.reward != null && message.hasOwnProperty("reward")) {
        if (!Array.isArray(message.reward)) return "reward: array expected";
        for (var i = 0; i < message.reward.length; ++i) {
          var error = $root.client_proto.PropItem.verify(message.reward[i]);
          if (error) return "reward." + error;
        }
      }
      if (message.propfrom != null && message.hasOwnProperty("propfrom"))
        switch (message.propfrom) {
          default:
            return "propfrom: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
            break;
        }
      if (message.params != null && message.hasOwnProperty("params"))
        if (!$util.isString(message.params)) return "params: string expected";
      return null;
    };

    /**
     * Creates a GetPropPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetPropPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetPropPush} GetPropPush
     */
    GetPropPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetPropPush) return object;
      var message = new $root.client_proto.GetPropPush();
      if (object.reward) {
        if (!Array.isArray(object.reward)) throw TypeError(".client_proto.GetPropPush.reward: array expected");
        message.reward = [];
        for (var i = 0; i < object.reward.length; ++i) {
          if (typeof object.reward[i] !== "object")
            throw TypeError(".client_proto.GetPropPush.reward: object expected");
          message.reward[i] = $root.client_proto.PropItem.fromObject(object.reward[i]);
        }
      }
      switch (object.propfrom) {
        default:
          if (typeof object.propfrom === "number") {
            message.propfrom = object.propfrom;
            break;
          }
          break;
        case "FRESH":
        case 0:
          message.propfrom = 0;
          break;
        case "RECHARGE":
        case 1:
          message.propfrom = 1;
          break;
        case "ACCUMULATED":
        case 2:
          message.propfrom = 2;
          break;
        case "EMAIL_PLATFORM":
        case 3:
          message.propfrom = 3;
          break;
        case "EMAIL_PERSONAL":
        case 4:
          message.propfrom = 4;
          break;
        case "SPIN_WITHDRAW":
        case 5:
          message.propfrom = 5;
          break;
        case "WALLET_RECHARGE":
        case 6:
          message.propfrom = 6;
          break;
        case "WALLET_WITHDRAW":
        case 7:
          message.propfrom = 7;
          break;
        case "VIP_WELFARE":
        case 8:
          message.propfrom = 8;
          break;
        case "COMMISSIONS":
        case 9:
          message.propfrom = 9;
          break;
        case "INVITE_ATIVITE":
        case 10:
          message.propfrom = 10;
          break;
        case "GIFT":
        case 11:
          message.propfrom = 11;
          break;
        case "VIP_BET_REDATE":
        case 12:
          message.propfrom = 12;
          break;
        case "ACTIVITE_GIFT":
        case 13:
          message.propfrom = 13;
          break;
        case "ACTIVITY_REGRESS_GIFT":
        case 14:
          message.propfrom = 14;
          break;
        case "ACTIVITY_REGRESS_WELFARE":
        case 15:
          message.propfrom = 15;
          break;
        case "ACTIVITY_SURPRISE_GIFT":
        case 16:
          message.propfrom = 16;
          break;
      }
      if (object.params != null) message.params = String(object.params);
      return message;
    };

    /**
     * Creates a plain object from a GetPropPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetPropPush
     * @static
     * @param {client_proto.GetPropPush} message GetPropPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetPropPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.reward = [];
      if (options.defaults) {
        object.propfrom = options.enums === String ? "FRESH" : 0;
        object.params = "";
      }
      if (message.reward && message.reward.length) {
        object.reward = [];
        for (var j = 0; j < message.reward.length; ++j)
          object.reward[j] = $root.client_proto.PropItem.toObject(message.reward[j], options);
      }
      if (message.propfrom != null && message.hasOwnProperty("propfrom"))
        object.propfrom =
          options.enums === String
            ? $root.client_proto.GetPropPush.PROP_FROM[message.propfrom] === undefined
              ? message.propfrom
              : $root.client_proto.GetPropPush.PROP_FROM[message.propfrom]
            : message.propfrom;
      if (message.params != null && message.hasOwnProperty("params")) object.params = message.params;
      return object;
    };

    /**
     * Converts this GetPropPush to JSON.
     * @function toJSON
     * @memberof client_proto.GetPropPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetPropPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetPropPush
     * @function getTypeUrl
     * @memberof client_proto.GetPropPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetPropPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetPropPush";
    };

    /**
     * PROP_FROM enum.
     * @name client_proto.GetPropPush.PROP_FROM
     * @enum {number}
     * @property {number} FRESH=0 FRESH value
     * @property {number} RECHARGE=1 RECHARGE value
     * @property {number} ACCUMULATED=2 ACCUMULATED value
     * @property {number} EMAIL_PLATFORM=3 EMAIL_PLATFORM value
     * @property {number} EMAIL_PERSONAL=4 EMAIL_PERSONAL value
     * @property {number} SPIN_WITHDRAW=5 SPIN_WITHDRAW value
     * @property {number} WALLET_RECHARGE=6 WALLET_RECHARGE value
     * @property {number} WALLET_WITHDRAW=7 WALLET_WITHDRAW value
     * @property {number} VIP_WELFARE=8 VIP_WELFARE value
     * @property {number} COMMISSIONS=9 COMMISSIONS value
     * @property {number} INVITE_ATIVITE=10 INVITE_ATIVITE value
     * @property {number} GIFT=11 GIFT value
     * @property {number} VIP_BET_REDATE=12 VIP_BET_REDATE value
     * @property {number} ACTIVITE_GIFT=13 ACTIVITE_GIFT value
     * @property {number} ACTIVITY_REGRESS_GIFT=14 ACTIVITY_REGRESS_GIFT value
     * @property {number} ACTIVITY_REGRESS_WELFARE=15 ACTIVITY_REGRESS_WELFARE value
     * @property {number} ACTIVITY_SURPRISE_GIFT=16 ACTIVITY_SURPRISE_GIFT value
     */
    GetPropPush.PROP_FROM = (function () {
      var valuesById = {},
        values = Object.create(valuesById);
      values[(valuesById[0] = "FRESH")] = 0;
      values[(valuesById[1] = "RECHARGE")] = 1;
      values[(valuesById[2] = "ACCUMULATED")] = 2;
      values[(valuesById[3] = "EMAIL_PLATFORM")] = 3;
      values[(valuesById[4] = "EMAIL_PERSONAL")] = 4;
      values[(valuesById[5] = "SPIN_WITHDRAW")] = 5;
      values[(valuesById[6] = "WALLET_RECHARGE")] = 6;
      values[(valuesById[7] = "WALLET_WITHDRAW")] = 7;
      values[(valuesById[8] = "VIP_WELFARE")] = 8;
      values[(valuesById[9] = "COMMISSIONS")] = 9;
      values[(valuesById[10] = "INVITE_ATIVITE")] = 10;
      values[(valuesById[11] = "GIFT")] = 11;
      values[(valuesById[12] = "VIP_BET_REDATE")] = 12;
      values[(valuesById[13] = "ACTIVITE_GIFT")] = 13;
      values[(valuesById[14] = "ACTIVITY_REGRESS_GIFT")] = 14;
      values[(valuesById[15] = "ACTIVITY_REGRESS_WELFARE")] = 15;
      values[(valuesById[16] = "ACTIVITY_SURPRISE_GIFT")] = 16;
      return values;
    })();

    return GetPropPush;
  })();

  client_proto.PropItem = (function () {
    /**
     * Properties of a PropItem.
     * @memberof client_proto
     * @interface IPropItem
     * @property {client_proto.PROP_ID|null} [id] PropItem id
     * @property {number|Long|null} [num] PropItem num
     */

    /**
     * Constructs a new PropItem.
     * @memberof client_proto
     * @classdesc Represents a PropItem.
     * @implements IPropItem
     * @constructor
     * @param {client_proto.IPropItem=} [properties] Properties to set
     */
    function PropItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PropItem id.
     * @member {client_proto.PROP_ID} id
     * @memberof client_proto.PropItem
     * @instance
     */
    PropItem.prototype.id = 0;

    /**
     * PropItem num.
     * @member {number|Long} num
     * @memberof client_proto.PropItem
     * @instance
     */
    PropItem.prototype.num = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new PropItem instance using the specified properties.
     * @function create
     * @memberof client_proto.PropItem
     * @static
     * @param {client_proto.IPropItem=} [properties] Properties to set
     * @returns {client_proto.PropItem} PropItem instance
     */
    PropItem.create = function create(properties) {
      return new PropItem(properties);
    };

    /**
     * Encodes the specified PropItem message. Does not implicitly {@link client_proto.PropItem.verify|verify} messages.
     * @function encode
     * @memberof client_proto.PropItem
     * @static
     * @param {client_proto.IPropItem} message PropItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PropItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.num != null && Object.hasOwnProperty.call(message, "num"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.num);
      return writer;
    };

    /**
     * Encodes the specified PropItem message, length delimited. Does not implicitly {@link client_proto.PropItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.PropItem
     * @static
     * @param {client_proto.IPropItem} message PropItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PropItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PropItem message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.PropItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.PropItem} PropItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PropItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.PropItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.num = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PropItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.PropItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.PropItem} PropItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PropItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PropItem message.
     * @function verify
     * @memberof client_proto.PropItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PropItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        switch (message.id) {
          default:
            return "id: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
            break;
        }
      if (message.num != null && message.hasOwnProperty("num"))
        if (
          !$util.isInteger(message.num) &&
          !(message.num && $util.isInteger(message.num.low) && $util.isInteger(message.num.high))
        )
          return "num: integer|Long expected";
      return null;
    };

    /**
     * Creates a PropItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.PropItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.PropItem} PropItem
     */
    PropItem.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.PropItem) return object;
      var message = new $root.client_proto.PropItem();
      switch (object.id) {
        default:
          if (typeof object.id === "number") {
            message.id = object.id;
            break;
          }
          break;
        case "PROP_NULL":
        case 0:
          message.id = 0;
          break;
        case "PROP_AMOUNT":
        case 1:
          message.id = 1;
          break;
        case "PROP_COIN":
        case 2:
          message.id = 2;
          break;
        case "PROP_TOTALBET":
        case 3:
          message.id = 3;
          break;
        case "PROP_TOTALRECHARGE":
        case 4:
          message.id = 4;
          break;
        case "PROP_BALANCE":
        case 5:
          message.id = 5;
          break;
        case "PROP_WITHDRAW_LIMIT":
        case 6:
          message.id = 6;
          break;
        case "PROP_DIAMOND":
        case 7:
          message.id = 7;
          break;
        case "PROP_EXP":
        case 8:
          message.id = 8;
          break;
        case "PROP_PRT":
        case 9:
          message.id = 9;
          break;
      }
      if (object.num != null)
        if ($util.Long) (message.num = $util.Long.fromValue(object.num)).unsigned = false;
        else if (typeof object.num === "string") message.num = parseInt(object.num, 10);
        else if (typeof object.num === "number") message.num = object.num;
        else if (typeof object.num === "object")
          message.num = new $util.LongBits(object.num.low >>> 0, object.num.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a PropItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.PropItem
     * @static
     * @param {client_proto.PropItem} message PropItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PropItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = options.enums === String ? "PROP_NULL" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.num = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.num = options.longs === String ? "0" : 0;
      }
      if (message.id != null && message.hasOwnProperty("id"))
        object.id =
          options.enums === String
            ? $root.client_proto.PROP_ID[message.id] === undefined
              ? message.id
              : $root.client_proto.PROP_ID[message.id]
            : message.id;
      if (message.num != null && message.hasOwnProperty("num"))
        if (typeof message.num === "number") object.num = options.longs === String ? String(message.num) : message.num;
        else
          object.num =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.num)
              : options.longs === Number
                ? new $util.LongBits(message.num.low >>> 0, message.num.high >>> 0).toNumber()
                : message.num;
      return object;
    };

    /**
     * Converts this PropItem to JSON.
     * @function toJSON
     * @memberof client_proto.PropItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PropItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PropItem
     * @function getTypeUrl
     * @memberof client_proto.PropItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PropItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.PropItem";
    };

    return PropItem;
  })();

  client_proto.GetRulesReq = (function () {
    /**
     * Properties of a GetRulesReq.
     * @memberof client_proto
     * @interface IGetRulesReq
     * @property {string|null} [key] GetRulesReq key
     * @property {string|null} [lang] GetRulesReq lang
     */

    /**
     * Constructs a new GetRulesReq.
     * @memberof client_proto
     * @classdesc Represents a GetRulesReq.
     * @implements IGetRulesReq
     * @constructor
     * @param {client_proto.IGetRulesReq=} [properties] Properties to set
     */
    function GetRulesReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetRulesReq key.
     * @member {string} key
     * @memberof client_proto.GetRulesReq
     * @instance
     */
    GetRulesReq.prototype.key = "";

    /**
     * GetRulesReq lang.
     * @member {string} lang
     * @memberof client_proto.GetRulesReq
     * @instance
     */
    GetRulesReq.prototype.lang = "";

    /**
     * Creates a new GetRulesReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GetRulesReq
     * @static
     * @param {client_proto.IGetRulesReq=} [properties] Properties to set
     * @returns {client_proto.GetRulesReq} GetRulesReq instance
     */
    GetRulesReq.create = function create(properties) {
      return new GetRulesReq(properties);
    };

    /**
     * Encodes the specified GetRulesReq message. Does not implicitly {@link client_proto.GetRulesReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetRulesReq
     * @static
     * @param {client_proto.IGetRulesReq} message GetRulesReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetRulesReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.key != null && Object.hasOwnProperty.call(message, "key"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.key);
      if (message.lang != null && Object.hasOwnProperty.call(message, "lang"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.lang);
      return writer;
    };

    /**
     * Encodes the specified GetRulesReq message, length delimited. Does not implicitly {@link client_proto.GetRulesReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetRulesReq
     * @static
     * @param {client_proto.IGetRulesReq} message GetRulesReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetRulesReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetRulesReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetRulesReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetRulesReq} GetRulesReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetRulesReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetRulesReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.key = reader.string();
            break;
          }
          case 2: {
            message.lang = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetRulesReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetRulesReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetRulesReq} GetRulesReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetRulesReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetRulesReq message.
     * @function verify
     * @memberof client_proto.GetRulesReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetRulesReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.key != null && message.hasOwnProperty("key"))
        if (!$util.isString(message.key)) return "key: string expected";
      if (message.lang != null && message.hasOwnProperty("lang"))
        if (!$util.isString(message.lang)) return "lang: string expected";
      return null;
    };

    /**
     * Creates a GetRulesReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetRulesReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetRulesReq} GetRulesReq
     */
    GetRulesReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetRulesReq) return object;
      var message = new $root.client_proto.GetRulesReq();
      if (object.key != null) message.key = String(object.key);
      if (object.lang != null) message.lang = String(object.lang);
      return message;
    };

    /**
     * Creates a plain object from a GetRulesReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetRulesReq
     * @static
     * @param {client_proto.GetRulesReq} message GetRulesReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetRulesReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.key = "";
        object.lang = "";
      }
      if (message.key != null && message.hasOwnProperty("key")) object.key = message.key;
      if (message.lang != null && message.hasOwnProperty("lang")) object.lang = message.lang;
      return object;
    };

    /**
     * Converts this GetRulesReq to JSON.
     * @function toJSON
     * @memberof client_proto.GetRulesReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetRulesReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetRulesReq
     * @function getTypeUrl
     * @memberof client_proto.GetRulesReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetRulesReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetRulesReq";
    };

    return GetRulesReq;
  })();

  client_proto.GetRulesResp = (function () {
    /**
     * Properties of a GetRulesResp.
     * @memberof client_proto
     * @interface IGetRulesResp
     * @property {string|null} [key] GetRulesResp key
     * @property {string|null} [rules] GetRulesResp rules
     */

    /**
     * Constructs a new GetRulesResp.
     * @memberof client_proto
     * @classdesc Represents a GetRulesResp.
     * @implements IGetRulesResp
     * @constructor
     * @param {client_proto.IGetRulesResp=} [properties] Properties to set
     */
    function GetRulesResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetRulesResp key.
     * @member {string} key
     * @memberof client_proto.GetRulesResp
     * @instance
     */
    GetRulesResp.prototype.key = "";

    /**
     * GetRulesResp rules.
     * @member {string} rules
     * @memberof client_proto.GetRulesResp
     * @instance
     */
    GetRulesResp.prototype.rules = "";

    /**
     * Creates a new GetRulesResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GetRulesResp
     * @static
     * @param {client_proto.IGetRulesResp=} [properties] Properties to set
     * @returns {client_proto.GetRulesResp} GetRulesResp instance
     */
    GetRulesResp.create = function create(properties) {
      return new GetRulesResp(properties);
    };

    /**
     * Encodes the specified GetRulesResp message. Does not implicitly {@link client_proto.GetRulesResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GetRulesResp
     * @static
     * @param {client_proto.IGetRulesResp} message GetRulesResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetRulesResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.key != null && Object.hasOwnProperty.call(message, "key"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.key);
      if (message.rules != null && Object.hasOwnProperty.call(message, "rules"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.rules);
      return writer;
    };

    /**
     * Encodes the specified GetRulesResp message, length delimited. Does not implicitly {@link client_proto.GetRulesResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GetRulesResp
     * @static
     * @param {client_proto.IGetRulesResp} message GetRulesResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetRulesResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetRulesResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GetRulesResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GetRulesResp} GetRulesResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetRulesResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GetRulesResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.key = reader.string();
            break;
          }
          case 2: {
            message.rules = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetRulesResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GetRulesResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GetRulesResp} GetRulesResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetRulesResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetRulesResp message.
     * @function verify
     * @memberof client_proto.GetRulesResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetRulesResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.key != null && message.hasOwnProperty("key"))
        if (!$util.isString(message.key)) return "key: string expected";
      if (message.rules != null && message.hasOwnProperty("rules"))
        if (!$util.isString(message.rules)) return "rules: string expected";
      return null;
    };

    /**
     * Creates a GetRulesResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GetRulesResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GetRulesResp} GetRulesResp
     */
    GetRulesResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GetRulesResp) return object;
      var message = new $root.client_proto.GetRulesResp();
      if (object.key != null) message.key = String(object.key);
      if (object.rules != null) message.rules = String(object.rules);
      return message;
    };

    /**
     * Creates a plain object from a GetRulesResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GetRulesResp
     * @static
     * @param {client_proto.GetRulesResp} message GetRulesResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetRulesResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.key = "";
        object.rules = "";
      }
      if (message.key != null && message.hasOwnProperty("key")) object.key = message.key;
      if (message.rules != null && message.hasOwnProperty("rules")) object.rules = message.rules;
      return object;
    };

    /**
     * Converts this GetRulesResp to JSON.
     * @function toJSON
     * @memberof client_proto.GetRulesResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetRulesResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetRulesResp
     * @function getTypeUrl
     * @memberof client_proto.GetRulesResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetRulesResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GetRulesResp";
    };

    return GetRulesResp;
  })();

  /**
   * GuideStataus enum.
   * @name client_proto.GuideStataus
   * @enum {number}
   * @property {number} GUIDE_STATUS_NULL=0 GUIDE_STATUS_NULL value
   * @property {number} GUIDE_STATUS_READY=1 GUIDE_STATUS_READY value
   * @property {number} GUIDE_STATUS_FINISH=2 GUIDE_STATUS_FINISH value
   */
  client_proto.GuideStataus = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "GUIDE_STATUS_NULL")] = 0;
    values[(valuesById[1] = "GUIDE_STATUS_READY")] = 1;
    values[(valuesById[2] = "GUIDE_STATUS_FINISH")] = 2;
    return values;
  })();

  client_proto.UserGuidePush = (function () {
    /**
     * Properties of a UserGuidePush.
     * @memberof client_proto
     * @interface IUserGuidePush
     * @property {Array.<string>|null} [guide] UserGuidePush guide
     */

    /**
     * Constructs a new UserGuidePush.
     * @memberof client_proto
     * @classdesc Represents a UserGuidePush.
     * @implements IUserGuidePush
     * @constructor
     * @param {client_proto.IUserGuidePush=} [properties] Properties to set
     */
    function UserGuidePush(properties) {
      this.guide = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserGuidePush guide.
     * @member {Array.<string>} guide
     * @memberof client_proto.UserGuidePush
     * @instance
     */
    UserGuidePush.prototype.guide = $util.emptyArray;

    /**
     * Creates a new UserGuidePush instance using the specified properties.
     * @function create
     * @memberof client_proto.UserGuidePush
     * @static
     * @param {client_proto.IUserGuidePush=} [properties] Properties to set
     * @returns {client_proto.UserGuidePush} UserGuidePush instance
     */
    UserGuidePush.create = function create(properties) {
      return new UserGuidePush(properties);
    };

    /**
     * Encodes the specified UserGuidePush message. Does not implicitly {@link client_proto.UserGuidePush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserGuidePush
     * @static
     * @param {client_proto.IUserGuidePush} message UserGuidePush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserGuidePush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.guide != null && message.guide.length)
        for (var i = 0; i < message.guide.length; ++i)
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.guide[i]);
      return writer;
    };

    /**
     * Encodes the specified UserGuidePush message, length delimited. Does not implicitly {@link client_proto.UserGuidePush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserGuidePush
     * @static
     * @param {client_proto.IUserGuidePush} message UserGuidePush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserGuidePush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserGuidePush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserGuidePush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserGuidePush} UserGuidePush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserGuidePush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserGuidePush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.guide && message.guide.length)) message.guide = [];
            message.guide.push(reader.string());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserGuidePush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserGuidePush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserGuidePush} UserGuidePush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserGuidePush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserGuidePush message.
     * @function verify
     * @memberof client_proto.UserGuidePush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserGuidePush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.guide != null && message.hasOwnProperty("guide")) {
        if (!Array.isArray(message.guide)) return "guide: array expected";
        for (var i = 0; i < message.guide.length; ++i)
          if (!$util.isString(message.guide[i])) return "guide: string[] expected";
      }
      return null;
    };

    /**
     * Creates a UserGuidePush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserGuidePush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserGuidePush} UserGuidePush
     */
    UserGuidePush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserGuidePush) return object;
      var message = new $root.client_proto.UserGuidePush();
      if (object.guide) {
        if (!Array.isArray(object.guide)) throw TypeError(".client_proto.UserGuidePush.guide: array expected");
        message.guide = [];
        for (var i = 0; i < object.guide.length; ++i) message.guide[i] = String(object.guide[i]);
      }
      return message;
    };

    /**
     * Creates a plain object from a UserGuidePush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserGuidePush
     * @static
     * @param {client_proto.UserGuidePush} message UserGuidePush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserGuidePush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.guide = [];
      if (message.guide && message.guide.length) {
        object.guide = [];
        for (var j = 0; j < message.guide.length; ++j) object.guide[j] = message.guide[j];
      }
      return object;
    };

    /**
     * Converts this UserGuidePush to JSON.
     * @function toJSON
     * @memberof client_proto.UserGuidePush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserGuidePush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserGuidePush
     * @function getTypeUrl
     * @memberof client_proto.UserGuidePush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserGuidePush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserGuidePush";
    };

    return UserGuidePush;
  })();

  client_proto.UserGuideFinishReq = (function () {
    /**
     * Properties of a UserGuideFinishReq.
     * @memberof client_proto
     * @interface IUserGuideFinishReq
     * @property {string|null} [guide] UserGuideFinishReq guide
     */

    /**
     * Constructs a new UserGuideFinishReq.
     * @memberof client_proto
     * @classdesc Represents a UserGuideFinishReq.
     * @implements IUserGuideFinishReq
     * @constructor
     * @param {client_proto.IUserGuideFinishReq=} [properties] Properties to set
     */
    function UserGuideFinishReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserGuideFinishReq guide.
     * @member {string} guide
     * @memberof client_proto.UserGuideFinishReq
     * @instance
     */
    UserGuideFinishReq.prototype.guide = "";

    /**
     * Creates a new UserGuideFinishReq instance using the specified properties.
     * @function create
     * @memberof client_proto.UserGuideFinishReq
     * @static
     * @param {client_proto.IUserGuideFinishReq=} [properties] Properties to set
     * @returns {client_proto.UserGuideFinishReq} UserGuideFinishReq instance
     */
    UserGuideFinishReq.create = function create(properties) {
      return new UserGuideFinishReq(properties);
    };

    /**
     * Encodes the specified UserGuideFinishReq message. Does not implicitly {@link client_proto.UserGuideFinishReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserGuideFinishReq
     * @static
     * @param {client_proto.IUserGuideFinishReq} message UserGuideFinishReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserGuideFinishReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.guide != null && Object.hasOwnProperty.call(message, "guide"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.guide);
      return writer;
    };

    /**
     * Encodes the specified UserGuideFinishReq message, length delimited. Does not implicitly {@link client_proto.UserGuideFinishReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserGuideFinishReq
     * @static
     * @param {client_proto.IUserGuideFinishReq} message UserGuideFinishReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserGuideFinishReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserGuideFinishReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserGuideFinishReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserGuideFinishReq} UserGuideFinishReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserGuideFinishReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserGuideFinishReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.guide = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserGuideFinishReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserGuideFinishReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserGuideFinishReq} UserGuideFinishReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserGuideFinishReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserGuideFinishReq message.
     * @function verify
     * @memberof client_proto.UserGuideFinishReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserGuideFinishReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.guide != null && message.hasOwnProperty("guide"))
        if (!$util.isString(message.guide)) return "guide: string expected";
      return null;
    };

    /**
     * Creates a UserGuideFinishReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserGuideFinishReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserGuideFinishReq} UserGuideFinishReq
     */
    UserGuideFinishReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserGuideFinishReq) return object;
      var message = new $root.client_proto.UserGuideFinishReq();
      if (object.guide != null) message.guide = String(object.guide);
      return message;
    };

    /**
     * Creates a plain object from a UserGuideFinishReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserGuideFinishReq
     * @static
     * @param {client_proto.UserGuideFinishReq} message UserGuideFinishReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserGuideFinishReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.guide = "";
      if (message.guide != null && message.hasOwnProperty("guide")) object.guide = message.guide;
      return object;
    };

    /**
     * Converts this UserGuideFinishReq to JSON.
     * @function toJSON
     * @memberof client_proto.UserGuideFinishReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserGuideFinishReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserGuideFinishReq
     * @function getTypeUrl
     * @memberof client_proto.UserGuideFinishReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserGuideFinishReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserGuideFinishReq";
    };

    return UserGuideFinishReq;
  })();

  client_proto.UserGuideFinishResp = (function () {
    /**
     * Properties of a UserGuideFinishResp.
     * @memberof client_proto
     * @interface IUserGuideFinishResp
     * @property {number|null} [result] UserGuideFinishResp result
     */

    /**
     * Constructs a new UserGuideFinishResp.
     * @memberof client_proto
     * @classdesc Represents a UserGuideFinishResp.
     * @implements IUserGuideFinishResp
     * @constructor
     * @param {client_proto.IUserGuideFinishResp=} [properties] Properties to set
     */
    function UserGuideFinishResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserGuideFinishResp result.
     * @member {number} result
     * @memberof client_proto.UserGuideFinishResp
     * @instance
     */
    UserGuideFinishResp.prototype.result = 0;

    /**
     * Creates a new UserGuideFinishResp instance using the specified properties.
     * @function create
     * @memberof client_proto.UserGuideFinishResp
     * @static
     * @param {client_proto.IUserGuideFinishResp=} [properties] Properties to set
     * @returns {client_proto.UserGuideFinishResp} UserGuideFinishResp instance
     */
    UserGuideFinishResp.create = function create(properties) {
      return new UserGuideFinishResp(properties);
    };

    /**
     * Encodes the specified UserGuideFinishResp message. Does not implicitly {@link client_proto.UserGuideFinishResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserGuideFinishResp
     * @static
     * @param {client_proto.IUserGuideFinishResp} message UserGuideFinishResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserGuideFinishResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified UserGuideFinishResp message, length delimited. Does not implicitly {@link client_proto.UserGuideFinishResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserGuideFinishResp
     * @static
     * @param {client_proto.IUserGuideFinishResp} message UserGuideFinishResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserGuideFinishResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserGuideFinishResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserGuideFinishResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserGuideFinishResp} UserGuideFinishResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserGuideFinishResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserGuideFinishResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserGuideFinishResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserGuideFinishResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserGuideFinishResp} UserGuideFinishResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserGuideFinishResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserGuideFinishResp message.
     * @function verify
     * @memberof client_proto.UserGuideFinishResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserGuideFinishResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a UserGuideFinishResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserGuideFinishResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserGuideFinishResp} UserGuideFinishResp
     */
    UserGuideFinishResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserGuideFinishResp) return object;
      var message = new $root.client_proto.UserGuideFinishResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a UserGuideFinishResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserGuideFinishResp
     * @static
     * @param {client_proto.UserGuideFinishResp} message UserGuideFinishResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserGuideFinishResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this UserGuideFinishResp to JSON.
     * @function toJSON
     * @memberof client_proto.UserGuideFinishResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserGuideFinishResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserGuideFinishResp
     * @function getTypeUrl
     * @memberof client_proto.UserGuideFinishResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserGuideFinishResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserGuideFinishResp";
    };

    return UserGuideFinishResp;
  })();

  /**
   * NotifyPushType enum.
   * @name client_proto.NotifyPushType
   * @enum {number}
   * @property {number} NORMAL=0 NORMAL value
   * @property {number} ERROR=1 ERROR value
   * @property {number} NOTIFY=2 NOTIFY value
   * @property {number} BROADCAST=3 BROADCAST value
   */
  client_proto.NotifyPushType = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "NORMAL")] = 0;
    values[(valuesById[1] = "ERROR")] = 1;
    values[(valuesById[2] = "NOTIFY")] = 2;
    values[(valuesById[3] = "BROADCAST")] = 3;
    return values;
  })();

  client_proto.NotifyPush = (function () {
    /**
     * Properties of a NotifyPush.
     * @memberof client_proto
     * @interface INotifyPush
     * @property {number|null} [type] NotifyPush type
     * @property {number|null} [code] NotifyPush code
     * @property {string|null} [values] NotifyPush values
     */

    /**
     * Constructs a new NotifyPush.
     * @memberof client_proto
     * @classdesc Represents a NotifyPush.
     * @implements INotifyPush
     * @constructor
     * @param {client_proto.INotifyPush=} [properties] Properties to set
     */
    function NotifyPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotifyPush type.
     * @member {number} type
     * @memberof client_proto.NotifyPush
     * @instance
     */
    NotifyPush.prototype.type = 0;

    /**
     * NotifyPush code.
     * @member {number} code
     * @memberof client_proto.NotifyPush
     * @instance
     */
    NotifyPush.prototype.code = 0;

    /**
     * NotifyPush values.
     * @member {string} values
     * @memberof client_proto.NotifyPush
     * @instance
     */
    NotifyPush.prototype.values = "";

    /**
     * Creates a new NotifyPush instance using the specified properties.
     * @function create
     * @memberof client_proto.NotifyPush
     * @static
     * @param {client_proto.INotifyPush=} [properties] Properties to set
     * @returns {client_proto.NotifyPush} NotifyPush instance
     */
    NotifyPush.create = function create(properties) {
      return new NotifyPush(properties);
    };

    /**
     * Encodes the specified NotifyPush message. Does not implicitly {@link client_proto.NotifyPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.NotifyPush
     * @static
     * @param {client_proto.INotifyPush} message NotifyPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotifyPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.type != null && Object.hasOwnProperty.call(message, "type"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.type);
      if (message.code != null && Object.hasOwnProperty.call(message, "code"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.code);
      if (message.values != null && Object.hasOwnProperty.call(message, "values"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.values);
      return writer;
    };

    /**
     * Encodes the specified NotifyPush message, length delimited. Does not implicitly {@link client_proto.NotifyPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.NotifyPush
     * @static
     * @param {client_proto.INotifyPush} message NotifyPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotifyPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a NotifyPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.NotifyPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.NotifyPush} NotifyPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotifyPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.NotifyPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.type = reader.int32();
            break;
          }
          case 2: {
            message.code = reader.int32();
            break;
          }
          case 3: {
            message.values = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a NotifyPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.NotifyPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.NotifyPush} NotifyPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotifyPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a NotifyPush message.
     * @function verify
     * @memberof client_proto.NotifyPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    NotifyPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.type != null && message.hasOwnProperty("type"))
        if (!$util.isInteger(message.type)) return "type: integer expected";
      if (message.code != null && message.hasOwnProperty("code"))
        if (!$util.isInteger(message.code)) return "code: integer expected";
      if (message.values != null && message.hasOwnProperty("values"))
        if (!$util.isString(message.values)) return "values: string expected";
      return null;
    };

    /**
     * Creates a NotifyPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.NotifyPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.NotifyPush} NotifyPush
     */
    NotifyPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.NotifyPush) return object;
      var message = new $root.client_proto.NotifyPush();
      if (object.type != null) message.type = object.type | 0;
      if (object.code != null) message.code = object.code | 0;
      if (object.values != null) message.values = String(object.values);
      return message;
    };

    /**
     * Creates a plain object from a NotifyPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.NotifyPush
     * @static
     * @param {client_proto.NotifyPush} message NotifyPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    NotifyPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.type = 0;
        object.code = 0;
        object.values = "";
      }
      if (message.type != null && message.hasOwnProperty("type")) object.type = message.type;
      if (message.code != null && message.hasOwnProperty("code")) object.code = message.code;
      if (message.values != null && message.hasOwnProperty("values")) object.values = message.values;
      return object;
    };

    /**
     * Converts this NotifyPush to JSON.
     * @function toJSON
     * @memberof client_proto.NotifyPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    NotifyPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for NotifyPush
     * @function getTypeUrl
     * @memberof client_proto.NotifyPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    NotifyPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.NotifyPush";
    };

    return NotifyPush;
  })();

  /**
   * BULLETIN_INFO_SUB_MSG_ID enum.
   * @name client_proto.BULLETIN_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} BULLETIN_MSG_ID_NULL=0 BULLETIN_MSG_ID_NULL value
   * @property {number} BULLETIN_MSG_ID_MIN=2470 BULLETIN_MSG_ID_MIN value
   * @property {number} BULLETIN_MSG_ID_USER_BULLETIN=2471 BULLETIN_MSG_ID_USER_BULLETIN value
   * @property {number} BULLETIN_MSG_ID_USER_BULLETIN_RESP=2472 BULLETIN_MSG_ID_USER_BULLETIN_RESP value
   * @property {number} BULLETIN_MSG_ID_MAX=2490 BULLETIN_MSG_ID_MAX value
   */
  client_proto.BULLETIN_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "BULLETIN_MSG_ID_NULL")] = 0;
    values[(valuesById[2470] = "BULLETIN_MSG_ID_MIN")] = 2470;
    values[(valuesById[2471] = "BULLETIN_MSG_ID_USER_BULLETIN")] = 2471;
    values[(valuesById[2472] = "BULLETIN_MSG_ID_USER_BULLETIN_RESP")] = 2472;
    values[(valuesById[2490] = "BULLETIN_MSG_ID_MAX")] = 2490;
    return values;
  })();

  client_proto.UserBulletinReq = (function () {
    /**
     * Properties of a UserBulletinReq.
     * @memberof client_proto
     * @interface IUserBulletinReq
     * @property {number|null} [uid] UserBulletinReq uid
     * @property {string|null} [language] UserBulletinReq language
     * @property {Uint8Array|null} [trans_data] UserBulletinReq trans_data
     */

    /**
     * Constructs a new UserBulletinReq.
     * @memberof client_proto
     * @classdesc Represents a UserBulletinReq.
     * @implements IUserBulletinReq
     * @constructor
     * @param {client_proto.IUserBulletinReq=} [properties] Properties to set
     */
    function UserBulletinReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserBulletinReq uid.
     * @member {number} uid
     * @memberof client_proto.UserBulletinReq
     * @instance
     */
    UserBulletinReq.prototype.uid = 0;

    /**
     * UserBulletinReq language.
     * @member {string} language
     * @memberof client_proto.UserBulletinReq
     * @instance
     */
    UserBulletinReq.prototype.language = "";

    /**
     * UserBulletinReq trans_data.
     * @member {Uint8Array} trans_data
     * @memberof client_proto.UserBulletinReq
     * @instance
     */
    UserBulletinReq.prototype.trans_data = $util.newBuffer([]);

    /**
     * Creates a new UserBulletinReq instance using the specified properties.
     * @function create
     * @memberof client_proto.UserBulletinReq
     * @static
     * @param {client_proto.IUserBulletinReq=} [properties] Properties to set
     * @returns {client_proto.UserBulletinReq} UserBulletinReq instance
     */
    UserBulletinReq.create = function create(properties) {
      return new UserBulletinReq(properties);
    };

    /**
     * Encodes the specified UserBulletinReq message. Does not implicitly {@link client_proto.UserBulletinReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserBulletinReq
     * @static
     * @param {client_proto.IUserBulletinReq} message UserBulletinReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserBulletinReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.language);
      if (message.trans_data != null && Object.hasOwnProperty.call(message, "trans_data"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).bytes(message.trans_data);
      return writer;
    };

    /**
     * Encodes the specified UserBulletinReq message, length delimited. Does not implicitly {@link client_proto.UserBulletinReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserBulletinReq
     * @static
     * @param {client_proto.IUserBulletinReq} message UserBulletinReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserBulletinReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserBulletinReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserBulletinReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserBulletinReq} UserBulletinReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserBulletinReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserBulletinReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.language = reader.string();
            break;
          }
          case 3: {
            message.trans_data = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserBulletinReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserBulletinReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserBulletinReq} UserBulletinReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserBulletinReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserBulletinReq message.
     * @function verify
     * @memberof client_proto.UserBulletinReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserBulletinReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        if (
          !((message.trans_data && typeof message.trans_data.length === "number") || $util.isString(message.trans_data))
        )
          return "trans_data: buffer expected";
      return null;
    };

    /**
     * Creates a UserBulletinReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserBulletinReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserBulletinReq} UserBulletinReq
     */
    UserBulletinReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserBulletinReq) return object;
      var message = new $root.client_proto.UserBulletinReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.language != null) message.language = String(object.language);
      if (object.trans_data != null)
        if (typeof object.trans_data === "string")
          $util.base64.decode(
            object.trans_data,
            (message.trans_data = $util.newBuffer($util.base64.length(object.trans_data))),
            0,
          );
        else if (object.trans_data.length >= 0) message.trans_data = object.trans_data;
      return message;
    };

    /**
     * Creates a plain object from a UserBulletinReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserBulletinReq
     * @static
     * @param {client_proto.UserBulletinReq} message UserBulletinReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserBulletinReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.language = "";
        if (options.bytes === String) object.trans_data = "";
        else {
          object.trans_data = [];
          if (options.bytes !== Array) object.trans_data = $util.newBuffer(object.trans_data);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        object.trans_data =
          options.bytes === String
            ? $util.base64.encode(message.trans_data, 0, message.trans_data.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans_data)
              : message.trans_data;
      return object;
    };

    /**
     * Converts this UserBulletinReq to JSON.
     * @function toJSON
     * @memberof client_proto.UserBulletinReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserBulletinReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserBulletinReq
     * @function getTypeUrl
     * @memberof client_proto.UserBulletinReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserBulletinReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserBulletinReq";
    };

    return UserBulletinReq;
  })();

  client_proto.UserBulletinResp = (function () {
    /**
     * Properties of a UserBulletinResp.
     * @memberof client_proto
     * @interface IUserBulletinResp
     * @property {number|null} [uid] UserBulletinResp uid
     * @property {string|null} [title] UserBulletinResp title
     * @property {string|null} [text] UserBulletinResp text
     * @property {Uint8Array|null} [trans_data] UserBulletinResp trans_data
     */

    /**
     * Constructs a new UserBulletinResp.
     * @memberof client_proto
     * @classdesc Represents a UserBulletinResp.
     * @implements IUserBulletinResp
     * @constructor
     * @param {client_proto.IUserBulletinResp=} [properties] Properties to set
     */
    function UserBulletinResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserBulletinResp uid.
     * @member {number} uid
     * @memberof client_proto.UserBulletinResp
     * @instance
     */
    UserBulletinResp.prototype.uid = 0;

    /**
     * UserBulletinResp title.
     * @member {string} title
     * @memberof client_proto.UserBulletinResp
     * @instance
     */
    UserBulletinResp.prototype.title = "";

    /**
     * UserBulletinResp text.
     * @member {string} text
     * @memberof client_proto.UserBulletinResp
     * @instance
     */
    UserBulletinResp.prototype.text = "";

    /**
     * UserBulletinResp trans_data.
     * @member {Uint8Array} trans_data
     * @memberof client_proto.UserBulletinResp
     * @instance
     */
    UserBulletinResp.prototype.trans_data = $util.newBuffer([]);

    /**
     * Creates a new UserBulletinResp instance using the specified properties.
     * @function create
     * @memberof client_proto.UserBulletinResp
     * @static
     * @param {client_proto.IUserBulletinResp=} [properties] Properties to set
     * @returns {client_proto.UserBulletinResp} UserBulletinResp instance
     */
    UserBulletinResp.create = function create(properties) {
      return new UserBulletinResp(properties);
    };

    /**
     * Encodes the specified UserBulletinResp message. Does not implicitly {@link client_proto.UserBulletinResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserBulletinResp
     * @static
     * @param {client_proto.IUserBulletinResp} message UserBulletinResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserBulletinResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.title != null && Object.hasOwnProperty.call(message, "title"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.title);
      if (message.text != null && Object.hasOwnProperty.call(message, "text"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.text);
      if (message.trans_data != null && Object.hasOwnProperty.call(message, "trans_data"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).bytes(message.trans_data);
      return writer;
    };

    /**
     * Encodes the specified UserBulletinResp message, length delimited. Does not implicitly {@link client_proto.UserBulletinResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserBulletinResp
     * @static
     * @param {client_proto.IUserBulletinResp} message UserBulletinResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserBulletinResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserBulletinResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserBulletinResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserBulletinResp} UserBulletinResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserBulletinResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserBulletinResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.title = reader.string();
            break;
          }
          case 3: {
            message.text = reader.string();
            break;
          }
          case 4: {
            message.trans_data = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserBulletinResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserBulletinResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserBulletinResp} UserBulletinResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserBulletinResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserBulletinResp message.
     * @function verify
     * @memberof client_proto.UserBulletinResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserBulletinResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.title != null && message.hasOwnProperty("title"))
        if (!$util.isString(message.title)) return "title: string expected";
      if (message.text != null && message.hasOwnProperty("text"))
        if (!$util.isString(message.text)) return "text: string expected";
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        if (
          !((message.trans_data && typeof message.trans_data.length === "number") || $util.isString(message.trans_data))
        )
          return "trans_data: buffer expected";
      return null;
    };

    /**
     * Creates a UserBulletinResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserBulletinResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserBulletinResp} UserBulletinResp
     */
    UserBulletinResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserBulletinResp) return object;
      var message = new $root.client_proto.UserBulletinResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.title != null) message.title = String(object.title);
      if (object.text != null) message.text = String(object.text);
      if (object.trans_data != null)
        if (typeof object.trans_data === "string")
          $util.base64.decode(
            object.trans_data,
            (message.trans_data = $util.newBuffer($util.base64.length(object.trans_data))),
            0,
          );
        else if (object.trans_data.length >= 0) message.trans_data = object.trans_data;
      return message;
    };

    /**
     * Creates a plain object from a UserBulletinResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserBulletinResp
     * @static
     * @param {client_proto.UserBulletinResp} message UserBulletinResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserBulletinResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.title = "";
        object.text = "";
        if (options.bytes === String) object.trans_data = "";
        else {
          object.trans_data = [];
          if (options.bytes !== Array) object.trans_data = $util.newBuffer(object.trans_data);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.title != null && message.hasOwnProperty("title")) object.title = message.title;
      if (message.text != null && message.hasOwnProperty("text")) object.text = message.text;
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        object.trans_data =
          options.bytes === String
            ? $util.base64.encode(message.trans_data, 0, message.trans_data.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans_data)
              : message.trans_data;
      return object;
    };

    /**
     * Converts this UserBulletinResp to JSON.
     * @function toJSON
     * @memberof client_proto.UserBulletinResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserBulletinResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserBulletinResp
     * @function getTypeUrl
     * @memberof client_proto.UserBulletinResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserBulletinResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserBulletinResp";
    };

    return UserBulletinResp;
  })();

  /**
   * GAME_BANNER_INFO_SUB_MSG_ID enum.
   * @name client_proto.GAME_BANNER_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} GAME_BANNER_MSG_ID_NULL=0 GAME_BANNER_MSG_ID_NULL value
   * @property {number} GAME_BANNER_MSG_ID_MIN=3400 GAME_BANNER_MSG_ID_MIN value
   * @property {number} GAME_BANNER_MSG_ID_PUSH=3401 GAME_BANNER_MSG_ID_PUSH value
   * @property {number} GAME_BANNER_MSG_ID_USER_PUSH=3403 GAME_BANNER_MSG_ID_USER_PUSH value
   * @property {number} GAME_BANNER_MSG_ID_LABEL_CHANGED=3404 GAME_BANNER_MSG_ID_LABEL_CHANGED value
   * @property {number} GAME_BANNER_MSG_ID_RECHARGE=3405 GAME_BANNER_MSG_ID_RECHARGE value
   * @property {number} GAME_BANNER_MSG_ID_USER_ACT_PUSH_REQ=3406 GAME_BANNER_MSG_ID_USER_ACT_PUSH_REQ value
   * @property {number} GAME_BANNER_MSG_ID_MAX=3450 GAME_BANNER_MSG_ID_MAX value
   */
  client_proto.GAME_BANNER_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "GAME_BANNER_MSG_ID_NULL")] = 0;
    values[(valuesById[3400] = "GAME_BANNER_MSG_ID_MIN")] = 3400;
    values[(valuesById[3401] = "GAME_BANNER_MSG_ID_PUSH")] = 3401;
    values[(valuesById[3403] = "GAME_BANNER_MSG_ID_USER_PUSH")] = 3403;
    values[(valuesById[3404] = "GAME_BANNER_MSG_ID_LABEL_CHANGED")] = 3404;
    values[(valuesById[3405] = "GAME_BANNER_MSG_ID_RECHARGE")] = 3405;
    values[(valuesById[3406] = "GAME_BANNER_MSG_ID_USER_ACT_PUSH_REQ")] = 3406;
    values[(valuesById[3450] = "GAME_BANNER_MSG_ID_MAX")] = 3450;
    return values;
  })();

  /**
   * REASON_ID enum.
   * @name client_proto.REASON_ID
   * @enum {number}
   * @property {number} REASON_ID_NULL=0 REASON_ID_NULL value
   * @property {number} REASON_ID_ENTER=1 REASON_ID_ENTER value
   * @property {number} REASON_ID_WINLOST=2 REASON_ID_WINLOST value
   * @property {number} REASON_ID_BANKRUPTCY=3 REASON_ID_BANKRUPTCY value
   */
  client_proto.REASON_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "REASON_ID_NULL")] = 0;
    values[(valuesById[1] = "REASON_ID_ENTER")] = 1;
    values[(valuesById[2] = "REASON_ID_WINLOST")] = 2;
    values[(valuesById[3] = "REASON_ID_BANKRUPTCY")] = 3;
    return values;
  })();

  client_proto.GameBannerPush = (function () {
    /**
     * Properties of a GameBannerPush.
     * @memberof client_proto
     * @interface IGameBannerPush
     * @property {number|null} [uid] GameBannerPush uid
     * @property {number|null} [reason] GameBannerPush reason
     * @property {number|Long|null} [balance] GameBannerPush balance
     * @property {string|null} [language] GameBannerPush language
     * @property {number|null} [agentid] GameBannerPush agentid
     */

    /**
     * Constructs a new GameBannerPush.
     * @memberof client_proto
     * @classdesc Represents a GameBannerPush.
     * @implements IGameBannerPush
     * @constructor
     * @param {client_proto.IGameBannerPush=} [properties] Properties to set
     */
    function GameBannerPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameBannerPush uid.
     * @member {number} uid
     * @memberof client_proto.GameBannerPush
     * @instance
     */
    GameBannerPush.prototype.uid = 0;

    /**
     * GameBannerPush reason.
     * @member {number} reason
     * @memberof client_proto.GameBannerPush
     * @instance
     */
    GameBannerPush.prototype.reason = 0;

    /**
     * GameBannerPush balance.
     * @member {number|Long} balance
     * @memberof client_proto.GameBannerPush
     * @instance
     */
    GameBannerPush.prototype.balance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GameBannerPush language.
     * @member {string} language
     * @memberof client_proto.GameBannerPush
     * @instance
     */
    GameBannerPush.prototype.language = "";

    /**
     * GameBannerPush agentid.
     * @member {number} agentid
     * @memberof client_proto.GameBannerPush
     * @instance
     */
    GameBannerPush.prototype.agentid = 0;

    /**
     * Creates a new GameBannerPush instance using the specified properties.
     * @function create
     * @memberof client_proto.GameBannerPush
     * @static
     * @param {client_proto.IGameBannerPush=} [properties] Properties to set
     * @returns {client_proto.GameBannerPush} GameBannerPush instance
     */
    GameBannerPush.create = function create(properties) {
      return new GameBannerPush(properties);
    };

    /**
     * Encodes the specified GameBannerPush message. Does not implicitly {@link client_proto.GameBannerPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GameBannerPush
     * @static
     * @param {client_proto.IGameBannerPush} message GameBannerPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameBannerPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.reason);
      if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.balance);
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.language);
      if (message.agentid != null && Object.hasOwnProperty.call(message, "agentid"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).uint32(message.agentid);
      return writer;
    };

    /**
     * Encodes the specified GameBannerPush message, length delimited. Does not implicitly {@link client_proto.GameBannerPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GameBannerPush
     * @static
     * @param {client_proto.IGameBannerPush} message GameBannerPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameBannerPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameBannerPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GameBannerPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GameBannerPush} GameBannerPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameBannerPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GameBannerPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.reason = reader.int32();
            break;
          }
          case 3: {
            message.balance = reader.int64();
            break;
          }
          case 4: {
            message.language = reader.string();
            break;
          }
          case 5: {
            message.agentid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameBannerPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GameBannerPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GameBannerPush} GameBannerPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameBannerPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameBannerPush message.
     * @function verify
     * @memberof client_proto.GameBannerPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameBannerPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.reason != null && message.hasOwnProperty("reason"))
        if (!$util.isInteger(message.reason)) return "reason: integer expected";
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (
          !$util.isInteger(message.balance) &&
          !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high))
        )
          return "balance: integer|Long expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      if (message.agentid != null && message.hasOwnProperty("agentid"))
        if (!$util.isInteger(message.agentid)) return "agentid: integer expected";
      return null;
    };

    /**
     * Creates a GameBannerPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GameBannerPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GameBannerPush} GameBannerPush
     */
    GameBannerPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GameBannerPush) return object;
      var message = new $root.client_proto.GameBannerPush();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.reason != null) message.reason = object.reason | 0;
      if (object.balance != null)
        if ($util.Long) (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
        else if (typeof object.balance === "string") message.balance = parseInt(object.balance, 10);
        else if (typeof object.balance === "number") message.balance = object.balance;
        else if (typeof object.balance === "object")
          message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
      if (object.language != null) message.language = String(object.language);
      if (object.agentid != null) message.agentid = object.agentid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a GameBannerPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GameBannerPush
     * @static
     * @param {client_proto.GameBannerPush} message GameBannerPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameBannerPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.reason = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.balance =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.balance = options.longs === String ? "0" : 0;
        object.language = "";
        object.agentid = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.reason != null && message.hasOwnProperty("reason")) object.reason = message.reason;
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (typeof message.balance === "number")
          object.balance = options.longs === String ? String(message.balance) : message.balance;
        else
          object.balance =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.balance)
              : options.longs === Number
                ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber()
                : message.balance;
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      if (message.agentid != null && message.hasOwnProperty("agentid")) object.agentid = message.agentid;
      return object;
    };

    /**
     * Converts this GameBannerPush to JSON.
     * @function toJSON
     * @memberof client_proto.GameBannerPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameBannerPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameBannerPush
     * @function getTypeUrl
     * @memberof client_proto.GameBannerPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameBannerPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GameBannerPush";
    };

    return GameBannerPush;
  })();

  client_proto.GameBannerUserData = (function () {
    /**
     * Properties of a GameBannerUserData.
     * @memberof client_proto
     * @interface IGameBannerUserData
     * @property {number|null} [banner_id] GameBannerUserData banner_id
     * @property {number|null} [order_id] GameBannerUserData order_id
     * @property {string|null} [jump] GameBannerUserData jump
     * @property {string|null} [title] GameBannerUserData title
     * @property {number|null} [anim] GameBannerUserData anim
     * @property {string|null} [pic] GameBannerUserData pic
     * @property {string|null} [spine_pic] GameBannerUserData spine_pic
     * @property {string|null} [atlas_url] GameBannerUserData atlas_url
     * @property {string|null} [skel_url] GameBannerUserData skel_url
     * @property {string|null} [anim_params] GameBannerUserData anim_params
     * @property {number|null} [pic_duration] GameBannerUserData pic_duration
     * @property {number|null} [reason] GameBannerUserData reason
     * @property {number|null} [type] GameBannerUserData type
     */

    /**
     * Constructs a new GameBannerUserData.
     * @memberof client_proto
     * @classdesc Represents a GameBannerUserData.
     * @implements IGameBannerUserData
     * @constructor
     * @param {client_proto.IGameBannerUserData=} [properties] Properties to set
     */
    function GameBannerUserData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameBannerUserData banner_id.
     * @member {number} banner_id
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.banner_id = 0;

    /**
     * GameBannerUserData order_id.
     * @member {number} order_id
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.order_id = 0;

    /**
     * GameBannerUserData jump.
     * @member {string} jump
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.jump = "";

    /**
     * GameBannerUserData title.
     * @member {string} title
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.title = "";

    /**
     * GameBannerUserData anim.
     * @member {number} anim
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.anim = 0;

    /**
     * GameBannerUserData pic.
     * @member {string} pic
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.pic = "";

    /**
     * GameBannerUserData spine_pic.
     * @member {string} spine_pic
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.spine_pic = "";

    /**
     * GameBannerUserData atlas_url.
     * @member {string} atlas_url
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.atlas_url = "";

    /**
     * GameBannerUserData skel_url.
     * @member {string} skel_url
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.skel_url = "";

    /**
     * GameBannerUserData anim_params.
     * @member {string} anim_params
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.anim_params = "";

    /**
     * GameBannerUserData pic_duration.
     * @member {number} pic_duration
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.pic_duration = 0;

    /**
     * GameBannerUserData reason.
     * @member {number} reason
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.reason = 0;

    /**
     * GameBannerUserData type.
     * @member {number} type
     * @memberof client_proto.GameBannerUserData
     * @instance
     */
    GameBannerUserData.prototype.type = 0;

    /**
     * Creates a new GameBannerUserData instance using the specified properties.
     * @function create
     * @memberof client_proto.GameBannerUserData
     * @static
     * @param {client_proto.IGameBannerUserData=} [properties] Properties to set
     * @returns {client_proto.GameBannerUserData} GameBannerUserData instance
     */
    GameBannerUserData.create = function create(properties) {
      return new GameBannerUserData(properties);
    };

    /**
     * Encodes the specified GameBannerUserData message. Does not implicitly {@link client_proto.GameBannerUserData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GameBannerUserData
     * @static
     * @param {client_proto.IGameBannerUserData} message GameBannerUserData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameBannerUserData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.banner_id != null && Object.hasOwnProperty.call(message, "banner_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.banner_id);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.order_id);
      if (message.jump != null && Object.hasOwnProperty.call(message, "jump"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.jump);
      if (message.title != null && Object.hasOwnProperty.call(message, "title"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.title);
      if (message.anim != null && Object.hasOwnProperty.call(message, "anim"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.anim);
      if (message.pic != null && Object.hasOwnProperty.call(message, "pic"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.pic);
      if (message.spine_pic != null && Object.hasOwnProperty.call(message, "spine_pic"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.spine_pic);
      if (message.atlas_url != null && Object.hasOwnProperty.call(message, "atlas_url"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.atlas_url);
      if (message.skel_url != null && Object.hasOwnProperty.call(message, "skel_url"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.skel_url);
      if (message.anim_params != null && Object.hasOwnProperty.call(message, "anim_params"))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.anim_params);
      if (message.pic_duration != null && Object.hasOwnProperty.call(message, "pic_duration"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int32(message.pic_duration);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).int32(message.reason);
      if (message.type != null && Object.hasOwnProperty.call(message, "type"))
        writer.uint32(/* id 13, wireType 0 =*/ 104).int32(message.type);
      return writer;
    };

    /**
     * Encodes the specified GameBannerUserData message, length delimited. Does not implicitly {@link client_proto.GameBannerUserData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GameBannerUserData
     * @static
     * @param {client_proto.IGameBannerUserData} message GameBannerUserData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameBannerUserData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameBannerUserData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GameBannerUserData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GameBannerUserData} GameBannerUserData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameBannerUserData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GameBannerUserData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.banner_id = reader.int32();
            break;
          }
          case 2: {
            message.order_id = reader.int32();
            break;
          }
          case 3: {
            message.jump = reader.string();
            break;
          }
          case 4: {
            message.title = reader.string();
            break;
          }
          case 5: {
            message.anim = reader.int32();
            break;
          }
          case 6: {
            message.pic = reader.string();
            break;
          }
          case 7: {
            message.spine_pic = reader.string();
            break;
          }
          case 8: {
            message.atlas_url = reader.string();
            break;
          }
          case 9: {
            message.skel_url = reader.string();
            break;
          }
          case 10: {
            message.anim_params = reader.string();
            break;
          }
          case 11: {
            message.pic_duration = reader.int32();
            break;
          }
          case 12: {
            message.reason = reader.int32();
            break;
          }
          case 13: {
            message.type = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameBannerUserData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GameBannerUserData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GameBannerUserData} GameBannerUserData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameBannerUserData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameBannerUserData message.
     * @function verify
     * @memberof client_proto.GameBannerUserData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameBannerUserData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.banner_id != null && message.hasOwnProperty("banner_id"))
        if (!$util.isInteger(message.banner_id)) return "banner_id: integer expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.jump != null && message.hasOwnProperty("jump"))
        if (!$util.isString(message.jump)) return "jump: string expected";
      if (message.title != null && message.hasOwnProperty("title"))
        if (!$util.isString(message.title)) return "title: string expected";
      if (message.anim != null && message.hasOwnProperty("anim"))
        if (!$util.isInteger(message.anim)) return "anim: integer expected";
      if (message.pic != null && message.hasOwnProperty("pic"))
        if (!$util.isString(message.pic)) return "pic: string expected";
      if (message.spine_pic != null && message.hasOwnProperty("spine_pic"))
        if (!$util.isString(message.spine_pic)) return "spine_pic: string expected";
      if (message.atlas_url != null && message.hasOwnProperty("atlas_url"))
        if (!$util.isString(message.atlas_url)) return "atlas_url: string expected";
      if (message.skel_url != null && message.hasOwnProperty("skel_url"))
        if (!$util.isString(message.skel_url)) return "skel_url: string expected";
      if (message.anim_params != null && message.hasOwnProperty("anim_params"))
        if (!$util.isString(message.anim_params)) return "anim_params: string expected";
      if (message.pic_duration != null && message.hasOwnProperty("pic_duration"))
        if (!$util.isInteger(message.pic_duration)) return "pic_duration: integer expected";
      if (message.reason != null && message.hasOwnProperty("reason"))
        if (!$util.isInteger(message.reason)) return "reason: integer expected";
      if (message.type != null && message.hasOwnProperty("type"))
        if (!$util.isInteger(message.type)) return "type: integer expected";
      return null;
    };

    /**
     * Creates a GameBannerUserData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GameBannerUserData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GameBannerUserData} GameBannerUserData
     */
    GameBannerUserData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GameBannerUserData) return object;
      var message = new $root.client_proto.GameBannerUserData();
      if (object.banner_id != null) message.banner_id = object.banner_id | 0;
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.jump != null) message.jump = String(object.jump);
      if (object.title != null) message.title = String(object.title);
      if (object.anim != null) message.anim = object.anim | 0;
      if (object.pic != null) message.pic = String(object.pic);
      if (object.spine_pic != null) message.spine_pic = String(object.spine_pic);
      if (object.atlas_url != null) message.atlas_url = String(object.atlas_url);
      if (object.skel_url != null) message.skel_url = String(object.skel_url);
      if (object.anim_params != null) message.anim_params = String(object.anim_params);
      if (object.pic_duration != null) message.pic_duration = object.pic_duration | 0;
      if (object.reason != null) message.reason = object.reason | 0;
      if (object.type != null) message.type = object.type | 0;
      return message;
    };

    /**
     * Creates a plain object from a GameBannerUserData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GameBannerUserData
     * @static
     * @param {client_proto.GameBannerUserData} message GameBannerUserData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameBannerUserData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.banner_id = 0;
        object.order_id = 0;
        object.jump = "";
        object.title = "";
        object.anim = 0;
        object.pic = "";
        object.spine_pic = "";
        object.atlas_url = "";
        object.skel_url = "";
        object.anim_params = "";
        object.pic_duration = 0;
        object.reason = 0;
        object.type = 0;
      }
      if (message.banner_id != null && message.hasOwnProperty("banner_id")) object.banner_id = message.banner_id;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.jump != null && message.hasOwnProperty("jump")) object.jump = message.jump;
      if (message.title != null && message.hasOwnProperty("title")) object.title = message.title;
      if (message.anim != null && message.hasOwnProperty("anim")) object.anim = message.anim;
      if (message.pic != null && message.hasOwnProperty("pic")) object.pic = message.pic;
      if (message.spine_pic != null && message.hasOwnProperty("spine_pic")) object.spine_pic = message.spine_pic;
      if (message.atlas_url != null && message.hasOwnProperty("atlas_url")) object.atlas_url = message.atlas_url;
      if (message.skel_url != null && message.hasOwnProperty("skel_url")) object.skel_url = message.skel_url;
      if (message.anim_params != null && message.hasOwnProperty("anim_params"))
        object.anim_params = message.anim_params;
      if (message.pic_duration != null && message.hasOwnProperty("pic_duration"))
        object.pic_duration = message.pic_duration;
      if (message.reason != null && message.hasOwnProperty("reason")) object.reason = message.reason;
      if (message.type != null && message.hasOwnProperty("type")) object.type = message.type;
      return object;
    };

    /**
     * Converts this GameBannerUserData to JSON.
     * @function toJSON
     * @memberof client_proto.GameBannerUserData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameBannerUserData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameBannerUserData
     * @function getTypeUrl
     * @memberof client_proto.GameBannerUserData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameBannerUserData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GameBannerUserData";
    };

    return GameBannerUserData;
  })();

  client_proto.GameBannerUserPush = (function () {
    /**
     * Properties of a GameBannerUserPush.
     * @memberof client_proto
     * @interface IGameBannerUserPush
     * @property {Array.<client_proto.IGameBannerUserData>|null} [data_list] GameBannerUserPush data_list
     * @property {string|null} [language] GameBannerUserPush language
     */

    /**
     * Constructs a new GameBannerUserPush.
     * @memberof client_proto
     * @classdesc Represents a GameBannerUserPush.
     * @implements IGameBannerUserPush
     * @constructor
     * @param {client_proto.IGameBannerUserPush=} [properties] Properties to set
     */
    function GameBannerUserPush(properties) {
      this.data_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameBannerUserPush data_list.
     * @member {Array.<client_proto.IGameBannerUserData>} data_list
     * @memberof client_proto.GameBannerUserPush
     * @instance
     */
    GameBannerUserPush.prototype.data_list = $util.emptyArray;

    /**
     * GameBannerUserPush language.
     * @member {string} language
     * @memberof client_proto.GameBannerUserPush
     * @instance
     */
    GameBannerUserPush.prototype.language = "";

    /**
     * Creates a new GameBannerUserPush instance using the specified properties.
     * @function create
     * @memberof client_proto.GameBannerUserPush
     * @static
     * @param {client_proto.IGameBannerUserPush=} [properties] Properties to set
     * @returns {client_proto.GameBannerUserPush} GameBannerUserPush instance
     */
    GameBannerUserPush.create = function create(properties) {
      return new GameBannerUserPush(properties);
    };

    /**
     * Encodes the specified GameBannerUserPush message. Does not implicitly {@link client_proto.GameBannerUserPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GameBannerUserPush
     * @static
     * @param {client_proto.IGameBannerUserPush} message GameBannerUserPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameBannerUserPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data_list != null && message.data_list.length)
        for (var i = 0; i < message.data_list.length; ++i)
          $root.client_proto.GameBannerUserData.encode(
            message.data_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.language);
      return writer;
    };

    /**
     * Encodes the specified GameBannerUserPush message, length delimited. Does not implicitly {@link client_proto.GameBannerUserPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GameBannerUserPush
     * @static
     * @param {client_proto.IGameBannerUserPush} message GameBannerUserPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameBannerUserPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameBannerUserPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GameBannerUserPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GameBannerUserPush} GameBannerUserPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameBannerUserPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GameBannerUserPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.data_list && message.data_list.length)) message.data_list = [];
            message.data_list.push($root.client_proto.GameBannerUserData.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.language = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameBannerUserPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GameBannerUserPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GameBannerUserPush} GameBannerUserPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameBannerUserPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameBannerUserPush message.
     * @function verify
     * @memberof client_proto.GameBannerUserPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameBannerUserPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data_list != null && message.hasOwnProperty("data_list")) {
        if (!Array.isArray(message.data_list)) return "data_list: array expected";
        for (var i = 0; i < message.data_list.length; ++i) {
          var error = $root.client_proto.GameBannerUserData.verify(message.data_list[i]);
          if (error) return "data_list." + error;
        }
      }
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      return null;
    };

    /**
     * Creates a GameBannerUserPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GameBannerUserPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GameBannerUserPush} GameBannerUserPush
     */
    GameBannerUserPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GameBannerUserPush) return object;
      var message = new $root.client_proto.GameBannerUserPush();
      if (object.data_list) {
        if (!Array.isArray(object.data_list))
          throw TypeError(".client_proto.GameBannerUserPush.data_list: array expected");
        message.data_list = [];
        for (var i = 0; i < object.data_list.length; ++i) {
          if (typeof object.data_list[i] !== "object")
            throw TypeError(".client_proto.GameBannerUserPush.data_list: object expected");
          message.data_list[i] = $root.client_proto.GameBannerUserData.fromObject(object.data_list[i]);
        }
      }
      if (object.language != null) message.language = String(object.language);
      return message;
    };

    /**
     * Creates a plain object from a GameBannerUserPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GameBannerUserPush
     * @static
     * @param {client_proto.GameBannerUserPush} message GameBannerUserPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameBannerUserPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.data_list = [];
      if (options.defaults) object.language = "";
      if (message.data_list && message.data_list.length) {
        object.data_list = [];
        for (var j = 0; j < message.data_list.length; ++j)
          object.data_list[j] = $root.client_proto.GameBannerUserData.toObject(message.data_list[j], options);
      }
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      return object;
    };

    /**
     * Converts this GameBannerUserPush to JSON.
     * @function toJSON
     * @memberof client_proto.GameBannerUserPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameBannerUserPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameBannerUserPush
     * @function getTypeUrl
     * @memberof client_proto.GameBannerUserPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameBannerUserPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GameBannerUserPush";
    };

    return GameBannerUserPush;
  })();

  client_proto.AssetFollowNotifyRecharge = (function () {
    /**
     * Properties of an AssetFollowNotifyRecharge.
     * @memberof client_proto
     * @interface IAssetFollowNotifyRecharge
     * @property {number|null} [uid] AssetFollowNotifyRecharge uid
     * @property {number|null} [reason] AssetFollowNotifyRecharge reason
     * @property {number|Long|null} [balance] AssetFollowNotifyRecharge balance
     * @property {number|Long|null} [deposit_amount] AssetFollowNotifyRecharge deposit_amount
     * @property {number|Long|null} [total_amount] AssetFollowNotifyRecharge total_amount
     */

    /**
     * Constructs a new AssetFollowNotifyRecharge.
     * @memberof client_proto
     * @classdesc Represents an AssetFollowNotifyRecharge.
     * @implements IAssetFollowNotifyRecharge
     * @constructor
     * @param {client_proto.IAssetFollowNotifyRecharge=} [properties] Properties to set
     */
    function AssetFollowNotifyRecharge(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AssetFollowNotifyRecharge uid.
     * @member {number} uid
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @instance
     */
    AssetFollowNotifyRecharge.prototype.uid = 0;

    /**
     * AssetFollowNotifyRecharge reason.
     * @member {number} reason
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @instance
     */
    AssetFollowNotifyRecharge.prototype.reason = 0;

    /**
     * AssetFollowNotifyRecharge balance.
     * @member {number|Long} balance
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @instance
     */
    AssetFollowNotifyRecharge.prototype.balance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AssetFollowNotifyRecharge deposit_amount.
     * @member {number|Long} deposit_amount
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @instance
     */
    AssetFollowNotifyRecharge.prototype.deposit_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AssetFollowNotifyRecharge total_amount.
     * @member {number|Long} total_amount
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @instance
     */
    AssetFollowNotifyRecharge.prototype.total_amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new AssetFollowNotifyRecharge instance using the specified properties.
     * @function create
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @static
     * @param {client_proto.IAssetFollowNotifyRecharge=} [properties] Properties to set
     * @returns {client_proto.AssetFollowNotifyRecharge} AssetFollowNotifyRecharge instance
     */
    AssetFollowNotifyRecharge.create = function create(properties) {
      return new AssetFollowNotifyRecharge(properties);
    };

    /**
     * Encodes the specified AssetFollowNotifyRecharge message. Does not implicitly {@link client_proto.AssetFollowNotifyRecharge.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @static
     * @param {client_proto.IAssetFollowNotifyRecharge} message AssetFollowNotifyRecharge message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetFollowNotifyRecharge.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.reason);
      if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.balance);
      if (message.deposit_amount != null && Object.hasOwnProperty.call(message, "deposit_amount"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.deposit_amount);
      if (message.total_amount != null && Object.hasOwnProperty.call(message, "total_amount"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.total_amount);
      return writer;
    };

    /**
     * Encodes the specified AssetFollowNotifyRecharge message, length delimited. Does not implicitly {@link client_proto.AssetFollowNotifyRecharge.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @static
     * @param {client_proto.IAssetFollowNotifyRecharge} message AssetFollowNotifyRecharge message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetFollowNotifyRecharge.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AssetFollowNotifyRecharge message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AssetFollowNotifyRecharge} AssetFollowNotifyRecharge
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetFollowNotifyRecharge.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AssetFollowNotifyRecharge();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.reason = reader.int32();
            break;
          }
          case 3: {
            message.balance = reader.int64();
            break;
          }
          case 4: {
            message.deposit_amount = reader.int64();
            break;
          }
          case 5: {
            message.total_amount = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AssetFollowNotifyRecharge message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AssetFollowNotifyRecharge} AssetFollowNotifyRecharge
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetFollowNotifyRecharge.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AssetFollowNotifyRecharge message.
     * @function verify
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AssetFollowNotifyRecharge.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.reason != null && message.hasOwnProperty("reason"))
        if (!$util.isInteger(message.reason)) return "reason: integer expected";
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (
          !$util.isInteger(message.balance) &&
          !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high))
        )
          return "balance: integer|Long expected";
      if (message.deposit_amount != null && message.hasOwnProperty("deposit_amount"))
        if (
          !$util.isInteger(message.deposit_amount) &&
          !(
            message.deposit_amount &&
            $util.isInteger(message.deposit_amount.low) &&
            $util.isInteger(message.deposit_amount.high)
          )
        )
          return "deposit_amount: integer|Long expected";
      if (message.total_amount != null && message.hasOwnProperty("total_amount"))
        if (
          !$util.isInteger(message.total_amount) &&
          !(
            message.total_amount &&
            $util.isInteger(message.total_amount.low) &&
            $util.isInteger(message.total_amount.high)
          )
        )
          return "total_amount: integer|Long expected";
      return null;
    };

    /**
     * Creates an AssetFollowNotifyRecharge message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AssetFollowNotifyRecharge} AssetFollowNotifyRecharge
     */
    AssetFollowNotifyRecharge.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AssetFollowNotifyRecharge) return object;
      var message = new $root.client_proto.AssetFollowNotifyRecharge();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.reason != null) message.reason = object.reason | 0;
      if (object.balance != null)
        if ($util.Long) (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
        else if (typeof object.balance === "string") message.balance = parseInt(object.balance, 10);
        else if (typeof object.balance === "number") message.balance = object.balance;
        else if (typeof object.balance === "object")
          message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
      if (object.deposit_amount != null)
        if ($util.Long) (message.deposit_amount = $util.Long.fromValue(object.deposit_amount)).unsigned = false;
        else if (typeof object.deposit_amount === "string")
          message.deposit_amount = parseInt(object.deposit_amount, 10);
        else if (typeof object.deposit_amount === "number") message.deposit_amount = object.deposit_amount;
        else if (typeof object.deposit_amount === "object")
          message.deposit_amount = new $util.LongBits(
            object.deposit_amount.low >>> 0,
            object.deposit_amount.high >>> 0,
          ).toNumber();
      if (object.total_amount != null)
        if ($util.Long) (message.total_amount = $util.Long.fromValue(object.total_amount)).unsigned = false;
        else if (typeof object.total_amount === "string") message.total_amount = parseInt(object.total_amount, 10);
        else if (typeof object.total_amount === "number") message.total_amount = object.total_amount;
        else if (typeof object.total_amount === "object")
          message.total_amount = new $util.LongBits(
            object.total_amount.low >>> 0,
            object.total_amount.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from an AssetFollowNotifyRecharge message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @static
     * @param {client_proto.AssetFollowNotifyRecharge} message AssetFollowNotifyRecharge
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AssetFollowNotifyRecharge.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.reason = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.balance =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.balance = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.deposit_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.deposit_amount = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total_amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total_amount = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.reason != null && message.hasOwnProperty("reason")) object.reason = message.reason;
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (typeof message.balance === "number")
          object.balance = options.longs === String ? String(message.balance) : message.balance;
        else
          object.balance =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.balance)
              : options.longs === Number
                ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber()
                : message.balance;
      if (message.deposit_amount != null && message.hasOwnProperty("deposit_amount"))
        if (typeof message.deposit_amount === "number")
          object.deposit_amount = options.longs === String ? String(message.deposit_amount) : message.deposit_amount;
        else
          object.deposit_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.deposit_amount)
              : options.longs === Number
                ? new $util.LongBits(message.deposit_amount.low >>> 0, message.deposit_amount.high >>> 0).toNumber()
                : message.deposit_amount;
      if (message.total_amount != null && message.hasOwnProperty("total_amount"))
        if (typeof message.total_amount === "number")
          object.total_amount = options.longs === String ? String(message.total_amount) : message.total_amount;
        else
          object.total_amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total_amount)
              : options.longs === Number
                ? new $util.LongBits(message.total_amount.low >>> 0, message.total_amount.high >>> 0).toNumber()
                : message.total_amount;
      return object;
    };

    /**
     * Converts this AssetFollowNotifyRecharge to JSON.
     * @function toJSON
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AssetFollowNotifyRecharge.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AssetFollowNotifyRecharge
     * @function getTypeUrl
     * @memberof client_proto.AssetFollowNotifyRecharge
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AssetFollowNotifyRecharge.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AssetFollowNotifyRecharge";
    };

    return AssetFollowNotifyRecharge;
  })();

  client_proto.ClinetActGameBannerReq = (function () {
    /**
     * Properties of a ClinetActGameBannerReq.
     * @memberof client_proto
     * @interface IClinetActGameBannerReq
     * @property {number|null} [uid] ClinetActGameBannerReq uid
     * @property {string|null} [language] ClinetActGameBannerReq language
     */

    /**
     * Constructs a new ClinetActGameBannerReq.
     * @memberof client_proto
     * @classdesc Represents a ClinetActGameBannerReq.
     * @implements IClinetActGameBannerReq
     * @constructor
     * @param {client_proto.IClinetActGameBannerReq=} [properties] Properties to set
     */
    function ClinetActGameBannerReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ClinetActGameBannerReq uid.
     * @member {number} uid
     * @memberof client_proto.ClinetActGameBannerReq
     * @instance
     */
    ClinetActGameBannerReq.prototype.uid = 0;

    /**
     * ClinetActGameBannerReq language.
     * @member {string} language
     * @memberof client_proto.ClinetActGameBannerReq
     * @instance
     */
    ClinetActGameBannerReq.prototype.language = "";

    /**
     * Creates a new ClinetActGameBannerReq instance using the specified properties.
     * @function create
     * @memberof client_proto.ClinetActGameBannerReq
     * @static
     * @param {client_proto.IClinetActGameBannerReq=} [properties] Properties to set
     * @returns {client_proto.ClinetActGameBannerReq} ClinetActGameBannerReq instance
     */
    ClinetActGameBannerReq.create = function create(properties) {
      return new ClinetActGameBannerReq(properties);
    };

    /**
     * Encodes the specified ClinetActGameBannerReq message. Does not implicitly {@link client_proto.ClinetActGameBannerReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ClinetActGameBannerReq
     * @static
     * @param {client_proto.IClinetActGameBannerReq} message ClinetActGameBannerReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClinetActGameBannerReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.language);
      return writer;
    };

    /**
     * Encodes the specified ClinetActGameBannerReq message, length delimited. Does not implicitly {@link client_proto.ClinetActGameBannerReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ClinetActGameBannerReq
     * @static
     * @param {client_proto.IClinetActGameBannerReq} message ClinetActGameBannerReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClinetActGameBannerReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ClinetActGameBannerReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ClinetActGameBannerReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ClinetActGameBannerReq} ClinetActGameBannerReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClinetActGameBannerReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ClinetActGameBannerReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.language = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ClinetActGameBannerReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ClinetActGameBannerReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ClinetActGameBannerReq} ClinetActGameBannerReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClinetActGameBannerReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ClinetActGameBannerReq message.
     * @function verify
     * @memberof client_proto.ClinetActGameBannerReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ClinetActGameBannerReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      return null;
    };

    /**
     * Creates a ClinetActGameBannerReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ClinetActGameBannerReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ClinetActGameBannerReq} ClinetActGameBannerReq
     */
    ClinetActGameBannerReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ClinetActGameBannerReq) return object;
      var message = new $root.client_proto.ClinetActGameBannerReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.language != null) message.language = String(object.language);
      return message;
    };

    /**
     * Creates a plain object from a ClinetActGameBannerReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ClinetActGameBannerReq
     * @static
     * @param {client_proto.ClinetActGameBannerReq} message ClinetActGameBannerReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ClinetActGameBannerReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.language = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      return object;
    };

    /**
     * Converts this ClinetActGameBannerReq to JSON.
     * @function toJSON
     * @memberof client_proto.ClinetActGameBannerReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ClinetActGameBannerReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ClinetActGameBannerReq
     * @function getTypeUrl
     * @memberof client_proto.ClinetActGameBannerReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ClinetActGameBannerReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ClinetActGameBannerReq";
    };

    return ClinetActGameBannerReq;
  })();

  /**
   * ROOM_LIST_SUB_MSG_ID enum.
   * @name client_proto.ROOM_LIST_SUB_MSG_ID
   * @enum {number}
   * @property {number} RLSMI_NULL=0 RLSMI_NULL value
   * @property {number} RLSMI_FIRST_LAYOUT_REQ=1 RLSMI_FIRST_LAYOUT_REQ value
   * @property {number} RLSMI_FIRST_LAYOUT_RESP=2 RLSMI_FIRST_LAYOUT_RESP value
   * @property {number} RLSMI_SECOND_LIST_REQ=3 RLSMI_SECOND_LIST_REQ value
   * @property {number} RLSMI_SECOND_LIST_RESP=4 RLSMI_SECOND_LIST_RESP value
   * @property {number} RLSMI_BEFORE_MATCH_REQ=5 RLSMI_BEFORE_MATCH_REQ value
   * @property {number} RLSMI_BEFORE_MATCH_RESP=6 RLSMI_BEFORE_MATCH_RESP value
   * @property {number} RLSMI_ENTER_MATCH_REQ=7 RLSMI_ENTER_MATCH_REQ value
   * @property {number} RLSMI_ENTER_MATCH_RESP=8 RLSMI_ENTER_MATCH_RESP value
   * @property {number} RLSMI_EXIT_MATCH_REQ=9 RLSMI_EXIT_MATCH_REQ value
   * @property {number} RLSMI_EXIT_MATCH_RESP=10 RLSMI_EXIT_MATCH_RESP value
   * @property {number} RLSMI_ENTER_ROOM_REQ=11 RLSMI_ENTER_ROOM_REQ value
   * @property {number} RLSMI_ENTER_ROOM_RESP=12 RLSMI_ENTER_ROOM_RESP value
   * @property {number} RLSMI_ROBOT_PLAY_REQ=13 RLSMI_ROBOT_PLAY_REQ value
   * @property {number} RLSMI_ROBOT_PLAY_RESP=14 RLSMI_ROBOT_PLAY_RESP value
   * @property {number} RLSMI_ROBOT_EXIT_PLAY_REQ=15 RLSMI_ROBOT_EXIT_PLAY_REQ value
   * @property {number} RLSMI_ROBOT_EXIT_PLAY_RESP=16 RLSMI_ROBOT_EXIT_PLAY_RESP value
   * @property {number} RLSMI_MATCH_INFO_PUSH=100 RLSMI_MATCH_INFO_PUSH value
   * @property {number} RLSMI_COMEBACK_INFO_PUSH=101 RLSMI_COMEBACK_INFO_PUSH value
   */
  client_proto.ROOM_LIST_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "RLSMI_NULL")] = 0;
    values[(valuesById[1] = "RLSMI_FIRST_LAYOUT_REQ")] = 1;
    values[(valuesById[2] = "RLSMI_FIRST_LAYOUT_RESP")] = 2;
    values[(valuesById[3] = "RLSMI_SECOND_LIST_REQ")] = 3;
    values[(valuesById[4] = "RLSMI_SECOND_LIST_RESP")] = 4;
    values[(valuesById[5] = "RLSMI_BEFORE_MATCH_REQ")] = 5;
    values[(valuesById[6] = "RLSMI_BEFORE_MATCH_RESP")] = 6;
    values[(valuesById[7] = "RLSMI_ENTER_MATCH_REQ")] = 7;
    values[(valuesById[8] = "RLSMI_ENTER_MATCH_RESP")] = 8;
    values[(valuesById[9] = "RLSMI_EXIT_MATCH_REQ")] = 9;
    values[(valuesById[10] = "RLSMI_EXIT_MATCH_RESP")] = 10;
    values[(valuesById[11] = "RLSMI_ENTER_ROOM_REQ")] = 11;
    values[(valuesById[12] = "RLSMI_ENTER_ROOM_RESP")] = 12;
    values[(valuesById[13] = "RLSMI_ROBOT_PLAY_REQ")] = 13;
    values[(valuesById[14] = "RLSMI_ROBOT_PLAY_RESP")] = 14;
    values[(valuesById[15] = "RLSMI_ROBOT_EXIT_PLAY_REQ")] = 15;
    values[(valuesById[16] = "RLSMI_ROBOT_EXIT_PLAY_RESP")] = 16;
    values[(valuesById[100] = "RLSMI_MATCH_INFO_PUSH")] = 100;
    values[(valuesById[101] = "RLSMI_COMEBACK_INFO_PUSH")] = 101;
    return values;
  })();

  /**
   * GAME_TYPE enum.
   * @name client_proto.GAME_TYPE
   * @enum {number}
   * @property {number} GT_NULL=0 GT_NULL value
   * @property {number} GT_LUDO=1 GT_LUDO value
   */
  client_proto.GAME_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "GT_NULL")] = 0;
    values[(valuesById[1] = "GT_LUDO")] = 1;
    return values;
  })();

  /**
   * ROOM_TYPE enum.
   * @name client_proto.ROOM_TYPE
   * @enum {number}
   * @property {number} RT_NULL=0 RT_NULL value
   * @property {number} RT_CLASSICS=1 RT_CLASSICS value
   * @property {number} RT_CONTINUE_BOMB=2 RT_CONTINUE_BOMB value
   * @property {number} RT_FLMATCH=3 RT_FLMATCH value
   * @property {number} RT_FRIEND=4 RT_FRIEND value
   */
  client_proto.ROOM_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "RT_NULL")] = 0;
    values[(valuesById[1] = "RT_CLASSICS")] = 1;
    values[(valuesById[2] = "RT_CONTINUE_BOMB")] = 2;
    values[(valuesById[3] = "RT_FLMATCH")] = 3;
    values[(valuesById[4] = "RT_FRIEND")] = 4;
    return values;
  })();

  /**
   * ROOM_LEVEL enum.
   * @name client_proto.ROOM_LEVEL
   * @enum {number}
   * @property {number} RL_NULL=0 RL_NULL value
   * @property {number} RL_BEGINNER=1 RL_BEGINNER value
   * @property {number} RL_INTERMEDIATE=2 RL_INTERMEDIATE value
   * @property {number} RL_ADVANCED=3 RL_ADVANCED value
   * @property {number} RL_MASTER=4 RL_MASTER value
   * @property {number} RL_GREAT_MASTER=5 RL_GREAT_MASTER value
   * @property {number} RL_YOUNG_KING=6 RL_YOUNG_KING value
   */
  client_proto.ROOM_LEVEL = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "RL_NULL")] = 0;
    values[(valuesById[1] = "RL_BEGINNER")] = 1;
    values[(valuesById[2] = "RL_INTERMEDIATE")] = 2;
    values[(valuesById[3] = "RL_ADVANCED")] = 3;
    values[(valuesById[4] = "RL_MASTER")] = 4;
    values[(valuesById[5] = "RL_GREAT_MASTER")] = 5;
    values[(valuesById[6] = "RL_YOUNG_KING")] = 6;
    return values;
  })();

  client_proto.SecondRoomListReq = (function () {
    /**
     * Properties of a SecondRoomListReq.
     * @memberof client_proto
     * @interface ISecondRoomListReq
     * @property {number|null} [game_type] SecondRoomListReq game_type
     */

    /**
     * Constructs a new SecondRoomListReq.
     * @memberof client_proto
     * @classdesc Represents a SecondRoomListReq.
     * @implements ISecondRoomListReq
     * @constructor
     * @param {client_proto.ISecondRoomListReq=} [properties] Properties to set
     */
    function SecondRoomListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SecondRoomListReq game_type.
     * @member {number} game_type
     * @memberof client_proto.SecondRoomListReq
     * @instance
     */
    SecondRoomListReq.prototype.game_type = 0;

    /**
     * Creates a new SecondRoomListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.SecondRoomListReq
     * @static
     * @param {client_proto.ISecondRoomListReq=} [properties] Properties to set
     * @returns {client_proto.SecondRoomListReq} SecondRoomListReq instance
     */
    SecondRoomListReq.create = function create(properties) {
      return new SecondRoomListReq(properties);
    };

    /**
     * Encodes the specified SecondRoomListReq message. Does not implicitly {@link client_proto.SecondRoomListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SecondRoomListReq
     * @static
     * @param {client_proto.ISecondRoomListReq} message SecondRoomListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SecondRoomListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_type != null && Object.hasOwnProperty.call(message, "game_type"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.game_type);
      return writer;
    };

    /**
     * Encodes the specified SecondRoomListReq message, length delimited. Does not implicitly {@link client_proto.SecondRoomListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SecondRoomListReq
     * @static
     * @param {client_proto.ISecondRoomListReq} message SecondRoomListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SecondRoomListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SecondRoomListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SecondRoomListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SecondRoomListReq} SecondRoomListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SecondRoomListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SecondRoomListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.game_type = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SecondRoomListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SecondRoomListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SecondRoomListReq} SecondRoomListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SecondRoomListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SecondRoomListReq message.
     * @function verify
     * @memberof client_proto.SecondRoomListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SecondRoomListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_type != null && message.hasOwnProperty("game_type"))
        if (!$util.isInteger(message.game_type)) return "game_type: integer expected";
      return null;
    };

    /**
     * Creates a SecondRoomListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SecondRoomListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SecondRoomListReq} SecondRoomListReq
     */
    SecondRoomListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SecondRoomListReq) return object;
      var message = new $root.client_proto.SecondRoomListReq();
      if (object.game_type != null) message.game_type = object.game_type | 0;
      return message;
    };

    /**
     * Creates a plain object from a SecondRoomListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SecondRoomListReq
     * @static
     * @param {client_proto.SecondRoomListReq} message SecondRoomListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SecondRoomListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.game_type = 0;
      if (message.game_type != null && message.hasOwnProperty("game_type")) object.game_type = message.game_type;
      return object;
    };

    /**
     * Converts this SecondRoomListReq to JSON.
     * @function toJSON
     * @memberof client_proto.SecondRoomListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SecondRoomListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SecondRoomListReq
     * @function getTypeUrl
     * @memberof client_proto.SecondRoomListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SecondRoomListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SecondRoomListReq";
    };

    return SecondRoomListReq;
  })();

  client_proto.OneRoomInfo = (function () {
    /**
     * Properties of an OneRoomInfo.
     * @memberof client_proto
     * @interface IOneRoomInfo
     * @property {number|null} [room_id] OneRoomInfo room_id
     * @property {string|null} [room_name] OneRoomInfo room_name
     * @property {number|null} [room_level] OneRoomInfo room_level
     * @property {number|Long|null} [enter_min] OneRoomInfo enter_min
     * @property {number|Long|null} [enter_max] OneRoomInfo enter_max
     * @property {number|null} [base_score] OneRoomInfo base_score
     * @property {number|null} [player_num] OneRoomInfo player_num
     */

    /**
     * Constructs a new OneRoomInfo.
     * @memberof client_proto
     * @classdesc Represents an OneRoomInfo.
     * @implements IOneRoomInfo
     * @constructor
     * @param {client_proto.IOneRoomInfo=} [properties] Properties to set
     */
    function OneRoomInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * OneRoomInfo room_id.
     * @member {number} room_id
     * @memberof client_proto.OneRoomInfo
     * @instance
     */
    OneRoomInfo.prototype.room_id = 0;

    /**
     * OneRoomInfo room_name.
     * @member {string} room_name
     * @memberof client_proto.OneRoomInfo
     * @instance
     */
    OneRoomInfo.prototype.room_name = "";

    /**
     * OneRoomInfo room_level.
     * @member {number} room_level
     * @memberof client_proto.OneRoomInfo
     * @instance
     */
    OneRoomInfo.prototype.room_level = 0;

    /**
     * OneRoomInfo enter_min.
     * @member {number|Long} enter_min
     * @memberof client_proto.OneRoomInfo
     * @instance
     */
    OneRoomInfo.prototype.enter_min = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * OneRoomInfo enter_max.
     * @member {number|Long} enter_max
     * @memberof client_proto.OneRoomInfo
     * @instance
     */
    OneRoomInfo.prototype.enter_max = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * OneRoomInfo base_score.
     * @member {number} base_score
     * @memberof client_proto.OneRoomInfo
     * @instance
     */
    OneRoomInfo.prototype.base_score = 0;

    /**
     * OneRoomInfo player_num.
     * @member {number} player_num
     * @memberof client_proto.OneRoomInfo
     * @instance
     */
    OneRoomInfo.prototype.player_num = 0;

    /**
     * Creates a new OneRoomInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.OneRoomInfo
     * @static
     * @param {client_proto.IOneRoomInfo=} [properties] Properties to set
     * @returns {client_proto.OneRoomInfo} OneRoomInfo instance
     */
    OneRoomInfo.create = function create(properties) {
      return new OneRoomInfo(properties);
    };

    /**
     * Encodes the specified OneRoomInfo message. Does not implicitly {@link client_proto.OneRoomInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.OneRoomInfo
     * @static
     * @param {client_proto.IOneRoomInfo} message OneRoomInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OneRoomInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      if (message.room_name != null && Object.hasOwnProperty.call(message, "room_name"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.room_name);
      if (message.room_level != null && Object.hasOwnProperty.call(message, "room_level"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.room_level);
      if (message.enter_min != null && Object.hasOwnProperty.call(message, "enter_min"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.enter_min);
      if (message.enter_max != null && Object.hasOwnProperty.call(message, "enter_max"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.enter_max);
      if (message.base_score != null && Object.hasOwnProperty.call(message, "base_score"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.base_score);
      if (message.player_num != null && Object.hasOwnProperty.call(message, "player_num"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.player_num);
      return writer;
    };

    /**
     * Encodes the specified OneRoomInfo message, length delimited. Does not implicitly {@link client_proto.OneRoomInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.OneRoomInfo
     * @static
     * @param {client_proto.IOneRoomInfo} message OneRoomInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OneRoomInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an OneRoomInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.OneRoomInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.OneRoomInfo} OneRoomInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OneRoomInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.OneRoomInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          case 2: {
            message.room_name = reader.string();
            break;
          }
          case 3: {
            message.room_level = reader.int32();
            break;
          }
          case 4: {
            message.enter_min = reader.int64();
            break;
          }
          case 5: {
            message.enter_max = reader.int64();
            break;
          }
          case 6: {
            message.base_score = reader.int32();
            break;
          }
          case 7: {
            message.player_num = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an OneRoomInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.OneRoomInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.OneRoomInfo} OneRoomInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OneRoomInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an OneRoomInfo message.
     * @function verify
     * @memberof client_proto.OneRoomInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    OneRoomInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      if (message.room_name != null && message.hasOwnProperty("room_name"))
        if (!$util.isString(message.room_name)) return "room_name: string expected";
      if (message.room_level != null && message.hasOwnProperty("room_level"))
        if (!$util.isInteger(message.room_level)) return "room_level: integer expected";
      if (message.enter_min != null && message.hasOwnProperty("enter_min"))
        if (
          !$util.isInteger(message.enter_min) &&
          !(message.enter_min && $util.isInteger(message.enter_min.low) && $util.isInteger(message.enter_min.high))
        )
          return "enter_min: integer|Long expected";
      if (message.enter_max != null && message.hasOwnProperty("enter_max"))
        if (
          !$util.isInteger(message.enter_max) &&
          !(message.enter_max && $util.isInteger(message.enter_max.low) && $util.isInteger(message.enter_max.high))
        )
          return "enter_max: integer|Long expected";
      if (message.base_score != null && message.hasOwnProperty("base_score"))
        if (!$util.isInteger(message.base_score)) return "base_score: integer expected";
      if (message.player_num != null && message.hasOwnProperty("player_num"))
        if (!$util.isInteger(message.player_num)) return "player_num: integer expected";
      return null;
    };

    /**
     * Creates an OneRoomInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.OneRoomInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.OneRoomInfo} OneRoomInfo
     */
    OneRoomInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.OneRoomInfo) return object;
      var message = new $root.client_proto.OneRoomInfo();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      if (object.room_name != null) message.room_name = String(object.room_name);
      if (object.room_level != null) message.room_level = object.room_level | 0;
      if (object.enter_min != null)
        if ($util.Long) (message.enter_min = $util.Long.fromValue(object.enter_min)).unsigned = false;
        else if (typeof object.enter_min === "string") message.enter_min = parseInt(object.enter_min, 10);
        else if (typeof object.enter_min === "number") message.enter_min = object.enter_min;
        else if (typeof object.enter_min === "object")
          message.enter_min = new $util.LongBits(object.enter_min.low >>> 0, object.enter_min.high >>> 0).toNumber();
      if (object.enter_max != null)
        if ($util.Long) (message.enter_max = $util.Long.fromValue(object.enter_max)).unsigned = false;
        else if (typeof object.enter_max === "string") message.enter_max = parseInt(object.enter_max, 10);
        else if (typeof object.enter_max === "number") message.enter_max = object.enter_max;
        else if (typeof object.enter_max === "object")
          message.enter_max = new $util.LongBits(object.enter_max.low >>> 0, object.enter_max.high >>> 0).toNumber();
      if (object.base_score != null) message.base_score = object.base_score | 0;
      if (object.player_num != null) message.player_num = object.player_num | 0;
      return message;
    };

    /**
     * Creates a plain object from an OneRoomInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.OneRoomInfo
     * @static
     * @param {client_proto.OneRoomInfo} message OneRoomInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    OneRoomInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.room_id = 0;
        object.room_name = "";
        object.room_level = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.enter_min =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.enter_min = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.enter_max =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.enter_max = options.longs === String ? "0" : 0;
        object.base_score = 0;
        object.player_num = 0;
      }
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      if (message.room_name != null && message.hasOwnProperty("room_name")) object.room_name = message.room_name;
      if (message.room_level != null && message.hasOwnProperty("room_level")) object.room_level = message.room_level;
      if (message.enter_min != null && message.hasOwnProperty("enter_min"))
        if (typeof message.enter_min === "number")
          object.enter_min = options.longs === String ? String(message.enter_min) : message.enter_min;
        else
          object.enter_min =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.enter_min)
              : options.longs === Number
                ? new $util.LongBits(message.enter_min.low >>> 0, message.enter_min.high >>> 0).toNumber()
                : message.enter_min;
      if (message.enter_max != null && message.hasOwnProperty("enter_max"))
        if (typeof message.enter_max === "number")
          object.enter_max = options.longs === String ? String(message.enter_max) : message.enter_max;
        else
          object.enter_max =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.enter_max)
              : options.longs === Number
                ? new $util.LongBits(message.enter_max.low >>> 0, message.enter_max.high >>> 0).toNumber()
                : message.enter_max;
      if (message.base_score != null && message.hasOwnProperty("base_score")) object.base_score = message.base_score;
      if (message.player_num != null && message.hasOwnProperty("player_num")) object.player_num = message.player_num;
      return object;
    };

    /**
     * Converts this OneRoomInfo to JSON.
     * @function toJSON
     * @memberof client_proto.OneRoomInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    OneRoomInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for OneRoomInfo
     * @function getTypeUrl
     * @memberof client_proto.OneRoomInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    OneRoomInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.OneRoomInfo";
    };

    return OneRoomInfo;
  })();

  client_proto.OneRoomTypeInfo = (function () {
    /**
     * Properties of an OneRoomTypeInfo.
     * @memberof client_proto
     * @interface IOneRoomTypeInfo
     * @property {number|null} [room_type] OneRoomTypeInfo room_type
     * @property {string|null} [type_name] OneRoomTypeInfo type_name
     * @property {Array.<client_proto.IOneRoomInfo>|null} [room_list] OneRoomTypeInfo room_list
     */

    /**
     * Constructs a new OneRoomTypeInfo.
     * @memberof client_proto
     * @classdesc Represents an OneRoomTypeInfo.
     * @implements IOneRoomTypeInfo
     * @constructor
     * @param {client_proto.IOneRoomTypeInfo=} [properties] Properties to set
     */
    function OneRoomTypeInfo(properties) {
      this.room_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * OneRoomTypeInfo room_type.
     * @member {number} room_type
     * @memberof client_proto.OneRoomTypeInfo
     * @instance
     */
    OneRoomTypeInfo.prototype.room_type = 0;

    /**
     * OneRoomTypeInfo type_name.
     * @member {string} type_name
     * @memberof client_proto.OneRoomTypeInfo
     * @instance
     */
    OneRoomTypeInfo.prototype.type_name = "";

    /**
     * OneRoomTypeInfo room_list.
     * @member {Array.<client_proto.IOneRoomInfo>} room_list
     * @memberof client_proto.OneRoomTypeInfo
     * @instance
     */
    OneRoomTypeInfo.prototype.room_list = $util.emptyArray;

    /**
     * Creates a new OneRoomTypeInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.OneRoomTypeInfo
     * @static
     * @param {client_proto.IOneRoomTypeInfo=} [properties] Properties to set
     * @returns {client_proto.OneRoomTypeInfo} OneRoomTypeInfo instance
     */
    OneRoomTypeInfo.create = function create(properties) {
      return new OneRoomTypeInfo(properties);
    };

    /**
     * Encodes the specified OneRoomTypeInfo message. Does not implicitly {@link client_proto.OneRoomTypeInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.OneRoomTypeInfo
     * @static
     * @param {client_proto.IOneRoomTypeInfo} message OneRoomTypeInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OneRoomTypeInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_type != null && Object.hasOwnProperty.call(message, "room_type"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_type);
      if (message.type_name != null && Object.hasOwnProperty.call(message, "type_name"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.type_name);
      if (message.room_list != null && message.room_list.length)
        for (var i = 0; i < message.room_list.length; ++i)
          $root.client_proto.OneRoomInfo.encode(
            message.room_list[i],
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified OneRoomTypeInfo message, length delimited. Does not implicitly {@link client_proto.OneRoomTypeInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.OneRoomTypeInfo
     * @static
     * @param {client_proto.IOneRoomTypeInfo} message OneRoomTypeInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OneRoomTypeInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an OneRoomTypeInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.OneRoomTypeInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.OneRoomTypeInfo} OneRoomTypeInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OneRoomTypeInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.OneRoomTypeInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_type = reader.int32();
            break;
          }
          case 2: {
            message.type_name = reader.string();
            break;
          }
          case 3: {
            if (!(message.room_list && message.room_list.length)) message.room_list = [];
            message.room_list.push($root.client_proto.OneRoomInfo.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an OneRoomTypeInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.OneRoomTypeInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.OneRoomTypeInfo} OneRoomTypeInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OneRoomTypeInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an OneRoomTypeInfo message.
     * @function verify
     * @memberof client_proto.OneRoomTypeInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    OneRoomTypeInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_type != null && message.hasOwnProperty("room_type"))
        if (!$util.isInteger(message.room_type)) return "room_type: integer expected";
      if (message.type_name != null && message.hasOwnProperty("type_name"))
        if (!$util.isString(message.type_name)) return "type_name: string expected";
      if (message.room_list != null && message.hasOwnProperty("room_list")) {
        if (!Array.isArray(message.room_list)) return "room_list: array expected";
        for (var i = 0; i < message.room_list.length; ++i) {
          var error = $root.client_proto.OneRoomInfo.verify(message.room_list[i]);
          if (error) return "room_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates an OneRoomTypeInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.OneRoomTypeInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.OneRoomTypeInfo} OneRoomTypeInfo
     */
    OneRoomTypeInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.OneRoomTypeInfo) return object;
      var message = new $root.client_proto.OneRoomTypeInfo();
      if (object.room_type != null) message.room_type = object.room_type | 0;
      if (object.type_name != null) message.type_name = String(object.type_name);
      if (object.room_list) {
        if (!Array.isArray(object.room_list))
          throw TypeError(".client_proto.OneRoomTypeInfo.room_list: array expected");
        message.room_list = [];
        for (var i = 0; i < object.room_list.length; ++i) {
          if (typeof object.room_list[i] !== "object")
            throw TypeError(".client_proto.OneRoomTypeInfo.room_list: object expected");
          message.room_list[i] = $root.client_proto.OneRoomInfo.fromObject(object.room_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from an OneRoomTypeInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.OneRoomTypeInfo
     * @static
     * @param {client_proto.OneRoomTypeInfo} message OneRoomTypeInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    OneRoomTypeInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.room_list = [];
      if (options.defaults) {
        object.room_type = 0;
        object.type_name = "";
      }
      if (message.room_type != null && message.hasOwnProperty("room_type")) object.room_type = message.room_type;
      if (message.type_name != null && message.hasOwnProperty("type_name")) object.type_name = message.type_name;
      if (message.room_list && message.room_list.length) {
        object.room_list = [];
        for (var j = 0; j < message.room_list.length; ++j)
          object.room_list[j] = $root.client_proto.OneRoomInfo.toObject(message.room_list[j], options);
      }
      return object;
    };

    /**
     * Converts this OneRoomTypeInfo to JSON.
     * @function toJSON
     * @memberof client_proto.OneRoomTypeInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    OneRoomTypeInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for OneRoomTypeInfo
     * @function getTypeUrl
     * @memberof client_proto.OneRoomTypeInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    OneRoomTypeInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.OneRoomTypeInfo";
    };

    return OneRoomTypeInfo;
  })();

  client_proto.SecondRoomListResp = (function () {
    /**
     * Properties of a SecondRoomListResp.
     * @memberof client_proto
     * @interface ISecondRoomListResp
     * @property {number|null} [game_type] SecondRoomListResp game_type
     * @property {number|null} [default_room_type] SecondRoomListResp default_room_type
     * @property {Array.<client_proto.IOneRoomTypeInfo>|null} [type_list] SecondRoomListResp type_list
     */

    /**
     * Constructs a new SecondRoomListResp.
     * @memberof client_proto
     * @classdesc Represents a SecondRoomListResp.
     * @implements ISecondRoomListResp
     * @constructor
     * @param {client_proto.ISecondRoomListResp=} [properties] Properties to set
     */
    function SecondRoomListResp(properties) {
      this.type_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * SecondRoomListResp game_type.
     * @member {number} game_type
     * @memberof client_proto.SecondRoomListResp
     * @instance
     */
    SecondRoomListResp.prototype.game_type = 0;

    /**
     * SecondRoomListResp default_room_type.
     * @member {number} default_room_type
     * @memberof client_proto.SecondRoomListResp
     * @instance
     */
    SecondRoomListResp.prototype.default_room_type = 0;

    /**
     * SecondRoomListResp type_list.
     * @member {Array.<client_proto.IOneRoomTypeInfo>} type_list
     * @memberof client_proto.SecondRoomListResp
     * @instance
     */
    SecondRoomListResp.prototype.type_list = $util.emptyArray;

    /**
     * Creates a new SecondRoomListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.SecondRoomListResp
     * @static
     * @param {client_proto.ISecondRoomListResp=} [properties] Properties to set
     * @returns {client_proto.SecondRoomListResp} SecondRoomListResp instance
     */
    SecondRoomListResp.create = function create(properties) {
      return new SecondRoomListResp(properties);
    };

    /**
     * Encodes the specified SecondRoomListResp message. Does not implicitly {@link client_proto.SecondRoomListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.SecondRoomListResp
     * @static
     * @param {client_proto.ISecondRoomListResp} message SecondRoomListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SecondRoomListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_type != null && Object.hasOwnProperty.call(message, "game_type"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.game_type);
      if (message.default_room_type != null && Object.hasOwnProperty.call(message, "default_room_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.default_room_type);
      if (message.type_list != null && message.type_list.length)
        for (var i = 0; i < message.type_list.length; ++i)
          $root.client_proto.OneRoomTypeInfo.encode(
            message.type_list[i],
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified SecondRoomListResp message, length delimited. Does not implicitly {@link client_proto.SecondRoomListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.SecondRoomListResp
     * @static
     * @param {client_proto.ISecondRoomListResp} message SecondRoomListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    SecondRoomListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a SecondRoomListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.SecondRoomListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.SecondRoomListResp} SecondRoomListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SecondRoomListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.SecondRoomListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.game_type = reader.int32();
            break;
          }
          case 2: {
            message.default_room_type = reader.int32();
            break;
          }
          case 3: {
            if (!(message.type_list && message.type_list.length)) message.type_list = [];
            message.type_list.push($root.client_proto.OneRoomTypeInfo.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a SecondRoomListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.SecondRoomListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.SecondRoomListResp} SecondRoomListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    SecondRoomListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a SecondRoomListResp message.
     * @function verify
     * @memberof client_proto.SecondRoomListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    SecondRoomListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_type != null && message.hasOwnProperty("game_type"))
        if (!$util.isInteger(message.game_type)) return "game_type: integer expected";
      if (message.default_room_type != null && message.hasOwnProperty("default_room_type"))
        if (!$util.isInteger(message.default_room_type)) return "default_room_type: integer expected";
      if (message.type_list != null && message.hasOwnProperty("type_list")) {
        if (!Array.isArray(message.type_list)) return "type_list: array expected";
        for (var i = 0; i < message.type_list.length; ++i) {
          var error = $root.client_proto.OneRoomTypeInfo.verify(message.type_list[i]);
          if (error) return "type_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates a SecondRoomListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.SecondRoomListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.SecondRoomListResp} SecondRoomListResp
     */
    SecondRoomListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.SecondRoomListResp) return object;
      var message = new $root.client_proto.SecondRoomListResp();
      if (object.game_type != null) message.game_type = object.game_type | 0;
      if (object.default_room_type != null) message.default_room_type = object.default_room_type | 0;
      if (object.type_list) {
        if (!Array.isArray(object.type_list))
          throw TypeError(".client_proto.SecondRoomListResp.type_list: array expected");
        message.type_list = [];
        for (var i = 0; i < object.type_list.length; ++i) {
          if (typeof object.type_list[i] !== "object")
            throw TypeError(".client_proto.SecondRoomListResp.type_list: object expected");
          message.type_list[i] = $root.client_proto.OneRoomTypeInfo.fromObject(object.type_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a SecondRoomListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.SecondRoomListResp
     * @static
     * @param {client_proto.SecondRoomListResp} message SecondRoomListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    SecondRoomListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.type_list = [];
      if (options.defaults) {
        object.game_type = 0;
        object.default_room_type = 0;
      }
      if (message.game_type != null && message.hasOwnProperty("game_type")) object.game_type = message.game_type;
      if (message.default_room_type != null && message.hasOwnProperty("default_room_type"))
        object.default_room_type = message.default_room_type;
      if (message.type_list && message.type_list.length) {
        object.type_list = [];
        for (var j = 0; j < message.type_list.length; ++j)
          object.type_list[j] = $root.client_proto.OneRoomTypeInfo.toObject(message.type_list[j], options);
      }
      return object;
    };

    /**
     * Converts this SecondRoomListResp to JSON.
     * @function toJSON
     * @memberof client_proto.SecondRoomListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    SecondRoomListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for SecondRoomListResp
     * @function getTypeUrl
     * @memberof client_proto.SecondRoomListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    SecondRoomListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.SecondRoomListResp";
    };

    return SecondRoomListResp;
  })();

  client_proto.BeforeMatchTableReq = (function () {
    /**
     * Properties of a BeforeMatchTableReq.
     * @memberof client_proto
     * @interface IBeforeMatchTableReq
     * @property {number|null} [room_id] BeforeMatchTableReq room_id
     */

    /**
     * Constructs a new BeforeMatchTableReq.
     * @memberof client_proto
     * @classdesc Represents a BeforeMatchTableReq.
     * @implements IBeforeMatchTableReq
     * @constructor
     * @param {client_proto.IBeforeMatchTableReq=} [properties] Properties to set
     */
    function BeforeMatchTableReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * BeforeMatchTableReq room_id.
     * @member {number} room_id
     * @memberof client_proto.BeforeMatchTableReq
     * @instance
     */
    BeforeMatchTableReq.prototype.room_id = 0;

    /**
     * Creates a new BeforeMatchTableReq instance using the specified properties.
     * @function create
     * @memberof client_proto.BeforeMatchTableReq
     * @static
     * @param {client_proto.IBeforeMatchTableReq=} [properties] Properties to set
     * @returns {client_proto.BeforeMatchTableReq} BeforeMatchTableReq instance
     */
    BeforeMatchTableReq.create = function create(properties) {
      return new BeforeMatchTableReq(properties);
    };

    /**
     * Encodes the specified BeforeMatchTableReq message. Does not implicitly {@link client_proto.BeforeMatchTableReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.BeforeMatchTableReq
     * @static
     * @param {client_proto.IBeforeMatchTableReq} message BeforeMatchTableReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BeforeMatchTableReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      return writer;
    };

    /**
     * Encodes the specified BeforeMatchTableReq message, length delimited. Does not implicitly {@link client_proto.BeforeMatchTableReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.BeforeMatchTableReq
     * @static
     * @param {client_proto.IBeforeMatchTableReq} message BeforeMatchTableReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BeforeMatchTableReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BeforeMatchTableReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.BeforeMatchTableReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.BeforeMatchTableReq} BeforeMatchTableReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BeforeMatchTableReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.BeforeMatchTableReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a BeforeMatchTableReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.BeforeMatchTableReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.BeforeMatchTableReq} BeforeMatchTableReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BeforeMatchTableReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BeforeMatchTableReq message.
     * @function verify
     * @memberof client_proto.BeforeMatchTableReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BeforeMatchTableReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      return null;
    };

    /**
     * Creates a BeforeMatchTableReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.BeforeMatchTableReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.BeforeMatchTableReq} BeforeMatchTableReq
     */
    BeforeMatchTableReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.BeforeMatchTableReq) return object;
      var message = new $root.client_proto.BeforeMatchTableReq();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a BeforeMatchTableReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.BeforeMatchTableReq
     * @static
     * @param {client_proto.BeforeMatchTableReq} message BeforeMatchTableReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BeforeMatchTableReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.room_id = 0;
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      return object;
    };

    /**
     * Converts this BeforeMatchTableReq to JSON.
     * @function toJSON
     * @memberof client_proto.BeforeMatchTableReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BeforeMatchTableReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BeforeMatchTableReq
     * @function getTypeUrl
     * @memberof client_proto.BeforeMatchTableReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BeforeMatchTableReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.BeforeMatchTableReq";
    };

    return BeforeMatchTableReq;
  })();

  client_proto.BeforeMatchTableResp = (function () {
    /**
     * Properties of a BeforeMatchTableResp.
     * @memberof client_proto
     * @interface IBeforeMatchTableResp
     * @property {number|null} [room_id] BeforeMatchTableResp room_id
     * @property {number|null} [svr_id] BeforeMatchTableResp svr_id
     * @property {number|null} [result] BeforeMatchTableResp result
     * @property {number|null} [game_type] BeforeMatchTableResp game_type
     * @property {number|null} [room_type] BeforeMatchTableResp room_type
     */

    /**
     * Constructs a new BeforeMatchTableResp.
     * @memberof client_proto
     * @classdesc Represents a BeforeMatchTableResp.
     * @implements IBeforeMatchTableResp
     * @constructor
     * @param {client_proto.IBeforeMatchTableResp=} [properties] Properties to set
     */
    function BeforeMatchTableResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * BeforeMatchTableResp room_id.
     * @member {number} room_id
     * @memberof client_proto.BeforeMatchTableResp
     * @instance
     */
    BeforeMatchTableResp.prototype.room_id = 0;

    /**
     * BeforeMatchTableResp svr_id.
     * @member {number} svr_id
     * @memberof client_proto.BeforeMatchTableResp
     * @instance
     */
    BeforeMatchTableResp.prototype.svr_id = 0;

    /**
     * BeforeMatchTableResp result.
     * @member {number} result
     * @memberof client_proto.BeforeMatchTableResp
     * @instance
     */
    BeforeMatchTableResp.prototype.result = 0;

    /**
     * BeforeMatchTableResp game_type.
     * @member {number} game_type
     * @memberof client_proto.BeforeMatchTableResp
     * @instance
     */
    BeforeMatchTableResp.prototype.game_type = 0;

    /**
     * BeforeMatchTableResp room_type.
     * @member {number} room_type
     * @memberof client_proto.BeforeMatchTableResp
     * @instance
     */
    BeforeMatchTableResp.prototype.room_type = 0;

    /**
     * Creates a new BeforeMatchTableResp instance using the specified properties.
     * @function create
     * @memberof client_proto.BeforeMatchTableResp
     * @static
     * @param {client_proto.IBeforeMatchTableResp=} [properties] Properties to set
     * @returns {client_proto.BeforeMatchTableResp} BeforeMatchTableResp instance
     */
    BeforeMatchTableResp.create = function create(properties) {
      return new BeforeMatchTableResp(properties);
    };

    /**
     * Encodes the specified BeforeMatchTableResp message. Does not implicitly {@link client_proto.BeforeMatchTableResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.BeforeMatchTableResp
     * @static
     * @param {client_proto.IBeforeMatchTableResp} message BeforeMatchTableResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BeforeMatchTableResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      if (message.svr_id != null && Object.hasOwnProperty.call(message, "svr_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.svr_id);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.result);
      if (message.game_type != null && Object.hasOwnProperty.call(message, "game_type"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.game_type);
      if (message.room_type != null && Object.hasOwnProperty.call(message, "room_type"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.room_type);
      return writer;
    };

    /**
     * Encodes the specified BeforeMatchTableResp message, length delimited. Does not implicitly {@link client_proto.BeforeMatchTableResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.BeforeMatchTableResp
     * @static
     * @param {client_proto.IBeforeMatchTableResp} message BeforeMatchTableResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BeforeMatchTableResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BeforeMatchTableResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.BeforeMatchTableResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.BeforeMatchTableResp} BeforeMatchTableResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BeforeMatchTableResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.BeforeMatchTableResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          case 2: {
            message.svr_id = reader.int32();
            break;
          }
          case 3: {
            message.result = reader.int32();
            break;
          }
          case 4: {
            message.game_type = reader.int32();
            break;
          }
          case 5: {
            message.room_type = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a BeforeMatchTableResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.BeforeMatchTableResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.BeforeMatchTableResp} BeforeMatchTableResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BeforeMatchTableResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BeforeMatchTableResp message.
     * @function verify
     * @memberof client_proto.BeforeMatchTableResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BeforeMatchTableResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      if (message.svr_id != null && message.hasOwnProperty("svr_id"))
        if (!$util.isInteger(message.svr_id)) return "svr_id: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.game_type != null && message.hasOwnProperty("game_type"))
        if (!$util.isInteger(message.game_type)) return "game_type: integer expected";
      if (message.room_type != null && message.hasOwnProperty("room_type"))
        if (!$util.isInteger(message.room_type)) return "room_type: integer expected";
      return null;
    };

    /**
     * Creates a BeforeMatchTableResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.BeforeMatchTableResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.BeforeMatchTableResp} BeforeMatchTableResp
     */
    BeforeMatchTableResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.BeforeMatchTableResp) return object;
      var message = new $root.client_proto.BeforeMatchTableResp();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      if (object.svr_id != null) message.svr_id = object.svr_id | 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.game_type != null) message.game_type = object.game_type | 0;
      if (object.room_type != null) message.room_type = object.room_type | 0;
      return message;
    };

    /**
     * Creates a plain object from a BeforeMatchTableResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.BeforeMatchTableResp
     * @static
     * @param {client_proto.BeforeMatchTableResp} message BeforeMatchTableResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BeforeMatchTableResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.room_id = 0;
        object.svr_id = 0;
        object.result = 0;
        object.game_type = 0;
        object.room_type = 0;
      }
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      if (message.svr_id != null && message.hasOwnProperty("svr_id")) object.svr_id = message.svr_id;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.game_type != null && message.hasOwnProperty("game_type")) object.game_type = message.game_type;
      if (message.room_type != null && message.hasOwnProperty("room_type")) object.room_type = message.room_type;
      return object;
    };

    /**
     * Converts this BeforeMatchTableResp to JSON.
     * @function toJSON
     * @memberof client_proto.BeforeMatchTableResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BeforeMatchTableResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BeforeMatchTableResp
     * @function getTypeUrl
     * @memberof client_proto.BeforeMatchTableResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BeforeMatchTableResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.BeforeMatchTableResp";
    };

    return BeforeMatchTableResp;
  })();

  client_proto.EnterMatchTableReq = (function () {
    /**
     * Properties of an EnterMatchTableReq.
     * @memberof client_proto
     * @interface IEnterMatchTableReq
     * @property {number|null} [room_id] EnterMatchTableReq room_id
     */

    /**
     * Constructs a new EnterMatchTableReq.
     * @memberof client_proto
     * @classdesc Represents an EnterMatchTableReq.
     * @implements IEnterMatchTableReq
     * @constructor
     * @param {client_proto.IEnterMatchTableReq=} [properties] Properties to set
     */
    function EnterMatchTableReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * EnterMatchTableReq room_id.
     * @member {number} room_id
     * @memberof client_proto.EnterMatchTableReq
     * @instance
     */
    EnterMatchTableReq.prototype.room_id = 0;

    /**
     * Creates a new EnterMatchTableReq instance using the specified properties.
     * @function create
     * @memberof client_proto.EnterMatchTableReq
     * @static
     * @param {client_proto.IEnterMatchTableReq=} [properties] Properties to set
     * @returns {client_proto.EnterMatchTableReq} EnterMatchTableReq instance
     */
    EnterMatchTableReq.create = function create(properties) {
      return new EnterMatchTableReq(properties);
    };

    /**
     * Encodes the specified EnterMatchTableReq message. Does not implicitly {@link client_proto.EnterMatchTableReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.EnterMatchTableReq
     * @static
     * @param {client_proto.IEnterMatchTableReq} message EnterMatchTableReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnterMatchTableReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      return writer;
    };

    /**
     * Encodes the specified EnterMatchTableReq message, length delimited. Does not implicitly {@link client_proto.EnterMatchTableReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.EnterMatchTableReq
     * @static
     * @param {client_proto.IEnterMatchTableReq} message EnterMatchTableReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnterMatchTableReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an EnterMatchTableReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.EnterMatchTableReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.EnterMatchTableReq} EnterMatchTableReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnterMatchTableReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.EnterMatchTableReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an EnterMatchTableReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.EnterMatchTableReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.EnterMatchTableReq} EnterMatchTableReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnterMatchTableReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an EnterMatchTableReq message.
     * @function verify
     * @memberof client_proto.EnterMatchTableReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    EnterMatchTableReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      return null;
    };

    /**
     * Creates an EnterMatchTableReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.EnterMatchTableReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.EnterMatchTableReq} EnterMatchTableReq
     */
    EnterMatchTableReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.EnterMatchTableReq) return object;
      var message = new $root.client_proto.EnterMatchTableReq();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      return message;
    };

    /**
     * Creates a plain object from an EnterMatchTableReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.EnterMatchTableReq
     * @static
     * @param {client_proto.EnterMatchTableReq} message EnterMatchTableReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    EnterMatchTableReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.room_id = 0;
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      return object;
    };

    /**
     * Converts this EnterMatchTableReq to JSON.
     * @function toJSON
     * @memberof client_proto.EnterMatchTableReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    EnterMatchTableReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for EnterMatchTableReq
     * @function getTypeUrl
     * @memberof client_proto.EnterMatchTableReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    EnterMatchTableReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.EnterMatchTableReq";
    };

    return EnterMatchTableReq;
  })();

  client_proto.EnterMatchTableResp = (function () {
    /**
     * Properties of an EnterMatchTableResp.
     * @memberof client_proto
     * @interface IEnterMatchTableResp
     * @property {number|null} [room_id] EnterMatchTableResp room_id
     * @property {number|null} [result] EnterMatchTableResp result
     * @property {number|null} [wait_sec] EnterMatchTableResp wait_sec
     * @property {string|null} [room_name] EnterMatchTableResp room_name
     * @property {number|null} [room_base] EnterMatchTableResp room_base
     */

    /**
     * Constructs a new EnterMatchTableResp.
     * @memberof client_proto
     * @classdesc Represents an EnterMatchTableResp.
     * @implements IEnterMatchTableResp
     * @constructor
     * @param {client_proto.IEnterMatchTableResp=} [properties] Properties to set
     */
    function EnterMatchTableResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * EnterMatchTableResp room_id.
     * @member {number} room_id
     * @memberof client_proto.EnterMatchTableResp
     * @instance
     */
    EnterMatchTableResp.prototype.room_id = 0;

    /**
     * EnterMatchTableResp result.
     * @member {number} result
     * @memberof client_proto.EnterMatchTableResp
     * @instance
     */
    EnterMatchTableResp.prototype.result = 0;

    /**
     * EnterMatchTableResp wait_sec.
     * @member {number} wait_sec
     * @memberof client_proto.EnterMatchTableResp
     * @instance
     */
    EnterMatchTableResp.prototype.wait_sec = 0;

    /**
     * EnterMatchTableResp room_name.
     * @member {string} room_name
     * @memberof client_proto.EnterMatchTableResp
     * @instance
     */
    EnterMatchTableResp.prototype.room_name = "";

    /**
     * EnterMatchTableResp room_base.
     * @member {number} room_base
     * @memberof client_proto.EnterMatchTableResp
     * @instance
     */
    EnterMatchTableResp.prototype.room_base = 0;

    /**
     * Creates a new EnterMatchTableResp instance using the specified properties.
     * @function create
     * @memberof client_proto.EnterMatchTableResp
     * @static
     * @param {client_proto.IEnterMatchTableResp=} [properties] Properties to set
     * @returns {client_proto.EnterMatchTableResp} EnterMatchTableResp instance
     */
    EnterMatchTableResp.create = function create(properties) {
      return new EnterMatchTableResp(properties);
    };

    /**
     * Encodes the specified EnterMatchTableResp message. Does not implicitly {@link client_proto.EnterMatchTableResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.EnterMatchTableResp
     * @static
     * @param {client_proto.IEnterMatchTableResp} message EnterMatchTableResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnterMatchTableResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.wait_sec != null && Object.hasOwnProperty.call(message, "wait_sec"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.wait_sec);
      if (message.room_name != null && Object.hasOwnProperty.call(message, "room_name"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.room_name);
      if (message.room_base != null && Object.hasOwnProperty.call(message, "room_base"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.room_base);
      return writer;
    };

    /**
     * Encodes the specified EnterMatchTableResp message, length delimited. Does not implicitly {@link client_proto.EnterMatchTableResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.EnterMatchTableResp
     * @static
     * @param {client_proto.IEnterMatchTableResp} message EnterMatchTableResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnterMatchTableResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an EnterMatchTableResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.EnterMatchTableResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.EnterMatchTableResp} EnterMatchTableResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnterMatchTableResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.EnterMatchTableResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            message.wait_sec = reader.int32();
            break;
          }
          case 4: {
            message.room_name = reader.string();
            break;
          }
          case 5: {
            message.room_base = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an EnterMatchTableResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.EnterMatchTableResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.EnterMatchTableResp} EnterMatchTableResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnterMatchTableResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an EnterMatchTableResp message.
     * @function verify
     * @memberof client_proto.EnterMatchTableResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    EnterMatchTableResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.wait_sec != null && message.hasOwnProperty("wait_sec"))
        if (!$util.isInteger(message.wait_sec)) return "wait_sec: integer expected";
      if (message.room_name != null && message.hasOwnProperty("room_name"))
        if (!$util.isString(message.room_name)) return "room_name: string expected";
      if (message.room_base != null && message.hasOwnProperty("room_base"))
        if (!$util.isInteger(message.room_base)) return "room_base: integer expected";
      return null;
    };

    /**
     * Creates an EnterMatchTableResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.EnterMatchTableResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.EnterMatchTableResp} EnterMatchTableResp
     */
    EnterMatchTableResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.EnterMatchTableResp) return object;
      var message = new $root.client_proto.EnterMatchTableResp();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.wait_sec != null) message.wait_sec = object.wait_sec | 0;
      if (object.room_name != null) message.room_name = String(object.room_name);
      if (object.room_base != null) message.room_base = object.room_base | 0;
      return message;
    };

    /**
     * Creates a plain object from an EnterMatchTableResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.EnterMatchTableResp
     * @static
     * @param {client_proto.EnterMatchTableResp} message EnterMatchTableResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    EnterMatchTableResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.room_id = 0;
        object.result = 0;
        object.wait_sec = 0;
        object.room_name = "";
        object.room_base = 0;
      }
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.wait_sec != null && message.hasOwnProperty("wait_sec")) object.wait_sec = message.wait_sec;
      if (message.room_name != null && message.hasOwnProperty("room_name")) object.room_name = message.room_name;
      if (message.room_base != null && message.hasOwnProperty("room_base")) object.room_base = message.room_base;
      return object;
    };

    /**
     * Converts this EnterMatchTableResp to JSON.
     * @function toJSON
     * @memberof client_proto.EnterMatchTableResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    EnterMatchTableResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for EnterMatchTableResp
     * @function getTypeUrl
     * @memberof client_proto.EnterMatchTableResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    EnterMatchTableResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.EnterMatchTableResp";
    };

    return EnterMatchTableResp;
  })();

  client_proto.MatchedTableInfoPush = (function () {
    /**
     * Properties of a MatchedTableInfoPush.
     * @memberof client_proto
     * @interface IMatchedTableInfoPush
     * @property {number|null} [room_id] MatchedTableInfoPush room_id
     * @property {number|null} [svr_id] MatchedTableInfoPush svr_id
     */

    /**
     * Constructs a new MatchedTableInfoPush.
     * @memberof client_proto
     * @classdesc Represents a MatchedTableInfoPush.
     * @implements IMatchedTableInfoPush
     * @constructor
     * @param {client_proto.IMatchedTableInfoPush=} [properties] Properties to set
     */
    function MatchedTableInfoPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MatchedTableInfoPush room_id.
     * @member {number} room_id
     * @memberof client_proto.MatchedTableInfoPush
     * @instance
     */
    MatchedTableInfoPush.prototype.room_id = 0;

    /**
     * MatchedTableInfoPush svr_id.
     * @member {number} svr_id
     * @memberof client_proto.MatchedTableInfoPush
     * @instance
     */
    MatchedTableInfoPush.prototype.svr_id = 0;

    /**
     * Creates a new MatchedTableInfoPush instance using the specified properties.
     * @function create
     * @memberof client_proto.MatchedTableInfoPush
     * @static
     * @param {client_proto.IMatchedTableInfoPush=} [properties] Properties to set
     * @returns {client_proto.MatchedTableInfoPush} MatchedTableInfoPush instance
     */
    MatchedTableInfoPush.create = function create(properties) {
      return new MatchedTableInfoPush(properties);
    };

    /**
     * Encodes the specified MatchedTableInfoPush message. Does not implicitly {@link client_proto.MatchedTableInfoPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.MatchedTableInfoPush
     * @static
     * @param {client_proto.IMatchedTableInfoPush} message MatchedTableInfoPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MatchedTableInfoPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      if (message.svr_id != null && Object.hasOwnProperty.call(message, "svr_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.svr_id);
      return writer;
    };

    /**
     * Encodes the specified MatchedTableInfoPush message, length delimited. Does not implicitly {@link client_proto.MatchedTableInfoPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.MatchedTableInfoPush
     * @static
     * @param {client_proto.IMatchedTableInfoPush} message MatchedTableInfoPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MatchedTableInfoPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MatchedTableInfoPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.MatchedTableInfoPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.MatchedTableInfoPush} MatchedTableInfoPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MatchedTableInfoPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.MatchedTableInfoPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          case 2: {
            message.svr_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MatchedTableInfoPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.MatchedTableInfoPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.MatchedTableInfoPush} MatchedTableInfoPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MatchedTableInfoPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MatchedTableInfoPush message.
     * @function verify
     * @memberof client_proto.MatchedTableInfoPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MatchedTableInfoPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      if (message.svr_id != null && message.hasOwnProperty("svr_id"))
        if (!$util.isInteger(message.svr_id)) return "svr_id: integer expected";
      return null;
    };

    /**
     * Creates a MatchedTableInfoPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.MatchedTableInfoPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.MatchedTableInfoPush} MatchedTableInfoPush
     */
    MatchedTableInfoPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.MatchedTableInfoPush) return object;
      var message = new $root.client_proto.MatchedTableInfoPush();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      if (object.svr_id != null) message.svr_id = object.svr_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a MatchedTableInfoPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.MatchedTableInfoPush
     * @static
     * @param {client_proto.MatchedTableInfoPush} message MatchedTableInfoPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MatchedTableInfoPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.room_id = 0;
        object.svr_id = 0;
      }
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      if (message.svr_id != null && message.hasOwnProperty("svr_id")) object.svr_id = message.svr_id;
      return object;
    };

    /**
     * Converts this MatchedTableInfoPush to JSON.
     * @function toJSON
     * @memberof client_proto.MatchedTableInfoPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MatchedTableInfoPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MatchedTableInfoPush
     * @function getTypeUrl
     * @memberof client_proto.MatchedTableInfoPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MatchedTableInfoPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.MatchedTableInfoPush";
    };

    return MatchedTableInfoPush;
  })();

  client_proto.ExitMatchTableReq = (function () {
    /**
     * Properties of an ExitMatchTableReq.
     * @memberof client_proto
     * @interface IExitMatchTableReq
     * @property {number|null} [room_id] ExitMatchTableReq room_id
     */

    /**
     * Constructs a new ExitMatchTableReq.
     * @memberof client_proto
     * @classdesc Represents an ExitMatchTableReq.
     * @implements IExitMatchTableReq
     * @constructor
     * @param {client_proto.IExitMatchTableReq=} [properties] Properties to set
     */
    function ExitMatchTableReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ExitMatchTableReq room_id.
     * @member {number} room_id
     * @memberof client_proto.ExitMatchTableReq
     * @instance
     */
    ExitMatchTableReq.prototype.room_id = 0;

    /**
     * Creates a new ExitMatchTableReq instance using the specified properties.
     * @function create
     * @memberof client_proto.ExitMatchTableReq
     * @static
     * @param {client_proto.IExitMatchTableReq=} [properties] Properties to set
     * @returns {client_proto.ExitMatchTableReq} ExitMatchTableReq instance
     */
    ExitMatchTableReq.create = function create(properties) {
      return new ExitMatchTableReq(properties);
    };

    /**
     * Encodes the specified ExitMatchTableReq message. Does not implicitly {@link client_proto.ExitMatchTableReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ExitMatchTableReq
     * @static
     * @param {client_proto.IExitMatchTableReq} message ExitMatchTableReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExitMatchTableReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      return writer;
    };

    /**
     * Encodes the specified ExitMatchTableReq message, length delimited. Does not implicitly {@link client_proto.ExitMatchTableReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ExitMatchTableReq
     * @static
     * @param {client_proto.IExitMatchTableReq} message ExitMatchTableReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExitMatchTableReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ExitMatchTableReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ExitMatchTableReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ExitMatchTableReq} ExitMatchTableReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExitMatchTableReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ExitMatchTableReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an ExitMatchTableReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ExitMatchTableReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ExitMatchTableReq} ExitMatchTableReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExitMatchTableReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ExitMatchTableReq message.
     * @function verify
     * @memberof client_proto.ExitMatchTableReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ExitMatchTableReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      return null;
    };

    /**
     * Creates an ExitMatchTableReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ExitMatchTableReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ExitMatchTableReq} ExitMatchTableReq
     */
    ExitMatchTableReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ExitMatchTableReq) return object;
      var message = new $root.client_proto.ExitMatchTableReq();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      return message;
    };

    /**
     * Creates a plain object from an ExitMatchTableReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ExitMatchTableReq
     * @static
     * @param {client_proto.ExitMatchTableReq} message ExitMatchTableReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ExitMatchTableReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.room_id = 0;
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      return object;
    };

    /**
     * Converts this ExitMatchTableReq to JSON.
     * @function toJSON
     * @memberof client_proto.ExitMatchTableReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ExitMatchTableReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ExitMatchTableReq
     * @function getTypeUrl
     * @memberof client_proto.ExitMatchTableReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ExitMatchTableReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ExitMatchTableReq";
    };

    return ExitMatchTableReq;
  })();

  client_proto.ExitMatchTableResp = (function () {
    /**
     * Properties of an ExitMatchTableResp.
     * @memberof client_proto
     * @interface IExitMatchTableResp
     * @property {number|null} [room_id] ExitMatchTableResp room_id
     * @property {number|null} [from] ExitMatchTableResp from
     * @property {number|null} [result] ExitMatchTableResp result
     */

    /**
     * Constructs a new ExitMatchTableResp.
     * @memberof client_proto
     * @classdesc Represents an ExitMatchTableResp.
     * @implements IExitMatchTableResp
     * @constructor
     * @param {client_proto.IExitMatchTableResp=} [properties] Properties to set
     */
    function ExitMatchTableResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ExitMatchTableResp room_id.
     * @member {number} room_id
     * @memberof client_proto.ExitMatchTableResp
     * @instance
     */
    ExitMatchTableResp.prototype.room_id = 0;

    /**
     * ExitMatchTableResp from.
     * @member {number} from
     * @memberof client_proto.ExitMatchTableResp
     * @instance
     */
    ExitMatchTableResp.prototype.from = 0;

    /**
     * ExitMatchTableResp result.
     * @member {number} result
     * @memberof client_proto.ExitMatchTableResp
     * @instance
     */
    ExitMatchTableResp.prototype.result = 0;

    /**
     * Creates a new ExitMatchTableResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ExitMatchTableResp
     * @static
     * @param {client_proto.IExitMatchTableResp=} [properties] Properties to set
     * @returns {client_proto.ExitMatchTableResp} ExitMatchTableResp instance
     */
    ExitMatchTableResp.create = function create(properties) {
      return new ExitMatchTableResp(properties);
    };

    /**
     * Encodes the specified ExitMatchTableResp message. Does not implicitly {@link client_proto.ExitMatchTableResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ExitMatchTableResp
     * @static
     * @param {client_proto.IExitMatchTableResp} message ExitMatchTableResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExitMatchTableResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      if (message.from != null && Object.hasOwnProperty.call(message, "from"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.from);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified ExitMatchTableResp message, length delimited. Does not implicitly {@link client_proto.ExitMatchTableResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ExitMatchTableResp
     * @static
     * @param {client_proto.IExitMatchTableResp} message ExitMatchTableResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExitMatchTableResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an ExitMatchTableResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ExitMatchTableResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ExitMatchTableResp} ExitMatchTableResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExitMatchTableResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ExitMatchTableResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          case 2: {
            message.from = reader.int32();
            break;
          }
          case 3: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an ExitMatchTableResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ExitMatchTableResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ExitMatchTableResp} ExitMatchTableResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExitMatchTableResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an ExitMatchTableResp message.
     * @function verify
     * @memberof client_proto.ExitMatchTableResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ExitMatchTableResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      if (message.from != null && message.hasOwnProperty("from"))
        if (!$util.isInteger(message.from)) return "from: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates an ExitMatchTableResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ExitMatchTableResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ExitMatchTableResp} ExitMatchTableResp
     */
    ExitMatchTableResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ExitMatchTableResp) return object;
      var message = new $root.client_proto.ExitMatchTableResp();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      if (object.from != null) message.from = object.from | 0;
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from an ExitMatchTableResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ExitMatchTableResp
     * @static
     * @param {client_proto.ExitMatchTableResp} message ExitMatchTableResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ExitMatchTableResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.room_id = 0;
        object.from = 0;
        object.result = 0;
      }
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      if (message.from != null && message.hasOwnProperty("from")) object.from = message.from;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this ExitMatchTableResp to JSON.
     * @function toJSON
     * @memberof client_proto.ExitMatchTableResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ExitMatchTableResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ExitMatchTableResp
     * @function getTypeUrl
     * @memberof client_proto.ExitMatchTableResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ExitMatchTableResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ExitMatchTableResp";
    };

    return ExitMatchTableResp;
  })();

  client_proto.EnterRoomReq = (function () {
    /**
     * Properties of an EnterRoomReq.
     * @memberof client_proto
     * @interface IEnterRoomReq
     * @property {number|null} [room_id] EnterRoomReq room_id
     */

    /**
     * Constructs a new EnterRoomReq.
     * @memberof client_proto
     * @classdesc Represents an EnterRoomReq.
     * @implements IEnterRoomReq
     * @constructor
     * @param {client_proto.IEnterRoomReq=} [properties] Properties to set
     */
    function EnterRoomReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * EnterRoomReq room_id.
     * @member {number} room_id
     * @memberof client_proto.EnterRoomReq
     * @instance
     */
    EnterRoomReq.prototype.room_id = 0;

    /**
     * Creates a new EnterRoomReq instance using the specified properties.
     * @function create
     * @memberof client_proto.EnterRoomReq
     * @static
     * @param {client_proto.IEnterRoomReq=} [properties] Properties to set
     * @returns {client_proto.EnterRoomReq} EnterRoomReq instance
     */
    EnterRoomReq.create = function create(properties) {
      return new EnterRoomReq(properties);
    };

    /**
     * Encodes the specified EnterRoomReq message. Does not implicitly {@link client_proto.EnterRoomReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.EnterRoomReq
     * @static
     * @param {client_proto.IEnterRoomReq} message EnterRoomReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnterRoomReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      return writer;
    };

    /**
     * Encodes the specified EnterRoomReq message, length delimited. Does not implicitly {@link client_proto.EnterRoomReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.EnterRoomReq
     * @static
     * @param {client_proto.IEnterRoomReq} message EnterRoomReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnterRoomReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an EnterRoomReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.EnterRoomReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.EnterRoomReq} EnterRoomReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnterRoomReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.EnterRoomReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an EnterRoomReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.EnterRoomReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.EnterRoomReq} EnterRoomReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnterRoomReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an EnterRoomReq message.
     * @function verify
     * @memberof client_proto.EnterRoomReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    EnterRoomReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      return null;
    };

    /**
     * Creates an EnterRoomReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.EnterRoomReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.EnterRoomReq} EnterRoomReq
     */
    EnterRoomReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.EnterRoomReq) return object;
      var message = new $root.client_proto.EnterRoomReq();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      return message;
    };

    /**
     * Creates a plain object from an EnterRoomReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.EnterRoomReq
     * @static
     * @param {client_proto.EnterRoomReq} message EnterRoomReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    EnterRoomReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.room_id = 0;
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      return object;
    };

    /**
     * Converts this EnterRoomReq to JSON.
     * @function toJSON
     * @memberof client_proto.EnterRoomReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    EnterRoomReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for EnterRoomReq
     * @function getTypeUrl
     * @memberof client_proto.EnterRoomReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    EnterRoomReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.EnterRoomReq";
    };

    return EnterRoomReq;
  })();

  client_proto.EnterRoomResp = (function () {
    /**
     * Properties of an EnterRoomResp.
     * @memberof client_proto
     * @interface IEnterRoomResp
     * @property {number|null} [room_id] EnterRoomResp room_id
     * @property {number|null} [result] EnterRoomResp result
     */

    /**
     * Constructs a new EnterRoomResp.
     * @memberof client_proto
     * @classdesc Represents an EnterRoomResp.
     * @implements IEnterRoomResp
     * @constructor
     * @param {client_proto.IEnterRoomResp=} [properties] Properties to set
     */
    function EnterRoomResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * EnterRoomResp room_id.
     * @member {number} room_id
     * @memberof client_proto.EnterRoomResp
     * @instance
     */
    EnterRoomResp.prototype.room_id = 0;

    /**
     * EnterRoomResp result.
     * @member {number} result
     * @memberof client_proto.EnterRoomResp
     * @instance
     */
    EnterRoomResp.prototype.result = 0;

    /**
     * Creates a new EnterRoomResp instance using the specified properties.
     * @function create
     * @memberof client_proto.EnterRoomResp
     * @static
     * @param {client_proto.IEnterRoomResp=} [properties] Properties to set
     * @returns {client_proto.EnterRoomResp} EnterRoomResp instance
     */
    EnterRoomResp.create = function create(properties) {
      return new EnterRoomResp(properties);
    };

    /**
     * Encodes the specified EnterRoomResp message. Does not implicitly {@link client_proto.EnterRoomResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.EnterRoomResp
     * @static
     * @param {client_proto.IEnterRoomResp} message EnterRoomResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnterRoomResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified EnterRoomResp message, length delimited. Does not implicitly {@link client_proto.EnterRoomResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.EnterRoomResp
     * @static
     * @param {client_proto.IEnterRoomResp} message EnterRoomResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    EnterRoomResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an EnterRoomResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.EnterRoomResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.EnterRoomResp} EnterRoomResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnterRoomResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.EnterRoomResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an EnterRoomResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.EnterRoomResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.EnterRoomResp} EnterRoomResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    EnterRoomResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an EnterRoomResp message.
     * @function verify
     * @memberof client_proto.EnterRoomResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    EnterRoomResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates an EnterRoomResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.EnterRoomResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.EnterRoomResp} EnterRoomResp
     */
    EnterRoomResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.EnterRoomResp) return object;
      var message = new $root.client_proto.EnterRoomResp();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from an EnterRoomResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.EnterRoomResp
     * @static
     * @param {client_proto.EnterRoomResp} message EnterRoomResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    EnterRoomResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.room_id = 0;
        object.result = 0;
      }
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this EnterRoomResp to JSON.
     * @function toJSON
     * @memberof client_proto.EnterRoomResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    EnterRoomResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for EnterRoomResp
     * @function getTypeUrl
     * @memberof client_proto.EnterRoomResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    EnterRoomResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.EnterRoomResp";
    };

    return EnterRoomResp;
  })();

  client_proto.ComebackRoomInfoPush = (function () {
    /**
     * Properties of a ComebackRoomInfoPush.
     * @memberof client_proto
     * @interface IComebackRoomInfoPush
     * @property {number|null} [room_id] ComebackRoomInfoPush room_id
     * @property {number|null} [svr_id] ComebackRoomInfoPush svr_id
     * @property {number|null} [game_type] ComebackRoomInfoPush game_type
     * @property {number|null} [room_type] ComebackRoomInfoPush room_type
     */

    /**
     * Constructs a new ComebackRoomInfoPush.
     * @memberof client_proto
     * @classdesc Represents a ComebackRoomInfoPush.
     * @implements IComebackRoomInfoPush
     * @constructor
     * @param {client_proto.IComebackRoomInfoPush=} [properties] Properties to set
     */
    function ComebackRoomInfoPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ComebackRoomInfoPush room_id.
     * @member {number} room_id
     * @memberof client_proto.ComebackRoomInfoPush
     * @instance
     */
    ComebackRoomInfoPush.prototype.room_id = 0;

    /**
     * ComebackRoomInfoPush svr_id.
     * @member {number} svr_id
     * @memberof client_proto.ComebackRoomInfoPush
     * @instance
     */
    ComebackRoomInfoPush.prototype.svr_id = 0;

    /**
     * ComebackRoomInfoPush game_type.
     * @member {number} game_type
     * @memberof client_proto.ComebackRoomInfoPush
     * @instance
     */
    ComebackRoomInfoPush.prototype.game_type = 0;

    /**
     * ComebackRoomInfoPush room_type.
     * @member {number} room_type
     * @memberof client_proto.ComebackRoomInfoPush
     * @instance
     */
    ComebackRoomInfoPush.prototype.room_type = 0;

    /**
     * Creates a new ComebackRoomInfoPush instance using the specified properties.
     * @function create
     * @memberof client_proto.ComebackRoomInfoPush
     * @static
     * @param {client_proto.IComebackRoomInfoPush=} [properties] Properties to set
     * @returns {client_proto.ComebackRoomInfoPush} ComebackRoomInfoPush instance
     */
    ComebackRoomInfoPush.create = function create(properties) {
      return new ComebackRoomInfoPush(properties);
    };

    /**
     * Encodes the specified ComebackRoomInfoPush message. Does not implicitly {@link client_proto.ComebackRoomInfoPush.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ComebackRoomInfoPush
     * @static
     * @param {client_proto.IComebackRoomInfoPush} message ComebackRoomInfoPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ComebackRoomInfoPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      if (message.svr_id != null && Object.hasOwnProperty.call(message, "svr_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.svr_id);
      if (message.game_type != null && Object.hasOwnProperty.call(message, "game_type"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.game_type);
      if (message.room_type != null && Object.hasOwnProperty.call(message, "room_type"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.room_type);
      return writer;
    };

    /**
     * Encodes the specified ComebackRoomInfoPush message, length delimited. Does not implicitly {@link client_proto.ComebackRoomInfoPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ComebackRoomInfoPush
     * @static
     * @param {client_proto.IComebackRoomInfoPush} message ComebackRoomInfoPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ComebackRoomInfoPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ComebackRoomInfoPush message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ComebackRoomInfoPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ComebackRoomInfoPush} ComebackRoomInfoPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ComebackRoomInfoPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ComebackRoomInfoPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          case 2: {
            message.svr_id = reader.int32();
            break;
          }
          case 3: {
            message.game_type = reader.int32();
            break;
          }
          case 4: {
            message.room_type = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ComebackRoomInfoPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ComebackRoomInfoPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ComebackRoomInfoPush} ComebackRoomInfoPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ComebackRoomInfoPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ComebackRoomInfoPush message.
     * @function verify
     * @memberof client_proto.ComebackRoomInfoPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ComebackRoomInfoPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      if (message.svr_id != null && message.hasOwnProperty("svr_id"))
        if (!$util.isInteger(message.svr_id)) return "svr_id: integer expected";
      if (message.game_type != null && message.hasOwnProperty("game_type"))
        if (!$util.isInteger(message.game_type)) return "game_type: integer expected";
      if (message.room_type != null && message.hasOwnProperty("room_type"))
        if (!$util.isInteger(message.room_type)) return "room_type: integer expected";
      return null;
    };

    /**
     * Creates a ComebackRoomInfoPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ComebackRoomInfoPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ComebackRoomInfoPush} ComebackRoomInfoPush
     */
    ComebackRoomInfoPush.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ComebackRoomInfoPush) return object;
      var message = new $root.client_proto.ComebackRoomInfoPush();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      if (object.svr_id != null) message.svr_id = object.svr_id | 0;
      if (object.game_type != null) message.game_type = object.game_type | 0;
      if (object.room_type != null) message.room_type = object.room_type | 0;
      return message;
    };

    /**
     * Creates a plain object from a ComebackRoomInfoPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ComebackRoomInfoPush
     * @static
     * @param {client_proto.ComebackRoomInfoPush} message ComebackRoomInfoPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ComebackRoomInfoPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.room_id = 0;
        object.svr_id = 0;
        object.game_type = 0;
        object.room_type = 0;
      }
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      if (message.svr_id != null && message.hasOwnProperty("svr_id")) object.svr_id = message.svr_id;
      if (message.game_type != null && message.hasOwnProperty("game_type")) object.game_type = message.game_type;
      if (message.room_type != null && message.hasOwnProperty("room_type")) object.room_type = message.room_type;
      return object;
    };

    /**
     * Converts this ComebackRoomInfoPush to JSON.
     * @function toJSON
     * @memberof client_proto.ComebackRoomInfoPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ComebackRoomInfoPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ComebackRoomInfoPush
     * @function getTypeUrl
     * @memberof client_proto.ComebackRoomInfoPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ComebackRoomInfoPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ComebackRoomInfoPush";
    };

    return ComebackRoomInfoPush;
  })();

  client_proto.RobotEnterPlayReq = (function () {
    /**
     * Properties of a RobotEnterPlayReq.
     * @memberof client_proto
     * @interface IRobotEnterPlayReq
     * @property {number|null} [room_id] RobotEnterPlayReq room_id
     */

    /**
     * Constructs a new RobotEnterPlayReq.
     * @memberof client_proto
     * @classdesc Represents a RobotEnterPlayReq.
     * @implements IRobotEnterPlayReq
     * @constructor
     * @param {client_proto.IRobotEnterPlayReq=} [properties] Properties to set
     */
    function RobotEnterPlayReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RobotEnterPlayReq room_id.
     * @member {number} room_id
     * @memberof client_proto.RobotEnterPlayReq
     * @instance
     */
    RobotEnterPlayReq.prototype.room_id = 0;

    /**
     * Creates a new RobotEnterPlayReq instance using the specified properties.
     * @function create
     * @memberof client_proto.RobotEnterPlayReq
     * @static
     * @param {client_proto.IRobotEnterPlayReq=} [properties] Properties to set
     * @returns {client_proto.RobotEnterPlayReq} RobotEnterPlayReq instance
     */
    RobotEnterPlayReq.create = function create(properties) {
      return new RobotEnterPlayReq(properties);
    };

    /**
     * Encodes the specified RobotEnterPlayReq message. Does not implicitly {@link client_proto.RobotEnterPlayReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RobotEnterPlayReq
     * @static
     * @param {client_proto.IRobotEnterPlayReq} message RobotEnterPlayReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RobotEnterPlayReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      return writer;
    };

    /**
     * Encodes the specified RobotEnterPlayReq message, length delimited. Does not implicitly {@link client_proto.RobotEnterPlayReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RobotEnterPlayReq
     * @static
     * @param {client_proto.IRobotEnterPlayReq} message RobotEnterPlayReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RobotEnterPlayReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RobotEnterPlayReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RobotEnterPlayReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RobotEnterPlayReq} RobotEnterPlayReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RobotEnterPlayReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RobotEnterPlayReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RobotEnterPlayReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RobotEnterPlayReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RobotEnterPlayReq} RobotEnterPlayReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RobotEnterPlayReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RobotEnterPlayReq message.
     * @function verify
     * @memberof client_proto.RobotEnterPlayReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RobotEnterPlayReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      return null;
    };

    /**
     * Creates a RobotEnterPlayReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RobotEnterPlayReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RobotEnterPlayReq} RobotEnterPlayReq
     */
    RobotEnterPlayReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RobotEnterPlayReq) return object;
      var message = new $root.client_proto.RobotEnterPlayReq();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a RobotEnterPlayReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RobotEnterPlayReq
     * @static
     * @param {client_proto.RobotEnterPlayReq} message RobotEnterPlayReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RobotEnterPlayReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.room_id = 0;
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      return object;
    };

    /**
     * Converts this RobotEnterPlayReq to JSON.
     * @function toJSON
     * @memberof client_proto.RobotEnterPlayReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RobotEnterPlayReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RobotEnterPlayReq
     * @function getTypeUrl
     * @memberof client_proto.RobotEnterPlayReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RobotEnterPlayReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RobotEnterPlayReq";
    };

    return RobotEnterPlayReq;
  })();

  client_proto.RobotEnterPlayResp = (function () {
    /**
     * Properties of a RobotEnterPlayResp.
     * @memberof client_proto
     * @interface IRobotEnterPlayResp
     * @property {number|null} [room_id] RobotEnterPlayResp room_id
     * @property {number|null} [result] RobotEnterPlayResp result
     */

    /**
     * Constructs a new RobotEnterPlayResp.
     * @memberof client_proto
     * @classdesc Represents a RobotEnterPlayResp.
     * @implements IRobotEnterPlayResp
     * @constructor
     * @param {client_proto.IRobotEnterPlayResp=} [properties] Properties to set
     */
    function RobotEnterPlayResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RobotEnterPlayResp room_id.
     * @member {number} room_id
     * @memberof client_proto.RobotEnterPlayResp
     * @instance
     */
    RobotEnterPlayResp.prototype.room_id = 0;

    /**
     * RobotEnterPlayResp result.
     * @member {number} result
     * @memberof client_proto.RobotEnterPlayResp
     * @instance
     */
    RobotEnterPlayResp.prototype.result = 0;

    /**
     * Creates a new RobotEnterPlayResp instance using the specified properties.
     * @function create
     * @memberof client_proto.RobotEnterPlayResp
     * @static
     * @param {client_proto.IRobotEnterPlayResp=} [properties] Properties to set
     * @returns {client_proto.RobotEnterPlayResp} RobotEnterPlayResp instance
     */
    RobotEnterPlayResp.create = function create(properties) {
      return new RobotEnterPlayResp(properties);
    };

    /**
     * Encodes the specified RobotEnterPlayResp message. Does not implicitly {@link client_proto.RobotEnterPlayResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RobotEnterPlayResp
     * @static
     * @param {client_proto.IRobotEnterPlayResp} message RobotEnterPlayResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RobotEnterPlayResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified RobotEnterPlayResp message, length delimited. Does not implicitly {@link client_proto.RobotEnterPlayResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RobotEnterPlayResp
     * @static
     * @param {client_proto.IRobotEnterPlayResp} message RobotEnterPlayResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RobotEnterPlayResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RobotEnterPlayResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RobotEnterPlayResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RobotEnterPlayResp} RobotEnterPlayResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RobotEnterPlayResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RobotEnterPlayResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RobotEnterPlayResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RobotEnterPlayResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RobotEnterPlayResp} RobotEnterPlayResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RobotEnterPlayResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RobotEnterPlayResp message.
     * @function verify
     * @memberof client_proto.RobotEnterPlayResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RobotEnterPlayResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a RobotEnterPlayResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RobotEnterPlayResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RobotEnterPlayResp} RobotEnterPlayResp
     */
    RobotEnterPlayResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RobotEnterPlayResp) return object;
      var message = new $root.client_proto.RobotEnterPlayResp();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a RobotEnterPlayResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RobotEnterPlayResp
     * @static
     * @param {client_proto.RobotEnterPlayResp} message RobotEnterPlayResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RobotEnterPlayResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.room_id = 0;
        object.result = 0;
      }
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this RobotEnterPlayResp to JSON.
     * @function toJSON
     * @memberof client_proto.RobotEnterPlayResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RobotEnterPlayResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RobotEnterPlayResp
     * @function getTypeUrl
     * @memberof client_proto.RobotEnterPlayResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RobotEnterPlayResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RobotEnterPlayResp";
    };

    return RobotEnterPlayResp;
  })();

  client_proto.RobotExitPlayReq = (function () {
    /**
     * Properties of a RobotExitPlayReq.
     * @memberof client_proto
     * @interface IRobotExitPlayReq
     * @property {number|null} [room_id] RobotExitPlayReq room_id
     */

    /**
     * Constructs a new RobotExitPlayReq.
     * @memberof client_proto
     * @classdesc Represents a RobotExitPlayReq.
     * @implements IRobotExitPlayReq
     * @constructor
     * @param {client_proto.IRobotExitPlayReq=} [properties] Properties to set
     */
    function RobotExitPlayReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RobotExitPlayReq room_id.
     * @member {number} room_id
     * @memberof client_proto.RobotExitPlayReq
     * @instance
     */
    RobotExitPlayReq.prototype.room_id = 0;

    /**
     * Creates a new RobotExitPlayReq instance using the specified properties.
     * @function create
     * @memberof client_proto.RobotExitPlayReq
     * @static
     * @param {client_proto.IRobotExitPlayReq=} [properties] Properties to set
     * @returns {client_proto.RobotExitPlayReq} RobotExitPlayReq instance
     */
    RobotExitPlayReq.create = function create(properties) {
      return new RobotExitPlayReq(properties);
    };

    /**
     * Encodes the specified RobotExitPlayReq message. Does not implicitly {@link client_proto.RobotExitPlayReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RobotExitPlayReq
     * @static
     * @param {client_proto.IRobotExitPlayReq} message RobotExitPlayReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RobotExitPlayReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      return writer;
    };

    /**
     * Encodes the specified RobotExitPlayReq message, length delimited. Does not implicitly {@link client_proto.RobotExitPlayReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RobotExitPlayReq
     * @static
     * @param {client_proto.IRobotExitPlayReq} message RobotExitPlayReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RobotExitPlayReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RobotExitPlayReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RobotExitPlayReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RobotExitPlayReq} RobotExitPlayReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RobotExitPlayReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RobotExitPlayReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RobotExitPlayReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RobotExitPlayReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RobotExitPlayReq} RobotExitPlayReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RobotExitPlayReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RobotExitPlayReq message.
     * @function verify
     * @memberof client_proto.RobotExitPlayReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RobotExitPlayReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      return null;
    };

    /**
     * Creates a RobotExitPlayReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RobotExitPlayReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RobotExitPlayReq} RobotExitPlayReq
     */
    RobotExitPlayReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RobotExitPlayReq) return object;
      var message = new $root.client_proto.RobotExitPlayReq();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a RobotExitPlayReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RobotExitPlayReq
     * @static
     * @param {client_proto.RobotExitPlayReq} message RobotExitPlayReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RobotExitPlayReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.room_id = 0;
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      return object;
    };

    /**
     * Converts this RobotExitPlayReq to JSON.
     * @function toJSON
     * @memberof client_proto.RobotExitPlayReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RobotExitPlayReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RobotExitPlayReq
     * @function getTypeUrl
     * @memberof client_proto.RobotExitPlayReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RobotExitPlayReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RobotExitPlayReq";
    };

    return RobotExitPlayReq;
  })();

  client_proto.RobotExitPlayResp = (function () {
    /**
     * Properties of a RobotExitPlayResp.
     * @memberof client_proto
     * @interface IRobotExitPlayResp
     * @property {number|null} [room_id] RobotExitPlayResp room_id
     * @property {number|null} [result] RobotExitPlayResp result
     */

    /**
     * Constructs a new RobotExitPlayResp.
     * @memberof client_proto
     * @classdesc Represents a RobotExitPlayResp.
     * @implements IRobotExitPlayResp
     * @constructor
     * @param {client_proto.IRobotExitPlayResp=} [properties] Properties to set
     */
    function RobotExitPlayResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RobotExitPlayResp room_id.
     * @member {number} room_id
     * @memberof client_proto.RobotExitPlayResp
     * @instance
     */
    RobotExitPlayResp.prototype.room_id = 0;

    /**
     * RobotExitPlayResp result.
     * @member {number} result
     * @memberof client_proto.RobotExitPlayResp
     * @instance
     */
    RobotExitPlayResp.prototype.result = 0;

    /**
     * Creates a new RobotExitPlayResp instance using the specified properties.
     * @function create
     * @memberof client_proto.RobotExitPlayResp
     * @static
     * @param {client_proto.IRobotExitPlayResp=} [properties] Properties to set
     * @returns {client_proto.RobotExitPlayResp} RobotExitPlayResp instance
     */
    RobotExitPlayResp.create = function create(properties) {
      return new RobotExitPlayResp(properties);
    };

    /**
     * Encodes the specified RobotExitPlayResp message. Does not implicitly {@link client_proto.RobotExitPlayResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.RobotExitPlayResp
     * @static
     * @param {client_proto.IRobotExitPlayResp} message RobotExitPlayResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RobotExitPlayResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.room_id != null && Object.hasOwnProperty.call(message, "room_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.room_id);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified RobotExitPlayResp message, length delimited. Does not implicitly {@link client_proto.RobotExitPlayResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.RobotExitPlayResp
     * @static
     * @param {client_proto.IRobotExitPlayResp} message RobotExitPlayResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RobotExitPlayResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RobotExitPlayResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.RobotExitPlayResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.RobotExitPlayResp} RobotExitPlayResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RobotExitPlayResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.RobotExitPlayResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.room_id = reader.int32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RobotExitPlayResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.RobotExitPlayResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.RobotExitPlayResp} RobotExitPlayResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RobotExitPlayResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RobotExitPlayResp message.
     * @function verify
     * @memberof client_proto.RobotExitPlayResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RobotExitPlayResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.room_id != null && message.hasOwnProperty("room_id"))
        if (!$util.isInteger(message.room_id)) return "room_id: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a RobotExitPlayResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.RobotExitPlayResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.RobotExitPlayResp} RobotExitPlayResp
     */
    RobotExitPlayResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.RobotExitPlayResp) return object;
      var message = new $root.client_proto.RobotExitPlayResp();
      if (object.room_id != null) message.room_id = object.room_id | 0;
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a RobotExitPlayResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.RobotExitPlayResp
     * @static
     * @param {client_proto.RobotExitPlayResp} message RobotExitPlayResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RobotExitPlayResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.room_id = 0;
        object.result = 0;
      }
      if (message.room_id != null && message.hasOwnProperty("room_id")) object.room_id = message.room_id;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this RobotExitPlayResp to JSON.
     * @function toJSON
     * @memberof client_proto.RobotExitPlayResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RobotExitPlayResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RobotExitPlayResp
     * @function getTypeUrl
     * @memberof client_proto.RobotExitPlayResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RobotExitPlayResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.RobotExitPlayResp";
    };

    return RobotExitPlayResp;
  })();

  /**
   * GIFT_INFO_SUB_MSG_ID enum.
   * @name client_proto.GIFT_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} GIFT_MSG_ID_NULL=0 GIFT_MSG_ID_NULL value
   * @property {number} GIFT_MSG_ID_MIN=4500 GIFT_MSG_ID_MIN value
   * @property {number} GIFT_MSG_ID_GET_SHOW_PAGE_REQ=4501 GIFT_MSG_ID_GET_SHOW_PAGE_REQ value
   * @property {number} GIFT_MSG_ID_GET_SHOW_PAGE_RESP=4502 GIFT_MSG_ID_GET_SHOW_PAGE_RESP value
   * @property {number} GIFT_MSG_ID_OPEN_GIFT_REQ=4503 GIFT_MSG_ID_OPEN_GIFT_REQ value
   * @property {number} GIFT_MSG_ID_OPEN_GIFT_RESP=4504 GIFT_MSG_ID_OPEN_GIFT_RESP value
   * @property {number} GIFT_MSG_ID_MAX=4550 GIFT_MSG_ID_MAX value
   */
  client_proto.GIFT_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "GIFT_MSG_ID_NULL")] = 0;
    values[(valuesById[4500] = "GIFT_MSG_ID_MIN")] = 4500;
    values[(valuesById[4501] = "GIFT_MSG_ID_GET_SHOW_PAGE_REQ")] = 4501;
    values[(valuesById[4502] = "GIFT_MSG_ID_GET_SHOW_PAGE_RESP")] = 4502;
    values[(valuesById[4503] = "GIFT_MSG_ID_OPEN_GIFT_REQ")] = 4503;
    values[(valuesById[4504] = "GIFT_MSG_ID_OPEN_GIFT_RESP")] = 4504;
    values[(valuesById[4550] = "GIFT_MSG_ID_MAX")] = 4550;
    return values;
  })();

  client_proto.GiftGetShowPageReq = (function () {
    /**
     * Properties of a GiftGetShowPageReq.
     * @memberof client_proto
     * @interface IGiftGetShowPageReq
     * @property {string|null} [language] GiftGetShowPageReq language
     * @property {number|null} [uid] GiftGetShowPageReq uid
     */

    /**
     * Constructs a new GiftGetShowPageReq.
     * @memberof client_proto
     * @classdesc Represents a GiftGetShowPageReq.
     * @implements IGiftGetShowPageReq
     * @constructor
     * @param {client_proto.IGiftGetShowPageReq=} [properties] Properties to set
     */
    function GiftGetShowPageReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GiftGetShowPageReq language.
     * @member {string} language
     * @memberof client_proto.GiftGetShowPageReq
     * @instance
     */
    GiftGetShowPageReq.prototype.language = "";

    /**
     * GiftGetShowPageReq uid.
     * @member {number} uid
     * @memberof client_proto.GiftGetShowPageReq
     * @instance
     */
    GiftGetShowPageReq.prototype.uid = 0;

    /**
     * Creates a new GiftGetShowPageReq instance using the specified properties.
     * @function create
     * @memberof client_proto.GiftGetShowPageReq
     * @static
     * @param {client_proto.IGiftGetShowPageReq=} [properties] Properties to set
     * @returns {client_proto.GiftGetShowPageReq} GiftGetShowPageReq instance
     */
    GiftGetShowPageReq.create = function create(properties) {
      return new GiftGetShowPageReq(properties);
    };

    /**
     * Encodes the specified GiftGetShowPageReq message. Does not implicitly {@link client_proto.GiftGetShowPageReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GiftGetShowPageReq
     * @static
     * @param {client_proto.IGiftGetShowPageReq} message GiftGetShowPageReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GiftGetShowPageReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.language);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified GiftGetShowPageReq message, length delimited. Does not implicitly {@link client_proto.GiftGetShowPageReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GiftGetShowPageReq
     * @static
     * @param {client_proto.IGiftGetShowPageReq} message GiftGetShowPageReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GiftGetShowPageReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GiftGetShowPageReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GiftGetShowPageReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GiftGetShowPageReq} GiftGetShowPageReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GiftGetShowPageReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GiftGetShowPageReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.language = reader.string();
            break;
          }
          case 2: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GiftGetShowPageReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GiftGetShowPageReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GiftGetShowPageReq} GiftGetShowPageReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GiftGetShowPageReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GiftGetShowPageReq message.
     * @function verify
     * @memberof client_proto.GiftGetShowPageReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GiftGetShowPageReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates a GiftGetShowPageReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GiftGetShowPageReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GiftGetShowPageReq} GiftGetShowPageReq
     */
    GiftGetShowPageReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GiftGetShowPageReq) return object;
      var message = new $root.client_proto.GiftGetShowPageReq();
      if (object.language != null) message.language = String(object.language);
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a GiftGetShowPageReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GiftGetShowPageReq
     * @static
     * @param {client_proto.GiftGetShowPageReq} message GiftGetShowPageReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GiftGetShowPageReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.language = "";
        object.uid = 0;
      }
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this GiftGetShowPageReq to JSON.
     * @function toJSON
     * @memberof client_proto.GiftGetShowPageReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GiftGetShowPageReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GiftGetShowPageReq
     * @function getTypeUrl
     * @memberof client_proto.GiftGetShowPageReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GiftGetShowPageReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GiftGetShowPageReq";
    };

    return GiftGetShowPageReq;
  })();

  client_proto.GiftChannelData = (function () {
    /**
     * Properties of a GiftChannelData.
     * @memberof client_proto
     * @interface IGiftChannelData
     * @property {number|null} [index] GiftChannelData index
     * @property {boolean|null} [open_hide] GiftChannelData open_hide
     * @property {string|null} [jump] GiftChannelData jump
     * @property {string|null} [name] GiftChannelData name
     */

    /**
     * Constructs a new GiftChannelData.
     * @memberof client_proto
     * @classdesc Represents a GiftChannelData.
     * @implements IGiftChannelData
     * @constructor
     * @param {client_proto.IGiftChannelData=} [properties] Properties to set
     */
    function GiftChannelData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GiftChannelData index.
     * @member {number} index
     * @memberof client_proto.GiftChannelData
     * @instance
     */
    GiftChannelData.prototype.index = 0;

    /**
     * GiftChannelData open_hide.
     * @member {boolean} open_hide
     * @memberof client_proto.GiftChannelData
     * @instance
     */
    GiftChannelData.prototype.open_hide = false;

    /**
     * GiftChannelData jump.
     * @member {string} jump
     * @memberof client_proto.GiftChannelData
     * @instance
     */
    GiftChannelData.prototype.jump = "";

    /**
     * GiftChannelData name.
     * @member {string} name
     * @memberof client_proto.GiftChannelData
     * @instance
     */
    GiftChannelData.prototype.name = "";

    /**
     * Creates a new GiftChannelData instance using the specified properties.
     * @function create
     * @memberof client_proto.GiftChannelData
     * @static
     * @param {client_proto.IGiftChannelData=} [properties] Properties to set
     * @returns {client_proto.GiftChannelData} GiftChannelData instance
     */
    GiftChannelData.create = function create(properties) {
      return new GiftChannelData(properties);
    };

    /**
     * Encodes the specified GiftChannelData message. Does not implicitly {@link client_proto.GiftChannelData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GiftChannelData
     * @static
     * @param {client_proto.IGiftChannelData} message GiftChannelData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GiftChannelData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.index != null && Object.hasOwnProperty.call(message, "index"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.index);
      if (message.open_hide != null && Object.hasOwnProperty.call(message, "open_hide"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).bool(message.open_hide);
      if (message.jump != null && Object.hasOwnProperty.call(message, "jump"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.jump);
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.name);
      return writer;
    };

    /**
     * Encodes the specified GiftChannelData message, length delimited. Does not implicitly {@link client_proto.GiftChannelData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GiftChannelData
     * @static
     * @param {client_proto.IGiftChannelData} message GiftChannelData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GiftChannelData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GiftChannelData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GiftChannelData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GiftChannelData} GiftChannelData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GiftChannelData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GiftChannelData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.index = reader.int32();
            break;
          }
          case 2: {
            message.open_hide = reader.bool();
            break;
          }
          case 3: {
            message.jump = reader.string();
            break;
          }
          case 4: {
            message.name = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GiftChannelData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GiftChannelData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GiftChannelData} GiftChannelData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GiftChannelData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GiftChannelData message.
     * @function verify
     * @memberof client_proto.GiftChannelData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GiftChannelData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.index != null && message.hasOwnProperty("index"))
        if (!$util.isInteger(message.index)) return "index: integer expected";
      if (message.open_hide != null && message.hasOwnProperty("open_hide"))
        if (typeof message.open_hide !== "boolean") return "open_hide: boolean expected";
      if (message.jump != null && message.hasOwnProperty("jump"))
        if (!$util.isString(message.jump)) return "jump: string expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      return null;
    };

    /**
     * Creates a GiftChannelData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GiftChannelData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GiftChannelData} GiftChannelData
     */
    GiftChannelData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GiftChannelData) return object;
      var message = new $root.client_proto.GiftChannelData();
      if (object.index != null) message.index = object.index | 0;
      if (object.open_hide != null) message.open_hide = Boolean(object.open_hide);
      if (object.jump != null) message.jump = String(object.jump);
      if (object.name != null) message.name = String(object.name);
      return message;
    };

    /**
     * Creates a plain object from a GiftChannelData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GiftChannelData
     * @static
     * @param {client_proto.GiftChannelData} message GiftChannelData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GiftChannelData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.index = 0;
        object.open_hide = false;
        object.jump = "";
        object.name = "";
      }
      if (message.index != null && message.hasOwnProperty("index")) object.index = message.index;
      if (message.open_hide != null && message.hasOwnProperty("open_hide")) object.open_hide = message.open_hide;
      if (message.jump != null && message.hasOwnProperty("jump")) object.jump = message.jump;
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      return object;
    };

    /**
     * Converts this GiftChannelData to JSON.
     * @function toJSON
     * @memberof client_proto.GiftChannelData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GiftChannelData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GiftChannelData
     * @function getTypeUrl
     * @memberof client_proto.GiftChannelData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GiftChannelData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GiftChannelData";
    };

    return GiftChannelData;
  })();

  client_proto.GiftGetShowPageResp = (function () {
    /**
     * Properties of a GiftGetShowPageResp.
     * @memberof client_proto
     * @interface IGiftGetShowPageResp
     * @property {Array.<client_proto.IGiftChannelData>|null} [channel_array] GiftGetShowPageResp channel_array
     * @property {number|Long|null} [show_min_money] GiftGetShowPageResp show_min_money
     * @property {number|Long|null} [show_max_money] GiftGetShowPageResp show_max_money
     * @property {string|null} [banner] GiftGetShowPageResp banner
     */

    /**
     * Constructs a new GiftGetShowPageResp.
     * @memberof client_proto
     * @classdesc Represents a GiftGetShowPageResp.
     * @implements IGiftGetShowPageResp
     * @constructor
     * @param {client_proto.IGiftGetShowPageResp=} [properties] Properties to set
     */
    function GiftGetShowPageResp(properties) {
      this.channel_array = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GiftGetShowPageResp channel_array.
     * @member {Array.<client_proto.IGiftChannelData>} channel_array
     * @memberof client_proto.GiftGetShowPageResp
     * @instance
     */
    GiftGetShowPageResp.prototype.channel_array = $util.emptyArray;

    /**
     * GiftGetShowPageResp show_min_money.
     * @member {number|Long} show_min_money
     * @memberof client_proto.GiftGetShowPageResp
     * @instance
     */
    GiftGetShowPageResp.prototype.show_min_money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GiftGetShowPageResp show_max_money.
     * @member {number|Long} show_max_money
     * @memberof client_proto.GiftGetShowPageResp
     * @instance
     */
    GiftGetShowPageResp.prototype.show_max_money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * GiftGetShowPageResp banner.
     * @member {string} banner
     * @memberof client_proto.GiftGetShowPageResp
     * @instance
     */
    GiftGetShowPageResp.prototype.banner = "";

    /**
     * Creates a new GiftGetShowPageResp instance using the specified properties.
     * @function create
     * @memberof client_proto.GiftGetShowPageResp
     * @static
     * @param {client_proto.IGiftGetShowPageResp=} [properties] Properties to set
     * @returns {client_proto.GiftGetShowPageResp} GiftGetShowPageResp instance
     */
    GiftGetShowPageResp.create = function create(properties) {
      return new GiftGetShowPageResp(properties);
    };

    /**
     * Encodes the specified GiftGetShowPageResp message. Does not implicitly {@link client_proto.GiftGetShowPageResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.GiftGetShowPageResp
     * @static
     * @param {client_proto.IGiftGetShowPageResp} message GiftGetShowPageResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GiftGetShowPageResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.channel_array != null && message.channel_array.length)
        for (var i = 0; i < message.channel_array.length; ++i)
          $root.client_proto.GiftChannelData.encode(
            message.channel_array[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.show_min_money != null && Object.hasOwnProperty.call(message, "show_min_money"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.show_min_money);
      if (message.show_max_money != null && Object.hasOwnProperty.call(message, "show_max_money"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.show_max_money);
      if (message.banner != null && Object.hasOwnProperty.call(message, "banner"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.banner);
      return writer;
    };

    /**
     * Encodes the specified GiftGetShowPageResp message, length delimited. Does not implicitly {@link client_proto.GiftGetShowPageResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.GiftGetShowPageResp
     * @static
     * @param {client_proto.IGiftGetShowPageResp} message GiftGetShowPageResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GiftGetShowPageResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GiftGetShowPageResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.GiftGetShowPageResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.GiftGetShowPageResp} GiftGetShowPageResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GiftGetShowPageResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.GiftGetShowPageResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.channel_array && message.channel_array.length)) message.channel_array = [];
            message.channel_array.push($root.client_proto.GiftChannelData.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.show_min_money = reader.int64();
            break;
          }
          case 3: {
            message.show_max_money = reader.int64();
            break;
          }
          case 4: {
            message.banner = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GiftGetShowPageResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.GiftGetShowPageResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.GiftGetShowPageResp} GiftGetShowPageResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GiftGetShowPageResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GiftGetShowPageResp message.
     * @function verify
     * @memberof client_proto.GiftGetShowPageResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GiftGetShowPageResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.channel_array != null && message.hasOwnProperty("channel_array")) {
        if (!Array.isArray(message.channel_array)) return "channel_array: array expected";
        for (var i = 0; i < message.channel_array.length; ++i) {
          var error = $root.client_proto.GiftChannelData.verify(message.channel_array[i]);
          if (error) return "channel_array." + error;
        }
      }
      if (message.show_min_money != null && message.hasOwnProperty("show_min_money"))
        if (
          !$util.isInteger(message.show_min_money) &&
          !(
            message.show_min_money &&
            $util.isInteger(message.show_min_money.low) &&
            $util.isInteger(message.show_min_money.high)
          )
        )
          return "show_min_money: integer|Long expected";
      if (message.show_max_money != null && message.hasOwnProperty("show_max_money"))
        if (
          !$util.isInteger(message.show_max_money) &&
          !(
            message.show_max_money &&
            $util.isInteger(message.show_max_money.low) &&
            $util.isInteger(message.show_max_money.high)
          )
        )
          return "show_max_money: integer|Long expected";
      if (message.banner != null && message.hasOwnProperty("banner"))
        if (!$util.isString(message.banner)) return "banner: string expected";
      return null;
    };

    /**
     * Creates a GiftGetShowPageResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.GiftGetShowPageResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.GiftGetShowPageResp} GiftGetShowPageResp
     */
    GiftGetShowPageResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.GiftGetShowPageResp) return object;
      var message = new $root.client_proto.GiftGetShowPageResp();
      if (object.channel_array) {
        if (!Array.isArray(object.channel_array))
          throw TypeError(".client_proto.GiftGetShowPageResp.channel_array: array expected");
        message.channel_array = [];
        for (var i = 0; i < object.channel_array.length; ++i) {
          if (typeof object.channel_array[i] !== "object")
            throw TypeError(".client_proto.GiftGetShowPageResp.channel_array: object expected");
          message.channel_array[i] = $root.client_proto.GiftChannelData.fromObject(object.channel_array[i]);
        }
      }
      if (object.show_min_money != null)
        if ($util.Long) (message.show_min_money = $util.Long.fromValue(object.show_min_money)).unsigned = false;
        else if (typeof object.show_min_money === "string")
          message.show_min_money = parseInt(object.show_min_money, 10);
        else if (typeof object.show_min_money === "number") message.show_min_money = object.show_min_money;
        else if (typeof object.show_min_money === "object")
          message.show_min_money = new $util.LongBits(
            object.show_min_money.low >>> 0,
            object.show_min_money.high >>> 0,
          ).toNumber();
      if (object.show_max_money != null)
        if ($util.Long) (message.show_max_money = $util.Long.fromValue(object.show_max_money)).unsigned = false;
        else if (typeof object.show_max_money === "string")
          message.show_max_money = parseInt(object.show_max_money, 10);
        else if (typeof object.show_max_money === "number") message.show_max_money = object.show_max_money;
        else if (typeof object.show_max_money === "object")
          message.show_max_money = new $util.LongBits(
            object.show_max_money.low >>> 0,
            object.show_max_money.high >>> 0,
          ).toNumber();
      if (object.banner != null) message.banner = String(object.banner);
      return message;
    };

    /**
     * Creates a plain object from a GiftGetShowPageResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.GiftGetShowPageResp
     * @static
     * @param {client_proto.GiftGetShowPageResp} message GiftGetShowPageResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GiftGetShowPageResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.channel_array = [];
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.show_min_money =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.show_min_money = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.show_max_money =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.show_max_money = options.longs === String ? "0" : 0;
        object.banner = "";
      }
      if (message.channel_array && message.channel_array.length) {
        object.channel_array = [];
        for (var j = 0; j < message.channel_array.length; ++j)
          object.channel_array[j] = $root.client_proto.GiftChannelData.toObject(message.channel_array[j], options);
      }
      if (message.show_min_money != null && message.hasOwnProperty("show_min_money"))
        if (typeof message.show_min_money === "number")
          object.show_min_money = options.longs === String ? String(message.show_min_money) : message.show_min_money;
        else
          object.show_min_money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.show_min_money)
              : options.longs === Number
                ? new $util.LongBits(message.show_min_money.low >>> 0, message.show_min_money.high >>> 0).toNumber()
                : message.show_min_money;
      if (message.show_max_money != null && message.hasOwnProperty("show_max_money"))
        if (typeof message.show_max_money === "number")
          object.show_max_money = options.longs === String ? String(message.show_max_money) : message.show_max_money;
        else
          object.show_max_money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.show_max_money)
              : options.longs === Number
                ? new $util.LongBits(message.show_max_money.low >>> 0, message.show_max_money.high >>> 0).toNumber()
                : message.show_max_money;
      if (message.banner != null && message.hasOwnProperty("banner")) object.banner = message.banner;
      return object;
    };

    /**
     * Converts this GiftGetShowPageResp to JSON.
     * @function toJSON
     * @memberof client_proto.GiftGetShowPageResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GiftGetShowPageResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GiftGetShowPageResp
     * @function getTypeUrl
     * @memberof client_proto.GiftGetShowPageResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GiftGetShowPageResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.GiftGetShowPageResp";
    };

    return GiftGetShowPageResp;
  })();

  client_proto.OpenGiftReq = (function () {
    /**
     * Properties of an OpenGiftReq.
     * @memberof client_proto
     * @interface IOpenGiftReq
     * @property {number|null} [uid] OpenGiftReq uid
     * @property {string|null} [key] OpenGiftReq key
     * @property {string|null} [language] OpenGiftReq language
     */

    /**
     * Constructs a new OpenGiftReq.
     * @memberof client_proto
     * @classdesc Represents an OpenGiftReq.
     * @implements IOpenGiftReq
     * @constructor
     * @param {client_proto.IOpenGiftReq=} [properties] Properties to set
     */
    function OpenGiftReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * OpenGiftReq uid.
     * @member {number} uid
     * @memberof client_proto.OpenGiftReq
     * @instance
     */
    OpenGiftReq.prototype.uid = 0;

    /**
     * OpenGiftReq key.
     * @member {string} key
     * @memberof client_proto.OpenGiftReq
     * @instance
     */
    OpenGiftReq.prototype.key = "";

    /**
     * OpenGiftReq language.
     * @member {string} language
     * @memberof client_proto.OpenGiftReq
     * @instance
     */
    OpenGiftReq.prototype.language = "";

    /**
     * Creates a new OpenGiftReq instance using the specified properties.
     * @function create
     * @memberof client_proto.OpenGiftReq
     * @static
     * @param {client_proto.IOpenGiftReq=} [properties] Properties to set
     * @returns {client_proto.OpenGiftReq} OpenGiftReq instance
     */
    OpenGiftReq.create = function create(properties) {
      return new OpenGiftReq(properties);
    };

    /**
     * Encodes the specified OpenGiftReq message. Does not implicitly {@link client_proto.OpenGiftReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.OpenGiftReq
     * @static
     * @param {client_proto.IOpenGiftReq} message OpenGiftReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OpenGiftReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.key != null && Object.hasOwnProperty.call(message, "key"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.key);
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.language);
      return writer;
    };

    /**
     * Encodes the specified OpenGiftReq message, length delimited. Does not implicitly {@link client_proto.OpenGiftReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.OpenGiftReq
     * @static
     * @param {client_proto.IOpenGiftReq} message OpenGiftReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OpenGiftReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an OpenGiftReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.OpenGiftReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.OpenGiftReq} OpenGiftReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OpenGiftReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.OpenGiftReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.key = reader.string();
            break;
          }
          case 3: {
            message.language = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an OpenGiftReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.OpenGiftReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.OpenGiftReq} OpenGiftReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OpenGiftReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an OpenGiftReq message.
     * @function verify
     * @memberof client_proto.OpenGiftReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    OpenGiftReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.key != null && message.hasOwnProperty("key"))
        if (!$util.isString(message.key)) return "key: string expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      return null;
    };

    /**
     * Creates an OpenGiftReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.OpenGiftReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.OpenGiftReq} OpenGiftReq
     */
    OpenGiftReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.OpenGiftReq) return object;
      var message = new $root.client_proto.OpenGiftReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.key != null) message.key = String(object.key);
      if (object.language != null) message.language = String(object.language);
      return message;
    };

    /**
     * Creates a plain object from an OpenGiftReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.OpenGiftReq
     * @static
     * @param {client_proto.OpenGiftReq} message OpenGiftReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    OpenGiftReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.key = "";
        object.language = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.key != null && message.hasOwnProperty("key")) object.key = message.key;
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      return object;
    };

    /**
     * Converts this OpenGiftReq to JSON.
     * @function toJSON
     * @memberof client_proto.OpenGiftReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    OpenGiftReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for OpenGiftReq
     * @function getTypeUrl
     * @memberof client_proto.OpenGiftReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    OpenGiftReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.OpenGiftReq";
    };

    return OpenGiftReq;
  })();

  /**
   * OPEN_GIFT_ERROR_CODE enum.
   * @name client_proto.OPEN_GIFT_ERROR_CODE
   * @enum {number}
   * @property {number} OPEN_GIFT_ERROR_NONE=0 OPEN_GIFT_ERROR_NONE value
   * @property {number} OPEN_GIFT_ERROR_KEY=101 OPEN_GIFT_ERROR_KEY value
   * @property {number} OPEN_GIFT_ERROR_GROUP=102 OPEN_GIFT_ERROR_GROUP value
   * @property {number} OPEN_GIFT_ERROR_TIME=103 OPEN_GIFT_ERROR_TIME value
   * @property {number} OPEN_GIFT_ERROR_QUERY=104 OPEN_GIFT_ERROR_QUERY value
   * @property {number} OPEN_GIFT_ERROR_KEY_LIMIT=105 OPEN_GIFT_ERROR_KEY_LIMIT value
   * @property {number} OPEN_GIFT_ERROR_USER_LIMIT=106 OPEN_GIFT_ERROR_USER_LIMIT value
   * @property {number} OPEN_GIFT_ERROR_USER_NOT_ALLOW=107 OPEN_GIFT_ERROR_USER_NOT_ALLOW value
   * @property {number} OPEN_GIFT_ERROR_TIMEOUT=108 OPEN_GIFT_ERROR_TIMEOUT value
   * @property {number} OPEN_GIFT_ERROR_WAIT=109 OPEN_GIFT_ERROR_WAIT value
   * @property {number} OPEN_GIFT_ERROR_LABEL=110 OPEN_GIFT_ERROR_LABEL value
   * @property {number} OPEN_GIFT_ERROR_PHONE=111 OPEN_GIFT_ERROR_PHONE value
   * @property {number} OPEN_GIFT_ERROR_BANK_CARD=112 OPEN_GIFT_ERROR_BANK_CARD value
   */
  client_proto.OPEN_GIFT_ERROR_CODE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "OPEN_GIFT_ERROR_NONE")] = 0;
    values[(valuesById[101] = "OPEN_GIFT_ERROR_KEY")] = 101;
    values[(valuesById[102] = "OPEN_GIFT_ERROR_GROUP")] = 102;
    values[(valuesById[103] = "OPEN_GIFT_ERROR_TIME")] = 103;
    values[(valuesById[104] = "OPEN_GIFT_ERROR_QUERY")] = 104;
    values[(valuesById[105] = "OPEN_GIFT_ERROR_KEY_LIMIT")] = 105;
    values[(valuesById[106] = "OPEN_GIFT_ERROR_USER_LIMIT")] = 106;
    values[(valuesById[107] = "OPEN_GIFT_ERROR_USER_NOT_ALLOW")] = 107;
    values[(valuesById[108] = "OPEN_GIFT_ERROR_TIMEOUT")] = 108;
    values[(valuesById[109] = "OPEN_GIFT_ERROR_WAIT")] = 109;
    values[(valuesById[110] = "OPEN_GIFT_ERROR_LABEL")] = 110;
    values[(valuesById[111] = "OPEN_GIFT_ERROR_PHONE")] = 111;
    values[(valuesById[112] = "OPEN_GIFT_ERROR_BANK_CARD")] = 112;
    return values;
  })();

  client_proto.OpenGiftResp = (function () {
    /**
     * Properties of an OpenGiftResp.
     * @memberof client_proto
     * @interface IOpenGiftResp
     * @property {number|null} [result] OpenGiftResp result
     * @property {number|Long|null} [amount] OpenGiftResp amount
     */

    /**
     * Constructs a new OpenGiftResp.
     * @memberof client_proto
     * @classdesc Represents an OpenGiftResp.
     * @implements IOpenGiftResp
     * @constructor
     * @param {client_proto.IOpenGiftResp=} [properties] Properties to set
     */
    function OpenGiftResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * OpenGiftResp result.
     * @member {number} result
     * @memberof client_proto.OpenGiftResp
     * @instance
     */
    OpenGiftResp.prototype.result = 0;

    /**
     * OpenGiftResp amount.
     * @member {number|Long} amount
     * @memberof client_proto.OpenGiftResp
     * @instance
     */
    OpenGiftResp.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new OpenGiftResp instance using the specified properties.
     * @function create
     * @memberof client_proto.OpenGiftResp
     * @static
     * @param {client_proto.IOpenGiftResp=} [properties] Properties to set
     * @returns {client_proto.OpenGiftResp} OpenGiftResp instance
     */
    OpenGiftResp.create = function create(properties) {
      return new OpenGiftResp(properties);
    };

    /**
     * Encodes the specified OpenGiftResp message. Does not implicitly {@link client_proto.OpenGiftResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.OpenGiftResp
     * @static
     * @param {client_proto.IOpenGiftResp} message OpenGiftResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OpenGiftResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.amount);
      return writer;
    };

    /**
     * Encodes the specified OpenGiftResp message, length delimited. Does not implicitly {@link client_proto.OpenGiftResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.OpenGiftResp
     * @static
     * @param {client_proto.IOpenGiftResp} message OpenGiftResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OpenGiftResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an OpenGiftResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.OpenGiftResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.OpenGiftResp} OpenGiftResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OpenGiftResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.OpenGiftResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.amount = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an OpenGiftResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.OpenGiftResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.OpenGiftResp} OpenGiftResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OpenGiftResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an OpenGiftResp message.
     * @function verify
     * @memberof client_proto.OpenGiftResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    OpenGiftResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      return null;
    };

    /**
     * Creates an OpenGiftResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.OpenGiftResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.OpenGiftResp} OpenGiftResp
     */
    OpenGiftResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.OpenGiftResp) return object;
      var message = new $root.client_proto.OpenGiftResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from an OpenGiftResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.OpenGiftResp
     * @static
     * @param {client_proto.OpenGiftResp} message OpenGiftResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    OpenGiftResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      return object;
    };

    /**
     * Converts this OpenGiftResp to JSON.
     * @function toJSON
     * @memberof client_proto.OpenGiftResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    OpenGiftResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for OpenGiftResp
     * @function getTypeUrl
     * @memberof client_proto.OpenGiftResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    OpenGiftResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.OpenGiftResp";
    };

    return OpenGiftResp;
  })();

  /**
   * AGENT_RANK_LIST_TYPE enum.
   * @name client_proto.AGENT_RANK_LIST_TYPE
   * @enum {number}
   * @property {number} AGENT_RANK_LIST_NULL=0 AGENT_RANK_LIST_NULL value
   * @property {number} AGENT_RANK_LIST_DAY=1 AGENT_RANK_LIST_DAY value
   * @property {number} AGENT_RANK_LIST_WEEK=2 AGENT_RANK_LIST_WEEK value
   * @property {number} AGENT_RANK_LIST_MONTH=3 AGENT_RANK_LIST_MONTH value
   */
  client_proto.AGENT_RANK_LIST_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "AGENT_RANK_LIST_NULL")] = 0;
    values[(valuesById[1] = "AGENT_RANK_LIST_DAY")] = 1;
    values[(valuesById[2] = "AGENT_RANK_LIST_WEEK")] = 2;
    values[(valuesById[3] = "AGENT_RANK_LIST_MONTH")] = 3;
    return values;
  })();

  /**
   * AGENT_RANK_INFO_SUB_MSG_ID enum.
   * @name client_proto.AGENT_RANK_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} AGENT_RANK_MSG_ID_NULL=0 AGENT_RANK_MSG_ID_NULL value
   * @property {number} AGENT_RANK_MSG_ID_MIN=5300 AGENT_RANK_MSG_ID_MIN value
   * @property {number} AGENT_RANK_MSG_ID_RANK_LIST=5301 AGENT_RANK_MSG_ID_RANK_LIST value
   * @property {number} AGENT_RANK_MSG_ID_RANK_LIST_RESP=5302 AGENT_RANK_MSG_ID_RANK_LIST_RESP value
   * @property {number} AGENT_RANK_MSG_ID_RANK_JACKPOT=5303 AGENT_RANK_MSG_ID_RANK_JACKPOT value
   * @property {number} AGENT_RANK_MSG_ID_RANK_JACKPOT_RESP=5304 AGENT_RANK_MSG_ID_RANK_JACKPOT_RESP value
   * @property {number} AGENT_RANK_MSG_ID_RANK_JOIN_GAME_LIST=5305 AGENT_RANK_MSG_ID_RANK_JOIN_GAME_LIST value
   * @property {number} AGENT_RANK_MSG_ID_RANK_JOIN_GAME_LIST_RESP=5306 AGENT_RANK_MSG_ID_RANK_JOIN_GAME_LIST_RESP value
   * @property {number} AGENT_RANK_MSG_ID_RANK_REWARD_LIST=5307 AGENT_RANK_MSG_ID_RANK_REWARD_LIST value
   * @property {number} AGENT_RANK_MSG_ID_RANK_REWARD_LIST_RESP=5308 AGENT_RANK_MSG_ID_RANK_REWARD_LIST_RESP value
   * @property {number} AGENT_RANK_MSG_ID_RANK_SHOW_LIST=5309 AGENT_RANK_MSG_ID_RANK_SHOW_LIST value
   * @property {number} AGENT_RANK_MSG_ID_RANK_SHOW_LIST_RESP=5310 AGENT_RANK_MSG_ID_RANK_SHOW_LIST_RESP value
   * @property {number} AGENT_RANK_MSG_ID_RANK_GET_REWARD=5311 AGENT_RANK_MSG_ID_RANK_GET_REWARD value
   * @property {number} AGENT_RANK_MSG_ID_RANK_GET_REWARD_RESP=5312 AGENT_RANK_MSG_ID_RANK_GET_REWARD_RESP value
   * @property {number} AGENT_RANK_MSG_ID_RANK_GET_REPORT=5313 AGENT_RANK_MSG_ID_RANK_GET_REPORT value
   * @property {number} AGENT_RANK_MSG_ID_RANK_GET_REPORT_RESP=5314 AGENT_RANK_MSG_ID_RANK_GET_REPORT_RESP value
   * @property {number} AGENT_RANK_MSG_ID_RANK_GET_USER_DETAILS=5315 AGENT_RANK_MSG_ID_RANK_GET_USER_DETAILS value
   * @property {number} AGENT_RANK_MSG_ID_RANK_GET_USER_DETAILS_RESP=5316 AGENT_RANK_MSG_ID_RANK_GET_USER_DETAILS_RESP value
   * @property {number} AGENT_RANK_MSG_ID_MID=5350 AGENT_RANK_MSG_ID_MID value
   * @property {number} AGENT_RANK_MSG_ID_MAX=5400 AGENT_RANK_MSG_ID_MAX value
   */
  client_proto.AGENT_RANK_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "AGENT_RANK_MSG_ID_NULL")] = 0;
    values[(valuesById[5300] = "AGENT_RANK_MSG_ID_MIN")] = 5300;
    values[(valuesById[5301] = "AGENT_RANK_MSG_ID_RANK_LIST")] = 5301;
    values[(valuesById[5302] = "AGENT_RANK_MSG_ID_RANK_LIST_RESP")] = 5302;
    values[(valuesById[5303] = "AGENT_RANK_MSG_ID_RANK_JACKPOT")] = 5303;
    values[(valuesById[5304] = "AGENT_RANK_MSG_ID_RANK_JACKPOT_RESP")] = 5304;
    values[(valuesById[5305] = "AGENT_RANK_MSG_ID_RANK_JOIN_GAME_LIST")] = 5305;
    values[(valuesById[5306] = "AGENT_RANK_MSG_ID_RANK_JOIN_GAME_LIST_RESP")] = 5306;
    values[(valuesById[5307] = "AGENT_RANK_MSG_ID_RANK_REWARD_LIST")] = 5307;
    values[(valuesById[5308] = "AGENT_RANK_MSG_ID_RANK_REWARD_LIST_RESP")] = 5308;
    values[(valuesById[5309] = "AGENT_RANK_MSG_ID_RANK_SHOW_LIST")] = 5309;
    values[(valuesById[5310] = "AGENT_RANK_MSG_ID_RANK_SHOW_LIST_RESP")] = 5310;
    values[(valuesById[5311] = "AGENT_RANK_MSG_ID_RANK_GET_REWARD")] = 5311;
    values[(valuesById[5312] = "AGENT_RANK_MSG_ID_RANK_GET_REWARD_RESP")] = 5312;
    values[(valuesById[5313] = "AGENT_RANK_MSG_ID_RANK_GET_REPORT")] = 5313;
    values[(valuesById[5314] = "AGENT_RANK_MSG_ID_RANK_GET_REPORT_RESP")] = 5314;
    values[(valuesById[5315] = "AGENT_RANK_MSG_ID_RANK_GET_USER_DETAILS")] = 5315;
    values[(valuesById[5316] = "AGENT_RANK_MSG_ID_RANK_GET_USER_DETAILS_RESP")] = 5316;
    values[(valuesById[5350] = "AGENT_RANK_MSG_ID_MID")] = 5350;
    values[(valuesById[5400] = "AGENT_RANK_MSG_ID_MAX")] = 5400;
    return values;
  })();

  client_proto.AgentRankRequestRankShowListReq = (function () {
    /**
     * Properties of an AgentRankRequestRankShowListReq.
     * @memberof client_proto
     * @interface IAgentRankRequestRankShowListReq
     * @property {number|null} [uid] AgentRankRequestRankShowListReq uid
     */

    /**
     * Constructs a new AgentRankRequestRankShowListReq.
     * @memberof client_proto
     * @classdesc Represents an AgentRankRequestRankShowListReq.
     * @implements IAgentRankRequestRankShowListReq
     * @constructor
     * @param {client_proto.IAgentRankRequestRankShowListReq=} [properties] Properties to set
     */
    function AgentRankRequestRankShowListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankRequestRankShowListReq uid.
     * @member {number} uid
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @instance
     */
    AgentRankRequestRankShowListReq.prototype.uid = 0;

    /**
     * Creates a new AgentRankRequestRankShowListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @static
     * @param {client_proto.IAgentRankRequestRankShowListReq=} [properties] Properties to set
     * @returns {client_proto.AgentRankRequestRankShowListReq} AgentRankRequestRankShowListReq instance
     */
    AgentRankRequestRankShowListReq.create = function create(properties) {
      return new AgentRankRequestRankShowListReq(properties);
    };

    /**
     * Encodes the specified AgentRankRequestRankShowListReq message. Does not implicitly {@link client_proto.AgentRankRequestRankShowListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @static
     * @param {client_proto.IAgentRankRequestRankShowListReq} message AgentRankRequestRankShowListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestRankShowListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified AgentRankRequestRankShowListReq message, length delimited. Does not implicitly {@link client_proto.AgentRankRequestRankShowListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @static
     * @param {client_proto.IAgentRankRequestRankShowListReq} message AgentRankRequestRankShowListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestRankShowListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankRequestRankShowListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankRequestRankShowListReq} AgentRankRequestRankShowListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestRankShowListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankRequestRankShowListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankRequestRankShowListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankRequestRankShowListReq} AgentRankRequestRankShowListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestRankShowListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankRequestRankShowListReq message.
     * @function verify
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankRequestRankShowListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankRequestRankShowListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankRequestRankShowListReq} AgentRankRequestRankShowListReq
     */
    AgentRankRequestRankShowListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankRequestRankShowListReq) return object;
      var message = new $root.client_proto.AgentRankRequestRankShowListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankRequestRankShowListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @static
     * @param {client_proto.AgentRankRequestRankShowListReq} message AgentRankRequestRankShowListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankRequestRankShowListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this AgentRankRequestRankShowListReq to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankRequestRankShowListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankRequestRankShowListReq
     * @function getTypeUrl
     * @memberof client_proto.AgentRankRequestRankShowListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankRequestRankShowListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankRequestRankShowListReq";
    };

    return AgentRankRequestRankShowListReq;
  })();

  client_proto.AgentRankShowData = (function () {
    /**
     * Properties of an AgentRankShowData.
     * @memberof client_proto
     * @interface IAgentRankShowData
     * @property {number|null} [id] AgentRankShowData id
     * @property {string|null} [name] AgentRankShowData name
     * @property {number|null} [rank_start_time] AgentRankShowData rank_start_time
     * @property {number|null} [rank_end_time] AgentRankShowData rank_end_time
     * @property {boolean|null} [is_history] AgentRankShowData is_history
     */

    /**
     * Constructs a new AgentRankShowData.
     * @memberof client_proto
     * @classdesc Represents an AgentRankShowData.
     * @implements IAgentRankShowData
     * @constructor
     * @param {client_proto.IAgentRankShowData=} [properties] Properties to set
     */
    function AgentRankShowData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankShowData id.
     * @member {number} id
     * @memberof client_proto.AgentRankShowData
     * @instance
     */
    AgentRankShowData.prototype.id = 0;

    /**
     * AgentRankShowData name.
     * @member {string} name
     * @memberof client_proto.AgentRankShowData
     * @instance
     */
    AgentRankShowData.prototype.name = "";

    /**
     * AgentRankShowData rank_start_time.
     * @member {number} rank_start_time
     * @memberof client_proto.AgentRankShowData
     * @instance
     */
    AgentRankShowData.prototype.rank_start_time = 0;

    /**
     * AgentRankShowData rank_end_time.
     * @member {number} rank_end_time
     * @memberof client_proto.AgentRankShowData
     * @instance
     */
    AgentRankShowData.prototype.rank_end_time = 0;

    /**
     * AgentRankShowData is_history.
     * @member {boolean} is_history
     * @memberof client_proto.AgentRankShowData
     * @instance
     */
    AgentRankShowData.prototype.is_history = false;

    /**
     * Creates a new AgentRankShowData instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankShowData
     * @static
     * @param {client_proto.IAgentRankShowData=} [properties] Properties to set
     * @returns {client_proto.AgentRankShowData} AgentRankShowData instance
     */
    AgentRankShowData.create = function create(properties) {
      return new AgentRankShowData(properties);
    };

    /**
     * Encodes the specified AgentRankShowData message. Does not implicitly {@link client_proto.AgentRankShowData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankShowData
     * @static
     * @param {client_proto.IAgentRankShowData} message AgentRankShowData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankShowData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name);
      if (message.rank_start_time != null && Object.hasOwnProperty.call(message, "rank_start_time"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.rank_start_time);
      if (message.rank_end_time != null && Object.hasOwnProperty.call(message, "rank_end_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.rank_end_time);
      if (message.is_history != null && Object.hasOwnProperty.call(message, "is_history"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).bool(message.is_history);
      return writer;
    };

    /**
     * Encodes the specified AgentRankShowData message, length delimited. Does not implicitly {@link client_proto.AgentRankShowData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankShowData
     * @static
     * @param {client_proto.IAgentRankShowData} message AgentRankShowData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankShowData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankShowData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankShowData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankShowData} AgentRankShowData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankShowData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankShowData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.name = reader.string();
            break;
          }
          case 3: {
            message.rank_start_time = reader.uint32();
            break;
          }
          case 4: {
            message.rank_end_time = reader.uint32();
            break;
          }
          case 5: {
            message.is_history = reader.bool();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankShowData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankShowData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankShowData} AgentRankShowData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankShowData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankShowData message.
     * @function verify
     * @memberof client_proto.AgentRankShowData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankShowData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        if (!$util.isInteger(message.rank_start_time)) return "rank_start_time: integer expected";
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        if (!$util.isInteger(message.rank_end_time)) return "rank_end_time: integer expected";
      if (message.is_history != null && message.hasOwnProperty("is_history"))
        if (typeof message.is_history !== "boolean") return "is_history: boolean expected";
      return null;
    };

    /**
     * Creates an AgentRankShowData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankShowData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankShowData} AgentRankShowData
     */
    AgentRankShowData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankShowData) return object;
      var message = new $root.client_proto.AgentRankShowData();
      if (object.id != null) message.id = object.id | 0;
      if (object.name != null) message.name = String(object.name);
      if (object.rank_start_time != null) message.rank_start_time = object.rank_start_time >>> 0;
      if (object.rank_end_time != null) message.rank_end_time = object.rank_end_time >>> 0;
      if (object.is_history != null) message.is_history = Boolean(object.is_history);
      return message;
    };

    /**
     * Creates a plain object from an AgentRankShowData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankShowData
     * @static
     * @param {client_proto.AgentRankShowData} message AgentRankShowData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankShowData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.name = "";
        object.rank_start_time = 0;
        object.rank_end_time = 0;
        object.is_history = false;
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        object.rank_start_time = message.rank_start_time;
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        object.rank_end_time = message.rank_end_time;
      if (message.is_history != null && message.hasOwnProperty("is_history")) object.is_history = message.is_history;
      return object;
    };

    /**
     * Converts this AgentRankShowData to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankShowData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankShowData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankShowData
     * @function getTypeUrl
     * @memberof client_proto.AgentRankShowData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankShowData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankShowData";
    };

    return AgentRankShowData;
  })();

  client_proto.AgentRankResponseRankShowListResp = (function () {
    /**
     * Properties of an AgentRankResponseRankShowListResp.
     * @memberof client_proto
     * @interface IAgentRankResponseRankShowListResp
     * @property {Array.<client_proto.IAgentRankShowData>|null} [show_list] AgentRankResponseRankShowListResp show_list
     */

    /**
     * Constructs a new AgentRankResponseRankShowListResp.
     * @memberof client_proto
     * @classdesc Represents an AgentRankResponseRankShowListResp.
     * @implements IAgentRankResponseRankShowListResp
     * @constructor
     * @param {client_proto.IAgentRankResponseRankShowListResp=} [properties] Properties to set
     */
    function AgentRankResponseRankShowListResp(properties) {
      this.show_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankResponseRankShowListResp show_list.
     * @member {Array.<client_proto.IAgentRankShowData>} show_list
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @instance
     */
    AgentRankResponseRankShowListResp.prototype.show_list = $util.emptyArray;

    /**
     * Creates a new AgentRankResponseRankShowListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @static
     * @param {client_proto.IAgentRankResponseRankShowListResp=} [properties] Properties to set
     * @returns {client_proto.AgentRankResponseRankShowListResp} AgentRankResponseRankShowListResp instance
     */
    AgentRankResponseRankShowListResp.create = function create(properties) {
      return new AgentRankResponseRankShowListResp(properties);
    };

    /**
     * Encodes the specified AgentRankResponseRankShowListResp message. Does not implicitly {@link client_proto.AgentRankResponseRankShowListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @static
     * @param {client_proto.IAgentRankResponseRankShowListResp} message AgentRankResponseRankShowListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseRankShowListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.show_list != null && message.show_list.length)
        for (var i = 0; i < message.show_list.length; ++i)
          $root.client_proto.AgentRankShowData.encode(
            message.show_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified AgentRankResponseRankShowListResp message, length delimited. Does not implicitly {@link client_proto.AgentRankResponseRankShowListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @static
     * @param {client_proto.IAgentRankResponseRankShowListResp} message AgentRankResponseRankShowListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseRankShowListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankResponseRankShowListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankResponseRankShowListResp} AgentRankResponseRankShowListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseRankShowListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankResponseRankShowListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.show_list && message.show_list.length)) message.show_list = [];
            message.show_list.push($root.client_proto.AgentRankShowData.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankResponseRankShowListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankResponseRankShowListResp} AgentRankResponseRankShowListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseRankShowListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankResponseRankShowListResp message.
     * @function verify
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankResponseRankShowListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.show_list != null && message.hasOwnProperty("show_list")) {
        if (!Array.isArray(message.show_list)) return "show_list: array expected";
        for (var i = 0; i < message.show_list.length; ++i) {
          var error = $root.client_proto.AgentRankShowData.verify(message.show_list[i]);
          if (error) return "show_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates an AgentRankResponseRankShowListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankResponseRankShowListResp} AgentRankResponseRankShowListResp
     */
    AgentRankResponseRankShowListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankResponseRankShowListResp) return object;
      var message = new $root.client_proto.AgentRankResponseRankShowListResp();
      if (object.show_list) {
        if (!Array.isArray(object.show_list))
          throw TypeError(".client_proto.AgentRankResponseRankShowListResp.show_list: array expected");
        message.show_list = [];
        for (var i = 0; i < object.show_list.length; ++i) {
          if (typeof object.show_list[i] !== "object")
            throw TypeError(".client_proto.AgentRankResponseRankShowListResp.show_list: object expected");
          message.show_list[i] = $root.client_proto.AgentRankShowData.fromObject(object.show_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from an AgentRankResponseRankShowListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @static
     * @param {client_proto.AgentRankResponseRankShowListResp} message AgentRankResponseRankShowListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankResponseRankShowListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.show_list = [];
      if (message.show_list && message.show_list.length) {
        object.show_list = [];
        for (var j = 0; j < message.show_list.length; ++j)
          object.show_list[j] = $root.client_proto.AgentRankShowData.toObject(message.show_list[j], options);
      }
      return object;
    };

    /**
     * Converts this AgentRankResponseRankShowListResp to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankResponseRankShowListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankResponseRankShowListResp
     * @function getTypeUrl
     * @memberof client_proto.AgentRankResponseRankShowListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankResponseRankShowListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankResponseRankShowListResp";
    };

    return AgentRankResponseRankShowListResp;
  })();

  client_proto.AgentRankUserRankData = (function () {
    /**
     * Properties of an AgentRankUserRankData.
     * @memberof client_proto
     * @interface IAgentRankUserRankData
     * @property {number|null} [rank] AgentRankUserRankData rank
     * @property {number|Long|null} [money] AgentRankUserRankData money
     * @property {number|null} [reward] AgentRankUserRankData reward
     * @property {number|null} [uid] AgentRankUserRankData uid
     * @property {string|null} [name] AgentRankUserRankData name
     * @property {string|null} [head] AgentRankUserRankData head
     * @property {number|Long|null} [reward_money] AgentRankUserRankData reward_money
     * @property {number|null} [open_reward_state] AgentRankUserRankData open_reward_state
     */

    /**
     * Constructs a new AgentRankUserRankData.
     * @memberof client_proto
     * @classdesc Represents an AgentRankUserRankData.
     * @implements IAgentRankUserRankData
     * @constructor
     * @param {client_proto.IAgentRankUserRankData=} [properties] Properties to set
     */
    function AgentRankUserRankData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankUserRankData rank.
     * @member {number} rank
     * @memberof client_proto.AgentRankUserRankData
     * @instance
     */
    AgentRankUserRankData.prototype.rank = 0;

    /**
     * AgentRankUserRankData money.
     * @member {number|Long} money
     * @memberof client_proto.AgentRankUserRankData
     * @instance
     */
    AgentRankUserRankData.prototype.money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankUserRankData reward.
     * @member {number} reward
     * @memberof client_proto.AgentRankUserRankData
     * @instance
     */
    AgentRankUserRankData.prototype.reward = 0;

    /**
     * AgentRankUserRankData uid.
     * @member {number} uid
     * @memberof client_proto.AgentRankUserRankData
     * @instance
     */
    AgentRankUserRankData.prototype.uid = 0;

    /**
     * AgentRankUserRankData name.
     * @member {string} name
     * @memberof client_proto.AgentRankUserRankData
     * @instance
     */
    AgentRankUserRankData.prototype.name = "";

    /**
     * AgentRankUserRankData head.
     * @member {string} head
     * @memberof client_proto.AgentRankUserRankData
     * @instance
     */
    AgentRankUserRankData.prototype.head = "";

    /**
     * AgentRankUserRankData reward_money.
     * @member {number|Long} reward_money
     * @memberof client_proto.AgentRankUserRankData
     * @instance
     */
    AgentRankUserRankData.prototype.reward_money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankUserRankData open_reward_state.
     * @member {number} open_reward_state
     * @memberof client_proto.AgentRankUserRankData
     * @instance
     */
    AgentRankUserRankData.prototype.open_reward_state = 0;

    /**
     * Creates a new AgentRankUserRankData instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankUserRankData
     * @static
     * @param {client_proto.IAgentRankUserRankData=} [properties] Properties to set
     * @returns {client_proto.AgentRankUserRankData} AgentRankUserRankData instance
     */
    AgentRankUserRankData.create = function create(properties) {
      return new AgentRankUserRankData(properties);
    };

    /**
     * Encodes the specified AgentRankUserRankData message. Does not implicitly {@link client_proto.AgentRankUserRankData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankUserRankData
     * @static
     * @param {client_proto.IAgentRankUserRankData} message AgentRankUserRankData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankUserRankData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.rank != null && Object.hasOwnProperty.call(message, "rank"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.rank);
      if (message.money != null && Object.hasOwnProperty.call(message, "money"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.money);
      if (message.reward != null && Object.hasOwnProperty.call(message, "reward"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.reward);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.uid);
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.name);
      if (message.head != null && Object.hasOwnProperty.call(message, "head"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.head);
      if (message.reward_money != null && Object.hasOwnProperty.call(message, "reward_money"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.reward_money);
      if (message.open_reward_state != null && Object.hasOwnProperty.call(message, "open_reward_state"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.open_reward_state);
      return writer;
    };

    /**
     * Encodes the specified AgentRankUserRankData message, length delimited. Does not implicitly {@link client_proto.AgentRankUserRankData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankUserRankData
     * @static
     * @param {client_proto.IAgentRankUserRankData} message AgentRankUserRankData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankUserRankData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankUserRankData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankUserRankData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankUserRankData} AgentRankUserRankData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankUserRankData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankUserRankData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.rank = reader.int32();
            break;
          }
          case 2: {
            message.money = reader.int64();
            break;
          }
          case 3: {
            message.reward = reader.int32();
            break;
          }
          case 4: {
            message.uid = reader.uint32();
            break;
          }
          case 5: {
            message.name = reader.string();
            break;
          }
          case 6: {
            message.head = reader.string();
            break;
          }
          case 7: {
            message.reward_money = reader.int64();
            break;
          }
          case 8: {
            message.open_reward_state = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankUserRankData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankUserRankData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankUserRankData} AgentRankUserRankData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankUserRankData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankUserRankData message.
     * @function verify
     * @memberof client_proto.AgentRankUserRankData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankUserRankData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.rank != null && message.hasOwnProperty("rank"))
        if (!$util.isInteger(message.rank)) return "rank: integer expected";
      if (message.money != null && message.hasOwnProperty("money"))
        if (
          !$util.isInteger(message.money) &&
          !(message.money && $util.isInteger(message.money.low) && $util.isInteger(message.money.high))
        )
          return "money: integer|Long expected";
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (!$util.isInteger(message.reward)) return "reward: integer expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      if (message.head != null && message.hasOwnProperty("head"))
        if (!$util.isString(message.head)) return "head: string expected";
      if (message.reward_money != null && message.hasOwnProperty("reward_money"))
        if (
          !$util.isInteger(message.reward_money) &&
          !(
            message.reward_money &&
            $util.isInteger(message.reward_money.low) &&
            $util.isInteger(message.reward_money.high)
          )
        )
          return "reward_money: integer|Long expected";
      if (message.open_reward_state != null && message.hasOwnProperty("open_reward_state"))
        if (!$util.isInteger(message.open_reward_state)) return "open_reward_state: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankUserRankData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankUserRankData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankUserRankData} AgentRankUserRankData
     */
    AgentRankUserRankData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankUserRankData) return object;
      var message = new $root.client_proto.AgentRankUserRankData();
      if (object.rank != null) message.rank = object.rank | 0;
      if (object.money != null)
        if ($util.Long) (message.money = $util.Long.fromValue(object.money)).unsigned = false;
        else if (typeof object.money === "string") message.money = parseInt(object.money, 10);
        else if (typeof object.money === "number") message.money = object.money;
        else if (typeof object.money === "object")
          message.money = new $util.LongBits(object.money.low >>> 0, object.money.high >>> 0).toNumber();
      if (object.reward != null) message.reward = object.reward | 0;
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.name != null) message.name = String(object.name);
      if (object.head != null) message.head = String(object.head);
      if (object.reward_money != null)
        if ($util.Long) (message.reward_money = $util.Long.fromValue(object.reward_money)).unsigned = false;
        else if (typeof object.reward_money === "string") message.reward_money = parseInt(object.reward_money, 10);
        else if (typeof object.reward_money === "number") message.reward_money = object.reward_money;
        else if (typeof object.reward_money === "object")
          message.reward_money = new $util.LongBits(
            object.reward_money.low >>> 0,
            object.reward_money.high >>> 0,
          ).toNumber();
      if (object.open_reward_state != null) message.open_reward_state = object.open_reward_state | 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankUserRankData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankUserRankData
     * @static
     * @param {client_proto.AgentRankUserRankData} message AgentRankUserRankData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankUserRankData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.rank = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.money = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.money = options.longs === String ? "0" : 0;
        object.reward = 0;
        object.uid = 0;
        object.name = "";
        object.head = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_money =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_money = options.longs === String ? "0" : 0;
        object.open_reward_state = 0;
      }
      if (message.rank != null && message.hasOwnProperty("rank")) object.rank = message.rank;
      if (message.money != null && message.hasOwnProperty("money"))
        if (typeof message.money === "number")
          object.money = options.longs === String ? String(message.money) : message.money;
        else
          object.money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.money)
              : options.longs === Number
                ? new $util.LongBits(message.money.low >>> 0, message.money.high >>> 0).toNumber()
                : message.money;
      if (message.reward != null && message.hasOwnProperty("reward")) object.reward = message.reward;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      if (message.head != null && message.hasOwnProperty("head")) object.head = message.head;
      if (message.reward_money != null && message.hasOwnProperty("reward_money"))
        if (typeof message.reward_money === "number")
          object.reward_money = options.longs === String ? String(message.reward_money) : message.reward_money;
        else
          object.reward_money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_money)
              : options.longs === Number
                ? new $util.LongBits(message.reward_money.low >>> 0, message.reward_money.high >>> 0).toNumber()
                : message.reward_money;
      if (message.open_reward_state != null && message.hasOwnProperty("open_reward_state"))
        object.open_reward_state = message.open_reward_state;
      return object;
    };

    /**
     * Converts this AgentRankUserRankData to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankUserRankData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankUserRankData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankUserRankData
     * @function getTypeUrl
     * @memberof client_proto.AgentRankUserRankData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankUserRankData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankUserRankData";
    };

    return AgentRankUserRankData;
  })();

  client_proto.AgentRankRequestRankListReq = (function () {
    /**
     * Properties of an AgentRankRequestRankListReq.
     * @memberof client_proto
     * @interface IAgentRankRequestRankListReq
     * @property {number|null} [uid] AgentRankRequestRankListReq uid
     * @property {number|null} [id] AgentRankRequestRankListReq id
     * @property {boolean|null} [is_history] AgentRankRequestRankListReq is_history
     * @property {number|null} [page_number] AgentRankRequestRankListReq page_number
     * @property {number|null} [number_per_page] AgentRankRequestRankListReq number_per_page
     */

    /**
     * Constructs a new AgentRankRequestRankListReq.
     * @memberof client_proto
     * @classdesc Represents an AgentRankRequestRankListReq.
     * @implements IAgentRankRequestRankListReq
     * @constructor
     * @param {client_proto.IAgentRankRequestRankListReq=} [properties] Properties to set
     */
    function AgentRankRequestRankListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankRequestRankListReq uid.
     * @member {number} uid
     * @memberof client_proto.AgentRankRequestRankListReq
     * @instance
     */
    AgentRankRequestRankListReq.prototype.uid = 0;

    /**
     * AgentRankRequestRankListReq id.
     * @member {number} id
     * @memberof client_proto.AgentRankRequestRankListReq
     * @instance
     */
    AgentRankRequestRankListReq.prototype.id = 0;

    /**
     * AgentRankRequestRankListReq is_history.
     * @member {boolean} is_history
     * @memberof client_proto.AgentRankRequestRankListReq
     * @instance
     */
    AgentRankRequestRankListReq.prototype.is_history = false;

    /**
     * AgentRankRequestRankListReq page_number.
     * @member {number} page_number
     * @memberof client_proto.AgentRankRequestRankListReq
     * @instance
     */
    AgentRankRequestRankListReq.prototype.page_number = 0;

    /**
     * AgentRankRequestRankListReq number_per_page.
     * @member {number} number_per_page
     * @memberof client_proto.AgentRankRequestRankListReq
     * @instance
     */
    AgentRankRequestRankListReq.prototype.number_per_page = 0;

    /**
     * Creates a new AgentRankRequestRankListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankRequestRankListReq
     * @static
     * @param {client_proto.IAgentRankRequestRankListReq=} [properties] Properties to set
     * @returns {client_proto.AgentRankRequestRankListReq} AgentRankRequestRankListReq instance
     */
    AgentRankRequestRankListReq.create = function create(properties) {
      return new AgentRankRequestRankListReq(properties);
    };

    /**
     * Encodes the specified AgentRankRequestRankListReq message. Does not implicitly {@link client_proto.AgentRankRequestRankListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankRequestRankListReq
     * @static
     * @param {client_proto.IAgentRankRequestRankListReq} message AgentRankRequestRankListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestRankListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.is_history != null && Object.hasOwnProperty.call(message, "is_history"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).bool(message.is_history);
      if (message.page_number != null && Object.hasOwnProperty.call(message, "page_number"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.page_number);
      if (message.number_per_page != null && Object.hasOwnProperty.call(message, "number_per_page"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.number_per_page);
      return writer;
    };

    /**
     * Encodes the specified AgentRankRequestRankListReq message, length delimited. Does not implicitly {@link client_proto.AgentRankRequestRankListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankRequestRankListReq
     * @static
     * @param {client_proto.IAgentRankRequestRankListReq} message AgentRankRequestRankListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestRankListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankRequestRankListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankRequestRankListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankRequestRankListReq} AgentRankRequestRankListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestRankListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankRequestRankListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            message.is_history = reader.bool();
            break;
          }
          case 4: {
            message.page_number = reader.int32();
            break;
          }
          case 5: {
            message.number_per_page = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankRequestRankListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankRequestRankListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankRequestRankListReq} AgentRankRequestRankListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestRankListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankRequestRankListReq message.
     * @function verify
     * @memberof client_proto.AgentRankRequestRankListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankRequestRankListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.is_history != null && message.hasOwnProperty("is_history"))
        if (typeof message.is_history !== "boolean") return "is_history: boolean expected";
      if (message.page_number != null && message.hasOwnProperty("page_number"))
        if (!$util.isInteger(message.page_number)) return "page_number: integer expected";
      if (message.number_per_page != null && message.hasOwnProperty("number_per_page"))
        if (!$util.isInteger(message.number_per_page)) return "number_per_page: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankRequestRankListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankRequestRankListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankRequestRankListReq} AgentRankRequestRankListReq
     */
    AgentRankRequestRankListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankRequestRankListReq) return object;
      var message = new $root.client_proto.AgentRankRequestRankListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      if (object.is_history != null) message.is_history = Boolean(object.is_history);
      if (object.page_number != null) message.page_number = object.page_number | 0;
      if (object.number_per_page != null) message.number_per_page = object.number_per_page | 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankRequestRankListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankRequestRankListReq
     * @static
     * @param {client_proto.AgentRankRequestRankListReq} message AgentRankRequestRankListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankRequestRankListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
        object.is_history = false;
        object.page_number = 0;
        object.number_per_page = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.is_history != null && message.hasOwnProperty("is_history")) object.is_history = message.is_history;
      if (message.page_number != null && message.hasOwnProperty("page_number"))
        object.page_number = message.page_number;
      if (message.number_per_page != null && message.hasOwnProperty("number_per_page"))
        object.number_per_page = message.number_per_page;
      return object;
    };

    /**
     * Converts this AgentRankRequestRankListReq to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankRequestRankListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankRequestRankListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankRequestRankListReq
     * @function getTypeUrl
     * @memberof client_proto.AgentRankRequestRankListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankRequestRankListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankRequestRankListReq";
    };

    return AgentRankRequestRankListReq;
  })();

  client_proto.AgentRankResponseRankListResp = (function () {
    /**
     * Properties of an AgentRankResponseRankListResp.
     * @memberof client_proto
     * @interface IAgentRankResponseRankListResp
     * @property {boolean|null} [is_have_data] AgentRankResponseRankListResp is_have_data
     * @property {Array.<client_proto.IAgentRankUserRankData>|null} [rank_list] AgentRankResponseRankListResp rank_list
     * @property {client_proto.IAgentRankUserRankData|null} [user_rank] AgentRankResponseRankListResp user_rank
     * @property {number|Long|null} [jackpot] AgentRankResponseRankListResp jackpot
     * @property {number|Long|null} [last_round_jackpot] AgentRankResponseRankListResp last_round_jackpot
     * @property {number|null} [game_reward] AgentRankResponseRankListResp game_reward
     * @property {number|Long|null} [current_join_next_round] AgentRankResponseRankListResp current_join_next_round
     * @property {number|null} [id] AgentRankResponseRankListResp id
     * @property {boolean|null} [is_history] AgentRankResponseRankListResp is_history
     * @property {number|null} [rank_start_time] AgentRankResponseRankListResp rank_start_time
     * @property {number|null} [rank_end_time] AgentRankResponseRankListResp rank_end_time
     * @property {number|null} [page_number] AgentRankResponseRankListResp page_number
     * @property {number|null} [number_per_page] AgentRankResponseRankListResp number_per_page
     * @property {number|null} [total_page_number] AgentRankResponseRankListResp total_page_number
     * @property {number|Long|null} [need_money_to_join] AgentRankResponseRankListResp need_money_to_join
     * @property {number|Long|null} [need_money_to_min_rank] AgentRankResponseRankListResp need_money_to_min_rank
     * @property {number|null} [percent] AgentRankResponseRankListResp percent
     */

    /**
     * Constructs a new AgentRankResponseRankListResp.
     * @memberof client_proto
     * @classdesc Represents an AgentRankResponseRankListResp.
     * @implements IAgentRankResponseRankListResp
     * @constructor
     * @param {client_proto.IAgentRankResponseRankListResp=} [properties] Properties to set
     */
    function AgentRankResponseRankListResp(properties) {
      this.rank_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankResponseRankListResp is_have_data.
     * @member {boolean} is_have_data
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.is_have_data = false;

    /**
     * AgentRankResponseRankListResp rank_list.
     * @member {Array.<client_proto.IAgentRankUserRankData>} rank_list
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.rank_list = $util.emptyArray;

    /**
     * AgentRankResponseRankListResp user_rank.
     * @member {client_proto.IAgentRankUserRankData|null|undefined} user_rank
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.user_rank = null;

    /**
     * AgentRankResponseRankListResp jackpot.
     * @member {number|Long} jackpot
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankResponseRankListResp last_round_jackpot.
     * @member {number|Long} last_round_jackpot
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.last_round_jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankResponseRankListResp game_reward.
     * @member {number} game_reward
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.game_reward = 0;

    /**
     * AgentRankResponseRankListResp current_join_next_round.
     * @member {number|Long} current_join_next_round
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.current_join_next_round = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankResponseRankListResp id.
     * @member {number} id
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.id = 0;

    /**
     * AgentRankResponseRankListResp is_history.
     * @member {boolean} is_history
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.is_history = false;

    /**
     * AgentRankResponseRankListResp rank_start_time.
     * @member {number} rank_start_time
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.rank_start_time = 0;

    /**
     * AgentRankResponseRankListResp rank_end_time.
     * @member {number} rank_end_time
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.rank_end_time = 0;

    /**
     * AgentRankResponseRankListResp page_number.
     * @member {number} page_number
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.page_number = 0;

    /**
     * AgentRankResponseRankListResp number_per_page.
     * @member {number} number_per_page
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.number_per_page = 0;

    /**
     * AgentRankResponseRankListResp total_page_number.
     * @member {number} total_page_number
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.total_page_number = 0;

    /**
     * AgentRankResponseRankListResp need_money_to_join.
     * @member {number|Long} need_money_to_join
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.need_money_to_join = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankResponseRankListResp need_money_to_min_rank.
     * @member {number|Long} need_money_to_min_rank
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.need_money_to_min_rank = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankResponseRankListResp percent.
     * @member {number} percent
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     */
    AgentRankResponseRankListResp.prototype.percent = 0;

    /**
     * Creates a new AgentRankResponseRankListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankResponseRankListResp
     * @static
     * @param {client_proto.IAgentRankResponseRankListResp=} [properties] Properties to set
     * @returns {client_proto.AgentRankResponseRankListResp} AgentRankResponseRankListResp instance
     */
    AgentRankResponseRankListResp.create = function create(properties) {
      return new AgentRankResponseRankListResp(properties);
    };

    /**
     * Encodes the specified AgentRankResponseRankListResp message. Does not implicitly {@link client_proto.AgentRankResponseRankListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankResponseRankListResp
     * @static
     * @param {client_proto.IAgentRankResponseRankListResp} message AgentRankResponseRankListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseRankListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.is_have_data != null && Object.hasOwnProperty.call(message, "is_have_data"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.is_have_data);
      if (message.rank_list != null && message.rank_list.length)
        for (var i = 0; i < message.rank_list.length; ++i)
          $root.client_proto.AgentRankUserRankData.encode(
            message.rank_list[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim();
      if (message.user_rank != null && Object.hasOwnProperty.call(message, "user_rank"))
        $root.client_proto.AgentRankUserRankData.encode(
          message.user_rank,
          writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
        ).ldelim();
      if (message.jackpot != null && Object.hasOwnProperty.call(message, "jackpot"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.jackpot);
      if (message.last_round_jackpot != null && Object.hasOwnProperty.call(message, "last_round_jackpot"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.last_round_jackpot);
      if (message.game_reward != null && Object.hasOwnProperty.call(message, "game_reward"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.game_reward);
      if (message.current_join_next_round != null && Object.hasOwnProperty.call(message, "current_join_next_round"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.current_join_next_round);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.id);
      if (message.is_history != null && Object.hasOwnProperty.call(message, "is_history"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).bool(message.is_history);
      if (message.rank_start_time != null && Object.hasOwnProperty.call(message, "rank_start_time"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).uint32(message.rank_start_time);
      if (message.rank_end_time != null && Object.hasOwnProperty.call(message, "rank_end_time"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).uint32(message.rank_end_time);
      if (message.page_number != null && Object.hasOwnProperty.call(message, "page_number"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).int32(message.page_number);
      if (message.number_per_page != null && Object.hasOwnProperty.call(message, "number_per_page"))
        writer.uint32(/* id 13, wireType 0 =*/ 104).int32(message.number_per_page);
      if (message.total_page_number != null && Object.hasOwnProperty.call(message, "total_page_number"))
        writer.uint32(/* id 14, wireType 0 =*/ 112).int32(message.total_page_number);
      if (message.need_money_to_join != null && Object.hasOwnProperty.call(message, "need_money_to_join"))
        writer.uint32(/* id 15, wireType 0 =*/ 120).int64(message.need_money_to_join);
      if (message.need_money_to_min_rank != null && Object.hasOwnProperty.call(message, "need_money_to_min_rank"))
        writer.uint32(/* id 16, wireType 0 =*/ 128).int64(message.need_money_to_min_rank);
      if (message.percent != null && Object.hasOwnProperty.call(message, "percent"))
        writer.uint32(/* id 17, wireType 0 =*/ 136).int32(message.percent);
      return writer;
    };

    /**
     * Encodes the specified AgentRankResponseRankListResp message, length delimited. Does not implicitly {@link client_proto.AgentRankResponseRankListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankResponseRankListResp
     * @static
     * @param {client_proto.IAgentRankResponseRankListResp} message AgentRankResponseRankListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseRankListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankResponseRankListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankResponseRankListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankResponseRankListResp} AgentRankResponseRankListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseRankListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankResponseRankListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.is_have_data = reader.bool();
            break;
          }
          case 2: {
            if (!(message.rank_list && message.rank_list.length)) message.rank_list = [];
            message.rank_list.push($root.client_proto.AgentRankUserRankData.decode(reader, reader.uint32()));
            break;
          }
          case 3: {
            message.user_rank = $root.client_proto.AgentRankUserRankData.decode(reader, reader.uint32());
            break;
          }
          case 4: {
            message.jackpot = reader.int64();
            break;
          }
          case 5: {
            message.last_round_jackpot = reader.int64();
            break;
          }
          case 6: {
            message.game_reward = reader.int32();
            break;
          }
          case 7: {
            message.current_join_next_round = reader.int64();
            break;
          }
          case 8: {
            message.id = reader.int32();
            break;
          }
          case 9: {
            message.is_history = reader.bool();
            break;
          }
          case 10: {
            message.rank_start_time = reader.uint32();
            break;
          }
          case 11: {
            message.rank_end_time = reader.uint32();
            break;
          }
          case 12: {
            message.page_number = reader.int32();
            break;
          }
          case 13: {
            message.number_per_page = reader.int32();
            break;
          }
          case 14: {
            message.total_page_number = reader.int32();
            break;
          }
          case 15: {
            message.need_money_to_join = reader.int64();
            break;
          }
          case 16: {
            message.need_money_to_min_rank = reader.int64();
            break;
          }
          case 17: {
            message.percent = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankResponseRankListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankResponseRankListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankResponseRankListResp} AgentRankResponseRankListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseRankListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankResponseRankListResp message.
     * @function verify
     * @memberof client_proto.AgentRankResponseRankListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankResponseRankListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        if (typeof message.is_have_data !== "boolean") return "is_have_data: boolean expected";
      if (message.rank_list != null && message.hasOwnProperty("rank_list")) {
        if (!Array.isArray(message.rank_list)) return "rank_list: array expected";
        for (var i = 0; i < message.rank_list.length; ++i) {
          var error = $root.client_proto.AgentRankUserRankData.verify(message.rank_list[i]);
          if (error) return "rank_list." + error;
        }
      }
      if (message.user_rank != null && message.hasOwnProperty("user_rank")) {
        var error = $root.client_proto.AgentRankUserRankData.verify(message.user_rank);
        if (error) return "user_rank." + error;
      }
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (
          !$util.isInteger(message.jackpot) &&
          !(message.jackpot && $util.isInteger(message.jackpot.low) && $util.isInteger(message.jackpot.high))
        )
          return "jackpot: integer|Long expected";
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (
          !$util.isInteger(message.last_round_jackpot) &&
          !(
            message.last_round_jackpot &&
            $util.isInteger(message.last_round_jackpot.low) &&
            $util.isInteger(message.last_round_jackpot.high)
          )
        )
          return "last_round_jackpot: integer|Long expected";
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        if (!$util.isInteger(message.game_reward)) return "game_reward: integer expected";
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (
          !$util.isInteger(message.current_join_next_round) &&
          !(
            message.current_join_next_round &&
            $util.isInteger(message.current_join_next_round.low) &&
            $util.isInteger(message.current_join_next_round.high)
          )
        )
          return "current_join_next_round: integer|Long expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.is_history != null && message.hasOwnProperty("is_history"))
        if (typeof message.is_history !== "boolean") return "is_history: boolean expected";
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        if (!$util.isInteger(message.rank_start_time)) return "rank_start_time: integer expected";
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        if (!$util.isInteger(message.rank_end_time)) return "rank_end_time: integer expected";
      if (message.page_number != null && message.hasOwnProperty("page_number"))
        if (!$util.isInteger(message.page_number)) return "page_number: integer expected";
      if (message.number_per_page != null && message.hasOwnProperty("number_per_page"))
        if (!$util.isInteger(message.number_per_page)) return "number_per_page: integer expected";
      if (message.total_page_number != null && message.hasOwnProperty("total_page_number"))
        if (!$util.isInteger(message.total_page_number)) return "total_page_number: integer expected";
      if (message.need_money_to_join != null && message.hasOwnProperty("need_money_to_join"))
        if (
          !$util.isInteger(message.need_money_to_join) &&
          !(
            message.need_money_to_join &&
            $util.isInteger(message.need_money_to_join.low) &&
            $util.isInteger(message.need_money_to_join.high)
          )
        )
          return "need_money_to_join: integer|Long expected";
      if (message.need_money_to_min_rank != null && message.hasOwnProperty("need_money_to_min_rank"))
        if (
          !$util.isInteger(message.need_money_to_min_rank) &&
          !(
            message.need_money_to_min_rank &&
            $util.isInteger(message.need_money_to_min_rank.low) &&
            $util.isInteger(message.need_money_to_min_rank.high)
          )
        )
          return "need_money_to_min_rank: integer|Long expected";
      if (message.percent != null && message.hasOwnProperty("percent"))
        if (!$util.isInteger(message.percent)) return "percent: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankResponseRankListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankResponseRankListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankResponseRankListResp} AgentRankResponseRankListResp
     */
    AgentRankResponseRankListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankResponseRankListResp) return object;
      var message = new $root.client_proto.AgentRankResponseRankListResp();
      if (object.is_have_data != null) message.is_have_data = Boolean(object.is_have_data);
      if (object.rank_list) {
        if (!Array.isArray(object.rank_list))
          throw TypeError(".client_proto.AgentRankResponseRankListResp.rank_list: array expected");
        message.rank_list = [];
        for (var i = 0; i < object.rank_list.length; ++i) {
          if (typeof object.rank_list[i] !== "object")
            throw TypeError(".client_proto.AgentRankResponseRankListResp.rank_list: object expected");
          message.rank_list[i] = $root.client_proto.AgentRankUserRankData.fromObject(object.rank_list[i]);
        }
      }
      if (object.user_rank != null) {
        if (typeof object.user_rank !== "object")
          throw TypeError(".client_proto.AgentRankResponseRankListResp.user_rank: object expected");
        message.user_rank = $root.client_proto.AgentRankUserRankData.fromObject(object.user_rank);
      }
      if (object.jackpot != null)
        if ($util.Long) (message.jackpot = $util.Long.fromValue(object.jackpot)).unsigned = false;
        else if (typeof object.jackpot === "string") message.jackpot = parseInt(object.jackpot, 10);
        else if (typeof object.jackpot === "number") message.jackpot = object.jackpot;
        else if (typeof object.jackpot === "object")
          message.jackpot = new $util.LongBits(object.jackpot.low >>> 0, object.jackpot.high >>> 0).toNumber();
      if (object.last_round_jackpot != null)
        if ($util.Long) (message.last_round_jackpot = $util.Long.fromValue(object.last_round_jackpot)).unsigned = false;
        else if (typeof object.last_round_jackpot === "string")
          message.last_round_jackpot = parseInt(object.last_round_jackpot, 10);
        else if (typeof object.last_round_jackpot === "number") message.last_round_jackpot = object.last_round_jackpot;
        else if (typeof object.last_round_jackpot === "object")
          message.last_round_jackpot = new $util.LongBits(
            object.last_round_jackpot.low >>> 0,
            object.last_round_jackpot.high >>> 0,
          ).toNumber();
      if (object.game_reward != null) message.game_reward = object.game_reward | 0;
      if (object.current_join_next_round != null)
        if ($util.Long)
          (message.current_join_next_round = $util.Long.fromValue(object.current_join_next_round)).unsigned = false;
        else if (typeof object.current_join_next_round === "string")
          message.current_join_next_round = parseInt(object.current_join_next_round, 10);
        else if (typeof object.current_join_next_round === "number")
          message.current_join_next_round = object.current_join_next_round;
        else if (typeof object.current_join_next_round === "object")
          message.current_join_next_round = new $util.LongBits(
            object.current_join_next_round.low >>> 0,
            object.current_join_next_round.high >>> 0,
          ).toNumber();
      if (object.id != null) message.id = object.id | 0;
      if (object.is_history != null) message.is_history = Boolean(object.is_history);
      if (object.rank_start_time != null) message.rank_start_time = object.rank_start_time >>> 0;
      if (object.rank_end_time != null) message.rank_end_time = object.rank_end_time >>> 0;
      if (object.page_number != null) message.page_number = object.page_number | 0;
      if (object.number_per_page != null) message.number_per_page = object.number_per_page | 0;
      if (object.total_page_number != null) message.total_page_number = object.total_page_number | 0;
      if (object.need_money_to_join != null)
        if ($util.Long) (message.need_money_to_join = $util.Long.fromValue(object.need_money_to_join)).unsigned = false;
        else if (typeof object.need_money_to_join === "string")
          message.need_money_to_join = parseInt(object.need_money_to_join, 10);
        else if (typeof object.need_money_to_join === "number") message.need_money_to_join = object.need_money_to_join;
        else if (typeof object.need_money_to_join === "object")
          message.need_money_to_join = new $util.LongBits(
            object.need_money_to_join.low >>> 0,
            object.need_money_to_join.high >>> 0,
          ).toNumber();
      if (object.need_money_to_min_rank != null)
        if ($util.Long)
          (message.need_money_to_min_rank = $util.Long.fromValue(object.need_money_to_min_rank)).unsigned = false;
        else if (typeof object.need_money_to_min_rank === "string")
          message.need_money_to_min_rank = parseInt(object.need_money_to_min_rank, 10);
        else if (typeof object.need_money_to_min_rank === "number")
          message.need_money_to_min_rank = object.need_money_to_min_rank;
        else if (typeof object.need_money_to_min_rank === "object")
          message.need_money_to_min_rank = new $util.LongBits(
            object.need_money_to_min_rank.low >>> 0,
            object.need_money_to_min_rank.high >>> 0,
          ).toNumber();
      if (object.percent != null) message.percent = object.percent | 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankResponseRankListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankResponseRankListResp
     * @static
     * @param {client_proto.AgentRankResponseRankListResp} message AgentRankResponseRankListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankResponseRankListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.rank_list = [];
      if (options.defaults) {
        object.is_have_data = false;
        object.user_rank = null;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.jackpot = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.last_round_jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.last_round_jackpot = options.longs === String ? "0" : 0;
        object.game_reward = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.current_join_next_round =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.current_join_next_round = options.longs === String ? "0" : 0;
        object.id = 0;
        object.is_history = false;
        object.rank_start_time = 0;
        object.rank_end_time = 0;
        object.page_number = 0;
        object.number_per_page = 0;
        object.total_page_number = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.need_money_to_join =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.need_money_to_join = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.need_money_to_min_rank =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.need_money_to_min_rank = options.longs === String ? "0" : 0;
        object.percent = 0;
      }
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        object.is_have_data = message.is_have_data;
      if (message.rank_list && message.rank_list.length) {
        object.rank_list = [];
        for (var j = 0; j < message.rank_list.length; ++j)
          object.rank_list[j] = $root.client_proto.AgentRankUserRankData.toObject(message.rank_list[j], options);
      }
      if (message.user_rank != null && message.hasOwnProperty("user_rank"))
        object.user_rank = $root.client_proto.AgentRankUserRankData.toObject(message.user_rank, options);
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (typeof message.jackpot === "number")
          object.jackpot = options.longs === String ? String(message.jackpot) : message.jackpot;
        else
          object.jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.jackpot)
              : options.longs === Number
                ? new $util.LongBits(message.jackpot.low >>> 0, message.jackpot.high >>> 0).toNumber()
                : message.jackpot;
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (typeof message.last_round_jackpot === "number")
          object.last_round_jackpot =
            options.longs === String ? String(message.last_round_jackpot) : message.last_round_jackpot;
        else
          object.last_round_jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.last_round_jackpot)
              : options.longs === Number
                ? new $util.LongBits(
                    message.last_round_jackpot.low >>> 0,
                    message.last_round_jackpot.high >>> 0,
                  ).toNumber()
                : message.last_round_jackpot;
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        object.game_reward = message.game_reward;
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (typeof message.current_join_next_round === "number")
          object.current_join_next_round =
            options.longs === String ? String(message.current_join_next_round) : message.current_join_next_round;
        else
          object.current_join_next_round =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.current_join_next_round)
              : options.longs === Number
                ? new $util.LongBits(
                    message.current_join_next_round.low >>> 0,
                    message.current_join_next_round.high >>> 0,
                  ).toNumber()
                : message.current_join_next_round;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.is_history != null && message.hasOwnProperty("is_history")) object.is_history = message.is_history;
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        object.rank_start_time = message.rank_start_time;
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        object.rank_end_time = message.rank_end_time;
      if (message.page_number != null && message.hasOwnProperty("page_number"))
        object.page_number = message.page_number;
      if (message.number_per_page != null && message.hasOwnProperty("number_per_page"))
        object.number_per_page = message.number_per_page;
      if (message.total_page_number != null && message.hasOwnProperty("total_page_number"))
        object.total_page_number = message.total_page_number;
      if (message.need_money_to_join != null && message.hasOwnProperty("need_money_to_join"))
        if (typeof message.need_money_to_join === "number")
          object.need_money_to_join =
            options.longs === String ? String(message.need_money_to_join) : message.need_money_to_join;
        else
          object.need_money_to_join =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.need_money_to_join)
              : options.longs === Number
                ? new $util.LongBits(
                    message.need_money_to_join.low >>> 0,
                    message.need_money_to_join.high >>> 0,
                  ).toNumber()
                : message.need_money_to_join;
      if (message.need_money_to_min_rank != null && message.hasOwnProperty("need_money_to_min_rank"))
        if (typeof message.need_money_to_min_rank === "number")
          object.need_money_to_min_rank =
            options.longs === String ? String(message.need_money_to_min_rank) : message.need_money_to_min_rank;
        else
          object.need_money_to_min_rank =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.need_money_to_min_rank)
              : options.longs === Number
                ? new $util.LongBits(
                    message.need_money_to_min_rank.low >>> 0,
                    message.need_money_to_min_rank.high >>> 0,
                  ).toNumber()
                : message.need_money_to_min_rank;
      if (message.percent != null && message.hasOwnProperty("percent")) object.percent = message.percent;
      return object;
    };

    /**
     * Converts this AgentRankResponseRankListResp to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankResponseRankListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankResponseRankListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankResponseRankListResp
     * @function getTypeUrl
     * @memberof client_proto.AgentRankResponseRankListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankResponseRankListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankResponseRankListResp";
    };

    return AgentRankResponseRankListResp;
  })();

  client_proto.AgentRankListReportReq = (function () {
    /**
     * Properties of an AgentRankListReportReq.
     * @memberof client_proto
     * @interface IAgentRankListReportReq
     * @property {number|null} [number] AgentRankListReportReq number
     * @property {string|null} [key] AgentRankListReportReq key
     */

    /**
     * Constructs a new AgentRankListReportReq.
     * @memberof client_proto
     * @classdesc Represents an AgentRankListReportReq.
     * @implements IAgentRankListReportReq
     * @constructor
     * @param {client_proto.IAgentRankListReportReq=} [properties] Properties to set
     */
    function AgentRankListReportReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankListReportReq number.
     * @member {number} number
     * @memberof client_proto.AgentRankListReportReq
     * @instance
     */
    AgentRankListReportReq.prototype.number = 0;

    /**
     * AgentRankListReportReq key.
     * @member {string} key
     * @memberof client_proto.AgentRankListReportReq
     * @instance
     */
    AgentRankListReportReq.prototype.key = "";

    /**
     * Creates a new AgentRankListReportReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankListReportReq
     * @static
     * @param {client_proto.IAgentRankListReportReq=} [properties] Properties to set
     * @returns {client_proto.AgentRankListReportReq} AgentRankListReportReq instance
     */
    AgentRankListReportReq.create = function create(properties) {
      return new AgentRankListReportReq(properties);
    };

    /**
     * Encodes the specified AgentRankListReportReq message. Does not implicitly {@link client_proto.AgentRankListReportReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankListReportReq
     * @static
     * @param {client_proto.IAgentRankListReportReq} message AgentRankListReportReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankListReportReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.number != null && Object.hasOwnProperty.call(message, "number"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.number);
      if (message.key != null && Object.hasOwnProperty.call(message, "key"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.key);
      return writer;
    };

    /**
     * Encodes the specified AgentRankListReportReq message, length delimited. Does not implicitly {@link client_proto.AgentRankListReportReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankListReportReq
     * @static
     * @param {client_proto.IAgentRankListReportReq} message AgentRankListReportReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankListReportReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankListReportReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankListReportReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankListReportReq} AgentRankListReportReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankListReportReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankListReportReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.number = reader.uint32();
            break;
          }
          case 2: {
            message.key = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankListReportReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankListReportReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankListReportReq} AgentRankListReportReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankListReportReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankListReportReq message.
     * @function verify
     * @memberof client_proto.AgentRankListReportReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankListReportReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.number != null && message.hasOwnProperty("number"))
        if (!$util.isInteger(message.number)) return "number: integer expected";
      if (message.key != null && message.hasOwnProperty("key"))
        if (!$util.isString(message.key)) return "key: string expected";
      return null;
    };

    /**
     * Creates an AgentRankListReportReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankListReportReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankListReportReq} AgentRankListReportReq
     */
    AgentRankListReportReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankListReportReq) return object;
      var message = new $root.client_proto.AgentRankListReportReq();
      if (object.number != null) message.number = object.number >>> 0;
      if (object.key != null) message.key = String(object.key);
      return message;
    };

    /**
     * Creates a plain object from an AgentRankListReportReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankListReportReq
     * @static
     * @param {client_proto.AgentRankListReportReq} message AgentRankListReportReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankListReportReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.number = 0;
        object.key = "";
      }
      if (message.number != null && message.hasOwnProperty("number")) object.number = message.number;
      if (message.key != null && message.hasOwnProperty("key")) object.key = message.key;
      return object;
    };

    /**
     * Converts this AgentRankListReportReq to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankListReportReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankListReportReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankListReportReq
     * @function getTypeUrl
     * @memberof client_proto.AgentRankListReportReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankListReportReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankListReportReq";
    };

    return AgentRankListReportReq;
  })();

  client_proto.AgentRankListReportResp = (function () {
    /**
     * Properties of an AgentRankListReportResp.
     * @memberof client_proto
     * @interface IAgentRankListReportResp
     * @property {boolean|null} [is_have_data] AgentRankListReportResp is_have_data
     * @property {Array.<client_proto.IAgentRankUserRankData>|null} [rank_list] AgentRankListReportResp rank_list
     * @property {number|Long|null} [jackpot] AgentRankListReportResp jackpot
     * @property {number|Long|null} [last_round_jackpot] AgentRankListReportResp last_round_jackpot
     * @property {number|null} [game_reward] AgentRankListReportResp game_reward
     * @property {number|Long|null} [current_join_next_round] AgentRankListReportResp current_join_next_round
     * @property {number|null} [id] AgentRankListReportResp id
     * @property {number|null} [rank_start_time] AgentRankListReportResp rank_start_time
     * @property {number|null} [rank_end_time] AgentRankListReportResp rank_end_time
     * @property {number|null} [number] AgentRankListReportResp number
     * @property {string|null} [key] AgentRankListReportResp key
     */

    /**
     * Constructs a new AgentRankListReportResp.
     * @memberof client_proto
     * @classdesc Represents an AgentRankListReportResp.
     * @implements IAgentRankListReportResp
     * @constructor
     * @param {client_proto.IAgentRankListReportResp=} [properties] Properties to set
     */
    function AgentRankListReportResp(properties) {
      this.rank_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankListReportResp is_have_data.
     * @member {boolean} is_have_data
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.is_have_data = false;

    /**
     * AgentRankListReportResp rank_list.
     * @member {Array.<client_proto.IAgentRankUserRankData>} rank_list
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.rank_list = $util.emptyArray;

    /**
     * AgentRankListReportResp jackpot.
     * @member {number|Long} jackpot
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankListReportResp last_round_jackpot.
     * @member {number|Long} last_round_jackpot
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.last_round_jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankListReportResp game_reward.
     * @member {number} game_reward
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.game_reward = 0;

    /**
     * AgentRankListReportResp current_join_next_round.
     * @member {number|Long} current_join_next_round
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.current_join_next_round = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankListReportResp id.
     * @member {number} id
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.id = 0;

    /**
     * AgentRankListReportResp rank_start_time.
     * @member {number} rank_start_time
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.rank_start_time = 0;

    /**
     * AgentRankListReportResp rank_end_time.
     * @member {number} rank_end_time
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.rank_end_time = 0;

    /**
     * AgentRankListReportResp number.
     * @member {number} number
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.number = 0;

    /**
     * AgentRankListReportResp key.
     * @member {string} key
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     */
    AgentRankListReportResp.prototype.key = "";

    /**
     * Creates a new AgentRankListReportResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankListReportResp
     * @static
     * @param {client_proto.IAgentRankListReportResp=} [properties] Properties to set
     * @returns {client_proto.AgentRankListReportResp} AgentRankListReportResp instance
     */
    AgentRankListReportResp.create = function create(properties) {
      return new AgentRankListReportResp(properties);
    };

    /**
     * Encodes the specified AgentRankListReportResp message. Does not implicitly {@link client_proto.AgentRankListReportResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankListReportResp
     * @static
     * @param {client_proto.IAgentRankListReportResp} message AgentRankListReportResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankListReportResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.is_have_data != null && Object.hasOwnProperty.call(message, "is_have_data"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.is_have_data);
      if (message.rank_list != null && message.rank_list.length)
        for (var i = 0; i < message.rank_list.length; ++i)
          $root.client_proto.AgentRankUserRankData.encode(
            message.rank_list[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim();
      if (message.jackpot != null && Object.hasOwnProperty.call(message, "jackpot"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.jackpot);
      if (message.last_round_jackpot != null && Object.hasOwnProperty.call(message, "last_round_jackpot"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.last_round_jackpot);
      if (message.game_reward != null && Object.hasOwnProperty.call(message, "game_reward"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.game_reward);
      if (message.current_join_next_round != null && Object.hasOwnProperty.call(message, "current_join_next_round"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.current_join_next_round);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.id);
      if (message.rank_start_time != null && Object.hasOwnProperty.call(message, "rank_start_time"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).uint32(message.rank_start_time);
      if (message.rank_end_time != null && Object.hasOwnProperty.call(message, "rank_end_time"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).uint32(message.rank_end_time);
      if (message.number != null && Object.hasOwnProperty.call(message, "number"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).uint32(message.number);
      if (message.key != null && Object.hasOwnProperty.call(message, "key"))
        writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.key);
      return writer;
    };

    /**
     * Encodes the specified AgentRankListReportResp message, length delimited. Does not implicitly {@link client_proto.AgentRankListReportResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankListReportResp
     * @static
     * @param {client_proto.IAgentRankListReportResp} message AgentRankListReportResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankListReportResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankListReportResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankListReportResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankListReportResp} AgentRankListReportResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankListReportResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankListReportResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.is_have_data = reader.bool();
            break;
          }
          case 2: {
            if (!(message.rank_list && message.rank_list.length)) message.rank_list = [];
            message.rank_list.push($root.client_proto.AgentRankUserRankData.decode(reader, reader.uint32()));
            break;
          }
          case 3: {
            message.jackpot = reader.int64();
            break;
          }
          case 4: {
            message.last_round_jackpot = reader.int64();
            break;
          }
          case 5: {
            message.game_reward = reader.int32();
            break;
          }
          case 6: {
            message.current_join_next_round = reader.int64();
            break;
          }
          case 7: {
            message.id = reader.int32();
            break;
          }
          case 8: {
            message.rank_start_time = reader.uint32();
            break;
          }
          case 9: {
            message.rank_end_time = reader.uint32();
            break;
          }
          case 10: {
            message.number = reader.uint32();
            break;
          }
          case 11: {
            message.key = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankListReportResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankListReportResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankListReportResp} AgentRankListReportResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankListReportResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankListReportResp message.
     * @function verify
     * @memberof client_proto.AgentRankListReportResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankListReportResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        if (typeof message.is_have_data !== "boolean") return "is_have_data: boolean expected";
      if (message.rank_list != null && message.hasOwnProperty("rank_list")) {
        if (!Array.isArray(message.rank_list)) return "rank_list: array expected";
        for (var i = 0; i < message.rank_list.length; ++i) {
          var error = $root.client_proto.AgentRankUserRankData.verify(message.rank_list[i]);
          if (error) return "rank_list." + error;
        }
      }
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (
          !$util.isInteger(message.jackpot) &&
          !(message.jackpot && $util.isInteger(message.jackpot.low) && $util.isInteger(message.jackpot.high))
        )
          return "jackpot: integer|Long expected";
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (
          !$util.isInteger(message.last_round_jackpot) &&
          !(
            message.last_round_jackpot &&
            $util.isInteger(message.last_round_jackpot.low) &&
            $util.isInteger(message.last_round_jackpot.high)
          )
        )
          return "last_round_jackpot: integer|Long expected";
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        if (!$util.isInteger(message.game_reward)) return "game_reward: integer expected";
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (
          !$util.isInteger(message.current_join_next_round) &&
          !(
            message.current_join_next_round &&
            $util.isInteger(message.current_join_next_round.low) &&
            $util.isInteger(message.current_join_next_round.high)
          )
        )
          return "current_join_next_round: integer|Long expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        if (!$util.isInteger(message.rank_start_time)) return "rank_start_time: integer expected";
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        if (!$util.isInteger(message.rank_end_time)) return "rank_end_time: integer expected";
      if (message.number != null && message.hasOwnProperty("number"))
        if (!$util.isInteger(message.number)) return "number: integer expected";
      if (message.key != null && message.hasOwnProperty("key"))
        if (!$util.isString(message.key)) return "key: string expected";
      return null;
    };

    /**
     * Creates an AgentRankListReportResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankListReportResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankListReportResp} AgentRankListReportResp
     */
    AgentRankListReportResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankListReportResp) return object;
      var message = new $root.client_proto.AgentRankListReportResp();
      if (object.is_have_data != null) message.is_have_data = Boolean(object.is_have_data);
      if (object.rank_list) {
        if (!Array.isArray(object.rank_list))
          throw TypeError(".client_proto.AgentRankListReportResp.rank_list: array expected");
        message.rank_list = [];
        for (var i = 0; i < object.rank_list.length; ++i) {
          if (typeof object.rank_list[i] !== "object")
            throw TypeError(".client_proto.AgentRankListReportResp.rank_list: object expected");
          message.rank_list[i] = $root.client_proto.AgentRankUserRankData.fromObject(object.rank_list[i]);
        }
      }
      if (object.jackpot != null)
        if ($util.Long) (message.jackpot = $util.Long.fromValue(object.jackpot)).unsigned = false;
        else if (typeof object.jackpot === "string") message.jackpot = parseInt(object.jackpot, 10);
        else if (typeof object.jackpot === "number") message.jackpot = object.jackpot;
        else if (typeof object.jackpot === "object")
          message.jackpot = new $util.LongBits(object.jackpot.low >>> 0, object.jackpot.high >>> 0).toNumber();
      if (object.last_round_jackpot != null)
        if ($util.Long) (message.last_round_jackpot = $util.Long.fromValue(object.last_round_jackpot)).unsigned = false;
        else if (typeof object.last_round_jackpot === "string")
          message.last_round_jackpot = parseInt(object.last_round_jackpot, 10);
        else if (typeof object.last_round_jackpot === "number") message.last_round_jackpot = object.last_round_jackpot;
        else if (typeof object.last_round_jackpot === "object")
          message.last_round_jackpot = new $util.LongBits(
            object.last_round_jackpot.low >>> 0,
            object.last_round_jackpot.high >>> 0,
          ).toNumber();
      if (object.game_reward != null) message.game_reward = object.game_reward | 0;
      if (object.current_join_next_round != null)
        if ($util.Long)
          (message.current_join_next_round = $util.Long.fromValue(object.current_join_next_round)).unsigned = false;
        else if (typeof object.current_join_next_round === "string")
          message.current_join_next_round = parseInt(object.current_join_next_round, 10);
        else if (typeof object.current_join_next_round === "number")
          message.current_join_next_round = object.current_join_next_round;
        else if (typeof object.current_join_next_round === "object")
          message.current_join_next_round = new $util.LongBits(
            object.current_join_next_round.low >>> 0,
            object.current_join_next_round.high >>> 0,
          ).toNumber();
      if (object.id != null) message.id = object.id | 0;
      if (object.rank_start_time != null) message.rank_start_time = object.rank_start_time >>> 0;
      if (object.rank_end_time != null) message.rank_end_time = object.rank_end_time >>> 0;
      if (object.number != null) message.number = object.number >>> 0;
      if (object.key != null) message.key = String(object.key);
      return message;
    };

    /**
     * Creates a plain object from an AgentRankListReportResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankListReportResp
     * @static
     * @param {client_proto.AgentRankListReportResp} message AgentRankListReportResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankListReportResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.rank_list = [];
      if (options.defaults) {
        object.is_have_data = false;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.jackpot = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.last_round_jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.last_round_jackpot = options.longs === String ? "0" : 0;
        object.game_reward = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.current_join_next_round =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.current_join_next_round = options.longs === String ? "0" : 0;
        object.id = 0;
        object.rank_start_time = 0;
        object.rank_end_time = 0;
        object.number = 0;
        object.key = "";
      }
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        object.is_have_data = message.is_have_data;
      if (message.rank_list && message.rank_list.length) {
        object.rank_list = [];
        for (var j = 0; j < message.rank_list.length; ++j)
          object.rank_list[j] = $root.client_proto.AgentRankUserRankData.toObject(message.rank_list[j], options);
      }
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (typeof message.jackpot === "number")
          object.jackpot = options.longs === String ? String(message.jackpot) : message.jackpot;
        else
          object.jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.jackpot)
              : options.longs === Number
                ? new $util.LongBits(message.jackpot.low >>> 0, message.jackpot.high >>> 0).toNumber()
                : message.jackpot;
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (typeof message.last_round_jackpot === "number")
          object.last_round_jackpot =
            options.longs === String ? String(message.last_round_jackpot) : message.last_round_jackpot;
        else
          object.last_round_jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.last_round_jackpot)
              : options.longs === Number
                ? new $util.LongBits(
                    message.last_round_jackpot.low >>> 0,
                    message.last_round_jackpot.high >>> 0,
                  ).toNumber()
                : message.last_round_jackpot;
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        object.game_reward = message.game_reward;
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (typeof message.current_join_next_round === "number")
          object.current_join_next_round =
            options.longs === String ? String(message.current_join_next_round) : message.current_join_next_round;
        else
          object.current_join_next_round =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.current_join_next_round)
              : options.longs === Number
                ? new $util.LongBits(
                    message.current_join_next_round.low >>> 0,
                    message.current_join_next_round.high >>> 0,
                  ).toNumber()
                : message.current_join_next_round;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        object.rank_start_time = message.rank_start_time;
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        object.rank_end_time = message.rank_end_time;
      if (message.number != null && message.hasOwnProperty("number")) object.number = message.number;
      if (message.key != null && message.hasOwnProperty("key")) object.key = message.key;
      return object;
    };

    /**
     * Converts this AgentRankListReportResp to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankListReportResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankListReportResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankListReportResp
     * @function getTypeUrl
     * @memberof client_proto.AgentRankListReportResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankListReportResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankListReportResp";
    };

    return AgentRankListReportResp;
  })();

  client_proto.AgentRankRequestRankJackpotReq = (function () {
    /**
     * Properties of an AgentRankRequestRankJackpotReq.
     * @memberof client_proto
     * @interface IAgentRankRequestRankJackpotReq
     * @property {number|null} [id] AgentRankRequestRankJackpotReq id
     */

    /**
     * Constructs a new AgentRankRequestRankJackpotReq.
     * @memberof client_proto
     * @classdesc Represents an AgentRankRequestRankJackpotReq.
     * @implements IAgentRankRequestRankJackpotReq
     * @constructor
     * @param {client_proto.IAgentRankRequestRankJackpotReq=} [properties] Properties to set
     */
    function AgentRankRequestRankJackpotReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankRequestRankJackpotReq id.
     * @member {number} id
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @instance
     */
    AgentRankRequestRankJackpotReq.prototype.id = 0;

    /**
     * Creates a new AgentRankRequestRankJackpotReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @static
     * @param {client_proto.IAgentRankRequestRankJackpotReq=} [properties] Properties to set
     * @returns {client_proto.AgentRankRequestRankJackpotReq} AgentRankRequestRankJackpotReq instance
     */
    AgentRankRequestRankJackpotReq.create = function create(properties) {
      return new AgentRankRequestRankJackpotReq(properties);
    };

    /**
     * Encodes the specified AgentRankRequestRankJackpotReq message. Does not implicitly {@link client_proto.AgentRankRequestRankJackpotReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @static
     * @param {client_proto.IAgentRankRequestRankJackpotReq} message AgentRankRequestRankJackpotReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestRankJackpotReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified AgentRankRequestRankJackpotReq message, length delimited. Does not implicitly {@link client_proto.AgentRankRequestRankJackpotReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @static
     * @param {client_proto.IAgentRankRequestRankJackpotReq} message AgentRankRequestRankJackpotReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestRankJackpotReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankRequestRankJackpotReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankRequestRankJackpotReq} AgentRankRequestRankJackpotReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestRankJackpotReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankRequestRankJackpotReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankRequestRankJackpotReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankRequestRankJackpotReq} AgentRankRequestRankJackpotReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestRankJackpotReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankRequestRankJackpotReq message.
     * @function verify
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankRequestRankJackpotReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankRequestRankJackpotReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankRequestRankJackpotReq} AgentRankRequestRankJackpotReq
     */
    AgentRankRequestRankJackpotReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankRequestRankJackpotReq) return object;
      var message = new $root.client_proto.AgentRankRequestRankJackpotReq();
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankRequestRankJackpotReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @static
     * @param {client_proto.AgentRankRequestRankJackpotReq} message AgentRankRequestRankJackpotReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankRequestRankJackpotReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.id = 0;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this AgentRankRequestRankJackpotReq to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankRequestRankJackpotReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankRequestRankJackpotReq
     * @function getTypeUrl
     * @memberof client_proto.AgentRankRequestRankJackpotReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankRequestRankJackpotReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankRequestRankJackpotReq";
    };

    return AgentRankRequestRankJackpotReq;
  })();

  client_proto.AgentRankResponseRankJackpotResp = (function () {
    /**
     * Properties of an AgentRankResponseRankJackpotResp.
     * @memberof client_proto
     * @interface IAgentRankResponseRankJackpotResp
     * @property {boolean|null} [is_have_data] AgentRankResponseRankJackpotResp is_have_data
     * @property {number|Long|null} [jackpot] AgentRankResponseRankJackpotResp jackpot
     * @property {number|Long|null} [last_round_jackpot] AgentRankResponseRankJackpotResp last_round_jackpot
     * @property {number|null} [game_reward] AgentRankResponseRankJackpotResp game_reward
     * @property {number|Long|null} [current_join_next_round] AgentRankResponseRankJackpotResp current_join_next_round
     * @property {number|null} [id] AgentRankResponseRankJackpotResp id
     */

    /**
     * Constructs a new AgentRankResponseRankJackpotResp.
     * @memberof client_proto
     * @classdesc Represents an AgentRankResponseRankJackpotResp.
     * @implements IAgentRankResponseRankJackpotResp
     * @constructor
     * @param {client_proto.IAgentRankResponseRankJackpotResp=} [properties] Properties to set
     */
    function AgentRankResponseRankJackpotResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankResponseRankJackpotResp is_have_data.
     * @member {boolean} is_have_data
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @instance
     */
    AgentRankResponseRankJackpotResp.prototype.is_have_data = false;

    /**
     * AgentRankResponseRankJackpotResp jackpot.
     * @member {number|Long} jackpot
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @instance
     */
    AgentRankResponseRankJackpotResp.prototype.jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankResponseRankJackpotResp last_round_jackpot.
     * @member {number|Long} last_round_jackpot
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @instance
     */
    AgentRankResponseRankJackpotResp.prototype.last_round_jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankResponseRankJackpotResp game_reward.
     * @member {number} game_reward
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @instance
     */
    AgentRankResponseRankJackpotResp.prototype.game_reward = 0;

    /**
     * AgentRankResponseRankJackpotResp current_join_next_round.
     * @member {number|Long} current_join_next_round
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @instance
     */
    AgentRankResponseRankJackpotResp.prototype.current_join_next_round = $util.Long
      ? $util.Long.fromBits(0, 0, false)
      : 0;

    /**
     * AgentRankResponseRankJackpotResp id.
     * @member {number} id
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @instance
     */
    AgentRankResponseRankJackpotResp.prototype.id = 0;

    /**
     * Creates a new AgentRankResponseRankJackpotResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @static
     * @param {client_proto.IAgentRankResponseRankJackpotResp=} [properties] Properties to set
     * @returns {client_proto.AgentRankResponseRankJackpotResp} AgentRankResponseRankJackpotResp instance
     */
    AgentRankResponseRankJackpotResp.create = function create(properties) {
      return new AgentRankResponseRankJackpotResp(properties);
    };

    /**
     * Encodes the specified AgentRankResponseRankJackpotResp message. Does not implicitly {@link client_proto.AgentRankResponseRankJackpotResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @static
     * @param {client_proto.IAgentRankResponseRankJackpotResp} message AgentRankResponseRankJackpotResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseRankJackpotResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.is_have_data != null && Object.hasOwnProperty.call(message, "is_have_data"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).bool(message.is_have_data);
      if (message.jackpot != null && Object.hasOwnProperty.call(message, "jackpot"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.jackpot);
      if (message.last_round_jackpot != null && Object.hasOwnProperty.call(message, "last_round_jackpot"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.last_round_jackpot);
      if (message.game_reward != null && Object.hasOwnProperty.call(message, "game_reward"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.game_reward);
      if (message.current_join_next_round != null && Object.hasOwnProperty.call(message, "current_join_next_round"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.current_join_next_round);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified AgentRankResponseRankJackpotResp message, length delimited. Does not implicitly {@link client_proto.AgentRankResponseRankJackpotResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @static
     * @param {client_proto.IAgentRankResponseRankJackpotResp} message AgentRankResponseRankJackpotResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseRankJackpotResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankResponseRankJackpotResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankResponseRankJackpotResp} AgentRankResponseRankJackpotResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseRankJackpotResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankResponseRankJackpotResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.is_have_data = reader.bool();
            break;
          }
          case 2: {
            message.jackpot = reader.int64();
            break;
          }
          case 3: {
            message.last_round_jackpot = reader.int64();
            break;
          }
          case 4: {
            message.game_reward = reader.int32();
            break;
          }
          case 5: {
            message.current_join_next_round = reader.int64();
            break;
          }
          case 6: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankResponseRankJackpotResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankResponseRankJackpotResp} AgentRankResponseRankJackpotResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseRankJackpotResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankResponseRankJackpotResp message.
     * @function verify
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankResponseRankJackpotResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        if (typeof message.is_have_data !== "boolean") return "is_have_data: boolean expected";
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (
          !$util.isInteger(message.jackpot) &&
          !(message.jackpot && $util.isInteger(message.jackpot.low) && $util.isInteger(message.jackpot.high))
        )
          return "jackpot: integer|Long expected";
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (
          !$util.isInteger(message.last_round_jackpot) &&
          !(
            message.last_round_jackpot &&
            $util.isInteger(message.last_round_jackpot.low) &&
            $util.isInteger(message.last_round_jackpot.high)
          )
        )
          return "last_round_jackpot: integer|Long expected";
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        if (!$util.isInteger(message.game_reward)) return "game_reward: integer expected";
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (
          !$util.isInteger(message.current_join_next_round) &&
          !(
            message.current_join_next_round &&
            $util.isInteger(message.current_join_next_round.low) &&
            $util.isInteger(message.current_join_next_round.high)
          )
        )
          return "current_join_next_round: integer|Long expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankResponseRankJackpotResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankResponseRankJackpotResp} AgentRankResponseRankJackpotResp
     */
    AgentRankResponseRankJackpotResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankResponseRankJackpotResp) return object;
      var message = new $root.client_proto.AgentRankResponseRankJackpotResp();
      if (object.is_have_data != null) message.is_have_data = Boolean(object.is_have_data);
      if (object.jackpot != null)
        if ($util.Long) (message.jackpot = $util.Long.fromValue(object.jackpot)).unsigned = false;
        else if (typeof object.jackpot === "string") message.jackpot = parseInt(object.jackpot, 10);
        else if (typeof object.jackpot === "number") message.jackpot = object.jackpot;
        else if (typeof object.jackpot === "object")
          message.jackpot = new $util.LongBits(object.jackpot.low >>> 0, object.jackpot.high >>> 0).toNumber();
      if (object.last_round_jackpot != null)
        if ($util.Long) (message.last_round_jackpot = $util.Long.fromValue(object.last_round_jackpot)).unsigned = false;
        else if (typeof object.last_round_jackpot === "string")
          message.last_round_jackpot = parseInt(object.last_round_jackpot, 10);
        else if (typeof object.last_round_jackpot === "number") message.last_round_jackpot = object.last_round_jackpot;
        else if (typeof object.last_round_jackpot === "object")
          message.last_round_jackpot = new $util.LongBits(
            object.last_round_jackpot.low >>> 0,
            object.last_round_jackpot.high >>> 0,
          ).toNumber();
      if (object.game_reward != null) message.game_reward = object.game_reward | 0;
      if (object.current_join_next_round != null)
        if ($util.Long)
          (message.current_join_next_round = $util.Long.fromValue(object.current_join_next_round)).unsigned = false;
        else if (typeof object.current_join_next_round === "string")
          message.current_join_next_round = parseInt(object.current_join_next_round, 10);
        else if (typeof object.current_join_next_round === "number")
          message.current_join_next_round = object.current_join_next_round;
        else if (typeof object.current_join_next_round === "object")
          message.current_join_next_round = new $util.LongBits(
            object.current_join_next_round.low >>> 0,
            object.current_join_next_round.high >>> 0,
          ).toNumber();
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankResponseRankJackpotResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @static
     * @param {client_proto.AgentRankResponseRankJackpotResp} message AgentRankResponseRankJackpotResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankResponseRankJackpotResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.is_have_data = false;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.jackpot = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.last_round_jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.last_round_jackpot = options.longs === String ? "0" : 0;
        object.game_reward = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.current_join_next_round =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.current_join_next_round = options.longs === String ? "0" : 0;
        object.id = 0;
      }
      if (message.is_have_data != null && message.hasOwnProperty("is_have_data"))
        object.is_have_data = message.is_have_data;
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (typeof message.jackpot === "number")
          object.jackpot = options.longs === String ? String(message.jackpot) : message.jackpot;
        else
          object.jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.jackpot)
              : options.longs === Number
                ? new $util.LongBits(message.jackpot.low >>> 0, message.jackpot.high >>> 0).toNumber()
                : message.jackpot;
      if (message.last_round_jackpot != null && message.hasOwnProperty("last_round_jackpot"))
        if (typeof message.last_round_jackpot === "number")
          object.last_round_jackpot =
            options.longs === String ? String(message.last_round_jackpot) : message.last_round_jackpot;
        else
          object.last_round_jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.last_round_jackpot)
              : options.longs === Number
                ? new $util.LongBits(
                    message.last_round_jackpot.low >>> 0,
                    message.last_round_jackpot.high >>> 0,
                  ).toNumber()
                : message.last_round_jackpot;
      if (message.game_reward != null && message.hasOwnProperty("game_reward"))
        object.game_reward = message.game_reward;
      if (message.current_join_next_round != null && message.hasOwnProperty("current_join_next_round"))
        if (typeof message.current_join_next_round === "number")
          object.current_join_next_round =
            options.longs === String ? String(message.current_join_next_round) : message.current_join_next_round;
        else
          object.current_join_next_round =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.current_join_next_round)
              : options.longs === Number
                ? new $util.LongBits(
                    message.current_join_next_round.low >>> 0,
                    message.current_join_next_round.high >>> 0,
                  ).toNumber()
                : message.current_join_next_round;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this AgentRankResponseRankJackpotResp to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankResponseRankJackpotResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankResponseRankJackpotResp
     * @function getTypeUrl
     * @memberof client_proto.AgentRankResponseRankJackpotResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankResponseRankJackpotResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankResponseRankJackpotResp";
    };

    return AgentRankResponseRankJackpotResp;
  })();

  client_proto.AgentRankRequestJoinGameListReq = (function () {
    /**
     * Properties of an AgentRankRequestJoinGameListReq.
     * @memberof client_proto
     * @interface IAgentRankRequestJoinGameListReq
     * @property {number|null} [id] AgentRankRequestJoinGameListReq id
     */

    /**
     * Constructs a new AgentRankRequestJoinGameListReq.
     * @memberof client_proto
     * @classdesc Represents an AgentRankRequestJoinGameListReq.
     * @implements IAgentRankRequestJoinGameListReq
     * @constructor
     * @param {client_proto.IAgentRankRequestJoinGameListReq=} [properties] Properties to set
     */
    function AgentRankRequestJoinGameListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankRequestJoinGameListReq id.
     * @member {number} id
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @instance
     */
    AgentRankRequestJoinGameListReq.prototype.id = 0;

    /**
     * Creates a new AgentRankRequestJoinGameListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @static
     * @param {client_proto.IAgentRankRequestJoinGameListReq=} [properties] Properties to set
     * @returns {client_proto.AgentRankRequestJoinGameListReq} AgentRankRequestJoinGameListReq instance
     */
    AgentRankRequestJoinGameListReq.create = function create(properties) {
      return new AgentRankRequestJoinGameListReq(properties);
    };

    /**
     * Encodes the specified AgentRankRequestJoinGameListReq message. Does not implicitly {@link client_proto.AgentRankRequestJoinGameListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @static
     * @param {client_proto.IAgentRankRequestJoinGameListReq} message AgentRankRequestJoinGameListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestJoinGameListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified AgentRankRequestJoinGameListReq message, length delimited. Does not implicitly {@link client_proto.AgentRankRequestJoinGameListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @static
     * @param {client_proto.IAgentRankRequestJoinGameListReq} message AgentRankRequestJoinGameListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestJoinGameListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankRequestJoinGameListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankRequestJoinGameListReq} AgentRankRequestJoinGameListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestJoinGameListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankRequestJoinGameListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankRequestJoinGameListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankRequestJoinGameListReq} AgentRankRequestJoinGameListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestJoinGameListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankRequestJoinGameListReq message.
     * @function verify
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankRequestJoinGameListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankRequestJoinGameListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankRequestJoinGameListReq} AgentRankRequestJoinGameListReq
     */
    AgentRankRequestJoinGameListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankRequestJoinGameListReq) return object;
      var message = new $root.client_proto.AgentRankRequestJoinGameListReq();
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankRequestJoinGameListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @static
     * @param {client_proto.AgentRankRequestJoinGameListReq} message AgentRankRequestJoinGameListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankRequestJoinGameListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.id = 0;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this AgentRankRequestJoinGameListReq to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankRequestJoinGameListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankRequestJoinGameListReq
     * @function getTypeUrl
     * @memberof client_proto.AgentRankRequestJoinGameListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankRequestJoinGameListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankRequestJoinGameListReq";
    };

    return AgentRankRequestJoinGameListReq;
  })();

  client_proto.AgentRankResponseJoinGameListResp = (function () {
    /**
     * Properties of an AgentRankResponseJoinGameListResp.
     * @memberof client_proto
     * @interface IAgentRankResponseJoinGameListResp
     * @property {Array.<number>|null} [game_id_list] AgentRankResponseJoinGameListResp game_id_list
     * @property {number|null} [id] AgentRankResponseJoinGameListResp id
     * @property {Array.<number>|null} [company_id_list] AgentRankResponseJoinGameListResp company_id_list
     * @property {Array.<number>|null} [company_black_list] AgentRankResponseJoinGameListResp company_black_list
     * @property {Array.<number>|null} [game_black_list] AgentRankResponseJoinGameListResp game_black_list
     */

    /**
     * Constructs a new AgentRankResponseJoinGameListResp.
     * @memberof client_proto
     * @classdesc Represents an AgentRankResponseJoinGameListResp.
     * @implements IAgentRankResponseJoinGameListResp
     * @constructor
     * @param {client_proto.IAgentRankResponseJoinGameListResp=} [properties] Properties to set
     */
    function AgentRankResponseJoinGameListResp(properties) {
      this.game_id_list = [];
      this.company_id_list = [];
      this.company_black_list = [];
      this.game_black_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankResponseJoinGameListResp game_id_list.
     * @member {Array.<number>} game_id_list
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @instance
     */
    AgentRankResponseJoinGameListResp.prototype.game_id_list = $util.emptyArray;

    /**
     * AgentRankResponseJoinGameListResp id.
     * @member {number} id
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @instance
     */
    AgentRankResponseJoinGameListResp.prototype.id = 0;

    /**
     * AgentRankResponseJoinGameListResp company_id_list.
     * @member {Array.<number>} company_id_list
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @instance
     */
    AgentRankResponseJoinGameListResp.prototype.company_id_list = $util.emptyArray;

    /**
     * AgentRankResponseJoinGameListResp company_black_list.
     * @member {Array.<number>} company_black_list
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @instance
     */
    AgentRankResponseJoinGameListResp.prototype.company_black_list = $util.emptyArray;

    /**
     * AgentRankResponseJoinGameListResp game_black_list.
     * @member {Array.<number>} game_black_list
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @instance
     */
    AgentRankResponseJoinGameListResp.prototype.game_black_list = $util.emptyArray;

    /**
     * Creates a new AgentRankResponseJoinGameListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @static
     * @param {client_proto.IAgentRankResponseJoinGameListResp=} [properties] Properties to set
     * @returns {client_proto.AgentRankResponseJoinGameListResp} AgentRankResponseJoinGameListResp instance
     */
    AgentRankResponseJoinGameListResp.create = function create(properties) {
      return new AgentRankResponseJoinGameListResp(properties);
    };

    /**
     * Encodes the specified AgentRankResponseJoinGameListResp message. Does not implicitly {@link client_proto.AgentRankResponseJoinGameListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @static
     * @param {client_proto.IAgentRankResponseJoinGameListResp} message AgentRankResponseJoinGameListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseJoinGameListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_id_list != null && message.game_id_list.length) {
        writer.uint32(/* id 1, wireType 2 =*/ 10).fork();
        for (var i = 0; i < message.game_id_list.length; ++i) writer.uint32(message.game_id_list[i]);
        writer.ldelim();
      }
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.company_id_list != null && message.company_id_list.length) {
        writer.uint32(/* id 3, wireType 2 =*/ 26).fork();
        for (var i = 0; i < message.company_id_list.length; ++i) writer.uint32(message.company_id_list[i]);
        writer.ldelim();
      }
      if (message.company_black_list != null && message.company_black_list.length) {
        writer.uint32(/* id 4, wireType 2 =*/ 34).fork();
        for (var i = 0; i < message.company_black_list.length; ++i) writer.uint32(message.company_black_list[i]);
        writer.ldelim();
      }
      if (message.game_black_list != null && message.game_black_list.length) {
        writer.uint32(/* id 5, wireType 2 =*/ 42).fork();
        for (var i = 0; i < message.game_black_list.length; ++i) writer.uint32(message.game_black_list[i]);
        writer.ldelim();
      }
      return writer;
    };

    /**
     * Encodes the specified AgentRankResponseJoinGameListResp message, length delimited. Does not implicitly {@link client_proto.AgentRankResponseJoinGameListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @static
     * @param {client_proto.IAgentRankResponseJoinGameListResp} message AgentRankResponseJoinGameListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseJoinGameListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankResponseJoinGameListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankResponseJoinGameListResp} AgentRankResponseJoinGameListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseJoinGameListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankResponseJoinGameListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.game_id_list && message.game_id_list.length)) message.game_id_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.game_id_list.push(reader.uint32());
            } else message.game_id_list.push(reader.uint32());
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            if (!(message.company_id_list && message.company_id_list.length)) message.company_id_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.company_id_list.push(reader.uint32());
            } else message.company_id_list.push(reader.uint32());
            break;
          }
          case 4: {
            if (!(message.company_black_list && message.company_black_list.length)) message.company_black_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.company_black_list.push(reader.uint32());
            } else message.company_black_list.push(reader.uint32());
            break;
          }
          case 5: {
            if (!(message.game_black_list && message.game_black_list.length)) message.game_black_list = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.game_black_list.push(reader.uint32());
            } else message.game_black_list.push(reader.uint32());
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankResponseJoinGameListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankResponseJoinGameListResp} AgentRankResponseJoinGameListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseJoinGameListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankResponseJoinGameListResp message.
     * @function verify
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankResponseJoinGameListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_id_list != null && message.hasOwnProperty("game_id_list")) {
        if (!Array.isArray(message.game_id_list)) return "game_id_list: array expected";
        for (var i = 0; i < message.game_id_list.length; ++i)
          if (!$util.isInteger(message.game_id_list[i])) return "game_id_list: integer[] expected";
      }
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.company_id_list != null && message.hasOwnProperty("company_id_list")) {
        if (!Array.isArray(message.company_id_list)) return "company_id_list: array expected";
        for (var i = 0; i < message.company_id_list.length; ++i)
          if (!$util.isInteger(message.company_id_list[i])) return "company_id_list: integer[] expected";
      }
      if (message.company_black_list != null && message.hasOwnProperty("company_black_list")) {
        if (!Array.isArray(message.company_black_list)) return "company_black_list: array expected";
        for (var i = 0; i < message.company_black_list.length; ++i)
          if (!$util.isInteger(message.company_black_list[i])) return "company_black_list: integer[] expected";
      }
      if (message.game_black_list != null && message.hasOwnProperty("game_black_list")) {
        if (!Array.isArray(message.game_black_list)) return "game_black_list: array expected";
        for (var i = 0; i < message.game_black_list.length; ++i)
          if (!$util.isInteger(message.game_black_list[i])) return "game_black_list: integer[] expected";
      }
      return null;
    };

    /**
     * Creates an AgentRankResponseJoinGameListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankResponseJoinGameListResp} AgentRankResponseJoinGameListResp
     */
    AgentRankResponseJoinGameListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankResponseJoinGameListResp) return object;
      var message = new $root.client_proto.AgentRankResponseJoinGameListResp();
      if (object.game_id_list) {
        if (!Array.isArray(object.game_id_list))
          throw TypeError(".client_proto.AgentRankResponseJoinGameListResp.game_id_list: array expected");
        message.game_id_list = [];
        for (var i = 0; i < object.game_id_list.length; ++i) message.game_id_list[i] = object.game_id_list[i] >>> 0;
      }
      if (object.id != null) message.id = object.id | 0;
      if (object.company_id_list) {
        if (!Array.isArray(object.company_id_list))
          throw TypeError(".client_proto.AgentRankResponseJoinGameListResp.company_id_list: array expected");
        message.company_id_list = [];
        for (var i = 0; i < object.company_id_list.length; ++i)
          message.company_id_list[i] = object.company_id_list[i] >>> 0;
      }
      if (object.company_black_list) {
        if (!Array.isArray(object.company_black_list))
          throw TypeError(".client_proto.AgentRankResponseJoinGameListResp.company_black_list: array expected");
        message.company_black_list = [];
        for (var i = 0; i < object.company_black_list.length; ++i)
          message.company_black_list[i] = object.company_black_list[i] >>> 0;
      }
      if (object.game_black_list) {
        if (!Array.isArray(object.game_black_list))
          throw TypeError(".client_proto.AgentRankResponseJoinGameListResp.game_black_list: array expected");
        message.game_black_list = [];
        for (var i = 0; i < object.game_black_list.length; ++i)
          message.game_black_list[i] = object.game_black_list[i] >>> 0;
      }
      return message;
    };

    /**
     * Creates a plain object from an AgentRankResponseJoinGameListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @static
     * @param {client_proto.AgentRankResponseJoinGameListResp} message AgentRankResponseJoinGameListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankResponseJoinGameListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) {
        object.game_id_list = [];
        object.company_id_list = [];
        object.company_black_list = [];
        object.game_black_list = [];
      }
      if (options.defaults) object.id = 0;
      if (message.game_id_list && message.game_id_list.length) {
        object.game_id_list = [];
        for (var j = 0; j < message.game_id_list.length; ++j) object.game_id_list[j] = message.game_id_list[j];
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.company_id_list && message.company_id_list.length) {
        object.company_id_list = [];
        for (var j = 0; j < message.company_id_list.length; ++j) object.company_id_list[j] = message.company_id_list[j];
      }
      if (message.company_black_list && message.company_black_list.length) {
        object.company_black_list = [];
        for (var j = 0; j < message.company_black_list.length; ++j)
          object.company_black_list[j] = message.company_black_list[j];
      }
      if (message.game_black_list && message.game_black_list.length) {
        object.game_black_list = [];
        for (var j = 0; j < message.game_black_list.length; ++j) object.game_black_list[j] = message.game_black_list[j];
      }
      return object;
    };

    /**
     * Converts this AgentRankResponseJoinGameListResp to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankResponseJoinGameListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankResponseJoinGameListResp
     * @function getTypeUrl
     * @memberof client_proto.AgentRankResponseJoinGameListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankResponseJoinGameListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankResponseJoinGameListResp";
    };

    return AgentRankResponseJoinGameListResp;
  })();

  client_proto.AgentRankRequestRewardListReq = (function () {
    /**
     * Properties of an AgentRankRequestRewardListReq.
     * @memberof client_proto
     * @interface IAgentRankRequestRewardListReq
     * @property {number|null} [uid] AgentRankRequestRewardListReq uid
     */

    /**
     * Constructs a new AgentRankRequestRewardListReq.
     * @memberof client_proto
     * @classdesc Represents an AgentRankRequestRewardListReq.
     * @implements IAgentRankRequestRewardListReq
     * @constructor
     * @param {client_proto.IAgentRankRequestRewardListReq=} [properties] Properties to set
     */
    function AgentRankRequestRewardListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankRequestRewardListReq uid.
     * @member {number} uid
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @instance
     */
    AgentRankRequestRewardListReq.prototype.uid = 0;

    /**
     * Creates a new AgentRankRequestRewardListReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @static
     * @param {client_proto.IAgentRankRequestRewardListReq=} [properties] Properties to set
     * @returns {client_proto.AgentRankRequestRewardListReq} AgentRankRequestRewardListReq instance
     */
    AgentRankRequestRewardListReq.create = function create(properties) {
      return new AgentRankRequestRewardListReq(properties);
    };

    /**
     * Encodes the specified AgentRankRequestRewardListReq message. Does not implicitly {@link client_proto.AgentRankRequestRewardListReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @static
     * @param {client_proto.IAgentRankRequestRewardListReq} message AgentRankRequestRewardListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestRewardListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified AgentRankRequestRewardListReq message, length delimited. Does not implicitly {@link client_proto.AgentRankRequestRewardListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @static
     * @param {client_proto.IAgentRankRequestRewardListReq} message AgentRankRequestRewardListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestRewardListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankRequestRewardListReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankRequestRewardListReq} AgentRankRequestRewardListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestRewardListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankRequestRewardListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankRequestRewardListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankRequestRewardListReq} AgentRankRequestRewardListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestRewardListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankRequestRewardListReq message.
     * @function verify
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankRequestRewardListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankRequestRewardListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankRequestRewardListReq} AgentRankRequestRewardListReq
     */
    AgentRankRequestRewardListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankRequestRewardListReq) return object;
      var message = new $root.client_proto.AgentRankRequestRewardListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankRequestRewardListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @static
     * @param {client_proto.AgentRankRequestRewardListReq} message AgentRankRequestRewardListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankRequestRewardListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this AgentRankRequestRewardListReq to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankRequestRewardListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankRequestRewardListReq
     * @function getTypeUrl
     * @memberof client_proto.AgentRankRequestRewardListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankRequestRewardListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankRequestRewardListReq";
    };

    return AgentRankRequestRewardListReq;
  })();

  client_proto.AgentRankRewardData = (function () {
    /**
     * Properties of an AgentRankRewardData.
     * @memberof client_proto
     * @interface IAgentRankRewardData
     * @property {number|null} [id] AgentRankRewardData id
     * @property {number|null} [rank_list_type] AgentRankRewardData rank_list_type
     * @property {number|null} [reward_time] AgentRankRewardData reward_time
     * @property {number|Long|null} [jackpot] AgentRankRewardData jackpot
     * @property {number|null} [reward] AgentRankRewardData reward
     * @property {number|null} [rank] AgentRankRewardData rank
     * @property {number|Long|null} [money] AgentRankRewardData money
     * @property {number|null} [state] AgentRankRewardData state
     * @property {number|Long|null} [reward_money] AgentRankRewardData reward_money
     * @property {number|Long|null} [reward_id] AgentRankRewardData reward_id
     * @property {number|null} [reward_money_type] AgentRankRewardData reward_money_type
     * @property {number|null} [rank_start_time] AgentRankRewardData rank_start_time
     * @property {number|null} [rank_end_time] AgentRankRewardData rank_end_time
     * @property {string|null} [name] AgentRankRewardData name
     */

    /**
     * Constructs a new AgentRankRewardData.
     * @memberof client_proto
     * @classdesc Represents an AgentRankRewardData.
     * @implements IAgentRankRewardData
     * @constructor
     * @param {client_proto.IAgentRankRewardData=} [properties] Properties to set
     */
    function AgentRankRewardData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankRewardData id.
     * @member {number} id
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.id = 0;

    /**
     * AgentRankRewardData rank_list_type.
     * @member {number} rank_list_type
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.rank_list_type = 0;

    /**
     * AgentRankRewardData reward_time.
     * @member {number} reward_time
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.reward_time = 0;

    /**
     * AgentRankRewardData jackpot.
     * @member {number|Long} jackpot
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankRewardData reward.
     * @member {number} reward
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.reward = 0;

    /**
     * AgentRankRewardData rank.
     * @member {number} rank
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.rank = 0;

    /**
     * AgentRankRewardData money.
     * @member {number|Long} money
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankRewardData state.
     * @member {number} state
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.state = 0;

    /**
     * AgentRankRewardData reward_money.
     * @member {number|Long} reward_money
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.reward_money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankRewardData reward_id.
     * @member {number|Long} reward_id
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.reward_id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankRewardData reward_money_type.
     * @member {number} reward_money_type
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.reward_money_type = 0;

    /**
     * AgentRankRewardData rank_start_time.
     * @member {number} rank_start_time
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.rank_start_time = 0;

    /**
     * AgentRankRewardData rank_end_time.
     * @member {number} rank_end_time
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.rank_end_time = 0;

    /**
     * AgentRankRewardData name.
     * @member {string} name
     * @memberof client_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.name = "";

    /**
     * Creates a new AgentRankRewardData instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankRewardData
     * @static
     * @param {client_proto.IAgentRankRewardData=} [properties] Properties to set
     * @returns {client_proto.AgentRankRewardData} AgentRankRewardData instance
     */
    AgentRankRewardData.create = function create(properties) {
      return new AgentRankRewardData(properties);
    };

    /**
     * Encodes the specified AgentRankRewardData message. Does not implicitly {@link client_proto.AgentRankRewardData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankRewardData
     * @static
     * @param {client_proto.IAgentRankRewardData} message AgentRankRewardData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRewardData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.id);
      if (message.rank_list_type != null && Object.hasOwnProperty.call(message, "rank_list_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.rank_list_type);
      if (message.reward_time != null && Object.hasOwnProperty.call(message, "reward_time"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.reward_time);
      if (message.jackpot != null && Object.hasOwnProperty.call(message, "jackpot"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.jackpot);
      if (message.reward != null && Object.hasOwnProperty.call(message, "reward"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.reward);
      if (message.rank != null && Object.hasOwnProperty.call(message, "rank"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.rank);
      if (message.money != null && Object.hasOwnProperty.call(message, "money"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.money);
      if (message.state != null && Object.hasOwnProperty.call(message, "state"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.state);
      if (message.reward_money != null && Object.hasOwnProperty.call(message, "reward_money"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.reward_money);
      if (message.reward_id != null && Object.hasOwnProperty.call(message, "reward_id"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.reward_id);
      if (message.reward_money_type != null && Object.hasOwnProperty.call(message, "reward_money_type"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int32(message.reward_money_type);
      if (message.rank_start_time != null && Object.hasOwnProperty.call(message, "rank_start_time"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).uint32(message.rank_start_time);
      if (message.rank_end_time != null && Object.hasOwnProperty.call(message, "rank_end_time"))
        writer.uint32(/* id 13, wireType 0 =*/ 104).uint32(message.rank_end_time);
      if (message.name != null && Object.hasOwnProperty.call(message, "name"))
        writer.uint32(/* id 14, wireType 2 =*/ 114).string(message.name);
      return writer;
    };

    /**
     * Encodes the specified AgentRankRewardData message, length delimited. Does not implicitly {@link client_proto.AgentRankRewardData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankRewardData
     * @static
     * @param {client_proto.IAgentRankRewardData} message AgentRankRewardData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRewardData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankRewardData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankRewardData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankRewardData} AgentRankRewardData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRewardData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankRewardData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.id = reader.int32();
            break;
          }
          case 2: {
            message.rank_list_type = reader.int32();
            break;
          }
          case 3: {
            message.reward_time = reader.uint32();
            break;
          }
          case 4: {
            message.jackpot = reader.int64();
            break;
          }
          case 5: {
            message.reward = reader.int32();
            break;
          }
          case 6: {
            message.rank = reader.int32();
            break;
          }
          case 7: {
            message.money = reader.int64();
            break;
          }
          case 8: {
            message.state = reader.int32();
            break;
          }
          case 9: {
            message.reward_money = reader.int64();
            break;
          }
          case 10: {
            message.reward_id = reader.int64();
            break;
          }
          case 11: {
            message.reward_money_type = reader.int32();
            break;
          }
          case 12: {
            message.rank_start_time = reader.uint32();
            break;
          }
          case 13: {
            message.rank_end_time = reader.uint32();
            break;
          }
          case 14: {
            message.name = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankRewardData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankRewardData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankRewardData} AgentRankRewardData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRewardData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankRewardData message.
     * @function verify
     * @memberof client_proto.AgentRankRewardData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankRewardData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.rank_list_type != null && message.hasOwnProperty("rank_list_type"))
        if (!$util.isInteger(message.rank_list_type)) return "rank_list_type: integer expected";
      if (message.reward_time != null && message.hasOwnProperty("reward_time"))
        if (!$util.isInteger(message.reward_time)) return "reward_time: integer expected";
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (
          !$util.isInteger(message.jackpot) &&
          !(message.jackpot && $util.isInteger(message.jackpot.low) && $util.isInteger(message.jackpot.high))
        )
          return "jackpot: integer|Long expected";
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (!$util.isInteger(message.reward)) return "reward: integer expected";
      if (message.rank != null && message.hasOwnProperty("rank"))
        if (!$util.isInteger(message.rank)) return "rank: integer expected";
      if (message.money != null && message.hasOwnProperty("money"))
        if (
          !$util.isInteger(message.money) &&
          !(message.money && $util.isInteger(message.money.low) && $util.isInteger(message.money.high))
        )
          return "money: integer|Long expected";
      if (message.state != null && message.hasOwnProperty("state"))
        if (!$util.isInteger(message.state)) return "state: integer expected";
      if (message.reward_money != null && message.hasOwnProperty("reward_money"))
        if (
          !$util.isInteger(message.reward_money) &&
          !(
            message.reward_money &&
            $util.isInteger(message.reward_money.low) &&
            $util.isInteger(message.reward_money.high)
          )
        )
          return "reward_money: integer|Long expected";
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (
          !$util.isInteger(message.reward_id) &&
          !(message.reward_id && $util.isInteger(message.reward_id.low) && $util.isInteger(message.reward_id.high))
        )
          return "reward_id: integer|Long expected";
      if (message.reward_money_type != null && message.hasOwnProperty("reward_money_type"))
        if (!$util.isInteger(message.reward_money_type)) return "reward_money_type: integer expected";
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        if (!$util.isInteger(message.rank_start_time)) return "rank_start_time: integer expected";
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        if (!$util.isInteger(message.rank_end_time)) return "rank_end_time: integer expected";
      if (message.name != null && message.hasOwnProperty("name"))
        if (!$util.isString(message.name)) return "name: string expected";
      return null;
    };

    /**
     * Creates an AgentRankRewardData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankRewardData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankRewardData} AgentRankRewardData
     */
    AgentRankRewardData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankRewardData) return object;
      var message = new $root.client_proto.AgentRankRewardData();
      if (object.id != null) message.id = object.id | 0;
      if (object.rank_list_type != null) message.rank_list_type = object.rank_list_type | 0;
      if (object.reward_time != null) message.reward_time = object.reward_time >>> 0;
      if (object.jackpot != null)
        if ($util.Long) (message.jackpot = $util.Long.fromValue(object.jackpot)).unsigned = false;
        else if (typeof object.jackpot === "string") message.jackpot = parseInt(object.jackpot, 10);
        else if (typeof object.jackpot === "number") message.jackpot = object.jackpot;
        else if (typeof object.jackpot === "object")
          message.jackpot = new $util.LongBits(object.jackpot.low >>> 0, object.jackpot.high >>> 0).toNumber();
      if (object.reward != null) message.reward = object.reward | 0;
      if (object.rank != null) message.rank = object.rank | 0;
      if (object.money != null)
        if ($util.Long) (message.money = $util.Long.fromValue(object.money)).unsigned = false;
        else if (typeof object.money === "string") message.money = parseInt(object.money, 10);
        else if (typeof object.money === "number") message.money = object.money;
        else if (typeof object.money === "object")
          message.money = new $util.LongBits(object.money.low >>> 0, object.money.high >>> 0).toNumber();
      if (object.state != null) message.state = object.state | 0;
      if (object.reward_money != null)
        if ($util.Long) (message.reward_money = $util.Long.fromValue(object.reward_money)).unsigned = false;
        else if (typeof object.reward_money === "string") message.reward_money = parseInt(object.reward_money, 10);
        else if (typeof object.reward_money === "number") message.reward_money = object.reward_money;
        else if (typeof object.reward_money === "object")
          message.reward_money = new $util.LongBits(
            object.reward_money.low >>> 0,
            object.reward_money.high >>> 0,
          ).toNumber();
      if (object.reward_id != null)
        if ($util.Long) (message.reward_id = $util.Long.fromValue(object.reward_id)).unsigned = false;
        else if (typeof object.reward_id === "string") message.reward_id = parseInt(object.reward_id, 10);
        else if (typeof object.reward_id === "number") message.reward_id = object.reward_id;
        else if (typeof object.reward_id === "object")
          message.reward_id = new $util.LongBits(object.reward_id.low >>> 0, object.reward_id.high >>> 0).toNumber();
      if (object.reward_money_type != null) message.reward_money_type = object.reward_money_type | 0;
      if (object.rank_start_time != null) message.rank_start_time = object.rank_start_time >>> 0;
      if (object.rank_end_time != null) message.rank_end_time = object.rank_end_time >>> 0;
      if (object.name != null) message.name = String(object.name);
      return message;
    };

    /**
     * Creates a plain object from an AgentRankRewardData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankRewardData
     * @static
     * @param {client_proto.AgentRankRewardData} message AgentRankRewardData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankRewardData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.id = 0;
        object.rank_list_type = 0;
        object.reward_time = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.jackpot = options.longs === String ? "0" : 0;
        object.reward = 0;
        object.rank = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.money = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.money = options.longs === String ? "0" : 0;
        object.state = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_money =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_money = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_id =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_id = options.longs === String ? "0" : 0;
        object.reward_money_type = 0;
        object.rank_start_time = 0;
        object.rank_end_time = 0;
        object.name = "";
      }
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.rank_list_type != null && message.hasOwnProperty("rank_list_type"))
        object.rank_list_type = message.rank_list_type;
      if (message.reward_time != null && message.hasOwnProperty("reward_time"))
        object.reward_time = message.reward_time;
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (typeof message.jackpot === "number")
          object.jackpot = options.longs === String ? String(message.jackpot) : message.jackpot;
        else
          object.jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.jackpot)
              : options.longs === Number
                ? new $util.LongBits(message.jackpot.low >>> 0, message.jackpot.high >>> 0).toNumber()
                : message.jackpot;
      if (message.reward != null && message.hasOwnProperty("reward")) object.reward = message.reward;
      if (message.rank != null && message.hasOwnProperty("rank")) object.rank = message.rank;
      if (message.money != null && message.hasOwnProperty("money"))
        if (typeof message.money === "number")
          object.money = options.longs === String ? String(message.money) : message.money;
        else
          object.money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.money)
              : options.longs === Number
                ? new $util.LongBits(message.money.low >>> 0, message.money.high >>> 0).toNumber()
                : message.money;
      if (message.state != null && message.hasOwnProperty("state")) object.state = message.state;
      if (message.reward_money != null && message.hasOwnProperty("reward_money"))
        if (typeof message.reward_money === "number")
          object.reward_money = options.longs === String ? String(message.reward_money) : message.reward_money;
        else
          object.reward_money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_money)
              : options.longs === Number
                ? new $util.LongBits(message.reward_money.low >>> 0, message.reward_money.high >>> 0).toNumber()
                : message.reward_money;
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (typeof message.reward_id === "number")
          object.reward_id = options.longs === String ? String(message.reward_id) : message.reward_id;
        else
          object.reward_id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_id)
              : options.longs === Number
                ? new $util.LongBits(message.reward_id.low >>> 0, message.reward_id.high >>> 0).toNumber()
                : message.reward_id;
      if (message.reward_money_type != null && message.hasOwnProperty("reward_money_type"))
        object.reward_money_type = message.reward_money_type;
      if (message.rank_start_time != null && message.hasOwnProperty("rank_start_time"))
        object.rank_start_time = message.rank_start_time;
      if (message.rank_end_time != null && message.hasOwnProperty("rank_end_time"))
        object.rank_end_time = message.rank_end_time;
      if (message.name != null && message.hasOwnProperty("name")) object.name = message.name;
      return object;
    };

    /**
     * Converts this AgentRankRewardData to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankRewardData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankRewardData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankRewardData
     * @function getTypeUrl
     * @memberof client_proto.AgentRankRewardData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankRewardData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankRewardData";
    };

    return AgentRankRewardData;
  })();

  client_proto.AgentRankResponseRewardListResp = (function () {
    /**
     * Properties of an AgentRankResponseRewardListResp.
     * @memberof client_proto
     * @interface IAgentRankResponseRewardListResp
     * @property {Array.<client_proto.IAgentRankRewardData>|null} [reward_list] AgentRankResponseRewardListResp reward_list
     * @property {number|null} [uid] AgentRankResponseRewardListResp uid
     */

    /**
     * Constructs a new AgentRankResponseRewardListResp.
     * @memberof client_proto
     * @classdesc Represents an AgentRankResponseRewardListResp.
     * @implements IAgentRankResponseRewardListResp
     * @constructor
     * @param {client_proto.IAgentRankResponseRewardListResp=} [properties] Properties to set
     */
    function AgentRankResponseRewardListResp(properties) {
      this.reward_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankResponseRewardListResp reward_list.
     * @member {Array.<client_proto.IAgentRankRewardData>} reward_list
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @instance
     */
    AgentRankResponseRewardListResp.prototype.reward_list = $util.emptyArray;

    /**
     * AgentRankResponseRewardListResp uid.
     * @member {number} uid
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @instance
     */
    AgentRankResponseRewardListResp.prototype.uid = 0;

    /**
     * Creates a new AgentRankResponseRewardListResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @static
     * @param {client_proto.IAgentRankResponseRewardListResp=} [properties] Properties to set
     * @returns {client_proto.AgentRankResponseRewardListResp} AgentRankResponseRewardListResp instance
     */
    AgentRankResponseRewardListResp.create = function create(properties) {
      return new AgentRankResponseRewardListResp(properties);
    };

    /**
     * Encodes the specified AgentRankResponseRewardListResp message. Does not implicitly {@link client_proto.AgentRankResponseRewardListResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @static
     * @param {client_proto.IAgentRankResponseRewardListResp} message AgentRankResponseRewardListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseRewardListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.reward_list != null && message.reward_list.length)
        for (var i = 0; i < message.reward_list.length; ++i)
          $root.client_proto.AgentRankRewardData.encode(
            message.reward_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified AgentRankResponseRewardListResp message, length delimited. Does not implicitly {@link client_proto.AgentRankResponseRewardListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @static
     * @param {client_proto.IAgentRankResponseRewardListResp} message AgentRankResponseRewardListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseRewardListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankResponseRewardListResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankResponseRewardListResp} AgentRankResponseRewardListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseRewardListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankResponseRewardListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.reward_list && message.reward_list.length)) message.reward_list = [];
            message.reward_list.push($root.client_proto.AgentRankRewardData.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankResponseRewardListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankResponseRewardListResp} AgentRankResponseRewardListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseRewardListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankResponseRewardListResp message.
     * @function verify
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankResponseRewardListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.reward_list != null && message.hasOwnProperty("reward_list")) {
        if (!Array.isArray(message.reward_list)) return "reward_list: array expected";
        for (var i = 0; i < message.reward_list.length; ++i) {
          var error = $root.client_proto.AgentRankRewardData.verify(message.reward_list[i]);
          if (error) return "reward_list." + error;
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankResponseRewardListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankResponseRewardListResp} AgentRankResponseRewardListResp
     */
    AgentRankResponseRewardListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankResponseRewardListResp) return object;
      var message = new $root.client_proto.AgentRankResponseRewardListResp();
      if (object.reward_list) {
        if (!Array.isArray(object.reward_list))
          throw TypeError(".client_proto.AgentRankResponseRewardListResp.reward_list: array expected");
        message.reward_list = [];
        for (var i = 0; i < object.reward_list.length; ++i) {
          if (typeof object.reward_list[i] !== "object")
            throw TypeError(".client_proto.AgentRankResponseRewardListResp.reward_list: object expected");
          message.reward_list[i] = $root.client_proto.AgentRankRewardData.fromObject(object.reward_list[i]);
        }
      }
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankResponseRewardListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @static
     * @param {client_proto.AgentRankResponseRewardListResp} message AgentRankResponseRewardListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankResponseRewardListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.reward_list = [];
      if (options.defaults) object.uid = 0;
      if (message.reward_list && message.reward_list.length) {
        object.reward_list = [];
        for (var j = 0; j < message.reward_list.length; ++j)
          object.reward_list[j] = $root.client_proto.AgentRankRewardData.toObject(message.reward_list[j], options);
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this AgentRankResponseRewardListResp to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankResponseRewardListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankResponseRewardListResp
     * @function getTypeUrl
     * @memberof client_proto.AgentRankResponseRewardListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankResponseRewardListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankResponseRewardListResp";
    };

    return AgentRankResponseRewardListResp;
  })();

  client_proto.AgentRankRequestGetRewardReq = (function () {
    /**
     * Properties of an AgentRankRequestGetRewardReq.
     * @memberof client_proto
     * @interface IAgentRankRequestGetRewardReq
     * @property {number|null} [uid] AgentRankRequestGetRewardReq uid
     * @property {number|Long|null} [reward_id] AgentRankRequestGetRewardReq reward_id
     */

    /**
     * Constructs a new AgentRankRequestGetRewardReq.
     * @memberof client_proto
     * @classdesc Represents an AgentRankRequestGetRewardReq.
     * @implements IAgentRankRequestGetRewardReq
     * @constructor
     * @param {client_proto.IAgentRankRequestGetRewardReq=} [properties] Properties to set
     */
    function AgentRankRequestGetRewardReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankRequestGetRewardReq uid.
     * @member {number} uid
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @instance
     */
    AgentRankRequestGetRewardReq.prototype.uid = 0;

    /**
     * AgentRankRequestGetRewardReq reward_id.
     * @member {number|Long} reward_id
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @instance
     */
    AgentRankRequestGetRewardReq.prototype.reward_id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new AgentRankRequestGetRewardReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @static
     * @param {client_proto.IAgentRankRequestGetRewardReq=} [properties] Properties to set
     * @returns {client_proto.AgentRankRequestGetRewardReq} AgentRankRequestGetRewardReq instance
     */
    AgentRankRequestGetRewardReq.create = function create(properties) {
      return new AgentRankRequestGetRewardReq(properties);
    };

    /**
     * Encodes the specified AgentRankRequestGetRewardReq message. Does not implicitly {@link client_proto.AgentRankRequestGetRewardReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @static
     * @param {client_proto.IAgentRankRequestGetRewardReq} message AgentRankRequestGetRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestGetRewardReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.reward_id != null && Object.hasOwnProperty.call(message, "reward_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.reward_id);
      return writer;
    };

    /**
     * Encodes the specified AgentRankRequestGetRewardReq message, length delimited. Does not implicitly {@link client_proto.AgentRankRequestGetRewardReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @static
     * @param {client_proto.IAgentRankRequestGetRewardReq} message AgentRankRequestGetRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestGetRewardReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankRequestGetRewardReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankRequestGetRewardReq} AgentRankRequestGetRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestGetRewardReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankRequestGetRewardReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.reward_id = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankRequestGetRewardReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankRequestGetRewardReq} AgentRankRequestGetRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestGetRewardReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankRequestGetRewardReq message.
     * @function verify
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankRequestGetRewardReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (
          !$util.isInteger(message.reward_id) &&
          !(message.reward_id && $util.isInteger(message.reward_id.low) && $util.isInteger(message.reward_id.high))
        )
          return "reward_id: integer|Long expected";
      return null;
    };

    /**
     * Creates an AgentRankRequestGetRewardReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankRequestGetRewardReq} AgentRankRequestGetRewardReq
     */
    AgentRankRequestGetRewardReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankRequestGetRewardReq) return object;
      var message = new $root.client_proto.AgentRankRequestGetRewardReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.reward_id != null)
        if ($util.Long) (message.reward_id = $util.Long.fromValue(object.reward_id)).unsigned = false;
        else if (typeof object.reward_id === "string") message.reward_id = parseInt(object.reward_id, 10);
        else if (typeof object.reward_id === "number") message.reward_id = object.reward_id;
        else if (typeof object.reward_id === "object")
          message.reward_id = new $util.LongBits(object.reward_id.low >>> 0, object.reward_id.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from an AgentRankRequestGetRewardReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @static
     * @param {client_proto.AgentRankRequestGetRewardReq} message AgentRankRequestGetRewardReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankRequestGetRewardReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_id =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_id = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (typeof message.reward_id === "number")
          object.reward_id = options.longs === String ? String(message.reward_id) : message.reward_id;
        else
          object.reward_id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_id)
              : options.longs === Number
                ? new $util.LongBits(message.reward_id.low >>> 0, message.reward_id.high >>> 0).toNumber()
                : message.reward_id;
      return object;
    };

    /**
     * Converts this AgentRankRequestGetRewardReq to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankRequestGetRewardReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankRequestGetRewardReq
     * @function getTypeUrl
     * @memberof client_proto.AgentRankRequestGetRewardReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankRequestGetRewardReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankRequestGetRewardReq";
    };

    return AgentRankRequestGetRewardReq;
  })();

  client_proto.AgentRankResponseGetRewardResp = (function () {
    /**
     * Properties of an AgentRankResponseGetRewardResp.
     * @memberof client_proto
     * @interface IAgentRankResponseGetRewardResp
     * @property {number|null} [uid] AgentRankResponseGetRewardResp uid
     * @property {number|Long|null} [reward_id] AgentRankResponseGetRewardResp reward_id
     * @property {number|null} [state] AgentRankResponseGetRewardResp state
     * @property {number|null} [error_code] AgentRankResponseGetRewardResp error_code
     */

    /**
     * Constructs a new AgentRankResponseGetRewardResp.
     * @memberof client_proto
     * @classdesc Represents an AgentRankResponseGetRewardResp.
     * @implements IAgentRankResponseGetRewardResp
     * @constructor
     * @param {client_proto.IAgentRankResponseGetRewardResp=} [properties] Properties to set
     */
    function AgentRankResponseGetRewardResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankResponseGetRewardResp uid.
     * @member {number} uid
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @instance
     */
    AgentRankResponseGetRewardResp.prototype.uid = 0;

    /**
     * AgentRankResponseGetRewardResp reward_id.
     * @member {number|Long} reward_id
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @instance
     */
    AgentRankResponseGetRewardResp.prototype.reward_id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankResponseGetRewardResp state.
     * @member {number} state
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @instance
     */
    AgentRankResponseGetRewardResp.prototype.state = 0;

    /**
     * AgentRankResponseGetRewardResp error_code.
     * @member {number} error_code
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @instance
     */
    AgentRankResponseGetRewardResp.prototype.error_code = 0;

    /**
     * Creates a new AgentRankResponseGetRewardResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @static
     * @param {client_proto.IAgentRankResponseGetRewardResp=} [properties] Properties to set
     * @returns {client_proto.AgentRankResponseGetRewardResp} AgentRankResponseGetRewardResp instance
     */
    AgentRankResponseGetRewardResp.create = function create(properties) {
      return new AgentRankResponseGetRewardResp(properties);
    };

    /**
     * Encodes the specified AgentRankResponseGetRewardResp message. Does not implicitly {@link client_proto.AgentRankResponseGetRewardResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @static
     * @param {client_proto.IAgentRankResponseGetRewardResp} message AgentRankResponseGetRewardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseGetRewardResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.reward_id != null && Object.hasOwnProperty.call(message, "reward_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.reward_id);
      if (message.state != null && Object.hasOwnProperty.call(message, "state"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.state);
      if (message.error_code != null && Object.hasOwnProperty.call(message, "error_code"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.error_code);
      return writer;
    };

    /**
     * Encodes the specified AgentRankResponseGetRewardResp message, length delimited. Does not implicitly {@link client_proto.AgentRankResponseGetRewardResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @static
     * @param {client_proto.IAgentRankResponseGetRewardResp} message AgentRankResponseGetRewardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseGetRewardResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankResponseGetRewardResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankResponseGetRewardResp} AgentRankResponseGetRewardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseGetRewardResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankResponseGetRewardResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.reward_id = reader.int64();
            break;
          }
          case 3: {
            message.state = reader.int32();
            break;
          }
          case 4: {
            message.error_code = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankResponseGetRewardResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankResponseGetRewardResp} AgentRankResponseGetRewardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseGetRewardResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankResponseGetRewardResp message.
     * @function verify
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankResponseGetRewardResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (
          !$util.isInteger(message.reward_id) &&
          !(message.reward_id && $util.isInteger(message.reward_id.low) && $util.isInteger(message.reward_id.high))
        )
          return "reward_id: integer|Long expected";
      if (message.state != null && message.hasOwnProperty("state"))
        if (!$util.isInteger(message.state)) return "state: integer expected";
      if (message.error_code != null && message.hasOwnProperty("error_code"))
        if (!$util.isInteger(message.error_code)) return "error_code: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankResponseGetRewardResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankResponseGetRewardResp} AgentRankResponseGetRewardResp
     */
    AgentRankResponseGetRewardResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankResponseGetRewardResp) return object;
      var message = new $root.client_proto.AgentRankResponseGetRewardResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.reward_id != null)
        if ($util.Long) (message.reward_id = $util.Long.fromValue(object.reward_id)).unsigned = false;
        else if (typeof object.reward_id === "string") message.reward_id = parseInt(object.reward_id, 10);
        else if (typeof object.reward_id === "number") message.reward_id = object.reward_id;
        else if (typeof object.reward_id === "object")
          message.reward_id = new $util.LongBits(object.reward_id.low >>> 0, object.reward_id.high >>> 0).toNumber();
      if (object.state != null) message.state = object.state | 0;
      if (object.error_code != null) message.error_code = object.error_code | 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankResponseGetRewardResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @static
     * @param {client_proto.AgentRankResponseGetRewardResp} message AgentRankResponseGetRewardResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankResponseGetRewardResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_id =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_id = options.longs === String ? "0" : 0;
        object.state = 0;
        object.error_code = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (typeof message.reward_id === "number")
          object.reward_id = options.longs === String ? String(message.reward_id) : message.reward_id;
        else
          object.reward_id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_id)
              : options.longs === Number
                ? new $util.LongBits(message.reward_id.low >>> 0, message.reward_id.high >>> 0).toNumber()
                : message.reward_id;
      if (message.state != null && message.hasOwnProperty("state")) object.state = message.state;
      if (message.error_code != null && message.hasOwnProperty("error_code")) object.error_code = message.error_code;
      return object;
    };

    /**
     * Converts this AgentRankResponseGetRewardResp to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankResponseGetRewardResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankResponseGetRewardResp
     * @function getTypeUrl
     * @memberof client_proto.AgentRankResponseGetRewardResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankResponseGetRewardResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankResponseGetRewardResp";
    };

    return AgentRankResponseGetRewardResp;
  })();

  client_proto.AgentRankGetUserDetailsReq = (function () {
    /**
     * Properties of an AgentRankGetUserDetailsReq.
     * @memberof client_proto
     * @interface IAgentRankGetUserDetailsReq
     * @property {number|null} [uid] AgentRankGetUserDetailsReq uid
     * @property {number|null} [id] AgentRankGetUserDetailsReq id
     * @property {boolean|null} [is_history] AgentRankGetUserDetailsReq is_history
     */

    /**
     * Constructs a new AgentRankGetUserDetailsReq.
     * @memberof client_proto
     * @classdesc Represents an AgentRankGetUserDetailsReq.
     * @implements IAgentRankGetUserDetailsReq
     * @constructor
     * @param {client_proto.IAgentRankGetUserDetailsReq=} [properties] Properties to set
     */
    function AgentRankGetUserDetailsReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankGetUserDetailsReq uid.
     * @member {number} uid
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @instance
     */
    AgentRankGetUserDetailsReq.prototype.uid = 0;

    /**
     * AgentRankGetUserDetailsReq id.
     * @member {number} id
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @instance
     */
    AgentRankGetUserDetailsReq.prototype.id = 0;

    /**
     * AgentRankGetUserDetailsReq is_history.
     * @member {boolean} is_history
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @instance
     */
    AgentRankGetUserDetailsReq.prototype.is_history = false;

    /**
     * Creates a new AgentRankGetUserDetailsReq instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @static
     * @param {client_proto.IAgentRankGetUserDetailsReq=} [properties] Properties to set
     * @returns {client_proto.AgentRankGetUserDetailsReq} AgentRankGetUserDetailsReq instance
     */
    AgentRankGetUserDetailsReq.create = function create(properties) {
      return new AgentRankGetUserDetailsReq(properties);
    };

    /**
     * Encodes the specified AgentRankGetUserDetailsReq message. Does not implicitly {@link client_proto.AgentRankGetUserDetailsReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @static
     * @param {client_proto.IAgentRankGetUserDetailsReq} message AgentRankGetUserDetailsReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankGetUserDetailsReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.id);
      if (message.is_history != null && Object.hasOwnProperty.call(message, "is_history"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).bool(message.is_history);
      return writer;
    };

    /**
     * Encodes the specified AgentRankGetUserDetailsReq message, length delimited. Does not implicitly {@link client_proto.AgentRankGetUserDetailsReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @static
     * @param {client_proto.IAgentRankGetUserDetailsReq} message AgentRankGetUserDetailsReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankGetUserDetailsReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankGetUserDetailsReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankGetUserDetailsReq} AgentRankGetUserDetailsReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankGetUserDetailsReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankGetUserDetailsReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.id = reader.int32();
            break;
          }
          case 3: {
            message.is_history = reader.bool();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankGetUserDetailsReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankGetUserDetailsReq} AgentRankGetUserDetailsReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankGetUserDetailsReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankGetUserDetailsReq message.
     * @function verify
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankGetUserDetailsReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      if (message.is_history != null && message.hasOwnProperty("is_history"))
        if (typeof message.is_history !== "boolean") return "is_history: boolean expected";
      return null;
    };

    /**
     * Creates an AgentRankGetUserDetailsReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankGetUserDetailsReq} AgentRankGetUserDetailsReq
     */
    AgentRankGetUserDetailsReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankGetUserDetailsReq) return object;
      var message = new $root.client_proto.AgentRankGetUserDetailsReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.id != null) message.id = object.id | 0;
      if (object.is_history != null) message.is_history = Boolean(object.is_history);
      return message;
    };

    /**
     * Creates a plain object from an AgentRankGetUserDetailsReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @static
     * @param {client_proto.AgentRankGetUserDetailsReq} message AgentRankGetUserDetailsReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankGetUserDetailsReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.id = 0;
        object.is_history = false;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      if (message.is_history != null && message.hasOwnProperty("is_history")) object.is_history = message.is_history;
      return object;
    };

    /**
     * Converts this AgentRankGetUserDetailsReq to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankGetUserDetailsReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankGetUserDetailsReq
     * @function getTypeUrl
     * @memberof client_proto.AgentRankGetUserDetailsReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankGetUserDetailsReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankGetUserDetailsReq";
    };

    return AgentRankGetUserDetailsReq;
  })();

  client_proto.AgentRankGetUserDetailsResp = (function () {
    /**
     * Properties of an AgentRankGetUserDetailsResp.
     * @memberof client_proto
     * @interface IAgentRankGetUserDetailsResp
     * @property {number|null} [uid] AgentRankGetUserDetailsResp uid
     * @property {number|Long|null} [bet] AgentRankGetUserDetailsResp bet
     * @property {number|Long|null} [deposit] AgentRankGetUserDetailsResp deposit
     * @property {number|Long|null} [invite] AgentRankGetUserDetailsResp invite
     * @property {number|Long|null} [task] AgentRankGetUserDetailsResp task
     * @property {number|Long|null} [total] AgentRankGetUserDetailsResp total
     * @property {boolean|null} [is_history] AgentRankGetUserDetailsResp is_history
     * @property {number|null} [id] AgentRankGetUserDetailsResp id
     */

    /**
     * Constructs a new AgentRankGetUserDetailsResp.
     * @memberof client_proto
     * @classdesc Represents an AgentRankGetUserDetailsResp.
     * @implements IAgentRankGetUserDetailsResp
     * @constructor
     * @param {client_proto.IAgentRankGetUserDetailsResp=} [properties] Properties to set
     */
    function AgentRankGetUserDetailsResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankGetUserDetailsResp uid.
     * @member {number} uid
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @instance
     */
    AgentRankGetUserDetailsResp.prototype.uid = 0;

    /**
     * AgentRankGetUserDetailsResp bet.
     * @member {number|Long} bet
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @instance
     */
    AgentRankGetUserDetailsResp.prototype.bet = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankGetUserDetailsResp deposit.
     * @member {number|Long} deposit
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @instance
     */
    AgentRankGetUserDetailsResp.prototype.deposit = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankGetUserDetailsResp invite.
     * @member {number|Long} invite
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @instance
     */
    AgentRankGetUserDetailsResp.prototype.invite = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankGetUserDetailsResp task.
     * @member {number|Long} task
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @instance
     */
    AgentRankGetUserDetailsResp.prototype.task = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankGetUserDetailsResp total.
     * @member {number|Long} total
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @instance
     */
    AgentRankGetUserDetailsResp.prototype.total = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankGetUserDetailsResp is_history.
     * @member {boolean} is_history
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @instance
     */
    AgentRankGetUserDetailsResp.prototype.is_history = false;

    /**
     * AgentRankGetUserDetailsResp id.
     * @member {number} id
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @instance
     */
    AgentRankGetUserDetailsResp.prototype.id = 0;

    /**
     * Creates a new AgentRankGetUserDetailsResp instance using the specified properties.
     * @function create
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @static
     * @param {client_proto.IAgentRankGetUserDetailsResp=} [properties] Properties to set
     * @returns {client_proto.AgentRankGetUserDetailsResp} AgentRankGetUserDetailsResp instance
     */
    AgentRankGetUserDetailsResp.create = function create(properties) {
      return new AgentRankGetUserDetailsResp(properties);
    };

    /**
     * Encodes the specified AgentRankGetUserDetailsResp message. Does not implicitly {@link client_proto.AgentRankGetUserDetailsResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @static
     * @param {client_proto.IAgentRankGetUserDetailsResp} message AgentRankGetUserDetailsResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankGetUserDetailsResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.bet != null && Object.hasOwnProperty.call(message, "bet"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.bet);
      if (message.deposit != null && Object.hasOwnProperty.call(message, "deposit"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.deposit);
      if (message.invite != null && Object.hasOwnProperty.call(message, "invite"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.invite);
      if (message.task != null && Object.hasOwnProperty.call(message, "task"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.task);
      if (message.total != null && Object.hasOwnProperty.call(message, "total"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.total);
      if (message.is_history != null && Object.hasOwnProperty.call(message, "is_history"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).bool(message.is_history);
      if (message.id != null && Object.hasOwnProperty.call(message, "id"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.id);
      return writer;
    };

    /**
     * Encodes the specified AgentRankGetUserDetailsResp message, length delimited. Does not implicitly {@link client_proto.AgentRankGetUserDetailsResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @static
     * @param {client_proto.IAgentRankGetUserDetailsResp} message AgentRankGetUserDetailsResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankGetUserDetailsResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankGetUserDetailsResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.AgentRankGetUserDetailsResp} AgentRankGetUserDetailsResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankGetUserDetailsResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.AgentRankGetUserDetailsResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.bet = reader.int64();
            break;
          }
          case 3: {
            message.deposit = reader.int64();
            break;
          }
          case 4: {
            message.invite = reader.int64();
            break;
          }
          case 5: {
            message.task = reader.int64();
            break;
          }
          case 6: {
            message.total = reader.int64();
            break;
          }
          case 7: {
            message.is_history = reader.bool();
            break;
          }
          case 8: {
            message.id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankGetUserDetailsResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.AgentRankGetUserDetailsResp} AgentRankGetUserDetailsResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankGetUserDetailsResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankGetUserDetailsResp message.
     * @function verify
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankGetUserDetailsResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.bet != null && message.hasOwnProperty("bet"))
        if (
          !$util.isInteger(message.bet) &&
          !(message.bet && $util.isInteger(message.bet.low) && $util.isInteger(message.bet.high))
        )
          return "bet: integer|Long expected";
      if (message.deposit != null && message.hasOwnProperty("deposit"))
        if (
          !$util.isInteger(message.deposit) &&
          !(message.deposit && $util.isInteger(message.deposit.low) && $util.isInteger(message.deposit.high))
        )
          return "deposit: integer|Long expected";
      if (message.invite != null && message.hasOwnProperty("invite"))
        if (
          !$util.isInteger(message.invite) &&
          !(message.invite && $util.isInteger(message.invite.low) && $util.isInteger(message.invite.high))
        )
          return "invite: integer|Long expected";
      if (message.task != null && message.hasOwnProperty("task"))
        if (
          !$util.isInteger(message.task) &&
          !(message.task && $util.isInteger(message.task.low) && $util.isInteger(message.task.high))
        )
          return "task: integer|Long expected";
      if (message.total != null && message.hasOwnProperty("total"))
        if (
          !$util.isInteger(message.total) &&
          !(message.total && $util.isInteger(message.total.low) && $util.isInteger(message.total.high))
        )
          return "total: integer|Long expected";
      if (message.is_history != null && message.hasOwnProperty("is_history"))
        if (typeof message.is_history !== "boolean") return "is_history: boolean expected";
      if (message.id != null && message.hasOwnProperty("id"))
        if (!$util.isInteger(message.id)) return "id: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankGetUserDetailsResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.AgentRankGetUserDetailsResp} AgentRankGetUserDetailsResp
     */
    AgentRankGetUserDetailsResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.AgentRankGetUserDetailsResp) return object;
      var message = new $root.client_proto.AgentRankGetUserDetailsResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.bet != null)
        if ($util.Long) (message.bet = $util.Long.fromValue(object.bet)).unsigned = false;
        else if (typeof object.bet === "string") message.bet = parseInt(object.bet, 10);
        else if (typeof object.bet === "number") message.bet = object.bet;
        else if (typeof object.bet === "object")
          message.bet = new $util.LongBits(object.bet.low >>> 0, object.bet.high >>> 0).toNumber();
      if (object.deposit != null)
        if ($util.Long) (message.deposit = $util.Long.fromValue(object.deposit)).unsigned = false;
        else if (typeof object.deposit === "string") message.deposit = parseInt(object.deposit, 10);
        else if (typeof object.deposit === "number") message.deposit = object.deposit;
        else if (typeof object.deposit === "object")
          message.deposit = new $util.LongBits(object.deposit.low >>> 0, object.deposit.high >>> 0).toNumber();
      if (object.invite != null)
        if ($util.Long) (message.invite = $util.Long.fromValue(object.invite)).unsigned = false;
        else if (typeof object.invite === "string") message.invite = parseInt(object.invite, 10);
        else if (typeof object.invite === "number") message.invite = object.invite;
        else if (typeof object.invite === "object")
          message.invite = new $util.LongBits(object.invite.low >>> 0, object.invite.high >>> 0).toNumber();
      if (object.task != null)
        if ($util.Long) (message.task = $util.Long.fromValue(object.task)).unsigned = false;
        else if (typeof object.task === "string") message.task = parseInt(object.task, 10);
        else if (typeof object.task === "number") message.task = object.task;
        else if (typeof object.task === "object")
          message.task = new $util.LongBits(object.task.low >>> 0, object.task.high >>> 0).toNumber();
      if (object.total != null)
        if ($util.Long) (message.total = $util.Long.fromValue(object.total)).unsigned = false;
        else if (typeof object.total === "string") message.total = parseInt(object.total, 10);
        else if (typeof object.total === "number") message.total = object.total;
        else if (typeof object.total === "object")
          message.total = new $util.LongBits(object.total.low >>> 0, object.total.high >>> 0).toNumber();
      if (object.is_history != null) message.is_history = Boolean(object.is_history);
      if (object.id != null) message.id = object.id | 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankGetUserDetailsResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @static
     * @param {client_proto.AgentRankGetUserDetailsResp} message AgentRankGetUserDetailsResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankGetUserDetailsResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.bet = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.bet = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.deposit =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.deposit = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.invite =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.invite = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.task = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.task = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.total = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.total = options.longs === String ? "0" : 0;
        object.is_history = false;
        object.id = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.bet != null && message.hasOwnProperty("bet"))
        if (typeof message.bet === "number") object.bet = options.longs === String ? String(message.bet) : message.bet;
        else
          object.bet =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.bet)
              : options.longs === Number
                ? new $util.LongBits(message.bet.low >>> 0, message.bet.high >>> 0).toNumber()
                : message.bet;
      if (message.deposit != null && message.hasOwnProperty("deposit"))
        if (typeof message.deposit === "number")
          object.deposit = options.longs === String ? String(message.deposit) : message.deposit;
        else
          object.deposit =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.deposit)
              : options.longs === Number
                ? new $util.LongBits(message.deposit.low >>> 0, message.deposit.high >>> 0).toNumber()
                : message.deposit;
      if (message.invite != null && message.hasOwnProperty("invite"))
        if (typeof message.invite === "number")
          object.invite = options.longs === String ? String(message.invite) : message.invite;
        else
          object.invite =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.invite)
              : options.longs === Number
                ? new $util.LongBits(message.invite.low >>> 0, message.invite.high >>> 0).toNumber()
                : message.invite;
      if (message.task != null && message.hasOwnProperty("task"))
        if (typeof message.task === "number")
          object.task = options.longs === String ? String(message.task) : message.task;
        else
          object.task =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.task)
              : options.longs === Number
                ? new $util.LongBits(message.task.low >>> 0, message.task.high >>> 0).toNumber()
                : message.task;
      if (message.total != null && message.hasOwnProperty("total"))
        if (typeof message.total === "number")
          object.total = options.longs === String ? String(message.total) : message.total;
        else
          object.total =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.total)
              : options.longs === Number
                ? new $util.LongBits(message.total.low >>> 0, message.total.high >>> 0).toNumber()
                : message.total;
      if (message.is_history != null && message.hasOwnProperty("is_history")) object.is_history = message.is_history;
      if (message.id != null && message.hasOwnProperty("id")) object.id = message.id;
      return object;
    };

    /**
     * Converts this AgentRankGetUserDetailsResp to JSON.
     * @function toJSON
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankGetUserDetailsResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankGetUserDetailsResp
     * @function getTypeUrl
     * @memberof client_proto.AgentRankGetUserDetailsResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankGetUserDetailsResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.AgentRankGetUserDetailsResp";
    };

    return AgentRankGetUserDetailsResp;
  })();

  /**
   * FLOATING_INFO_SUB_MSG_ID enum.
   * @name client_proto.FLOATING_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} FLOATING_MSG_ID_NULL=0 FLOATING_MSG_ID_NULL value
   * @property {number} FLOATING_MSG_ID_MIN=5600 FLOATING_MSG_ID_MIN value
   * @property {number} FLOATING_MSG_ID_PULL=5601 FLOATING_MSG_ID_PULL value
   * @property {number} FLOATING_MSG_ID_PULL_RESP=5602 FLOATING_MSG_ID_PULL_RESP value
   * @property {number} FLOATING_MSG_ID_MAX=5650 FLOATING_MSG_ID_MAX value
   */
  client_proto.FLOATING_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "FLOATING_MSG_ID_NULL")] = 0;
    values[(valuesById[5600] = "FLOATING_MSG_ID_MIN")] = 5600;
    values[(valuesById[5601] = "FLOATING_MSG_ID_PULL")] = 5601;
    values[(valuesById[5602] = "FLOATING_MSG_ID_PULL_RESP")] = 5602;
    values[(valuesById[5650] = "FLOATING_MSG_ID_MAX")] = 5650;
    return values;
  })();

  client_proto.FloatingPullReq = (function () {
    /**
     * Properties of a FloatingPullReq.
     * @memberof client_proto
     * @interface IFloatingPullReq
     * @property {number|null} [uid] FloatingPullReq uid
     * @property {string|null} [language] FloatingPullReq language
     */

    /**
     * Constructs a new FloatingPullReq.
     * @memberof client_proto
     * @classdesc Represents a FloatingPullReq.
     * @implements IFloatingPullReq
     * @constructor
     * @param {client_proto.IFloatingPullReq=} [properties] Properties to set
     */
    function FloatingPullReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FloatingPullReq uid.
     * @member {number} uid
     * @memberof client_proto.FloatingPullReq
     * @instance
     */
    FloatingPullReq.prototype.uid = 0;

    /**
     * FloatingPullReq language.
     * @member {string} language
     * @memberof client_proto.FloatingPullReq
     * @instance
     */
    FloatingPullReq.prototype.language = "";

    /**
     * Creates a new FloatingPullReq instance using the specified properties.
     * @function create
     * @memberof client_proto.FloatingPullReq
     * @static
     * @param {client_proto.IFloatingPullReq=} [properties] Properties to set
     * @returns {client_proto.FloatingPullReq} FloatingPullReq instance
     */
    FloatingPullReq.create = function create(properties) {
      return new FloatingPullReq(properties);
    };

    /**
     * Encodes the specified FloatingPullReq message. Does not implicitly {@link client_proto.FloatingPullReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.FloatingPullReq
     * @static
     * @param {client_proto.IFloatingPullReq} message FloatingPullReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FloatingPullReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.language);
      return writer;
    };

    /**
     * Encodes the specified FloatingPullReq message, length delimited. Does not implicitly {@link client_proto.FloatingPullReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.FloatingPullReq
     * @static
     * @param {client_proto.IFloatingPullReq} message FloatingPullReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FloatingPullReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FloatingPullReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.FloatingPullReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.FloatingPullReq} FloatingPullReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FloatingPullReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.FloatingPullReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.language = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FloatingPullReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.FloatingPullReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.FloatingPullReq} FloatingPullReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FloatingPullReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FloatingPullReq message.
     * @function verify
     * @memberof client_proto.FloatingPullReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FloatingPullReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      return null;
    };

    /**
     * Creates a FloatingPullReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.FloatingPullReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.FloatingPullReq} FloatingPullReq
     */
    FloatingPullReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.FloatingPullReq) return object;
      var message = new $root.client_proto.FloatingPullReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.language != null) message.language = String(object.language);
      return message;
    };

    /**
     * Creates a plain object from a FloatingPullReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.FloatingPullReq
     * @static
     * @param {client_proto.FloatingPullReq} message FloatingPullReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FloatingPullReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.language = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      return object;
    };

    /**
     * Converts this FloatingPullReq to JSON.
     * @function toJSON
     * @memberof client_proto.FloatingPullReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FloatingPullReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FloatingPullReq
     * @function getTypeUrl
     * @memberof client_proto.FloatingPullReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FloatingPullReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.FloatingPullReq";
    };

    return FloatingPullReq;
  })();

  client_proto.FloatingData = (function () {
    /**
     * Properties of a FloatingData.
     * @memberof client_proto
     * @interface IFloatingData
     * @property {number|null} [floating_id] FloatingData floating_id
     * @property {number|null} [order_id] FloatingData order_id
     * @property {number|null} [show_type] FloatingData show_type
     * @property {number|null} [show_start_time] FloatingData show_start_time
     * @property {number|null} [show_end_time] FloatingData show_end_time
     * @property {string|null} [pic] FloatingData pic
     * @property {string|null} [jump] FloatingData jump
     * @property {string|null} [jump_button] FloatingData jump_button
     * @property {number|null} [jump_type] FloatingData jump_type
     * @property {number|null} [is_show_time] FloatingData is_show_time
     * @property {number|null} [count_down_show] FloatingData count_down_show
     * @property {string|null} [spine_pic] FloatingData spine_pic
     * @property {string|null} [atlas_url] FloatingData atlas_url
     * @property {string|null} [skel_url] FloatingData skel_url
     * @property {string|null} [anim_params] FloatingData anim_params
     * @property {number|null} [is_red_dot_show] FloatingData is_red_dot_show
     * @property {number|null} [group_id] FloatingData group_id
     */

    /**
     * Constructs a new FloatingData.
     * @memberof client_proto
     * @classdesc Represents a FloatingData.
     * @implements IFloatingData
     * @constructor
     * @param {client_proto.IFloatingData=} [properties] Properties to set
     */
    function FloatingData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FloatingData floating_id.
     * @member {number} floating_id
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.floating_id = 0;

    /**
     * FloatingData order_id.
     * @member {number} order_id
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.order_id = 0;

    /**
     * FloatingData show_type.
     * @member {number} show_type
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.show_type = 0;

    /**
     * FloatingData show_start_time.
     * @member {number} show_start_time
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.show_start_time = 0;

    /**
     * FloatingData show_end_time.
     * @member {number} show_end_time
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.show_end_time = 0;

    /**
     * FloatingData pic.
     * @member {string} pic
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.pic = "";

    /**
     * FloatingData jump.
     * @member {string} jump
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.jump = "";

    /**
     * FloatingData jump_button.
     * @member {string} jump_button
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.jump_button = "";

    /**
     * FloatingData jump_type.
     * @member {number} jump_type
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.jump_type = 0;

    /**
     * FloatingData is_show_time.
     * @member {number} is_show_time
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.is_show_time = 0;

    /**
     * FloatingData count_down_show.
     * @member {number} count_down_show
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.count_down_show = 0;

    /**
     * FloatingData spine_pic.
     * @member {string} spine_pic
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.spine_pic = "";

    /**
     * FloatingData atlas_url.
     * @member {string} atlas_url
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.atlas_url = "";

    /**
     * FloatingData skel_url.
     * @member {string} skel_url
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.skel_url = "";

    /**
     * FloatingData anim_params.
     * @member {string} anim_params
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.anim_params = "";

    /**
     * FloatingData is_red_dot_show.
     * @member {number} is_red_dot_show
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.is_red_dot_show = 0;

    /**
     * FloatingData group_id.
     * @member {number} group_id
     * @memberof client_proto.FloatingData
     * @instance
     */
    FloatingData.prototype.group_id = 0;

    /**
     * Creates a new FloatingData instance using the specified properties.
     * @function create
     * @memberof client_proto.FloatingData
     * @static
     * @param {client_proto.IFloatingData=} [properties] Properties to set
     * @returns {client_proto.FloatingData} FloatingData instance
     */
    FloatingData.create = function create(properties) {
      return new FloatingData(properties);
    };

    /**
     * Encodes the specified FloatingData message. Does not implicitly {@link client_proto.FloatingData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.FloatingData
     * @static
     * @param {client_proto.IFloatingData} message FloatingData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FloatingData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.floating_id != null && Object.hasOwnProperty.call(message, "floating_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.floating_id);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.order_id);
      if (message.show_type != null && Object.hasOwnProperty.call(message, "show_type"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.show_type);
      if (message.show_start_time != null && Object.hasOwnProperty.call(message, "show_start_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.show_start_time);
      if (message.show_end_time != null && Object.hasOwnProperty.call(message, "show_end_time"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).uint32(message.show_end_time);
      if (message.pic != null && Object.hasOwnProperty.call(message, "pic"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.pic);
      if (message.jump != null && Object.hasOwnProperty.call(message, "jump"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.jump);
      if (message.jump_button != null && Object.hasOwnProperty.call(message, "jump_button"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.jump_button);
      if (message.jump_type != null && Object.hasOwnProperty.call(message, "jump_type"))
        writer.uint32(/* id 9, wireType 0 =*/ 72).int32(message.jump_type);
      if (message.is_show_time != null && Object.hasOwnProperty.call(message, "is_show_time"))
        writer.uint32(/* id 10, wireType 0 =*/ 80).int32(message.is_show_time);
      if (message.count_down_show != null && Object.hasOwnProperty.call(message, "count_down_show"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int32(message.count_down_show);
      if (message.spine_pic != null && Object.hasOwnProperty.call(message, "spine_pic"))
        writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.spine_pic);
      if (message.atlas_url != null && Object.hasOwnProperty.call(message, "atlas_url"))
        writer.uint32(/* id 13, wireType 2 =*/ 106).string(message.atlas_url);
      if (message.skel_url != null && Object.hasOwnProperty.call(message, "skel_url"))
        writer.uint32(/* id 14, wireType 2 =*/ 114).string(message.skel_url);
      if (message.anim_params != null && Object.hasOwnProperty.call(message, "anim_params"))
        writer.uint32(/* id 15, wireType 2 =*/ 122).string(message.anim_params);
      if (message.is_red_dot_show != null && Object.hasOwnProperty.call(message, "is_red_dot_show"))
        writer.uint32(/* id 16, wireType 0 =*/ 128).int32(message.is_red_dot_show);
      if (message.group_id != null && Object.hasOwnProperty.call(message, "group_id"))
        writer.uint32(/* id 17, wireType 0 =*/ 136).int32(message.group_id);
      return writer;
    };

    /**
     * Encodes the specified FloatingData message, length delimited. Does not implicitly {@link client_proto.FloatingData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.FloatingData
     * @static
     * @param {client_proto.IFloatingData} message FloatingData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FloatingData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FloatingData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.FloatingData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.FloatingData} FloatingData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FloatingData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.FloatingData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.floating_id = reader.int32();
            break;
          }
          case 2: {
            message.order_id = reader.int32();
            break;
          }
          case 3: {
            message.show_type = reader.int32();
            break;
          }
          case 4: {
            message.show_start_time = reader.uint32();
            break;
          }
          case 5: {
            message.show_end_time = reader.uint32();
            break;
          }
          case 6: {
            message.pic = reader.string();
            break;
          }
          case 7: {
            message.jump = reader.string();
            break;
          }
          case 8: {
            message.jump_button = reader.string();
            break;
          }
          case 9: {
            message.jump_type = reader.int32();
            break;
          }
          case 10: {
            message.is_show_time = reader.int32();
            break;
          }
          case 11: {
            message.count_down_show = reader.int32();
            break;
          }
          case 12: {
            message.spine_pic = reader.string();
            break;
          }
          case 13: {
            message.atlas_url = reader.string();
            break;
          }
          case 14: {
            message.skel_url = reader.string();
            break;
          }
          case 15: {
            message.anim_params = reader.string();
            break;
          }
          case 16: {
            message.is_red_dot_show = reader.int32();
            break;
          }
          case 17: {
            message.group_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FloatingData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.FloatingData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.FloatingData} FloatingData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FloatingData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FloatingData message.
     * @function verify
     * @memberof client_proto.FloatingData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FloatingData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.floating_id != null && message.hasOwnProperty("floating_id"))
        if (!$util.isInteger(message.floating_id)) return "floating_id: integer expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isInteger(message.order_id)) return "order_id: integer expected";
      if (message.show_type != null && message.hasOwnProperty("show_type"))
        if (!$util.isInteger(message.show_type)) return "show_type: integer expected";
      if (message.show_start_time != null && message.hasOwnProperty("show_start_time"))
        if (!$util.isInteger(message.show_start_time)) return "show_start_time: integer expected";
      if (message.show_end_time != null && message.hasOwnProperty("show_end_time"))
        if (!$util.isInteger(message.show_end_time)) return "show_end_time: integer expected";
      if (message.pic != null && message.hasOwnProperty("pic"))
        if (!$util.isString(message.pic)) return "pic: string expected";
      if (message.jump != null && message.hasOwnProperty("jump"))
        if (!$util.isString(message.jump)) return "jump: string expected";
      if (message.jump_button != null && message.hasOwnProperty("jump_button"))
        if (!$util.isString(message.jump_button)) return "jump_button: string expected";
      if (message.jump_type != null && message.hasOwnProperty("jump_type"))
        if (!$util.isInteger(message.jump_type)) return "jump_type: integer expected";
      if (message.is_show_time != null && message.hasOwnProperty("is_show_time"))
        if (!$util.isInteger(message.is_show_time)) return "is_show_time: integer expected";
      if (message.count_down_show != null && message.hasOwnProperty("count_down_show"))
        if (!$util.isInteger(message.count_down_show)) return "count_down_show: integer expected";
      if (message.spine_pic != null && message.hasOwnProperty("spine_pic"))
        if (!$util.isString(message.spine_pic)) return "spine_pic: string expected";
      if (message.atlas_url != null && message.hasOwnProperty("atlas_url"))
        if (!$util.isString(message.atlas_url)) return "atlas_url: string expected";
      if (message.skel_url != null && message.hasOwnProperty("skel_url"))
        if (!$util.isString(message.skel_url)) return "skel_url: string expected";
      if (message.anim_params != null && message.hasOwnProperty("anim_params"))
        if (!$util.isString(message.anim_params)) return "anim_params: string expected";
      if (message.is_red_dot_show != null && message.hasOwnProperty("is_red_dot_show"))
        if (!$util.isInteger(message.is_red_dot_show)) return "is_red_dot_show: integer expected";
      if (message.group_id != null && message.hasOwnProperty("group_id"))
        if (!$util.isInteger(message.group_id)) return "group_id: integer expected";
      return null;
    };

    /**
     * Creates a FloatingData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.FloatingData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.FloatingData} FloatingData
     */
    FloatingData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.FloatingData) return object;
      var message = new $root.client_proto.FloatingData();
      if (object.floating_id != null) message.floating_id = object.floating_id | 0;
      if (object.order_id != null) message.order_id = object.order_id | 0;
      if (object.show_type != null) message.show_type = object.show_type | 0;
      if (object.show_start_time != null) message.show_start_time = object.show_start_time >>> 0;
      if (object.show_end_time != null) message.show_end_time = object.show_end_time >>> 0;
      if (object.pic != null) message.pic = String(object.pic);
      if (object.jump != null) message.jump = String(object.jump);
      if (object.jump_button != null) message.jump_button = String(object.jump_button);
      if (object.jump_type != null) message.jump_type = object.jump_type | 0;
      if (object.is_show_time != null) message.is_show_time = object.is_show_time | 0;
      if (object.count_down_show != null) message.count_down_show = object.count_down_show | 0;
      if (object.spine_pic != null) message.spine_pic = String(object.spine_pic);
      if (object.atlas_url != null) message.atlas_url = String(object.atlas_url);
      if (object.skel_url != null) message.skel_url = String(object.skel_url);
      if (object.anim_params != null) message.anim_params = String(object.anim_params);
      if (object.is_red_dot_show != null) message.is_red_dot_show = object.is_red_dot_show | 0;
      if (object.group_id != null) message.group_id = object.group_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a FloatingData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.FloatingData
     * @static
     * @param {client_proto.FloatingData} message FloatingData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FloatingData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.floating_id = 0;
        object.order_id = 0;
        object.show_type = 0;
        object.show_start_time = 0;
        object.show_end_time = 0;
        object.pic = "";
        object.jump = "";
        object.jump_button = "";
        object.jump_type = 0;
        object.is_show_time = 0;
        object.count_down_show = 0;
        object.spine_pic = "";
        object.atlas_url = "";
        object.skel_url = "";
        object.anim_params = "";
        object.is_red_dot_show = 0;
        object.group_id = 0;
      }
      if (message.floating_id != null && message.hasOwnProperty("floating_id"))
        object.floating_id = message.floating_id;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.show_type != null && message.hasOwnProperty("show_type")) object.show_type = message.show_type;
      if (message.show_start_time != null && message.hasOwnProperty("show_start_time"))
        object.show_start_time = message.show_start_time;
      if (message.show_end_time != null && message.hasOwnProperty("show_end_time"))
        object.show_end_time = message.show_end_time;
      if (message.pic != null && message.hasOwnProperty("pic")) object.pic = message.pic;
      if (message.jump != null && message.hasOwnProperty("jump")) object.jump = message.jump;
      if (message.jump_button != null && message.hasOwnProperty("jump_button"))
        object.jump_button = message.jump_button;
      if (message.jump_type != null && message.hasOwnProperty("jump_type")) object.jump_type = message.jump_type;
      if (message.is_show_time != null && message.hasOwnProperty("is_show_time"))
        object.is_show_time = message.is_show_time;
      if (message.count_down_show != null && message.hasOwnProperty("count_down_show"))
        object.count_down_show = message.count_down_show;
      if (message.spine_pic != null && message.hasOwnProperty("spine_pic")) object.spine_pic = message.spine_pic;
      if (message.atlas_url != null && message.hasOwnProperty("atlas_url")) object.atlas_url = message.atlas_url;
      if (message.skel_url != null && message.hasOwnProperty("skel_url")) object.skel_url = message.skel_url;
      if (message.anim_params != null && message.hasOwnProperty("anim_params"))
        object.anim_params = message.anim_params;
      if (message.is_red_dot_show != null && message.hasOwnProperty("is_red_dot_show"))
        object.is_red_dot_show = message.is_red_dot_show;
      if (message.group_id != null && message.hasOwnProperty("group_id")) object.group_id = message.group_id;
      return object;
    };

    /**
     * Converts this FloatingData to JSON.
     * @function toJSON
     * @memberof client_proto.FloatingData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FloatingData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FloatingData
     * @function getTypeUrl
     * @memberof client_proto.FloatingData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FloatingData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.FloatingData";
    };

    return FloatingData;
  })();

  client_proto.FloatingGroupData = (function () {
    /**
     * Properties of a FloatingGroupData.
     * @memberof client_proto
     * @interface IFloatingGroupData
     * @property {client_proto.IFloatingData|null} [group_data] FloatingGroupData group_data
     * @property {Array.<client_proto.IFloatingData>|null} [children_list] FloatingGroupData children_list
     */

    /**
     * Constructs a new FloatingGroupData.
     * @memberof client_proto
     * @classdesc Represents a FloatingGroupData.
     * @implements IFloatingGroupData
     * @constructor
     * @param {client_proto.IFloatingGroupData=} [properties] Properties to set
     */
    function FloatingGroupData(properties) {
      this.children_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FloatingGroupData group_data.
     * @member {client_proto.IFloatingData|null|undefined} group_data
     * @memberof client_proto.FloatingGroupData
     * @instance
     */
    FloatingGroupData.prototype.group_data = null;

    /**
     * FloatingGroupData children_list.
     * @member {Array.<client_proto.IFloatingData>} children_list
     * @memberof client_proto.FloatingGroupData
     * @instance
     */
    FloatingGroupData.prototype.children_list = $util.emptyArray;

    /**
     * Creates a new FloatingGroupData instance using the specified properties.
     * @function create
     * @memberof client_proto.FloatingGroupData
     * @static
     * @param {client_proto.IFloatingGroupData=} [properties] Properties to set
     * @returns {client_proto.FloatingGroupData} FloatingGroupData instance
     */
    FloatingGroupData.create = function create(properties) {
      return new FloatingGroupData(properties);
    };

    /**
     * Encodes the specified FloatingGroupData message. Does not implicitly {@link client_proto.FloatingGroupData.verify|verify} messages.
     * @function encode
     * @memberof client_proto.FloatingGroupData
     * @static
     * @param {client_proto.IFloatingGroupData} message FloatingGroupData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FloatingGroupData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.group_data != null && Object.hasOwnProperty.call(message, "group_data"))
        $root.client_proto.FloatingData.encode(
          message.group_data,
          writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
        ).ldelim();
      if (message.children_list != null && message.children_list.length)
        for (var i = 0; i < message.children_list.length; ++i)
          $root.client_proto.FloatingData.encode(
            message.children_list[i],
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified FloatingGroupData message, length delimited. Does not implicitly {@link client_proto.FloatingGroupData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.FloatingGroupData
     * @static
     * @param {client_proto.IFloatingGroupData} message FloatingGroupData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FloatingGroupData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FloatingGroupData message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.FloatingGroupData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.FloatingGroupData} FloatingGroupData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FloatingGroupData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.FloatingGroupData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.group_data = $root.client_proto.FloatingData.decode(reader, reader.uint32());
            break;
          }
          case 2: {
            if (!(message.children_list && message.children_list.length)) message.children_list = [];
            message.children_list.push($root.client_proto.FloatingData.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FloatingGroupData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.FloatingGroupData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.FloatingGroupData} FloatingGroupData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FloatingGroupData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FloatingGroupData message.
     * @function verify
     * @memberof client_proto.FloatingGroupData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FloatingGroupData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.group_data != null && message.hasOwnProperty("group_data")) {
        var error = $root.client_proto.FloatingData.verify(message.group_data);
        if (error) return "group_data." + error;
      }
      if (message.children_list != null && message.hasOwnProperty("children_list")) {
        if (!Array.isArray(message.children_list)) return "children_list: array expected";
        for (var i = 0; i < message.children_list.length; ++i) {
          var error = $root.client_proto.FloatingData.verify(message.children_list[i]);
          if (error) return "children_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates a FloatingGroupData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.FloatingGroupData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.FloatingGroupData} FloatingGroupData
     */
    FloatingGroupData.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.FloatingGroupData) return object;
      var message = new $root.client_proto.FloatingGroupData();
      if (object.group_data != null) {
        if (typeof object.group_data !== "object")
          throw TypeError(".client_proto.FloatingGroupData.group_data: object expected");
        message.group_data = $root.client_proto.FloatingData.fromObject(object.group_data);
      }
      if (object.children_list) {
        if (!Array.isArray(object.children_list))
          throw TypeError(".client_proto.FloatingGroupData.children_list: array expected");
        message.children_list = [];
        for (var i = 0; i < object.children_list.length; ++i) {
          if (typeof object.children_list[i] !== "object")
            throw TypeError(".client_proto.FloatingGroupData.children_list: object expected");
          message.children_list[i] = $root.client_proto.FloatingData.fromObject(object.children_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a FloatingGroupData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.FloatingGroupData
     * @static
     * @param {client_proto.FloatingGroupData} message FloatingGroupData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FloatingGroupData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.children_list = [];
      if (options.defaults) object.group_data = null;
      if (message.group_data != null && message.hasOwnProperty("group_data"))
        object.group_data = $root.client_proto.FloatingData.toObject(message.group_data, options);
      if (message.children_list && message.children_list.length) {
        object.children_list = [];
        for (var j = 0; j < message.children_list.length; ++j)
          object.children_list[j] = $root.client_proto.FloatingData.toObject(message.children_list[j], options);
      }
      return object;
    };

    /**
     * Converts this FloatingGroupData to JSON.
     * @function toJSON
     * @memberof client_proto.FloatingGroupData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FloatingGroupData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FloatingGroupData
     * @function getTypeUrl
     * @memberof client_proto.FloatingGroupData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FloatingGroupData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.FloatingGroupData";
    };

    return FloatingGroupData;
  })();

  client_proto.FloatingPullResp = (function () {
    /**
     * Properties of a FloatingPullResp.
     * @memberof client_proto
     * @interface IFloatingPullResp
     * @property {Array.<client_proto.IFloatingGroupData>|null} [data_list] FloatingPullResp data_list
     * @property {string|null} [language] FloatingPullResp language
     */

    /**
     * Constructs a new FloatingPullResp.
     * @memberof client_proto
     * @classdesc Represents a FloatingPullResp.
     * @implements IFloatingPullResp
     * @constructor
     * @param {client_proto.IFloatingPullResp=} [properties] Properties to set
     */
    function FloatingPullResp(properties) {
      this.data_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FloatingPullResp data_list.
     * @member {Array.<client_proto.IFloatingGroupData>} data_list
     * @memberof client_proto.FloatingPullResp
     * @instance
     */
    FloatingPullResp.prototype.data_list = $util.emptyArray;

    /**
     * FloatingPullResp language.
     * @member {string} language
     * @memberof client_proto.FloatingPullResp
     * @instance
     */
    FloatingPullResp.prototype.language = "";

    /**
     * Creates a new FloatingPullResp instance using the specified properties.
     * @function create
     * @memberof client_proto.FloatingPullResp
     * @static
     * @param {client_proto.IFloatingPullResp=} [properties] Properties to set
     * @returns {client_proto.FloatingPullResp} FloatingPullResp instance
     */
    FloatingPullResp.create = function create(properties) {
      return new FloatingPullResp(properties);
    };

    /**
     * Encodes the specified FloatingPullResp message. Does not implicitly {@link client_proto.FloatingPullResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.FloatingPullResp
     * @static
     * @param {client_proto.IFloatingPullResp} message FloatingPullResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FloatingPullResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data_list != null && message.data_list.length)
        for (var i = 0; i < message.data_list.length; ++i)
          $root.client_proto.FloatingGroupData.encode(
            message.data_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      if (message.language != null && Object.hasOwnProperty.call(message, "language"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.language);
      return writer;
    };

    /**
     * Encodes the specified FloatingPullResp message, length delimited. Does not implicitly {@link client_proto.FloatingPullResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.FloatingPullResp
     * @static
     * @param {client_proto.IFloatingPullResp} message FloatingPullResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FloatingPullResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FloatingPullResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.FloatingPullResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.FloatingPullResp} FloatingPullResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FloatingPullResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.FloatingPullResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.data_list && message.data_list.length)) message.data_list = [];
            message.data_list.push($root.client_proto.FloatingGroupData.decode(reader, reader.uint32()));
            break;
          }
          case 2: {
            message.language = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FloatingPullResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.FloatingPullResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.FloatingPullResp} FloatingPullResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FloatingPullResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FloatingPullResp message.
     * @function verify
     * @memberof client_proto.FloatingPullResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FloatingPullResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data_list != null && message.hasOwnProperty("data_list")) {
        if (!Array.isArray(message.data_list)) return "data_list: array expected";
        for (var i = 0; i < message.data_list.length; ++i) {
          var error = $root.client_proto.FloatingGroupData.verify(message.data_list[i]);
          if (error) return "data_list." + error;
        }
      }
      if (message.language != null && message.hasOwnProperty("language"))
        if (!$util.isString(message.language)) return "language: string expected";
      return null;
    };

    /**
     * Creates a FloatingPullResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.FloatingPullResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.FloatingPullResp} FloatingPullResp
     */
    FloatingPullResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.FloatingPullResp) return object;
      var message = new $root.client_proto.FloatingPullResp();
      if (object.data_list) {
        if (!Array.isArray(object.data_list))
          throw TypeError(".client_proto.FloatingPullResp.data_list: array expected");
        message.data_list = [];
        for (var i = 0; i < object.data_list.length; ++i) {
          if (typeof object.data_list[i] !== "object")
            throw TypeError(".client_proto.FloatingPullResp.data_list: object expected");
          message.data_list[i] = $root.client_proto.FloatingGroupData.fromObject(object.data_list[i]);
        }
      }
      if (object.language != null) message.language = String(object.language);
      return message;
    };

    /**
     * Creates a plain object from a FloatingPullResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.FloatingPullResp
     * @static
     * @param {client_proto.FloatingPullResp} message FloatingPullResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FloatingPullResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.data_list = [];
      if (options.defaults) object.language = "";
      if (message.data_list && message.data_list.length) {
        object.data_list = [];
        for (var j = 0; j < message.data_list.length; ++j)
          object.data_list[j] = $root.client_proto.FloatingGroupData.toObject(message.data_list[j], options);
      }
      if (message.language != null && message.hasOwnProperty("language")) object.language = message.language;
      return object;
    };

    /**
     * Converts this FloatingPullResp to JSON.
     * @function toJSON
     * @memberof client_proto.FloatingPullResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FloatingPullResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FloatingPullResp
     * @function getTypeUrl
     * @memberof client_proto.FloatingPullResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FloatingPullResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.FloatingPullResp";
    };

    return FloatingPullResp;
  })();

  /**
   * ON_OFF_SERVER_SUB_MSG_ID enum.
   * @name client_proto.ON_OFF_SERVER_SUB_MSG_ID
   * @enum {number}
   * @property {number} ON_OFF_SERVER_MSG_ID_NULL=0 ON_OFF_SERVER_MSG_ID_NULL value
   * @property {number} ON_OFF_SERVER_MSG_ID_MIN=5900 ON_OFF_SERVER_MSG_ID_MIN value
   * @property {number} ON_OFF_SERVER_MSG_ID_REQ=5901 ON_OFF_SERVER_MSG_ID_REQ value
   * @property {number} ON_OFF_SERVER_MSG_ID_RESP=5902 ON_OFF_SERVER_MSG_ID_RESP value
   * @property {number} ON_OFF_SERVER_MSG_ID_MAX=5950 ON_OFF_SERVER_MSG_ID_MAX value
   */
  client_proto.ON_OFF_SERVER_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "ON_OFF_SERVER_MSG_ID_NULL")] = 0;
    values[(valuesById[5900] = "ON_OFF_SERVER_MSG_ID_MIN")] = 5900;
    values[(valuesById[5901] = "ON_OFF_SERVER_MSG_ID_REQ")] = 5901;
    values[(valuesById[5902] = "ON_OFF_SERVER_MSG_ID_RESP")] = 5902;
    values[(valuesById[5950] = "ON_OFF_SERVER_MSG_ID_MAX")] = 5950;
    return values;
  })();

  client_proto.OnOffReq = (function () {
    /**
     * Properties of an OnOffReq.
     * @memberof client_proto
     * @interface IOnOffReq
     * @property {number|null} [uid] OnOffReq uid
     */

    /**
     * Constructs a new OnOffReq.
     * @memberof client_proto
     * @classdesc Represents an OnOffReq.
     * @implements IOnOffReq
     * @constructor
     * @param {client_proto.IOnOffReq=} [properties] Properties to set
     */
    function OnOffReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * OnOffReq uid.
     * @member {number} uid
     * @memberof client_proto.OnOffReq
     * @instance
     */
    OnOffReq.prototype.uid = 0;

    /**
     * Creates a new OnOffReq instance using the specified properties.
     * @function create
     * @memberof client_proto.OnOffReq
     * @static
     * @param {client_proto.IOnOffReq=} [properties] Properties to set
     * @returns {client_proto.OnOffReq} OnOffReq instance
     */
    OnOffReq.create = function create(properties) {
      return new OnOffReq(properties);
    };

    /**
     * Encodes the specified OnOffReq message. Does not implicitly {@link client_proto.OnOffReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.OnOffReq
     * @static
     * @param {client_proto.IOnOffReq} message OnOffReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OnOffReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified OnOffReq message, length delimited. Does not implicitly {@link client_proto.OnOffReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.OnOffReq
     * @static
     * @param {client_proto.IOnOffReq} message OnOffReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OnOffReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an OnOffReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.OnOffReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.OnOffReq} OnOffReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OnOffReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.OnOffReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an OnOffReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.OnOffReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.OnOffReq} OnOffReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OnOffReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an OnOffReq message.
     * @function verify
     * @memberof client_proto.OnOffReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    OnOffReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates an OnOffReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.OnOffReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.OnOffReq} OnOffReq
     */
    OnOffReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.OnOffReq) return object;
      var message = new $root.client_proto.OnOffReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from an OnOffReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.OnOffReq
     * @static
     * @param {client_proto.OnOffReq} message OnOffReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    OnOffReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this OnOffReq to JSON.
     * @function toJSON
     * @memberof client_proto.OnOffReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    OnOffReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for OnOffReq
     * @function getTypeUrl
     * @memberof client_proto.OnOffReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    OnOffReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.OnOffReq";
    };

    return OnOffReq;
  })();

  client_proto.OnOffResp = (function () {
    /**
     * Properties of an OnOffResp.
     * @memberof client_proto
     * @interface IOnOffResp
     * @property {string|null} [config] OnOffResp config
     */

    /**
     * Constructs a new OnOffResp.
     * @memberof client_proto
     * @classdesc Represents an OnOffResp.
     * @implements IOnOffResp
     * @constructor
     * @param {client_proto.IOnOffResp=} [properties] Properties to set
     */
    function OnOffResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * OnOffResp config.
     * @member {string} config
     * @memberof client_proto.OnOffResp
     * @instance
     */
    OnOffResp.prototype.config = "";

    /**
     * Creates a new OnOffResp instance using the specified properties.
     * @function create
     * @memberof client_proto.OnOffResp
     * @static
     * @param {client_proto.IOnOffResp=} [properties] Properties to set
     * @returns {client_proto.OnOffResp} OnOffResp instance
     */
    OnOffResp.create = function create(properties) {
      return new OnOffResp(properties);
    };

    /**
     * Encodes the specified OnOffResp message. Does not implicitly {@link client_proto.OnOffResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.OnOffResp
     * @static
     * @param {client_proto.IOnOffResp} message OnOffResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OnOffResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.config != null && Object.hasOwnProperty.call(message, "config"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.config);
      return writer;
    };

    /**
     * Encodes the specified OnOffResp message, length delimited. Does not implicitly {@link client_proto.OnOffResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.OnOffResp
     * @static
     * @param {client_proto.IOnOffResp} message OnOffResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OnOffResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an OnOffResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.OnOffResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.OnOffResp} OnOffResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OnOffResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.OnOffResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.config = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an OnOffResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.OnOffResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.OnOffResp} OnOffResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OnOffResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an OnOffResp message.
     * @function verify
     * @memberof client_proto.OnOffResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    OnOffResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.config != null && message.hasOwnProperty("config"))
        if (!$util.isString(message.config)) return "config: string expected";
      return null;
    };

    /**
     * Creates an OnOffResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.OnOffResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.OnOffResp} OnOffResp
     */
    OnOffResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.OnOffResp) return object;
      var message = new $root.client_proto.OnOffResp();
      if (object.config != null) message.config = String(object.config);
      return message;
    };

    /**
     * Creates a plain object from an OnOffResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.OnOffResp
     * @static
     * @param {client_proto.OnOffResp} message OnOffResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    OnOffResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.config = "";
      if (message.config != null && message.hasOwnProperty("config")) object.config = message.config;
      return object;
    };

    /**
     * Converts this OnOffResp to JSON.
     * @function toJSON
     * @memberof client_proto.OnOffResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    OnOffResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for OnOffResp
     * @function getTypeUrl
     * @memberof client_proto.OnOffResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    OnOffResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.OnOffResp";
    };

    return OnOffResp;
  })();

  /**
   * LOGIN_SUB_MSG_ID enum.
   * @name client_proto.LOGIN_SUB_MSG_ID
   * @enum {number}
   * @property {number} LSMI_LOGIN_NULL=0 LSMI_LOGIN_NULL value
   * @property {number} LSMI_LOGIN_ID_MIN=899 LSMI_LOGIN_ID_MIN value
   * @property {number} LSMI_LOGIN_REQ=901 LSMI_LOGIN_REQ value
   * @property {number} LSMI_LOGIN_RESP=902 LSMI_LOGIN_RESP value
   * @property {number} LSMI_LOGIN_PHP_REQ=903 LSMI_LOGIN_PHP_REQ value
   * @property {number} LSMI_LOGIN_PHP_RESP=904 LSMI_LOGIN_PHP_RESP value
   * @property {number} LSMI_LOGIN_BIND_REQ=905 LSMI_LOGIN_BIND_REQ value
   * @property {number} LSMI_LOGIN_BIND_RESP=906 LSMI_LOGIN_BIND_RESP value
   * @property {number} LSMI_REPORT_REG_DATA_REQ=909 LSMI_REPORT_REG_DATA_REQ value
   * @property {number} LSMI_REPORT_REG_DATA_RESP=910 LSMI_REPORT_REG_DATA_RESP value
   * @property {number} LSMI_REPORT_KOL_DATA_REQ=911 LSMI_REPORT_KOL_DATA_REQ value
   * @property {number} LSMI_REPORT_KOL_DATA_RESP=912 LSMI_REPORT_KOL_DATA_RESP value
   * @property {number} LSMI_LOGIN_ID_MAX=950 LSMI_LOGIN_ID_MAX value
   */
  client_proto.LOGIN_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "LSMI_LOGIN_NULL")] = 0;
    values[(valuesById[899] = "LSMI_LOGIN_ID_MIN")] = 899;
    values[(valuesById[901] = "LSMI_LOGIN_REQ")] = 901;
    values[(valuesById[902] = "LSMI_LOGIN_RESP")] = 902;
    values[(valuesById[903] = "LSMI_LOGIN_PHP_REQ")] = 903;
    values[(valuesById[904] = "LSMI_LOGIN_PHP_RESP")] = 904;
    values[(valuesById[905] = "LSMI_LOGIN_BIND_REQ")] = 905;
    values[(valuesById[906] = "LSMI_LOGIN_BIND_RESP")] = 906;
    values[(valuesById[909] = "LSMI_REPORT_REG_DATA_REQ")] = 909;
    values[(valuesById[910] = "LSMI_REPORT_REG_DATA_RESP")] = 910;
    values[(valuesById[911] = "LSMI_REPORT_KOL_DATA_REQ")] = 911;
    values[(valuesById[912] = "LSMI_REPORT_KOL_DATA_RESP")] = 912;
    values[(valuesById[950] = "LSMI_LOGIN_ID_MAX")] = 950;
    return values;
  })();

  client_proto.UserBasicInfo = (function () {
    /**
     * Properties of a UserBasicInfo.
     * @memberof client_proto
     * @interface IUserBasicInfo
     * @property {number|null} [uid] UserBasicInfo uid
     * @property {string|null} [user_name] UserBasicInfo user_name
     * @property {string|null} [user_mobile] UserBasicInfo user_mobile
     * @property {string|null} [user_email] UserBasicInfo user_email
     * @property {string|null} [user_avatar] UserBasicInfo user_avatar
     * @property {number|null} [user_gender] UserBasicInfo user_gender
     * @property {string|null} [package_name] UserBasicInfo package_name
     * @property {string|null} [channel_id] UserBasicInfo channel_id
     * @property {string|null} [cur_cli_ver] UserBasicInfo cur_cli_ver
     * @property {string|null} [invite_code] UserBasicInfo invite_code
     * @property {number|Long|null} [user_score] UserBasicInfo user_score
     * @property {number|Long|null} [user_level] UserBasicInfo user_level
     * @property {boolean|null} [pwd_set] UserBasicInfo pwd_set
     */

    /**
     * Constructs a new UserBasicInfo.
     * @memberof client_proto
     * @classdesc Represents a UserBasicInfo.
     * @implements IUserBasicInfo
     * @constructor
     * @param {client_proto.IUserBasicInfo=} [properties] Properties to set
     */
    function UserBasicInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserBasicInfo uid.
     * @member {number} uid
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.uid = 0;

    /**
     * UserBasicInfo user_name.
     * @member {string} user_name
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.user_name = "";

    /**
     * UserBasicInfo user_mobile.
     * @member {string} user_mobile
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.user_mobile = "";

    /**
     * UserBasicInfo user_email.
     * @member {string} user_email
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.user_email = "";

    /**
     * UserBasicInfo user_avatar.
     * @member {string} user_avatar
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.user_avatar = "";

    /**
     * UserBasicInfo user_gender.
     * @member {number} user_gender
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.user_gender = 0;

    /**
     * UserBasicInfo package_name.
     * @member {string} package_name
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.package_name = "";

    /**
     * UserBasicInfo channel_id.
     * @member {string} channel_id
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.channel_id = "";

    /**
     * UserBasicInfo cur_cli_ver.
     * @member {string} cur_cli_ver
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.cur_cli_ver = "";

    /**
     * UserBasicInfo invite_code.
     * @member {string} invite_code
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.invite_code = "";

    /**
     * UserBasicInfo user_score.
     * @member {number|Long} user_score
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.user_score = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * UserBasicInfo user_level.
     * @member {number|Long} user_level
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.user_level = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * UserBasicInfo pwd_set.
     * @member {boolean} pwd_set
     * @memberof client_proto.UserBasicInfo
     * @instance
     */
    UserBasicInfo.prototype.pwd_set = false;

    /**
     * Creates a new UserBasicInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.UserBasicInfo
     * @static
     * @param {client_proto.IUserBasicInfo=} [properties] Properties to set
     * @returns {client_proto.UserBasicInfo} UserBasicInfo instance
     */
    UserBasicInfo.create = function create(properties) {
      return new UserBasicInfo(properties);
    };

    /**
     * Encodes the specified UserBasicInfo message. Does not implicitly {@link client_proto.UserBasicInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserBasicInfo
     * @static
     * @param {client_proto.IUserBasicInfo} message UserBasicInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserBasicInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.user_name != null && Object.hasOwnProperty.call(message, "user_name"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.user_name);
      if (message.user_mobile != null && Object.hasOwnProperty.call(message, "user_mobile"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.user_mobile);
      if (message.user_email != null && Object.hasOwnProperty.call(message, "user_email"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.user_email);
      if (message.user_avatar != null && Object.hasOwnProperty.call(message, "user_avatar"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.user_avatar);
      if (message.user_gender != null && Object.hasOwnProperty.call(message, "user_gender"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.user_gender);
      if (message.package_name != null && Object.hasOwnProperty.call(message, "package_name"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.package_name);
      if (message.channel_id != null && Object.hasOwnProperty.call(message, "channel_id"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.channel_id);
      if (message.cur_cli_ver != null && Object.hasOwnProperty.call(message, "cur_cli_ver"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.cur_cli_ver);
      if (message.invite_code != null && Object.hasOwnProperty.call(message, "invite_code"))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.invite_code);
      if (message.user_score != null && Object.hasOwnProperty.call(message, "user_score"))
        writer.uint32(/* id 11, wireType 0 =*/ 88).int64(message.user_score);
      if (message.user_level != null && Object.hasOwnProperty.call(message, "user_level"))
        writer.uint32(/* id 12, wireType 0 =*/ 96).int64(message.user_level);
      if (message.pwd_set != null && Object.hasOwnProperty.call(message, "pwd_set"))
        writer.uint32(/* id 13, wireType 0 =*/ 104).bool(message.pwd_set);
      return writer;
    };

    /**
     * Encodes the specified UserBasicInfo message, length delimited. Does not implicitly {@link client_proto.UserBasicInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserBasicInfo
     * @static
     * @param {client_proto.IUserBasicInfo} message UserBasicInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserBasicInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserBasicInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserBasicInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserBasicInfo} UserBasicInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserBasicInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserBasicInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.user_name = reader.string();
            break;
          }
          case 3: {
            message.user_mobile = reader.string();
            break;
          }
          case 4: {
            message.user_email = reader.string();
            break;
          }
          case 5: {
            message.user_avatar = reader.string();
            break;
          }
          case 6: {
            message.user_gender = reader.int32();
            break;
          }
          case 7: {
            message.package_name = reader.string();
            break;
          }
          case 8: {
            message.channel_id = reader.string();
            break;
          }
          case 9: {
            message.cur_cli_ver = reader.string();
            break;
          }
          case 10: {
            message.invite_code = reader.string();
            break;
          }
          case 11: {
            message.user_score = reader.int64();
            break;
          }
          case 12: {
            message.user_level = reader.int64();
            break;
          }
          case 13: {
            message.pwd_set = reader.bool();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserBasicInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserBasicInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserBasicInfo} UserBasicInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserBasicInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserBasicInfo message.
     * @function verify
     * @memberof client_proto.UserBasicInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserBasicInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.user_name != null && message.hasOwnProperty("user_name"))
        if (!$util.isString(message.user_name)) return "user_name: string expected";
      if (message.user_mobile != null && message.hasOwnProperty("user_mobile"))
        if (!$util.isString(message.user_mobile)) return "user_mobile: string expected";
      if (message.user_email != null && message.hasOwnProperty("user_email"))
        if (!$util.isString(message.user_email)) return "user_email: string expected";
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        if (!$util.isString(message.user_avatar)) return "user_avatar: string expected";
      if (message.user_gender != null && message.hasOwnProperty("user_gender"))
        if (!$util.isInteger(message.user_gender)) return "user_gender: integer expected";
      if (message.package_name != null && message.hasOwnProperty("package_name"))
        if (!$util.isString(message.package_name)) return "package_name: string expected";
      if (message.channel_id != null && message.hasOwnProperty("channel_id"))
        if (!$util.isString(message.channel_id)) return "channel_id: string expected";
      if (message.cur_cli_ver != null && message.hasOwnProperty("cur_cli_ver"))
        if (!$util.isString(message.cur_cli_ver)) return "cur_cli_ver: string expected";
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        if (!$util.isString(message.invite_code)) return "invite_code: string expected";
      if (message.user_score != null && message.hasOwnProperty("user_score"))
        if (
          !$util.isInteger(message.user_score) &&
          !(message.user_score && $util.isInteger(message.user_score.low) && $util.isInteger(message.user_score.high))
        )
          return "user_score: integer|Long expected";
      if (message.user_level != null && message.hasOwnProperty("user_level"))
        if (
          !$util.isInteger(message.user_level) &&
          !(message.user_level && $util.isInteger(message.user_level.low) && $util.isInteger(message.user_level.high))
        )
          return "user_level: integer|Long expected";
      if (message.pwd_set != null && message.hasOwnProperty("pwd_set"))
        if (typeof message.pwd_set !== "boolean") return "pwd_set: boolean expected";
      return null;
    };

    /**
     * Creates a UserBasicInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserBasicInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserBasicInfo} UserBasicInfo
     */
    UserBasicInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserBasicInfo) return object;
      var message = new $root.client_proto.UserBasicInfo();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.user_name != null) message.user_name = String(object.user_name);
      if (object.user_mobile != null) message.user_mobile = String(object.user_mobile);
      if (object.user_email != null) message.user_email = String(object.user_email);
      if (object.user_avatar != null) message.user_avatar = String(object.user_avatar);
      if (object.user_gender != null) message.user_gender = object.user_gender | 0;
      if (object.package_name != null) message.package_name = String(object.package_name);
      if (object.channel_id != null) message.channel_id = String(object.channel_id);
      if (object.cur_cli_ver != null) message.cur_cli_ver = String(object.cur_cli_ver);
      if (object.invite_code != null) message.invite_code = String(object.invite_code);
      if (object.user_score != null)
        if ($util.Long) (message.user_score = $util.Long.fromValue(object.user_score)).unsigned = false;
        else if (typeof object.user_score === "string") message.user_score = parseInt(object.user_score, 10);
        else if (typeof object.user_score === "number") message.user_score = object.user_score;
        else if (typeof object.user_score === "object")
          message.user_score = new $util.LongBits(object.user_score.low >>> 0, object.user_score.high >>> 0).toNumber();
      if (object.user_level != null)
        if ($util.Long) (message.user_level = $util.Long.fromValue(object.user_level)).unsigned = false;
        else if (typeof object.user_level === "string") message.user_level = parseInt(object.user_level, 10);
        else if (typeof object.user_level === "number") message.user_level = object.user_level;
        else if (typeof object.user_level === "object")
          message.user_level = new $util.LongBits(object.user_level.low >>> 0, object.user_level.high >>> 0).toNumber();
      if (object.pwd_set != null) message.pwd_set = Boolean(object.pwd_set);
      return message;
    };

    /**
     * Creates a plain object from a UserBasicInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserBasicInfo
     * @static
     * @param {client_proto.UserBasicInfo} message UserBasicInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserBasicInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.user_name = "";
        object.user_mobile = "";
        object.user_email = "";
        object.user_avatar = "";
        object.user_gender = 0;
        object.package_name = "";
        object.channel_id = "";
        object.cur_cli_ver = "";
        object.invite_code = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.user_score =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.user_score = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.user_level =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.user_level = options.longs === String ? "0" : 0;
        object.pwd_set = false;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.user_name != null && message.hasOwnProperty("user_name")) object.user_name = message.user_name;
      if (message.user_mobile != null && message.hasOwnProperty("user_mobile"))
        object.user_mobile = message.user_mobile;
      if (message.user_email != null && message.hasOwnProperty("user_email")) object.user_email = message.user_email;
      if (message.user_avatar != null && message.hasOwnProperty("user_avatar"))
        object.user_avatar = message.user_avatar;
      if (message.user_gender != null && message.hasOwnProperty("user_gender"))
        object.user_gender = message.user_gender;
      if (message.package_name != null && message.hasOwnProperty("package_name"))
        object.package_name = message.package_name;
      if (message.channel_id != null && message.hasOwnProperty("channel_id")) object.channel_id = message.channel_id;
      if (message.cur_cli_ver != null && message.hasOwnProperty("cur_cli_ver"))
        object.cur_cli_ver = message.cur_cli_ver;
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        object.invite_code = message.invite_code;
      if (message.user_score != null && message.hasOwnProperty("user_score"))
        if (typeof message.user_score === "number")
          object.user_score = options.longs === String ? String(message.user_score) : message.user_score;
        else
          object.user_score =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.user_score)
              : options.longs === Number
                ? new $util.LongBits(message.user_score.low >>> 0, message.user_score.high >>> 0).toNumber()
                : message.user_score;
      if (message.user_level != null && message.hasOwnProperty("user_level"))
        if (typeof message.user_level === "number")
          object.user_level = options.longs === String ? String(message.user_level) : message.user_level;
        else
          object.user_level =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.user_level)
              : options.longs === Number
                ? new $util.LongBits(message.user_level.low >>> 0, message.user_level.high >>> 0).toNumber()
                : message.user_level;
      if (message.pwd_set != null && message.hasOwnProperty("pwd_set")) object.pwd_set = message.pwd_set;
      return object;
    };

    /**
     * Converts this UserBasicInfo to JSON.
     * @function toJSON
     * @memberof client_proto.UserBasicInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserBasicInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserBasicInfo
     * @function getTypeUrl
     * @memberof client_proto.UserBasicInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserBasicInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserBasicInfo";
    };

    return UserBasicInfo;
  })();

  client_proto.LoginReq = (function () {
    /**
     * Properties of a LoginReq.
     * @memberof client_proto
     * @interface ILoginReq
     * @property {string|null} [login_token] LoginReq login_token
     * @property {string|null} [login_ip] LoginReq login_ip
     * @property {string|null} [lang] LoginReq lang
     * @property {string|null} [client_version] LoginReq client_version
     * @property {string|null} [os_version] LoginReq os_version
     * @property {string|null} [device_id] LoginReq device_id
     * @property {string|null} [device_type] LoginReq device_type
     * @property {string|null} [network_type] LoginReq network_type
     */

    /**
     * Constructs a new LoginReq.
     * @memberof client_proto
     * @classdesc Represents a LoginReq.
     * @implements ILoginReq
     * @constructor
     * @param {client_proto.ILoginReq=} [properties] Properties to set
     */
    function LoginReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginReq login_token.
     * @member {string} login_token
     * @memberof client_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.login_token = "";

    /**
     * LoginReq login_ip.
     * @member {string} login_ip
     * @memberof client_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.login_ip = "";

    /**
     * LoginReq lang.
     * @member {string} lang
     * @memberof client_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.lang = "";

    /**
     * LoginReq client_version.
     * @member {string} client_version
     * @memberof client_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.client_version = "";

    /**
     * LoginReq os_version.
     * @member {string} os_version
     * @memberof client_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.os_version = "";

    /**
     * LoginReq device_id.
     * @member {string} device_id
     * @memberof client_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.device_id = "";

    /**
     * LoginReq device_type.
     * @member {string} device_type
     * @memberof client_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.device_type = "";

    /**
     * LoginReq network_type.
     * @member {string} network_type
     * @memberof client_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.network_type = "";

    /**
     * Creates a new LoginReq instance using the specified properties.
     * @function create
     * @memberof client_proto.LoginReq
     * @static
     * @param {client_proto.ILoginReq=} [properties] Properties to set
     * @returns {client_proto.LoginReq} LoginReq instance
     */
    LoginReq.create = function create(properties) {
      return new LoginReq(properties);
    };

    /**
     * Encodes the specified LoginReq message. Does not implicitly {@link client_proto.LoginReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.LoginReq
     * @static
     * @param {client_proto.ILoginReq} message LoginReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.login_token != null && Object.hasOwnProperty.call(message, "login_token"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.login_token);
      if (message.login_ip != null && Object.hasOwnProperty.call(message, "login_ip"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.login_ip);
      if (message.lang != null && Object.hasOwnProperty.call(message, "lang"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.lang);
      if (message.client_version != null && Object.hasOwnProperty.call(message, "client_version"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.client_version);
      if (message.os_version != null && Object.hasOwnProperty.call(message, "os_version"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.os_version);
      if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.device_id);
      if (message.device_type != null && Object.hasOwnProperty.call(message, "device_type"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.device_type);
      if (message.network_type != null && Object.hasOwnProperty.call(message, "network_type"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.network_type);
      return writer;
    };

    /**
     * Encodes the specified LoginReq message, length delimited. Does not implicitly {@link client_proto.LoginReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.LoginReq
     * @static
     * @param {client_proto.ILoginReq} message LoginReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LoginReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.LoginReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.LoginReq} LoginReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.LoginReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.login_token = reader.string();
            break;
          }
          case 2: {
            message.login_ip = reader.string();
            break;
          }
          case 3: {
            message.lang = reader.string();
            break;
          }
          case 4: {
            message.client_version = reader.string();
            break;
          }
          case 5: {
            message.os_version = reader.string();
            break;
          }
          case 6: {
            message.device_id = reader.string();
            break;
          }
          case 7: {
            message.device_type = reader.string();
            break;
          }
          case 8: {
            message.network_type = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LoginReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.LoginReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.LoginReq} LoginReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LoginReq message.
     * @function verify
     * @memberof client_proto.LoginReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.login_token != null && message.hasOwnProperty("login_token"))
        if (!$util.isString(message.login_token)) return "login_token: string expected";
      if (message.login_ip != null && message.hasOwnProperty("login_ip"))
        if (!$util.isString(message.login_ip)) return "login_ip: string expected";
      if (message.lang != null && message.hasOwnProperty("lang"))
        if (!$util.isString(message.lang)) return "lang: string expected";
      if (message.client_version != null && message.hasOwnProperty("client_version"))
        if (!$util.isString(message.client_version)) return "client_version: string expected";
      if (message.os_version != null && message.hasOwnProperty("os_version"))
        if (!$util.isString(message.os_version)) return "os_version: string expected";
      if (message.device_id != null && message.hasOwnProperty("device_id"))
        if (!$util.isString(message.device_id)) return "device_id: string expected";
      if (message.device_type != null && message.hasOwnProperty("device_type"))
        if (!$util.isString(message.device_type)) return "device_type: string expected";
      if (message.network_type != null && message.hasOwnProperty("network_type"))
        if (!$util.isString(message.network_type)) return "network_type: string expected";
      return null;
    };

    /**
     * Creates a LoginReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.LoginReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.LoginReq} LoginReq
     */
    LoginReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.LoginReq) return object;
      var message = new $root.client_proto.LoginReq();
      if (object.login_token != null) message.login_token = String(object.login_token);
      if (object.login_ip != null) message.login_ip = String(object.login_ip);
      if (object.lang != null) message.lang = String(object.lang);
      if (object.client_version != null) message.client_version = String(object.client_version);
      if (object.os_version != null) message.os_version = String(object.os_version);
      if (object.device_id != null) message.device_id = String(object.device_id);
      if (object.device_type != null) message.device_type = String(object.device_type);
      if (object.network_type != null) message.network_type = String(object.network_type);
      return message;
    };

    /**
     * Creates a plain object from a LoginReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.LoginReq
     * @static
     * @param {client_proto.LoginReq} message LoginReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.login_token = "";
        object.login_ip = "";
        object.lang = "";
        object.client_version = "";
        object.os_version = "";
        object.device_id = "";
        object.device_type = "";
        object.network_type = "";
      }
      if (message.login_token != null && message.hasOwnProperty("login_token"))
        object.login_token = message.login_token;
      if (message.login_ip != null && message.hasOwnProperty("login_ip")) object.login_ip = message.login_ip;
      if (message.lang != null && message.hasOwnProperty("lang")) object.lang = message.lang;
      if (message.client_version != null && message.hasOwnProperty("client_version"))
        object.client_version = message.client_version;
      if (message.os_version != null && message.hasOwnProperty("os_version")) object.os_version = message.os_version;
      if (message.device_id != null && message.hasOwnProperty("device_id")) object.device_id = message.device_id;
      if (message.device_type != null && message.hasOwnProperty("device_type"))
        object.device_type = message.device_type;
      if (message.network_type != null && message.hasOwnProperty("network_type"))
        object.network_type = message.network_type;
      return object;
    };

    /**
     * Converts this LoginReq to JSON.
     * @function toJSON
     * @memberof client_proto.LoginReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LoginReq
     * @function getTypeUrl
     * @memberof client_proto.LoginReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LoginReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.LoginReq";
    };

    return LoginReq;
  })();

  /**
   * IS_BIND_PHONE_DEF enum.
   * @name client_proto.IS_BIND_PHONE_DEF
   * @enum {number}
   * @property {number} IBPD_NULL=0 IBPD_NULL value
   * @property {number} IBPD_YES=1 IBPD_YES value
   * @property {number} IBPD_NO=2 IBPD_NO value
   * @property {number} IBPD_NOT_SUPPORT=3 IBPD_NOT_SUPPORT value
   */
  client_proto.IS_BIND_PHONE_DEF = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "IBPD_NULL")] = 0;
    values[(valuesById[1] = "IBPD_YES")] = 1;
    values[(valuesById[2] = "IBPD_NO")] = 2;
    values[(valuesById[3] = "IBPD_NOT_SUPPORT")] = 3;
    return values;
  })();

  /**
   * LOGIN_ERR_CODE enum.
   * @name client_proto.LOGIN_ERR_CODE
   * @enum {number}
   * @property {number} LOGIN_ERR_NULL=0 LOGIN_ERR_NULL value
   * @property {number} LOGIN_ERR_USER_NOTEXIST=1 LOGIN_ERR_USER_NOTEXIST value
   * @property {number} LOGIN_ERR_TOKEN_INVALID=2 LOGIN_ERR_TOKEN_INVALID value
   * @property {number} LOGIN_ERR_FORBIDDEN=3 LOGIN_ERR_FORBIDDEN value
   * @property {number} LOGIN_ERR_MOBILE_INVALID=4 LOGIN_ERR_MOBILE_INVALID value
   * @property {number} LOGIN_ERR_PARAM_INVALID=5 LOGIN_ERR_PARAM_INVALID value
   */
  client_proto.LOGIN_ERR_CODE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "LOGIN_ERR_NULL")] = 0;
    values[(valuesById[1] = "LOGIN_ERR_USER_NOTEXIST")] = 1;
    values[(valuesById[2] = "LOGIN_ERR_TOKEN_INVALID")] = 2;
    values[(valuesById[3] = "LOGIN_ERR_FORBIDDEN")] = 3;
    values[(valuesById[4] = "LOGIN_ERR_MOBILE_INVALID")] = 4;
    values[(valuesById[5] = "LOGIN_ERR_PARAM_INVALID")] = 5;
    return values;
  })();

  client_proto.UserGameInfo = (function () {
    /**
     * Properties of a UserGameInfo.
     * @memberof client_proto
     * @interface IUserGameInfo
     * @property {number|null} [game_id] UserGameInfo game_id
     * @property {number|null} [game_srvtype] UserGameInfo game_srvtype
     * @property {number|null} [game_svid] UserGameInfo game_svid
     * @property {number|null} [game_tid] UserGameInfo game_tid
     * @property {string|null} [game_plat] UserGameInfo game_plat
     */

    /**
     * Constructs a new UserGameInfo.
     * @memberof client_proto
     * @classdesc Represents a UserGameInfo.
     * @implements IUserGameInfo
     * @constructor
     * @param {client_proto.IUserGameInfo=} [properties] Properties to set
     */
    function UserGameInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserGameInfo game_id.
     * @member {number} game_id
     * @memberof client_proto.UserGameInfo
     * @instance
     */
    UserGameInfo.prototype.game_id = 0;

    /**
     * UserGameInfo game_srvtype.
     * @member {number} game_srvtype
     * @memberof client_proto.UserGameInfo
     * @instance
     */
    UserGameInfo.prototype.game_srvtype = 0;

    /**
     * UserGameInfo game_svid.
     * @member {number} game_svid
     * @memberof client_proto.UserGameInfo
     * @instance
     */
    UserGameInfo.prototype.game_svid = 0;

    /**
     * UserGameInfo game_tid.
     * @member {number} game_tid
     * @memberof client_proto.UserGameInfo
     * @instance
     */
    UserGameInfo.prototype.game_tid = 0;

    /**
     * UserGameInfo game_plat.
     * @member {string} game_plat
     * @memberof client_proto.UserGameInfo
     * @instance
     */
    UserGameInfo.prototype.game_plat = "";

    /**
     * Creates a new UserGameInfo instance using the specified properties.
     * @function create
     * @memberof client_proto.UserGameInfo
     * @static
     * @param {client_proto.IUserGameInfo=} [properties] Properties to set
     * @returns {client_proto.UserGameInfo} UserGameInfo instance
     */
    UserGameInfo.create = function create(properties) {
      return new UserGameInfo(properties);
    };

    /**
     * Encodes the specified UserGameInfo message. Does not implicitly {@link client_proto.UserGameInfo.verify|verify} messages.
     * @function encode
     * @memberof client_proto.UserGameInfo
     * @static
     * @param {client_proto.IUserGameInfo} message UserGameInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserGameInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_id != null && Object.hasOwnProperty.call(message, "game_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.game_id);
      if (message.game_srvtype != null && Object.hasOwnProperty.call(message, "game_srvtype"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.game_srvtype);
      if (message.game_svid != null && Object.hasOwnProperty.call(message, "game_svid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.game_svid);
      if (message.game_tid != null && Object.hasOwnProperty.call(message, "game_tid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.game_tid);
      if (message.game_plat != null && Object.hasOwnProperty.call(message, "game_plat"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.game_plat);
      return writer;
    };

    /**
     * Encodes the specified UserGameInfo message, length delimited. Does not implicitly {@link client_proto.UserGameInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.UserGameInfo
     * @static
     * @param {client_proto.IUserGameInfo} message UserGameInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserGameInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserGameInfo message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.UserGameInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.UserGameInfo} UserGameInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserGameInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.UserGameInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.game_id = reader.int32();
            break;
          }
          case 2: {
            message.game_srvtype = reader.int32();
            break;
          }
          case 3: {
            message.game_svid = reader.int32();
            break;
          }
          case 4: {
            message.game_tid = reader.int32();
            break;
          }
          case 5: {
            message.game_plat = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserGameInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.UserGameInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.UserGameInfo} UserGameInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserGameInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserGameInfo message.
     * @function verify
     * @memberof client_proto.UserGameInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserGameInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_id != null && message.hasOwnProperty("game_id"))
        if (!$util.isInteger(message.game_id)) return "game_id: integer expected";
      if (message.game_srvtype != null && message.hasOwnProperty("game_srvtype"))
        if (!$util.isInteger(message.game_srvtype)) return "game_srvtype: integer expected";
      if (message.game_svid != null && message.hasOwnProperty("game_svid"))
        if (!$util.isInteger(message.game_svid)) return "game_svid: integer expected";
      if (message.game_tid != null && message.hasOwnProperty("game_tid"))
        if (!$util.isInteger(message.game_tid)) return "game_tid: integer expected";
      if (message.game_plat != null && message.hasOwnProperty("game_plat"))
        if (!$util.isString(message.game_plat)) return "game_plat: string expected";
      return null;
    };

    /**
     * Creates a UserGameInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.UserGameInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.UserGameInfo} UserGameInfo
     */
    UserGameInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.UserGameInfo) return object;
      var message = new $root.client_proto.UserGameInfo();
      if (object.game_id != null) message.game_id = object.game_id | 0;
      if (object.game_srvtype != null) message.game_srvtype = object.game_srvtype | 0;
      if (object.game_svid != null) message.game_svid = object.game_svid | 0;
      if (object.game_tid != null) message.game_tid = object.game_tid | 0;
      if (object.game_plat != null) message.game_plat = String(object.game_plat);
      return message;
    };

    /**
     * Creates a plain object from a UserGameInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.UserGameInfo
     * @static
     * @param {client_proto.UserGameInfo} message UserGameInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserGameInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.game_id = 0;
        object.game_srvtype = 0;
        object.game_svid = 0;
        object.game_tid = 0;
        object.game_plat = "";
      }
      if (message.game_id != null && message.hasOwnProperty("game_id")) object.game_id = message.game_id;
      if (message.game_srvtype != null && message.hasOwnProperty("game_srvtype"))
        object.game_srvtype = message.game_srvtype;
      if (message.game_svid != null && message.hasOwnProperty("game_svid")) object.game_svid = message.game_svid;
      if (message.game_tid != null && message.hasOwnProperty("game_tid")) object.game_tid = message.game_tid;
      if (message.game_plat != null && message.hasOwnProperty("game_plat")) object.game_plat = message.game_plat;
      return object;
    };

    /**
     * Converts this UserGameInfo to JSON.
     * @function toJSON
     * @memberof client_proto.UserGameInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserGameInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserGameInfo
     * @function getTypeUrl
     * @memberof client_proto.UserGameInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserGameInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.UserGameInfo";
    };

    return UserGameInfo;
  })();

  client_proto.LoginResp = (function () {
    /**
     * Properties of a LoginResp.
     * @memberof client_proto
     * @interface ILoginResp
     * @property {number|null} [result] LoginResp result
     * @property {number|null} [user_id] LoginResp user_id
     * @property {number|null} [temp_uin] LoginResp temp_uin
     * @property {client_proto.IUserBasicInfo|null} [basic_info] LoginResp basic_info
     * @property {number|Long|null} [timestamp] LoginResp timestamp
     * @property {client_proto.IUserGameInfo|null} [game_info] LoginResp game_info
     * @property {number|null} [login_type] LoginResp login_type
     * @property {number|Long|null} [zerohour] LoginResp zerohour
     */

    /**
     * Constructs a new LoginResp.
     * @memberof client_proto
     * @classdesc Represents a LoginResp.
     * @implements ILoginResp
     * @constructor
     * @param {client_proto.ILoginResp=} [properties] Properties to set
     */
    function LoginResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginResp result.
     * @member {number} result
     * @memberof client_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.result = 0;

    /**
     * LoginResp user_id.
     * @member {number} user_id
     * @memberof client_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.user_id = 0;

    /**
     * LoginResp temp_uin.
     * @member {number} temp_uin
     * @memberof client_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.temp_uin = 0;

    /**
     * LoginResp basic_info.
     * @member {client_proto.IUserBasicInfo|null|undefined} basic_info
     * @memberof client_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.basic_info = null;

    /**
     * LoginResp timestamp.
     * @member {number|Long} timestamp
     * @memberof client_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.timestamp = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * LoginResp game_info.
     * @member {client_proto.IUserGameInfo|null|undefined} game_info
     * @memberof client_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.game_info = null;

    /**
     * LoginResp login_type.
     * @member {number} login_type
     * @memberof client_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.login_type = 0;

    /**
     * LoginResp zerohour.
     * @member {number|Long} zerohour
     * @memberof client_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.zerohour = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new LoginResp instance using the specified properties.
     * @function create
     * @memberof client_proto.LoginResp
     * @static
     * @param {client_proto.ILoginResp=} [properties] Properties to set
     * @returns {client_proto.LoginResp} LoginResp instance
     */
    LoginResp.create = function create(properties) {
      return new LoginResp(properties);
    };

    /**
     * Encodes the specified LoginResp message. Does not implicitly {@link client_proto.LoginResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.LoginResp
     * @static
     * @param {client_proto.ILoginResp} message LoginResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.user_id != null && Object.hasOwnProperty.call(message, "user_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.user_id);
      if (message.temp_uin != null && Object.hasOwnProperty.call(message, "temp_uin"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.temp_uin);
      if (message.basic_info != null && Object.hasOwnProperty.call(message, "basic_info"))
        $root.client_proto.UserBasicInfo.encode(
          message.basic_info,
          writer.uint32(/* id 4, wireType 2 =*/ 34).fork(),
        ).ldelim();
      if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.timestamp);
      if (message.game_info != null && Object.hasOwnProperty.call(message, "game_info"))
        $root.client_proto.UserGameInfo.encode(
          message.game_info,
          writer.uint32(/* id 6, wireType 2 =*/ 50).fork(),
        ).ldelim();
      if (message.login_type != null && Object.hasOwnProperty.call(message, "login_type"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.login_type);
      if (message.zerohour != null && Object.hasOwnProperty.call(message, "zerohour"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.zerohour);
      return writer;
    };

    /**
     * Encodes the specified LoginResp message, length delimited. Does not implicitly {@link client_proto.LoginResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.LoginResp
     * @static
     * @param {client_proto.ILoginResp} message LoginResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LoginResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.LoginResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.LoginResp} LoginResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.LoginResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.user_id = reader.uint32();
            break;
          }
          case 3: {
            message.temp_uin = reader.uint32();
            break;
          }
          case 4: {
            message.basic_info = $root.client_proto.UserBasicInfo.decode(reader, reader.uint32());
            break;
          }
          case 5: {
            message.timestamp = reader.int64();
            break;
          }
          case 6: {
            message.game_info = $root.client_proto.UserGameInfo.decode(reader, reader.uint32());
            break;
          }
          case 7: {
            message.login_type = reader.int32();
            break;
          }
          case 8: {
            message.zerohour = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LoginResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.LoginResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.LoginResp} LoginResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LoginResp message.
     * @function verify
     * @memberof client_proto.LoginResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.user_id != null && message.hasOwnProperty("user_id"))
        if (!$util.isInteger(message.user_id)) return "user_id: integer expected";
      if (message.temp_uin != null && message.hasOwnProperty("temp_uin"))
        if (!$util.isInteger(message.temp_uin)) return "temp_uin: integer expected";
      if (message.basic_info != null && message.hasOwnProperty("basic_info")) {
        var error = $root.client_proto.UserBasicInfo.verify(message.basic_info);
        if (error) return "basic_info." + error;
      }
      if (message.timestamp != null && message.hasOwnProperty("timestamp"))
        if (
          !$util.isInteger(message.timestamp) &&
          !(message.timestamp && $util.isInteger(message.timestamp.low) && $util.isInteger(message.timestamp.high))
        )
          return "timestamp: integer|Long expected";
      if (message.game_info != null && message.hasOwnProperty("game_info")) {
        var error = $root.client_proto.UserGameInfo.verify(message.game_info);
        if (error) return "game_info." + error;
      }
      if (message.login_type != null && message.hasOwnProperty("login_type"))
        if (!$util.isInteger(message.login_type)) return "login_type: integer expected";
      if (message.zerohour != null && message.hasOwnProperty("zerohour"))
        if (
          !$util.isInteger(message.zerohour) &&
          !(message.zerohour && $util.isInteger(message.zerohour.low) && $util.isInteger(message.zerohour.high))
        )
          return "zerohour: integer|Long expected";
      return null;
    };

    /**
     * Creates a LoginResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.LoginResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.LoginResp} LoginResp
     */
    LoginResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.LoginResp) return object;
      var message = new $root.client_proto.LoginResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.user_id != null) message.user_id = object.user_id >>> 0;
      if (object.temp_uin != null) message.temp_uin = object.temp_uin >>> 0;
      if (object.basic_info != null) {
        if (typeof object.basic_info !== "object")
          throw TypeError(".client_proto.LoginResp.basic_info: object expected");
        message.basic_info = $root.client_proto.UserBasicInfo.fromObject(object.basic_info);
      }
      if (object.timestamp != null)
        if ($util.Long) (message.timestamp = $util.Long.fromValue(object.timestamp)).unsigned = false;
        else if (typeof object.timestamp === "string") message.timestamp = parseInt(object.timestamp, 10);
        else if (typeof object.timestamp === "number") message.timestamp = object.timestamp;
        else if (typeof object.timestamp === "object")
          message.timestamp = new $util.LongBits(object.timestamp.low >>> 0, object.timestamp.high >>> 0).toNumber();
      if (object.game_info != null) {
        if (typeof object.game_info !== "object") throw TypeError(".client_proto.LoginResp.game_info: object expected");
        message.game_info = $root.client_proto.UserGameInfo.fromObject(object.game_info);
      }
      if (object.login_type != null) message.login_type = object.login_type | 0;
      if (object.zerohour != null)
        if ($util.Long) (message.zerohour = $util.Long.fromValue(object.zerohour)).unsigned = false;
        else if (typeof object.zerohour === "string") message.zerohour = parseInt(object.zerohour, 10);
        else if (typeof object.zerohour === "number") message.zerohour = object.zerohour;
        else if (typeof object.zerohour === "object")
          message.zerohour = new $util.LongBits(object.zerohour.low >>> 0, object.zerohour.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a LoginResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.LoginResp
     * @static
     * @param {client_proto.LoginResp} message LoginResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.user_id = 0;
        object.temp_uin = 0;
        object.basic_info = null;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.timestamp =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.timestamp = options.longs === String ? "0" : 0;
        object.game_info = null;
        object.login_type = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.zerohour =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.zerohour = options.longs === String ? "0" : 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.user_id != null && message.hasOwnProperty("user_id")) object.user_id = message.user_id;
      if (message.temp_uin != null && message.hasOwnProperty("temp_uin")) object.temp_uin = message.temp_uin;
      if (message.basic_info != null && message.hasOwnProperty("basic_info"))
        object.basic_info = $root.client_proto.UserBasicInfo.toObject(message.basic_info, options);
      if (message.timestamp != null && message.hasOwnProperty("timestamp"))
        if (typeof message.timestamp === "number")
          object.timestamp = options.longs === String ? String(message.timestamp) : message.timestamp;
        else
          object.timestamp =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.timestamp)
              : options.longs === Number
                ? new $util.LongBits(message.timestamp.low >>> 0, message.timestamp.high >>> 0).toNumber()
                : message.timestamp;
      if (message.game_info != null && message.hasOwnProperty("game_info"))
        object.game_info = $root.client_proto.UserGameInfo.toObject(message.game_info, options);
      if (message.login_type != null && message.hasOwnProperty("login_type")) object.login_type = message.login_type;
      if (message.zerohour != null && message.hasOwnProperty("zerohour"))
        if (typeof message.zerohour === "number")
          object.zerohour = options.longs === String ? String(message.zerohour) : message.zerohour;
        else
          object.zerohour =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.zerohour)
              : options.longs === Number
                ? new $util.LongBits(message.zerohour.low >>> 0, message.zerohour.high >>> 0).toNumber()
                : message.zerohour;
      return object;
    };

    /**
     * Converts this LoginResp to JSON.
     * @function toJSON
     * @memberof client_proto.LoginResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LoginResp
     * @function getTypeUrl
     * @memberof client_proto.LoginResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LoginResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.LoginResp";
    };

    return LoginResp;
  })();

  /**
   * LOGIN_TYPE_DEF enum.
   * @name client_proto.LOGIN_TYPE_DEF
   * @enum {number}
   * @property {number} LTD_NULL=0 LTD_NULL value
   * @property {number} LTD_TOKEN=1 LTD_TOKEN value
   * @property {number} LTD_PASSWORD=2 LTD_PASSWORD value
   * @property {number} LTD_META=3 LTD_META value
   * @property {number} LTD_GOOGLE=4 LTD_GOOGLE value
   */
  client_proto.LOGIN_TYPE_DEF = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "LTD_NULL")] = 0;
    values[(valuesById[1] = "LTD_TOKEN")] = 1;
    values[(valuesById[2] = "LTD_PASSWORD")] = 2;
    values[(valuesById[3] = "LTD_META")] = 3;
    values[(valuesById[4] = "LTD_GOOGLE")] = 4;
    return values;
  })();

  client_proto.LoginPhpReq = (function () {
    /**
     * Properties of a LoginPhpReq.
     * @memberof client_proto
     * @interface ILoginPhpReq
     * @property {string|null} [channel] LoginPhpReq channel
     * @property {string|null} [device_id] LoginPhpReq device_id
     * @property {string|null} [login_ip] LoginPhpReq login_ip
     * @property {string|null} [fbid] LoginPhpReq fbid
     * @property {string|null} [pkg] LoginPhpReq pkg
     * @property {string|null} [version] LoginPhpReq version
     * @property {string|null} [telephone] LoginPhpReq telephone
     * @property {number|null} [login_type] LoginPhpReq login_type
     * @property {string|null} [param] LoginPhpReq param
     * @property {string|null} [custom_channel] LoginPhpReq custom_channel
     * @property {string|null} [open_id] LoginPhpReq open_id
     * @property {string|null} [email] LoginPhpReq email
     */

    /**
     * Constructs a new LoginPhpReq.
     * @memberof client_proto
     * @classdesc Represents a LoginPhpReq.
     * @implements ILoginPhpReq
     * @constructor
     * @param {client_proto.ILoginPhpReq=} [properties] Properties to set
     */
    function LoginPhpReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginPhpReq channel.
     * @member {string} channel
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.channel = "";

    /**
     * LoginPhpReq device_id.
     * @member {string} device_id
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.device_id = "";

    /**
     * LoginPhpReq login_ip.
     * @member {string} login_ip
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.login_ip = "";

    /**
     * LoginPhpReq fbid.
     * @member {string} fbid
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.fbid = "";

    /**
     * LoginPhpReq pkg.
     * @member {string} pkg
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.pkg = "";

    /**
     * LoginPhpReq version.
     * @member {string} version
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.version = "";

    /**
     * LoginPhpReq telephone.
     * @member {string} telephone
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.telephone = "";

    /**
     * LoginPhpReq login_type.
     * @member {number} login_type
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.login_type = 0;

    /**
     * LoginPhpReq param.
     * @member {string} param
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.param = "";

    /**
     * LoginPhpReq custom_channel.
     * @member {string} custom_channel
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.custom_channel = "";

    /**
     * LoginPhpReq open_id.
     * @member {string} open_id
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.open_id = "";

    /**
     * LoginPhpReq email.
     * @member {string} email
     * @memberof client_proto.LoginPhpReq
     * @instance
     */
    LoginPhpReq.prototype.email = "";

    /**
     * Creates a new LoginPhpReq instance using the specified properties.
     * @function create
     * @memberof client_proto.LoginPhpReq
     * @static
     * @param {client_proto.ILoginPhpReq=} [properties] Properties to set
     * @returns {client_proto.LoginPhpReq} LoginPhpReq instance
     */
    LoginPhpReq.create = function create(properties) {
      return new LoginPhpReq(properties);
    };

    /**
     * Encodes the specified LoginPhpReq message. Does not implicitly {@link client_proto.LoginPhpReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.LoginPhpReq
     * @static
     * @param {client_proto.ILoginPhpReq} message LoginPhpReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginPhpReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.channel);
      if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.device_id);
      if (message.login_ip != null && Object.hasOwnProperty.call(message, "login_ip"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.login_ip);
      if (message.fbid != null && Object.hasOwnProperty.call(message, "fbid"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.fbid);
      if (message.pkg != null && Object.hasOwnProperty.call(message, "pkg"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.pkg);
      if (message.version != null && Object.hasOwnProperty.call(message, "version"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.version);
      if (message.telephone != null && Object.hasOwnProperty.call(message, "telephone"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.telephone);
      if (message.login_type != null && Object.hasOwnProperty.call(message, "login_type"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.login_type);
      if (message.param != null && Object.hasOwnProperty.call(message, "param"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.param);
      if (message.custom_channel != null && Object.hasOwnProperty.call(message, "custom_channel"))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.custom_channel);
      if (message.open_id != null && Object.hasOwnProperty.call(message, "open_id"))
        writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.open_id);
      if (message.email != null && Object.hasOwnProperty.call(message, "email"))
        writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.email);
      return writer;
    };

    /**
     * Encodes the specified LoginPhpReq message, length delimited. Does not implicitly {@link client_proto.LoginPhpReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.LoginPhpReq
     * @static
     * @param {client_proto.ILoginPhpReq} message LoginPhpReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginPhpReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LoginPhpReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.LoginPhpReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.LoginPhpReq} LoginPhpReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginPhpReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.LoginPhpReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.channel = reader.string();
            break;
          }
          case 2: {
            message.device_id = reader.string();
            break;
          }
          case 3: {
            message.login_ip = reader.string();
            break;
          }
          case 4: {
            message.fbid = reader.string();
            break;
          }
          case 5: {
            message.pkg = reader.string();
            break;
          }
          case 6: {
            message.version = reader.string();
            break;
          }
          case 7: {
            message.telephone = reader.string();
            break;
          }
          case 8: {
            message.login_type = reader.int32();
            break;
          }
          case 9: {
            message.param = reader.string();
            break;
          }
          case 10: {
            message.custom_channel = reader.string();
            break;
          }
          case 11: {
            message.open_id = reader.string();
            break;
          }
          case 12: {
            message.email = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LoginPhpReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.LoginPhpReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.LoginPhpReq} LoginPhpReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginPhpReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LoginPhpReq message.
     * @function verify
     * @memberof client_proto.LoginPhpReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginPhpReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.channel != null && message.hasOwnProperty("channel"))
        if (!$util.isString(message.channel)) return "channel: string expected";
      if (message.device_id != null && message.hasOwnProperty("device_id"))
        if (!$util.isString(message.device_id)) return "device_id: string expected";
      if (message.login_ip != null && message.hasOwnProperty("login_ip"))
        if (!$util.isString(message.login_ip)) return "login_ip: string expected";
      if (message.fbid != null && message.hasOwnProperty("fbid"))
        if (!$util.isString(message.fbid)) return "fbid: string expected";
      if (message.pkg != null && message.hasOwnProperty("pkg"))
        if (!$util.isString(message.pkg)) return "pkg: string expected";
      if (message.version != null && message.hasOwnProperty("version"))
        if (!$util.isString(message.version)) return "version: string expected";
      if (message.telephone != null && message.hasOwnProperty("telephone"))
        if (!$util.isString(message.telephone)) return "telephone: string expected";
      if (message.login_type != null && message.hasOwnProperty("login_type"))
        if (!$util.isInteger(message.login_type)) return "login_type: integer expected";
      if (message.param != null && message.hasOwnProperty("param"))
        if (!$util.isString(message.param)) return "param: string expected";
      if (message.custom_channel != null && message.hasOwnProperty("custom_channel"))
        if (!$util.isString(message.custom_channel)) return "custom_channel: string expected";
      if (message.open_id != null && message.hasOwnProperty("open_id"))
        if (!$util.isString(message.open_id)) return "open_id: string expected";
      if (message.email != null && message.hasOwnProperty("email"))
        if (!$util.isString(message.email)) return "email: string expected";
      return null;
    };

    /**
     * Creates a LoginPhpReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.LoginPhpReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.LoginPhpReq} LoginPhpReq
     */
    LoginPhpReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.LoginPhpReq) return object;
      var message = new $root.client_proto.LoginPhpReq();
      if (object.channel != null) message.channel = String(object.channel);
      if (object.device_id != null) message.device_id = String(object.device_id);
      if (object.login_ip != null) message.login_ip = String(object.login_ip);
      if (object.fbid != null) message.fbid = String(object.fbid);
      if (object.pkg != null) message.pkg = String(object.pkg);
      if (object.version != null) message.version = String(object.version);
      if (object.telephone != null) message.telephone = String(object.telephone);
      if (object.login_type != null) message.login_type = object.login_type | 0;
      if (object.param != null) message.param = String(object.param);
      if (object.custom_channel != null) message.custom_channel = String(object.custom_channel);
      if (object.open_id != null) message.open_id = String(object.open_id);
      if (object.email != null) message.email = String(object.email);
      return message;
    };

    /**
     * Creates a plain object from a LoginPhpReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.LoginPhpReq
     * @static
     * @param {client_proto.LoginPhpReq} message LoginPhpReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginPhpReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.channel = "";
        object.device_id = "";
        object.login_ip = "";
        object.fbid = "";
        object.pkg = "";
        object.version = "";
        object.telephone = "";
        object.login_type = 0;
        object.param = "";
        object.custom_channel = "";
        object.open_id = "";
        object.email = "";
      }
      if (message.channel != null && message.hasOwnProperty("channel")) object.channel = message.channel;
      if (message.device_id != null && message.hasOwnProperty("device_id")) object.device_id = message.device_id;
      if (message.login_ip != null && message.hasOwnProperty("login_ip")) object.login_ip = message.login_ip;
      if (message.fbid != null && message.hasOwnProperty("fbid")) object.fbid = message.fbid;
      if (message.pkg != null && message.hasOwnProperty("pkg")) object.pkg = message.pkg;
      if (message.version != null && message.hasOwnProperty("version")) object.version = message.version;
      if (message.telephone != null && message.hasOwnProperty("telephone")) object.telephone = message.telephone;
      if (message.login_type != null && message.hasOwnProperty("login_type")) object.login_type = message.login_type;
      if (message.param != null && message.hasOwnProperty("param")) object.param = message.param;
      if (message.custom_channel != null && message.hasOwnProperty("custom_channel"))
        object.custom_channel = message.custom_channel;
      if (message.open_id != null && message.hasOwnProperty("open_id")) object.open_id = message.open_id;
      if (message.email != null && message.hasOwnProperty("email")) object.email = message.email;
      return object;
    };

    /**
     * Converts this LoginPhpReq to JSON.
     * @function toJSON
     * @memberof client_proto.LoginPhpReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginPhpReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LoginPhpReq
     * @function getTypeUrl
     * @memberof client_proto.LoginPhpReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LoginPhpReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.LoginPhpReq";
    };

    return LoginPhpReq;
  })();

  client_proto.LoginPhpResp = (function () {
    /**
     * Properties of a LoginPhpResp.
     * @memberof client_proto
     * @interface ILoginPhpResp
     * @property {number|null} [result] LoginPhpResp result
     * @property {string|null} [token] LoginPhpResp token
     * @property {number|null} [temp_uin] LoginPhpResp temp_uin
     * @property {number|null} [is_register] LoginPhpResp is_register
     */

    /**
     * Constructs a new LoginPhpResp.
     * @memberof client_proto
     * @classdesc Represents a LoginPhpResp.
     * @implements ILoginPhpResp
     * @constructor
     * @param {client_proto.ILoginPhpResp=} [properties] Properties to set
     */
    function LoginPhpResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginPhpResp result.
     * @member {number} result
     * @memberof client_proto.LoginPhpResp
     * @instance
     */
    LoginPhpResp.prototype.result = 0;

    /**
     * LoginPhpResp token.
     * @member {string} token
     * @memberof client_proto.LoginPhpResp
     * @instance
     */
    LoginPhpResp.prototype.token = "";

    /**
     * LoginPhpResp temp_uin.
     * @member {number} temp_uin
     * @memberof client_proto.LoginPhpResp
     * @instance
     */
    LoginPhpResp.prototype.temp_uin = 0;

    /**
     * LoginPhpResp is_register.
     * @member {number} is_register
     * @memberof client_proto.LoginPhpResp
     * @instance
     */
    LoginPhpResp.prototype.is_register = 0;

    /**
     * Creates a new LoginPhpResp instance using the specified properties.
     * @function create
     * @memberof client_proto.LoginPhpResp
     * @static
     * @param {client_proto.ILoginPhpResp=} [properties] Properties to set
     * @returns {client_proto.LoginPhpResp} LoginPhpResp instance
     */
    LoginPhpResp.create = function create(properties) {
      return new LoginPhpResp(properties);
    };

    /**
     * Encodes the specified LoginPhpResp message. Does not implicitly {@link client_proto.LoginPhpResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.LoginPhpResp
     * @static
     * @param {client_proto.ILoginPhpResp} message LoginPhpResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginPhpResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.token != null && Object.hasOwnProperty.call(message, "token"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.token);
      if (message.temp_uin != null && Object.hasOwnProperty.call(message, "temp_uin"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.temp_uin);
      if (message.is_register != null && Object.hasOwnProperty.call(message, "is_register"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.is_register);
      return writer;
    };

    /**
     * Encodes the specified LoginPhpResp message, length delimited. Does not implicitly {@link client_proto.LoginPhpResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.LoginPhpResp
     * @static
     * @param {client_proto.ILoginPhpResp} message LoginPhpResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginPhpResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LoginPhpResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.LoginPhpResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.LoginPhpResp} LoginPhpResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginPhpResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.LoginPhpResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.token = reader.string();
            break;
          }
          case 3: {
            message.temp_uin = reader.uint32();
            break;
          }
          case 4: {
            message.is_register = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LoginPhpResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.LoginPhpResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.LoginPhpResp} LoginPhpResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginPhpResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LoginPhpResp message.
     * @function verify
     * @memberof client_proto.LoginPhpResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginPhpResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.token != null && message.hasOwnProperty("token"))
        if (!$util.isString(message.token)) return "token: string expected";
      if (message.temp_uin != null && message.hasOwnProperty("temp_uin"))
        if (!$util.isInteger(message.temp_uin)) return "temp_uin: integer expected";
      if (message.is_register != null && message.hasOwnProperty("is_register"))
        if (!$util.isInteger(message.is_register)) return "is_register: integer expected";
      return null;
    };

    /**
     * Creates a LoginPhpResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.LoginPhpResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.LoginPhpResp} LoginPhpResp
     */
    LoginPhpResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.LoginPhpResp) return object;
      var message = new $root.client_proto.LoginPhpResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.token != null) message.token = String(object.token);
      if (object.temp_uin != null) message.temp_uin = object.temp_uin >>> 0;
      if (object.is_register != null) message.is_register = object.is_register | 0;
      return message;
    };

    /**
     * Creates a plain object from a LoginPhpResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.LoginPhpResp
     * @static
     * @param {client_proto.LoginPhpResp} message LoginPhpResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginPhpResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.token = "";
        object.temp_uin = 0;
        object.is_register = 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.token != null && message.hasOwnProperty("token")) object.token = message.token;
      if (message.temp_uin != null && message.hasOwnProperty("temp_uin")) object.temp_uin = message.temp_uin;
      if (message.is_register != null && message.hasOwnProperty("is_register"))
        object.is_register = message.is_register;
      return object;
    };

    /**
     * Converts this LoginPhpResp to JSON.
     * @function toJSON
     * @memberof client_proto.LoginPhpResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginPhpResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LoginPhpResp
     * @function getTypeUrl
     * @memberof client_proto.LoginPhpResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LoginPhpResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.LoginPhpResp";
    };

    return LoginPhpResp;
  })();

  client_proto.BindTelephoneReq = (function () {
    /**
     * Properties of a BindTelephoneReq.
     * @memberof client_proto
     * @interface IBindTelephoneReq
     * @property {string|null} [telephone] BindTelephoneReq telephone
     * @property {string|null} [device_id] BindTelephoneReq device_id
     * @property {string|null} [token] BindTelephoneReq token
     * @property {string|null} [channel] BindTelephoneReq channel
     * @property {string|null} [uid] BindTelephoneReq uid
     */

    /**
     * Constructs a new BindTelephoneReq.
     * @memberof client_proto
     * @classdesc Represents a BindTelephoneReq.
     * @implements IBindTelephoneReq
     * @constructor
     * @param {client_proto.IBindTelephoneReq=} [properties] Properties to set
     */
    function BindTelephoneReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * BindTelephoneReq telephone.
     * @member {string} telephone
     * @memberof client_proto.BindTelephoneReq
     * @instance
     */
    BindTelephoneReq.prototype.telephone = "";

    /**
     * BindTelephoneReq device_id.
     * @member {string} device_id
     * @memberof client_proto.BindTelephoneReq
     * @instance
     */
    BindTelephoneReq.prototype.device_id = "";

    /**
     * BindTelephoneReq token.
     * @member {string} token
     * @memberof client_proto.BindTelephoneReq
     * @instance
     */
    BindTelephoneReq.prototype.token = "";

    /**
     * BindTelephoneReq channel.
     * @member {string} channel
     * @memberof client_proto.BindTelephoneReq
     * @instance
     */
    BindTelephoneReq.prototype.channel = "";

    /**
     * BindTelephoneReq uid.
     * @member {string} uid
     * @memberof client_proto.BindTelephoneReq
     * @instance
     */
    BindTelephoneReq.prototype.uid = "";

    /**
     * Creates a new BindTelephoneReq instance using the specified properties.
     * @function create
     * @memberof client_proto.BindTelephoneReq
     * @static
     * @param {client_proto.IBindTelephoneReq=} [properties] Properties to set
     * @returns {client_proto.BindTelephoneReq} BindTelephoneReq instance
     */
    BindTelephoneReq.create = function create(properties) {
      return new BindTelephoneReq(properties);
    };

    /**
     * Encodes the specified BindTelephoneReq message. Does not implicitly {@link client_proto.BindTelephoneReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.BindTelephoneReq
     * @static
     * @param {client_proto.IBindTelephoneReq} message BindTelephoneReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BindTelephoneReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.telephone != null && Object.hasOwnProperty.call(message, "telephone"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.telephone);
      if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.device_id);
      if (message.token != null && Object.hasOwnProperty.call(message, "token"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.token);
      if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.channel);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.uid);
      return writer;
    };

    /**
     * Encodes the specified BindTelephoneReq message, length delimited. Does not implicitly {@link client_proto.BindTelephoneReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.BindTelephoneReq
     * @static
     * @param {client_proto.IBindTelephoneReq} message BindTelephoneReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BindTelephoneReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BindTelephoneReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.BindTelephoneReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.BindTelephoneReq} BindTelephoneReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BindTelephoneReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.BindTelephoneReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.telephone = reader.string();
            break;
          }
          case 2: {
            message.device_id = reader.string();
            break;
          }
          case 3: {
            message.token = reader.string();
            break;
          }
          case 4: {
            message.channel = reader.string();
            break;
          }
          case 5: {
            message.uid = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a BindTelephoneReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.BindTelephoneReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.BindTelephoneReq} BindTelephoneReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BindTelephoneReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BindTelephoneReq message.
     * @function verify
     * @memberof client_proto.BindTelephoneReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BindTelephoneReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.telephone != null && message.hasOwnProperty("telephone"))
        if (!$util.isString(message.telephone)) return "telephone: string expected";
      if (message.device_id != null && message.hasOwnProperty("device_id"))
        if (!$util.isString(message.device_id)) return "device_id: string expected";
      if (message.token != null && message.hasOwnProperty("token"))
        if (!$util.isString(message.token)) return "token: string expected";
      if (message.channel != null && message.hasOwnProperty("channel"))
        if (!$util.isString(message.channel)) return "channel: string expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isString(message.uid)) return "uid: string expected";
      return null;
    };

    /**
     * Creates a BindTelephoneReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.BindTelephoneReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.BindTelephoneReq} BindTelephoneReq
     */
    BindTelephoneReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.BindTelephoneReq) return object;
      var message = new $root.client_proto.BindTelephoneReq();
      if (object.telephone != null) message.telephone = String(object.telephone);
      if (object.device_id != null) message.device_id = String(object.device_id);
      if (object.token != null) message.token = String(object.token);
      if (object.channel != null) message.channel = String(object.channel);
      if (object.uid != null) message.uid = String(object.uid);
      return message;
    };

    /**
     * Creates a plain object from a BindTelephoneReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.BindTelephoneReq
     * @static
     * @param {client_proto.BindTelephoneReq} message BindTelephoneReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BindTelephoneReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.telephone = "";
        object.device_id = "";
        object.token = "";
        object.channel = "";
        object.uid = "";
      }
      if (message.telephone != null && message.hasOwnProperty("telephone")) object.telephone = message.telephone;
      if (message.device_id != null && message.hasOwnProperty("device_id")) object.device_id = message.device_id;
      if (message.token != null && message.hasOwnProperty("token")) object.token = message.token;
      if (message.channel != null && message.hasOwnProperty("channel")) object.channel = message.channel;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this BindTelephoneReq to JSON.
     * @function toJSON
     * @memberof client_proto.BindTelephoneReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BindTelephoneReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BindTelephoneReq
     * @function getTypeUrl
     * @memberof client_proto.BindTelephoneReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BindTelephoneReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.BindTelephoneReq";
    };

    return BindTelephoneReq;
  })();

  client_proto.BindTelephoneResp = (function () {
    /**
     * Properties of a BindTelephoneResp.
     * @memberof client_proto
     * @interface IBindTelephoneResp
     * @property {number|null} [result] BindTelephoneResp result
     */

    /**
     * Constructs a new BindTelephoneResp.
     * @memberof client_proto
     * @classdesc Represents a BindTelephoneResp.
     * @implements IBindTelephoneResp
     * @constructor
     * @param {client_proto.IBindTelephoneResp=} [properties] Properties to set
     */
    function BindTelephoneResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * BindTelephoneResp result.
     * @member {number} result
     * @memberof client_proto.BindTelephoneResp
     * @instance
     */
    BindTelephoneResp.prototype.result = 0;

    /**
     * Creates a new BindTelephoneResp instance using the specified properties.
     * @function create
     * @memberof client_proto.BindTelephoneResp
     * @static
     * @param {client_proto.IBindTelephoneResp=} [properties] Properties to set
     * @returns {client_proto.BindTelephoneResp} BindTelephoneResp instance
     */
    BindTelephoneResp.create = function create(properties) {
      return new BindTelephoneResp(properties);
    };

    /**
     * Encodes the specified BindTelephoneResp message. Does not implicitly {@link client_proto.BindTelephoneResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.BindTelephoneResp
     * @static
     * @param {client_proto.IBindTelephoneResp} message BindTelephoneResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BindTelephoneResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified BindTelephoneResp message, length delimited. Does not implicitly {@link client_proto.BindTelephoneResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.BindTelephoneResp
     * @static
     * @param {client_proto.IBindTelephoneResp} message BindTelephoneResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BindTelephoneResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BindTelephoneResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.BindTelephoneResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.BindTelephoneResp} BindTelephoneResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BindTelephoneResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.BindTelephoneResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a BindTelephoneResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.BindTelephoneResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.BindTelephoneResp} BindTelephoneResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BindTelephoneResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BindTelephoneResp message.
     * @function verify
     * @memberof client_proto.BindTelephoneResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BindTelephoneResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a BindTelephoneResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.BindTelephoneResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.BindTelephoneResp} BindTelephoneResp
     */
    BindTelephoneResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.BindTelephoneResp) return object;
      var message = new $root.client_proto.BindTelephoneResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a BindTelephoneResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.BindTelephoneResp
     * @static
     * @param {client_proto.BindTelephoneResp} message BindTelephoneResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BindTelephoneResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this BindTelephoneResp to JSON.
     * @function toJSON
     * @memberof client_proto.BindTelephoneResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BindTelephoneResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BindTelephoneResp
     * @function getTypeUrl
     * @memberof client_proto.BindTelephoneResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BindTelephoneResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.BindTelephoneResp";
    };

    return BindTelephoneResp;
  })();

  client_proto.ReportRegDataReq = (function () {
    /**
     * Properties of a ReportRegDataReq.
     * @memberof client_proto
     * @interface IReportRegDataReq
     * @property {string|null} [invite_code] ReportRegDataReq invite_code
     * @property {string|null} [af_id] ReportRegDataReq af_id
     * @property {string|null} [fb_id] ReportRegDataReq fb_id
     * @property {string|null} [channel] ReportRegDataReq channel
     * @property {string|null} [fbc] ReportRegDataReq fbc
     * @property {string|null} [fbp] ReportRegDataReq fbp
     * @property {string|null} [ua] ReportRegDataReq ua
     * @property {string|null} [device_id] ReportRegDataReq device_id
     * @property {string|null} [device_type] ReportRegDataReq device_type
     * @property {string|null} [os] ReportRegDataReq os
     * @property {string|null} [network_type] ReportRegDataReq network_type
     */

    /**
     * Constructs a new ReportRegDataReq.
     * @memberof client_proto
     * @classdesc Represents a ReportRegDataReq.
     * @implements IReportRegDataReq
     * @constructor
     * @param {client_proto.IReportRegDataReq=} [properties] Properties to set
     */
    function ReportRegDataReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ReportRegDataReq invite_code.
     * @member {string} invite_code
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.invite_code = "";

    /**
     * ReportRegDataReq af_id.
     * @member {string} af_id
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.af_id = "";

    /**
     * ReportRegDataReq fb_id.
     * @member {string} fb_id
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.fb_id = "";

    /**
     * ReportRegDataReq channel.
     * @member {string} channel
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.channel = "";

    /**
     * ReportRegDataReq fbc.
     * @member {string} fbc
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.fbc = "";

    /**
     * ReportRegDataReq fbp.
     * @member {string} fbp
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.fbp = "";

    /**
     * ReportRegDataReq ua.
     * @member {string} ua
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.ua = "";

    /**
     * ReportRegDataReq device_id.
     * @member {string} device_id
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.device_id = "";

    /**
     * ReportRegDataReq device_type.
     * @member {string} device_type
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.device_type = "";

    /**
     * ReportRegDataReq os.
     * @member {string} os
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.os = "";

    /**
     * ReportRegDataReq network_type.
     * @member {string} network_type
     * @memberof client_proto.ReportRegDataReq
     * @instance
     */
    ReportRegDataReq.prototype.network_type = "";

    /**
     * Creates a new ReportRegDataReq instance using the specified properties.
     * @function create
     * @memberof client_proto.ReportRegDataReq
     * @static
     * @param {client_proto.IReportRegDataReq=} [properties] Properties to set
     * @returns {client_proto.ReportRegDataReq} ReportRegDataReq instance
     */
    ReportRegDataReq.create = function create(properties) {
      return new ReportRegDataReq(properties);
    };

    /**
     * Encodes the specified ReportRegDataReq message. Does not implicitly {@link client_proto.ReportRegDataReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ReportRegDataReq
     * @static
     * @param {client_proto.IReportRegDataReq} message ReportRegDataReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReportRegDataReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.invite_code != null && Object.hasOwnProperty.call(message, "invite_code"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.invite_code);
      if (message.af_id != null && Object.hasOwnProperty.call(message, "af_id"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.af_id);
      if (message.fb_id != null && Object.hasOwnProperty.call(message, "fb_id"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.fb_id);
      if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.channel);
      if (message.fbc != null && Object.hasOwnProperty.call(message, "fbc"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.fbc);
      if (message.fbp != null && Object.hasOwnProperty.call(message, "fbp"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.fbp);
      if (message.ua != null && Object.hasOwnProperty.call(message, "ua"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.ua);
      if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.device_id);
      if (message.device_type != null && Object.hasOwnProperty.call(message, "device_type"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.device_type);
      if (message.os != null && Object.hasOwnProperty.call(message, "os"))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.os);
      if (message.network_type != null && Object.hasOwnProperty.call(message, "network_type"))
        writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.network_type);
      return writer;
    };

    /**
     * Encodes the specified ReportRegDataReq message, length delimited. Does not implicitly {@link client_proto.ReportRegDataReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ReportRegDataReq
     * @static
     * @param {client_proto.IReportRegDataReq} message ReportRegDataReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReportRegDataReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ReportRegDataReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ReportRegDataReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ReportRegDataReq} ReportRegDataReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReportRegDataReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ReportRegDataReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.invite_code = reader.string();
            break;
          }
          case 2: {
            message.af_id = reader.string();
            break;
          }
          case 3: {
            message.fb_id = reader.string();
            break;
          }
          case 4: {
            message.channel = reader.string();
            break;
          }
          case 5: {
            message.fbc = reader.string();
            break;
          }
          case 6: {
            message.fbp = reader.string();
            break;
          }
          case 7: {
            message.ua = reader.string();
            break;
          }
          case 8: {
            message.device_id = reader.string();
            break;
          }
          case 9: {
            message.device_type = reader.string();
            break;
          }
          case 10: {
            message.os = reader.string();
            break;
          }
          case 11: {
            message.network_type = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ReportRegDataReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ReportRegDataReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ReportRegDataReq} ReportRegDataReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReportRegDataReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ReportRegDataReq message.
     * @function verify
     * @memberof client_proto.ReportRegDataReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ReportRegDataReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        if (!$util.isString(message.invite_code)) return "invite_code: string expected";
      if (message.af_id != null && message.hasOwnProperty("af_id"))
        if (!$util.isString(message.af_id)) return "af_id: string expected";
      if (message.fb_id != null && message.hasOwnProperty("fb_id"))
        if (!$util.isString(message.fb_id)) return "fb_id: string expected";
      if (message.channel != null && message.hasOwnProperty("channel"))
        if (!$util.isString(message.channel)) return "channel: string expected";
      if (message.fbc != null && message.hasOwnProperty("fbc"))
        if (!$util.isString(message.fbc)) return "fbc: string expected";
      if (message.fbp != null && message.hasOwnProperty("fbp"))
        if (!$util.isString(message.fbp)) return "fbp: string expected";
      if (message.ua != null && message.hasOwnProperty("ua"))
        if (!$util.isString(message.ua)) return "ua: string expected";
      if (message.device_id != null && message.hasOwnProperty("device_id"))
        if (!$util.isString(message.device_id)) return "device_id: string expected";
      if (message.device_type != null && message.hasOwnProperty("device_type"))
        if (!$util.isString(message.device_type)) return "device_type: string expected";
      if (message.os != null && message.hasOwnProperty("os"))
        if (!$util.isString(message.os)) return "os: string expected";
      if (message.network_type != null && message.hasOwnProperty("network_type"))
        if (!$util.isString(message.network_type)) return "network_type: string expected";
      return null;
    };

    /**
     * Creates a ReportRegDataReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ReportRegDataReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ReportRegDataReq} ReportRegDataReq
     */
    ReportRegDataReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ReportRegDataReq) return object;
      var message = new $root.client_proto.ReportRegDataReq();
      if (object.invite_code != null) message.invite_code = String(object.invite_code);
      if (object.af_id != null) message.af_id = String(object.af_id);
      if (object.fb_id != null) message.fb_id = String(object.fb_id);
      if (object.channel != null) message.channel = String(object.channel);
      if (object.fbc != null) message.fbc = String(object.fbc);
      if (object.fbp != null) message.fbp = String(object.fbp);
      if (object.ua != null) message.ua = String(object.ua);
      if (object.device_id != null) message.device_id = String(object.device_id);
      if (object.device_type != null) message.device_type = String(object.device_type);
      if (object.os != null) message.os = String(object.os);
      if (object.network_type != null) message.network_type = String(object.network_type);
      return message;
    };

    /**
     * Creates a plain object from a ReportRegDataReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ReportRegDataReq
     * @static
     * @param {client_proto.ReportRegDataReq} message ReportRegDataReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ReportRegDataReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.invite_code = "";
        object.af_id = "";
        object.fb_id = "";
        object.channel = "";
        object.fbc = "";
        object.fbp = "";
        object.ua = "";
        object.device_id = "";
        object.device_type = "";
        object.os = "";
        object.network_type = "";
      }
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        object.invite_code = message.invite_code;
      if (message.af_id != null && message.hasOwnProperty("af_id")) object.af_id = message.af_id;
      if (message.fb_id != null && message.hasOwnProperty("fb_id")) object.fb_id = message.fb_id;
      if (message.channel != null && message.hasOwnProperty("channel")) object.channel = message.channel;
      if (message.fbc != null && message.hasOwnProperty("fbc")) object.fbc = message.fbc;
      if (message.fbp != null && message.hasOwnProperty("fbp")) object.fbp = message.fbp;
      if (message.ua != null && message.hasOwnProperty("ua")) object.ua = message.ua;
      if (message.device_id != null && message.hasOwnProperty("device_id")) object.device_id = message.device_id;
      if (message.device_type != null && message.hasOwnProperty("device_type"))
        object.device_type = message.device_type;
      if (message.os != null && message.hasOwnProperty("os")) object.os = message.os;
      if (message.network_type != null && message.hasOwnProperty("network_type"))
        object.network_type = message.network_type;
      return object;
    };

    /**
     * Converts this ReportRegDataReq to JSON.
     * @function toJSON
     * @memberof client_proto.ReportRegDataReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ReportRegDataReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ReportRegDataReq
     * @function getTypeUrl
     * @memberof client_proto.ReportRegDataReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ReportRegDataReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ReportRegDataReq";
    };

    return ReportRegDataReq;
  })();

  client_proto.ReportRegDataResp = (function () {
    /**
     * Properties of a ReportRegDataResp.
     * @memberof client_proto
     * @interface IReportRegDataResp
     * @property {number|null} [result] ReportRegDataResp result
     * @property {string|null} [invite_code] ReportRegDataResp invite_code
     */

    /**
     * Constructs a new ReportRegDataResp.
     * @memberof client_proto
     * @classdesc Represents a ReportRegDataResp.
     * @implements IReportRegDataResp
     * @constructor
     * @param {client_proto.IReportRegDataResp=} [properties] Properties to set
     */
    function ReportRegDataResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ReportRegDataResp result.
     * @member {number} result
     * @memberof client_proto.ReportRegDataResp
     * @instance
     */
    ReportRegDataResp.prototype.result = 0;

    /**
     * ReportRegDataResp invite_code.
     * @member {string} invite_code
     * @memberof client_proto.ReportRegDataResp
     * @instance
     */
    ReportRegDataResp.prototype.invite_code = "";

    /**
     * Creates a new ReportRegDataResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ReportRegDataResp
     * @static
     * @param {client_proto.IReportRegDataResp=} [properties] Properties to set
     * @returns {client_proto.ReportRegDataResp} ReportRegDataResp instance
     */
    ReportRegDataResp.create = function create(properties) {
      return new ReportRegDataResp(properties);
    };

    /**
     * Encodes the specified ReportRegDataResp message. Does not implicitly {@link client_proto.ReportRegDataResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ReportRegDataResp
     * @static
     * @param {client_proto.IReportRegDataResp} message ReportRegDataResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReportRegDataResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.invite_code != null && Object.hasOwnProperty.call(message, "invite_code"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.invite_code);
      return writer;
    };

    /**
     * Encodes the specified ReportRegDataResp message, length delimited. Does not implicitly {@link client_proto.ReportRegDataResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ReportRegDataResp
     * @static
     * @param {client_proto.IReportRegDataResp} message ReportRegDataResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReportRegDataResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ReportRegDataResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ReportRegDataResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ReportRegDataResp} ReportRegDataResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReportRegDataResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ReportRegDataResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.invite_code = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ReportRegDataResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ReportRegDataResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ReportRegDataResp} ReportRegDataResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReportRegDataResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ReportRegDataResp message.
     * @function verify
     * @memberof client_proto.ReportRegDataResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ReportRegDataResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        if (!$util.isString(message.invite_code)) return "invite_code: string expected";
      return null;
    };

    /**
     * Creates a ReportRegDataResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ReportRegDataResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ReportRegDataResp} ReportRegDataResp
     */
    ReportRegDataResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ReportRegDataResp) return object;
      var message = new $root.client_proto.ReportRegDataResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.invite_code != null) message.invite_code = String(object.invite_code);
      return message;
    };

    /**
     * Creates a plain object from a ReportRegDataResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ReportRegDataResp
     * @static
     * @param {client_proto.ReportRegDataResp} message ReportRegDataResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ReportRegDataResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.invite_code = "";
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        object.invite_code = message.invite_code;
      return object;
    };

    /**
     * Converts this ReportRegDataResp to JSON.
     * @function toJSON
     * @memberof client_proto.ReportRegDataResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ReportRegDataResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ReportRegDataResp
     * @function getTypeUrl
     * @memberof client_proto.ReportRegDataResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ReportRegDataResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ReportRegDataResp";
    };

    return ReportRegDataResp;
  })();

  client_proto.ReportKolDataReq = (function () {
    /**
     * Properties of a ReportKolDataReq.
     * @memberof client_proto
     * @interface IReportKolDataReq
     * @property {string|null} [channel] ReportKolDataReq channel
     * @property {string|null} [aid] ReportKolDataReq aid
     * @property {string|null} [idfa] ReportKolDataReq idfa
     * @property {string|null} [gaid] ReportKolDataReq gaid
     * @property {string|null} [device_id] ReportKolDataReq device_id
     * @property {string|null} [device_type] ReportKolDataReq device_type
     * @property {string|null} [os] ReportKolDataReq os
     * @property {string|null} [network_type] ReportKolDataReq network_type
     */

    /**
     * Constructs a new ReportKolDataReq.
     * @memberof client_proto
     * @classdesc Represents a ReportKolDataReq.
     * @implements IReportKolDataReq
     * @constructor
     * @param {client_proto.IReportKolDataReq=} [properties] Properties to set
     */
    function ReportKolDataReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ReportKolDataReq channel.
     * @member {string} channel
     * @memberof client_proto.ReportKolDataReq
     * @instance
     */
    ReportKolDataReq.prototype.channel = "";

    /**
     * ReportKolDataReq aid.
     * @member {string} aid
     * @memberof client_proto.ReportKolDataReq
     * @instance
     */
    ReportKolDataReq.prototype.aid = "";

    /**
     * ReportKolDataReq idfa.
     * @member {string} idfa
     * @memberof client_proto.ReportKolDataReq
     * @instance
     */
    ReportKolDataReq.prototype.idfa = "";

    /**
     * ReportKolDataReq gaid.
     * @member {string} gaid
     * @memberof client_proto.ReportKolDataReq
     * @instance
     */
    ReportKolDataReq.prototype.gaid = "";

    /**
     * ReportKolDataReq device_id.
     * @member {string} device_id
     * @memberof client_proto.ReportKolDataReq
     * @instance
     */
    ReportKolDataReq.prototype.device_id = "";

    /**
     * ReportKolDataReq device_type.
     * @member {string} device_type
     * @memberof client_proto.ReportKolDataReq
     * @instance
     */
    ReportKolDataReq.prototype.device_type = "";

    /**
     * ReportKolDataReq os.
     * @member {string} os
     * @memberof client_proto.ReportKolDataReq
     * @instance
     */
    ReportKolDataReq.prototype.os = "";

    /**
     * ReportKolDataReq network_type.
     * @member {string} network_type
     * @memberof client_proto.ReportKolDataReq
     * @instance
     */
    ReportKolDataReq.prototype.network_type = "";

    /**
     * Creates a new ReportKolDataReq instance using the specified properties.
     * @function create
     * @memberof client_proto.ReportKolDataReq
     * @static
     * @param {client_proto.IReportKolDataReq=} [properties] Properties to set
     * @returns {client_proto.ReportKolDataReq} ReportKolDataReq instance
     */
    ReportKolDataReq.create = function create(properties) {
      return new ReportKolDataReq(properties);
    };

    /**
     * Encodes the specified ReportKolDataReq message. Does not implicitly {@link client_proto.ReportKolDataReq.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ReportKolDataReq
     * @static
     * @param {client_proto.IReportKolDataReq} message ReportKolDataReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReportKolDataReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.channel);
      if (message.aid != null && Object.hasOwnProperty.call(message, "aid"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.aid);
      if (message.idfa != null && Object.hasOwnProperty.call(message, "idfa"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.idfa);
      if (message.gaid != null && Object.hasOwnProperty.call(message, "gaid"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.gaid);
      if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.device_id);
      if (message.device_type != null && Object.hasOwnProperty.call(message, "device_type"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.device_type);
      if (message.os != null && Object.hasOwnProperty.call(message, "os"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.os);
      if (message.network_type != null && Object.hasOwnProperty.call(message, "network_type"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.network_type);
      return writer;
    };

    /**
     * Encodes the specified ReportKolDataReq message, length delimited. Does not implicitly {@link client_proto.ReportKolDataReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ReportKolDataReq
     * @static
     * @param {client_proto.IReportKolDataReq} message ReportKolDataReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReportKolDataReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ReportKolDataReq message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ReportKolDataReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ReportKolDataReq} ReportKolDataReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReportKolDataReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ReportKolDataReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.channel = reader.string();
            break;
          }
          case 2: {
            message.aid = reader.string();
            break;
          }
          case 3: {
            message.idfa = reader.string();
            break;
          }
          case 4: {
            message.gaid = reader.string();
            break;
          }
          case 5: {
            message.device_id = reader.string();
            break;
          }
          case 6: {
            message.device_type = reader.string();
            break;
          }
          case 7: {
            message.os = reader.string();
            break;
          }
          case 8: {
            message.network_type = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ReportKolDataReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ReportKolDataReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ReportKolDataReq} ReportKolDataReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReportKolDataReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ReportKolDataReq message.
     * @function verify
     * @memberof client_proto.ReportKolDataReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ReportKolDataReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.channel != null && message.hasOwnProperty("channel"))
        if (!$util.isString(message.channel)) return "channel: string expected";
      if (message.aid != null && message.hasOwnProperty("aid"))
        if (!$util.isString(message.aid)) return "aid: string expected";
      if (message.idfa != null && message.hasOwnProperty("idfa"))
        if (!$util.isString(message.idfa)) return "idfa: string expected";
      if (message.gaid != null && message.hasOwnProperty("gaid"))
        if (!$util.isString(message.gaid)) return "gaid: string expected";
      if (message.device_id != null && message.hasOwnProperty("device_id"))
        if (!$util.isString(message.device_id)) return "device_id: string expected";
      if (message.device_type != null && message.hasOwnProperty("device_type"))
        if (!$util.isString(message.device_type)) return "device_type: string expected";
      if (message.os != null && message.hasOwnProperty("os"))
        if (!$util.isString(message.os)) return "os: string expected";
      if (message.network_type != null && message.hasOwnProperty("network_type"))
        if (!$util.isString(message.network_type)) return "network_type: string expected";
      return null;
    };

    /**
     * Creates a ReportKolDataReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ReportKolDataReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ReportKolDataReq} ReportKolDataReq
     */
    ReportKolDataReq.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ReportKolDataReq) return object;
      var message = new $root.client_proto.ReportKolDataReq();
      if (object.channel != null) message.channel = String(object.channel);
      if (object.aid != null) message.aid = String(object.aid);
      if (object.idfa != null) message.idfa = String(object.idfa);
      if (object.gaid != null) message.gaid = String(object.gaid);
      if (object.device_id != null) message.device_id = String(object.device_id);
      if (object.device_type != null) message.device_type = String(object.device_type);
      if (object.os != null) message.os = String(object.os);
      if (object.network_type != null) message.network_type = String(object.network_type);
      return message;
    };

    /**
     * Creates a plain object from a ReportKolDataReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ReportKolDataReq
     * @static
     * @param {client_proto.ReportKolDataReq} message ReportKolDataReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ReportKolDataReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.channel = "";
        object.aid = "";
        object.idfa = "";
        object.gaid = "";
        object.device_id = "";
        object.device_type = "";
        object.os = "";
        object.network_type = "";
      }
      if (message.channel != null && message.hasOwnProperty("channel")) object.channel = message.channel;
      if (message.aid != null && message.hasOwnProperty("aid")) object.aid = message.aid;
      if (message.idfa != null && message.hasOwnProperty("idfa")) object.idfa = message.idfa;
      if (message.gaid != null && message.hasOwnProperty("gaid")) object.gaid = message.gaid;
      if (message.device_id != null && message.hasOwnProperty("device_id")) object.device_id = message.device_id;
      if (message.device_type != null && message.hasOwnProperty("device_type"))
        object.device_type = message.device_type;
      if (message.os != null && message.hasOwnProperty("os")) object.os = message.os;
      if (message.network_type != null && message.hasOwnProperty("network_type"))
        object.network_type = message.network_type;
      return object;
    };

    /**
     * Converts this ReportKolDataReq to JSON.
     * @function toJSON
     * @memberof client_proto.ReportKolDataReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ReportKolDataReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ReportKolDataReq
     * @function getTypeUrl
     * @memberof client_proto.ReportKolDataReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ReportKolDataReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ReportKolDataReq";
    };

    return ReportKolDataReq;
  })();

  client_proto.ReportKolDataResp = (function () {
    /**
     * Properties of a ReportKolDataResp.
     * @memberof client_proto
     * @interface IReportKolDataResp
     * @property {number|null} [result] ReportKolDataResp result
     * @property {string|null} [invite_code] ReportKolDataResp invite_code
     */

    /**
     * Constructs a new ReportKolDataResp.
     * @memberof client_proto
     * @classdesc Represents a ReportKolDataResp.
     * @implements IReportKolDataResp
     * @constructor
     * @param {client_proto.IReportKolDataResp=} [properties] Properties to set
     */
    function ReportKolDataResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ReportKolDataResp result.
     * @member {number} result
     * @memberof client_proto.ReportKolDataResp
     * @instance
     */
    ReportKolDataResp.prototype.result = 0;

    /**
     * ReportKolDataResp invite_code.
     * @member {string} invite_code
     * @memberof client_proto.ReportKolDataResp
     * @instance
     */
    ReportKolDataResp.prototype.invite_code = "";

    /**
     * Creates a new ReportKolDataResp instance using the specified properties.
     * @function create
     * @memberof client_proto.ReportKolDataResp
     * @static
     * @param {client_proto.IReportKolDataResp=} [properties] Properties to set
     * @returns {client_proto.ReportKolDataResp} ReportKolDataResp instance
     */
    ReportKolDataResp.create = function create(properties) {
      return new ReportKolDataResp(properties);
    };

    /**
     * Encodes the specified ReportKolDataResp message. Does not implicitly {@link client_proto.ReportKolDataResp.verify|verify} messages.
     * @function encode
     * @memberof client_proto.ReportKolDataResp
     * @static
     * @param {client_proto.IReportKolDataResp} message ReportKolDataResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReportKolDataResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.invite_code != null && Object.hasOwnProperty.call(message, "invite_code"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.invite_code);
      return writer;
    };

    /**
     * Encodes the specified ReportKolDataResp message, length delimited. Does not implicitly {@link client_proto.ReportKolDataResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof client_proto.ReportKolDataResp
     * @static
     * @param {client_proto.IReportKolDataResp} message ReportKolDataResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReportKolDataResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ReportKolDataResp message from the specified reader or buffer.
     * @function decode
     * @memberof client_proto.ReportKolDataResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {client_proto.ReportKolDataResp} ReportKolDataResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReportKolDataResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.client_proto.ReportKolDataResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.invite_code = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ReportKolDataResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof client_proto.ReportKolDataResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {client_proto.ReportKolDataResp} ReportKolDataResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReportKolDataResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ReportKolDataResp message.
     * @function verify
     * @memberof client_proto.ReportKolDataResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ReportKolDataResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        if (!$util.isString(message.invite_code)) return "invite_code: string expected";
      return null;
    };

    /**
     * Creates a ReportKolDataResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof client_proto.ReportKolDataResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {client_proto.ReportKolDataResp} ReportKolDataResp
     */
    ReportKolDataResp.fromObject = function fromObject(object) {
      if (object instanceof $root.client_proto.ReportKolDataResp) return object;
      var message = new $root.client_proto.ReportKolDataResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.invite_code != null) message.invite_code = String(object.invite_code);
      return message;
    };

    /**
     * Creates a plain object from a ReportKolDataResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof client_proto.ReportKolDataResp
     * @static
     * @param {client_proto.ReportKolDataResp} message ReportKolDataResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ReportKolDataResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.invite_code = "";
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.invite_code != null && message.hasOwnProperty("invite_code"))
        object.invite_code = message.invite_code;
      return object;
    };

    /**
     * Converts this ReportKolDataResp to JSON.
     * @function toJSON
     * @memberof client_proto.ReportKolDataResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ReportKolDataResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ReportKolDataResp
     * @function getTypeUrl
     * @memberof client_proto.ReportKolDataResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ReportKolDataResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/client_proto.ReportKolDataResp";
    };

    return ReportKolDataResp;
  })();

  return client_proto;
})();

$root.rank_server_proto = (function () {
  /**
   * Namespace rank_server_proto.
   * @exports rank_server_proto
   * @namespace
   */
  var rank_server_proto = {};

  /**
   * RANK_LIST_TYPE enum.
   * @name rank_server_proto.RANK_LIST_TYPE
   * @enum {number}
   * @property {number} RANK_LIST_NULL=0 RANK_LIST_NULL value
   * @property {number} RANK_LIST_DAY=1 RANK_LIST_DAY value
   * @property {number} RANK_LIST_WEEK=2 RANK_LIST_WEEK value
   * @property {number} RANK_LIST_MONTH=3 RANK_LIST_MONTH value
   */
  rank_server_proto.RANK_LIST_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "RANK_LIST_NULL")] = 0;
    values[(valuesById[1] = "RANK_LIST_DAY")] = 1;
    values[(valuesById[2] = "RANK_LIST_WEEK")] = 2;
    values[(valuesById[3] = "RANK_LIST_MONTH")] = 3;
    return values;
  })();

  /**
   * RANK_INFO_SUB_MSG_ID enum.
   * @name rank_server_proto.RANK_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} RANK_MSG_ID_NULL=0 RANK_MSG_ID_NULL value
   * @property {number} RANK_MSG_ID_MIN=1400 RANK_MSG_ID_MIN value
   * @property {number} RANK_MSG_ID_REPORT_PLAYING=1441 RANK_MSG_ID_REPORT_PLAYING value
   * @property {number} RANK_MSG_ID_REPORT_PLAYING_RESP=1442 RANK_MSG_ID_REPORT_PLAYING_RESP value
   * @property {number} RANK_MSG_ID_REPORT_REFRESH_CONFIG=1443 RANK_MSG_ID_REPORT_REFRESH_CONFIG value
   * @property {number} RANK_MSG_ID_REPORT_DELETE_CONFIG=1444 RANK_MSG_ID_REPORT_DELETE_CONFIG value
   * @property {number} RANK_MSG_ID_VERIFY_RANK=1445 RANK_MSG_ID_VERIFY_RANK value
   * @property {number} RANK_MSG_ID_MID=1450 RANK_MSG_ID_MID value
   * @property {number} RANK_MSG_ID_MAX=1500 RANK_MSG_ID_MAX value
   */
  rank_server_proto.RANK_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "RANK_MSG_ID_NULL")] = 0;
    values[(valuesById[1400] = "RANK_MSG_ID_MIN")] = 1400;
    values[(valuesById[1441] = "RANK_MSG_ID_REPORT_PLAYING")] = 1441;
    values[(valuesById[1442] = "RANK_MSG_ID_REPORT_PLAYING_RESP")] = 1442;
    values[(valuesById[1443] = "RANK_MSG_ID_REPORT_REFRESH_CONFIG")] = 1443;
    values[(valuesById[1444] = "RANK_MSG_ID_REPORT_DELETE_CONFIG")] = 1444;
    values[(valuesById[1445] = "RANK_MSG_ID_VERIFY_RANK")] = 1445;
    values[(valuesById[1450] = "RANK_MSG_ID_MID")] = 1450;
    values[(valuesById[1500] = "RANK_MSG_ID_MAX")] = 1500;
    return values;
  })();

  rank_server_proto.ReportUserPlaying = (function () {
    /**
     * Properties of a ReportUserPlaying.
     * @memberof rank_server_proto
     * @interface IReportUserPlaying
     * @property {number|null} [uid] ReportUserPlaying uid
     * @property {number|null} [company_id] ReportUserPlaying company_id
     * @property {string|null} [game_uid] ReportUserPlaying game_uid
     * @property {number|Long|null} [money] ReportUserPlaying money
     * @property {string|null} [platform] ReportUserPlaying platform
     */

    /**
     * Constructs a new ReportUserPlaying.
     * @memberof rank_server_proto
     * @classdesc Represents a ReportUserPlaying.
     * @implements IReportUserPlaying
     * @constructor
     * @param {rank_server_proto.IReportUserPlaying=} [properties] Properties to set
     */
    function ReportUserPlaying(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ReportUserPlaying uid.
     * @member {number} uid
     * @memberof rank_server_proto.ReportUserPlaying
     * @instance
     */
    ReportUserPlaying.prototype.uid = 0;

    /**
     * ReportUserPlaying company_id.
     * @member {number} company_id
     * @memberof rank_server_proto.ReportUserPlaying
     * @instance
     */
    ReportUserPlaying.prototype.company_id = 0;

    /**
     * ReportUserPlaying game_uid.
     * @member {string} game_uid
     * @memberof rank_server_proto.ReportUserPlaying
     * @instance
     */
    ReportUserPlaying.prototype.game_uid = "";

    /**
     * ReportUserPlaying money.
     * @member {number|Long} money
     * @memberof rank_server_proto.ReportUserPlaying
     * @instance
     */
    ReportUserPlaying.prototype.money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * ReportUserPlaying platform.
     * @member {string} platform
     * @memberof rank_server_proto.ReportUserPlaying
     * @instance
     */
    ReportUserPlaying.prototype.platform = "";

    /**
     * Creates a new ReportUserPlaying instance using the specified properties.
     * @function create
     * @memberof rank_server_proto.ReportUserPlaying
     * @static
     * @param {rank_server_proto.IReportUserPlaying=} [properties] Properties to set
     * @returns {rank_server_proto.ReportUserPlaying} ReportUserPlaying instance
     */
    ReportUserPlaying.create = function create(properties) {
      return new ReportUserPlaying(properties);
    };

    /**
     * Encodes the specified ReportUserPlaying message. Does not implicitly {@link rank_server_proto.ReportUserPlaying.verify|verify} messages.
     * @function encode
     * @memberof rank_server_proto.ReportUserPlaying
     * @static
     * @param {rank_server_proto.IReportUserPlaying} message ReportUserPlaying message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReportUserPlaying.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.company_id != null && Object.hasOwnProperty.call(message, "company_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.company_id);
      if (message.game_uid != null && Object.hasOwnProperty.call(message, "game_uid"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.game_uid);
      if (message.money != null && Object.hasOwnProperty.call(message, "money"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.money);
      if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.platform);
      return writer;
    };

    /**
     * Encodes the specified ReportUserPlaying message, length delimited. Does not implicitly {@link rank_server_proto.ReportUserPlaying.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rank_server_proto.ReportUserPlaying
     * @static
     * @param {rank_server_proto.IReportUserPlaying} message ReportUserPlaying message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ReportUserPlaying.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ReportUserPlaying message from the specified reader or buffer.
     * @function decode
     * @memberof rank_server_proto.ReportUserPlaying
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rank_server_proto.ReportUserPlaying} ReportUserPlaying
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReportUserPlaying.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rank_server_proto.ReportUserPlaying();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.company_id = reader.uint32();
            break;
          }
          case 3: {
            message.game_uid = reader.string();
            break;
          }
          case 4: {
            message.money = reader.int64();
            break;
          }
          case 5: {
            message.platform = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ReportUserPlaying message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rank_server_proto.ReportUserPlaying
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rank_server_proto.ReportUserPlaying} ReportUserPlaying
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ReportUserPlaying.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ReportUserPlaying message.
     * @function verify
     * @memberof rank_server_proto.ReportUserPlaying
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ReportUserPlaying.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.company_id != null && message.hasOwnProperty("company_id"))
        if (!$util.isInteger(message.company_id)) return "company_id: integer expected";
      if (message.game_uid != null && message.hasOwnProperty("game_uid"))
        if (!$util.isString(message.game_uid)) return "game_uid: string expected";
      if (message.money != null && message.hasOwnProperty("money"))
        if (
          !$util.isInteger(message.money) &&
          !(message.money && $util.isInteger(message.money.low) && $util.isInteger(message.money.high))
        )
          return "money: integer|Long expected";
      if (message.platform != null && message.hasOwnProperty("platform"))
        if (!$util.isString(message.platform)) return "platform: string expected";
      return null;
    };

    /**
     * Creates a ReportUserPlaying message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rank_server_proto.ReportUserPlaying
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rank_server_proto.ReportUserPlaying} ReportUserPlaying
     */
    ReportUserPlaying.fromObject = function fromObject(object) {
      if (object instanceof $root.rank_server_proto.ReportUserPlaying) return object;
      var message = new $root.rank_server_proto.ReportUserPlaying();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.company_id != null) message.company_id = object.company_id >>> 0;
      if (object.game_uid != null) message.game_uid = String(object.game_uid);
      if (object.money != null)
        if ($util.Long) (message.money = $util.Long.fromValue(object.money)).unsigned = false;
        else if (typeof object.money === "string") message.money = parseInt(object.money, 10);
        else if (typeof object.money === "number") message.money = object.money;
        else if (typeof object.money === "object")
          message.money = new $util.LongBits(object.money.low >>> 0, object.money.high >>> 0).toNumber();
      if (object.platform != null) message.platform = String(object.platform);
      return message;
    };

    /**
     * Creates a plain object from a ReportUserPlaying message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rank_server_proto.ReportUserPlaying
     * @static
     * @param {rank_server_proto.ReportUserPlaying} message ReportUserPlaying
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ReportUserPlaying.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.company_id = 0;
        object.game_uid = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.money = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.money = options.longs === String ? "0" : 0;
        object.platform = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.company_id != null && message.hasOwnProperty("company_id")) object.company_id = message.company_id;
      if (message.game_uid != null && message.hasOwnProperty("game_uid")) object.game_uid = message.game_uid;
      if (message.money != null && message.hasOwnProperty("money"))
        if (typeof message.money === "number")
          object.money = options.longs === String ? String(message.money) : message.money;
        else
          object.money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.money)
              : options.longs === Number
                ? new $util.LongBits(message.money.low >>> 0, message.money.high >>> 0).toNumber()
                : message.money;
      if (message.platform != null && message.hasOwnProperty("platform")) object.platform = message.platform;
      return object;
    };

    /**
     * Converts this ReportUserPlaying to JSON.
     * @function toJSON
     * @memberof rank_server_proto.ReportUserPlaying
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ReportUserPlaying.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ReportUserPlaying
     * @function getTypeUrl
     * @memberof rank_server_proto.ReportUserPlaying
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ReportUserPlaying.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/rank_server_proto.ReportUserPlaying";
    };

    return ReportUserPlaying;
  })();

  rank_server_proto.ResponseUserPlaying = (function () {
    /**
     * Properties of a ResponseUserPlaying.
     * @memberof rank_server_proto
     * @interface IResponseUserPlaying
     */

    /**
     * Constructs a new ResponseUserPlaying.
     * @memberof rank_server_proto
     * @classdesc Represents a ResponseUserPlaying.
     * @implements IResponseUserPlaying
     * @constructor
     * @param {rank_server_proto.IResponseUserPlaying=} [properties] Properties to set
     */
    function ResponseUserPlaying(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new ResponseUserPlaying instance using the specified properties.
     * @function create
     * @memberof rank_server_proto.ResponseUserPlaying
     * @static
     * @param {rank_server_proto.IResponseUserPlaying=} [properties] Properties to set
     * @returns {rank_server_proto.ResponseUserPlaying} ResponseUserPlaying instance
     */
    ResponseUserPlaying.create = function create(properties) {
      return new ResponseUserPlaying(properties);
    };

    /**
     * Encodes the specified ResponseUserPlaying message. Does not implicitly {@link rank_server_proto.ResponseUserPlaying.verify|verify} messages.
     * @function encode
     * @memberof rank_server_proto.ResponseUserPlaying
     * @static
     * @param {rank_server_proto.IResponseUserPlaying} message ResponseUserPlaying message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseUserPlaying.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified ResponseUserPlaying message, length delimited. Does not implicitly {@link rank_server_proto.ResponseUserPlaying.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rank_server_proto.ResponseUserPlaying
     * @static
     * @param {rank_server_proto.IResponseUserPlaying} message ResponseUserPlaying message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseUserPlaying.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ResponseUserPlaying message from the specified reader or buffer.
     * @function decode
     * @memberof rank_server_proto.ResponseUserPlaying
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rank_server_proto.ResponseUserPlaying} ResponseUserPlaying
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseUserPlaying.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rank_server_proto.ResponseUserPlaying();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ResponseUserPlaying message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rank_server_proto.ResponseUserPlaying
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rank_server_proto.ResponseUserPlaying} ResponseUserPlaying
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseUserPlaying.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ResponseUserPlaying message.
     * @function verify
     * @memberof rank_server_proto.ResponseUserPlaying
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ResponseUserPlaying.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a ResponseUserPlaying message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rank_server_proto.ResponseUserPlaying
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rank_server_proto.ResponseUserPlaying} ResponseUserPlaying
     */
    ResponseUserPlaying.fromObject = function fromObject(object) {
      if (object instanceof $root.rank_server_proto.ResponseUserPlaying) return object;
      return new $root.rank_server_proto.ResponseUserPlaying();
    };

    /**
     * Creates a plain object from a ResponseUserPlaying message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rank_server_proto.ResponseUserPlaying
     * @static
     * @param {rank_server_proto.ResponseUserPlaying} message ResponseUserPlaying
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ResponseUserPlaying.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this ResponseUserPlaying to JSON.
     * @function toJSON
     * @memberof rank_server_proto.ResponseUserPlaying
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ResponseUserPlaying.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ResponseUserPlaying
     * @function getTypeUrl
     * @memberof rank_server_proto.ResponseUserPlaying
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ResponseUserPlaying.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/rank_server_proto.ResponseUserPlaying";
    };

    return ResponseUserPlaying;
  })();

  rank_server_proto.RequestVerifyRank = (function () {
    /**
     * Properties of a RequestVerifyRank.
     * @memberof rank_server_proto
     * @interface IRequestVerifyRank
     * @property {number|Long|null} [reward_id] RequestVerifyRank reward_id
     * @property {number|null} [uid] RequestVerifyRank uid
     * @property {number|null} [audit_uid] RequestVerifyRank audit_uid
     * @property {number|null} [audit_time] RequestVerifyRank audit_time
     * @property {string|null} [audit_name] RequestVerifyRank audit_name
     * @property {string|null} [audit_reason] RequestVerifyRank audit_reason
     * @property {number|null} [past_verify] RequestVerifyRank past_verify
     */

    /**
     * Constructs a new RequestVerifyRank.
     * @memberof rank_server_proto
     * @classdesc Represents a RequestVerifyRank.
     * @implements IRequestVerifyRank
     * @constructor
     * @param {rank_server_proto.IRequestVerifyRank=} [properties] Properties to set
     */
    function RequestVerifyRank(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestVerifyRank reward_id.
     * @member {number|Long} reward_id
     * @memberof rank_server_proto.RequestVerifyRank
     * @instance
     */
    RequestVerifyRank.prototype.reward_id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * RequestVerifyRank uid.
     * @member {number} uid
     * @memberof rank_server_proto.RequestVerifyRank
     * @instance
     */
    RequestVerifyRank.prototype.uid = 0;

    /**
     * RequestVerifyRank audit_uid.
     * @member {number} audit_uid
     * @memberof rank_server_proto.RequestVerifyRank
     * @instance
     */
    RequestVerifyRank.prototype.audit_uid = 0;

    /**
     * RequestVerifyRank audit_time.
     * @member {number} audit_time
     * @memberof rank_server_proto.RequestVerifyRank
     * @instance
     */
    RequestVerifyRank.prototype.audit_time = 0;

    /**
     * RequestVerifyRank audit_name.
     * @member {string} audit_name
     * @memberof rank_server_proto.RequestVerifyRank
     * @instance
     */
    RequestVerifyRank.prototype.audit_name = "";

    /**
     * RequestVerifyRank audit_reason.
     * @member {string} audit_reason
     * @memberof rank_server_proto.RequestVerifyRank
     * @instance
     */
    RequestVerifyRank.prototype.audit_reason = "";

    /**
     * RequestVerifyRank past_verify.
     * @member {number} past_verify
     * @memberof rank_server_proto.RequestVerifyRank
     * @instance
     */
    RequestVerifyRank.prototype.past_verify = 0;

    /**
     * Creates a new RequestVerifyRank instance using the specified properties.
     * @function create
     * @memberof rank_server_proto.RequestVerifyRank
     * @static
     * @param {rank_server_proto.IRequestVerifyRank=} [properties] Properties to set
     * @returns {rank_server_proto.RequestVerifyRank} RequestVerifyRank instance
     */
    RequestVerifyRank.create = function create(properties) {
      return new RequestVerifyRank(properties);
    };

    /**
     * Encodes the specified RequestVerifyRank message. Does not implicitly {@link rank_server_proto.RequestVerifyRank.verify|verify} messages.
     * @function encode
     * @memberof rank_server_proto.RequestVerifyRank
     * @static
     * @param {rank_server_proto.IRequestVerifyRank} message RequestVerifyRank message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestVerifyRank.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.reward_id != null && Object.hasOwnProperty.call(message, "reward_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.reward_id);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.uid);
      if (message.audit_uid != null && Object.hasOwnProperty.call(message, "audit_uid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.audit_uid);
      if (message.audit_time != null && Object.hasOwnProperty.call(message, "audit_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.audit_time);
      if (message.audit_name != null && Object.hasOwnProperty.call(message, "audit_name"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.audit_name);
      if (message.audit_reason != null && Object.hasOwnProperty.call(message, "audit_reason"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.audit_reason);
      if (message.past_verify != null && Object.hasOwnProperty.call(message, "past_verify"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.past_verify);
      return writer;
    };

    /**
     * Encodes the specified RequestVerifyRank message, length delimited. Does not implicitly {@link rank_server_proto.RequestVerifyRank.verify|verify} messages.
     * @function encodeDelimited
     * @memberof rank_server_proto.RequestVerifyRank
     * @static
     * @param {rank_server_proto.IRequestVerifyRank} message RequestVerifyRank message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestVerifyRank.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RequestVerifyRank message from the specified reader or buffer.
     * @function decode
     * @memberof rank_server_proto.RequestVerifyRank
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {rank_server_proto.RequestVerifyRank} RequestVerifyRank
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestVerifyRank.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.rank_server_proto.RequestVerifyRank();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.reward_id = reader.int64();
            break;
          }
          case 2: {
            message.uid = reader.uint32();
            break;
          }
          case 3: {
            message.audit_uid = reader.int32();
            break;
          }
          case 4: {
            message.audit_time = reader.uint32();
            break;
          }
          case 5: {
            message.audit_name = reader.string();
            break;
          }
          case 6: {
            message.audit_reason = reader.string();
            break;
          }
          case 7: {
            message.past_verify = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RequestVerifyRank message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof rank_server_proto.RequestVerifyRank
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {rank_server_proto.RequestVerifyRank} RequestVerifyRank
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestVerifyRank.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RequestVerifyRank message.
     * @function verify
     * @memberof rank_server_proto.RequestVerifyRank
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RequestVerifyRank.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (
          !$util.isInteger(message.reward_id) &&
          !(message.reward_id && $util.isInteger(message.reward_id.low) && $util.isInteger(message.reward_id.high))
        )
          return "reward_id: integer|Long expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.audit_uid != null && message.hasOwnProperty("audit_uid"))
        if (!$util.isInteger(message.audit_uid)) return "audit_uid: integer expected";
      if (message.audit_time != null && message.hasOwnProperty("audit_time"))
        if (!$util.isInteger(message.audit_time)) return "audit_time: integer expected";
      if (message.audit_name != null && message.hasOwnProperty("audit_name"))
        if (!$util.isString(message.audit_name)) return "audit_name: string expected";
      if (message.audit_reason != null && message.hasOwnProperty("audit_reason"))
        if (!$util.isString(message.audit_reason)) return "audit_reason: string expected";
      if (message.past_verify != null && message.hasOwnProperty("past_verify"))
        if (!$util.isInteger(message.past_verify)) return "past_verify: integer expected";
      return null;
    };

    /**
     * Creates a RequestVerifyRank message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof rank_server_proto.RequestVerifyRank
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {rank_server_proto.RequestVerifyRank} RequestVerifyRank
     */
    RequestVerifyRank.fromObject = function fromObject(object) {
      if (object instanceof $root.rank_server_proto.RequestVerifyRank) return object;
      var message = new $root.rank_server_proto.RequestVerifyRank();
      if (object.reward_id != null)
        if ($util.Long) (message.reward_id = $util.Long.fromValue(object.reward_id)).unsigned = false;
        else if (typeof object.reward_id === "string") message.reward_id = parseInt(object.reward_id, 10);
        else if (typeof object.reward_id === "number") message.reward_id = object.reward_id;
        else if (typeof object.reward_id === "object")
          message.reward_id = new $util.LongBits(object.reward_id.low >>> 0, object.reward_id.high >>> 0).toNumber();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.audit_uid != null) message.audit_uid = object.audit_uid | 0;
      if (object.audit_time != null) message.audit_time = object.audit_time >>> 0;
      if (object.audit_name != null) message.audit_name = String(object.audit_name);
      if (object.audit_reason != null) message.audit_reason = String(object.audit_reason);
      if (object.past_verify != null) message.past_verify = object.past_verify | 0;
      return message;
    };

    /**
     * Creates a plain object from a RequestVerifyRank message. Also converts values to other types if specified.
     * @function toObject
     * @memberof rank_server_proto.RequestVerifyRank
     * @static
     * @param {rank_server_proto.RequestVerifyRank} message RequestVerifyRank
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RequestVerifyRank.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_id =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_id = options.longs === String ? "0" : 0;
        object.uid = 0;
        object.audit_uid = 0;
        object.audit_time = 0;
        object.audit_name = "";
        object.audit_reason = "";
        object.past_verify = 0;
      }
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (typeof message.reward_id === "number")
          object.reward_id = options.longs === String ? String(message.reward_id) : message.reward_id;
        else
          object.reward_id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_id)
              : options.longs === Number
                ? new $util.LongBits(message.reward_id.low >>> 0, message.reward_id.high >>> 0).toNumber()
                : message.reward_id;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.audit_uid != null && message.hasOwnProperty("audit_uid")) object.audit_uid = message.audit_uid;
      if (message.audit_time != null && message.hasOwnProperty("audit_time")) object.audit_time = message.audit_time;
      if (message.audit_name != null && message.hasOwnProperty("audit_name")) object.audit_name = message.audit_name;
      if (message.audit_reason != null && message.hasOwnProperty("audit_reason"))
        object.audit_reason = message.audit_reason;
      if (message.past_verify != null && message.hasOwnProperty("past_verify"))
        object.past_verify = message.past_verify;
      return object;
    };

    /**
     * Converts this RequestVerifyRank to JSON.
     * @function toJSON
     * @memberof rank_server_proto.RequestVerifyRank
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RequestVerifyRank.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RequestVerifyRank
     * @function getTypeUrl
     * @memberof rank_server_proto.RequestVerifyRank
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RequestVerifyRank.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/rank_server_proto.RequestVerifyRank";
    };

    return RequestVerifyRank;
  })();

  return rank_server_proto;
})();

$root.asset = (function () {
  /**
   * Namespace asset.
   * @exports asset
   * @namespace
   */
  var asset = {};

  /**
   * ASSET_CMD enum.
   * @name asset.ASSET_CMD
   * @enum {number}
   * @property {number} ASSET_CMD_NONE=0 ASSET_CMD_NONE value
   * @property {number} ASSET_CMD_GET_ASSET=1450 ASSET_CMD_GET_ASSET value
   * @property {number} ASSET_CMD_GET_ASSET_RESP=1451 ASSET_CMD_GET_ASSET_RESP value
   * @property {number} ASSET_CMD_UPDATE_ASSET=1452 ASSET_CMD_UPDATE_ASSET value
   * @property {number} ASSET_CMD_UPDATE_ASSET_RESP=1453 ASSET_CMD_UPDATE_ASSET_RESP value
   * @property {number} ASSET_CMD_GET_ASSET_BYFIELD=1454 ASSET_CMD_GET_ASSET_BYFIELD value
   * @property {number} ASSET_CMD_GET_ASSET_BYFIELD_RESP=1455 ASSET_CMD_GET_ASSET_BYFIELD_RESP value
   * @property {number} ASSET_CMD_ASSET_FLOW_PUSH=1456 ASSET_CMD_ASSET_FLOW_PUSH value
   * @property {number} ASSET_CMD_ADD_ACCOUNT=1457 ASSET_CMD_ADD_ACCOUNT value
   * @property {number} ASSET_CMD_ADD_ACCOUNT_RESP=1458 ASSET_CMD_ADD_ACCOUNT_RESP value
   */
  asset.ASSET_CMD = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "ASSET_CMD_NONE")] = 0;
    values[(valuesById[1450] = "ASSET_CMD_GET_ASSET")] = 1450;
    values[(valuesById[1451] = "ASSET_CMD_GET_ASSET_RESP")] = 1451;
    values[(valuesById[1452] = "ASSET_CMD_UPDATE_ASSET")] = 1452;
    values[(valuesById[1453] = "ASSET_CMD_UPDATE_ASSET_RESP")] = 1453;
    values[(valuesById[1454] = "ASSET_CMD_GET_ASSET_BYFIELD")] = 1454;
    values[(valuesById[1455] = "ASSET_CMD_GET_ASSET_BYFIELD_RESP")] = 1455;
    values[(valuesById[1456] = "ASSET_CMD_ASSET_FLOW_PUSH")] = 1456;
    values[(valuesById[1457] = "ASSET_CMD_ADD_ACCOUNT")] = 1457;
    values[(valuesById[1458] = "ASSET_CMD_ADD_ACCOUNT_RESP")] = 1458;
    return values;
  })();

  asset.AssetReq = (function () {
    /**
     * Properties of an AssetReq.
     * @memberof asset
     * @interface IAssetReq
     * @property {number|null} [uid] AssetReq uid
     * @property {Uint8Array|null} [trans] AssetReq trans
     */

    /**
     * Constructs a new AssetReq.
     * @memberof asset
     * @classdesc Represents an AssetReq.
     * @implements IAssetReq
     * @constructor
     * @param {asset.IAssetReq=} [properties] Properties to set
     */
    function AssetReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AssetReq uid.
     * @member {number} uid
     * @memberof asset.AssetReq
     * @instance
     */
    AssetReq.prototype.uid = 0;

    /**
     * AssetReq trans.
     * @member {Uint8Array} trans
     * @memberof asset.AssetReq
     * @instance
     */
    AssetReq.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new AssetReq instance using the specified properties.
     * @function create
     * @memberof asset.AssetReq
     * @static
     * @param {asset.IAssetReq=} [properties] Properties to set
     * @returns {asset.AssetReq} AssetReq instance
     */
    AssetReq.create = function create(properties) {
      return new AssetReq(properties);
    };

    /**
     * Encodes the specified AssetReq message. Does not implicitly {@link asset.AssetReq.verify|verify} messages.
     * @function encode
     * @memberof asset.AssetReq
     * @static
     * @param {asset.IAssetReq} message AssetReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified AssetReq message, length delimited. Does not implicitly {@link asset.AssetReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.AssetReq
     * @static
     * @param {asset.IAssetReq} message AssetReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AssetReq message from the specified reader or buffer.
     * @function decode
     * @memberof asset.AssetReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.AssetReq} AssetReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.AssetReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AssetReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.AssetReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.AssetReq} AssetReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AssetReq message.
     * @function verify
     * @memberof asset.AssetReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AssetReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates an AssetReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.AssetReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.AssetReq} AssetReq
     */
    AssetReq.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.AssetReq) return object;
      var message = new $root.asset.AssetReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from an AssetReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.AssetReq
     * @static
     * @param {asset.AssetReq} message AssetReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AssetReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this AssetReq to JSON.
     * @function toJSON
     * @memberof asset.AssetReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AssetReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AssetReq
     * @function getTypeUrl
     * @memberof asset.AssetReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AssetReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.AssetReq";
    };

    return AssetReq;
  })();

  asset.AssetResp = (function () {
    /**
     * Properties of an AssetResp.
     * @memberof asset
     * @interface IAssetResp
     * @property {number|null} [uid] AssetResp uid
     * @property {number|null} [result] AssetResp result
     * @property {number|Long|null} [amount] AssetResp amount
     * @property {number|Long|null} [coin] AssetResp coin
     * @property {number|Long|null} [totalbet] AssetResp totalbet
     * @property {number|Long|null} [totalrecharge] AssetResp totalrecharge
     * @property {number|null} [rechargecount] AssetResp rechargecount
     * @property {Uint8Array|null} [trans] AssetResp trans
     */

    /**
     * Constructs a new AssetResp.
     * @memberof asset
     * @classdesc Represents an AssetResp.
     * @implements IAssetResp
     * @constructor
     * @param {asset.IAssetResp=} [properties] Properties to set
     */
    function AssetResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AssetResp uid.
     * @member {number} uid
     * @memberof asset.AssetResp
     * @instance
     */
    AssetResp.prototype.uid = 0;

    /**
     * AssetResp result.
     * @member {number} result
     * @memberof asset.AssetResp
     * @instance
     */
    AssetResp.prototype.result = 0;

    /**
     * AssetResp amount.
     * @member {number|Long} amount
     * @memberof asset.AssetResp
     * @instance
     */
    AssetResp.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AssetResp coin.
     * @member {number|Long} coin
     * @memberof asset.AssetResp
     * @instance
     */
    AssetResp.prototype.coin = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AssetResp totalbet.
     * @member {number|Long} totalbet
     * @memberof asset.AssetResp
     * @instance
     */
    AssetResp.prototype.totalbet = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * AssetResp totalrecharge.
     * @member {number|Long} totalrecharge
     * @memberof asset.AssetResp
     * @instance
     */
    AssetResp.prototype.totalrecharge = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * AssetResp rechargecount.
     * @member {number} rechargecount
     * @memberof asset.AssetResp
     * @instance
     */
    AssetResp.prototype.rechargecount = 0;

    /**
     * AssetResp trans.
     * @member {Uint8Array} trans
     * @memberof asset.AssetResp
     * @instance
     */
    AssetResp.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new AssetResp instance using the specified properties.
     * @function create
     * @memberof asset.AssetResp
     * @static
     * @param {asset.IAssetResp=} [properties] Properties to set
     * @returns {asset.AssetResp} AssetResp instance
     */
    AssetResp.create = function create(properties) {
      return new AssetResp(properties);
    };

    /**
     * Encodes the specified AssetResp message. Does not implicitly {@link asset.AssetResp.verify|verify} messages.
     * @function encode
     * @memberof asset.AssetResp
     * @static
     * @param {asset.IAssetResp} message AssetResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.amount);
      if (message.coin != null && Object.hasOwnProperty.call(message, "coin"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.coin);
      if (message.totalbet != null && Object.hasOwnProperty.call(message, "totalbet"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).uint64(message.totalbet);
      if (message.totalrecharge != null && Object.hasOwnProperty.call(message, "totalrecharge"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).uint64(message.totalrecharge);
      if (message.rechargecount != null && Object.hasOwnProperty.call(message, "rechargecount"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).uint32(message.rechargecount);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified AssetResp message, length delimited. Does not implicitly {@link asset.AssetResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.AssetResp
     * @static
     * @param {asset.IAssetResp} message AssetResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AssetResp message from the specified reader or buffer.
     * @function decode
     * @memberof asset.AssetResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.AssetResp} AssetResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.AssetResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            message.amount = reader.int64();
            break;
          }
          case 4: {
            message.coin = reader.int64();
            break;
          }
          case 5: {
            message.totalbet = reader.uint64();
            break;
          }
          case 6: {
            message.totalrecharge = reader.uint64();
            break;
          }
          case 7: {
            message.rechargecount = reader.uint32();
            break;
          }
          case 8: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AssetResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.AssetResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.AssetResp} AssetResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AssetResp message.
     * @function verify
     * @memberof asset.AssetResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AssetResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (
          !$util.isInteger(message.amount) &&
          !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
        )
          return "amount: integer|Long expected";
      if (message.coin != null && message.hasOwnProperty("coin"))
        if (
          !$util.isInteger(message.coin) &&
          !(message.coin && $util.isInteger(message.coin.low) && $util.isInteger(message.coin.high))
        )
          return "coin: integer|Long expected";
      if (message.totalbet != null && message.hasOwnProperty("totalbet"))
        if (
          !$util.isInteger(message.totalbet) &&
          !(message.totalbet && $util.isInteger(message.totalbet.low) && $util.isInteger(message.totalbet.high))
        )
          return "totalbet: integer|Long expected";
      if (message.totalrecharge != null && message.hasOwnProperty("totalrecharge"))
        if (
          !$util.isInteger(message.totalrecharge) &&
          !(
            message.totalrecharge &&
            $util.isInteger(message.totalrecharge.low) &&
            $util.isInteger(message.totalrecharge.high)
          )
        )
          return "totalrecharge: integer|Long expected";
      if (message.rechargecount != null && message.hasOwnProperty("rechargecount"))
        if (!$util.isInteger(message.rechargecount)) return "rechargecount: integer expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates an AssetResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.AssetResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.AssetResp} AssetResp
     */
    AssetResp.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.AssetResp) return object;
      var message = new $root.asset.AssetResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.amount != null)
        if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
        else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
        else if (typeof object.amount === "number") message.amount = object.amount;
        else if (typeof object.amount === "object")
          message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
      if (object.coin != null)
        if ($util.Long) (message.coin = $util.Long.fromValue(object.coin)).unsigned = false;
        else if (typeof object.coin === "string") message.coin = parseInt(object.coin, 10);
        else if (typeof object.coin === "number") message.coin = object.coin;
        else if (typeof object.coin === "object")
          message.coin = new $util.LongBits(object.coin.low >>> 0, object.coin.high >>> 0).toNumber();
      if (object.totalbet != null)
        if ($util.Long) (message.totalbet = $util.Long.fromValue(object.totalbet)).unsigned = true;
        else if (typeof object.totalbet === "string") message.totalbet = parseInt(object.totalbet, 10);
        else if (typeof object.totalbet === "number") message.totalbet = object.totalbet;
        else if (typeof object.totalbet === "object")
          message.totalbet = new $util.LongBits(object.totalbet.low >>> 0, object.totalbet.high >>> 0).toNumber(true);
      if (object.totalrecharge != null)
        if ($util.Long) (message.totalrecharge = $util.Long.fromValue(object.totalrecharge)).unsigned = true;
        else if (typeof object.totalrecharge === "string") message.totalrecharge = parseInt(object.totalrecharge, 10);
        else if (typeof object.totalrecharge === "number") message.totalrecharge = object.totalrecharge;
        else if (typeof object.totalrecharge === "object")
          message.totalrecharge = new $util.LongBits(
            object.totalrecharge.low >>> 0,
            object.totalrecharge.high >>> 0,
          ).toNumber(true);
      if (object.rechargecount != null) message.rechargecount = object.rechargecount >>> 0;
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from an AssetResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.AssetResp
     * @static
     * @param {asset.AssetResp} message AssetResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AssetResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.result = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.amount =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.amount = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.coin = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.coin = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.totalbet =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.totalbet = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.totalrecharge =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.totalrecharge = options.longs === String ? "0" : 0;
        object.rechargecount = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (typeof message.amount === "number")
          object.amount = options.longs === String ? String(message.amount) : message.amount;
        else
          object.amount =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.amount)
              : options.longs === Number
                ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                : message.amount;
      if (message.coin != null && message.hasOwnProperty("coin"))
        if (typeof message.coin === "number")
          object.coin = options.longs === String ? String(message.coin) : message.coin;
        else
          object.coin =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.coin)
              : options.longs === Number
                ? new $util.LongBits(message.coin.low >>> 0, message.coin.high >>> 0).toNumber()
                : message.coin;
      if (message.totalbet != null && message.hasOwnProperty("totalbet"))
        if (typeof message.totalbet === "number")
          object.totalbet = options.longs === String ? String(message.totalbet) : message.totalbet;
        else
          object.totalbet =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.totalbet)
              : options.longs === Number
                ? new $util.LongBits(message.totalbet.low >>> 0, message.totalbet.high >>> 0).toNumber(true)
                : message.totalbet;
      if (message.totalrecharge != null && message.hasOwnProperty("totalrecharge"))
        if (typeof message.totalrecharge === "number")
          object.totalrecharge = options.longs === String ? String(message.totalrecharge) : message.totalrecharge;
        else
          object.totalrecharge =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.totalrecharge)
              : options.longs === Number
                ? new $util.LongBits(message.totalrecharge.low >>> 0, message.totalrecharge.high >>> 0).toNumber(true)
                : message.totalrecharge;
      if (message.rechargecount != null && message.hasOwnProperty("rechargecount"))
        object.rechargecount = message.rechargecount;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this AssetResp to JSON.
     * @function toJSON
     * @memberof asset.AssetResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AssetResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AssetResp
     * @function getTypeUrl
     * @memberof asset.AssetResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AssetResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.AssetResp";
    };

    return AssetResp;
  })();

  /**
   * OP_FIELD enum.
   * @name asset.OP_FIELD
   * @enum {number}
   * @property {number} FIELD_NONE=0 FIELD_NONE value
   * @property {number} FIELD_AMOUNT=1 FIELD_AMOUNT value
   * @property {number} FIELD_COIN=2 FIELD_COIN value
   * @property {number} FIELD_TOTALBET=3 FIELD_TOTALBET value
   * @property {number} FIELD_TOTALRECHARGE=4 FIELD_TOTALRECHARGE value
   * @property {number} FIELD_BALANCE=5 FIELD_BALANCE value
   * @property {number} FIELD_WITHDRAW_LIMIT=6 FIELD_WITHDRAW_LIMIT value
   * @property {number} FIELD_TOTALWITHDRAW=7 FIELD_TOTALWITHDRAW value
   * @property {number} FIELD_TOTALWIN=8 FIELD_TOTALWIN value
   * @property {number} FIELD_WITHDRAWCOUNT=9 FIELD_WITHDRAWCOUNT value
   * @property {number} FIELD_RECHARGECOUNT=10 FIELD_RECHARGECOUNT value
   * @property {number} FIELD_TOTALVALIDFLOW=11 FIELD_TOTALVALIDFLOW value
   * @property {number} FIELD_VSTOTALVALIDFLOW=12 FIELD_VSTOTALVALIDFLOW value
   */
  asset.OP_FIELD = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "FIELD_NONE")] = 0;
    values[(valuesById[1] = "FIELD_AMOUNT")] = 1;
    values[(valuesById[2] = "FIELD_COIN")] = 2;
    values[(valuesById[3] = "FIELD_TOTALBET")] = 3;
    values[(valuesById[4] = "FIELD_TOTALRECHARGE")] = 4;
    values[(valuesById[5] = "FIELD_BALANCE")] = 5;
    values[(valuesById[6] = "FIELD_WITHDRAW_LIMIT")] = 6;
    values[(valuesById[7] = "FIELD_TOTALWITHDRAW")] = 7;
    values[(valuesById[8] = "FIELD_TOTALWIN")] = 8;
    values[(valuesById[9] = "FIELD_WITHDRAWCOUNT")] = 9;
    values[(valuesById[10] = "FIELD_RECHARGECOUNT")] = 10;
    values[(valuesById[11] = "FIELD_TOTALVALIDFLOW")] = 11;
    values[(valuesById[12] = "FIELD_VSTOTALVALIDFLOW")] = 12;
    return values;
  })();

  /**
   * OP_ACT enum.
   * @name asset.OP_ACT
   * @enum {number}
   * @property {number} ACT_NONE=0 ACT_NONE value
   * @property {number} ACT_BET=1 ACT_BET value
   * @property {number} ACT_WINLOST=2 ACT_WINLOST value
   * @property {number} ACT_RECHARGE=3 ACT_RECHARGE value
   * @property {number} ACT_SPIN_WITHDRAW=4 ACT_SPIN_WITHDRAW value
   * @property {number} ACT_WITHDRAW=5 ACT_WITHDRAW value
   * @property {number} ACT_REGISTER=6 ACT_REGISTER value
   * @property {number} ACT_BACKEND=7 ACT_BACKEND value
   * @property {number} ACT_REBATE=8 ACT_REBATE value
   * @property {number} ACT_CANCEL_BET=9 ACT_CANCEL_BET value
   * @property {number} ACT_CANCEL_WINLOST=10 ACT_CANCEL_WINLOST value
   * @property {number} ACT_FREEZE=11 ACT_FREEZE value
   * @property {number} ACT_VIP_WELFARE=12 ACT_VIP_WELFARE value
   * @property {number} ACT_WITHDRAW_REJECT=13 ACT_WITHDRAW_REJECT value
   * @property {number} ACT_INVITE=14 ACT_INVITE value
   * @property {number} ACT_COMMISSIONS=15 ACT_COMMISSIONS value
   * @property {number} ACT_GAME_REWARD=16 ACT_GAME_REWARD value
   * @property {number} ACT_RANK=17 ACT_RANK value
   * @property {number} ACT_VIP_UPGRADE=18 ACT_VIP_UPGRADE value
   * @property {number} ACT_ACTIVITYGIFT=19 ACT_ACTIVITYGIFT value
   * @property {number} ACT_GIFT=20 ACT_GIFT value
   * @property {number} ACT_VIP_BET_REDATE=21 ACT_VIP_BET_REDATE value
   * @property {number} ACT_JACKPOT_REWARD=22 ACT_JACKPOT_REWARD value
   * @property {number} ACT_VALID_FLOW=23 ACT_VALID_FLOW value
   * @property {number} ACT_ACCUMULATECHARGE=24 ACT_ACCUMULATECHARGE value
   * @property {number} ACT_ACTIVITY_REGRESS_GIFT=25 ACT_ACTIVITY_REGRESS_GIFT value
   * @property {number} ACT_ACTIVITY_REGRESS_WELFARE=26 ACT_ACTIVITY_REGRESS_WELFARE value
   * @property {number} ACT_AGENT_RANK=27 ACT_AGENT_RANK value
   * @property {number} ACT_MAIL=28 ACT_MAIL value
   */
  asset.OP_ACT = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "ACT_NONE")] = 0;
    values[(valuesById[1] = "ACT_BET")] = 1;
    values[(valuesById[2] = "ACT_WINLOST")] = 2;
    values[(valuesById[3] = "ACT_RECHARGE")] = 3;
    values[(valuesById[4] = "ACT_SPIN_WITHDRAW")] = 4;
    values[(valuesById[5] = "ACT_WITHDRAW")] = 5;
    values[(valuesById[6] = "ACT_REGISTER")] = 6;
    values[(valuesById[7] = "ACT_BACKEND")] = 7;
    values[(valuesById[8] = "ACT_REBATE")] = 8;
    values[(valuesById[9] = "ACT_CANCEL_BET")] = 9;
    values[(valuesById[10] = "ACT_CANCEL_WINLOST")] = 10;
    values[(valuesById[11] = "ACT_FREEZE")] = 11;
    values[(valuesById[12] = "ACT_VIP_WELFARE")] = 12;
    values[(valuesById[13] = "ACT_WITHDRAW_REJECT")] = 13;
    values[(valuesById[14] = "ACT_INVITE")] = 14;
    values[(valuesById[15] = "ACT_COMMISSIONS")] = 15;
    values[(valuesById[16] = "ACT_GAME_REWARD")] = 16;
    values[(valuesById[17] = "ACT_RANK")] = 17;
    values[(valuesById[18] = "ACT_VIP_UPGRADE")] = 18;
    values[(valuesById[19] = "ACT_ACTIVITYGIFT")] = 19;
    values[(valuesById[20] = "ACT_GIFT")] = 20;
    values[(valuesById[21] = "ACT_VIP_BET_REDATE")] = 21;
    values[(valuesById[22] = "ACT_JACKPOT_REWARD")] = 22;
    values[(valuesById[23] = "ACT_VALID_FLOW")] = 23;
    values[(valuesById[24] = "ACT_ACCUMULATECHARGE")] = 24;
    values[(valuesById[25] = "ACT_ACTIVITY_REGRESS_GIFT")] = 25;
    values[(valuesById[26] = "ACT_ACTIVITY_REGRESS_WELFARE")] = 26;
    values[(valuesById[27] = "ACT_AGENT_RANK")] = 27;
    values[(valuesById[28] = "ACT_MAIL")] = 28;
    return values;
  })();

  /**
   * OP_UPDATE_TYPE enum.
   * @name asset.OP_UPDATE_TYPE
   * @enum {number}
   * @property {number} UPDATE_TYPE_INC_DEC=0 UPDATE_TYPE_INC_DEC value
   * @property {number} UPDATE_TYPE_SET=1 UPDATE_TYPE_SET value
   */
  asset.OP_UPDATE_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "UPDATE_TYPE_INC_DEC")] = 0;
    values[(valuesById[1] = "UPDATE_TYPE_SET")] = 1;
    return values;
  })();

  asset.FieldValue = (function () {
    /**
     * Properties of a FieldValue.
     * @memberof asset
     * @interface IFieldValue
     * @property {asset.OP_FIELD|null} [field] FieldValue field
     * @property {number|Long|null} [val] FieldValue val
     */

    /**
     * Constructs a new FieldValue.
     * @memberof asset
     * @classdesc Represents a FieldValue.
     * @implements IFieldValue
     * @constructor
     * @param {asset.IFieldValue=} [properties] Properties to set
     */
    function FieldValue(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * FieldValue field.
     * @member {asset.OP_FIELD} field
     * @memberof asset.FieldValue
     * @instance
     */
    FieldValue.prototype.field = 0;

    /**
     * FieldValue val.
     * @member {number|Long} val
     * @memberof asset.FieldValue
     * @instance
     */
    FieldValue.prototype.val = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new FieldValue instance using the specified properties.
     * @function create
     * @memberof asset.FieldValue
     * @static
     * @param {asset.IFieldValue=} [properties] Properties to set
     * @returns {asset.FieldValue} FieldValue instance
     */
    FieldValue.create = function create(properties) {
      return new FieldValue(properties);
    };

    /**
     * Encodes the specified FieldValue message. Does not implicitly {@link asset.FieldValue.verify|verify} messages.
     * @function encode
     * @memberof asset.FieldValue
     * @static
     * @param {asset.IFieldValue} message FieldValue message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FieldValue.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.field != null && Object.hasOwnProperty.call(message, "field"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.field);
      if (message.val != null && Object.hasOwnProperty.call(message, "val"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).sint64(message.val);
      return writer;
    };

    /**
     * Encodes the specified FieldValue message, length delimited. Does not implicitly {@link asset.FieldValue.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.FieldValue
     * @static
     * @param {asset.IFieldValue} message FieldValue message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FieldValue.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FieldValue message from the specified reader or buffer.
     * @function decode
     * @memberof asset.FieldValue
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.FieldValue} FieldValue
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FieldValue.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.FieldValue();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.field = reader.int32();
            break;
          }
          case 2: {
            message.val = reader.sint64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a FieldValue message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.FieldValue
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.FieldValue} FieldValue
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FieldValue.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FieldValue message.
     * @function verify
     * @memberof asset.FieldValue
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FieldValue.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.field != null && message.hasOwnProperty("field"))
        switch (message.field) {
          default:
            return "field: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
            break;
        }
      if (message.val != null && message.hasOwnProperty("val"))
        if (
          !$util.isInteger(message.val) &&
          !(message.val && $util.isInteger(message.val.low) && $util.isInteger(message.val.high))
        )
          return "val: integer|Long expected";
      return null;
    };

    /**
     * Creates a FieldValue message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.FieldValue
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.FieldValue} FieldValue
     */
    FieldValue.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.FieldValue) return object;
      var message = new $root.asset.FieldValue();
      switch (object.field) {
        default:
          if (typeof object.field === "number") {
            message.field = object.field;
            break;
          }
          break;
        case "FIELD_NONE":
        case 0:
          message.field = 0;
          break;
        case "FIELD_AMOUNT":
        case 1:
          message.field = 1;
          break;
        case "FIELD_COIN":
        case 2:
          message.field = 2;
          break;
        case "FIELD_TOTALBET":
        case 3:
          message.field = 3;
          break;
        case "FIELD_TOTALRECHARGE":
        case 4:
          message.field = 4;
          break;
        case "FIELD_BALANCE":
        case 5:
          message.field = 5;
          break;
        case "FIELD_WITHDRAW_LIMIT":
        case 6:
          message.field = 6;
          break;
        case "FIELD_TOTALWITHDRAW":
        case 7:
          message.field = 7;
          break;
        case "FIELD_TOTALWIN":
        case 8:
          message.field = 8;
          break;
        case "FIELD_WITHDRAWCOUNT":
        case 9:
          message.field = 9;
          break;
        case "FIELD_RECHARGECOUNT":
        case 10:
          message.field = 10;
          break;
        case "FIELD_TOTALVALIDFLOW":
        case 11:
          message.field = 11;
          break;
        case "FIELD_VSTOTALVALIDFLOW":
        case 12:
          message.field = 12;
          break;
      }
      if (object.val != null)
        if ($util.Long) (message.val = $util.Long.fromValue(object.val)).unsigned = false;
        else if (typeof object.val === "string") message.val = parseInt(object.val, 10);
        else if (typeof object.val === "number") message.val = object.val;
        else if (typeof object.val === "object")
          message.val = new $util.LongBits(object.val.low >>> 0, object.val.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a FieldValue message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.FieldValue
     * @static
     * @param {asset.FieldValue} message FieldValue
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FieldValue.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.field = options.enums === String ? "FIELD_NONE" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.val = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.val = options.longs === String ? "0" : 0;
      }
      if (message.field != null && message.hasOwnProperty("field"))
        object.field =
          options.enums === String
            ? $root.asset.OP_FIELD[message.field] === undefined
              ? message.field
              : $root.asset.OP_FIELD[message.field]
            : message.field;
      if (message.val != null && message.hasOwnProperty("val"))
        if (typeof message.val === "number") object.val = options.longs === String ? String(message.val) : message.val;
        else
          object.val =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.val)
              : options.longs === Number
                ? new $util.LongBits(message.val.low >>> 0, message.val.high >>> 0).toNumber()
                : message.val;
      return object;
    };

    /**
     * Converts this FieldValue to JSON.
     * @function toJSON
     * @memberof asset.FieldValue
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FieldValue.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FieldValue
     * @function getTypeUrl
     * @memberof asset.FieldValue
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FieldValue.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.FieldValue";
    };

    return FieldValue;
  })();

  asset.AssetByFieldReq = (function () {
    /**
     * Properties of an AssetByFieldReq.
     * @memberof asset
     * @interface IAssetByFieldReq
     * @property {number|null} [uid] AssetByFieldReq uid
     * @property {Array.<asset.OP_FIELD>|null} [fields] AssetByFieldReq fields
     * @property {Uint8Array|null} [trans] AssetByFieldReq trans
     */

    /**
     * Constructs a new AssetByFieldReq.
     * @memberof asset
     * @classdesc Represents an AssetByFieldReq.
     * @implements IAssetByFieldReq
     * @constructor
     * @param {asset.IAssetByFieldReq=} [properties] Properties to set
     */
    function AssetByFieldReq(properties) {
      this.fields = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AssetByFieldReq uid.
     * @member {number} uid
     * @memberof asset.AssetByFieldReq
     * @instance
     */
    AssetByFieldReq.prototype.uid = 0;

    /**
     * AssetByFieldReq fields.
     * @member {Array.<asset.OP_FIELD>} fields
     * @memberof asset.AssetByFieldReq
     * @instance
     */
    AssetByFieldReq.prototype.fields = $util.emptyArray;

    /**
     * AssetByFieldReq trans.
     * @member {Uint8Array} trans
     * @memberof asset.AssetByFieldReq
     * @instance
     */
    AssetByFieldReq.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new AssetByFieldReq instance using the specified properties.
     * @function create
     * @memberof asset.AssetByFieldReq
     * @static
     * @param {asset.IAssetByFieldReq=} [properties] Properties to set
     * @returns {asset.AssetByFieldReq} AssetByFieldReq instance
     */
    AssetByFieldReq.create = function create(properties) {
      return new AssetByFieldReq(properties);
    };

    /**
     * Encodes the specified AssetByFieldReq message. Does not implicitly {@link asset.AssetByFieldReq.verify|verify} messages.
     * @function encode
     * @memberof asset.AssetByFieldReq
     * @static
     * @param {asset.IAssetByFieldReq} message AssetByFieldReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetByFieldReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.fields != null && message.fields.length) {
        writer.uint32(/* id 2, wireType 2 =*/ 18).fork();
        for (var i = 0; i < message.fields.length; ++i) writer.int32(message.fields[i]);
        writer.ldelim();
      }
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified AssetByFieldReq message, length delimited. Does not implicitly {@link asset.AssetByFieldReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.AssetByFieldReq
     * @static
     * @param {asset.IAssetByFieldReq} message AssetByFieldReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetByFieldReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AssetByFieldReq message from the specified reader or buffer.
     * @function decode
     * @memberof asset.AssetByFieldReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.AssetByFieldReq} AssetByFieldReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetByFieldReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.AssetByFieldReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            if (!(message.fields && message.fields.length)) message.fields = [];
            if ((tag & 7) === 2) {
              var end2 = reader.uint32() + reader.pos;
              while (reader.pos < end2) message.fields.push(reader.int32());
            } else message.fields.push(reader.int32());
            break;
          }
          case 3: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AssetByFieldReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.AssetByFieldReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.AssetByFieldReq} AssetByFieldReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetByFieldReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AssetByFieldReq message.
     * @function verify
     * @memberof asset.AssetByFieldReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AssetByFieldReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.fields != null && message.hasOwnProperty("fields")) {
        if (!Array.isArray(message.fields)) return "fields: array expected";
        for (var i = 0; i < message.fields.length; ++i)
          switch (message.fields[i]) {
            default:
              return "fields: enum value[] expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
              break;
          }
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates an AssetByFieldReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.AssetByFieldReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.AssetByFieldReq} AssetByFieldReq
     */
    AssetByFieldReq.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.AssetByFieldReq) return object;
      var message = new $root.asset.AssetByFieldReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.fields) {
        if (!Array.isArray(object.fields)) throw TypeError(".asset.AssetByFieldReq.fields: array expected");
        message.fields = [];
        for (var i = 0; i < object.fields.length; ++i)
          switch (object.fields[i]) {
            default:
              if (typeof object.fields[i] === "number") {
                message.fields[i] = object.fields[i];
                break;
              }
            case "FIELD_NONE":
            case 0:
              message.fields[i] = 0;
              break;
            case "FIELD_AMOUNT":
            case 1:
              message.fields[i] = 1;
              break;
            case "FIELD_COIN":
            case 2:
              message.fields[i] = 2;
              break;
            case "FIELD_TOTALBET":
            case 3:
              message.fields[i] = 3;
              break;
            case "FIELD_TOTALRECHARGE":
            case 4:
              message.fields[i] = 4;
              break;
            case "FIELD_BALANCE":
            case 5:
              message.fields[i] = 5;
              break;
            case "FIELD_WITHDRAW_LIMIT":
            case 6:
              message.fields[i] = 6;
              break;
            case "FIELD_TOTALWITHDRAW":
            case 7:
              message.fields[i] = 7;
              break;
            case "FIELD_TOTALWIN":
            case 8:
              message.fields[i] = 8;
              break;
            case "FIELD_WITHDRAWCOUNT":
            case 9:
              message.fields[i] = 9;
              break;
            case "FIELD_RECHARGECOUNT":
            case 10:
              message.fields[i] = 10;
              break;
            case "FIELD_TOTALVALIDFLOW":
            case 11:
              message.fields[i] = 11;
              break;
            case "FIELD_VSTOTALVALIDFLOW":
            case 12:
              message.fields[i] = 12;
              break;
          }
      }
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from an AssetByFieldReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.AssetByFieldReq
     * @static
     * @param {asset.AssetByFieldReq} message AssetByFieldReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AssetByFieldReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.fields = [];
      if (options.defaults) {
        object.uid = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.fields && message.fields.length) {
        object.fields = [];
        for (var j = 0; j < message.fields.length; ++j)
          object.fields[j] =
            options.enums === String
              ? $root.asset.OP_FIELD[message.fields[j]] === undefined
                ? message.fields[j]
                : $root.asset.OP_FIELD[message.fields[j]]
              : message.fields[j];
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this AssetByFieldReq to JSON.
     * @function toJSON
     * @memberof asset.AssetByFieldReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AssetByFieldReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AssetByFieldReq
     * @function getTypeUrl
     * @memberof asset.AssetByFieldReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AssetByFieldReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.AssetByFieldReq";
    };

    return AssetByFieldReq;
  })();

  asset.AssetByFieldResp = (function () {
    /**
     * Properties of an AssetByFieldResp.
     * @memberof asset
     * @interface IAssetByFieldResp
     * @property {number|null} [uid] AssetByFieldResp uid
     * @property {number|null} [result] AssetByFieldResp result
     * @property {Array.<asset.IFieldValue>|null} [fieldval] AssetByFieldResp fieldval
     * @property {Uint8Array|null} [trans] AssetByFieldResp trans
     */

    /**
     * Constructs a new AssetByFieldResp.
     * @memberof asset
     * @classdesc Represents an AssetByFieldResp.
     * @implements IAssetByFieldResp
     * @constructor
     * @param {asset.IAssetByFieldResp=} [properties] Properties to set
     */
    function AssetByFieldResp(properties) {
      this.fieldval = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AssetByFieldResp uid.
     * @member {number} uid
     * @memberof asset.AssetByFieldResp
     * @instance
     */
    AssetByFieldResp.prototype.uid = 0;

    /**
     * AssetByFieldResp result.
     * @member {number} result
     * @memberof asset.AssetByFieldResp
     * @instance
     */
    AssetByFieldResp.prototype.result = 0;

    /**
     * AssetByFieldResp fieldval.
     * @member {Array.<asset.IFieldValue>} fieldval
     * @memberof asset.AssetByFieldResp
     * @instance
     */
    AssetByFieldResp.prototype.fieldval = $util.emptyArray;

    /**
     * AssetByFieldResp trans.
     * @member {Uint8Array} trans
     * @memberof asset.AssetByFieldResp
     * @instance
     */
    AssetByFieldResp.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new AssetByFieldResp instance using the specified properties.
     * @function create
     * @memberof asset.AssetByFieldResp
     * @static
     * @param {asset.IAssetByFieldResp=} [properties] Properties to set
     * @returns {asset.AssetByFieldResp} AssetByFieldResp instance
     */
    AssetByFieldResp.create = function create(properties) {
      return new AssetByFieldResp(properties);
    };

    /**
     * Encodes the specified AssetByFieldResp message. Does not implicitly {@link asset.AssetByFieldResp.verify|verify} messages.
     * @function encode
     * @memberof asset.AssetByFieldResp
     * @static
     * @param {asset.IAssetByFieldResp} message AssetByFieldResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetByFieldResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.fieldval != null && message.fieldval.length)
        for (var i = 0; i < message.fieldval.length; ++i)
          $root.asset.FieldValue.encode(message.fieldval[i], writer.uint32(/* id 3, wireType 2 =*/ 26).fork()).ldelim();
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified AssetByFieldResp message, length delimited. Does not implicitly {@link asset.AssetByFieldResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.AssetByFieldResp
     * @static
     * @param {asset.IAssetByFieldResp} message AssetByFieldResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetByFieldResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AssetByFieldResp message from the specified reader or buffer.
     * @function decode
     * @memberof asset.AssetByFieldResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.AssetByFieldResp} AssetByFieldResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetByFieldResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.AssetByFieldResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            if (!(message.fieldval && message.fieldval.length)) message.fieldval = [];
            message.fieldval.push($root.asset.FieldValue.decode(reader, reader.uint32()));
            break;
          }
          case 4: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AssetByFieldResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.AssetByFieldResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.AssetByFieldResp} AssetByFieldResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetByFieldResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AssetByFieldResp message.
     * @function verify
     * @memberof asset.AssetByFieldResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AssetByFieldResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.fieldval != null && message.hasOwnProperty("fieldval")) {
        if (!Array.isArray(message.fieldval)) return "fieldval: array expected";
        for (var i = 0; i < message.fieldval.length; ++i) {
          var error = $root.asset.FieldValue.verify(message.fieldval[i]);
          if (error) return "fieldval." + error;
        }
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates an AssetByFieldResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.AssetByFieldResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.AssetByFieldResp} AssetByFieldResp
     */
    AssetByFieldResp.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.AssetByFieldResp) return object;
      var message = new $root.asset.AssetByFieldResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.fieldval) {
        if (!Array.isArray(object.fieldval)) throw TypeError(".asset.AssetByFieldResp.fieldval: array expected");
        message.fieldval = [];
        for (var i = 0; i < object.fieldval.length; ++i) {
          if (typeof object.fieldval[i] !== "object")
            throw TypeError(".asset.AssetByFieldResp.fieldval: object expected");
          message.fieldval[i] = $root.asset.FieldValue.fromObject(object.fieldval[i]);
        }
      }
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from an AssetByFieldResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.AssetByFieldResp
     * @static
     * @param {asset.AssetByFieldResp} message AssetByFieldResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AssetByFieldResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.fieldval = [];
      if (options.defaults) {
        object.uid = 0;
        object.result = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.fieldval && message.fieldval.length) {
        object.fieldval = [];
        for (var j = 0; j < message.fieldval.length; ++j)
          object.fieldval[j] = $root.asset.FieldValue.toObject(message.fieldval[j], options);
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this AssetByFieldResp to JSON.
     * @function toJSON
     * @memberof asset.AssetByFieldResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AssetByFieldResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AssetByFieldResp
     * @function getTypeUrl
     * @memberof asset.AssetByFieldResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AssetByFieldResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.AssetByFieldResp";
    };

    return AssetByFieldResp;
  })();

  asset.Oparition = (function () {
    /**
     * Properties of an Oparition.
     * @memberof asset
     * @interface IOparition
     * @property {asset.OP_FIELD|null} [field] Oparition field
     * @property {asset.OP_ACT|null} [act] Oparition act
     * @property {number|Long|null} [delt] Oparition delt
     * @property {asset.OP_UPDATE_TYPE|null} [upatetype] Oparition upatetype
     */

    /**
     * Constructs a new Oparition.
     * @memberof asset
     * @classdesc Represents an Oparition.
     * @implements IOparition
     * @constructor
     * @param {asset.IOparition=} [properties] Properties to set
     */
    function Oparition(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Oparition field.
     * @member {asset.OP_FIELD} field
     * @memberof asset.Oparition
     * @instance
     */
    Oparition.prototype.field = 0;

    /**
     * Oparition act.
     * @member {asset.OP_ACT} act
     * @memberof asset.Oparition
     * @instance
     */
    Oparition.prototype.act = 0;

    /**
     * Oparition delt.
     * @member {number|Long} delt
     * @memberof asset.Oparition
     * @instance
     */
    Oparition.prototype.delt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Oparition upatetype.
     * @member {asset.OP_UPDATE_TYPE} upatetype
     * @memberof asset.Oparition
     * @instance
     */
    Oparition.prototype.upatetype = 0;

    /**
     * Creates a new Oparition instance using the specified properties.
     * @function create
     * @memberof asset.Oparition
     * @static
     * @param {asset.IOparition=} [properties] Properties to set
     * @returns {asset.Oparition} Oparition instance
     */
    Oparition.create = function create(properties) {
      return new Oparition(properties);
    };

    /**
     * Encodes the specified Oparition message. Does not implicitly {@link asset.Oparition.verify|verify} messages.
     * @function encode
     * @memberof asset.Oparition
     * @static
     * @param {asset.IOparition} message Oparition message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Oparition.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.field != null && Object.hasOwnProperty.call(message, "field"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.field);
      if (message.act != null && Object.hasOwnProperty.call(message, "act"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.act);
      if (message.delt != null && Object.hasOwnProperty.call(message, "delt"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).sint64(message.delt);
      if (message.upatetype != null && Object.hasOwnProperty.call(message, "upatetype"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.upatetype);
      return writer;
    };

    /**
     * Encodes the specified Oparition message, length delimited. Does not implicitly {@link asset.Oparition.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.Oparition
     * @static
     * @param {asset.IOparition} message Oparition message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Oparition.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an Oparition message from the specified reader or buffer.
     * @function decode
     * @memberof asset.Oparition
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.Oparition} Oparition
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Oparition.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.Oparition();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.field = reader.int32();
            break;
          }
          case 2: {
            message.act = reader.int32();
            break;
          }
          case 3: {
            message.delt = reader.sint64();
            break;
          }
          case 4: {
            message.upatetype = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an Oparition message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.Oparition
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.Oparition} Oparition
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Oparition.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an Oparition message.
     * @function verify
     * @memberof asset.Oparition
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Oparition.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.field != null && message.hasOwnProperty("field"))
        switch (message.field) {
          default:
            return "field: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
            break;
        }
      if (message.act != null && message.hasOwnProperty("act"))
        switch (message.act) {
          default:
            return "act: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
          case 9:
          case 10:
          case 11:
          case 12:
          case 13:
          case 14:
          case 15:
          case 16:
          case 17:
          case 18:
          case 19:
          case 20:
          case 21:
          case 22:
          case 23:
          case 24:
          case 25:
          case 26:
          case 27:
          case 28:
            break;
        }
      if (message.delt != null && message.hasOwnProperty("delt"))
        if (
          !$util.isInteger(message.delt) &&
          !(message.delt && $util.isInteger(message.delt.low) && $util.isInteger(message.delt.high))
        )
          return "delt: integer|Long expected";
      if (message.upatetype != null && message.hasOwnProperty("upatetype"))
        switch (message.upatetype) {
          default:
            return "upatetype: enum value expected";
          case 0:
          case 1:
            break;
        }
      return null;
    };

    /**
     * Creates an Oparition message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.Oparition
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.Oparition} Oparition
     */
    Oparition.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.Oparition) return object;
      var message = new $root.asset.Oparition();
      switch (object.field) {
        default:
          if (typeof object.field === "number") {
            message.field = object.field;
            break;
          }
          break;
        case "FIELD_NONE":
        case 0:
          message.field = 0;
          break;
        case "FIELD_AMOUNT":
        case 1:
          message.field = 1;
          break;
        case "FIELD_COIN":
        case 2:
          message.field = 2;
          break;
        case "FIELD_TOTALBET":
        case 3:
          message.field = 3;
          break;
        case "FIELD_TOTALRECHARGE":
        case 4:
          message.field = 4;
          break;
        case "FIELD_BALANCE":
        case 5:
          message.field = 5;
          break;
        case "FIELD_WITHDRAW_LIMIT":
        case 6:
          message.field = 6;
          break;
        case "FIELD_TOTALWITHDRAW":
        case 7:
          message.field = 7;
          break;
        case "FIELD_TOTALWIN":
        case 8:
          message.field = 8;
          break;
        case "FIELD_WITHDRAWCOUNT":
        case 9:
          message.field = 9;
          break;
        case "FIELD_RECHARGECOUNT":
        case 10:
          message.field = 10;
          break;
        case "FIELD_TOTALVALIDFLOW":
        case 11:
          message.field = 11;
          break;
        case "FIELD_VSTOTALVALIDFLOW":
        case 12:
          message.field = 12;
          break;
      }
      switch (object.act) {
        default:
          if (typeof object.act === "number") {
            message.act = object.act;
            break;
          }
          break;
        case "ACT_NONE":
        case 0:
          message.act = 0;
          break;
        case "ACT_BET":
        case 1:
          message.act = 1;
          break;
        case "ACT_WINLOST":
        case 2:
          message.act = 2;
          break;
        case "ACT_RECHARGE":
        case 3:
          message.act = 3;
          break;
        case "ACT_SPIN_WITHDRAW":
        case 4:
          message.act = 4;
          break;
        case "ACT_WITHDRAW":
        case 5:
          message.act = 5;
          break;
        case "ACT_REGISTER":
        case 6:
          message.act = 6;
          break;
        case "ACT_BACKEND":
        case 7:
          message.act = 7;
          break;
        case "ACT_REBATE":
        case 8:
          message.act = 8;
          break;
        case "ACT_CANCEL_BET":
        case 9:
          message.act = 9;
          break;
        case "ACT_CANCEL_WINLOST":
        case 10:
          message.act = 10;
          break;
        case "ACT_FREEZE":
        case 11:
          message.act = 11;
          break;
        case "ACT_VIP_WELFARE":
        case 12:
          message.act = 12;
          break;
        case "ACT_WITHDRAW_REJECT":
        case 13:
          message.act = 13;
          break;
        case "ACT_INVITE":
        case 14:
          message.act = 14;
          break;
        case "ACT_COMMISSIONS":
        case 15:
          message.act = 15;
          break;
        case "ACT_GAME_REWARD":
        case 16:
          message.act = 16;
          break;
        case "ACT_RANK":
        case 17:
          message.act = 17;
          break;
        case "ACT_VIP_UPGRADE":
        case 18:
          message.act = 18;
          break;
        case "ACT_ACTIVITYGIFT":
        case 19:
          message.act = 19;
          break;
        case "ACT_GIFT":
        case 20:
          message.act = 20;
          break;
        case "ACT_VIP_BET_REDATE":
        case 21:
          message.act = 21;
          break;
        case "ACT_JACKPOT_REWARD":
        case 22:
          message.act = 22;
          break;
        case "ACT_VALID_FLOW":
        case 23:
          message.act = 23;
          break;
        case "ACT_ACCUMULATECHARGE":
        case 24:
          message.act = 24;
          break;
        case "ACT_ACTIVITY_REGRESS_GIFT":
        case 25:
          message.act = 25;
          break;
        case "ACT_ACTIVITY_REGRESS_WELFARE":
        case 26:
          message.act = 26;
          break;
        case "ACT_AGENT_RANK":
        case 27:
          message.act = 27;
          break;
        case "ACT_MAIL":
        case 28:
          message.act = 28;
          break;
      }
      if (object.delt != null)
        if ($util.Long) (message.delt = $util.Long.fromValue(object.delt)).unsigned = false;
        else if (typeof object.delt === "string") message.delt = parseInt(object.delt, 10);
        else if (typeof object.delt === "number") message.delt = object.delt;
        else if (typeof object.delt === "object")
          message.delt = new $util.LongBits(object.delt.low >>> 0, object.delt.high >>> 0).toNumber();
      switch (object.upatetype) {
        default:
          if (typeof object.upatetype === "number") {
            message.upatetype = object.upatetype;
            break;
          }
          break;
        case "UPDATE_TYPE_INC_DEC":
        case 0:
          message.upatetype = 0;
          break;
        case "UPDATE_TYPE_SET":
        case 1:
          message.upatetype = 1;
          break;
      }
      return message;
    };

    /**
     * Creates a plain object from an Oparition message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.Oparition
     * @static
     * @param {asset.Oparition} message Oparition
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Oparition.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.field = options.enums === String ? "FIELD_NONE" : 0;
        object.act = options.enums === String ? "ACT_NONE" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.delt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.delt = options.longs === String ? "0" : 0;
        object.upatetype = options.enums === String ? "UPDATE_TYPE_INC_DEC" : 0;
      }
      if (message.field != null && message.hasOwnProperty("field"))
        object.field =
          options.enums === String
            ? $root.asset.OP_FIELD[message.field] === undefined
              ? message.field
              : $root.asset.OP_FIELD[message.field]
            : message.field;
      if (message.act != null && message.hasOwnProperty("act"))
        object.act =
          options.enums === String
            ? $root.asset.OP_ACT[message.act] === undefined
              ? message.act
              : $root.asset.OP_ACT[message.act]
            : message.act;
      if (message.delt != null && message.hasOwnProperty("delt"))
        if (typeof message.delt === "number")
          object.delt = options.longs === String ? String(message.delt) : message.delt;
        else
          object.delt =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.delt)
              : options.longs === Number
                ? new $util.LongBits(message.delt.low >>> 0, message.delt.high >>> 0).toNumber()
                : message.delt;
      if (message.upatetype != null && message.hasOwnProperty("upatetype"))
        object.upatetype =
          options.enums === String
            ? $root.asset.OP_UPDATE_TYPE[message.upatetype] === undefined
              ? message.upatetype
              : $root.asset.OP_UPDATE_TYPE[message.upatetype]
            : message.upatetype;
      return object;
    };

    /**
     * Converts this Oparition to JSON.
     * @function toJSON
     * @memberof asset.Oparition
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Oparition.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for Oparition
     * @function getTypeUrl
     * @memberof asset.Oparition
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    Oparition.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.Oparition";
    };

    return Oparition;
  })();

  asset.GameInfo = (function () {
    /**
     * Properties of a GameInfo.
     * @memberof asset
     * @interface IGameInfo
     * @property {string|null} [th] GameInfo th
     * @property {string|null} [game] GameInfo game
     * @property {string|null} [provider] GameInfo provider
     * @property {number|null} [gametype] GameInfo gametype
     * @property {string|null} [cycle] GameInfo cycle
     * @property {string|null} [orderid] GameInfo orderid
     * @property {string|null} [associatedorder] GameInfo associatedorder
     */

    /**
     * Constructs a new GameInfo.
     * @memberof asset
     * @classdesc Represents a GameInfo.
     * @implements IGameInfo
     * @constructor
     * @param {asset.IGameInfo=} [properties] Properties to set
     */
    function GameInfo(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameInfo th.
     * @member {string} th
     * @memberof asset.GameInfo
     * @instance
     */
    GameInfo.prototype.th = "";

    /**
     * GameInfo game.
     * @member {string} game
     * @memberof asset.GameInfo
     * @instance
     */
    GameInfo.prototype.game = "";

    /**
     * GameInfo provider.
     * @member {string} provider
     * @memberof asset.GameInfo
     * @instance
     */
    GameInfo.prototype.provider = "";

    /**
     * GameInfo gametype.
     * @member {number} gametype
     * @memberof asset.GameInfo
     * @instance
     */
    GameInfo.prototype.gametype = 0;

    /**
     * GameInfo cycle.
     * @member {string} cycle
     * @memberof asset.GameInfo
     * @instance
     */
    GameInfo.prototype.cycle = "";

    /**
     * GameInfo orderid.
     * @member {string} orderid
     * @memberof asset.GameInfo
     * @instance
     */
    GameInfo.prototype.orderid = "";

    /**
     * GameInfo associatedorder.
     * @member {string} associatedorder
     * @memberof asset.GameInfo
     * @instance
     */
    GameInfo.prototype.associatedorder = "";

    /**
     * Creates a new GameInfo instance using the specified properties.
     * @function create
     * @memberof asset.GameInfo
     * @static
     * @param {asset.IGameInfo=} [properties] Properties to set
     * @returns {asset.GameInfo} GameInfo instance
     */
    GameInfo.create = function create(properties) {
      return new GameInfo(properties);
    };

    /**
     * Encodes the specified GameInfo message. Does not implicitly {@link asset.GameInfo.verify|verify} messages.
     * @function encode
     * @memberof asset.GameInfo
     * @static
     * @param {asset.IGameInfo} message GameInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameInfo.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.th != null && Object.hasOwnProperty.call(message, "th"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.th);
      if (message.game != null && Object.hasOwnProperty.call(message, "game"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.game);
      if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.provider);
      if (message.gametype != null && Object.hasOwnProperty.call(message, "gametype"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.gametype);
      if (message.cycle != null && Object.hasOwnProperty.call(message, "cycle"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.cycle);
      if (message.orderid != null && Object.hasOwnProperty.call(message, "orderid"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.orderid);
      if (message.associatedorder != null && Object.hasOwnProperty.call(message, "associatedorder"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.associatedorder);
      return writer;
    };

    /**
     * Encodes the specified GameInfo message, length delimited. Does not implicitly {@link asset.GameInfo.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.GameInfo
     * @static
     * @param {asset.IGameInfo} message GameInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameInfo.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameInfo message from the specified reader or buffer.
     * @function decode
     * @memberof asset.GameInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.GameInfo} GameInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameInfo.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.GameInfo();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.th = reader.string();
            break;
          }
          case 2: {
            message.game = reader.string();
            break;
          }
          case 3: {
            message.provider = reader.string();
            break;
          }
          case 4: {
            message.gametype = reader.uint32();
            break;
          }
          case 5: {
            message.cycle = reader.string();
            break;
          }
          case 6: {
            message.orderid = reader.string();
            break;
          }
          case 7: {
            message.associatedorder = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameInfo message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.GameInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.GameInfo} GameInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameInfo.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameInfo message.
     * @function verify
     * @memberof asset.GameInfo
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameInfo.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.th != null && message.hasOwnProperty("th"))
        if (!$util.isString(message.th)) return "th: string expected";
      if (message.game != null && message.hasOwnProperty("game"))
        if (!$util.isString(message.game)) return "game: string expected";
      if (message.provider != null && message.hasOwnProperty("provider"))
        if (!$util.isString(message.provider)) return "provider: string expected";
      if (message.gametype != null && message.hasOwnProperty("gametype"))
        if (!$util.isInteger(message.gametype)) return "gametype: integer expected";
      if (message.cycle != null && message.hasOwnProperty("cycle"))
        if (!$util.isString(message.cycle)) return "cycle: string expected";
      if (message.orderid != null && message.hasOwnProperty("orderid"))
        if (!$util.isString(message.orderid)) return "orderid: string expected";
      if (message.associatedorder != null && message.hasOwnProperty("associatedorder"))
        if (!$util.isString(message.associatedorder)) return "associatedorder: string expected";
      return null;
    };

    /**
     * Creates a GameInfo message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.GameInfo
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.GameInfo} GameInfo
     */
    GameInfo.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.GameInfo) return object;
      var message = new $root.asset.GameInfo();
      if (object.th != null) message.th = String(object.th);
      if (object.game != null) message.game = String(object.game);
      if (object.provider != null) message.provider = String(object.provider);
      if (object.gametype != null) message.gametype = object.gametype >>> 0;
      if (object.cycle != null) message.cycle = String(object.cycle);
      if (object.orderid != null) message.orderid = String(object.orderid);
      if (object.associatedorder != null) message.associatedorder = String(object.associatedorder);
      return message;
    };

    /**
     * Creates a plain object from a GameInfo message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.GameInfo
     * @static
     * @param {asset.GameInfo} message GameInfo
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameInfo.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.th = "";
        object.game = "";
        object.provider = "";
        object.gametype = 0;
        object.cycle = "";
        object.orderid = "";
        object.associatedorder = "";
      }
      if (message.th != null && message.hasOwnProperty("th")) object.th = message.th;
      if (message.game != null && message.hasOwnProperty("game")) object.game = message.game;
      if (message.provider != null && message.hasOwnProperty("provider")) object.provider = message.provider;
      if (message.gametype != null && message.hasOwnProperty("gametype")) object.gametype = message.gametype;
      if (message.cycle != null && message.hasOwnProperty("cycle")) object.cycle = message.cycle;
      if (message.orderid != null && message.hasOwnProperty("orderid")) object.orderid = message.orderid;
      if (message.associatedorder != null && message.hasOwnProperty("associatedorder"))
        object.associatedorder = message.associatedorder;
      return object;
    };

    /**
     * Converts this GameInfo to JSON.
     * @function toJSON
     * @memberof asset.GameInfo
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameInfo.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameInfo
     * @function getTypeUrl
     * @memberof asset.GameInfo
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameInfo.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.GameInfo";
    };

    return GameInfo;
  })();

  asset.UpdateAssetReq = (function () {
    /**
     * Properties of an UpdateAssetReq.
     * @memberof asset
     * @interface IUpdateAssetReq
     * @property {number|null} [uid] UpdateAssetReq uid
     * @property {Array.<asset.IOparition>|null} [op] UpdateAssetReq op
     * @property {asset.IGameInfo|null} [gameinfo] UpdateAssetReq gameinfo
     * @property {string|null} [ext] UpdateAssetReq ext
     * @property {number|Long|null} [optime] UpdateAssetReq optime
     * @property {Uint8Array|null} [trans] UpdateAssetReq trans
     */

    /**
     * Constructs a new UpdateAssetReq.
     * @memberof asset
     * @classdesc Represents an UpdateAssetReq.
     * @implements IUpdateAssetReq
     * @constructor
     * @param {asset.IUpdateAssetReq=} [properties] Properties to set
     */
    function UpdateAssetReq(properties) {
      this.op = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateAssetReq uid.
     * @member {number} uid
     * @memberof asset.UpdateAssetReq
     * @instance
     */
    UpdateAssetReq.prototype.uid = 0;

    /**
     * UpdateAssetReq op.
     * @member {Array.<asset.IOparition>} op
     * @memberof asset.UpdateAssetReq
     * @instance
     */
    UpdateAssetReq.prototype.op = $util.emptyArray;

    /**
     * UpdateAssetReq gameinfo.
     * @member {asset.IGameInfo|null|undefined} gameinfo
     * @memberof asset.UpdateAssetReq
     * @instance
     */
    UpdateAssetReq.prototype.gameinfo = null;

    /**
     * UpdateAssetReq ext.
     * @member {string} ext
     * @memberof asset.UpdateAssetReq
     * @instance
     */
    UpdateAssetReq.prototype.ext = "";

    /**
     * UpdateAssetReq optime.
     * @member {number|Long} optime
     * @memberof asset.UpdateAssetReq
     * @instance
     */
    UpdateAssetReq.prototype.optime = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * UpdateAssetReq trans.
     * @member {Uint8Array} trans
     * @memberof asset.UpdateAssetReq
     * @instance
     */
    UpdateAssetReq.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new UpdateAssetReq instance using the specified properties.
     * @function create
     * @memberof asset.UpdateAssetReq
     * @static
     * @param {asset.IUpdateAssetReq=} [properties] Properties to set
     * @returns {asset.UpdateAssetReq} UpdateAssetReq instance
     */
    UpdateAssetReq.create = function create(properties) {
      return new UpdateAssetReq(properties);
    };

    /**
     * Encodes the specified UpdateAssetReq message. Does not implicitly {@link asset.UpdateAssetReq.verify|verify} messages.
     * @function encode
     * @memberof asset.UpdateAssetReq
     * @static
     * @param {asset.IUpdateAssetReq} message UpdateAssetReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAssetReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.op != null && message.op.length)
        for (var i = 0; i < message.op.length; ++i)
          $root.asset.Oparition.encode(message.op[i], writer.uint32(/* id 2, wireType 2 =*/ 18).fork()).ldelim();
      if (message.gameinfo != null && Object.hasOwnProperty.call(message, "gameinfo"))
        $root.asset.GameInfo.encode(message.gameinfo, writer.uint32(/* id 3, wireType 2 =*/ 26).fork()).ldelim();
      if (message.ext != null && Object.hasOwnProperty.call(message, "ext"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.ext);
      if (message.optime != null && Object.hasOwnProperty.call(message, "optime"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).uint64(message.optime);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified UpdateAssetReq message, length delimited. Does not implicitly {@link asset.UpdateAssetReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.UpdateAssetReq
     * @static
     * @param {asset.IUpdateAssetReq} message UpdateAssetReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAssetReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateAssetReq message from the specified reader or buffer.
     * @function decode
     * @memberof asset.UpdateAssetReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.UpdateAssetReq} UpdateAssetReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAssetReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.UpdateAssetReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            if (!(message.op && message.op.length)) message.op = [];
            message.op.push($root.asset.Oparition.decode(reader, reader.uint32()));
            break;
          }
          case 3: {
            message.gameinfo = $root.asset.GameInfo.decode(reader, reader.uint32());
            break;
          }
          case 4: {
            message.ext = reader.string();
            break;
          }
          case 5: {
            message.optime = reader.uint64();
            break;
          }
          case 6: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an UpdateAssetReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.UpdateAssetReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.UpdateAssetReq} UpdateAssetReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAssetReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateAssetReq message.
     * @function verify
     * @memberof asset.UpdateAssetReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateAssetReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.op != null && message.hasOwnProperty("op")) {
        if (!Array.isArray(message.op)) return "op: array expected";
        for (var i = 0; i < message.op.length; ++i) {
          var error = $root.asset.Oparition.verify(message.op[i]);
          if (error) return "op." + error;
        }
      }
      if (message.gameinfo != null && message.hasOwnProperty("gameinfo")) {
        var error = $root.asset.GameInfo.verify(message.gameinfo);
        if (error) return "gameinfo." + error;
      }
      if (message.ext != null && message.hasOwnProperty("ext"))
        if (!$util.isString(message.ext)) return "ext: string expected";
      if (message.optime != null && message.hasOwnProperty("optime"))
        if (
          !$util.isInteger(message.optime) &&
          !(message.optime && $util.isInteger(message.optime.low) && $util.isInteger(message.optime.high))
        )
          return "optime: integer|Long expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates an UpdateAssetReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.UpdateAssetReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.UpdateAssetReq} UpdateAssetReq
     */
    UpdateAssetReq.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.UpdateAssetReq) return object;
      var message = new $root.asset.UpdateAssetReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.op) {
        if (!Array.isArray(object.op)) throw TypeError(".asset.UpdateAssetReq.op: array expected");
        message.op = [];
        for (var i = 0; i < object.op.length; ++i) {
          if (typeof object.op[i] !== "object") throw TypeError(".asset.UpdateAssetReq.op: object expected");
          message.op[i] = $root.asset.Oparition.fromObject(object.op[i]);
        }
      }
      if (object.gameinfo != null) {
        if (typeof object.gameinfo !== "object") throw TypeError(".asset.UpdateAssetReq.gameinfo: object expected");
        message.gameinfo = $root.asset.GameInfo.fromObject(object.gameinfo);
      }
      if (object.ext != null) message.ext = String(object.ext);
      if (object.optime != null)
        if ($util.Long) (message.optime = $util.Long.fromValue(object.optime)).unsigned = true;
        else if (typeof object.optime === "string") message.optime = parseInt(object.optime, 10);
        else if (typeof object.optime === "number") message.optime = object.optime;
        else if (typeof object.optime === "object")
          message.optime = new $util.LongBits(object.optime.low >>> 0, object.optime.high >>> 0).toNumber(true);
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from an UpdateAssetReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.UpdateAssetReq
     * @static
     * @param {asset.UpdateAssetReq} message UpdateAssetReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateAssetReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.op = [];
      if (options.defaults) {
        object.uid = 0;
        object.gameinfo = null;
        object.ext = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.optime =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.optime = options.longs === String ? "0" : 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.op && message.op.length) {
        object.op = [];
        for (var j = 0; j < message.op.length; ++j)
          object.op[j] = $root.asset.Oparition.toObject(message.op[j], options);
      }
      if (message.gameinfo != null && message.hasOwnProperty("gameinfo"))
        object.gameinfo = $root.asset.GameInfo.toObject(message.gameinfo, options);
      if (message.ext != null && message.hasOwnProperty("ext")) object.ext = message.ext;
      if (message.optime != null && message.hasOwnProperty("optime"))
        if (typeof message.optime === "number")
          object.optime = options.longs === String ? String(message.optime) : message.optime;
        else
          object.optime =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.optime)
              : options.longs === Number
                ? new $util.LongBits(message.optime.low >>> 0, message.optime.high >>> 0).toNumber(true)
                : message.optime;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this UpdateAssetReq to JSON.
     * @function toJSON
     * @memberof asset.UpdateAssetReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateAssetReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateAssetReq
     * @function getTypeUrl
     * @memberof asset.UpdateAssetReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateAssetReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.UpdateAssetReq";
    };

    return UpdateAssetReq;
  })();

  asset.UpdateAssetResp = (function () {
    /**
     * Properties of an UpdateAssetResp.
     * @memberof asset
     * @interface IUpdateAssetResp
     * @property {number|null} [uid] UpdateAssetResp uid
     * @property {number|null} [result] UpdateAssetResp result
     * @property {Array.<asset.IFieldValue>|null} [fieldval] UpdateAssetResp fieldval
     * @property {Uint8Array|null} [trans] UpdateAssetResp trans
     */

    /**
     * Constructs a new UpdateAssetResp.
     * @memberof asset
     * @classdesc Represents an UpdateAssetResp.
     * @implements IUpdateAssetResp
     * @constructor
     * @param {asset.IUpdateAssetResp=} [properties] Properties to set
     */
    function UpdateAssetResp(properties) {
      this.fieldval = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateAssetResp uid.
     * @member {number} uid
     * @memberof asset.UpdateAssetResp
     * @instance
     */
    UpdateAssetResp.prototype.uid = 0;

    /**
     * UpdateAssetResp result.
     * @member {number} result
     * @memberof asset.UpdateAssetResp
     * @instance
     */
    UpdateAssetResp.prototype.result = 0;

    /**
     * UpdateAssetResp fieldval.
     * @member {Array.<asset.IFieldValue>} fieldval
     * @memberof asset.UpdateAssetResp
     * @instance
     */
    UpdateAssetResp.prototype.fieldval = $util.emptyArray;

    /**
     * UpdateAssetResp trans.
     * @member {Uint8Array} trans
     * @memberof asset.UpdateAssetResp
     * @instance
     */
    UpdateAssetResp.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new UpdateAssetResp instance using the specified properties.
     * @function create
     * @memberof asset.UpdateAssetResp
     * @static
     * @param {asset.IUpdateAssetResp=} [properties] Properties to set
     * @returns {asset.UpdateAssetResp} UpdateAssetResp instance
     */
    UpdateAssetResp.create = function create(properties) {
      return new UpdateAssetResp(properties);
    };

    /**
     * Encodes the specified UpdateAssetResp message. Does not implicitly {@link asset.UpdateAssetResp.verify|verify} messages.
     * @function encode
     * @memberof asset.UpdateAssetResp
     * @static
     * @param {asset.IUpdateAssetResp} message UpdateAssetResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAssetResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.fieldval != null && message.fieldval.length)
        for (var i = 0; i < message.fieldval.length; ++i)
          $root.asset.FieldValue.encode(message.fieldval[i], writer.uint32(/* id 3, wireType 2 =*/ 26).fork()).ldelim();
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified UpdateAssetResp message, length delimited. Does not implicitly {@link asset.UpdateAssetResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.UpdateAssetResp
     * @static
     * @param {asset.IUpdateAssetResp} message UpdateAssetResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateAssetResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateAssetResp message from the specified reader or buffer.
     * @function decode
     * @memberof asset.UpdateAssetResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.UpdateAssetResp} UpdateAssetResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAssetResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.UpdateAssetResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            if (!(message.fieldval && message.fieldval.length)) message.fieldval = [];
            message.fieldval.push($root.asset.FieldValue.decode(reader, reader.uint32()));
            break;
          }
          case 4: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an UpdateAssetResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.UpdateAssetResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.UpdateAssetResp} UpdateAssetResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateAssetResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateAssetResp message.
     * @function verify
     * @memberof asset.UpdateAssetResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateAssetResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.fieldval != null && message.hasOwnProperty("fieldval")) {
        if (!Array.isArray(message.fieldval)) return "fieldval: array expected";
        for (var i = 0; i < message.fieldval.length; ++i) {
          var error = $root.asset.FieldValue.verify(message.fieldval[i]);
          if (error) return "fieldval." + error;
        }
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates an UpdateAssetResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.UpdateAssetResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.UpdateAssetResp} UpdateAssetResp
     */
    UpdateAssetResp.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.UpdateAssetResp) return object;
      var message = new $root.asset.UpdateAssetResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.fieldval) {
        if (!Array.isArray(object.fieldval)) throw TypeError(".asset.UpdateAssetResp.fieldval: array expected");
        message.fieldval = [];
        for (var i = 0; i < object.fieldval.length; ++i) {
          if (typeof object.fieldval[i] !== "object")
            throw TypeError(".asset.UpdateAssetResp.fieldval: object expected");
          message.fieldval[i] = $root.asset.FieldValue.fromObject(object.fieldval[i]);
        }
      }
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from an UpdateAssetResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.UpdateAssetResp
     * @static
     * @param {asset.UpdateAssetResp} message UpdateAssetResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateAssetResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.fieldval = [];
      if (options.defaults) {
        object.uid = 0;
        object.result = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.fieldval && message.fieldval.length) {
        object.fieldval = [];
        for (var j = 0; j < message.fieldval.length; ++j)
          object.fieldval[j] = $root.asset.FieldValue.toObject(message.fieldval[j], options);
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this UpdateAssetResp to JSON.
     * @function toJSON
     * @memberof asset.UpdateAssetResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateAssetResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateAssetResp
     * @function getTypeUrl
     * @memberof asset.UpdateAssetResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateAssetResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.UpdateAssetResp";
    };

    return UpdateAssetResp;
  })();

  asset.AssetFlow = (function () {
    /**
     * Properties of an AssetFlow.
     * @memberof asset
     * @interface IAssetFlow
     * @property {number|null} [uid] AssetFlow uid
     * @property {asset.IOparition|null} [op] AssetFlow op
     * @property {asset.IGameInfo|null} [gameinfo] AssetFlow gameinfo
     * @property {string|null} [ext] AssetFlow ext
     * @property {number|Long|null} [optime] AssetFlow optime
     * @property {number|null} [result] AssetFlow result
     * @property {Array.<asset.IFieldValue>|null} [fieldval] AssetFlow fieldval
     */

    /**
     * Constructs a new AssetFlow.
     * @memberof asset
     * @classdesc Represents an AssetFlow.
     * @implements IAssetFlow
     * @constructor
     * @param {asset.IAssetFlow=} [properties] Properties to set
     */
    function AssetFlow(properties) {
      this.fieldval = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AssetFlow uid.
     * @member {number} uid
     * @memberof asset.AssetFlow
     * @instance
     */
    AssetFlow.prototype.uid = 0;

    /**
     * AssetFlow op.
     * @member {asset.IOparition|null|undefined} op
     * @memberof asset.AssetFlow
     * @instance
     */
    AssetFlow.prototype.op = null;

    /**
     * AssetFlow gameinfo.
     * @member {asset.IGameInfo|null|undefined} gameinfo
     * @memberof asset.AssetFlow
     * @instance
     */
    AssetFlow.prototype.gameinfo = null;

    /**
     * AssetFlow ext.
     * @member {string} ext
     * @memberof asset.AssetFlow
     * @instance
     */
    AssetFlow.prototype.ext = "";

    /**
     * AssetFlow optime.
     * @member {number|Long} optime
     * @memberof asset.AssetFlow
     * @instance
     */
    AssetFlow.prototype.optime = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * AssetFlow result.
     * @member {number} result
     * @memberof asset.AssetFlow
     * @instance
     */
    AssetFlow.prototype.result = 0;

    /**
     * AssetFlow fieldval.
     * @member {Array.<asset.IFieldValue>} fieldval
     * @memberof asset.AssetFlow
     * @instance
     */
    AssetFlow.prototype.fieldval = $util.emptyArray;

    /**
     * Creates a new AssetFlow instance using the specified properties.
     * @function create
     * @memberof asset.AssetFlow
     * @static
     * @param {asset.IAssetFlow=} [properties] Properties to set
     * @returns {asset.AssetFlow} AssetFlow instance
     */
    AssetFlow.create = function create(properties) {
      return new AssetFlow(properties);
    };

    /**
     * Encodes the specified AssetFlow message. Does not implicitly {@link asset.AssetFlow.verify|verify} messages.
     * @function encode
     * @memberof asset.AssetFlow
     * @static
     * @param {asset.IAssetFlow} message AssetFlow message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetFlow.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.op != null && Object.hasOwnProperty.call(message, "op"))
        $root.asset.Oparition.encode(message.op, writer.uint32(/* id 2, wireType 2 =*/ 18).fork()).ldelim();
      if (message.gameinfo != null && Object.hasOwnProperty.call(message, "gameinfo"))
        $root.asset.GameInfo.encode(message.gameinfo, writer.uint32(/* id 3, wireType 2 =*/ 26).fork()).ldelim();
      if (message.ext != null && Object.hasOwnProperty.call(message, "ext"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.ext);
      if (message.optime != null && Object.hasOwnProperty.call(message, "optime"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).uint64(message.optime);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.result);
      if (message.fieldval != null && message.fieldval.length)
        for (var i = 0; i < message.fieldval.length; ++i)
          $root.asset.FieldValue.encode(message.fieldval[i], writer.uint32(/* id 7, wireType 2 =*/ 58).fork()).ldelim();
      return writer;
    };

    /**
     * Encodes the specified AssetFlow message, length delimited. Does not implicitly {@link asset.AssetFlow.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.AssetFlow
     * @static
     * @param {asset.IAssetFlow} message AssetFlow message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetFlow.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AssetFlow message from the specified reader or buffer.
     * @function decode
     * @memberof asset.AssetFlow
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.AssetFlow} AssetFlow
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetFlow.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.AssetFlow();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.op = $root.asset.Oparition.decode(reader, reader.uint32());
            break;
          }
          case 3: {
            message.gameinfo = $root.asset.GameInfo.decode(reader, reader.uint32());
            break;
          }
          case 4: {
            message.ext = reader.string();
            break;
          }
          case 5: {
            message.optime = reader.uint64();
            break;
          }
          case 6: {
            message.result = reader.int32();
            break;
          }
          case 7: {
            if (!(message.fieldval && message.fieldval.length)) message.fieldval = [];
            message.fieldval.push($root.asset.FieldValue.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AssetFlow message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.AssetFlow
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.AssetFlow} AssetFlow
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetFlow.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AssetFlow message.
     * @function verify
     * @memberof asset.AssetFlow
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AssetFlow.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.op != null && message.hasOwnProperty("op")) {
        var error = $root.asset.Oparition.verify(message.op);
        if (error) return "op." + error;
      }
      if (message.gameinfo != null && message.hasOwnProperty("gameinfo")) {
        var error = $root.asset.GameInfo.verify(message.gameinfo);
        if (error) return "gameinfo." + error;
      }
      if (message.ext != null && message.hasOwnProperty("ext"))
        if (!$util.isString(message.ext)) return "ext: string expected";
      if (message.optime != null && message.hasOwnProperty("optime"))
        if (
          !$util.isInteger(message.optime) &&
          !(message.optime && $util.isInteger(message.optime.low) && $util.isInteger(message.optime.high))
        )
          return "optime: integer|Long expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.fieldval != null && message.hasOwnProperty("fieldval")) {
        if (!Array.isArray(message.fieldval)) return "fieldval: array expected";
        for (var i = 0; i < message.fieldval.length; ++i) {
          var error = $root.asset.FieldValue.verify(message.fieldval[i]);
          if (error) return "fieldval." + error;
        }
      }
      return null;
    };

    /**
     * Creates an AssetFlow message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.AssetFlow
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.AssetFlow} AssetFlow
     */
    AssetFlow.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.AssetFlow) return object;
      var message = new $root.asset.AssetFlow();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.op != null) {
        if (typeof object.op !== "object") throw TypeError(".asset.AssetFlow.op: object expected");
        message.op = $root.asset.Oparition.fromObject(object.op);
      }
      if (object.gameinfo != null) {
        if (typeof object.gameinfo !== "object") throw TypeError(".asset.AssetFlow.gameinfo: object expected");
        message.gameinfo = $root.asset.GameInfo.fromObject(object.gameinfo);
      }
      if (object.ext != null) message.ext = String(object.ext);
      if (object.optime != null)
        if ($util.Long) (message.optime = $util.Long.fromValue(object.optime)).unsigned = true;
        else if (typeof object.optime === "string") message.optime = parseInt(object.optime, 10);
        else if (typeof object.optime === "number") message.optime = object.optime;
        else if (typeof object.optime === "object")
          message.optime = new $util.LongBits(object.optime.low >>> 0, object.optime.high >>> 0).toNumber(true);
      if (object.result != null) message.result = object.result | 0;
      if (object.fieldval) {
        if (!Array.isArray(object.fieldval)) throw TypeError(".asset.AssetFlow.fieldval: array expected");
        message.fieldval = [];
        for (var i = 0; i < object.fieldval.length; ++i) {
          if (typeof object.fieldval[i] !== "object") throw TypeError(".asset.AssetFlow.fieldval: object expected");
          message.fieldval[i] = $root.asset.FieldValue.fromObject(object.fieldval[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from an AssetFlow message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.AssetFlow
     * @static
     * @param {asset.AssetFlow} message AssetFlow
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AssetFlow.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.fieldval = [];
      if (options.defaults) {
        object.uid = 0;
        object.op = null;
        object.gameinfo = null;
        object.ext = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.optime =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.optime = options.longs === String ? "0" : 0;
        object.result = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.op != null && message.hasOwnProperty("op"))
        object.op = $root.asset.Oparition.toObject(message.op, options);
      if (message.gameinfo != null && message.hasOwnProperty("gameinfo"))
        object.gameinfo = $root.asset.GameInfo.toObject(message.gameinfo, options);
      if (message.ext != null && message.hasOwnProperty("ext")) object.ext = message.ext;
      if (message.optime != null && message.hasOwnProperty("optime"))
        if (typeof message.optime === "number")
          object.optime = options.longs === String ? String(message.optime) : message.optime;
        else
          object.optime =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.optime)
              : options.longs === Number
                ? new $util.LongBits(message.optime.low >>> 0, message.optime.high >>> 0).toNumber(true)
                : message.optime;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.fieldval && message.fieldval.length) {
        object.fieldval = [];
        for (var j = 0; j < message.fieldval.length; ++j)
          object.fieldval[j] = $root.asset.FieldValue.toObject(message.fieldval[j], options);
      }
      return object;
    };

    /**
     * Converts this AssetFlow to JSON.
     * @function toJSON
     * @memberof asset.AssetFlow
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AssetFlow.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AssetFlow
     * @function getTypeUrl
     * @memberof asset.AssetFlow
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AssetFlow.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.AssetFlow";
    };

    return AssetFlow;
  })();

  asset.AssetFlowPush = (function () {
    /**
     * Properties of an AssetFlowPush.
     * @memberof asset
     * @interface IAssetFlowPush
     * @property {Array.<asset.IAssetFlow>|null} [flow] AssetFlowPush flow
     */

    /**
     * Constructs a new AssetFlowPush.
     * @memberof asset
     * @classdesc Represents an AssetFlowPush.
     * @implements IAssetFlowPush
     * @constructor
     * @param {asset.IAssetFlowPush=} [properties] Properties to set
     */
    function AssetFlowPush(properties) {
      this.flow = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AssetFlowPush flow.
     * @member {Array.<asset.IAssetFlow>} flow
     * @memberof asset.AssetFlowPush
     * @instance
     */
    AssetFlowPush.prototype.flow = $util.emptyArray;

    /**
     * Creates a new AssetFlowPush instance using the specified properties.
     * @function create
     * @memberof asset.AssetFlowPush
     * @static
     * @param {asset.IAssetFlowPush=} [properties] Properties to set
     * @returns {asset.AssetFlowPush} AssetFlowPush instance
     */
    AssetFlowPush.create = function create(properties) {
      return new AssetFlowPush(properties);
    };

    /**
     * Encodes the specified AssetFlowPush message. Does not implicitly {@link asset.AssetFlowPush.verify|verify} messages.
     * @function encode
     * @memberof asset.AssetFlowPush
     * @static
     * @param {asset.IAssetFlowPush} message AssetFlowPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetFlowPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.flow != null && message.flow.length)
        for (var i = 0; i < message.flow.length; ++i)
          $root.asset.AssetFlow.encode(message.flow[i], writer.uint32(/* id 1, wireType 2 =*/ 10).fork()).ldelim();
      return writer;
    };

    /**
     * Encodes the specified AssetFlowPush message, length delimited. Does not implicitly {@link asset.AssetFlowPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.AssetFlowPush
     * @static
     * @param {asset.IAssetFlowPush} message AssetFlowPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetFlowPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AssetFlowPush message from the specified reader or buffer.
     * @function decode
     * @memberof asset.AssetFlowPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.AssetFlowPush} AssetFlowPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetFlowPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.AssetFlowPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.flow && message.flow.length)) message.flow = [];
            message.flow.push($root.asset.AssetFlow.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AssetFlowPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.AssetFlowPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.AssetFlowPush} AssetFlowPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetFlowPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AssetFlowPush message.
     * @function verify
     * @memberof asset.AssetFlowPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AssetFlowPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.flow != null && message.hasOwnProperty("flow")) {
        if (!Array.isArray(message.flow)) return "flow: array expected";
        for (var i = 0; i < message.flow.length; ++i) {
          var error = $root.asset.AssetFlow.verify(message.flow[i]);
          if (error) return "flow." + error;
        }
      }
      return null;
    };

    /**
     * Creates an AssetFlowPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.AssetFlowPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.AssetFlowPush} AssetFlowPush
     */
    AssetFlowPush.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.AssetFlowPush) return object;
      var message = new $root.asset.AssetFlowPush();
      if (object.flow) {
        if (!Array.isArray(object.flow)) throw TypeError(".asset.AssetFlowPush.flow: array expected");
        message.flow = [];
        for (var i = 0; i < object.flow.length; ++i) {
          if (typeof object.flow[i] !== "object") throw TypeError(".asset.AssetFlowPush.flow: object expected");
          message.flow[i] = $root.asset.AssetFlow.fromObject(object.flow[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from an AssetFlowPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.AssetFlowPush
     * @static
     * @param {asset.AssetFlowPush} message AssetFlowPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AssetFlowPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.flow = [];
      if (message.flow && message.flow.length) {
        object.flow = [];
        for (var j = 0; j < message.flow.length; ++j)
          object.flow[j] = $root.asset.AssetFlow.toObject(message.flow[j], options);
      }
      return object;
    };

    /**
     * Converts this AssetFlowPush to JSON.
     * @function toJSON
     * @memberof asset.AssetFlowPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AssetFlowPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AssetFlowPush
     * @function getTypeUrl
     * @memberof asset.AssetFlowPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AssetFlowPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.AssetFlowPush";
    };

    return AssetFlowPush;
  })();

  asset.AssetAddAccountReq = (function () {
    /**
     * Properties of an AssetAddAccountReq.
     * @memberof asset
     * @interface IAssetAddAccountReq
     * @property {number|null} [uid] AssetAddAccountReq uid
     * @property {Array.<asset.IOparition>|null} [op] AssetAddAccountReq op
     * @property {Uint8Array|null} [trans] AssetAddAccountReq trans
     */

    /**
     * Constructs a new AssetAddAccountReq.
     * @memberof asset
     * @classdesc Represents an AssetAddAccountReq.
     * @implements IAssetAddAccountReq
     * @constructor
     * @param {asset.IAssetAddAccountReq=} [properties] Properties to set
     */
    function AssetAddAccountReq(properties) {
      this.op = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AssetAddAccountReq uid.
     * @member {number} uid
     * @memberof asset.AssetAddAccountReq
     * @instance
     */
    AssetAddAccountReq.prototype.uid = 0;

    /**
     * AssetAddAccountReq op.
     * @member {Array.<asset.IOparition>} op
     * @memberof asset.AssetAddAccountReq
     * @instance
     */
    AssetAddAccountReq.prototype.op = $util.emptyArray;

    /**
     * AssetAddAccountReq trans.
     * @member {Uint8Array} trans
     * @memberof asset.AssetAddAccountReq
     * @instance
     */
    AssetAddAccountReq.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new AssetAddAccountReq instance using the specified properties.
     * @function create
     * @memberof asset.AssetAddAccountReq
     * @static
     * @param {asset.IAssetAddAccountReq=} [properties] Properties to set
     * @returns {asset.AssetAddAccountReq} AssetAddAccountReq instance
     */
    AssetAddAccountReq.create = function create(properties) {
      return new AssetAddAccountReq(properties);
    };

    /**
     * Encodes the specified AssetAddAccountReq message. Does not implicitly {@link asset.AssetAddAccountReq.verify|verify} messages.
     * @function encode
     * @memberof asset.AssetAddAccountReq
     * @static
     * @param {asset.IAssetAddAccountReq} message AssetAddAccountReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetAddAccountReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.op != null && message.op.length)
        for (var i = 0; i < message.op.length; ++i)
          $root.asset.Oparition.encode(message.op[i], writer.uint32(/* id 2, wireType 2 =*/ 18).fork()).ldelim();
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified AssetAddAccountReq message, length delimited. Does not implicitly {@link asset.AssetAddAccountReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.AssetAddAccountReq
     * @static
     * @param {asset.IAssetAddAccountReq} message AssetAddAccountReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetAddAccountReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AssetAddAccountReq message from the specified reader or buffer.
     * @function decode
     * @memberof asset.AssetAddAccountReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.AssetAddAccountReq} AssetAddAccountReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetAddAccountReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.AssetAddAccountReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            if (!(message.op && message.op.length)) message.op = [];
            message.op.push($root.asset.Oparition.decode(reader, reader.uint32()));
            break;
          }
          case 3: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AssetAddAccountReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.AssetAddAccountReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.AssetAddAccountReq} AssetAddAccountReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetAddAccountReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AssetAddAccountReq message.
     * @function verify
     * @memberof asset.AssetAddAccountReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AssetAddAccountReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.op != null && message.hasOwnProperty("op")) {
        if (!Array.isArray(message.op)) return "op: array expected";
        for (var i = 0; i < message.op.length; ++i) {
          var error = $root.asset.Oparition.verify(message.op[i]);
          if (error) return "op." + error;
        }
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates an AssetAddAccountReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.AssetAddAccountReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.AssetAddAccountReq} AssetAddAccountReq
     */
    AssetAddAccountReq.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.AssetAddAccountReq) return object;
      var message = new $root.asset.AssetAddAccountReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.op) {
        if (!Array.isArray(object.op)) throw TypeError(".asset.AssetAddAccountReq.op: array expected");
        message.op = [];
        for (var i = 0; i < object.op.length; ++i) {
          if (typeof object.op[i] !== "object") throw TypeError(".asset.AssetAddAccountReq.op: object expected");
          message.op[i] = $root.asset.Oparition.fromObject(object.op[i]);
        }
      }
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from an AssetAddAccountReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.AssetAddAccountReq
     * @static
     * @param {asset.AssetAddAccountReq} message AssetAddAccountReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AssetAddAccountReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.op = [];
      if (options.defaults) {
        object.uid = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.op && message.op.length) {
        object.op = [];
        for (var j = 0; j < message.op.length; ++j)
          object.op[j] = $root.asset.Oparition.toObject(message.op[j], options);
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this AssetAddAccountReq to JSON.
     * @function toJSON
     * @memberof asset.AssetAddAccountReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AssetAddAccountReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AssetAddAccountReq
     * @function getTypeUrl
     * @memberof asset.AssetAddAccountReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AssetAddAccountReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.AssetAddAccountReq";
    };

    return AssetAddAccountReq;
  })();

  asset.AssetAddAccountResp = (function () {
    /**
     * Properties of an AssetAddAccountResp.
     * @memberof asset
     * @interface IAssetAddAccountResp
     * @property {number|null} [uid] AssetAddAccountResp uid
     * @property {number|null} [result] AssetAddAccountResp result
     * @property {Array.<asset.IFieldValue>|null} [fieldval] AssetAddAccountResp fieldval
     * @property {Uint8Array|null} [trans] AssetAddAccountResp trans
     */

    /**
     * Constructs a new AssetAddAccountResp.
     * @memberof asset
     * @classdesc Represents an AssetAddAccountResp.
     * @implements IAssetAddAccountResp
     * @constructor
     * @param {asset.IAssetAddAccountResp=} [properties] Properties to set
     */
    function AssetAddAccountResp(properties) {
      this.fieldval = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AssetAddAccountResp uid.
     * @member {number} uid
     * @memberof asset.AssetAddAccountResp
     * @instance
     */
    AssetAddAccountResp.prototype.uid = 0;

    /**
     * AssetAddAccountResp result.
     * @member {number} result
     * @memberof asset.AssetAddAccountResp
     * @instance
     */
    AssetAddAccountResp.prototype.result = 0;

    /**
     * AssetAddAccountResp fieldval.
     * @member {Array.<asset.IFieldValue>} fieldval
     * @memberof asset.AssetAddAccountResp
     * @instance
     */
    AssetAddAccountResp.prototype.fieldval = $util.emptyArray;

    /**
     * AssetAddAccountResp trans.
     * @member {Uint8Array} trans
     * @memberof asset.AssetAddAccountResp
     * @instance
     */
    AssetAddAccountResp.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new AssetAddAccountResp instance using the specified properties.
     * @function create
     * @memberof asset.AssetAddAccountResp
     * @static
     * @param {asset.IAssetAddAccountResp=} [properties] Properties to set
     * @returns {asset.AssetAddAccountResp} AssetAddAccountResp instance
     */
    AssetAddAccountResp.create = function create(properties) {
      return new AssetAddAccountResp(properties);
    };

    /**
     * Encodes the specified AssetAddAccountResp message. Does not implicitly {@link asset.AssetAddAccountResp.verify|verify} messages.
     * @function encode
     * @memberof asset.AssetAddAccountResp
     * @static
     * @param {asset.IAssetAddAccountResp} message AssetAddAccountResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetAddAccountResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.fieldval != null && message.fieldval.length)
        for (var i = 0; i < message.fieldval.length; ++i)
          $root.asset.FieldValue.encode(message.fieldval[i], writer.uint32(/* id 3, wireType 2 =*/ 26).fork()).ldelim();
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified AssetAddAccountResp message, length delimited. Does not implicitly {@link asset.AssetAddAccountResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof asset.AssetAddAccountResp
     * @static
     * @param {asset.IAssetAddAccountResp} message AssetAddAccountResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AssetAddAccountResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AssetAddAccountResp message from the specified reader or buffer.
     * @function decode
     * @memberof asset.AssetAddAccountResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {asset.AssetAddAccountResp} AssetAddAccountResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetAddAccountResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.asset.AssetAddAccountResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            if (!(message.fieldval && message.fieldval.length)) message.fieldval = [];
            message.fieldval.push($root.asset.FieldValue.decode(reader, reader.uint32()));
            break;
          }
          case 4: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AssetAddAccountResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof asset.AssetAddAccountResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {asset.AssetAddAccountResp} AssetAddAccountResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AssetAddAccountResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AssetAddAccountResp message.
     * @function verify
     * @memberof asset.AssetAddAccountResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AssetAddAccountResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.fieldval != null && message.hasOwnProperty("fieldval")) {
        if (!Array.isArray(message.fieldval)) return "fieldval: array expected";
        for (var i = 0; i < message.fieldval.length; ++i) {
          var error = $root.asset.FieldValue.verify(message.fieldval[i]);
          if (error) return "fieldval." + error;
        }
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates an AssetAddAccountResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof asset.AssetAddAccountResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {asset.AssetAddAccountResp} AssetAddAccountResp
     */
    AssetAddAccountResp.fromObject = function fromObject(object) {
      if (object instanceof $root.asset.AssetAddAccountResp) return object;
      var message = new $root.asset.AssetAddAccountResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.fieldval) {
        if (!Array.isArray(object.fieldval)) throw TypeError(".asset.AssetAddAccountResp.fieldval: array expected");
        message.fieldval = [];
        for (var i = 0; i < object.fieldval.length; ++i) {
          if (typeof object.fieldval[i] !== "object")
            throw TypeError(".asset.AssetAddAccountResp.fieldval: object expected");
          message.fieldval[i] = $root.asset.FieldValue.fromObject(object.fieldval[i]);
        }
      }
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from an AssetAddAccountResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof asset.AssetAddAccountResp
     * @static
     * @param {asset.AssetAddAccountResp} message AssetAddAccountResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AssetAddAccountResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.fieldval = [];
      if (options.defaults) {
        object.uid = 0;
        object.result = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.fieldval && message.fieldval.length) {
        object.fieldval = [];
        for (var j = 0; j < message.fieldval.length; ++j)
          object.fieldval[j] = $root.asset.FieldValue.toObject(message.fieldval[j], options);
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this AssetAddAccountResp to JSON.
     * @function toJSON
     * @memberof asset.AssetAddAccountResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AssetAddAccountResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AssetAddAccountResp
     * @function getTypeUrl
     * @memberof asset.AssetAddAccountResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AssetAddAccountResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/asset.AssetAddAccountResp";
    };

    return AssetAddAccountResp;
  })();

  return asset;
})();

$root.bridge_proxy_proto = (function () {
  /**
   * Namespace bridge_proxy_proto.
   * @exports bridge_proxy_proto
   * @namespace
   */
  var bridge_proxy_proto = {};

  /**
   * RBRIDGE_PROXY_SUB_MSG_ID enum.
   * @name bridge_proxy_proto.RBRIDGE_PROXY_SUB_MSG_ID
   * @enum {number}
   * @property {number} BRIDGE_PROXY_MSG_ID_NULL=0 BRIDGE_PROXY_MSG_ID_NULL value
   * @property {number} BRIDGE_PROXY_MSG_ID_MIN=1500 BRIDGE_PROXY_MSG_ID_MIN value
   * @property {number} BRIDGE_PROXY_LOGIN_REQ=1501 BRIDGE_PROXY_LOGIN_REQ value
   * @property {number} BRIDGE_PROXY_LOGIN_RESP=1502 BRIDGE_PROXY_LOGIN_RESP value
   * @property {number} BRIDGE_PROXY_QUERY_GAME_RECORD_REQ=1503 BRIDGE_PROXY_QUERY_GAME_RECORD_REQ value
   * @property {number} BRIDGE_PROXY_QUERY_GAME_RECORD_RESP=1504 BRIDGE_PROXY_QUERY_GAME_RECORD_RESP value
   * @property {number} BRIDGE_PROXY_QUERY_GAME_STATUS_LIST_REQ=1505 BRIDGE_PROXY_QUERY_GAME_STATUS_LIST_REQ value
   * @property {number} BRIDGE_PROXY_QUERY_GAME_STATUS_LIST_RESP=1506 BRIDGE_PROXY_QUERY_GAME_STATUS_LIST_RESP value
   * @property {number} BRIDGE_PROXY_KICKOUT_PUSH=1510 BRIDGE_PROXY_KICKOUT_PUSH value
   * @property {number} BRIDGE_PROXY_MSG_ID_MAX=1600 BRIDGE_PROXY_MSG_ID_MAX value
   */
  bridge_proxy_proto.RBRIDGE_PROXY_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "BRIDGE_PROXY_MSG_ID_NULL")] = 0;
    values[(valuesById[1500] = "BRIDGE_PROXY_MSG_ID_MIN")] = 1500;
    values[(valuesById[1501] = "BRIDGE_PROXY_LOGIN_REQ")] = 1501;
    values[(valuesById[1502] = "BRIDGE_PROXY_LOGIN_RESP")] = 1502;
    values[(valuesById[1503] = "BRIDGE_PROXY_QUERY_GAME_RECORD_REQ")] = 1503;
    values[(valuesById[1504] = "BRIDGE_PROXY_QUERY_GAME_RECORD_RESP")] = 1504;
    values[(valuesById[1505] = "BRIDGE_PROXY_QUERY_GAME_STATUS_LIST_REQ")] = 1505;
    values[(valuesById[1506] = "BRIDGE_PROXY_QUERY_GAME_STATUS_LIST_RESP")] = 1506;
    values[(valuesById[1510] = "BRIDGE_PROXY_KICKOUT_PUSH")] = 1510;
    values[(valuesById[1600] = "BRIDGE_PROXY_MSG_ID_MAX")] = 1600;
    return values;
  })();

  bridge_proxy_proto.LoginReq = (function () {
    /**
     * Properties of a LoginReq.
     * @memberof bridge_proxy_proto
     * @interface ILoginReq
     * @property {number|null} [uid] LoginReq uid
     * @property {string|null} [game_uid] LoginReq game_uid
     * @property {string|null} [member_ip] LoginReq member_ip
     * @property {string|null} [channel] LoginReq channel
     */

    /**
     * Constructs a new LoginReq.
     * @memberof bridge_proxy_proto
     * @classdesc Represents a LoginReq.
     * @implements ILoginReq
     * @constructor
     * @param {bridge_proxy_proto.ILoginReq=} [properties] Properties to set
     */
    function LoginReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginReq uid.
     * @member {number} uid
     * @memberof bridge_proxy_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.uid = 0;

    /**
     * LoginReq game_uid.
     * @member {string} game_uid
     * @memberof bridge_proxy_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.game_uid = "";

    /**
     * LoginReq member_ip.
     * @member {string} member_ip
     * @memberof bridge_proxy_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.member_ip = "";

    /**
     * LoginReq channel.
     * @member {string} channel
     * @memberof bridge_proxy_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.channel = "";

    /**
     * Creates a new LoginReq instance using the specified properties.
     * @function create
     * @memberof bridge_proxy_proto.LoginReq
     * @static
     * @param {bridge_proxy_proto.ILoginReq=} [properties] Properties to set
     * @returns {bridge_proxy_proto.LoginReq} LoginReq instance
     */
    LoginReq.create = function create(properties) {
      return new LoginReq(properties);
    };

    /**
     * Encodes the specified LoginReq message. Does not implicitly {@link bridge_proxy_proto.LoginReq.verify|verify} messages.
     * @function encode
     * @memberof bridge_proxy_proto.LoginReq
     * @static
     * @param {bridge_proxy_proto.ILoginReq} message LoginReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.game_uid != null && Object.hasOwnProperty.call(message, "game_uid"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.game_uid);
      if (message.member_ip != null && Object.hasOwnProperty.call(message, "member_ip"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.member_ip);
      if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.channel);
      return writer;
    };

    /**
     * Encodes the specified LoginReq message, length delimited. Does not implicitly {@link bridge_proxy_proto.LoginReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof bridge_proxy_proto.LoginReq
     * @static
     * @param {bridge_proxy_proto.ILoginReq} message LoginReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LoginReq message from the specified reader or buffer.
     * @function decode
     * @memberof bridge_proxy_proto.LoginReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {bridge_proxy_proto.LoginReq} LoginReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.bridge_proxy_proto.LoginReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.game_uid = reader.string();
            break;
          }
          case 3: {
            message.member_ip = reader.string();
            break;
          }
          case 4: {
            message.channel = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LoginReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof bridge_proxy_proto.LoginReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {bridge_proxy_proto.LoginReq} LoginReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LoginReq message.
     * @function verify
     * @memberof bridge_proxy_proto.LoginReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.game_uid != null && message.hasOwnProperty("game_uid"))
        if (!$util.isString(message.game_uid)) return "game_uid: string expected";
      if (message.member_ip != null && message.hasOwnProperty("member_ip"))
        if (!$util.isString(message.member_ip)) return "member_ip: string expected";
      if (message.channel != null && message.hasOwnProperty("channel"))
        if (!$util.isString(message.channel)) return "channel: string expected";
      return null;
    };

    /**
     * Creates a LoginReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof bridge_proxy_proto.LoginReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {bridge_proxy_proto.LoginReq} LoginReq
     */
    LoginReq.fromObject = function fromObject(object) {
      if (object instanceof $root.bridge_proxy_proto.LoginReq) return object;
      var message = new $root.bridge_proxy_proto.LoginReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.game_uid != null) message.game_uid = String(object.game_uid);
      if (object.member_ip != null) message.member_ip = String(object.member_ip);
      if (object.channel != null) message.channel = String(object.channel);
      return message;
    };

    /**
     * Creates a plain object from a LoginReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof bridge_proxy_proto.LoginReq
     * @static
     * @param {bridge_proxy_proto.LoginReq} message LoginReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.game_uid = "";
        object.member_ip = "";
        object.channel = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.game_uid != null && message.hasOwnProperty("game_uid")) object.game_uid = message.game_uid;
      if (message.member_ip != null && message.hasOwnProperty("member_ip")) object.member_ip = message.member_ip;
      if (message.channel != null && message.hasOwnProperty("channel")) object.channel = message.channel;
      return object;
    };

    /**
     * Converts this LoginReq to JSON.
     * @function toJSON
     * @memberof bridge_proxy_proto.LoginReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LoginReq
     * @function getTypeUrl
     * @memberof bridge_proxy_proto.LoginReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LoginReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/bridge_proxy_proto.LoginReq";
    };

    return LoginReq;
  })();

  bridge_proxy_proto.LoginResp = (function () {
    /**
     * Properties of a LoginResp.
     * @memberof bridge_proxy_proto
     * @interface ILoginResp
     * @property {string|null} [game_launch_url] LoginResp game_launch_url
     */

    /**
     * Constructs a new LoginResp.
     * @memberof bridge_proxy_proto
     * @classdesc Represents a LoginResp.
     * @implements ILoginResp
     * @constructor
     * @param {bridge_proxy_proto.ILoginResp=} [properties] Properties to set
     */
    function LoginResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginResp game_launch_url.
     * @member {string} game_launch_url
     * @memberof bridge_proxy_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.game_launch_url = "";

    /**
     * Creates a new LoginResp instance using the specified properties.
     * @function create
     * @memberof bridge_proxy_proto.LoginResp
     * @static
     * @param {bridge_proxy_proto.ILoginResp=} [properties] Properties to set
     * @returns {bridge_proxy_proto.LoginResp} LoginResp instance
     */
    LoginResp.create = function create(properties) {
      return new LoginResp(properties);
    };

    /**
     * Encodes the specified LoginResp message. Does not implicitly {@link bridge_proxy_proto.LoginResp.verify|verify} messages.
     * @function encode
     * @memberof bridge_proxy_proto.LoginResp
     * @static
     * @param {bridge_proxy_proto.ILoginResp} message LoginResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_launch_url != null && Object.hasOwnProperty.call(message, "game_launch_url"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.game_launch_url);
      return writer;
    };

    /**
     * Encodes the specified LoginResp message, length delimited. Does not implicitly {@link bridge_proxy_proto.LoginResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof bridge_proxy_proto.LoginResp
     * @static
     * @param {bridge_proxy_proto.ILoginResp} message LoginResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LoginResp message from the specified reader or buffer.
     * @function decode
     * @memberof bridge_proxy_proto.LoginResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {bridge_proxy_proto.LoginResp} LoginResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.bridge_proxy_proto.LoginResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.game_launch_url = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LoginResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof bridge_proxy_proto.LoginResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {bridge_proxy_proto.LoginResp} LoginResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LoginResp message.
     * @function verify
     * @memberof bridge_proxy_proto.LoginResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_launch_url != null && message.hasOwnProperty("game_launch_url"))
        if (!$util.isString(message.game_launch_url)) return "game_launch_url: string expected";
      return null;
    };

    /**
     * Creates a LoginResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof bridge_proxy_proto.LoginResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {bridge_proxy_proto.LoginResp} LoginResp
     */
    LoginResp.fromObject = function fromObject(object) {
      if (object instanceof $root.bridge_proxy_proto.LoginResp) return object;
      var message = new $root.bridge_proxy_proto.LoginResp();
      if (object.game_launch_url != null) message.game_launch_url = String(object.game_launch_url);
      return message;
    };

    /**
     * Creates a plain object from a LoginResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof bridge_proxy_proto.LoginResp
     * @static
     * @param {bridge_proxy_proto.LoginResp} message LoginResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.game_launch_url = "";
      if (message.game_launch_url != null && message.hasOwnProperty("game_launch_url"))
        object.game_launch_url = message.game_launch_url;
      return object;
    };

    /**
     * Converts this LoginResp to JSON.
     * @function toJSON
     * @memberof bridge_proxy_proto.LoginResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LoginResp
     * @function getTypeUrl
     * @memberof bridge_proxy_proto.LoginResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LoginResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/bridge_proxy_proto.LoginResp";
    };

    return LoginResp;
  })();

  bridge_proxy_proto.QueryGameRecordReq = (function () {
    /**
     * Properties of a QueryGameRecordReq.
     * @memberof bridge_proxy_proto
     * @interface IQueryGameRecordReq
     * @property {number|null} [uid] QueryGameRecordReq uid
     * @property {string|null} [game_uid] QueryGameRecordReq game_uid
     * @property {string|null} [game_cycle_uid] QueryGameRecordReq game_cycle_uid
     * @property {number|null} [order_final_datetime] QueryGameRecordReq order_final_datetime
     */

    /**
     * Constructs a new QueryGameRecordReq.
     * @memberof bridge_proxy_proto
     * @classdesc Represents a QueryGameRecordReq.
     * @implements IQueryGameRecordReq
     * @constructor
     * @param {bridge_proxy_proto.IQueryGameRecordReq=} [properties] Properties to set
     */
    function QueryGameRecordReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueryGameRecordReq uid.
     * @member {number} uid
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @instance
     */
    QueryGameRecordReq.prototype.uid = 0;

    /**
     * QueryGameRecordReq game_uid.
     * @member {string} game_uid
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @instance
     */
    QueryGameRecordReq.prototype.game_uid = "";

    /**
     * QueryGameRecordReq game_cycle_uid.
     * @member {string} game_cycle_uid
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @instance
     */
    QueryGameRecordReq.prototype.game_cycle_uid = "";

    /**
     * QueryGameRecordReq order_final_datetime.
     * @member {number} order_final_datetime
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @instance
     */
    QueryGameRecordReq.prototype.order_final_datetime = 0;

    /**
     * Creates a new QueryGameRecordReq instance using the specified properties.
     * @function create
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @static
     * @param {bridge_proxy_proto.IQueryGameRecordReq=} [properties] Properties to set
     * @returns {bridge_proxy_proto.QueryGameRecordReq} QueryGameRecordReq instance
     */
    QueryGameRecordReq.create = function create(properties) {
      return new QueryGameRecordReq(properties);
    };

    /**
     * Encodes the specified QueryGameRecordReq message. Does not implicitly {@link bridge_proxy_proto.QueryGameRecordReq.verify|verify} messages.
     * @function encode
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @static
     * @param {bridge_proxy_proto.IQueryGameRecordReq} message QueryGameRecordReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueryGameRecordReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.game_uid != null && Object.hasOwnProperty.call(message, "game_uid"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.game_uid);
      if (message.game_cycle_uid != null && Object.hasOwnProperty.call(message, "game_cycle_uid"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.game_cycle_uid);
      if (message.order_final_datetime != null && Object.hasOwnProperty.call(message, "order_final_datetime"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.order_final_datetime);
      return writer;
    };

    /**
     * Encodes the specified QueryGameRecordReq message, length delimited. Does not implicitly {@link bridge_proxy_proto.QueryGameRecordReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @static
     * @param {bridge_proxy_proto.IQueryGameRecordReq} message QueryGameRecordReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueryGameRecordReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a QueryGameRecordReq message from the specified reader or buffer.
     * @function decode
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {bridge_proxy_proto.QueryGameRecordReq} QueryGameRecordReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueryGameRecordReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.bridge_proxy_proto.QueryGameRecordReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.game_uid = reader.string();
            break;
          }
          case 3: {
            message.game_cycle_uid = reader.string();
            break;
          }
          case 4: {
            message.order_final_datetime = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a QueryGameRecordReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {bridge_proxy_proto.QueryGameRecordReq} QueryGameRecordReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueryGameRecordReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a QueryGameRecordReq message.
     * @function verify
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    QueryGameRecordReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.game_uid != null && message.hasOwnProperty("game_uid"))
        if (!$util.isString(message.game_uid)) return "game_uid: string expected";
      if (message.game_cycle_uid != null && message.hasOwnProperty("game_cycle_uid"))
        if (!$util.isString(message.game_cycle_uid)) return "game_cycle_uid: string expected";
      if (message.order_final_datetime != null && message.hasOwnProperty("order_final_datetime"))
        if (!$util.isInteger(message.order_final_datetime)) return "order_final_datetime: integer expected";
      return null;
    };

    /**
     * Creates a QueryGameRecordReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {bridge_proxy_proto.QueryGameRecordReq} QueryGameRecordReq
     */
    QueryGameRecordReq.fromObject = function fromObject(object) {
      if (object instanceof $root.bridge_proxy_proto.QueryGameRecordReq) return object;
      var message = new $root.bridge_proxy_proto.QueryGameRecordReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.game_uid != null) message.game_uid = String(object.game_uid);
      if (object.game_cycle_uid != null) message.game_cycle_uid = String(object.game_cycle_uid);
      if (object.order_final_datetime != null) message.order_final_datetime = object.order_final_datetime | 0;
      return message;
    };

    /**
     * Creates a plain object from a QueryGameRecordReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @static
     * @param {bridge_proxy_proto.QueryGameRecordReq} message QueryGameRecordReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    QueryGameRecordReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.game_uid = "";
        object.game_cycle_uid = "";
        object.order_final_datetime = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.game_uid != null && message.hasOwnProperty("game_uid")) object.game_uid = message.game_uid;
      if (message.game_cycle_uid != null && message.hasOwnProperty("game_cycle_uid"))
        object.game_cycle_uid = message.game_cycle_uid;
      if (message.order_final_datetime != null && message.hasOwnProperty("order_final_datetime"))
        object.order_final_datetime = message.order_final_datetime;
      return object;
    };

    /**
     * Converts this QueryGameRecordReq to JSON.
     * @function toJSON
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    QueryGameRecordReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for QueryGameRecordReq
     * @function getTypeUrl
     * @memberof bridge_proxy_proto.QueryGameRecordReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    QueryGameRecordReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/bridge_proxy_proto.QueryGameRecordReq";
    };

    return QueryGameRecordReq;
  })();

  bridge_proxy_proto.QueryGameRecordResp = (function () {
    /**
     * Properties of a QueryGameRecordResp.
     * @memberof bridge_proxy_proto
     * @interface IQueryGameRecordResp
     * @property {string|null} [game_history_url] QueryGameRecordResp game_history_url
     */

    /**
     * Constructs a new QueryGameRecordResp.
     * @memberof bridge_proxy_proto
     * @classdesc Represents a QueryGameRecordResp.
     * @implements IQueryGameRecordResp
     * @constructor
     * @param {bridge_proxy_proto.IQueryGameRecordResp=} [properties] Properties to set
     */
    function QueryGameRecordResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueryGameRecordResp game_history_url.
     * @member {string} game_history_url
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @instance
     */
    QueryGameRecordResp.prototype.game_history_url = "";

    /**
     * Creates a new QueryGameRecordResp instance using the specified properties.
     * @function create
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @static
     * @param {bridge_proxy_proto.IQueryGameRecordResp=} [properties] Properties to set
     * @returns {bridge_proxy_proto.QueryGameRecordResp} QueryGameRecordResp instance
     */
    QueryGameRecordResp.create = function create(properties) {
      return new QueryGameRecordResp(properties);
    };

    /**
     * Encodes the specified QueryGameRecordResp message. Does not implicitly {@link bridge_proxy_proto.QueryGameRecordResp.verify|verify} messages.
     * @function encode
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @static
     * @param {bridge_proxy_proto.IQueryGameRecordResp} message QueryGameRecordResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueryGameRecordResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_history_url != null && Object.hasOwnProperty.call(message, "game_history_url"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.game_history_url);
      return writer;
    };

    /**
     * Encodes the specified QueryGameRecordResp message, length delimited. Does not implicitly {@link bridge_proxy_proto.QueryGameRecordResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @static
     * @param {bridge_proxy_proto.IQueryGameRecordResp} message QueryGameRecordResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueryGameRecordResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a QueryGameRecordResp message from the specified reader or buffer.
     * @function decode
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {bridge_proxy_proto.QueryGameRecordResp} QueryGameRecordResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueryGameRecordResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.bridge_proxy_proto.QueryGameRecordResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.game_history_url = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a QueryGameRecordResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {bridge_proxy_proto.QueryGameRecordResp} QueryGameRecordResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueryGameRecordResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a QueryGameRecordResp message.
     * @function verify
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    QueryGameRecordResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_history_url != null && message.hasOwnProperty("game_history_url"))
        if (!$util.isString(message.game_history_url)) return "game_history_url: string expected";
      return null;
    };

    /**
     * Creates a QueryGameRecordResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {bridge_proxy_proto.QueryGameRecordResp} QueryGameRecordResp
     */
    QueryGameRecordResp.fromObject = function fromObject(object) {
      if (object instanceof $root.bridge_proxy_proto.QueryGameRecordResp) return object;
      var message = new $root.bridge_proxy_proto.QueryGameRecordResp();
      if (object.game_history_url != null) message.game_history_url = String(object.game_history_url);
      return message;
    };

    /**
     * Creates a plain object from a QueryGameRecordResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @static
     * @param {bridge_proxy_proto.QueryGameRecordResp} message QueryGameRecordResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    QueryGameRecordResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.game_history_url = "";
      if (message.game_history_url != null && message.hasOwnProperty("game_history_url"))
        object.game_history_url = message.game_history_url;
      return object;
    };

    /**
     * Converts this QueryGameRecordResp to JSON.
     * @function toJSON
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    QueryGameRecordResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for QueryGameRecordResp
     * @function getTypeUrl
     * @memberof bridge_proxy_proto.QueryGameRecordResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    QueryGameRecordResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/bridge_proxy_proto.QueryGameRecordResp";
    };

    return QueryGameRecordResp;
  })();

  bridge_proxy_proto.GameStatus = (function () {
    /**
     * Properties of a GameStatus.
     * @memberof bridge_proxy_proto
     * @interface IGameStatus
     * @property {string|null} [game_uid] GameStatus game_uid
     * @property {string|null} [game_name] GameStatus game_name
     * @property {string|null} [vendor_name] GameStatus vendor_name
     * @property {string|null} [game_type_name] GameStatus game_type_name
     * @property {number|null} [game_status] GameStatus game_status
     */

    /**
     * Constructs a new GameStatus.
     * @memberof bridge_proxy_proto
     * @classdesc Represents a GameStatus.
     * @implements IGameStatus
     * @constructor
     * @param {bridge_proxy_proto.IGameStatus=} [properties] Properties to set
     */
    function GameStatus(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameStatus game_uid.
     * @member {string} game_uid
     * @memberof bridge_proxy_proto.GameStatus
     * @instance
     */
    GameStatus.prototype.game_uid = "";

    /**
     * GameStatus game_name.
     * @member {string} game_name
     * @memberof bridge_proxy_proto.GameStatus
     * @instance
     */
    GameStatus.prototype.game_name = "";

    /**
     * GameStatus vendor_name.
     * @member {string} vendor_name
     * @memberof bridge_proxy_proto.GameStatus
     * @instance
     */
    GameStatus.prototype.vendor_name = "";

    /**
     * GameStatus game_type_name.
     * @member {string} game_type_name
     * @memberof bridge_proxy_proto.GameStatus
     * @instance
     */
    GameStatus.prototype.game_type_name = "";

    /**
     * GameStatus game_status.
     * @member {number} game_status
     * @memberof bridge_proxy_proto.GameStatus
     * @instance
     */
    GameStatus.prototype.game_status = 0;

    /**
     * Creates a new GameStatus instance using the specified properties.
     * @function create
     * @memberof bridge_proxy_proto.GameStatus
     * @static
     * @param {bridge_proxy_proto.IGameStatus=} [properties] Properties to set
     * @returns {bridge_proxy_proto.GameStatus} GameStatus instance
     */
    GameStatus.create = function create(properties) {
      return new GameStatus(properties);
    };

    /**
     * Encodes the specified GameStatus message. Does not implicitly {@link bridge_proxy_proto.GameStatus.verify|verify} messages.
     * @function encode
     * @memberof bridge_proxy_proto.GameStatus
     * @static
     * @param {bridge_proxy_proto.IGameStatus} message GameStatus message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameStatus.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_uid != null && Object.hasOwnProperty.call(message, "game_uid"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.game_uid);
      if (message.game_name != null && Object.hasOwnProperty.call(message, "game_name"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.game_name);
      if (message.vendor_name != null && Object.hasOwnProperty.call(message, "vendor_name"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.vendor_name);
      if (message.game_type_name != null && Object.hasOwnProperty.call(message, "game_type_name"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.game_type_name);
      if (message.game_status != null && Object.hasOwnProperty.call(message, "game_status"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.game_status);
      return writer;
    };

    /**
     * Encodes the specified GameStatus message, length delimited. Does not implicitly {@link bridge_proxy_proto.GameStatus.verify|verify} messages.
     * @function encodeDelimited
     * @memberof bridge_proxy_proto.GameStatus
     * @static
     * @param {bridge_proxy_proto.IGameStatus} message GameStatus message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameStatus.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameStatus message from the specified reader or buffer.
     * @function decode
     * @memberof bridge_proxy_proto.GameStatus
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {bridge_proxy_proto.GameStatus} GameStatus
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameStatus.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.bridge_proxy_proto.GameStatus();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.game_uid = reader.string();
            break;
          }
          case 2: {
            message.game_name = reader.string();
            break;
          }
          case 3: {
            message.vendor_name = reader.string();
            break;
          }
          case 4: {
            message.game_type_name = reader.string();
            break;
          }
          case 5: {
            message.game_status = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameStatus message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof bridge_proxy_proto.GameStatus
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {bridge_proxy_proto.GameStatus} GameStatus
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameStatus.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameStatus message.
     * @function verify
     * @memberof bridge_proxy_proto.GameStatus
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameStatus.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_uid != null && message.hasOwnProperty("game_uid"))
        if (!$util.isString(message.game_uid)) return "game_uid: string expected";
      if (message.game_name != null && message.hasOwnProperty("game_name"))
        if (!$util.isString(message.game_name)) return "game_name: string expected";
      if (message.vendor_name != null && message.hasOwnProperty("vendor_name"))
        if (!$util.isString(message.vendor_name)) return "vendor_name: string expected";
      if (message.game_type_name != null && message.hasOwnProperty("game_type_name"))
        if (!$util.isString(message.game_type_name)) return "game_type_name: string expected";
      if (message.game_status != null && message.hasOwnProperty("game_status"))
        if (!$util.isInteger(message.game_status)) return "game_status: integer expected";
      return null;
    };

    /**
     * Creates a GameStatus message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof bridge_proxy_proto.GameStatus
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {bridge_proxy_proto.GameStatus} GameStatus
     */
    GameStatus.fromObject = function fromObject(object) {
      if (object instanceof $root.bridge_proxy_proto.GameStatus) return object;
      var message = new $root.bridge_proxy_proto.GameStatus();
      if (object.game_uid != null) message.game_uid = String(object.game_uid);
      if (object.game_name != null) message.game_name = String(object.game_name);
      if (object.vendor_name != null) message.vendor_name = String(object.vendor_name);
      if (object.game_type_name != null) message.game_type_name = String(object.game_type_name);
      if (object.game_status != null) message.game_status = object.game_status | 0;
      return message;
    };

    /**
     * Creates a plain object from a GameStatus message. Also converts values to other types if specified.
     * @function toObject
     * @memberof bridge_proxy_proto.GameStatus
     * @static
     * @param {bridge_proxy_proto.GameStatus} message GameStatus
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameStatus.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.game_uid = "";
        object.game_name = "";
        object.vendor_name = "";
        object.game_type_name = "";
        object.game_status = 0;
      }
      if (message.game_uid != null && message.hasOwnProperty("game_uid")) object.game_uid = message.game_uid;
      if (message.game_name != null && message.hasOwnProperty("game_name")) object.game_name = message.game_name;
      if (message.vendor_name != null && message.hasOwnProperty("vendor_name"))
        object.vendor_name = message.vendor_name;
      if (message.game_type_name != null && message.hasOwnProperty("game_type_name"))
        object.game_type_name = message.game_type_name;
      if (message.game_status != null && message.hasOwnProperty("game_status"))
        object.game_status = message.game_status;
      return object;
    };

    /**
     * Converts this GameStatus to JSON.
     * @function toJSON
     * @memberof bridge_proxy_proto.GameStatus
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameStatus.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameStatus
     * @function getTypeUrl
     * @memberof bridge_proxy_proto.GameStatus
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameStatus.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/bridge_proxy_proto.GameStatus";
    };

    return GameStatus;
  })();

  bridge_proxy_proto.QueryGameStatusListReq = (function () {
    /**
     * Properties of a QueryGameStatusListReq.
     * @memberof bridge_proxy_proto
     * @interface IQueryGameStatusListReq
     * @property {number|null} [uid] QueryGameStatusListReq uid
     */

    /**
     * Constructs a new QueryGameStatusListReq.
     * @memberof bridge_proxy_proto
     * @classdesc Represents a QueryGameStatusListReq.
     * @implements IQueryGameStatusListReq
     * @constructor
     * @param {bridge_proxy_proto.IQueryGameStatusListReq=} [properties] Properties to set
     */
    function QueryGameStatusListReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueryGameStatusListReq uid.
     * @member {number} uid
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @instance
     */
    QueryGameStatusListReq.prototype.uid = 0;

    /**
     * Creates a new QueryGameStatusListReq instance using the specified properties.
     * @function create
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @static
     * @param {bridge_proxy_proto.IQueryGameStatusListReq=} [properties] Properties to set
     * @returns {bridge_proxy_proto.QueryGameStatusListReq} QueryGameStatusListReq instance
     */
    QueryGameStatusListReq.create = function create(properties) {
      return new QueryGameStatusListReq(properties);
    };

    /**
     * Encodes the specified QueryGameStatusListReq message. Does not implicitly {@link bridge_proxy_proto.QueryGameStatusListReq.verify|verify} messages.
     * @function encode
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @static
     * @param {bridge_proxy_proto.IQueryGameStatusListReq} message QueryGameStatusListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueryGameStatusListReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified QueryGameStatusListReq message, length delimited. Does not implicitly {@link bridge_proxy_proto.QueryGameStatusListReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @static
     * @param {bridge_proxy_proto.IQueryGameStatusListReq} message QueryGameStatusListReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueryGameStatusListReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a QueryGameStatusListReq message from the specified reader or buffer.
     * @function decode
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {bridge_proxy_proto.QueryGameStatusListReq} QueryGameStatusListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueryGameStatusListReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.bridge_proxy_proto.QueryGameStatusListReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a QueryGameStatusListReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {bridge_proxy_proto.QueryGameStatusListReq} QueryGameStatusListReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueryGameStatusListReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a QueryGameStatusListReq message.
     * @function verify
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    QueryGameStatusListReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates a QueryGameStatusListReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {bridge_proxy_proto.QueryGameStatusListReq} QueryGameStatusListReq
     */
    QueryGameStatusListReq.fromObject = function fromObject(object) {
      if (object instanceof $root.bridge_proxy_proto.QueryGameStatusListReq) return object;
      var message = new $root.bridge_proxy_proto.QueryGameStatusListReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a QueryGameStatusListReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @static
     * @param {bridge_proxy_proto.QueryGameStatusListReq} message QueryGameStatusListReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    QueryGameStatusListReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this QueryGameStatusListReq to JSON.
     * @function toJSON
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    QueryGameStatusListReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for QueryGameStatusListReq
     * @function getTypeUrl
     * @memberof bridge_proxy_proto.QueryGameStatusListReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    QueryGameStatusListReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/bridge_proxy_proto.QueryGameStatusListReq";
    };

    return QueryGameStatusListReq;
  })();

  bridge_proxy_proto.QueryGameStatusListResp = (function () {
    /**
     * Properties of a QueryGameStatusListResp.
     * @memberof bridge_proxy_proto
     * @interface IQueryGameStatusListResp
     * @property {Array.<bridge_proxy_proto.IGameStatus>|null} [game_status_list] QueryGameStatusListResp game_status_list
     */

    /**
     * Constructs a new QueryGameStatusListResp.
     * @memberof bridge_proxy_proto
     * @classdesc Represents a QueryGameStatusListResp.
     * @implements IQueryGameStatusListResp
     * @constructor
     * @param {bridge_proxy_proto.IQueryGameStatusListResp=} [properties] Properties to set
     */
    function QueryGameStatusListResp(properties) {
      this.game_status_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueryGameStatusListResp game_status_list.
     * @member {Array.<bridge_proxy_proto.IGameStatus>} game_status_list
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @instance
     */
    QueryGameStatusListResp.prototype.game_status_list = $util.emptyArray;

    /**
     * Creates a new QueryGameStatusListResp instance using the specified properties.
     * @function create
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @static
     * @param {bridge_proxy_proto.IQueryGameStatusListResp=} [properties] Properties to set
     * @returns {bridge_proxy_proto.QueryGameStatusListResp} QueryGameStatusListResp instance
     */
    QueryGameStatusListResp.create = function create(properties) {
      return new QueryGameStatusListResp(properties);
    };

    /**
     * Encodes the specified QueryGameStatusListResp message. Does not implicitly {@link bridge_proxy_proto.QueryGameStatusListResp.verify|verify} messages.
     * @function encode
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @static
     * @param {bridge_proxy_proto.IQueryGameStatusListResp} message QueryGameStatusListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueryGameStatusListResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_status_list != null && message.game_status_list.length)
        for (var i = 0; i < message.game_status_list.length; ++i)
          $root.bridge_proxy_proto.GameStatus.encode(
            message.game_status_list[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified QueryGameStatusListResp message, length delimited. Does not implicitly {@link bridge_proxy_proto.QueryGameStatusListResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @static
     * @param {bridge_proxy_proto.IQueryGameStatusListResp} message QueryGameStatusListResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueryGameStatusListResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a QueryGameStatusListResp message from the specified reader or buffer.
     * @function decode
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {bridge_proxy_proto.QueryGameStatusListResp} QueryGameStatusListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueryGameStatusListResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.bridge_proxy_proto.QueryGameStatusListResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.game_status_list && message.game_status_list.length)) message.game_status_list = [];
            message.game_status_list.push($root.bridge_proxy_proto.GameStatus.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a QueryGameStatusListResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {bridge_proxy_proto.QueryGameStatusListResp} QueryGameStatusListResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueryGameStatusListResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a QueryGameStatusListResp message.
     * @function verify
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    QueryGameStatusListResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_status_list != null && message.hasOwnProperty("game_status_list")) {
        if (!Array.isArray(message.game_status_list)) return "game_status_list: array expected";
        for (var i = 0; i < message.game_status_list.length; ++i) {
          var error = $root.bridge_proxy_proto.GameStatus.verify(message.game_status_list[i]);
          if (error) return "game_status_list." + error;
        }
      }
      return null;
    };

    /**
     * Creates a QueryGameStatusListResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {bridge_proxy_proto.QueryGameStatusListResp} QueryGameStatusListResp
     */
    QueryGameStatusListResp.fromObject = function fromObject(object) {
      if (object instanceof $root.bridge_proxy_proto.QueryGameStatusListResp) return object;
      var message = new $root.bridge_proxy_proto.QueryGameStatusListResp();
      if (object.game_status_list) {
        if (!Array.isArray(object.game_status_list))
          throw TypeError(".bridge_proxy_proto.QueryGameStatusListResp.game_status_list: array expected");
        message.game_status_list = [];
        for (var i = 0; i < object.game_status_list.length; ++i) {
          if (typeof object.game_status_list[i] !== "object")
            throw TypeError(".bridge_proxy_proto.QueryGameStatusListResp.game_status_list: object expected");
          message.game_status_list[i] = $root.bridge_proxy_proto.GameStatus.fromObject(object.game_status_list[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a QueryGameStatusListResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @static
     * @param {bridge_proxy_proto.QueryGameStatusListResp} message QueryGameStatusListResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    QueryGameStatusListResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.game_status_list = [];
      if (message.game_status_list && message.game_status_list.length) {
        object.game_status_list = [];
        for (var j = 0; j < message.game_status_list.length; ++j)
          object.game_status_list[j] = $root.bridge_proxy_proto.GameStatus.toObject(
            message.game_status_list[j],
            options,
          );
      }
      return object;
    };

    /**
     * Converts this QueryGameStatusListResp to JSON.
     * @function toJSON
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    QueryGameStatusListResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for QueryGameStatusListResp
     * @function getTypeUrl
     * @memberof bridge_proxy_proto.QueryGameStatusListResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    QueryGameStatusListResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/bridge_proxy_proto.QueryGameStatusListResp";
    };

    return QueryGameStatusListResp;
  })();

  bridge_proxy_proto.KickOutPush = (function () {
    /**
     * Properties of a KickOutPush.
     * @memberof bridge_proxy_proto
     * @interface IKickOutPush
     * @property {string|null} [from] KickOutPush from
     */

    /**
     * Constructs a new KickOutPush.
     * @memberof bridge_proxy_proto
     * @classdesc Represents a KickOutPush.
     * @implements IKickOutPush
     * @constructor
     * @param {bridge_proxy_proto.IKickOutPush=} [properties] Properties to set
     */
    function KickOutPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * KickOutPush from.
     * @member {string} from
     * @memberof bridge_proxy_proto.KickOutPush
     * @instance
     */
    KickOutPush.prototype.from = "";

    /**
     * Creates a new KickOutPush instance using the specified properties.
     * @function create
     * @memberof bridge_proxy_proto.KickOutPush
     * @static
     * @param {bridge_proxy_proto.IKickOutPush=} [properties] Properties to set
     * @returns {bridge_proxy_proto.KickOutPush} KickOutPush instance
     */
    KickOutPush.create = function create(properties) {
      return new KickOutPush(properties);
    };

    /**
     * Encodes the specified KickOutPush message. Does not implicitly {@link bridge_proxy_proto.KickOutPush.verify|verify} messages.
     * @function encode
     * @memberof bridge_proxy_proto.KickOutPush
     * @static
     * @param {bridge_proxy_proto.IKickOutPush} message KickOutPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KickOutPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.from != null && Object.hasOwnProperty.call(message, "from"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.from);
      return writer;
    };

    /**
     * Encodes the specified KickOutPush message, length delimited. Does not implicitly {@link bridge_proxy_proto.KickOutPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof bridge_proxy_proto.KickOutPush
     * @static
     * @param {bridge_proxy_proto.IKickOutPush} message KickOutPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KickOutPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a KickOutPush message from the specified reader or buffer.
     * @function decode
     * @memberof bridge_proxy_proto.KickOutPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {bridge_proxy_proto.KickOutPush} KickOutPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KickOutPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.bridge_proxy_proto.KickOutPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.from = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a KickOutPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof bridge_proxy_proto.KickOutPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {bridge_proxy_proto.KickOutPush} KickOutPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KickOutPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a KickOutPush message.
     * @function verify
     * @memberof bridge_proxy_proto.KickOutPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    KickOutPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.from != null && message.hasOwnProperty("from"))
        if (!$util.isString(message.from)) return "from: string expected";
      return null;
    };

    /**
     * Creates a KickOutPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof bridge_proxy_proto.KickOutPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {bridge_proxy_proto.KickOutPush} KickOutPush
     */
    KickOutPush.fromObject = function fromObject(object) {
      if (object instanceof $root.bridge_proxy_proto.KickOutPush) return object;
      var message = new $root.bridge_proxy_proto.KickOutPush();
      if (object.from != null) message.from = String(object.from);
      return message;
    };

    /**
     * Creates a plain object from a KickOutPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof bridge_proxy_proto.KickOutPush
     * @static
     * @param {bridge_proxy_proto.KickOutPush} message KickOutPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    KickOutPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.from = "";
      if (message.from != null && message.hasOwnProperty("from")) object.from = message.from;
      return object;
    };

    /**
     * Converts this KickOutPush to JSON.
     * @function toJSON
     * @memberof bridge_proxy_proto.KickOutPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    KickOutPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for KickOutPush
     * @function getTypeUrl
     * @memberof bridge_proxy_proto.KickOutPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    KickOutPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/bridge_proxy_proto.KickOutPush";
    };

    return KickOutPush;
  })();

  return bridge_proxy_proto;
})();

$root.agent = (function () {
  /**
   * Namespace agent.
   * @exports agent
   * @namespace
   */
  var agent = {};

  /**
   * AGENT_CMD enum.
   * @name agent.AGENT_CMD
   * @enum {number}
   * @property {number} AGENT_CMD_NONE=0 AGENT_CMD_NONE value
   * @property {number} AGENT_CMD_HEART_BEAT=1600 AGENT_CMD_HEART_BEAT value
   * @property {number} AGENT_CMD_KICK=1601 AGENT_CMD_KICK value
   */
  agent.AGENT_CMD = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "AGENT_CMD_NONE")] = 0;
    values[(valuesById[1600] = "AGENT_CMD_HEART_BEAT")] = 1600;
    values[(valuesById[1601] = "AGENT_CMD_KICK")] = 1601;
    return values;
  })();

  agent.KickPush = (function () {
    /**
     * Properties of a KickPush.
     * @memberof agent
     * @interface IKickPush
     * @property {number|null} [uid] KickPush uid
     * @property {number|null} [result] KickPush result
     */

    /**
     * Constructs a new KickPush.
     * @memberof agent
     * @classdesc Represents a KickPush.
     * @implements IKickPush
     * @constructor
     * @param {agent.IKickPush=} [properties] Properties to set
     */
    function KickPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * KickPush uid.
     * @member {number} uid
     * @memberof agent.KickPush
     * @instance
     */
    KickPush.prototype.uid = 0;

    /**
     * KickPush result.
     * @member {number} result
     * @memberof agent.KickPush
     * @instance
     */
    KickPush.prototype.result = 0;

    /**
     * Creates a new KickPush instance using the specified properties.
     * @function create
     * @memberof agent.KickPush
     * @static
     * @param {agent.IKickPush=} [properties] Properties to set
     * @returns {agent.KickPush} KickPush instance
     */
    KickPush.create = function create(properties) {
      return new KickPush(properties);
    };

    /**
     * Encodes the specified KickPush message. Does not implicitly {@link agent.KickPush.verify|verify} messages.
     * @function encode
     * @memberof agent.KickPush
     * @static
     * @param {agent.IKickPush} message KickPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KickPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified KickPush message, length delimited. Does not implicitly {@link agent.KickPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof agent.KickPush
     * @static
     * @param {agent.IKickPush} message KickPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KickPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a KickPush message from the specified reader or buffer.
     * @function decode
     * @memberof agent.KickPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {agent.KickPush} KickPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KickPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.agent.KickPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a KickPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof agent.KickPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {agent.KickPush} KickPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KickPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a KickPush message.
     * @function verify
     * @memberof agent.KickPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    KickPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a KickPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof agent.KickPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {agent.KickPush} KickPush
     */
    KickPush.fromObject = function fromObject(object) {
      if (object instanceof $root.agent.KickPush) return object;
      var message = new $root.agent.KickPush();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a KickPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof agent.KickPush
     * @static
     * @param {agent.KickPush} message KickPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    KickPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.result = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this KickPush to JSON.
     * @function toJSON
     * @memberof agent.KickPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    KickPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for KickPush
     * @function getTypeUrl
     * @memberof agent.KickPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    KickPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/agent.KickPush";
    };

    return KickPush;
  })();

  agent.HeartBeat = (function () {
    /**
     * Properties of a HeartBeat.
     * @memberof agent
     * @interface IHeartBeat
     * @property {number|Long|null} [trans] HeartBeat trans
     * @property {number|Long|null} [srvt] HeartBeat srvt
     */

    /**
     * Constructs a new HeartBeat.
     * @memberof agent
     * @classdesc Represents a HeartBeat.
     * @implements IHeartBeat
     * @constructor
     * @param {agent.IHeartBeat=} [properties] Properties to set
     */
    function HeartBeat(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * HeartBeat trans.
     * @member {number|Long} trans
     * @memberof agent.HeartBeat
     * @instance
     */
    HeartBeat.prototype.trans = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * HeartBeat srvt.
     * @member {number|Long} srvt
     * @memberof agent.HeartBeat
     * @instance
     */
    HeartBeat.prototype.srvt = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * Creates a new HeartBeat instance using the specified properties.
     * @function create
     * @memberof agent.HeartBeat
     * @static
     * @param {agent.IHeartBeat=} [properties] Properties to set
     * @returns {agent.HeartBeat} HeartBeat instance
     */
    HeartBeat.create = function create(properties) {
      return new HeartBeat(properties);
    };

    /**
     * Encodes the specified HeartBeat message. Does not implicitly {@link agent.HeartBeat.verify|verify} messages.
     * @function encode
     * @memberof agent.HeartBeat
     * @static
     * @param {agent.IHeartBeat} message HeartBeat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    HeartBeat.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint64(message.trans);
      if (message.srvt != null && Object.hasOwnProperty.call(message, "srvt"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.srvt);
      return writer;
    };

    /**
     * Encodes the specified HeartBeat message, length delimited. Does not implicitly {@link agent.HeartBeat.verify|verify} messages.
     * @function encodeDelimited
     * @memberof agent.HeartBeat
     * @static
     * @param {agent.IHeartBeat} message HeartBeat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    HeartBeat.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a HeartBeat message from the specified reader or buffer.
     * @function decode
     * @memberof agent.HeartBeat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {agent.HeartBeat} HeartBeat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    HeartBeat.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.agent.HeartBeat();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.trans = reader.uint64();
            break;
          }
          case 2: {
            message.srvt = reader.uint64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a HeartBeat message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof agent.HeartBeat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {agent.HeartBeat} HeartBeat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    HeartBeat.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a HeartBeat message.
     * @function verify
     * @memberof agent.HeartBeat
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    HeartBeat.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (
          !$util.isInteger(message.trans) &&
          !(message.trans && $util.isInteger(message.trans.low) && $util.isInteger(message.trans.high))
        )
          return "trans: integer|Long expected";
      if (message.srvt != null && message.hasOwnProperty("srvt"))
        if (
          !$util.isInteger(message.srvt) &&
          !(message.srvt && $util.isInteger(message.srvt.low) && $util.isInteger(message.srvt.high))
        )
          return "srvt: integer|Long expected";
      return null;
    };

    /**
     * Creates a HeartBeat message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof agent.HeartBeat
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {agent.HeartBeat} HeartBeat
     */
    HeartBeat.fromObject = function fromObject(object) {
      if (object instanceof $root.agent.HeartBeat) return object;
      var message = new $root.agent.HeartBeat();
      if (object.trans != null)
        if ($util.Long) (message.trans = $util.Long.fromValue(object.trans)).unsigned = true;
        else if (typeof object.trans === "string") message.trans = parseInt(object.trans, 10);
        else if (typeof object.trans === "number") message.trans = object.trans;
        else if (typeof object.trans === "object")
          message.trans = new $util.LongBits(object.trans.low >>> 0, object.trans.high >>> 0).toNumber(true);
      if (object.srvt != null)
        if ($util.Long) (message.srvt = $util.Long.fromValue(object.srvt)).unsigned = true;
        else if (typeof object.srvt === "string") message.srvt = parseInt(object.srvt, 10);
        else if (typeof object.srvt === "number") message.srvt = object.srvt;
        else if (typeof object.srvt === "object")
          message.srvt = new $util.LongBits(object.srvt.low >>> 0, object.srvt.high >>> 0).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from a HeartBeat message. Also converts values to other types if specified.
     * @function toObject
     * @memberof agent.HeartBeat
     * @static
     * @param {agent.HeartBeat} message HeartBeat
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    HeartBeat.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.trans = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.trans = options.longs === String ? "0" : 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.srvt = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.srvt = options.longs === String ? "0" : 0;
      }
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (typeof message.trans === "number")
          object.trans = options.longs === String ? String(message.trans) : message.trans;
        else
          object.trans =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.trans)
              : options.longs === Number
                ? new $util.LongBits(message.trans.low >>> 0, message.trans.high >>> 0).toNumber(true)
                : message.trans;
      if (message.srvt != null && message.hasOwnProperty("srvt"))
        if (typeof message.srvt === "number")
          object.srvt = options.longs === String ? String(message.srvt) : message.srvt;
        else
          object.srvt =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.srvt)
              : options.longs === Number
                ? new $util.LongBits(message.srvt.low >>> 0, message.srvt.high >>> 0).toNumber(true)
                : message.srvt;
      return object;
    };

    /**
     * Converts this HeartBeat to JSON.
     * @function toJSON
     * @memberof agent.HeartBeat
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    HeartBeat.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for HeartBeat
     * @function getTypeUrl
     * @memberof agent.HeartBeat
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    HeartBeat.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/agent.HeartBeat";
    };

    return HeartBeat;
  })();

  return agent;
})();

$root.AgentBackEnd = (function () {
  /**
   * Namespace AgentBackEnd.
   * @exports AgentBackEnd
   * @namespace
   */
  var AgentBackEnd = {};

  /**
   * AGENT_BACKEND_INFO_SUB_MSG_ID enum.
   * @name AgentBackEnd.AGENT_BACKEND_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} AGENT_BACKEND_MSG_ID_NULL=0 AGENT_BACKEND_MSG_ID_NULL value
   * @property {number} AGENT_BACKEND_MSG_ID_MIN=1650 AGENT_BACKEND_MSG_ID_MIN value
   * @property {number} AGENT_BACKEND_MSG_ID_REFRESH_CONFIG=1651 AGENT_BACKEND_MSG_ID_REFRESH_CONFIG value
   * @property {number} AGENT_BACKEND_MSG_ID_REPORT_DATA=1652 AGENT_BACKEND_MSG_ID_REPORT_DATA value
   * @property {number} AGENT_BACKEND_MSG_ID_SERVER_RETIRE=1653 AGENT_BACKEND_MSG_ID_SERVER_RETIRE value
   * @property {number} AGENT_BACKEND_MSG_ID_REPORT_KOL=1654 AGENT_BACKEND_MSG_ID_REPORT_KOL value
   * @property {number} RANK_MSG_ID_MAX=1700 RANK_MSG_ID_MAX value
   */
  AgentBackEnd.AGENT_BACKEND_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "AGENT_BACKEND_MSG_ID_NULL")] = 0;
    values[(valuesById[1650] = "AGENT_BACKEND_MSG_ID_MIN")] = 1650;
    values[(valuesById[1651] = "AGENT_BACKEND_MSG_ID_REFRESH_CONFIG")] = 1651;
    values[(valuesById[1652] = "AGENT_BACKEND_MSG_ID_REPORT_DATA")] = 1652;
    values[(valuesById[1653] = "AGENT_BACKEND_MSG_ID_SERVER_RETIRE")] = 1653;
    values[(valuesById[1654] = "AGENT_BACKEND_MSG_ID_REPORT_KOL")] = 1654;
    values[(valuesById[1700] = "RANK_MSG_ID_MAX")] = 1700;
    return values;
  })();

  /**
   * SERVER_TYPE enum.
   * @name AgentBackEnd.SERVER_TYPE
   * @enum {number}
   * @property {number} SERVER_TYPE_NONE=0 SERVER_TYPE_NONE value
   * @property {number} SERVER_TYPE_GAME_PLAYER=1 SERVER_TYPE_GAME_PLAYER value
   * @property {number} SERVER_TYPE_AGENT=2 SERVER_TYPE_AGENT value
   * @property {number} SERVER_TYPE_ALLOCSERVER=3 SERVER_TYPE_ALLOCSERVER value
   * @property {number} SERVER_TYPE_ONLINE=4 SERVER_TYPE_ONLINE value
   * @property {number} SERVER_TYPE_ROOMSERVER=5 SERVER_TYPE_ROOMSERVER value
   * @property {number} SERVER_TYPE_MASTER=6 SERVER_TYPE_MASTER value
   * @property {number} SERVER_TYPE_ASSET=7 SERVER_TYPE_ASSET value
   * @property {number} SERVER_TYPE_DB_UPDATE=8 SERVER_TYPE_DB_UPDATE value
   * @property {number} SERVER_TYPE_USERINFO_CACHE=9 SERVER_TYPE_USERINFO_CACHE value
   * @property {number} SERVER_TYPE_PHP_AGENT=10 SERVER_TYPE_PHP_AGENT value
   * @property {number} SERVER_TYPE_ACCOUNT=11 SERVER_TYPE_ACCOUNT value
   * @property {number} SERVER_TYPE_DISPATCH=12 SERVER_TYPE_DISPATCH value
   * @property {number} SERVER_TYPE_RANK=13 SERVER_TYPE_RANK value
   * @property {number} SERVER_TYPE_SERVICE_MANAGER=14 SERVER_TYPE_SERVICE_MANAGER value
   * @property {number} SERVER_TYPE_BRIDGE_PROXY=16 SERVER_TYPE_BRIDGE_PROXY value
   * @property {number} SERVER_TYPE_ACTIVITY=18 SERVER_TYPE_ACTIVITY value
   * @property {number} SERVER_TYPE_PHP=19 SERVER_TYPE_PHP value
   */
  AgentBackEnd.SERVER_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "SERVER_TYPE_NONE")] = 0;
    values[(valuesById[1] = "SERVER_TYPE_GAME_PLAYER")] = 1;
    values[(valuesById[2] = "SERVER_TYPE_AGENT")] = 2;
    values[(valuesById[3] = "SERVER_TYPE_ALLOCSERVER")] = 3;
    values[(valuesById[4] = "SERVER_TYPE_ONLINE")] = 4;
    values[(valuesById[5] = "SERVER_TYPE_ROOMSERVER")] = 5;
    values[(valuesById[6] = "SERVER_TYPE_MASTER")] = 6;
    values[(valuesById[7] = "SERVER_TYPE_ASSET")] = 7;
    values[(valuesById[8] = "SERVER_TYPE_DB_UPDATE")] = 8;
    values[(valuesById[9] = "SERVER_TYPE_USERINFO_CACHE")] = 9;
    values[(valuesById[10] = "SERVER_TYPE_PHP_AGENT")] = 10;
    values[(valuesById[11] = "SERVER_TYPE_ACCOUNT")] = 11;
    values[(valuesById[12] = "SERVER_TYPE_DISPATCH")] = 12;
    values[(valuesById[13] = "SERVER_TYPE_RANK")] = 13;
    values[(valuesById[14] = "SERVER_TYPE_SERVICE_MANAGER")] = 14;
    values[(valuesById[16] = "SERVER_TYPE_BRIDGE_PROXY")] = 16;
    values[(valuesById[18] = "SERVER_TYPE_ACTIVITY")] = 18;
    values[(valuesById[19] = "SERVER_TYPE_PHP")] = 19;
    return values;
  })();

  /**
   * OP enum.
   * @name AgentBackEnd.OP
   * @enum {number}
   * @property {number} OP_NONE=0 OP_NONE value
   * @property {number} OP_UPDATE=1 OP_UPDATE value
   * @property {number} OP_ADD=2 OP_ADD value
   * @property {number} OP_DEL=3 OP_DEL value
   */
  AgentBackEnd.OP = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "OP_NONE")] = 0;
    values[(valuesById[1] = "OP_UPDATE")] = 1;
    values[(valuesById[2] = "OP_ADD")] = 2;
    values[(valuesById[3] = "OP_DEL")] = 3;
    return values;
  })();

  AgentBackEnd.ConfigItem = (function () {
    /**
     * Properties of a ConfigItem.
     * @memberof AgentBackEnd
     * @interface IConfigItem
     * @property {string|null} [configname] ConfigItem configname
     * @property {AgentBackEnd.OP|null} [operation] ConfigItem operation
     * @property {string|null} [config] ConfigItem config
     * @property {string|null} [sqlpath] ConfigItem sqlpath
     * @property {string|null} [redispath] ConfigItem redispath
     */

    /**
     * Constructs a new ConfigItem.
     * @memberof AgentBackEnd
     * @classdesc Represents a ConfigItem.
     * @implements IConfigItem
     * @constructor
     * @param {AgentBackEnd.IConfigItem=} [properties] Properties to set
     */
    function ConfigItem(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ConfigItem configname.
     * @member {string} configname
     * @memberof AgentBackEnd.ConfigItem
     * @instance
     */
    ConfigItem.prototype.configname = "";

    /**
     * ConfigItem operation.
     * @member {AgentBackEnd.OP} operation
     * @memberof AgentBackEnd.ConfigItem
     * @instance
     */
    ConfigItem.prototype.operation = 0;

    /**
     * ConfigItem config.
     * @member {string} config
     * @memberof AgentBackEnd.ConfigItem
     * @instance
     */
    ConfigItem.prototype.config = "";

    /**
     * ConfigItem sqlpath.
     * @member {string} sqlpath
     * @memberof AgentBackEnd.ConfigItem
     * @instance
     */
    ConfigItem.prototype.sqlpath = "";

    /**
     * ConfigItem redispath.
     * @member {string} redispath
     * @memberof AgentBackEnd.ConfigItem
     * @instance
     */
    ConfigItem.prototype.redispath = "";

    /**
     * Creates a new ConfigItem instance using the specified properties.
     * @function create
     * @memberof AgentBackEnd.ConfigItem
     * @static
     * @param {AgentBackEnd.IConfigItem=} [properties] Properties to set
     * @returns {AgentBackEnd.ConfigItem} ConfigItem instance
     */
    ConfigItem.create = function create(properties) {
      return new ConfigItem(properties);
    };

    /**
     * Encodes the specified ConfigItem message. Does not implicitly {@link AgentBackEnd.ConfigItem.verify|verify} messages.
     * @function encode
     * @memberof AgentBackEnd.ConfigItem
     * @static
     * @param {AgentBackEnd.IConfigItem} message ConfigItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ConfigItem.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.configname != null && Object.hasOwnProperty.call(message, "configname"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.configname);
      if (message.operation != null && Object.hasOwnProperty.call(message, "operation"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.operation);
      if (message.config != null && Object.hasOwnProperty.call(message, "config"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.config);
      if (message.sqlpath != null && Object.hasOwnProperty.call(message, "sqlpath"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.sqlpath);
      if (message.redispath != null && Object.hasOwnProperty.call(message, "redispath"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.redispath);
      return writer;
    };

    /**
     * Encodes the specified ConfigItem message, length delimited. Does not implicitly {@link AgentBackEnd.ConfigItem.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AgentBackEnd.ConfigItem
     * @static
     * @param {AgentBackEnd.IConfigItem} message ConfigItem message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ConfigItem.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ConfigItem message from the specified reader or buffer.
     * @function decode
     * @memberof AgentBackEnd.ConfigItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AgentBackEnd.ConfigItem} ConfigItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ConfigItem.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.AgentBackEnd.ConfigItem();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.configname = reader.string();
            break;
          }
          case 2: {
            message.operation = reader.int32();
            break;
          }
          case 3: {
            message.config = reader.string();
            break;
          }
          case 4: {
            message.sqlpath = reader.string();
            break;
          }
          case 5: {
            message.redispath = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ConfigItem message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AgentBackEnd.ConfigItem
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AgentBackEnd.ConfigItem} ConfigItem
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ConfigItem.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ConfigItem message.
     * @function verify
     * @memberof AgentBackEnd.ConfigItem
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ConfigItem.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.configname != null && message.hasOwnProperty("configname"))
        if (!$util.isString(message.configname)) return "configname: string expected";
      if (message.operation != null && message.hasOwnProperty("operation"))
        switch (message.operation) {
          default:
            return "operation: enum value expected";
          case 0:
          case 1:
          case 2:
          case 3:
            break;
        }
      if (message.config != null && message.hasOwnProperty("config"))
        if (!$util.isString(message.config)) return "config: string expected";
      if (message.sqlpath != null && message.hasOwnProperty("sqlpath"))
        if (!$util.isString(message.sqlpath)) return "sqlpath: string expected";
      if (message.redispath != null && message.hasOwnProperty("redispath"))
        if (!$util.isString(message.redispath)) return "redispath: string expected";
      return null;
    };

    /**
     * Creates a ConfigItem message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AgentBackEnd.ConfigItem
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AgentBackEnd.ConfigItem} ConfigItem
     */
    ConfigItem.fromObject = function fromObject(object) {
      if (object instanceof $root.AgentBackEnd.ConfigItem) return object;
      var message = new $root.AgentBackEnd.ConfigItem();
      if (object.configname != null) message.configname = String(object.configname);
      switch (object.operation) {
        default:
          if (typeof object.operation === "number") {
            message.operation = object.operation;
            break;
          }
          break;
        case "OP_NONE":
        case 0:
          message.operation = 0;
          break;
        case "OP_UPDATE":
        case 1:
          message.operation = 1;
          break;
        case "OP_ADD":
        case 2:
          message.operation = 2;
          break;
        case "OP_DEL":
        case 3:
          message.operation = 3;
          break;
      }
      if (object.config != null) message.config = String(object.config);
      if (object.sqlpath != null) message.sqlpath = String(object.sqlpath);
      if (object.redispath != null) message.redispath = String(object.redispath);
      return message;
    };

    /**
     * Creates a plain object from a ConfigItem message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AgentBackEnd.ConfigItem
     * @static
     * @param {AgentBackEnd.ConfigItem} message ConfigItem
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ConfigItem.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.configname = "";
        object.operation = options.enums === String ? "OP_NONE" : 0;
        object.config = "";
        object.sqlpath = "";
        object.redispath = "";
      }
      if (message.configname != null && message.hasOwnProperty("configname")) object.configname = message.configname;
      if (message.operation != null && message.hasOwnProperty("operation"))
        object.operation =
          options.enums === String
            ? $root.AgentBackEnd.OP[message.operation] === undefined
              ? message.operation
              : $root.AgentBackEnd.OP[message.operation]
            : message.operation;
      if (message.config != null && message.hasOwnProperty("config")) object.config = message.config;
      if (message.sqlpath != null && message.hasOwnProperty("sqlpath")) object.sqlpath = message.sqlpath;
      if (message.redispath != null && message.hasOwnProperty("redispath")) object.redispath = message.redispath;
      return object;
    };

    /**
     * Converts this ConfigItem to JSON.
     * @function toJSON
     * @memberof AgentBackEnd.ConfigItem
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ConfigItem.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ConfigItem
     * @function getTypeUrl
     * @memberof AgentBackEnd.ConfigItem
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ConfigItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/AgentBackEnd.ConfigItem";
    };

    return ConfigItem;
  })();

  AgentBackEnd.RefreshConfigNotify = (function () {
    /**
     * Properties of a RefreshConfigNotify.
     * @memberof AgentBackEnd
     * @interface IRefreshConfigNotify
     * @property {Array.<AgentBackEnd.IConfigItem>|null} [config] RefreshConfigNotify config
     */

    /**
     * Constructs a new RefreshConfigNotify.
     * @memberof AgentBackEnd
     * @classdesc Represents a RefreshConfigNotify.
     * @implements IRefreshConfigNotify
     * @constructor
     * @param {AgentBackEnd.IRefreshConfigNotify=} [properties] Properties to set
     */
    function RefreshConfigNotify(properties) {
      this.config = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * RefreshConfigNotify config.
     * @member {Array.<AgentBackEnd.IConfigItem>} config
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @instance
     */
    RefreshConfigNotify.prototype.config = $util.emptyArray;

    /**
     * Creates a new RefreshConfigNotify instance using the specified properties.
     * @function create
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @static
     * @param {AgentBackEnd.IRefreshConfigNotify=} [properties] Properties to set
     * @returns {AgentBackEnd.RefreshConfigNotify} RefreshConfigNotify instance
     */
    RefreshConfigNotify.create = function create(properties) {
      return new RefreshConfigNotify(properties);
    };

    /**
     * Encodes the specified RefreshConfigNotify message. Does not implicitly {@link AgentBackEnd.RefreshConfigNotify.verify|verify} messages.
     * @function encode
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @static
     * @param {AgentBackEnd.IRefreshConfigNotify} message RefreshConfigNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RefreshConfigNotify.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.config != null && message.config.length)
        for (var i = 0; i < message.config.length; ++i)
          $root.AgentBackEnd.ConfigItem.encode(
            message.config[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified RefreshConfigNotify message, length delimited. Does not implicitly {@link AgentBackEnd.RefreshConfigNotify.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @static
     * @param {AgentBackEnd.IRefreshConfigNotify} message RefreshConfigNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RefreshConfigNotify.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a RefreshConfigNotify message from the specified reader or buffer.
     * @function decode
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AgentBackEnd.RefreshConfigNotify} RefreshConfigNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RefreshConfigNotify.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.AgentBackEnd.RefreshConfigNotify();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.config && message.config.length)) message.config = [];
            message.config.push($root.AgentBackEnd.ConfigItem.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a RefreshConfigNotify message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AgentBackEnd.RefreshConfigNotify} RefreshConfigNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RefreshConfigNotify.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a RefreshConfigNotify message.
     * @function verify
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    RefreshConfigNotify.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.config != null && message.hasOwnProperty("config")) {
        if (!Array.isArray(message.config)) return "config: array expected";
        for (var i = 0; i < message.config.length; ++i) {
          var error = $root.AgentBackEnd.ConfigItem.verify(message.config[i]);
          if (error) return "config." + error;
        }
      }
      return null;
    };

    /**
     * Creates a RefreshConfigNotify message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AgentBackEnd.RefreshConfigNotify} RefreshConfigNotify
     */
    RefreshConfigNotify.fromObject = function fromObject(object) {
      if (object instanceof $root.AgentBackEnd.RefreshConfigNotify) return object;
      var message = new $root.AgentBackEnd.RefreshConfigNotify();
      if (object.config) {
        if (!Array.isArray(object.config)) throw TypeError(".AgentBackEnd.RefreshConfigNotify.config: array expected");
        message.config = [];
        for (var i = 0; i < object.config.length; ++i) {
          if (typeof object.config[i] !== "object")
            throw TypeError(".AgentBackEnd.RefreshConfigNotify.config: object expected");
          message.config[i] = $root.AgentBackEnd.ConfigItem.fromObject(object.config[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a RefreshConfigNotify message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @static
     * @param {AgentBackEnd.RefreshConfigNotify} message RefreshConfigNotify
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    RefreshConfigNotify.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.config = [];
      if (message.config && message.config.length) {
        object.config = [];
        for (var j = 0; j < message.config.length; ++j)
          object.config[j] = $root.AgentBackEnd.ConfigItem.toObject(message.config[j], options);
      }
      return object;
    };

    /**
     * Converts this RefreshConfigNotify to JSON.
     * @function toJSON
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    RefreshConfigNotify.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for RefreshConfigNotify
     * @function getTypeUrl
     * @memberof AgentBackEnd.RefreshConfigNotify
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    RefreshConfigNotify.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/AgentBackEnd.RefreshConfigNotify";
    };

    return RefreshConfigNotify;
  })();

  AgentBackEnd.PhpReportUploadReq = (function () {
    /**
     * Properties of a PhpReportUploadReq.
     * @memberof AgentBackEnd
     * @interface IPhpReportUploadReq
     * @property {string|null} [eventType] PhpReportUploadReq eventType
     * @property {string|null} [fbid] PhpReportUploadReq fbid
     * @property {string|null} [mobile] PhpReportUploadReq mobile
     * @property {string|null} [ip] PhpReportUploadReq ip
     * @property {string|null} [fbc] PhpReportUploadReq fbc
     * @property {string|null} [fbp] PhpReportUploadReq fbp
     * @property {string|null} [ua] PhpReportUploadReq ua
     * @property {string|null} [amount] PhpReportUploadReq amount
     * @property {string|null} [order_id] PhpReportUploadReq order_id
     * @property {string|null} [device_id] PhpReportUploadReq device_id
     * @property {string|null} [pkg] PhpReportUploadReq pkg
     * @property {string|null} [uid] PhpReportUploadReq uid
     * @property {string|null} [afid] PhpReportUploadReq afid
     * @property {string|null} [renew] PhpReportUploadReq renew
     */

    /**
     * Constructs a new PhpReportUploadReq.
     * @memberof AgentBackEnd
     * @classdesc Represents a PhpReportUploadReq.
     * @implements IPhpReportUploadReq
     * @constructor
     * @param {AgentBackEnd.IPhpReportUploadReq=} [properties] Properties to set
     */
    function PhpReportUploadReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpReportUploadReq eventType.
     * @member {string} eventType
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.eventType = "";

    /**
     * PhpReportUploadReq fbid.
     * @member {string} fbid
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.fbid = "";

    /**
     * PhpReportUploadReq mobile.
     * @member {string} mobile
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.mobile = "";

    /**
     * PhpReportUploadReq ip.
     * @member {string} ip
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.ip = "";

    /**
     * PhpReportUploadReq fbc.
     * @member {string} fbc
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.fbc = "";

    /**
     * PhpReportUploadReq fbp.
     * @member {string} fbp
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.fbp = "";

    /**
     * PhpReportUploadReq ua.
     * @member {string} ua
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.ua = "";

    /**
     * PhpReportUploadReq amount.
     * @member {string} amount
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.amount = "";

    /**
     * PhpReportUploadReq order_id.
     * @member {string} order_id
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.order_id = "";

    /**
     * PhpReportUploadReq device_id.
     * @member {string} device_id
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.device_id = "";

    /**
     * PhpReportUploadReq pkg.
     * @member {string} pkg
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.pkg = "";

    /**
     * PhpReportUploadReq uid.
     * @member {string} uid
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.uid = "";

    /**
     * PhpReportUploadReq afid.
     * @member {string} afid
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.afid = "";

    /**
     * PhpReportUploadReq renew.
     * @member {string} renew
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     */
    PhpReportUploadReq.prototype.renew = "";

    /**
     * Creates a new PhpReportUploadReq instance using the specified properties.
     * @function create
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @static
     * @param {AgentBackEnd.IPhpReportUploadReq=} [properties] Properties to set
     * @returns {AgentBackEnd.PhpReportUploadReq} PhpReportUploadReq instance
     */
    PhpReportUploadReq.create = function create(properties) {
      return new PhpReportUploadReq(properties);
    };

    /**
     * Encodes the specified PhpReportUploadReq message. Does not implicitly {@link AgentBackEnd.PhpReportUploadReq.verify|verify} messages.
     * @function encode
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @static
     * @param {AgentBackEnd.IPhpReportUploadReq} message PhpReportUploadReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpReportUploadReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.eventType != null && Object.hasOwnProperty.call(message, "eventType"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.eventType);
      if (message.fbid != null && Object.hasOwnProperty.call(message, "fbid"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.fbid);
      if (message.mobile != null && Object.hasOwnProperty.call(message, "mobile"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.mobile);
      if (message.ip != null && Object.hasOwnProperty.call(message, "ip"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.ip);
      if (message.fbc != null && Object.hasOwnProperty.call(message, "fbc"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.fbc);
      if (message.fbp != null && Object.hasOwnProperty.call(message, "fbp"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.fbp);
      if (message.ua != null && Object.hasOwnProperty.call(message, "ua"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.ua);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.amount);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.order_id);
      if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.device_id);
      if (message.pkg != null && Object.hasOwnProperty.call(message, "pkg"))
        writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.pkg);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 12, wireType 2 =*/ 98).string(message.uid);
      if (message.afid != null && Object.hasOwnProperty.call(message, "afid"))
        writer.uint32(/* id 13, wireType 2 =*/ 106).string(message.afid);
      if (message.renew != null && Object.hasOwnProperty.call(message, "renew"))
        writer.uint32(/* id 14, wireType 2 =*/ 114).string(message.renew);
      return writer;
    };

    /**
     * Encodes the specified PhpReportUploadReq message, length delimited. Does not implicitly {@link AgentBackEnd.PhpReportUploadReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @static
     * @param {AgentBackEnd.IPhpReportUploadReq} message PhpReportUploadReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpReportUploadReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpReportUploadReq message from the specified reader or buffer.
     * @function decode
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AgentBackEnd.PhpReportUploadReq} PhpReportUploadReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpReportUploadReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.AgentBackEnd.PhpReportUploadReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.eventType = reader.string();
            break;
          }
          case 2: {
            message.fbid = reader.string();
            break;
          }
          case 3: {
            message.mobile = reader.string();
            break;
          }
          case 4: {
            message.ip = reader.string();
            break;
          }
          case 5: {
            message.fbc = reader.string();
            break;
          }
          case 6: {
            message.fbp = reader.string();
            break;
          }
          case 7: {
            message.ua = reader.string();
            break;
          }
          case 8: {
            message.amount = reader.string();
            break;
          }
          case 9: {
            message.order_id = reader.string();
            break;
          }
          case 10: {
            message.device_id = reader.string();
            break;
          }
          case 11: {
            message.pkg = reader.string();
            break;
          }
          case 12: {
            message.uid = reader.string();
            break;
          }
          case 13: {
            message.afid = reader.string();
            break;
          }
          case 14: {
            message.renew = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpReportUploadReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AgentBackEnd.PhpReportUploadReq} PhpReportUploadReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpReportUploadReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpReportUploadReq message.
     * @function verify
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpReportUploadReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.eventType != null && message.hasOwnProperty("eventType"))
        if (!$util.isString(message.eventType)) return "eventType: string expected";
      if (message.fbid != null && message.hasOwnProperty("fbid"))
        if (!$util.isString(message.fbid)) return "fbid: string expected";
      if (message.mobile != null && message.hasOwnProperty("mobile"))
        if (!$util.isString(message.mobile)) return "mobile: string expected";
      if (message.ip != null && message.hasOwnProperty("ip"))
        if (!$util.isString(message.ip)) return "ip: string expected";
      if (message.fbc != null && message.hasOwnProperty("fbc"))
        if (!$util.isString(message.fbc)) return "fbc: string expected";
      if (message.fbp != null && message.hasOwnProperty("fbp"))
        if (!$util.isString(message.fbp)) return "fbp: string expected";
      if (message.ua != null && message.hasOwnProperty("ua"))
        if (!$util.isString(message.ua)) return "ua: string expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (!$util.isString(message.amount)) return "amount: string expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isString(message.order_id)) return "order_id: string expected";
      if (message.device_id != null && message.hasOwnProperty("device_id"))
        if (!$util.isString(message.device_id)) return "device_id: string expected";
      if (message.pkg != null && message.hasOwnProperty("pkg"))
        if (!$util.isString(message.pkg)) return "pkg: string expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isString(message.uid)) return "uid: string expected";
      if (message.afid != null && message.hasOwnProperty("afid"))
        if (!$util.isString(message.afid)) return "afid: string expected";
      if (message.renew != null && message.hasOwnProperty("renew"))
        if (!$util.isString(message.renew)) return "renew: string expected";
      return null;
    };

    /**
     * Creates a PhpReportUploadReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AgentBackEnd.PhpReportUploadReq} PhpReportUploadReq
     */
    PhpReportUploadReq.fromObject = function fromObject(object) {
      if (object instanceof $root.AgentBackEnd.PhpReportUploadReq) return object;
      var message = new $root.AgentBackEnd.PhpReportUploadReq();
      if (object.eventType != null) message.eventType = String(object.eventType);
      if (object.fbid != null) message.fbid = String(object.fbid);
      if (object.mobile != null) message.mobile = String(object.mobile);
      if (object.ip != null) message.ip = String(object.ip);
      if (object.fbc != null) message.fbc = String(object.fbc);
      if (object.fbp != null) message.fbp = String(object.fbp);
      if (object.ua != null) message.ua = String(object.ua);
      if (object.amount != null) message.amount = String(object.amount);
      if (object.order_id != null) message.order_id = String(object.order_id);
      if (object.device_id != null) message.device_id = String(object.device_id);
      if (object.pkg != null) message.pkg = String(object.pkg);
      if (object.uid != null) message.uid = String(object.uid);
      if (object.afid != null) message.afid = String(object.afid);
      if (object.renew != null) message.renew = String(object.renew);
      return message;
    };

    /**
     * Creates a plain object from a PhpReportUploadReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @static
     * @param {AgentBackEnd.PhpReportUploadReq} message PhpReportUploadReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpReportUploadReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.eventType = "";
        object.fbid = "";
        object.mobile = "";
        object.ip = "";
        object.fbc = "";
        object.fbp = "";
        object.ua = "";
        object.amount = "";
        object.order_id = "";
        object.device_id = "";
        object.pkg = "";
        object.uid = "";
        object.afid = "";
        object.renew = "";
      }
      if (message.eventType != null && message.hasOwnProperty("eventType")) object.eventType = message.eventType;
      if (message.fbid != null && message.hasOwnProperty("fbid")) object.fbid = message.fbid;
      if (message.mobile != null && message.hasOwnProperty("mobile")) object.mobile = message.mobile;
      if (message.ip != null && message.hasOwnProperty("ip")) object.ip = message.ip;
      if (message.fbc != null && message.hasOwnProperty("fbc")) object.fbc = message.fbc;
      if (message.fbp != null && message.hasOwnProperty("fbp")) object.fbp = message.fbp;
      if (message.ua != null && message.hasOwnProperty("ua")) object.ua = message.ua;
      if (message.amount != null && message.hasOwnProperty("amount")) object.amount = message.amount;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      if (message.device_id != null && message.hasOwnProperty("device_id")) object.device_id = message.device_id;
      if (message.pkg != null && message.hasOwnProperty("pkg")) object.pkg = message.pkg;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.afid != null && message.hasOwnProperty("afid")) object.afid = message.afid;
      if (message.renew != null && message.hasOwnProperty("renew")) object.renew = message.renew;
      return object;
    };

    /**
     * Converts this PhpReportUploadReq to JSON.
     * @function toJSON
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpReportUploadReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpReportUploadReq
     * @function getTypeUrl
     * @memberof AgentBackEnd.PhpReportUploadReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpReportUploadReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/AgentBackEnd.PhpReportUploadReq";
    };

    return PhpReportUploadReq;
  })();

  AgentBackEnd.ServerRetireNotify = (function () {
    /**
     * Properties of a ServerRetireNotify.
     * @memberof AgentBackEnd
     * @interface IServerRetireNotify
     * @property {number|null} [status] ServerRetireNotify status
     * @property {number|null} [isforce] ServerRetireNotify isforce
     */

    /**
     * Constructs a new ServerRetireNotify.
     * @memberof AgentBackEnd
     * @classdesc Represents a ServerRetireNotify.
     * @implements IServerRetireNotify
     * @constructor
     * @param {AgentBackEnd.IServerRetireNotify=} [properties] Properties to set
     */
    function ServerRetireNotify(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ServerRetireNotify status.
     * @member {number} status
     * @memberof AgentBackEnd.ServerRetireNotify
     * @instance
     */
    ServerRetireNotify.prototype.status = 0;

    /**
     * ServerRetireNotify isforce.
     * @member {number} isforce
     * @memberof AgentBackEnd.ServerRetireNotify
     * @instance
     */
    ServerRetireNotify.prototype.isforce = 0;

    /**
     * Creates a new ServerRetireNotify instance using the specified properties.
     * @function create
     * @memberof AgentBackEnd.ServerRetireNotify
     * @static
     * @param {AgentBackEnd.IServerRetireNotify=} [properties] Properties to set
     * @returns {AgentBackEnd.ServerRetireNotify} ServerRetireNotify instance
     */
    ServerRetireNotify.create = function create(properties) {
      return new ServerRetireNotify(properties);
    };

    /**
     * Encodes the specified ServerRetireNotify message. Does not implicitly {@link AgentBackEnd.ServerRetireNotify.verify|verify} messages.
     * @function encode
     * @memberof AgentBackEnd.ServerRetireNotify
     * @static
     * @param {AgentBackEnd.IServerRetireNotify} message ServerRetireNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ServerRetireNotify.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.status != null && Object.hasOwnProperty.call(message, "status"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.status);
      if (message.isforce != null && Object.hasOwnProperty.call(message, "isforce"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.isforce);
      return writer;
    };

    /**
     * Encodes the specified ServerRetireNotify message, length delimited. Does not implicitly {@link AgentBackEnd.ServerRetireNotify.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AgentBackEnd.ServerRetireNotify
     * @static
     * @param {AgentBackEnd.IServerRetireNotify} message ServerRetireNotify message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ServerRetireNotify.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ServerRetireNotify message from the specified reader or buffer.
     * @function decode
     * @memberof AgentBackEnd.ServerRetireNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AgentBackEnd.ServerRetireNotify} ServerRetireNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ServerRetireNotify.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.AgentBackEnd.ServerRetireNotify();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.status = reader.int32();
            break;
          }
          case 2: {
            message.isforce = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ServerRetireNotify message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AgentBackEnd.ServerRetireNotify
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AgentBackEnd.ServerRetireNotify} ServerRetireNotify
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ServerRetireNotify.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ServerRetireNotify message.
     * @function verify
     * @memberof AgentBackEnd.ServerRetireNotify
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ServerRetireNotify.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.status != null && message.hasOwnProperty("status"))
        if (!$util.isInteger(message.status)) return "status: integer expected";
      if (message.isforce != null && message.hasOwnProperty("isforce"))
        if (!$util.isInteger(message.isforce)) return "isforce: integer expected";
      return null;
    };

    /**
     * Creates a ServerRetireNotify message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AgentBackEnd.ServerRetireNotify
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AgentBackEnd.ServerRetireNotify} ServerRetireNotify
     */
    ServerRetireNotify.fromObject = function fromObject(object) {
      if (object instanceof $root.AgentBackEnd.ServerRetireNotify) return object;
      var message = new $root.AgentBackEnd.ServerRetireNotify();
      if (object.status != null) message.status = object.status | 0;
      if (object.isforce != null) message.isforce = object.isforce | 0;
      return message;
    };

    /**
     * Creates a plain object from a ServerRetireNotify message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AgentBackEnd.ServerRetireNotify
     * @static
     * @param {AgentBackEnd.ServerRetireNotify} message ServerRetireNotify
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ServerRetireNotify.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.status = 0;
        object.isforce = 0;
      }
      if (message.status != null && message.hasOwnProperty("status")) object.status = message.status;
      if (message.isforce != null && message.hasOwnProperty("isforce")) object.isforce = message.isforce;
      return object;
    };

    /**
     * Converts this ServerRetireNotify to JSON.
     * @function toJSON
     * @memberof AgentBackEnd.ServerRetireNotify
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ServerRetireNotify.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ServerRetireNotify
     * @function getTypeUrl
     * @memberof AgentBackEnd.ServerRetireNotify
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ServerRetireNotify.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/AgentBackEnd.ServerRetireNotify";
    };

    return ServerRetireNotify;
  })();

  AgentBackEnd.PhpReportKolReq = (function () {
    /**
     * Properties of a PhpReportKolReq.
     * @memberof AgentBackEnd
     * @interface IPhpReportKolReq
     * @property {string|null} [eventType] PhpReportKolReq eventType
     * @property {string|null} [pkg] PhpReportKolReq pkg
     * @property {string|null} [channel] PhpReportKolReq channel
     * @property {string|null} [aid] PhpReportKolReq aid
     * @property {string|null} [idfa] PhpReportKolReq idfa
     * @property {string|null} [gaid] PhpReportKolReq gaid
     * @property {string|null} [uid] PhpReportKolReq uid
     * @property {string|null} [device_id] PhpReportKolReq device_id
     * @property {string|null} [amount] PhpReportKolReq amount
     * @property {string|null} [total_amount] PhpReportKolReq total_amount
     * @property {string|null} [order_id] PhpReportKolReq order_id
     */

    /**
     * Constructs a new PhpReportKolReq.
     * @memberof AgentBackEnd
     * @classdesc Represents a PhpReportKolReq.
     * @implements IPhpReportKolReq
     * @constructor
     * @param {AgentBackEnd.IPhpReportKolReq=} [properties] Properties to set
     */
    function PhpReportKolReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * PhpReportKolReq eventType.
     * @member {string} eventType
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.eventType = "";

    /**
     * PhpReportKolReq pkg.
     * @member {string} pkg
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.pkg = "";

    /**
     * PhpReportKolReq channel.
     * @member {string} channel
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.channel = "";

    /**
     * PhpReportKolReq aid.
     * @member {string} aid
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.aid = "";

    /**
     * PhpReportKolReq idfa.
     * @member {string} idfa
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.idfa = "";

    /**
     * PhpReportKolReq gaid.
     * @member {string} gaid
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.gaid = "";

    /**
     * PhpReportKolReq uid.
     * @member {string} uid
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.uid = "";

    /**
     * PhpReportKolReq device_id.
     * @member {string} device_id
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.device_id = "";

    /**
     * PhpReportKolReq amount.
     * @member {string} amount
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.amount = "";

    /**
     * PhpReportKolReq total_amount.
     * @member {string} total_amount
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.total_amount = "";

    /**
     * PhpReportKolReq order_id.
     * @member {string} order_id
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     */
    PhpReportKolReq.prototype.order_id = "";

    /**
     * Creates a new PhpReportKolReq instance using the specified properties.
     * @function create
     * @memberof AgentBackEnd.PhpReportKolReq
     * @static
     * @param {AgentBackEnd.IPhpReportKolReq=} [properties] Properties to set
     * @returns {AgentBackEnd.PhpReportKolReq} PhpReportKolReq instance
     */
    PhpReportKolReq.create = function create(properties) {
      return new PhpReportKolReq(properties);
    };

    /**
     * Encodes the specified PhpReportKolReq message. Does not implicitly {@link AgentBackEnd.PhpReportKolReq.verify|verify} messages.
     * @function encode
     * @memberof AgentBackEnd.PhpReportKolReq
     * @static
     * @param {AgentBackEnd.IPhpReportKolReq} message PhpReportKolReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpReportKolReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.eventType != null && Object.hasOwnProperty.call(message, "eventType"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.eventType);
      if (message.pkg != null && Object.hasOwnProperty.call(message, "pkg"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.pkg);
      if (message.channel != null && Object.hasOwnProperty.call(message, "channel"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.channel);
      if (message.aid != null && Object.hasOwnProperty.call(message, "aid"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.aid);
      if (message.idfa != null && Object.hasOwnProperty.call(message, "idfa"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.idfa);
      if (message.gaid != null && Object.hasOwnProperty.call(message, "gaid"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.gaid);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.uid);
      if (message.device_id != null && Object.hasOwnProperty.call(message, "device_id"))
        writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.device_id);
      if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.amount);
      if (message.total_amount != null && Object.hasOwnProperty.call(message, "total_amount"))
        writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.total_amount);
      if (message.order_id != null && Object.hasOwnProperty.call(message, "order_id"))
        writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.order_id);
      return writer;
    };

    /**
     * Encodes the specified PhpReportKolReq message, length delimited. Does not implicitly {@link AgentBackEnd.PhpReportKolReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AgentBackEnd.PhpReportKolReq
     * @static
     * @param {AgentBackEnd.IPhpReportKolReq} message PhpReportKolReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PhpReportKolReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a PhpReportKolReq message from the specified reader or buffer.
     * @function decode
     * @memberof AgentBackEnd.PhpReportKolReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AgentBackEnd.PhpReportKolReq} PhpReportKolReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpReportKolReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.AgentBackEnd.PhpReportKolReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.eventType = reader.string();
            break;
          }
          case 2: {
            message.pkg = reader.string();
            break;
          }
          case 3: {
            message.channel = reader.string();
            break;
          }
          case 4: {
            message.aid = reader.string();
            break;
          }
          case 5: {
            message.idfa = reader.string();
            break;
          }
          case 6: {
            message.gaid = reader.string();
            break;
          }
          case 7: {
            message.uid = reader.string();
            break;
          }
          case 8: {
            message.device_id = reader.string();
            break;
          }
          case 9: {
            message.amount = reader.string();
            break;
          }
          case 10: {
            message.total_amount = reader.string();
            break;
          }
          case 11: {
            message.order_id = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a PhpReportKolReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AgentBackEnd.PhpReportKolReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AgentBackEnd.PhpReportKolReq} PhpReportKolReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PhpReportKolReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a PhpReportKolReq message.
     * @function verify
     * @memberof AgentBackEnd.PhpReportKolReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    PhpReportKolReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.eventType != null && message.hasOwnProperty("eventType"))
        if (!$util.isString(message.eventType)) return "eventType: string expected";
      if (message.pkg != null && message.hasOwnProperty("pkg"))
        if (!$util.isString(message.pkg)) return "pkg: string expected";
      if (message.channel != null && message.hasOwnProperty("channel"))
        if (!$util.isString(message.channel)) return "channel: string expected";
      if (message.aid != null && message.hasOwnProperty("aid"))
        if (!$util.isString(message.aid)) return "aid: string expected";
      if (message.idfa != null && message.hasOwnProperty("idfa"))
        if (!$util.isString(message.idfa)) return "idfa: string expected";
      if (message.gaid != null && message.hasOwnProperty("gaid"))
        if (!$util.isString(message.gaid)) return "gaid: string expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isString(message.uid)) return "uid: string expected";
      if (message.device_id != null && message.hasOwnProperty("device_id"))
        if (!$util.isString(message.device_id)) return "device_id: string expected";
      if (message.amount != null && message.hasOwnProperty("amount"))
        if (!$util.isString(message.amount)) return "amount: string expected";
      if (message.total_amount != null && message.hasOwnProperty("total_amount"))
        if (!$util.isString(message.total_amount)) return "total_amount: string expected";
      if (message.order_id != null && message.hasOwnProperty("order_id"))
        if (!$util.isString(message.order_id)) return "order_id: string expected";
      return null;
    };

    /**
     * Creates a PhpReportKolReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AgentBackEnd.PhpReportKolReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AgentBackEnd.PhpReportKolReq} PhpReportKolReq
     */
    PhpReportKolReq.fromObject = function fromObject(object) {
      if (object instanceof $root.AgentBackEnd.PhpReportKolReq) return object;
      var message = new $root.AgentBackEnd.PhpReportKolReq();
      if (object.eventType != null) message.eventType = String(object.eventType);
      if (object.pkg != null) message.pkg = String(object.pkg);
      if (object.channel != null) message.channel = String(object.channel);
      if (object.aid != null) message.aid = String(object.aid);
      if (object.idfa != null) message.idfa = String(object.idfa);
      if (object.gaid != null) message.gaid = String(object.gaid);
      if (object.uid != null) message.uid = String(object.uid);
      if (object.device_id != null) message.device_id = String(object.device_id);
      if (object.amount != null) message.amount = String(object.amount);
      if (object.total_amount != null) message.total_amount = String(object.total_amount);
      if (object.order_id != null) message.order_id = String(object.order_id);
      return message;
    };

    /**
     * Creates a plain object from a PhpReportKolReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AgentBackEnd.PhpReportKolReq
     * @static
     * @param {AgentBackEnd.PhpReportKolReq} message PhpReportKolReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    PhpReportKolReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.eventType = "";
        object.pkg = "";
        object.channel = "";
        object.aid = "";
        object.idfa = "";
        object.gaid = "";
        object.uid = "";
        object.device_id = "";
        object.amount = "";
        object.total_amount = "";
        object.order_id = "";
      }
      if (message.eventType != null && message.hasOwnProperty("eventType")) object.eventType = message.eventType;
      if (message.pkg != null && message.hasOwnProperty("pkg")) object.pkg = message.pkg;
      if (message.channel != null && message.hasOwnProperty("channel")) object.channel = message.channel;
      if (message.aid != null && message.hasOwnProperty("aid")) object.aid = message.aid;
      if (message.idfa != null && message.hasOwnProperty("idfa")) object.idfa = message.idfa;
      if (message.gaid != null && message.hasOwnProperty("gaid")) object.gaid = message.gaid;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.device_id != null && message.hasOwnProperty("device_id")) object.device_id = message.device_id;
      if (message.amount != null && message.hasOwnProperty("amount")) object.amount = message.amount;
      if (message.total_amount != null && message.hasOwnProperty("total_amount"))
        object.total_amount = message.total_amount;
      if (message.order_id != null && message.hasOwnProperty("order_id")) object.order_id = message.order_id;
      return object;
    };

    /**
     * Converts this PhpReportKolReq to JSON.
     * @function toJSON
     * @memberof AgentBackEnd.PhpReportKolReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    PhpReportKolReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for PhpReportKolReq
     * @function getTypeUrl
     * @memberof AgentBackEnd.PhpReportKolReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    PhpReportKolReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/AgentBackEnd.PhpReportKolReq";
    };

    return PhpReportKolReq;
  })();

  return AgentBackEnd;
})();

$root.game_manage_proto = (function () {
  /**
   * Namespace game_manage_proto.
   * @exports game_manage_proto
   * @namespace
   */
  var game_manage_proto = {};

  /**
   * GAME_MANAGE_SUB_MSG_ID enum.
   * @name game_manage_proto.GAME_MANAGE_SUB_MSG_ID
   * @enum {number}
   * @property {number} GAME_MANAGE_MSG_ID_NULL=0 GAME_MANAGE_MSG_ID_NULL value
   * @property {number} GAME_MANAGE_MSG_ID_MIN=1900 GAME_MANAGE_MSG_ID_MIN value
   * @property {number} GAME_MANAGE_GAME_HALL_DETAIL_REQ=1901 GAME_MANAGE_GAME_HALL_DETAIL_REQ value
   * @property {number} GAME_MANAGE_GAME_HALL_DETAIL_RESP=1902 GAME_MANAGE_GAME_HALL_DETAIL_RESP value
   * @property {number} GAME_MANAGE_MULTI_LANG_GAME_GROUP_REQ=1903 GAME_MANAGE_MULTI_LANG_GAME_GROUP_REQ value
   * @property {number} GAME_MANAGE_MULTI_LANG_GAME_GROUP_RESP=1904 GAME_MANAGE_MULTI_LANG_GAME_GROUP_RESP value
   * @property {number} GAME_MANAGE_GAME_GROUP_DETAIL_REQ=1905 GAME_MANAGE_GAME_GROUP_DETAIL_REQ value
   * @property {number} GAME_MANAGE_GAME_GROUP_DETAIL_RESP=1906 GAME_MANAGE_GAME_GROUP_DETAIL_RESP value
   * @property {number} GAME_MANAGE_MULTI_LANG_GAME_GROUP_V1_REQ=1907 GAME_MANAGE_MULTI_LANG_GAME_GROUP_V1_REQ value
   * @property {number} GAME_MANAGE_MULTI_LANG_GAME_GROUP_V1_RESP=1908 GAME_MANAGE_MULTI_LANG_GAME_GROUP_V1_RESP value
   * @property {number} GAME_MANAGE_GAME_GROUP_DETAIL_V1_REQ=1909 GAME_MANAGE_GAME_GROUP_DETAIL_V1_REQ value
   * @property {number} GAME_MANAGE_GAME_GROUP_DETAIL_V1_RESP=1910 GAME_MANAGE_GAME_GROUP_DETAIL_V1_RESP value
   * @property {number} GAME_MANAGE_GAME_GROUP_DETAIL_V2_REQ=1911 GAME_MANAGE_GAME_GROUP_DETAIL_V2_REQ value
   * @property {number} GAME_MANAGE_GAME_GROUP_DETAIL_V2_RESP=1912 GAME_MANAGE_GAME_GROUP_DETAIL_V2_RESP value
   * @property {number} GAME_MANAGE_GAME_DETAIL_REQ=1913 GAME_MANAGE_GAME_DETAIL_REQ value
   * @property {number} GAME_MANAGE_GAME_DETAIL_RESP=1914 GAME_MANAGE_GAME_DETAIL_RESP value
   * @property {number} GAME_MANAGE_MSG_ID_MAX=1950 GAME_MANAGE_MSG_ID_MAX value
   */
  game_manage_proto.GAME_MANAGE_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "GAME_MANAGE_MSG_ID_NULL")] = 0;
    values[(valuesById[1900] = "GAME_MANAGE_MSG_ID_MIN")] = 1900;
    values[(valuesById[1901] = "GAME_MANAGE_GAME_HALL_DETAIL_REQ")] = 1901;
    values[(valuesById[1902] = "GAME_MANAGE_GAME_HALL_DETAIL_RESP")] = 1902;
    values[(valuesById[1903] = "GAME_MANAGE_MULTI_LANG_GAME_GROUP_REQ")] = 1903;
    values[(valuesById[1904] = "GAME_MANAGE_MULTI_LANG_GAME_GROUP_RESP")] = 1904;
    values[(valuesById[1905] = "GAME_MANAGE_GAME_GROUP_DETAIL_REQ")] = 1905;
    values[(valuesById[1906] = "GAME_MANAGE_GAME_GROUP_DETAIL_RESP")] = 1906;
    values[(valuesById[1907] = "GAME_MANAGE_MULTI_LANG_GAME_GROUP_V1_REQ")] = 1907;
    values[(valuesById[1908] = "GAME_MANAGE_MULTI_LANG_GAME_GROUP_V1_RESP")] = 1908;
    values[(valuesById[1909] = "GAME_MANAGE_GAME_GROUP_DETAIL_V1_REQ")] = 1909;
    values[(valuesById[1910] = "GAME_MANAGE_GAME_GROUP_DETAIL_V1_RESP")] = 1910;
    values[(valuesById[1911] = "GAME_MANAGE_GAME_GROUP_DETAIL_V2_REQ")] = 1911;
    values[(valuesById[1912] = "GAME_MANAGE_GAME_GROUP_DETAIL_V2_RESP")] = 1912;
    values[(valuesById[1913] = "GAME_MANAGE_GAME_DETAIL_REQ")] = 1913;
    values[(valuesById[1914] = "GAME_MANAGE_GAME_DETAIL_RESP")] = 1914;
    values[(valuesById[1950] = "GAME_MANAGE_MSG_ID_MAX")] = 1950;
    return values;
  })();

  game_manage_proto.GameHallDetailReq = (function () {
    /**
     * Properties of a GameHallDetailReq.
     * @memberof game_manage_proto
     * @interface IGameHallDetailReq
     * @property {number|null} [uid] GameHallDetailReq uid
     */

    /**
     * Constructs a new GameHallDetailReq.
     * @memberof game_manage_proto
     * @classdesc Represents a GameHallDetailReq.
     * @implements IGameHallDetailReq
     * @constructor
     * @param {game_manage_proto.IGameHallDetailReq=} [properties] Properties to set
     */
    function GameHallDetailReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameHallDetailReq uid.
     * @member {number} uid
     * @memberof game_manage_proto.GameHallDetailReq
     * @instance
     */
    GameHallDetailReq.prototype.uid = 0;

    /**
     * Creates a new GameHallDetailReq instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.GameHallDetailReq
     * @static
     * @param {game_manage_proto.IGameHallDetailReq=} [properties] Properties to set
     * @returns {game_manage_proto.GameHallDetailReq} GameHallDetailReq instance
     */
    GameHallDetailReq.create = function create(properties) {
      return new GameHallDetailReq(properties);
    };

    /**
     * Encodes the specified GameHallDetailReq message. Does not implicitly {@link game_manage_proto.GameHallDetailReq.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.GameHallDetailReq
     * @static
     * @param {game_manage_proto.IGameHallDetailReq} message GameHallDetailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameHallDetailReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified GameHallDetailReq message, length delimited. Does not implicitly {@link game_manage_proto.GameHallDetailReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.GameHallDetailReq
     * @static
     * @param {game_manage_proto.IGameHallDetailReq} message GameHallDetailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameHallDetailReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameHallDetailReq message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.GameHallDetailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.GameHallDetailReq} GameHallDetailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameHallDetailReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.GameHallDetailReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameHallDetailReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.GameHallDetailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.GameHallDetailReq} GameHallDetailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameHallDetailReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameHallDetailReq message.
     * @function verify
     * @memberof game_manage_proto.GameHallDetailReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameHallDetailReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates a GameHallDetailReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.GameHallDetailReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.GameHallDetailReq} GameHallDetailReq
     */
    GameHallDetailReq.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.GameHallDetailReq) return object;
      var message = new $root.game_manage_proto.GameHallDetailReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a GameHallDetailReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.GameHallDetailReq
     * @static
     * @param {game_manage_proto.GameHallDetailReq} message GameHallDetailReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameHallDetailReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this GameHallDetailReq to JSON.
     * @function toJSON
     * @memberof game_manage_proto.GameHallDetailReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameHallDetailReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameHallDetailReq
     * @function getTypeUrl
     * @memberof game_manage_proto.GameHallDetailReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameHallDetailReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.GameHallDetailReq";
    };

    return GameHallDetailReq;
  })();

  game_manage_proto.GameHallDetailResp = (function () {
    /**
     * Properties of a GameHallDetailResp.
     * @memberof game_manage_proto
     * @interface IGameHallDetailResp
     * @property {string|null} [data] GameHallDetailResp data
     */

    /**
     * Constructs a new GameHallDetailResp.
     * @memberof game_manage_proto
     * @classdesc Represents a GameHallDetailResp.
     * @implements IGameHallDetailResp
     * @constructor
     * @param {game_manage_proto.IGameHallDetailResp=} [properties] Properties to set
     */
    function GameHallDetailResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameHallDetailResp data.
     * @member {string} data
     * @memberof game_manage_proto.GameHallDetailResp
     * @instance
     */
    GameHallDetailResp.prototype.data = "";

    /**
     * Creates a new GameHallDetailResp instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.GameHallDetailResp
     * @static
     * @param {game_manage_proto.IGameHallDetailResp=} [properties] Properties to set
     * @returns {game_manage_proto.GameHallDetailResp} GameHallDetailResp instance
     */
    GameHallDetailResp.create = function create(properties) {
      return new GameHallDetailResp(properties);
    };

    /**
     * Encodes the specified GameHallDetailResp message. Does not implicitly {@link game_manage_proto.GameHallDetailResp.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.GameHallDetailResp
     * @static
     * @param {game_manage_proto.IGameHallDetailResp} message GameHallDetailResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameHallDetailResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data != null && Object.hasOwnProperty.call(message, "data"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.data);
      return writer;
    };

    /**
     * Encodes the specified GameHallDetailResp message, length delimited. Does not implicitly {@link game_manage_proto.GameHallDetailResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.GameHallDetailResp
     * @static
     * @param {game_manage_proto.IGameHallDetailResp} message GameHallDetailResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameHallDetailResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameHallDetailResp message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.GameHallDetailResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.GameHallDetailResp} GameHallDetailResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameHallDetailResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.GameHallDetailResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.data = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameHallDetailResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.GameHallDetailResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.GameHallDetailResp} GameHallDetailResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameHallDetailResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameHallDetailResp message.
     * @function verify
     * @memberof game_manage_proto.GameHallDetailResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameHallDetailResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data != null && message.hasOwnProperty("data"))
        if (!$util.isString(message.data)) return "data: string expected";
      return null;
    };

    /**
     * Creates a GameHallDetailResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.GameHallDetailResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.GameHallDetailResp} GameHallDetailResp
     */
    GameHallDetailResp.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.GameHallDetailResp) return object;
      var message = new $root.game_manage_proto.GameHallDetailResp();
      if (object.data != null) message.data = String(object.data);
      return message;
    };

    /**
     * Creates a plain object from a GameHallDetailResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.GameHallDetailResp
     * @static
     * @param {game_manage_proto.GameHallDetailResp} message GameHallDetailResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameHallDetailResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.data = "";
      if (message.data != null && message.hasOwnProperty("data")) object.data = message.data;
      return object;
    };

    /**
     * Converts this GameHallDetailResp to JSON.
     * @function toJSON
     * @memberof game_manage_proto.GameHallDetailResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameHallDetailResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameHallDetailResp
     * @function getTypeUrl
     * @memberof game_manage_proto.GameHallDetailResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameHallDetailResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.GameHallDetailResp";
    };

    return GameHallDetailResp;
  })();

  game_manage_proto.MultiLanguageGameGroupReq = (function () {
    /**
     * Properties of a MultiLanguageGameGroupReq.
     * @memberof game_manage_proto
     * @interface IMultiLanguageGameGroupReq
     * @property {number|null} [uid] MultiLanguageGameGroupReq uid
     * @property {string|null} [lang] MultiLanguageGameGroupReq lang
     */

    /**
     * Constructs a new MultiLanguageGameGroupReq.
     * @memberof game_manage_proto
     * @classdesc Represents a MultiLanguageGameGroupReq.
     * @implements IMultiLanguageGameGroupReq
     * @constructor
     * @param {game_manage_proto.IMultiLanguageGameGroupReq=} [properties] Properties to set
     */
    function MultiLanguageGameGroupReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MultiLanguageGameGroupReq uid.
     * @member {number} uid
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @instance
     */
    MultiLanguageGameGroupReq.prototype.uid = 0;

    /**
     * MultiLanguageGameGroupReq lang.
     * @member {string} lang
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @instance
     */
    MultiLanguageGameGroupReq.prototype.lang = "";

    /**
     * Creates a new MultiLanguageGameGroupReq instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupReq=} [properties] Properties to set
     * @returns {game_manage_proto.MultiLanguageGameGroupReq} MultiLanguageGameGroupReq instance
     */
    MultiLanguageGameGroupReq.create = function create(properties) {
      return new MultiLanguageGameGroupReq(properties);
    };

    /**
     * Encodes the specified MultiLanguageGameGroupReq message. Does not implicitly {@link game_manage_proto.MultiLanguageGameGroupReq.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupReq} message MultiLanguageGameGroupReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiLanguageGameGroupReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.lang != null && Object.hasOwnProperty.call(message, "lang"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.lang);
      return writer;
    };

    /**
     * Encodes the specified MultiLanguageGameGroupReq message, length delimited. Does not implicitly {@link game_manage_proto.MultiLanguageGameGroupReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupReq} message MultiLanguageGameGroupReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiLanguageGameGroupReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MultiLanguageGameGroupReq message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.MultiLanguageGameGroupReq} MultiLanguageGameGroupReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiLanguageGameGroupReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.MultiLanguageGameGroupReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.lang = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MultiLanguageGameGroupReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.MultiLanguageGameGroupReq} MultiLanguageGameGroupReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiLanguageGameGroupReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MultiLanguageGameGroupReq message.
     * @function verify
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MultiLanguageGameGroupReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.lang != null && message.hasOwnProperty("lang"))
        if (!$util.isString(message.lang)) return "lang: string expected";
      return null;
    };

    /**
     * Creates a MultiLanguageGameGroupReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.MultiLanguageGameGroupReq} MultiLanguageGameGroupReq
     */
    MultiLanguageGameGroupReq.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.MultiLanguageGameGroupReq) return object;
      var message = new $root.game_manage_proto.MultiLanguageGameGroupReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.lang != null) message.lang = String(object.lang);
      return message;
    };

    /**
     * Creates a plain object from a MultiLanguageGameGroupReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @static
     * @param {game_manage_proto.MultiLanguageGameGroupReq} message MultiLanguageGameGroupReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MultiLanguageGameGroupReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.lang = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.lang != null && message.hasOwnProperty("lang")) object.lang = message.lang;
      return object;
    };

    /**
     * Converts this MultiLanguageGameGroupReq to JSON.
     * @function toJSON
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MultiLanguageGameGroupReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MultiLanguageGameGroupReq
     * @function getTypeUrl
     * @memberof game_manage_proto.MultiLanguageGameGroupReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MultiLanguageGameGroupReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.MultiLanguageGameGroupReq";
    };

    return MultiLanguageGameGroupReq;
  })();

  game_manage_proto.MultiLanguageGameGroupResp = (function () {
    /**
     * Properties of a MultiLanguageGameGroupResp.
     * @memberof game_manage_proto
     * @interface IMultiLanguageGameGroupResp
     * @property {string|null} [data] MultiLanguageGameGroupResp data
     */

    /**
     * Constructs a new MultiLanguageGameGroupResp.
     * @memberof game_manage_proto
     * @classdesc Represents a MultiLanguageGameGroupResp.
     * @implements IMultiLanguageGameGroupResp
     * @constructor
     * @param {game_manage_proto.IMultiLanguageGameGroupResp=} [properties] Properties to set
     */
    function MultiLanguageGameGroupResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MultiLanguageGameGroupResp data.
     * @member {string} data
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @instance
     */
    MultiLanguageGameGroupResp.prototype.data = "";

    /**
     * Creates a new MultiLanguageGameGroupResp instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupResp=} [properties] Properties to set
     * @returns {game_manage_proto.MultiLanguageGameGroupResp} MultiLanguageGameGroupResp instance
     */
    MultiLanguageGameGroupResp.create = function create(properties) {
      return new MultiLanguageGameGroupResp(properties);
    };

    /**
     * Encodes the specified MultiLanguageGameGroupResp message. Does not implicitly {@link game_manage_proto.MultiLanguageGameGroupResp.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupResp} message MultiLanguageGameGroupResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiLanguageGameGroupResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data != null && Object.hasOwnProperty.call(message, "data"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.data);
      return writer;
    };

    /**
     * Encodes the specified MultiLanguageGameGroupResp message, length delimited. Does not implicitly {@link game_manage_proto.MultiLanguageGameGroupResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupResp} message MultiLanguageGameGroupResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiLanguageGameGroupResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MultiLanguageGameGroupResp message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.MultiLanguageGameGroupResp} MultiLanguageGameGroupResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiLanguageGameGroupResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.MultiLanguageGameGroupResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.data = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MultiLanguageGameGroupResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.MultiLanguageGameGroupResp} MultiLanguageGameGroupResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiLanguageGameGroupResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MultiLanguageGameGroupResp message.
     * @function verify
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MultiLanguageGameGroupResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data != null && message.hasOwnProperty("data"))
        if (!$util.isString(message.data)) return "data: string expected";
      return null;
    };

    /**
     * Creates a MultiLanguageGameGroupResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.MultiLanguageGameGroupResp} MultiLanguageGameGroupResp
     */
    MultiLanguageGameGroupResp.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.MultiLanguageGameGroupResp) return object;
      var message = new $root.game_manage_proto.MultiLanguageGameGroupResp();
      if (object.data != null) message.data = String(object.data);
      return message;
    };

    /**
     * Creates a plain object from a MultiLanguageGameGroupResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @static
     * @param {game_manage_proto.MultiLanguageGameGroupResp} message MultiLanguageGameGroupResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MultiLanguageGameGroupResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.data = "";
      if (message.data != null && message.hasOwnProperty("data")) object.data = message.data;
      return object;
    };

    /**
     * Converts this MultiLanguageGameGroupResp to JSON.
     * @function toJSON
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MultiLanguageGameGroupResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MultiLanguageGameGroupResp
     * @function getTypeUrl
     * @memberof game_manage_proto.MultiLanguageGameGroupResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MultiLanguageGameGroupResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.MultiLanguageGameGroupResp";
    };

    return MultiLanguageGameGroupResp;
  })();

  game_manage_proto.GameGroupDetailReq = (function () {
    /**
     * Properties of a GameGroupDetailReq.
     * @memberof game_manage_proto
     * @interface IGameGroupDetailReq
     * @property {number|null} [uid] GameGroupDetailReq uid
     * @property {number|null} [game_group_id] GameGroupDetailReq game_group_id
     * @property {number|null} [page] GameGroupDetailReq page
     * @property {number|null} [page_size] GameGroupDetailReq page_size
     */

    /**
     * Constructs a new GameGroupDetailReq.
     * @memberof game_manage_proto
     * @classdesc Represents a GameGroupDetailReq.
     * @implements IGameGroupDetailReq
     * @constructor
     * @param {game_manage_proto.IGameGroupDetailReq=} [properties] Properties to set
     */
    function GameGroupDetailReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameGroupDetailReq uid.
     * @member {number} uid
     * @memberof game_manage_proto.GameGroupDetailReq
     * @instance
     */
    GameGroupDetailReq.prototype.uid = 0;

    /**
     * GameGroupDetailReq game_group_id.
     * @member {number} game_group_id
     * @memberof game_manage_proto.GameGroupDetailReq
     * @instance
     */
    GameGroupDetailReq.prototype.game_group_id = 0;

    /**
     * GameGroupDetailReq page.
     * @member {number} page
     * @memberof game_manage_proto.GameGroupDetailReq
     * @instance
     */
    GameGroupDetailReq.prototype.page = 0;

    /**
     * GameGroupDetailReq page_size.
     * @member {number} page_size
     * @memberof game_manage_proto.GameGroupDetailReq
     * @instance
     */
    GameGroupDetailReq.prototype.page_size = 0;

    /**
     * Creates a new GameGroupDetailReq instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.GameGroupDetailReq
     * @static
     * @param {game_manage_proto.IGameGroupDetailReq=} [properties] Properties to set
     * @returns {game_manage_proto.GameGroupDetailReq} GameGroupDetailReq instance
     */
    GameGroupDetailReq.create = function create(properties) {
      return new GameGroupDetailReq(properties);
    };

    /**
     * Encodes the specified GameGroupDetailReq message. Does not implicitly {@link game_manage_proto.GameGroupDetailReq.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.GameGroupDetailReq
     * @static
     * @param {game_manage_proto.IGameGroupDetailReq} message GameGroupDetailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.game_group_id != null && Object.hasOwnProperty.call(message, "game_group_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.game_group_id);
      if (message.page != null && Object.hasOwnProperty.call(message, "page"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.page);
      if (message.page_size != null && Object.hasOwnProperty.call(message, "page_size"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.page_size);
      return writer;
    };

    /**
     * Encodes the specified GameGroupDetailReq message, length delimited. Does not implicitly {@link game_manage_proto.GameGroupDetailReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.GameGroupDetailReq
     * @static
     * @param {game_manage_proto.IGameGroupDetailReq} message GameGroupDetailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameGroupDetailReq message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.GameGroupDetailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.GameGroupDetailReq} GameGroupDetailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.GameGroupDetailReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.game_group_id = reader.uint32();
            break;
          }
          case 3: {
            message.page = reader.uint32();
            break;
          }
          case 4: {
            message.page_size = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameGroupDetailReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.GameGroupDetailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.GameGroupDetailReq} GameGroupDetailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameGroupDetailReq message.
     * @function verify
     * @memberof game_manage_proto.GameGroupDetailReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameGroupDetailReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.game_group_id != null && message.hasOwnProperty("game_group_id"))
        if (!$util.isInteger(message.game_group_id)) return "game_group_id: integer expected";
      if (message.page != null && message.hasOwnProperty("page"))
        if (!$util.isInteger(message.page)) return "page: integer expected";
      if (message.page_size != null && message.hasOwnProperty("page_size"))
        if (!$util.isInteger(message.page_size)) return "page_size: integer expected";
      return null;
    };

    /**
     * Creates a GameGroupDetailReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.GameGroupDetailReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.GameGroupDetailReq} GameGroupDetailReq
     */
    GameGroupDetailReq.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.GameGroupDetailReq) return object;
      var message = new $root.game_manage_proto.GameGroupDetailReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.game_group_id != null) message.game_group_id = object.game_group_id >>> 0;
      if (object.page != null) message.page = object.page >>> 0;
      if (object.page_size != null) message.page_size = object.page_size >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a GameGroupDetailReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.GameGroupDetailReq
     * @static
     * @param {game_manage_proto.GameGroupDetailReq} message GameGroupDetailReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameGroupDetailReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.game_group_id = 0;
        object.page = 0;
        object.page_size = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.game_group_id != null && message.hasOwnProperty("game_group_id"))
        object.game_group_id = message.game_group_id;
      if (message.page != null && message.hasOwnProperty("page")) object.page = message.page;
      if (message.page_size != null && message.hasOwnProperty("page_size")) object.page_size = message.page_size;
      return object;
    };

    /**
     * Converts this GameGroupDetailReq to JSON.
     * @function toJSON
     * @memberof game_manage_proto.GameGroupDetailReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameGroupDetailReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameGroupDetailReq
     * @function getTypeUrl
     * @memberof game_manage_proto.GameGroupDetailReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameGroupDetailReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.GameGroupDetailReq";
    };

    return GameGroupDetailReq;
  })();

  game_manage_proto.GameGroupDetailResp = (function () {
    /**
     * Properties of a GameGroupDetailResp.
     * @memberof game_manage_proto
     * @interface IGameGroupDetailResp
     * @property {string|null} [data] GameGroupDetailResp data
     * @property {number|null} [total_num] GameGroupDetailResp total_num
     */

    /**
     * Constructs a new GameGroupDetailResp.
     * @memberof game_manage_proto
     * @classdesc Represents a GameGroupDetailResp.
     * @implements IGameGroupDetailResp
     * @constructor
     * @param {game_manage_proto.IGameGroupDetailResp=} [properties] Properties to set
     */
    function GameGroupDetailResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameGroupDetailResp data.
     * @member {string} data
     * @memberof game_manage_proto.GameGroupDetailResp
     * @instance
     */
    GameGroupDetailResp.prototype.data = "";

    /**
     * GameGroupDetailResp total_num.
     * @member {number} total_num
     * @memberof game_manage_proto.GameGroupDetailResp
     * @instance
     */
    GameGroupDetailResp.prototype.total_num = 0;

    /**
     * Creates a new GameGroupDetailResp instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.GameGroupDetailResp
     * @static
     * @param {game_manage_proto.IGameGroupDetailResp=} [properties] Properties to set
     * @returns {game_manage_proto.GameGroupDetailResp} GameGroupDetailResp instance
     */
    GameGroupDetailResp.create = function create(properties) {
      return new GameGroupDetailResp(properties);
    };

    /**
     * Encodes the specified GameGroupDetailResp message. Does not implicitly {@link game_manage_proto.GameGroupDetailResp.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.GameGroupDetailResp
     * @static
     * @param {game_manage_proto.IGameGroupDetailResp} message GameGroupDetailResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data != null && Object.hasOwnProperty.call(message, "data"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.data);
      if (message.total_num != null && Object.hasOwnProperty.call(message, "total_num"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.total_num);
      return writer;
    };

    /**
     * Encodes the specified GameGroupDetailResp message, length delimited. Does not implicitly {@link game_manage_proto.GameGroupDetailResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.GameGroupDetailResp
     * @static
     * @param {game_manage_proto.IGameGroupDetailResp} message GameGroupDetailResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameGroupDetailResp message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.GameGroupDetailResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.GameGroupDetailResp} GameGroupDetailResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.GameGroupDetailResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.data = reader.string();
            break;
          }
          case 2: {
            message.total_num = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameGroupDetailResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.GameGroupDetailResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.GameGroupDetailResp} GameGroupDetailResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameGroupDetailResp message.
     * @function verify
     * @memberof game_manage_proto.GameGroupDetailResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameGroupDetailResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data != null && message.hasOwnProperty("data"))
        if (!$util.isString(message.data)) return "data: string expected";
      if (message.total_num != null && message.hasOwnProperty("total_num"))
        if (!$util.isInteger(message.total_num)) return "total_num: integer expected";
      return null;
    };

    /**
     * Creates a GameGroupDetailResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.GameGroupDetailResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.GameGroupDetailResp} GameGroupDetailResp
     */
    GameGroupDetailResp.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.GameGroupDetailResp) return object;
      var message = new $root.game_manage_proto.GameGroupDetailResp();
      if (object.data != null) message.data = String(object.data);
      if (object.total_num != null) message.total_num = object.total_num >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a GameGroupDetailResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.GameGroupDetailResp
     * @static
     * @param {game_manage_proto.GameGroupDetailResp} message GameGroupDetailResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameGroupDetailResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.data = "";
        object.total_num = 0;
      }
      if (message.data != null && message.hasOwnProperty("data")) object.data = message.data;
      if (message.total_num != null && message.hasOwnProperty("total_num")) object.total_num = message.total_num;
      return object;
    };

    /**
     * Converts this GameGroupDetailResp to JSON.
     * @function toJSON
     * @memberof game_manage_proto.GameGroupDetailResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameGroupDetailResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameGroupDetailResp
     * @function getTypeUrl
     * @memberof game_manage_proto.GameGroupDetailResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameGroupDetailResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.GameGroupDetailResp";
    };

    return GameGroupDetailResp;
  })();

  game_manage_proto.MultiLanguageGameGroupV1Req = (function () {
    /**
     * Properties of a MultiLanguageGameGroupV1Req.
     * @memberof game_manage_proto
     * @interface IMultiLanguageGameGroupV1Req
     * @property {number|null} [uid] MultiLanguageGameGroupV1Req uid
     * @property {string|null} [lang] MultiLanguageGameGroupV1Req lang
     * @property {number|null} [plat_type] MultiLanguageGameGroupV1Req plat_type
     */

    /**
     * Constructs a new MultiLanguageGameGroupV1Req.
     * @memberof game_manage_proto
     * @classdesc Represents a MultiLanguageGameGroupV1Req.
     * @implements IMultiLanguageGameGroupV1Req
     * @constructor
     * @param {game_manage_proto.IMultiLanguageGameGroupV1Req=} [properties] Properties to set
     */
    function MultiLanguageGameGroupV1Req(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MultiLanguageGameGroupV1Req uid.
     * @member {number} uid
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @instance
     */
    MultiLanguageGameGroupV1Req.prototype.uid = 0;

    /**
     * MultiLanguageGameGroupV1Req lang.
     * @member {string} lang
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @instance
     */
    MultiLanguageGameGroupV1Req.prototype.lang = "";

    /**
     * MultiLanguageGameGroupV1Req plat_type.
     * @member {number} plat_type
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @instance
     */
    MultiLanguageGameGroupV1Req.prototype.plat_type = 0;

    /**
     * Creates a new MultiLanguageGameGroupV1Req instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupV1Req=} [properties] Properties to set
     * @returns {game_manage_proto.MultiLanguageGameGroupV1Req} MultiLanguageGameGroupV1Req instance
     */
    MultiLanguageGameGroupV1Req.create = function create(properties) {
      return new MultiLanguageGameGroupV1Req(properties);
    };

    /**
     * Encodes the specified MultiLanguageGameGroupV1Req message. Does not implicitly {@link game_manage_proto.MultiLanguageGameGroupV1Req.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupV1Req} message MultiLanguageGameGroupV1Req message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiLanguageGameGroupV1Req.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.lang != null && Object.hasOwnProperty.call(message, "lang"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.lang);
      if (message.plat_type != null && Object.hasOwnProperty.call(message, "plat_type"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.plat_type);
      return writer;
    };

    /**
     * Encodes the specified MultiLanguageGameGroupV1Req message, length delimited. Does not implicitly {@link game_manage_proto.MultiLanguageGameGroupV1Req.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupV1Req} message MultiLanguageGameGroupV1Req message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiLanguageGameGroupV1Req.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MultiLanguageGameGroupV1Req message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.MultiLanguageGameGroupV1Req} MultiLanguageGameGroupV1Req
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiLanguageGameGroupV1Req.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.MultiLanguageGameGroupV1Req();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.lang = reader.string();
            break;
          }
          case 3: {
            message.plat_type = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MultiLanguageGameGroupV1Req message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.MultiLanguageGameGroupV1Req} MultiLanguageGameGroupV1Req
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiLanguageGameGroupV1Req.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MultiLanguageGameGroupV1Req message.
     * @function verify
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MultiLanguageGameGroupV1Req.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.lang != null && message.hasOwnProperty("lang"))
        if (!$util.isString(message.lang)) return "lang: string expected";
      if (message.plat_type != null && message.hasOwnProperty("plat_type"))
        if (!$util.isInteger(message.plat_type)) return "plat_type: integer expected";
      return null;
    };

    /**
     * Creates a MultiLanguageGameGroupV1Req message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.MultiLanguageGameGroupV1Req} MultiLanguageGameGroupV1Req
     */
    MultiLanguageGameGroupV1Req.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.MultiLanguageGameGroupV1Req) return object;
      var message = new $root.game_manage_proto.MultiLanguageGameGroupV1Req();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.lang != null) message.lang = String(object.lang);
      if (object.plat_type != null) message.plat_type = object.plat_type >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a MultiLanguageGameGroupV1Req message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @static
     * @param {game_manage_proto.MultiLanguageGameGroupV1Req} message MultiLanguageGameGroupV1Req
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MultiLanguageGameGroupV1Req.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.lang = "";
        object.plat_type = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.lang != null && message.hasOwnProperty("lang")) object.lang = message.lang;
      if (message.plat_type != null && message.hasOwnProperty("plat_type")) object.plat_type = message.plat_type;
      return object;
    };

    /**
     * Converts this MultiLanguageGameGroupV1Req to JSON.
     * @function toJSON
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MultiLanguageGameGroupV1Req.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MultiLanguageGameGroupV1Req
     * @function getTypeUrl
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Req
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MultiLanguageGameGroupV1Req.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.MultiLanguageGameGroupV1Req";
    };

    return MultiLanguageGameGroupV1Req;
  })();

  game_manage_proto.MultiLanguageGameGroupV1Resp = (function () {
    /**
     * Properties of a MultiLanguageGameGroupV1Resp.
     * @memberof game_manage_proto
     * @interface IMultiLanguageGameGroupV1Resp
     * @property {string|null} [data] MultiLanguageGameGroupV1Resp data
     */

    /**
     * Constructs a new MultiLanguageGameGroupV1Resp.
     * @memberof game_manage_proto
     * @classdesc Represents a MultiLanguageGameGroupV1Resp.
     * @implements IMultiLanguageGameGroupV1Resp
     * @constructor
     * @param {game_manage_proto.IMultiLanguageGameGroupV1Resp=} [properties] Properties to set
     */
    function MultiLanguageGameGroupV1Resp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MultiLanguageGameGroupV1Resp data.
     * @member {string} data
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @instance
     */
    MultiLanguageGameGroupV1Resp.prototype.data = "";

    /**
     * Creates a new MultiLanguageGameGroupV1Resp instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupV1Resp=} [properties] Properties to set
     * @returns {game_manage_proto.MultiLanguageGameGroupV1Resp} MultiLanguageGameGroupV1Resp instance
     */
    MultiLanguageGameGroupV1Resp.create = function create(properties) {
      return new MultiLanguageGameGroupV1Resp(properties);
    };

    /**
     * Encodes the specified MultiLanguageGameGroupV1Resp message. Does not implicitly {@link game_manage_proto.MultiLanguageGameGroupV1Resp.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupV1Resp} message MultiLanguageGameGroupV1Resp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiLanguageGameGroupV1Resp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data != null && Object.hasOwnProperty.call(message, "data"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.data);
      return writer;
    };

    /**
     * Encodes the specified MultiLanguageGameGroupV1Resp message, length delimited. Does not implicitly {@link game_manage_proto.MultiLanguageGameGroupV1Resp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @static
     * @param {game_manage_proto.IMultiLanguageGameGroupV1Resp} message MultiLanguageGameGroupV1Resp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MultiLanguageGameGroupV1Resp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MultiLanguageGameGroupV1Resp message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.MultiLanguageGameGroupV1Resp} MultiLanguageGameGroupV1Resp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiLanguageGameGroupV1Resp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.MultiLanguageGameGroupV1Resp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.data = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MultiLanguageGameGroupV1Resp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.MultiLanguageGameGroupV1Resp} MultiLanguageGameGroupV1Resp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MultiLanguageGameGroupV1Resp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MultiLanguageGameGroupV1Resp message.
     * @function verify
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MultiLanguageGameGroupV1Resp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data != null && message.hasOwnProperty("data"))
        if (!$util.isString(message.data)) return "data: string expected";
      return null;
    };

    /**
     * Creates a MultiLanguageGameGroupV1Resp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.MultiLanguageGameGroupV1Resp} MultiLanguageGameGroupV1Resp
     */
    MultiLanguageGameGroupV1Resp.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.MultiLanguageGameGroupV1Resp) return object;
      var message = new $root.game_manage_proto.MultiLanguageGameGroupV1Resp();
      if (object.data != null) message.data = String(object.data);
      return message;
    };

    /**
     * Creates a plain object from a MultiLanguageGameGroupV1Resp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @static
     * @param {game_manage_proto.MultiLanguageGameGroupV1Resp} message MultiLanguageGameGroupV1Resp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MultiLanguageGameGroupV1Resp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.data = "";
      if (message.data != null && message.hasOwnProperty("data")) object.data = message.data;
      return object;
    };

    /**
     * Converts this MultiLanguageGameGroupV1Resp to JSON.
     * @function toJSON
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MultiLanguageGameGroupV1Resp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MultiLanguageGameGroupV1Resp
     * @function getTypeUrl
     * @memberof game_manage_proto.MultiLanguageGameGroupV1Resp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MultiLanguageGameGroupV1Resp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.MultiLanguageGameGroupV1Resp";
    };

    return MultiLanguageGameGroupV1Resp;
  })();

  game_manage_proto.GameGroupDetailV1Req = (function () {
    /**
     * Properties of a GameGroupDetailV1Req.
     * @memberof game_manage_proto
     * @interface IGameGroupDetailV1Req
     * @property {number|null} [uid] GameGroupDetailV1Req uid
     * @property {number|null} [game_group_id] GameGroupDetailV1Req game_group_id
     */

    /**
     * Constructs a new GameGroupDetailV1Req.
     * @memberof game_manage_proto
     * @classdesc Represents a GameGroupDetailV1Req.
     * @implements IGameGroupDetailV1Req
     * @constructor
     * @param {game_manage_proto.IGameGroupDetailV1Req=} [properties] Properties to set
     */
    function GameGroupDetailV1Req(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameGroupDetailV1Req uid.
     * @member {number} uid
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @instance
     */
    GameGroupDetailV1Req.prototype.uid = 0;

    /**
     * GameGroupDetailV1Req game_group_id.
     * @member {number} game_group_id
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @instance
     */
    GameGroupDetailV1Req.prototype.game_group_id = 0;

    /**
     * Creates a new GameGroupDetailV1Req instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @static
     * @param {game_manage_proto.IGameGroupDetailV1Req=} [properties] Properties to set
     * @returns {game_manage_proto.GameGroupDetailV1Req} GameGroupDetailV1Req instance
     */
    GameGroupDetailV1Req.create = function create(properties) {
      return new GameGroupDetailV1Req(properties);
    };

    /**
     * Encodes the specified GameGroupDetailV1Req message. Does not implicitly {@link game_manage_proto.GameGroupDetailV1Req.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @static
     * @param {game_manage_proto.IGameGroupDetailV1Req} message GameGroupDetailV1Req message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailV1Req.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.game_group_id != null && Object.hasOwnProperty.call(message, "game_group_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.game_group_id);
      return writer;
    };

    /**
     * Encodes the specified GameGroupDetailV1Req message, length delimited. Does not implicitly {@link game_manage_proto.GameGroupDetailV1Req.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @static
     * @param {game_manage_proto.IGameGroupDetailV1Req} message GameGroupDetailV1Req message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailV1Req.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameGroupDetailV1Req message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.GameGroupDetailV1Req} GameGroupDetailV1Req
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailV1Req.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.GameGroupDetailV1Req();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.game_group_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameGroupDetailV1Req message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.GameGroupDetailV1Req} GameGroupDetailV1Req
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailV1Req.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameGroupDetailV1Req message.
     * @function verify
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameGroupDetailV1Req.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.game_group_id != null && message.hasOwnProperty("game_group_id"))
        if (!$util.isInteger(message.game_group_id)) return "game_group_id: integer expected";
      return null;
    };

    /**
     * Creates a GameGroupDetailV1Req message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.GameGroupDetailV1Req} GameGroupDetailV1Req
     */
    GameGroupDetailV1Req.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.GameGroupDetailV1Req) return object;
      var message = new $root.game_manage_proto.GameGroupDetailV1Req();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.game_group_id != null) message.game_group_id = object.game_group_id >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a GameGroupDetailV1Req message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @static
     * @param {game_manage_proto.GameGroupDetailV1Req} message GameGroupDetailV1Req
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameGroupDetailV1Req.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.game_group_id = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.game_group_id != null && message.hasOwnProperty("game_group_id"))
        object.game_group_id = message.game_group_id;
      return object;
    };

    /**
     * Converts this GameGroupDetailV1Req to JSON.
     * @function toJSON
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameGroupDetailV1Req.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameGroupDetailV1Req
     * @function getTypeUrl
     * @memberof game_manage_proto.GameGroupDetailV1Req
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameGroupDetailV1Req.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.GameGroupDetailV1Req";
    };

    return GameGroupDetailV1Req;
  })();

  game_manage_proto.GameGroupDetailV1Resp = (function () {
    /**
     * Properties of a GameGroupDetailV1Resp.
     * @memberof game_manage_proto
     * @interface IGameGroupDetailV1Resp
     * @property {string|null} [data] GameGroupDetailV1Resp data
     */

    /**
     * Constructs a new GameGroupDetailV1Resp.
     * @memberof game_manage_proto
     * @classdesc Represents a GameGroupDetailV1Resp.
     * @implements IGameGroupDetailV1Resp
     * @constructor
     * @param {game_manage_proto.IGameGroupDetailV1Resp=} [properties] Properties to set
     */
    function GameGroupDetailV1Resp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameGroupDetailV1Resp data.
     * @member {string} data
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @instance
     */
    GameGroupDetailV1Resp.prototype.data = "";

    /**
     * Creates a new GameGroupDetailV1Resp instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @static
     * @param {game_manage_proto.IGameGroupDetailV1Resp=} [properties] Properties to set
     * @returns {game_manage_proto.GameGroupDetailV1Resp} GameGroupDetailV1Resp instance
     */
    GameGroupDetailV1Resp.create = function create(properties) {
      return new GameGroupDetailV1Resp(properties);
    };

    /**
     * Encodes the specified GameGroupDetailV1Resp message. Does not implicitly {@link game_manage_proto.GameGroupDetailV1Resp.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @static
     * @param {game_manage_proto.IGameGroupDetailV1Resp} message GameGroupDetailV1Resp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailV1Resp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data != null && Object.hasOwnProperty.call(message, "data"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.data);
      return writer;
    };

    /**
     * Encodes the specified GameGroupDetailV1Resp message, length delimited. Does not implicitly {@link game_manage_proto.GameGroupDetailV1Resp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @static
     * @param {game_manage_proto.IGameGroupDetailV1Resp} message GameGroupDetailV1Resp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailV1Resp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameGroupDetailV1Resp message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.GameGroupDetailV1Resp} GameGroupDetailV1Resp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailV1Resp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.GameGroupDetailV1Resp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.data = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameGroupDetailV1Resp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.GameGroupDetailV1Resp} GameGroupDetailV1Resp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailV1Resp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameGroupDetailV1Resp message.
     * @function verify
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameGroupDetailV1Resp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data != null && message.hasOwnProperty("data"))
        if (!$util.isString(message.data)) return "data: string expected";
      return null;
    };

    /**
     * Creates a GameGroupDetailV1Resp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.GameGroupDetailV1Resp} GameGroupDetailV1Resp
     */
    GameGroupDetailV1Resp.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.GameGroupDetailV1Resp) return object;
      var message = new $root.game_manage_proto.GameGroupDetailV1Resp();
      if (object.data != null) message.data = String(object.data);
      return message;
    };

    /**
     * Creates a plain object from a GameGroupDetailV1Resp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @static
     * @param {game_manage_proto.GameGroupDetailV1Resp} message GameGroupDetailV1Resp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameGroupDetailV1Resp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.data = "";
      if (message.data != null && message.hasOwnProperty("data")) object.data = message.data;
      return object;
    };

    /**
     * Converts this GameGroupDetailV1Resp to JSON.
     * @function toJSON
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameGroupDetailV1Resp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameGroupDetailV1Resp
     * @function getTypeUrl
     * @memberof game_manage_proto.GameGroupDetailV1Resp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameGroupDetailV1Resp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.GameGroupDetailV1Resp";
    };

    return GameGroupDetailV1Resp;
  })();

  game_manage_proto.GameGroupDetailV2Req = (function () {
    /**
     * Properties of a GameGroupDetailV2Req.
     * @memberof game_manage_proto
     * @interface IGameGroupDetailV2Req
     * @property {number|null} [uid] GameGroupDetailV2Req uid
     * @property {number|null} [game_group_id] GameGroupDetailV2Req game_group_id
     */

    /**
     * Constructs a new GameGroupDetailV2Req.
     * @memberof game_manage_proto
     * @classdesc Represents a GameGroupDetailV2Req.
     * @implements IGameGroupDetailV2Req
     * @constructor
     * @param {game_manage_proto.IGameGroupDetailV2Req=} [properties] Properties to set
     */
    function GameGroupDetailV2Req(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameGroupDetailV2Req uid.
     * @member {number} uid
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @instance
     */
    GameGroupDetailV2Req.prototype.uid = 0;

    /**
     * GameGroupDetailV2Req game_group_id.
     * @member {number} game_group_id
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @instance
     */
    GameGroupDetailV2Req.prototype.game_group_id = 0;

    /**
     * Creates a new GameGroupDetailV2Req instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @static
     * @param {game_manage_proto.IGameGroupDetailV2Req=} [properties] Properties to set
     * @returns {game_manage_proto.GameGroupDetailV2Req} GameGroupDetailV2Req instance
     */
    GameGroupDetailV2Req.create = function create(properties) {
      return new GameGroupDetailV2Req(properties);
    };

    /**
     * Encodes the specified GameGroupDetailV2Req message. Does not implicitly {@link game_manage_proto.GameGroupDetailV2Req.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @static
     * @param {game_manage_proto.IGameGroupDetailV2Req} message GameGroupDetailV2Req message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailV2Req.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.game_group_id != null && Object.hasOwnProperty.call(message, "game_group_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.game_group_id);
      return writer;
    };

    /**
     * Encodes the specified GameGroupDetailV2Req message, length delimited. Does not implicitly {@link game_manage_proto.GameGroupDetailV2Req.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @static
     * @param {game_manage_proto.IGameGroupDetailV2Req} message GameGroupDetailV2Req message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailV2Req.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameGroupDetailV2Req message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.GameGroupDetailV2Req} GameGroupDetailV2Req
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailV2Req.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.GameGroupDetailV2Req();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.game_group_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameGroupDetailV2Req message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.GameGroupDetailV2Req} GameGroupDetailV2Req
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailV2Req.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameGroupDetailV2Req message.
     * @function verify
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameGroupDetailV2Req.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.game_group_id != null && message.hasOwnProperty("game_group_id"))
        if (!$util.isInteger(message.game_group_id)) return "game_group_id: integer expected";
      return null;
    };

    /**
     * Creates a GameGroupDetailV2Req message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.GameGroupDetailV2Req} GameGroupDetailV2Req
     */
    GameGroupDetailV2Req.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.GameGroupDetailV2Req) return object;
      var message = new $root.game_manage_proto.GameGroupDetailV2Req();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.game_group_id != null) message.game_group_id = object.game_group_id >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a GameGroupDetailV2Req message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @static
     * @param {game_manage_proto.GameGroupDetailV2Req} message GameGroupDetailV2Req
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameGroupDetailV2Req.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.game_group_id = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.game_group_id != null && message.hasOwnProperty("game_group_id"))
        object.game_group_id = message.game_group_id;
      return object;
    };

    /**
     * Converts this GameGroupDetailV2Req to JSON.
     * @function toJSON
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameGroupDetailV2Req.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameGroupDetailV2Req
     * @function getTypeUrl
     * @memberof game_manage_proto.GameGroupDetailV2Req
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameGroupDetailV2Req.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.GameGroupDetailV2Req";
    };

    return GameGroupDetailV2Req;
  })();

  game_manage_proto.GameGroupDetailV2Resp = (function () {
    /**
     * Properties of a GameGroupDetailV2Resp.
     * @memberof game_manage_proto
     * @interface IGameGroupDetailV2Resp
     * @property {string|null} [data] GameGroupDetailV2Resp data
     * @property {number|null} [game_group_id] GameGroupDetailV2Resp game_group_id
     */

    /**
     * Constructs a new GameGroupDetailV2Resp.
     * @memberof game_manage_proto
     * @classdesc Represents a GameGroupDetailV2Resp.
     * @implements IGameGroupDetailV2Resp
     * @constructor
     * @param {game_manage_proto.IGameGroupDetailV2Resp=} [properties] Properties to set
     */
    function GameGroupDetailV2Resp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameGroupDetailV2Resp data.
     * @member {string} data
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @instance
     */
    GameGroupDetailV2Resp.prototype.data = "";

    /**
     * GameGroupDetailV2Resp game_group_id.
     * @member {number} game_group_id
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @instance
     */
    GameGroupDetailV2Resp.prototype.game_group_id = 0;

    /**
     * Creates a new GameGroupDetailV2Resp instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @static
     * @param {game_manage_proto.IGameGroupDetailV2Resp=} [properties] Properties to set
     * @returns {game_manage_proto.GameGroupDetailV2Resp} GameGroupDetailV2Resp instance
     */
    GameGroupDetailV2Resp.create = function create(properties) {
      return new GameGroupDetailV2Resp(properties);
    };

    /**
     * Encodes the specified GameGroupDetailV2Resp message. Does not implicitly {@link game_manage_proto.GameGroupDetailV2Resp.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @static
     * @param {game_manage_proto.IGameGroupDetailV2Resp} message GameGroupDetailV2Resp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailV2Resp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data != null && Object.hasOwnProperty.call(message, "data"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.data);
      if (message.game_group_id != null && Object.hasOwnProperty.call(message, "game_group_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.game_group_id);
      return writer;
    };

    /**
     * Encodes the specified GameGroupDetailV2Resp message, length delimited. Does not implicitly {@link game_manage_proto.GameGroupDetailV2Resp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @static
     * @param {game_manage_proto.IGameGroupDetailV2Resp} message GameGroupDetailV2Resp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameGroupDetailV2Resp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameGroupDetailV2Resp message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.GameGroupDetailV2Resp} GameGroupDetailV2Resp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailV2Resp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.GameGroupDetailV2Resp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.data = reader.string();
            break;
          }
          case 2: {
            message.game_group_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameGroupDetailV2Resp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.GameGroupDetailV2Resp} GameGroupDetailV2Resp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameGroupDetailV2Resp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameGroupDetailV2Resp message.
     * @function verify
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameGroupDetailV2Resp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data != null && message.hasOwnProperty("data"))
        if (!$util.isString(message.data)) return "data: string expected";
      if (message.game_group_id != null && message.hasOwnProperty("game_group_id"))
        if (!$util.isInteger(message.game_group_id)) return "game_group_id: integer expected";
      return null;
    };

    /**
     * Creates a GameGroupDetailV2Resp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.GameGroupDetailV2Resp} GameGroupDetailV2Resp
     */
    GameGroupDetailV2Resp.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.GameGroupDetailV2Resp) return object;
      var message = new $root.game_manage_proto.GameGroupDetailV2Resp();
      if (object.data != null) message.data = String(object.data);
      if (object.game_group_id != null) message.game_group_id = object.game_group_id >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a GameGroupDetailV2Resp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @static
     * @param {game_manage_proto.GameGroupDetailV2Resp} message GameGroupDetailV2Resp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameGroupDetailV2Resp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.data = "";
        object.game_group_id = 0;
      }
      if (message.data != null && message.hasOwnProperty("data")) object.data = message.data;
      if (message.game_group_id != null && message.hasOwnProperty("game_group_id"))
        object.game_group_id = message.game_group_id;
      return object;
    };

    /**
     * Converts this GameGroupDetailV2Resp to JSON.
     * @function toJSON
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameGroupDetailV2Resp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameGroupDetailV2Resp
     * @function getTypeUrl
     * @memberof game_manage_proto.GameGroupDetailV2Resp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameGroupDetailV2Resp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.GameGroupDetailV2Resp";
    };

    return GameGroupDetailV2Resp;
  })();

  game_manage_proto.GameDetailReq = (function () {
    /**
     * Properties of a GameDetailReq.
     * @memberof game_manage_proto
     * @interface IGameDetailReq
     * @property {number|null} [uid] GameDetailReq uid
     * @property {string|null} [plat] GameDetailReq plat
     * @property {string|null} [third_game_id] GameDetailReq third_game_id
     * @property {string|null} [seq] GameDetailReq seq
     */

    /**
     * Constructs a new GameDetailReq.
     * @memberof game_manage_proto
     * @classdesc Represents a GameDetailReq.
     * @implements IGameDetailReq
     * @constructor
     * @param {game_manage_proto.IGameDetailReq=} [properties] Properties to set
     */
    function GameDetailReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameDetailReq uid.
     * @member {number} uid
     * @memberof game_manage_proto.GameDetailReq
     * @instance
     */
    GameDetailReq.prototype.uid = 0;

    /**
     * GameDetailReq plat.
     * @member {string} plat
     * @memberof game_manage_proto.GameDetailReq
     * @instance
     */
    GameDetailReq.prototype.plat = "";

    /**
     * GameDetailReq third_game_id.
     * @member {string} third_game_id
     * @memberof game_manage_proto.GameDetailReq
     * @instance
     */
    GameDetailReq.prototype.third_game_id = "";

    /**
     * GameDetailReq seq.
     * @member {string} seq
     * @memberof game_manage_proto.GameDetailReq
     * @instance
     */
    GameDetailReq.prototype.seq = "";

    /**
     * Creates a new GameDetailReq instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.GameDetailReq
     * @static
     * @param {game_manage_proto.IGameDetailReq=} [properties] Properties to set
     * @returns {game_manage_proto.GameDetailReq} GameDetailReq instance
     */
    GameDetailReq.create = function create(properties) {
      return new GameDetailReq(properties);
    };

    /**
     * Encodes the specified GameDetailReq message. Does not implicitly {@link game_manage_proto.GameDetailReq.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.GameDetailReq
     * @static
     * @param {game_manage_proto.IGameDetailReq} message GameDetailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameDetailReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.plat != null && Object.hasOwnProperty.call(message, "plat"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.plat);
      if (message.third_game_id != null && Object.hasOwnProperty.call(message, "third_game_id"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.third_game_id);
      if (message.seq != null && Object.hasOwnProperty.call(message, "seq"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.seq);
      return writer;
    };

    /**
     * Encodes the specified GameDetailReq message, length delimited. Does not implicitly {@link game_manage_proto.GameDetailReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.GameDetailReq
     * @static
     * @param {game_manage_proto.IGameDetailReq} message GameDetailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameDetailReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameDetailReq message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.GameDetailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.GameDetailReq} GameDetailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameDetailReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.GameDetailReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.plat = reader.string();
            break;
          }
          case 3: {
            message.third_game_id = reader.string();
            break;
          }
          case 4: {
            message.seq = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameDetailReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.GameDetailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.GameDetailReq} GameDetailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameDetailReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameDetailReq message.
     * @function verify
     * @memberof game_manage_proto.GameDetailReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameDetailReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.plat != null && message.hasOwnProperty("plat"))
        if (!$util.isString(message.plat)) return "plat: string expected";
      if (message.third_game_id != null && message.hasOwnProperty("third_game_id"))
        if (!$util.isString(message.third_game_id)) return "third_game_id: string expected";
      if (message.seq != null && message.hasOwnProperty("seq"))
        if (!$util.isString(message.seq)) return "seq: string expected";
      return null;
    };

    /**
     * Creates a GameDetailReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.GameDetailReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.GameDetailReq} GameDetailReq
     */
    GameDetailReq.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.GameDetailReq) return object;
      var message = new $root.game_manage_proto.GameDetailReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.plat != null) message.plat = String(object.plat);
      if (object.third_game_id != null) message.third_game_id = String(object.third_game_id);
      if (object.seq != null) message.seq = String(object.seq);
      return message;
    };

    /**
     * Creates a plain object from a GameDetailReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.GameDetailReq
     * @static
     * @param {game_manage_proto.GameDetailReq} message GameDetailReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameDetailReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.plat = "";
        object.third_game_id = "";
        object.seq = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.plat != null && message.hasOwnProperty("plat")) object.plat = message.plat;
      if (message.third_game_id != null && message.hasOwnProperty("third_game_id"))
        object.third_game_id = message.third_game_id;
      if (message.seq != null && message.hasOwnProperty("seq")) object.seq = message.seq;
      return object;
    };

    /**
     * Converts this GameDetailReq to JSON.
     * @function toJSON
     * @memberof game_manage_proto.GameDetailReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameDetailReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameDetailReq
     * @function getTypeUrl
     * @memberof game_manage_proto.GameDetailReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameDetailReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.GameDetailReq";
    };

    return GameDetailReq;
  })();

  game_manage_proto.GameDetailResp = (function () {
    /**
     * Properties of a GameDetailResp.
     * @memberof game_manage_proto
     * @interface IGameDetailResp
     * @property {string|null} [data] GameDetailResp data
     * @property {string|null} [seq] GameDetailResp seq
     */

    /**
     * Constructs a new GameDetailResp.
     * @memberof game_manage_proto
     * @classdesc Represents a GameDetailResp.
     * @implements IGameDetailResp
     * @constructor
     * @param {game_manage_proto.IGameDetailResp=} [properties] Properties to set
     */
    function GameDetailResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameDetailResp data.
     * @member {string} data
     * @memberof game_manage_proto.GameDetailResp
     * @instance
     */
    GameDetailResp.prototype.data = "";

    /**
     * GameDetailResp seq.
     * @member {string} seq
     * @memberof game_manage_proto.GameDetailResp
     * @instance
     */
    GameDetailResp.prototype.seq = "";

    /**
     * Creates a new GameDetailResp instance using the specified properties.
     * @function create
     * @memberof game_manage_proto.GameDetailResp
     * @static
     * @param {game_manage_proto.IGameDetailResp=} [properties] Properties to set
     * @returns {game_manage_proto.GameDetailResp} GameDetailResp instance
     */
    GameDetailResp.create = function create(properties) {
      return new GameDetailResp(properties);
    };

    /**
     * Encodes the specified GameDetailResp message. Does not implicitly {@link game_manage_proto.GameDetailResp.verify|verify} messages.
     * @function encode
     * @memberof game_manage_proto.GameDetailResp
     * @static
     * @param {game_manage_proto.IGameDetailResp} message GameDetailResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameDetailResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.data != null && Object.hasOwnProperty.call(message, "data"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.data);
      if (message.seq != null && Object.hasOwnProperty.call(message, "seq"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.seq);
      return writer;
    };

    /**
     * Encodes the specified GameDetailResp message, length delimited. Does not implicitly {@link game_manage_proto.GameDetailResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_manage_proto.GameDetailResp
     * @static
     * @param {game_manage_proto.IGameDetailResp} message GameDetailResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameDetailResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameDetailResp message from the specified reader or buffer.
     * @function decode
     * @memberof game_manage_proto.GameDetailResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_manage_proto.GameDetailResp} GameDetailResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameDetailResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_manage_proto.GameDetailResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.data = reader.string();
            break;
          }
          case 2: {
            message.seq = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameDetailResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_manage_proto.GameDetailResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_manage_proto.GameDetailResp} GameDetailResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameDetailResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameDetailResp message.
     * @function verify
     * @memberof game_manage_proto.GameDetailResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameDetailResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.data != null && message.hasOwnProperty("data"))
        if (!$util.isString(message.data)) return "data: string expected";
      if (message.seq != null && message.hasOwnProperty("seq"))
        if (!$util.isString(message.seq)) return "seq: string expected";
      return null;
    };

    /**
     * Creates a GameDetailResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_manage_proto.GameDetailResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_manage_proto.GameDetailResp} GameDetailResp
     */
    GameDetailResp.fromObject = function fromObject(object) {
      if (object instanceof $root.game_manage_proto.GameDetailResp) return object;
      var message = new $root.game_manage_proto.GameDetailResp();
      if (object.data != null) message.data = String(object.data);
      if (object.seq != null) message.seq = String(object.seq);
      return message;
    };

    /**
     * Creates a plain object from a GameDetailResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_manage_proto.GameDetailResp
     * @static
     * @param {game_manage_proto.GameDetailResp} message GameDetailResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameDetailResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.data = "";
        object.seq = "";
      }
      if (message.data != null && message.hasOwnProperty("data")) object.data = message.data;
      if (message.seq != null && message.hasOwnProperty("seq")) object.seq = message.seq;
      return object;
    };

    /**
     * Converts this GameDetailResp to JSON.
     * @function toJSON
     * @memberof game_manage_proto.GameDetailResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameDetailResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GameDetailResp
     * @function getTypeUrl
     * @memberof game_manage_proto.GameDetailResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GameDetailResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_manage_proto.GameDetailResp";
    };

    return GameDetailResp;
  })();

  return game_manage_proto;
})();

$root.gamealloc = (function () {
  /**
   * Namespace gamealloc.
   * @exports gamealloc
   * @namespace
   */
  var gamealloc = {};

  /**
   * GAMEALLOC_CMD enum.
   * @name gamealloc.GAMEALLOC_CMD
   * @enum {number}
   * @property {number} GAMEALLOC_CMD_NONE=0 GAMEALLOC_CMD_NONE value
   * @property {number} GAMEALLOC_CMD_ALLOC_REQ=2000 GAMEALLOC_CMD_ALLOC_REQ value
   * @property {number} GAMEALLOC_CMD_ALLOC_RESP=2001 GAMEALLOC_CMD_ALLOC_RESP value
   */
  gamealloc.GAMEALLOC_CMD = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "GAMEALLOC_CMD_NONE")] = 0;
    values[(valuesById[2000] = "GAMEALLOC_CMD_ALLOC_REQ")] = 2000;
    values[(valuesById[2001] = "GAMEALLOC_CMD_ALLOC_RESP")] = 2001;
    return values;
  })();

  gamealloc.AllocReq = (function () {
    /**
     * Properties of an AllocReq.
     * @memberof gamealloc
     * @interface IAllocReq
     * @property {number|null} [uid] AllocReq uid
     * @property {number|null} [accessid] AllocReq accessid
     * @property {string|null} [access] AllocReq access
     * @property {string|null} [game] AllocReq game
     */

    /**
     * Constructs a new AllocReq.
     * @memberof gamealloc
     * @classdesc Represents an AllocReq.
     * @implements IAllocReq
     * @constructor
     * @param {gamealloc.IAllocReq=} [properties] Properties to set
     */
    function AllocReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AllocReq uid.
     * @member {number} uid
     * @memberof gamealloc.AllocReq
     * @instance
     */
    AllocReq.prototype.uid = 0;

    /**
     * AllocReq accessid.
     * @member {number} accessid
     * @memberof gamealloc.AllocReq
     * @instance
     */
    AllocReq.prototype.accessid = 0;

    /**
     * AllocReq access.
     * @member {string} access
     * @memberof gamealloc.AllocReq
     * @instance
     */
    AllocReq.prototype.access = "";

    /**
     * AllocReq game.
     * @member {string} game
     * @memberof gamealloc.AllocReq
     * @instance
     */
    AllocReq.prototype.game = "";

    /**
     * Creates a new AllocReq instance using the specified properties.
     * @function create
     * @memberof gamealloc.AllocReq
     * @static
     * @param {gamealloc.IAllocReq=} [properties] Properties to set
     * @returns {gamealloc.AllocReq} AllocReq instance
     */
    AllocReq.create = function create(properties) {
      return new AllocReq(properties);
    };

    /**
     * Encodes the specified AllocReq message. Does not implicitly {@link gamealloc.AllocReq.verify|verify} messages.
     * @function encode
     * @memberof gamealloc.AllocReq
     * @static
     * @param {gamealloc.IAllocReq} message AllocReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AllocReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.accessid != null && Object.hasOwnProperty.call(message, "accessid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.accessid);
      if (message.access != null && Object.hasOwnProperty.call(message, "access"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.access);
      if (message.game != null && Object.hasOwnProperty.call(message, "game"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.game);
      return writer;
    };

    /**
     * Encodes the specified AllocReq message, length delimited. Does not implicitly {@link gamealloc.AllocReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamealloc.AllocReq
     * @static
     * @param {gamealloc.IAllocReq} message AllocReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AllocReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AllocReq message from the specified reader or buffer.
     * @function decode
     * @memberof gamealloc.AllocReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamealloc.AllocReq} AllocReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AllocReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamealloc.AllocReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.accessid = reader.uint32();
            break;
          }
          case 3: {
            message.access = reader.string();
            break;
          }
          case 4: {
            message.game = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AllocReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamealloc.AllocReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamealloc.AllocReq} AllocReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AllocReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AllocReq message.
     * @function verify
     * @memberof gamealloc.AllocReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AllocReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.accessid != null && message.hasOwnProperty("accessid"))
        if (!$util.isInteger(message.accessid)) return "accessid: integer expected";
      if (message.access != null && message.hasOwnProperty("access"))
        if (!$util.isString(message.access)) return "access: string expected";
      if (message.game != null && message.hasOwnProperty("game"))
        if (!$util.isString(message.game)) return "game: string expected";
      return null;
    };

    /**
     * Creates an AllocReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamealloc.AllocReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamealloc.AllocReq} AllocReq
     */
    AllocReq.fromObject = function fromObject(object) {
      if (object instanceof $root.gamealloc.AllocReq) return object;
      var message = new $root.gamealloc.AllocReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.accessid != null) message.accessid = object.accessid >>> 0;
      if (object.access != null) message.access = String(object.access);
      if (object.game != null) message.game = String(object.game);
      return message;
    };

    /**
     * Creates a plain object from an AllocReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamealloc.AllocReq
     * @static
     * @param {gamealloc.AllocReq} message AllocReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AllocReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.accessid = 0;
        object.access = "";
        object.game = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.accessid != null && message.hasOwnProperty("accessid")) object.accessid = message.accessid;
      if (message.access != null && message.hasOwnProperty("access")) object.access = message.access;
      if (message.game != null && message.hasOwnProperty("game")) object.game = message.game;
      return object;
    };

    /**
     * Converts this AllocReq to JSON.
     * @function toJSON
     * @memberof gamealloc.AllocReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AllocReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AllocReq
     * @function getTypeUrl
     * @memberof gamealloc.AllocReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AllocReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/gamealloc.AllocReq";
    };

    return AllocReq;
  })();

  gamealloc.AllocResp = (function () {
    /**
     * Properties of an AllocResp.
     * @memberof gamealloc
     * @interface IAllocResp
     * @property {number|null} [uid] AllocResp uid
     * @property {number|null} [result] AllocResp result
     * @property {number|null} [stype] AllocResp stype
     * @property {number|null} [srvid] AllocResp srvid
     */

    /**
     * Constructs a new AllocResp.
     * @memberof gamealloc
     * @classdesc Represents an AllocResp.
     * @implements IAllocResp
     * @constructor
     * @param {gamealloc.IAllocResp=} [properties] Properties to set
     */
    function AllocResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AllocResp uid.
     * @member {number} uid
     * @memberof gamealloc.AllocResp
     * @instance
     */
    AllocResp.prototype.uid = 0;

    /**
     * AllocResp result.
     * @member {number} result
     * @memberof gamealloc.AllocResp
     * @instance
     */
    AllocResp.prototype.result = 0;

    /**
     * AllocResp stype.
     * @member {number} stype
     * @memberof gamealloc.AllocResp
     * @instance
     */
    AllocResp.prototype.stype = 0;

    /**
     * AllocResp srvid.
     * @member {number} srvid
     * @memberof gamealloc.AllocResp
     * @instance
     */
    AllocResp.prototype.srvid = 0;

    /**
     * Creates a new AllocResp instance using the specified properties.
     * @function create
     * @memberof gamealloc.AllocResp
     * @static
     * @param {gamealloc.IAllocResp=} [properties] Properties to set
     * @returns {gamealloc.AllocResp} AllocResp instance
     */
    AllocResp.create = function create(properties) {
      return new AllocResp(properties);
    };

    /**
     * Encodes the specified AllocResp message. Does not implicitly {@link gamealloc.AllocResp.verify|verify} messages.
     * @function encode
     * @memberof gamealloc.AllocResp
     * @static
     * @param {gamealloc.IAllocResp} message AllocResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AllocResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.stype != null && Object.hasOwnProperty.call(message, "stype"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.stype);
      if (message.srvid != null && Object.hasOwnProperty.call(message, "srvid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.srvid);
      return writer;
    };

    /**
     * Encodes the specified AllocResp message, length delimited. Does not implicitly {@link gamealloc.AllocResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamealloc.AllocResp
     * @static
     * @param {gamealloc.IAllocResp} message AllocResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AllocResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AllocResp message from the specified reader or buffer.
     * @function decode
     * @memberof gamealloc.AllocResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamealloc.AllocResp} AllocResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AllocResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamealloc.AllocResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            message.stype = reader.uint32();
            break;
          }
          case 4: {
            message.srvid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AllocResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamealloc.AllocResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamealloc.AllocResp} AllocResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AllocResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AllocResp message.
     * @function verify
     * @memberof gamealloc.AllocResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AllocResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.stype != null && message.hasOwnProperty("stype"))
        if (!$util.isInteger(message.stype)) return "stype: integer expected";
      if (message.srvid != null && message.hasOwnProperty("srvid"))
        if (!$util.isInteger(message.srvid)) return "srvid: integer expected";
      return null;
    };

    /**
     * Creates an AllocResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamealloc.AllocResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamealloc.AllocResp} AllocResp
     */
    AllocResp.fromObject = function fromObject(object) {
      if (object instanceof $root.gamealloc.AllocResp) return object;
      var message = new $root.gamealloc.AllocResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.stype != null) message.stype = object.stype >>> 0;
      if (object.srvid != null) message.srvid = object.srvid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from an AllocResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamealloc.AllocResp
     * @static
     * @param {gamealloc.AllocResp} message AllocResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AllocResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.result = 0;
        object.stype = 0;
        object.srvid = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.stype != null && message.hasOwnProperty("stype")) object.stype = message.stype;
      if (message.srvid != null && message.hasOwnProperty("srvid")) object.srvid = message.srvid;
      return object;
    };

    /**
     * Converts this AllocResp to JSON.
     * @function toJSON
     * @memberof gamealloc.AllocResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AllocResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AllocResp
     * @function getTypeUrl
     * @memberof gamealloc.AllocResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AllocResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/gamealloc.AllocResp";
    };

    return AllocResp;
  })();

  return gamealloc;
})();

$root.server_proto = (function () {
  /**
   * Namespace server_proto.
   * @exports server_proto
   * @namespace
   */
  var server_proto = {};

  /**
   * EMAIL_INFO_SUB_MSG_ID enum.
   * @name server_proto.EMAIL_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} EMAIL_MSG_ID_NULL=0 EMAIL_MSG_ID_NULL value
   * @property {number} EMAIL_MSG_ID_MIN=2100 EMAIL_MSG_ID_MIN value
   * @property {number} EMAIL_MSG_ID_UNREAD_INFO=2101 EMAIL_MSG_ID_UNREAD_INFO value
   * @property {number} EMAIL_MSG_ID_UNREAD_INFO_RESP=2102 EMAIL_MSG_ID_UNREAD_INFO_RESP value
   * @property {number} EMAIL_MSG_ID_MAIL_LIST=2103 EMAIL_MSG_ID_MAIL_LIST value
   * @property {number} EMAIL_MSG_ID_MAIL_LIST_RESP=2104 EMAIL_MSG_ID_MAIL_LIST_RESP value
   * @property {number} EMAIL_MSG_ID_DELETE_LIST=2105 EMAIL_MSG_ID_DELETE_LIST value
   * @property {number} EMAIL_MSG_ID_DELETE_LIST_RESP=2106 EMAIL_MSG_ID_DELETE_LIST_RESP value
   * @property {number} EMAIL_MSG_ID_RECEIVE_LIST=2107 EMAIL_MSG_ID_RECEIVE_LIST value
   * @property {number} EMAIL_MSG_ID_RECEIVE_LIST_RESP=2108 EMAIL_MSG_ID_RECEIVE_LIST_RESP value
   * @property {number} EMAIL_MSG_ID_MARK_READ=2109 EMAIL_MSG_ID_MARK_READ value
   * @property {number} EMAIL_MSG_ID_MARK_READ_RESP=2110 EMAIL_MSG_ID_MARK_READ_RESP value
   * @property {number} EMAIL_MSG_ID_MAIL_INFO_PUSH=2111 EMAIL_MSG_ID_MAIL_INFO_PUSH value
   * @property {number} SIMI_MSG_MAIL_STOP_SYSTEM_MAIL=2131 SIMI_MSG_MAIL_STOP_SYSTEM_MAIL value
   * @property {number} SIMI_MSG_MAIL_STOP_SYSTEM_MAIL_RESP=2132 SIMI_MSG_MAIL_STOP_SYSTEM_MAIL_RESP value
   * @property {number} SIMI_MSG_MAIL_NOTIFY_RECEIVE=2133 SIMI_MSG_MAIL_NOTIFY_RECEIVE value
   * @property {number} SIMI_MSG_MAIL_CREATE=2141 SIMI_MSG_MAIL_CREATE value
   * @property {number} SIMI_MSG_MAIL_CREATE_RESP=2142 SIMI_MSG_MAIL_CREATE_RESP value
   * @property {number} SIMI_MSG_MAIL_CREATE_SYSTEM=2143 SIMI_MSG_MAIL_CREATE_SYSTEM value
   * @property {number} SIMI_MSG_MAIL_CREATE_SYSTEM_RESP=2144 SIMI_MSG_MAIL_CREATE_SYSTEM_RESP value
   * @property {number} SIMI_MSG_MAIL_CREATE_PHP=2145 SIMI_MSG_MAIL_CREATE_PHP value
   * @property {number} SIMI_MSG_MAIL_CREATE_PHP_RESP=2146 SIMI_MSG_MAIL_CREATE_PHP_RESP value
   * @property {number} SIMI_MSG_MAIL_DEL_SYSTEM_BATCH=2147 SIMI_MSG_MAIL_DEL_SYSTEM_BATCH value
   * @property {number} SIMI_MSG_MAIL_DEL_SYSTEM_BATCH_RESP=2148 SIMI_MSG_MAIL_DEL_SYSTEM_BATCH_RESP value
   * @property {number} SIMI_MSG_MAIL_CREATE_SYSTEM_MAIL=2149 SIMI_MSG_MAIL_CREATE_SYSTEM_MAIL value
   * @property {number} EMAIL_MSG_ID_MAX=2150 EMAIL_MSG_ID_MAX value
   */
  server_proto.EMAIL_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "EMAIL_MSG_ID_NULL")] = 0;
    values[(valuesById[2100] = "EMAIL_MSG_ID_MIN")] = 2100;
    values[(valuesById[2101] = "EMAIL_MSG_ID_UNREAD_INFO")] = 2101;
    values[(valuesById[2102] = "EMAIL_MSG_ID_UNREAD_INFO_RESP")] = 2102;
    values[(valuesById[2103] = "EMAIL_MSG_ID_MAIL_LIST")] = 2103;
    values[(valuesById[2104] = "EMAIL_MSG_ID_MAIL_LIST_RESP")] = 2104;
    values[(valuesById[2105] = "EMAIL_MSG_ID_DELETE_LIST")] = 2105;
    values[(valuesById[2106] = "EMAIL_MSG_ID_DELETE_LIST_RESP")] = 2106;
    values[(valuesById[2107] = "EMAIL_MSG_ID_RECEIVE_LIST")] = 2107;
    values[(valuesById[2108] = "EMAIL_MSG_ID_RECEIVE_LIST_RESP")] = 2108;
    values[(valuesById[2109] = "EMAIL_MSG_ID_MARK_READ")] = 2109;
    values[(valuesById[2110] = "EMAIL_MSG_ID_MARK_READ_RESP")] = 2110;
    values[(valuesById[2111] = "EMAIL_MSG_ID_MAIL_INFO_PUSH")] = 2111;
    values[(valuesById[2131] = "SIMI_MSG_MAIL_STOP_SYSTEM_MAIL")] = 2131;
    values[(valuesById[2132] = "SIMI_MSG_MAIL_STOP_SYSTEM_MAIL_RESP")] = 2132;
    values[(valuesById[2133] = "SIMI_MSG_MAIL_NOTIFY_RECEIVE")] = 2133;
    values[(valuesById[2141] = "SIMI_MSG_MAIL_CREATE")] = 2141;
    values[(valuesById[2142] = "SIMI_MSG_MAIL_CREATE_RESP")] = 2142;
    values[(valuesById[2143] = "SIMI_MSG_MAIL_CREATE_SYSTEM")] = 2143;
    values[(valuesById[2144] = "SIMI_MSG_MAIL_CREATE_SYSTEM_RESP")] = 2144;
    values[(valuesById[2145] = "SIMI_MSG_MAIL_CREATE_PHP")] = 2145;
    values[(valuesById[2146] = "SIMI_MSG_MAIL_CREATE_PHP_RESP")] = 2146;
    values[(valuesById[2147] = "SIMI_MSG_MAIL_DEL_SYSTEM_BATCH")] = 2147;
    values[(valuesById[2148] = "SIMI_MSG_MAIL_DEL_SYSTEM_BATCH_RESP")] = 2148;
    values[(valuesById[2149] = "SIMI_MSG_MAIL_CREATE_SYSTEM_MAIL")] = 2149;
    values[(valuesById[2150] = "EMAIL_MSG_ID_MAX")] = 2150;
    return values;
  })();

  /**
   * SYSTEM_MAIL_CONTENT_TYPE enum.
   * @name server_proto.SYSTEM_MAIL_CONTENT_TYPE
   * @enum {number}
   * @property {number} MAIL_TYPE_NULL=0 MAIL_TYPE_NULL value
   * @property {number} MAIL_SPIN_WITHDRAW_SUCCESS=101 MAIL_SPIN_WITHDRAW_SUCCESS value
   * @property {number} MAIL_SPIN_WITHDRAW_FAIL=102 MAIL_SPIN_WITHDRAW_FAIL value
   * @property {number} MAIL_RANK_CHECKOUT=103 MAIL_RANK_CHECKOUT value
   * @property {number} MAIL_RANK_GET_REWARD=104 MAIL_RANK_GET_REWARD value
   * @property {number} MAIL_AGENT_DAILY_SETTLE=105 MAIL_AGENT_DAILY_SETTLE value
   * @property {number} MAIL_AGENT_WITHDRAW_SUCCESS=106 MAIL_AGENT_WITHDRAW_SUCCESS value
   * @property {number} MAIL_AGENT_WITHDRAW_FAIL=107 MAIL_AGENT_WITHDRAW_FAIL value
   * @property {number} MAIL_COIN_WITHDRAW_SUCCESS=108 MAIL_COIN_WITHDRAW_SUCCESS value
   * @property {number} MAIL_COIN_WITHDRAW_FAIL=109 MAIL_COIN_WITHDRAW_FAIL value
   * @property {number} MAIL_VIP_LEVELUP_NOTIFY=110 MAIL_VIP_LEVELUP_NOTIFY value
   * @property {number} MAIL_RECHARGE_SUCCESS=111 MAIL_RECHARGE_SUCCESS value
   * @property {number} MAIL_GET_INVITE_REWARD=112 MAIL_GET_INVITE_REWARD value
   * @property {number} MAIL_GET_INVITE_TOTAL_REWARD=113 MAIL_GET_INVITE_TOTAL_REWARD value
   * @property {number} MAIL_GET_INVITE_NOTIFY=114 MAIL_GET_INVITE_NOTIFY value
   * @property {number} MAIL_AGENT_RANK_GET_REWARD=115 MAIL_AGENT_RANK_GET_REWARD value
   * @property {number} MAIL_FIRST_RECHARGE_BAG=201 MAIL_FIRST_RECHARGE_BAG value
   * @property {number} MAIL_SPIN_ACTIVITY_OPEN=202 MAIL_SPIN_ACTIVITY_OPEN value
   * @property {number} MAIL_BANKRUPT_BAG=203 MAIL_BANKRUPT_BAG value
   * @property {number} MAIL_REGRESSWELFARE=204 MAIL_REGRESSWELFARE value
   */
  server_proto.SYSTEM_MAIL_CONTENT_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "MAIL_TYPE_NULL")] = 0;
    values[(valuesById[101] = "MAIL_SPIN_WITHDRAW_SUCCESS")] = 101;
    values[(valuesById[102] = "MAIL_SPIN_WITHDRAW_FAIL")] = 102;
    values[(valuesById[103] = "MAIL_RANK_CHECKOUT")] = 103;
    values[(valuesById[104] = "MAIL_RANK_GET_REWARD")] = 104;
    values[(valuesById[105] = "MAIL_AGENT_DAILY_SETTLE")] = 105;
    values[(valuesById[106] = "MAIL_AGENT_WITHDRAW_SUCCESS")] = 106;
    values[(valuesById[107] = "MAIL_AGENT_WITHDRAW_FAIL")] = 107;
    values[(valuesById[108] = "MAIL_COIN_WITHDRAW_SUCCESS")] = 108;
    values[(valuesById[109] = "MAIL_COIN_WITHDRAW_FAIL")] = 109;
    values[(valuesById[110] = "MAIL_VIP_LEVELUP_NOTIFY")] = 110;
    values[(valuesById[111] = "MAIL_RECHARGE_SUCCESS")] = 111;
    values[(valuesById[112] = "MAIL_GET_INVITE_REWARD")] = 112;
    values[(valuesById[113] = "MAIL_GET_INVITE_TOTAL_REWARD")] = 113;
    values[(valuesById[114] = "MAIL_GET_INVITE_NOTIFY")] = 114;
    values[(valuesById[115] = "MAIL_AGENT_RANK_GET_REWARD")] = 115;
    values[(valuesById[201] = "MAIL_FIRST_RECHARGE_BAG")] = 201;
    values[(valuesById[202] = "MAIL_SPIN_ACTIVITY_OPEN")] = 202;
    values[(valuesById[203] = "MAIL_BANKRUPT_BAG")] = 203;
    values[(valuesById[204] = "MAIL_REGRESSWELFARE")] = 204;
    return values;
  })();

  server_proto.CreateMailInfoSystemReq = (function () {
    /**
     * Properties of a CreateMailInfoSystemReq.
     * @memberof server_proto
     * @interface ICreateMailInfoSystemReq
     * @property {server_proto.SYSTEM_MAIL_CONTENT_TYPE|null} [content_type] CreateMailInfoSystemReq content_type
     * @property {number|null} [uid] CreateMailInfoSystemReq uid
     * @property {number|null} [receive_time] CreateMailInfoSystemReq receive_time
     * @property {Array.<string>|null} [title_list] CreateMailInfoSystemReq title_list
     * @property {Array.<string>|null} [content_list] CreateMailInfoSystemReq content_list
     * @property {string|null} [attachment] CreateMailInfoSystemReq attachment
     */

    /**
     * Constructs a new CreateMailInfoSystemReq.
     * @memberof server_proto
     * @classdesc Represents a CreateMailInfoSystemReq.
     * @implements ICreateMailInfoSystemReq
     * @constructor
     * @param {server_proto.ICreateMailInfoSystemReq=} [properties] Properties to set
     */
    function CreateMailInfoSystemReq(properties) {
      this.title_list = [];
      this.content_list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateMailInfoSystemReq content_type.
     * @member {server_proto.SYSTEM_MAIL_CONTENT_TYPE} content_type
     * @memberof server_proto.CreateMailInfoSystemReq
     * @instance
     */
    CreateMailInfoSystemReq.prototype.content_type = 0;

    /**
     * CreateMailInfoSystemReq uid.
     * @member {number} uid
     * @memberof server_proto.CreateMailInfoSystemReq
     * @instance
     */
    CreateMailInfoSystemReq.prototype.uid = 0;

    /**
     * CreateMailInfoSystemReq receive_time.
     * @member {number} receive_time
     * @memberof server_proto.CreateMailInfoSystemReq
     * @instance
     */
    CreateMailInfoSystemReq.prototype.receive_time = 0;

    /**
     * CreateMailInfoSystemReq title_list.
     * @member {Array.<string>} title_list
     * @memberof server_proto.CreateMailInfoSystemReq
     * @instance
     */
    CreateMailInfoSystemReq.prototype.title_list = $util.emptyArray;

    /**
     * CreateMailInfoSystemReq content_list.
     * @member {Array.<string>} content_list
     * @memberof server_proto.CreateMailInfoSystemReq
     * @instance
     */
    CreateMailInfoSystemReq.prototype.content_list = $util.emptyArray;

    /**
     * CreateMailInfoSystemReq attachment.
     * @member {string} attachment
     * @memberof server_proto.CreateMailInfoSystemReq
     * @instance
     */
    CreateMailInfoSystemReq.prototype.attachment = "";

    /**
     * Creates a new CreateMailInfoSystemReq instance using the specified properties.
     * @function create
     * @memberof server_proto.CreateMailInfoSystemReq
     * @static
     * @param {server_proto.ICreateMailInfoSystemReq=} [properties] Properties to set
     * @returns {server_proto.CreateMailInfoSystemReq} CreateMailInfoSystemReq instance
     */
    CreateMailInfoSystemReq.create = function create(properties) {
      return new CreateMailInfoSystemReq(properties);
    };

    /**
     * Encodes the specified CreateMailInfoSystemReq message. Does not implicitly {@link server_proto.CreateMailInfoSystemReq.verify|verify} messages.
     * @function encode
     * @memberof server_proto.CreateMailInfoSystemReq
     * @static
     * @param {server_proto.ICreateMailInfoSystemReq} message CreateMailInfoSystemReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateMailInfoSystemReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.content_type != null && Object.hasOwnProperty.call(message, "content_type"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.content_type);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.uid);
      if (message.receive_time != null && Object.hasOwnProperty.call(message, "receive_time"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.receive_time);
      if (message.title_list != null && message.title_list.length)
        for (var i = 0; i < message.title_list.length; ++i)
          writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.title_list[i]);
      if (message.content_list != null && message.content_list.length)
        for (var i = 0; i < message.content_list.length; ++i)
          writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.content_list[i]);
      if (message.attachment != null && Object.hasOwnProperty.call(message, "attachment"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.attachment);
      return writer;
    };

    /**
     * Encodes the specified CreateMailInfoSystemReq message, length delimited. Does not implicitly {@link server_proto.CreateMailInfoSystemReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.CreateMailInfoSystemReq
     * @static
     * @param {server_proto.ICreateMailInfoSystemReq} message CreateMailInfoSystemReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateMailInfoSystemReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateMailInfoSystemReq message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.CreateMailInfoSystemReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.CreateMailInfoSystemReq} CreateMailInfoSystemReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateMailInfoSystemReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.CreateMailInfoSystemReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.content_type = reader.int32();
            break;
          }
          case 2: {
            message.uid = reader.uint32();
            break;
          }
          case 3: {
            message.receive_time = reader.uint32();
            break;
          }
          case 4: {
            if (!(message.title_list && message.title_list.length)) message.title_list = [];
            message.title_list.push(reader.string());
            break;
          }
          case 5: {
            if (!(message.content_list && message.content_list.length)) message.content_list = [];
            message.content_list.push(reader.string());
            break;
          }
          case 6: {
            message.attachment = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateMailInfoSystemReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.CreateMailInfoSystemReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.CreateMailInfoSystemReq} CreateMailInfoSystemReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateMailInfoSystemReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateMailInfoSystemReq message.
     * @function verify
     * @memberof server_proto.CreateMailInfoSystemReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateMailInfoSystemReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.content_type != null && message.hasOwnProperty("content_type"))
        switch (message.content_type) {
          default:
            return "content_type: enum value expected";
          case 0:
          case 101:
          case 102:
          case 103:
          case 104:
          case 105:
          case 106:
          case 107:
          case 108:
          case 109:
          case 110:
          case 111:
          case 112:
          case 113:
          case 114:
          case 115:
          case 201:
          case 202:
          case 203:
          case 204:
            break;
        }
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.receive_time != null && message.hasOwnProperty("receive_time"))
        if (!$util.isInteger(message.receive_time)) return "receive_time: integer expected";
      if (message.title_list != null && message.hasOwnProperty("title_list")) {
        if (!Array.isArray(message.title_list)) return "title_list: array expected";
        for (var i = 0; i < message.title_list.length; ++i)
          if (!$util.isString(message.title_list[i])) return "title_list: string[] expected";
      }
      if (message.content_list != null && message.hasOwnProperty("content_list")) {
        if (!Array.isArray(message.content_list)) return "content_list: array expected";
        for (var i = 0; i < message.content_list.length; ++i)
          if (!$util.isString(message.content_list[i])) return "content_list: string[] expected";
      }
      if (message.attachment != null && message.hasOwnProperty("attachment"))
        if (!$util.isString(message.attachment)) return "attachment: string expected";
      return null;
    };

    /**
     * Creates a CreateMailInfoSystemReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.CreateMailInfoSystemReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.CreateMailInfoSystemReq} CreateMailInfoSystemReq
     */
    CreateMailInfoSystemReq.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.CreateMailInfoSystemReq) return object;
      var message = new $root.server_proto.CreateMailInfoSystemReq();
      switch (object.content_type) {
        default:
          if (typeof object.content_type === "number") {
            message.content_type = object.content_type;
            break;
          }
          break;
        case "MAIL_TYPE_NULL":
        case 0:
          message.content_type = 0;
          break;
        case "MAIL_SPIN_WITHDRAW_SUCCESS":
        case 101:
          message.content_type = 101;
          break;
        case "MAIL_SPIN_WITHDRAW_FAIL":
        case 102:
          message.content_type = 102;
          break;
        case "MAIL_RANK_CHECKOUT":
        case 103:
          message.content_type = 103;
          break;
        case "MAIL_RANK_GET_REWARD":
        case 104:
          message.content_type = 104;
          break;
        case "MAIL_AGENT_DAILY_SETTLE":
        case 105:
          message.content_type = 105;
          break;
        case "MAIL_AGENT_WITHDRAW_SUCCESS":
        case 106:
          message.content_type = 106;
          break;
        case "MAIL_AGENT_WITHDRAW_FAIL":
        case 107:
          message.content_type = 107;
          break;
        case "MAIL_COIN_WITHDRAW_SUCCESS":
        case 108:
          message.content_type = 108;
          break;
        case "MAIL_COIN_WITHDRAW_FAIL":
        case 109:
          message.content_type = 109;
          break;
        case "MAIL_VIP_LEVELUP_NOTIFY":
        case 110:
          message.content_type = 110;
          break;
        case "MAIL_RECHARGE_SUCCESS":
        case 111:
          message.content_type = 111;
          break;
        case "MAIL_GET_INVITE_REWARD":
        case 112:
          message.content_type = 112;
          break;
        case "MAIL_GET_INVITE_TOTAL_REWARD":
        case 113:
          message.content_type = 113;
          break;
        case "MAIL_GET_INVITE_NOTIFY":
        case 114:
          message.content_type = 114;
          break;
        case "MAIL_AGENT_RANK_GET_REWARD":
        case 115:
          message.content_type = 115;
          break;
        case "MAIL_FIRST_RECHARGE_BAG":
        case 201:
          message.content_type = 201;
          break;
        case "MAIL_SPIN_ACTIVITY_OPEN":
        case 202:
          message.content_type = 202;
          break;
        case "MAIL_BANKRUPT_BAG":
        case 203:
          message.content_type = 203;
          break;
        case "MAIL_REGRESSWELFARE":
        case 204:
          message.content_type = 204;
          break;
      }
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.receive_time != null) message.receive_time = object.receive_time >>> 0;
      if (object.title_list) {
        if (!Array.isArray(object.title_list))
          throw TypeError(".server_proto.CreateMailInfoSystemReq.title_list: array expected");
        message.title_list = [];
        for (var i = 0; i < object.title_list.length; ++i) message.title_list[i] = String(object.title_list[i]);
      }
      if (object.content_list) {
        if (!Array.isArray(object.content_list))
          throw TypeError(".server_proto.CreateMailInfoSystemReq.content_list: array expected");
        message.content_list = [];
        for (var i = 0; i < object.content_list.length; ++i) message.content_list[i] = String(object.content_list[i]);
      }
      if (object.attachment != null) message.attachment = String(object.attachment);
      return message;
    };

    /**
     * Creates a plain object from a CreateMailInfoSystemReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.CreateMailInfoSystemReq
     * @static
     * @param {server_proto.CreateMailInfoSystemReq} message CreateMailInfoSystemReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateMailInfoSystemReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) {
        object.title_list = [];
        object.content_list = [];
      }
      if (options.defaults) {
        object.content_type = options.enums === String ? "MAIL_TYPE_NULL" : 0;
        object.uid = 0;
        object.receive_time = 0;
        object.attachment = "";
      }
      if (message.content_type != null && message.hasOwnProperty("content_type"))
        object.content_type =
          options.enums === String
            ? $root.server_proto.SYSTEM_MAIL_CONTENT_TYPE[message.content_type] === undefined
              ? message.content_type
              : $root.server_proto.SYSTEM_MAIL_CONTENT_TYPE[message.content_type]
            : message.content_type;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.receive_time != null && message.hasOwnProperty("receive_time"))
        object.receive_time = message.receive_time;
      if (message.title_list && message.title_list.length) {
        object.title_list = [];
        for (var j = 0; j < message.title_list.length; ++j) object.title_list[j] = message.title_list[j];
      }
      if (message.content_list && message.content_list.length) {
        object.content_list = [];
        for (var j = 0; j < message.content_list.length; ++j) object.content_list[j] = message.content_list[j];
      }
      if (message.attachment != null && message.hasOwnProperty("attachment")) object.attachment = message.attachment;
      return object;
    };

    /**
     * Converts this CreateMailInfoSystemReq to JSON.
     * @function toJSON
     * @memberof server_proto.CreateMailInfoSystemReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateMailInfoSystemReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateMailInfoSystemReq
     * @function getTypeUrl
     * @memberof server_proto.CreateMailInfoSystemReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateMailInfoSystemReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.CreateMailInfoSystemReq";
    };

    return CreateMailInfoSystemReq;
  })();

  server_proto.CreateMailInfoReq = (function () {
    /**
     * Properties of a CreateMailInfoReq.
     * @memberof server_proto
     * @interface ICreateMailInfoReq
     * @property {number|null} [uid] CreateMailInfoReq uid
     * @property {number|null} [receive_time] CreateMailInfoReq receive_time
     * @property {string|null} [title] CreateMailInfoReq title
     * @property {string|null} [content] CreateMailInfoReq content
     * @property {string|null} [attachment] CreateMailInfoReq attachment
     * @property {number|null} [mail_type] CreateMailInfoReq mail_type
     * @property {number|null} [system_id] CreateMailInfoReq system_id
     * @property {number|null} [batch_id] CreateMailInfoReq batch_id
     * @property {Uint8Array|null} [trans_data] CreateMailInfoReq trans_data
     */

    /**
     * Constructs a new CreateMailInfoReq.
     * @memberof server_proto
     * @classdesc Represents a CreateMailInfoReq.
     * @implements ICreateMailInfoReq
     * @constructor
     * @param {server_proto.ICreateMailInfoReq=} [properties] Properties to set
     */
    function CreateMailInfoReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateMailInfoReq uid.
     * @member {number} uid
     * @memberof server_proto.CreateMailInfoReq
     * @instance
     */
    CreateMailInfoReq.prototype.uid = 0;

    /**
     * CreateMailInfoReq receive_time.
     * @member {number} receive_time
     * @memberof server_proto.CreateMailInfoReq
     * @instance
     */
    CreateMailInfoReq.prototype.receive_time = 0;

    /**
     * CreateMailInfoReq title.
     * @member {string} title
     * @memberof server_proto.CreateMailInfoReq
     * @instance
     */
    CreateMailInfoReq.prototype.title = "";

    /**
     * CreateMailInfoReq content.
     * @member {string} content
     * @memberof server_proto.CreateMailInfoReq
     * @instance
     */
    CreateMailInfoReq.prototype.content = "";

    /**
     * CreateMailInfoReq attachment.
     * @member {string} attachment
     * @memberof server_proto.CreateMailInfoReq
     * @instance
     */
    CreateMailInfoReq.prototype.attachment = "";

    /**
     * CreateMailInfoReq mail_type.
     * @member {number} mail_type
     * @memberof server_proto.CreateMailInfoReq
     * @instance
     */
    CreateMailInfoReq.prototype.mail_type = 0;

    /**
     * CreateMailInfoReq system_id.
     * @member {number} system_id
     * @memberof server_proto.CreateMailInfoReq
     * @instance
     */
    CreateMailInfoReq.prototype.system_id = 0;

    /**
     * CreateMailInfoReq batch_id.
     * @member {number} batch_id
     * @memberof server_proto.CreateMailInfoReq
     * @instance
     */
    CreateMailInfoReq.prototype.batch_id = 0;

    /**
     * CreateMailInfoReq trans_data.
     * @member {Uint8Array} trans_data
     * @memberof server_proto.CreateMailInfoReq
     * @instance
     */
    CreateMailInfoReq.prototype.trans_data = $util.newBuffer([]);

    /**
     * Creates a new CreateMailInfoReq instance using the specified properties.
     * @function create
     * @memberof server_proto.CreateMailInfoReq
     * @static
     * @param {server_proto.ICreateMailInfoReq=} [properties] Properties to set
     * @returns {server_proto.CreateMailInfoReq} CreateMailInfoReq instance
     */
    CreateMailInfoReq.create = function create(properties) {
      return new CreateMailInfoReq(properties);
    };

    /**
     * Encodes the specified CreateMailInfoReq message. Does not implicitly {@link server_proto.CreateMailInfoReq.verify|verify} messages.
     * @function encode
     * @memberof server_proto.CreateMailInfoReq
     * @static
     * @param {server_proto.ICreateMailInfoReq} message CreateMailInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateMailInfoReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.receive_time != null && Object.hasOwnProperty.call(message, "receive_time"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.receive_time);
      if (message.title != null && Object.hasOwnProperty.call(message, "title"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.title);
      if (message.content != null && Object.hasOwnProperty.call(message, "content"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.content);
      if (message.attachment != null && Object.hasOwnProperty.call(message, "attachment"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.attachment);
      if (message.mail_type != null && Object.hasOwnProperty.call(message, "mail_type"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.mail_type);
      if (message.system_id != null && Object.hasOwnProperty.call(message, "system_id"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.system_id);
      if (message.batch_id != null && Object.hasOwnProperty.call(message, "batch_id"))
        writer.uint32(/* id 8, wireType 0 =*/ 64).int32(message.batch_id);
      if (message.trans_data != null && Object.hasOwnProperty.call(message, "trans_data"))
        writer.uint32(/* id 9, wireType 2 =*/ 74).bytes(message.trans_data);
      return writer;
    };

    /**
     * Encodes the specified CreateMailInfoReq message, length delimited. Does not implicitly {@link server_proto.CreateMailInfoReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.CreateMailInfoReq
     * @static
     * @param {server_proto.ICreateMailInfoReq} message CreateMailInfoReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateMailInfoReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateMailInfoReq message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.CreateMailInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.CreateMailInfoReq} CreateMailInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateMailInfoReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.CreateMailInfoReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.receive_time = reader.uint32();
            break;
          }
          case 3: {
            message.title = reader.string();
            break;
          }
          case 4: {
            message.content = reader.string();
            break;
          }
          case 5: {
            message.attachment = reader.string();
            break;
          }
          case 6: {
            message.mail_type = reader.int32();
            break;
          }
          case 7: {
            message.system_id = reader.int32();
            break;
          }
          case 8: {
            message.batch_id = reader.int32();
            break;
          }
          case 9: {
            message.trans_data = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateMailInfoReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.CreateMailInfoReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.CreateMailInfoReq} CreateMailInfoReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateMailInfoReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateMailInfoReq message.
     * @function verify
     * @memberof server_proto.CreateMailInfoReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateMailInfoReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.receive_time != null && message.hasOwnProperty("receive_time"))
        if (!$util.isInteger(message.receive_time)) return "receive_time: integer expected";
      if (message.title != null && message.hasOwnProperty("title"))
        if (!$util.isString(message.title)) return "title: string expected";
      if (message.content != null && message.hasOwnProperty("content"))
        if (!$util.isString(message.content)) return "content: string expected";
      if (message.attachment != null && message.hasOwnProperty("attachment"))
        if (!$util.isString(message.attachment)) return "attachment: string expected";
      if (message.mail_type != null && message.hasOwnProperty("mail_type"))
        if (!$util.isInteger(message.mail_type)) return "mail_type: integer expected";
      if (message.system_id != null && message.hasOwnProperty("system_id"))
        if (!$util.isInteger(message.system_id)) return "system_id: integer expected";
      if (message.batch_id != null && message.hasOwnProperty("batch_id"))
        if (!$util.isInteger(message.batch_id)) return "batch_id: integer expected";
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        if (
          !((message.trans_data && typeof message.trans_data.length === "number") || $util.isString(message.trans_data))
        )
          return "trans_data: buffer expected";
      return null;
    };

    /**
     * Creates a CreateMailInfoReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.CreateMailInfoReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.CreateMailInfoReq} CreateMailInfoReq
     */
    CreateMailInfoReq.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.CreateMailInfoReq) return object;
      var message = new $root.server_proto.CreateMailInfoReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.receive_time != null) message.receive_time = object.receive_time >>> 0;
      if (object.title != null) message.title = String(object.title);
      if (object.content != null) message.content = String(object.content);
      if (object.attachment != null) message.attachment = String(object.attachment);
      if (object.mail_type != null) message.mail_type = object.mail_type | 0;
      if (object.system_id != null) message.system_id = object.system_id | 0;
      if (object.batch_id != null) message.batch_id = object.batch_id | 0;
      if (object.trans_data != null)
        if (typeof object.trans_data === "string")
          $util.base64.decode(
            object.trans_data,
            (message.trans_data = $util.newBuffer($util.base64.length(object.trans_data))),
            0,
          );
        else if (object.trans_data.length >= 0) message.trans_data = object.trans_data;
      return message;
    };

    /**
     * Creates a plain object from a CreateMailInfoReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.CreateMailInfoReq
     * @static
     * @param {server_proto.CreateMailInfoReq} message CreateMailInfoReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateMailInfoReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.receive_time = 0;
        object.title = "";
        object.content = "";
        object.attachment = "";
        object.mail_type = 0;
        object.system_id = 0;
        object.batch_id = 0;
        if (options.bytes === String) object.trans_data = "";
        else {
          object.trans_data = [];
          if (options.bytes !== Array) object.trans_data = $util.newBuffer(object.trans_data);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.receive_time != null && message.hasOwnProperty("receive_time"))
        object.receive_time = message.receive_time;
      if (message.title != null && message.hasOwnProperty("title")) object.title = message.title;
      if (message.content != null && message.hasOwnProperty("content")) object.content = message.content;
      if (message.attachment != null && message.hasOwnProperty("attachment")) object.attachment = message.attachment;
      if (message.mail_type != null && message.hasOwnProperty("mail_type")) object.mail_type = message.mail_type;
      if (message.system_id != null && message.hasOwnProperty("system_id")) object.system_id = message.system_id;
      if (message.batch_id != null && message.hasOwnProperty("batch_id")) object.batch_id = message.batch_id;
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        object.trans_data =
          options.bytes === String
            ? $util.base64.encode(message.trans_data, 0, message.trans_data.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans_data)
              : message.trans_data;
      return object;
    };

    /**
     * Converts this CreateMailInfoReq to JSON.
     * @function toJSON
     * @memberof server_proto.CreateMailInfoReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateMailInfoReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateMailInfoReq
     * @function getTypeUrl
     * @memberof server_proto.CreateMailInfoReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateMailInfoReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.CreateMailInfoReq";
    };

    return CreateMailInfoReq;
  })();

  server_proto.CreateMailInfoResp = (function () {
    /**
     * Properties of a CreateMailInfoResp.
     * @memberof server_proto
     * @interface ICreateMailInfoResp
     * @property {number|null} [result] CreateMailInfoResp result
     * @property {Uint8Array|null} [trans_data] CreateMailInfoResp trans_data
     */

    /**
     * Constructs a new CreateMailInfoResp.
     * @memberof server_proto
     * @classdesc Represents a CreateMailInfoResp.
     * @implements ICreateMailInfoResp
     * @constructor
     * @param {server_proto.ICreateMailInfoResp=} [properties] Properties to set
     */
    function CreateMailInfoResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateMailInfoResp result.
     * @member {number} result
     * @memberof server_proto.CreateMailInfoResp
     * @instance
     */
    CreateMailInfoResp.prototype.result = 0;

    /**
     * CreateMailInfoResp trans_data.
     * @member {Uint8Array} trans_data
     * @memberof server_proto.CreateMailInfoResp
     * @instance
     */
    CreateMailInfoResp.prototype.trans_data = $util.newBuffer([]);

    /**
     * Creates a new CreateMailInfoResp instance using the specified properties.
     * @function create
     * @memberof server_proto.CreateMailInfoResp
     * @static
     * @param {server_proto.ICreateMailInfoResp=} [properties] Properties to set
     * @returns {server_proto.CreateMailInfoResp} CreateMailInfoResp instance
     */
    CreateMailInfoResp.create = function create(properties) {
      return new CreateMailInfoResp(properties);
    };

    /**
     * Encodes the specified CreateMailInfoResp message. Does not implicitly {@link server_proto.CreateMailInfoResp.verify|verify} messages.
     * @function encode
     * @memberof server_proto.CreateMailInfoResp
     * @static
     * @param {server_proto.ICreateMailInfoResp} message CreateMailInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateMailInfoResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.trans_data != null && Object.hasOwnProperty.call(message, "trans_data"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).bytes(message.trans_data);
      return writer;
    };

    /**
     * Encodes the specified CreateMailInfoResp message, length delimited. Does not implicitly {@link server_proto.CreateMailInfoResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.CreateMailInfoResp
     * @static
     * @param {server_proto.ICreateMailInfoResp} message CreateMailInfoResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateMailInfoResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateMailInfoResp message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.CreateMailInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.CreateMailInfoResp} CreateMailInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateMailInfoResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.CreateMailInfoResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          case 2: {
            message.trans_data = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateMailInfoResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.CreateMailInfoResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.CreateMailInfoResp} CreateMailInfoResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateMailInfoResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateMailInfoResp message.
     * @function verify
     * @memberof server_proto.CreateMailInfoResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateMailInfoResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        if (
          !((message.trans_data && typeof message.trans_data.length === "number") || $util.isString(message.trans_data))
        )
          return "trans_data: buffer expected";
      return null;
    };

    /**
     * Creates a CreateMailInfoResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.CreateMailInfoResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.CreateMailInfoResp} CreateMailInfoResp
     */
    CreateMailInfoResp.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.CreateMailInfoResp) return object;
      var message = new $root.server_proto.CreateMailInfoResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.trans_data != null)
        if (typeof object.trans_data === "string")
          $util.base64.decode(
            object.trans_data,
            (message.trans_data = $util.newBuffer($util.base64.length(object.trans_data))),
            0,
          );
        else if (object.trans_data.length >= 0) message.trans_data = object.trans_data;
      return message;
    };

    /**
     * Creates a plain object from a CreateMailInfoResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.CreateMailInfoResp
     * @static
     * @param {server_proto.CreateMailInfoResp} message CreateMailInfoResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateMailInfoResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        if (options.bytes === String) object.trans_data = "";
        else {
          object.trans_data = [];
          if (options.bytes !== Array) object.trans_data = $util.newBuffer(object.trans_data);
        }
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.trans_data != null && message.hasOwnProperty("trans_data"))
        object.trans_data =
          options.bytes === String
            ? $util.base64.encode(message.trans_data, 0, message.trans_data.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans_data)
              : message.trans_data;
      return object;
    };

    /**
     * Converts this CreateMailInfoResp to JSON.
     * @function toJSON
     * @memberof server_proto.CreateMailInfoResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateMailInfoResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateMailInfoResp
     * @function getTypeUrl
     * @memberof server_proto.CreateMailInfoResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateMailInfoResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.CreateMailInfoResp";
    };

    return CreateMailInfoResp;
  })();

  server_proto.CreateSystemMailReq = (function () {
    /**
     * Properties of a CreateSystemMailReq.
     * @memberof server_proto
     * @interface ICreateSystemMailReq
     * @property {string|null} [system_data] CreateSystemMailReq system_data
     */

    /**
     * Constructs a new CreateSystemMailReq.
     * @memberof server_proto
     * @classdesc Represents a CreateSystemMailReq.
     * @implements ICreateSystemMailReq
     * @constructor
     * @param {server_proto.ICreateSystemMailReq=} [properties] Properties to set
     */
    function CreateSystemMailReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateSystemMailReq system_data.
     * @member {string} system_data
     * @memberof server_proto.CreateSystemMailReq
     * @instance
     */
    CreateSystemMailReq.prototype.system_data = "";

    /**
     * Creates a new CreateSystemMailReq instance using the specified properties.
     * @function create
     * @memberof server_proto.CreateSystemMailReq
     * @static
     * @param {server_proto.ICreateSystemMailReq=} [properties] Properties to set
     * @returns {server_proto.CreateSystemMailReq} CreateSystemMailReq instance
     */
    CreateSystemMailReq.create = function create(properties) {
      return new CreateSystemMailReq(properties);
    };

    /**
     * Encodes the specified CreateSystemMailReq message. Does not implicitly {@link server_proto.CreateSystemMailReq.verify|verify} messages.
     * @function encode
     * @memberof server_proto.CreateSystemMailReq
     * @static
     * @param {server_proto.ICreateSystemMailReq} message CreateSystemMailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateSystemMailReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.system_data != null && Object.hasOwnProperty.call(message, "system_data"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.system_data);
      return writer;
    };

    /**
     * Encodes the specified CreateSystemMailReq message, length delimited. Does not implicitly {@link server_proto.CreateSystemMailReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.CreateSystemMailReq
     * @static
     * @param {server_proto.ICreateSystemMailReq} message CreateSystemMailReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateSystemMailReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateSystemMailReq message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.CreateSystemMailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.CreateSystemMailReq} CreateSystemMailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateSystemMailReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.CreateSystemMailReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.system_data = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateSystemMailReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.CreateSystemMailReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.CreateSystemMailReq} CreateSystemMailReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateSystemMailReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateSystemMailReq message.
     * @function verify
     * @memberof server_proto.CreateSystemMailReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateSystemMailReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.system_data != null && message.hasOwnProperty("system_data"))
        if (!$util.isString(message.system_data)) return "system_data: string expected";
      return null;
    };

    /**
     * Creates a CreateSystemMailReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.CreateSystemMailReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.CreateSystemMailReq} CreateSystemMailReq
     */
    CreateSystemMailReq.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.CreateSystemMailReq) return object;
      var message = new $root.server_proto.CreateSystemMailReq();
      if (object.system_data != null) message.system_data = String(object.system_data);
      return message;
    };

    /**
     * Creates a plain object from a CreateSystemMailReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.CreateSystemMailReq
     * @static
     * @param {server_proto.CreateSystemMailReq} message CreateSystemMailReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateSystemMailReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.system_data = "";
      if (message.system_data != null && message.hasOwnProperty("system_data"))
        object.system_data = message.system_data;
      return object;
    };

    /**
     * Converts this CreateSystemMailReq to JSON.
     * @function toJSON
     * @memberof server_proto.CreateSystemMailReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateSystemMailReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateSystemMailReq
     * @function getTypeUrl
     * @memberof server_proto.CreateSystemMailReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateSystemMailReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.CreateSystemMailReq";
    };

    return CreateSystemMailReq;
  })();

  server_proto.CreateSystemMailResp = (function () {
    /**
     * Properties of a CreateSystemMailResp.
     * @memberof server_proto
     * @interface ICreateSystemMailResp
     * @property {number|null} [result] CreateSystemMailResp result
     */

    /**
     * Constructs a new CreateSystemMailResp.
     * @memberof server_proto
     * @classdesc Represents a CreateSystemMailResp.
     * @implements ICreateSystemMailResp
     * @constructor
     * @param {server_proto.ICreateSystemMailResp=} [properties] Properties to set
     */
    function CreateSystemMailResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateSystemMailResp result.
     * @member {number} result
     * @memberof server_proto.CreateSystemMailResp
     * @instance
     */
    CreateSystemMailResp.prototype.result = 0;

    /**
     * Creates a new CreateSystemMailResp instance using the specified properties.
     * @function create
     * @memberof server_proto.CreateSystemMailResp
     * @static
     * @param {server_proto.ICreateSystemMailResp=} [properties] Properties to set
     * @returns {server_proto.CreateSystemMailResp} CreateSystemMailResp instance
     */
    CreateSystemMailResp.create = function create(properties) {
      return new CreateSystemMailResp(properties);
    };

    /**
     * Encodes the specified CreateSystemMailResp message. Does not implicitly {@link server_proto.CreateSystemMailResp.verify|verify} messages.
     * @function encode
     * @memberof server_proto.CreateSystemMailResp
     * @static
     * @param {server_proto.ICreateSystemMailResp} message CreateSystemMailResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateSystemMailResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified CreateSystemMailResp message, length delimited. Does not implicitly {@link server_proto.CreateSystemMailResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.CreateSystemMailResp
     * @static
     * @param {server_proto.ICreateSystemMailResp} message CreateSystemMailResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateSystemMailResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateSystemMailResp message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.CreateSystemMailResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.CreateSystemMailResp} CreateSystemMailResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateSystemMailResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.CreateSystemMailResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateSystemMailResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.CreateSystemMailResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.CreateSystemMailResp} CreateSystemMailResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateSystemMailResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateSystemMailResp message.
     * @function verify
     * @memberof server_proto.CreateSystemMailResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateSystemMailResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a CreateSystemMailResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.CreateSystemMailResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.CreateSystemMailResp} CreateSystemMailResp
     */
    CreateSystemMailResp.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.CreateSystemMailResp) return object;
      var message = new $root.server_proto.CreateSystemMailResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a CreateSystemMailResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.CreateSystemMailResp
     * @static
     * @param {server_proto.CreateSystemMailResp} message CreateSystemMailResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateSystemMailResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this CreateSystemMailResp to JSON.
     * @function toJSON
     * @memberof server_proto.CreateSystemMailResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateSystemMailResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateSystemMailResp
     * @function getTypeUrl
     * @memberof server_proto.CreateSystemMailResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateSystemMailResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.CreateSystemMailResp";
    };

    return CreateSystemMailResp;
  })();

  server_proto.CreateMailForPHPReq = (function () {
    /**
     * Properties of a CreateMailForPHPReq.
     * @memberof server_proto
     * @interface ICreateMailForPHPReq
     * @property {number|null} [uid] CreateMailForPHPReq uid
     * @property {string|null} [mail_data] CreateMailForPHPReq mail_data
     */

    /**
     * Constructs a new CreateMailForPHPReq.
     * @memberof server_proto
     * @classdesc Represents a CreateMailForPHPReq.
     * @implements ICreateMailForPHPReq
     * @constructor
     * @param {server_proto.ICreateMailForPHPReq=} [properties] Properties to set
     */
    function CreateMailForPHPReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateMailForPHPReq uid.
     * @member {number} uid
     * @memberof server_proto.CreateMailForPHPReq
     * @instance
     */
    CreateMailForPHPReq.prototype.uid = 0;

    /**
     * CreateMailForPHPReq mail_data.
     * @member {string} mail_data
     * @memberof server_proto.CreateMailForPHPReq
     * @instance
     */
    CreateMailForPHPReq.prototype.mail_data = "";

    /**
     * Creates a new CreateMailForPHPReq instance using the specified properties.
     * @function create
     * @memberof server_proto.CreateMailForPHPReq
     * @static
     * @param {server_proto.ICreateMailForPHPReq=} [properties] Properties to set
     * @returns {server_proto.CreateMailForPHPReq} CreateMailForPHPReq instance
     */
    CreateMailForPHPReq.create = function create(properties) {
      return new CreateMailForPHPReq(properties);
    };

    /**
     * Encodes the specified CreateMailForPHPReq message. Does not implicitly {@link server_proto.CreateMailForPHPReq.verify|verify} messages.
     * @function encode
     * @memberof server_proto.CreateMailForPHPReq
     * @static
     * @param {server_proto.ICreateMailForPHPReq} message CreateMailForPHPReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateMailForPHPReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.mail_data != null && Object.hasOwnProperty.call(message, "mail_data"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.mail_data);
      return writer;
    };

    /**
     * Encodes the specified CreateMailForPHPReq message, length delimited. Does not implicitly {@link server_proto.CreateMailForPHPReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.CreateMailForPHPReq
     * @static
     * @param {server_proto.ICreateMailForPHPReq} message CreateMailForPHPReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateMailForPHPReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateMailForPHPReq message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.CreateMailForPHPReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.CreateMailForPHPReq} CreateMailForPHPReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateMailForPHPReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.CreateMailForPHPReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.mail_data = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateMailForPHPReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.CreateMailForPHPReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.CreateMailForPHPReq} CreateMailForPHPReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateMailForPHPReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateMailForPHPReq message.
     * @function verify
     * @memberof server_proto.CreateMailForPHPReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateMailForPHPReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.mail_data != null && message.hasOwnProperty("mail_data"))
        if (!$util.isString(message.mail_data)) return "mail_data: string expected";
      return null;
    };

    /**
     * Creates a CreateMailForPHPReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.CreateMailForPHPReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.CreateMailForPHPReq} CreateMailForPHPReq
     */
    CreateMailForPHPReq.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.CreateMailForPHPReq) return object;
      var message = new $root.server_proto.CreateMailForPHPReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.mail_data != null) message.mail_data = String(object.mail_data);
      return message;
    };

    /**
     * Creates a plain object from a CreateMailForPHPReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.CreateMailForPHPReq
     * @static
     * @param {server_proto.CreateMailForPHPReq} message CreateMailForPHPReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateMailForPHPReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.mail_data = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.mail_data != null && message.hasOwnProperty("mail_data")) object.mail_data = message.mail_data;
      return object;
    };

    /**
     * Converts this CreateMailForPHPReq to JSON.
     * @function toJSON
     * @memberof server_proto.CreateMailForPHPReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateMailForPHPReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateMailForPHPReq
     * @function getTypeUrl
     * @memberof server_proto.CreateMailForPHPReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateMailForPHPReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.CreateMailForPHPReq";
    };

    return CreateMailForPHPReq;
  })();

  server_proto.CreateMailForPHPResp = (function () {
    /**
     * Properties of a CreateMailForPHPResp.
     * @memberof server_proto
     * @interface ICreateMailForPHPResp
     * @property {number|null} [result] CreateMailForPHPResp result
     */

    /**
     * Constructs a new CreateMailForPHPResp.
     * @memberof server_proto
     * @classdesc Represents a CreateMailForPHPResp.
     * @implements ICreateMailForPHPResp
     * @constructor
     * @param {server_proto.ICreateMailForPHPResp=} [properties] Properties to set
     */
    function CreateMailForPHPResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * CreateMailForPHPResp result.
     * @member {number} result
     * @memberof server_proto.CreateMailForPHPResp
     * @instance
     */
    CreateMailForPHPResp.prototype.result = 0;

    /**
     * Creates a new CreateMailForPHPResp instance using the specified properties.
     * @function create
     * @memberof server_proto.CreateMailForPHPResp
     * @static
     * @param {server_proto.ICreateMailForPHPResp=} [properties] Properties to set
     * @returns {server_proto.CreateMailForPHPResp} CreateMailForPHPResp instance
     */
    CreateMailForPHPResp.create = function create(properties) {
      return new CreateMailForPHPResp(properties);
    };

    /**
     * Encodes the specified CreateMailForPHPResp message. Does not implicitly {@link server_proto.CreateMailForPHPResp.verify|verify} messages.
     * @function encode
     * @memberof server_proto.CreateMailForPHPResp
     * @static
     * @param {server_proto.ICreateMailForPHPResp} message CreateMailForPHPResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateMailForPHPResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified CreateMailForPHPResp message, length delimited. Does not implicitly {@link server_proto.CreateMailForPHPResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.CreateMailForPHPResp
     * @static
     * @param {server_proto.ICreateMailForPHPResp} message CreateMailForPHPResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CreateMailForPHPResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CreateMailForPHPResp message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.CreateMailForPHPResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.CreateMailForPHPResp} CreateMailForPHPResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateMailForPHPResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.CreateMailForPHPResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a CreateMailForPHPResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.CreateMailForPHPResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.CreateMailForPHPResp} CreateMailForPHPResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CreateMailForPHPResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CreateMailForPHPResp message.
     * @function verify
     * @memberof server_proto.CreateMailForPHPResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CreateMailForPHPResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a CreateMailForPHPResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.CreateMailForPHPResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.CreateMailForPHPResp} CreateMailForPHPResp
     */
    CreateMailForPHPResp.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.CreateMailForPHPResp) return object;
      var message = new $root.server_proto.CreateMailForPHPResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a CreateMailForPHPResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.CreateMailForPHPResp
     * @static
     * @param {server_proto.CreateMailForPHPResp} message CreateMailForPHPResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CreateMailForPHPResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this CreateMailForPHPResp to JSON.
     * @function toJSON
     * @memberof server_proto.CreateMailForPHPResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CreateMailForPHPResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CreateMailForPHPResp
     * @function getTypeUrl
     * @memberof server_proto.CreateMailForPHPResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CreateMailForPHPResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.CreateMailForPHPResp";
    };

    return CreateMailForPHPResp;
  })();

  server_proto.DelSystemMailBatchReq = (function () {
    /**
     * Properties of a DelSystemMailBatchReq.
     * @memberof server_proto
     * @interface IDelSystemMailBatchReq
     * @property {number|null} [batch_id] DelSystemMailBatchReq batch_id
     */

    /**
     * Constructs a new DelSystemMailBatchReq.
     * @memberof server_proto
     * @classdesc Represents a DelSystemMailBatchReq.
     * @implements IDelSystemMailBatchReq
     * @constructor
     * @param {server_proto.IDelSystemMailBatchReq=} [properties] Properties to set
     */
    function DelSystemMailBatchReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * DelSystemMailBatchReq batch_id.
     * @member {number} batch_id
     * @memberof server_proto.DelSystemMailBatchReq
     * @instance
     */
    DelSystemMailBatchReq.prototype.batch_id = 0;

    /**
     * Creates a new DelSystemMailBatchReq instance using the specified properties.
     * @function create
     * @memberof server_proto.DelSystemMailBatchReq
     * @static
     * @param {server_proto.IDelSystemMailBatchReq=} [properties] Properties to set
     * @returns {server_proto.DelSystemMailBatchReq} DelSystemMailBatchReq instance
     */
    DelSystemMailBatchReq.create = function create(properties) {
      return new DelSystemMailBatchReq(properties);
    };

    /**
     * Encodes the specified DelSystemMailBatchReq message. Does not implicitly {@link server_proto.DelSystemMailBatchReq.verify|verify} messages.
     * @function encode
     * @memberof server_proto.DelSystemMailBatchReq
     * @static
     * @param {server_proto.IDelSystemMailBatchReq} message DelSystemMailBatchReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DelSystemMailBatchReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.batch_id != null && Object.hasOwnProperty.call(message, "batch_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.batch_id);
      return writer;
    };

    /**
     * Encodes the specified DelSystemMailBatchReq message, length delimited. Does not implicitly {@link server_proto.DelSystemMailBatchReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.DelSystemMailBatchReq
     * @static
     * @param {server_proto.IDelSystemMailBatchReq} message DelSystemMailBatchReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DelSystemMailBatchReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DelSystemMailBatchReq message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.DelSystemMailBatchReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.DelSystemMailBatchReq} DelSystemMailBatchReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DelSystemMailBatchReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.DelSystemMailBatchReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.batch_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a DelSystemMailBatchReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.DelSystemMailBatchReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.DelSystemMailBatchReq} DelSystemMailBatchReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DelSystemMailBatchReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DelSystemMailBatchReq message.
     * @function verify
     * @memberof server_proto.DelSystemMailBatchReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DelSystemMailBatchReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.batch_id != null && message.hasOwnProperty("batch_id"))
        if (!$util.isInteger(message.batch_id)) return "batch_id: integer expected";
      return null;
    };

    /**
     * Creates a DelSystemMailBatchReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.DelSystemMailBatchReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.DelSystemMailBatchReq} DelSystemMailBatchReq
     */
    DelSystemMailBatchReq.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.DelSystemMailBatchReq) return object;
      var message = new $root.server_proto.DelSystemMailBatchReq();
      if (object.batch_id != null) message.batch_id = object.batch_id >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a DelSystemMailBatchReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.DelSystemMailBatchReq
     * @static
     * @param {server_proto.DelSystemMailBatchReq} message DelSystemMailBatchReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DelSystemMailBatchReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.batch_id = 0;
      if (message.batch_id != null && message.hasOwnProperty("batch_id")) object.batch_id = message.batch_id;
      return object;
    };

    /**
     * Converts this DelSystemMailBatchReq to JSON.
     * @function toJSON
     * @memberof server_proto.DelSystemMailBatchReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DelSystemMailBatchReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DelSystemMailBatchReq
     * @function getTypeUrl
     * @memberof server_proto.DelSystemMailBatchReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DelSystemMailBatchReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.DelSystemMailBatchReq";
    };

    return DelSystemMailBatchReq;
  })();

  server_proto.DelSystemMailBatchResp = (function () {
    /**
     * Properties of a DelSystemMailBatchResp.
     * @memberof server_proto
     * @interface IDelSystemMailBatchResp
     * @property {number|null} [result] DelSystemMailBatchResp result
     */

    /**
     * Constructs a new DelSystemMailBatchResp.
     * @memberof server_proto
     * @classdesc Represents a DelSystemMailBatchResp.
     * @implements IDelSystemMailBatchResp
     * @constructor
     * @param {server_proto.IDelSystemMailBatchResp=} [properties] Properties to set
     */
    function DelSystemMailBatchResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * DelSystemMailBatchResp result.
     * @member {number} result
     * @memberof server_proto.DelSystemMailBatchResp
     * @instance
     */
    DelSystemMailBatchResp.prototype.result = 0;

    /**
     * Creates a new DelSystemMailBatchResp instance using the specified properties.
     * @function create
     * @memberof server_proto.DelSystemMailBatchResp
     * @static
     * @param {server_proto.IDelSystemMailBatchResp=} [properties] Properties to set
     * @returns {server_proto.DelSystemMailBatchResp} DelSystemMailBatchResp instance
     */
    DelSystemMailBatchResp.create = function create(properties) {
      return new DelSystemMailBatchResp(properties);
    };

    /**
     * Encodes the specified DelSystemMailBatchResp message. Does not implicitly {@link server_proto.DelSystemMailBatchResp.verify|verify} messages.
     * @function encode
     * @memberof server_proto.DelSystemMailBatchResp
     * @static
     * @param {server_proto.IDelSystemMailBatchResp} message DelSystemMailBatchResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DelSystemMailBatchResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified DelSystemMailBatchResp message, length delimited. Does not implicitly {@link server_proto.DelSystemMailBatchResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.DelSystemMailBatchResp
     * @static
     * @param {server_proto.IDelSystemMailBatchResp} message DelSystemMailBatchResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DelSystemMailBatchResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DelSystemMailBatchResp message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.DelSystemMailBatchResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.DelSystemMailBatchResp} DelSystemMailBatchResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DelSystemMailBatchResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.DelSystemMailBatchResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a DelSystemMailBatchResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.DelSystemMailBatchResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.DelSystemMailBatchResp} DelSystemMailBatchResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DelSystemMailBatchResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DelSystemMailBatchResp message.
     * @function verify
     * @memberof server_proto.DelSystemMailBatchResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DelSystemMailBatchResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a DelSystemMailBatchResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.DelSystemMailBatchResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.DelSystemMailBatchResp} DelSystemMailBatchResp
     */
    DelSystemMailBatchResp.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.DelSystemMailBatchResp) return object;
      var message = new $root.server_proto.DelSystemMailBatchResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a DelSystemMailBatchResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.DelSystemMailBatchResp
     * @static
     * @param {server_proto.DelSystemMailBatchResp} message DelSystemMailBatchResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DelSystemMailBatchResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this DelSystemMailBatchResp to JSON.
     * @function toJSON
     * @memberof server_proto.DelSystemMailBatchResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DelSystemMailBatchResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DelSystemMailBatchResp
     * @function getTypeUrl
     * @memberof server_proto.DelSystemMailBatchResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DelSystemMailBatchResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.DelSystemMailBatchResp";
    };

    return DelSystemMailBatchResp;
  })();

  server_proto.StopSystemMailBatchReq = (function () {
    /**
     * Properties of a StopSystemMailBatchReq.
     * @memberof server_proto
     * @interface IStopSystemMailBatchReq
     * @property {number|null} [batch_id] StopSystemMailBatchReq batch_id
     */

    /**
     * Constructs a new StopSystemMailBatchReq.
     * @memberof server_proto
     * @classdesc Represents a StopSystemMailBatchReq.
     * @implements IStopSystemMailBatchReq
     * @constructor
     * @param {server_proto.IStopSystemMailBatchReq=} [properties] Properties to set
     */
    function StopSystemMailBatchReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * StopSystemMailBatchReq batch_id.
     * @member {number} batch_id
     * @memberof server_proto.StopSystemMailBatchReq
     * @instance
     */
    StopSystemMailBatchReq.prototype.batch_id = 0;

    /**
     * Creates a new StopSystemMailBatchReq instance using the specified properties.
     * @function create
     * @memberof server_proto.StopSystemMailBatchReq
     * @static
     * @param {server_proto.IStopSystemMailBatchReq=} [properties] Properties to set
     * @returns {server_proto.StopSystemMailBatchReq} StopSystemMailBatchReq instance
     */
    StopSystemMailBatchReq.create = function create(properties) {
      return new StopSystemMailBatchReq(properties);
    };

    /**
     * Encodes the specified StopSystemMailBatchReq message. Does not implicitly {@link server_proto.StopSystemMailBatchReq.verify|verify} messages.
     * @function encode
     * @memberof server_proto.StopSystemMailBatchReq
     * @static
     * @param {server_proto.IStopSystemMailBatchReq} message StopSystemMailBatchReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StopSystemMailBatchReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.batch_id != null && Object.hasOwnProperty.call(message, "batch_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.batch_id);
      return writer;
    };

    /**
     * Encodes the specified StopSystemMailBatchReq message, length delimited. Does not implicitly {@link server_proto.StopSystemMailBatchReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.StopSystemMailBatchReq
     * @static
     * @param {server_proto.IStopSystemMailBatchReq} message StopSystemMailBatchReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StopSystemMailBatchReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StopSystemMailBatchReq message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.StopSystemMailBatchReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.StopSystemMailBatchReq} StopSystemMailBatchReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StopSystemMailBatchReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.StopSystemMailBatchReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.batch_id = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a StopSystemMailBatchReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.StopSystemMailBatchReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.StopSystemMailBatchReq} StopSystemMailBatchReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StopSystemMailBatchReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StopSystemMailBatchReq message.
     * @function verify
     * @memberof server_proto.StopSystemMailBatchReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StopSystemMailBatchReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.batch_id != null && message.hasOwnProperty("batch_id"))
        if (!$util.isInteger(message.batch_id)) return "batch_id: integer expected";
      return null;
    };

    /**
     * Creates a StopSystemMailBatchReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.StopSystemMailBatchReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.StopSystemMailBatchReq} StopSystemMailBatchReq
     */
    StopSystemMailBatchReq.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.StopSystemMailBatchReq) return object;
      var message = new $root.server_proto.StopSystemMailBatchReq();
      if (object.batch_id != null) message.batch_id = object.batch_id >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a StopSystemMailBatchReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.StopSystemMailBatchReq
     * @static
     * @param {server_proto.StopSystemMailBatchReq} message StopSystemMailBatchReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StopSystemMailBatchReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.batch_id = 0;
      if (message.batch_id != null && message.hasOwnProperty("batch_id")) object.batch_id = message.batch_id;
      return object;
    };

    /**
     * Converts this StopSystemMailBatchReq to JSON.
     * @function toJSON
     * @memberof server_proto.StopSystemMailBatchReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StopSystemMailBatchReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StopSystemMailBatchReq
     * @function getTypeUrl
     * @memberof server_proto.StopSystemMailBatchReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StopSystemMailBatchReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.StopSystemMailBatchReq";
    };

    return StopSystemMailBatchReq;
  })();

  server_proto.StopSystemMailBatchResp = (function () {
    /**
     * Properties of a StopSystemMailBatchResp.
     * @memberof server_proto
     * @interface IStopSystemMailBatchResp
     * @property {number|null} [result] StopSystemMailBatchResp result
     */

    /**
     * Constructs a new StopSystemMailBatchResp.
     * @memberof server_proto
     * @classdesc Represents a StopSystemMailBatchResp.
     * @implements IStopSystemMailBatchResp
     * @constructor
     * @param {server_proto.IStopSystemMailBatchResp=} [properties] Properties to set
     */
    function StopSystemMailBatchResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * StopSystemMailBatchResp result.
     * @member {number} result
     * @memberof server_proto.StopSystemMailBatchResp
     * @instance
     */
    StopSystemMailBatchResp.prototype.result = 0;

    /**
     * Creates a new StopSystemMailBatchResp instance using the specified properties.
     * @function create
     * @memberof server_proto.StopSystemMailBatchResp
     * @static
     * @param {server_proto.IStopSystemMailBatchResp=} [properties] Properties to set
     * @returns {server_proto.StopSystemMailBatchResp} StopSystemMailBatchResp instance
     */
    StopSystemMailBatchResp.create = function create(properties) {
      return new StopSystemMailBatchResp(properties);
    };

    /**
     * Encodes the specified StopSystemMailBatchResp message. Does not implicitly {@link server_proto.StopSystemMailBatchResp.verify|verify} messages.
     * @function encode
     * @memberof server_proto.StopSystemMailBatchResp
     * @static
     * @param {server_proto.IStopSystemMailBatchResp} message StopSystemMailBatchResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StopSystemMailBatchResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified StopSystemMailBatchResp message, length delimited. Does not implicitly {@link server_proto.StopSystemMailBatchResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.StopSystemMailBatchResp
     * @static
     * @param {server_proto.IStopSystemMailBatchResp} message StopSystemMailBatchResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StopSystemMailBatchResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a StopSystemMailBatchResp message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.StopSystemMailBatchResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.StopSystemMailBatchResp} StopSystemMailBatchResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StopSystemMailBatchResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.StopSystemMailBatchResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.result = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a StopSystemMailBatchResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.StopSystemMailBatchResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.StopSystemMailBatchResp} StopSystemMailBatchResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StopSystemMailBatchResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a StopSystemMailBatchResp message.
     * @function verify
     * @memberof server_proto.StopSystemMailBatchResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    StopSystemMailBatchResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a StopSystemMailBatchResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.StopSystemMailBatchResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.StopSystemMailBatchResp} StopSystemMailBatchResp
     */
    StopSystemMailBatchResp.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.StopSystemMailBatchResp) return object;
      var message = new $root.server_proto.StopSystemMailBatchResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a StopSystemMailBatchResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.StopSystemMailBatchResp
     * @static
     * @param {server_proto.StopSystemMailBatchResp} message StopSystemMailBatchResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    StopSystemMailBatchResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this StopSystemMailBatchResp to JSON.
     * @function toJSON
     * @memberof server_proto.StopSystemMailBatchResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    StopSystemMailBatchResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for StopSystemMailBatchResp
     * @function getTypeUrl
     * @memberof server_proto.StopSystemMailBatchResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    StopSystemMailBatchResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.StopSystemMailBatchResp";
    };

    return StopSystemMailBatchResp;
  })();

  server_proto.MailNotifyUserReceive = (function () {
    /**
     * Properties of a MailNotifyUserReceive.
     * @memberof server_proto
     * @interface IMailNotifyUserReceive
     * @property {number|null} [uid] MailNotifyUserReceive uid
     * @property {string|null} [param] MailNotifyUserReceive param
     */

    /**
     * Constructs a new MailNotifyUserReceive.
     * @memberof server_proto
     * @classdesc Represents a MailNotifyUserReceive.
     * @implements IMailNotifyUserReceive
     * @constructor
     * @param {server_proto.IMailNotifyUserReceive=} [properties] Properties to set
     */
    function MailNotifyUserReceive(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * MailNotifyUserReceive uid.
     * @member {number} uid
     * @memberof server_proto.MailNotifyUserReceive
     * @instance
     */
    MailNotifyUserReceive.prototype.uid = 0;

    /**
     * MailNotifyUserReceive param.
     * @member {string} param
     * @memberof server_proto.MailNotifyUserReceive
     * @instance
     */
    MailNotifyUserReceive.prototype.param = "";

    /**
     * Creates a new MailNotifyUserReceive instance using the specified properties.
     * @function create
     * @memberof server_proto.MailNotifyUserReceive
     * @static
     * @param {server_proto.IMailNotifyUserReceive=} [properties] Properties to set
     * @returns {server_proto.MailNotifyUserReceive} MailNotifyUserReceive instance
     */
    MailNotifyUserReceive.create = function create(properties) {
      return new MailNotifyUserReceive(properties);
    };

    /**
     * Encodes the specified MailNotifyUserReceive message. Does not implicitly {@link server_proto.MailNotifyUserReceive.verify|verify} messages.
     * @function encode
     * @memberof server_proto.MailNotifyUserReceive
     * @static
     * @param {server_proto.IMailNotifyUserReceive} message MailNotifyUserReceive message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailNotifyUserReceive.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.param != null && Object.hasOwnProperty.call(message, "param"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.param);
      return writer;
    };

    /**
     * Encodes the specified MailNotifyUserReceive message, length delimited. Does not implicitly {@link server_proto.MailNotifyUserReceive.verify|verify} messages.
     * @function encodeDelimited
     * @memberof server_proto.MailNotifyUserReceive
     * @static
     * @param {server_proto.IMailNotifyUserReceive} message MailNotifyUserReceive message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MailNotifyUserReceive.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a MailNotifyUserReceive message from the specified reader or buffer.
     * @function decode
     * @memberof server_proto.MailNotifyUserReceive
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {server_proto.MailNotifyUserReceive} MailNotifyUserReceive
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailNotifyUserReceive.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.server_proto.MailNotifyUserReceive();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.param = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a MailNotifyUserReceive message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof server_proto.MailNotifyUserReceive
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {server_proto.MailNotifyUserReceive} MailNotifyUserReceive
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MailNotifyUserReceive.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a MailNotifyUserReceive message.
     * @function verify
     * @memberof server_proto.MailNotifyUserReceive
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    MailNotifyUserReceive.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.param != null && message.hasOwnProperty("param"))
        if (!$util.isString(message.param)) return "param: string expected";
      return null;
    };

    /**
     * Creates a MailNotifyUserReceive message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof server_proto.MailNotifyUserReceive
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {server_proto.MailNotifyUserReceive} MailNotifyUserReceive
     */
    MailNotifyUserReceive.fromObject = function fromObject(object) {
      if (object instanceof $root.server_proto.MailNotifyUserReceive) return object;
      var message = new $root.server_proto.MailNotifyUserReceive();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.param != null) message.param = String(object.param);
      return message;
    };

    /**
     * Creates a plain object from a MailNotifyUserReceive message. Also converts values to other types if specified.
     * @function toObject
     * @memberof server_proto.MailNotifyUserReceive
     * @static
     * @param {server_proto.MailNotifyUserReceive} message MailNotifyUserReceive
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    MailNotifyUserReceive.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.param = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.param != null && message.hasOwnProperty("param")) object.param = message.param;
      return object;
    };

    /**
     * Converts this MailNotifyUserReceive to JSON.
     * @function toJSON
     * @memberof server_proto.MailNotifyUserReceive
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    MailNotifyUserReceive.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for MailNotifyUserReceive
     * @function getTypeUrl
     * @memberof server_proto.MailNotifyUserReceive
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    MailNotifyUserReceive.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/server_proto.MailNotifyUserReceive";
    };

    return MailNotifyUserReceive;
  })();

  return server_proto;
})();

$root.game_proxy_mng_proto = (function () {
  /**
   * Namespace game_proxy_mng_proto.
   * @exports game_proxy_mng_proto
   * @namespace
   */
  var game_proxy_mng_proto = {};

  /**
   * GAME_PROXY_MNG_SUB_MSG_ID enum.
   * @name game_proxy_mng_proto.GAME_PROXY_MNG_SUB_MSG_ID
   * @enum {number}
   * @property {number} GAME_PROXY_MNG_MSG_ID_NULL=0 GAME_PROXY_MNG_MSG_ID_NULL value
   * @property {number} GAME_PROXY_MNG_MSG_ID_MIN=2200 GAME_PROXY_MNG_MSG_ID_MIN value
   * @property {number} GAME_PROXY_MNG_DISPATCH_SERVICE_REQ=2201 GAME_PROXY_MNG_DISPATCH_SERVICE_REQ value
   * @property {number} GAME_PROXY_MNG_DISPATCH_SERVICE_RESP=2202 GAME_PROXY_MNG_DISPATCH_SERVICE_RESP value
   * @property {number} GAME_PROXY_MNG_MSG_ID_MAX=2250 GAME_PROXY_MNG_MSG_ID_MAX value
   */
  game_proxy_mng_proto.GAME_PROXY_MNG_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "GAME_PROXY_MNG_MSG_ID_NULL")] = 0;
    values[(valuesById[2200] = "GAME_PROXY_MNG_MSG_ID_MIN")] = 2200;
    values[(valuesById[2201] = "GAME_PROXY_MNG_DISPATCH_SERVICE_REQ")] = 2201;
    values[(valuesById[2202] = "GAME_PROXY_MNG_DISPATCH_SERVICE_RESP")] = 2202;
    values[(valuesById[2250] = "GAME_PROXY_MNG_MSG_ID_MAX")] = 2250;
    return values;
  })();

  game_proxy_mng_proto.DispatchServiceReq = (function () {
    /**
     * Properties of a DispatchServiceReq.
     * @memberof game_proxy_mng_proto
     * @interface IDispatchServiceReq
     * @property {number|null} [uid] DispatchServiceReq uid
     * @property {number|null} [service_type] DispatchServiceReq service_type
     */

    /**
     * Constructs a new DispatchServiceReq.
     * @memberof game_proxy_mng_proto
     * @classdesc Represents a DispatchServiceReq.
     * @implements IDispatchServiceReq
     * @constructor
     * @param {game_proxy_mng_proto.IDispatchServiceReq=} [properties] Properties to set
     */
    function DispatchServiceReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * DispatchServiceReq uid.
     * @member {number} uid
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @instance
     */
    DispatchServiceReq.prototype.uid = 0;

    /**
     * DispatchServiceReq service_type.
     * @member {number} service_type
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @instance
     */
    DispatchServiceReq.prototype.service_type = 0;

    /**
     * Creates a new DispatchServiceReq instance using the specified properties.
     * @function create
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @static
     * @param {game_proxy_mng_proto.IDispatchServiceReq=} [properties] Properties to set
     * @returns {game_proxy_mng_proto.DispatchServiceReq} DispatchServiceReq instance
     */
    DispatchServiceReq.create = function create(properties) {
      return new DispatchServiceReq(properties);
    };

    /**
     * Encodes the specified DispatchServiceReq message. Does not implicitly {@link game_proxy_mng_proto.DispatchServiceReq.verify|verify} messages.
     * @function encode
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @static
     * @param {game_proxy_mng_proto.IDispatchServiceReq} message DispatchServiceReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DispatchServiceReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.service_type != null && Object.hasOwnProperty.call(message, "service_type"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.service_type);
      return writer;
    };

    /**
     * Encodes the specified DispatchServiceReq message, length delimited. Does not implicitly {@link game_proxy_mng_proto.DispatchServiceReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @static
     * @param {game_proxy_mng_proto.IDispatchServiceReq} message DispatchServiceReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DispatchServiceReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DispatchServiceReq message from the specified reader or buffer.
     * @function decode
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_proxy_mng_proto.DispatchServiceReq} DispatchServiceReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DispatchServiceReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_proxy_mng_proto.DispatchServiceReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.service_type = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a DispatchServiceReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_proxy_mng_proto.DispatchServiceReq} DispatchServiceReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DispatchServiceReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DispatchServiceReq message.
     * @function verify
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DispatchServiceReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.service_type != null && message.hasOwnProperty("service_type"))
        if (!$util.isInteger(message.service_type)) return "service_type: integer expected";
      return null;
    };

    /**
     * Creates a DispatchServiceReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_proxy_mng_proto.DispatchServiceReq} DispatchServiceReq
     */
    DispatchServiceReq.fromObject = function fromObject(object) {
      if (object instanceof $root.game_proxy_mng_proto.DispatchServiceReq) return object;
      var message = new $root.game_proxy_mng_proto.DispatchServiceReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.service_type != null) message.service_type = object.service_type | 0;
      return message;
    };

    /**
     * Creates a plain object from a DispatchServiceReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @static
     * @param {game_proxy_mng_proto.DispatchServiceReq} message DispatchServiceReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DispatchServiceReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.service_type = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.service_type != null && message.hasOwnProperty("service_type"))
        object.service_type = message.service_type;
      return object;
    };

    /**
     * Converts this DispatchServiceReq to JSON.
     * @function toJSON
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DispatchServiceReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DispatchServiceReq
     * @function getTypeUrl
     * @memberof game_proxy_mng_proto.DispatchServiceReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DispatchServiceReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_proxy_mng_proto.DispatchServiceReq";
    };

    return DispatchServiceReq;
  })();

  game_proxy_mng_proto.DispatchServiceResp = (function () {
    /**
     * Properties of a DispatchServiceResp.
     * @memberof game_proxy_mng_proto
     * @interface IDispatchServiceResp
     * @property {number|null} [service_id] DispatchServiceResp service_id
     */

    /**
     * Constructs a new DispatchServiceResp.
     * @memberof game_proxy_mng_proto
     * @classdesc Represents a DispatchServiceResp.
     * @implements IDispatchServiceResp
     * @constructor
     * @param {game_proxy_mng_proto.IDispatchServiceResp=} [properties] Properties to set
     */
    function DispatchServiceResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * DispatchServiceResp service_id.
     * @member {number} service_id
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @instance
     */
    DispatchServiceResp.prototype.service_id = 0;

    /**
     * Creates a new DispatchServiceResp instance using the specified properties.
     * @function create
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @static
     * @param {game_proxy_mng_proto.IDispatchServiceResp=} [properties] Properties to set
     * @returns {game_proxy_mng_proto.DispatchServiceResp} DispatchServiceResp instance
     */
    DispatchServiceResp.create = function create(properties) {
      return new DispatchServiceResp(properties);
    };

    /**
     * Encodes the specified DispatchServiceResp message. Does not implicitly {@link game_proxy_mng_proto.DispatchServiceResp.verify|verify} messages.
     * @function encode
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @static
     * @param {game_proxy_mng_proto.IDispatchServiceResp} message DispatchServiceResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DispatchServiceResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.service_id != null && Object.hasOwnProperty.call(message, "service_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.service_id);
      return writer;
    };

    /**
     * Encodes the specified DispatchServiceResp message, length delimited. Does not implicitly {@link game_proxy_mng_proto.DispatchServiceResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @static
     * @param {game_proxy_mng_proto.IDispatchServiceResp} message DispatchServiceResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DispatchServiceResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DispatchServiceResp message from the specified reader or buffer.
     * @function decode
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {game_proxy_mng_proto.DispatchServiceResp} DispatchServiceResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DispatchServiceResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.game_proxy_mng_proto.DispatchServiceResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.service_id = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a DispatchServiceResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {game_proxy_mng_proto.DispatchServiceResp} DispatchServiceResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DispatchServiceResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DispatchServiceResp message.
     * @function verify
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DispatchServiceResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.service_id != null && message.hasOwnProperty("service_id"))
        if (!$util.isInteger(message.service_id)) return "service_id: integer expected";
      return null;
    };

    /**
     * Creates a DispatchServiceResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {game_proxy_mng_proto.DispatchServiceResp} DispatchServiceResp
     */
    DispatchServiceResp.fromObject = function fromObject(object) {
      if (object instanceof $root.game_proxy_mng_proto.DispatchServiceResp) return object;
      var message = new $root.game_proxy_mng_proto.DispatchServiceResp();
      if (object.service_id != null) message.service_id = object.service_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a DispatchServiceResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @static
     * @param {game_proxy_mng_proto.DispatchServiceResp} message DispatchServiceResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DispatchServiceResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.service_id = 0;
      if (message.service_id != null && message.hasOwnProperty("service_id")) object.service_id = message.service_id;
      return object;
    };

    /**
     * Converts this DispatchServiceResp to JSON.
     * @function toJSON
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DispatchServiceResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DispatchServiceResp
     * @function getTypeUrl
     * @memberof game_proxy_mng_proto.DispatchServiceResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DispatchServiceResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/game_proxy_mng_proto.DispatchServiceResp";
    };

    return DispatchServiceResp;
  })();

  return game_proxy_mng_proto;
})();

$root.tgame_proto = (function () {
  /**
   * Namespace tgame_proto.
   * @exports tgame_proto
   * @namespace
   */
  var tgame_proto = {};

  /**
   * TGAME_SUB_MSG_ID enum.
   * @name tgame_proto.TGAME_SUB_MSG_ID
   * @enum {number}
   * @property {number} TGAME_MSG_ID_NULL=0 TGAME_MSG_ID_NULL value
   * @property {number} TGAME_MSG_ID_MIN=2350 TGAME_MSG_ID_MIN value
   * @property {number} TGAME_LOGIN_REQ=2351 TGAME_LOGIN_REQ value
   * @property {number} TGAME_LOGIN_RESP=2352 TGAME_LOGIN_RESP value
   * @property {number} TGAME_LOGOUT_REQ=2353 TGAME_LOGOUT_REQ value
   * @property {number} TGAME_LOGOUT_RESP=2354 TGAME_LOGOUT_RESP value
   * @property {number} TGAME_KICKOUT_PUSH=2360 TGAME_KICKOUT_PUSH value
   * @property {number} TGAME_MSG_ID_MAX=2400 TGAME_MSG_ID_MAX value
   */
  tgame_proto.TGAME_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "TGAME_MSG_ID_NULL")] = 0;
    values[(valuesById[2350] = "TGAME_MSG_ID_MIN")] = 2350;
    values[(valuesById[2351] = "TGAME_LOGIN_REQ")] = 2351;
    values[(valuesById[2352] = "TGAME_LOGIN_RESP")] = 2352;
    values[(valuesById[2353] = "TGAME_LOGOUT_REQ")] = 2353;
    values[(valuesById[2354] = "TGAME_LOGOUT_RESP")] = 2354;
    values[(valuesById[2360] = "TGAME_KICKOUT_PUSH")] = 2360;
    values[(valuesById[2400] = "TGAME_MSG_ID_MAX")] = 2400;
    return values;
  })();

  tgame_proto.LoginReq = (function () {
    /**
     * Properties of a LoginReq.
     * @memberof tgame_proto
     * @interface ILoginReq
     * @property {number|null} [uid] LoginReq uid
     * @property {string|null} [plat] LoginReq plat
     * @property {string|null} [game_uid] LoginReq game_uid
     * @property {string|null} [extern_json] LoginReq extern_json
     */

    /**
     * Constructs a new LoginReq.
     * @memberof tgame_proto
     * @classdesc Represents a LoginReq.
     * @implements ILoginReq
     * @constructor
     * @param {tgame_proto.ILoginReq=} [properties] Properties to set
     */
    function LoginReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginReq uid.
     * @member {number} uid
     * @memberof tgame_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.uid = 0;

    /**
     * LoginReq plat.
     * @member {string} plat
     * @memberof tgame_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.plat = "";

    /**
     * LoginReq game_uid.
     * @member {string} game_uid
     * @memberof tgame_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.game_uid = "";

    /**
     * LoginReq extern_json.
     * @member {string} extern_json
     * @memberof tgame_proto.LoginReq
     * @instance
     */
    LoginReq.prototype.extern_json = "";

    /**
     * Creates a new LoginReq instance using the specified properties.
     * @function create
     * @memberof tgame_proto.LoginReq
     * @static
     * @param {tgame_proto.ILoginReq=} [properties] Properties to set
     * @returns {tgame_proto.LoginReq} LoginReq instance
     */
    LoginReq.create = function create(properties) {
      return new LoginReq(properties);
    };

    /**
     * Encodes the specified LoginReq message. Does not implicitly {@link tgame_proto.LoginReq.verify|verify} messages.
     * @function encode
     * @memberof tgame_proto.LoginReq
     * @static
     * @param {tgame_proto.ILoginReq} message LoginReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.plat != null && Object.hasOwnProperty.call(message, "plat"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.plat);
      if (message.game_uid != null && Object.hasOwnProperty.call(message, "game_uid"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.game_uid);
      if (message.extern_json != null && Object.hasOwnProperty.call(message, "extern_json"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).string(message.extern_json);
      return writer;
    };

    /**
     * Encodes the specified LoginReq message, length delimited. Does not implicitly {@link tgame_proto.LoginReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof tgame_proto.LoginReq
     * @static
     * @param {tgame_proto.ILoginReq} message LoginReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LoginReq message from the specified reader or buffer.
     * @function decode
     * @memberof tgame_proto.LoginReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {tgame_proto.LoginReq} LoginReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.tgame_proto.LoginReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.plat = reader.string();
            break;
          }
          case 3: {
            message.game_uid = reader.string();
            break;
          }
          case 4: {
            message.extern_json = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LoginReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof tgame_proto.LoginReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {tgame_proto.LoginReq} LoginReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LoginReq message.
     * @function verify
     * @memberof tgame_proto.LoginReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.plat != null && message.hasOwnProperty("plat"))
        if (!$util.isString(message.plat)) return "plat: string expected";
      if (message.game_uid != null && message.hasOwnProperty("game_uid"))
        if (!$util.isString(message.game_uid)) return "game_uid: string expected";
      if (message.extern_json != null && message.hasOwnProperty("extern_json"))
        if (!$util.isString(message.extern_json)) return "extern_json: string expected";
      return null;
    };

    /**
     * Creates a LoginReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof tgame_proto.LoginReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {tgame_proto.LoginReq} LoginReq
     */
    LoginReq.fromObject = function fromObject(object) {
      if (object instanceof $root.tgame_proto.LoginReq) return object;
      var message = new $root.tgame_proto.LoginReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.plat != null) message.plat = String(object.plat);
      if (object.game_uid != null) message.game_uid = String(object.game_uid);
      if (object.extern_json != null) message.extern_json = String(object.extern_json);
      return message;
    };

    /**
     * Creates a plain object from a LoginReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof tgame_proto.LoginReq
     * @static
     * @param {tgame_proto.LoginReq} message LoginReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.plat = "";
        object.game_uid = "";
        object.extern_json = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.plat != null && message.hasOwnProperty("plat")) object.plat = message.plat;
      if (message.game_uid != null && message.hasOwnProperty("game_uid")) object.game_uid = message.game_uid;
      if (message.extern_json != null && message.hasOwnProperty("extern_json"))
        object.extern_json = message.extern_json;
      return object;
    };

    /**
     * Converts this LoginReq to JSON.
     * @function toJSON
     * @memberof tgame_proto.LoginReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LoginReq
     * @function getTypeUrl
     * @memberof tgame_proto.LoginReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LoginReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/tgame_proto.LoginReq";
    };

    return LoginReq;
  })();

  tgame_proto.LoginResp = (function () {
    /**
     * Properties of a LoginResp.
     * @memberof tgame_proto
     * @interface ILoginResp
     * @property {string|null} [game_launch_url] LoginResp game_launch_url
     * @property {string|null} [extern_json] LoginResp extern_json
     */

    /**
     * Constructs a new LoginResp.
     * @memberof tgame_proto
     * @classdesc Represents a LoginResp.
     * @implements ILoginResp
     * @constructor
     * @param {tgame_proto.ILoginResp=} [properties] Properties to set
     */
    function LoginResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginResp game_launch_url.
     * @member {string} game_launch_url
     * @memberof tgame_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.game_launch_url = "";

    /**
     * LoginResp extern_json.
     * @member {string} extern_json
     * @memberof tgame_proto.LoginResp
     * @instance
     */
    LoginResp.prototype.extern_json = "";

    /**
     * Creates a new LoginResp instance using the specified properties.
     * @function create
     * @memberof tgame_proto.LoginResp
     * @static
     * @param {tgame_proto.ILoginResp=} [properties] Properties to set
     * @returns {tgame_proto.LoginResp} LoginResp instance
     */
    LoginResp.create = function create(properties) {
      return new LoginResp(properties);
    };

    /**
     * Encodes the specified LoginResp message. Does not implicitly {@link tgame_proto.LoginResp.verify|verify} messages.
     * @function encode
     * @memberof tgame_proto.LoginResp
     * @static
     * @param {tgame_proto.ILoginResp} message LoginResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_launch_url != null && Object.hasOwnProperty.call(message, "game_launch_url"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.game_launch_url);
      if (message.extern_json != null && Object.hasOwnProperty.call(message, "extern_json"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.extern_json);
      return writer;
    };

    /**
     * Encodes the specified LoginResp message, length delimited. Does not implicitly {@link tgame_proto.LoginResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof tgame_proto.LoginResp
     * @static
     * @param {tgame_proto.ILoginResp} message LoginResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LoginResp message from the specified reader or buffer.
     * @function decode
     * @memberof tgame_proto.LoginResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {tgame_proto.LoginResp} LoginResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.tgame_proto.LoginResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.game_launch_url = reader.string();
            break;
          }
          case 2: {
            message.extern_json = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LoginResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof tgame_proto.LoginResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {tgame_proto.LoginResp} LoginResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LoginResp message.
     * @function verify
     * @memberof tgame_proto.LoginResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LoginResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_launch_url != null && message.hasOwnProperty("game_launch_url"))
        if (!$util.isString(message.game_launch_url)) return "game_launch_url: string expected";
      if (message.extern_json != null && message.hasOwnProperty("extern_json"))
        if (!$util.isString(message.extern_json)) return "extern_json: string expected";
      return null;
    };

    /**
     * Creates a LoginResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof tgame_proto.LoginResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {tgame_proto.LoginResp} LoginResp
     */
    LoginResp.fromObject = function fromObject(object) {
      if (object instanceof $root.tgame_proto.LoginResp) return object;
      var message = new $root.tgame_proto.LoginResp();
      if (object.game_launch_url != null) message.game_launch_url = String(object.game_launch_url);
      if (object.extern_json != null) message.extern_json = String(object.extern_json);
      return message;
    };

    /**
     * Creates a plain object from a LoginResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof tgame_proto.LoginResp
     * @static
     * @param {tgame_proto.LoginResp} message LoginResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LoginResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.game_launch_url = "";
        object.extern_json = "";
      }
      if (message.game_launch_url != null && message.hasOwnProperty("game_launch_url"))
        object.game_launch_url = message.game_launch_url;
      if (message.extern_json != null && message.hasOwnProperty("extern_json"))
        object.extern_json = message.extern_json;
      return object;
    };

    /**
     * Converts this LoginResp to JSON.
     * @function toJSON
     * @memberof tgame_proto.LoginResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LoginResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LoginResp
     * @function getTypeUrl
     * @memberof tgame_proto.LoginResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LoginResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/tgame_proto.LoginResp";
    };

    return LoginResp;
  })();

  tgame_proto.LogoutReq = (function () {
    /**
     * Properties of a LogoutReq.
     * @memberof tgame_proto
     * @interface ILogoutReq
     * @property {number|null} [uid] LogoutReq uid
     */

    /**
     * Constructs a new LogoutReq.
     * @memberof tgame_proto
     * @classdesc Represents a LogoutReq.
     * @implements ILogoutReq
     * @constructor
     * @param {tgame_proto.ILogoutReq=} [properties] Properties to set
     */
    function LogoutReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LogoutReq uid.
     * @member {number} uid
     * @memberof tgame_proto.LogoutReq
     * @instance
     */
    LogoutReq.prototype.uid = 0;

    /**
     * Creates a new LogoutReq instance using the specified properties.
     * @function create
     * @memberof tgame_proto.LogoutReq
     * @static
     * @param {tgame_proto.ILogoutReq=} [properties] Properties to set
     * @returns {tgame_proto.LogoutReq} LogoutReq instance
     */
    LogoutReq.create = function create(properties) {
      return new LogoutReq(properties);
    };

    /**
     * Encodes the specified LogoutReq message. Does not implicitly {@link tgame_proto.LogoutReq.verify|verify} messages.
     * @function encode
     * @memberof tgame_proto.LogoutReq
     * @static
     * @param {tgame_proto.ILogoutReq} message LogoutReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LogoutReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified LogoutReq message, length delimited. Does not implicitly {@link tgame_proto.LogoutReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof tgame_proto.LogoutReq
     * @static
     * @param {tgame_proto.ILogoutReq} message LogoutReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LogoutReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LogoutReq message from the specified reader or buffer.
     * @function decode
     * @memberof tgame_proto.LogoutReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {tgame_proto.LogoutReq} LogoutReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LogoutReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.tgame_proto.LogoutReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LogoutReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof tgame_proto.LogoutReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {tgame_proto.LogoutReq} LogoutReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LogoutReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LogoutReq message.
     * @function verify
     * @memberof tgame_proto.LogoutReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LogoutReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates a LogoutReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof tgame_proto.LogoutReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {tgame_proto.LogoutReq} LogoutReq
     */
    LogoutReq.fromObject = function fromObject(object) {
      if (object instanceof $root.tgame_proto.LogoutReq) return object;
      var message = new $root.tgame_proto.LogoutReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a LogoutReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof tgame_proto.LogoutReq
     * @static
     * @param {tgame_proto.LogoutReq} message LogoutReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LogoutReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this LogoutReq to JSON.
     * @function toJSON
     * @memberof tgame_proto.LogoutReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LogoutReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LogoutReq
     * @function getTypeUrl
     * @memberof tgame_proto.LogoutReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LogoutReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/tgame_proto.LogoutReq";
    };

    return LogoutReq;
  })();

  tgame_proto.LogoutResp = (function () {
    /**
     * Properties of a LogoutResp.
     * @memberof tgame_proto
     * @interface ILogoutResp
     * @property {number|null} [state] LogoutResp state
     * @property {number|Long|null} [balance] LogoutResp balance
     */

    /**
     * Constructs a new LogoutResp.
     * @memberof tgame_proto
     * @classdesc Represents a LogoutResp.
     * @implements ILogoutResp
     * @constructor
     * @param {tgame_proto.ILogoutResp=} [properties] Properties to set
     */
    function LogoutResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LogoutResp state.
     * @member {number} state
     * @memberof tgame_proto.LogoutResp
     * @instance
     */
    LogoutResp.prototype.state = 0;

    /**
     * LogoutResp balance.
     * @member {number|Long} balance
     * @memberof tgame_proto.LogoutResp
     * @instance
     */
    LogoutResp.prototype.balance = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * Creates a new LogoutResp instance using the specified properties.
     * @function create
     * @memberof tgame_proto.LogoutResp
     * @static
     * @param {tgame_proto.ILogoutResp=} [properties] Properties to set
     * @returns {tgame_proto.LogoutResp} LogoutResp instance
     */
    LogoutResp.create = function create(properties) {
      return new LogoutResp(properties);
    };

    /**
     * Encodes the specified LogoutResp message. Does not implicitly {@link tgame_proto.LogoutResp.verify|verify} messages.
     * @function encode
     * @memberof tgame_proto.LogoutResp
     * @static
     * @param {tgame_proto.ILogoutResp} message LogoutResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LogoutResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.state != null && Object.hasOwnProperty.call(message, "state"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.state);
      if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.balance);
      return writer;
    };

    /**
     * Encodes the specified LogoutResp message, length delimited. Does not implicitly {@link tgame_proto.LogoutResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof tgame_proto.LogoutResp
     * @static
     * @param {tgame_proto.ILogoutResp} message LogoutResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LogoutResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LogoutResp message from the specified reader or buffer.
     * @function decode
     * @memberof tgame_proto.LogoutResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {tgame_proto.LogoutResp} LogoutResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LogoutResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.tgame_proto.LogoutResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.state = reader.uint32();
            break;
          }
          case 2: {
            message.balance = reader.uint64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LogoutResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof tgame_proto.LogoutResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {tgame_proto.LogoutResp} LogoutResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LogoutResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LogoutResp message.
     * @function verify
     * @memberof tgame_proto.LogoutResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LogoutResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.state != null && message.hasOwnProperty("state"))
        if (!$util.isInteger(message.state)) return "state: integer expected";
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (
          !$util.isInteger(message.balance) &&
          !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high))
        )
          return "balance: integer|Long expected";
      return null;
    };

    /**
     * Creates a LogoutResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof tgame_proto.LogoutResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {tgame_proto.LogoutResp} LogoutResp
     */
    LogoutResp.fromObject = function fromObject(object) {
      if (object instanceof $root.tgame_proto.LogoutResp) return object;
      var message = new $root.tgame_proto.LogoutResp();
      if (object.state != null) message.state = object.state >>> 0;
      if (object.balance != null)
        if ($util.Long) (message.balance = $util.Long.fromValue(object.balance)).unsigned = true;
        else if (typeof object.balance === "string") message.balance = parseInt(object.balance, 10);
        else if (typeof object.balance === "number") message.balance = object.balance;
        else if (typeof object.balance === "object")
          message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber(true);
      return message;
    };

    /**
     * Creates a plain object from a LogoutResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof tgame_proto.LogoutResp
     * @static
     * @param {tgame_proto.LogoutResp} message LogoutResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LogoutResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.state = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.balance =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.balance = options.longs === String ? "0" : 0;
      }
      if (message.state != null && message.hasOwnProperty("state")) object.state = message.state;
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (typeof message.balance === "number")
          object.balance = options.longs === String ? String(message.balance) : message.balance;
        else
          object.balance =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.balance)
              : options.longs === Number
                ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber(true)
                : message.balance;
      return object;
    };

    /**
     * Converts this LogoutResp to JSON.
     * @function toJSON
     * @memberof tgame_proto.LogoutResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LogoutResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for LogoutResp
     * @function getTypeUrl
     * @memberof tgame_proto.LogoutResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    LogoutResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/tgame_proto.LogoutResp";
    };

    return LogoutResp;
  })();

  tgame_proto.KickOutPush = (function () {
    /**
     * Properties of a KickOutPush.
     * @memberof tgame_proto
     * @interface IKickOutPush
     * @property {string|null} [from] KickOutPush from
     */

    /**
     * Constructs a new KickOutPush.
     * @memberof tgame_proto
     * @classdesc Represents a KickOutPush.
     * @implements IKickOutPush
     * @constructor
     * @param {tgame_proto.IKickOutPush=} [properties] Properties to set
     */
    function KickOutPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * KickOutPush from.
     * @member {string} from
     * @memberof tgame_proto.KickOutPush
     * @instance
     */
    KickOutPush.prototype.from = "";

    /**
     * Creates a new KickOutPush instance using the specified properties.
     * @function create
     * @memberof tgame_proto.KickOutPush
     * @static
     * @param {tgame_proto.IKickOutPush=} [properties] Properties to set
     * @returns {tgame_proto.KickOutPush} KickOutPush instance
     */
    KickOutPush.create = function create(properties) {
      return new KickOutPush(properties);
    };

    /**
     * Encodes the specified KickOutPush message. Does not implicitly {@link tgame_proto.KickOutPush.verify|verify} messages.
     * @function encode
     * @memberof tgame_proto.KickOutPush
     * @static
     * @param {tgame_proto.IKickOutPush} message KickOutPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KickOutPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.from != null && Object.hasOwnProperty.call(message, "from"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.from);
      return writer;
    };

    /**
     * Encodes the specified KickOutPush message, length delimited. Does not implicitly {@link tgame_proto.KickOutPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof tgame_proto.KickOutPush
     * @static
     * @param {tgame_proto.IKickOutPush} message KickOutPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KickOutPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a KickOutPush message from the specified reader or buffer.
     * @function decode
     * @memberof tgame_proto.KickOutPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {tgame_proto.KickOutPush} KickOutPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KickOutPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.tgame_proto.KickOutPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.from = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a KickOutPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof tgame_proto.KickOutPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {tgame_proto.KickOutPush} KickOutPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KickOutPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a KickOutPush message.
     * @function verify
     * @memberof tgame_proto.KickOutPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    KickOutPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.from != null && message.hasOwnProperty("from"))
        if (!$util.isString(message.from)) return "from: string expected";
      return null;
    };

    /**
     * Creates a KickOutPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof tgame_proto.KickOutPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {tgame_proto.KickOutPush} KickOutPush
     */
    KickOutPush.fromObject = function fromObject(object) {
      if (object instanceof $root.tgame_proto.KickOutPush) return object;
      var message = new $root.tgame_proto.KickOutPush();
      if (object.from != null) message.from = String(object.from);
      return message;
    };

    /**
     * Creates a plain object from a KickOutPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof tgame_proto.KickOutPush
     * @static
     * @param {tgame_proto.KickOutPush} message KickOutPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    KickOutPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.from = "";
      if (message.from != null && message.hasOwnProperty("from")) object.from = message.from;
      return object;
    };

    /**
     * Converts this KickOutPush to JSON.
     * @function toJSON
     * @memberof tgame_proto.KickOutPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    KickOutPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for KickOutPush
     * @function getTypeUrl
     * @memberof tgame_proto.KickOutPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    KickOutPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/tgame_proto.KickOutPush";
    };

    return KickOutPush;
  })();

  return tgame_proto;
})();

$root.transactions = (function () {
  /**
   * Namespace transactions.
   * @exports transactions
   * @namespace
   */
  var transactions = {};

  /**
   * TRANSACTIONS_CMD enum.
   * @name transactions.TRANSACTIONS_CMD
   * @enum {number}
   * @property {number} TRANSACTIONS_CMD_NONE=0 TRANSACTIONS_CMD_NONE value
   * @property {number} TRANSACTIONS_CMD_GET_TRANSACTIONS=2800 TRANSACTIONS_CMD_GET_TRANSACTIONS value
   * @property {number} TRANSACTIONS_CMD_GET_TRANSACTIONS_RESP=2801 TRANSACTIONS_CMD_GET_TRANSACTIONS_RESP value
   * @property {number} TRANSACTIONS_CMD_TRANSACTIONS_FLOW_PUSH=2802 TRANSACTIONS_CMD_TRANSACTIONS_FLOW_PUSH value
   */
  transactions.TRANSACTIONS_CMD = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "TRANSACTIONS_CMD_NONE")] = 0;
    values[(valuesById[2800] = "TRANSACTIONS_CMD_GET_TRANSACTIONS")] = 2800;
    values[(valuesById[2801] = "TRANSACTIONS_CMD_GET_TRANSACTIONS_RESP")] = 2801;
    values[(valuesById[2802] = "TRANSACTIONS_CMD_TRANSACTIONS_FLOW_PUSH")] = 2802;
    return values;
  })();

  /**
   * OP_ACT enum.
   * @name transactions.OP_ACT
   * @enum {number}
   * @property {number} ACT_NONE=0 ACT_NONE value
   * @property {number} ACT_BET=1 ACT_BET value
   * @property {number} ACT_WINLOST=2 ACT_WINLOST value
   * @property {number} ACT_RECHARGE=3 ACT_RECHARGE value
   * @property {number} ACT_SPIN_WITHDRAW=4 ACT_SPIN_WITHDRAW value
   * @property {number} ACT_WITHDRAW=5 ACT_WITHDRAW value
   * @property {number} ACT_REGISTER=6 ACT_REGISTER value
   * @property {number} ACT_BACKEND=7 ACT_BACKEND value
   * @property {number} ACT_REBATE=8 ACT_REBATE value
   * @property {number} ACT_CANCEL_BET=9 ACT_CANCEL_BET value
   * @property {number} ACT_CANCEL_WINLOST=10 ACT_CANCEL_WINLOST value
   * @property {number} ACT_FREEZE=11 ACT_FREEZE value
   * @property {number} ACT_VIP_WELFARE=12 ACT_VIP_WELFARE value
   * @property {number} ACT_WITHDRAW_REJECT=13 ACT_WITHDRAW_REJECT value
   */
  transactions.OP_ACT = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "ACT_NONE")] = 0;
    values[(valuesById[1] = "ACT_BET")] = 1;
    values[(valuesById[2] = "ACT_WINLOST")] = 2;
    values[(valuesById[3] = "ACT_RECHARGE")] = 3;
    values[(valuesById[4] = "ACT_SPIN_WITHDRAW")] = 4;
    values[(valuesById[5] = "ACT_WITHDRAW")] = 5;
    values[(valuesById[6] = "ACT_REGISTER")] = 6;
    values[(valuesById[7] = "ACT_BACKEND")] = 7;
    values[(valuesById[8] = "ACT_REBATE")] = 8;
    values[(valuesById[9] = "ACT_CANCEL_BET")] = 9;
    values[(valuesById[10] = "ACT_CANCEL_WINLOST")] = 10;
    values[(valuesById[11] = "ACT_FREEZE")] = 11;
    values[(valuesById[12] = "ACT_VIP_WELFARE")] = 12;
    values[(valuesById[13] = "ACT_WITHDRAW_REJECT")] = 13;
    return values;
  })();

  transactions.TransactionsReq = (function () {
    /**
     * Properties of a TransactionsReq.
     * @memberof transactions
     * @interface ITransactionsReq
     * @property {number|null} [uid] TransactionsReq uid
     * @property {number|Long|null} [pos] TransactionsReq pos
     * @property {number|null} [size] TransactionsReq size
     * @property {Uint8Array|null} [trans] TransactionsReq trans
     */

    /**
     * Constructs a new TransactionsReq.
     * @memberof transactions
     * @classdesc Represents a TransactionsReq.
     * @implements ITransactionsReq
     * @constructor
     * @param {transactions.ITransactionsReq=} [properties] Properties to set
     */
    function TransactionsReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * TransactionsReq uid.
     * @member {number} uid
     * @memberof transactions.TransactionsReq
     * @instance
     */
    TransactionsReq.prototype.uid = 0;

    /**
     * TransactionsReq pos.
     * @member {number|Long} pos
     * @memberof transactions.TransactionsReq
     * @instance
     */
    TransactionsReq.prototype.pos = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

    /**
     * TransactionsReq size.
     * @member {number} size
     * @memberof transactions.TransactionsReq
     * @instance
     */
    TransactionsReq.prototype.size = 0;

    /**
     * TransactionsReq trans.
     * @member {Uint8Array} trans
     * @memberof transactions.TransactionsReq
     * @instance
     */
    TransactionsReq.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new TransactionsReq instance using the specified properties.
     * @function create
     * @memberof transactions.TransactionsReq
     * @static
     * @param {transactions.ITransactionsReq=} [properties] Properties to set
     * @returns {transactions.TransactionsReq} TransactionsReq instance
     */
    TransactionsReq.create = function create(properties) {
      return new TransactionsReq(properties);
    };

    /**
     * Encodes the specified TransactionsReq message. Does not implicitly {@link transactions.TransactionsReq.verify|verify} messages.
     * @function encode
     * @memberof transactions.TransactionsReq
     * @static
     * @param {transactions.ITransactionsReq} message TransactionsReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TransactionsReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.pos != null && Object.hasOwnProperty.call(message, "pos"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint64(message.pos);
      if (message.size != null && Object.hasOwnProperty.call(message, "size"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.size);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 4, wireType 2 =*/ 34).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified TransactionsReq message, length delimited. Does not implicitly {@link transactions.TransactionsReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof transactions.TransactionsReq
     * @static
     * @param {transactions.ITransactionsReq} message TransactionsReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TransactionsReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TransactionsReq message from the specified reader or buffer.
     * @function decode
     * @memberof transactions.TransactionsReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {transactions.TransactionsReq} TransactionsReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TransactionsReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.transactions.TransactionsReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.pos = reader.uint64();
            break;
          }
          case 3: {
            message.size = reader.uint32();
            break;
          }
          case 4: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a TransactionsReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof transactions.TransactionsReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {transactions.TransactionsReq} TransactionsReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TransactionsReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TransactionsReq message.
     * @function verify
     * @memberof transactions.TransactionsReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TransactionsReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.pos != null && message.hasOwnProperty("pos"))
        if (
          !$util.isInteger(message.pos) &&
          !(message.pos && $util.isInteger(message.pos.low) && $util.isInteger(message.pos.high))
        )
          return "pos: integer|Long expected";
      if (message.size != null && message.hasOwnProperty("size"))
        if (!$util.isInteger(message.size)) return "size: integer expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates a TransactionsReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof transactions.TransactionsReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {transactions.TransactionsReq} TransactionsReq
     */
    TransactionsReq.fromObject = function fromObject(object) {
      if (object instanceof $root.transactions.TransactionsReq) return object;
      var message = new $root.transactions.TransactionsReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.pos != null)
        if ($util.Long) (message.pos = $util.Long.fromValue(object.pos)).unsigned = true;
        else if (typeof object.pos === "string") message.pos = parseInt(object.pos, 10);
        else if (typeof object.pos === "number") message.pos = object.pos;
        else if (typeof object.pos === "object")
          message.pos = new $util.LongBits(object.pos.low >>> 0, object.pos.high >>> 0).toNumber(true);
      if (object.size != null) message.size = object.size >>> 0;
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from a TransactionsReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof transactions.TransactionsReq
     * @static
     * @param {transactions.TransactionsReq} message TransactionsReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TransactionsReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, true);
          object.pos = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.pos = options.longs === String ? "0" : 0;
        object.size = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.pos != null && message.hasOwnProperty("pos"))
        if (typeof message.pos === "number") object.pos = options.longs === String ? String(message.pos) : message.pos;
        else
          object.pos =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.pos)
              : options.longs === Number
                ? new $util.LongBits(message.pos.low >>> 0, message.pos.high >>> 0).toNumber(true)
                : message.pos;
      if (message.size != null && message.hasOwnProperty("size")) object.size = message.size;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this TransactionsReq to JSON.
     * @function toJSON
     * @memberof transactions.TransactionsReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TransactionsReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TransactionsReq
     * @function getTypeUrl
     * @memberof transactions.TransactionsReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TransactionsReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/transactions.TransactionsReq";
    };

    return TransactionsReq;
  })();

  transactions.TransactionsResp = (function () {
    /**
     * Properties of a TransactionsResp.
     * @memberof transactions
     * @interface ITransactionsResp
     * @property {number|null} [uid] TransactionsResp uid
     * @property {number|null} [result] TransactionsResp result
     * @property {number|null} [endflag] TransactionsResp endflag
     * @property {Array.<transactions.TransactionsResp.ITransactionsItem>|null} [list] TransactionsResp list
     * @property {number|null} [totalsize] TransactionsResp totalsize
     * @property {Uint8Array|null} [trans] TransactionsResp trans
     */

    /**
     * Constructs a new TransactionsResp.
     * @memberof transactions
     * @classdesc Represents a TransactionsResp.
     * @implements ITransactionsResp
     * @constructor
     * @param {transactions.ITransactionsResp=} [properties] Properties to set
     */
    function TransactionsResp(properties) {
      this.list = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * TransactionsResp uid.
     * @member {number} uid
     * @memberof transactions.TransactionsResp
     * @instance
     */
    TransactionsResp.prototype.uid = 0;

    /**
     * TransactionsResp result.
     * @member {number} result
     * @memberof transactions.TransactionsResp
     * @instance
     */
    TransactionsResp.prototype.result = 0;

    /**
     * TransactionsResp endflag.
     * @member {number} endflag
     * @memberof transactions.TransactionsResp
     * @instance
     */
    TransactionsResp.prototype.endflag = 0;

    /**
     * TransactionsResp list.
     * @member {Array.<transactions.TransactionsResp.ITransactionsItem>} list
     * @memberof transactions.TransactionsResp
     * @instance
     */
    TransactionsResp.prototype.list = $util.emptyArray;

    /**
     * TransactionsResp totalsize.
     * @member {number} totalsize
     * @memberof transactions.TransactionsResp
     * @instance
     */
    TransactionsResp.prototype.totalsize = 0;

    /**
     * TransactionsResp trans.
     * @member {Uint8Array} trans
     * @memberof transactions.TransactionsResp
     * @instance
     */
    TransactionsResp.prototype.trans = $util.newBuffer([]);

    /**
     * Creates a new TransactionsResp instance using the specified properties.
     * @function create
     * @memberof transactions.TransactionsResp
     * @static
     * @param {transactions.ITransactionsResp=} [properties] Properties to set
     * @returns {transactions.TransactionsResp} TransactionsResp instance
     */
    TransactionsResp.create = function create(properties) {
      return new TransactionsResp(properties);
    };

    /**
     * Encodes the specified TransactionsResp message. Does not implicitly {@link transactions.TransactionsResp.verify|verify} messages.
     * @function encode
     * @memberof transactions.TransactionsResp
     * @static
     * @param {transactions.ITransactionsResp} message TransactionsResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TransactionsResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.result);
      if (message.endflag != null && Object.hasOwnProperty.call(message, "endflag"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.endflag);
      if (message.list != null && message.list.length)
        for (var i = 0; i < message.list.length; ++i)
          $root.transactions.TransactionsResp.TransactionsItem.encode(
            message.list[i],
            writer.uint32(/* id 4, wireType 2 =*/ 34).fork(),
          ).ldelim();
      if (message.totalsize != null && Object.hasOwnProperty.call(message, "totalsize"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).uint32(message.totalsize);
      if (message.trans != null && Object.hasOwnProperty.call(message, "trans"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).bytes(message.trans);
      return writer;
    };

    /**
     * Encodes the specified TransactionsResp message, length delimited. Does not implicitly {@link transactions.TransactionsResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof transactions.TransactionsResp
     * @static
     * @param {transactions.ITransactionsResp} message TransactionsResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TransactionsResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TransactionsResp message from the specified reader or buffer.
     * @function decode
     * @memberof transactions.TransactionsResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {transactions.TransactionsResp} TransactionsResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TransactionsResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.transactions.TransactionsResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.result = reader.int32();
            break;
          }
          case 3: {
            message.endflag = reader.int32();
            break;
          }
          case 4: {
            if (!(message.list && message.list.length)) message.list = [];
            message.list.push($root.transactions.TransactionsResp.TransactionsItem.decode(reader, reader.uint32()));
            break;
          }
          case 5: {
            message.totalsize = reader.uint32();
            break;
          }
          case 6: {
            message.trans = reader.bytes();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a TransactionsResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof transactions.TransactionsResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {transactions.TransactionsResp} TransactionsResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TransactionsResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TransactionsResp message.
     * @function verify
     * @memberof transactions.TransactionsResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TransactionsResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.endflag != null && message.hasOwnProperty("endflag"))
        if (!$util.isInteger(message.endflag)) return "endflag: integer expected";
      if (message.list != null && message.hasOwnProperty("list")) {
        if (!Array.isArray(message.list)) return "list: array expected";
        for (var i = 0; i < message.list.length; ++i) {
          var error = $root.transactions.TransactionsResp.TransactionsItem.verify(message.list[i]);
          if (error) return "list." + error;
        }
      }
      if (message.totalsize != null && message.hasOwnProperty("totalsize"))
        if (!$util.isInteger(message.totalsize)) return "totalsize: integer expected";
      if (message.trans != null && message.hasOwnProperty("trans"))
        if (!((message.trans && typeof message.trans.length === "number") || $util.isString(message.trans)))
          return "trans: buffer expected";
      return null;
    };

    /**
     * Creates a TransactionsResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof transactions.TransactionsResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {transactions.TransactionsResp} TransactionsResp
     */
    TransactionsResp.fromObject = function fromObject(object) {
      if (object instanceof $root.transactions.TransactionsResp) return object;
      var message = new $root.transactions.TransactionsResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.result != null) message.result = object.result | 0;
      if (object.endflag != null) message.endflag = object.endflag | 0;
      if (object.list) {
        if (!Array.isArray(object.list)) throw TypeError(".transactions.TransactionsResp.list: array expected");
        message.list = [];
        for (var i = 0; i < object.list.length; ++i) {
          if (typeof object.list[i] !== "object")
            throw TypeError(".transactions.TransactionsResp.list: object expected");
          message.list[i] = $root.transactions.TransactionsResp.TransactionsItem.fromObject(object.list[i]);
        }
      }
      if (object.totalsize != null) message.totalsize = object.totalsize >>> 0;
      if (object.trans != null)
        if (typeof object.trans === "string")
          $util.base64.decode(object.trans, (message.trans = $util.newBuffer($util.base64.length(object.trans))), 0);
        else if (object.trans.length >= 0) message.trans = object.trans;
      return message;
    };

    /**
     * Creates a plain object from a TransactionsResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof transactions.TransactionsResp
     * @static
     * @param {transactions.TransactionsResp} message TransactionsResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TransactionsResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.list = [];
      if (options.defaults) {
        object.uid = 0;
        object.result = 0;
        object.endflag = 0;
        object.totalsize = 0;
        if (options.bytes === String) object.trans = "";
        else {
          object.trans = [];
          if (options.bytes !== Array) object.trans = $util.newBuffer(object.trans);
        }
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.endflag != null && message.hasOwnProperty("endflag")) object.endflag = message.endflag;
      if (message.list && message.list.length) {
        object.list = [];
        for (var j = 0; j < message.list.length; ++j)
          object.list[j] = $root.transactions.TransactionsResp.TransactionsItem.toObject(message.list[j], options);
      }
      if (message.totalsize != null && message.hasOwnProperty("totalsize")) object.totalsize = message.totalsize;
      if (message.trans != null && message.hasOwnProperty("trans"))
        object.trans =
          options.bytes === String
            ? $util.base64.encode(message.trans, 0, message.trans.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.trans)
              : message.trans;
      return object;
    };

    /**
     * Converts this TransactionsResp to JSON.
     * @function toJSON
     * @memberof transactions.TransactionsResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TransactionsResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TransactionsResp
     * @function getTypeUrl
     * @memberof transactions.TransactionsResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TransactionsResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/transactions.TransactionsResp";
    };

    TransactionsResp.TransactionsItem = (function () {
      /**
       * Properties of a TransactionsItem.
       * @memberof transactions.TransactionsResp
       * @interface ITransactionsItem
       * @property {transactions.OP_ACT|null} [act] TransactionsItem act
       * @property {number|Long|null} [delt] TransactionsItem delt
       * @property {number|Long|null} [amount] TransactionsItem amount
       * @property {number|Long|null} [coin] TransactionsItem coin
       * @property {number|Long|null} [id] TransactionsItem id
       * @property {number|Long|null} [time] TransactionsItem time
       */

      /**
       * Constructs a new TransactionsItem.
       * @memberof transactions.TransactionsResp
       * @classdesc Represents a TransactionsItem.
       * @implements ITransactionsItem
       * @constructor
       * @param {transactions.TransactionsResp.ITransactionsItem=} [properties] Properties to set
       */
      function TransactionsItem(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }

      /**
       * TransactionsItem act.
       * @member {transactions.OP_ACT} act
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @instance
       */
      TransactionsItem.prototype.act = 0;

      /**
       * TransactionsItem delt.
       * @member {number|Long} delt
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @instance
       */
      TransactionsItem.prototype.delt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * TransactionsItem amount.
       * @member {number|Long} amount
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @instance
       */
      TransactionsItem.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * TransactionsItem coin.
       * @member {number|Long} coin
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @instance
       */
      TransactionsItem.prototype.coin = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * TransactionsItem id.
       * @member {number|Long} id
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @instance
       */
      TransactionsItem.prototype.id = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

      /**
       * TransactionsItem time.
       * @member {number|Long} time
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @instance
       */
      TransactionsItem.prototype.time = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

      /**
       * Creates a new TransactionsItem instance using the specified properties.
       * @function create
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @static
       * @param {transactions.TransactionsResp.ITransactionsItem=} [properties] Properties to set
       * @returns {transactions.TransactionsResp.TransactionsItem} TransactionsItem instance
       */
      TransactionsItem.create = function create(properties) {
        return new TransactionsItem(properties);
      };

      /**
       * Encodes the specified TransactionsItem message. Does not implicitly {@link transactions.TransactionsResp.TransactionsItem.verify|verify} messages.
       * @function encode
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @static
       * @param {transactions.TransactionsResp.ITransactionsItem} message TransactionsItem message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      TransactionsItem.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.act != null && Object.hasOwnProperty.call(message, "act"))
          writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.act);
        if (message.delt != null && Object.hasOwnProperty.call(message, "delt"))
          writer.uint32(/* id 2, wireType 0 =*/ 16).sint64(message.delt);
        if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
          writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.amount);
        if (message.coin != null && Object.hasOwnProperty.call(message, "coin"))
          writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.coin);
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
          writer.uint32(/* id 5, wireType 0 =*/ 40).uint64(message.id);
        if (message.time != null && Object.hasOwnProperty.call(message, "time"))
          writer.uint32(/* id 6, wireType 0 =*/ 48).uint64(message.time);
        return writer;
      };

      /**
       * Encodes the specified TransactionsItem message, length delimited. Does not implicitly {@link transactions.TransactionsResp.TransactionsItem.verify|verify} messages.
       * @function encodeDelimited
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @static
       * @param {transactions.TransactionsResp.ITransactionsItem} message TransactionsItem message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      TransactionsItem.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a TransactionsItem message from the specified reader or buffer.
       * @function decode
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {transactions.TransactionsResp.TransactionsItem} TransactionsItem
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      TransactionsItem.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.transactions.TransactionsResp.TransactionsItem();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.act = reader.int32();
              break;
            }
            case 2: {
              message.delt = reader.sint64();
              break;
            }
            case 3: {
              message.amount = reader.int64();
              break;
            }
            case 4: {
              message.coin = reader.int64();
              break;
            }
            case 5: {
              message.id = reader.uint64();
              break;
            }
            case 6: {
              message.time = reader.uint64();
              break;
            }
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a TransactionsItem message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {transactions.TransactionsResp.TransactionsItem} TransactionsItem
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      TransactionsItem.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a TransactionsItem message.
       * @function verify
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      TransactionsItem.verify = function verify(message) {
        if (typeof message !== "object" || message === null) return "object expected";
        if (message.act != null && message.hasOwnProperty("act"))
          switch (message.act) {
            default:
              return "act: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
              break;
          }
        if (message.delt != null && message.hasOwnProperty("delt"))
          if (
            !$util.isInteger(message.delt) &&
            !(message.delt && $util.isInteger(message.delt.low) && $util.isInteger(message.delt.high))
          )
            return "delt: integer|Long expected";
        if (message.amount != null && message.hasOwnProperty("amount"))
          if (
            !$util.isInteger(message.amount) &&
            !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
          )
            return "amount: integer|Long expected";
        if (message.coin != null && message.hasOwnProperty("coin"))
          if (
            !$util.isInteger(message.coin) &&
            !(message.coin && $util.isInteger(message.coin.low) && $util.isInteger(message.coin.high))
          )
            return "coin: integer|Long expected";
        if (message.id != null && message.hasOwnProperty("id"))
          if (
            !$util.isInteger(message.id) &&
            !(message.id && $util.isInteger(message.id.low) && $util.isInteger(message.id.high))
          )
            return "id: integer|Long expected";
        if (message.time != null && message.hasOwnProperty("time"))
          if (
            !$util.isInteger(message.time) &&
            !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high))
          )
            return "time: integer|Long expected";
        return null;
      };

      /**
       * Creates a TransactionsItem message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {transactions.TransactionsResp.TransactionsItem} TransactionsItem
       */
      TransactionsItem.fromObject = function fromObject(object) {
        if (object instanceof $root.transactions.TransactionsResp.TransactionsItem) return object;
        var message = new $root.transactions.TransactionsResp.TransactionsItem();
        switch (object.act) {
          default:
            if (typeof object.act === "number") {
              message.act = object.act;
              break;
            }
            break;
          case "ACT_NONE":
          case 0:
            message.act = 0;
            break;
          case "ACT_BET":
          case 1:
            message.act = 1;
            break;
          case "ACT_WINLOST":
          case 2:
            message.act = 2;
            break;
          case "ACT_RECHARGE":
          case 3:
            message.act = 3;
            break;
          case "ACT_SPIN_WITHDRAW":
          case 4:
            message.act = 4;
            break;
          case "ACT_WITHDRAW":
          case 5:
            message.act = 5;
            break;
          case "ACT_REGISTER":
          case 6:
            message.act = 6;
            break;
          case "ACT_BACKEND":
          case 7:
            message.act = 7;
            break;
          case "ACT_REBATE":
          case 8:
            message.act = 8;
            break;
          case "ACT_CANCEL_BET":
          case 9:
            message.act = 9;
            break;
          case "ACT_CANCEL_WINLOST":
          case 10:
            message.act = 10;
            break;
          case "ACT_FREEZE":
          case 11:
            message.act = 11;
            break;
          case "ACT_VIP_WELFARE":
          case 12:
            message.act = 12;
            break;
          case "ACT_WITHDRAW_REJECT":
          case 13:
            message.act = 13;
            break;
        }
        if (object.delt != null)
          if ($util.Long) (message.delt = $util.Long.fromValue(object.delt)).unsigned = false;
          else if (typeof object.delt === "string") message.delt = parseInt(object.delt, 10);
          else if (typeof object.delt === "number") message.delt = object.delt;
          else if (typeof object.delt === "object")
            message.delt = new $util.LongBits(object.delt.low >>> 0, object.delt.high >>> 0).toNumber();
        if (object.amount != null)
          if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
          else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
          else if (typeof object.amount === "number") message.amount = object.amount;
          else if (typeof object.amount === "object")
            message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
        if (object.coin != null)
          if ($util.Long) (message.coin = $util.Long.fromValue(object.coin)).unsigned = false;
          else if (typeof object.coin === "string") message.coin = parseInt(object.coin, 10);
          else if (typeof object.coin === "number") message.coin = object.coin;
          else if (typeof object.coin === "object")
            message.coin = new $util.LongBits(object.coin.low >>> 0, object.coin.high >>> 0).toNumber();
        if (object.id != null)
          if ($util.Long) (message.id = $util.Long.fromValue(object.id)).unsigned = true;
          else if (typeof object.id === "string") message.id = parseInt(object.id, 10);
          else if (typeof object.id === "number") message.id = object.id;
          else if (typeof object.id === "object")
            message.id = new $util.LongBits(object.id.low >>> 0, object.id.high >>> 0).toNumber(true);
        if (object.time != null)
          if ($util.Long) (message.time = $util.Long.fromValue(object.time)).unsigned = true;
          else if (typeof object.time === "string") message.time = parseInt(object.time, 10);
          else if (typeof object.time === "number") message.time = object.time;
          else if (typeof object.time === "object")
            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber(true);
        return message;
      };

      /**
       * Creates a plain object from a TransactionsItem message. Also converts values to other types if specified.
       * @function toObject
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @static
       * @param {transactions.TransactionsResp.TransactionsItem} message TransactionsItem
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      TransactionsItem.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.act = options.enums === String ? "ACT_NONE" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.delt =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.delt = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.amount =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.amount = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.coin =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.coin = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.id = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.id = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.time =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.time = options.longs === String ? "0" : 0;
        }
        if (message.act != null && message.hasOwnProperty("act"))
          object.act =
            options.enums === String
              ? $root.transactions.OP_ACT[message.act] === undefined
                ? message.act
                : $root.transactions.OP_ACT[message.act]
              : message.act;
        if (message.delt != null && message.hasOwnProperty("delt"))
          if (typeof message.delt === "number")
            object.delt = options.longs === String ? String(message.delt) : message.delt;
          else
            object.delt =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.delt)
                : options.longs === Number
                  ? new $util.LongBits(message.delt.low >>> 0, message.delt.high >>> 0).toNumber()
                  : message.delt;
        if (message.amount != null && message.hasOwnProperty("amount"))
          if (typeof message.amount === "number")
            object.amount = options.longs === String ? String(message.amount) : message.amount;
          else
            object.amount =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.amount)
                : options.longs === Number
                  ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                  : message.amount;
        if (message.coin != null && message.hasOwnProperty("coin"))
          if (typeof message.coin === "number")
            object.coin = options.longs === String ? String(message.coin) : message.coin;
          else
            object.coin =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.coin)
                : options.longs === Number
                  ? new $util.LongBits(message.coin.low >>> 0, message.coin.high >>> 0).toNumber()
                  : message.coin;
        if (message.id != null && message.hasOwnProperty("id"))
          if (typeof message.id === "number") object.id = options.longs === String ? String(message.id) : message.id;
          else
            object.id =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.id)
                : options.longs === Number
                  ? new $util.LongBits(message.id.low >>> 0, message.id.high >>> 0).toNumber(true)
                  : message.id;
        if (message.time != null && message.hasOwnProperty("time"))
          if (typeof message.time === "number")
            object.time = options.longs === String ? String(message.time) : message.time;
          else
            object.time =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.time)
                : options.longs === Number
                  ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber(true)
                  : message.time;
        return object;
      };

      /**
       * Converts this TransactionsItem to JSON.
       * @function toJSON
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      TransactionsItem.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for TransactionsItem
       * @function getTypeUrl
       * @memberof transactions.TransactionsResp.TransactionsItem
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      TransactionsItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/transactions.TransactionsResp.TransactionsItem";
      };

      return TransactionsItem;
    })();

    return TransactionsResp;
  })();

  transactions.TransactionsFlowPush = (function () {
    /**
     * Properties of a TransactionsFlowPush.
     * @memberof transactions
     * @interface ITransactionsFlowPush
     * @property {Array.<transactions.TransactionsFlowPush.IFlowItem>|null} [flowlist] TransactionsFlowPush flowlist
     */

    /**
     * Constructs a new TransactionsFlowPush.
     * @memberof transactions
     * @classdesc Represents a TransactionsFlowPush.
     * @implements ITransactionsFlowPush
     * @constructor
     * @param {transactions.ITransactionsFlowPush=} [properties] Properties to set
     */
    function TransactionsFlowPush(properties) {
      this.flowlist = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * TransactionsFlowPush flowlist.
     * @member {Array.<transactions.TransactionsFlowPush.IFlowItem>} flowlist
     * @memberof transactions.TransactionsFlowPush
     * @instance
     */
    TransactionsFlowPush.prototype.flowlist = $util.emptyArray;

    /**
     * Creates a new TransactionsFlowPush instance using the specified properties.
     * @function create
     * @memberof transactions.TransactionsFlowPush
     * @static
     * @param {transactions.ITransactionsFlowPush=} [properties] Properties to set
     * @returns {transactions.TransactionsFlowPush} TransactionsFlowPush instance
     */
    TransactionsFlowPush.create = function create(properties) {
      return new TransactionsFlowPush(properties);
    };

    /**
     * Encodes the specified TransactionsFlowPush message. Does not implicitly {@link transactions.TransactionsFlowPush.verify|verify} messages.
     * @function encode
     * @memberof transactions.TransactionsFlowPush
     * @static
     * @param {transactions.ITransactionsFlowPush} message TransactionsFlowPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TransactionsFlowPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.flowlist != null && message.flowlist.length)
        for (var i = 0; i < message.flowlist.length; ++i)
          $root.transactions.TransactionsFlowPush.FlowItem.encode(
            message.flowlist[i],
            writer.uint32(/* id 1, wireType 2 =*/ 10).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified TransactionsFlowPush message, length delimited. Does not implicitly {@link transactions.TransactionsFlowPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof transactions.TransactionsFlowPush
     * @static
     * @param {transactions.ITransactionsFlowPush} message TransactionsFlowPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TransactionsFlowPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a TransactionsFlowPush message from the specified reader or buffer.
     * @function decode
     * @memberof transactions.TransactionsFlowPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {transactions.TransactionsFlowPush} TransactionsFlowPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TransactionsFlowPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.transactions.TransactionsFlowPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            if (!(message.flowlist && message.flowlist.length)) message.flowlist = [];
            message.flowlist.push($root.transactions.TransactionsFlowPush.FlowItem.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a TransactionsFlowPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof transactions.TransactionsFlowPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {transactions.TransactionsFlowPush} TransactionsFlowPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TransactionsFlowPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a TransactionsFlowPush message.
     * @function verify
     * @memberof transactions.TransactionsFlowPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    TransactionsFlowPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.flowlist != null && message.hasOwnProperty("flowlist")) {
        if (!Array.isArray(message.flowlist)) return "flowlist: array expected";
        for (var i = 0; i < message.flowlist.length; ++i) {
          var error = $root.transactions.TransactionsFlowPush.FlowItem.verify(message.flowlist[i]);
          if (error) return "flowlist." + error;
        }
      }
      return null;
    };

    /**
     * Creates a TransactionsFlowPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof transactions.TransactionsFlowPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {transactions.TransactionsFlowPush} TransactionsFlowPush
     */
    TransactionsFlowPush.fromObject = function fromObject(object) {
      if (object instanceof $root.transactions.TransactionsFlowPush) return object;
      var message = new $root.transactions.TransactionsFlowPush();
      if (object.flowlist) {
        if (!Array.isArray(object.flowlist))
          throw TypeError(".transactions.TransactionsFlowPush.flowlist: array expected");
        message.flowlist = [];
        for (var i = 0; i < object.flowlist.length; ++i) {
          if (typeof object.flowlist[i] !== "object")
            throw TypeError(".transactions.TransactionsFlowPush.flowlist: object expected");
          message.flowlist[i] = $root.transactions.TransactionsFlowPush.FlowItem.fromObject(object.flowlist[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a TransactionsFlowPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof transactions.TransactionsFlowPush
     * @static
     * @param {transactions.TransactionsFlowPush} message TransactionsFlowPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    TransactionsFlowPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.flowlist = [];
      if (message.flowlist && message.flowlist.length) {
        object.flowlist = [];
        for (var j = 0; j < message.flowlist.length; ++j)
          object.flowlist[j] = $root.transactions.TransactionsFlowPush.FlowItem.toObject(message.flowlist[j], options);
      }
      return object;
    };

    /**
     * Converts this TransactionsFlowPush to JSON.
     * @function toJSON
     * @memberof transactions.TransactionsFlowPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    TransactionsFlowPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for TransactionsFlowPush
     * @function getTypeUrl
     * @memberof transactions.TransactionsFlowPush
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    TransactionsFlowPush.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/transactions.TransactionsFlowPush";
    };

    TransactionsFlowPush.FlowItem = (function () {
      /**
       * Properties of a FlowItem.
       * @memberof transactions.TransactionsFlowPush
       * @interface IFlowItem
       * @property {number|null} [uid] FlowItem uid
       * @property {transactions.OP_ACT|null} [act] FlowItem act
       * @property {number|Long|null} [delt] FlowItem delt
       * @property {number|Long|null} [amount] FlowItem amount
       * @property {number|Long|null} [coin] FlowItem coin
       * @property {number|Long|null} [time] FlowItem time
       * @property {string|null} [th] FlowItem th
       * @property {string|null} [game] FlowItem game
       * @property {string|null} [cycle] FlowItem cycle
       * @property {string|null} [orderid] FlowItem orderid
       * @property {string|null} [associatedorder] FlowItem associatedorder
       */

      /**
       * Constructs a new FlowItem.
       * @memberof transactions.TransactionsFlowPush
       * @classdesc Represents a FlowItem.
       * @implements IFlowItem
       * @constructor
       * @param {transactions.TransactionsFlowPush.IFlowItem=} [properties] Properties to set
       */
      function FlowItem(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }

      /**
       * FlowItem uid.
       * @member {number} uid
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.uid = 0;

      /**
       * FlowItem act.
       * @member {transactions.OP_ACT} act
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.act = 0;

      /**
       * FlowItem delt.
       * @member {number|Long} delt
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.delt = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * FlowItem amount.
       * @member {number|Long} amount
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.amount = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * FlowItem coin.
       * @member {number|Long} coin
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.coin = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * FlowItem time.
       * @member {number|Long} time
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.time = $util.Long ? $util.Long.fromBits(0, 0, true) : 0;

      /**
       * FlowItem th.
       * @member {string} th
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.th = "";

      /**
       * FlowItem game.
       * @member {string} game
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.game = "";

      /**
       * FlowItem cycle.
       * @member {string} cycle
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.cycle = "";

      /**
       * FlowItem orderid.
       * @member {string} orderid
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.orderid = "";

      /**
       * FlowItem associatedorder.
       * @member {string} associatedorder
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       */
      FlowItem.prototype.associatedorder = "";

      /**
       * Creates a new FlowItem instance using the specified properties.
       * @function create
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @static
       * @param {transactions.TransactionsFlowPush.IFlowItem=} [properties] Properties to set
       * @returns {transactions.TransactionsFlowPush.FlowItem} FlowItem instance
       */
      FlowItem.create = function create(properties) {
        return new FlowItem(properties);
      };

      /**
       * Encodes the specified FlowItem message. Does not implicitly {@link transactions.TransactionsFlowPush.FlowItem.verify|verify} messages.
       * @function encode
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @static
       * @param {transactions.TransactionsFlowPush.IFlowItem} message FlowItem message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FlowItem.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
          writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
        if (message.act != null && Object.hasOwnProperty.call(message, "act"))
          writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.act);
        if (message.delt != null && Object.hasOwnProperty.call(message, "delt"))
          writer.uint32(/* id 3, wireType 0 =*/ 24).sint64(message.delt);
        if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
          writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.amount);
        if (message.coin != null && Object.hasOwnProperty.call(message, "coin"))
          writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.coin);
        if (message.time != null && Object.hasOwnProperty.call(message, "time"))
          writer.uint32(/* id 6, wireType 0 =*/ 48).uint64(message.time);
        if (message.th != null && Object.hasOwnProperty.call(message, "th"))
          writer.uint32(/* id 7, wireType 2 =*/ 58).string(message.th);
        if (message.game != null && Object.hasOwnProperty.call(message, "game"))
          writer.uint32(/* id 8, wireType 2 =*/ 66).string(message.game);
        if (message.cycle != null && Object.hasOwnProperty.call(message, "cycle"))
          writer.uint32(/* id 9, wireType 2 =*/ 74).string(message.cycle);
        if (message.orderid != null && Object.hasOwnProperty.call(message, "orderid"))
          writer.uint32(/* id 10, wireType 2 =*/ 82).string(message.orderid);
        if (message.associatedorder != null && Object.hasOwnProperty.call(message, "associatedorder"))
          writer.uint32(/* id 11, wireType 2 =*/ 90).string(message.associatedorder);
        return writer;
      };

      /**
       * Encodes the specified FlowItem message, length delimited. Does not implicitly {@link transactions.TransactionsFlowPush.FlowItem.verify|verify} messages.
       * @function encodeDelimited
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @static
       * @param {transactions.TransactionsFlowPush.IFlowItem} message FlowItem message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      FlowItem.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a FlowItem message from the specified reader or buffer.
       * @function decode
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {transactions.TransactionsFlowPush.FlowItem} FlowItem
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FlowItem.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.transactions.TransactionsFlowPush.FlowItem();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 1: {
              message.uid = reader.uint32();
              break;
            }
            case 2: {
              message.act = reader.int32();
              break;
            }
            case 3: {
              message.delt = reader.sint64();
              break;
            }
            case 4: {
              message.amount = reader.int64();
              break;
            }
            case 5: {
              message.coin = reader.int64();
              break;
            }
            case 6: {
              message.time = reader.uint64();
              break;
            }
            case 7: {
              message.th = reader.string();
              break;
            }
            case 8: {
              message.game = reader.string();
              break;
            }
            case 9: {
              message.cycle = reader.string();
              break;
            }
            case 10: {
              message.orderid = reader.string();
              break;
            }
            case 11: {
              message.associatedorder = reader.string();
              break;
            }
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a FlowItem message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {transactions.TransactionsFlowPush.FlowItem} FlowItem
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      FlowItem.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a FlowItem message.
       * @function verify
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      FlowItem.verify = function verify(message) {
        if (typeof message !== "object" || message === null) return "object expected";
        if (message.uid != null && message.hasOwnProperty("uid"))
          if (!$util.isInteger(message.uid)) return "uid: integer expected";
        if (message.act != null && message.hasOwnProperty("act"))
          switch (message.act) {
            default:
              return "act: enum value expected";
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
            case 8:
            case 9:
            case 10:
            case 11:
            case 12:
            case 13:
              break;
          }
        if (message.delt != null && message.hasOwnProperty("delt"))
          if (
            !$util.isInteger(message.delt) &&
            !(message.delt && $util.isInteger(message.delt.low) && $util.isInteger(message.delt.high))
          )
            return "delt: integer|Long expected";
        if (message.amount != null && message.hasOwnProperty("amount"))
          if (
            !$util.isInteger(message.amount) &&
            !(message.amount && $util.isInteger(message.amount.low) && $util.isInteger(message.amount.high))
          )
            return "amount: integer|Long expected";
        if (message.coin != null && message.hasOwnProperty("coin"))
          if (
            !$util.isInteger(message.coin) &&
            !(message.coin && $util.isInteger(message.coin.low) && $util.isInteger(message.coin.high))
          )
            return "coin: integer|Long expected";
        if (message.time != null && message.hasOwnProperty("time"))
          if (
            !$util.isInteger(message.time) &&
            !(message.time && $util.isInteger(message.time.low) && $util.isInteger(message.time.high))
          )
            return "time: integer|Long expected";
        if (message.th != null && message.hasOwnProperty("th"))
          if (!$util.isString(message.th)) return "th: string expected";
        if (message.game != null && message.hasOwnProperty("game"))
          if (!$util.isString(message.game)) return "game: string expected";
        if (message.cycle != null && message.hasOwnProperty("cycle"))
          if (!$util.isString(message.cycle)) return "cycle: string expected";
        if (message.orderid != null && message.hasOwnProperty("orderid"))
          if (!$util.isString(message.orderid)) return "orderid: string expected";
        if (message.associatedorder != null && message.hasOwnProperty("associatedorder"))
          if (!$util.isString(message.associatedorder)) return "associatedorder: string expected";
        return null;
      };

      /**
       * Creates a FlowItem message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {transactions.TransactionsFlowPush.FlowItem} FlowItem
       */
      FlowItem.fromObject = function fromObject(object) {
        if (object instanceof $root.transactions.TransactionsFlowPush.FlowItem) return object;
        var message = new $root.transactions.TransactionsFlowPush.FlowItem();
        if (object.uid != null) message.uid = object.uid >>> 0;
        switch (object.act) {
          default:
            if (typeof object.act === "number") {
              message.act = object.act;
              break;
            }
            break;
          case "ACT_NONE":
          case 0:
            message.act = 0;
            break;
          case "ACT_BET":
          case 1:
            message.act = 1;
            break;
          case "ACT_WINLOST":
          case 2:
            message.act = 2;
            break;
          case "ACT_RECHARGE":
          case 3:
            message.act = 3;
            break;
          case "ACT_SPIN_WITHDRAW":
          case 4:
            message.act = 4;
            break;
          case "ACT_WITHDRAW":
          case 5:
            message.act = 5;
            break;
          case "ACT_REGISTER":
          case 6:
            message.act = 6;
            break;
          case "ACT_BACKEND":
          case 7:
            message.act = 7;
            break;
          case "ACT_REBATE":
          case 8:
            message.act = 8;
            break;
          case "ACT_CANCEL_BET":
          case 9:
            message.act = 9;
            break;
          case "ACT_CANCEL_WINLOST":
          case 10:
            message.act = 10;
            break;
          case "ACT_FREEZE":
          case 11:
            message.act = 11;
            break;
          case "ACT_VIP_WELFARE":
          case 12:
            message.act = 12;
            break;
          case "ACT_WITHDRAW_REJECT":
          case 13:
            message.act = 13;
            break;
        }
        if (object.delt != null)
          if ($util.Long) (message.delt = $util.Long.fromValue(object.delt)).unsigned = false;
          else if (typeof object.delt === "string") message.delt = parseInt(object.delt, 10);
          else if (typeof object.delt === "number") message.delt = object.delt;
          else if (typeof object.delt === "object")
            message.delt = new $util.LongBits(object.delt.low >>> 0, object.delt.high >>> 0).toNumber();
        if (object.amount != null)
          if ($util.Long) (message.amount = $util.Long.fromValue(object.amount)).unsigned = false;
          else if (typeof object.amount === "string") message.amount = parseInt(object.amount, 10);
          else if (typeof object.amount === "number") message.amount = object.amount;
          else if (typeof object.amount === "object")
            message.amount = new $util.LongBits(object.amount.low >>> 0, object.amount.high >>> 0).toNumber();
        if (object.coin != null)
          if ($util.Long) (message.coin = $util.Long.fromValue(object.coin)).unsigned = false;
          else if (typeof object.coin === "string") message.coin = parseInt(object.coin, 10);
          else if (typeof object.coin === "number") message.coin = object.coin;
          else if (typeof object.coin === "object")
            message.coin = new $util.LongBits(object.coin.low >>> 0, object.coin.high >>> 0).toNumber();
        if (object.time != null)
          if ($util.Long) (message.time = $util.Long.fromValue(object.time)).unsigned = true;
          else if (typeof object.time === "string") message.time = parseInt(object.time, 10);
          else if (typeof object.time === "number") message.time = object.time;
          else if (typeof object.time === "object")
            message.time = new $util.LongBits(object.time.low >>> 0, object.time.high >>> 0).toNumber(true);
        if (object.th != null) message.th = String(object.th);
        if (object.game != null) message.game = String(object.game);
        if (object.cycle != null) message.cycle = String(object.cycle);
        if (object.orderid != null) message.orderid = String(object.orderid);
        if (object.associatedorder != null) message.associatedorder = String(object.associatedorder);
        return message;
      };

      /**
       * Creates a plain object from a FlowItem message. Also converts values to other types if specified.
       * @function toObject
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @static
       * @param {transactions.TransactionsFlowPush.FlowItem} message FlowItem
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      FlowItem.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.uid = 0;
          object.act = options.enums === String ? "ACT_NONE" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.delt =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.delt = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.amount =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.amount = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.coin =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.coin = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, true);
            object.time =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.time = options.longs === String ? "0" : 0;
          object.th = "";
          object.game = "";
          object.cycle = "";
          object.orderid = "";
          object.associatedorder = "";
        }
        if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
        if (message.act != null && message.hasOwnProperty("act"))
          object.act =
            options.enums === String
              ? $root.transactions.OP_ACT[message.act] === undefined
                ? message.act
                : $root.transactions.OP_ACT[message.act]
              : message.act;
        if (message.delt != null && message.hasOwnProperty("delt"))
          if (typeof message.delt === "number")
            object.delt = options.longs === String ? String(message.delt) : message.delt;
          else
            object.delt =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.delt)
                : options.longs === Number
                  ? new $util.LongBits(message.delt.low >>> 0, message.delt.high >>> 0).toNumber()
                  : message.delt;
        if (message.amount != null && message.hasOwnProperty("amount"))
          if (typeof message.amount === "number")
            object.amount = options.longs === String ? String(message.amount) : message.amount;
          else
            object.amount =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.amount)
                : options.longs === Number
                  ? new $util.LongBits(message.amount.low >>> 0, message.amount.high >>> 0).toNumber()
                  : message.amount;
        if (message.coin != null && message.hasOwnProperty("coin"))
          if (typeof message.coin === "number")
            object.coin = options.longs === String ? String(message.coin) : message.coin;
          else
            object.coin =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.coin)
                : options.longs === Number
                  ? new $util.LongBits(message.coin.low >>> 0, message.coin.high >>> 0).toNumber()
                  : message.coin;
        if (message.time != null && message.hasOwnProperty("time"))
          if (typeof message.time === "number")
            object.time = options.longs === String ? String(message.time) : message.time;
          else
            object.time =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.time)
                : options.longs === Number
                  ? new $util.LongBits(message.time.low >>> 0, message.time.high >>> 0).toNumber(true)
                  : message.time;
        if (message.th != null && message.hasOwnProperty("th")) object.th = message.th;
        if (message.game != null && message.hasOwnProperty("game")) object.game = message.game;
        if (message.cycle != null && message.hasOwnProperty("cycle")) object.cycle = message.cycle;
        if (message.orderid != null && message.hasOwnProperty("orderid")) object.orderid = message.orderid;
        if (message.associatedorder != null && message.hasOwnProperty("associatedorder"))
          object.associatedorder = message.associatedorder;
        return object;
      };

      /**
       * Converts this FlowItem to JSON.
       * @function toJSON
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      FlowItem.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      /**
       * Gets the default type url for FlowItem
       * @function getTypeUrl
       * @memberof transactions.TransactionsFlowPush.FlowItem
       * @static
       * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
       * @returns {string} The default type url
       */
      FlowItem.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
          typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/transactions.TransactionsFlowPush.FlowItem";
      };

      return FlowItem;
    })();

    return TransactionsFlowPush;
  })();

  return transactions;
})();

$root.vipbetredate = (function () {
  /**
   * Namespace vipbetredate.
   * @exports vipbetredate
   * @namespace
   */
  var vipbetredate = {};

  /**
   * ROOMALLOC_CMD enum.
   * @name vipbetredate.ROOMALLOC_CMD
   * @enum {number}
   * @property {number} ROOMALLOC_CMD_NONE=0 ROOMALLOC_CMD_NONE value
   * @property {number} ROOMALLOC_CMD_VIP_BET_REDATE_REQ=4800 ROOMALLOC_CMD_VIP_BET_REDATE_REQ value
   * @property {number} ROOMALLOC_CMD_VIP_BET_REDATE_RESP=4801 ROOMALLOC_CMD_VIP_BET_REDATE_RESP value
   * @property {number} ROOMALLOC_CMD_VIP_BET_REDATE_INFO_REQ=4802 ROOMALLOC_CMD_VIP_BET_REDATE_INFO_REQ value
   * @property {number} ROOMALLOC_CMD_VIP_BET_REDATE_INFO_RESP=4803 ROOMALLOC_CMD_VIP_BET_REDATE_INFO_RESP value
   */
  vipbetredate.ROOMALLOC_CMD = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "ROOMALLOC_CMD_NONE")] = 0;
    values[(valuesById[4800] = "ROOMALLOC_CMD_VIP_BET_REDATE_REQ")] = 4800;
    values[(valuesById[4801] = "ROOMALLOC_CMD_VIP_BET_REDATE_RESP")] = 4801;
    values[(valuesById[4802] = "ROOMALLOC_CMD_VIP_BET_REDATE_INFO_REQ")] = 4802;
    values[(valuesById[4803] = "ROOMALLOC_CMD_VIP_BET_REDATE_INFO_RESP")] = 4803;
    return values;
  })();

  vipbetredate.ClaimRewardReq = (function () {
    /**
     * Properties of a ClaimRewardReq.
     * @memberof vipbetredate
     * @interface IClaimRewardReq
     * @property {number|null} [uid] ClaimRewardReq uid
     */

    /**
     * Constructs a new ClaimRewardReq.
     * @memberof vipbetredate
     * @classdesc Represents a ClaimRewardReq.
     * @implements IClaimRewardReq
     * @constructor
     * @param {vipbetredate.IClaimRewardReq=} [properties] Properties to set
     */
    function ClaimRewardReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ClaimRewardReq uid.
     * @member {number} uid
     * @memberof vipbetredate.ClaimRewardReq
     * @instance
     */
    ClaimRewardReq.prototype.uid = 0;

    /**
     * Creates a new ClaimRewardReq instance using the specified properties.
     * @function create
     * @memberof vipbetredate.ClaimRewardReq
     * @static
     * @param {vipbetredate.IClaimRewardReq=} [properties] Properties to set
     * @returns {vipbetredate.ClaimRewardReq} ClaimRewardReq instance
     */
    ClaimRewardReq.create = function create(properties) {
      return new ClaimRewardReq(properties);
    };

    /**
     * Encodes the specified ClaimRewardReq message. Does not implicitly {@link vipbetredate.ClaimRewardReq.verify|verify} messages.
     * @function encode
     * @memberof vipbetredate.ClaimRewardReq
     * @static
     * @param {vipbetredate.IClaimRewardReq} message ClaimRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClaimRewardReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified ClaimRewardReq message, length delimited. Does not implicitly {@link vipbetredate.ClaimRewardReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof vipbetredate.ClaimRewardReq
     * @static
     * @param {vipbetredate.IClaimRewardReq} message ClaimRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClaimRewardReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ClaimRewardReq message from the specified reader or buffer.
     * @function decode
     * @memberof vipbetredate.ClaimRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {vipbetredate.ClaimRewardReq} ClaimRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClaimRewardReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.vipbetredate.ClaimRewardReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ClaimRewardReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof vipbetredate.ClaimRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {vipbetredate.ClaimRewardReq} ClaimRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClaimRewardReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ClaimRewardReq message.
     * @function verify
     * @memberof vipbetredate.ClaimRewardReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ClaimRewardReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates a ClaimRewardReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof vipbetredate.ClaimRewardReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {vipbetredate.ClaimRewardReq} ClaimRewardReq
     */
    ClaimRewardReq.fromObject = function fromObject(object) {
      if (object instanceof $root.vipbetredate.ClaimRewardReq) return object;
      var message = new $root.vipbetredate.ClaimRewardReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a ClaimRewardReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof vipbetredate.ClaimRewardReq
     * @static
     * @param {vipbetredate.ClaimRewardReq} message ClaimRewardReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ClaimRewardReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this ClaimRewardReq to JSON.
     * @function toJSON
     * @memberof vipbetredate.ClaimRewardReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ClaimRewardReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ClaimRewardReq
     * @function getTypeUrl
     * @memberof vipbetredate.ClaimRewardReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ClaimRewardReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/vipbetredate.ClaimRewardReq";
    };

    return ClaimRewardReq;
  })();

  vipbetredate.ClaimRewardResp = (function () {
    /**
     * Properties of a ClaimRewardResp.
     * @memberof vipbetredate
     * @interface IClaimRewardResp
     * @property {number|null} [uid] ClaimRewardResp uid
     * @property {number|null} [sign] ClaimRewardResp sign
     * @property {number|Long|null} [reward] ClaimRewardResp reward
     */

    /**
     * Constructs a new ClaimRewardResp.
     * @memberof vipbetredate
     * @classdesc Represents a ClaimRewardResp.
     * @implements IClaimRewardResp
     * @constructor
     * @param {vipbetredate.IClaimRewardResp=} [properties] Properties to set
     */
    function ClaimRewardResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * ClaimRewardResp uid.
     * @member {number} uid
     * @memberof vipbetredate.ClaimRewardResp
     * @instance
     */
    ClaimRewardResp.prototype.uid = 0;

    /**
     * ClaimRewardResp sign.
     * @member {number} sign
     * @memberof vipbetredate.ClaimRewardResp
     * @instance
     */
    ClaimRewardResp.prototype.sign = 0;

    /**
     * ClaimRewardResp reward.
     * @member {number|Long} reward
     * @memberof vipbetredate.ClaimRewardResp
     * @instance
     */
    ClaimRewardResp.prototype.reward = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new ClaimRewardResp instance using the specified properties.
     * @function create
     * @memberof vipbetredate.ClaimRewardResp
     * @static
     * @param {vipbetredate.IClaimRewardResp=} [properties] Properties to set
     * @returns {vipbetredate.ClaimRewardResp} ClaimRewardResp instance
     */
    ClaimRewardResp.create = function create(properties) {
      return new ClaimRewardResp(properties);
    };

    /**
     * Encodes the specified ClaimRewardResp message. Does not implicitly {@link vipbetredate.ClaimRewardResp.verify|verify} messages.
     * @function encode
     * @memberof vipbetredate.ClaimRewardResp
     * @static
     * @param {vipbetredate.IClaimRewardResp} message ClaimRewardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClaimRewardResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.sign != null && Object.hasOwnProperty.call(message, "sign"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.sign);
      if (message.reward != null && Object.hasOwnProperty.call(message, "reward"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.reward);
      return writer;
    };

    /**
     * Encodes the specified ClaimRewardResp message, length delimited. Does not implicitly {@link vipbetredate.ClaimRewardResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof vipbetredate.ClaimRewardResp
     * @static
     * @param {vipbetredate.IClaimRewardResp} message ClaimRewardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClaimRewardResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ClaimRewardResp message from the specified reader or buffer.
     * @function decode
     * @memberof vipbetredate.ClaimRewardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {vipbetredate.ClaimRewardResp} ClaimRewardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClaimRewardResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.vipbetredate.ClaimRewardResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.sign = reader.int32();
            break;
          }
          case 3: {
            message.reward = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a ClaimRewardResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof vipbetredate.ClaimRewardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {vipbetredate.ClaimRewardResp} ClaimRewardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClaimRewardResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ClaimRewardResp message.
     * @function verify
     * @memberof vipbetredate.ClaimRewardResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ClaimRewardResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.sign != null && message.hasOwnProperty("sign"))
        if (!$util.isInteger(message.sign)) return "sign: integer expected";
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (
          !$util.isInteger(message.reward) &&
          !(message.reward && $util.isInteger(message.reward.low) && $util.isInteger(message.reward.high))
        )
          return "reward: integer|Long expected";
      return null;
    };

    /**
     * Creates a ClaimRewardResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof vipbetredate.ClaimRewardResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {vipbetredate.ClaimRewardResp} ClaimRewardResp
     */
    ClaimRewardResp.fromObject = function fromObject(object) {
      if (object instanceof $root.vipbetredate.ClaimRewardResp) return object;
      var message = new $root.vipbetredate.ClaimRewardResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.sign != null) message.sign = object.sign | 0;
      if (object.reward != null)
        if ($util.Long) (message.reward = $util.Long.fromValue(object.reward)).unsigned = false;
        else if (typeof object.reward === "string") message.reward = parseInt(object.reward, 10);
        else if (typeof object.reward === "number") message.reward = object.reward;
        else if (typeof object.reward === "object")
          message.reward = new $util.LongBits(object.reward.low >>> 0, object.reward.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a ClaimRewardResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof vipbetredate.ClaimRewardResp
     * @static
     * @param {vipbetredate.ClaimRewardResp} message ClaimRewardResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ClaimRewardResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.sign = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.sign != null && message.hasOwnProperty("sign")) object.sign = message.sign;
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (typeof message.reward === "number")
          object.reward = options.longs === String ? String(message.reward) : message.reward;
        else
          object.reward =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward)
              : options.longs === Number
                ? new $util.LongBits(message.reward.low >>> 0, message.reward.high >>> 0).toNumber()
                : message.reward;
      return object;
    };

    /**
     * Converts this ClaimRewardResp to JSON.
     * @function toJSON
     * @memberof vipbetredate.ClaimRewardResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ClaimRewardResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ClaimRewardResp
     * @function getTypeUrl
     * @memberof vipbetredate.ClaimRewardResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ClaimRewardResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/vipbetredate.ClaimRewardResp";
    };

    return ClaimRewardResp;
  })();

  vipbetredate.QueryRewardReq = (function () {
    /**
     * Properties of a QueryRewardReq.
     * @memberof vipbetredate
     * @interface IQueryRewardReq
     * @property {number|null} [uid] QueryRewardReq uid
     */

    /**
     * Constructs a new QueryRewardReq.
     * @memberof vipbetredate
     * @classdesc Represents a QueryRewardReq.
     * @implements IQueryRewardReq
     * @constructor
     * @param {vipbetredate.IQueryRewardReq=} [properties] Properties to set
     */
    function QueryRewardReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueryRewardReq uid.
     * @member {number} uid
     * @memberof vipbetredate.QueryRewardReq
     * @instance
     */
    QueryRewardReq.prototype.uid = 0;

    /**
     * Creates a new QueryRewardReq instance using the specified properties.
     * @function create
     * @memberof vipbetredate.QueryRewardReq
     * @static
     * @param {vipbetredate.IQueryRewardReq=} [properties] Properties to set
     * @returns {vipbetredate.QueryRewardReq} QueryRewardReq instance
     */
    QueryRewardReq.create = function create(properties) {
      return new QueryRewardReq(properties);
    };

    /**
     * Encodes the specified QueryRewardReq message. Does not implicitly {@link vipbetredate.QueryRewardReq.verify|verify} messages.
     * @function encode
     * @memberof vipbetredate.QueryRewardReq
     * @static
     * @param {vipbetredate.IQueryRewardReq} message QueryRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueryRewardReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      return writer;
    };

    /**
     * Encodes the specified QueryRewardReq message, length delimited. Does not implicitly {@link vipbetredate.QueryRewardReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof vipbetredate.QueryRewardReq
     * @static
     * @param {vipbetredate.IQueryRewardReq} message QueryRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueryRewardReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a QueryRewardReq message from the specified reader or buffer.
     * @function decode
     * @memberof vipbetredate.QueryRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {vipbetredate.QueryRewardReq} QueryRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueryRewardReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.vipbetredate.QueryRewardReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a QueryRewardReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof vipbetredate.QueryRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {vipbetredate.QueryRewardReq} QueryRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueryRewardReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a QueryRewardReq message.
     * @function verify
     * @memberof vipbetredate.QueryRewardReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    QueryRewardReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      return null;
    };

    /**
     * Creates a QueryRewardReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof vipbetredate.QueryRewardReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {vipbetredate.QueryRewardReq} QueryRewardReq
     */
    QueryRewardReq.fromObject = function fromObject(object) {
      if (object instanceof $root.vipbetredate.QueryRewardReq) return object;
      var message = new $root.vipbetredate.QueryRewardReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a QueryRewardReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof vipbetredate.QueryRewardReq
     * @static
     * @param {vipbetredate.QueryRewardReq} message QueryRewardReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    QueryRewardReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.uid = 0;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      return object;
    };

    /**
     * Converts this QueryRewardReq to JSON.
     * @function toJSON
     * @memberof vipbetredate.QueryRewardReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    QueryRewardReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for QueryRewardReq
     * @function getTypeUrl
     * @memberof vipbetredate.QueryRewardReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    QueryRewardReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/vipbetredate.QueryRewardReq";
    };

    return QueryRewardReq;
  })();

  vipbetredate.QuerylaimRewardResp = (function () {
    /**
     * Properties of a QuerylaimRewardResp.
     * @memberof vipbetredate
     * @interface IQuerylaimRewardResp
     * @property {number|null} [uid] QuerylaimRewardResp uid
     * @property {number|null} [sign] QuerylaimRewardResp sign
     * @property {string|null} [info] QuerylaimRewardResp info
     */

    /**
     * Constructs a new QuerylaimRewardResp.
     * @memberof vipbetredate
     * @classdesc Represents a QuerylaimRewardResp.
     * @implements IQuerylaimRewardResp
     * @constructor
     * @param {vipbetredate.IQuerylaimRewardResp=} [properties] Properties to set
     */
    function QuerylaimRewardResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * QuerylaimRewardResp uid.
     * @member {number} uid
     * @memberof vipbetredate.QuerylaimRewardResp
     * @instance
     */
    QuerylaimRewardResp.prototype.uid = 0;

    /**
     * QuerylaimRewardResp sign.
     * @member {number} sign
     * @memberof vipbetredate.QuerylaimRewardResp
     * @instance
     */
    QuerylaimRewardResp.prototype.sign = 0;

    /**
     * QuerylaimRewardResp info.
     * @member {string} info
     * @memberof vipbetredate.QuerylaimRewardResp
     * @instance
     */
    QuerylaimRewardResp.prototype.info = "";

    /**
     * Creates a new QuerylaimRewardResp instance using the specified properties.
     * @function create
     * @memberof vipbetredate.QuerylaimRewardResp
     * @static
     * @param {vipbetredate.IQuerylaimRewardResp=} [properties] Properties to set
     * @returns {vipbetredate.QuerylaimRewardResp} QuerylaimRewardResp instance
     */
    QuerylaimRewardResp.create = function create(properties) {
      return new QuerylaimRewardResp(properties);
    };

    /**
     * Encodes the specified QuerylaimRewardResp message. Does not implicitly {@link vipbetredate.QuerylaimRewardResp.verify|verify} messages.
     * @function encode
     * @memberof vipbetredate.QuerylaimRewardResp
     * @static
     * @param {vipbetredate.IQuerylaimRewardResp} message QuerylaimRewardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QuerylaimRewardResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.sign != null && Object.hasOwnProperty.call(message, "sign"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.sign);
      if (message.info != null && Object.hasOwnProperty.call(message, "info"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.info);
      return writer;
    };

    /**
     * Encodes the specified QuerylaimRewardResp message, length delimited. Does not implicitly {@link vipbetredate.QuerylaimRewardResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof vipbetredate.QuerylaimRewardResp
     * @static
     * @param {vipbetredate.IQuerylaimRewardResp} message QuerylaimRewardResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QuerylaimRewardResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a QuerylaimRewardResp message from the specified reader or buffer.
     * @function decode
     * @memberof vipbetredate.QuerylaimRewardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {vipbetredate.QuerylaimRewardResp} QuerylaimRewardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QuerylaimRewardResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.vipbetredate.QuerylaimRewardResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.sign = reader.int32();
            break;
          }
          case 3: {
            message.info = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a QuerylaimRewardResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof vipbetredate.QuerylaimRewardResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {vipbetredate.QuerylaimRewardResp} QuerylaimRewardResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QuerylaimRewardResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a QuerylaimRewardResp message.
     * @function verify
     * @memberof vipbetredate.QuerylaimRewardResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    QuerylaimRewardResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.sign != null && message.hasOwnProperty("sign"))
        if (!$util.isInteger(message.sign)) return "sign: integer expected";
      if (message.info != null && message.hasOwnProperty("info"))
        if (!$util.isString(message.info)) return "info: string expected";
      return null;
    };

    /**
     * Creates a QuerylaimRewardResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof vipbetredate.QuerylaimRewardResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {vipbetredate.QuerylaimRewardResp} QuerylaimRewardResp
     */
    QuerylaimRewardResp.fromObject = function fromObject(object) {
      if (object instanceof $root.vipbetredate.QuerylaimRewardResp) return object;
      var message = new $root.vipbetredate.QuerylaimRewardResp();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.sign != null) message.sign = object.sign | 0;
      if (object.info != null) message.info = String(object.info);
      return message;
    };

    /**
     * Creates a plain object from a QuerylaimRewardResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof vipbetredate.QuerylaimRewardResp
     * @static
     * @param {vipbetredate.QuerylaimRewardResp} message QuerylaimRewardResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    QuerylaimRewardResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.sign = 0;
        object.info = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.sign != null && message.hasOwnProperty("sign")) object.sign = message.sign;
      if (message.info != null && message.hasOwnProperty("info")) object.info = message.info;
      return object;
    };

    /**
     * Converts this QuerylaimRewardResp to JSON.
     * @function toJSON
     * @memberof vipbetredate.QuerylaimRewardResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    QuerylaimRewardResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for QuerylaimRewardResp
     * @function getTypeUrl
     * @memberof vipbetredate.QuerylaimRewardResp
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    QuerylaimRewardResp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/vipbetredate.QuerylaimRewardResp";
    };

    return QuerylaimRewardResp;
  })();

  return vipbetredate;
})();

$root.agent_rank_proto = (function () {
  /**
   * Namespace agent_rank_proto.
   * @exports agent_rank_proto
   * @namespace
   */
  var agent_rank_proto = {};

  /**
   * AGENT_RANK_LIST_TYPE enum.
   * @name agent_rank_proto.AGENT_RANK_LIST_TYPE
   * @enum {number}
   * @property {number} AGENT_RANK_LIST_NULL=0 AGENT_RANK_LIST_NULL value
   * @property {number} AGENT_RANK_LIST_DAY=1 AGENT_RANK_LIST_DAY value
   * @property {number} AGENT_RANK_LIST_WEEK=2 AGENT_RANK_LIST_WEEK value
   * @property {number} AGENT_RANK_LIST_MONTH=3 AGENT_RANK_LIST_MONTH value
   */
  agent_rank_proto.AGENT_RANK_LIST_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "AGENT_RANK_LIST_NULL")] = 0;
    values[(valuesById[1] = "AGENT_RANK_LIST_DAY")] = 1;
    values[(valuesById[2] = "AGENT_RANK_LIST_WEEK")] = 2;
    values[(valuesById[3] = "AGENT_RANK_LIST_MONTH")] = 3;
    return values;
  })();

  /**
   * AGENT_RANK_INFO_SUB_MSG_ID enum.
   * @name agent_rank_proto.AGENT_RANK_INFO_SUB_MSG_ID
   * @enum {number}
   * @property {number} AGENT_RANK_MSG_ID_NULL=0 AGENT_RANK_MSG_ID_NULL value
   * @property {number} AGENT_RANK_MSG_ID_MIN=5300 AGENT_RANK_MSG_ID_MIN value
   * @property {number} AGENT_RANK_MSG_ID_REPORT_PLAYING=5341 AGENT_RANK_MSG_ID_REPORT_PLAYING value
   * @property {number} AGENT_RANK_MSG_ID_REPORT_PLAYING_RESP=5342 AGENT_RANK_MSG_ID_REPORT_PLAYING_RESP value
   * @property {number} AGENT_RANK_MSG_ID_NOTIFY_AGENT_USER_REWARD=5343 AGENT_RANK_MSG_ID_NOTIFY_AGENT_USER_REWARD value
   * @property {number} AGENT_RANK_MSG_ID_NOTIFY_AGENT_USER_BINDING=5344 AGENT_RANK_MSG_ID_NOTIFY_AGENT_USER_BINDING value
   * @property {number} AGENT_RANK_MSG_ID_NOTIFY_AGENT_RANK_STATISTIC=5345 AGENT_RANK_MSG_ID_NOTIFY_AGENT_RANK_STATISTIC value
   * @property {number} AGENT_RANK_MSG_ID_NOTIFY_AGENT_RANK_REWARD=5346 AGENT_RANK_MSG_ID_NOTIFY_AGENT_RANK_REWARD value
   * @property {number} AGENT_RANK_MSG_ID_MID=5350 AGENT_RANK_MSG_ID_MID value
   * @property {number} AGENT_RANK_MSG_ID_MAX=5400 AGENT_RANK_MSG_ID_MAX value
   */
  agent_rank_proto.AGENT_RANK_INFO_SUB_MSG_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "AGENT_RANK_MSG_ID_NULL")] = 0;
    values[(valuesById[5300] = "AGENT_RANK_MSG_ID_MIN")] = 5300;
    values[(valuesById[5341] = "AGENT_RANK_MSG_ID_REPORT_PLAYING")] = 5341;
    values[(valuesById[5342] = "AGENT_RANK_MSG_ID_REPORT_PLAYING_RESP")] = 5342;
    values[(valuesById[5343] = "AGENT_RANK_MSG_ID_NOTIFY_AGENT_USER_REWARD")] = 5343;
    values[(valuesById[5344] = "AGENT_RANK_MSG_ID_NOTIFY_AGENT_USER_BINDING")] = 5344;
    values[(valuesById[5345] = "AGENT_RANK_MSG_ID_NOTIFY_AGENT_RANK_STATISTIC")] = 5345;
    values[(valuesById[5346] = "AGENT_RANK_MSG_ID_NOTIFY_AGENT_RANK_REWARD")] = 5346;
    values[(valuesById[5350] = "AGENT_RANK_MSG_ID_MID")] = 5350;
    values[(valuesById[5400] = "AGENT_RANK_MSG_ID_MAX")] = 5400;
    return values;
  })();

  agent_rank_proto.AgentRankReportUserPlaying = (function () {
    /**
     * Properties of an AgentRankReportUserPlaying.
     * @memberof agent_rank_proto
     * @interface IAgentRankReportUserPlaying
     * @property {number|null} [uid] AgentRankReportUserPlaying uid
     * @property {number|null} [company_id] AgentRankReportUserPlaying company_id
     * @property {string|null} [game_uid] AgentRankReportUserPlaying game_uid
     * @property {number|Long|null} [money] AgentRankReportUserPlaying money
     * @property {string|null} [platform] AgentRankReportUserPlaying platform
     */

    /**
     * Constructs a new AgentRankReportUserPlaying.
     * @memberof agent_rank_proto
     * @classdesc Represents an AgentRankReportUserPlaying.
     * @implements IAgentRankReportUserPlaying
     * @constructor
     * @param {agent_rank_proto.IAgentRankReportUserPlaying=} [properties] Properties to set
     */
    function AgentRankReportUserPlaying(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankReportUserPlaying uid.
     * @member {number} uid
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @instance
     */
    AgentRankReportUserPlaying.prototype.uid = 0;

    /**
     * AgentRankReportUserPlaying company_id.
     * @member {number} company_id
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @instance
     */
    AgentRankReportUserPlaying.prototype.company_id = 0;

    /**
     * AgentRankReportUserPlaying game_uid.
     * @member {string} game_uid
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @instance
     */
    AgentRankReportUserPlaying.prototype.game_uid = "";

    /**
     * AgentRankReportUserPlaying money.
     * @member {number|Long} money
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @instance
     */
    AgentRankReportUserPlaying.prototype.money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankReportUserPlaying platform.
     * @member {string} platform
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @instance
     */
    AgentRankReportUserPlaying.prototype.platform = "";

    /**
     * Creates a new AgentRankReportUserPlaying instance using the specified properties.
     * @function create
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @static
     * @param {agent_rank_proto.IAgentRankReportUserPlaying=} [properties] Properties to set
     * @returns {agent_rank_proto.AgentRankReportUserPlaying} AgentRankReportUserPlaying instance
     */
    AgentRankReportUserPlaying.create = function create(properties) {
      return new AgentRankReportUserPlaying(properties);
    };

    /**
     * Encodes the specified AgentRankReportUserPlaying message. Does not implicitly {@link agent_rank_proto.AgentRankReportUserPlaying.verify|verify} messages.
     * @function encode
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @static
     * @param {agent_rank_proto.IAgentRankReportUserPlaying} message AgentRankReportUserPlaying message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankReportUserPlaying.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.company_id != null && Object.hasOwnProperty.call(message, "company_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.company_id);
      if (message.game_uid != null && Object.hasOwnProperty.call(message, "game_uid"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.game_uid);
      if (message.money != null && Object.hasOwnProperty.call(message, "money"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.money);
      if (message.platform != null && Object.hasOwnProperty.call(message, "platform"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.platform);
      return writer;
    };

    /**
     * Encodes the specified AgentRankReportUserPlaying message, length delimited. Does not implicitly {@link agent_rank_proto.AgentRankReportUserPlaying.verify|verify} messages.
     * @function encodeDelimited
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @static
     * @param {agent_rank_proto.IAgentRankReportUserPlaying} message AgentRankReportUserPlaying message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankReportUserPlaying.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankReportUserPlaying message from the specified reader or buffer.
     * @function decode
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {agent_rank_proto.AgentRankReportUserPlaying} AgentRankReportUserPlaying
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankReportUserPlaying.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.agent_rank_proto.AgentRankReportUserPlaying();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.company_id = reader.uint32();
            break;
          }
          case 3: {
            message.game_uid = reader.string();
            break;
          }
          case 4: {
            message.money = reader.int64();
            break;
          }
          case 5: {
            message.platform = reader.string();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankReportUserPlaying message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {agent_rank_proto.AgentRankReportUserPlaying} AgentRankReportUserPlaying
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankReportUserPlaying.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankReportUserPlaying message.
     * @function verify
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankReportUserPlaying.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.company_id != null && message.hasOwnProperty("company_id"))
        if (!$util.isInteger(message.company_id)) return "company_id: integer expected";
      if (message.game_uid != null && message.hasOwnProperty("game_uid"))
        if (!$util.isString(message.game_uid)) return "game_uid: string expected";
      if (message.money != null && message.hasOwnProperty("money"))
        if (
          !$util.isInteger(message.money) &&
          !(message.money && $util.isInteger(message.money.low) && $util.isInteger(message.money.high))
        )
          return "money: integer|Long expected";
      if (message.platform != null && message.hasOwnProperty("platform"))
        if (!$util.isString(message.platform)) return "platform: string expected";
      return null;
    };

    /**
     * Creates an AgentRankReportUserPlaying message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {agent_rank_proto.AgentRankReportUserPlaying} AgentRankReportUserPlaying
     */
    AgentRankReportUserPlaying.fromObject = function fromObject(object) {
      if (object instanceof $root.agent_rank_proto.AgentRankReportUserPlaying) return object;
      var message = new $root.agent_rank_proto.AgentRankReportUserPlaying();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.company_id != null) message.company_id = object.company_id >>> 0;
      if (object.game_uid != null) message.game_uid = String(object.game_uid);
      if (object.money != null)
        if ($util.Long) (message.money = $util.Long.fromValue(object.money)).unsigned = false;
        else if (typeof object.money === "string") message.money = parseInt(object.money, 10);
        else if (typeof object.money === "number") message.money = object.money;
        else if (typeof object.money === "object")
          message.money = new $util.LongBits(object.money.low >>> 0, object.money.high >>> 0).toNumber();
      if (object.platform != null) message.platform = String(object.platform);
      return message;
    };

    /**
     * Creates a plain object from an AgentRankReportUserPlaying message. Also converts values to other types if specified.
     * @function toObject
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @static
     * @param {agent_rank_proto.AgentRankReportUserPlaying} message AgentRankReportUserPlaying
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankReportUserPlaying.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.company_id = 0;
        object.game_uid = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.money = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.money = options.longs === String ? "0" : 0;
        object.platform = "";
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.company_id != null && message.hasOwnProperty("company_id")) object.company_id = message.company_id;
      if (message.game_uid != null && message.hasOwnProperty("game_uid")) object.game_uid = message.game_uid;
      if (message.money != null && message.hasOwnProperty("money"))
        if (typeof message.money === "number")
          object.money = options.longs === String ? String(message.money) : message.money;
        else
          object.money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.money)
              : options.longs === Number
                ? new $util.LongBits(message.money.low >>> 0, message.money.high >>> 0).toNumber()
                : message.money;
      if (message.platform != null && message.hasOwnProperty("platform")) object.platform = message.platform;
      return object;
    };

    /**
     * Converts this AgentRankReportUserPlaying to JSON.
     * @function toJSON
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankReportUserPlaying.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankReportUserPlaying
     * @function getTypeUrl
     * @memberof agent_rank_proto.AgentRankReportUserPlaying
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankReportUserPlaying.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/agent_rank_proto.AgentRankReportUserPlaying";
    };

    return AgentRankReportUserPlaying;
  })();

  agent_rank_proto.AgentRankResponseUserPlaying = (function () {
    /**
     * Properties of an AgentRankResponseUserPlaying.
     * @memberof agent_rank_proto
     * @interface IAgentRankResponseUserPlaying
     */

    /**
     * Constructs a new AgentRankResponseUserPlaying.
     * @memberof agent_rank_proto
     * @classdesc Represents an AgentRankResponseUserPlaying.
     * @implements IAgentRankResponseUserPlaying
     * @constructor
     * @param {agent_rank_proto.IAgentRankResponseUserPlaying=} [properties] Properties to set
     */
    function AgentRankResponseUserPlaying(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new AgentRankResponseUserPlaying instance using the specified properties.
     * @function create
     * @memberof agent_rank_proto.AgentRankResponseUserPlaying
     * @static
     * @param {agent_rank_proto.IAgentRankResponseUserPlaying=} [properties] Properties to set
     * @returns {agent_rank_proto.AgentRankResponseUserPlaying} AgentRankResponseUserPlaying instance
     */
    AgentRankResponseUserPlaying.create = function create(properties) {
      return new AgentRankResponseUserPlaying(properties);
    };

    /**
     * Encodes the specified AgentRankResponseUserPlaying message. Does not implicitly {@link agent_rank_proto.AgentRankResponseUserPlaying.verify|verify} messages.
     * @function encode
     * @memberof agent_rank_proto.AgentRankResponseUserPlaying
     * @static
     * @param {agent_rank_proto.IAgentRankResponseUserPlaying} message AgentRankResponseUserPlaying message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseUserPlaying.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified AgentRankResponseUserPlaying message, length delimited. Does not implicitly {@link agent_rank_proto.AgentRankResponseUserPlaying.verify|verify} messages.
     * @function encodeDelimited
     * @memberof agent_rank_proto.AgentRankResponseUserPlaying
     * @static
     * @param {agent_rank_proto.IAgentRankResponseUserPlaying} message AgentRankResponseUserPlaying message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankResponseUserPlaying.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankResponseUserPlaying message from the specified reader or buffer.
     * @function decode
     * @memberof agent_rank_proto.AgentRankResponseUserPlaying
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {agent_rank_proto.AgentRankResponseUserPlaying} AgentRankResponseUserPlaying
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseUserPlaying.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.agent_rank_proto.AgentRankResponseUserPlaying();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankResponseUserPlaying message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof agent_rank_proto.AgentRankResponseUserPlaying
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {agent_rank_proto.AgentRankResponseUserPlaying} AgentRankResponseUserPlaying
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankResponseUserPlaying.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankResponseUserPlaying message.
     * @function verify
     * @memberof agent_rank_proto.AgentRankResponseUserPlaying
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankResponseUserPlaying.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates an AgentRankResponseUserPlaying message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof agent_rank_proto.AgentRankResponseUserPlaying
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {agent_rank_proto.AgentRankResponseUserPlaying} AgentRankResponseUserPlaying
     */
    AgentRankResponseUserPlaying.fromObject = function fromObject(object) {
      if (object instanceof $root.agent_rank_proto.AgentRankResponseUserPlaying) return object;
      return new $root.agent_rank_proto.AgentRankResponseUserPlaying();
    };

    /**
     * Creates a plain object from an AgentRankResponseUserPlaying message. Also converts values to other types if specified.
     * @function toObject
     * @memberof agent_rank_proto.AgentRankResponseUserPlaying
     * @static
     * @param {agent_rank_proto.AgentRankResponseUserPlaying} message AgentRankResponseUserPlaying
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankResponseUserPlaying.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this AgentRankResponseUserPlaying to JSON.
     * @function toJSON
     * @memberof agent_rank_proto.AgentRankResponseUserPlaying
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankResponseUserPlaying.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankResponseUserPlaying
     * @function getTypeUrl
     * @memberof agent_rank_proto.AgentRankResponseUserPlaying
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankResponseUserPlaying.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/agent_rank_proto.AgentRankResponseUserPlaying";
    };

    return AgentRankResponseUserPlaying;
  })();

  agent_rank_proto.AgentRankRequestVerifyRank = (function () {
    /**
     * Properties of an AgentRankRequestVerifyRank.
     * @memberof agent_rank_proto
     * @interface IAgentRankRequestVerifyRank
     * @property {number|Long|null} [reward_id] AgentRankRequestVerifyRank reward_id
     * @property {number|null} [uid] AgentRankRequestVerifyRank uid
     * @property {number|null} [audit_uid] AgentRankRequestVerifyRank audit_uid
     * @property {number|null} [audit_time] AgentRankRequestVerifyRank audit_time
     * @property {string|null} [audit_name] AgentRankRequestVerifyRank audit_name
     * @property {string|null} [audit_reason] AgentRankRequestVerifyRank audit_reason
     * @property {number|null} [past_verify] AgentRankRequestVerifyRank past_verify
     */

    /**
     * Constructs a new AgentRankRequestVerifyRank.
     * @memberof agent_rank_proto
     * @classdesc Represents an AgentRankRequestVerifyRank.
     * @implements IAgentRankRequestVerifyRank
     * @constructor
     * @param {agent_rank_proto.IAgentRankRequestVerifyRank=} [properties] Properties to set
     */
    function AgentRankRequestVerifyRank(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankRequestVerifyRank reward_id.
     * @member {number|Long} reward_id
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @instance
     */
    AgentRankRequestVerifyRank.prototype.reward_id = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankRequestVerifyRank uid.
     * @member {number} uid
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @instance
     */
    AgentRankRequestVerifyRank.prototype.uid = 0;

    /**
     * AgentRankRequestVerifyRank audit_uid.
     * @member {number} audit_uid
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @instance
     */
    AgentRankRequestVerifyRank.prototype.audit_uid = 0;

    /**
     * AgentRankRequestVerifyRank audit_time.
     * @member {number} audit_time
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @instance
     */
    AgentRankRequestVerifyRank.prototype.audit_time = 0;

    /**
     * AgentRankRequestVerifyRank audit_name.
     * @member {string} audit_name
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @instance
     */
    AgentRankRequestVerifyRank.prototype.audit_name = "";

    /**
     * AgentRankRequestVerifyRank audit_reason.
     * @member {string} audit_reason
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @instance
     */
    AgentRankRequestVerifyRank.prototype.audit_reason = "";

    /**
     * AgentRankRequestVerifyRank past_verify.
     * @member {number} past_verify
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @instance
     */
    AgentRankRequestVerifyRank.prototype.past_verify = 0;

    /**
     * Creates a new AgentRankRequestVerifyRank instance using the specified properties.
     * @function create
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @static
     * @param {agent_rank_proto.IAgentRankRequestVerifyRank=} [properties] Properties to set
     * @returns {agent_rank_proto.AgentRankRequestVerifyRank} AgentRankRequestVerifyRank instance
     */
    AgentRankRequestVerifyRank.create = function create(properties) {
      return new AgentRankRequestVerifyRank(properties);
    };

    /**
     * Encodes the specified AgentRankRequestVerifyRank message. Does not implicitly {@link agent_rank_proto.AgentRankRequestVerifyRank.verify|verify} messages.
     * @function encode
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @static
     * @param {agent_rank_proto.IAgentRankRequestVerifyRank} message AgentRankRequestVerifyRank message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestVerifyRank.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.reward_id != null && Object.hasOwnProperty.call(message, "reward_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.reward_id);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.uid);
      if (message.audit_uid != null && Object.hasOwnProperty.call(message, "audit_uid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.audit_uid);
      if (message.audit_time != null && Object.hasOwnProperty.call(message, "audit_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.audit_time);
      if (message.audit_name != null && Object.hasOwnProperty.call(message, "audit_name"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.audit_name);
      if (message.audit_reason != null && Object.hasOwnProperty.call(message, "audit_reason"))
        writer.uint32(/* id 6, wireType 2 =*/ 50).string(message.audit_reason);
      if (message.past_verify != null && Object.hasOwnProperty.call(message, "past_verify"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int32(message.past_verify);
      return writer;
    };

    /**
     * Encodes the specified AgentRankRequestVerifyRank message, length delimited. Does not implicitly {@link agent_rank_proto.AgentRankRequestVerifyRank.verify|verify} messages.
     * @function encodeDelimited
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @static
     * @param {agent_rank_proto.IAgentRankRequestVerifyRank} message AgentRankRequestVerifyRank message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRequestVerifyRank.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankRequestVerifyRank message from the specified reader or buffer.
     * @function decode
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {agent_rank_proto.AgentRankRequestVerifyRank} AgentRankRequestVerifyRank
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestVerifyRank.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.agent_rank_proto.AgentRankRequestVerifyRank();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.reward_id = reader.int64();
            break;
          }
          case 2: {
            message.uid = reader.uint32();
            break;
          }
          case 3: {
            message.audit_uid = reader.int32();
            break;
          }
          case 4: {
            message.audit_time = reader.uint32();
            break;
          }
          case 5: {
            message.audit_name = reader.string();
            break;
          }
          case 6: {
            message.audit_reason = reader.string();
            break;
          }
          case 7: {
            message.past_verify = reader.int32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankRequestVerifyRank message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {agent_rank_proto.AgentRankRequestVerifyRank} AgentRankRequestVerifyRank
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRequestVerifyRank.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankRequestVerifyRank message.
     * @function verify
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankRequestVerifyRank.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (
          !$util.isInteger(message.reward_id) &&
          !(message.reward_id && $util.isInteger(message.reward_id.low) && $util.isInteger(message.reward_id.high))
        )
          return "reward_id: integer|Long expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.audit_uid != null && message.hasOwnProperty("audit_uid"))
        if (!$util.isInteger(message.audit_uid)) return "audit_uid: integer expected";
      if (message.audit_time != null && message.hasOwnProperty("audit_time"))
        if (!$util.isInteger(message.audit_time)) return "audit_time: integer expected";
      if (message.audit_name != null && message.hasOwnProperty("audit_name"))
        if (!$util.isString(message.audit_name)) return "audit_name: string expected";
      if (message.audit_reason != null && message.hasOwnProperty("audit_reason"))
        if (!$util.isString(message.audit_reason)) return "audit_reason: string expected";
      if (message.past_verify != null && message.hasOwnProperty("past_verify"))
        if (!$util.isInteger(message.past_verify)) return "past_verify: integer expected";
      return null;
    };

    /**
     * Creates an AgentRankRequestVerifyRank message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {agent_rank_proto.AgentRankRequestVerifyRank} AgentRankRequestVerifyRank
     */
    AgentRankRequestVerifyRank.fromObject = function fromObject(object) {
      if (object instanceof $root.agent_rank_proto.AgentRankRequestVerifyRank) return object;
      var message = new $root.agent_rank_proto.AgentRankRequestVerifyRank();
      if (object.reward_id != null)
        if ($util.Long) (message.reward_id = $util.Long.fromValue(object.reward_id)).unsigned = false;
        else if (typeof object.reward_id === "string") message.reward_id = parseInt(object.reward_id, 10);
        else if (typeof object.reward_id === "number") message.reward_id = object.reward_id;
        else if (typeof object.reward_id === "object")
          message.reward_id = new $util.LongBits(object.reward_id.low >>> 0, object.reward_id.high >>> 0).toNumber();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.audit_uid != null) message.audit_uid = object.audit_uid | 0;
      if (object.audit_time != null) message.audit_time = object.audit_time >>> 0;
      if (object.audit_name != null) message.audit_name = String(object.audit_name);
      if (object.audit_reason != null) message.audit_reason = String(object.audit_reason);
      if (object.past_verify != null) message.past_verify = object.past_verify | 0;
      return message;
    };

    /**
     * Creates a plain object from an AgentRankRequestVerifyRank message. Also converts values to other types if specified.
     * @function toObject
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @static
     * @param {agent_rank_proto.AgentRankRequestVerifyRank} message AgentRankRequestVerifyRank
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankRequestVerifyRank.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_id =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_id = options.longs === String ? "0" : 0;
        object.uid = 0;
        object.audit_uid = 0;
        object.audit_time = 0;
        object.audit_name = "";
        object.audit_reason = "";
        object.past_verify = 0;
      }
      if (message.reward_id != null && message.hasOwnProperty("reward_id"))
        if (typeof message.reward_id === "number")
          object.reward_id = options.longs === String ? String(message.reward_id) : message.reward_id;
        else
          object.reward_id =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_id)
              : options.longs === Number
                ? new $util.LongBits(message.reward_id.low >>> 0, message.reward_id.high >>> 0).toNumber()
                : message.reward_id;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.audit_uid != null && message.hasOwnProperty("audit_uid")) object.audit_uid = message.audit_uid;
      if (message.audit_time != null && message.hasOwnProperty("audit_time")) object.audit_time = message.audit_time;
      if (message.audit_name != null && message.hasOwnProperty("audit_name")) object.audit_name = message.audit_name;
      if (message.audit_reason != null && message.hasOwnProperty("audit_reason"))
        object.audit_reason = message.audit_reason;
      if (message.past_verify != null && message.hasOwnProperty("past_verify"))
        object.past_verify = message.past_verify;
      return object;
    };

    /**
     * Converts this AgentRankRequestVerifyRank to JSON.
     * @function toJSON
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankRequestVerifyRank.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankRequestVerifyRank
     * @function getTypeUrl
     * @memberof agent_rank_proto.AgentRankRequestVerifyRank
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankRequestVerifyRank.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/agent_rank_proto.AgentRankRequestVerifyRank";
    };

    return AgentRankRequestVerifyRank;
  })();

  /**
   * AgentRankRewardType enum.
   * @name agent_rank_proto.AgentRankRewardType
   * @enum {number}
   * @property {number} AGENT_RANK_REWARD_TYPE_RECHARGE=0 AGENT_RANK_REWARD_TYPE_RECHARGE value
   * @property {number} AGENT_RANK_REWARD_TYPE_TOTAL_COUNT=1 AGENT_RANK_REWARD_TYPE_TOTAL_COUNT value
   * @property {number} AGENT_RANK_REWARD_TYPE_INVITE_COUNT=2 AGENT_RANK_REWARD_TYPE_INVITE_COUNT value
   * @property {number} AGENT_RANK_REWARD_TYPE_BET=3 AGENT_RANK_REWARD_TYPE_BET value
   * @property {number} AGENT_RANK_REWARD_TYPE_PUT_IN_JACKPOT=4 AGENT_RANK_REWARD_TYPE_PUT_IN_JACKPOT value
   */
  agent_rank_proto.AgentRankRewardType = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "AGENT_RANK_REWARD_TYPE_RECHARGE")] = 0;
    values[(valuesById[1] = "AGENT_RANK_REWARD_TYPE_TOTAL_COUNT")] = 1;
    values[(valuesById[2] = "AGENT_RANK_REWARD_TYPE_INVITE_COUNT")] = 2;
    values[(valuesById[3] = "AGENT_RANK_REWARD_TYPE_BET")] = 3;
    values[(valuesById[4] = "AGENT_RANK_REWARD_TYPE_PUT_IN_JACKPOT")] = 4;
    return values;
  })();

  agent_rank_proto.NotifyAgentUserRewardReq = (function () {
    /**
     * Properties of a NotifyAgentUserRewardReq.
     * @memberof agent_rank_proto
     * @interface INotifyAgentUserRewardReq
     * @property {number|null} [agent_uid] NotifyAgentUserRewardReq agent_uid
     * @property {number|null} [active_uid] NotifyAgentUserRewardReq active_uid
     * @property {number|null} [add_type] NotifyAgentUserRewardReq add_type
     * @property {number|Long|null} [add_value] NotifyAgentUserRewardReq add_value
     */

    /**
     * Constructs a new NotifyAgentUserRewardReq.
     * @memberof agent_rank_proto
     * @classdesc Represents a NotifyAgentUserRewardReq.
     * @implements INotifyAgentUserRewardReq
     * @constructor
     * @param {agent_rank_proto.INotifyAgentUserRewardReq=} [properties] Properties to set
     */
    function NotifyAgentUserRewardReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotifyAgentUserRewardReq agent_uid.
     * @member {number} agent_uid
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @instance
     */
    NotifyAgentUserRewardReq.prototype.agent_uid = 0;

    /**
     * NotifyAgentUserRewardReq active_uid.
     * @member {number} active_uid
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @instance
     */
    NotifyAgentUserRewardReq.prototype.active_uid = 0;

    /**
     * NotifyAgentUserRewardReq add_type.
     * @member {number} add_type
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @instance
     */
    NotifyAgentUserRewardReq.prototype.add_type = 0;

    /**
     * NotifyAgentUserRewardReq add_value.
     * @member {number|Long} add_value
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @instance
     */
    NotifyAgentUserRewardReq.prototype.add_value = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new NotifyAgentUserRewardReq instance using the specified properties.
     * @function create
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @static
     * @param {agent_rank_proto.INotifyAgentUserRewardReq=} [properties] Properties to set
     * @returns {agent_rank_proto.NotifyAgentUserRewardReq} NotifyAgentUserRewardReq instance
     */
    NotifyAgentUserRewardReq.create = function create(properties) {
      return new NotifyAgentUserRewardReq(properties);
    };

    /**
     * Encodes the specified NotifyAgentUserRewardReq message. Does not implicitly {@link agent_rank_proto.NotifyAgentUserRewardReq.verify|verify} messages.
     * @function encode
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @static
     * @param {agent_rank_proto.INotifyAgentUserRewardReq} message NotifyAgentUserRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotifyAgentUserRewardReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.agent_uid != null && Object.hasOwnProperty.call(message, "agent_uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.agent_uid);
      if (message.active_uid != null && Object.hasOwnProperty.call(message, "active_uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.active_uid);
      if (message.add_type != null && Object.hasOwnProperty.call(message, "add_type"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.add_type);
      if (message.add_value != null && Object.hasOwnProperty.call(message, "add_value"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.add_value);
      return writer;
    };

    /**
     * Encodes the specified NotifyAgentUserRewardReq message, length delimited. Does not implicitly {@link agent_rank_proto.NotifyAgentUserRewardReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @static
     * @param {agent_rank_proto.INotifyAgentUserRewardReq} message NotifyAgentUserRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotifyAgentUserRewardReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a NotifyAgentUserRewardReq message from the specified reader or buffer.
     * @function decode
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {agent_rank_proto.NotifyAgentUserRewardReq} NotifyAgentUserRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotifyAgentUserRewardReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.agent_rank_proto.NotifyAgentUserRewardReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.agent_uid = reader.uint32();
            break;
          }
          case 2: {
            message.active_uid = reader.uint32();
            break;
          }
          case 3: {
            message.add_type = reader.int32();
            break;
          }
          case 4: {
            message.add_value = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a NotifyAgentUserRewardReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {agent_rank_proto.NotifyAgentUserRewardReq} NotifyAgentUserRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotifyAgentUserRewardReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a NotifyAgentUserRewardReq message.
     * @function verify
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    NotifyAgentUserRewardReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.agent_uid != null && message.hasOwnProperty("agent_uid"))
        if (!$util.isInteger(message.agent_uid)) return "agent_uid: integer expected";
      if (message.active_uid != null && message.hasOwnProperty("active_uid"))
        if (!$util.isInteger(message.active_uid)) return "active_uid: integer expected";
      if (message.add_type != null && message.hasOwnProperty("add_type"))
        if (!$util.isInteger(message.add_type)) return "add_type: integer expected";
      if (message.add_value != null && message.hasOwnProperty("add_value"))
        if (
          !$util.isInteger(message.add_value) &&
          !(message.add_value && $util.isInteger(message.add_value.low) && $util.isInteger(message.add_value.high))
        )
          return "add_value: integer|Long expected";
      return null;
    };

    /**
     * Creates a NotifyAgentUserRewardReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {agent_rank_proto.NotifyAgentUserRewardReq} NotifyAgentUserRewardReq
     */
    NotifyAgentUserRewardReq.fromObject = function fromObject(object) {
      if (object instanceof $root.agent_rank_proto.NotifyAgentUserRewardReq) return object;
      var message = new $root.agent_rank_proto.NotifyAgentUserRewardReq();
      if (object.agent_uid != null) message.agent_uid = object.agent_uid >>> 0;
      if (object.active_uid != null) message.active_uid = object.active_uid >>> 0;
      if (object.add_type != null) message.add_type = object.add_type | 0;
      if (object.add_value != null)
        if ($util.Long) (message.add_value = $util.Long.fromValue(object.add_value)).unsigned = false;
        else if (typeof object.add_value === "string") message.add_value = parseInt(object.add_value, 10);
        else if (typeof object.add_value === "number") message.add_value = object.add_value;
        else if (typeof object.add_value === "object")
          message.add_value = new $util.LongBits(object.add_value.low >>> 0, object.add_value.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a NotifyAgentUserRewardReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @static
     * @param {agent_rank_proto.NotifyAgentUserRewardReq} message NotifyAgentUserRewardReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    NotifyAgentUserRewardReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.agent_uid = 0;
        object.active_uid = 0;
        object.add_type = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.add_value =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.add_value = options.longs === String ? "0" : 0;
      }
      if (message.agent_uid != null && message.hasOwnProperty("agent_uid")) object.agent_uid = message.agent_uid;
      if (message.active_uid != null && message.hasOwnProperty("active_uid")) object.active_uid = message.active_uid;
      if (message.add_type != null && message.hasOwnProperty("add_type")) object.add_type = message.add_type;
      if (message.add_value != null && message.hasOwnProperty("add_value"))
        if (typeof message.add_value === "number")
          object.add_value = options.longs === String ? String(message.add_value) : message.add_value;
        else
          object.add_value =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.add_value)
              : options.longs === Number
                ? new $util.LongBits(message.add_value.low >>> 0, message.add_value.high >>> 0).toNumber()
                : message.add_value;
      return object;
    };

    /**
     * Converts this NotifyAgentUserRewardReq to JSON.
     * @function toJSON
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    NotifyAgentUserRewardReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for NotifyAgentUserRewardReq
     * @function getTypeUrl
     * @memberof agent_rank_proto.NotifyAgentUserRewardReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    NotifyAgentUserRewardReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/agent_rank_proto.NotifyAgentUserRewardReq";
    };

    return NotifyAgentUserRewardReq;
  })();

  agent_rank_proto.NotifyAgentUserBindingReq = (function () {
    /**
     * Properties of a NotifyAgentUserBindingReq.
     * @memberof agent_rank_proto
     * @interface INotifyAgentUserBindingReq
     * @property {number|null} [agent_uid] NotifyAgentUserBindingReq agent_uid
     * @property {number|null} [active_uid] NotifyAgentUserBindingReq active_uid
     */

    /**
     * Constructs a new NotifyAgentUserBindingReq.
     * @memberof agent_rank_proto
     * @classdesc Represents a NotifyAgentUserBindingReq.
     * @implements INotifyAgentUserBindingReq
     * @constructor
     * @param {agent_rank_proto.INotifyAgentUserBindingReq=} [properties] Properties to set
     */
    function NotifyAgentUserBindingReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotifyAgentUserBindingReq agent_uid.
     * @member {number} agent_uid
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @instance
     */
    NotifyAgentUserBindingReq.prototype.agent_uid = 0;

    /**
     * NotifyAgentUserBindingReq active_uid.
     * @member {number} active_uid
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @instance
     */
    NotifyAgentUserBindingReq.prototype.active_uid = 0;

    /**
     * Creates a new NotifyAgentUserBindingReq instance using the specified properties.
     * @function create
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @static
     * @param {agent_rank_proto.INotifyAgentUserBindingReq=} [properties] Properties to set
     * @returns {agent_rank_proto.NotifyAgentUserBindingReq} NotifyAgentUserBindingReq instance
     */
    NotifyAgentUserBindingReq.create = function create(properties) {
      return new NotifyAgentUserBindingReq(properties);
    };

    /**
     * Encodes the specified NotifyAgentUserBindingReq message. Does not implicitly {@link agent_rank_proto.NotifyAgentUserBindingReq.verify|verify} messages.
     * @function encode
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @static
     * @param {agent_rank_proto.INotifyAgentUserBindingReq} message NotifyAgentUserBindingReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotifyAgentUserBindingReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.agent_uid != null && Object.hasOwnProperty.call(message, "agent_uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.agent_uid);
      if (message.active_uid != null && Object.hasOwnProperty.call(message, "active_uid"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.active_uid);
      return writer;
    };

    /**
     * Encodes the specified NotifyAgentUserBindingReq message, length delimited. Does not implicitly {@link agent_rank_proto.NotifyAgentUserBindingReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @static
     * @param {agent_rank_proto.INotifyAgentUserBindingReq} message NotifyAgentUserBindingReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotifyAgentUserBindingReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a NotifyAgentUserBindingReq message from the specified reader or buffer.
     * @function decode
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {agent_rank_proto.NotifyAgentUserBindingReq} NotifyAgentUserBindingReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotifyAgentUserBindingReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.agent_rank_proto.NotifyAgentUserBindingReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.agent_uid = reader.uint32();
            break;
          }
          case 2: {
            message.active_uid = reader.uint32();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a NotifyAgentUserBindingReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {agent_rank_proto.NotifyAgentUserBindingReq} NotifyAgentUserBindingReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotifyAgentUserBindingReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a NotifyAgentUserBindingReq message.
     * @function verify
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    NotifyAgentUserBindingReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.agent_uid != null && message.hasOwnProperty("agent_uid"))
        if (!$util.isInteger(message.agent_uid)) return "agent_uid: integer expected";
      if (message.active_uid != null && message.hasOwnProperty("active_uid"))
        if (!$util.isInteger(message.active_uid)) return "active_uid: integer expected";
      return null;
    };

    /**
     * Creates a NotifyAgentUserBindingReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {agent_rank_proto.NotifyAgentUserBindingReq} NotifyAgentUserBindingReq
     */
    NotifyAgentUserBindingReq.fromObject = function fromObject(object) {
      if (object instanceof $root.agent_rank_proto.NotifyAgentUserBindingReq) return object;
      var message = new $root.agent_rank_proto.NotifyAgentUserBindingReq();
      if (object.agent_uid != null) message.agent_uid = object.agent_uid >>> 0;
      if (object.active_uid != null) message.active_uid = object.active_uid >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a NotifyAgentUserBindingReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @static
     * @param {agent_rank_proto.NotifyAgentUserBindingReq} message NotifyAgentUserBindingReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    NotifyAgentUserBindingReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.agent_uid = 0;
        object.active_uid = 0;
      }
      if (message.agent_uid != null && message.hasOwnProperty("agent_uid")) object.agent_uid = message.agent_uid;
      if (message.active_uid != null && message.hasOwnProperty("active_uid")) object.active_uid = message.active_uid;
      return object;
    };

    /**
     * Converts this NotifyAgentUserBindingReq to JSON.
     * @function toJSON
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    NotifyAgentUserBindingReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for NotifyAgentUserBindingReq
     * @function getTypeUrl
     * @memberof agent_rank_proto.NotifyAgentUserBindingReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    NotifyAgentUserBindingReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/agent_rank_proto.NotifyAgentUserBindingReq";
    };

    return NotifyAgentUserBindingReq;
  })();

  agent_rank_proto.NotifyAgentRankStatisticReq = (function () {
    /**
     * Properties of a NotifyAgentRankStatisticReq.
     * @memberof agent_rank_proto
     * @interface INotifyAgentRankStatisticReq
     * @property {number|null} [rank_id] NotifyAgentRankStatisticReq rank_id
     * @property {number|null} [session_id] NotifyAgentRankStatisticReq session_id
     * @property {number|null} [uid] NotifyAgentRankStatisticReq uid
     * @property {number|null} [add_type] NotifyAgentRankStatisticReq add_type
     * @property {number|Long|null} [add_value] NotifyAgentRankStatisticReq add_value
     */

    /**
     * Constructs a new NotifyAgentRankStatisticReq.
     * @memberof agent_rank_proto
     * @classdesc Represents a NotifyAgentRankStatisticReq.
     * @implements INotifyAgentRankStatisticReq
     * @constructor
     * @param {agent_rank_proto.INotifyAgentRankStatisticReq=} [properties] Properties to set
     */
    function NotifyAgentRankStatisticReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotifyAgentRankStatisticReq rank_id.
     * @member {number} rank_id
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @instance
     */
    NotifyAgentRankStatisticReq.prototype.rank_id = 0;

    /**
     * NotifyAgentRankStatisticReq session_id.
     * @member {number} session_id
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @instance
     */
    NotifyAgentRankStatisticReq.prototype.session_id = 0;

    /**
     * NotifyAgentRankStatisticReq uid.
     * @member {number} uid
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @instance
     */
    NotifyAgentRankStatisticReq.prototype.uid = 0;

    /**
     * NotifyAgentRankStatisticReq add_type.
     * @member {number} add_type
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @instance
     */
    NotifyAgentRankStatisticReq.prototype.add_type = 0;

    /**
     * NotifyAgentRankStatisticReq add_value.
     * @member {number|Long} add_value
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @instance
     */
    NotifyAgentRankStatisticReq.prototype.add_value = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new NotifyAgentRankStatisticReq instance using the specified properties.
     * @function create
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @static
     * @param {agent_rank_proto.INotifyAgentRankStatisticReq=} [properties] Properties to set
     * @returns {agent_rank_proto.NotifyAgentRankStatisticReq} NotifyAgentRankStatisticReq instance
     */
    NotifyAgentRankStatisticReq.create = function create(properties) {
      return new NotifyAgentRankStatisticReq(properties);
    };

    /**
     * Encodes the specified NotifyAgentRankStatisticReq message. Does not implicitly {@link agent_rank_proto.NotifyAgentRankStatisticReq.verify|verify} messages.
     * @function encode
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @static
     * @param {agent_rank_proto.INotifyAgentRankStatisticReq} message NotifyAgentRankStatisticReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotifyAgentRankStatisticReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.rank_id != null && Object.hasOwnProperty.call(message, "rank_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.rank_id);
      if (message.session_id != null && Object.hasOwnProperty.call(message, "session_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.session_id);
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).uint32(message.uid);
      if (message.add_type != null && Object.hasOwnProperty.call(message, "add_type"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.add_type);
      if (message.add_value != null && Object.hasOwnProperty.call(message, "add_value"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.add_value);
      return writer;
    };

    /**
     * Encodes the specified NotifyAgentRankStatisticReq message, length delimited. Does not implicitly {@link agent_rank_proto.NotifyAgentRankStatisticReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @static
     * @param {agent_rank_proto.INotifyAgentRankStatisticReq} message NotifyAgentRankStatisticReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotifyAgentRankStatisticReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a NotifyAgentRankStatisticReq message from the specified reader or buffer.
     * @function decode
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {agent_rank_proto.NotifyAgentRankStatisticReq} NotifyAgentRankStatisticReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotifyAgentRankStatisticReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.agent_rank_proto.NotifyAgentRankStatisticReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.rank_id = reader.int32();
            break;
          }
          case 2: {
            message.session_id = reader.int32();
            break;
          }
          case 3: {
            message.uid = reader.uint32();
            break;
          }
          case 4: {
            message.add_type = reader.int32();
            break;
          }
          case 5: {
            message.add_value = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a NotifyAgentRankStatisticReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {agent_rank_proto.NotifyAgentRankStatisticReq} NotifyAgentRankStatisticReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotifyAgentRankStatisticReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a NotifyAgentRankStatisticReq message.
     * @function verify
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    NotifyAgentRankStatisticReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.rank_id != null && message.hasOwnProperty("rank_id"))
        if (!$util.isInteger(message.rank_id)) return "rank_id: integer expected";
      if (message.session_id != null && message.hasOwnProperty("session_id"))
        if (!$util.isInteger(message.session_id)) return "session_id: integer expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.add_type != null && message.hasOwnProperty("add_type"))
        if (!$util.isInteger(message.add_type)) return "add_type: integer expected";
      if (message.add_value != null && message.hasOwnProperty("add_value"))
        if (
          !$util.isInteger(message.add_value) &&
          !(message.add_value && $util.isInteger(message.add_value.low) && $util.isInteger(message.add_value.high))
        )
          return "add_value: integer|Long expected";
      return null;
    };

    /**
     * Creates a NotifyAgentRankStatisticReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {agent_rank_proto.NotifyAgentRankStatisticReq} NotifyAgentRankStatisticReq
     */
    NotifyAgentRankStatisticReq.fromObject = function fromObject(object) {
      if (object instanceof $root.agent_rank_proto.NotifyAgentRankStatisticReq) return object;
      var message = new $root.agent_rank_proto.NotifyAgentRankStatisticReq();
      if (object.rank_id != null) message.rank_id = object.rank_id | 0;
      if (object.session_id != null) message.session_id = object.session_id | 0;
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.add_type != null) message.add_type = object.add_type | 0;
      if (object.add_value != null)
        if ($util.Long) (message.add_value = $util.Long.fromValue(object.add_value)).unsigned = false;
        else if (typeof object.add_value === "string") message.add_value = parseInt(object.add_value, 10);
        else if (typeof object.add_value === "number") message.add_value = object.add_value;
        else if (typeof object.add_value === "object")
          message.add_value = new $util.LongBits(object.add_value.low >>> 0, object.add_value.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a NotifyAgentRankStatisticReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @static
     * @param {agent_rank_proto.NotifyAgentRankStatisticReq} message NotifyAgentRankStatisticReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    NotifyAgentRankStatisticReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.rank_id = 0;
        object.session_id = 0;
        object.uid = 0;
        object.add_type = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.add_value =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.add_value = options.longs === String ? "0" : 0;
      }
      if (message.rank_id != null && message.hasOwnProperty("rank_id")) object.rank_id = message.rank_id;
      if (message.session_id != null && message.hasOwnProperty("session_id")) object.session_id = message.session_id;
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.add_type != null && message.hasOwnProperty("add_type")) object.add_type = message.add_type;
      if (message.add_value != null && message.hasOwnProperty("add_value"))
        if (typeof message.add_value === "number")
          object.add_value = options.longs === String ? String(message.add_value) : message.add_value;
        else
          object.add_value =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.add_value)
              : options.longs === Number
                ? new $util.LongBits(message.add_value.low >>> 0, message.add_value.high >>> 0).toNumber()
                : message.add_value;
      return object;
    };

    /**
     * Converts this NotifyAgentRankStatisticReq to JSON.
     * @function toJSON
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    NotifyAgentRankStatisticReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for NotifyAgentRankStatisticReq
     * @function getTypeUrl
     * @memberof agent_rank_proto.NotifyAgentRankStatisticReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    NotifyAgentRankStatisticReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/agent_rank_proto.NotifyAgentRankStatisticReq";
    };

    return NotifyAgentRankStatisticReq;
  })();

  agent_rank_proto.AgentRankRewardData = (function () {
    /**
     * Properties of an AgentRankRewardData.
     * @memberof agent_rank_proto
     * @interface IAgentRankRewardData
     * @property {number|null} [uid] AgentRankRewardData uid
     * @property {number|null} [reward] AgentRankRewardData reward
     * @property {number|null} [rank] AgentRankRewardData rank
     * @property {number|Long|null} [money] AgentRankRewardData money
     * @property {number|null} [state] AgentRankRewardData state
     * @property {number|null} [reward_money_type] AgentRankRewardData reward_money_type
     * @property {number|Long|null} [reward_money] AgentRankRewardData reward_money
     */

    /**
     * Constructs a new AgentRankRewardData.
     * @memberof agent_rank_proto
     * @classdesc Represents an AgentRankRewardData.
     * @implements IAgentRankRewardData
     * @constructor
     * @param {agent_rank_proto.IAgentRankRewardData=} [properties] Properties to set
     */
    function AgentRankRewardData(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AgentRankRewardData uid.
     * @member {number} uid
     * @memberof agent_rank_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.uid = 0;

    /**
     * AgentRankRewardData reward.
     * @member {number} reward
     * @memberof agent_rank_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.reward = 0;

    /**
     * AgentRankRewardData rank.
     * @member {number} rank
     * @memberof agent_rank_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.rank = 0;

    /**
     * AgentRankRewardData money.
     * @member {number|Long} money
     * @memberof agent_rank_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AgentRankRewardData state.
     * @member {number} state
     * @memberof agent_rank_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.state = 0;

    /**
     * AgentRankRewardData reward_money_type.
     * @member {number} reward_money_type
     * @memberof agent_rank_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.reward_money_type = 0;

    /**
     * AgentRankRewardData reward_money.
     * @member {number|Long} reward_money
     * @memberof agent_rank_proto.AgentRankRewardData
     * @instance
     */
    AgentRankRewardData.prototype.reward_money = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new AgentRankRewardData instance using the specified properties.
     * @function create
     * @memberof agent_rank_proto.AgentRankRewardData
     * @static
     * @param {agent_rank_proto.IAgentRankRewardData=} [properties] Properties to set
     * @returns {agent_rank_proto.AgentRankRewardData} AgentRankRewardData instance
     */
    AgentRankRewardData.create = function create(properties) {
      return new AgentRankRewardData(properties);
    };

    /**
     * Encodes the specified AgentRankRewardData message. Does not implicitly {@link agent_rank_proto.AgentRankRewardData.verify|verify} messages.
     * @function encode
     * @memberof agent_rank_proto.AgentRankRewardData
     * @static
     * @param {agent_rank_proto.IAgentRankRewardData} message AgentRankRewardData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRewardData.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.reward != null && Object.hasOwnProperty.call(message, "reward"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.reward);
      if (message.rank != null && Object.hasOwnProperty.call(message, "rank"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.rank);
      if (message.money != null && Object.hasOwnProperty.call(message, "money"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.money);
      if (message.state != null && Object.hasOwnProperty.call(message, "state"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.state);
      if (message.reward_money_type != null && Object.hasOwnProperty.call(message, "reward_money_type"))
        writer.uint32(/* id 6, wireType 0 =*/ 48).int32(message.reward_money_type);
      if (message.reward_money != null && Object.hasOwnProperty.call(message, "reward_money"))
        writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.reward_money);
      return writer;
    };

    /**
     * Encodes the specified AgentRankRewardData message, length delimited. Does not implicitly {@link agent_rank_proto.AgentRankRewardData.verify|verify} messages.
     * @function encodeDelimited
     * @memberof agent_rank_proto.AgentRankRewardData
     * @static
     * @param {agent_rank_proto.IAgentRankRewardData} message AgentRankRewardData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AgentRankRewardData.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AgentRankRewardData message from the specified reader or buffer.
     * @function decode
     * @memberof agent_rank_proto.AgentRankRewardData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {agent_rank_proto.AgentRankRewardData} AgentRankRewardData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRewardData.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.agent_rank_proto.AgentRankRewardData();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.uid = reader.uint32();
            break;
          }
          case 2: {
            message.reward = reader.int32();
            break;
          }
          case 3: {
            message.rank = reader.int32();
            break;
          }
          case 4: {
            message.money = reader.int64();
            break;
          }
          case 5: {
            message.state = reader.int32();
            break;
          }
          case 6: {
            message.reward_money_type = reader.int32();
            break;
          }
          case 7: {
            message.reward_money = reader.int64();
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AgentRankRewardData message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof agent_rank_proto.AgentRankRewardData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {agent_rank_proto.AgentRankRewardData} AgentRankRewardData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AgentRankRewardData.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AgentRankRewardData message.
     * @function verify
     * @memberof agent_rank_proto.AgentRankRewardData
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AgentRankRewardData.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.reward != null && message.hasOwnProperty("reward"))
        if (!$util.isInteger(message.reward)) return "reward: integer expected";
      if (message.rank != null && message.hasOwnProperty("rank"))
        if (!$util.isInteger(message.rank)) return "rank: integer expected";
      if (message.money != null && message.hasOwnProperty("money"))
        if (
          !$util.isInteger(message.money) &&
          !(message.money && $util.isInteger(message.money.low) && $util.isInteger(message.money.high))
        )
          return "money: integer|Long expected";
      if (message.state != null && message.hasOwnProperty("state"))
        if (!$util.isInteger(message.state)) return "state: integer expected";
      if (message.reward_money_type != null && message.hasOwnProperty("reward_money_type"))
        if (!$util.isInteger(message.reward_money_type)) return "reward_money_type: integer expected";
      if (message.reward_money != null && message.hasOwnProperty("reward_money"))
        if (
          !$util.isInteger(message.reward_money) &&
          !(
            message.reward_money &&
            $util.isInteger(message.reward_money.low) &&
            $util.isInteger(message.reward_money.high)
          )
        )
          return "reward_money: integer|Long expected";
      return null;
    };

    /**
     * Creates an AgentRankRewardData message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof agent_rank_proto.AgentRankRewardData
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {agent_rank_proto.AgentRankRewardData} AgentRankRewardData
     */
    AgentRankRewardData.fromObject = function fromObject(object) {
      if (object instanceof $root.agent_rank_proto.AgentRankRewardData) return object;
      var message = new $root.agent_rank_proto.AgentRankRewardData();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.reward != null) message.reward = object.reward | 0;
      if (object.rank != null) message.rank = object.rank | 0;
      if (object.money != null)
        if ($util.Long) (message.money = $util.Long.fromValue(object.money)).unsigned = false;
        else if (typeof object.money === "string") message.money = parseInt(object.money, 10);
        else if (typeof object.money === "number") message.money = object.money;
        else if (typeof object.money === "object")
          message.money = new $util.LongBits(object.money.low >>> 0, object.money.high >>> 0).toNumber();
      if (object.state != null) message.state = object.state | 0;
      if (object.reward_money_type != null) message.reward_money_type = object.reward_money_type | 0;
      if (object.reward_money != null)
        if ($util.Long) (message.reward_money = $util.Long.fromValue(object.reward_money)).unsigned = false;
        else if (typeof object.reward_money === "string") message.reward_money = parseInt(object.reward_money, 10);
        else if (typeof object.reward_money === "number") message.reward_money = object.reward_money;
        else if (typeof object.reward_money === "object")
          message.reward_money = new $util.LongBits(
            object.reward_money.low >>> 0,
            object.reward_money.high >>> 0,
          ).toNumber();
      return message;
    };

    /**
     * Creates a plain object from an AgentRankRewardData message. Also converts values to other types if specified.
     * @function toObject
     * @memberof agent_rank_proto.AgentRankRewardData
     * @static
     * @param {agent_rank_proto.AgentRankRewardData} message AgentRankRewardData
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AgentRankRewardData.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.reward = 0;
        object.rank = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.money = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.money = options.longs === String ? "0" : 0;
        object.state = 0;
        object.reward_money_type = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.reward_money =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.reward_money = options.longs === String ? "0" : 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.reward != null && message.hasOwnProperty("reward")) object.reward = message.reward;
      if (message.rank != null && message.hasOwnProperty("rank")) object.rank = message.rank;
      if (message.money != null && message.hasOwnProperty("money"))
        if (typeof message.money === "number")
          object.money = options.longs === String ? String(message.money) : message.money;
        else
          object.money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.money)
              : options.longs === Number
                ? new $util.LongBits(message.money.low >>> 0, message.money.high >>> 0).toNumber()
                : message.money;
      if (message.state != null && message.hasOwnProperty("state")) object.state = message.state;
      if (message.reward_money_type != null && message.hasOwnProperty("reward_money_type"))
        object.reward_money_type = message.reward_money_type;
      if (message.reward_money != null && message.hasOwnProperty("reward_money"))
        if (typeof message.reward_money === "number")
          object.reward_money = options.longs === String ? String(message.reward_money) : message.reward_money;
        else
          object.reward_money =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.reward_money)
              : options.longs === Number
                ? new $util.LongBits(message.reward_money.low >>> 0, message.reward_money.high >>> 0).toNumber()
                : message.reward_money;
      return object;
    };

    /**
     * Converts this AgentRankRewardData to JSON.
     * @function toJSON
     * @memberof agent_rank_proto.AgentRankRewardData
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AgentRankRewardData.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AgentRankRewardData
     * @function getTypeUrl
     * @memberof agent_rank_proto.AgentRankRewardData
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AgentRankRewardData.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/agent_rank_proto.AgentRankRewardData";
    };

    return AgentRankRewardData;
  })();

  agent_rank_proto.NotifyAgentRankRewardReq = (function () {
    /**
     * Properties of a NotifyAgentRankRewardReq.
     * @memberof agent_rank_proto
     * @interface INotifyAgentRankRewardReq
     * @property {number|null} [rank_id] NotifyAgentRankRewardReq rank_id
     * @property {number|null} [session_id] NotifyAgentRankRewardReq session_id
     * @property {number|null} [rank_list_type] NotifyAgentRankRewardReq rank_list_type
     * @property {number|null} [reward_time] NotifyAgentRankRewardReq reward_time
     * @property {number|Long|null} [jackpot] NotifyAgentRankRewardReq jackpot
     * @property {Array.<agent_rank_proto.IAgentRankRewardData>|null} [data_array] NotifyAgentRankRewardReq data_array
     */

    /**
     * Constructs a new NotifyAgentRankRewardReq.
     * @memberof agent_rank_proto
     * @classdesc Represents a NotifyAgentRankRewardReq.
     * @implements INotifyAgentRankRewardReq
     * @constructor
     * @param {agent_rank_proto.INotifyAgentRankRewardReq=} [properties] Properties to set
     */
    function NotifyAgentRankRewardReq(properties) {
      this.data_array = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotifyAgentRankRewardReq rank_id.
     * @member {number} rank_id
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @instance
     */
    NotifyAgentRankRewardReq.prototype.rank_id = 0;

    /**
     * NotifyAgentRankRewardReq session_id.
     * @member {number} session_id
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @instance
     */
    NotifyAgentRankRewardReq.prototype.session_id = 0;

    /**
     * NotifyAgentRankRewardReq rank_list_type.
     * @member {number} rank_list_type
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @instance
     */
    NotifyAgentRankRewardReq.prototype.rank_list_type = 0;

    /**
     * NotifyAgentRankRewardReq reward_time.
     * @member {number} reward_time
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @instance
     */
    NotifyAgentRankRewardReq.prototype.reward_time = 0;

    /**
     * NotifyAgentRankRewardReq jackpot.
     * @member {number|Long} jackpot
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @instance
     */
    NotifyAgentRankRewardReq.prototype.jackpot = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * NotifyAgentRankRewardReq data_array.
     * @member {Array.<agent_rank_proto.IAgentRankRewardData>} data_array
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @instance
     */
    NotifyAgentRankRewardReq.prototype.data_array = $util.emptyArray;

    /**
     * Creates a new NotifyAgentRankRewardReq instance using the specified properties.
     * @function create
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @static
     * @param {agent_rank_proto.INotifyAgentRankRewardReq=} [properties] Properties to set
     * @returns {agent_rank_proto.NotifyAgentRankRewardReq} NotifyAgentRankRewardReq instance
     */
    NotifyAgentRankRewardReq.create = function create(properties) {
      return new NotifyAgentRankRewardReq(properties);
    };

    /**
     * Encodes the specified NotifyAgentRankRewardReq message. Does not implicitly {@link agent_rank_proto.NotifyAgentRankRewardReq.verify|verify} messages.
     * @function encode
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @static
     * @param {agent_rank_proto.INotifyAgentRankRewardReq} message NotifyAgentRankRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotifyAgentRankRewardReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.rank_id != null && Object.hasOwnProperty.call(message, "rank_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.rank_id);
      if (message.session_id != null && Object.hasOwnProperty.call(message, "session_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.session_id);
      if (message.rank_list_type != null && Object.hasOwnProperty.call(message, "rank_list_type"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.rank_list_type);
      if (message.reward_time != null && Object.hasOwnProperty.call(message, "reward_time"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).uint32(message.reward_time);
      if (message.jackpot != null && Object.hasOwnProperty.call(message, "jackpot"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int64(message.jackpot);
      if (message.data_array != null && message.data_array.length)
        for (var i = 0; i < message.data_array.length; ++i)
          $root.agent_rank_proto.AgentRankRewardData.encode(
            message.data_array[i],
            writer.uint32(/* id 6, wireType 2 =*/ 50).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified NotifyAgentRankRewardReq message, length delimited. Does not implicitly {@link agent_rank_proto.NotifyAgentRankRewardReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @static
     * @param {agent_rank_proto.INotifyAgentRankRewardReq} message NotifyAgentRankRewardReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotifyAgentRankRewardReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a NotifyAgentRankRewardReq message from the specified reader or buffer.
     * @function decode
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {agent_rank_proto.NotifyAgentRankRewardReq} NotifyAgentRankRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotifyAgentRankRewardReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.agent_rank_proto.NotifyAgentRankRewardReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1: {
            message.rank_id = reader.int32();
            break;
          }
          case 2: {
            message.session_id = reader.int32();
            break;
          }
          case 3: {
            message.rank_list_type = reader.int32();
            break;
          }
          case 4: {
            message.reward_time = reader.uint32();
            break;
          }
          case 5: {
            message.jackpot = reader.int64();
            break;
          }
          case 6: {
            if (!(message.data_array && message.data_array.length)) message.data_array = [];
            message.data_array.push($root.agent_rank_proto.AgentRankRewardData.decode(reader, reader.uint32()));
            break;
          }
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a NotifyAgentRankRewardReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {agent_rank_proto.NotifyAgentRankRewardReq} NotifyAgentRankRewardReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotifyAgentRankRewardReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a NotifyAgentRankRewardReq message.
     * @function verify
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    NotifyAgentRankRewardReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.rank_id != null && message.hasOwnProperty("rank_id"))
        if (!$util.isInteger(message.rank_id)) return "rank_id: integer expected";
      if (message.session_id != null && message.hasOwnProperty("session_id"))
        if (!$util.isInteger(message.session_id)) return "session_id: integer expected";
      if (message.rank_list_type != null && message.hasOwnProperty("rank_list_type"))
        if (!$util.isInteger(message.rank_list_type)) return "rank_list_type: integer expected";
      if (message.reward_time != null && message.hasOwnProperty("reward_time"))
        if (!$util.isInteger(message.reward_time)) return "reward_time: integer expected";
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (
          !$util.isInteger(message.jackpot) &&
          !(message.jackpot && $util.isInteger(message.jackpot.low) && $util.isInteger(message.jackpot.high))
        )
          return "jackpot: integer|Long expected";
      if (message.data_array != null && message.hasOwnProperty("data_array")) {
        if (!Array.isArray(message.data_array)) return "data_array: array expected";
        for (var i = 0; i < message.data_array.length; ++i) {
          var error = $root.agent_rank_proto.AgentRankRewardData.verify(message.data_array[i]);
          if (error) return "data_array." + error;
        }
      }
      return null;
    };

    /**
     * Creates a NotifyAgentRankRewardReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {agent_rank_proto.NotifyAgentRankRewardReq} NotifyAgentRankRewardReq
     */
    NotifyAgentRankRewardReq.fromObject = function fromObject(object) {
      if (object instanceof $root.agent_rank_proto.NotifyAgentRankRewardReq) return object;
      var message = new $root.agent_rank_proto.NotifyAgentRankRewardReq();
      if (object.rank_id != null) message.rank_id = object.rank_id | 0;
      if (object.session_id != null) message.session_id = object.session_id | 0;
      if (object.rank_list_type != null) message.rank_list_type = object.rank_list_type | 0;
      if (object.reward_time != null) message.reward_time = object.reward_time >>> 0;
      if (object.jackpot != null)
        if ($util.Long) (message.jackpot = $util.Long.fromValue(object.jackpot)).unsigned = false;
        else if (typeof object.jackpot === "string") message.jackpot = parseInt(object.jackpot, 10);
        else if (typeof object.jackpot === "number") message.jackpot = object.jackpot;
        else if (typeof object.jackpot === "object")
          message.jackpot = new $util.LongBits(object.jackpot.low >>> 0, object.jackpot.high >>> 0).toNumber();
      if (object.data_array) {
        if (!Array.isArray(object.data_array))
          throw TypeError(".agent_rank_proto.NotifyAgentRankRewardReq.data_array: array expected");
        message.data_array = [];
        for (var i = 0; i < object.data_array.length; ++i) {
          if (typeof object.data_array[i] !== "object")
            throw TypeError(".agent_rank_proto.NotifyAgentRankRewardReq.data_array: object expected");
          message.data_array[i] = $root.agent_rank_proto.AgentRankRewardData.fromObject(object.data_array[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a NotifyAgentRankRewardReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @static
     * @param {agent_rank_proto.NotifyAgentRankRewardReq} message NotifyAgentRankRewardReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    NotifyAgentRankRewardReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.data_array = [];
      if (options.defaults) {
        object.rank_id = 0;
        object.session_id = 0;
        object.rank_list_type = 0;
        object.reward_time = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.jackpot =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.jackpot = options.longs === String ? "0" : 0;
      }
      if (message.rank_id != null && message.hasOwnProperty("rank_id")) object.rank_id = message.rank_id;
      if (message.session_id != null && message.hasOwnProperty("session_id")) object.session_id = message.session_id;
      if (message.rank_list_type != null && message.hasOwnProperty("rank_list_type"))
        object.rank_list_type = message.rank_list_type;
      if (message.reward_time != null && message.hasOwnProperty("reward_time"))
        object.reward_time = message.reward_time;
      if (message.jackpot != null && message.hasOwnProperty("jackpot"))
        if (typeof message.jackpot === "number")
          object.jackpot = options.longs === String ? String(message.jackpot) : message.jackpot;
        else
          object.jackpot =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.jackpot)
              : options.longs === Number
                ? new $util.LongBits(message.jackpot.low >>> 0, message.jackpot.high >>> 0).toNumber()
                : message.jackpot;
      if (message.data_array && message.data_array.length) {
        object.data_array = [];
        for (var j = 0; j < message.data_array.length; ++j)
          object.data_array[j] = $root.agent_rank_proto.AgentRankRewardData.toObject(message.data_array[j], options);
      }
      return object;
    };

    /**
     * Converts this NotifyAgentRankRewardReq to JSON.
     * @function toJSON
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    NotifyAgentRankRewardReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for NotifyAgentRankRewardReq
     * @function getTypeUrl
     * @memberof agent_rank_proto.NotifyAgentRankRewardReq
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    NotifyAgentRankRewardReq.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
      if (typeUrlPrefix === undefined) {
        typeUrlPrefix = "type.googleapis.com";
      }
      return typeUrlPrefix + "/agent_rank_proto.NotifyAgentRankRewardReq";
    };

    return NotifyAgentRankRewardReq;
  })();

  return agent_rank_proto;
})();

module.exports = $root;
