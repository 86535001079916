import { clientProtoPrefix as prefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace Agent {
  export const Req = {
    INFO: prefix("GetUserProxyInfo"), // 请求代理数据
    WITHDRAW_LIST: prefix("GetUserWithdrawList"), // 请求提现详情明细
    WITHDRAW: prefix("Withdraw"), // 用户请求提现操作
    TEAM_INFO: prefix("GetUserTeamInfo"), // 请求团队详情信息
    USER_SUB_INFO: prefix("GetUserSubInfo"), // 搜索下属详情信息
    TEAM_LIST: prefix("GetUserTeamList"), // 团队下属列表
    COMMISSION_LIST: prefix("GetCommissionFlowList"), // 请求佣金流水明细
    INVITE: prefix("GetUserInvite"), // 请求分享邀请海报
    CONFIG: prefix("GetUserAgentConfig"), // 请求代理配置信息
    INVITE_REWARD: prefix("GetInviteReward"), // 请求获取邀请奖励
    CHECK_INVITESTATUS: prefix("CheckInviteStatus"),
  };
  export const Resp = {
    TEAM_INFO: prefix("GetUserTeamInfoResp"), // 请求团队详情信息
  };
}

CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_INFO_RESP] = Agent.Req.INFO;
CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_WITHDRAW_LIST_RESP] = Agent.Req.WITHDRAW_LIST;
CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_WITHDRAW_RESP] = Agent.Req.WITHDRAW;
CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_TEAM_INFO_RESP] = Agent.Resp.TEAM_INFO;
CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_SUB_INFO_RESP] = Agent.Req.USER_SUB_INFO;
CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_TEAM_LIST_RESP] = Agent.Req.TEAM_LIST;
CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_COMMISSION_LIST_RESP] = Agent.Req.COMMISSION_LIST;
CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_INVITE_RESP] = Agent.Req.INVITE;
CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_AGENT_CONFIG_RESP] = Agent.Req.CONFIG;
CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_GET_INVITE_REWARD_RESP] = Agent.Req.INVITE_REWARD;
CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_CHECK_INVITE_STATUS_RESP] = Agent.Req.CHECK_INVITESTATUS;
