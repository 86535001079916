import { client_proto } from "@/utils/net/shared/proto";
import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep, setWith } from "lodash-es";

type CheckInviteStatus = {
  bonus: number;
  charge_limit: number;
  flow_limit: number;
  total_charge: number;
  total_flow: number;
  uid: number;
};

type AgentState = {
  checkInviteStatus: Partial<CheckInviteStatus>;

  withrawList: client_proto.IGetUserWithdrawListResp;

  info: client_proto.IGetUserProxyInfoResp;

  withraw: client_proto.IWithdrawResp;

  teamInfo: client_proto.IGetUserTeamInfoResp;

  userSubInfo: client_proto.IGetUserSubInfoResp;

  teamList: client_proto.IGetUserTeamListResp;

  commissionList: client_proto.IGetCommissionFlowListResp;

  invite: client_proto.IGetUserInviteResp;

  config: client_proto.IGetUserAgentConfigResp;

  inviteReward: client_proto.IGetInviteRewardResp;
};

const initialState: AgentState = {
  checkInviteStatus: {},
  withrawList: {
    withdraw_info: [],
    page_index: null,
  },
  info: {},
  withraw: {},
  teamInfo: {},
  userSubInfo: {},
  teamList: {},
  commissionList: {},
  invite: {},
  config: {},
  inviteReward: {},
};

export const agentSlice = createSlice({
  name: "agent",
  initialState,
  reducers: {
    setInfo: (state, action) => {
      const { payload } = action;
      state.info = payload;
    },

    setWithrawList: (state, action) => {
      const { payload } = action;
      state.withrawList = payload;
    },

    setWithraw: (state, action) => {
      const { payload } = action;
      state.withraw = cloneDeep(payload);
    },

    setTeamInfo: (state, action) => {
      const { payload } = action;
      state.teamInfo = payload;
    },

    setUserSubInfo: (state, action) => {
      const { payload } = action;
      state.userSubInfo = payload;
    },

    setTeamList: (state, action) => {
      const { payload } = action;
      state.teamList = payload;
    },

    setCommssionList: (state, action) => {
      const { payload } = action;
      state.commissionList = payload;
    },

    setInvite: (state, action) => {
      const { payload } = action;
      state.invite = payload;
    },

    setConfig: (state, action) => {
      const { payload } = action;
      state.config = payload;
    },

    setInviteReward: (state, action) => {
      const { payload } = action;
      return {
        ...state,
        inviteReward: payload,
      };
    },

    setCheckInviteStatus: (state, action) => {
      const { payload } = action;
      state.checkInviteStatus = payload;
    },
  },
});

export namespace AgentActions {
  export const {
    setInfo,
    setWithrawList,
    setWithraw,
    setTeamInfo,
    setUserSubInfo,
    setTeamList,
    setCommssionList,
    setInvite,
    setConfig,
    setInviteReward,
    setCheckInviteStatus,
  } = agentSlice.actions;
}

export default agentSlice.reducer;
