const alert = require("./alert.json");
const common = require("./common.json");
const earn = require("./earn.json");
const profile = require("./profile.json");
const ranking = require("./ranking.json");
const share = require("./share.json");
const vip = require("./vip.json");
const shopping = require("./shopping.json");
const withdrawal = require("./withdrawal.json");
const totalRechange = require("./totalRechage.json");
const spin = require("./spin.json");
const rank = require("./rank.json");
const home = require("./home.json");
const login = require("./login.json");
const wallet = require("./wallet.json");
const game = require("./game.json");
const gifts = require("./gifts.json");
const notfiy = require("./notify.json");
const activity = require("./activity.json");

const pt = {
  alert,
  ...common,
  ...earn,
  ...profile,
  ranking,
  ...share,
  ...login,
  ...totalRechange,
  vip,
  shopping,
  withdrawal,
  spin,
  rank,
  home,
  wallet,
  ...game,
  gifts,
  notfiy,
  activity,
};

export default pt;
