/*
 * @Description: 网络接口
 * @Author: zy
 * @Date: 2021-03-02 15:11:34
 * @Reference:
 */
import protoRoot from "./shared/proto.js";
import gameprotoRoot from "./shared/game_common/game_common_proto.js";

export interface INetContract {
  onNet(event: NetEvent, data?: any): void;
}

export type NetMsg<T> = {
  route: string;
  rc?: number;
  data?: T;
  body?: T;
  structure?: any;
};

export enum NetEvent {
  DATA,
  READY,
  NOT_READY,
  AUTH_ERROR,
  CONNECT_FAILED,
  CLOSED,
  DELAY,
}
export enum NetState {
  NONE,
  WAITING,
  READY,
  CLOSED,
}

// 消息名映射
export const CmdToPbName: { [key: number]: string } = {};

export const asset_proto = protoRoot.asset;
export const client_proto = protoRoot.client_proto;
export const vipbetredate_proto = protoRoot.vipbetredate;
export const room_alloc_proto = gameprotoRoot.roomalloc;
export const game_base_proto = gameprotoRoot.gamebase;
