import { client_proto } from "@/utils/net/shared/proto";
import { createSlice } from "@reduxjs/toolkit";

type HallState = {
  bannerHall: Partial<client_proto.IBannerHallResp>;
  facePull: Partial<client_proto.IFacePullResp>;
  isFCP: boolean;
  isFace: boolean; // 判断是否跳转到首页，然后开始请求拍脸图数据
};

const initialState: HallState = {
  bannerHall: {},
  facePull: {},
  isFCP: false,
  isFace: false,
};

export const hallSlice = createSlice({
  name: "hall",
  initialState,
  reducers: {
    setBannerHall: (state, action) => {
      const { payload } = action;
      state.bannerHall = payload;
    },
    setFacePull: (state, action) => {
      const { payload } = action;
      state.facePull = payload;
    },
    setFCP: (state, action) => {
      const { payload } = action;
      state.isFCP = payload;
    },
    setFace: (state, action) => {
      const { payload } = action;
      state.isFace = payload;
    },
  },
});

export namespace HallActions {
  export const { setBannerHall, setFacePull, setFCP, setFace } = hallSlice.actions;
}

export default hallSlice.reducer;
