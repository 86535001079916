import { client_proto } from "@/utils/net/shared/proto";
import { createSlice } from "@reduxjs/toolkit";

type RecommendState = {
  facePull: client_proto.IFacePullResp;
};

const initialState: RecommendState = {
  facePull: {},
};

export const recommendSlice = createSlice({
  name: "recommend",
  initialState,
  reducers: {
    setFacePull(state, action) {
      const { payload } = action;
      state.facePull = payload;
    },
  },
});

export namespace RecommendAction {
  export const { setFacePull } = recommendSlice.actions;
}

export default recommendSlice.reducer;
