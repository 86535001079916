import { client_proto } from "@/utils/net/shared/proto";
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

type RankItem = {
  id: number;
  name: string;
  rank_start_time: number;
  rank_end_time: number;
};

type RankState = {
  showList: client_proto.IResponseRankShowListResp;
  dataList: client_proto.IResponseRankListResp;
  historyDataList: client_proto.IResponseRankListResp;
  jackpot: client_proto.IResponseRankJackpotResp;
  rewardList: client_proto.IResponseRewardListResp;
  getReward: client_proto.IResponseGetRewardResp;
  gameList: client_proto.IResponseJoinGameListResp;
  getReport: client_proto.IRankListReportResp;
};

const initialState: RankState = {
  showList: {},
  dataList: {},
  historyDataList: {},
  jackpot: {},
  rewardList: {},
  getReward: {},
  gameList: {},
  getReport: {},
};

export const rankSlice = createSlice({
  name: "rank",
  initialState: initialState,
  reducers: {
    setShowList: (state, action) => {
      const { payload } = action;
      state.showList = payload;
    },
    setDataList: (state, action) => {
      const { payload } = action;
      state.dataList = payload;
    },
    setHistoryDataList: (state, action) => {
      const { payload } = action;
      state.historyDataList = payload;
    },
    setJackpot: (state, action) => {
      const { payload } = action;
      state.jackpot = payload;
    },
    setRewardList: (state, action) => {
      const { payload } = action;
      state.rewardList = payload;
    },
    setGetReward: (state, action) => {
      const { payload } = action;
      state.getReward = payload;
    },
    setGameList: (state, action) => {
      const { payload } = action;
      state.gameList = payload;
    },
    setGetReport: (state, action) => {
      const { payload } = action;
      state.getReport = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<RankState>) => {
    builder.addCase(PURGE, (state, action) => {
      return initialState;
    });
  },
});

export namespace RankActions {
  export const {
    setShowList,
    setDataList,
    setJackpot,
    setRewardList,
    setGetReward,
    setGameList,
    setGetReport,
    setHistoryDataList,
  } = rankSlice.actions;
}

export default rankSlice.reducer;
