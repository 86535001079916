import { bridge_proxy_proto, client_proto, game_manage_proto, tgame_proto } from "@/utils/net/shared/proto";
import { createSlice } from "@reduxjs/toolkit";

type MultiLanguageGameGroupV1 = {
  lang: string;
  hall_id: number;
  categoryList: {
    active: string;
    cover: string;
    desc: string;
    flow_id: number;
    icon: string;
    id: number;
    is_recommend: number;
    lines: number;
    name: string;
    parent_id: number;
    sort: number;
    status: number;
    style: number;
    user_tag_ids: number[];
  }[];
  user_tags: any[];
};

type TeamState = {
  multiLanguageGameGroupV1: MultiLanguageGameGroupV1;
  gameGroupDetailV1: Partial<game_manage_proto.GameGroupDetailV1Resp>;
  gameGroupDetailV2: Partial<game_manage_proto.GameGroupDetailV2Resp>;
  bridgeLoginResp: bridge_proxy_proto.ILoginResp;
  tGameLoginResp: tgame_proto.ILoginResp;
  tGameLogoutResp: tgame_proto.ILogoutResp;
  gameHallDetailResp: game_manage_proto.IGameHallDetailResp;
  gameGroupDetailResp: game_manage_proto.IGameGroupDetailResp;
  bridgeProxyKickOutResp: bridge_proxy_proto.IKickOutPush;
  tGameKickOutResp: tgame_proto.IKickOutPush;
  gameBannerUserPush: client_proto.IGameBannerUserPush;
};

const initialState: TeamState = {
  multiLanguageGameGroupV1: {} as any,
  gameGroupDetailV1: {},
  gameGroupDetailV2: {},
  bridgeLoginResp: {},
  tGameLoginResp: {},
  tGameLogoutResp: {},
  gameHallDetailResp: {},
  gameGroupDetailResp: {},
  bridgeProxyKickOutResp: {},
  tGameKickOutResp: {},
  gameBannerUserPush: {},
};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setMultiLanguageGameGroupV1: (state, action) => {
      const { payload } = action;
      state.multiLanguageGameGroupV1 = payload;
    },
    setGameGroupDetailV1: (state, action) => {
      const { payload } = action;
      state.gameGroupDetailV1 = payload;
    },
    setGameGroupDetailV2: (state, action) => {
      const { payload } = action;
      state.gameGroupDetailV2 = payload;
    },
    setBridgeLoginResp: (state, action) => {
      const { payload } = action;
      state.bridgeLoginResp = payload;
    },
    setTGameLoginResp: (state, action) => {
      const { payload } = action;
      state.tGameLoginResp = payload;
    },
    setTGameLogoutResp: (state, action) => {
      const { payload } = action;
      state.tGameLogoutResp = payload;
    },
    setGameHallDetailResp: (state, action) => {
      const { payload } = action;
      state.gameHallDetailResp = payload;
    },
    setGameGroupDetailResp: (state, action) => {
      const { payload } = action;
      state.gameGroupDetailResp = payload;
    },
    setBridgeProxyKickOutResp: (state, action) => {
      const { payload } = action;
      state.bridgeProxyKickOutResp = payload;
    },
    setTGameKickOutResp: (state, action) => {
      const { payload } = action;
      state.tGameKickOutResp = payload;
    },
    setGameBannerUserPush: (state, action) => {
      const { payload } = action;
      state.gameBannerUserPush = payload;
    },
  },
});

export namespace TeamActions {
  export const {
    setBridgeLoginResp,
    setBridgeProxyKickOutResp,
    setGameGroupDetailResp,
    setGameGroupDetailV1,
    setGameGroupDetailV2,
    setGameHallDetailResp,
    setMultiLanguageGameGroupV1,
    setTGameLoginResp,
    setTGameLogoutResp,
    setTGameKickOutResp,
    setGameBannerUserPush,
  } = teamSlice.actions;
}

export default teamSlice.reducer;
