import { clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace VIP {
  export const Req = {
    PAGE_DATA: clientProtoPrefix("GetVipConfigInfoReq"),
    REWARD: clientProtoPrefix("GetVipPrivilegeReq"),
    HISTORY: clientProtoPrefix("GetVipDetailLogReq"),
    DETAIL: clientProtoPrefix("VipLevelDescReq"),
    VIP_INFO: clientProtoPrefix("GetUserVipReq"),

    QUERY_REWARD: "vipbetredate.QueryRewardReq",
  };

  export const Resp = {
    PAGE_DATA: clientProtoPrefix("GetVipConfigInfoResp"),
    REWARD: clientProtoPrefix("SetVipPrivilegeResp"),
    HISTORY: clientProtoPrefix("GetVipDetailLogResp"),
    DETAIL: clientProtoPrefix("VipLevelDescResp"),
    VIP_INFO: clientProtoPrefix("GetUserVipResp"),
  };
}

CmdToPbName[client_proto.VIP_INFO_SUB_MSG_ID.VIPMI_VIP_GET_INFO_RESP] = VIP.Resp.PAGE_DATA;
CmdToPbName[client_proto.VIP_INFO_SUB_MSG_ID.VIPMI_VIP_SET_PRIVILEGE_INFO_RESP] = VIP.Resp.REWARD;
CmdToPbName[client_proto.VIP_INFO_SUB_MSG_ID.VIPMI_VIP_GET_DETAIL_LOG_RESP] = VIP.Resp.HISTORY;
CmdToPbName[client_proto.VIP_INFO_SUB_MSG_ID.VIPMI_GET_LEVEL_DESC_RESP] = VIP.Resp.DETAIL;
CmdToPbName[client_proto.VIP_INFO_SUB_MSG_ID.VIPMI_GET_USER_VIP_RESP] = VIP.Resp.VIP_INFO;
