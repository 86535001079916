import { client_proto } from "@/utils/net/shared/proto";
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

type WalletState = {
  depositInfo: client_proto.IChargeConfigResp;
  depositOrder: client_proto.ICreateChargeOrderResp;
  depositRecord: client_proto.IGetChargeLogResp;
  depositBanner: client_proto.IGetBannerListResp;
  withdrawInfo: client_proto.IGetWithdrawConfigResp;
  withdrawBank: client_proto.ISetBankCardResp;
  withdrawOrder: client_proto.ICreateWithdrawOrderResp;
  withdrawRecord: client_proto.IGetWithdrawLogResp;
};
const initialState: WalletState = {
  depositInfo: {},
  depositOrder: {},
  depositBanner: {},
  depositRecord: {},
  withdrawInfo: {},
  withdrawBank: {},
  withdrawOrder: {},
  withdrawRecord: {},
};
const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    setDepositInfo(state, action) {
      const { payload } = action;
      state.depositInfo = payload;
    },
    setDepositOrder(state, action) {
      const { payload } = action;
      return {
        ...state,
        depositOrder: payload,
      };
    },
    setDepositRecord(state, action) {
      const { payload } = action;
      state.depositRecord = payload;
    },
    setDepositBanner(state, action) {
      const { payload } = action;
      state.depositBanner = payload;
    },
    setWithdrawInfo(state, action) {
      const { payload } = action;
      state.withdrawInfo = payload;
    },
    setWithdrawBank(state, action) {
      const { payload } = action;
      state.withdrawBank = payload;
    },
    setWithdrawOrder(state, action) {
      const { payload } = action;
      return {
        ...state,
        withdrawOrder: payload,
      };
    },
    setWithdrawRecord(state, action) {
      const { payload } = action;
      state.withdrawRecord = payload;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<WalletState>) => {
    builder.addCase(PURGE, (state, action) => {
      return initialState;
    });
  },
});

export const { reducer } = walletSlice;
export namespace WalletActions {
  export const {
    setDepositInfo,
    setDepositOrder,
    setDepositRecord,
    setDepositBanner,
    setWithdrawInfo,
    setWithdrawBank,
    setWithdrawOrder,
    setWithdrawRecord,
  } = walletSlice.actions;
}
export default walletSlice.reducer;
