import { clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace Recommend {
  export const Req = {
    /** 大厅 */
    FacePull: clientProtoPrefix("FacePullReq"),
  };

  export const Resp = {
    /** 大厅 */
    FacePull: clientProtoPrefix("FacePullResp"),
  };
}

//推荐弹窗
CmdToPbName[client_proto.FACE_INFO_SUB_MSG_ID.FACE_MSG_ID_PULL_RESP] = Recommend.Resp.FacePull;
