import { assetProtoPrefix } from "@/utils";
import { asset_proto, CmdToPbName } from "@/utils/net/INet";

export namespace Asset {
  export const Req = {
    Asset: assetProtoPrefix("AssetReq"),
  };
  export const Resp = {
    Asset: assetProtoPrefix("AssetResp"),
  };
}

CmdToPbName[asset_proto.ASSET_CMD.ASSET_CMD_GET_ASSET_RESP] = Asset.Resp.Asset;
