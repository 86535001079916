import { clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace ExchangeCode {
  export const Req = {
    /** 获取配置 */
    GiftGetShowPage: clientProtoPrefix("GiftGetShowPageReq"),
    /** 请求兑换码 */
    OpenGift: clientProtoPrefix("OpenGiftReq"),
  };

  export const Resp = {
    /** 获取配置 */
    GiftGetShowPage: clientProtoPrefix("GiftGetShowPageResp"),

    /** 请求兑换码 */
    OpenGift: clientProtoPrefix("OpenGiftResp"),
  };
}

// 兑换码配置返回
CmdToPbName[client_proto.GIFT_INFO_SUB_MSG_ID.GIFT_MSG_ID_GET_SHOW_PAGE_RESP] = ExchangeCode.Resp.GiftGetShowPage;

// 兑换码兑换返回
CmdToPbName[client_proto.GIFT_INFO_SUB_MSG_ID.GIFT_MSG_ID_OPEN_GIFT_RESP] = ExchangeCode.Resp.OpenGift;
