import md5 from "md5";
import { StorageData } from "../net/storage/StorageData";

namespace SDK {
  export const device = (): string => {
    let deviceID = StorageData.local.get("deviceID");
    if (deviceID == null || deviceID == "") {
      const encode = new Date().getTime() + Math.random() * 10000;
      deviceID = "_" + md5(encode.toString());
      StorageData.local.set("deviceID", deviceID);
    }
    return deviceID as string;
  };

  /**
   * table校验，返回自身或者{}、[]
   * @param param
   * @param isArray 默认false 为空时返回{}
   * @returns
   */
  export function tableVerify(param: any, isArray = false) {
    if (!param) {
      if (isArray) {
        return [];
      } else {
        return {};
      }
    }
    let propty = Object.prototype.toString.call(param);
    if (propty === "[object Object]" || propty == "[object Array]") {
      return param;
    }
    if (isArray) {
      return [];
    } else {
      return {};
    }
  }
}

export default SDK;
