import { VIP } from "@/const";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto, vipbetredate } from "@/utils/net/shared/proto";

export namespace VipService {
  export const getVipPageData = (user_id: number) => {
    BrowserWsClient.instance.uid = user_id;
    BrowserWsClient.instance.handleSendData({
      route: VIP.Req.PAGE_DATA,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO,
      cmd: client_proto.VIP_INFO_SUB_MSG_ID.VIPMI_VIP_GET_INFO_REQ,
      data: {
        user_id,
      },
    });
  };

  // 查询vipbet是否有奖励
  export const getVipBetRedate = (uid: number) => {
    BrowserWsClient.instance.uid = uid;
    BrowserWsClient.instance.handleSendData({
      route: VIP.Req.QUERY_REWARD,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_VIP_BET_REDATE,
      cmd: vipbetredate.ROOMALLOC_CMD.ROOMALLOC_CMD_VIP_BET_REDATE_INFO_REQ,
      data: {
        uid,
      },
    });
  };

  /**
   * @description 获取vip的一些基础数据
   */
  export const getCurVipInfo = (user_id: number) => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.VIP_INFO_SUB_MSG_ID.VIPMI_GET_USER_VIP_REQ;
    BrowserWsClient.instance.handleSendData({
      route: VIP.Req.VIP_INFO,
      dsttype,
      cmd,
      data: {
        user_id,
      },
    });
  };

  /**
   * @description 获取vip详情的数据
   */
  export const getVipDetail = () => {
    const dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    const cmd = client_proto.VIP_INFO_SUB_MSG_ID.VIPMI_GET_LEVEL_DESC_REQ;
    BrowserWsClient.instance.handleSendData({
      route: VIP.Req.DETAIL,
      dsttype,
      cmd,
      data: {},
    });
  };

  /**
   * @description 获取vip奖励的历史数据
   */
  export const getVipRewardHistory = (period: number, pageSize: number, pageIndex: number) => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let ctype = client_proto.VIP_INFO_SUB_MSG_ID.VIPMI_VIP_GET_DETAIL_LOG_REQ;
    BrowserWsClient.instance.handleSendData({
      route: VIP.Req.HISTORY,
      dsttype,
      cmd: ctype,
      data: {
        period: period,
        page_size: pageSize,
        page_index: pageIndex,
      },
    });
  };
  /**
   * @description 获取vip奖励的数据
   */
  export const getVipReward = (uid: number, rewardId: number, vipId: number) => {
    BrowserWsClient.instance.handleSendData({
      route: VIP.Req.REWARD,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO,
      cmd: client_proto.VIP_INFO_SUB_MSG_ID.VIPMI_VIP_GET_PRIVILEGE_INFO_REQ,
      data: {
        user_id: uid,
        reward_id: rewardId,
        vip_id: vipId,
      },
    });
  };
}
