/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal.js");

// Common aliases
var $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.roomalloc = (function () {
  /**
   * Namespace roomalloc.
   * @exports roomalloc
   * @namespace
   */
  var roomalloc = {};

  /**
   * ROOMALLOC_CMD enum.
   * @name roomalloc.ROOMALLOC_CMD
   * @enum {number}
   * @property {number} ROOMALLOC_CMD_NONE=0 ROOMALLOC_CMD_NONE value
   * @property {number} ROOMALLOC_CMD_ALLOC_TABLE_REQ=4702 ROOMALLOC_CMD_ALLOC_TABLE_REQ value
   * @property {number} ROOMALLOC_CMD_ALLOC_TABLE_RESP=4703 ROOMALLOC_CMD_ALLOC_TABLE_RESP value
   * @property {number} ROOMALLOC_CMD_LEVEL_CONFIG_REQ=4704 ROOMALLOC_CMD_LEVEL_CONFIG_REQ value
   * @property {number} ROOMALLOC_CMD_LEVEL_CONFIG_RESP=4705 ROOMALLOC_CMD_LEVEL_CONFIG_RESP value
   */
  roomalloc.ROOMALLOC_CMD = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "ROOMALLOC_CMD_NONE")] = 0;
    values[(valuesById[4702] = "ROOMALLOC_CMD_ALLOC_TABLE_REQ")] = 4702;
    values[(valuesById[4703] = "ROOMALLOC_CMD_ALLOC_TABLE_RESP")] = 4703;
    values[(valuesById[4704] = "ROOMALLOC_CMD_LEVEL_CONFIG_REQ")] = 4704;
    values[(valuesById[4705] = "ROOMALLOC_CMD_LEVEL_CONFIG_RESP")] = 4705;
    return values;
  })();

  roomalloc.AllocTableReq = (function () {
    /**
     * Properties of an AllocTableReq.
     * @memberof roomalloc
     * @interface IAllocTableReq
     * @property {number|null} [uid] AllocTableReq uid
     * @property {number|null} [game_id] AllocTableReq game_id
     * @property {number|Long|null} [alloc_param] AllocTableReq alloc_param
     * @property {number|null} [except_tid] AllocTableReq except_tid
     * @property {number|null} [target_tid] AllocTableReq target_tid
     */

    /**
     * Constructs a new AllocTableReq.
     * @memberof roomalloc
     * @classdesc Represents an AllocTableReq.
     * @implements IAllocTableReq
     * @constructor
     * @param {roomalloc.IAllocTableReq=} [properties] Properties to set
     */
    function AllocTableReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AllocTableReq uid.
     * @member {number} uid
     * @memberof roomalloc.AllocTableReq
     * @instance
     */
    AllocTableReq.prototype.uid = 0;

    /**
     * AllocTableReq game_id.
     * @member {number} game_id
     * @memberof roomalloc.AllocTableReq
     * @instance
     */
    AllocTableReq.prototype.game_id = 0;

    /**
     * AllocTableReq alloc_param.
     * @member {number|Long} alloc_param
     * @memberof roomalloc.AllocTableReq
     * @instance
     */
    AllocTableReq.prototype.alloc_param = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * AllocTableReq except_tid.
     * @member {number} except_tid
     * @memberof roomalloc.AllocTableReq
     * @instance
     */
    AllocTableReq.prototype.except_tid = 0;

    /**
     * AllocTableReq target_tid.
     * @member {number} target_tid
     * @memberof roomalloc.AllocTableReq
     * @instance
     */
    AllocTableReq.prototype.target_tid = 0;

    /**
     * Creates a new AllocTableReq instance using the specified properties.
     * @function create
     * @memberof roomalloc.AllocTableReq
     * @static
     * @param {roomalloc.IAllocTableReq=} [properties] Properties to set
     * @returns {roomalloc.AllocTableReq} AllocTableReq instance
     */
    AllocTableReq.create = function create(properties) {
      return new AllocTableReq(properties);
    };

    /**
     * Encodes the specified AllocTableReq message. Does not implicitly {@link roomalloc.AllocTableReq.verify|verify} messages.
     * @function encode
     * @memberof roomalloc.AllocTableReq
     * @static
     * @param {roomalloc.IAllocTableReq} message AllocTableReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AllocTableReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.game_id != null && Object.hasOwnProperty.call(message, "game_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.game_id);
      if (message.alloc_param != null && Object.hasOwnProperty.call(message, "alloc_param"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.alloc_param);
      if (message.except_tid != null && Object.hasOwnProperty.call(message, "except_tid"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int32(message.except_tid);
      if (message.target_tid != null && Object.hasOwnProperty.call(message, "target_tid"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).int32(message.target_tid);
      return writer;
    };

    /**
     * Encodes the specified AllocTableReq message, length delimited. Does not implicitly {@link roomalloc.AllocTableReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof roomalloc.AllocTableReq
     * @static
     * @param {roomalloc.IAllocTableReq} message AllocTableReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AllocTableReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AllocTableReq message from the specified reader or buffer.
     * @function decode
     * @memberof roomalloc.AllocTableReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {roomalloc.AllocTableReq} AllocTableReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AllocTableReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.roomalloc.AllocTableReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.uid = reader.uint32();
            break;
          case 2:
            message.game_id = reader.int32();
            break;
          case 3:
            message.alloc_param = reader.int64();
            break;
          case 4:
            message.except_tid = reader.int32();
            break;
          case 5:
            message.target_tid = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AllocTableReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof roomalloc.AllocTableReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {roomalloc.AllocTableReq} AllocTableReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AllocTableReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AllocTableReq message.
     * @function verify
     * @memberof roomalloc.AllocTableReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AllocTableReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.game_id != null && message.hasOwnProperty("game_id"))
        if (!$util.isInteger(message.game_id)) return "game_id: integer expected";
      if (message.alloc_param != null && message.hasOwnProperty("alloc_param"))
        if (
          !$util.isInteger(message.alloc_param) &&
          !(
            message.alloc_param &&
            $util.isInteger(message.alloc_param.low) &&
            $util.isInteger(message.alloc_param.high)
          )
        )
          return "alloc_param: integer|Long expected";
      if (message.except_tid != null && message.hasOwnProperty("except_tid"))
        if (!$util.isInteger(message.except_tid)) return "except_tid: integer expected";
      if (message.target_tid != null && message.hasOwnProperty("target_tid"))
        if (!$util.isInteger(message.target_tid)) return "target_tid: integer expected";
      return null;
    };

    /**
     * Creates an AllocTableReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof roomalloc.AllocTableReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {roomalloc.AllocTableReq} AllocTableReq
     */
    AllocTableReq.fromObject = function fromObject(object) {
      if (object instanceof $root.roomalloc.AllocTableReq) return object;
      var message = new $root.roomalloc.AllocTableReq();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.game_id != null) message.game_id = object.game_id | 0;
      if (object.alloc_param != null)
        if ($util.Long) (message.alloc_param = $util.Long.fromValue(object.alloc_param)).unsigned = false;
        else if (typeof object.alloc_param === "string") message.alloc_param = parseInt(object.alloc_param, 10);
        else if (typeof object.alloc_param === "number") message.alloc_param = object.alloc_param;
        else if (typeof object.alloc_param === "object")
          message.alloc_param = new $util.LongBits(
            object.alloc_param.low >>> 0,
            object.alloc_param.high >>> 0,
          ).toNumber();
      if (object.except_tid != null) message.except_tid = object.except_tid | 0;
      if (object.target_tid != null) message.target_tid = object.target_tid | 0;
      return message;
    };

    /**
     * Creates a plain object from an AllocTableReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof roomalloc.AllocTableReq
     * @static
     * @param {roomalloc.AllocTableReq} message AllocTableReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AllocTableReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.game_id = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.alloc_param =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.alloc_param = options.longs === String ? "0" : 0;
        object.except_tid = 0;
        object.target_tid = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.game_id != null && message.hasOwnProperty("game_id")) object.game_id = message.game_id;
      if (message.alloc_param != null && message.hasOwnProperty("alloc_param"))
        if (typeof message.alloc_param === "number")
          object.alloc_param = options.longs === String ? String(message.alloc_param) : message.alloc_param;
        else
          object.alloc_param =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.alloc_param)
              : options.longs === Number
                ? new $util.LongBits(message.alloc_param.low >>> 0, message.alloc_param.high >>> 0).toNumber()
                : message.alloc_param;
      if (message.except_tid != null && message.hasOwnProperty("except_tid")) object.except_tid = message.except_tid;
      if (message.target_tid != null && message.hasOwnProperty("target_tid")) object.target_tid = message.target_tid;
      return object;
    };

    /**
     * Converts this AllocTableReq to JSON.
     * @function toJSON
     * @memberof roomalloc.AllocTableReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AllocTableReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return AllocTableReq;
  })();

  roomalloc.AllocTableResp = (function () {
    /**
     * Properties of an AllocTableResp.
     * @memberof roomalloc
     * @interface IAllocTableResp
     * @property {number|null} [result] AllocTableResp result
     */

    /**
     * Constructs a new AllocTableResp.
     * @memberof roomalloc
     * @classdesc Represents an AllocTableResp.
     * @implements IAllocTableResp
     * @constructor
     * @param {roomalloc.IAllocTableResp=} [properties] Properties to set
     */
    function AllocTableResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AllocTableResp result.
     * @member {number} result
     * @memberof roomalloc.AllocTableResp
     * @instance
     */
    AllocTableResp.prototype.result = 0;

    /**
     * Creates a new AllocTableResp instance using the specified properties.
     * @function create
     * @memberof roomalloc.AllocTableResp
     * @static
     * @param {roomalloc.IAllocTableResp=} [properties] Properties to set
     * @returns {roomalloc.AllocTableResp} AllocTableResp instance
     */
    AllocTableResp.create = function create(properties) {
      return new AllocTableResp(properties);
    };

    /**
     * Encodes the specified AllocTableResp message. Does not implicitly {@link roomalloc.AllocTableResp.verify|verify} messages.
     * @function encode
     * @memberof roomalloc.AllocTableResp
     * @static
     * @param {roomalloc.IAllocTableResp} message AllocTableResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AllocTableResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified AllocTableResp message, length delimited. Does not implicitly {@link roomalloc.AllocTableResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof roomalloc.AllocTableResp
     * @static
     * @param {roomalloc.IAllocTableResp} message AllocTableResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AllocTableResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AllocTableResp message from the specified reader or buffer.
     * @function decode
     * @memberof roomalloc.AllocTableResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {roomalloc.AllocTableResp} AllocTableResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AllocTableResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.roomalloc.AllocTableResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.result = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AllocTableResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof roomalloc.AllocTableResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {roomalloc.AllocTableResp} AllocTableResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AllocTableResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AllocTableResp message.
     * @function verify
     * @memberof roomalloc.AllocTableResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AllocTableResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates an AllocTableResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof roomalloc.AllocTableResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {roomalloc.AllocTableResp} AllocTableResp
     */
    AllocTableResp.fromObject = function fromObject(object) {
      if (object instanceof $root.roomalloc.AllocTableResp) return object;
      var message = new $root.roomalloc.AllocTableResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from an AllocTableResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof roomalloc.AllocTableResp
     * @static
     * @param {roomalloc.AllocTableResp} message AllocTableResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AllocTableResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this AllocTableResp to JSON.
     * @function toJSON
     * @memberof roomalloc.AllocTableResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AllocTableResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return AllocTableResp;
  })();

  roomalloc.GameLevelConfigReq = (function () {
    /**
     * Properties of a GameLevelConfigReq.
     * @memberof roomalloc
     * @interface IGameLevelConfigReq
     * @property {number|null} [game_id] GameLevelConfigReq game_id
     */

    /**
     * Constructs a new GameLevelConfigReq.
     * @memberof roomalloc
     * @classdesc Represents a GameLevelConfigReq.
     * @implements IGameLevelConfigReq
     * @constructor
     * @param {roomalloc.IGameLevelConfigReq=} [properties] Properties to set
     */
    function GameLevelConfigReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameLevelConfigReq game_id.
     * @member {number} game_id
     * @memberof roomalloc.GameLevelConfigReq
     * @instance
     */
    GameLevelConfigReq.prototype.game_id = 0;

    /**
     * Creates a new GameLevelConfigReq instance using the specified properties.
     * @function create
     * @memberof roomalloc.GameLevelConfigReq
     * @static
     * @param {roomalloc.IGameLevelConfigReq=} [properties] Properties to set
     * @returns {roomalloc.GameLevelConfigReq} GameLevelConfigReq instance
     */
    GameLevelConfigReq.create = function create(properties) {
      return new GameLevelConfigReq(properties);
    };

    /**
     * Encodes the specified GameLevelConfigReq message. Does not implicitly {@link roomalloc.GameLevelConfigReq.verify|verify} messages.
     * @function encode
     * @memberof roomalloc.GameLevelConfigReq
     * @static
     * @param {roomalloc.IGameLevelConfigReq} message GameLevelConfigReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameLevelConfigReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_id != null && Object.hasOwnProperty.call(message, "game_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.game_id);
      return writer;
    };

    /**
     * Encodes the specified GameLevelConfigReq message, length delimited. Does not implicitly {@link roomalloc.GameLevelConfigReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof roomalloc.GameLevelConfigReq
     * @static
     * @param {roomalloc.IGameLevelConfigReq} message GameLevelConfigReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameLevelConfigReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameLevelConfigReq message from the specified reader or buffer.
     * @function decode
     * @memberof roomalloc.GameLevelConfigReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {roomalloc.GameLevelConfigReq} GameLevelConfigReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameLevelConfigReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.roomalloc.GameLevelConfigReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.game_id = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameLevelConfigReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof roomalloc.GameLevelConfigReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {roomalloc.GameLevelConfigReq} GameLevelConfigReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameLevelConfigReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameLevelConfigReq message.
     * @function verify
     * @memberof roomalloc.GameLevelConfigReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameLevelConfigReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_id != null && message.hasOwnProperty("game_id"))
        if (!$util.isInteger(message.game_id)) return "game_id: integer expected";
      return null;
    };

    /**
     * Creates a GameLevelConfigReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof roomalloc.GameLevelConfigReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {roomalloc.GameLevelConfigReq} GameLevelConfigReq
     */
    GameLevelConfigReq.fromObject = function fromObject(object) {
      if (object instanceof $root.roomalloc.GameLevelConfigReq) return object;
      var message = new $root.roomalloc.GameLevelConfigReq();
      if (object.game_id != null) message.game_id = object.game_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a GameLevelConfigReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof roomalloc.GameLevelConfigReq
     * @static
     * @param {roomalloc.GameLevelConfigReq} message GameLevelConfigReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameLevelConfigReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.game_id = 0;
      if (message.game_id != null && message.hasOwnProperty("game_id")) object.game_id = message.game_id;
      return object;
    };

    /**
     * Converts this GameLevelConfigReq to JSON.
     * @function toJSON
     * @memberof roomalloc.GameLevelConfigReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameLevelConfigReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return GameLevelConfigReq;
  })();

  roomalloc.GameLevelConfigResp = (function () {
    /**
     * Properties of a GameLevelConfigResp.
     * @memberof roomalloc
     * @interface IGameLevelConfigResp
     * @property {number|null} [game_id] GameLevelConfigResp game_id
     * @property {number|null} [auto_alloc] GameLevelConfigResp auto_alloc
     * @property {Array.<roomalloc.GameLevelConfigResp.ILevelConfig>|null} [level_config] GameLevelConfigResp level_config
     */

    /**
     * Constructs a new GameLevelConfigResp.
     * @memberof roomalloc
     * @classdesc Represents a GameLevelConfigResp.
     * @implements IGameLevelConfigResp
     * @constructor
     * @param {roomalloc.IGameLevelConfigResp=} [properties] Properties to set
     */
    function GameLevelConfigResp(properties) {
      this.level_config = [];
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GameLevelConfigResp game_id.
     * @member {number} game_id
     * @memberof roomalloc.GameLevelConfigResp
     * @instance
     */
    GameLevelConfigResp.prototype.game_id = 0;

    /**
     * GameLevelConfigResp auto_alloc.
     * @member {number} auto_alloc
     * @memberof roomalloc.GameLevelConfigResp
     * @instance
     */
    GameLevelConfigResp.prototype.auto_alloc = 0;

    /**
     * GameLevelConfigResp level_config.
     * @member {Array.<roomalloc.GameLevelConfigResp.ILevelConfig>} level_config
     * @memberof roomalloc.GameLevelConfigResp
     * @instance
     */
    GameLevelConfigResp.prototype.level_config = $util.emptyArray;

    /**
     * Creates a new GameLevelConfigResp instance using the specified properties.
     * @function create
     * @memberof roomalloc.GameLevelConfigResp
     * @static
     * @param {roomalloc.IGameLevelConfigResp=} [properties] Properties to set
     * @returns {roomalloc.GameLevelConfigResp} GameLevelConfigResp instance
     */
    GameLevelConfigResp.create = function create(properties) {
      return new GameLevelConfigResp(properties);
    };

    /**
     * Encodes the specified GameLevelConfigResp message. Does not implicitly {@link roomalloc.GameLevelConfigResp.verify|verify} messages.
     * @function encode
     * @memberof roomalloc.GameLevelConfigResp
     * @static
     * @param {roomalloc.IGameLevelConfigResp} message GameLevelConfigResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameLevelConfigResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.game_id != null && Object.hasOwnProperty.call(message, "game_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.game_id);
      if (message.auto_alloc != null && Object.hasOwnProperty.call(message, "auto_alloc"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.auto_alloc);
      if (message.level_config != null && message.level_config.length)
        for (var i = 0; i < message.level_config.length; ++i)
          $root.roomalloc.GameLevelConfigResp.LevelConfig.encode(
            message.level_config[i],
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork(),
          ).ldelim();
      return writer;
    };

    /**
     * Encodes the specified GameLevelConfigResp message, length delimited. Does not implicitly {@link roomalloc.GameLevelConfigResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof roomalloc.GameLevelConfigResp
     * @static
     * @param {roomalloc.IGameLevelConfigResp} message GameLevelConfigResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GameLevelConfigResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GameLevelConfigResp message from the specified reader or buffer.
     * @function decode
     * @memberof roomalloc.GameLevelConfigResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {roomalloc.GameLevelConfigResp} GameLevelConfigResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameLevelConfigResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.roomalloc.GameLevelConfigResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.game_id = reader.int32();
            break;
          case 2:
            message.auto_alloc = reader.int32();
            break;
          case 3:
            if (!(message.level_config && message.level_config.length)) message.level_config = [];
            message.level_config.push($root.roomalloc.GameLevelConfigResp.LevelConfig.decode(reader, reader.uint32()));
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GameLevelConfigResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof roomalloc.GameLevelConfigResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {roomalloc.GameLevelConfigResp} GameLevelConfigResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GameLevelConfigResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GameLevelConfigResp message.
     * @function verify
     * @memberof roomalloc.GameLevelConfigResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GameLevelConfigResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.game_id != null && message.hasOwnProperty("game_id"))
        if (!$util.isInteger(message.game_id)) return "game_id: integer expected";
      if (message.auto_alloc != null && message.hasOwnProperty("auto_alloc"))
        if (!$util.isInteger(message.auto_alloc)) return "auto_alloc: integer expected";
      if (message.level_config != null && message.hasOwnProperty("level_config")) {
        if (!Array.isArray(message.level_config)) return "level_config: array expected";
        for (var i = 0; i < message.level_config.length; ++i) {
          var error = $root.roomalloc.GameLevelConfigResp.LevelConfig.verify(message.level_config[i]);
          if (error) return "level_config." + error;
        }
      }
      return null;
    };

    /**
     * Creates a GameLevelConfigResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof roomalloc.GameLevelConfigResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {roomalloc.GameLevelConfigResp} GameLevelConfigResp
     */
    GameLevelConfigResp.fromObject = function fromObject(object) {
      if (object instanceof $root.roomalloc.GameLevelConfigResp) return object;
      var message = new $root.roomalloc.GameLevelConfigResp();
      if (object.game_id != null) message.game_id = object.game_id | 0;
      if (object.auto_alloc != null) message.auto_alloc = object.auto_alloc | 0;
      if (object.level_config) {
        if (!Array.isArray(object.level_config))
          throw TypeError(".roomalloc.GameLevelConfigResp.level_config: array expected");
        message.level_config = [];
        for (var i = 0; i < object.level_config.length; ++i) {
          if (typeof object.level_config[i] !== "object")
            throw TypeError(".roomalloc.GameLevelConfigResp.level_config: object expected");
          message.level_config[i] = $root.roomalloc.GameLevelConfigResp.LevelConfig.fromObject(object.level_config[i]);
        }
      }
      return message;
    };

    /**
     * Creates a plain object from a GameLevelConfigResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof roomalloc.GameLevelConfigResp
     * @static
     * @param {roomalloc.GameLevelConfigResp} message GameLevelConfigResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GameLevelConfigResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.arrays || options.defaults) object.level_config = [];
      if (options.defaults) {
        object.game_id = 0;
        object.auto_alloc = 0;
      }
      if (message.game_id != null && message.hasOwnProperty("game_id")) object.game_id = message.game_id;
      if (message.auto_alloc != null && message.hasOwnProperty("auto_alloc")) object.auto_alloc = message.auto_alloc;
      if (message.level_config && message.level_config.length) {
        object.level_config = [];
        for (var j = 0; j < message.level_config.length; ++j)
          object.level_config[j] = $root.roomalloc.GameLevelConfigResp.LevelConfig.toObject(
            message.level_config[j],
            options,
          );
      }
      return object;
    };

    /**
     * Converts this GameLevelConfigResp to JSON.
     * @function toJSON
     * @memberof roomalloc.GameLevelConfigResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GameLevelConfigResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    GameLevelConfigResp.LevelConfig = (function () {
      /**
       * Properties of a LevelConfig.
       * @memberof roomalloc.GameLevelConfigResp
       * @interface ILevelConfig
       * @property {number|null} [level] LevelConfig level
       * @property {number|Long|null} [base_ante] LevelConfig base_ante
       * @property {number|Long|null} [entry_min] LevelConfig entry_min
       * @property {number|Long|null} [entry_max] LevelConfig entry_max
       * @property {string|null} [title] LevelConfig title
       * @property {number|Long|null} [recommend_min] LevelConfig recommend_min
       * @property {number|Long|null} [recommend_max] LevelConfig recommend_max
       * @property {number|Long|null} [bet_min] LevelConfig bet_min
       * @property {number|Long|null} [bet_max] LevelConfig bet_max
       * @property {number|Long|null} [pay_max] LevelConfig pay_max
       */

      /**
       * Constructs a new LevelConfig.
       * @memberof roomalloc.GameLevelConfigResp
       * @classdesc Represents a LevelConfig.
       * @implements ILevelConfig
       * @constructor
       * @param {roomalloc.GameLevelConfigResp.ILevelConfig=} [properties] Properties to set
       */
      function LevelConfig(properties) {
        if (properties)
          for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
      }

      /**
       * LevelConfig level.
       * @member {number} level
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       */
      LevelConfig.prototype.level = 0;

      /**
       * LevelConfig base_ante.
       * @member {number|Long} base_ante
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       */
      LevelConfig.prototype.base_ante = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * LevelConfig entry_min.
       * @member {number|Long} entry_min
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       */
      LevelConfig.prototype.entry_min = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * LevelConfig entry_max.
       * @member {number|Long} entry_max
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       */
      LevelConfig.prototype.entry_max = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * LevelConfig title.
       * @member {string} title
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       */
      LevelConfig.prototype.title = "";

      /**
       * LevelConfig recommend_min.
       * @member {number|Long} recommend_min
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       */
      LevelConfig.prototype.recommend_min = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * LevelConfig recommend_max.
       * @member {number|Long} recommend_max
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       */
      LevelConfig.prototype.recommend_max = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * LevelConfig bet_min.
       * @member {number|Long} bet_min
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       */
      LevelConfig.prototype.bet_min = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * LevelConfig bet_max.
       * @member {number|Long} bet_max
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       */
      LevelConfig.prototype.bet_max = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * LevelConfig pay_max.
       * @member {number|Long} pay_max
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       */
      LevelConfig.prototype.pay_max = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

      /**
       * Creates a new LevelConfig instance using the specified properties.
       * @function create
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @static
       * @param {roomalloc.GameLevelConfigResp.ILevelConfig=} [properties] Properties to set
       * @returns {roomalloc.GameLevelConfigResp.LevelConfig} LevelConfig instance
       */
      LevelConfig.create = function create(properties) {
        return new LevelConfig(properties);
      };

      /**
       * Encodes the specified LevelConfig message. Does not implicitly {@link roomalloc.GameLevelConfigResp.LevelConfig.verify|verify} messages.
       * @function encode
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @static
       * @param {roomalloc.GameLevelConfigResp.ILevelConfig} message LevelConfig message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      LevelConfig.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.level != null && Object.hasOwnProperty.call(message, "level"))
          writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.level);
        if (message.base_ante != null && Object.hasOwnProperty.call(message, "base_ante"))
          writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.base_ante);
        if (message.entry_min != null && Object.hasOwnProperty.call(message, "entry_min"))
          writer.uint32(/* id 3, wireType 0 =*/ 24).int64(message.entry_min);
        if (message.entry_max != null && Object.hasOwnProperty.call(message, "entry_max"))
          writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.entry_max);
        if (message.title != null && Object.hasOwnProperty.call(message, "title"))
          writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.title);
        if (message.recommend_min != null && Object.hasOwnProperty.call(message, "recommend_min"))
          writer.uint32(/* id 6, wireType 0 =*/ 48).int64(message.recommend_min);
        if (message.recommend_max != null && Object.hasOwnProperty.call(message, "recommend_max"))
          writer.uint32(/* id 7, wireType 0 =*/ 56).int64(message.recommend_max);
        if (message.bet_min != null && Object.hasOwnProperty.call(message, "bet_min"))
          writer.uint32(/* id 8, wireType 0 =*/ 64).int64(message.bet_min);
        if (message.bet_max != null && Object.hasOwnProperty.call(message, "bet_max"))
          writer.uint32(/* id 9, wireType 0 =*/ 72).int64(message.bet_max);
        if (message.pay_max != null && Object.hasOwnProperty.call(message, "pay_max"))
          writer.uint32(/* id 10, wireType 0 =*/ 80).int64(message.pay_max);
        return writer;
      };

      /**
       * Encodes the specified LevelConfig message, length delimited. Does not implicitly {@link roomalloc.GameLevelConfigResp.LevelConfig.verify|verify} messages.
       * @function encodeDelimited
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @static
       * @param {roomalloc.GameLevelConfigResp.ILevelConfig} message LevelConfig message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      LevelConfig.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a LevelConfig message from the specified reader or buffer.
       * @function decode
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {roomalloc.GameLevelConfigResp.LevelConfig} LevelConfig
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      LevelConfig.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.roomalloc.GameLevelConfigResp.LevelConfig();
        while (reader.pos < end) {
          var tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.level = reader.int32();
              break;
            case 2:
              message.base_ante = reader.int64();
              break;
            case 3:
              message.entry_min = reader.int64();
              break;
            case 4:
              message.entry_max = reader.int64();
              break;
            case 5:
              message.title = reader.string();
              break;
            case 6:
              message.recommend_min = reader.int64();
              break;
            case 7:
              message.recommend_max = reader.int64();
              break;
            case 8:
              message.bet_min = reader.int64();
              break;
            case 9:
              message.bet_max = reader.int64();
              break;
            case 10:
              message.pay_max = reader.int64();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a LevelConfig message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {roomalloc.GameLevelConfigResp.LevelConfig} LevelConfig
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      LevelConfig.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a LevelConfig message.
       * @function verify
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      LevelConfig.verify = function verify(message) {
        if (typeof message !== "object" || message === null) return "object expected";
        if (message.level != null && message.hasOwnProperty("level"))
          if (!$util.isInteger(message.level)) return "level: integer expected";
        if (message.base_ante != null && message.hasOwnProperty("base_ante"))
          if (
            !$util.isInteger(message.base_ante) &&
            !(message.base_ante && $util.isInteger(message.base_ante.low) && $util.isInteger(message.base_ante.high))
          )
            return "base_ante: integer|Long expected";
        if (message.entry_min != null && message.hasOwnProperty("entry_min"))
          if (
            !$util.isInteger(message.entry_min) &&
            !(message.entry_min && $util.isInteger(message.entry_min.low) && $util.isInteger(message.entry_min.high))
          )
            return "entry_min: integer|Long expected";
        if (message.entry_max != null && message.hasOwnProperty("entry_max"))
          if (
            !$util.isInteger(message.entry_max) &&
            !(message.entry_max && $util.isInteger(message.entry_max.low) && $util.isInteger(message.entry_max.high))
          )
            return "entry_max: integer|Long expected";
        if (message.title != null && message.hasOwnProperty("title"))
          if (!$util.isString(message.title)) return "title: string expected";
        if (message.recommend_min != null && message.hasOwnProperty("recommend_min"))
          if (
            !$util.isInteger(message.recommend_min) &&
            !(
              message.recommend_min &&
              $util.isInteger(message.recommend_min.low) &&
              $util.isInteger(message.recommend_min.high)
            )
          )
            return "recommend_min: integer|Long expected";
        if (message.recommend_max != null && message.hasOwnProperty("recommend_max"))
          if (
            !$util.isInteger(message.recommend_max) &&
            !(
              message.recommend_max &&
              $util.isInteger(message.recommend_max.low) &&
              $util.isInteger(message.recommend_max.high)
            )
          )
            return "recommend_max: integer|Long expected";
        if (message.bet_min != null && message.hasOwnProperty("bet_min"))
          if (
            !$util.isInteger(message.bet_min) &&
            !(message.bet_min && $util.isInteger(message.bet_min.low) && $util.isInteger(message.bet_min.high))
          )
            return "bet_min: integer|Long expected";
        if (message.bet_max != null && message.hasOwnProperty("bet_max"))
          if (
            !$util.isInteger(message.bet_max) &&
            !(message.bet_max && $util.isInteger(message.bet_max.low) && $util.isInteger(message.bet_max.high))
          )
            return "bet_max: integer|Long expected";
        if (message.pay_max != null && message.hasOwnProperty("pay_max"))
          if (
            !$util.isInteger(message.pay_max) &&
            !(message.pay_max && $util.isInteger(message.pay_max.low) && $util.isInteger(message.pay_max.high))
          )
            return "pay_max: integer|Long expected";
        return null;
      };

      /**
       * Creates a LevelConfig message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {roomalloc.GameLevelConfigResp.LevelConfig} LevelConfig
       */
      LevelConfig.fromObject = function fromObject(object) {
        if (object instanceof $root.roomalloc.GameLevelConfigResp.LevelConfig) return object;
        var message = new $root.roomalloc.GameLevelConfigResp.LevelConfig();
        if (object.level != null) message.level = object.level | 0;
        if (object.base_ante != null)
          if ($util.Long) (message.base_ante = $util.Long.fromValue(object.base_ante)).unsigned = false;
          else if (typeof object.base_ante === "string") message.base_ante = parseInt(object.base_ante, 10);
          else if (typeof object.base_ante === "number") message.base_ante = object.base_ante;
          else if (typeof object.base_ante === "object")
            message.base_ante = new $util.LongBits(object.base_ante.low >>> 0, object.base_ante.high >>> 0).toNumber();
        if (object.entry_min != null)
          if ($util.Long) (message.entry_min = $util.Long.fromValue(object.entry_min)).unsigned = false;
          else if (typeof object.entry_min === "string") message.entry_min = parseInt(object.entry_min, 10);
          else if (typeof object.entry_min === "number") message.entry_min = object.entry_min;
          else if (typeof object.entry_min === "object")
            message.entry_min = new $util.LongBits(object.entry_min.low >>> 0, object.entry_min.high >>> 0).toNumber();
        if (object.entry_max != null)
          if ($util.Long) (message.entry_max = $util.Long.fromValue(object.entry_max)).unsigned = false;
          else if (typeof object.entry_max === "string") message.entry_max = parseInt(object.entry_max, 10);
          else if (typeof object.entry_max === "number") message.entry_max = object.entry_max;
          else if (typeof object.entry_max === "object")
            message.entry_max = new $util.LongBits(object.entry_max.low >>> 0, object.entry_max.high >>> 0).toNumber();
        if (object.title != null) message.title = String(object.title);
        if (object.recommend_min != null)
          if ($util.Long) (message.recommend_min = $util.Long.fromValue(object.recommend_min)).unsigned = false;
          else if (typeof object.recommend_min === "string") message.recommend_min = parseInt(object.recommend_min, 10);
          else if (typeof object.recommend_min === "number") message.recommend_min = object.recommend_min;
          else if (typeof object.recommend_min === "object")
            message.recommend_min = new $util.LongBits(
              object.recommend_min.low >>> 0,
              object.recommend_min.high >>> 0,
            ).toNumber();
        if (object.recommend_max != null)
          if ($util.Long) (message.recommend_max = $util.Long.fromValue(object.recommend_max)).unsigned = false;
          else if (typeof object.recommend_max === "string") message.recommend_max = parseInt(object.recommend_max, 10);
          else if (typeof object.recommend_max === "number") message.recommend_max = object.recommend_max;
          else if (typeof object.recommend_max === "object")
            message.recommend_max = new $util.LongBits(
              object.recommend_max.low >>> 0,
              object.recommend_max.high >>> 0,
            ).toNumber();
        if (object.bet_min != null)
          if ($util.Long) (message.bet_min = $util.Long.fromValue(object.bet_min)).unsigned = false;
          else if (typeof object.bet_min === "string") message.bet_min = parseInt(object.bet_min, 10);
          else if (typeof object.bet_min === "number") message.bet_min = object.bet_min;
          else if (typeof object.bet_min === "object")
            message.bet_min = new $util.LongBits(object.bet_min.low >>> 0, object.bet_min.high >>> 0).toNumber();
        if (object.bet_max != null)
          if ($util.Long) (message.bet_max = $util.Long.fromValue(object.bet_max)).unsigned = false;
          else if (typeof object.bet_max === "string") message.bet_max = parseInt(object.bet_max, 10);
          else if (typeof object.bet_max === "number") message.bet_max = object.bet_max;
          else if (typeof object.bet_max === "object")
            message.bet_max = new $util.LongBits(object.bet_max.low >>> 0, object.bet_max.high >>> 0).toNumber();
        if (object.pay_max != null)
          if ($util.Long) (message.pay_max = $util.Long.fromValue(object.pay_max)).unsigned = false;
          else if (typeof object.pay_max === "string") message.pay_max = parseInt(object.pay_max, 10);
          else if (typeof object.pay_max === "number") message.pay_max = object.pay_max;
          else if (typeof object.pay_max === "object")
            message.pay_max = new $util.LongBits(object.pay_max.low >>> 0, object.pay_max.high >>> 0).toNumber();
        return message;
      };

      /**
       * Creates a plain object from a LevelConfig message. Also converts values to other types if specified.
       * @function toObject
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @static
       * @param {roomalloc.GameLevelConfigResp.LevelConfig} message LevelConfig
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      LevelConfig.toObject = function toObject(message, options) {
        if (!options) options = {};
        var object = {};
        if (options.defaults) {
          object.level = 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.base_ante =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.base_ante = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.entry_min =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.entry_min = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.entry_max =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.entry_max = options.longs === String ? "0" : 0;
          object.title = "";
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.recommend_min =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.recommend_min = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.recommend_max =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.recommend_max = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.bet_min =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.bet_min = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.bet_max =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.bet_max = options.longs === String ? "0" : 0;
          if ($util.Long) {
            var long = new $util.Long(0, 0, false);
            object.pay_max =
              options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
          } else object.pay_max = options.longs === String ? "0" : 0;
        }
        if (message.level != null && message.hasOwnProperty("level")) object.level = message.level;
        if (message.base_ante != null && message.hasOwnProperty("base_ante"))
          if (typeof message.base_ante === "number")
            object.base_ante = options.longs === String ? String(message.base_ante) : message.base_ante;
          else
            object.base_ante =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.base_ante)
                : options.longs === Number
                  ? new $util.LongBits(message.base_ante.low >>> 0, message.base_ante.high >>> 0).toNumber()
                  : message.base_ante;
        if (message.entry_min != null && message.hasOwnProperty("entry_min"))
          if (typeof message.entry_min === "number")
            object.entry_min = options.longs === String ? String(message.entry_min) : message.entry_min;
          else
            object.entry_min =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.entry_min)
                : options.longs === Number
                  ? new $util.LongBits(message.entry_min.low >>> 0, message.entry_min.high >>> 0).toNumber()
                  : message.entry_min;
        if (message.entry_max != null && message.hasOwnProperty("entry_max"))
          if (typeof message.entry_max === "number")
            object.entry_max = options.longs === String ? String(message.entry_max) : message.entry_max;
          else
            object.entry_max =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.entry_max)
                : options.longs === Number
                  ? new $util.LongBits(message.entry_max.low >>> 0, message.entry_max.high >>> 0).toNumber()
                  : message.entry_max;
        if (message.title != null && message.hasOwnProperty("title")) object.title = message.title;
        if (message.recommend_min != null && message.hasOwnProperty("recommend_min"))
          if (typeof message.recommend_min === "number")
            object.recommend_min = options.longs === String ? String(message.recommend_min) : message.recommend_min;
          else
            object.recommend_min =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.recommend_min)
                : options.longs === Number
                  ? new $util.LongBits(message.recommend_min.low >>> 0, message.recommend_min.high >>> 0).toNumber()
                  : message.recommend_min;
        if (message.recommend_max != null && message.hasOwnProperty("recommend_max"))
          if (typeof message.recommend_max === "number")
            object.recommend_max = options.longs === String ? String(message.recommend_max) : message.recommend_max;
          else
            object.recommend_max =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.recommend_max)
                : options.longs === Number
                  ? new $util.LongBits(message.recommend_max.low >>> 0, message.recommend_max.high >>> 0).toNumber()
                  : message.recommend_max;
        if (message.bet_min != null && message.hasOwnProperty("bet_min"))
          if (typeof message.bet_min === "number")
            object.bet_min = options.longs === String ? String(message.bet_min) : message.bet_min;
          else
            object.bet_min =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.bet_min)
                : options.longs === Number
                  ? new $util.LongBits(message.bet_min.low >>> 0, message.bet_min.high >>> 0).toNumber()
                  : message.bet_min;
        if (message.bet_max != null && message.hasOwnProperty("bet_max"))
          if (typeof message.bet_max === "number")
            object.bet_max = options.longs === String ? String(message.bet_max) : message.bet_max;
          else
            object.bet_max =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.bet_max)
                : options.longs === Number
                  ? new $util.LongBits(message.bet_max.low >>> 0, message.bet_max.high >>> 0).toNumber()
                  : message.bet_max;
        if (message.pay_max != null && message.hasOwnProperty("pay_max"))
          if (typeof message.pay_max === "number")
            object.pay_max = options.longs === String ? String(message.pay_max) : message.pay_max;
          else
            object.pay_max =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.pay_max)
                : options.longs === Number
                  ? new $util.LongBits(message.pay_max.low >>> 0, message.pay_max.high >>> 0).toNumber()
                  : message.pay_max;
        return object;
      };

      /**
       * Converts this LevelConfig to JSON.
       * @function toJSON
       * @memberof roomalloc.GameLevelConfigResp.LevelConfig
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      LevelConfig.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return LevelConfig;
    })();

    return GameLevelConfigResp;
  })();

  return roomalloc;
})();

$root.gamebase = (function () {
  /**
   * Namespace gamebase.
   * @exports gamebase
   * @namespace
   */
  var gamebase = {};

  /**
   * SERVER_INNER_MSG_TYPE enum.
   * @name gamebase.SERVER_INNER_MSG_TYPE
   * @enum {number}
   * @property {number} SERVER_TYPE_NONE=0 SERVER_TYPE_NONE value
   * @property {number} SERVER_TYPE_GAME_PLAYER=1 SERVER_TYPE_GAME_PLAYER value
   * @property {number} SERVER_TYPE_ROOMSERVER=5 SERVER_TYPE_ROOMSERVER value
   * @property {number} SERVER_TYPE_GAME_MANAGE=20 SERVER_TYPE_GAME_MANAGE value
   * @property {number} SERVER_TYPE_ROOMALLOC=47 SERVER_TYPE_ROOMALLOC value
   */
  gamebase.SERVER_INNER_MSG_TYPE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "SERVER_TYPE_NONE")] = 0;
    values[(valuesById[1] = "SERVER_TYPE_GAME_PLAYER")] = 1;
    values[(valuesById[5] = "SERVER_TYPE_ROOMSERVER")] = 5;
    values[(valuesById[20] = "SERVER_TYPE_GAME_MANAGE")] = 20;
    values[(valuesById[47] = "SERVER_TYPE_ROOMALLOC")] = 47;
    return values;
  })();

  /**
   * CCGAME_ID enum.
   * @name gamebase.CCGAME_ID
   * @enum {number}
   * @property {number} CCGAME_ID_NULL=0 CCGAME_ID_NULL value
   * @property {number} CCGAME_AVIATOR=101 CCGAME_AVIATOR value
   * @property {number} CCGAME_ANDAR=102 CCGAME_ANDAR value
   */
  gamebase.CCGAME_ID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "CCGAME_ID_NULL")] = 0;
    values[(valuesById[101] = "CCGAME_AVIATOR")] = 101;
    values[(valuesById[102] = "CCGAME_ANDAR")] = 102;
    return values;
  })();

  /**
   * CCGAME_ERR_CODE enum.
   * @name gamebase.CCGAME_ERR_CODE
   * @enum {number}
   * @property {number} RET_SUCCESS=0 RET_SUCCESS value
   * @property {number} RET_FAIL=1 RET_FAIL value
   * @property {number} RET_TABLE_NOT_EXIST=2 RET_TABLE_NOT_EXIST value
   * @property {number} RET_LOGIN_REPEAT=3 RET_LOGIN_REPEAT value
   * @property {number} RET_SERVER_KICKOUT=4 RET_SERVER_KICKOUT value
   * @property {number} RET_ABNORMAL=5 RET_ABNORMAL value
   * @property {number} RET_INPUT_PARAM=6 RET_INPUT_PARAM value
   * @property {number} RET_UID_NOT_FOUND=7 RET_UID_NOT_FOUND value
   * @property {number} RET_RANK_TYPE=8 RET_RANK_TYPE value
   * @property {number} RET_STATUS_ERROR=9 RET_STATUS_ERROR value
   * @property {number} RET_BET_REPEAT=10 RET_BET_REPEAT value
   * @property {number} RET_LESS_MONEY=11 RET_LESS_MONEY value
   * @property {number} RET_MORE_MONEY=12 RET_MORE_MONEY value
   */
  gamebase.CCGAME_ERR_CODE = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "RET_SUCCESS")] = 0;
    values[(valuesById[1] = "RET_FAIL")] = 1;
    values[(valuesById[2] = "RET_TABLE_NOT_EXIST")] = 2;
    values[(valuesById[3] = "RET_LOGIN_REPEAT")] = 3;
    values[(valuesById[4] = "RET_SERVER_KICKOUT")] = 4;
    values[(valuesById[5] = "RET_ABNORMAL")] = 5;
    values[(valuesById[6] = "RET_INPUT_PARAM")] = 6;
    values[(valuesById[7] = "RET_UID_NOT_FOUND")] = 7;
    values[(valuesById[8] = "RET_RANK_TYPE")] = 8;
    values[(valuesById[9] = "RET_STATUS_ERROR")] = 9;
    values[(valuesById[10] = "RET_BET_REPEAT")] = 10;
    values[(valuesById[11] = "RET_LESS_MONEY")] = 11;
    values[(valuesById[12] = "RET_MORE_MONEY")] = 12;
    return values;
  })();

  /**
   * CCGAME_MSGID enum.
   * @name gamebase.CCGAME_MSGID
   * @enum {number}
   * @property {number} CC_GAME_MSGID_NULL=0 CC_GAME_MSGID_NULL value
   * @property {number} CC_GAME_ALLOC_TABLE_PUSH=10010 CC_GAME_ALLOC_TABLE_PUSH value
   * @property {number} CC_GAME_LEAVE_REQ=10011 CC_GAME_LEAVE_REQ value
   * @property {number} CC_GAME_LEAVE_RESP=10012 CC_GAME_LEAVE_RESP value
   * @property {number} CC_GAME_JOIN_TABLE_REQ=10013 CC_GAME_JOIN_TABLE_REQ value
   * @property {number} CC_GAME_JOIN_TABLE_RESP=10014 CC_GAME_JOIN_TABLE_RESP value
   * @property {number} CC_GAME_STAND_UP_PUSH=10015 CC_GAME_STAND_UP_PUSH value
   * @property {number} CC_GAME_KICKOUT_PUSH=10016 CC_GAME_KICKOUT_PUSH value
   * @property {number} CC_GAME_SITDOWN_REQ=10017 CC_GAME_SITDOWN_REQ value
   * @property {number} CC_GAME_SITDOWN_RESP=10018 CC_GAME_SITDOWN_RESP value
   * @property {number} CC_GAME_SITDOWN_PUSH=10019 CC_GAME_SITDOWN_PUSH value
   * @property {number} CC_GAME_OFFLINE_REQ=10020 CC_GAME_OFFLINE_REQ value
   * @property {number} CC_GAME_OFFLINE_RESP=10021 CC_GAME_OFFLINE_RESP value
   * @property {number} CC_GAME_GET_RULE_REQ=10022 CC_GAME_GET_RULE_REQ value
   * @property {number} CC_GAME_GET_RULE_RESP=10023 CC_GAME_GET_RULE_RESP value
   */
  gamebase.CCGAME_MSGID = (function () {
    var valuesById = {},
      values = Object.create(valuesById);
    values[(valuesById[0] = "CC_GAME_MSGID_NULL")] = 0;
    values[(valuesById[10010] = "CC_GAME_ALLOC_TABLE_PUSH")] = 10010;
    values[(valuesById[10011] = "CC_GAME_LEAVE_REQ")] = 10011;
    values[(valuesById[10012] = "CC_GAME_LEAVE_RESP")] = 10012;
    values[(valuesById[10013] = "CC_GAME_JOIN_TABLE_REQ")] = 10013;
    values[(valuesById[10014] = "CC_GAME_JOIN_TABLE_RESP")] = 10014;
    values[(valuesById[10015] = "CC_GAME_STAND_UP_PUSH")] = 10015;
    values[(valuesById[10016] = "CC_GAME_KICKOUT_PUSH")] = 10016;
    values[(valuesById[10017] = "CC_GAME_SITDOWN_REQ")] = 10017;
    values[(valuesById[10018] = "CC_GAME_SITDOWN_RESP")] = 10018;
    values[(valuesById[10019] = "CC_GAME_SITDOWN_PUSH")] = 10019;
    values[(valuesById[10020] = "CC_GAME_OFFLINE_REQ")] = 10020;
    values[(valuesById[10021] = "CC_GAME_OFFLINE_RESP")] = 10021;
    values[(valuesById[10022] = "CC_GAME_GET_RULE_REQ")] = 10022;
    values[(valuesById[10023] = "CC_GAME_GET_RULE_RESP")] = 10023;
    return values;
  })();

  gamebase.Card = (function () {
    /**
     * Properties of a Card.
     * @memberof gamebase
     * @interface ICard
     * @property {number|null} [card] Card card
     */

    /**
     * Constructs a new Card.
     * @memberof gamebase
     * @classdesc Represents a Card.
     * @implements ICard
     * @constructor
     * @param {gamebase.ICard=} [properties] Properties to set
     */
    function Card(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Card card.
     * @member {number} card
     * @memberof gamebase.Card
     * @instance
     */
    Card.prototype.card = 0;

    /**
     * Creates a new Card instance using the specified properties.
     * @function create
     * @memberof gamebase.Card
     * @static
     * @param {gamebase.ICard=} [properties] Properties to set
     * @returns {gamebase.Card} Card instance
     */
    Card.create = function create(properties) {
      return new Card(properties);
    };

    /**
     * Encodes the specified Card message. Does not implicitly {@link gamebase.Card.verify|verify} messages.
     * @function encode
     * @memberof gamebase.Card
     * @static
     * @param {gamebase.ICard} message Card message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Card.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.card != null && Object.hasOwnProperty.call(message, "card"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.card);
      return writer;
    };

    /**
     * Encodes the specified Card message, length delimited. Does not implicitly {@link gamebase.Card.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.Card
     * @static
     * @param {gamebase.ICard} message Card message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Card.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a Card message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.Card
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.Card} Card
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Card.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.Card();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.card = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a Card message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.Card
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.Card} Card
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Card.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a Card message.
     * @function verify
     * @memberof gamebase.Card
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    Card.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.card != null && message.hasOwnProperty("card"))
        if (!$util.isInteger(message.card)) return "card: integer expected";
      return null;
    };

    /**
     * Creates a Card message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.Card
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.Card} Card
     */
    Card.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.Card) return object;
      var message = new $root.gamebase.Card();
      if (object.card != null) message.card = object.card | 0;
      return message;
    };

    /**
     * Creates a plain object from a Card message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.Card
     * @static
     * @param {gamebase.Card} message Card
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    Card.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.card = 0;
      if (message.card != null && message.hasOwnProperty("card")) object.card = message.card;
      return object;
    };

    /**
     * Converts this Card to JSON.
     * @function toJSON
     * @memberof gamebase.Card
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    Card.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return Card;
  })();

  gamebase.LeaveRoomReq = (function () {
    /**
     * Properties of a LeaveRoomReq.
     * @memberof gamebase
     * @interface ILeaveRoomReq
     */

    /**
     * Constructs a new LeaveRoomReq.
     * @memberof gamebase
     * @classdesc Represents a LeaveRoomReq.
     * @implements ILeaveRoomReq
     * @constructor
     * @param {gamebase.ILeaveRoomReq=} [properties] Properties to set
     */
    function LeaveRoomReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new LeaveRoomReq instance using the specified properties.
     * @function create
     * @memberof gamebase.LeaveRoomReq
     * @static
     * @param {gamebase.ILeaveRoomReq=} [properties] Properties to set
     * @returns {gamebase.LeaveRoomReq} LeaveRoomReq instance
     */
    LeaveRoomReq.create = function create(properties) {
      return new LeaveRoomReq(properties);
    };

    /**
     * Encodes the specified LeaveRoomReq message. Does not implicitly {@link gamebase.LeaveRoomReq.verify|verify} messages.
     * @function encode
     * @memberof gamebase.LeaveRoomReq
     * @static
     * @param {gamebase.ILeaveRoomReq} message LeaveRoomReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LeaveRoomReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      return writer;
    };

    /**
     * Encodes the specified LeaveRoomReq message, length delimited. Does not implicitly {@link gamebase.LeaveRoomReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.LeaveRoomReq
     * @static
     * @param {gamebase.ILeaveRoomReq} message LeaveRoomReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LeaveRoomReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LeaveRoomReq message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.LeaveRoomReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.LeaveRoomReq} LeaveRoomReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LeaveRoomReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.LeaveRoomReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LeaveRoomReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.LeaveRoomReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.LeaveRoomReq} LeaveRoomReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LeaveRoomReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LeaveRoomReq message.
     * @function verify
     * @memberof gamebase.LeaveRoomReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LeaveRoomReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      return null;
    };

    /**
     * Creates a LeaveRoomReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.LeaveRoomReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.LeaveRoomReq} LeaveRoomReq
     */
    LeaveRoomReq.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.LeaveRoomReq) return object;
      return new $root.gamebase.LeaveRoomReq();
    };

    /**
     * Creates a plain object from a LeaveRoomReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.LeaveRoomReq
     * @static
     * @param {gamebase.LeaveRoomReq} message LeaveRoomReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LeaveRoomReq.toObject = function toObject() {
      return {};
    };

    /**
     * Converts this LeaveRoomReq to JSON.
     * @function toJSON
     * @memberof gamebase.LeaveRoomReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LeaveRoomReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return LeaveRoomReq;
  })();

  gamebase.LeaveRoomResp = (function () {
    /**
     * Properties of a LeaveRoomResp.
     * @memberof gamebase
     * @interface ILeaveRoomResp
     * @property {number|null} [result] LeaveRoomResp result
     * @property {number|Long|null} [balance] LeaveRoomResp balance
     */

    /**
     * Constructs a new LeaveRoomResp.
     * @memberof gamebase
     * @classdesc Represents a LeaveRoomResp.
     * @implements ILeaveRoomResp
     * @constructor
     * @param {gamebase.ILeaveRoomResp=} [properties] Properties to set
     */
    function LeaveRoomResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * LeaveRoomResp result.
     * @member {number} result
     * @memberof gamebase.LeaveRoomResp
     * @instance
     */
    LeaveRoomResp.prototype.result = 0;

    /**
     * LeaveRoomResp balance.
     * @member {number|Long} balance
     * @memberof gamebase.LeaveRoomResp
     * @instance
     */
    LeaveRoomResp.prototype.balance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * Creates a new LeaveRoomResp instance using the specified properties.
     * @function create
     * @memberof gamebase.LeaveRoomResp
     * @static
     * @param {gamebase.ILeaveRoomResp=} [properties] Properties to set
     * @returns {gamebase.LeaveRoomResp} LeaveRoomResp instance
     */
    LeaveRoomResp.create = function create(properties) {
      return new LeaveRoomResp(properties);
    };

    /**
     * Encodes the specified LeaveRoomResp message. Does not implicitly {@link gamebase.LeaveRoomResp.verify|verify} messages.
     * @function encode
     * @memberof gamebase.LeaveRoomResp
     * @static
     * @param {gamebase.ILeaveRoomResp} message LeaveRoomResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LeaveRoomResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int64(message.balance);
      return writer;
    };

    /**
     * Encodes the specified LeaveRoomResp message, length delimited. Does not implicitly {@link gamebase.LeaveRoomResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.LeaveRoomResp
     * @static
     * @param {gamebase.ILeaveRoomResp} message LeaveRoomResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LeaveRoomResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a LeaveRoomResp message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.LeaveRoomResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.LeaveRoomResp} LeaveRoomResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LeaveRoomResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.LeaveRoomResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.result = reader.int32();
            break;
          case 2:
            message.balance = reader.int64();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a LeaveRoomResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.LeaveRoomResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.LeaveRoomResp} LeaveRoomResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LeaveRoomResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a LeaveRoomResp message.
     * @function verify
     * @memberof gamebase.LeaveRoomResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    LeaveRoomResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (
          !$util.isInteger(message.balance) &&
          !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high))
        )
          return "balance: integer|Long expected";
      return null;
    };

    /**
     * Creates a LeaveRoomResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.LeaveRoomResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.LeaveRoomResp} LeaveRoomResp
     */
    LeaveRoomResp.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.LeaveRoomResp) return object;
      var message = new $root.gamebase.LeaveRoomResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.balance != null)
        if ($util.Long) (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
        else if (typeof object.balance === "string") message.balance = parseInt(object.balance, 10);
        else if (typeof object.balance === "number") message.balance = object.balance;
        else if (typeof object.balance === "object")
          message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
      return message;
    };

    /**
     * Creates a plain object from a LeaveRoomResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.LeaveRoomResp
     * @static
     * @param {gamebase.LeaveRoomResp} message LeaveRoomResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    LeaveRoomResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.balance =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.balance = options.longs === String ? "0" : 0;
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (typeof message.balance === "number")
          object.balance = options.longs === String ? String(message.balance) : message.balance;
        else
          object.balance =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.balance)
              : options.longs === Number
                ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber()
                : message.balance;
      return object;
    };

    /**
     * Converts this LeaveRoomResp to JSON.
     * @function toJSON
     * @memberof gamebase.LeaveRoomResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    LeaveRoomResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return LeaveRoomResp;
  })();

  gamebase.KickoutRoomPush = (function () {
    /**
     * Properties of a KickoutRoomPush.
     * @memberof gamebase
     * @interface IKickoutRoomPush
     * @property {number|null} [result] KickoutRoomPush result
     */

    /**
     * Constructs a new KickoutRoomPush.
     * @memberof gamebase
     * @classdesc Represents a KickoutRoomPush.
     * @implements IKickoutRoomPush
     * @constructor
     * @param {gamebase.IKickoutRoomPush=} [properties] Properties to set
     */
    function KickoutRoomPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * KickoutRoomPush result.
     * @member {number} result
     * @memberof gamebase.KickoutRoomPush
     * @instance
     */
    KickoutRoomPush.prototype.result = 0;

    /**
     * Creates a new KickoutRoomPush instance using the specified properties.
     * @function create
     * @memberof gamebase.KickoutRoomPush
     * @static
     * @param {gamebase.IKickoutRoomPush=} [properties] Properties to set
     * @returns {gamebase.KickoutRoomPush} KickoutRoomPush instance
     */
    KickoutRoomPush.create = function create(properties) {
      return new KickoutRoomPush(properties);
    };

    /**
     * Encodes the specified KickoutRoomPush message. Does not implicitly {@link gamebase.KickoutRoomPush.verify|verify} messages.
     * @function encode
     * @memberof gamebase.KickoutRoomPush
     * @static
     * @param {gamebase.IKickoutRoomPush} message KickoutRoomPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KickoutRoomPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified KickoutRoomPush message, length delimited. Does not implicitly {@link gamebase.KickoutRoomPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.KickoutRoomPush
     * @static
     * @param {gamebase.IKickoutRoomPush} message KickoutRoomPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    KickoutRoomPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a KickoutRoomPush message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.KickoutRoomPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.KickoutRoomPush} KickoutRoomPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KickoutRoomPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.KickoutRoomPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.result = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a KickoutRoomPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.KickoutRoomPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.KickoutRoomPush} KickoutRoomPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    KickoutRoomPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a KickoutRoomPush message.
     * @function verify
     * @memberof gamebase.KickoutRoomPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    KickoutRoomPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a KickoutRoomPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.KickoutRoomPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.KickoutRoomPush} KickoutRoomPush
     */
    KickoutRoomPush.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.KickoutRoomPush) return object;
      var message = new $root.gamebase.KickoutRoomPush();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a KickoutRoomPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.KickoutRoomPush
     * @static
     * @param {gamebase.KickoutRoomPush} message KickoutRoomPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    KickoutRoomPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this KickoutRoomPush to JSON.
     * @function toJSON
     * @memberof gamebase.KickoutRoomPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    KickoutRoomPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return KickoutRoomPush;
  })();

  gamebase.UserSitDownReq = (function () {
    /**
     * Properties of a UserSitDownReq.
     * @memberof gamebase
     * @interface IUserSitDownReq
     * @property {number|null} [seat_id] UserSitDownReq seat_id
     */

    /**
     * Constructs a new UserSitDownReq.
     * @memberof gamebase
     * @classdesc Represents a UserSitDownReq.
     * @implements IUserSitDownReq
     * @constructor
     * @param {gamebase.IUserSitDownReq=} [properties] Properties to set
     */
    function UserSitDownReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserSitDownReq seat_id.
     * @member {number} seat_id
     * @memberof gamebase.UserSitDownReq
     * @instance
     */
    UserSitDownReq.prototype.seat_id = 0;

    /**
     * Creates a new UserSitDownReq instance using the specified properties.
     * @function create
     * @memberof gamebase.UserSitDownReq
     * @static
     * @param {gamebase.IUserSitDownReq=} [properties] Properties to set
     * @returns {gamebase.UserSitDownReq} UserSitDownReq instance
     */
    UserSitDownReq.create = function create(properties) {
      return new UserSitDownReq(properties);
    };

    /**
     * Encodes the specified UserSitDownReq message. Does not implicitly {@link gamebase.UserSitDownReq.verify|verify} messages.
     * @function encode
     * @memberof gamebase.UserSitDownReq
     * @static
     * @param {gamebase.IUserSitDownReq} message UserSitDownReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserSitDownReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.seat_id != null && Object.hasOwnProperty.call(message, "seat_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.seat_id);
      return writer;
    };

    /**
     * Encodes the specified UserSitDownReq message, length delimited. Does not implicitly {@link gamebase.UserSitDownReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.UserSitDownReq
     * @static
     * @param {gamebase.IUserSitDownReq} message UserSitDownReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserSitDownReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserSitDownReq message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.UserSitDownReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.UserSitDownReq} UserSitDownReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserSitDownReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.UserSitDownReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.seat_id = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserSitDownReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.UserSitDownReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.UserSitDownReq} UserSitDownReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserSitDownReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserSitDownReq message.
     * @function verify
     * @memberof gamebase.UserSitDownReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserSitDownReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.seat_id != null && message.hasOwnProperty("seat_id"))
        if (!$util.isInteger(message.seat_id)) return "seat_id: integer expected";
      return null;
    };

    /**
     * Creates a UserSitDownReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.UserSitDownReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.UserSitDownReq} UserSitDownReq
     */
    UserSitDownReq.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.UserSitDownReq) return object;
      var message = new $root.gamebase.UserSitDownReq();
      if (object.seat_id != null) message.seat_id = object.seat_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a UserSitDownReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.UserSitDownReq
     * @static
     * @param {gamebase.UserSitDownReq} message UserSitDownReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserSitDownReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.seat_id = 0;
      if (message.seat_id != null && message.hasOwnProperty("seat_id")) object.seat_id = message.seat_id;
      return object;
    };

    /**
     * Converts this UserSitDownReq to JSON.
     * @function toJSON
     * @memberof gamebase.UserSitDownReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserSitDownReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return UserSitDownReq;
  })();

  gamebase.UserSitDownResp = (function () {
    /**
     * Properties of a UserSitDownResp.
     * @memberof gamebase
     * @interface IUserSitDownResp
     * @property {number|null} [result] UserSitDownResp result
     */

    /**
     * Constructs a new UserSitDownResp.
     * @memberof gamebase
     * @classdesc Represents a UserSitDownResp.
     * @implements IUserSitDownResp
     * @constructor
     * @param {gamebase.IUserSitDownResp=} [properties] Properties to set
     */
    function UserSitDownResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserSitDownResp result.
     * @member {number} result
     * @memberof gamebase.UserSitDownResp
     * @instance
     */
    UserSitDownResp.prototype.result = 0;

    /**
     * Creates a new UserSitDownResp instance using the specified properties.
     * @function create
     * @memberof gamebase.UserSitDownResp
     * @static
     * @param {gamebase.IUserSitDownResp=} [properties] Properties to set
     * @returns {gamebase.UserSitDownResp} UserSitDownResp instance
     */
    UserSitDownResp.create = function create(properties) {
      return new UserSitDownResp(properties);
    };

    /**
     * Encodes the specified UserSitDownResp message. Does not implicitly {@link gamebase.UserSitDownResp.verify|verify} messages.
     * @function encode
     * @memberof gamebase.UserSitDownResp
     * @static
     * @param {gamebase.IUserSitDownResp} message UserSitDownResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserSitDownResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      return writer;
    };

    /**
     * Encodes the specified UserSitDownResp message, length delimited. Does not implicitly {@link gamebase.UserSitDownResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.UserSitDownResp
     * @static
     * @param {gamebase.IUserSitDownResp} message UserSitDownResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserSitDownResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserSitDownResp message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.UserSitDownResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.UserSitDownResp} UserSitDownResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserSitDownResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.UserSitDownResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.result = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserSitDownResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.UserSitDownResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.UserSitDownResp} UserSitDownResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserSitDownResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserSitDownResp message.
     * @function verify
     * @memberof gamebase.UserSitDownResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserSitDownResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      return null;
    };

    /**
     * Creates a UserSitDownResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.UserSitDownResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.UserSitDownResp} UserSitDownResp
     */
    UserSitDownResp.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.UserSitDownResp) return object;
      var message = new $root.gamebase.UserSitDownResp();
      if (object.result != null) message.result = object.result | 0;
      return message;
    };

    /**
     * Creates a plain object from a UserSitDownResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.UserSitDownResp
     * @static
     * @param {gamebase.UserSitDownResp} message UserSitDownResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserSitDownResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.result = 0;
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      return object;
    };

    /**
     * Converts this UserSitDownResp to JSON.
     * @function toJSON
     * @memberof gamebase.UserSitDownResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserSitDownResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return UserSitDownResp;
  })();

  gamebase.UserSitDownPush = (function () {
    /**
     * Properties of a UserSitDownPush.
     * @memberof gamebase
     * @interface IUserSitDownPush
     * @property {number|null} [uid] UserSitDownPush uid
     * @property {number|null} [seat_id] UserSitDownPush seat_id
     * @property {string|null} [baseinfo] UserSitDownPush baseinfo
     * @property {number|Long|null} [balance] UserSitDownPush balance
     * @property {number|null} [userCount] UserSitDownPush userCount
     */

    /**
     * Constructs a new UserSitDownPush.
     * @memberof gamebase
     * @classdesc Represents a UserSitDownPush.
     * @implements IUserSitDownPush
     * @constructor
     * @param {gamebase.IUserSitDownPush=} [properties] Properties to set
     */
    function UserSitDownPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserSitDownPush uid.
     * @member {number} uid
     * @memberof gamebase.UserSitDownPush
     * @instance
     */
    UserSitDownPush.prototype.uid = 0;

    /**
     * UserSitDownPush seat_id.
     * @member {number} seat_id
     * @memberof gamebase.UserSitDownPush
     * @instance
     */
    UserSitDownPush.prototype.seat_id = 0;

    /**
     * UserSitDownPush baseinfo.
     * @member {string} baseinfo
     * @memberof gamebase.UserSitDownPush
     * @instance
     */
    UserSitDownPush.prototype.baseinfo = "";

    /**
     * UserSitDownPush balance.
     * @member {number|Long} balance
     * @memberof gamebase.UserSitDownPush
     * @instance
     */
    UserSitDownPush.prototype.balance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * UserSitDownPush userCount.
     * @member {number} userCount
     * @memberof gamebase.UserSitDownPush
     * @instance
     */
    UserSitDownPush.prototype.userCount = 0;

    /**
     * Creates a new UserSitDownPush instance using the specified properties.
     * @function create
     * @memberof gamebase.UserSitDownPush
     * @static
     * @param {gamebase.IUserSitDownPush=} [properties] Properties to set
     * @returns {gamebase.UserSitDownPush} UserSitDownPush instance
     */
    UserSitDownPush.create = function create(properties) {
      return new UserSitDownPush(properties);
    };

    /**
     * Encodes the specified UserSitDownPush message. Does not implicitly {@link gamebase.UserSitDownPush.verify|verify} messages.
     * @function encode
     * @memberof gamebase.UserSitDownPush
     * @static
     * @param {gamebase.IUserSitDownPush} message UserSitDownPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserSitDownPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.seat_id != null && Object.hasOwnProperty.call(message, "seat_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.seat_id);
      if (message.baseinfo != null && Object.hasOwnProperty.call(message, "baseinfo"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).string(message.baseinfo);
      if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.balance);
      if (message.userCount != null && Object.hasOwnProperty.call(message, "userCount"))
        writer.uint32(/* id 5, wireType 0 =*/ 40).uint32(message.userCount);
      return writer;
    };

    /**
     * Encodes the specified UserSitDownPush message, length delimited. Does not implicitly {@link gamebase.UserSitDownPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.UserSitDownPush
     * @static
     * @param {gamebase.IUserSitDownPush} message UserSitDownPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserSitDownPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserSitDownPush message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.UserSitDownPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.UserSitDownPush} UserSitDownPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserSitDownPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.UserSitDownPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.uid = reader.uint32();
            break;
          case 2:
            message.seat_id = reader.int32();
            break;
          case 3:
            message.baseinfo = reader.string();
            break;
          case 4:
            message.balance = reader.int64();
            break;
          case 5:
            message.userCount = reader.uint32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserSitDownPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.UserSitDownPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.UserSitDownPush} UserSitDownPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserSitDownPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserSitDownPush message.
     * @function verify
     * @memberof gamebase.UserSitDownPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserSitDownPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.seat_id != null && message.hasOwnProperty("seat_id"))
        if (!$util.isInteger(message.seat_id)) return "seat_id: integer expected";
      if (message.baseinfo != null && message.hasOwnProperty("baseinfo"))
        if (!$util.isString(message.baseinfo)) return "baseinfo: string expected";
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (
          !$util.isInteger(message.balance) &&
          !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high))
        )
          return "balance: integer|Long expected";
      if (message.userCount != null && message.hasOwnProperty("userCount"))
        if (!$util.isInteger(message.userCount)) return "userCount: integer expected";
      return null;
    };

    /**
     * Creates a UserSitDownPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.UserSitDownPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.UserSitDownPush} UserSitDownPush
     */
    UserSitDownPush.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.UserSitDownPush) return object;
      var message = new $root.gamebase.UserSitDownPush();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.seat_id != null) message.seat_id = object.seat_id | 0;
      if (object.baseinfo != null) message.baseinfo = String(object.baseinfo);
      if (object.balance != null)
        if ($util.Long) (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
        else if (typeof object.balance === "string") message.balance = parseInt(object.balance, 10);
        else if (typeof object.balance === "number") message.balance = object.balance;
        else if (typeof object.balance === "object")
          message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
      if (object.userCount != null) message.userCount = object.userCount >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a UserSitDownPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.UserSitDownPush
     * @static
     * @param {gamebase.UserSitDownPush} message UserSitDownPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserSitDownPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.seat_id = 0;
        object.baseinfo = "";
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.balance =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.balance = options.longs === String ? "0" : 0;
        object.userCount = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.seat_id != null && message.hasOwnProperty("seat_id")) object.seat_id = message.seat_id;
      if (message.baseinfo != null && message.hasOwnProperty("baseinfo")) object.baseinfo = message.baseinfo;
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (typeof message.balance === "number")
          object.balance = options.longs === String ? String(message.balance) : message.balance;
        else
          object.balance =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.balance)
              : options.longs === Number
                ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber()
                : message.balance;
      if (message.userCount != null && message.hasOwnProperty("userCount")) object.userCount = message.userCount;
      return object;
    };

    /**
     * Converts this UserSitDownPush to JSON.
     * @function toJSON
     * @memberof gamebase.UserSitDownPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserSitDownPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return UserSitDownPush;
  })();

  gamebase.UserJoinTableReq = (function () {
    /**
     * Properties of a UserJoinTableReq.
     * @memberof gamebase
     * @interface IUserJoinTableReq
     * @property {number|null} [table_id] UserJoinTableReq table_id
     */

    /**
     * Constructs a new UserJoinTableReq.
     * @memberof gamebase
     * @classdesc Represents a UserJoinTableReq.
     * @implements IUserJoinTableReq
     * @constructor
     * @param {gamebase.IUserJoinTableReq=} [properties] Properties to set
     */
    function UserJoinTableReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserJoinTableReq table_id.
     * @member {number} table_id
     * @memberof gamebase.UserJoinTableReq
     * @instance
     */
    UserJoinTableReq.prototype.table_id = 0;

    /**
     * Creates a new UserJoinTableReq instance using the specified properties.
     * @function create
     * @memberof gamebase.UserJoinTableReq
     * @static
     * @param {gamebase.IUserJoinTableReq=} [properties] Properties to set
     * @returns {gamebase.UserJoinTableReq} UserJoinTableReq instance
     */
    UserJoinTableReq.create = function create(properties) {
      return new UserJoinTableReq(properties);
    };

    /**
     * Encodes the specified UserJoinTableReq message. Does not implicitly {@link gamebase.UserJoinTableReq.verify|verify} messages.
     * @function encode
     * @memberof gamebase.UserJoinTableReq
     * @static
     * @param {gamebase.IUserJoinTableReq} message UserJoinTableReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserJoinTableReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.table_id != null && Object.hasOwnProperty.call(message, "table_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.table_id);
      return writer;
    };

    /**
     * Encodes the specified UserJoinTableReq message, length delimited. Does not implicitly {@link gamebase.UserJoinTableReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.UserJoinTableReq
     * @static
     * @param {gamebase.IUserJoinTableReq} message UserJoinTableReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserJoinTableReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserJoinTableReq message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.UserJoinTableReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.UserJoinTableReq} UserJoinTableReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserJoinTableReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.UserJoinTableReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.table_id = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserJoinTableReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.UserJoinTableReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.UserJoinTableReq} UserJoinTableReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserJoinTableReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserJoinTableReq message.
     * @function verify
     * @memberof gamebase.UserJoinTableReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserJoinTableReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.table_id != null && message.hasOwnProperty("table_id"))
        if (!$util.isInteger(message.table_id)) return "table_id: integer expected";
      return null;
    };

    /**
     * Creates a UserJoinTableReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.UserJoinTableReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.UserJoinTableReq} UserJoinTableReq
     */
    UserJoinTableReq.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.UserJoinTableReq) return object;
      var message = new $root.gamebase.UserJoinTableReq();
      if (object.table_id != null) message.table_id = object.table_id | 0;
      return message;
    };

    /**
     * Creates a plain object from a UserJoinTableReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.UserJoinTableReq
     * @static
     * @param {gamebase.UserJoinTableReq} message UserJoinTableReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserJoinTableReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) object.table_id = 0;
      if (message.table_id != null && message.hasOwnProperty("table_id")) object.table_id = message.table_id;
      return object;
    };

    /**
     * Converts this UserJoinTableReq to JSON.
     * @function toJSON
     * @memberof gamebase.UserJoinTableReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserJoinTableReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return UserJoinTableReq;
  })();

  gamebase.UserJoinTableResp = (function () {
    /**
     * Properties of a UserJoinTableResp.
     * @memberof gamebase
     * @interface IUserJoinTableResp
     * @property {number|null} [result] UserJoinTableResp result
     * @property {string|null} [msgname] UserJoinTableResp msgname
     * @property {Uint8Array|null} [tableinfo] UserJoinTableResp tableinfo
     * @property {number|Long|null} [balance] UserJoinTableResp balance
     * @property {string|null} [basicinfo] UserJoinTableResp basicinfo
     */

    /**
     * Constructs a new UserJoinTableResp.
     * @memberof gamebase
     * @classdesc Represents a UserJoinTableResp.
     * @implements IUserJoinTableResp
     * @constructor
     * @param {gamebase.IUserJoinTableResp=} [properties] Properties to set
     */
    function UserJoinTableResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserJoinTableResp result.
     * @member {number} result
     * @memberof gamebase.UserJoinTableResp
     * @instance
     */
    UserJoinTableResp.prototype.result = 0;

    /**
     * UserJoinTableResp msgname.
     * @member {string} msgname
     * @memberof gamebase.UserJoinTableResp
     * @instance
     */
    UserJoinTableResp.prototype.msgname = "";

    /**
     * UserJoinTableResp tableinfo.
     * @member {Uint8Array} tableinfo
     * @memberof gamebase.UserJoinTableResp
     * @instance
     */
    UserJoinTableResp.prototype.tableinfo = $util.newBuffer([]);

    /**
     * UserJoinTableResp balance.
     * @member {number|Long} balance
     * @memberof gamebase.UserJoinTableResp
     * @instance
     */
    UserJoinTableResp.prototype.balance = $util.Long ? $util.Long.fromBits(0, 0, false) : 0;

    /**
     * UserJoinTableResp basicinfo.
     * @member {string} basicinfo
     * @memberof gamebase.UserJoinTableResp
     * @instance
     */
    UserJoinTableResp.prototype.basicinfo = "";

    /**
     * Creates a new UserJoinTableResp instance using the specified properties.
     * @function create
     * @memberof gamebase.UserJoinTableResp
     * @static
     * @param {gamebase.IUserJoinTableResp=} [properties] Properties to set
     * @returns {gamebase.UserJoinTableResp} UserJoinTableResp instance
     */
    UserJoinTableResp.create = function create(properties) {
      return new UserJoinTableResp(properties);
    };

    /**
     * Encodes the specified UserJoinTableResp message. Does not implicitly {@link gamebase.UserJoinTableResp.verify|verify} messages.
     * @function encode
     * @memberof gamebase.UserJoinTableResp
     * @static
     * @param {gamebase.IUserJoinTableResp} message UserJoinTableResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserJoinTableResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.result != null && Object.hasOwnProperty.call(message, "result"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.result);
      if (message.msgname != null && Object.hasOwnProperty.call(message, "msgname"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.msgname);
      if (message.tableinfo != null && Object.hasOwnProperty.call(message, "tableinfo"))
        writer.uint32(/* id 3, wireType 2 =*/ 26).bytes(message.tableinfo);
      if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
        writer.uint32(/* id 4, wireType 0 =*/ 32).int64(message.balance);
      if (message.basicinfo != null && Object.hasOwnProperty.call(message, "basicinfo"))
        writer.uint32(/* id 5, wireType 2 =*/ 42).string(message.basicinfo);
      return writer;
    };

    /**
     * Encodes the specified UserJoinTableResp message, length delimited. Does not implicitly {@link gamebase.UserJoinTableResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.UserJoinTableResp
     * @static
     * @param {gamebase.IUserJoinTableResp} message UserJoinTableResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserJoinTableResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserJoinTableResp message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.UserJoinTableResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.UserJoinTableResp} UserJoinTableResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserJoinTableResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.UserJoinTableResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.result = reader.int32();
            break;
          case 2:
            message.msgname = reader.string();
            break;
          case 3:
            message.tableinfo = reader.bytes();
            break;
          case 4:
            message.balance = reader.int64();
            break;
          case 5:
            message.basicinfo = reader.string();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserJoinTableResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.UserJoinTableResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.UserJoinTableResp} UserJoinTableResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserJoinTableResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserJoinTableResp message.
     * @function verify
     * @memberof gamebase.UserJoinTableResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserJoinTableResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.result != null && message.hasOwnProperty("result"))
        if (!$util.isInteger(message.result)) return "result: integer expected";
      if (message.msgname != null && message.hasOwnProperty("msgname"))
        if (!$util.isString(message.msgname)) return "msgname: string expected";
      if (message.tableinfo != null && message.hasOwnProperty("tableinfo"))
        if (!((message.tableinfo && typeof message.tableinfo.length === "number") || $util.isString(message.tableinfo)))
          return "tableinfo: buffer expected";
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (
          !$util.isInteger(message.balance) &&
          !(message.balance && $util.isInteger(message.balance.low) && $util.isInteger(message.balance.high))
        )
          return "balance: integer|Long expected";
      if (message.basicinfo != null && message.hasOwnProperty("basicinfo"))
        if (!$util.isString(message.basicinfo)) return "basicinfo: string expected";
      return null;
    };

    /**
     * Creates a UserJoinTableResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.UserJoinTableResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.UserJoinTableResp} UserJoinTableResp
     */
    UserJoinTableResp.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.UserJoinTableResp) return object;
      var message = new $root.gamebase.UserJoinTableResp();
      if (object.result != null) message.result = object.result | 0;
      if (object.msgname != null) message.msgname = String(object.msgname);
      if (object.tableinfo != null)
        if (typeof object.tableinfo === "string")
          $util.base64.decode(
            object.tableinfo,
            (message.tableinfo = $util.newBuffer($util.base64.length(object.tableinfo))),
            0,
          );
        else if (object.tableinfo.length) message.tableinfo = object.tableinfo;
      if (object.balance != null)
        if ($util.Long) (message.balance = $util.Long.fromValue(object.balance)).unsigned = false;
        else if (typeof object.balance === "string") message.balance = parseInt(object.balance, 10);
        else if (typeof object.balance === "number") message.balance = object.balance;
        else if (typeof object.balance === "object")
          message.balance = new $util.LongBits(object.balance.low >>> 0, object.balance.high >>> 0).toNumber();
      if (object.basicinfo != null) message.basicinfo = String(object.basicinfo);
      return message;
    };

    /**
     * Creates a plain object from a UserJoinTableResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.UserJoinTableResp
     * @static
     * @param {gamebase.UserJoinTableResp} message UserJoinTableResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserJoinTableResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.result = 0;
        object.msgname = "";
        if (options.bytes === String) object.tableinfo = "";
        else {
          object.tableinfo = [];
          if (options.bytes !== Array) object.tableinfo = $util.newBuffer(object.tableinfo);
        }
        if ($util.Long) {
          var long = new $util.Long(0, 0, false);
          object.balance =
            options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
        } else object.balance = options.longs === String ? "0" : 0;
        object.basicinfo = "";
      }
      if (message.result != null && message.hasOwnProperty("result")) object.result = message.result;
      if (message.msgname != null && message.hasOwnProperty("msgname")) object.msgname = message.msgname;
      if (message.tableinfo != null && message.hasOwnProperty("tableinfo"))
        object.tableinfo =
          options.bytes === String
            ? $util.base64.encode(message.tableinfo, 0, message.tableinfo.length)
            : options.bytes === Array
              ? Array.prototype.slice.call(message.tableinfo)
              : message.tableinfo;
      if (message.balance != null && message.hasOwnProperty("balance"))
        if (typeof message.balance === "number")
          object.balance = options.longs === String ? String(message.balance) : message.balance;
        else
          object.balance =
            options.longs === String
              ? $util.Long.prototype.toString.call(message.balance)
              : options.longs === Number
                ? new $util.LongBits(message.balance.low >>> 0, message.balance.high >>> 0).toNumber()
                : message.balance;
      if (message.basicinfo != null && message.hasOwnProperty("basicinfo")) object.basicinfo = message.basicinfo;
      return object;
    };

    /**
     * Converts this UserJoinTableResp to JSON.
     * @function toJSON
     * @memberof gamebase.UserJoinTableResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserJoinTableResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return UserJoinTableResp;
  })();

  gamebase.AllocTablePush = (function () {
    /**
     * Properties of an AllocTablePush.
     * @memberof gamebase
     * @interface IAllocTablePush
     * @property {number|null} [table_id] AllocTablePush table_id
     * @property {number|null} [game_id] AllocTablePush game_id
     * @property {number|null} [svid] AllocTablePush svid
     */

    /**
     * Constructs a new AllocTablePush.
     * @memberof gamebase
     * @classdesc Represents an AllocTablePush.
     * @implements IAllocTablePush
     * @constructor
     * @param {gamebase.IAllocTablePush=} [properties] Properties to set
     */
    function AllocTablePush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * AllocTablePush table_id.
     * @member {number} table_id
     * @memberof gamebase.AllocTablePush
     * @instance
     */
    AllocTablePush.prototype.table_id = 0;

    /**
     * AllocTablePush game_id.
     * @member {number} game_id
     * @memberof gamebase.AllocTablePush
     * @instance
     */
    AllocTablePush.prototype.game_id = 0;

    /**
     * AllocTablePush svid.
     * @member {number} svid
     * @memberof gamebase.AllocTablePush
     * @instance
     */
    AllocTablePush.prototype.svid = 0;

    /**
     * Creates a new AllocTablePush instance using the specified properties.
     * @function create
     * @memberof gamebase.AllocTablePush
     * @static
     * @param {gamebase.IAllocTablePush=} [properties] Properties to set
     * @returns {gamebase.AllocTablePush} AllocTablePush instance
     */
    AllocTablePush.create = function create(properties) {
      return new AllocTablePush(properties);
    };

    /**
     * Encodes the specified AllocTablePush message. Does not implicitly {@link gamebase.AllocTablePush.verify|verify} messages.
     * @function encode
     * @memberof gamebase.AllocTablePush
     * @static
     * @param {gamebase.IAllocTablePush} message AllocTablePush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AllocTablePush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.table_id != null && Object.hasOwnProperty.call(message, "table_id"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).int32(message.table_id);
      if (message.game_id != null && Object.hasOwnProperty.call(message, "game_id"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.game_id);
      if (message.svid != null && Object.hasOwnProperty.call(message, "svid"))
        writer.uint32(/* id 3, wireType 0 =*/ 24).int32(message.svid);
      return writer;
    };

    /**
     * Encodes the specified AllocTablePush message, length delimited. Does not implicitly {@link gamebase.AllocTablePush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.AllocTablePush
     * @static
     * @param {gamebase.IAllocTablePush} message AllocTablePush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AllocTablePush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AllocTablePush message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.AllocTablePush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.AllocTablePush} AllocTablePush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AllocTablePush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.AllocTablePush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.table_id = reader.int32();
            break;
          case 2:
            message.game_id = reader.int32();
            break;
          case 3:
            message.svid = reader.int32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes an AllocTablePush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.AllocTablePush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.AllocTablePush} AllocTablePush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AllocTablePush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AllocTablePush message.
     * @function verify
     * @memberof gamebase.AllocTablePush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AllocTablePush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.table_id != null && message.hasOwnProperty("table_id"))
        if (!$util.isInteger(message.table_id)) return "table_id: integer expected";
      if (message.game_id != null && message.hasOwnProperty("game_id"))
        if (!$util.isInteger(message.game_id)) return "game_id: integer expected";
      if (message.svid != null && message.hasOwnProperty("svid"))
        if (!$util.isInteger(message.svid)) return "svid: integer expected";
      return null;
    };

    /**
     * Creates an AllocTablePush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.AllocTablePush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.AllocTablePush} AllocTablePush
     */
    AllocTablePush.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.AllocTablePush) return object;
      var message = new $root.gamebase.AllocTablePush();
      if (object.table_id != null) message.table_id = object.table_id | 0;
      if (object.game_id != null) message.game_id = object.game_id | 0;
      if (object.svid != null) message.svid = object.svid | 0;
      return message;
    };

    /**
     * Creates a plain object from an AllocTablePush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.AllocTablePush
     * @static
     * @param {gamebase.AllocTablePush} message AllocTablePush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AllocTablePush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.table_id = 0;
        object.game_id = 0;
        object.svid = 0;
      }
      if (message.table_id != null && message.hasOwnProperty("table_id")) object.table_id = message.table_id;
      if (message.game_id != null && message.hasOwnProperty("game_id")) object.game_id = message.game_id;
      if (message.svid != null && message.hasOwnProperty("svid")) object.svid = message.svid;
      return object;
    };

    /**
     * Converts this AllocTablePush to JSON.
     * @function toJSON
     * @memberof gamebase.AllocTablePush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AllocTablePush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return AllocTablePush;
  })();

  gamebase.GetGameRuleReq = (function () {
    /**
     * Properties of a GetGameRuleReq.
     * @memberof gamebase
     * @interface IGetGameRuleReq
     * @property {string|null} [key] GetGameRuleReq key
     * @property {string|null} [lang] GetGameRuleReq lang
     */

    /**
     * Constructs a new GetGameRuleReq.
     * @memberof gamebase
     * @classdesc Represents a GetGameRuleReq.
     * @implements IGetGameRuleReq
     * @constructor
     * @param {gamebase.IGetGameRuleReq=} [properties] Properties to set
     */
    function GetGameRuleReq(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetGameRuleReq key.
     * @member {string} key
     * @memberof gamebase.GetGameRuleReq
     * @instance
     */
    GetGameRuleReq.prototype.key = "";

    /**
     * GetGameRuleReq lang.
     * @member {string} lang
     * @memberof gamebase.GetGameRuleReq
     * @instance
     */
    GetGameRuleReq.prototype.lang = "";

    /**
     * Creates a new GetGameRuleReq instance using the specified properties.
     * @function create
     * @memberof gamebase.GetGameRuleReq
     * @static
     * @param {gamebase.IGetGameRuleReq=} [properties] Properties to set
     * @returns {gamebase.GetGameRuleReq} GetGameRuleReq instance
     */
    GetGameRuleReq.create = function create(properties) {
      return new GetGameRuleReq(properties);
    };

    /**
     * Encodes the specified GetGameRuleReq message. Does not implicitly {@link gamebase.GetGameRuleReq.verify|verify} messages.
     * @function encode
     * @memberof gamebase.GetGameRuleReq
     * @static
     * @param {gamebase.IGetGameRuleReq} message GetGameRuleReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetGameRuleReq.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.key != null && Object.hasOwnProperty.call(message, "key"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.key);
      if (message.lang != null && Object.hasOwnProperty.call(message, "lang"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.lang);
      return writer;
    };

    /**
     * Encodes the specified GetGameRuleReq message, length delimited. Does not implicitly {@link gamebase.GetGameRuleReq.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.GetGameRuleReq
     * @static
     * @param {gamebase.IGetGameRuleReq} message GetGameRuleReq message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetGameRuleReq.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetGameRuleReq message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.GetGameRuleReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.GetGameRuleReq} GetGameRuleReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetGameRuleReq.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.GetGameRuleReq();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.key = reader.string();
            break;
          case 2:
            message.lang = reader.string();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetGameRuleReq message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.GetGameRuleReq
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.GetGameRuleReq} GetGameRuleReq
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetGameRuleReq.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetGameRuleReq message.
     * @function verify
     * @memberof gamebase.GetGameRuleReq
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetGameRuleReq.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.key != null && message.hasOwnProperty("key"))
        if (!$util.isString(message.key)) return "key: string expected";
      if (message.lang != null && message.hasOwnProperty("lang"))
        if (!$util.isString(message.lang)) return "lang: string expected";
      return null;
    };

    /**
     * Creates a GetGameRuleReq message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.GetGameRuleReq
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.GetGameRuleReq} GetGameRuleReq
     */
    GetGameRuleReq.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.GetGameRuleReq) return object;
      var message = new $root.gamebase.GetGameRuleReq();
      if (object.key != null) message.key = String(object.key);
      if (object.lang != null) message.lang = String(object.lang);
      return message;
    };

    /**
     * Creates a plain object from a GetGameRuleReq message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.GetGameRuleReq
     * @static
     * @param {gamebase.GetGameRuleReq} message GetGameRuleReq
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetGameRuleReq.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.key = "";
        object.lang = "";
      }
      if (message.key != null && message.hasOwnProperty("key")) object.key = message.key;
      if (message.lang != null && message.hasOwnProperty("lang")) object.lang = message.lang;
      return object;
    };

    /**
     * Converts this GetGameRuleReq to JSON.
     * @function toJSON
     * @memberof gamebase.GetGameRuleReq
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetGameRuleReq.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return GetGameRuleReq;
  })();

  gamebase.GetGameRuleResp = (function () {
    /**
     * Properties of a GetGameRuleResp.
     * @memberof gamebase
     * @interface IGetGameRuleResp
     * @property {string|null} [key] GetGameRuleResp key
     * @property {string|null} [rules] GetGameRuleResp rules
     */

    /**
     * Constructs a new GetGameRuleResp.
     * @memberof gamebase
     * @classdesc Represents a GetGameRuleResp.
     * @implements IGetGameRuleResp
     * @constructor
     * @param {gamebase.IGetGameRuleResp=} [properties] Properties to set
     */
    function GetGameRuleResp(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetGameRuleResp key.
     * @member {string} key
     * @memberof gamebase.GetGameRuleResp
     * @instance
     */
    GetGameRuleResp.prototype.key = "";

    /**
     * GetGameRuleResp rules.
     * @member {string} rules
     * @memberof gamebase.GetGameRuleResp
     * @instance
     */
    GetGameRuleResp.prototype.rules = "";

    /**
     * Creates a new GetGameRuleResp instance using the specified properties.
     * @function create
     * @memberof gamebase.GetGameRuleResp
     * @static
     * @param {gamebase.IGetGameRuleResp=} [properties] Properties to set
     * @returns {gamebase.GetGameRuleResp} GetGameRuleResp instance
     */
    GetGameRuleResp.create = function create(properties) {
      return new GetGameRuleResp(properties);
    };

    /**
     * Encodes the specified GetGameRuleResp message. Does not implicitly {@link gamebase.GetGameRuleResp.verify|verify} messages.
     * @function encode
     * @memberof gamebase.GetGameRuleResp
     * @static
     * @param {gamebase.IGetGameRuleResp} message GetGameRuleResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetGameRuleResp.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.key != null && Object.hasOwnProperty.call(message, "key"))
        writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.key);
      if (message.rules != null && Object.hasOwnProperty.call(message, "rules"))
        writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.rules);
      return writer;
    };

    /**
     * Encodes the specified GetGameRuleResp message, length delimited. Does not implicitly {@link gamebase.GetGameRuleResp.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.GetGameRuleResp
     * @static
     * @param {gamebase.IGetGameRuleResp} message GetGameRuleResp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetGameRuleResp.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetGameRuleResp message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.GetGameRuleResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.GetGameRuleResp} GetGameRuleResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetGameRuleResp.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.GetGameRuleResp();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.key = reader.string();
            break;
          case 2:
            message.rules = reader.string();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a GetGameRuleResp message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.GetGameRuleResp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.GetGameRuleResp} GetGameRuleResp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetGameRuleResp.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetGameRuleResp message.
     * @function verify
     * @memberof gamebase.GetGameRuleResp
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetGameRuleResp.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.key != null && message.hasOwnProperty("key"))
        if (!$util.isString(message.key)) return "key: string expected";
      if (message.rules != null && message.hasOwnProperty("rules"))
        if (!$util.isString(message.rules)) return "rules: string expected";
      return null;
    };

    /**
     * Creates a GetGameRuleResp message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.GetGameRuleResp
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.GetGameRuleResp} GetGameRuleResp
     */
    GetGameRuleResp.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.GetGameRuleResp) return object;
      var message = new $root.gamebase.GetGameRuleResp();
      if (object.key != null) message.key = String(object.key);
      if (object.rules != null) message.rules = String(object.rules);
      return message;
    };

    /**
     * Creates a plain object from a GetGameRuleResp message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.GetGameRuleResp
     * @static
     * @param {gamebase.GetGameRuleResp} message GetGameRuleResp
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetGameRuleResp.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.key = "";
        object.rules = "";
      }
      if (message.key != null && message.hasOwnProperty("key")) object.key = message.key;
      if (message.rules != null && message.hasOwnProperty("rules")) object.rules = message.rules;
      return object;
    };

    /**
     * Converts this GetGameRuleResp to JSON.
     * @function toJSON
     * @memberof gamebase.GetGameRuleResp
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetGameRuleResp.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return GetGameRuleResp;
  })();

  gamebase.UserStandUpPush = (function () {
    /**
     * Properties of a UserStandUpPush.
     * @memberof gamebase
     * @interface IUserStandUpPush
     * @property {number|null} [uid] UserStandUpPush uid
     * @property {number|null} [userCount] UserStandUpPush userCount
     */

    /**
     * Constructs a new UserStandUpPush.
     * @memberof gamebase
     * @classdesc Represents a UserStandUpPush.
     * @implements IUserStandUpPush
     * @constructor
     * @param {gamebase.IUserStandUpPush=} [properties] Properties to set
     */
    function UserStandUpPush(properties) {
      if (properties)
        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
          if (properties[keys[i]] != null) this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserStandUpPush uid.
     * @member {number} uid
     * @memberof gamebase.UserStandUpPush
     * @instance
     */
    UserStandUpPush.prototype.uid = 0;

    /**
     * UserStandUpPush userCount.
     * @member {number} userCount
     * @memberof gamebase.UserStandUpPush
     * @instance
     */
    UserStandUpPush.prototype.userCount = 0;

    /**
     * Creates a new UserStandUpPush instance using the specified properties.
     * @function create
     * @memberof gamebase.UserStandUpPush
     * @static
     * @param {gamebase.IUserStandUpPush=} [properties] Properties to set
     * @returns {gamebase.UserStandUpPush} UserStandUpPush instance
     */
    UserStandUpPush.create = function create(properties) {
      return new UserStandUpPush(properties);
    };

    /**
     * Encodes the specified UserStandUpPush message. Does not implicitly {@link gamebase.UserStandUpPush.verify|verify} messages.
     * @function encode
     * @memberof gamebase.UserStandUpPush
     * @static
     * @param {gamebase.IUserStandUpPush} message UserStandUpPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserStandUpPush.encode = function encode(message, writer) {
      if (!writer) writer = $Writer.create();
      if (message.uid != null && Object.hasOwnProperty.call(message, "uid"))
        writer.uint32(/* id 1, wireType 0 =*/ 8).uint32(message.uid);
      if (message.userCount != null && Object.hasOwnProperty.call(message, "userCount"))
        writer.uint32(/* id 2, wireType 0 =*/ 16).uint32(message.userCount);
      return writer;
    };

    /**
     * Encodes the specified UserStandUpPush message, length delimited. Does not implicitly {@link gamebase.UserStandUpPush.verify|verify} messages.
     * @function encodeDelimited
     * @memberof gamebase.UserStandUpPush
     * @static
     * @param {gamebase.IUserStandUpPush} message UserStandUpPush message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserStandUpPush.encodeDelimited = function encodeDelimited(message, writer) {
      return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserStandUpPush message from the specified reader or buffer.
     * @function decode
     * @memberof gamebase.UserStandUpPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {gamebase.UserStandUpPush} UserStandUpPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserStandUpPush.decode = function decode(reader, length) {
      if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
      var end = length === undefined ? reader.len : reader.pos + length,
        message = new $root.gamebase.UserStandUpPush();
      while (reader.pos < end) {
        var tag = reader.uint32();
        switch (tag >>> 3) {
          case 1:
            message.uid = reader.uint32();
            break;
          case 2:
            message.userCount = reader.uint32();
            break;
          default:
            reader.skipType(tag & 7);
            break;
        }
      }
      return message;
    };

    /**
     * Decodes a UserStandUpPush message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof gamebase.UserStandUpPush
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {gamebase.UserStandUpPush} UserStandUpPush
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserStandUpPush.decodeDelimited = function decodeDelimited(reader) {
      if (!(reader instanceof $Reader)) reader = new $Reader(reader);
      return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserStandUpPush message.
     * @function verify
     * @memberof gamebase.UserStandUpPush
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserStandUpPush.verify = function verify(message) {
      if (typeof message !== "object" || message === null) return "object expected";
      if (message.uid != null && message.hasOwnProperty("uid"))
        if (!$util.isInteger(message.uid)) return "uid: integer expected";
      if (message.userCount != null && message.hasOwnProperty("userCount"))
        if (!$util.isInteger(message.userCount)) return "userCount: integer expected";
      return null;
    };

    /**
     * Creates a UserStandUpPush message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof gamebase.UserStandUpPush
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {gamebase.UserStandUpPush} UserStandUpPush
     */
    UserStandUpPush.fromObject = function fromObject(object) {
      if (object instanceof $root.gamebase.UserStandUpPush) return object;
      var message = new $root.gamebase.UserStandUpPush();
      if (object.uid != null) message.uid = object.uid >>> 0;
      if (object.userCount != null) message.userCount = object.userCount >>> 0;
      return message;
    };

    /**
     * Creates a plain object from a UserStandUpPush message. Also converts values to other types if specified.
     * @function toObject
     * @memberof gamebase.UserStandUpPush
     * @static
     * @param {gamebase.UserStandUpPush} message UserStandUpPush
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserStandUpPush.toObject = function toObject(message, options) {
      if (!options) options = {};
      var object = {};
      if (options.defaults) {
        object.uid = 0;
        object.userCount = 0;
      }
      if (message.uid != null && message.hasOwnProperty("uid")) object.uid = message.uid;
      if (message.userCount != null && message.hasOwnProperty("userCount")) object.userCount = message.userCount;
      return object;
    };

    /**
     * Converts this UserStandUpPush to JSON.
     * @function toJSON
     * @memberof gamebase.UserStandUpPush
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserStandUpPush.prototype.toJSON = function toJSON() {
      return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    return UserStandUpPush;
  })();

  return gamebase;
})();

module.exports = $root;
