import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { Email } from "@/const";
import { client_proto } from "@/utils/net/shared/proto";

export namespace EmailService {
  export const reqUnReadInfoReq = (uid: number, language = "en") => {
    BrowserWsClient.instance.handleSendData({
      route: Email.Req.UnReadInfo,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_MAIL,
      cmd: client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_UNREAD_INFO,
      data: {
        uid,
        language,
      },
    });
  };

  export const reqMailList = (uid: number, minSpanceID = 0, maxSpanceID = 0) => {
    BrowserWsClient.instance.handleSendData({
      route: Email.Req.MailList,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_MAIL,
      cmd: client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_MAIL_LIST,
      data: {
        uid,
        min_id: minSpanceID,
        max_id: maxSpanceID,
      },
    });
  };

  export const reqMailDelete = (uid: number, id_list: number | Array<number>) => {
    BrowserWsClient.instance.handleSendData({
      route: Email.Req.MailDelete,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_MAIL,
      cmd: client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_DELETE_LIST,
      data: {
        uid,
        id_list,
      },
    });
  };

  export const reqMailReceive = (uid: number, id_list: number | Array<number>) => {
    BrowserWsClient.instance.handleSendData({
      route: Email.Req.MailReceive,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_MAIL,
      cmd: client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_RECEIVE_LIST,
      data: {
        uid,
        id_list,
      },
    });
  };

  export const reqMailMarkRead = (uid: number, id_list: number | Array<number>) => {
    BrowserWsClient.instance.handleSendData({
      route: Email.Req.MailMarkRead,
      dsttype: client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_MAIL,
      cmd: client_proto.EMAIL_INFO_SUB_MSG_ID.EMAIL_MSG_ID_MARK_READ,
      data: {
        uid,
        id_list,
      },
    });
  };
}
