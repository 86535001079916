import { clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace Goods {
  export const Req = {
    RULES: clientProtoPrefix("GetRulesReq"),
  };

  export const Resp = {
    RULES: clientProtoPrefix("GetRulesResp"),
  };

  export const Push = {
    GetPropPush: clientProtoPrefix("GetPropPush"),
  };
}

CmdToPbName[client_proto.COMMON_MSG_SUB_ID.COMM_MSG_GET_RULES_RESP] = Goods.Resp.RULES;
// 物品获得推送
CmdToPbName[client_proto.COMMON_MSG_SUB_ID.COMM_MSG_GET_PROP_PUSH] = Goods.Push.GetPropPush;
