import { clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace Team {
  export const Resp = {
    USER_TEAM_INFO: clientProtoPrefix("GetUserTeamInfoResp"),
  };
}

CmdToPbName[client_proto.PROXY_USER_INFO_SUB_MSG_ID.PROXYMI_USER_TEAM_INFO_RESP] = Team.Resp.USER_TEAM_INFO;
