class SystemConfig {
  private static _instance: SystemConfig;
  private _delayTime!: number;
  private _CLIENT_IP!: string;
  private _cs_url!: string;

  public static get instance() {
    if (!this._instance) {
      this._instance = new SystemConfig();
    }
    return this._instance;
  }

  public get delayTime() {
    return this._delayTime;
  }

  public set delayTime(value: number) {
    this._delayTime = value;
  }

  public get CLIENT_IP() {
    return this._CLIENT_IP || process.env.NEXT_PUBLIC_MEMEBR_IP!;
  }

  public set CLIENT_IP(value: string) {
    this._CLIENT_IP = value;
  }

  public get cs_url() {
    return this._cs_url || process.env.NEXT_PUBLIC_CS_URL!;
  }

  public set cs_url(value: string) {
    this._cs_url = value;
  }
}

export default SystemConfig.instance;
