import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { client_proto } from "@/utils/net/shared/proto";
import { PURGE } from "redux-persist";

//AgentRankResponseRankListResp
type AgentRankState = {
  showListResp: client_proto.IAgentRankResponseRankShowListResp;
  dataList: client_proto.IAgentRankResponseRankListResp;
  getUserDetailsResp: client_proto.IAgentRankGetUserDetailsResp;
  rewardListResp: client_proto.IAgentRankResponseRewardListResp;
  rankListReport: client_proto.IAgentRankListReportResp;
};

const initialState: AgentRankState = {
  showListResp: {},
  dataList: {},
  getUserDetailsResp: {},
  rewardListResp: {},
  rankListReport: {},
};

export const agentRankSlice = createSlice({
  name: "agentRank",
  initialState,
  reducers: {
    setAgentRank: (state, action) => {
      return {
        ...state,
        showListResp: action.payload,
      };
    },
    setDataList: (state, action) => {
      return {
        ...state,
        dataList: action.payload,
      };
    },
    setGetUserDetailsResp: (state, action) => {
      return {
        ...state,
        getUserDetailsResp: action.payload,
      };
    },
    setRewardList: (state, action) => {
      return {
        ...state,
        rewardListResp: action.payload,
      };
    },
    setRankListReport: (state, action) => {
      return {
        ...state,
        rankListReport: action.payload,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AgentRankState>) => {
    builder.addCase(PURGE, (state, action) => {
      return initialState;
    });
  },
});

export namespace AgentRankActions {
  export const { setAgentRank, setDataList, setGetUserDetailsResp, setRewardList, setRankListReport } =
    agentRankSlice.actions;
}

export default agentRankSlice.reducer;
