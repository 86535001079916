/**
 * 钱包
 */
import { Spin } from "@/const";
import BrowserWsClient from "@/utils/net/BrowserWsClient";
import { client_proto } from "@/utils/net/shared/proto";

export namespace SpinService {
  export const reqInfo = (uid: number) => {
    const dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    const cmd = client_proto.SPIN_SUB_MSG_ID.SSMI_SPIN_INFO_REQ;
    BrowserWsClient.instance.handleSendData({
      route: Spin.Req.INFO,
      dsttype,
      cmd,
      data: {
        uid,
      },
    });
  };

  export const reqSpin = (uid: number) => {
    const dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    const cmd = client_proto.SPIN_SUB_MSG_ID.SSMI_SPIN_REQ;
    BrowserWsClient.instance.handleSendData({
      route: Spin.Req.SPIN,
      dsttype,
      cmd,
      data: {
        uid,
      },
    });
  };

  export const reqWithdRaw = (uid: number) => {
    const dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    const cmd = client_proto.SPIN_SUB_MSG_ID.SSMI_WITHDRAW_REQ;
    BrowserWsClient.instance.handleSendData({
      route: Spin.Req.SPIN,
      dsttype,
      cmd,
      data: {
        uid,
      },
    });
  };

  export const reqRecord = (page?: number) => {
    let dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    let cmd = client_proto.SPIN_SUB_MSG_ID.SSMI_WITHDRAW_LOG_REQ;
    let parm: Record<string, any> = {
      page_num: page || 1,
    };
    BrowserWsClient.instance.handleSendData({
      route: Spin.Req.RECORD,
      dsttype,
      cmd,
      data: parm,
    });
  };

  export const reqCurData = () => {
    const dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    const cmd = client_proto.SPIN_SUB_MSG_ID.SSMI_GET_CUR_DATA_REQ;
    BrowserWsClient.instance.handleSendData({
      route: Spin.Req.CURDATA,
      dsttype,
      cmd,
      data: {},
    });
  };

  export const reqPoster = () => {
    const dsttype = client_proto.SERVER_INNER_MSG_TYPE.SERVER_TYPE_USERINFO;
    const cmd = client_proto.SPIN_SUB_MSG_ID.SSMI_GET_SPIN_POSTER_REQ;
    BrowserWsClient.instance.handleSendData({
      route: Spin.Req.POSTER,
      dsttype,
      cmd,
      data: {},
    });
  };
}
