import {
  AMOUNT,
  END_TIME,
  GIFT_LIST,
  INVITE_CODE,
  QRCODE,
  RECORD_LIST,
  REWARD_LIST,
  TAR_AMOUNT,
  TICKETS,
} from "@/const/wheel";
import { ActionReducerMapBuilder, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
  [TICKETS]: 0,
  [AMOUNT]: 0,
  [TAR_AMOUNT]: 0,
  [END_TIME]: "",
  [INVITE_CODE]: "",
  [QRCODE]: "",
  [REWARD_LIST]: "",
  [GIFT_LIST]: "",
  [RECORD_LIST]: "",
};

const wheelSlice = createSlice({
  name: "wheel",
  initialState,
  reducers: {
    setWheelData(state, action) {
      const { tickets, amount, amount_full, end_time, invite_code, items, boxes, rec_list } = action.payload;
      state[TICKETS] = tickets;
      state[AMOUNT] = amount / 1000;
      state[TAR_AMOUNT] = amount_full / 1000;
      state[END_TIME] = end_time;
      state[INVITE_CODE] = invite_code;
      state[REWARD_LIST] = items;
      state[GIFT_LIST] = boxes;
      if (rec_list) {
        let list = JSON.parse(rec_list);
        // log(".........................", list);
        state[RECORD_LIST] = list;
      }
    },
    setPropertyValue(state, { payload }) {
      const { key, value } = payload;
      switch (key) {
        case TICKETS:
          state[TICKETS] = value;
          break;
        case QRCODE:
          state[QRCODE] = value;
          break;
        case END_TIME:
          state[END_TIME] = value;
          break;
        case INVITE_CODE:
          state[INVITE_CODE] = value;
          break;
        case AMOUNT:
          state[AMOUNT] = value;
          break;
        case TAR_AMOUNT:
          state[TAR_AMOUNT] = value;
          break;
        case RECORD_LIST:
          state[RECORD_LIST] = value;
          break;
        case REWARD_LIST:
          state[REWARD_LIST] = value;
          break;
        case GIFT_LIST:
          state[GIFT_LIST] = value;
          break;
        default:
          break;
      }
    },
    setCurData(state, action) {
      const { amount, target, tickets } = action.payload;
      state[AMOUNT] = amount / 1000;
      state[TAR_AMOUNT] = tickets / 1000;
      state[TICKETS] = target / 1000;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<any>) => {
    builder.addCase(PURGE, (state, action) => {
      return initialState;
    });
  },
});

export namespace WheelActions {
  export const { setWheelData, setPropertyValue, setCurData } = wheelSlice.actions;
}

export default wheelSlice.reducer;
