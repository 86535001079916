import { clientProtoPrefix } from "@/utils";

export namespace WHEEL {
  export const Req = {
    INFO: clientProtoPrefix("SpinInfoReq"),
    SPIN: clientProtoPrefix("SpinReq"),
    WITHDRAW: clientProtoPrefix("SpinWithdrawReq"),
    RECORD: clientProtoPrefix("SpinOrderLogReq"),
    CURDATA: clientProtoPrefix("SpinGetCurDataReq"),
    POSTER: clientProtoPrefix("SpinPosterReq"),
  };
  export const Res = {
    INFO: clientProtoPrefix("SpinInfoResp"),
    SPIN: clientProtoPrefix("SpinResp"),
    WITHDRAW: clientProtoPrefix("SpinWithdrawResp"),
    RECORD: clientProtoPrefix("SpinOrderLogResp"),
    ADD: clientProtoPrefix("SpinRecordAddPush"),
    CURDATA: clientProtoPrefix("SpinGetCurDataResp"),
    POSTER: clientProtoPrefix("SpinPosterResp"),
  };
}

export const TICKETS = "tickets"; // 剩余次数
export const AMOUNT = "amount"; // 当前累计金额
export const TAR_AMOUNT = "targetAmount"; // 满足提取金额
export const END_TIME = "endTime"; // 结束时间
export const INVITE_CODE = "inviteCode"; // 邀请码
export const QRCODE = "qrcode"; // 二维码
export const REWARD_LIST = "rewardList"; // 转盘奖励列表
export const GIFT_LIST = "giftList"; // 礼物列表
export const RECORD_LIST = "recordList"; // 历史记录列表
