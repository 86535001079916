import { client_proto, agent } from "@/utils/net/shared/proto";
import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

type AuthState = {
  value: any;
  show_profile_alert: {
    aside?: boolean;
    bind_phone?: boolean;
    bind_phone_complete?: boolean;
    change_password?: boolean;
    logout?: boolean;
    avatar?: boolean;
    genders?: boolean;
    definir_senha?: boolean;
    settings?: boolean;
    net_error?: boolean;
    turntable_alert?: boolean;
    tourist_modal?: boolean;
    weak_network?: boolean;
  };
  asset: {
    amount?: number;
    coin?: number;
    rechargecount?: number;
    result?: number;
    totalbet?: number;
    totalrecharge?: number;
  };
  basic_info: client_proto.ILoginResp["basic_info"];
  user_basic_info_resp: client_proto.IGetUserBasicInfoResp;
  set_user_info_resp: client_proto.ISetUserInfoResp;
  set_lang_code_resp: client_proto.ISetLangCodeResp;
  set_bing_password_resp: any;
  reset_password_resp: any;
  report: any;
  loginType: number; //登录方式（LOGIN_TYPE_DEF 1-游客; 2-手机登录; 3-META; 4-GOOGLE）
  kickpush: agent.IKickPush | null;
  loginErrorInfo: {
    result: number;
    msg?: string;
  } | null;
};

const initialState: AuthState = {
  value: {
    isAuth: false,
    username: "",
  },
  show_profile_alert: {},
  asset: {},
  basic_info: {},
  user_basic_info_resp: {},
  set_user_info_resp: {},
  set_lang_code_resp: {},
  set_bing_password_resp: {},
  reset_password_resp: {},
  report: {},
  loginType: 1, //默认未登录
  kickpush: null,
  loginErrorInfo: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logIn: (state, action: PayloadAction<string>) => {},
    AssetResp: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.asset = payload;
    },
    BaseInfoResp: (state, action: PayloadAction<client_proto.ILoginResp["basic_info"]>) => {
      const { payload } = action;
      return {
        ...state,
        basic_info: payload,
      };
    },
    setUserBasicInfoResp: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.user_basic_info_resp = payload;
    },
    setUserInfoResp: (state, action: PayloadAction<client_proto.ISetUserInfoResp>) => {
      const { payload } = action;
      state.set_user_info_resp = payload;
    },
    setLangCodeResp: (state, action: PayloadAction<client_proto.ISetLangCodeResp>) => {
      const { payload } = action;
      state.set_lang_code_resp = payload;
    },
    setAsideAlert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, aside: payload } };
    },
    setBindPhoneAlert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, bind_phone: payload } };
    },
    setBindPhoneCompleteAlert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, bind_phone_complete: payload } };
    },
    setChangePasswordAlert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, change_password: payload } };
    },
    setLogoutAlert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, logout: payload } };
    },
    setAvatarAlert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, avatar: payload } };
    },
    setGendersAlert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, genders: payload } };
    },
    setDefinirSenhaAlert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, definir_senha: payload } };
    },
    setSettingsAlert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, settings: payload } };
    },
    setTouristModal: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, tourist_modal: payload } };
    },
    setTurntableAlert: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, turntable_alert: payload } };
    },
    setWeakNetworkAlert: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, weak_network: payload } };
    },
    setNetError: (state, action: PayloadAction<boolean>) => {
      const { payload } = action;
      return { ...state, show_profile_alert: { ...state.show_profile_alert, net_error: payload } };
    },
    setBindPassword: (state, action) => {
      const { payload } = action;
      state.set_bing_password_resp = payload;
    },
    setResetPassword: (state, action) => {
      const { payload } = action;
      state.reset_password_resp = payload;
    },
    setReport: (state, action) => {
      const { payload } = action;
      state.report = payload;
    },
    setLoginType: (state, action: PayloadAction<number>) => {
      const { payload } = action;
      return { ...state, loginType: payload };
    },
    setKickPush(state, action) {
      const { payload } = action;
      return {
        ...state,
        kickpush: payload,
      };
    },
    setLoginErrorInfo(state, action) {
      const { payload } = action;
      return {
        ...state,
        loginErrorInfo: payload,
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<AuthState>) => {
    builder.addCase(PURGE, (state, action) => {
      return initialState;
    });
  },
});

export const { logIn, AssetResp, BaseInfoResp } = authSlice.actions;
export namespace AuthActions {
  export const {
    setAsideAlert,
    setBindPhoneAlert,
    setBindPhoneCompleteAlert,
    setChangePasswordAlert,
    setLangCodeResp,
    setLogoutAlert,
    setUserBasicInfoResp,
    setUserInfoResp,
    setAvatarAlert,
    BaseInfoResp,
    setGendersAlert,
    setDefinirSenhaAlert,
    setSettingsAlert,
    setNetError,
    setWeakNetworkAlert,
    setBindPassword,
    setResetPassword,
    setTurntableAlert,
    setTouristModal,
    setReport,
    setLoginType,
    setKickPush,
    setLoginErrorInfo,
  } = authSlice.actions;
}

export default authSlice.reducer;
