import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { isNull } from "lodash-es";

dayjs.extend(utc);
dayjs.extend(timezone);

export default dayjs;

export interface TimeRemaining {
  day: string | number;
  hours: string | number;
  minutes: string | number;
  seconds: string | number;
  millisecond: string | number;
}

/** 计算出剩余时间
 * @param time 剩余时间 按照毫秒来计算
 * @param isToTxt 是否返回文本格式 默认为false
 */
export function timeRemaining(time: number, isToTxt = false): TimeRemaining | null | string {
  if (isNull(time)) {
    return null;
  }
  time = Number(time);

  let res: TimeRemaining = {
    day: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    millisecond: 0,
  };

  res.day = Math.floor(time / (24 * 3600 * 1000));
  time = time % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
  res.hours = Math.floor(time / (3600 * 1000));
  time = time % (3600 * 1000); //计算小时后剩余的毫秒数
  res.minutes = Math.floor(time / (60 * 1000));
  time = time % (60 * 1000); //计算分钟数后剩余的毫秒数
  res.seconds = Math.round(time / 1000);

  if (isToTxt) {
    let txt = "";
    if (res.day > 0) {
      txt = txt + res.day + "天";
    }
    if (res.hours > 0 || txt != "") {
      txt = txt + res.hours + "时";
    }
    if (res.minutes > 0 || txt != "") {
      txt = txt + res.minutes + "分";
    }
    if (res.seconds > 0 || txt != "") {
      txt = txt + res.seconds + "秒";
    }
    return txt;
  } else {
    if (res.day > 0) {
      res.day = res.day >= 10 ? res.day.toString() : `0${res.day}`;
    }
    if (res.hours > 0) {
      res.hours = res.hours >= 10 ? res.hours.toString() : `0${res.hours}`;
    }
    if (res.minutes > 0) {
      res.minutes = res.minutes >= 10 ? res.minutes.toString() : `0${res.minutes}`;
    }
    if (res.seconds > 0) {
      res.seconds = res.seconds >= 10 ? res.seconds.toString() : `0${res.seconds}`;
    }
    return res;
  }
}

/** 计算出剩余时间
 * @param time 剩余时间 按照毫秒来计算
 * @param isToTxt 是否返回文本格式 默认为false
 */
export function timeRemainingSeconde(time: number): TimeRemaining | null {
  if (isNull(time)) {
    return null;
  }
  time = Number(time);

  let res: TimeRemaining = {
    day: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    millisecond: 0,
  };

  const oneDay = 24 * 60 * 60;
  const oneHour = 60 * 60;
  const oneMinute = 60;

  res.day = Math.floor(time / oneDay);
  if (res.day >= 0) {
    res.day = res.day >= 10 ? res.day.toString() : `0${res.day}`;
  }
  time = time % oneDay;
  res.hours = Math.floor(time / oneHour);
  if (res.hours >= 0) {
    res.hours = res.hours >= 10 ? res.hours.toString() : `0${res.hours}`;
  }
  time = time % oneHour;
  res.minutes = Math.floor(time / oneMinute);
  if (res.minutes >= 0) {
    res.minutes = res.minutes >= 10 ? res.minutes.toString() : `0${res.minutes}`;
  }
  time = time % oneMinute;
  res.seconds = time;
  if (res.seconds >= 0) {
    res.seconds = res.seconds >= 10 ? res.seconds.toString() : `0${res.seconds}`;
  }
  return res;
}
