function getDecimalsFromFormat(format: string) {
  const match = format.match(/\.(\d+)/);
  return match ? match[1].length : 0;
}

function numeral(value: number, decimals: string = "0.00a") {
  const units = ["", "K", "M", "B", "T"];
  let unitIndex = 0;

  while (Math.abs(value) >= 1000 && unitIndex < units.length - 1) {
    value /= 1000;
    unitIndex++;
  }

  const decimalPlaces = getDecimalsFromFormat(decimals);
  const formattedValue = truncateDecimals(value, decimalPlaces);

  return formattedValue + units[unitIndex];
}

function formatPercentage(num: number, decimalPlaces = 2) {
  let formattedNum = num.toFixed(decimalPlaces);
  // 去除末尾多余的0和小数点
  formattedNum = formattedNum.replace(/\.?0+$/, "");
  return formattedNum;
}

// 处理小数
function truncateDecimals(value: number, decimalPlaces: number) {
  const factor = Math.pow(10, decimalPlaces);
  const truncatedValue = Math.floor(value * factor) / factor;
  return truncatedValue.toFixed(decimalPlaces);
}

export { formatPercentage, truncateDecimals };

export default numeral;
