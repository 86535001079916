"use client";

import { isServer, systemConfigUtils } from "@/utils";

let workerRef: Worker | undefined;

if (!isServer) {
  workerRef = new Worker(new URL("@/worker/delayTime.ts", import.meta.url));
  workerRef.onmessage = (event: MessageEvent) => {
    const { data } = event;
    // 获取添加后的时间
    // console.log("worker 返回的数据 delayTime", data);
    systemConfigUtils.delayTime = data;
  };
}

export { workerRef };
