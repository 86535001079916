import { EventGlobalManager } from "../events";
import { isHttps, isWss } from "@/utils";
import WsBase from "./WsBase";

interface SendData {
  route: string;
  dsttype: number;
  cmd: number;
  data: any;
  force?: boolean;
}

class BrowserWsClient extends WsBase {
  private static _instance: BrowserWsClient;
  private _socket!: WebSocket;

  public static get instance() {
    if (!this._instance) {
      this._instance = new BrowserWsClient();
    }
    return this._instance;
  }

  public getSocket() {
    return this._socket;
  }

  public setSocket(socket: WebSocket) {
    this._socket = socket;
  }

  public connect(url: string) {
    if (!this._socket) {
      try {
        const proto = isHttps() ? "wss" : "ws";
        const wsUrl = isWss(url) ? url : `${proto}://${url}`;
        this.connectUrl = wsUrl;
        this._socket = new WebSocket(wsUrl);
        this._socket.onopen = () => {
          console.log("WebSocket connected");
          this.startHeartbeat();
        };
        this._socket.onmessage = this.onMessage.bind(this);
        this._socket.onclose = this.onClose.bind(this);
        this._socket.onerror = this.onError.bind(this);
      } catch (err) {
        console.error(err);
        setTimeout(() => {
          this.connect(url);
        }, 100);
      }
    }
  }

  public onMessage(event: MessageEvent) {
    const data = event.data as Blob;
    data.arrayBuffer().then((buf) => {
      if (buf) {
        const recvData = this.recvRsp(buf) as any;
        if (recvData) {
          const { route } = recvData;
          EventGlobalManager.emit(route, recvData);
        }
      }
    });
  }

  public onClose() {
    console.error("WebSocket closed");
    this.reconnect();
  }

  public onError() {
    console.error("WebSocket error");
    this.reconnect();
  }

  public handleSendData(payload: SendData) {
    const { route, dsttype, cmd, data, force } = payload;
    if (!this.uid) {
      const uid = data?.uid || data?.user_id;
      if (uid) {
        this.uid = uid;
      }
    }
    this.send(route, dsttype, cmd, data, force);
  }

  /**
   * 用于测试接口使用一般不推荐
   * @param url
   * @param payload
   * @returns
   */
  public connectAndSendData(url: string, payload: SendData) {
    return new Promise<any>((resolve, reject) => {
      this._socket = new WebSocket(url);
      this._socket.onopen = () => {
        const { route, dsttype, cmd, data } = payload;
        this.send(route, dsttype, cmd, data);
      };

      this._socket.onmessage = (e: MessageEvent) => {
        const data = e.data as Blob;
        data.arrayBuffer().then((buf) => {
          const recvData = this.recvRsp(buf);

          if (recvData) {
            resolve(recvData);
            setTimeout(() => {
              this._socket.close();
            }, 10);
          }
        });
      };
      this._socket.onerror = (err: any) => {
        console.error("WebSocket error:", err);
        reject(err);
        this._socket.close();
      };

      this._socket.onclose = () => {
        reject("WebSocket closed");
      };
    });
  }

  public send(route: string, dsttype: number, cmd: number, data?: any, force = false) {
    this.setSendQueue(route, dsttype, cmd, data, force);
  }
}

export default BrowserWsClient;
