import { clientProtoPrefix } from "@/utils";
import { CmdToPbName, client_proto } from "@/utils/net/INet";

export namespace Hall {
  export const Req = {
    BANNER_HALL: clientProtoPrefix("BannerHall"),
  };

  export const Resp = {
    BANNER_HALL: clientProtoPrefix("BannerHall"),
  };
}

CmdToPbName[client_proto.BANNER_INFO_SUB_MSG_ID.BANNER_MSG_ID_PULL_HALL_RESP] = Hall.Resp.BANNER_HALL;
